import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresLeftLittleRightBig = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
           <g id="littleLabiaLeft_x5F_bigLabiaRigth">
								<path fill={r_labia_color} className="st--5" d="M187.4,109.7c-1.2,7.2-19,31.3-20.3,31.3s-10.5-24.1-11.7-31.3c-0.5-2.7-0.5-5.4,0-8.1
									c0.6-3.1,2.8-12,3.8-14.2c1.7-3.8,5.1-17.7,7.9-17.7s8.4,12.9,10.1,16.7c0.7,1.6,9,10.8,9.6,12.9
									C187.8,102.7,188,106.2,187.4,109.7z"/>
								<path class="st2" d="M187.8,109.8c-0.3,1.7-1.1,3.3-1.8,4.8c-0.7,1.5-1.6,3-2.4,4.4c-1.7,2.9-3.5,5.7-5.4,8.5
									c-1.9,2.8-3.8,5.5-5.8,8.2c-1,1.3-2,2.7-3.1,3.9c-0.3,0.3-0.6,0.6-0.9,0.9c-0.1,0.2-0.3,0.3-0.5,0.5
									c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.4,0-0.5-0.1c-0.1-0.1-0.1-0.1-0.2-0.2
									c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.4-0.4-0.8-0.6-1.1c-1.4-3-2.6-6.2-3.8-9.3
									c-1.2-3.1-2.3-6.3-3.3-9.5c-0.5-1.6-1-3.2-1.5-4.8c-0.5-1.6-1-3.2-1.3-4.8c-0.7-3.3-0.9-6.7-0.1-10c0.7-3.3,1.4-6.5,2.3-9.8
									c0.2-0.8,0.5-1.6,0.7-2.4c0.1-0.4,0.3-0.8,0.4-1.2c0.2-0.4,0.3-0.8,0.4-1.1c0.6-1.6,1.1-3.1,1.6-4.7c1.1-3.2,2-6.4,3.6-9.4
									c0.4-0.8,0.8-1.5,1.5-2.2c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.1,0.6-0.2,0.9-0.1c0.6,0.1,1,0.4,1.3,0.7c1.3,1.2,2.2,2.7,3.1,4.1
									c1.7,2.9,3.2,5.9,4.5,9l1,2.3c0.2,0.4,0.3,0.8,0.5,1.1c0.2,0.3,0.4,0.7,0.7,1c1,1.3,2.1,2.6,3.1,3.9c1,1.3,2.1,2.6,3.1,3.9
									c0.5,0.7,1,1.3,1.5,2c0.2,0.3,0.5,0.7,0.7,1.1c0.2,0.4,0.4,0.9,0.5,1.3C188.2,102.9,188.4,106.4,187.8,109.8z M187.1,109.6
									c0.5-3.2,0.4-6.6-0.5-9.8c-0.1-0.4-0.2-0.7-0.4-1.1c-0.2-0.3-0.4-0.7-0.7-1c-0.5-0.7-1-1.3-1.5-2c-1-1.3-2.1-2.6-3.1-3.9
									c-1.1-1.3-2.1-2.6-3.2-3.9c-0.3-0.3-0.5-0.7-0.8-1.1c-0.3-0.4-0.4-0.8-0.6-1.2l-1-2.3c-1.4-3-2.8-6-4.5-8.9
									c-0.8-1.4-1.7-2.9-2.9-3.9c-0.3-0.3-0.6-0.5-0.8-0.5c-0.2-0.1-0.4,0.1-0.7,0.3c-0.5,0.5-0.9,1.2-1.3,1.9
									c-1.5,2.9-2.5,6.1-3.5,9.3c-0.5,1.6-1,3.2-1.6,4.8c-0.1,0.4-0.3,0.8-0.5,1.2c-0.2,0.4-0.3,0.7-0.4,1.1
									c-0.3,0.8-0.5,1.6-0.8,2.4c-0.9,3.2-1.8,6.4-2.5,9.7c-0.8,3.2-0.8,6.6-0.1,9.8c0.7,3.2,1.8,6.4,2.9,9.6
									c1.1,3.1,2.3,6.3,3.5,9.4c1.2,3.1,2.5,6.2,4,9.2c0.2,0.4,0.4,0.7,0.6,1.1c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.1,0.1,0.2,0.2
									c0,0,0.1,0.1,0,0c0,0-0.1,0-0.2,0c-0.1,0,0,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.3,0.4-0.4
									c0.3-0.3,0.5-0.6,0.8-0.9c1.1-1.3,2.1-2.6,3.1-3.9c2-2.6,4-5.4,5.9-8.1c1.9-2.8,3.7-5.5,5.4-8.4c0.8-1.4,1.7-2.9,2.4-4.4
									C186.1,112.7,186.8,111.2,187.1,109.6z"/>
							</g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}