import React from 'react'
import { useSelector } from 'react-redux'

export const Hymen2 = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)
    const r_allWhite = useSelector(state => state.parameters.allWhite)

    return (
    <path id="Hymen2" fill={r_allWhite ? 'white' : '#e79ac3'} className="st8" d="
    M166.9,99c-1,0-5.6,2.7-5.6,10.6s3.6,14.3,5.7,14.3s6.7-6.4,6.7-14.3S167.9,99,166.9,99z" />
)
}
