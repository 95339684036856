import React from 'react'
import { useSelector } from 'react-redux'

export const Ridges = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Ridges">
                                    <path className="st2" d="M165,116c0.8,0.2,1.7,0.3,2.5,0.4c0.4,0,0.9,0,1.3-0.1c0.4,0,0.8-0.1,1.2-0.3c-0.4,0.3-0.8,0.5-1.2,0.5
									c-0.4,0.1-0.9,0.1-1.3,0.1C166.6,116.6,165.8,116.4,165,116z"/>
                                    <path className="st2" d="M165,119.8c0.8,0.2,1.7,0.3,2.5,0.4c0.4,0,0.9,0,1.3-0.1c0.4,0,0.8-0.1,1.2-0.3
									c-0.4,0.3-0.8,0.5-1.2,0.5c-0.4,0.1-0.9,0.1-1.3,0.1C166.7,120.4,165.8,120.2,165,119.8z"/>
                                    <path className="st2" d="M164.2,113.5c1.1,0.4,2.2,0.6,3.4,0.6c0.6,0,1.1,0,1.7-0.1c0.6-0.1,1.1-0.2,1.6-0.5
									c-0.5,0.4-1,0.6-1.6,0.8c-0.6,0.1-1.2,0.2-1.8,0.2C166.3,114.4,165.2,114,164.2,113.5z"/>
                                    <path className="st2" d="M164.2,109.4c1.1,0.4,2.2,0.6,3.4,0.6c0.6,0,1.1,0,1.7-0.1c0.6-0.1,1.1-0.2,1.6-0.5
									c-0.5,0.4-1,0.6-1.6,0.8c-0.6,0.1-1.2,0.2-1.8,0.2C166.3,110.3,165.2,110,164.2,109.4z"/>
                                    <path className="st2" d="M164.2,107.3c1.1,0.4,2.2,0.6,3.4,0.6c0.6,0,1.1,0,1.7-0.1c0.6-0.1,1.1-0.2,1.6-0.5
									c-0.5,0.4-1,0.6-1.6,0.8c-0.6,0.1-1.2,0.2-1.8,0.2C166.3,108.2,165.2,107.9,164.2,107.3z"/>
                                </g>
    )
}