import React from 'react'
import {useSelector} from 'react-redux'

export const Hymen1v2 = (props) => {

    const r_allWhite = useSelector(state => state.parameters.allWhite)

    return (
        <path id="Hymen1" fill={r_allWhite ? 'white' : '#e79ac3'} className="st8 hymen-target" d="
        M 166.9 99 c -1 0 -5.6 2.7 -5.6 10.6 s 3.6 14.3 5.7 14.3 s 6.7 -6.4 6.7 -14.3 S 167.9 99 166.9 99
        M 168.7 116 c -0.35 1.12 -0.91 1.4 -1.4 1.4 c -0.42 0 -1.12 -0.28 -1.4 -1.4 c -0.21 -0.7 0.07 -1.68 0 -2.66 c -0.07 -0.77 -0.56 -1.61 -0.56 -2.52 c 0 -0.7 0.49 -1.26 0.56 -1.75 c 0.07 -0.77 -0.07 -1.54 0.07 -1.96 c 0.42 -0.7 1.05 -0.42 1.26 -0.42 c 0.28 0 0.91 -0.35 1.33 0.42 c 0.21 0.42 0 1.19 0.14 1.96 c 0.07 0.49 0.56 1.05 0.56 1.75 c 0 0.91 -0.49 1.75 -0.56 2.52 c -0.07 0.98 0.14 1.96 0 2.66
        "/>
    )
}
