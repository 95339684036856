import React from 'react'
import { useSelector } from 'react-redux'

export const Cicatrice3 = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Cicatrice3" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                <path className="st1" d="M166.8,100.1c0.1,1.3,0.3,12.2,0.3,12.2" />
                <path className="st2" d="M166.8,100.1c0.2,1,0.4,2,0.4,3l0.2,3.1c0.1,2,0.2,4.1,0.2,6.1h-1c-0.1-2-0.1-4.1-0.1-6.1v-3.1
				C166.5,102.1,166.6,101.1,166.8,100.1z"/>
            </g>
    )
}