import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { first, second } from '../../services/translation/i18n'

export const TypeHymen = (props) => {

    const r_action = useDispatch()
    const r_typeHymen = useSelector(state => state.parameters.typeHymen)

    return (
        <div className={`m-05 p-05 r transition ${r_typeHymen === props.hymenNb ? ' b-2-secondary' : 'b-2-white'}`} style={{width: 200}} onClick={() => {
            r_action({ type: 'SET_TYPE_HYMEN', typeHymen: props.hymenNb })
        }}>
            <div style={{width: 70, height: 130, margin: 'auto'}}><img src={props.typeHymen.img}/></div>
            <div className="text-bold text-18">{first.t(props.typeHymen.label)}</div>
            {first.language !== second.language ? <div className="text-italic text-14">{second.t(props.typeHymen.label)}</div> : ""}

        </div>
    )
} 
