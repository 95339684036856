import React from 'react'
import { useSelector } from 'react-redux'

export const PetitesLevres1a = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetitesLevres1a" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                        <path fill={r_labia_color} className="st--5" d="M178.5,109.3c-1.2,7.2-10.4,31.3-11.7,31.3s-10.5-24.1-11.7-31.3c-0.5-2.7-0.5-5.4,0-8.1
						c0.6-3.1,4.9-12,5.8-14.2c1.4-3.7,3-10.7,5.9-10.7s3.9,6,5.2,9.7c0.6,1.7,5.3,10.8,5.9,12.9
						C178.9,102.2,179.1,105.8,178.5,109.3z"/>
                        <path className="st2" d="M178.8,109.3c-0.6,2.9-1.4,5.7-2.4,8.4c-0.9,2.8-1.9,5.5-2.9,8.2s-2.1,5.4-3.2,8.1c-0.6,1.3-1.1,2.7-1.8,4
						c-0.3,0.7-0.6,1.3-1,1.9c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3,0-0.4-0.2c-0.3-0.3-0.5-0.6-0.7-0.9
						c-1.3-2.6-2.5-5.3-3.6-8c-1.1-2.7-2.2-5.4-3.2-8.1s-2-5.5-2.8-8.3c-1-2.8-1.6-5.7-1.9-8.6c-0.1-1.5-0.1-3,0.1-4.4
						c0.1-0.7,0.2-1.5,0.4-2.2c0.2-0.7,0.5-1.4,0.7-2.1c1-2.8,2.2-5.4,3.4-8c0.6-1.3,1.2-2.6,1.7-4s0.9-2.7,1.4-4.1
						c0.5-1.4,1.1-2.7,1.9-4c0.4-0.7,1-1.3,1.7-1.7c0.8-0.4,1.8-0.3,2.5,0.3c0.6,0.5,1.1,1.1,1.4,1.9c0.3,0.7,0.6,1.4,0.9,2.1
						c0.5,1.4,0.9,2.8,1.3,4.2c0.2,0.7,0.4,1.3,0.7,2c0.3,0.7,0.6,1.3,0.9,2l1.9,3.9c0.6,1.3,1.2,2.6,1.8,4c0.3,0.7,0.6,1.3,0.8,2
						c0.2,0.7,0.4,1.4,0.6,2.2C179.3,103.5,179.4,106.4,178.8,109.3z M178.2,109.2c0.5-2.8,0.4-5.7-0.2-8.5
						c-0.1-0.7-0.3-1.4-0.5-2.1c-0.2-0.7-0.5-1.3-0.8-2c-0.6-1.3-1.2-2.6-1.9-3.9l-1.9-3.9c-0.3-0.6-0.6-1.3-0.9-2
						c-0.3-0.7-0.5-1.4-0.8-2.1c-0.4-1.4-0.8-2.8-1.3-4.1c-0.4-1.3-1-2.5-2-3.5c-0.4-0.4-1.1-0.5-1.6-0.2c-0.6,0.3-1,0.8-1.3,1.4
						c-0.7,1.2-1.3,2.5-1.7,3.9l-1.4,4.1c-0.5,1.4-1.1,2.7-1.7,4.1c-2.2,5.3-5.3,10.5-4.7,16.3c0.2,2.9,0.8,5.7,1.7,8.4
						c0.8,2.8,1.7,5.5,2.7,8.3c1,2.7,2,5.5,3,8.2c1,2.7,2.2,5.4,3.5,8c0.2,0.3,0.3,0.6,0.6,0.9c0,0.2,0.3-0.2,0.5-0.5
						c0.4-0.6,0.7-1.3,1-1.9c0.6-1.3,1.2-2.6,1.8-4c1.1-2.7,2.2-5.4,3.2-8.1c1-2.7,2-5.5,2.8-8.2
						C176.9,114.8,177.6,112.1,178.2,109.2z"/>
                        <path className="st2" d="M166.8,84.1c-0.8,2-1.8,3.8-2.7,5.7s-1.9,3.7-2.7,5.6c-0.8,1.9-1.5,3.9-2,5.9c-0.5,2-0.7,4.1-0.6,6.2
						c-0.2-2.1,0-4.2,0.4-6.2c0.5-2,1.1-4.1,1.9-6c0.8-1.9,1.7-3.8,2.6-5.7s1.8-3.7,2.5-5.7l0,0c0.1-0.2,0.2-0.2,0.4-0.2
						C166.9,83.8,166.9,83.9,166.8,84.1C166.9,84.1,166.9,84.1,166.8,84.1L166.8,84.1z"/>
                        <path className="st2" d="M167.5,84.7c1.9,2.9,3.7,5.9,5.2,9c0.8,1.6,1.5,3.2,2,4.8c0.6,1.6,0.8,3.4,0.5,5.1c0.2-1.7,0-3.5-0.7-5.1
						c-0.6-1.6-1.3-3.2-2.1-4.7c-1.6-3-3.5-6-5.5-8.8c-0.1-0.1-0.1-0.3,0.1-0.4c0,0,0,0,0,0C167.2,84.5,167.4,84.5,167.5,84.7
						C167.4,84.6,167.4,84.6,167.5,84.7L167.5,84.7z"/>
                        <path className="st2" d="M174.1,107.3c0.1,1.6,0.3,3.2,0.4,4.8c0.1,1.6,0,3.3-0.4,4.9c-0.4,1.6-1,3.1-1.7,4.6
						c-0.7,1.4-1.3,3-1.8,4.5c0.3-1.6,0.8-3.1,1.5-4.6c0.7-1.5,1.2-3,1.6-4.6c0.3-1.6,0.5-3.2,0.4-4.8L174.1,107.3z"/>
                        <path className="st2" d="M158,98.9c-0.4,0.8-0.7,1.6-0.9,2.4c-0.2,0.8-0.3,1.7-0.4,2.5c-0.2,1.7,0,3.4,0.5,5
						c-0.8-1.6-1.1-3.3-0.9-5.1c0.1-0.9,0.3-1.7,0.5-2.6C157,100.4,157.5,99.6,158,98.9z"/>
                    </g>
    )
}