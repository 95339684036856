import React, {useEffect, useState} from 'react'
import { first, second } from '../../services/translation/i18n'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {GlobalLayout} from "../globallayout/globalLayout";

export const StepsParameters = (props) => {
    const r_step = useSelector(state => state.parameters.step)
    const r_type = useSelector(state => state.parameters.fgm_type)
    const r_fgmAll = useSelector(state => state.parameters.fgmAll)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(JSON.parse(localStorage.getItem('loadingLanguage')).loadingLanguage) {
            setLoading(false);
        }
    });

    const steps = [
        {
            title: 'anatomie',
            link: '/anatomie',
            isBlocked: false,
        },
        {
            title: 'ecran1-vulve',
            link: '/parameters',
            isBlocked: false,
        },
        {
            title: 'fgm-type',
            link: '/fgm-type',
            isBlocked: false,
        },
        {
            title: 'fgm-sub-type',
            link: '/fgm-subtype',
            isBlocked: !r_fgmAll[+r_type - 1],
        },
        {
            title: 'ecran4-priseencharge',
            link: '/care',
            isBlocked: false,
        }
    ]

    return (
            <div className="relative overflow my-05" style={{height: 80, overflowY: 'hidden'}}>
                <div className="flex absolute">
                    {steps.map((step, i) => {
                        return (
                            <Link key={i} to={step.isBlocked ? '#' : step.link}  className={`block p-05 m-05 bg-white r flex f-column f-center ${r_step === i ? 'b-2-secondary' : ''} ${step.isBlocked ? 'bg-neutral' : ''}`} style={{ width: 130 }}>
                                <div className={`text-bold text-center ${r_step === i ? 'text-secondary' : ''}`}>{first.t(step.title)}</div>
                                {first.language !== second.language ? <div className={`text-14 ${r_step === i ? 'text-secondary' : ''}`}>{second.t(step.title)}</div> : ""}
                            </Link>
                        )
                    })}
                </div>
            </div>
    )
}
