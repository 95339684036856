import React from 'react'
import {first, second} from '../services/translation/i18n'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {StepsParameters} from '../components/stepsParameters/stepsParameters'
import {FiArrowLeft} from 'react-icons/fi'
import {useWindowSize} from '../services/hooks/useWindowSize'
import {Link} from 'react-router-dom'
import {GlobalLayout} from '../components/globallayout/globalLayout'
import {BlockIllu} from '../components/svg/blockIllu'


export default function TypeFGM(props) {
    const r_action = useDispatch()
    const r_fgmAll = useSelector(state => state.parameters.fgmAll)

    const windowSize = useWindowSize()

    const loading = props.loading

    useEffect(() => {
        r_action({type: 'SET_STEP', step: 2})
        r_action({type: 'SET_SKIN_OPACITY', skinOpacity: 1})
        r_action({type: 'SET_FGM_TYPE', fgm_type: 0})
        r_action({type: 'SET_FGM_SUB_TYPE', fgm_subType: 'a'})
        r_action({type: 'SET_ANIMATION_STEP', animationStep: 0})
    }, [])

    return (
        <GlobalLayout className="bg-pattern" loading={loading}>
            <div className="flex f-column f-center f-1">
                <div className="w-xl-content flex f-full f-column f-1">
                    <StepsParameters/>
                    <div className="relative flex f-center mx-1">
                        <Link to="/parameters" className="block absolute text-white" style={{left: 0}}> <FiArrowLeft
                            size={30}/></Link>
                        <div className="f-1">
                            <h1 className="text-white text-center text-20 mt-03 text-bold">{first.t('ecran1-choix-fgm')}</h1>
                            <h2 className="text-white text-center text-18 px-2">
                                {first.language !== second.language ? second.t("ecran1-choix-fgm") : ""}
                            </h2>
                        </div>
                    </div>

                    <div className="f-1 relative overflow mt-1">
                        <div className="h-full flex">
                            {r_fgmAll.map((fgm, i) => {
                                return (
                                    <div key={i}
                                         className="relative flex f-column bg-blured-back f-full r-tl r-tr ml-1 overflow bloc-flex"
                                         style={{width: windowSize.width < 550 ? windowSize.width : 400,}}>
                                        <div className="p-1">
                                            <div className="f-1">
                                                <h3 className="text-center text-bold text-uppercase text-18">{first.t(fgm.title)}</h3>
                                                <p className="text-center mt-05 text-uppercase text-18">
                                                    {first.language !== second.language ? second.t(fgm.title) : ""}
                                                </p>

                                                <p className="text-center text-18 text-bold mt-1">{first.t(fgm.content)}</p>
                                                <p className="text-center text-14 mt-08">
                                                    {first.language !== second.language ? second.t(fgm.content) : ""}</p>
                                            </div>

                                            {r_fgmAll[+fgm.fgm - 1].subTypes.length !== 0 &&
                                            <BlockIllu
                                                type={fgm.fgm}
                                                opacityFGM={1}
                                                stepAnimation={r_fgmAll[+fgm.fgm - 1].subTypes[0] ? Object.keys(r_fgmAll[+fgm.fgm - 1].subTypes[0].animation).length - 1 : 0}
                                                opacityNormal={0}
                                                hideOptions={true}
                                            />
                                            }
                                        </div>

                                        <div className="w-full">
                                            <Link to={fgm.link ? fgm.link : `/fgm-subtype`}
                                                  onClick={() => r_action({type: 'SET_FGM_TYPE', fgm_type: fgm.fgm})}
                                                  className="block bg-secondary py-1 w-full flex f-center f-column"
                                                  style={{bottom: 0, left: 0, right: 0}}>
                                                <div
                                                    className="text-uppercase text-bolder text-white mt-05">{first.t(fgm.btnChoice)}</div>
                                                <div className="text-uppercase text-white mb-05 mt-03">
                                                    {first.language !== second.language ? second.t(fgm.btnChoice) : ""}</div>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })}


                        </div>
                    </div>
                </div>
            </div>
        </GlobalLayout>
    )
}
