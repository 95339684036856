import React from 'react'
import { useSelector } from 'react-redux'

export const Poils = (props) => {

    const r_hair_color = useSelector(state => state.parameters.hairColor)
    const r_type_hair = useSelector(state => state.parameters.typeHair)
    const r_skin_opacity = useSelector(state => state.parameters.skinOpacity)

    return (

  <g id="Layer_2" data-name="Layer 2" className="transition" style={{ opacity: r_skin_opacity }}>
    <g id="Layer_20_copy" data-name="Layer 20 copy">
      {r_type_hair >= 1 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.45,98.82h0a.08.08,0,1,0,.12.1,4.78,4.78,0,0,1,.81-.74A4.46,4.46,0,0,0,47.45,98.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.33,92.51h0a.08.08,0,0,0,.12.1,4.86,4.86,0,0,1,.82-.74A4.23,4.23,0,0,0,49.33,92.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.7,91.23h0a.07.07,0,0,0,0,.11.07.07,0,0,0,.1,0,4.5,4.5,0,0,1,.84-.73A4.14,4.14,0,0,0,53.7,91.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.15,97.06a4.43,4.43,0,0,1,.83-.72A4.73,4.73,0,0,0,50,97h0a.08.08,0,0,0,.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.41,93.58h0a.08.08,0,0,0,.12.1,4.41,4.41,0,0,1,.82-.73A4.08,4.08,0,0,0,51.41,93.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.83,91.26a4.43,4.43,0,0,1,.82-.74,4.76,4.76,0,0,0-.93.63h0a.07.07,0,0,0,0,.11A.08.08,0,0,0,51.83,91.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.92,89.89A.07.07,0,1,0,50,90a4.76,4.76,0,0,1,.82-.73,4.71,4.71,0,0,0-.93.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.68,88.8a4.47,4.47,0,0,1,.85-.7,4.35,4.35,0,0,0-1,.59h0a.07.07,0,0,0,0,.1A.08.08,0,0,0,51.68,88.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.39,86.19a5.23,5.23,0,0,0-1,.53h0a.08.08,0,0,0,.11.11A4.42,4.42,0,0,1,54.39,86.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.73,87.25a.08.08,0,0,0,0,.11.08.08,0,0,0,.11,0,4.38,4.38,0,0,1,.79-.76,4.43,4.43,0,0,0-.9.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.51,84.85h0a.07.07,0,0,0,0,.1.07.07,0,0,0,.11,0,4.44,4.44,0,0,1,.9-.64A4.26,4.26,0,0,0,53.51,84.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.39,85.68h0a.08.08,0,1,0,.12.09,4.12,4.12,0,0,1,.78-.78A4.2,4.2,0,0,0,59.39,85.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.5,85.09a.08.08,0,1,0,.11.11,4.09,4.09,0,0,1,.83-.72,4.32,4.32,0,0,0-.94.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.19,84.6h0a.08.08,0,0,0,0,.11.08.08,0,0,0,.1,0,4.35,4.35,0,0,1,.91-.63A3.94,3.94,0,0,0,55.19,84.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.3,82.9h0a.08.08,0,0,0,0,.11.09.09,0,0,0,.11,0,4.32,4.32,0,0,1,.92-.61A4.68,4.68,0,0,0,55.3,82.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.58,83.27h0a.08.08,0,0,0,0,.11.09.09,0,0,0,.11,0,3.88,3.88,0,0,1,.89-.66A4.34,4.34,0,0,0,57.58,83.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.9,81.69h0a.06.06,0,0,0,0,.1.07.07,0,0,0,.11,0,4.39,4.39,0,0,1,.91-.61A4.48,4.48,0,0,0,53.9,81.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.91,81.87a.07.07,0,0,0,0,.11A.08.08,0,0,0,58,82a4.43,4.43,0,0,1,.91-.62,4.52,4.52,0,0,0-1,.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.4,82.41a.06.06,0,0,0,0,.1.07.07,0,0,0,.1,0,4.43,4.43,0,0,1,.87-.68,4.33,4.33,0,0,0-1,.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.33,80.9h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.79,4.79,0,0,1,.92-.62A4.52,4.52,0,0,0,55.33,80.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.6,80.4h0a.08.08,0,0,0,.1.12,4.28,4.28,0,0,1,.92-.6A4.06,4.06,0,0,0,56.6,80.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.46,80.73h0a.08.08,0,0,0,.11.12,4,4,0,0,1,.9-.63A4.49,4.49,0,0,0,59.46,80.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,79.44a.09.09,0,0,0,.11,0,4.11,4.11,0,0,1,.93-.6,4.29,4.29,0,0,0-1,.47h0S55,79.4,55,79.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,79.35a.08.08,0,0,0,0,.11.07.07,0,0,0,.11,0,4.45,4.45,0,0,1,.93-.59,4.29,4.29,0,0,0-1,.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.17,80.06h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.89,4.89,0,0,1,.89-.66A4.11,4.11,0,0,0,61.17,80.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.87,78.07h0a.07.07,0,0,0,0,.1s.07,0,.11,0a4.75,4.75,0,0,1,.95-.56A4.48,4.48,0,0,0,55.87,78.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.71,78.65h0a.07.07,0,0,0,0,.11.08.08,0,0,0,.11,0,4.65,4.65,0,0,1,.92-.59A4.41,4.41,0,0,0,60.71,78.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.57,77.11a4.42,4.42,0,0,0-1,.41h0a.08.08,0,0,0,0,.11.08.08,0,0,0,.11,0A4.24,4.24,0,0,1,58.57,77.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,77.51h0a.07.07,0,0,0,0,.1.07.07,0,0,0,.11,0,4.24,4.24,0,0,1,1-.54A4.42,4.42,0,0,0,59.14,77.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,76.56h0s0,.07,0,.11a.08.08,0,0,0,.11,0,4.78,4.78,0,0,1,1-.53A4.94,4.94,0,0,0,56,76.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.93,76.4a4.63,4.63,0,0,0-1,.4h0a.07.07,0,0,0,0,.1.06.06,0,0,0,.1,0A5.07,5.07,0,0,1,61.93,76.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.47,75.81h0a.06.06,0,0,0,0,.1.07.07,0,0,0,.11,0,4.27,4.27,0,0,1,1-.51A4.4,4.4,0,0,0,58.47,75.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.56,75.19h0a.08.08,0,0,0,0,.11.08.08,0,0,0,.1,0,4.38,4.38,0,0,1,1-.52A4.1,4.1,0,0,0,56.56,75.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.08,75.9a.09.09,0,0,0,0,.11.09.09,0,0,0,.11,0,4.38,4.38,0,0,1,1-.52,4.47,4.47,0,0,0-1.06.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.12,75h0a.07.07,0,0,0,0,.11.08.08,0,0,0,.1,0,3.94,3.94,0,0,1,1-.5A4,4,0,0,0,60.12,75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.41,74.37a.09.09,0,0,0,.11,0,4.34,4.34,0,0,1,1-.51,4.45,4.45,0,0,0-1.06.37h0A.09.09,0,0,0,58.41,74.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.74,73.36a4.4,4.4,0,0,0-1.05.38h0s0,.07,0,.11a.09.09,0,0,0,.11,0A3.94,3.94,0,0,1,57.74,73.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.54,74.13h0a.08.08,0,0,0,0,.11.09.09,0,0,0,.11,0,4.38,4.38,0,0,1,1-.5A4.73,4.73,0,0,0,61.54,74.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,72.92h0a.07.07,0,0,0,0,.11.08.08,0,0,0,.1,0,4.57,4.57,0,0,1,1-.51A4.37,4.37,0,0,0,59,72.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.65,73.27a.08.08,0,0,0,.09.13,4.31,4.31,0,0,1,1-.5,5.1,5.1,0,0,0-1.06.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.86,72.55a.09.09,0,0,0,0,.11.07.07,0,0,0,.11,0,4.76,4.76,0,0,1,1-.5,4.61,4.61,0,0,0-1.06.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.52,71.87a.07.07,0,0,0,0,.1.07.07,0,0,0,.11,0,4.75,4.75,0,0,1,1-.52,4.91,4.91,0,0,0-1,.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.59,71.15a4.63,4.63,0,0,0-1.05.4h0a.07.07,0,0,0,0,.11.08.08,0,0,0,.1,0A4.57,4.57,0,0,1,57.59,71.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.14,70.94a4.15,4.15,0,0,0-1.06.36h0a.09.09,0,0,0,0,.11.09.09,0,0,0,.11,0A4.28,4.28,0,0,1,61.14,70.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.62,70.84h0s0,.07,0,.11a.09.09,0,0,0,.11,0,4.31,4.31,0,0,1,1-.52A4.4,4.4,0,0,0,61.62,70.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.81,69.73a4.46,4.46,0,0,0-1.05.4h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0A4.78,4.78,0,0,1,57.81,69.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.07,69.35a4.61,4.61,0,0,0-1.06.37h0a.07.07,0,0,0,0,.11.08.08,0,0,0,.1,0A3.94,3.94,0,0,1,61.07,69.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.08,68.85h0a.06.06,0,0,0,0,.1s.07,0,.11,0a3.91,3.91,0,0,1,1-.53A4.2,4.2,0,0,0,58.08,68.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,68h0a.07.07,0,0,0,0,.1s.07,0,.11,0A4.48,4.48,0,0,1,58,67.5,4.73,4.73,0,0,0,57,68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.76,67.39a4,4,0,0,0-1,.39h0a.07.07,0,0,0,0,.11s.07,0,.11,0A4.1,4.1,0,0,1,60.76,67.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.26,67.37h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.13,4.13,0,0,1,1-.55A4.48,4.48,0,0,0,61.26,67.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.18,66.5h0a.08.08,0,0,0,0,.11.08.08,0,0,0,.11,0,4.49,4.49,0,0,1,1-.54A4.12,4.12,0,0,0,58.18,66.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.7,66.14a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.11,4.11,0,0,1,.93-.6,4.32,4.32,0,0,0-1,.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.62,65.17h0a.08.08,0,0,0,0,.11.08.08,0,0,0,.11,0,4.45,4.45,0,0,1,.93-.59A4.29,4.29,0,0,0,57.62,65.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.62,65.35h0a.08.08,0,0,0,.1.12,4.32,4.32,0,0,1,.92-.61A4.44,4.44,0,0,0,61.62,65.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.67,64.92a.07.07,0,0,0,0,.1.07.07,0,0,0,.11,0,4.27,4.27,0,0,1,.89-.64,4.47,4.47,0,0,0-1,.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.47,63.94a4.29,4.29,0,0,0-1,.47h0a.08.08,0,0,0,.1.12A4.45,4.45,0,0,1,60.47,63.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.6,63.69h0a.08.08,0,0,0,.11.11,4.36,4.36,0,0,1,.9-.62A4.45,4.45,0,0,0,58.6,63.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.75,63.81h0a.08.08,0,0,0,.1.12,4.72,4.72,0,0,1,.93-.59A4.91,4.91,0,0,0,61.75,63.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.6,62.34a4.14,4.14,0,0,0-1,.56h0a.08.08,0,0,0,.11.11A4.54,4.54,0,0,1,60.6,62.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.85,62.6h0a.08.08,0,0,0,.11.12,4.27,4.27,0,0,1,.89-.64A4.47,4.47,0,0,0,60.85,62.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,62.14h0a.07.07,0,1,0,.1.11,4.43,4.43,0,0,1,.91-.62A4.68,4.68,0,0,0,62,62.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.12,61.5h0a.08.08,0,0,0,.11.11,4.38,4.38,0,0,1,.87-.67A4.29,4.29,0,0,0,60.12,61.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.33,61h0a.07.07,0,0,0,0,.11.09.09,0,0,0,.11,0,4.09,4.09,0,0,1,.9-.64A4.54,4.54,0,0,0,62.33,61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.48,60.4h0a.08.08,0,0,0,.11.11,4.16,4.16,0,0,1,.89-.65A4.34,4.34,0,0,0,61.48,60.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.28,60.33a4.73,4.73,0,0,1,.84-.7,4.53,4.53,0,0,0-.95.59h0a.09.09,0,0,0,0,.11A.08.08,0,0,0,59.28,60.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.16,59.7h0a.07.07,0,1,0,.11.1,4.82,4.82,0,0,1,.86-.69A4.72,4.72,0,0,0,60.16,59.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.11,59.16h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.89,4.89,0,0,1,.89-.66A4.11,4.11,0,0,0,62.11,59.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.13,58.63a.08.08,0,0,0,.11.11,4.49,4.49,0,0,1,.86-.69,4.37,4.37,0,0,0-1,.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.71,58.19a.08.08,0,0,0,0,.11.07.07,0,0,0,.11,0,4.75,4.75,0,0,1,.81-.74,4.46,4.46,0,0,0-.92.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.77,56.77h0s0,0,0,.06a4.48,4.48,0,0,0-.91.6h0a.07.07,0,0,0,0,.1.08.08,0,0,0,.11,0,4.58,4.58,0,0,1,.81-.71s0,0,0,.05a.07.07,0,0,0,.1,0,4.06,4.06,0,0,1,.85-.7A4.07,4.07,0,0,0,61.77,56.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.84,56.31h0a.08.08,0,0,0,0,.11s.09,0,.11,0a4.78,4.78,0,0,1,.75-.81A4.91,4.91,0,0,0,59.84,56.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.24,55.32a.07.07,0,0,0,0,.1.08.08,0,0,0,.11,0,4.43,4.43,0,0,1,.75-.81,4.91,4.91,0,0,0-.87.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.64,54.64a.06.06,0,0,0,0,.1.07.07,0,0,0,.1,0,4.89,4.89,0,0,1,.84-.73,4.5,4.5,0,0,0-.94.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.42,53.85a.09.09,0,0,0,0,.11.08.08,0,0,0,.11,0,4.38,4.38,0,0,1,.73-.82,4.5,4.5,0,0,0-.85.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.42,53.16a.07.07,0,0,0,0,.11.08.08,0,0,0,.11,0,4.08,4.08,0,0,1,.79-.76,4.49,4.49,0,0,0-.91.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.39,52.51h0a.07.07,0,0,0,0,.11.07.07,0,0,0,.1,0,4.84,4.84,0,0,1,.84-.72A4.46,4.46,0,0,0,62.39,52.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.41,51.42a.08.08,0,0,0,0,.11.08.08,0,0,0,.11,0,4.72,4.72,0,0,1,.75-.8,4.45,4.45,0,0,0-.87.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.53,50.71h0a.07.07,0,0,0,0,.11.06.06,0,0,0,.1,0,4.13,4.13,0,0,1,.82-.74A4.41,4.41,0,0,0,62.53,50.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.41,49.92a.06.06,0,0,0,0,.1.07.07,0,0,0,.11,0,4.34,4.34,0,0,1,.72-.83,5,5,0,0,0-.85.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.64,49.23h0a.09.09,0,0,0,0,.11s.09,0,.11,0a4.43,4.43,0,0,1,.79-.77A4.43,4.43,0,0,0,62.64,49.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.71,48.56h0a.07.07,0,0,0,0,.11.08.08,0,0,0,.1,0,4.77,4.77,0,0,1,.73-.83A4.5,4.5,0,0,0,61.71,48.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.11,47.65h0a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0,4.37,4.37,0,0,1,.66-.89A4.6,4.6,0,0,0,61.11,47.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.21,47.13h0a.07.07,0,0,0,0,.11.08.08,0,0,0,.1,0,4.14,4.14,0,0,1,.73-.83A4.21,4.21,0,0,0,62.21,47.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.61,46.16h0a.09.09,0,0,0,0,.11.08.08,0,0,0,.11,0,4.65,4.65,0,0,1,.68-.87A5,5,0,0,0,61.61,46.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.76,45.18a.07.07,0,1,0,.13.07,4.72,4.72,0,0,1,.65-.89,4.61,4.61,0,0,0-.78.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.56,43.77a4.61,4.61,0,0,0-.73.85h0a.08.08,0,0,0,0,.1.09.09,0,0,0,.11,0A4,4,0,0,1,61.56,43.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.05,42.87s0,0,0,.06a4.89,4.89,0,0,0-.55.72h0a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0,5.18,5.18,0,0,1,.42-.76h0a.07.07,0,0,0,.1,0,4.54,4.54,0,0,1,.57-.94,4.24,4.24,0,0,0-.7.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.18,42.19a.08.08,0,1,0,.15.05,4.32,4.32,0,0,1,.5-1,4.21,4.21,0,0,0-.64.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.74,41.5a.09.09,0,0,0,0,.1.07.07,0,0,0,.1,0,5.42,5.42,0,0,1,.52-1,4.86,4.86,0,0,0-.65.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.88,40.88h0a.07.07,0,0,0,.05.09.07.07,0,0,0,.1,0,4.27,4.27,0,0,1,.47-1A4.21,4.21,0,0,0,59.88,40.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.28,40.33a.07.07,0,0,0,0,.09.07.07,0,0,0,.1,0,4.15,4.15,0,0,1,.47-1,4.21,4.21,0,0,0-.61.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,39.54h0a.08.08,0,0,0,.05.1.1.1,0,0,0,.1-.06,4.27,4.27,0,0,1,.41-1A4.9,4.9,0,0,0,59,39.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.46,39a.07.07,0,0,0,0,.09.08.08,0,0,0,.1,0,4.14,4.14,0,0,1,.42-1,4.26,4.26,0,0,0-.57,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.41,38a.08.08,0,0,0,.06.1.09.09,0,0,0,.09-.06A4.08,4.08,0,0,1,57.92,37a4.71,4.71,0,0,0-.51,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.54,37.24a.09.09,0,0,0,.07.09.08.08,0,0,0,.09-.06A4.41,4.41,0,0,1,57,36.22a4.47,4.47,0,0,0-.48,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.07,36.45a.06.06,0,0,0,.06.08.08.08,0,0,0,.09-.06,4.92,4.92,0,0,1,.33-1,4.68,4.68,0,0,0-.48,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.37,35.72a.08.08,0,0,0,.06.09.08.08,0,0,0,.09-.07,4.89,4.89,0,0,1,.32-1.05,5,5,0,0,0-.47,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.75,35.27a.08.08,0,0,0,.09-.06,4.44,4.44,0,0,1,.31-1.06,4.26,4.26,0,0,0-.46,1h0A.08.08,0,0,0,54.75,35.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.43,33.67a4.56,4.56,0,0,0-.46,1h0a.07.07,0,0,0,.07.08.06.06,0,0,0,.08-.06A4.44,4.44,0,0,1,54.43,33.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.84,33.79a.07.07,0,0,0,.06.09.08.08,0,0,0,.09-.06,4.41,4.41,0,0,1,.3-1.06,4.65,4.65,0,0,0-.45,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.6,33.45a.08.08,0,0,0,.06.09.09.09,0,0,0,.09-.07A4.39,4.39,0,0,1,53,32.41a4.26,4.26,0,0,0-.44,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.64,32.73a.08.08,0,0,0,.06.09.08.08,0,0,0,.09-.07,4,4,0,0,1,.3-1.06,4.73,4.73,0,0,0-.45,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,32.63h0a.08.08,0,0,0,.16,0,4.29,4.29,0,0,1,.16-.71l0,0a1,1,0,0,1,0-.17l.06-.17a.3.3,0,0,0,0,.08,4.59,4.59,0,0,1,.24-.8,4.1,4.1,0,0,0-.44,1h0s0,.05.06.07A3.61,3.61,0,0,0,52,32.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.32,32.05h0a.09.09,0,0,0,.07.09.08.08,0,0,0,.08-.07A5.09,5.09,0,0,1,51.74,31,4.93,4.93,0,0,0,51.32,32.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.6,31.65h0a.1.1,0,0,0,.07.09.08.08,0,0,0,.08-.07A4.57,4.57,0,0,1,52,30.61,4.75,4.75,0,0,0,51.6,31.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.93,32.37h0a.08.08,0,0,0,.16,0,4,4,0,0,1,.25-1.08A4.77,4.77,0,0,0,50.93,32.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.89,31.71a4.49,4.49,0,0,1,.25-1.07,4.75,4.75,0,0,0-.4,1.05h0a.08.08,0,0,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.86,31.12a4.06,4.06,0,0,1,.25-1.07,4.3,4.3,0,0,0-.4,1.05h0a.08.08,0,0,0,.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.26,29.84a.08.08,0,0,0,.07.08.07.07,0,0,0,.08-.07,4.46,4.46,0,0,1,.2-1.09,4.47,4.47,0,0,0-.35,1.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.68,26.82h0a.08.08,0,0,0,.16,0,4.07,4.07,0,0,1,0-1.1A4.61,4.61,0,0,0,46.68,26.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.13,100.34h0a.08.08,0,1,0,.08-.13,4.35,4.35,0,0,1-.87-.68A4.6,4.6,0,0,0,34.13,100.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,98.82a4.28,4.28,0,0,0,.8.79h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A5.12,5.12,0,0,1,32,98.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.72,98.08h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.77,4.77,0,0,1-.89-.66A4.55,4.55,0,0,0,31.72,98.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.55,96.65a4,4,0,0,0,.81.78h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.37,4.37,0,0,1,29.55,96.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.1,96.23h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4,4,0,0,1-.89-.65A4.28,4.28,0,0,0,30.1,96.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.57,94.44a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.31,4.31,0,0,1-.88-.66,4.92,4.92,0,0,0,.79.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.31,93.93h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.45,4.45,0,0,1-.88-.67A3.94,3.94,0,0,0,29.31,93.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.24,92.36h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.34,4.34,0,0,1-.86-.69A4.54,4.54,0,0,0,29.24,92.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,91.48a4.54,4.54,0,0,0,.77.81h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11A4.68,4.68,0,0,1,27,91.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.65,90.79h0a.07.07,0,0,0,.08-.12A5.24,5.24,0,0,1,26.9,90,4.26,4.26,0,0,0,27.65,90.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.84,89.41a4.5,4.5,0,0,0,.73.85h0a.07.07,0,1,0,.08-.12A4.76,4.76,0,0,1,25.84,89.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,89h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.8,4.8,0,0,1-.78-.78A4.12,4.12,0,0,0,27,89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,87.19a4.34,4.34,0,0,0,.65.91h0a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11A4.07,4.07,0,0,1,25,87.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.27,86.59a.07.07,0,1,0,.1-.11,4.43,4.43,0,0,1-.72-.83,4.73,4.73,0,0,0,.61.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.82,84.64a4.07,4.07,0,0,0,.59,1h0a.07.07,0,1,0,.1-.11A4.8,4.8,0,0,1,23.82,84.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.74,84.17a.08.08,0,1,0,.11-.11,4,4,0,0,1-.67-.87,4.25,4.25,0,0,0,.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.44,83.62a.08.08,0,0,0,.11-.11,4.17,4.17,0,0,1-.67-.88,4.48,4.48,0,0,0,.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,82.09a.08.08,0,0,0,.11-.11,4.82,4.82,0,0,1-.66-.88,5,5,0,0,0,.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.14,81.19a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.79,4.79,0,0,1-.67-.87,4.29,4.29,0,0,0,.56,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.31,78.46h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.12,4.12,0,0,1-.77-.79A4.22,4.22,0,0,0,23.31,78.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.71,75.37a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.36,4.36,0,0,1-.88-.67,5,5,0,0,0,.79.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,72.85h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.1,3.92,3.92,0,0,1-.95-.55A4.54,4.54,0,0,0,25,72.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.44,71.79a.08.08,0,0,0,.06-.14,4.54,4.54,0,0,1-1-.54,4.28,4.28,0,0,0,.89.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.28,70.57h0a.09.09,0,0,0,.1,0,.07.07,0,0,0-.05-.09,4.56,4.56,0,0,1-1-.49A4.23,4.23,0,0,0,24.28,70.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.25,68.68a.08.08,0,1,0,.05-.15,4.59,4.59,0,0,1-1-.43,4.91,4.91,0,0,0,1,.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.27,67.27h0a.09.09,0,0,0,.1-.06.08.08,0,0,0-.06-.09,4.7,4.7,0,0,1-1-.4A4.16,4.16,0,0,0,24.27,67.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.09,66.12a4.38,4.38,0,0,1-1-.37,4.45,4.45,0,0,0,1,.52h0a.07.07,0,0,0,.09,0A.08.08,0,0,0,25.09,66.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.42,64.34a.08.08,0,0,0,.09-.06.09.09,0,0,0-.06-.09,4.08,4.08,0,0,1-1.05-.36,4.56,4.56,0,0,0,1,.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.88,62.45a.09.09,0,0,0,.1-.06.07.07,0,0,0-.06-.09,4.33,4.33,0,0,1-1-.38,4.68,4.68,0,0,0,1,.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.59,61.16h0a.08.08,0,0,0,0-.15,5,5,0,0,1-1-.39A5,5,0,0,0,24.59,61.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.65,60.45a.07.07,0,0,0,.09-.05.07.07,0,0,0,0-.1,4.24,4.24,0,0,1-1-.4,4.2,4.2,0,0,0,1,.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.2,57.73a.07.07,0,0,0,.09,0,.08.08,0,0,0-.05-.1,4.79,4.79,0,0,1-1-.45,3.94,3.94,0,0,0,1,.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.27,55.64h0a.09.09,0,0,0,.1,0,.08.08,0,0,0-.05-.1,4.15,4.15,0,0,1-1-.47A4.21,4.21,0,0,0,24.27,55.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,54.22s.08,0,.1,0a.07.07,0,0,0-.05-.09,4.56,4.56,0,0,1-1-.49,4.61,4.61,0,0,0,.93.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.71,50.91h0a.08.08,0,0,0,.1-.05.07.07,0,0,0,0-.1,4.13,4.13,0,0,1-1-.52A4.17,4.17,0,0,0,23.71,50.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.23,52.55a.08.08,0,0,0,.05-.15,4.32,4.32,0,0,1-1-.5,4.31,4.31,0,0,0,.92.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.79,47.93h0a.09.09,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.17,4.17,0,0,1-1-.53A4.58,4.58,0,0,0,25.79,47.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.92,49.3a4.53,4.53,0,0,0,.9.66h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.13,4.13,0,0,1,24.92,49.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.27,51.1h0a.07.07,0,0,0,.1,0,.08.08,0,0,0-.05-.1,4.49,4.49,0,0,1-1-.51A4.56,4.56,0,0,0,25.27,51.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.41,48.51a.08.08,0,1,0,.06-.14,4.57,4.57,0,0,1-1-.53,5,5,0,0,0,.9.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.48,45.26a4.6,4.6,0,0,0,.81.78h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.37,4.37,0,0,1,28.48,45.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.52,46.6a4.23,4.23,0,0,0,.81.77h0a.08.08,0,1,0,.08-.14A4.09,4.09,0,0,1,28.52,46.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.12,45.5h0a.08.08,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.32,4.32,0,0,1-1-.56A4.19,4.19,0,0,0,27.12,45.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.79,50h0a.08.08,0,1,0,.06-.14,4.49,4.49,0,0,1-.94-.56A4.6,4.6,0,0,0,28.79,50Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.24,46.42h0a.07.07,0,0,0,.1,0,.09.09,0,0,0,0-.11,4.26,4.26,0,0,1-.93-.58A4.56,4.56,0,0,0,28.24,46.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.21,47.24a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.54,4.54,0,0,1-1-.54,4.28,4.28,0,0,0,.89.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.62,48.14a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.4,4.4,0,0,1-.91-.64,4.34,4.34,0,0,0,.83.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.81,51h0a.09.09,0,0,0,.1-.05.07.07,0,0,0-.05-.09,4.81,4.81,0,0,1-1-.51A4.62,4.62,0,0,0,26.81,51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.51,47.1a4.63,4.63,0,0,0,.87.72h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11A4.18,4.18,0,0,1,27.51,47.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.51,45.05a4.58,4.58,0,0,1-.91-.62,4.24,4.24,0,0,0,.83.75h0a.09.09,0,0,0,.11,0A.08.08,0,0,0,28.51,45.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.27,50.22a.09.09,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.19,4.19,0,0,1-1-.52,4.23,4.23,0,0,0,.91.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.49,48.33a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.38,4.38,0,0,1-.87-.67,4.91,4.91,0,0,0,.78.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.37,44a.07.07,0,0,0,.11,0,.06.06,0,0,0,0-.1,4.77,4.77,0,0,1-.83-.73,4.61,4.61,0,0,0,.74.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.16,45.4a.08.08,0,0,0,.09-.13,4.11,4.11,0,0,1-.85-.71,4.24,4.24,0,0,0,.75.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.48,49.16h0a.08.08,0,0,0,.1,0,.09.09,0,0,0,0-.11,4.32,4.32,0,0,1-.92-.61A4.25,4.25,0,0,0,29.48,49.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.18,43.8h0a.07.07,0,1,0,.07-.13,5.08,5.08,0,0,1-.9-.64A5,5,0,0,0,28.18,43.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.86,41.68a4.91,4.91,0,0,0,.78.8h0a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11A4,4,0,0,1,27.86,41.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.84,42.11h0a.08.08,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.28,4.28,0,0,1-.92-.6A4.26,4.26,0,0,0,26.84,42.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,41.84a4.5,4.5,0,0,0,.85.72h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1A4.3,4.3,0,0,1,24,41.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.36,42.77a4.25,4.25,0,0,0,.84.74h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1A4.32,4.32,0,0,1,22.36,42.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.41,43.28h0a.07.07,0,1,0,.07-.13,4.72,4.72,0,0,1-.91-.62A4.58,4.58,0,0,0,22.41,43.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.33,42.93h0a.08.08,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.62,4.62,0,0,1-.9-.64A4.28,4.28,0,0,0,21.33,42.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.33,42.51h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.37,4.37,0,0,1-.89-.66A4.6,4.6,0,0,0,20.33,42.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,41.77a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.36,4.36,0,0,1-.88-.67,4.28,4.28,0,0,0,.79.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.52,41.07a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4,4,0,0,1-.87-.67,4,4,0,0,0,.79.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.42,39.57a4,4,0,0,0,.79.8h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11A4.3,4.3,0,0,1,20.42,39.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.41,40.92h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.34,4.34,0,0,1-.86-.69A4.27,4.27,0,0,0,19.41,40.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.22,38.56h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.76,4.76,0,0,1-.88-.67A4.54,4.54,0,0,0,22.22,38.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.67,38h0a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.1,4.1,0,0,1-.88-.67A4.28,4.28,0,0,0,22.67,38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.85,37.1h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.3,4.3,0,0,1-.87-.67A4.28,4.28,0,0,0,24.85,37.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.37,35.4l0,0a4.51,4.51,0,0,0,.65.92h0a.07.07,0,0,0,.09-.11,5,5,0,0,1-.44-.48s.05,0,.07,0a.09.09,0,0,0,0-.11,4.37,4.37,0,0,1-.74-.81A4.18,4.18,0,0,0,27.37,35.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.12,35.22h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.36,4.36,0,0,1-.57-1A4.44,4.44,0,0,0,31.12,35.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.67,34.8h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.34,4.34,0,0,1-.51-1A4.47,4.47,0,0,0,32.67,34.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.15,34a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.53,4.53,0,0,1-.48-1A4.32,4.32,0,0,0,33.15,34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.77,33.44h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.53,4.53,0,0,1-.46-1A4.26,4.26,0,0,0,33.77,33.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,32.22h0a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4,4,0,0,1-.44-1A4.16,4.16,0,0,0,34,32.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.14,31.6a.08.08,0,0,0,0-.11,3.94,3.94,0,0,1-.43-1A4.54,4.54,0,0,0,34,31.56h0A.07.07,0,0,0,34.14,31.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.76,29a4.38,4.38,0,0,1-.42-1c0,.13,0,.25.07.37a3.84,3.84,0,0,1-.39-.85,4.49,4.49,0,0,0,.33,1.06h0a.08.08,0,0,0,.11,0h0c0,.07,0,.14.07.22a4.68,4.68,0,0,0,.29.87h0a.07.07,0,0,0,.11,0,.06.06,0,0,0,0-.1,4.44,4.44,0,0,1-.28-.53s0,0,0,0A.08.08,0,0,0,33.76,29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.8,27.67a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.11,4.11,0,0,1-.41-1,4.58,4.58,0,0,0,.27,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.41,25.62a.08.08,0,0,0,0-.1,4.16,4.16,0,0,1-.4-1,4.13,4.13,0,0,0,.26,1.09h0A.08.08,0,0,0,38.41,25.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.57,26.77h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.61,4.61,0,0,1-.41-1A4.58,4.58,0,0,0,34.57,26.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.32,26.33a4.24,4.24,0,0,0,.27,1.09h0a.08.08,0,0,0,.14-.08A4.11,4.11,0,0,1,34.32,26.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.81,26.59h0a.08.08,0,0,0,.14-.08,4.09,4.09,0,0,1-.4-1A4.67,4.67,0,0,0,36.81,26.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.88,26.08a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.16,4.16,0,0,1-.4-1,4.56,4.56,0,0,0,.26,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.37,23.88h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.77,4.77,0,0,1-.37-1A4.69,4.69,0,0,0,42.37,23.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.49,24.73a.09.09,0,0,0,0-.11,3.74,3.74,0,0,1-.36-.93s0,0,.06,0a.07.07,0,0,0,0-.1,4.61,4.61,0,0,1-.34-1.05,4.71,4.71,0,0,0,.2,1.11h0a4.9,4.9,0,0,0,.23,1h0s0,0,0,0a4.6,4.6,0,0,0,.15.89h0s.06.05.1,0a.09.09,0,0,0,0-.1,5,5,0,0,1-.27-.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.5,94.59a4.21,4.21,0,0,0,.74.84h0a.07.07,0,0,0,.11,0,.09.09,0,0,0,0-.11A4.66,4.66,0,0,1,33.5,94.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.45,94.11a4.43,4.43,0,0,1-.77-.79,4.73,4.73,0,0,0,.66.9h0a.08.08,0,0,0,.11,0A.08.08,0,0,0,34.45,94.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.88,93.26a.08.08,0,0,0,.11-.12,4.11,4.11,0,0,1-.71-.84,4.48,4.48,0,0,0,.6,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.37,94.15a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.11,4.72,4.72,0,0,1-.8-.75,4.51,4.51,0,0,0,.71.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.31,95.42a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.72,4.72,0,0,1-.8-.75,4.86,4.86,0,0,0,.7.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.25,94.61a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.13,4.13,0,0,1-.74-.82,4.48,4.48,0,0,0,.63.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.24,92.58h0a.07.07,0,1,0,.09-.11,4.81,4.81,0,0,1-.75-.81A4.51,4.51,0,0,0,34.24,92.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.84,91.34a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.8,4.8,0,0,1-.76-.8,5.21,5.21,0,0,0,.65.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.05,89a.08.08,0,0,0,.11-.11,4.38,4.38,0,0,1-.67-.87A4.66,4.66,0,0,0,34,89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.86,88.53h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.49,4.49,0,0,1-.6-.93A4.75,4.75,0,0,0,32.86,88.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.36,87.06a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11A4.75,4.75,0,0,1,32.9,86a5,5,0,0,0,.45,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.54,85.61a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.71,4.71,0,0,1-.55-1,4.93,4.93,0,0,0,.42,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.77,84.08a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,3.91,3.91,0,0,1-.53-1,4,4,0,0,0,.4,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.24,82.78a.07.07,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.13,4.13,0,0,1-.55-1,4.27,4.27,0,0,0,.42,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.29,82a.08.08,0,1,0,.12-.1,4.26,4.26,0,0,1-.58-.93,4.05,4.05,0,0,0,.46,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.88,79.35h0a.08.08,0,0,0,.1-.12,4.08,4.08,0,0,1-.74-.81A4.41,4.41,0,0,0,28.88,79.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.73,77.72a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.43,4.43,0,0,1-.82-.74,4.51,4.51,0,0,0,.72.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.58,75.78h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.44,4.44,0,0,1-.9-.64A4.94,4.94,0,0,0,28.58,75.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.44,73.71a.08.08,0,0,0,.11,0,.09.09,0,0,0-.05-.1,4.47,4.47,0,0,1-.95-.54,4.49,4.49,0,0,0,.88.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.38,71.92a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.1,4.27,4.27,0,0,1-1-.47,4.21,4.21,0,0,0,.94.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.42,69.89a4.31,4.31,0,0,1-1-.4,4.94,4.94,0,0,0,1,.55h0a.07.07,0,0,0,.09-.05A.08.08,0,0,0,28.42,69.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.23,68.47h0a.08.08,0,0,0,0-.15,4.3,4.3,0,0,1-1-.37A4.52,4.52,0,0,0,28.23,68.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.19,66.23a4.22,4.22,0,0,0,1,.49h0a.09.09,0,0,0,.09-.06s0-.09-.07-.09A4.39,4.39,0,0,1,27.19,66.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.15,64.25a4.71,4.71,0,0,0,1,.49h0a.08.08,0,0,0,0-.15A4.44,4.44,0,0,1,27.15,64.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.52,62.55a.08.08,0,0,0,0-.15,5.07,5.07,0,0,1-.83-.28h0a.08.08,0,0,0-.06-.09,4.46,4.46,0,0,1-1-.37,4.52,4.52,0,0,0,1,.52h0s0,0,.05,0a4.15,4.15,0,0,0,.82.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.06,59.21a4.52,4.52,0,0,0,1,.56h0a.07.07,0,0,0,.09-.05.09.09,0,0,0-.05-.1A4.34,4.34,0,0,1,27.06,59.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.31,60.66a.07.07,0,0,0,.09,0,.09.09,0,0,0-.06-.1,4.21,4.21,0,0,1-1-.39,4,4,0,0,0,1,.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.14,59.25h0a.08.08,0,0,0,.1,0,.08.08,0,0,0-.06-.1,3.86,3.86,0,0,1-1-.42A4.42,4.42,0,0,0,26.14,59.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.6,58.33a.08.08,0,0,0,.1,0,.09.09,0,0,0-.05-.1,4.1,4.1,0,0,1-1-.43,4.83,4.83,0,0,0,.95.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.21,56.9a.08.08,0,0,0-.05-.1,4.12,4.12,0,0,1-1-.46,4,4,0,0,0,.95.6h0A.07.07,0,0,0,28.21,56.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.41,55.46a.07.07,0,0,0,.09-.05.07.07,0,0,0-.05-.1,4.27,4.27,0,0,1-1-.47,4.21,4.21,0,0,0,.94.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,53.34a.08.08,0,0,0,.05-.15,4.1,4.1,0,0,1-1-.53,4,4,0,0,0,.9.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.22,49.58a.08.08,0,1,0,.09-.13,3.85,3.85,0,0,1-.83-.73,4.21,4.21,0,0,0,.73.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.18,45.37a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.84,4.84,0,0,1-.72-.84,4.73,4.73,0,0,0,.61.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.81,44.15a.08.08,0,0,0,.11-.11,4.13,4.13,0,0,1-.66-.88,4.11,4.11,0,0,0,.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.5,42.69a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.17,4.17,0,0,1-.6-.92,4.08,4.08,0,0,0,.48,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.13,41.56a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.13,4.13,0,0,1-.55-1,4.48,4.48,0,0,0,.43,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.76,40.39a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.34,4.34,0,0,1-.51-1,4.85,4.85,0,0,0,.37,1.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.26,39.22a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.33,4.33,0,0,1-.46-1,4,4,0,0,0,.33,1.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,38.27a4.2,4.2,0,0,1-.44-1,4.21,4.21,0,0,0,.3,1.09h0a.07.07,0,0,0,.1,0A.07.07,0,0,0,37,38.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.35,37.14a.08.08,0,1,0,.13-.08,4.56,4.56,0,0,1-.42-1,5.14,5.14,0,0,0,.28,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.15,35.52a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.11,4.11,0,0,1-.41-1,4.15,4.15,0,0,0,.27,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.19,34.34h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.16,4.16,0,0,1-.4-1A4.56,4.56,0,0,0,37.19,34.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.6,31.51a4.31,4.31,0,0,1-.4-1,4.67,4.67,0,0,0,.26,1.09h0a.08.08,0,0,0,.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.91,29.23h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.83,4.83,0,0,1-.39-1A4.73,4.73,0,0,0,38.91,29.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.85,24.77h0a.09.09,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.25,4.25,0,0,1-.35-1A4.19,4.19,0,0,0,42.85,24.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.65,20.63a.08.08,0,0,0,.14-.06,4.77,4.77,0,0,1-.34-1.05,4.63,4.63,0,0,0,.2,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,17.94s.05-.06,0-.1a4.16,4.16,0,0,1-.34-1.05,4.78,4.78,0,0,0,.19,1.11h0A.07.07,0,0,0,40,17.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.6,32.53s0,0,0,0a4.17,4.17,0,0,0,.7.88h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.11l-.4-.34a.08.08,0,0,0,.08,0,.08.08,0,0,0,0-.11,4.43,4.43,0,0,1-.78-.78A4,4,0,0,0,20.6,32.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.36,31.87a4.52,4.52,0,0,0,.69.89h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11A4.43,4.43,0,0,1,19.36,31.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,33.91a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11A4.1,4.1,0,0,1,18.3,33a4.58,4.58,0,0,0,.71.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.51,32.61a4.18,4.18,0,0,0,.7.87h0a.06.06,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.12,4.12,0,0,1,19.51,32.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,32.14h0s.09,0,.11,0a.07.07,0,0,0,0-.11,4.1,4.1,0,0,1-.74-.81A4.11,4.11,0,0,0,22,32.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.47,33.3a.06.06,0,0,0,0-.1,4.78,4.78,0,0,1-.8-.76,4.23,4.23,0,0,0,.71.87h0A.07.07,0,0,0,19.47,33.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.3,32.42a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.44,4.44,0,0,1-.76-.8,4.23,4.23,0,0,0,.66.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.7,32.16s.09,0,.11,0a.08.08,0,0,0,0-.11,3.8,3.8,0,0,1-.77-.78,4.13,4.13,0,0,0,.67.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.5,31.91a4.73,4.73,0,0,0,.66.9h0a.08.08,0,0,0,.1-.13A4.06,4.06,0,0,1,21.5,31.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.59,30.68a4.07,4.07,0,0,0,.59.95h0a.07.07,0,1,0,.1-.11A4.47,4.47,0,0,1,22.59,30.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,30.67a.08.08,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.49,4.49,0,0,1-.6-.93,4.75,4.75,0,0,0,.48,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.05,30.33h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.53,4.53,0,0,1-.48-1A4.55,4.55,0,0,0,28.05,30.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.73,29.27h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.5,4.5,0,0,1-.45-1A4.38,4.38,0,0,0,29.73,29.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.8,28.05h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.33,4.33,0,0,1-.43-1A4.14,4.14,0,0,0,30.8,28.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.55,26.74a.07.07,0,0,0,.13-.07,4.56,4.56,0,0,1-.42-1,4.52,4.52,0,0,0,.28,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.52,25.28h0a.08.08,0,0,0,.14-.08,4.11,4.11,0,0,1-.41-1A4.24,4.24,0,0,0,32.52,25.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.22,25.38a4.56,4.56,0,0,1-.39-1,4.54,4.54,0,0,0,.25,1.09h0a.07.07,0,0,0,.1,0A.07.07,0,0,0,39.22,25.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.09,24.66a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.11,4.11,0,0,1-.38-1,4.52,4.52,0,0,0,.24,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.12,23.23a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.18,4.18,0,0,1-.35-1.05,4.19,4.19,0,0,0,.21,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.41,21.58h0a.08.08,0,0,0,.1,0,.09.09,0,0,0,0-.11,4.25,4.25,0,0,1-.35-1A4.63,4.63,0,0,0,43.41,21.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,19.45a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.27,4.27,0,0,1-.33-1.05,4.16,4.16,0,0,0,.19,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.42,18.42h0a.08.08,0,1,0,.14-.06,4.75,4.75,0,0,1-.33-1.05A5.16,5.16,0,0,0,42.42,18.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.11,17.73a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.15,4.15,0,0,1,42,16.61a4.18,4.18,0,0,0,.15,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,20.12h0s.06.07.1.05a.07.07,0,0,0,0-.09A4.45,4.45,0,0,1,44.93,19,4.67,4.67,0,0,0,45,20.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.6,20.33a4.67,4.67,0,0,0,0,1.12h0a.08.08,0,0,0,.1.06.07.07,0,0,0,.05-.09A4.52,4.52,0,0,1,45.6,20.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.43,22.28a4.68,4.68,0,0,0-.11,1.12h0a.07.07,0,0,0,.09.06.06.06,0,0,0,.06-.08A4.59,4.59,0,0,1,46.43,22.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.81,25.61h0a.08.08,0,0,0,.08.07A.09.09,0,0,0,47,25.6a4.07,4.07,0,0,1,0-1.1A4.16,4.16,0,0,0,46.81,25.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.22,26.58a4.43,4.43,0,0,1,.17-1.09,4.4,4.4,0,0,0-.32,1.07h0a.08.08,0,0,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.53,26.79a.1.1,0,0,0,.07.09.08.08,0,0,0,.08-.07,4.46,4.46,0,0,1,.25-1.08,4.75,4.75,0,0,0-.4,1.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.69,27.09h0a.08.08,0,0,0,.06.09.08.08,0,0,0,.09-.07,4,4,0,0,1,.29-1.06A4.26,4.26,0,0,0,51.69,27.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.84,27.17a.08.08,0,1,0,.16,0,4,4,0,0,1,.31-1.05,4.09,4.09,0,0,0-.46,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.69,27.48c0,.05,0,.09.06.09a.07.07,0,0,0,.09-.06,4.22,4.22,0,0,1,.34-1,4.64,4.64,0,0,0-.49,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.66,26.62a4.49,4.49,0,0,0-.51,1h0a.08.08,0,0,0,.15,0A4.46,4.46,0,0,1,56.66,26.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.09,28a.07.07,0,0,0,.06.09.08.08,0,0,0,.09-.06,4.41,4.41,0,0,1,.38-1,4.27,4.27,0,0,0-.53,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.08,28.29a.08.08,0,0,0,.1-.05,3.77,3.77,0,0,1,.39-1,4.45,4.45,0,0,0-.54,1h0A.07.07,0,0,0,58.08,28.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.81,28.78a.09.09,0,0,0,.06.1.08.08,0,0,0,.09-.06,5,5,0,0,1,.4-1,4.45,4.45,0,0,0-.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.48,28.57a.08.08,0,0,0,.06.09.07.07,0,0,0,.09-.06,5,5,0,0,1,.41-1,4.48,4.48,0,0,0-.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.65,29.21a.08.08,0,0,0,.15,0,4.27,4.27,0,0,1,.41-1,4.63,4.63,0,0,0-.56,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.37,29h0s0,0,0,0a4.6,4.6,0,0,0-.53.91v0a.07.07,0,0,0,0,.09.08.08,0,0,0,.1,0,4.92,4.92,0,0,1,.38-1s0,.05,0,.06a.07.07,0,0,0,.09-.05,4.34,4.34,0,0,1,.43-1A4.26,4.26,0,0,0,60.37,29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.76,29.67s0,0,0,.06a3.61,3.61,0,0,0-.22.44h0a.07.07,0,0,0,0,.09.08.08,0,0,0,.1-.05c0-.17.11-.33.17-.49l0,0a.78.78,0,0,1,.05-.14,2.53,2.53,0,0,1,.18-.37l-.11.16a4.39,4.39,0,0,1,.31-.66,4.46,4.46,0,0,0-.58,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.31,29.44a.07.07,0,0,0,0,.09.07.07,0,0,0,.09-.05,4.79,4.79,0,0,1,.45-1,4.66,4.66,0,0,0-.59,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.84,29.91a.08.08,0,0,0,.15,0,4.48,4.48,0,0,1,.4-1,4.3,4.3,0,0,0-.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.83,29.83a.08.08,0,0,0,.15,0,4.25,4.25,0,0,1,.38-1,4.68,4.68,0,0,0-.53,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.33,29.69s0,.09.05.09a.08.08,0,0,0,.1-.05,4.3,4.3,0,0,1,.37-1,4.45,4.45,0,0,0-.52,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.89,22.34a4.26,4.26,0,0,0,.33,1.07h0a.09.09,0,0,0,.11,0s0,0,0,0a4.08,4.08,0,0,0,.28.9h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.57,4.57,0,0,1-.4-.87s0,0,0,0A4.26,4.26,0,0,1,26.89,22.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.35,22.46h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.34,4.34,0,0,1-.51-1A4.47,4.47,0,0,0,26.35,22.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.13,22.67a.08.08,0,0,0,.13-.09,4.32,4.32,0,0,1-.48-1,4.07,4.07,0,0,0,.35,1.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.93,23h0s.07,0,.11,0a.08.08,0,0,0,0-.11,5,5,0,0,1-.51-1A4.56,4.56,0,0,0,25.93,23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.47,23.53h0a.08.08,0,1,0,.13-.09,4.15,4.15,0,0,1-.49-1A4.47,4.47,0,0,0,26.47,23.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.09,25.82h0a.07.07,0,0,0,.12-.08,4.34,4.34,0,0,1-.51-1A4.64,4.64,0,0,0,26.09,25.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.42,30.31h0a.07.07,0,0,0,.11,0,.09.09,0,0,0,0-.11,3.8,3.8,0,0,1-.53-1A4.72,4.72,0,0,0,26.42,30.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.76,32.76h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.76,4.76,0,0,1-.67-.88A4.66,4.66,0,0,0,25.76,32.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,33.58h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,3.88,3.88,0,0,1-.66-.89A4.76,4.76,0,0,0,27,33.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.27,34.61h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.46,4.46,0,0,1-.7-.86A4.72,4.72,0,0,0,27.27,34.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,35.72h0a.08.08,0,1,0,.11-.11,4.43,4.43,0,0,1-.68-.87A4.29,4.29,0,0,0,29,35.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.05,37.59h0a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.43,4.43,0,0,1-.76-.8A4.34,4.34,0,0,0,29.05,37.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.21,36.36h0a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.89,4.89,0,0,1-.66-.89A4.76,4.76,0,0,0,30.21,36.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.29,37.19h0a.08.08,0,0,0,.11-.11,4.14,4.14,0,0,1-.69-.86A4.74,4.74,0,0,0,30.29,37.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.54,35.61a4.87,4.87,0,0,0,.69.88h0s.09,0,.11,0a.07.07,0,0,0,0-.11A4.05,4.05,0,0,1,26.54,35.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.57,33.92a4.46,4.46,0,0,0,.69.88h0a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11A4.37,4.37,0,0,1,24.57,33.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.08,32.31a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.36,4.36,0,0,1-.62-.9,4.45,4.45,0,0,0,.5,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,30.07h0a.08.08,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.57,4.57,0,0,1-.47-1A5.28,5.28,0,0,0,29,30.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,26.52h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.33,4.33,0,0,1-.43-1A4.54,4.54,0,0,0,30,26.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.66,22.44a.07.07,0,0,0,0-.1,4.16,4.16,0,0,1-.4-1,4.56,4.56,0,0,0,.26,1.09h0A.08.08,0,0,0,32.66,22.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.33,20.57a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.33,4.33,0,0,1-.38-1,4.25,4.25,0,0,0,.24,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.94,18.56a.08.08,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.77,4.77,0,0,1-.37-1,4.69,4.69,0,0,0,.23,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.3,19.18a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.18,4.18,0,0,1-.38-1,4.25,4.25,0,0,0,.24,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.92,19.76a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.8,4.8,0,0,1-.38-1,4.71,4.71,0,0,0,.24,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.45,20.54h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.64,4.64,0,0,1-.39-1A4.73,4.73,0,0,0,35.45,20.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.51,23.83a4.51,4.51,0,0,1-.4-1,4.67,4.67,0,0,0,.26,1.09h0a.09.09,0,0,0,.11,0A.08.08,0,0,0,36.51,23.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.74,25.19a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.11,4.11,0,0,1-.41-1,4.24,4.24,0,0,0,.27,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.45,27.12h0a.08.08,0,0,0,.14-.08,4.56,4.56,0,0,1-.42-1A4.73,4.73,0,0,0,33.45,27.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.16,25.61h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.7,4.7,0,0,1-.4-1A4.6,4.6,0,0,0,34.16,25.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.93,25.46h0a.07.07,0,0,0,.13-.07,4.59,4.59,0,0,1-.4-1A4.56,4.56,0,0,0,36.93,25.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.52,26.72h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4,4,0,0,1-.23-.51.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.1,4.16,4.16,0,0,1-.4-1,3.34,3.34,0,0,0,.14.7s0-.07,0-.11A4.24,4.24,0,0,0,35.52,26.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.41,22.85h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.18,4.18,0,0,1-.38-1A4.25,4.25,0,0,0,42.41,22.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.59,16.26h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.14,4.14,0,0,1-.33-1A4.17,4.17,0,0,0,37.59,16.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.7,13.8h0a.08.08,0,0,0,.14-.06,4.81,4.81,0,0,1-.28-1.07A4.25,4.25,0,0,0,39.7,13.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.66,12.33h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.75,4.75,0,0,1-.24-1.07A4.25,4.25,0,0,0,40.66,12.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.65,11.51a.09.09,0,0,0,.1.05.08.08,0,0,0,0-.1,3.87,3.87,0,0,1-.24-1.07,4.17,4.17,0,0,0,.09,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.31,12.76a.07.07,0,0,0,.1,0,.07.07,0,0,0,.05-.09,4.44,4.44,0,0,1-.24-1.08,4.24,4.24,0,0,0,.09,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.56,15h0a.08.08,0,1,0,.14-.06,4.29,4.29,0,0,1-.26-1.07A4.73,4.73,0,0,0,41.56,15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.16,16.77a.07.07,0,0,0,.1,0,.08.08,0,0,0,.05-.1,4.29,4.29,0,0,1-.26-1.07,4.73,4.73,0,0,0,.11,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.48,19.59h0s.06.07.1,0a.07.07,0,0,0,.05-.09,4.89,4.89,0,0,1-.14-.64h0a.08.08,0,0,0,.05-.1,4.31,4.31,0,0,1-.21-1.08,4.22,4.22,0,0,0,.06,1.12h0s0,0,0,0A4.12,4.12,0,0,0,44.48,19.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.75,18.5a.08.08,0,1,0,.15-.05,4.4,4.4,0,0,1-.21-1.08,4.22,4.22,0,0,0,.06,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.15,14.57a4.22,4.22,0,0,0,.06,1.12h0a.07.07,0,0,0,.09.05.07.07,0,0,0,0-.09A4.47,4.47,0,0,1,43.15,14.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.44,14.11a.09.09,0,0,0,.1.06.08.08,0,0,0,.05-.1A4.4,4.4,0,0,1,46.46,13a4.2,4.2,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.27,15.32a.08.08,0,0,0,.06-.09,4.49,4.49,0,0,1,0-1.1,4.56,4.56,0,0,0-.13,1.11h0A.09.09,0,0,0,50.27,15.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.42,16.59h0a.08.08,0,1,0,.15,0,4.49,4.49,0,0,1,0-1.1A4.45,4.45,0,0,0,51.42,16.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.23,16.39h0a.08.08,0,1,0,.15,0,4.49,4.49,0,0,1,0-1.1A4.08,4.08,0,0,0,50.23,16.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.17,18.17h0a.08.08,0,0,0,.16,0,4.05,4.05,0,0,1,0-1.1A4.51,4.51,0,0,0,48.17,18.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,24.78a4.11,4.11,0,0,1,.08-1.1,4.48,4.48,0,0,0-.23,1.1h0a.08.08,0,1,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.62,27.52a4.13,4.13,0,0,1,.09-1.1,4.08,4.08,0,0,0-.24,1.1h0a.07.07,0,0,0,.08.07A.08.08,0,0,0,47.62,27.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.35,27.9a4.11,4.11,0,0,1,.08-1.1,4.07,4.07,0,0,0-.23,1.1h0a.07.07,0,0,0,.08.07A.08.08,0,0,0,47.35,27.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.26,26.46a.07.07,0,0,0,.07.08.07.07,0,0,0,.08-.08,4.51,4.51,0,0,1,.13-1.09,4.4,4.4,0,0,0-.28,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.35,23.47h0a.08.08,0,0,0,.07.08.07.07,0,0,0,.08-.07,4.47,4.47,0,0,1,.24-1.07A4.72,4.72,0,0,0,51.35,23.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.5,20.15a4.42,4.42,0,0,1,.27-1.07,4.53,4.53,0,0,0-.42,1h0a.08.08,0,0,0,.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.1,18a4.59,4.59,0,0,1,.29-1.07A5.24,5.24,0,0,0,56,18h0a.07.07,0,0,0,.06.09A.07.07,0,0,0,56.1,18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.3,18.61a.09.09,0,0,0,.07.09.07.07,0,0,0,.08-.07,4.39,4.39,0,0,1,.32-1.05,4.09,4.09,0,0,0-.46,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.86,19.6a.1.1,0,0,0,.07.09.08.08,0,0,0,.08-.07,4.9,4.9,0,0,1,.29-1.06,5,5,0,0,0-.44,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.74,23a.07.07,0,0,0,.07.08.07.07,0,0,0,.08-.07,5,5,0,0,1,.24-1.07A4.72,4.72,0,0,0,51.74,23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,24.53a4.53,4.53,0,0,1,.23-1.08,4.32,4.32,0,0,0-.38,1.06h0a.07.07,0,0,0,.07.08A.08.08,0,0,0,51,24.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,26.92a3.8,3.8,0,0,1,.17-.88s0,.05,0,.05a.07.07,0,0,0,.08-.07,4.63,4.63,0,0,1,.23-1.08A4.47,4.47,0,0,0,50.16,26h0a4.35,4.35,0,0,0-.3.88h0a.07.07,0,0,0,.07.08A.08.08,0,0,0,50,26.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,25.84a.09.09,0,0,0,.07.09.08.08,0,0,0,.08-.07,4.52,4.52,0,0,1,.26-1.07,4.69,4.69,0,0,0-.41,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.74,23.39a.09.09,0,0,0,.07.09.08.08,0,0,0,.09-.06,4,4,0,0,1,.33-1.05,4.47,4.47,0,0,0-.48,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.25,19.9a4.75,4.75,0,0,1,.39-1,4.86,4.86,0,0,0-.53,1h0a.08.08,0,0,0,.06.1A.08.08,0,0,0,59.25,19.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,19.11a.09.09,0,0,0,.06.1.08.08,0,0,0,.09-.06,4.48,4.48,0,0,1,.4-1,4.45,4.45,0,0,0-.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.68,19.4a.07.07,0,1,0,.14,0,4.73,4.73,0,0,1,.41-1,4.42,4.42,0,0,0-.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.94,21.8v0a.08.08,0,1,0,.15,0,4.28,4.28,0,0,1,.39-1A4,4,0,0,0,57.94,21.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,25.19a.08.08,0,0,0,.06.09.09.09,0,0,0,.09-.06,4.32,4.32,0,0,1,.35-1,4.53,4.53,0,0,0-.5,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.91,26.86A.08.08,0,0,0,54,27a.09.09,0,0,0,.09-.07,4,4,0,0,1,.33-1.05,4.75,4.75,0,0,0-.48,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,27.45a.08.08,0,1,0,.15,0,4.75,4.75,0,0,1,.33-1.05,4.47,4.47,0,0,0-.48,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.44,26.65a.08.08,0,1,0,.15,0,4.74,4.74,0,0,1,.36-1,3.9,3.9,0,0,0-.5,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.21,24.38a.08.08,0,0,0,0,.1.09.09,0,0,0,.1-.06,4.27,4.27,0,0,1,.41-1,4.45,4.45,0,0,0-.56,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.15,23a4.2,4.2,0,0,1,.44-1,4.46,4.46,0,0,0-.58,1h0a.08.08,0,1,0,.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.22,22.94a4.12,4.12,0,0,1,.46-1,4.24,4.24,0,0,0-.6.95h0a.07.07,0,0,0,.05.09A.07.07,0,0,0,63.22,22.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.9,23.81a4.55,4.55,0,0,1,.44-1,4.4,4.4,0,0,0-.58.95h0a.07.07,0,0,0,.05.1A.07.07,0,0,0,61.9,23.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.94,26.6a4.27,4.27,0,0,1,.41-1,3.86,3.86,0,0,0-.55,1h0a.09.09,0,0,0,.06.1A.08.08,0,0,0,58.94,26.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.64,29.22a.08.08,0,0,0,0,.1.09.09,0,0,0,.1-.06,4.27,4.27,0,0,1,.36-1,4.41,4.41,0,0,0-.51,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.27,29.68a.08.08,0,1,0,.15,0,4,4,0,0,1,.36-1,4.71,4.71,0,0,0-.51,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.88,29.9a.08.08,0,1,0,.15,0,4.5,4.5,0,0,1,.36-1.05,4.56,4.56,0,0,0-.51,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.25,29.14a.08.08,0,0,0,0,.1.09.09,0,0,0,.1-.06,4,4,0,0,1,.39-1,4.45,4.45,0,0,0-.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,27.36a4.54,4.54,0,0,1,.47-1,4.52,4.52,0,0,0-.61.95h0a.07.07,0,0,0,.05.09A.07.07,0,0,0,63,27.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.08,25.65a3.88,3.88,0,0,0-.65.91h0a.08.08,0,1,0,.15.05A3.76,3.76,0,0,1,66.08,25.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.26,26.45a.08.08,0,1,0,.15.05,4.78,4.78,0,0,1,.52-1,4.6,4.6,0,0,0-.66.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.85,28.15a3.87,3.87,0,0,0-.62.93h0a.08.08,0,0,0,.15.05A3.78,3.78,0,0,1,63.85,28.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.06,33.14a.08.08,0,0,0,.06.1.08.08,0,0,0,.09-.06,4.45,4.45,0,0,1,.39-1,4.45,4.45,0,0,0-.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.78,34.17a.08.08,0,1,0,.15,0,4.3,4.3,0,0,1,.37-1,4.31,4.31,0,0,0-.52,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.75,34.31a4.41,4.41,0,0,1,.38-1,4.24,4.24,0,0,0-.52,1h0a.08.08,0,0,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.06,33.12s0,.08,0,.1a.07.07,0,0,0,.09,0,4.53,4.53,0,0,1,.46-1,4.56,4.56,0,0,0-.6.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.63,29.86a4.5,4.5,0,0,0-.72.85h0a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0A4.72,4.72,0,0,1,68.63,29.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,29.56h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.32,4.32,0,0,1,.65-.89A4.61,4.61,0,0,0,72,29.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.91,29.52h0a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0,4.72,4.72,0,0,1,.65-.89A4.29,4.29,0,0,0,72.91,29.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.59,30.46h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.63,4.63,0,0,1,.63-.91A4.29,4.29,0,0,0,69.59,30.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,33.11h0a.08.08,0,1,0,.14.06,4.32,4.32,0,0,1,.56-1A4.19,4.19,0,0,0,66,33.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.44,34.52a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0,4.5,4.5,0,0,1,.53-1,4.51,4.51,0,0,0-.67.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,34.12a4.53,4.53,0,0,1,.52-1,4.53,4.53,0,0,0-.66.9h0a.07.07,0,0,0,0,.1A.07.07,0,0,0,65,34.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.33,32.68a4.53,4.53,0,0,1,.61-.91,4,4,0,0,0-.74.84h0a.09.09,0,0,0,0,.11A.08.08,0,0,0,68.33,32.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.23,29.19a4.29,4.29,0,0,1,.63-.9,4.57,4.57,0,0,0-.76.82h0a.07.07,0,0,0,0,.1A.07.07,0,0,0,71.23,29.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.42,27.93h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.32,4.32,0,0,1,.65-.89A4.29,4.29,0,0,0,73.42,27.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.79,27.42a.07.07,0,0,0,0,.11.08.08,0,0,0,.1,0,4.27,4.27,0,0,1,.64-.89,4.23,4.23,0,0,0-.77.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.38,27.35h0a.07.07,0,1,0,.13.07,4.63,4.63,0,0,1,.63-.91A4.29,4.29,0,0,0,71.38,27.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.65,27h0a.07.07,0,0,0,.05.1.07.07,0,0,0,.09,0,4.76,4.76,0,0,1,.48-1A4.57,4.57,0,0,0,63.65,27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.88,26.17h0a.07.07,0,0,0,.06.09.08.08,0,0,0,.09-.06,4.25,4.25,0,0,1,.35-1A4.68,4.68,0,0,0,54.88,26.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.91,25.92a5,5,0,0,1,.15-1.09,4.79,4.79,0,0,0-.3,1.08h0a.08.08,0,0,0,.07.08A.08.08,0,0,0,48.91,25.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.21,18h0a.08.08,0,0,0,.1.05.07.07,0,0,0,0-.1,4.46,4.46,0,0,1-.25-1.08A4.72,4.72,0,0,0,43.21,18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.67,19.38a4.19,4.19,0,0,0,.21,1.1h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.25,4.25,0,0,1,42.67,19.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.31,23.76a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.54,4.54,0,0,1-.41-1,5.27,5.27,0,0,0,.27,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.39,26.76h0a.07.07,0,0,0,.13-.07,4.62,4.62,0,0,1-.44-1A5.18,5.18,0,0,0,29.39,26.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.83,27.44h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.11,5,5,0,0,1-.47-1A4.75,4.75,0,0,0,27.83,27.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.6,27.37h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.78,4.78,0,0,1-.53-1A4.94,4.94,0,0,0,25.6,27.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.89,28.39h0a.08.08,0,0,0,.12-.1,4.79,4.79,0,0,1-.62-.92A5,5,0,0,0,23.89,28.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.67,30a.08.08,0,0,0,.11-.11,4.14,4.14,0,0,1-.69-.86,4.37,4.37,0,0,0,.58,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.86,34.19a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.77,4.77,0,0,1-.82-.74,4.91,4.91,0,0,0,.72.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.41,36.93h0a.09.09,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.34,4.34,0,0,1-.83-.72A4.55,4.55,0,0,0,19.41,36.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.89,38.61a.07.07,0,0,0,0-.11,4.06,4.06,0,0,1-.85-.7,4.21,4.21,0,0,0,.76.82h0A.08.08,0,0,0,19.89,38.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.53,39a4.27,4.27,0,0,0,.78.81h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1A4.34,4.34,0,0,1,19.53,39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.17,41.31h0a.07.07,0,1,0,.07-.13,4.63,4.63,0,0,1-.91-.63A4.63,4.63,0,0,0,23.17,41.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.79,40.88a5,5,0,0,0,.85.73h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.61,4.61,0,0,1,23.79,40.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.38,41.64a4,4,0,0,0,.82.77h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.33,4.33,0,0,1,21.38,41.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.92,41.36h0a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.11,4,4,0,0,1-.85-.69A4.54,4.54,0,0,0,17.92,41.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.18,36.18a.08.08,0,0,0,.09-.13,4.43,4.43,0,0,1-.82-.74,4.51,4.51,0,0,0,.72.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.4,33.9a.08.08,0,0,0,.1-.12,4.48,4.48,0,0,1-.81-.76,4.57,4.57,0,0,0,.71.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.69,30.91a.08.08,0,0,0,.1-.12A4.81,4.81,0,0,1,20,30a4.46,4.46,0,0,0,.64.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.11,28.76a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.75,4.75,0,0,1-.56-.95,5,5,0,0,0,.43,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.39,28.38a4.28,4.28,0,0,1-.49-1,4.15,4.15,0,0,0,.36,1.06h0a.09.09,0,0,0,.11,0A.09.09,0,0,0,27.39,28.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.94,29h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.32,4.32,0,0,1-.48-1A4.45,4.45,0,0,0,27.94,29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,32.4h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.72,4.72,0,0,1-.59-.93A4.68,4.68,0,0,0,27,32.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.16,35.82a.07.07,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.11,4.11,0,0,1-.84-.72,4.61,4.61,0,0,0,.74.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.24,36.74a4.28,4.28,0,0,0,.8.79h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11A4.37,4.37,0,0,1,23.24,36.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.78,37.75h0a.09.09,0,0,0,.11,0s0-.06,0-.09a4.09,4.09,0,0,0,.66.72h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.45,4.45,0,0,1-.85-.71l.06.07a4.49,4.49,0,0,1-.83-.68A4.93,4.93,0,0,0,26.78,37.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.7,38.51s.09,0,.11,0a.07.07,0,0,0,0-.1,4.44,4.44,0,0,1-.72-.84,4.09,4.09,0,0,0,.61.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.79,38.44a.08.08,0,0,0,.11-.11,4.38,4.38,0,0,1-.67-.87,4.66,4.66,0,0,0,.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.56,38.24a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.43,4.43,0,0,1-.62-.91,4.64,4.64,0,0,0,.49,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.35,36.75a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.31,4.31,0,0,1-.54-1,4.42,4.42,0,0,0,.41,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.85,35h0A.07.07,0,0,0,34,35a4.56,4.56,0,0,1-.49-1A4.42,4.42,0,0,0,33.85,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.41,33.14h0a.08.08,0,1,0,.13-.09,4.08,4.08,0,0,1-.47-1A4.71,4.71,0,0,0,32.41,33.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.34,31.18h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.38,4.38,0,0,1-.45-1A4.59,4.59,0,0,0,32.34,31.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.17,28a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.49,4.49,0,0,1-.42-1A4.65,4.65,0,0,0,32.16,28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.72,26.23a.07.07,0,0,0,.13-.07,4.27,4.27,0,0,1-.41-1,4.62,4.62,0,0,0,.27,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.91,29.57h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.56,4.56,0,0,1-.42-1A4.54,4.54,0,0,0,32.91,29.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.17,30a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.59,4.59,0,0,1-.43-1,4.14,4.14,0,0,0,.3,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.55,33.4h0a.08.08,0,1,0,.13-.09,4.35,4.35,0,0,1-.49-1A4.47,4.47,0,0,0,31.55,33.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,35.63a4.28,4.28,0,0,1-.55-1,4.17,4.17,0,0,0,.43,1h0a4.46,4.46,0,0,0,.41.92h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.65,4.65,0,0,1-.52-.83s.05,0,.07,0A.07.07,0,0,0,32,35.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.08,37.19h0a.07.07,0,1,0,.11-.1,4.72,4.72,0,0,1-.65-.89A4,4,0,0,0,31.08,37.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,39.65a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.35,4.35,0,0,1-.91-.63,4.63,4.63,0,0,0,.83.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.38,38.68h0a.08.08,0,0,0,.08-.14,4.37,4.37,0,0,1-.89-.66A4.6,4.6,0,0,0,26.38,38.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.84,39.7a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4,4,0,0,1-.89-.65,4.22,4.22,0,0,0,.8.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.33,39.59h0a.08.08,0,0,0,.08-.13,4.4,4.4,0,0,1-.85-.7A4.21,4.21,0,0,0,28.33,39.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.86,37.65h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.08,4.08,0,0,1-.87-.68A4.27,4.27,0,0,0,25.86,37.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.73,34.2h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.71,4.71,0,0,1-.53-1A4.38,4.38,0,0,0,30.73,34.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.93,26.21h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.59,4.59,0,0,1-.43-1A4.62,4.62,0,0,0,30.93,26.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.33,20.79a.07.07,0,0,0,.13-.07,5.16,5.16,0,0,1-.38-1,5.28,5.28,0,0,0,.24,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.69,18.94a.07.07,0,0,0,0-.1,4.77,4.77,0,0,1-.37-1,4.69,4.69,0,0,0,.23,1.1h0A.07.07,0,0,0,34.69,18.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.19,16.69h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.72,4.72,0,0,1-.35-1A4.19,4.19,0,0,0,36.19,16.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.56,14.87a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.2,4.2,0,0,1-.32-1.06,4.59,4.59,0,0,0,.17,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.36,13.87a.08.08,0,1,0,.14-.06,4.85,4.85,0,0,1-.27-1.06,4.67,4.67,0,0,0,.13,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.85,16.15a.07.07,0,1,0,.14,0A4.44,4.44,0,0,1,43.81,15a4.2,4.2,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.56,28.93a4.54,4.54,0,0,1,.14-1.1,5,5,0,0,0-.3,1.09h0a.08.08,0,0,0,.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,33.58c0,.05,0,.08.07.09a.08.08,0,0,0,.08-.08,4.05,4.05,0,0,1,.21-1.08A4,4,0,0,0,50,33.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.32,37.08a.08.08,0,0,0,.16,0A4.46,4.46,0,0,1,52.73,36a4.77,4.77,0,0,0-.41,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.69,45.51a.06.06,0,0,0,.06.08.07.07,0,0,0,.09-.06,4,4,0,0,1,.3-1.06,4.53,4.53,0,0,0-.45,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.67,47a.08.08,0,0,0,.06.09.1.1,0,0,0,.09-.07,4,4,0,0,1,.3-1.06,4.73,4.73,0,0,0-.45,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.61,48.21a.06.06,0,0,0,.06.08.08.08,0,0,0,.09-.06,4.92,4.92,0,0,1,.3-1.06,4.65,4.65,0,0,0-.45,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.54,49.18h0a.08.08,0,1,0,.15,0A4.52,4.52,0,0,1,54,48.13,4.32,4.32,0,0,0,53.54,49.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.67,48.39a4.51,4.51,0,0,1,.22-1.08,4.3,4.3,0,0,0-.37,1.06h0a.07.07,0,0,0,.07.08A.07.07,0,0,0,52.67,48.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.12,45.42a.08.08,0,0,0,.15,0,4.53,4.53,0,0,1,.23-1.08,4.32,4.32,0,0,0-.38,1.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.39,41.45a.08.08,0,0,0,.08.08.08.08,0,0,0,.08-.07,4.49,4.49,0,0,1,.21-1.08,4.28,4.28,0,0,0-.36,1.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.17,39.26a.08.08,0,0,0,.07.08.08.08,0,0,0,.08-.08,4.45,4.45,0,0,1,.19-1.08,4.18,4.18,0,0,0-.34,1.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.59,37.83a.13.13,0,0,1,0-.06,4.42,4.42,0,0,0-.33,1.07h0a.08.08,0,0,0,.07.08.08.08,0,0,0,.08-.08c0-.17,0-.35,0-.52,0,0,0,.07.07.07a.08.08,0,0,0,.08-.07,5.07,5.07,0,0,1,.18-1.09A5.89,5.89,0,0,0,50.59,37.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.58,37.93a.07.07,0,0,0,.07.08.08.08,0,0,0,.08-.07A4.51,4.51,0,0,1,52,36.86a4.75,4.75,0,0,0-.37,1.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.12,38A.08.08,0,0,0,51.2,38a4.57,4.57,0,0,1,.21-1.09A5,5,0,0,0,51.05,38h0A.08.08,0,0,0,51.12,38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.23,38.6a.08.08,0,0,0,.15,0,4.45,4.45,0,0,1,.23-1.07,4.25,4.25,0,0,0-.38,1.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.93,39.88A.08.08,0,0,0,53,40a.07.07,0,0,0,.08-.07,4.49,4.49,0,0,1,.25-1.07,4.3,4.3,0,0,0-.4,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.91,41.83a.06.06,0,0,0,.06.08.07.07,0,0,0,.09-.06,4.18,4.18,0,0,1,.15-.72l0,0c0-.08,0-.15,0-.23l0-.09a.08.08,0,0,1,0,0,4.42,4.42,0,0,1,.22-.79,4.53,4.53,0,0,0-.42,1h0s0,.05,0,.06a4.23,4.23,0,0,0-.24.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.25,44.26a5.09,5.09,0,0,1,.3-1.06,4.73,4.73,0,0,0-.45,1h0a.08.08,0,0,0,.06.09A.09.09,0,0,0,55.25,44.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.91,46.21a.08.08,0,1,0,.15,0,4.25,4.25,0,0,1,.35-1,3.9,3.9,0,0,0-.5,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.42,48a.08.08,0,0,0,.06.09.07.07,0,0,0,.09,0,4.31,4.31,0,0,1,.4-1,4.45,4.45,0,0,0-.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.92,48.43a.08.08,0,0,0,0,.09.07.07,0,0,0,.1,0,4.57,4.57,0,0,1,.53-1,4.23,4.23,0,0,0-.66.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.22,48.3a4,4,0,0,0-.64.92h0a.08.08,0,0,0,.15.05A4.12,4.12,0,0,1,59.22,48.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.91,51.26a.08.08,0,0,0,0,.1.07.07,0,0,0,.1,0,5.06,5.06,0,0,1,.58-.94,4.58,4.58,0,0,0-.71.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,51.76a.07.07,0,0,0,0,.09.07.07,0,0,0,.1-.05,4.27,4.27,0,0,1,.47-1,4.21,4.21,0,0,0-.61.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.64,50.62h0a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0,4.32,4.32,0,0,1,.61-.92A4.25,4.25,0,0,0,59.64,50.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.42,50.51a.07.07,0,0,0,0,.1.09.09,0,0,0,.1,0,4.57,4.57,0,0,1,.53-1,4.58,4.58,0,0,0-.67.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.79,51.34a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0,4.49,4.49,0,0,1,.51-1,4.56,4.56,0,0,0-.65.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,50.58a.07.07,0,0,0,0,.09.09.09,0,0,0,.1-.05,4.09,4.09,0,0,1,.45-1,4,4,0,0,0-.6,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.58,50.74a.09.09,0,0,0,.07.09.06.06,0,0,0,.08-.06,4.75,4.75,0,0,1,.33-1.05,4,4,0,0,0-.47,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.87,51.22a4.59,4.59,0,0,1,.29-1.07,4.58,4.58,0,0,0-.44,1h0a.07.07,0,0,0,.07.08A.06.06,0,0,0,53.87,51.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.11,52.15a.09.09,0,0,0,.07.09.08.08,0,0,0,.08-.07,4.59,4.59,0,0,1,.29-1.07,4.51,4.51,0,0,0-.44,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.05,52a.08.08,0,0,0,.15,0,4.92,4.92,0,0,1,.33-1,4.47,4.47,0,0,0-.48,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.35,52.32a4.31,4.31,0,0,1,.4-1,4.48,4.48,0,0,0-.55,1h0a.07.07,0,0,0,0,.09A.08.08,0,0,0,55.35,52.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.92,50.58a.08.08,0,0,0,.09-.06,4.48,4.48,0,0,1,.4-1,4.48,4.48,0,0,0-.55,1v0l0,0a4.19,4.19,0,0,0-.49.91h0a.08.08,0,0,0,.06.09.07.07,0,0,0,.09-.06,4.6,4.6,0,0,1,.34-.94S55.9,50.57,55.92,50.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.31,49.87a4.39,4.39,0,0,1,.35-1,4.25,4.25,0,0,0-.5,1h0a.07.07,0,0,0,.06.09A.07.07,0,0,0,55.31,49.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,49a.08.08,0,1,0,.15,0,3.9,3.9,0,0,1,.39-1A4.45,4.45,0,0,0,56,49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.11,48.09h0a.08.08,0,0,0,.06.09.07.07,0,0,0,.09,0,4.34,4.34,0,0,1,.41-1A4.48,4.48,0,0,0,57.11,48.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.43,47.39a.09.09,0,0,0,.1,0,4.3,4.3,0,0,1,.46-1,4.24,4.24,0,0,0-.6.95h0A.07.07,0,0,0,58.43,47.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.53,45.61a4.76,4.76,0,0,1,.44-1,4.46,4.46,0,0,0-.58,1h0a.1.1,0,0,0,.06.1A.07.07,0,0,0,58.53,45.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.66,43.86a.07.07,0,1,0,.14,0,4.76,4.76,0,0,1,.44-1,4.83,4.83,0,0,0-.58,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.16,40.73a.08.08,0,0,0,.06.09.07.07,0,0,0,.09,0,4.31,4.31,0,0,1,.4-1,4.45,4.45,0,0,0-.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.22,38.22a.08.08,0,0,0,.15,0,4.49,4.49,0,0,1,.38-1,4.52,4.52,0,0,0-.52,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.08,35.72a.08.08,0,0,0,.15,0,4.47,4.47,0,0,1,.35-1,4.14,4.14,0,0,0-.5,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,34.07a.08.08,0,1,0,.15,0,4.25,4.25,0,0,1,.35-1,4.25,4.25,0,0,0-.5,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.37,32.7a.08.08,0,1,0,.15,0,4.72,4.72,0,0,1,.38-1,4.38,4.38,0,0,0-.52,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,31a.07.07,0,0,0,0,.09s.08,0,.1-.05a4.09,4.09,0,0,1,.45-1,4.38,4.38,0,0,0-.6,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,29.83a4.54,4.54,0,0,1,.54-1,4.28,4.28,0,0,0-.68.89h0a.08.08,0,1,0,.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.26,27.77a4.63,4.63,0,0,0-.72.86h0a.08.08,0,0,0,0,.1.07.07,0,0,0,.1,0A4.8,4.8,0,0,1,69.26,27.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.33,27.11a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0,5.43,5.43,0,0,1,.61-.92,4.31,4.31,0,0,0-.74.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.4,26.13a4.63,4.63,0,0,1,.63-.91,4.63,4.63,0,0,0-.76.83h0a.07.07,0,0,0,0,.1A.07.07,0,0,0,72.4,26.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.14,25.75a3.94,3.94,0,0,1,.64-.89,4.23,4.23,0,0,0-.77.81h0a.08.08,0,0,0,0,.11A.08.08,0,0,0,74.14,25.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.23,24.93h0a.08.08,0,0,0,0,.1.08.08,0,0,0,.11,0,4.27,4.27,0,0,1,.64-.89A4.23,4.23,0,0,0,75.23,24.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.69,24.11a4.29,4.29,0,0,1,.63-.9,4.25,4.25,0,0,0-.76.82h0a.08.08,0,0,0,.14.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.25,24a.07.07,0,0,0,0,.1.08.08,0,0,0,.11,0,5,5,0,0,1,.62-.91,4.24,4.24,0,0,0-.75.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.32,24.24a4.63,4.63,0,0,1,.59-.94,4.57,4.57,0,0,0-.72.86h0a.09.09,0,0,0,0,.11S71.31,24.28,71.32,24.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.41,24a4.51,4.51,0,0,0-.67.89h0a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0A4.1,4.1,0,0,1,68.41,24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.52,24.36a4.61,4.61,0,0,0-.63.93h0a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0A4.56,4.56,0,0,1,65.52,24.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.9,25.38a.08.08,0,0,0,.15.05,4.34,4.34,0,0,1,.47-1,4.93,4.93,0,0,0-.62.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.46,23.65h0a.08.08,0,0,0,0,.1.07.07,0,0,0,.1,0,4.19,4.19,0,0,1,.5-1A4.46,4.46,0,0,0,66.46,23.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.86,21.73a4.17,4.17,0,0,1,.53-1,4.58,4.58,0,0,0-.67.9h0a.08.08,0,1,0,.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.59,20.58a4.59,4.59,0,0,1,.55-1,4.7,4.7,0,0,0-.69.89h0a.07.07,0,0,0,0,.1S72.58,20.62,72.59,20.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.35,19.83h0a.08.08,0,0,0,0,.1.08.08,0,0,0,.1,0A4.75,4.75,0,0,1,75,19,4.26,4.26,0,0,0,74.35,19.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.89,19.43h0a.07.07,0,0,0,0,.1.09.09,0,0,0,.1,0,4.61,4.61,0,0,1,.54-1A4.63,4.63,0,0,0,72.89,19.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.35,21.55a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0,4.77,4.77,0,0,1,.5-1,4.88,4.88,0,0,0-.64.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.16,25a.07.07,0,0,0,.05.09.08.08,0,0,0,.1-.05,4.79,4.79,0,0,1,.45-1,3.94,3.94,0,0,0-.59,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.7,24.85a4.26,4.26,0,0,0-.57,1h0a.07.07,0,0,0,0,.09.09.09,0,0,0,.1-.05A3.92,3.92,0,0,1,60.7,24.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.25,25.07a.09.09,0,0,0,0,.1.08.08,0,0,0,.1-.06,5,5,0,0,1,.42-1,4.94,4.94,0,0,0-.57,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.84,21.46a.07.07,0,0,0,0,.09.08.08,0,0,0,.1-.05,4.33,4.33,0,0,1,.45-1,4.31,4.31,0,0,0-.6,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.32,18.43a5,5,0,0,1,.45-1,4.87,4.87,0,0,0-.59.95h0a.09.09,0,0,0,0,.1A.08.08,0,0,0,66.32,18.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.52,16a.07.07,0,0,0,0,.09.07.07,0,0,0,.09-.05,4.79,4.79,0,0,1,.45-1,4.66,4.66,0,0,0-.59,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,15.33a.07.07,0,0,0,0,.1.07.07,0,0,0,.09,0,4.37,4.37,0,0,1,.46-1,4.48,4.48,0,0,0-.6,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.42,15.94a4.62,4.62,0,0,1,.44-1,4.46,4.46,0,0,0-.58,1h0a.08.08,0,0,0,0,.1A.07.07,0,0,0,65.42,15.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.84,16.94a4.45,4.45,0,0,0-.54,1h0a.07.07,0,0,0,.05.09.08.08,0,0,0,.1-.05A3.77,3.77,0,0,1,60.84,16.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.36,23.08A4.39,4.39,0,0,1,53.65,22a4.7,4.7,0,0,0-.44,1h0a.08.08,0,1,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.13,22.26a.09.09,0,0,0,.07.09.08.08,0,0,0,.08-.07,4.44,4.44,0,0,1,.31-1.06,4.76,4.76,0,0,0-.46,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.44,17.68a4.39,4.39,0,0,1,.32-1.05,4.22,4.22,0,0,0-.47,1v0a.08.08,0,0,0,.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,13.12a4.44,4.44,0,0,1,.31-1.06,4.69,4.69,0,0,0-.46,1h0a.09.09,0,0,0,.07.09A.08.08,0,0,0,60,13.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.39,11A4.47,4.47,0,0,1,60.68,10a4.26,4.26,0,0,0-.44,1h0a.07.07,0,0,0,.06.09A.08.08,0,0,0,60.39,11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.66,11.77a.08.08,0,0,0,.16,0,4.39,4.39,0,0,1,.32-1,4,4,0,0,0-.47,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.77,13.2A4.06,4.06,0,0,1,58,12.13a4.3,4.3,0,0,0-.4,1h0a.08.08,0,0,0,.07.08A.08.08,0,0,0,57.77,13.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.51,17.06A4.43,4.43,0,0,1,53.68,16,4.4,4.4,0,0,0,53.36,17h0a.08.08,0,1,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.24,18.83a.08.08,0,1,0,.15,0,4.47,4.47,0,0,1,.1-1.09,4.33,4.33,0,0,0-.25,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.63,17.56a.08.08,0,0,0,.07-.08,4.06,4.06,0,0,1,0-1.1,4.53,4.53,0,0,0-.19,1.1h0C49.55,17.54,49.58,17.57,49.63,17.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.87,15.06a.07.07,0,0,0,.06-.09,4.06,4.06,0,0,1,0-1.1A4.15,4.15,0,0,0,51.78,15h0A.09.09,0,0,0,51.87,15.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.74,9.46a.08.08,0,0,0,.06-.09,4.05,4.05,0,0,1-.07-1.1,4.17,4.17,0,0,0-.08,1.12h0A.08.08,0,0,0,52.74,9.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.63,7.65a.07.07,0,0,0,.06-.09,4.5,4.5,0,0,1-.08-1.1,4.66,4.66,0,0,0-.07,1.12h0A.08.08,0,0,0,53.63,7.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.81,7.23a.08.08,0,1,0,.15,0,4.52,4.52,0,0,1-.11-1.1,4.19,4.19,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.47,8.94a.08.08,0,0,0,.1.05.07.07,0,0,0,0-.09,4.47,4.47,0,0,1-.12-1.1,4.2,4.2,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.91,12.36a.08.08,0,1,0,.15,0A4.39,4.39,0,0,1,50,11.23a4.63,4.63,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.92,13A.07.07,0,0,0,48,13a.08.08,0,0,0,.06-.09,4.06,4.06,0,0,1-.15-1.1,4.2,4.2,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.26,12a4.48,4.48,0,0,1-.14-1.1,4.66,4.66,0,0,0,0,1.12v0a.08.08,0,0,0,.1,0A.07.07,0,0,0,47.26,12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.08,11.82a.07.07,0,0,0,.09,0,.08.08,0,0,0,.06-.09,4.06,4.06,0,0,1-.15-1.1,4.27,4.27,0,0,0,0,1.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.78,7.42a.08.08,0,0,0,.09.06.08.08,0,0,0,.06-.1,4.94,4.94,0,0,1-.15-1.09,4.2,4.2,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.74,5.45h0a.08.08,0,0,0,.09.06.08.08,0,0,0,.06-.1,4.94,4.94,0,0,1-.15-1.09A4.66,4.66,0,0,0,45.74,5.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.92,6.3a.08.08,0,0,0,.1.06.07.07,0,0,0,0-.09,5.11,5.11,0,0,1-.15-1.1,4.66,4.66,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.43,8.51a4.35,4.35,0,0,1-.16-1.09,4.66,4.66,0,0,0,0,1.12v0a.07.07,0,0,0,.09,0A.07.07,0,0,0,44.43,8.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.14,10.74h0a.07.07,0,0,0,.09.05.08.08,0,0,0,.06-.1,3.94,3.94,0,0,1-.18-1.08A4.22,4.22,0,0,0,44.14,10.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.94,10.47s0,0,.07,0a5.11,5.11,0,0,0,.05.78h0a.07.07,0,0,0,.14,0,6.57,6.57,0,0,1-.16-.77s0,0,0-.06a4.46,4.46,0,0,1-.2-1.09,4.21,4.21,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.54,10a.08.08,0,0,0,.15,0,4.38,4.38,0,0,1-.2-1.09,4.76,4.76,0,0,0,0,1.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.47,10.72a.08.08,0,0,0,.15-.05,3.91,3.91,0,0,1-.22-1.08,4.22,4.22,0,0,0,.07,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.52,9.42a.06.06,0,0,0,.09,0,.08.08,0,0,0,0-.1,4.28,4.28,0,0,1-.25-1.07,4.72,4.72,0,0,0,.1,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.2,9.5a.08.08,0,0,0,.14-.06,4.85,4.85,0,0,1-.27-1.06,5.24,5.24,0,0,0,.12,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.53,8.83a.08.08,0,0,0,.1.05.07.07,0,0,0,0-.1,4.29,4.29,0,0,1-.26-1.07,4.65,4.65,0,0,0,.11,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.54,12v0a.09.09,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.31,4.31,0,0,1-.27-1.07A4.15,4.15,0,0,0,38.54,12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.18,12.52a.08.08,0,0,0,.1.05.07.07,0,0,0,0-.1,4.8,4.8,0,0,1-.27-1.07,4.67,4.67,0,0,0,.13,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.86,13.2a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.25,4.25,0,0,1-.28-1.06,4.21,4.21,0,0,0,.13,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.9,13.83a4.39,4.39,0,0,1-.29-1.06,4.69,4.69,0,0,0,.14,1.11h0a.07.07,0,0,0,.1,0A.07.07,0,0,0,38.9,13.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.14,13.31a.08.08,0,1,0,.14-.06A4.81,4.81,0,0,1,38,12.18a4.25,4.25,0,0,0,.14,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.81,11.73a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.62,4.62,0,0,1-.3-1.06,4.65,4.65,0,0,0,.16,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,10a.08.08,0,1,0,.14-.06,4.31,4.31,0,0,1-.31-1.06A4.21,4.21,0,0,0,35,10Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.74,9.84a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.3,4.3,0,0,1-.34-1,4.61,4.61,0,0,0,.19,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.76,10.8h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.61,4.61,0,0,1-.34-1.05A4.71,4.71,0,0,0,33.76,10.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.66,13.24a.08.08,0,1,0,.14-.06,4.77,4.77,0,0,1-.34-1,4.63,4.63,0,0,0,.2,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.4,15.42a4.16,4.16,0,0,1-.34-1.05,4.16,4.16,0,0,0,.19,1.1h0a4.41,4.41,0,0,0,.16.72h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,5.07,5.07,0,0,1-.24-.65s0,0,0,0A.07.07,0,0,0,35.4,15.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.11,15.87s.06.05.1,0a.09.09,0,0,0,0-.11,4.25,4.25,0,0,1-.35-1,4.63,4.63,0,0,0,.2,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.49,13.93a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.3,4.3,0,0,1-.37-1,4.67,4.67,0,0,0,.22,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.5,10.82a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.56,4.56,0,0,1-.39-1,4.54,4.54,0,0,0,.25,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.13,9.54a.08.08,0,1,0,.13-.08,4.51,4.51,0,0,1-.4-1,4.67,4.67,0,0,0,.26,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.31,9.72a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,3.92,3.92,0,0,1-.42-1,4.09,4.09,0,0,0,.28,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.69,11.13h0a.08.08,0,0,0,.14-.07,4.59,4.59,0,0,1-.43-1A4.54,4.54,0,0,0,27.69,11.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.11,13.79a.08.08,0,0,0,.14-.08,4.54,4.54,0,0,1-.41-1,5.27,5.27,0,0,0,.27,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.55,16.43a.07.07,0,1,0,.13-.07,4.75,4.75,0,0,1-.39-1,4.54,4.54,0,0,0,.25,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.61,17.3a.07.07,0,0,0,.13-.07,5.16,5.16,0,0,1-.38-1,5.28,5.28,0,0,0,.24,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.93,17.18a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.13,4.13,0,0,1-.39-1,4.11,4.11,0,0,0,.25,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.94,15.08a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.56,4.56,0,0,1-.42-1,4.73,4.73,0,0,0,.28,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.17,13.69a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.31,4.31,0,0,1-.54-1,4.42,4.42,0,0,0,.41,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.43,12.92a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.1,4.1,0,0,1-.63-.91,5.19,5.19,0,0,0,.5,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.37,13.75a.07.07,0,0,0,.1,0,.06.06,0,0,0,0-.1,4.51,4.51,0,0,1-.64-.91,4.33,4.33,0,0,0,.52,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.53,17.48h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.38,4.38,0,0,1-.54-1A4.49,4.49,0,0,0,25.53,17.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.33,21.36a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.36,4.36,0,0,1-.47-1,5.18,5.18,0,0,0,.33,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.12,23.88h0a.09.09,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.58,4.58,0,0,1-.45-1A4.59,4.59,0,0,0,28.12,23.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.18,20.55h0a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.57,4.57,0,0,1-.51-1A4.37,4.37,0,0,0,26.18,20.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.18,17a.08.08,0,0,0,.11-.11,4.79,4.79,0,0,1-.67-.87,4.66,4.66,0,0,0,.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.92,15.54h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.11,4.37,4.37,0,0,1-.78-.77A4.88,4.88,0,0,0,20.92,15.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.8,16.49a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.12,4.12,0,0,1-.8-.76,4.45,4.45,0,0,0,.7.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.87,18.69a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.43,4.43,0,0,1-.75-.81,4.46,4.46,0,0,0,.64.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.76,21.66a.08.08,0,0,0,.11-.11,4.8,4.8,0,0,1-.7-.85,4.77,4.77,0,0,0,.59,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.56,24.08h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.09,4.09,0,0,1-.64-.9A4.26,4.26,0,0,0,23.56,24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.83,24.77h0a.08.08,0,0,0,.12-.1,4.43,4.43,0,0,1-.62-.91A4.25,4.25,0,0,0,23.83,24.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.85,23.41a.07.07,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.43,4.43,0,0,1-.74-.82,4.41,4.41,0,0,0,.63.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.38,21.81h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.75,4.75,0,0,1,17.6,21,4.54,4.54,0,0,0,18.38,21.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,20.86h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.62,4.62,0,0,1-.9-.64A4.28,4.28,0,0,0,17,20.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.21,21.09h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.56,4.56,0,0,1-.9-.63A4.93,4.93,0,0,0,16.21,21.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.58,22.36h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.6,4.6,0,0,1-.88-.66A4.6,4.6,0,0,0,16.58,22.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.77,25.17a4.17,4.17,0,0,0,.71.87h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.11A4.37,4.37,0,0,1,17.77,25.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,27.73h0a.08.08,0,0,0,.09-.13,4.37,4.37,0,0,1-.78-.77A4.88,4.88,0,0,0,19,27.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.61,27.77a4.73,4.73,0,0,0,.66.9h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11A4.06,4.06,0,0,1,18.61,27.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.83,28a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.38,4.38,0,0,1-.76-.79,4.17,4.17,0,0,0,.66.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.56,26.42a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.38,4.38,0,0,1-.82-.73,4.16,4.16,0,0,0,.72.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.62,24.63a4.14,4.14,0,0,1-.63-.55,4.54,4.54,0,0,0,.75.83h0a.11.11,0,0,0,.08,0,4,4,0,0,0,.46.46h0a.08.08,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.34,4.34,0,0,1-.54-.42s0,0,0,0l-.12-.09-.18-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.35,26.1h0a.07.07,0,1,0,.08-.12,4.77,4.77,0,0,1-.83-.73A4.26,4.26,0,0,0,14.35,26.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.31,28.84a.09.09,0,0,0,0-.11,4.78,4.78,0,0,1-.8-.76,4.92,4.92,0,0,0,.7.88h0A.08.08,0,0,0,15.31,28.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.6,31.68a.08.08,0,1,0,.09-.13,4.37,4.37,0,0,1-.78-.77,4.47,4.47,0,0,0,.68.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.41,33s0,0,.05,0a.08.08,0,0,0,0-.11,4.37,4.37,0,0,1-.79-.76,4.46,4.46,0,0,0,.69.88h0a4.43,4.43,0,0,0,.55.68h0a.08.08,0,1,0,.09-.12A4.6,4.6,0,0,1,15.41,33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.83,33.36a.06.06,0,0,0,0-.09A4.43,4.43,0,0,1,14,32.5a4.46,4.46,0,0,0,.69.88h0a.07.07,0,0,0,.06,0,4.76,4.76,0,0,0,.5.61h0a.08.08,0,1,0,.09-.12A4.45,4.45,0,0,1,14.83,33.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.63,31.79a4.77,4.77,0,0,0,.69.88h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11A4.37,4.37,0,0,1,15.63,31.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.64,31.8a.08.08,0,0,0,.1-.12,4.43,4.43,0,0,1-.79-.77,4.19,4.19,0,0,0,.69.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.94,31.06h0a.08.08,0,0,0,.11,0A.08.08,0,0,0,17,31a4.12,4.12,0,0,1-.78-.78A4.12,4.12,0,0,0,16.94,31.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.69,31.19a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.8,4.8,0,0,1-.78-.78,4.42,4.42,0,0,0,.67.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.59,99.09h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.11,0,4.75,4.75,0,0,1,.81-.74A4.46,4.46,0,0,0,49.59,99.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.47,97.6h0a.07.07,0,0,0,0,.11.07.07,0,0,0,.1,0,4.84,4.84,0,0,1,.84-.72A4.68,4.68,0,0,0,54.47,97.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.63,96.74a.09.09,0,0,0,0,.11.08.08,0,0,0,.11,0,4.43,4.43,0,0,1,.83-.72,4.73,4.73,0,0,0-.94.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.38,88.65h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.11,0,4.43,4.43,0,0,1,.83-.72A4.46,4.46,0,0,0,54.38,88.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.21,86.09h0a.08.08,0,1,0,.11.11,4.18,4.18,0,0,1,.86-.7A4.72,4.72,0,0,0,52.21,86.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.48,83.58a4.8,4.8,0,0,1,.85-.7,4.44,4.44,0,0,0-.95.59h0a.08.08,0,0,0,0,.11A.07.07,0,0,0,53.48,83.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,84a4.09,4.09,0,0,1,.86-.68,4.06,4.06,0,0,0-1,.57h0a.07.07,0,0,0,0,.1A.08.08,0,0,0,52,84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.17,95.42a4.39,4.39,0,0,1,.84-.71,4.68,4.68,0,0,0-.94.6h0a.07.07,0,0,0,0,.11A.07.07,0,0,0,49.17,95.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.29,100.44a.08.08,0,0,0,0,.11.09.09,0,0,0,.11,0,4.84,4.84,0,0,1,.84-.72,4.21,4.21,0,0,0-.94.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,96a4.14,4.14,0,0,1,.86-.69,4.74,4.74,0,0,0-1,.57h0A.08.08,0,1,0,53,96Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.42,93.29h0a.09.09,0,0,0,0,.11.08.08,0,0,0,.11,0,4.47,4.47,0,0,1,.85-.7A4.77,4.77,0,0,0,53.42,93.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.65,88.1a.08.08,0,0,0,.12.11,4.14,4.14,0,0,1,.83-.73,4.14,4.14,0,0,0-.94.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.35,81.31h0a.09.09,0,0,0,0,.11.08.08,0,0,0,.11,0,4.4,4.4,0,0,1,.85-.7A4.72,4.72,0,0,0,52.35,81.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.06,78.64h0a.08.08,0,1,0,.1.12,4.45,4.45,0,0,1,.93-.59A4.29,4.29,0,0,0,53.06,78.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.81,75.59a4.42,4.42,0,0,0-1,.41h0a.07.07,0,0,0,0,.1.08.08,0,0,0,.11,0A4.31,4.31,0,0,1,54.81,75.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.39,72.9a.08.08,0,0,0,0,.11.08.08,0,0,0,.11,0,4.1,4.1,0,0,1,1-.54,4.05,4.05,0,0,0-1,.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,69.64h0s0,.07,0,.11a.08.08,0,0,0,.1,0,4.33,4.33,0,0,1,.94-.58A4.4,4.4,0,0,0,55,69.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.05,66.65h0a.07.07,0,0,0,0,.1.06.06,0,0,0,.1,0,4.72,4.72,0,0,1,.89-.65A4.23,4.23,0,0,0,55.05,66.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.22,64h0a.08.08,0,1,0,.12.1,4.13,4.13,0,0,1,.82-.74A4.23,4.23,0,0,0,55.22,64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.71,61.51h0a.07.07,0,0,0,0,.11.09.09,0,0,0,.11,0,4.34,4.34,0,0,1,.72-.83A4.9,4.9,0,0,0,55.71,61.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.46,59.62a.08.08,0,0,0,0,.1.08.08,0,0,0,.11,0,4.62,4.62,0,0,1,.64-.9,4.28,4.28,0,0,0-.77.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.76,57.8a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0,4.61,4.61,0,0,1,.54-1,4.22,4.22,0,0,0-.67.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.69,57.81h0a.08.08,0,0,0,0,.1.08.08,0,0,0,.11,0,4.84,4.84,0,0,1,.6-.92A4.66,4.66,0,0,0,55.69,57.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.21,102.45h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.24,4.24,0,0,1-.86-.67A4.53,4.53,0,0,0,34.21,102.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.23,99.67a4.22,4.22,0,0,0,.8.78h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.05,4.05,0,0,1,31.23,99.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.35,95.67a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.6,4.6,0,0,1-.88-.66,4.6,4.6,0,0,0,.8.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.84,89.9a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.1,4.1,0,0,1-.81-.75,4.58,4.58,0,0,0,.71.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.1,86.4a4.17,4.17,0,0,1-.71-.85,4.81,4.81,0,0,0,.6,1h0a.08.08,0,1,0,.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.33,82.92a.07.07,0,1,0,.11-.1,4.67,4.67,0,0,1-.64-.89,4.23,4.23,0,0,0,.53,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.82,77.29a.08.08,0,1,0,.09-.13,4.1,4.1,0,0,1-.82-.74,4,4,0,0,0,.72.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.76,72.87h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.29,4.29,0,0,1-.94-.57A4.18,4.18,0,0,0,23.76,72.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.37,69.92a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.53,4.53,0,0,1-1-.48,4.57,4.57,0,0,0,.93.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.69,71.6a.08.08,0,1,0,0-.15,4.09,4.09,0,0,1-1-.51,4.56,4.56,0,0,0,.91.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.44,79.53a.07.07,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.46,4.46,0,0,1-.74-.82,4.48,4.48,0,0,0,.63.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.73,83.8a.07.07,0,1,0,.11-.1,4.72,4.72,0,0,1-.65-.89,4.51,4.51,0,0,0,.53,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.56,88.12a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.13,4.13,0,0,1-.74-.82,4.48,4.48,0,0,0,.63.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.78,96.6a4.51,4.51,0,0,0,.7.88h0a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11A4.12,4.12,0,0,1,35.78,96.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.53,98.74a.08.08,0,1,0,.11-.11,4.09,4.09,0,0,1-.68-.86,4.33,4.33,0,0,0,.57,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.14,99.52a4.34,4.34,0,0,1-.53-1,4.46,4.46,0,0,0,.4,1h0a.08.08,0,0,0,.11,0A.07.07,0,0,0,40.14,99.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.72,97.81a.08.08,0,0,0,0-.1,4.38,4.38,0,0,1-.52-1,4.1,4.1,0,0,0,.39,1.06h0A.08.08,0,0,0,39.72,97.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.44,93.56h0a.08.08,0,0,0,.12-.1,4.28,4.28,0,0,1-.6-.92A4.06,4.06,0,0,0,37.44,93.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.74,90.33a.08.08,0,1,0,.11-.11,4.4,4.4,0,0,1-.63-.9,4.71,4.71,0,0,0,.51,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.26,86.84a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.09,4.09,0,0,1-.61-.92,4.11,4.11,0,0,0,.49,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.75,75.29h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.1,4.1,0,0,1-.91-.63A4.29,4.29,0,0,0,26.75,75.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.26,68.39a.08.08,0,0,0,.1-.06.08.08,0,0,0-.06-.09,5,5,0,0,1-1-.39,4.45,4.45,0,0,0,1,.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.4,63.52a.08.08,0,0,0,.09-.06.07.07,0,0,0-.06-.09,5.13,5.13,0,0,1-1-.36,4.71,4.71,0,0,0,1,.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.12,62.46h0a.08.08,0,0,0,.1-.05.09.09,0,0,0-.06-.1,4.25,4.25,0,0,1-1-.38A4.86,4.86,0,0,0,22.12,62.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.9,63.54a.08.08,0,0,0,0-.15,4.75,4.75,0,0,1-1-.39,4.45,4.45,0,0,0,1,.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.74,64.55a.07.07,0,0,0,.09-.06.07.07,0,0,0,0-.09,3.77,3.77,0,0,1-1-.39,4.45,4.45,0,0,0,1,.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.93,71.42h0a.08.08,0,0,0,.1,0,.1.1,0,0,0,0-.1,4.59,4.59,0,0,1-1-.55A4.7,4.7,0,0,0,21.93,71.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.71,79.36a4.77,4.77,0,0,0,.59,1h0a.08.08,0,0,0,.11-.11A4.8,4.8,0,0,1,21.71,79.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.47,85.42a.08.08,0,1,0,.11-.11,4.79,4.79,0,0,1-.67-.87,4.29,4.29,0,0,0,.56,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.35,79.12h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.07,4.07,0,0,1-.75-.8A3.88,3.88,0,0,0,27.35,79.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.15,70.11a.08.08,0,0,0,0-.15,4.3,4.3,0,0,1-1-.44,4.23,4.23,0,0,0,1,.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.68,65.59a.09.09,0,0,0,.1-.06.08.08,0,0,0-.06-.09,4.33,4.33,0,0,1-1-.38,5.23,5.23,0,0,0,1,.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,62.41a.08.08,0,0,0,.09-.06.07.07,0,0,0-.05-.09,4.8,4.8,0,0,1-1-.38,4.45,4.45,0,0,0,1,.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.24,60.4h0a.08.08,0,0,0,.09-.06.07.07,0,0,0-.05-.09,4.73,4.73,0,0,1-1-.41A4.2,4.2,0,0,0,22.24,60.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.22,55.24a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.1,4.6,4.6,0,0,1-1-.48,4.64,4.64,0,0,0,.94.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.24,47.69a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.51,4.51,0,0,1-.93-.58,4.63,4.63,0,0,0,.86.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,42.57a4.59,4.59,0,0,0,.79.8h0a.07.07,0,1,0,.07-.13A4.69,4.69,0,0,1,17,42.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.21,42h0a.06.06,0,0,0,.1,0,.09.09,0,0,0,0-.11,4.43,4.43,0,0,1-.87-.68A4.27,4.27,0,0,0,18.21,42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.76,39.71h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.31,4.31,0,0,1-.88-.66A4.6,4.6,0,0,0,21.76,39.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.1,53.21a.08.08,0,0,0,0-.15,4.08,4.08,0,0,1-1-.47,4.15,4.15,0,0,0,.93.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.61,53.72a.09.09,0,0,0,.1,0,.07.07,0,0,0-.05-.09,4.6,4.6,0,0,1-1-.48,4.25,4.25,0,0,0,.94.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.1,53.18a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.1,4.37,4.37,0,0,1-1-.48,4.64,4.64,0,0,0,.94.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.56,52.42a.08.08,0,1,0,.05-.15,4.09,4.09,0,0,1-1-.49,4,4,0,0,0,.92.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,51a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.17,4.17,0,0,1-1-.53A4.58,4.58,0,0,0,20,51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.36,49.64a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.12,4.12,0,0,1-1-.55,4.19,4.19,0,0,0,.88.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.15,48.53s.09,0,.1,0a.07.07,0,0,0,0-.1,4.54,4.54,0,0,1-.94-.57,4.53,4.53,0,0,0,.87.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.79,48.13h0a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.2,4.2,0,0,1-.95-.57A4.35,4.35,0,0,0,21.79,48.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.68,50.14a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.31,4.31,0,0,1-1-.54,4.28,4.28,0,0,0,.89.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.73,53.15s.08,0,.1,0a.07.07,0,0,0,0-.09,4.6,4.6,0,0,1-1-.5,4.65,4.65,0,0,0,.93.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.63,56.44h0a.07.07,0,0,0,.09,0,.09.09,0,0,0-.05-.1,4.3,4.3,0,0,1-1-.44A4.66,4.66,0,0,0,21.63,56.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.28,58.46a.08.08,0,0,0,.1-.06.08.08,0,0,0-.06-.09,4.45,4.45,0,0,1-1-.41,4.66,4.66,0,0,0,1,.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.12,58.68a.09.09,0,0,0,.1-.06.08.08,0,0,0-.06-.09,5,5,0,0,1-1-.42,4.08,4.08,0,0,0,1,.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.72,56.93h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.09,4.79,4.79,0,0,1-1-.45A4.87,4.87,0,0,0,22.72,56.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.73,53.81a.08.08,0,0,0,.1-.05.09.09,0,0,0,0-.1,4.35,4.35,0,0,1-1-.49,4.23,4.23,0,0,0,.93.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.22,51.44a.1.1,0,0,0,0-.1,4.19,4.19,0,0,1-1-.52,4.23,4.23,0,0,0,.91.66h0A.07.07,0,0,0,24.22,51.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.43,48.85s.09,0,.1,0a.07.07,0,0,0,0-.1,4.52,4.52,0,0,1-.95-.55,4.49,4.49,0,0,0,.88.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.74,46.42a4.59,4.59,0,0,0,.88.7h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11A4.16,4.16,0,0,1,23.74,46.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.12,46.47h0a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4,4,0,0,1-1-.56A4.67,4.67,0,0,0,25.12,46.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.36,43.83h0a.08.08,0,0,0,.1,0,.09.09,0,0,0,0-.11,4,4,0,0,1-.93-.59A5,5,0,0,0,26.36,43.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.63,39.74a3.92,3.92,0,0,0,.74.84h0a.08.08,0,0,0,.1,0,.07.07,0,0,0,0-.11A4.34,4.34,0,0,1,27.63,39.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.36,36.9h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.11,4.48,4.48,0,0,1-.73-.83A4.54,4.54,0,0,0,29.36,36.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.7,32.51h0s.07,0,.11,0a.09.09,0,0,0,0-.11,4.36,4.36,0,0,1-.57-.95A4.26,4.26,0,0,0,30.7,32.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.64,30.77a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.53,4.53,0,0,1-.52-1,4.6,4.6,0,0,0,.39,1.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.8,24.71s.07.06.1,0a.07.07,0,0,0,0-.1,4.59,4.59,0,0,1-.4-1,4.56,4.56,0,0,0,.26,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.13,23.82h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.74,4.74,0,0,1-.36-1A4.21,4.21,0,0,0,39.13,23.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.66,24.11a4.23,4.23,0,0,1-.31-1.05,4.27,4.27,0,0,0,.17,1.11h0a4.17,4.17,0,0,0,.13.85h0a.08.08,0,1,0,.14-.06,5.14,5.14,0,0,1-.23-.79s0,0,.06,0A.07.07,0,0,0,41.66,24.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.3,26.19a4.25,4.25,0,0,1-.23-1.07,4.23,4.23,0,0,0,.08,1.12h0a.07.07,0,0,0,.1,0A.08.08,0,0,0,43.3,26.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.81,28.31a4.94,4.94,0,0,1-.12-1.1,4.74,4.74,0,0,0,0,1.13h0a.08.08,0,0,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46,29.62h0a.08.08,0,0,0,.09.07.08.08,0,0,0,.06-.09,5.06,5.06,0,0,1,0-1.1A4.57,4.57,0,0,0,46,29.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.37,30.37a4.06,4.06,0,0,1,.09-1.1,4.54,4.54,0,0,0-.25,1.09h0a.08.08,0,0,0,.08.08C47.34,30.44,47.37,30.41,47.37,30.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.57,30.8h0c0,.05,0,.08.08.08a.08.08,0,0,0,.08-.08,4.57,4.57,0,0,1,.11-1.1A5.11,5.11,0,0,0,47.57,30.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.27,31.41h0a.07.07,0,0,0,.08.08.08.08,0,0,0,.07-.08,4.51,4.51,0,0,1,.1-1.1A4.54,4.54,0,0,0,47.27,31.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,31.35a4.27,4.27,0,0,1,.06-.79s0,.06.06.06a.08.08,0,0,0,.08-.08,4.51,4.51,0,0,1,.13-1.09,4.3,4.3,0,0,0-.26.95,4.45,4.45,0,0,0-.23.94h0a.08.08,0,0,0,.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.76,33.91s0,.07.08.08a.09.09,0,0,0,.08-.08,4.58,4.58,0,0,1,.17-1.09,4.4,4.4,0,0,0-.32,1.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.5,35.28a4.75,4.75,0,0,0-.37,1.06h0a.08.08,0,0,0,.15,0A4.41,4.41,0,0,1,50.5,35.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.92,40.67a.06.06,0,0,0,.06.08.09.09,0,0,0,.09-.07,4.11,4.11,0,0,1,.24-1.08,4.43,4.43,0,0,0-.39,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,42.9h0a.1.1,0,0,0,.07.09.08.08,0,0,0,.08-.07,5.09,5.09,0,0,1,.27-1.07A4.53,4.53,0,0,0,53,42.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.41,43.74a.07.07,0,0,0,.07.08.06.06,0,0,0,.08-.06,4.33,4.33,0,0,1,.28-1.07,4.55,4.55,0,0,0-.43,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.68,44.05c0,.05,0,.08.07.09a.08.08,0,0,0,.08-.07A4.49,4.49,0,0,1,53.08,43a4.67,4.67,0,0,0-.4,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.08,43.28a.08.08,0,0,0,.07.08.09.09,0,0,0,.09-.07,4.11,4.11,0,0,1,.24-1.08,4,4,0,0,0-.39,1.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.3,42.73a4.49,4.49,0,0,1,.21-1.08,4.28,4.28,0,0,0-.36,1.06h0a.08.08,0,0,0,.08.08S51.29,42.77,51.3,42.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.17,41.1A4.58,4.58,0,0,1,49.34,40,4.48,4.48,0,0,0,49,41.09h0a.07.07,0,0,0,.07.08A.08.08,0,0,0,49.17,41.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.27,40.18a4.94,4.94,0,0,1,.15-1.09,4.79,4.79,0,0,0-.3,1.08h0a.07.07,0,0,0,.08.07A.07.07,0,0,0,48.27,40.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,40a4.5,4.5,0,0,1,.17-1.09,4.4,4.4,0,0,0-.32,1.07v0a.07.07,0,0,0,.07.07A.07.07,0,0,0,49,40Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.3,41.29h0a.08.08,0,0,0,.08.08s0,0,0-.05a4.41,4.41,0,0,0-.2.71h0a.08.08,0,0,0,.07.09.09.09,0,0,0,.08-.08A4.45,4.45,0,0,1,50.57,41c0,.09-.07.18-.11.28a4.47,4.47,0,0,1,.2-1A4.73,4.73,0,0,0,50.3,41.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,44.43h0a.07.07,0,0,0,.07.08.08.08,0,0,0,.08-.07,4.4,4.4,0,0,1,.21-1.08A4.73,4.73,0,0,0,51,44.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.54,48a.08.08,0,0,0,.06.09.09.09,0,0,0,.09-.07A4.39,4.39,0,0,1,54,47a4.26,4.26,0,0,0-.44,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.26,52.88a.08.08,0,0,0,.06.09.07.07,0,0,0,.09,0,4.31,4.31,0,0,1,.4-1,4.94,4.94,0,0,0-.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.6,55.65a.08.08,0,0,0,0,.1.07.07,0,0,0,.1,0,3.94,3.94,0,0,1,.5-1,4.15,4.15,0,0,0-.64.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.65,57.47a5,5,0,0,1,.59-.93,4.27,4.27,0,0,0-.72.86h0a.08.08,0,0,0,0,.11A.08.08,0,0,0,56.65,57.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.75,58.78h0a.07.07,0,0,0,0,.1.09.09,0,0,0,.11,0,3.79,3.79,0,0,1,.68-.87A4.27,4.27,0,0,0,56.75,58.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.79,57.21h0a.07.07,0,0,0,.13.07,4.6,4.6,0,0,1,.66-.88A4.6,4.6,0,0,0,57.79,57.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.1,55.52h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,5,5,0,0,1,.62-.91A4.58,4.58,0,0,0,58.1,55.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.45,53.63h0a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0,4.12,4.12,0,0,1,.58-.93A4.27,4.27,0,0,0,58.45,53.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.84,52.52a4.6,4.6,0,0,1,.57-1,4.59,4.59,0,0,0-.7.88h0a.08.08,0,0,0,0,.1A.07.07,0,0,0,58.84,52.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.59,47.29a.07.07,0,0,0,0,.09.08.08,0,0,0,.1-.05,4.3,4.3,0,0,1,.44-1,4.44,4.44,0,0,0-.59,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.8,43.94a.08.08,0,0,0,.06.09A.07.07,0,0,0,57,44a4.41,4.41,0,0,1,.38-1,4.94,4.94,0,0,0-.53,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.78,41.37h0a.09.09,0,0,0,.06.09.07.07,0,0,0,.09-.06,4.71,4.71,0,0,1,.19-.64s0,.08,0,.08a.07.07,0,0,0,.09-.05,4.8,4.8,0,0,1,.38-1,4,4,0,0,0-.4.73,1.12,1.12,0,0,1,.05-.13A4.14,4.14,0,0,0,55.78,41.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.78,38.65h0a.08.08,0,0,0,.15,0,4.41,4.41,0,0,1,.33-1A4.47,4.47,0,0,0,54.78,38.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.62,36.16a.08.08,0,0,0,.15,0,4.46,4.46,0,0,1,.32-1.06,4.72,4.72,0,0,0-.47,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.77,35.27a.1.1,0,0,0,.07.09.09.09,0,0,0,.09-.07,4.11,4.11,0,0,1,.3-1.06,4.73,4.73,0,0,0-.45,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.82,34.05a.08.08,0,0,0,.06.09.1.1,0,0,0,.09-.07A4.39,4.39,0,0,1,52.26,33a4.1,4.1,0,0,0-.44,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.28,31a.08.08,0,0,0,.15,0A4.51,4.51,0,0,1,49.65,30,4.75,4.75,0,0,0,49.28,31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.58,29.16a.08.08,0,0,0,.07.08.08.08,0,0,0,.08-.08,4.51,4.51,0,0,1,.13-1.09,4.4,4.4,0,0,0-.28,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.11,27.75a4.06,4.06,0,0,1,0-1.1A4.15,4.15,0,0,0,46,27.76h0a.08.08,0,0,0,.08.07A.09.09,0,0,0,46.11,27.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.84,26.5h0a.08.08,0,0,0,.15,0,4.5,4.5,0,0,1-.08-1.1A4.63,4.63,0,0,0,44.84,26.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.47,25.83v0a.08.08,0,0,0,.15,0,4.42,4.42,0,0,1-.11-1.1A4.67,4.67,0,0,0,44.47,25.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.05,25.44a4.27,4.27,0,0,1-.29-1.06,4.69,4.69,0,0,0,.14,1.11h0a.07.07,0,0,0,.1,0A.08.08,0,0,0,42.05,25.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.58,25.69a4.34,4.34,0,0,1-.29-1.07,4.26,4.26,0,0,0,.15,1.12h0a.1.1,0,0,0,.1,0A.07.07,0,0,0,41.58,25.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.11,25.25h0s.06,0,.1,0a.09.09,0,0,0,0-.11,4.59,4.59,0,0,1-.33-1.05A5.35,5.35,0,0,0,40.11,25.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.62,23.7a4.18,4.18,0,0,1-.35-1.05,4.63,4.63,0,0,0,.2,1.1h0a.07.07,0,0,0,.1,0A.08.08,0,0,0,38.62,23.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.29,23.43a4.59,4.59,0,0,1-.37-1,4.69,4.69,0,0,0,.23,1.1h0a.07.07,0,0,0,.1,0A.07.07,0,0,0,37.29,23.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.64,23.38h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.56,4.56,0,0,1-.39-1A4.54,4.54,0,0,0,35.64,23.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.33,24h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.56,4.56,0,0,1-.39-1A4.73,4.73,0,0,0,35.33,24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.05,17.66h0a.07.07,0,0,0,.1,0s.05-.05,0-.09a4.37,4.37,0,0,0,.12.52v0a.07.07,0,0,0,.1,0s0-.06,0-.1A4.75,4.75,0,0,1,41.13,17a5,5,0,0,0,.06.54,4.54,4.54,0,0,1-.27-1A4.76,4.76,0,0,0,41.05,17.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.22,12.33a.07.07,0,0,0,.06-.09,5,5,0,0,1-.06-1.1,5.24,5.24,0,0,0-.09,1.12h0A.08.08,0,0,0,49.22,12.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.66,12.48a4.6,4.6,0,0,1,.1-1.1,4.56,4.56,0,0,0-.26,1.09v0a.08.08,0,0,0,.08.07A.08.08,0,0,0,52.66,12.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.63,13.54a4.92,4.92,0,0,1,.21-1.08,4.73,4.73,0,0,0-.36,1.06h0a.08.08,0,1,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,13.45a4.55,4.55,0,0,1,.2-1.09,4.47,4.47,0,0,0-.35,1.07h0a.08.08,0,0,0,.07.08A.07.07,0,0,0,54,13.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.34,16.25a.09.09,0,0,0,.07-.09,4.05,4.05,0,0,1,0-1.1,4.58,4.58,0,0,0-.16,1.11h0A.08.08,0,0,0,48.34,16.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.84,17.52a.08.08,0,0,0,.15-.05,4.38,4.38,0,0,1-.2-1.09,4.68,4.68,0,0,0,.05,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,17.23h0a.08.08,0,0,0,.1.05.08.08,0,0,0,.05-.1A4.35,4.35,0,0,1,45,16.1,4.75,4.75,0,0,0,45,17.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.05,16.88A4.67,4.67,0,0,0,47,18h0a.08.08,0,0,0,.09.06A.06.06,0,0,0,47.1,18,4.57,4.57,0,0,1,47.05,16.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.16,18.94a.08.08,0,1,0,.15,0,4.47,4.47,0,0,1,.1-1.09,4.33,4.33,0,0,0-.25,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.87,19.69a4.51,4.51,0,0,1,.22-1.08,4.3,4.3,0,0,0-.37,1.06h0a.08.08,0,0,0,.08.08S51.86,19.73,51.87,19.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.47,20.41a4.39,4.39,0,0,1,.29-1.06,4.26,4.26,0,0,0-.44,1h0a.08.08,0,1,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.56,22.43a4.29,4.29,0,0,1,.2-.7h0v0c0-.1.08-.21.13-.31s0,.09-.08.14a4.87,4.87,0,0,1,.28-.84,4.47,4.47,0,0,0-.48,1h0a.08.08,0,0,0,.06.08,4.66,4.66,0,0,0-.26.61h0a.08.08,0,1,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.25,25.81a.08.08,0,0,0,.15,0,4.41,4.41,0,0,1,.38-1,4.24,4.24,0,0,0-.52,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.54,30.32a.08.08,0,1,0,.15,0,3.9,3.9,0,0,1,.39-1,4.45,4.45,0,0,0-.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,33.17a.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0,4.72,4.72,0,0,1,.43-1,4.59,4.59,0,0,0-.57,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.51,33.55h0a.07.07,0,0,0,0,.09.08.08,0,0,0,.1,0,4.72,4.72,0,0,1,.43-1A4.2,4.2,0,0,0,63.51,33.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.89,32.36a.08.08,0,0,0,0,.1.1.1,0,0,0,.1-.06,3.86,3.86,0,0,1,.42-1,4.42,4.42,0,0,0-.57,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,27.56a.08.08,0,0,0,.06.09.07.07,0,0,0,.09,0,4.8,4.8,0,0,1,.38-1,4.45,4.45,0,0,0-.52,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.38,23.29h0a.08.08,0,0,0,.07.08.07.07,0,0,0,.08-.08,4.47,4.47,0,0,1,.2-1.08A4.2,4.2,0,0,0,49.38,23.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.31,22a.07.07,0,0,0,.1,0,.09.09,0,0,0,0-.1,3.84,3.84,0,0,1-.36-1.05,4,4,0,0,0,.21,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,22.1h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.79,4.79,0,0,1-.45-1A4.51,4.51,0,0,0,30,22.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.52,22.25h0a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.53,4.53,0,0,1-.46-1A4,4,0,0,0,28.52,22.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5,20.36a4.21,4.21,0,0,0,.73.85h0a.09.09,0,0,0,.11,0,.07.07,0,0,0,0-.11A4.38,4.38,0,0,1,5,20.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.94,21.24a.07.07,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.43,4.43,0,0,1-.82-.74,4.91,4.91,0,0,0,.72.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.34,20.4a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.43,4.43,0,0,1-.82-.74,4.27,4.27,0,0,0,.73.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.94,15.74a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.44,4.44,0,0,1-.83-.73,4.56,4.56,0,0,0,.73.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.51,13.52a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.37,4.37,0,0,1-.81-.74,4.85,4.85,0,0,0,.71.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.42,10.68a.08.08,0,0,0,0-.11,3.84,3.84,0,0,1-.78-.78,4.48,4.48,0,0,0,.67.9h0A.08.08,0,0,0,11.42,10.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.16,8.9a.08.08,0,1,0,.1-.12,4.11,4.11,0,0,1-.71-.84,4.48,4.48,0,0,0,.6,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.15,7.75a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.72,4.72,0,0,1-.65-.89,4.51,4.51,0,0,0,.53,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.62,6.21a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.75,4.75,0,0,1-.56-1,4.44,4.44,0,0,0,.44,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.51,5.81a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.35,4.35,0,0,1-.49-1A4.62,4.62,0,0,0,23.5,5.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.08,5.4a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.33,4.33,0,0,1-.43-1,4.14,4.14,0,0,0,.3,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.89,4.63a.09.09,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.77,4.77,0,0,1-.37-1,4.69,4.69,0,0,0,.23,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.82,4.08a.07.07,0,0,0,.1,0A.08.08,0,0,0,37,4,4.33,4.33,0,0,1,36.68,3a4.7,4.7,0,0,0,.13,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.06,3.68a.08.08,0,0,0,.15,0A4.47,4.47,0,0,1,43,2.55a4.22,4.22,0,0,0,.06,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.59,4a.08.08,0,0,0,.15,0,4.07,4.07,0,0,1-.1-1.1,4.19,4.19,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.42,4.66a4.08,4.08,0,0,1,.12-1.1,4.15,4.15,0,0,0-.27,1.09h0a.08.08,0,0,0,.08.08A.08.08,0,0,0,58.42,4.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.77,5.7A4,4,0,0,1,64,4.63a4.56,4.56,0,0,0-.4,1h0a.08.08,0,0,0,.07.08A.1.1,0,0,0,63.77,5.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.25,8.74a4.24,4.24,0,0,1,.4-1,4.48,4.48,0,0,0-.55,1h0a.07.07,0,0,0,.05.09A.08.08,0,0,0,69.25,8.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.9,11.9a4.34,4.34,0,0,1,.51-1,4.56,4.56,0,0,0-.65.91h0a.08.08,0,0,0,0,.1A.06.06,0,0,0,74.9,11.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.18,13.87a3.92,3.92,0,0,1,.55-.95,4.19,4.19,0,0,0-.69.88h0a.1.1,0,0,0,0,.1A.08.08,0,0,0,78.18,13.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.94,18.6a.08.08,0,0,0,0,.1.08.08,0,0,0,.11,0,4.51,4.51,0,0,1,.58-.93,4.63,4.63,0,0,0-.72.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82,19.14a4.63,4.63,0,0,0-.72.86h0a.08.08,0,1,0,.14.06A4.26,4.26,0,0,1,82,19.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.49,22.93a.09.09,0,0,0,0,.11s.09,0,.1,0a5.06,5.06,0,0,1,.58-.94,4.29,4.29,0,0,0-.71.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.91,27.25h0a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0,4.12,4.12,0,0,1,.55-.95A4.54,4.54,0,0,0,76.91,27.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.35,30.85h0a.08.08,0,1,0,.14.06A4.53,4.53,0,0,1,75,30,4.53,4.53,0,0,0,74.35,30.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.67,34.9a.07.07,0,0,0,0,.09.07.07,0,0,0,.1,0,4.32,4.32,0,0,1,.5-1,4,4,0,0,0-.64.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.62,41.79a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0,4.16,4.16,0,0,1,.56-1,4.59,4.59,0,0,0-.7.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,47.6a4.23,4.23,0,0,0-.77.81h0a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0A4.27,4.27,0,0,1,73,47.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.48,53.16h0a.07.07,0,0,0,0,.1.06.06,0,0,0,.1,0,4.11,4.11,0,0,1,.72-.84A4.26,4.26,0,0,0,71.48,53.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.08,57.39a.08.08,0,0,0,.11,0,4.12,4.12,0,0,1,.79-.77,4.22,4.22,0,0,0-.9.67h0A.08.08,0,0,0,71.08,57.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.81,59.91a.07.07,0,0,0,0,.1.08.08,0,0,0,.11,0,4.84,4.84,0,0,1,.84-.72,4.46,4.46,0,0,0-.94.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.11,63a.08.08,0,1,0,.11.11,4.82,4.82,0,0,1,.88-.66,4.69,4.69,0,0,0-1,.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,65.68h0a.08.08,0,0,0,.1.12,4.29,4.29,0,0,1,.9-.63A3.88,3.88,0,0,0,69,65.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,65a3.94,3.94,0,0,0-1,.51h0a.08.08,0,0,0,0,.11.09.09,0,0,0,.11,0A4.1,4.1,0,0,1,71,65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.2,64.07a4.71,4.71,0,0,0-1,.51h0a.08.08,0,0,0,.11.11A4.4,4.4,0,0,1,70.2,64.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,56.87h0a.07.07,0,0,0,0,.1.08.08,0,0,0,.11,0,4.43,4.43,0,0,1,.77-.79A4.52,4.52,0,0,0,69,56.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.22,51.37h0a.08.08,0,1,0,.13.08,4.05,4.05,0,0,1,.66-.88A4.22,4.22,0,0,0,69.22,51.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.07,43.28a4.17,4.17,0,0,1,.53-1,4.58,4.58,0,0,0-.67.9h0a.07.07,0,0,0,0,.1A.07.07,0,0,0,69.07,43.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.52,40.64a4.53,4.53,0,0,1,.48-1,4.57,4.57,0,0,0-.62.93h0a.07.07,0,0,0,0,.09A.07.07,0,0,0,68.52,40.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.76,22.85a.08.08,0,0,0,.1-.12A4.43,4.43,0,0,1,9.07,22a4.19,4.19,0,0,0,.69.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.09,23.14h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.43,4.43,0,0,1-.79-.77A4.52,4.52,0,0,0,9.09,23.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9,28.08a.06.06,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.84,4.84,0,0,1-.8-.77,4.59,4.59,0,0,0,.7.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.67,46.9h0a.08.08,0,1,0,.06-.14,4.51,4.51,0,0,1-.93-.58A4.63,4.63,0,0,0,8.67,46.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.45,56.22h0a.07.07,0,0,0,.1,0,.07.07,0,0,0-.05-.09,4.79,4.79,0,0,1-1-.47A4.89,4.89,0,0,0,8.45,56.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.51,66.35a.09.09,0,0,0,.1,0,.08.08,0,0,0-.06-.1,4.3,4.3,0,0,1-1-.44,5,5,0,0,0,1,.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8,75.07a4.54,4.54,0,0,0,.79.79h0a.07.07,0,0,0,.07-.13A4.69,4.69,0,0,1,8,75.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.71,85.38a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.11A4.37,4.37,0,0,1,10,84.5a4.46,4.46,0,0,0,.69.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.15,92.31a4.51,4.51,0,0,0,.72.86h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11A4.71,4.71,0,0,1,12.15,92.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.5,95a4.9,4.9,0,0,0,.73.85h0a.08.08,0,1,0,.09-.13A4.77,4.77,0,0,1,13.5,95Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.09,92.59a.06.06,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.43,4.43,0,0,1-.82-.74,4.51,4.51,0,0,0,.72.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.31,81.56a4.19,4.19,0,0,0,.69.88h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11A4.05,4.05,0,0,1,11.31,81.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.82,72.79a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.35,4.35,0,0,1-.93-.6,5,5,0,0,0,.85.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,64.28a4.79,4.79,0,0,1-1-.45,4.44,4.44,0,0,0,.95.59h0a.07.07,0,0,0,.1,0A.07.07,0,0,0,10,64.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.51,56.79a.07.07,0,0,0,.09,0s0-.08,0-.1a4.3,4.3,0,0,1-1-.46,5,5,0,0,0,.95.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.72,50.47h0a.07.07,0,1,0,0-.14,4.54,4.54,0,0,1-1-.54A4.22,4.22,0,0,0,8.72,50.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.7,49.64h0a.09.09,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.54,4.54,0,0,1-1-.54A4.28,4.28,0,0,0,8.7,49.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.31,66.6a4.21,4.21,0,0,0,.94.61h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.09A4.15,4.15,0,0,1,14.31,66.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.7,79.85a4.88,4.88,0,0,0,.68.89h0a.08.08,0,0,0,.11,0s0-.09,0-.11A4.12,4.12,0,0,1,16.7,79.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.7,95.44a4.1,4.1,0,0,1-.81-.75,4.58,4.58,0,0,0,.71.87h0a.07.07,0,0,0,.11,0A.07.07,0,0,0,19.7,95.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.28,105.13h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.29,4.29,0,0,1-.84-.69h0a.08.08,0,0,0,0-.11,4.28,4.28,0,0,1-.85-.69,4.54,4.54,0,0,0,.76.82h0a.07.07,0,0,0,.1,0A4.37,4.37,0,0,0,21.28,105.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.94,107a4.55,4.55,0,0,0,.74.84h0a.08.08,0,1,0,.09-.13A4.11,4.11,0,0,1,20.94,107Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.7,70.61a4.94,4.94,0,0,0,.87.7h0a.08.08,0,0,0,.1,0,.07.07,0,0,0,0-.1A4.54,4.54,0,0,1,6.7,70.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M1.22,47.69a4.53,4.53,0,0,0,.9.66h0a.09.09,0,0,0,.1,0,.06.06,0,0,0,0-.09A5.07,5.07,0,0,1,1.22,47.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M0,33.31a4.51,4.51,0,0,0,.71.86h0a.07.07,0,0,0,.11,0,.07.07,0,0,0,0-.1A4.81,4.81,0,0,1,0,33.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M1.54,32.17a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.37,4.37,0,0,1-.8-.75,4.45,4.45,0,0,0,.7.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M2.68,35.68a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.39,4.39,0,0,1-.83-.73,4.56,4.56,0,0,0,.73.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M2.63,63.37a4.93,4.93,0,0,0,.92.65h0a.08.08,0,0,0,.1,0,.09.09,0,0,0-.05-.1A4.32,4.32,0,0,1,2.63,63.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.73,90.54a4.56,4.56,0,0,0,.73.86h0a.09.09,0,0,0,.11,0,.07.07,0,0,0,0-.11A4.77,4.77,0,0,1,4.73,90.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.45,102.8a4.85,4.85,0,0,0,.71.86h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11A4.37,4.37,0,0,1,10.45,102.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.33,114.58a4.76,4.76,0,0,0,.63.93h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11A4.13,4.13,0,0,1,23.33,114.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.41,118.61h0a.07.07,0,0,0,.09.06.07.07,0,0,0,.05-.1,5,5,0,0,1-.16-1.09A4.66,4.66,0,0,0,36.41,118.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.31,105.07a.08.08,0,0,0,0,.11.07.07,0,0,0,.11,0,4.41,4.41,0,0,1,.82-.73,4.23,4.23,0,0,0-.93.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.12,84.79a.08.08,0,0,0,.11.11,4.72,4.72,0,0,1,.89-.65,5,5,0,0,0-1,.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78,65.77a.07.07,0,1,0,.1.11,4.87,4.87,0,0,1,.9-.64,4.68,4.68,0,0,0-1,.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.51,55.89a.08.08,0,0,0,0,.11.09.09,0,0,0,.11,0,4.13,4.13,0,0,1,.82-.74,4.48,4.48,0,0,0-.93.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.59,56.67a.07.07,0,0,0,0,.11.08.08,0,0,0,.1,0,4.83,4.83,0,0,1,.83-.73,4.43,4.43,0,0,0-.93.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.48,73.92h0a.09.09,0,0,0,0,.11.08.08,0,0,0,.11,0,4.71,4.71,0,0,1,.94-.57A4.67,4.67,0,0,0,76.48,73.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.08,97a.07.07,0,1,0,.11.1,4.84,4.84,0,0,1,.84-.72,4.52,4.52,0,0,0-1,.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.25,108.62a.09.09,0,0,0,0,.11.08.08,0,0,0,.11,0,4.43,4.43,0,0,1,.82-.74,4.76,4.76,0,0,0-.93.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.51,108.64a.07.07,0,0,0,0,.11.09.09,0,0,0,.11,0,4.08,4.08,0,0,1,.82-.73,4.08,4.08,0,0,0-.93.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.25,77.34a.08.08,0,0,0,0,.11.08.08,0,0,0,.11,0,4.26,4.26,0,0,1,.93-.58,4.87,4.87,0,0,0-1,.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.79,58.46a.08.08,0,0,0,0,.11.08.08,0,0,0,.11,0,4.71,4.71,0,0,1,.83-.72,4.39,4.39,0,0,0-.93.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.39,46h0a.08.08,0,0,0,0,.11.08.08,0,0,0,.1,0,4.79,4.79,0,0,1,.7-.86A4.6,4.6,0,0,0,83.39,46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.44,42.66h0a.07.07,0,0,0,0,.1.07.07,0,0,0,.1,0,4.29,4.29,0,0,1,.63-.9A4,4,0,0,0,81.44,42.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.39,45.55a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0,4.43,4.43,0,0,1,.68-.87,3.94,3.94,0,0,0-.8.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.11,63h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.72,4.72,0,0,1,.89-.65A3.76,3.76,0,0,0,76.11,63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.77,80.49h0a.06.06,0,0,0,0,.1s.07,0,.11,0a4.17,4.17,0,0,1,.92-.6A4.32,4.32,0,0,0,71.77,80.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.57,90.64a.08.08,0,0,0,.12.11,4.35,4.35,0,0,1,.85-.69,4.31,4.31,0,0,0-1,.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.86,93.46h0a.07.07,0,0,0,0,.11.08.08,0,0,0,.1,0,4.4,4.4,0,0,1,.85-.7A4.07,4.07,0,0,0,65.86,93.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,81.63s0,.07,0,.11a.08.08,0,0,0,.11,0,4.74,4.74,0,0,1,.92-.61,4.48,4.48,0,0,0-1,.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.59,57.55a.08.08,0,0,0,0,.11.07.07,0,0,0,.11,0,4.39,4.39,0,0,1,.84-.71,4.48,4.48,0,0,0-1,.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.83,38.7a.08.08,0,0,0,.14.06,4.18,4.18,0,0,1,.58-.94,5,5,0,0,0-.72.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.57,28.89a.08.08,0,0,0,0,.1.08.08,0,0,0,.11,0,4.51,4.51,0,0,1,.58-.93,4.63,4.63,0,0,0-.72.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.45,22.61h0a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0,4,4,0,0,1,.59-.93A4.56,4.56,0,0,0,82.45,22.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M84.77,16.54a.08.08,0,0,0,0,.11s.09,0,.1,0a4.3,4.3,0,0,1,.59-.93,4.91,4.91,0,0,0-.72.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M86.63,14h0a.08.08,0,0,0,.14.06,4.26,4.26,0,0,1,.58-.93A4.23,4.23,0,0,0,86.63,14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.24,35.48a.07.07,0,0,0,.11,0,.06.06,0,0,0,0-.1,4.77,4.77,0,0,1-.83-.73,5,5,0,0,0,.74.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.26,36.18a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.44,4.44,0,0,1-.83-.73,4.5,4.5,0,0,0,.73.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.64,35.74A4.34,4.34,0,0,1,8.81,35a4.55,4.55,0,0,0,.74.84h0a.07.07,0,0,0,.11,0A.07.07,0,0,0,9.64,35.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8,33.64a.09.09,0,0,0,.11,0,.07.07,0,0,0,0-.11,4.77,4.77,0,0,1-.82-.74,5,5,0,0,0,.73.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.86,30.69a4.58,4.58,0,0,0,.71.87h0a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11A4.1,4.1,0,0,1,5.86,30.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.94,29.3a4.43,4.43,0,0,1-.81-.75,4.91,4.91,0,0,0,.71.87h0a.08.08,0,0,0,.11,0A.09.09,0,0,0,5.94,29.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.39,25.79a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.05,4.05,0,0,1-.8-.75,3.92,3.92,0,0,0,.71.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.27,22.86a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.1A4.84,4.84,0,0,1,4.54,22a4.27,4.27,0,0,0,.72.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.25,17.05a4.27,4.27,0,0,0,.72.87H7a.07.07,0,0,0,.08-.12A4.4,4.4,0,0,1,6.25,17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.25,14.54a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.12,4.12,0,0,1-.79-.77,4.41,4.41,0,0,0,.68.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.87,11.64a.08.08,0,1,0,.11-.11,4.43,4.43,0,0,1-.68-.87,4.29,4.29,0,0,0,.56,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.1,10.81a.06.06,0,0,0,.1,0,.07.07,0,0,0,0-.11,4.47,4.47,0,0,1-.66-.89,4.76,4.76,0,0,0,.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.85,12.26a.09.09,0,0,0,.11,0,0,0,0,0,0,0,0,5.3,5.3,0,0,0,.52.75h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.83,4.83,0,0,1-.64-.71s0,0,0,0a4.13,4.13,0,0,1-.74-.82,4.48,4.48,0,0,0,.63.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.45,16h0a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.8,4.8,0,0,1-.78-.78A4.88,4.88,0,0,0,10.45,16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.87,16.93a4.51,4.51,0,0,0,.71.87h0s.09,0,.11,0a.07.07,0,0,0,0-.11A4.72,4.72,0,0,1,7.87,16.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.25,19a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.12,4.12,0,0,1-.8-.76,4.51,4.51,0,0,0,.7.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.56,16a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11A4.53,4.53,0,0,1,15,15a4.5,4.5,0,0,0,.59,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.48,10.18a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.53,4.53,0,0,1-.46-1,4.4,4.4,0,0,0,.32,1.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.05,8.66a.07.07,0,0,0,.1,0s.05-.06,0-.1a4.25,4.25,0,0,1-.32-1A4.74,4.74,0,0,0,35,8.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.5,9.67a.09.09,0,0,0,.1.05.08.08,0,0,0,0-.1,4.36,4.36,0,0,1-.18-1.08,4.2,4.2,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.94,11.89a4.48,4.48,0,0,1,.11-1.09,4.37,4.37,0,0,0-.26,1.08v0a.07.07,0,0,0,.08.07A.08.08,0,0,0,54.94,11.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.83,13.64a4.92,4.92,0,0,1,.38-1,4.24,4.24,0,0,0-.52,1h0a.08.08,0,0,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.66,14.17a4.34,4.34,0,0,1,.51-1,4.35,4.35,0,0,0-.65.92h0a.06.06,0,0,0,0,.09A.07.07,0,0,0,72.66,14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79,14.67a4.83,4.83,0,0,1,.56-.95,4.59,4.59,0,0,0-.7.88h0a.07.07,0,0,0,0,.1A.07.07,0,0,0,79,14.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.79,15.55a.08.08,0,0,0,0,.1.09.09,0,0,0,.11,0,4.29,4.29,0,0,1,.57-.94,4.29,4.29,0,0,0-.71.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.57,14.45a4.54,4.54,0,0,1,.57-.94,4.53,4.53,0,0,0-.7.87h0s0,.09,0,.1A.07.07,0,0,0,81.57,14.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.2,11.34a4.1,4.1,0,0,1,.53-1,4.3,4.3,0,0,0-.67.9h0a.07.07,0,0,0,0,.1A.08.08,0,0,0,76.2,11.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.11,10.43a4.15,4.15,0,0,1,.49-1,4.23,4.23,0,0,0-.63.93h0a.08.08,0,1,0,.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.63,9.27a4.28,4.28,0,0,1,.39-1,4.45,4.45,0,0,0-.54,1h0a.07.07,0,0,0,0,.09A.08.08,0,0,0,67.63,9.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.05,8.08a4.33,4.33,0,0,1,.38-1,4.68,4.68,0,0,0-.52,1h0a.08.08,0,0,0,.06.1A.08.08,0,0,0,67.05,8.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.24,8.88a4.15,4.15,0,0,1,.49-1,4.23,4.23,0,0,0-.63.93h0s0,.08.05.1A.07.07,0,0,0,74.24,8.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.16,15.57s0,.09,0,.1a.07.07,0,0,0,.1,0,4.83,4.83,0,0,1,.56-1,4.54,4.54,0,0,0-.69.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.57,25.65a.08.08,0,1,0,.14.06,4.54,4.54,0,0,1,.54-1,4.28,4.28,0,0,0-.68.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.82,31.66h0a.07.07,0,0,0,.05.09s.08,0,.1-.05a4.3,4.3,0,0,1,.46-1A4.67,4.67,0,0,0,68.82,31.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.27,34.9a.08.08,0,0,0,.06.09.07.07,0,0,0,.09-.05,4.34,4.34,0,0,1,.41-1,4.48,4.48,0,0,0-.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.27,34.2a.08.08,0,0,0,.06.1.08.08,0,0,0,.09-.06,4.11,4.11,0,0,1,.38-1,4.86,4.86,0,0,0-.53,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.89,36.43h0a.08.08,0,1,0,.15,0,4.48,4.48,0,0,1,.4-1A4.48,4.48,0,0,0,63.89,36.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.54,40.57a4.5,4.5,0,0,1,.46-1,4.48,4.48,0,0,0-.6,1h0a.07.07,0,0,0,0,.09A.07.07,0,0,0,66.54,40.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.37,44.35a.08.08,0,0,0,.14.06,4.61,4.61,0,0,1,.52-1,4.6,4.6,0,0,0-.66.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.06,48.12a.07.07,0,0,0,0,.1.09.09,0,0,0,.11,0,4,4,0,0,1,.59-.93,4.26,4.26,0,0,0-.73.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,48.91a.07.07,0,0,0,0,.1.08.08,0,0,0,.1,0,4.72,4.72,0,0,1,.59-.93,4.5,4.5,0,0,0-.72.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.26,46.37h0a.08.08,0,0,0,.14.06,4,4,0,0,1,.56-.95A4.19,4.19,0,0,0,67.26,46.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,39.41a4.5,4.5,0,0,0-.59,1h0a.08.08,0,0,0,0,.1.08.08,0,0,0,.1-.06A4.3,4.3,0,0,1,65,39.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.39,35a4.52,4.52,0,0,0-.54,1h0a.08.08,0,0,0,.06.09.07.07,0,0,0,.09-.06A4,4,0,0,1,62.39,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.33,31.65a.08.08,0,0,0,.06.09.09.09,0,0,0,.09-.07,4.37,4.37,0,0,1,.34-1,4.11,4.11,0,0,0-.49,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.12,29.28s0,.09.07.09a.06.06,0,0,0,.08-.06,5.23,5.23,0,0,1,.12-.54.08.08,0,0,0,.08-.06,3.15,3.15,0,0,1,.09-.42s0,0,0,0v0a3.45,3.45,0,0,1,.2-.62,4.73,4.73,0,0,0-.45,1h0s0,0,0,0c-.08.19-.15.37-.21.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.49,31.18a4,4,0,0,0-.47,1h0a.09.09,0,0,0,.07.09.06.06,0,0,0,.08-.06A4.92,4.92,0,0,1,57.49,31.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,36.93a.09.09,0,0,0,.06.1.09.09,0,0,0,.09-.06,4.33,4.33,0,0,1,.38-1,4.48,4.48,0,0,0-.53,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.64,41.36s0,.08,0,.1a.08.08,0,0,0,.09-.06,4.3,4.3,0,0,1,.44-1,4.46,4.46,0,0,0-.58,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.13,42.27h0a.08.08,0,0,0,.15,0,4.3,4.3,0,0,1,.44-1A4.4,4.4,0,0,0,63.13,42.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.83,39a.08.08,0,0,0,.06.1A.08.08,0,0,0,61,39a5,5,0,0,1,.39-1,4.9,4.9,0,0,0-.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.89,32.64a.09.09,0,0,0,.07.09.07.07,0,0,0,.08-.07,4.57,4.57,0,0,1,.31-1.06,4.76,4.76,0,0,0-.46,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.35,28.22a.07.07,0,0,0,.07.08.08.08,0,0,0,.08-.08,4.45,4.45,0,0,1,.19-1.08,4.45,4.45,0,0,0-.34,1.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.87,24.26h0a.08.08,0,0,0,.08.07s.08,0,.07-.09a5.08,5.08,0,0,1,0-1.1A4.6,4.6,0,0,0,46.87,24.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.48,22.65v0s0,.06.09,0a.07.07,0,0,0,.06-.09,4.42,4.42,0,0,1-.1-1.1A5.26,5.26,0,0,0,44.48,22.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.93,21.7a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.1,4.25,4.25,0,0,1-.23-1.07,4.7,4.7,0,0,0,.08,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.88,22.27a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.18,4.18,0,0,1-.35-1.05,4.62,4.62,0,0,0,.2,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.18,24h0a.08.08,0,0,0,.14-.07,4.14,4.14,0,0,1-.42-1A4.2,4.2,0,0,0,33.18,24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.65,26a.09.09,0,0,0,.11,0,.07.07,0,0,0,0-.11,4.29,4.29,0,0,1-.47-1A4.29,4.29,0,0,0,31.65,26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.27,30.19a4.75,4.75,0,0,1-.56-1,4.51,4.51,0,0,0,.44,1h0a.08.08,0,0,0,.11,0A.08.08,0,0,0,30.27,30.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.2,34h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.47,4.47,0,0,1-.66-.89A4.45,4.45,0,0,0,29.2,34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.73,34.58a.07.07,0,1,0,.11-.1,4.42,4.42,0,0,1-.65-.89,4,4,0,0,0,.54,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.36,32.17h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.45,4.45,0,0,1-.59-.93A4.91,4.91,0,0,0,31.36,32.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.56,28.48a.07.07,0,0,0,.13-.07,4.14,4.14,0,0,1-.42-1,4.09,4.09,0,0,0,.28,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.18,28.31h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.18,4.18,0,0,1-.41-1A4.58,4.58,0,0,0,36.18,28.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.9,28.11a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.1,4.56,4.56,0,0,1-.39-1,4.54,4.54,0,0,0,.25,1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.05,27.38a4.61,4.61,0,0,1-.34-1.05,4.71,4.71,0,0,0,.2,1.11h0a.07.07,0,0,0,.13-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.35,27.82a.07.07,0,0,0,.1,0,.09.09,0,0,0,0-.1,4.37,4.37,0,0,1-.34-1.06,4.61,4.61,0,0,0,.19,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.32,27.69a.07.07,0,0,0,.1,0s0-.06,0-.1a4.17,4.17,0,0,1-.3-1.06,4.64,4.64,0,0,0,.15,1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.15,27.79h0a.08.08,0,0,0,.1.05.07.07,0,0,0,0-.1A4.29,4.29,0,0,1,40,26.68,4.65,4.65,0,0,0,40.15,27.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41,27.84a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.09,4.43,4.43,0,0,1-.23-1.08A4.7,4.7,0,0,0,41,27.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42,27.73h0a.07.07,0,0,0,.09.05.08.08,0,0,0,.06-.09A4.45,4.45,0,0,1,42,26.61,4.67,4.67,0,0,0,42,27.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.18,28.35a.08.08,0,1,0,.15,0,4.52,4.52,0,0,1-.11-1.1,4.21,4.21,0,0,0,0,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.86,28.61h0a.08.08,0,1,0,.15,0,4.5,4.5,0,0,1-.08-1.1A4.2,4.2,0,0,0,43.86,28.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.88,28.43a4.56,4.56,0,0,0-.13,1.11h0a.1.1,0,0,0,.09.07.08.08,0,0,0,.06-.09A4.49,4.49,0,0,1,44.88,28.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.14,31a.07.07,0,0,0,.08.07.08.08,0,0,0,.07-.08,4.57,4.57,0,0,1,.08-1.1,4.48,4.48,0,0,0-.23,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,33.36a4.14,4.14,0,0,1,.18-1.09,4,4,0,0,0-.33,1.08h0s0,.07.08.08A.09.09,0,0,0,48,33.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,35.54s0,.08.07.09a.08.08,0,0,0,.08-.07,5,5,0,0,1,.24-1.08A4.72,4.72,0,0,0,49,35.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.41,37.09a.06.06,0,0,0,.06.08.07.07,0,0,0,.09-.06A3.91,3.91,0,0,1,49.83,36a4.53,4.53,0,0,0-.42,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.76,40h0a.07.07,0,0,0,.06.09.08.08,0,0,0,.09-.06,4.46,4.46,0,0,1,.32-1.06A4.72,4.72,0,0,0,50.76,40Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,45a4,4,0,0,1,.39-1,4.3,4.3,0,0,0-.54,1h0a.07.07,0,0,0,0,.09A.08.08,0,0,0,52,45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,46.53a4.34,4.34,0,0,0-.59.95h0a.07.07,0,0,0,.14,0A4.5,4.5,0,0,1,53,46.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.91,50.5a4.34,4.34,0,0,1,.51-1,4.56,4.56,0,0,0-.65.91h0a.08.08,0,0,0,.05.1A.07.07,0,0,0,52.91,50.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,54.75a.09.09,0,0,0,0,.11.09.09,0,0,0,.11,0,4.53,4.53,0,0,1,.61-.91,4.58,4.58,0,0,0-.75.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,56h0a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4.72,4.72,0,0,1,.65-.89A4.17,4.17,0,0,0,56,56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.42,54.16a.08.08,0,0,0,0,.11.07.07,0,0,0,.1,0,4,4,0,0,1,.61-.92,4.55,4.55,0,0,0-.74.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.3,49.32a.07.07,0,0,0,0,.1s.09,0,.1,0a4.61,4.61,0,0,1,.52-1,5,5,0,0,0-.66.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.74,45.43a.08.08,0,0,0,.06.1.07.07,0,0,0,.09-.05,4.79,4.79,0,0,1,.45-1,4.87,4.87,0,0,0-.59,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.09,42.76a.09.09,0,0,0,.06.1.08.08,0,0,0,.09-.06,4.27,4.27,0,0,1,.41-1,3.86,3.86,0,0,0-.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.76,37.39a.08.08,0,0,0,.06.09.09.09,0,0,0,.09-.06,5,5,0,0,1,.33-1.06,4.75,4.75,0,0,0-.48,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.12,34.05a.08.08,0,0,0,.15,0A4.47,4.47,0,0,1,53.56,33a4.7,4.7,0,0,0-.44,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.69,30a.08.08,0,0,0,.07.08.08.08,0,0,0,.08-.07,5.12,5.12,0,0,1,.21-1.09,4.91,4.91,0,0,0-.36,1.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.46,27.19v0a.08.08,0,0,0,.08.07.09.09,0,0,0,.08-.08,4.6,4.6,0,0,1,.1-1.1A4.56,4.56,0,0,0,48.46,27.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.78,22.89A5.19,5.19,0,0,0,45.66,24h0a.08.08,0,0,0,.09.06.07.07,0,0,0,.06-.09A5,5,0,0,1,45.78,22.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.64,22.57h0a.08.08,0,0,0,.09.06.09.09,0,0,0,.06-.1,4,4,0,0,1-.12-1.09A4.19,4.19,0,0,0,43.64,22.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.92,22.22a3.9,3.9,0,0,1-.21-1.08,4.22,4.22,0,0,0,.06,1.12h0a.09.09,0,0,0,.1.05A.08.08,0,0,0,40.92,22.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.7,22.9a.09.09,0,0,0,.1,0,.09.09,0,0,0,.05-.1,3.91,3.91,0,0,1-.27-1.07,4.27,4.27,0,0,0,.12,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.72,24.7a4.23,4.23,0,0,1-.31-1.05,4.27,4.27,0,0,0,.17,1.11h0a.1.1,0,0,0,.1,0A.08.08,0,0,0,37.72,24.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,27.24h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.1,4.33,4.33,0,0,1-.38-1A4.25,4.25,0,0,0,36,27.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.46,28.54h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.33,4.33,0,0,1-.43-1A4.14,4.14,0,0,0,34.46,28.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.55,29.52a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.38,4.38,0,0,1-.45-1,4.18,4.18,0,0,0,.32,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.23,31.85h0a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.38,4.38,0,0,1-.52-1A4.47,4.47,0,0,0,33.23,31.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.76,35.85a.08.08,0,0,0,.12-.1,4.47,4.47,0,0,1-.63-.91,4.71,4.71,0,0,0,.51,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.4,39.63a.08.08,0,0,0,.11,0,.07.07,0,0,0,0-.11,4,4,0,0,1-.71-.83,4.68,4.68,0,0,0,.6.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.47,41.77A4.38,4.38,0,0,1,29.7,41a4.42,4.42,0,0,0,.67.89h0a.08.08,0,1,0,.09-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.54,42.73a4.5,4.5,0,0,0,.72.85h0a.08.08,0,1,0,.09-.13A4.38,4.38,0,0,1,28.54,42.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.37,46.47h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.3,4.3,0,0,1-.87-.67A4.27,4.27,0,0,0,27.37,46.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.89,50.37a.08.08,0,0,0,.11,0s0-.09,0-.1a4.61,4.61,0,0,1-.93-.6,5,5,0,0,0,.85.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.32,53.26a.08.08,0,1,0,.06-.14,4.54,4.54,0,0,1-1-.54,4.16,4.16,0,0,0,.89.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,55.21a4.31,4.31,0,0,0,.92.64h0a.07.07,0,0,0,.1,0,.08.08,0,0,0-.05-.1A4.77,4.77,0,0,1,26,55.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.89,57.31a.08.08,0,0,0,.1-.05.09.09,0,0,0,0-.1,3.93,3.93,0,0,1-1-.48,3.87,3.87,0,0,0,.93.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.91,59.52a.07.07,0,0,0,.09-.05.08.08,0,0,0-.05-.1,4.12,4.12,0,0,1-1-.46,4.18,4.18,0,0,0,.94.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.51,61.9a.08.08,0,0,0,.09-.06.07.07,0,0,0-.05-.09,4.76,4.76,0,0,1-1-.44,4.23,4.23,0,0,0,1,.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.33,62.57a4.3,4.3,0,0,1-1-.44,4.5,4.5,0,0,0,1,.59h0a.09.09,0,0,0,.1,0A.1.1,0,0,0,26.33,62.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.39,61a.07.07,0,0,0,.09-.05.09.09,0,0,0-.05-.1,4.3,4.3,0,0,1-1-.44,4.66,4.66,0,0,0,1,.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.06,57.81a.08.08,0,0,0,.05-.15,4.15,4.15,0,0,1-1-.47,4.64,4.64,0,0,0,.94.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,56.23a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.1,4.56,4.56,0,0,1-1-.49,4.61,4.61,0,0,0,.93.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.87,53a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.54,4.54,0,0,1-1-.54,3.92,3.92,0,0,0,.89.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.76,46.43a.08.08,0,1,0,.08-.13,4.68,4.68,0,0,1-.86-.68,4.54,4.54,0,0,0,.77.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.24,41.75a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.07,4.07,0,0,1-.77-.78,4.48,4.48,0,0,0,.67.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.93,36.83a.07.07,0,1,0,.11-.1,4.44,4.44,0,0,1-.64-.9,4.68,4.68,0,0,0,.52,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.9,32.59a.07.07,0,0,0,.11,0,.08.08,0,0,0,0-.1,4.38,4.38,0,0,1-.52-1,4.4,4.4,0,0,0,.38,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.12,30.84h0a.08.08,0,0,0,.1,0,.08.08,0,0,0,0-.11,4.08,4.08,0,0,1-.47-1A4.26,4.26,0,0,0,35.12,30.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.77,27.7h0a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.53,4.53,0,0,1-.46-1A4,4,0,0,0,32.77,27.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.22,24.36h0a.09.09,0,0,0,.11,0,.07.07,0,0,0,0-.11,5,5,0,0,1-.47-1A4.52,4.52,0,0,0,30.22,24.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.33,22.85h0a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.6,4.6,0,0,1-.48-1A4.45,4.45,0,0,0,29.33,22.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.55,20.19h0a.07.07,0,0,0,.13-.07,4.3,4.3,0,0,1-.44-1A4.07,4.07,0,0,0,29.55,20.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.54,17.92a.07.07,0,0,0,.1,0,.07.07,0,0,0,0-.1,4.25,4.25,0,0,1-.35-1,4.19,4.19,0,0,0,.21,1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,16.45a.08.08,0,0,0,.1.05.07.07,0,0,0,0-.1,4.84,4.84,0,0,1-.24-1.08,4.21,4.21,0,0,0,.1,1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.21,15.71h0a.07.07,0,0,0,.09,0,.07.07,0,0,0,.06-.09,4.4,4.4,0,0,1-.13-1.09A4.19,4.19,0,0,0,45.21,15.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.37,14.56a.07.07,0,0,0,.07-.08,4,4,0,0,1,.05-1.1,4,4,0,0,0-.2,1.1h0A.07.07,0,0,0,51.37,14.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,14.77a4.12,4.12,0,0,1,.21-1.09,4.42,4.42,0,0,0-.36,1.06v0s0,.07.07.08A.08.08,0,0,0,57,14.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.6,113.42h0a.08.08,0,0,0,.11-.11,4.36,4.36,0,0,1-.65-.88A4.11,4.11,0,0,0,38.6,113.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.68,113.16h0a.08.08,0,1,0,.11-.11,4.4,4.4,0,0,1-.66-.88A5,5,0,0,0,37.68,113.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.27,112.85h0a.08.08,0,1,0,.15,0,4,4,0,0,1-.24-1.08A4.24,4.24,0,0,0,43.27,112.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.87,113.64a.08.08,0,0,0,.06.1.09.09,0,0,0,.09-.06,4.3,4.3,0,0,1,.37-1,4.41,4.41,0,0,0-.51,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.78,113.55h0a.07.07,0,0,0,0,.11.09.09,0,0,0,.11,0,4.39,4.39,0,0,1,.71-.84A4.9,4.9,0,0,0,54.78,113.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.74,107.82a4.76,4.76,0,0,0,.63.93h0a.07.07,0,0,0,.1,0,.08.08,0,0,0,0-.11A4.43,4.43,0,0,1,33.74,107.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.8,109a4.48,4.48,0,0,0,.63.93h0a.08.08,0,0,0,.11,0,.09.09,0,0,0,0-.11A4.13,4.13,0,0,1,34.8,109Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.61,109.45a.07.07,0,1,0,.11-.1,4.89,4.89,0,0,1-.66-.89,4.38,4.38,0,0,0,.55,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.82,109.83h0a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09,4.44,4.44,0,0,1-.24-1.08A4.24,4.24,0,0,0,42.82,109.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.33,109.34a.07.07,0,0,0,0,.1.09.09,0,0,0,.1,0,4.54,4.54,0,0,1,.54-1,4.63,4.63,0,0,0-.68.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.94,107.93a.08.08,0,0,0,0,.11.08.08,0,0,0,.11,0,4.12,4.12,0,0,1,.79-.77,4.43,4.43,0,0,0-.9.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.68,107.19a.08.08,0,0,0,0,.11s.09,0,.11,0a4.81,4.81,0,0,1,.81-.75,4.51,4.51,0,0,0-.92.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.78,106.25a.09.09,0,0,0,0,.1.08.08,0,0,0,.1,0,4.17,4.17,0,0,1,.53-1,4.58,4.58,0,0,0-.67.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.42,106.19a.08.08,0,0,0,.11-.11,4.43,4.43,0,0,1-.62-.91,5,5,0,0,0,.5,1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.14,105.56a4.43,4.43,0,0,0,.66.9h0a.09.09,0,0,0,.11,0,.08.08,0,0,0,0-.11A4.43,4.43,0,0,1,31.14,105.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.85,107.05a4.88,4.88,0,0,0,.68.89h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11A4.37,4.37,0,0,1,28.85,107.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.36,105h0a.08.08,0,0,0,.11,0,.08.08,0,0,0,0-.11,4.11,4.11,0,0,1-.38-1A4.09,4.09,0,0,0,40.36,105Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.22,104.39h0a.09.09,0,0,0,.11,0,.09.09,0,0,0,0-.11,4.11,4.11,0,0,1-.38-1A4.09,4.09,0,0,0,41.22,104.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.66,106.15h0s.06,0,.1,0a.09.09,0,0,0,0-.11,4.14,4.14,0,0,1-.33-1A4.68,4.68,0,0,0,41.66,106.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.84,107.36h0a.08.08,0,1,0,.15,0,4.52,4.52,0,0,1-.11-1.1A4.67,4.67,0,0,0,43.84,107.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.43,109.23h0a.09.09,0,0,0,.09.07.07.07,0,0,0,.06-.09,5.07,5.07,0,0,1,0-1.1A4.59,4.59,0,0,0,45.43,109.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.41,110.8h0a.08.08,0,0,0,.08.07.07.07,0,0,0,.07-.08,4.49,4.49,0,0,1,.06-1.1A4.46,4.46,0,0,0,46.41,110.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.43,106.79a.08.08,0,0,0,.08.08s.07,0,.08-.08a4.12,4.12,0,0,1,.17-1.09,4.84,4.84,0,0,0-.33,1.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.33,102.74h0a.08.08,0,0,0,0,.1.07.07,0,0,0,.1,0,3.94,3.94,0,0,1,.5-1A4.15,4.15,0,0,0,48.33,102.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.6,99.53a.08.08,0,0,0,0,.11.09.09,0,0,0,.11,0,4.12,4.12,0,0,1,.8-.76,3.88,3.88,0,0,0-.91.65Z"/>
      </g>}
      {r_type_hair >= 1 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.39,74.75a6,6,0,0,1-.77-.73,6.41,6.41,0,0,0,.84.59.09.09,0,0,1,.05.1.1.1,0,0,1-.11.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.09,73.27a6,6,0,0,1-.77-.73,6.41,6.41,0,0,0,.84.59.1.1,0,0,1,.05.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.34,73.21a5.9,5.9,0,0,1-.75-.75,5.36,5.36,0,0,0,.83.61.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,73a5.34,5.34,0,0,1-.76-.74,6.42,6.42,0,0,0,.83.6.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.22,74.83a5.9,5.9,0,0,1-.77-.72,6.32,6.32,0,0,0,.84.58.07.07,0,0,1,.05.1.1.1,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.25,73.87a6,6,0,0,1-.77-.73,6.41,6.41,0,0,0,.84.59.08.08,0,0,1,.05.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.52,73.84a5.87,5.87,0,0,1-.76-.73,5.81,5.81,0,0,0,.84.59.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.37,74a6.59,6.59,0,0,1-.76-.73,6.41,6.41,0,0,0,.84.59.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.16,73a6.57,6.57,0,0,1-.75-.74,6,6,0,0,0,.83.6.08.08,0,1,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.11,73a6.59,6.59,0,0,1-.76-.73,5.81,5.81,0,0,0,.84.59.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,74.24a5.9,5.9,0,0,1-.77-.72,6.32,6.32,0,0,0,.84.58.09.09,0,0,1,.05.1.1.1,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.61,74.12a5.84,5.84,0,0,1-.75-.74,5.8,5.8,0,0,0,.83.6.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.3,75a5.9,5.9,0,0,1-.77-.72,5.81,5.81,0,0,0,.85.58.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.66,72.29a5.83,5.83,0,0,1-.75-.74,5.59,5.59,0,0,0,.83.6.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.29,71.92a5.81,5.81,0,0,1-.74-.75,5.89,5.89,0,0,0,.82.62.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.74,71.53a5.9,5.9,0,0,1-.75-.75,6.63,6.63,0,0,0,.83.62.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.37,70.77a6.6,6.6,0,0,1-.74-.76,6.54,6.54,0,0,0,.82.62.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.67,70.29a6.52,6.52,0,0,1-.73-.76,5.89,5.89,0,0,0,.82.62.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.21,69.47a6.6,6.6,0,0,1-.73-.77,6.65,6.65,0,0,0,.82.63.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.87,69.07a5.26,5.26,0,0,1-.73-.77,5.9,5.9,0,0,0,.81.63A.08.08,0,0,1,22,69a.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.32,68.17a6.52,6.52,0,0,1-.73-.76,5.89,5.89,0,0,0,.82.62.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.38,67.53a5.88,5.88,0,0,1-.74-.76,7.8,7.8,0,0,0,.82.63.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.17,66.89a5.82,5.82,0,0,1-.73-.76,5.2,5.2,0,0,0,.82.62.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.2,66a5.88,5.88,0,0,1-.74-.76,5.7,5.7,0,0,0,.82.62.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.33,65.76a5.88,5.88,0,0,1-.74-.76,6.65,6.65,0,0,0,.82.63.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.38,64.65a6.6,6.6,0,0,1-.73-.77,5.28,5.28,0,0,0,.82.63.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.45,64.09a6.6,6.6,0,0,1-.73-.77,5.21,5.21,0,0,0,.81.63.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.72,63a6.45,6.45,0,0,1-.72-.77,6.6,6.6,0,0,0,.8.64.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.86,62.56a5.75,5.75,0,0,1-.71-.78,6.9,6.9,0,0,0,.8.65.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.38,61.68a5.66,5.66,0,0,1-.69-.79,5.94,5.94,0,0,0,.78.66.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.61,61a6.37,6.37,0,0,1-.69-.8,6,6,0,0,0,.78.67.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.84,60.59a6.43,6.43,0,0,1-.68-.82,5.36,5.36,0,0,0,.77.68.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.82,59.57a5.16,5.16,0,0,1-.66-.82,4.89,4.89,0,0,0,.76.69s0,.08,0,.11a.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.3,59.24a6.94,6.94,0,0,1-.65-.83,6.11,6.11,0,0,0,.75.7.08.08,0,1,1-.08.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,57.87a6.22,6.22,0,0,1-.64-.84,7,7,0,0,0,.74.71.08.08,0,0,1,0,.11.1.1,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.16,57.39a5.49,5.49,0,0,1-.63-.85,5.5,5.5,0,0,0,.73.72.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,56.45a5.6,5.6,0,0,1-.61-.86,6.27,6.27,0,0,0,.71.74.08.08,0,0,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.1,56.23a6.78,6.78,0,0,1-.61-.86,7.15,7.15,0,0,0,.71.74.08.08,0,0,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.84,55.64a5.64,5.64,0,0,1-.59-.88,5.68,5.68,0,0,0,.7.76.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.17,54.79a6.08,6.08,0,0,1-.57-.89,6.34,6.34,0,0,0,.68.77.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.72,54a5.8,5.8,0,0,1-.56-.9,5.09,5.09,0,0,0,.67.78.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.92,53.34a6.59,6.59,0,0,1-.54-.91,6.47,6.47,0,0,0,.65.8s0,.07,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.45,52.21a6.54,6.54,0,0,1-.51-.93,6.52,6.52,0,0,0,.62.82.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.27,51.41a5.59,5.59,0,0,1-.47-.95,6.6,6.6,0,0,0,.59.84.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.89,50.18a5.44,5.44,0,0,1-.43-1,6.3,6.3,0,0,0,.55.86.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.59,49.49a6.7,6.7,0,0,1-.38-1,6,6,0,0,0,.51.9.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.56,48.72a6.78,6.78,0,0,1-.3-1,6,6,0,0,0,.44.92.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.89,47.66a5.76,5.76,0,0,1-.28-1,5.71,5.71,0,0,0,.42.94.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.15,47A6.5,6.5,0,0,1,26,45.94a6.26,6.26,0,0,0,.27,1,.08.08,0,1,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,45.75a6.46,6.46,0,0,1-.11-1,6,6,0,0,0,.26,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.19,45.7a6.07,6.07,0,0,1,0-1.05,5.69,5.69,0,0,0,.12,1,.08.08,0,0,1-.05.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.91,44.78A6,6,0,0,1,27,43.73a6.69,6.69,0,0,0,.08,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.51,43.85a6.14,6.14,0,0,1,.1-1.06,5.77,5.77,0,0,0,.06,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,43.77a6,6,0,0,1,.21-1,6,6,0,0,0-.05,1,.1.1,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.39,42.64a5.53,5.53,0,0,1,.22-1,5.88,5.88,0,0,0-.06,1,.1.1,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.65,42.33a6.69,6.69,0,0,1,.3-1,6.24,6.24,0,0,0-.14,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,41.3a5.4,5.4,0,0,1,.31-1,5.14,5.14,0,0,0-.15,1,.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.44,41a6.5,6.5,0,0,1,.37-1,6.5,6.5,0,0,0-.21,1,.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.08,40.07a6.13,6.13,0,0,1,.37-1,6.26,6.26,0,0,0-.21,1,.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.46,39.29a6.47,6.47,0,0,1,.36-1,6.22,6.22,0,0,0-.2,1,.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.23,38.68a6.13,6.13,0,0,1,.37-1,6.15,6.15,0,0,0-.21,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.08,38.84a7.17,7.17,0,0,1,.4-1,6.3,6.3,0,0,0-.24,1,.09.09,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.62,37.93a7.17,7.17,0,0,1,.4-1,6.3,6.3,0,0,0-.24,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.8,37.43a5.72,5.72,0,0,1,.39-1,6.05,6.05,0,0,0-.24,1,.07.07,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.77,35.4a6.13,6.13,0,0,1,.37-1,6,6,0,0,0-.21,1,.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.49,34.39a6.13,6.13,0,0,1,.37-1,6,6,0,0,0-.21,1,.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,36a5.24,5.24,0,0,1,.38-1,5.29,5.29,0,0,0-.22,1,.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.82,34.63a5.8,5.8,0,0,1,.37-1,6.15,6.15,0,0,0-.21,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.72,34.1a6.13,6.13,0,0,1,.37-1,6.15,6.15,0,0,0-.21,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.48,35.26a6.13,6.13,0,0,1,.37-1,6.15,6.15,0,0,0-.21,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.9,35a6.13,6.13,0,0,1,.37-1,6.5,6.5,0,0,0-.21,1,.09.09,0,0,1-.08.08A.08.08,0,0,1,32.9,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,35.93a7.26,7.26,0,0,1,.38-1,7.49,7.49,0,0,0-.22,1,.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.82,35.33a6.15,6.15,0,0,1,.38-1,5.22,5.22,0,0,0-.22,1,.07.07,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.79,36.48a6.18,6.18,0,0,1,.39-1,6.25,6.25,0,0,0-.23,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.57,36.61a5.34,5.34,0,0,1,.38-1,5.29,5.29,0,0,0-.22,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.15,37.86a7.12,7.12,0,0,1,.38-1,7.49,7.49,0,0,0-.22,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.48,39.91a5.74,5.74,0,0,1,.4-1,6.3,6.3,0,0,0-.24,1,.07.07,0,0,1-.09.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.39,41.53a6.42,6.42,0,0,1,.38-1,6.2,6.2,0,0,0-.22,1,.1.1,0,0,1-.09.08.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.33,43.18a6.58,6.58,0,0,1,.31-1,6.28,6.28,0,0,0-.15,1,.1.1,0,0,1-.07.09.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.27,46.05a5.79,5.79,0,0,1,.17-1,7.11,7.11,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.51,47.66a5.28,5.28,0,0,1,0-1,5.46,5.46,0,0,0,.16,1,.08.08,0,0,1-.05.11.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.1,48.9a6.29,6.29,0,0,1-.13-1,7,7,0,0,0,.28,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.7,50.51a6.64,6.64,0,0,1-.26-1,6.64,6.64,0,0,0,.4.94.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.24,51.93a5.75,5.75,0,0,1-.38-1,5.9,5.9,0,0,0,.51.89.09.09,0,0,1-.13.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.6,53.28a5.59,5.59,0,0,1-.47-1,6.72,6.72,0,0,0,.59.85.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.34,54.32a6.51,6.51,0,0,1-.53-.91,5.58,5.58,0,0,0,.65.79.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.52,55.66a6.67,6.67,0,0,1-.58-.88,6.3,6.3,0,0,0,.69.76.09.09,0,0,1,0,.11.11.11,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.21,57a6.88,6.88,0,0,1-.62-.86,6.22,6.22,0,0,0,.72.73.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.18,58.38a6.22,6.22,0,0,1-.64-.84,7,7,0,0,0,.74.71.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.17,59.08a6.49,6.49,0,0,1-.66-.83,5.53,5.53,0,0,0,.76.7.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.79,60.1a6.25,6.25,0,0,1-.67-.81,6.86,6.86,0,0,0,.76.68.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.47,61.39a6.35,6.35,0,0,1-.68-.81,5.36,5.36,0,0,0,.77.67.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.92,62.29a6.49,6.49,0,0,1-.71-.79,6.74,6.74,0,0,0,.79.66.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.57,63.39a6.5,6.5,0,0,1-.71-.78,7.86,7.86,0,0,0,.8.65.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.81,64.11a6.45,6.45,0,0,1-.72-.77,6.6,6.6,0,0,0,.8.64.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.81,65.27a5.26,5.26,0,0,1-.73-.77,5.21,5.21,0,0,0,.81.63.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.68,65.94a6.51,6.51,0,0,1-.72-.77,5.21,5.21,0,0,0,.81.63.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,67a5.77,5.77,0,0,1-.72-.77,6,6,0,0,0,.81.64.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.55,66.35a6.6,6.6,0,0,1-.73-.77,5.72,5.72,0,0,0,.81.63.08.08,0,1,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.18,66.91a5.7,5.7,0,0,1-.72-.76,6.79,6.79,0,0,0,.81.63.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.82,66.63a7.39,7.39,0,0,1-.72-.77,6,6,0,0,0,.81.64.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,65.63a5.77,5.77,0,0,1-.72-.77,6.6,6.6,0,0,0,.8.64.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.24,64.81a5.84,5.84,0,0,1-.72-.78,6.6,6.6,0,0,0,.8.64.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,64.07a5.75,5.75,0,0,1-.71-.78,6.81,6.81,0,0,0,.79.65.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.87,62.9a5.21,5.21,0,0,1-.7-.79,6,6,0,0,0,.79.66.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.75,61.66a5.64,5.64,0,0,1-.69-.8,6.92,6.92,0,0,0,.78.67.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.79,59.93a6.24,6.24,0,0,1-.66-.82,5.45,5.45,0,0,0,.76.69.08.08,0,0,1-.09.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.34,57.7a5.49,5.49,0,0,1-.63-.85,5.5,5.5,0,0,0,.73.72.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.62,54.69a5.43,5.43,0,0,1-.57-.9,5.76,5.76,0,0,0,.68.78.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.12,52.05a6.41,6.41,0,0,1-.48-.94,5.68,5.68,0,0,0,.6.83.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.59,50.06a5.69,5.69,0,0,1-.37-1,6,6,0,0,0,.5.9.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,47.47a5.41,5.41,0,0,1-.22-1,5.77,5.77,0,0,0,.36,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.7,46a6.39,6.39,0,0,1-.08-1.06,6.09,6.09,0,0,0,.23,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.06,44A6.28,6.28,0,0,1,25,43a6.58,6.58,0,0,0,.17,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.9,42.53A6,6,0,0,1,26,41.48a5.8,5.8,0,0,0,0,1,.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.33,41a6.71,6.71,0,0,1,.22-1,5.88,5.88,0,0,0-.06,1,.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.7,39.45a6.65,6.65,0,0,1,.28-1,6.15,6.15,0,0,0-.12,1,.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08S26.7,39.45,26.7,39.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.33,37.7a6.14,6.14,0,0,1,.31-1,6,6,0,0,0-.15,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.83,36.08a5.31,5.31,0,0,1,.32-1,6.05,6.05,0,0,0-.16,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.53,34.67a6.17,6.17,0,0,1,.33-1,6.27,6.27,0,0,0-.17,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.25,33.26a5.37,5.37,0,0,1,.35-1,5.42,5.42,0,0,0-.19,1,.07.07,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.12,32.06a5.27,5.27,0,0,1,.35-1,5.17,5.17,0,0,0-.19,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.56,30.76a5.37,5.37,0,0,1,.35-1,5.27,5.27,0,0,0-.19,1,.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.61,30A5.27,5.27,0,0,1,32,29a5.17,5.17,0,0,0-.19,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.87,28.71a6.19,6.19,0,0,1,.34-1,6.32,6.32,0,0,0-.18,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.89,28.1a5.71,5.71,0,0,1,.34-1,5.33,5.33,0,0,0-.18,1,.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.61,26.48a6.29,6.29,0,0,1,.33-1,6.27,6.27,0,0,0-.17,1,.08.08,0,0,1-.07.09.08.08,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.4,25.29a6.26,6.26,0,0,1,.31-1,6,6,0,0,0-.15,1,.08.08,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.24,24a6.69,6.69,0,0,1,.3-1,6.24,6.24,0,0,0-.14,1,.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.42,22.72a5.72,5.72,0,0,1,.3-1,6.24,6.24,0,0,0-.14,1,.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.07,21.86a5.7,5.7,0,0,1,.29-1,5.28,5.28,0,0,0-.13,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,21.66a6.65,6.65,0,0,1,.28-1,6.15,6.15,0,0,0-.12,1,.07.07,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.31,22.13a6.46,6.46,0,0,1,.25-1,6,6,0,0,0-.09,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.86,20.6a6.62,6.62,0,0,1,.26-1,6.07,6.07,0,0,0-.1,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.09,19.36a5.55,5.55,0,0,1,.26-1,5.18,5.18,0,0,0-.1,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,19.74a6.74,6.74,0,0,1,.25-1,7.42,7.42,0,0,0-.09,1,.08.08,0,1,1-.15,0A0,0,0,0,1,39,19.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.41,18.72a5.62,5.62,0,0,1,.24-1,5.92,5.92,0,0,0-.08,1,.1.1,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,20.35a5.71,5.71,0,0,1,.22-1,5.88,5.88,0,0,0-.06,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.4,19.34a5.71,5.71,0,0,1,.23-1,5.2,5.2,0,0,0-.07,1,.08.08,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41,18.62a6.71,6.71,0,0,1,.22-1,5.88,5.88,0,0,0-.06,1,.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.28,20.12a6.84,6.84,0,0,1,.2-1,7.21,7.21,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42,19.17a6,6,0,0,1,.19-1,6,6,0,0,0,0,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.56,20a5.79,5.79,0,0,1,.17-1,6,6,0,0,0,0,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.14,19.09a5.79,5.79,0,0,1,.17-1,7.11,7.11,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.42,20.64a6.86,6.86,0,0,1,.14-1,5.76,5.76,0,0,0,0,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,21.42a7.18,7.18,0,0,1,.12-1.05,6.93,6.93,0,0,0,0,1,.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.55,22.3a6,6,0,0,1,.09-1.05,5.64,5.64,0,0,0,.07,1,.08.08,0,0,1-.16.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.86,23.58a6.11,6.11,0,0,1,0-1,6.78,6.78,0,0,0,.1,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.23,24.82a5.34,5.34,0,0,1,0-1.06,5.58,5.58,0,0,0,.13,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.3,26.43a6.14,6.14,0,0,1,0-1.05,6.45,6.45,0,0,0,.16,1,.1.1,0,0,1-.05.11.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.07,27.48a6.2,6.2,0,0,1,0-1.05,5.47,5.47,0,0,0,.19,1,.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.18,28.68a6.23,6.23,0,0,1,0-1.05,5.47,5.47,0,0,0,.2,1,.09.09,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.29,30.38a5.48,5.48,0,0,1-.1-1.05,5.42,5.42,0,0,0,.25,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.21,31.77a5.45,5.45,0,0,1-.07-1.06,5.49,5.49,0,0,0,.22,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.25,32.87a6.38,6.38,0,0,1-.09-1,6.35,6.35,0,0,0,.24,1,.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.88,34A6.36,6.36,0,0,1,48.81,33,6.09,6.09,0,0,0,49,34a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.37,35.37a6.32,6.32,0,0,1,0-1.06,6.45,6.45,0,0,0,.18,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.55,36.6a6.14,6.14,0,0,1,0-1.05,6.46,6.46,0,0,0,.15,1,.09.09,0,0,1-.05.11.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.33,37.59a7.21,7.21,0,0,1,0-1.05,6.77,6.77,0,0,0,.12,1,.09.09,0,0,1-.05.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.66,39.08a5.8,5.8,0,0,1,.13-1,5.74,5.74,0,0,0,0,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.27,40.56a5.63,5.63,0,0,1,.22-1,6.05,6.05,0,0,0-.06,1,.1.1,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.68,41.8a6.24,6.24,0,0,1,.29-1,6,6,0,0,0-.13,1,.08.08,0,0,1-.08.09.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.52,42.88a5.29,5.29,0,0,1,.36-1,6.15,6.15,0,0,0-.21,1,.07.07,0,0,1-.08.08.07.07,0,0,1-.08-.07S55.51,42.88,55.52,42.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.32,44.86a7,7,0,0,1,.46-1,6.44,6.44,0,0,0-.3,1,.08.08,0,0,1-.09.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.24,46.29a5.51,5.51,0,0,1,.53-.92,6.38,6.38,0,0,0-.38,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.61,47.85A5.32,5.32,0,0,1,58.2,47a6,6,0,0,0-.44.93.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.23,49.48a7,7,0,0,1,.64-.85,6.19,6.19,0,0,0-.49.91.08.08,0,0,1-.1.05.07.07,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.34,50.91a6.12,6.12,0,0,1,.67-.82,6.23,6.23,0,0,0-.53.88.07.07,0,0,1-.1.05.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.55,51.68a5.28,5.28,0,0,1,.69-.79,5.68,5.68,0,0,0-.55.86.09.09,0,0,1-.1,0,.08.08,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.2,53.19a5.86,5.86,0,0,1,.72-.77,5.12,5.12,0,0,0-.58.85.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.59,54.58a6.59,6.59,0,0,1,.73-.76,5.81,5.81,0,0,0-.59.84.07.07,0,0,1-.1,0,.09.09,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.06,55.63a7.63,7.63,0,0,1,.74-.76,6.51,6.51,0,0,0-.6.84.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.31,56.84a6.59,6.59,0,0,1,.73-.76,6.8,6.8,0,0,0-.6.84.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.11,58.64a6,6,0,0,1,.7-.79,5,5,0,0,0-.55.86.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.53,59.78a5.84,5.84,0,0,1,.73-.76,5.12,5.12,0,0,0-.59.84.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.17,60.77a6.5,6.5,0,0,1,.78-.71,6.71,6.71,0,0,0-.65.8.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.59,61.79a6.24,6.24,0,0,1,.82-.66,5.45,5.45,0,0,0-.69.76.08.08,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.36,63a5.67,5.67,0,0,1,.86-.62,6.31,6.31,0,0,0-.74.72.08.08,0,1,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.91,64.29a6,6,0,0,1,.9-.55,5.65,5.65,0,0,0-.78.66.09.09,0,0,1-.12,0,.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.76,65.33a6,6,0,0,1,.92-.52,6.46,6.46,0,0,0-.81.63.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.47,66.4a6,6,0,0,1,.94-.48,5.78,5.78,0,0,0-.84.6.08.08,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,67.32a6.28,6.28,0,0,1,1-.43,4.72,4.72,0,0,0-.86.56.08.08,0,1,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.94,67.84a5.9,5.9,0,0,1,1-.46A7.47,7.47,0,0,0,64,68a.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,68.45A5.13,5.13,0,0,1,66,68a5.8,5.8,0,0,0-.87.54.09.09,0,0,1-.12,0,.08.08,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.55,68.91a6.41,6.41,0,0,1,1-.45,8,8,0,0,0-.86.57.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.34,69.52a5.81,5.81,0,0,1,1-.42,5.8,5.8,0,0,0-.87.54.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.16,69.93a5.46,5.46,0,0,1,1-.4,5.94,5.94,0,0,0-.89.52.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.6,70.49a5.49,5.49,0,0,1,1-.44,7.48,7.48,0,0,0-.86.56.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.56,71.1a6.62,6.62,0,0,1,1-.4,5.55,5.55,0,0,0-.88.53.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.26,71.5a8.19,8.19,0,0,1,1-.44,5.73,5.73,0,0,0-.85.57.08.08,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.11,72.24a6.77,6.77,0,0,1,1-.42,6.79,6.79,0,0,0-.87.55.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.78,73a5.53,5.53,0,0,1,1-.43,5.84,5.84,0,0,0-.87.55.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.16,73.84a6.41,6.41,0,0,1,.94-.48,6.65,6.65,0,0,0-.84.6.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.92,73.06a6.51,6.51,0,0,1,.95-.48,5.78,5.78,0,0,0-.84.6.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,73.48a5.61,5.61,0,0,1,1-.46,6.67,6.67,0,0,0-.85.58.09.09,0,0,1-.12,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.57,72.28a5.93,5.93,0,0,1,1-.46,5.29,5.29,0,0,0-.85.58.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.75,71.54a6,6,0,0,1,.94-.48,6.65,6.65,0,0,0-.84.6.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.17,70.49a6.44,6.44,0,0,1,.95-.47,6,6,0,0,0-.84.59.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.43,69.78a5.69,5.69,0,0,1,.94-.5,5.23,5.23,0,0,0-.83.61.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.53,68.66a6.46,6.46,0,0,1,.93-.5,6.52,6.52,0,0,0-.82.62.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.17,67.37a5.64,5.64,0,0,1,.88-.59,6.37,6.37,0,0,0-.76.7.09.09,0,0,1-.11,0,.11.11,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.34,65.39a5.12,5.12,0,0,1,.82-.67,5.45,5.45,0,0,0-.69.76.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.09,63.49a5.81,5.81,0,0,1,.75-.74,5.81,5.81,0,0,0-.61.82.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.63,61.09a6.66,6.66,0,0,1,.68-.8,6.22,6.22,0,0,0-.54.87.09.09,0,0,1-.1.05.09.09,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.91,59.4a5.86,5.86,0,0,1,.72-.77,6.4,6.4,0,0,0-.58.85.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.78,57.66a5.9,5.9,0,0,1,.75-.75,6.52,6.52,0,0,0-.61.83.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,55.43a6.59,6.59,0,0,1,.73-.76,5.81,5.81,0,0,0-.59.84.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.65,53.22a6,6,0,0,1,.71-.79,5.56,5.56,0,0,0-.56.87.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.81,51.05a5.38,5.38,0,0,1,.66-.82,5,5,0,0,0-.51.89.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.91,47.74a6.64,6.64,0,0,1,.55-.89,5.41,5.41,0,0,0-.4.94.08.08,0,0,1-.1.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.37,45.93a6.4,6.4,0,0,1,.49-.94,6.17,6.17,0,0,0-.33,1,.08.08,0,0,1-.09.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.32,43.21a6.11,6.11,0,0,1,.36-1,6,6,0,0,0-.2,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.43,40.48a6.71,6.71,0,0,1,.22-1,6,6,0,0,0-.06,1,.08.08,0,0,1-.07.1.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.52,37a5.25,5.25,0,0,1,0-1.05,6.47,6.47,0,0,0,.14,1,.08.08,0,0,1-.05.11.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.26,34.52a5.48,5.48,0,0,1-.13-1.05,5.15,5.15,0,0,0,.28,1,.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.75,31.76a6.53,6.53,0,0,1-.24-1,6.11,6.11,0,0,0,.39,1,.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.38,29.56a5.52,5.52,0,0,1-.25-1,5.4,5.4,0,0,0,.39.95.11.11,0,0,1,0,.12.1.1,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.3,28a6.28,6.28,0,0,1-.21-1,5.77,5.77,0,0,0,.36,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.29,26.6a5.47,5.47,0,0,1-.16-1,5.1,5.1,0,0,0,.31,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.48,26.23a5.58,5.58,0,0,1-.13-1.05,6.26,6.26,0,0,0,.27,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.88,26.77a5.58,5.58,0,0,1-.13-1.05,6.28,6.28,0,0,0,.28,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,27.79a5.57,5.57,0,0,1-.16-1.05,5.1,5.1,0,0,0,.31,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.08,28.85a5.5,5.5,0,0,1-.17-1,5.25,5.25,0,0,0,.32,1,.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,30.64a6.48,6.48,0,0,1-.18-1,6.15,6.15,0,0,0,.33,1,.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.9,34.16a6.32,6.32,0,0,1,0-1.06,5.47,5.47,0,0,0,.19,1,.1.1,0,0,1-.05.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.8,37.4a5.94,5.94,0,0,1,.1-1,5.71,5.71,0,0,0,.06,1,.08.08,0,0,1-.06.1.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.45,39.57a6.84,6.84,0,0,1,.19-1,6,6,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.66,42.64a6.19,6.19,0,0,1,.34-1,6.32,6.32,0,0,0-.18,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.67,45.85a5.53,5.53,0,0,1,.48-.94,5.29,5.29,0,0,0-.33,1,.07.07,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.46,49a6.75,6.75,0,0,1,.59-.88,6.51,6.51,0,0,0-.44.93.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.92,50.56a6.05,6.05,0,0,1,.65-.84,6.74,6.74,0,0,0-.5.9.08.08,0,0,1-.1.06.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.26,52.74a5.49,5.49,0,0,1,.7-.8,5.07,5.07,0,0,0-.55.87.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.73,54.23a5.31,5.31,0,0,1,.71-.78,7,7,0,0,0-.57.85.1.1,0,0,1-.11.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,56a5.87,5.87,0,0,1,.73-.76,6.33,6.33,0,0,0-.59.83.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.91,57.84a5.9,5.9,0,0,1,.75-.75,6.52,6.52,0,0,0-.61.83.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.93,59.07a5.84,5.84,0,0,1,.74-.75,5.59,5.59,0,0,0-.6.83.08.08,0,0,1-.11,0s-.05-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.6,60.56a6.8,6.8,0,0,1,.7-.8,6.31,6.31,0,0,0-.55.87.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.81,61.59a6.82,6.82,0,0,1,.67-.82,6.24,6.24,0,0,0-.52.89.09.09,0,0,1-.11.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.32,62.86a5.4,5.4,0,0,1,.68-.81,5.08,5.08,0,0,0-.54.88.08.08,0,0,1-.1,0,.1.1,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.44,64.12a5.37,5.37,0,0,1,.72-.78,6.11,6.11,0,0,0-.58.85.1.1,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.19,65.26a6.69,6.69,0,0,1,.73-.77,5.57,5.57,0,0,0-.58.85.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.21,66.54A5.81,5.81,0,0,1,57,65.8a5.81,5.81,0,0,0-.61.82.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,67.58a5.23,5.23,0,0,1,.76-.73,5.9,5.9,0,0,0-.63.81.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.73,68.58a6.25,6.25,0,0,1,.81-.67,6.15,6.15,0,0,0-.68.77.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.1,69.74a5.73,5.73,0,0,1,.81-.68,5.43,5.43,0,0,0-.68.78.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.17,70.19a5.42,5.42,0,0,1,.84-.63,7,7,0,0,0-.72.72.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.46,71a6.22,6.22,0,0,1,.84-.64,7,7,0,0,0-.71.74.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.92,72a6.22,6.22,0,0,1,.86-.62,6.31,6.31,0,0,0-.74.72.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.37,72.8a6.22,6.22,0,0,1,.87-.61,6.38,6.38,0,0,0-.75.71s-.07,0-.11,0a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.11,73.41a5.57,5.57,0,0,1,.88-.58,7.32,7.32,0,0,0-.77.68.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.23,73.54a5.72,5.72,0,0,1,.9-.55,5.65,5.65,0,0,0-.78.66.09.09,0,0,1-.12,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.83,72.66a6,6,0,0,1,.89-.56,7.23,7.23,0,0,0-.78.67.08.08,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.54,71.46a7,7,0,0,1,.89-.57,6.56,6.56,0,0,0-.78.68s-.07,0-.11,0a.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.52,71.16a6,6,0,0,1,.9-.54,6.36,6.36,0,0,0-.79.65.07.07,0,0,1-.11,0s0-.07,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.35,69.06a6,6,0,0,1,.89-.56,6.27,6.27,0,0,0-.77.67.09.09,0,0,1-.12,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.9,67.47a5.23,5.23,0,0,1,.9-.54,6.36,6.36,0,0,0-.79.65.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.44,65.34a6,6,0,0,1,.9-.55,6.42,6.42,0,0,0-.79.66.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.28,63.33a5.12,5.12,0,0,1,.83-.66,6.11,6.11,0,0,0-.7.75.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.12,61.26a5.81,5.81,0,0,1,.75-.74,5.81,5.81,0,0,0-.61.82.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.22,59.62a6.69,6.69,0,0,1,.7-.79,6.31,6.31,0,0,0-.56.86.08.08,0,0,1-.1.05.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.31,57.65a5.29,5.29,0,0,1,.73-.76,6.33,6.33,0,0,0-.59.83.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,55.83a6.66,6.66,0,0,1,.74-.76,5.89,5.89,0,0,0-.6.84.07.07,0,0,1-.1,0,.1.1,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.45,53.06a5.87,5.87,0,0,1,.73-.76,5.81,5.81,0,0,0-.59.84.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.52,49.5a5.38,5.38,0,0,1,.66-.82,6.15,6.15,0,0,0-.52.88.08.08,0,0,1-.1.06.09.09,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.79,46.43a6.15,6.15,0,0,1,.57-.89,6,6,0,0,0-.41.94.09.09,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.23,41.35a5.77,5.77,0,0,1,.35-1,6.06,6.06,0,0,0-.19,1,.08.08,0,0,1-.08.08.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.34,38a6.84,6.84,0,0,1,.17-1,7,7,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.86,35.21a5.29,5.29,0,0,1,0-1.05,6.45,6.45,0,0,0,.17,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.9,32.44a6.29,6.29,0,0,1-.18-1,5.94,5.94,0,0,0,.33,1,.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.67,29.73a6.5,6.5,0,0,1-.29-1,6.24,6.24,0,0,0,.43.94.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,26.6a7.87,7.87,0,0,1-.19-1,9.2,9.2,0,0,0,.33,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.15,23.17a5.43,5.43,0,0,1,0-1.06,5.47,5.47,0,0,0,.19,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.77,20.52a5.25,5.25,0,0,1,0-1.05,4.9,4.9,0,0,0,.11,1,.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.11,19.36a5.15,5.15,0,0,1,.07-1.05,5.69,5.69,0,0,0,.09,1,.09.09,0,0,1-.06.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.86,17.7A6,6,0,0,1,51,16.65a5.64,5.64,0,0,0,.07,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.41,17.59a5.94,5.94,0,0,1,.1-1.05,5.71,5.71,0,0,0,.06,1,.08.08,0,0,1-.06.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,18.25a6.15,6.15,0,0,1,.09-1.06,5.75,5.75,0,0,0,.07,1,.09.09,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.72,20.1a6.11,6.11,0,0,1,.05-1.05,6.77,6.77,0,0,0,.11,1,.08.08,0,1,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.4,21.73a6.12,6.12,0,0,1,0-1.05,5.46,5.46,0,0,0,.15,1,.08.08,0,0,1-.05.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.21,25.9a6.4,6.4,0,0,1-.16-1,5.9,5.9,0,0,0,.31,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.43,29.8a5.41,5.41,0,0,1-.35-1,6,6,0,0,0,.48.91.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.75,33.23a6.4,6.4,0,0,1-.19-1,6.18,6.18,0,0,0,.34,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.8,35.94a6.18,6.18,0,0,1,0-1,6.45,6.45,0,0,0,.17,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.2,39.08a5.64,5.64,0,0,1,.19-1,5.85,5.85,0,0,0,0,1,.09.09,0,0,1-.07.1.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,41a6.58,6.58,0,0,1,.31-1,7.62,7.62,0,0,0-.15,1,.07.07,0,0,1-.07.08A.08.08,0,0,1,58,41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.87,43.56a7,7,0,0,1,.44-1,6.48,6.48,0,0,0-.29,1,.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.11,45.22a6.39,6.39,0,0,1,.52-.93,5.45,5.45,0,0,0-.36,1,.08.08,0,0,1-.1.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.16,47.66a6.08,6.08,0,0,1,.6-.87,5.57,5.57,0,0,0-.45.92.08.08,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.48,50.21a5.92,5.92,0,0,1,.66-.82,6.66,6.66,0,0,0-.52.89.08.08,0,0,1-.1,0,.09.09,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.06,52.94a6.73,6.73,0,0,1,.71-.78,7.52,7.52,0,0,0-.57.86.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.38,56a5.25,5.25,0,0,1,.74-.75,5.59,5.59,0,0,0-.6.83.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.81,58.5a5.81,5.81,0,0,1,.72-.76,5.73,5.73,0,0,0-.58.84.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.9,60.53a6,6,0,0,1,.7-.79,6.31,6.31,0,0,0-.56.86.08.08,0,0,1-.1,0,.1.1,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.55,62.53a5.81,5.81,0,0,1,.75-.74,7.68,7.68,0,0,0-.62.82.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.61,64.24a5,5,0,0,1,.84-.64,5.5,5.5,0,0,0-.72.73.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.29,65.21a6.76,6.76,0,0,1,.88-.59,6.3,6.3,0,0,0-.76.69.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.74,66a5.27,5.27,0,0,1,.88-.57,6.34,6.34,0,0,0-.77.68s-.07,0-.11,0a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.73,66.05a6,6,0,0,1,.9-.55,6.42,6.42,0,0,0-.79.66.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.84,66a5.6,5.6,0,0,1,.86-.61,7.15,7.15,0,0,0-.74.71.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.92,65a6.22,6.22,0,0,1,.86-.62,5.53,5.53,0,0,0-.73.72.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.23,63.85a8.08,8.08,0,0,1,.86-.62,6.22,6.22,0,0,0-.73.72.09.09,0,0,1-.11,0,.1.1,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.07,61.65a8.49,8.49,0,0,1,.78-.71,6.6,6.6,0,0,0-.64.8.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.73,58.83a5.9,5.9,0,0,1,.7-.79,6.31,6.31,0,0,0-.56.86.09.09,0,0,1-.1.05.08.08,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.47,53.51a6,6,0,0,1,.72-.78,5.05,5.05,0,0,0-.57.85.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.16,49a6.08,6.08,0,0,1,.62-.86,6.67,6.67,0,0,0-.47.92.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.28,43.35a5.72,5.72,0,0,1,.39-1,5.16,5.16,0,0,0-.23,1,.08.08,0,0,1-.09.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.73,40.2a5.71,5.71,0,0,1,.22-1,5.88,5.88,0,0,0-.06,1,.1.1,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,37.74a7.12,7.12,0,0,1,.07-1.05,5.69,5.69,0,0,0,.09,1,.08.08,0,0,1-.05.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.6,33.45a5.45,5.45,0,0,1-.2-1,4.84,4.84,0,0,0,.35,1,.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.61,29.17a5.62,5.62,0,0,1-.32-1,6,6,0,0,0,.45.92.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.42,22a6.25,6.25,0,0,1,0-1.06,6.42,6.42,0,0,0,.15,1,.07.07,0,0,1,0,.1s-.08,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.13,17a6,6,0,0,1,.1-1,7,7,0,0,0,.05,1,.07.07,0,0,1-.05.09.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,14.84a5.79,5.79,0,0,1,.15-1,5.77,5.77,0,0,0,0,1,.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.79,13.87a5.9,5.9,0,0,1,.16-1,5.9,5.9,0,0,0,0,1,.07.07,0,0,1-.07.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.74,13.12a6.84,6.84,0,0,1,.17-1,7.11,7.11,0,0,0,0,1,.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.66,12.81a6,6,0,0,1,.18-1,6,6,0,0,0,0,1,.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.57,12.59a6.62,6.62,0,0,1,.19-1,7,7,0,0,0,0,1,.08.08,0,0,1-.06.1s-.09,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.88,12.42a5.63,5.63,0,0,1,.2-1,7.21,7.21,0,0,0,0,1,.08.08,0,0,1-.07.09.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41,13.05a5.7,5.7,0,0,1,.21-1,6,6,0,0,0,0,1,.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.52,14.41a6.72,6.72,0,0,1,.23-1,7.33,7.33,0,0,0-.07,1,.08.08,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.13,15.7a6.73,6.73,0,0,1,.24-1,6.11,6.11,0,0,0-.08,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.09-.07S40.13,15.71,40.13,15.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.67,17.17a5.73,5.73,0,0,1,.24-1,6.08,6.08,0,0,0-.08,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.78,21A5.62,5.62,0,0,1,39,20a5.92,5.92,0,0,0-.08,1,.1.1,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.36,23.52a5.45,5.45,0,0,1,.27-1,5.13,5.13,0,0,0-.11,1,.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.11,24.22a6.26,6.26,0,0,1,.31-1,6.28,6.28,0,0,0-.15,1,.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.71,25.43a6.15,6.15,0,0,1,.32-1,6.05,6.05,0,0,0-.16,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.47,25.47a6.63,6.63,0,0,1,.33-1,6.38,6.38,0,0,0-.17,1,.08.08,0,0,1-.07.08.08.08,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.85,27.05a5.33,5.33,0,0,1,.33-1,5.2,5.2,0,0,0-.17,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07S30.85,27.06,30.85,27.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.68,26.18a6.17,6.17,0,0,1,.33-1,6.09,6.09,0,0,0-.17,1,.08.08,0,0,1-.07.08.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.66,26.66a6.19,6.19,0,0,1,.34-1,6.13,6.13,0,0,0-.18,1,.07.07,0,0,1-.07.08.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.66,26.89a5.33,5.33,0,0,1,.33-1,5.2,5.2,0,0,0-.17,1,.09.09,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.67,27A6.26,6.26,0,0,1,35,26a6.17,6.17,0,0,0-.15,1,.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.68,27.34a5.37,5.37,0,0,1,.29-1,7.11,7.11,0,0,0-.13,1,.07.07,0,0,1-.07.08s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.8,26.91a6.46,6.46,0,0,1,.23-1,7.09,7.09,0,0,0-.07,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,25.13a6,6,0,0,1,.19-1,6,6,0,0,0,0,1s0,.09-.07.09a.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.2,25a6.85,6.85,0,0,1,.16-1,5.79,5.79,0,0,0,0,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.53,24.46a7.17,7.17,0,0,1,.13-1.05,5.83,5.83,0,0,0,0,1,.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.44,22.85a5.19,5.19,0,0,1,.11-1.05,5.8,5.8,0,0,0,0,1,.07.07,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.43,23.76a6,6,0,0,1,.1-1.05,5.77,5.77,0,0,0,.06,1,.08.08,0,0,1-.06.09.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.51,22.9a7,7,0,0,1,.14-1,7,7,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.54,22.66a5.9,5.9,0,0,1,.17-1.05,5.08,5.08,0,0,0,0,1,.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.58,23.34a6.71,6.71,0,0,1,.22-1,7.29,7.29,0,0,0-.06,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.09-.07S39.58,23.35,39.58,23.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,23.21a5.7,5.7,0,0,1,.29-1,5.28,5.28,0,0,0-.13,1,.08.08,0,0,1-.08.09.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.27,22.43a6.26,6.26,0,0,1,.31-1,6.28,6.28,0,0,0-.15,1,.07.07,0,0,1-.07.08.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,21.86a5.61,5.61,0,0,1,.3-1,5.31,5.31,0,0,0-.14,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.3,22.36a6.25,6.25,0,0,1,.3-1,7.21,7.21,0,0,0-.14,1,.08.08,0,0,1-.07.09.08.08,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.48,20.62a6.67,6.67,0,0,1,.29-1,6.19,6.19,0,0,0-.13,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.13,18.14a5.63,5.63,0,0,1,.2-1,5.92,5.92,0,0,0,0,1,.08.08,0,0,1-.07.1.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.15,18.57a6.83,6.83,0,0,1,.14-1.05,7,7,0,0,0,0,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.48,18a7.17,7.17,0,0,1,.13-1.05,5.83,5.83,0,0,0,0,1,.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.82,18.44a6.06,6.06,0,0,1,.09-1.06,6.88,6.88,0,0,0,.06,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.67,19a5.15,5.15,0,0,1,.07-1.05,6.8,6.8,0,0,0,.08,1,.08.08,0,0,1,0,.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,19.61a6,6,0,0,1,.07-1.05,5.69,5.69,0,0,0,.09,1,.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.61,18.79a5.19,5.19,0,0,1,.11-1.05,5,5,0,0,0,.05,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.09,17.39a5.79,5.79,0,0,1,.17-1,5,5,0,0,0,0,1,.09.09,0,0,1-.07.1.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.4,17.27a5.75,5.75,0,0,1,.26-1,7.47,7.47,0,0,0-.1,1,.08.08,0,0,1-.07.09.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.27,18.45a6.08,6.08,0,0,1,.28-1,6,6,0,0,0-.12,1,.07.07,0,0,1-.07.08s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.84,19.89a6.46,6.46,0,0,1,.25-1,6,6,0,0,0-.09,1,.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,21.64a5.71,5.71,0,0,1,.23-1,5.1,5.1,0,0,0-.07,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,23.16a5.71,5.71,0,0,1,.23-1,5.9,5.9,0,0,0-.07,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.3,24.77a5.7,5.7,0,0,1,.21-1,5.86,5.86,0,0,0-.05,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.64,27.91a6.71,6.71,0,0,1,.22-1,6.05,6.05,0,0,0-.06,1,.08.08,0,0,1-.06.09.1.1,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,31a6,6,0,0,1,.2-1,5.13,5.13,0,0,0,0,1A.08.08,0,0,1,25,31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.82,33.54a6,6,0,0,1,.18-1,6,6,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.51,36a6,6,0,0,1,.15-1.05,5.87,5.87,0,0,0,0,1,.09.09,0,0,1-.07.1.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.32,39.11a5.92,5.92,0,0,1,.12-1,5.73,5.73,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.32,41.74a6.2,6.2,0,0,1,0-1.06,6.48,6.48,0,0,0,.12,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.19,45.29a6.3,6.3,0,0,1-.14-1,5.73,5.73,0,0,0,.29,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.55,48.83a6.49,6.49,0,0,1-.21-1,5.77,5.77,0,0,0,.36,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.3,52.78a5.75,5.75,0,0,1-.38-1,6,6,0,0,0,.51.9.08.08,0,0,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.08,57.82a6.22,6.22,0,0,1-.61-.87,7.15,7.15,0,0,0,.71.74.08.08,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.72,62.33a6.25,6.25,0,0,1-.67-.81,6.86,6.86,0,0,0,.76.68.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.61,65.34a5.67,5.67,0,0,1-.7-.79,5.35,5.35,0,0,0,.79.65.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.33,67.42a5.75,5.75,0,0,1-.71-.78,6.81,6.81,0,0,0,.79.65.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.87,68.39a5.84,5.84,0,0,1-.72-.78,6.6,6.6,0,0,0,.8.64.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.75,68.43a5.84,5.84,0,0,1-.72-.78,6.6,6.6,0,0,0,.8.64.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.2,67.31a6.45,6.45,0,0,1-.72-.77,6.6,6.6,0,0,0,.8.64.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.64,66.11a5.75,5.75,0,0,1-.71-.78,5.42,5.42,0,0,0,.8.65.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.67,64.46a6.49,6.49,0,0,1-.7-.79,6,6,0,0,0,.79.66.07.07,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.44,61.37a6.33,6.33,0,0,1-.67-.82,8,8,0,0,0,.76.69.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.93,58.15a7.08,7.08,0,0,1-.63-.84A7.07,7.07,0,0,0,26,58a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.73,53.46a5.94,5.94,0,0,1-.51-.92,5.81,5.81,0,0,0,.63.81.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.81,50.68a6.3,6.3,0,0,1-.36-1,5.66,5.66,0,0,0,.49.91.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.87,46.85a6.23,6.23,0,0,1,0-1.06,5.57,5.57,0,0,0,.15,1,.08.08,0,0,1-.05.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.31,43.14a7.35,7.35,0,0,1,.35-1,7.45,7.45,0,0,0-.19,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.94,39.7a6.15,6.15,0,0,1,.42-1,5.47,5.47,0,0,0-.26,1,.07.07,0,0,1-.09.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.51,33.07a7.22,7.22,0,0,1,.36-1,7.37,7.37,0,0,0-.2,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.21,29.8a5.66,5.66,0,0,1,.32-1,6.38,6.38,0,0,0-.17,1,.07.07,0,0,1-.07.08.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.05,26.82a6.67,6.67,0,0,1,.29-1,6.19,6.19,0,0,0-.13,1,.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.75,25.39a6.65,6.65,0,0,1,.28-1,6.15,6.15,0,0,0-.12,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.44,26a5.55,5.55,0,0,1,.27-1,6.11,6.11,0,0,0-.11,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.66,27.3a6.62,6.62,0,0,1,.26-1,7.33,7.33,0,0,0-.1,1,.07.07,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.24,28.47a5.66,5.66,0,0,1,.27-1,6.11,6.11,0,0,0-.11,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.69,30.13a6.69,6.69,0,0,1,.3-1,6.24,6.24,0,0,0-.14,1s0,.08-.07.09a.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.55,33a6.17,6.17,0,0,1,.33-1,6.27,6.27,0,0,0-.17,1,.1.1,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.08,38.51a5.23,5.23,0,0,1,.41-1,5.32,5.32,0,0,0-.25,1,.08.08,0,0,1-.09.08.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.41,42.23a5.7,5.7,0,0,1,.43-1,6.36,6.36,0,0,0-.27,1,.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.22,45.56a6.58,6.58,0,0,1,.31-1,6.28,6.28,0,0,0-.15,1,.08.08,0,0,1-.07.08.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.78,48.82a6.25,6.25,0,0,1,0-1.06,6.42,6.42,0,0,0,.15,1,.07.07,0,0,1-.05.1.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,51.35a6.65,6.65,0,0,1-.24-1,6.72,6.72,0,0,0,.38,1,.08.08,0,0,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,53.82a5.41,5.41,0,0,1-.44-1,6.58,6.58,0,0,0,.56.85.07.07,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.66,55.16a5.33,5.33,0,0,1-.53-.92,6.72,6.72,0,0,0,.64.81.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.33,56.35a6.16,6.16,0,0,1-.58-.89,6.44,6.44,0,0,0,.68.77.08.08,0,1,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.71,56.4a5.41,5.41,0,0,1-.6-.87,5.59,5.59,0,0,0,.7.75s0,.07,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.49,56.74a7.1,7.1,0,0,1-.6-.87,6.34,6.34,0,0,0,.71.75.09.09,0,0,1,0,.11.11.11,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,55.14a6.58,6.58,0,0,1-.55-.9,5.65,5.65,0,0,0,.66.78.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.9,52a6.85,6.85,0,0,1-.43-1,6.79,6.79,0,0,0,.55.87.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.19,44.71a5.73,5.73,0,0,1,.24-1,6.11,6.11,0,0,0-.08,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.33,36.93a5.29,5.29,0,0,1,.36-1,6,6,0,0,0-.21,1,.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08S29.32,36.94,29.33,36.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.23,31.71a5.39,5.39,0,0,1,.36-1,5.46,5.46,0,0,0-.2,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.43,28a6.15,6.15,0,0,1,.32-1,6.05,6.05,0,0,0-.16,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.37,22a6.58,6.58,0,0,1,.31-1,6.28,6.28,0,0,0-.15,1,.08.08,0,0,1-.07.08.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.78,19.62a6.35,6.35,0,0,1,.28-1,6,6,0,0,0-.12,1s0,.08-.07.09a.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.94,20.94a5.79,5.79,0,0,1,.18-1,6,6,0,0,0,0,1A.08.08,0,0,1,42,21a.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.51,20.84a5.13,5.13,0,0,1,.1-1.05,5.71,5.71,0,0,0,.06,1,.08.08,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.44,24.61a7.57,7.57,0,0,1,0-1.06,7.54,7.54,0,0,0,.19,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.84,29.67a6.26,6.26,0,0,1-.17-1,5.92,5.92,0,0,0,.32,1,.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.07,35A6.5,6.5,0,0,1,52,33.91a6,6,0,0,0,.27,1,.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.55,38.62a5.93,5.93,0,0,1,.11-1,6.77,6.77,0,0,0,.05,1,.08.08,0,0,1-.06.1.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.41,44.77a7,7,0,0,1,.44-1,7.21,7.21,0,0,0-.28,1,.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.11,50.56a5.51,5.51,0,0,1,.65-.84,6.18,6.18,0,0,0-.5.9.09.09,0,0,1-.1.05.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.87,59.24a6,6,0,0,1,.73-.77,5.5,5.5,0,0,0-.58.84.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.19,66.47a6.31,6.31,0,0,1,.84-.65,5.55,5.55,0,0,0-.71.75.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,70.26a6.51,6.51,0,0,1,.91-.53,7.47,7.47,0,0,0-.8.64.08.08,0,1,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.51,72.67a6.54,6.54,0,0,1,.93-.51,5.81,5.81,0,0,0-.81.63.09.09,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.73,73.56a6,6,0,0,1,.92-.52,5.81,5.81,0,0,0-.81.63.08.08,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.65,71.59a6.54,6.54,0,0,1,.93-.51,6.52,6.52,0,0,0-.82.62.08.08,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.46,68.73a6.14,6.14,0,0,1,.92-.54,5.8,5.8,0,0,0-.8.65.09.09,0,0,1-.12,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.28,62.38a5.86,5.86,0,0,1,.77-.73,5.21,5.21,0,0,0-.63.81.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.56,55.38a5.93,5.93,0,0,1,.74-.76,5.89,5.89,0,0,0-.6.84.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.09,47.85a6.1,6.1,0,0,1,.56-.89,6,6,0,0,0-.41.94.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,41.58a6.65,6.65,0,0,1,.28-1,6.15,6.15,0,0,0-.12,1,.07.07,0,0,1-.07.09s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.38,38.17a6,6,0,0,1,.08-1.05,6.69,6.69,0,0,0,.08,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.34,34.56a5.33,5.33,0,0,1-.1-1.05,6.09,6.09,0,0,0,.25,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.15,31.85a6.4,6.4,0,0,1-.16-1,5.9,5.9,0,0,0,.31,1,.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.46,30.33a5.42,5.42,0,0,1-.05-1.06,6.45,6.45,0,0,0,.2,1,.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.28,29.87a6,6,0,0,1,0-1.05,5.69,5.69,0,0,0,.12,1,.09.09,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.3,29.36a6,6,0,0,1,.14-1.05,7.05,7.05,0,0,0,0,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.29,29.53a6.46,6.46,0,0,1,.25-1,6,6,0,0,0-.09,1,.08.08,0,0,1-.07.09c-.05,0-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.62,28.94a5.33,5.33,0,0,1,.33-1,5.2,5.2,0,0,0-.17,1,.09.09,0,0,1-.08.08.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.11,28.53a6.63,6.63,0,0,1,.33-1,6.2,6.2,0,0,0-.17,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.47,31.52a6.29,6.29,0,0,1,.33-1,6.09,6.09,0,0,0-.17,1,.08.08,0,0,1-.07.09.08.08,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.41,35.48a6.63,6.63,0,0,1,.27-1,6.11,6.11,0,0,0-.11,1,.07.07,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.67,42.93a5.38,5.38,0,0,1-.13-1,5,5,0,0,0,.28,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.24,52.22a5.31,5.31,0,0,1-.54-.91,5.8,5.8,0,0,0,.65.8s0,.07,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.67,61.92a6.35,6.35,0,0,1-.68-.81,6,6,0,0,0,.77.67.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.11,68.34a6.45,6.45,0,0,1-.72-.77,5.21,5.21,0,0,0,.81.63.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.83,70.43a6.6,6.6,0,0,1-.73-.77,5.21,5.21,0,0,0,.81.63.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.05,70.59a5.77,5.77,0,0,1-.72-.77,5.9,5.9,0,0,0,.8.64.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.72,69.55a7.51,7.51,0,0,1-.73-.77,6.57,6.57,0,0,0,.81.63.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.52,67.18a5.21,5.21,0,0,1-.73-.76,6.79,6.79,0,0,0,.81.63.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.74,60.23a6.33,6.33,0,0,1-.67-.82,6.08,6.08,0,0,0,.76.69.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.75,47.07a5.57,5.57,0,0,1-.31-1,6.63,6.63,0,0,0,.44.93.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.33,37.91a5.79,5.79,0,0,1,.14-1,4.93,4.93,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.66,31.52a7.74,7.74,0,0,1,.27-1,7.52,7.52,0,0,0-.11,1,.07.07,0,0,1-.07.08s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.14,26a6.37,6.37,0,0,1,.3-1,6.24,6.24,0,0,0-.14,1,.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.88,23.34a6.26,6.26,0,0,1,.31-1,6.13,6.13,0,0,0-.15,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.34,22.6a6.47,6.47,0,0,1,.27-1,6.11,6.11,0,0,0-.11,1,.07.07,0,0,1-.07.08s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.68,23.57a5.79,5.79,0,0,1,.15-1,5.89,5.89,0,0,0,0,1,.09.09,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,22.59a6.22,6.22,0,0,1,0-1.06,5.46,5.46,0,0,0,.14,1,.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.3,24.83a7.75,7.75,0,0,1-.1-1,7.69,7.69,0,0,0,.25,1,.08.08,0,0,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.7,28a5.55,5.55,0,0,1-.26-1,5.42,5.42,0,0,0,.4.95.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.3,32.08a6.41,6.41,0,0,1-.27-1,6.8,6.8,0,0,0,.41.95.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,37.56a6,6,0,0,1,.1-1.05,5.82,5.82,0,0,0,.06,1,.08.08,0,0,1-.06.09.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.45,42.57a6.21,6.21,0,0,1,.4-1,6.57,6.57,0,0,0-.24,1,.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.55,47.83a6.08,6.08,0,0,1,.62-.86,6,6,0,0,0-.47.91.08.08,0,0,1-.1.06.09.09,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.36,51.68a6,6,0,0,1,.71-.79,5.56,5.56,0,0,0-.57.86.1.1,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.59,54.54a6.57,6.57,0,0,1,.74-.75,7.55,7.55,0,0,0-.6.83.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.51,57.65a5.31,5.31,0,0,1,.71-.78,5.05,5.05,0,0,0-.57.85.09.09,0,0,1-.1.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,61.32a6.49,6.49,0,0,1,.79-.71,5.3,5.3,0,0,0-.65.8.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.21,63.93a6.06,6.06,0,0,1,.87-.59,5.59,5.59,0,0,0-.75.7.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.69,66.8a6.54,6.54,0,0,1,.93-.51,6.52,6.52,0,0,0-.82.62.08.08,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.12,70.16A5.28,5.28,0,0,1,60,69.6a5.09,5.09,0,0,0-.78.67.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,75.31a6.08,6.08,0,0,1,.89-.57,6.34,6.34,0,0,0-.77.68.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.21,77.87a6.13,6.13,0,0,1,.85-.62,6.22,6.22,0,0,0-.73.72.08.08,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.66,80.14a5.64,5.64,0,0,1,.8-.69,6,6,0,0,0-.67.78.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.14,83.17a6.59,6.59,0,0,1,.73-.76,5.81,5.81,0,0,0-.59.84.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,84.84a4.82,4.82,0,0,1,.7-.79,5.06,5.06,0,0,0-.56.86.09.09,0,0,1-.1,0,.08.08,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.08,87.64a5.94,5.94,0,0,1,.63-.84,6,6,0,0,0-.48.9.08.08,0,0,1-.1.06.1.1,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.94,89.18a6,6,0,0,1,.61-.86,5.27,5.27,0,0,0-.46.92.07.07,0,0,1-.1.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.42,91.25a6.09,6.09,0,0,1,.58-.88,6.43,6.43,0,0,0-.43.93.08.08,0,0,1-.09.06.08.08,0,0,1-.07-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,93.11a6.69,6.69,0,0,1,.56-.89,6.88,6.88,0,0,0-.41.94.08.08,0,0,1-.1.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.59,94.3a5.46,5.46,0,0,1,.54-.91,6.34,6.34,0,0,0-.39,1,.1.1,0,0,1-.09.07.1.1,0,0,1-.07-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.19,95.45a5.9,5.9,0,0,1,.54-.91,6.8,6.8,0,0,0-.39.95.08.08,0,0,1-.09.07.09.09,0,0,1-.07-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.45,96.29a6.82,6.82,0,0,1,.54-.91,5.47,5.47,0,0,0-.38,1,.08.08,0,0,1-.1.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.63,95.86a5.79,5.79,0,0,1,.52-.92,7.57,7.57,0,0,0-.37,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.47,94.76a6.27,6.27,0,0,1,.52-.92,6.25,6.25,0,0,0-.36,1,.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,91.1a6.84,6.84,0,0,1,.52-.92,6.25,6.25,0,0,0-.36,1,.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.42,86.58a5.42,5.42,0,0,1,.61-.87,5.64,5.64,0,0,0-.46.92.08.08,0,0,1-.1.06.09.09,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.87,84.81a6,6,0,0,1,.64-.84,6.18,6.18,0,0,0-.5.9.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.3,82.76A6,6,0,0,1,55,82a5,5,0,0,0-.55.86.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.58,81.5a5.32,5.32,0,0,1,.75-.75,6.63,6.63,0,0,0-.62.83.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.24,81.28a6.47,6.47,0,0,1,.8-.7,6.74,6.74,0,0,0-.66.79.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.2,81.2a6.33,6.33,0,0,1,.82-.67,6.87,6.87,0,0,0-.69.76.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.55,82.25a6.35,6.35,0,0,1,.81-.68,6.94,6.94,0,0,0-.68.77.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.54,84.87a6.45,6.45,0,0,1,.77-.72,5.9,5.9,0,0,0-.63.81.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.38,88.67a5.86,5.86,0,0,1,.68-.8,7.33,7.33,0,0,0-.54.87.08.08,0,0,1-.1.05.09.09,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.39,92.32a6.17,6.17,0,0,1,.55-.9,7.1,7.1,0,0,0-.4,1,.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.4,95.69a5.44,5.44,0,0,1,.51-.92,5.36,5.36,0,0,0-.36,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.76,98.71a6.32,6.32,0,0,1,.5-.93,5.34,5.34,0,0,0-.34,1,.08.08,0,0,1-.09.06.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.74,99.94a6.37,6.37,0,0,1,.48-.94,6.22,6.22,0,0,0-.32,1,.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.31,101.18a6,6,0,0,1,.45-1,5.52,5.52,0,0,0-.29,1,.1.1,0,0,1-.09.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.68,102.59a7.2,7.2,0,0,1,.41-1,7.69,7.69,0,0,0-.25,1,.09.09,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.5,103.75a5.59,5.59,0,0,1,.37-1,5.35,5.35,0,0,0-.21,1,.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.84,104.94a5.68,5.68,0,0,1,.33-1,5.3,5.3,0,0,0-.17,1,.09.09,0,0,1-.08.08.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.86,105.93a5.31,5.31,0,0,1,.32-1,6.09,6.09,0,0,0-.17,1,.08.08,0,0,1-.07.09.1.1,0,0,1-.09-.08S41.85,105.94,41.86,105.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.07,105.78a5.8,5.8,0,0,1,.37-1,6.15,6.15,0,0,0-.21,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.53,105.19a5.67,5.67,0,0,1,.42-1,6.3,6.3,0,0,0-.26,1,.09.09,0,0,1-.09.08.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,103.8a6.4,6.4,0,0,1,.49-.94,5.29,5.29,0,0,0-.33,1s-.05.08-.09.07a.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.65,99.9a7.38,7.38,0,0,1,.53-.92,5.41,5.41,0,0,0-.37,1,.08.08,0,0,1-.09.06.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.64,97a5.51,5.51,0,0,1,.53-.92,6.38,6.38,0,0,0-.38,1,.08.08,0,0,1-.09.06.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.15,92.34a6.87,6.87,0,0,1,.5-.93,6.23,6.23,0,0,0-.34,1,.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.21,89.08a6.17,6.17,0,0,1,.55-.9,6,6,0,0,0-.4.95.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.74,86a5.37,5.37,0,0,1,.6-.87,5.57,5.57,0,0,0-.45.92.08.08,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.27,83.57a6.81,6.81,0,0,1,.65-.83,6.65,6.65,0,0,0-.5.89.07.07,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.47,83.82a6.63,6.63,0,0,1,.68-.81,6,6,0,0,0-.54.88.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.38,85.68a6.12,6.12,0,0,1,.67-.82,6.15,6.15,0,0,0-.52.88.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.45,88.31a6,6,0,0,1,.66-.83,6.84,6.84,0,0,0-.52.89.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.43,101.16a6.15,6.15,0,0,1,.57-.89,5.16,5.16,0,0,0-.41.94.09.09,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.49,105.72a5.85,5.85,0,0,1,.51-.92,6.25,6.25,0,0,0-.36,1,.1.1,0,0,1-.09.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.52,107.93a6,6,0,0,1,.47-1,6.22,6.22,0,0,0-.32,1,.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.72,109.09a6.09,6.09,0,0,1,.4-1,6.3,6.3,0,0,0-.24,1,.09.09,0,0,1-.09.08.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.82,109.88a6.47,6.47,0,0,1,.36-1,5.46,5.46,0,0,0-.2,1,.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.23,110.66a6.65,6.65,0,0,1,.28-1,6.15,6.15,0,0,0-.12,1,.08.08,0,0,1-.08.09.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.29,110.57a6,6,0,0,1,.18-1,7.17,7.17,0,0,0,0,1,.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.07,110.68a6,6,0,0,1,.12-1.05,5.8,5.8,0,0,0,0,1,.1.1,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.09,109.64a7.2,7.2,0,0,1,.08-1,6.69,6.69,0,0,0,.08,1,.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.3,107.64a6.11,6.11,0,0,1,.07-1,5.53,5.53,0,0,0,.09,1,.08.08,0,0,1-.06.1s-.08,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.7,103.76a6.19,6.19,0,0,1,0-1.06,5.48,5.48,0,0,0,.12,1,.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.55,99.49a6.25,6.25,0,0,1,0-1.06,6.46,6.46,0,0,0,.15,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.6,95.26a6.32,6.32,0,0,1-.05-1.06,6.23,6.23,0,0,0,.2,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.8,91.47a7.71,7.71,0,0,1-.16-1,7,7,0,0,0,.31,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.51,88.38a5.48,5.48,0,0,1-.29-1,5.64,5.64,0,0,0,.43.93.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.56,86.65a6.63,6.63,0,0,1-.37-1,6,6,0,0,0,.5.9.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.3,84.29a5.63,5.63,0,0,1-.49-.94,5.23,5.23,0,0,0,.61.83.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.83,83a5.73,5.73,0,0,1-.54-.91,6.36,6.36,0,0,0,.65.79A.07.07,0,0,1,28,83s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.13,82.84a6.22,6.22,0,0,1-.64-.84,5.48,5.48,0,0,0,.74.71.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.93,82.68a5.41,5.41,0,0,1-.6-.87,6.17,6.17,0,0,0,.7.74.08.08,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.52,83.94a7.1,7.1,0,0,1-.6-.87,6.38,6.38,0,0,0,.71.75.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.66,86.2a5.61,5.61,0,0,1-.5-.93,5.63,5.63,0,0,0,.61.82.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.67,90.71a5.71,5.71,0,0,1-.25-1,5.73,5.73,0,0,0,.39.95.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.61,95.34a6.26,6.26,0,0,1-.12-1,5.72,5.72,0,0,0,.27,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,99.85a6,6,0,0,1,.09-1,5.75,5.75,0,0,0,.07,1,.08.08,0,0,1-.06.09.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.49,102.65a5.9,5.9,0,0,1,.15-1.05,7.05,7.05,0,0,0,0,1,.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.15,103.86a5.79,5.79,0,0,1,.17-1,6,6,0,0,0,0,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.16,103.71a6,6,0,0,1,.12-1,5.8,5.8,0,0,0,0,1,.09.09,0,0,1-.06.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.59,102.13a6.29,6.29,0,0,1,0-1.06,6.45,6.45,0,0,0,.17,1,.08.08,0,0,1,0,.11.1.1,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.26,99.26a6.48,6.48,0,0,1-.15-1.05,5.89,5.89,0,0,0,.3,1,.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.74,95.64a6.28,6.28,0,0,1-.24-1,6.72,6.72,0,0,0,.38.95.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.19,90.25a6.3,6.3,0,0,1-.36-1,5.94,5.94,0,0,0,.49.9.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.6,85.67a5.55,5.55,0,0,1-.45-1,5.25,5.25,0,0,0,.57.85.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.62,81.65a5,5,0,0,1-.58-.88,6.32,6.32,0,0,0,.68.76.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.88,78.06a6.49,6.49,0,0,1-.71-.79,6.74,6.74,0,0,0,.79.66.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.3,74.42a5.35,5.35,0,0,1-.77-.73,5.58,5.58,0,0,0,.84.59.1.1,0,0,1,.05.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.59,72.84a6,6,0,0,1-.77-.73,5.89,5.89,0,0,0,.85.59.08.08,0,0,1,0,.1.1.1,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.38,73a5.29,5.29,0,0,1-.77-.72,6.32,6.32,0,0,0,.84.58.1.1,0,0,1,.05.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.23,73.93a5.37,5.37,0,0,1-.78-.72,7.13,7.13,0,0,0,.85.58.09.09,0,0,1,.05.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.8,75.46a6.73,6.73,0,0,1-.78-.71,6.31,6.31,0,0,0,.86.56.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.87,78.49a6.62,6.62,0,0,1-.77-.72,5.81,5.81,0,0,0,.85.58.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.22,87.42a6.27,6.27,0,0,1-.68-.8,6,6,0,0,0,.77.67.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.16,95.13a6.1,6.1,0,0,1-.56-.9,5.71,5.71,0,0,0,.67.78.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.19,99.4a6.91,6.91,0,0,1-.45-1,5.73,5.73,0,0,0,.57.85.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.48,102.08a6.7,6.7,0,0,1-.38-1,6,6,0,0,0,.51.9.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.11,103.58a5.58,5.58,0,0,1-.35-1,5.9,5.9,0,0,0,.48.9.08.08,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.05,103.61a5.48,5.48,0,0,1-.29-1,6.57,6.57,0,0,0,.42.93.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.88,102.41a5.76,5.76,0,0,1-.28-1,5.26,5.26,0,0,0,.42.93.11.11,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.72,95.55a6.31,6.31,0,0,1-.45-1,5.78,5.78,0,0,0,.58.85.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,84.1a6.52,6.52,0,0,1-.73-.76,5.9,5.9,0,0,0,.81.63.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.85,73.3a6.59,6.59,0,0,1-.76-.73,6.41,6.41,0,0,0,.84.59.08.08,0,0,1-.06.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.44,63.18a5.69,5.69,0,0,1-.71-.77,5.35,5.35,0,0,0,.8.64.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.51,54.16a6.07,6.07,0,0,1-.58-.88,7.32,7.32,0,0,0,.68.77.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.66,49.32a6,6,0,0,1-.49-.94,5.23,5.23,0,0,0,.61.83.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.81,45.36a6.77,6.77,0,0,1-.42-1,5.8,5.8,0,0,0,.54.87.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.8,41.2a5.62,5.62,0,0,1-.34-1,6.5,6.5,0,0,0,.47.91.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,36a5.55,5.55,0,0,1-.26-1,5.42,5.42,0,0,0,.4,1,.09.09,0,0,1,0,.11A.07.07,0,0,1,19,36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.9,31.32a5.58,5.58,0,0,1-.22-1,6.81,6.81,0,0,0,.36,1,.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.6,29a6.52,6.52,0,0,1-.19-1,5.54,5.54,0,0,0,.34,1,.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.41,30a5.62,5.62,0,0,1-.23-1,5.36,5.36,0,0,0,.37.95.11.11,0,0,1,0,.12.1.1,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.9,33.74a5.65,5.65,0,0,1-.48-1,5.78,5.78,0,0,0,.6.84.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.94,43.79a5.52,5.52,0,0,1-.66-.82,6.89,6.89,0,0,0,.75.69.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,48a5.12,5.12,0,0,1-.67-.82,5.45,5.45,0,0,0,.76.69A.08.08,0,1,1,13,48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.47,49.35a5.73,5.73,0,0,1-.68-.81,8,8,0,0,0,.77.68.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12,48.14a5.75,5.75,0,0,1-.71-.78,6.71,6.71,0,0,0,.8.65.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12,44.49a6,6,0,0,1-.77-.73,7.53,7.53,0,0,0,.84.59.08.08,0,0,1-.06.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.34,36.25a5.36,5.36,0,0,1-.56-.9,5.7,5.7,0,0,0,.67.79.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.24,30.29a5.38,5.38,0,0,1-.13-1.05,5,5,0,0,0,.28,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,26.26A6.17,6.17,0,0,1,23,25.2a5.51,5.51,0,0,0,.1,1,.08.08,0,0,1-.06.1s-.08,0-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.9,24.79a6,6,0,0,1,.1-1.05,5.77,5.77,0,0,0,.06,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.11,25.59a6.22,6.22,0,0,1,0-1.06,5.57,5.57,0,0,0,.14,1,.08.08,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.88,31.31a5.69,5.69,0,0,1-.37-1,6.51,6.51,0,0,0,.5.9.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,36a7.42,7.42,0,0,1-.73-.76,5.89,5.89,0,0,0,.82.62.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,39.48a6,6,0,0,1-.86-.61,6.58,6.58,0,0,0,.92.46.08.08,0,0,1,0,.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.81,38.76a6.08,6.08,0,0,1-.87-.6,6,6,0,0,0,.92.45.08.08,0,0,1,0,.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12,37.5a6.63,6.63,0,0,1-.81-.68,8.34,8.34,0,0,0,.88.54.08.08,0,0,1,.05.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.75,28.56a5.45,5.45,0,0,1-.07-1.06,5.49,5.49,0,0,0,.22,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.43,21.7a5.79,5.79,0,0,1,.18-1,7,7,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.09,16.56a5.73,5.73,0,0,1,.24-1,6,6,0,0,0-.08,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.74,14.91a5.71,5.71,0,0,1,.23-1,6,6,0,0,0-.07,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.86,15.94a5.63,5.63,0,0,1,.21-1,6,6,0,0,0-.05,1A.1.1,0,0,1,31,16a.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.91,18.6a6.19,6.19,0,0,1,0-1.06,5.48,5.48,0,0,0,.12,1,.08.08,0,0,1-.05.1.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.58,19.81a5.45,5.45,0,0,1-.09-1,6.35,6.35,0,0,0,.24,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,20.88a6.32,6.32,0,0,1-.25-1,6.74,6.74,0,0,0,.39.95.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.16,21.61a6.77,6.77,0,0,1-.42-1,6.79,6.79,0,0,0,.55.87.08.08,0,0,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.54,21a7.62,7.62,0,0,1-.44-1,5.35,5.35,0,0,0,.57.86.08.08,0,0,1-.12.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,16.77a5.37,5.37,0,0,1,0-1.06,6.4,6.4,0,0,0,.16,1,.07.07,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.58,14.79a5.3,5.3,0,0,1,.07-1.06,6.83,6.83,0,0,0,.08,1s0,.08-.05.1a.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.33,13.69a6,6,0,0,1,.11-1,5,5,0,0,0,0,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.35,12.83a6,6,0,0,1,.12-1.05,5.8,5.8,0,0,0,0,1,.09.09,0,0,1-.06.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.69,13.06A5.19,5.19,0,0,1,24.8,12a5,5,0,0,0,.05,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,19.32a6.25,6.25,0,0,1,0-1.06,6.42,6.42,0,0,0,.15,1,.07.07,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14,24.87a6.45,6.45,0,0,1-.34-1,6.54,6.54,0,0,0,.48.91.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.79,29.36A7.74,7.74,0,0,1,9,28.72a6.19,6.19,0,0,0,.91.49.08.08,0,0,1,.05.1.07.07,0,0,1-.1.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.46,31.34a6.32,6.32,0,0,1-.93-.5,6.29,6.29,0,0,0,1,.35.08.08,0,1,1,0,.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.36,30.48A5.08,5.08,0,0,1,7.44,30a5.88,5.88,0,0,0,1,.36.07.07,0,0,1,.06.09.08.08,0,0,1-.09.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,28.26a5.37,5.37,0,0,1-.8-.69,6.31,6.31,0,0,0,.87.55.07.07,0,0,1,0,.1.07.07,0,0,1-.1.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.74,20.65a5.38,5.38,0,0,1,0-1.06,6.26,6.26,0,0,0,.17,1,.08.08,0,0,1,0,.11.1.1,0,0,1-.11-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.83,13.41a5,5,0,0,1,.22-1,6.05,6.05,0,0,0-.06,1,.1.1,0,0,1-.07.09s-.09,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.53,10.78a5.79,5.79,0,0,1,.16-1,7.11,7.11,0,0,0,0,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.29,11.06A6,6,0,0,1,48.43,10a5,5,0,0,0,0,1,.09.09,0,0,1-.06.1.09.09,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.63,12.4a5.79,5.79,0,0,1,.15-1,5.77,5.77,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.66,18.51a5.79,5.79,0,0,1,.17-1,5.08,5.08,0,0,0,0,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.8,34.43a6.29,6.29,0,0,1,0-1.06,6.58,6.58,0,0,0,.17,1s0,.08-.05.1a.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,45.22a5.65,5.65,0,0,1,.57-.89,6.36,6.36,0,0,0-.42.93.09.09,0,0,1-.09.07.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.13,50.14a5.31,5.31,0,0,1,.71-.78,5.56,5.56,0,0,0-.57.86.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.53,52.14a5.25,5.25,0,0,1,.74-.75,6.52,6.52,0,0,0-.61.83.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.78,51.17a5.33,5.33,0,0,1,.74-.76,5.59,5.59,0,0,0-.6.83.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.67,47.93a6.8,6.8,0,0,1,.7-.8,6.4,6.4,0,0,0-.56.87.08.08,0,0,1-.1,0,.1.1,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.7,37.25a6.75,6.75,0,0,1,.59-.88,5.52,5.52,0,0,0-.43.93.08.08,0,0,1-.1.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.56,27.52a6.84,6.84,0,0,1,.52-.92,6.25,6.25,0,0,0-.36,1,.1.1,0,0,1-.1.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.41,23a6.07,6.07,0,0,1,.43-1,5.42,5.42,0,0,0-.27,1,.08.08,0,0,1-.09.08.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.48,21.76a5.53,5.53,0,0,1,.24-1,6,6,0,0,0-.08,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.18,22a5.94,5.94,0,0,1,.1-1.05,6.84,6.84,0,0,0,0,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.17,23.93a6.17,6.17,0,0,1,.06-1.06,6.56,6.56,0,0,0,.09,1,.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.41,32.16a6.13,6.13,0,0,1,.37-1,6.15,6.15,0,0,0-.21,1,.07.07,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.19,39.25a5.31,5.31,0,0,1,.71-.78,5.05,5.05,0,0,0-.57.85.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.09,40.6a6.64,6.64,0,0,1,.75-.75,6.52,6.52,0,0,0-.61.83.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.83,40.29a6.52,6.52,0,0,1,.76-.73,5.89,5.89,0,0,0-.62.82.08.08,0,0,1-.11,0s0-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.23,38.84a5.91,5.91,0,0,1,.74-.76,5.19,5.19,0,0,0-.6.84.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.13,33.85a6.84,6.84,0,0,1,.52-.92,6.25,6.25,0,0,0-.36,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.08,29.36a6.59,6.59,0,0,1,.22-1,6.05,6.05,0,0,0-.06,1,.08.08,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.15,25.05A6,6,0,0,1,59.22,24a5.69,5.69,0,0,0,.09,1,.09.09,0,0,1-.06.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.52,24a6.14,6.14,0,0,1,0-1.05,6.46,6.46,0,0,0,.15,1,.07.07,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.28,24.29a7.31,7.31,0,0,1,0-1.05,6.47,6.47,0,0,0,.14,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.66,25.68a6.62,6.62,0,0,1,.19-1,5.85,5.85,0,0,0,0,1,.08.08,0,0,1-.06.1.1.1,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,30.05a7.26,7.26,0,0,1,.38-1,6.31,6.31,0,0,0-.22,1,.09.09,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.46,32.22A6.84,6.84,0,0,1,69,31.3a6.25,6.25,0,0,0-.36,1,.09.09,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.06,35.57a6,6,0,0,1,.61-.86,6.58,6.58,0,0,0-.46.92.08.08,0,0,1-.16-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.7,37.31a6.81,6.81,0,0,1,.65-.83,6.65,6.65,0,0,0-.5.89.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.24,35.48a5.63,5.63,0,0,1,.59-.88,5.52,5.52,0,0,0-.43.93.09.09,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.8,26.39a5.7,5.7,0,0,1,.29-1,6.19,6.19,0,0,0-.13,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.66,20.15a6.84,6.84,0,0,1,.19-1,5.88,5.88,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.69,18.67a5.77,5.77,0,0,1,.15-1.05,5.89,5.89,0,0,0,0,1,.08.08,0,0,1-.06.09.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.64,17.69a5,5,0,0,1,.16-1,7.11,7.11,0,0,0,0,1,.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.83,18.2a5.31,5.31,0,0,1,.32-1,5.17,5.17,0,0,0-.16,1,.1.1,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.19,18.12a7.73,7.73,0,0,1,.62-.85,6,6,0,0,0-.47.91.1.1,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.56,18.64a6.73,6.73,0,0,1,.69-.8,6.22,6.22,0,0,0-.54.87.09.09,0,0,1-.11.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.46,19.58a6.52,6.52,0,0,1,.76-.73,6.65,6.65,0,0,0-.63.82.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.46,18.7a5.29,5.29,0,0,1,.77-.73,6,6,0,0,0-.64.81.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.16,19.23a5.29,5.29,0,0,1,.73-.76,6.33,6.33,0,0,0-.59.83.1.1,0,0,1-.11.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,17.43a5.47,5.47,0,0,1,.66-.83,5,5,0,0,0-.51.89.09.09,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.49,14.67a5.89,5.89,0,0,1,.47-.94,5.29,5.29,0,0,0-.31,1,.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.53,13.12a5.63,5.63,0,0,1,.21-1,6,6,0,0,0-.05,1,.08.08,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.21,11.9a5.78,5.78,0,0,1,.14-1.05,5.87,5.87,0,0,0,0,1,.09.09,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.73,11.06A6,6,0,0,1,46.87,10a7.05,7.05,0,0,0,0,1,.07.07,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.64,10.48a6,6,0,0,1,.14-1.05,7,7,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.08,12.16a5,5,0,0,1,.22-1,5.88,5.88,0,0,0-.06,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.47,13.68a5.91,5.91,0,0,1,.5-.93,6.29,6.29,0,0,0-.35,1,.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,17a5.92,5.92,0,0,1,.69-.8,6.31,6.31,0,0,0-.55.87.08.08,0,0,1-.1.05A.1.1,0,0,1,71,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.55,16.94a6.52,6.52,0,0,1,.76-.73,5.89,5.89,0,0,0-.62.82.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.46,17.83a5.81,5.81,0,0,1,.75-.74,5.81,5.81,0,0,0-.61.82.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.49,15.56a5.94,5.94,0,0,1,.71-.78,7,7,0,0,0-.57.85.1.1,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.79,12.73a5.42,5.42,0,0,1,.61-.87,5.64,5.64,0,0,0-.46.92.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,10.11a6.11,6.11,0,0,1,.36-1,6.1,6.1,0,0,0-.2,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.86,8.54a6,6,0,0,1,.13-1,5.85,5.85,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.22,7.65a6.15,6.15,0,0,1,.08-1.06,6.83,6.83,0,0,0,.08,1,.08.08,0,0,1-.06.1.1.1,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.21,7.53a6,6,0,0,1,.1-1,5.77,5.77,0,0,0,.06,1,.1.1,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.43,8.5a5.9,5.9,0,0,1,.18-1,6,6,0,0,0,0,1,.07.07,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,9.82a5.79,5.79,0,0,1,.16-1,5.9,5.9,0,0,0,0,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,11.68a7.19,7.19,0,0,1,.1-1,6.74,6.74,0,0,0,.06,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.33,13.36a6,6,0,0,1,.06-1,5.69,5.69,0,0,0,.1,1,.08.08,0,1,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.14,14.85a6.23,6.23,0,0,1,0-1.06,5.7,5.7,0,0,0,.15,1,.09.09,0,0,1-.05.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.76,16.67a6.19,6.19,0,0,1-.08-1.05,6.09,6.09,0,0,0,.23,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.21,17.83a6.48,6.48,0,0,1-.18-1,7,7,0,0,0,.32,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.44,17.85a5.76,5.76,0,0,1-.28-1,6.67,6.67,0,0,0,.41.94.08.08,0,0,1,0,.11.11.11,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,18.69a6.57,6.57,0,0,1-.36-1,7,7,0,0,0,.49.9.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.84,18.67a6.39,6.39,0,0,1-.29-1,5.64,5.64,0,0,0,.43.93.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12,16.44a6.3,6.3,0,0,1-.14-1.05,6.78,6.78,0,0,0,.28,1,.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.17,15.16a5.3,5.3,0,0,1,0-1.05,5.47,5.47,0,0,0,.19,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.57,12a6.18,6.18,0,0,1,.05-1.06,6.5,6.5,0,0,0,.11,1,.09.09,0,0,1-.05.11.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.26,9.64a6,6,0,0,1,.12-1.05,5.8,5.8,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.2,7.91a5,5,0,0,1,.19-1,6,6,0,0,0,0,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.67,7.82a7,7,0,0,1,.11-1.05,6.77,6.77,0,0,0,0,1,.08.08,0,0,1-.06.1.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46,8.51a6.05,6.05,0,0,1,.1-1.06,6.9,6.9,0,0,0,.05,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.29,11a5.79,5.79,0,0,1,.16-1,5.79,5.79,0,0,0,0,1,.09.09,0,0,1-.07.1.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.76,12.45a6,6,0,0,1,.45-1,6.48,6.48,0,0,0-.29,1,.07.07,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.34,13.66A5,5,0,0,1,68,12.8a6,6,0,0,0-.47.91.08.08,0,0,1-.1.06.09.09,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.83,14.07a6,6,0,0,1,.66-.83,5.43,5.43,0,0,0-.51.9.09.09,0,0,1-.1,0,.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.62,15.63a5.9,5.9,0,0,1,.75-.75,6.52,6.52,0,0,0-.61.83.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.77,17.73a6.27,6.27,0,0,1,.8-.68,6,6,0,0,0-.67.77.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.32,19.63a6.15,6.15,0,0,1,.82-.65,6.16,6.16,0,0,0-.7.75.08.08,0,1,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.77,18.74a7.17,7.17,0,0,1,.84-.64,5.46,5.46,0,0,0-.71.74.1.1,0,0,1-.12,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.92,17.43a5.65,5.65,0,0,1,.83-.65,7,7,0,0,0-.71.74.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.18,15.28a6.49,6.49,0,0,1,.79-.71,6,6,0,0,0-.65.8.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.28,12.63a6.12,6.12,0,0,1,.69-.81,5.49,5.49,0,0,0-.54.88.09.09,0,0,1-.1.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.33,10.59a6.2,6.2,0,0,1,.53-.91,5.88,5.88,0,0,0-.38,1,.08.08,0,0,1-.09.06s-.07,0-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.28,9a6.26,6.26,0,0,1,.31-1,6,6,0,0,0-.15,1,.08.08,0,0,1-.07.09A.09.09,0,0,1,59.28,9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.56,7.36a6,6,0,0,1,.14-1.05,5.87,5.87,0,0,0,0,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.15,5.16a6,6,0,0,1,.06-1,5.69,5.69,0,0,0,.1,1,.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.43,4.34a6,6,0,0,1,.08-1,6.81,6.81,0,0,0,.07,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.8,4.26a5.79,5.79,0,0,1,.15-1,5.76,5.76,0,0,0,0,1,.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.2,4.48a5.9,5.9,0,0,1,.18-1.05,6,6,0,0,0,0,1,.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.65,5.3a7.17,7.17,0,0,1,.13-1,7,7,0,0,0,0,1,.09.09,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.12,6.54a6.16,6.16,0,0,1,.07-1.06,5.72,5.72,0,0,0,.09,1,.1.1,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.47,7.94a6.2,6.2,0,0,1,0-1.06,5.47,5.47,0,0,0,.13,1,.08.08,0,0,1-.05.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14,9.34a6.16,6.16,0,0,1,0-1.05,6.45,6.45,0,0,0,.17,1,.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.42,11a6.25,6.25,0,0,1-.05-1.05,6.1,6.1,0,0,0,.21,1,.1.1,0,0,1-.05.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.22,12.25a7.92,7.92,0,0,1-.13-1.05,7.57,7.57,0,0,0,.28,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.61,10.6a6.32,6.32,0,0,1-.07-1,5.22,5.22,0,0,0,.23,1,.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.19,8.16a5.4,5.4,0,0,1-.07-1,6.09,6.09,0,0,0,.22,1,.08.08,0,1,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.4,5.72a5.39,5.39,0,0,1,0-1.06,5.46,5.46,0,0,0,.17,1,.09.09,0,0,1-.05.11.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,4.71a5.29,5.29,0,0,1,.1-1.06,5.77,5.77,0,0,0,.06,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.61,3.94a6.84,6.84,0,0,1,.17-1,7,7,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.72,6.44a6,6,0,0,1,.08-1.05,6.8,6.8,0,0,0,.08,1,.08.08,0,0,1-.05.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.14,9.51a6,6,0,0,1,.12-1,5.84,5.84,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.64,14.62a6.32,6.32,0,0,1,.5-.93,5.53,5.53,0,0,0-.34,1,.08.08,0,0,1-.09.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.09,15.8a6,6,0,0,1,.61-.86,5.64,5.64,0,0,0-.46.92.09.09,0,0,1-.1.05.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.7,16.05a5.38,5.38,0,0,1,.66-.82,5.73,5.73,0,0,0-.52.89.08.08,0,0,1-.1.05.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.54,17.47a6.59,6.59,0,0,1,.73-.76,6.41,6.41,0,0,0-.59.84.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.3,18.8a5.73,5.73,0,0,1,.81-.68,6.05,6.05,0,0,0-.68.77.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.06,19.79a6,6,0,0,1,.86-.62,5.53,5.53,0,0,0-.73.72.11.11,0,0,1-.12,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.27,18.68a5.6,5.6,0,0,1,.86-.61,5.62,5.62,0,0,0-.74.72.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.76,16.37a6.22,6.22,0,0,1,.86-.62,5.53,5.53,0,0,0-.73.72.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.57,13.27a5.42,5.42,0,0,1,.84-.63,5.42,5.42,0,0,0-.71.73.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.9,15.14a5.65,5.65,0,0,1,.83-.65,5.46,5.46,0,0,0-.7.75.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,22.19a5.31,5.31,0,0,1,.71-.78,5.12,5.12,0,0,0-.57.86.07.07,0,0,1-.1,0s-.07-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.63,27.1a6.07,6.07,0,0,1,.68-.81,7.33,7.33,0,0,0-.54.87.07.07,0,0,1-.1.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.56,31.69a5.37,5.37,0,0,1,.69-.8,5.48,5.48,0,0,0-.55.87.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.31,36.9a5.87,5.87,0,0,1,.73-.76,5.81,5.81,0,0,0-.59.84.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.82,42.79a5.77,5.77,0,0,1,.73-.75,6.33,6.33,0,0,0-.59.83.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.22,44.32a6,6,0,0,1,.74-.76,7.55,7.55,0,0,0-.6.83.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,45.89a5.9,5.9,0,0,1,.75-.75,6.52,6.52,0,0,0-.61.83A.08.08,0,1,1,71,45.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.77,47.17a5.81,5.81,0,0,1,.75-.74,5.81,5.81,0,0,0-.61.82.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,46.51a7.63,7.63,0,0,1,.74-.76,6.8,6.8,0,0,0-.6.84.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.35,42.46a6,6,0,0,1,.66-.83,6.84,6.84,0,0,0-.52.89.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.56,38.86a6,6,0,0,1,.53-.92,6.38,6.38,0,0,0-.38,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.51,36.09a5.7,5.7,0,0,1,.43-1,6.36,6.36,0,0,0-.27,1,.09.09,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.76,33.79a6.19,6.19,0,0,1,.34-1,7.75,7.75,0,0,0-.19,1,.08.08,0,0,1-.07.09.09.09,0,0,1-.09-.08S64.75,33.8,64.76,33.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.47,34.66a5.74,5.74,0,0,1,.4-1,6.05,6.05,0,0,0-.24,1,.07.07,0,0,1-.08.07.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,37.45a6.84,6.84,0,0,1,.52-.92,6.25,6.25,0,0,0-.36,1,.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.8,40.88a5.51,5.51,0,0,1,.61-.86,5.19,5.19,0,0,0-.46.91.08.08,0,0,1-.1.06.09.09,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.5,44.3a6,6,0,0,1,.69-.8,6.31,6.31,0,0,0-.55.87.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,49a6.46,6.46,0,0,1,.74-.74,6.7,6.7,0,0,0-.61.82.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.5,51.66a5.88,5.88,0,0,1,.76-.74,5.89,5.89,0,0,0-.62.82.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.94,53.07a5.81,5.81,0,0,1,.75-.74,5.81,5.81,0,0,0-.61.82.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.43,51.85a7.42,7.42,0,0,1,.76-.73,7.58,7.58,0,0,0-.62.81.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.45,46.23a6.62,6.62,0,0,1,.72-.77,5.05,5.05,0,0,0-.57.85.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,39.9a6.08,6.08,0,0,1,.6-.87,5.29,5.29,0,0,0-.45.93.08.08,0,0,1-.1,0,.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.66,30.81a5.65,5.65,0,0,1,.57-.89,5.45,5.45,0,0,0-.42.93.08.08,0,0,1-.09.07.09.09,0,0,1-.07-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.28,24.2a6.57,6.57,0,0,1,.74-.75,5.8,5.8,0,0,0-.6.83.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.36,20.6a7.28,7.28,0,0,1,.83-.66,6.18,6.18,0,0,0-.7.76.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.63,14.42a7.17,7.17,0,0,1,.85-.63,7.19,7.19,0,0,0-.73.73.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.42,14.82a6.22,6.22,0,0,1,.85-.63,6.29,6.29,0,0,0-.73.73.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.22,16.86a6.22,6.22,0,0,1,.86-.62,5.53,5.53,0,0,0-.73.72.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.46,22.09a5.6,5.6,0,0,1,.86-.61,6.27,6.27,0,0,0-.74.71.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.82,30.16a6.39,6.39,0,0,1,.79-.7,6,6,0,0,0-.66.79.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.28,34.38a5.9,5.9,0,0,1,.75-.75,6.52,6.52,0,0,0-.61.83.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.88,33a5.25,5.25,0,0,1,.74-.75A5.12,5.12,0,0,0,74,33a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.42,25.21a6.87,6.87,0,0,1,.64-.84,6.1,6.1,0,0,0-.49.9.07.07,0,0,1-.1.05.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.05,19.76a6.08,6.08,0,0,1,.6-.87,6.49,6.49,0,0,0-.45.92.08.08,0,0,1-.1.06.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.1,15.66a5.51,5.51,0,0,1,.53-.92,6.38,6.38,0,0,0-.38,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,12.2a5.37,5.37,0,0,1,.29-1,5.18,5.18,0,0,0-.13,1,.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.33,10.1a7.17,7.17,0,0,1,.13-1,7,7,0,0,0,0,1,.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.67,9.86a5.79,5.79,0,0,1,.15-1,7.08,7.08,0,0,0,0,1,.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.64,10.86a5.79,5.79,0,0,1,.18-1,5.85,5.85,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.45,13.77a5.79,5.79,0,0,1,.19-1,7.21,7.21,0,0,0,0,1,.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.57,19.61a5.79,5.79,0,0,1,.18-1,6,6,0,0,0,0,1,.09.09,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.7,27.41a5.93,5.93,0,0,1,.11-1.05,4.92,4.92,0,0,0,.05,1,.08.08,0,0,1-.06.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.19,36.4a6.55,6.55,0,0,1-.17-1,5.92,5.92,0,0,0,.32,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.69,44.06a5.85,5.85,0,0,1-.44-1,5.73,5.73,0,0,0,.57.85.08.08,0,1,1-.12.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,48.83a6.12,6.12,0,0,1-.55-.91,5.21,5.21,0,0,0,.66.79.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.61,49.57a6.67,6.67,0,0,1-.56-.9,5.71,5.71,0,0,0,.67.78.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.73,47.27a6,6,0,0,1-.51-.92,6.41,6.41,0,0,0,.62.81.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.18,38.64a6.29,6.29,0,0,1-.18-1,5.94,5.94,0,0,0,.33,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,32a5.17,5.17,0,0,1,.05-1,5.69,5.69,0,0,0,.1,1A.08.08,0,0,1,23,32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.33,27.13a6.73,6.73,0,0,1,.24-1,7.37,7.37,0,0,0-.08,1,.08.08,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.83,22.58a5.26,5.26,0,0,1,.29-1,5.28,5.28,0,0,0-.13,1,.07.07,0,0,1-.07.08.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.55,20a6.24,6.24,0,0,1,.29-1,7.16,7.16,0,0,0-.13,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.73,19.79a5.91,5.91,0,0,1,.13-1.05,5.85,5.85,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.58,21a7.35,7.35,0,0,1,.07-1.06,5.72,5.72,0,0,0,.09,1,.08.08,0,0,1-.06.1.1.1,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,26a5.65,5.65,0,0,1,.26-1,6,6,0,0,0-.1,1,.08.08,0,0,1-.07.09A.1.1,0,0,1,63,26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.55,29.63a7,7,0,0,1,.47-.95,5.54,5.54,0,0,0-.31,1,.07.07,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.34,34a6,6,0,0,1,.65-.83,8.09,8.09,0,0,0-.51.89.08.08,0,0,1-.1.06.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,37.72A6.62,6.62,0,0,1,73.7,37a5.81,5.81,0,0,0-.58.85.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.19,37.55a5.4,5.4,0,0,1,.71-.79,5.06,5.06,0,0,0-.56.86.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.55,35.11a5.92,5.92,0,0,1,.66-.82,7.35,7.35,0,0,0-.52.89.08.08,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.4,30.09a6.75,6.75,0,0,1,.5-.92,6.18,6.18,0,0,0-.35,1,.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.85,24.85a5.41,5.41,0,0,1,.32-1,5.17,5.17,0,0,0-.16,1,.08.08,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.81,21.09a6.61,6.61,0,0,1,.2-1,7.12,7.12,0,0,0,0,1,.08.08,0,0,1-.06.1.1.1,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.59,15.84a5.81,5.81,0,0,1,.12-1.05,5.73,5.73,0,0,0,0,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.39,15.32a6,6,0,0,1,.2-1,6,6,0,0,0,0,1,.07.07,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.89,15a4.91,4.91,0,0,1,.25-1,5.15,5.15,0,0,0-.09,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.43,16a4.76,4.76,0,0,1,.25-1,5.09,5.09,0,0,0-.09,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.51,17.88a6,6,0,0,1,.15-1.05,5.89,5.89,0,0,0,0,1,.07.07,0,0,1-.07.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.38,20.41a6.05,6.05,0,0,1,.06-1.05,5.69,5.69,0,0,0,.1,1,.08.08,0,1,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.46,21.68a6.23,6.23,0,0,1,0-1.06,5.45,5.45,0,0,0,.15,1,.08.08,0,0,1-.05.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.76,22.77a6.29,6.29,0,0,1,0-1.06,6.45,6.45,0,0,0,.17,1,.08.08,0,0,1,0,.11.1.1,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.28,22a5.34,5.34,0,0,1,0-1.06,5.58,5.58,0,0,0,.13,1,.08.08,0,0,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.46,21.47a6,6,0,0,1,.09-1,7,7,0,0,0,.06,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.09,17.41a6.71,6.71,0,0,1,.22-1,5.88,5.88,0,0,0-.06,1,.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.48,15.78a5.65,5.65,0,0,1,.26-1,5.11,5.11,0,0,0-.1,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.39,16a6,6,0,0,1,.14-1.05,5.87,5.87,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.54,19.34a6,6,0,0,1,.1-1.05,6.9,6.9,0,0,0,0,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.59,27.64a6,6,0,0,1,.14-1.05,5.87,5.87,0,0,0,0,1,.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.79,37.86a6,6,0,0,1,.48-1,5.59,5.59,0,0,0-.32,1,.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.12,42.91a5.94,5.94,0,0,1,.63-.84,6.1,6.1,0,0,0-.49.9.07.07,0,0,1-.1.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.09,45.94a5.45,5.45,0,0,1,.69-.81,5.85,5.85,0,0,0-.55.88.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.4,48.37a6.69,6.69,0,0,1,.73-.77,5.89,5.89,0,0,0-.59.85.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.39,49.34a5.77,5.77,0,0,1,.73-.75,5.72,5.72,0,0,0-.59.83.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.48,49.1a6.62,6.62,0,0,1,.72-.77,6.4,6.4,0,0,0-.58.85.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,47.49a6,6,0,0,1,.71-.79,7.52,7.52,0,0,0-.57.86.08.08,0,0,1-.1,0,.1.1,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.71,46.52a6,6,0,0,1,.68-.81,6.23,6.23,0,0,0-.53.88.09.09,0,0,1-.1,0s-.07-.06-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.75,45.33a5.51,5.51,0,0,1,.65-.84,7.37,7.37,0,0,0-.51.9.08.08,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.47,44.35a5.9,5.9,0,0,1,.64-.83,5.29,5.29,0,0,0-.49.9.09.09,0,0,1-.11,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.4,43.81A5.33,5.33,0,0,1,66,43a5.27,5.27,0,0,0-.46.92s-.06.07-.1.05a.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.54,42.73a5.32,5.32,0,0,1,.59-.87,5.63,5.63,0,0,0-.44.93.07.07,0,0,1-.1.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.81,41.7a5.36,5.36,0,0,1,.54-.9,5.14,5.14,0,0,0-.39,1,.08.08,0,0,1-.09.06.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.84,40.62a5.54,5.54,0,0,1,.51-.93,6.35,6.35,0,0,0-.36,1s0,.07-.09.07a.1.1,0,0,1-.07-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.89,39.56a5.15,5.15,0,0,1,.49-.93,5.29,5.29,0,0,0-.33,1,.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.81,39.38a6,6,0,0,1,.43-1,6,6,0,0,0-.27,1,.09.09,0,0,1-.09.08.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,38.26a6,6,0,0,1,.42-1,6.3,6.3,0,0,0-.26,1,.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.09,37.35a6.45,6.45,0,0,1,.39-1,6.25,6.25,0,0,0-.23,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.66,37.08a6.26,6.26,0,0,1,.31-1,6.13,6.13,0,0,0-.15,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.94,35.79a6.25,6.25,0,0,1,.3-1,6.09,6.09,0,0,0-.14,1,.07.07,0,0,1-.07.08.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.93,35.47a5.71,5.71,0,0,1,.23-1,5.1,5.1,0,0,0-.07,1,.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.28,34.26a5,5,0,0,1,.22-1,6.05,6.05,0,0,0-.06,1,.1.1,0,0,1-.07.09s-.09,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,34.36a5.79,5.79,0,0,1,.15-1,5.76,5.76,0,0,0,0,1,.08.08,0,0,1-.07.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.62,32.94a6,6,0,0,1,.15-1,5.87,5.87,0,0,0,0,1,.09.09,0,0,1-.06.1.08.08,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.52,32.78a5.2,5.2,0,0,1,.08-1.05,6.63,6.63,0,0,0,.07,1,.07.07,0,0,1-.05.1.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.07,31.41a7.06,7.06,0,0,1,.1-1.05,6.82,6.82,0,0,0,.06,1,.08.08,0,0,1-.06.1.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.09,31.55a6,6,0,0,1,0-1.05,5.69,5.69,0,0,0,.12,1,.09.09,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.19,32.09a5.38,5.38,0,0,1,0-1.06,7.78,7.78,0,0,0,.16,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.16,30.08a6.11,6.11,0,0,1,0-1,6.77,6.77,0,0,0,.12,1,.07.07,0,0,1-.05.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.35,30.58a5.45,5.45,0,0,1-.07-1.06,5.21,5.21,0,0,0,.22,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,30a6.2,6.2,0,0,1,0-1,6.45,6.45,0,0,0,.18,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.87,27.65a5.35,5.35,0,0,1-.07-1,5.21,5.21,0,0,0,.22,1,.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.08,27.76A6.46,6.46,0,0,1,57,26.71a6.25,6.25,0,0,0,.26,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.55,29A6.24,6.24,0,0,1,57.44,28a6.1,6.1,0,0,0,.26,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.7,28.57a7.62,7.62,0,0,1-.16-1.05,7.16,7.16,0,0,0,.31,1s0,.08,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,29.48a6.32,6.32,0,0,1-.15-1.05,6,6,0,0,0,.3,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.63,28.78a6.25,6.25,0,0,1-.05-1.05,6.1,6.1,0,0,0,.2,1,.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.34,27.5a6.1,6.1,0,0,1,0-1.05,6.47,6.47,0,0,0,.14,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.48,28a5.25,5.25,0,0,1,0-1.05,6.47,6.47,0,0,0,.14,1,.09.09,0,0,1-.05.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,27.61a6.25,6.25,0,0,1,0-1.06,5.71,5.71,0,0,0,.16,1,.09.09,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.43,26.35a6.25,6.25,0,0,1,0-1.06,5.71,5.71,0,0,0,.16,1,.09.09,0,0,1-.05.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.49,25.73a7.61,7.61,0,0,1-.07-1.05,6.09,6.09,0,0,0,.23,1,.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.65,25.29a6.19,6.19,0,0,1-.08-1.05,6.09,6.09,0,0,0,.23,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,24.66a6.38,6.38,0,0,1-.09-1.05,6.22,6.22,0,0,0,.24,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.33,24.14a5.47,5.47,0,0,1-.08-1.06,6.09,6.09,0,0,0,.23,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.45,23.28a6.23,6.23,0,0,1,0-1.05,5.21,5.21,0,0,0,.2,1,.1.1,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.78,23.05a5.29,5.29,0,0,1,0-1.05,6.45,6.45,0,0,0,.17,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.11,23.51a5.37,5.37,0,0,1,0-1.06,6.4,6.4,0,0,0,.16,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.52,25A5.14,5.14,0,0,1,43.6,24a5.7,5.7,0,0,0,.07,1,.07.07,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.22,26a5.79,5.79,0,0,1,.15-1,5.77,5.77,0,0,0,0,1,.09.09,0,0,1-.07.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.41,25.83a5.81,5.81,0,0,1,.22-1,6.05,6.05,0,0,0-.06,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.85,25.83a5.38,5.38,0,0,1,.3-1,5.11,5.11,0,0,0-.14,1,.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.33,24.86a6.26,6.26,0,0,1,.31-1,6,6,0,0,0-.15,1,.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.42,25.89a5.53,5.53,0,0,1,.24-1,5.92,5.92,0,0,0-.08,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.73,26a6,6,0,0,1,.1-1.05,5.65,5.65,0,0,0,.06,1,.08.08,0,0,1-.16.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.33,26.57a6.18,6.18,0,0,1,0-1.05,5.46,5.46,0,0,0,.18,1,.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.91,26.57a5.51,5.51,0,0,1-.1-1.06,5.23,5.23,0,0,0,.25,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.92,26.94a6.3,6.3,0,0,1-.14-1.05,5.73,5.73,0,0,0,.29,1,.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,27.94a6.32,6.32,0,0,1-.15-1.05,6.32,6.32,0,0,0,.3,1,.09.09,0,0,1,0,.11A.08.08,0,0,1,19,28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.44,29.48a5.47,5.47,0,0,1-.16-1,5.89,5.89,0,0,0,.3,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.25,32.77a6.59,6.59,0,0,1-.23-1,6.83,6.83,0,0,0,.37,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.77,33.78a5.55,5.55,0,0,1-.26-1,5.42,5.42,0,0,0,.4,1,.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.53,34a5.41,5.41,0,0,1-.22-1,5.77,5.77,0,0,0,.36,1,.08.08,0,0,1,0,.11s-.08,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.12,35.5a5.62,5.62,0,0,1-.32-1,5.27,5.27,0,0,0,.46.92.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.13,37a6.36,6.36,0,0,1-.41-1,5.44,5.44,0,0,0,.54.88.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.83,37.77a5.27,5.27,0,0,1-.52-.92,6.46,6.46,0,0,0,.63.81.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.59,38.33a6.37,6.37,0,0,1-.46-.95,6.67,6.67,0,0,0,.58.85.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.56,39.66a6.58,6.58,0,0,1-.55-.9,5.65,5.65,0,0,0,.66.78.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.44,40.6a5.24,5.24,0,0,1-.5-.93,7.74,7.74,0,0,0,.61.83.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.66,41.5a6.3,6.3,0,0,1-.63-.86,5.59,5.59,0,0,0,.73.73.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.52,42.07a6,6,0,0,1-.56-.89,6.27,6.27,0,0,0,.67.77.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.45,41.19a5.35,5.35,0,0,1-.57-.89,5.71,5.71,0,0,0,.67.78.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.41,41.8a6.46,6.46,0,0,1-.5-.93,6.52,6.52,0,0,0,.62.82.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.34,44.15a5.35,5.35,0,0,1-.57-.89,5.66,5.66,0,0,0,.68.77.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.32,45.38a5.27,5.27,0,0,1-.57-.88,5.59,5.59,0,0,0,.68.77.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.75,46.47a6.06,6.06,0,0,1-.59-.87,5.59,5.59,0,0,0,.7.75.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.36,47.05a6.08,6.08,0,0,1-.57-.89,6.44,6.44,0,0,0,.68.78.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.24,44.86a6,6,0,0,1-.52-.92,5.19,5.19,0,0,0,.64.81.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.19,35a7.13,7.13,0,0,1-.39-1,5.83,5.83,0,0,0,.52.88.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.25,29.42a5.76,5.76,0,0,1-.28-1,5.71,5.71,0,0,0,.42.94.09.09,0,0,1,0,.11s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.46,23.87a6.28,6.28,0,0,1-.06-1.05,6.1,6.1,0,0,0,.21,1,.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.17,19.32a6,6,0,0,1,0-1.05,6.77,6.77,0,0,0,.11,1s0,.08-.05.1a.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.76,15a6,6,0,0,1,.09-1,5.64,5.64,0,0,0,.07,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.89,12.34a6.86,6.86,0,0,1,.14-1,6.89,6.89,0,0,0,0,1,.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.18,11.17a6.79,6.79,0,0,1,.16-1.05,5.9,5.9,0,0,0,0,1,.08.08,0,0,1-.06.09.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.11,9.74a5.9,5.9,0,0,1,.18-1.05,6,6,0,0,0,0,1,.07.07,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.75,8.24a6,6,0,0,1,.12-1,5.8,5.8,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.61,7.9a7.06,7.06,0,0,1,.1-1.05,5.71,5.71,0,0,0,.06,1,.08.08,0,0,1-.06.1.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.12,8.73a5.93,5.93,0,0,1,.11-1.05,6.84,6.84,0,0,0,.05,1,.08.08,0,0,1-.06.1.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.64,11.27a6.84,6.84,0,0,1,.19-1,6,6,0,0,0,0,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.4,14.88a6.22,6.22,0,0,1,.36-1,6.22,6.22,0,0,0-.2,1,.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.33,17.73a6.45,6.45,0,0,1,.42-1,6.41,6.41,0,0,0-.26,1,.07.07,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.59,20.32a7,7,0,0,1,.44-1,6.37,6.37,0,0,0-.29,1,.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.19,21.5a5.53,5.53,0,0,1,.48-.94,6.11,6.11,0,0,0-.32,1,.08.08,0,0,1-.09.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,19.81a6.87,6.87,0,0,1,.5-.93,5.34,5.34,0,0,0-.34,1,.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.58,18.6a7.49,7.49,0,0,1,.47-.94,6.22,6.22,0,0,0-.32,1,.08.08,0,0,1-.08.07.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.53,17.07a6.09,6.09,0,0,1,.4-1,6.3,6.3,0,0,0-.24,1,.1.1,0,0,1-.09.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.5,14.84a5.73,5.73,0,0,1,.24-1,5.22,5.22,0,0,0-.08,1,.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.08,13.37a6.86,6.86,0,0,1,.14-1,6.89,6.89,0,0,0,0,1,.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.84,13A6,6,0,0,1,43,12a7.21,7.21,0,0,0,0,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.07,13.07a6.71,6.71,0,0,1,.22-1,5.94,5.94,0,0,0-.06,1,.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.66,14.64a5.79,5.79,0,0,1,.15-1,6.94,6.94,0,0,0,0,1,.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.17,16.43a6.15,6.15,0,0,1,.09-1.06,5.75,5.75,0,0,0,.07,1,.09.09,0,0,1-.06.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,16.76a5.15,5.15,0,0,1,.07-1.05,5.69,5.69,0,0,0,.09,1,.09.09,0,0,1-.06.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.88,17.61a6.11,6.11,0,0,1,.06-1.05,6.56,6.56,0,0,0,.09,1,.07.07,0,0,1-.05.1.07.07,0,0,1-.1-.05Z"/>
      </g>}
      {r_type_hair >= 1 &&< g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.32,49.46a1.1,1.1,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17.05-.25.12c.06-.08.11-.2.26-.22s.21.18.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,1,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.63,50.11a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.17.06-.24.13.1-.2.24-.23.23.16.27.26a1.46,1.46,0,0,1,.09.7.22.22,0,0,1-.23.18.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.07,64a1.16,1.16,0,0,0-.43-.35c-.08,0-.18-.07-.22,0s0,.18,0,.27a.39.39,0,0,1-.09-.32c.09-.15.25-.12.36-.11a1.41,1.41,0,0,1,.65.27.2.2,0,1,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.12,63.36A1.23,1.23,0,0,0,22.7,63c-.09,0-.19-.06-.22,0s0,.18,0,.28a.4.4,0,0,1-.09-.33c.09-.14.25-.11.36-.11a1.39,1.39,0,0,1,.66.27.19.19,0,0,1,0,.28.2.2,0,0,1-.28.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,64.4a1.27,1.27,0,0,0-.43-.35c-.08,0-.18-.06-.22,0a.39.39,0,0,0,0,.28.4.4,0,0,1-.09-.33c.09-.14.25-.11.36-.11a1.41,1.41,0,0,1,.65.27.2.2,0,0,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,64.52a1.24,1.24,0,0,0-.42-.36c-.08,0-.18-.07-.22,0s0,.18,0,.27a.39.39,0,0,1-.09-.32c.09-.15.26-.12.36-.11a1.5,1.5,0,0,1,.66.27.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0S21,64.53,21,64.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.16,64.2a1.16,1.16,0,0,0-.43-.35c-.08,0-.18-.07-.22,0a.39.39,0,0,0,0,.28.4.4,0,0,1-.09-.33c.08-.15.25-.12.35-.11a1.43,1.43,0,0,1,.66.26.21.21,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.09,64.51a1.13,1.13,0,0,0-.42-.35c-.08,0-.18-.07-.22,0s0,.18,0,.27c0-.09-.13-.17-.09-.32s.26-.12.36-.11a1.47,1.47,0,0,1,.66.26.21.21,0,0,1,.05.28.19.19,0,0,1-.28,0S24.11,64.53,24.09,64.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.55,64.19a1.11,1.11,0,0,0-.42-.34c-.09,0-.18-.07-.22,0s0,.18,0,.27c0-.09-.13-.18-.09-.32s.25-.12.36-.12a1.47,1.47,0,0,1,.66.26.22.22,0,0,1,.05.29.21.21,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.9,65a1.16,1.16,0,0,0-.43-.35c-.08,0-.18-.07-.22,0s0,.18,0,.27c-.05-.09-.13-.17-.09-.32s.25-.12.35-.11a1.43,1.43,0,0,1,.66.26.21.21,0,0,1,.05.28A.2.2,0,0,1,26,65,.12.12,0,0,1,25.9,65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.88,65.07a1.05,1.05,0,0,0-.42-.35c-.08,0-.18-.07-.22,0s0,.17,0,.27c0-.09-.12-.18-.08-.32s.25-.12.36-.11a1.41,1.41,0,0,1,.65.27.21.21,0,0,1,0,.28.22.22,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.84,65.44a1.13,1.13,0,0,0-.42-.35c-.08,0-.18-.07-.22,0s0,.17,0,.27a.4.4,0,0,1-.09-.33c.09-.14.26-.11.36-.1a1.5,1.5,0,0,1,.66.27.19.19,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.94,63.52a1.14,1.14,0,0,0-.42-.35c-.08,0-.18-.07-.22,0s0,.18,0,.27c0-.09-.13-.17-.09-.32s.25-.12.36-.11a1.41,1.41,0,0,1,.65.27.2.2,0,0,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.92,65.37A1.13,1.13,0,0,0,24.5,65c-.08,0-.18-.07-.22,0s0,.18,0,.28c0-.1-.13-.18-.08-.33s.25-.12.35-.11a1.45,1.45,0,0,1,.66.27.2.2,0,0,1,.05.28.22.22,0,0,1-.29.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.51,63.28a1.11,1.11,0,0,0-.42-.34c-.08,0-.18-.07-.22,0s0,.18,0,.28c-.05-.09-.14-.18-.1-.33s.25-.12.36-.11a1.37,1.37,0,0,1,.66.26.2.2,0,1,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.57,63.23a1.16,1.16,0,0,0-.43-.35c-.08,0-.18-.06-.22,0s0,.17,0,.27a.39.39,0,0,1-.09-.32c.08-.15.25-.12.35-.12a1.43,1.43,0,0,1,.66.26.2.2,0,0,1,0,.28.2.2,0,0,1-.28.05A.12.12,0,0,1,26.57,63.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.21,63.48a1.25,1.25,0,0,0-.43-.34c-.08,0-.18-.07-.22,0s0,.18,0,.27c-.05-.09-.13-.17-.09-.32s.25-.12.35-.11a1.43,1.43,0,0,1,.66.26.2.2,0,0,1,.05.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.88,65.55a1.21,1.21,0,0,0-.42-.35c-.09,0-.18-.07-.22,0s0,.18,0,.27a.39.39,0,0,1-.09-.32c.09-.15.26-.12.36-.11a1.5,1.5,0,0,1,.66.27.19.19,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.9,62.86a1.13,1.13,0,0,0-.42-.35c-.08,0-.18-.07-.22,0s0,.18,0,.27c-.05-.09-.14-.18-.1-.32s.26-.12.36-.11a1.43,1.43,0,0,1,.66.26.21.21,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.56,62.37a1.25,1.25,0,0,0-.43-.34c-.08,0-.18-.07-.22,0a.39.39,0,0,0,0,.28.4.4,0,0,1-.09-.33c.08-.14.25-.12.35-.11a1.43,1.43,0,0,1,.66.26.19.19,0,0,1,.05.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,62a1.09,1.09,0,0,0-.43-.35c-.08,0-.18-.06-.22,0a.39.39,0,0,0,0,.28.39.39,0,0,1-.09-.32c.08-.15.25-.12.35-.12a1.43,1.43,0,0,1,.66.26.2.2,0,1,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.85,61.5a1.23,1.23,0,0,0-.43-.34c-.09,0-.19-.07-.22,0s0,.18,0,.28c-.05-.09-.14-.18-.1-.33s.25-.12.35-.11a1.35,1.35,0,0,1,.67.25.2.2,0,1,1-.23.33A.12.12,0,0,1,25.85,61.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.13,60.67a1.11,1.11,0,0,0-.42-.34c-.09,0-.19-.07-.22,0s0,.18,0,.27c0-.09-.13-.17-.09-.32s.25-.12.35-.12a1.47,1.47,0,0,1,.67.26.2.2,0,1,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.33,59.9a1.23,1.23,0,0,0-.43-.34c-.09,0-.19-.07-.22,0s0,.18,0,.28c-.05-.09-.14-.18-.1-.33s.25-.12.36-.12a1.47,1.47,0,0,1,.66.26.2.2,0,1,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.29,59a1.14,1.14,0,0,0-.43-.34c-.08,0-.18-.06-.22,0s0,.17,0,.27a.41.41,0,0,1-.1-.32c.09-.15.25-.12.36-.12a1.35,1.35,0,0,1,.66.25.2.2,0,0,1,.05.28.19.19,0,0,1-.27.05A.1.1,0,0,1,26.29,59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.45,58.23a1.07,1.07,0,0,0-.43-.34c-.08,0-.18-.06-.22,0s0,.18,0,.27a.41.41,0,0,1-.1-.32c.09-.15.26-.12.36-.12a1.35,1.35,0,0,1,.66.25.21.21,0,0,1,.06.28.2.2,0,0,1-.28.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,57.13a1.14,1.14,0,0,0-.43-.34c-.08,0-.18-.07-.22,0s0,.18,0,.28a.44.44,0,0,1-.1-.33c.09-.15.25-.12.36-.11a1.35,1.35,0,0,1,.66.25.19.19,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.3,56.65a1.23,1.23,0,0,0-.43-.34c-.09,0-.18-.06-.22,0s0,.17,0,.27c-.05-.09-.14-.17-.1-.32s.25-.13.36-.12a1.35,1.35,0,0,1,.66.25.19.19,0,0,1,.05.28.2.2,0,0,1-.28.05A.12.12,0,0,1,26.3,56.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.72,55.66a1.23,1.23,0,0,0-.43-.34c-.08,0-.18-.07-.22,0s0,.18,0,.28c0-.09-.14-.18-.1-.33a.34.34,0,0,1,.36-.11,1.35,1.35,0,0,1,.66.25.2.2,0,0,1-.23.33A.12.12,0,0,1,24.72,55.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.53,54.83a1.25,1.25,0,0,0-.43-.34c-.08,0-.18-.06-.22,0s0,.18,0,.27a.41.41,0,0,1-.1-.32c.09-.15.25-.12.36-.12a1.58,1.58,0,0,1,.66.25.21.21,0,0,1-.23.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.24,53a1.27,1.27,0,0,0-.43-.35c-.08,0-.18-.06-.22,0a.39.39,0,0,0,0,.28.4.4,0,0,1-.09-.33c.09-.14.25-.11.36-.11a1.41,1.41,0,0,1,.65.27.2.2,0,1,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.14,52.27a1.05,1.05,0,0,0-.42-.35c-.08,0-.18-.07-.22,0s0,.18,0,.27a.39.39,0,0,1-.09-.32c.08-.15.25-.12.35-.11a1.43,1.43,0,0,1,.66.26.22.22,0,0,1,.05.29.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.72,51.64a1.23,1.23,0,0,0-.42-.35c-.08,0-.18-.06-.22,0s0,.18,0,.28c0-.09-.13-.18-.09-.33s.26-.11.36-.11a1.5,1.5,0,0,1,.66.27.2.2,0,1,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.53,50.69a1.05,1.05,0,0,0-.42-.35c-.08,0-.18-.07-.22,0a.44.44,0,0,0,0,.28.43.43,0,0,1-.08-.33c.09-.14.25-.11.36-.1a1.41,1.41,0,0,1,.65.27.2.2,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.89,50.2a1.13,1.13,0,0,0-.42-.35c-.08,0-.18-.08-.22,0s0,.18,0,.28a.36.36,0,0,1-.08-.33c.09-.15.25-.11.35-.1a1.45,1.45,0,0,1,.66.27.2.2,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.92,49.42a1,1,0,0,0-.41-.36c-.09-.05-.18-.08-.22,0s0,.18,0,.27c0-.09-.12-.18-.08-.32s.26-.11.36-.1a1.33,1.33,0,0,1,.65.28.2.2,0,0,1-.24.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.31,48.94a1.21,1.21,0,0,0-.41-.36c-.08,0-.18-.07-.22,0s0,.17,0,.27a.36.36,0,0,1-.08-.33c.09-.14.25-.11.36-.1a1.61,1.61,0,0,1,.65.28.21.21,0,1,1-.25.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.48,48.19a1.1,1.1,0,0,0-.4-.37c-.08,0-.18-.08-.22,0s0,.18,0,.27c0-.09-.12-.18-.08-.32s.26-.11.37-.1a1.34,1.34,0,0,1,.64.3.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0A.12.12,0,0,1,22.48,48.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.49,47.71a1.14,1.14,0,0,0-.41-.37c-.08,0-.17-.08-.21,0s0,.18,0,.27a.38.38,0,0,1-.08-.32c.1-.15.26-.11.36-.1a1.37,1.37,0,0,1,.65.3.2.2,0,1,1-.25.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.24,47a1.2,1.2,0,0,0-.4-.37c-.08,0-.18-.08-.22,0s0,.18,0,.28a.38.38,0,0,1-.07-.33c.09-.15.26-.1.36-.09a1.44,1.44,0,0,1,.64.3.2.2,0,0,1-.25.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.93,45.73a1.29,1.29,0,0,0-.39-.38c-.08-.05-.18-.08-.22-.05s0,.18,0,.28a.42.42,0,0,1-.06-.34c.1-.14.26-.09.37-.08a1.51,1.51,0,0,1,.63.32.22.22,0,0,1,0,.29.2.2,0,0,1-.28,0A.15.15,0,0,1,22.93,45.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.86,45.68a1.18,1.18,0,0,0-.39-.38c-.08,0-.18-.08-.22,0s0,.17,0,.27a.37.37,0,0,1-.07-.33c.1-.14.26-.1.37-.08a1.55,1.55,0,0,1,.63.31.21.21,0,0,1,0,.29.22.22,0,0,1-.29,0A.15.15,0,0,1,24.86,45.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.13,44.79a1.1,1.1,0,0,0-.39-.38c-.08,0-.17-.09-.21-.06s0,.18,0,.28c0-.1-.11-.19-.05-.33a.32.32,0,0,1,.36-.08,1.42,1.42,0,0,1,.63.33.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0A.15.15,0,0,1,24.13,44.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.21,43.92a1.08,1.08,0,0,0-.37-.4c-.08-.05-.17-.09-.22-.06s0,.18,0,.28a.48.48,0,0,1-.05-.34c.11-.13.27-.08.37-.06a1.36,1.36,0,0,1,.62.34.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.42,44.05a1.12,1.12,0,0,0-.39-.39c-.07,0-.17-.09-.21-.06s0,.18,0,.27a.37.37,0,0,1-.05-.33c.1-.14.26-.09.37-.07a1.47,1.47,0,0,1,.62.34.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0A.21.21,0,0,1,25.42,44.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.8,42.94a1.18,1.18,0,0,0-.37-.41c-.07-.05-.17-.09-.21-.06a.41.41,0,0,0,0,.28c0-.1-.1-.2,0-.34s.27-.08.37-.06a1.37,1.37,0,0,1,.61.36.2.2,0,1,1-.27.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.11,42a1.1,1.1,0,0,0-.36-.42c-.08,0-.17-.1-.21-.07s-.05.17,0,.27a.37.37,0,0,1,0-.33c.11-.13.27-.07.37-.05a1.47,1.47,0,0,1,.6.37.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.61,42.55a1.29,1.29,0,0,0-.37-.41c-.07,0-.17-.09-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.33s.27-.08.37-.06a1.37,1.37,0,0,1,.61.36.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0A.21.21,0,0,1,26.61,42.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.86,42.59a1.29,1.29,0,0,0-.37-.41c-.08,0-.17-.09-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.33s.27-.08.37-.06a1.43,1.43,0,0,1,.61.36.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.23,40.93a1.21,1.21,0,0,0-.34-.42c-.08-.06-.17-.11-.21-.08s-.06.17-.05.27c0-.1-.1-.2,0-.33a.34.34,0,0,1,.38,0,1.49,1.49,0,0,1,.59.39.21.21,0,1,1-.3.28A.25.25,0,0,1,25.23,40.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.27,41.33a1.05,1.05,0,0,0-.35-.42c-.07,0-.16-.1-.21-.07a.38.38,0,0,0,0,.27c0-.1-.1-.2,0-.33s.27-.07.37-.05a1.56,1.56,0,0,1,.6.38.22.22,0,0,1,0,.29.19.19,0,0,1-.28,0A.15.15,0,0,1,28.27,41.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.54,39.63a1.14,1.14,0,0,0-.32-.45c-.07,0-.16-.11-.21-.08s-.06.17-.06.27a.4.4,0,0,1,0-.34c.12-.12.27-.05.37,0a1.54,1.54,0,0,1,.58.42.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0A.21.21,0,0,1,25.54,39.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.88,40.31a1.07,1.07,0,0,0-.34-.43c-.07-.06-.16-.11-.21-.08a.38.38,0,0,0,0,.27c0-.1-.09-.2,0-.33s.27-.07.37,0a1.51,1.51,0,0,1,.59.4.22.22,0,0,1,0,.29.21.21,0,0,1-.29,0S27.89,40.33,27.88,40.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.1,39.5a1.14,1.14,0,0,0-.33-.44c-.07-.06-.16-.11-.21-.08s-.06.17-.06.27a.44.44,0,0,1,0-.34.33.33,0,0,1,.38,0,1.38,1.38,0,0,1,.57.41.19.19,0,0,1,0,.28.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,38.28a1,1,0,0,0-.31-.45c-.07-.06-.16-.11-.2-.09s-.07.17-.07.27a.37.37,0,0,1,0-.34c.12-.12.27,0,.37,0a1.31,1.31,0,0,1,.56.44.2.2,0,0,1-.31.25A.25.25,0,0,1,27,38.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.17,38.92a1.11,1.11,0,0,0-.32-.44c-.07-.06-.16-.11-.21-.09s-.06.17-.06.27a.4.4,0,0,1,0-.34c.12-.12.27,0,.37,0a1.43,1.43,0,0,1,.57.42.2.2,0,0,1,0,.29.2.2,0,0,1-.28,0A.25.25,0,0,1,29.17,38.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.52,38a1,1,0,0,0-.31-.45c-.06-.06-.15-.12-.2-.09s-.07.16-.07.26c0-.1-.08-.2,0-.33a.33.33,0,0,1,.38,0,1.35,1.35,0,0,1,.55.44.2.2,0,0,1-.31.25A.25.25,0,0,1,28.52,38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.24,36.77a1.22,1.22,0,0,0-.29-.46c-.06-.07-.14-.13-.19-.1s-.08.16-.09.26a.45.45,0,0,1,0-.34c.13-.11.28,0,.38,0a1.57,1.57,0,0,1,.53.47.2.2,0,0,1-.33.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.5,36.54a1.05,1.05,0,0,0-.28-.47c-.07-.07-.15-.13-.2-.1s-.08.16-.09.26a.42.42,0,0,1,0-.34c.13-.11.27,0,.37,0a1.48,1.48,0,0,1,.53.47.19.19,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.61,36.39a1.05,1.05,0,0,0-.28-.47c-.06-.07-.15-.13-.19-.1s-.08.16-.09.26a.38.38,0,0,1,0-.34c.13-.11.28,0,.38,0a1.52,1.52,0,0,1,.53.46.22.22,0,0,1-.05.29.2.2,0,0,1-.28,0A.25.25,0,0,1,29.61,36.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.33,35a1.27,1.27,0,0,0-.25-.49c-.07-.07-.14-.13-.19-.11s-.09.15-.11.25c0-.1-.05-.22,0-.33s.27,0,.37,0a1.44,1.44,0,0,1,.5.51.19.19,0,0,1-.07.27.19.19,0,0,1-.27-.06A.11.11,0,0,1,28.33,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.53,34.53a1.18,1.18,0,0,0-.24-.49c-.06-.07-.14-.14-.19-.12s-.09.16-.11.26a.42.42,0,0,1,.05-.34c.14-.1.28,0,.37,0a1.34,1.34,0,0,1,.49.51.2.2,0,0,1-.34.2S29.54,34.55,29.53,34.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.14,35a1.17,1.17,0,0,0-.25-.48c-.06-.07-.14-.14-.19-.12s-.09.16-.1.26a.42.42,0,0,1,0-.34c.14-.1.28,0,.37,0a1.41,1.41,0,0,1,.5.5.21.21,0,0,1-.06.28.2.2,0,0,1-.28-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.12,33.29a1.26,1.26,0,0,0-.22-.51c0-.07-.12-.14-.18-.13s-.1.15-.12.25a.41.41,0,0,1,.07-.33c.15-.09.28,0,.37.07a1.44,1.44,0,0,1,.46.54.2.2,0,0,1-.36.18A.19.19,0,0,1,30.12,33.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.31,33.18a1.08,1.08,0,0,0-.21-.5c-.05-.08-.12-.15-.18-.14s-.1.15-.12.25a.41.41,0,0,1,.07-.33c.15-.09.28,0,.37.07a1.37,1.37,0,0,1,.45.55.2.2,0,1,1-.36.18A.24.24,0,0,1,31.31,33.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.43,31.93a1.25,1.25,0,0,0-.18-.52c-.05-.08-.12-.15-.17-.14s-.11.14-.14.23c0-.1,0-.22.1-.32s.27,0,.36.1a1.45,1.45,0,0,1,.41.57.2.2,0,0,1-.37.15A.17.17,0,0,1,30.43,31.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.41,33a1.16,1.16,0,0,0-.2-.51c-.05-.08-.13-.15-.18-.14s-.1.15-.13.24c0-.1,0-.22.08-.32s.28,0,.37.08a1.36,1.36,0,0,1,.44.55.2.2,0,0,1-.09.27.21.21,0,0,1-.27-.1A.19.19,0,0,1,32.41,33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.16,31A1.18,1.18,0,0,0,31,30.5c0-.08-.1-.16-.16-.15s-.12.13-.15.22c0-.1,0-.22.11-.31s.28,0,.36.11a1.47,1.47,0,0,1,.38.6.2.2,0,1,1-.38.13A.17.17,0,0,1,31.16,31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,31.85a1.1,1.1,0,0,0-.17-.53c0-.07-.11-.15-.16-.14s-.12.14-.15.23a.41.41,0,0,1,.1-.32c.15-.07.28,0,.36.1a1.45,1.45,0,0,1,.41.58.2.2,0,0,1-.11.26.2.2,0,0,1-.26-.11A.19.19,0,0,1,32,31.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.5,32.47a1.12,1.12,0,0,0-.18-.52c0-.07-.12-.15-.17-.14S33,32,33,32.05a.39.39,0,0,1,.09-.33c.15-.08.28,0,.36.1a1.36,1.36,0,0,1,.43.56.21.21,0,0,1-.11.27.19.19,0,0,1-.26-.11A.19.19,0,0,1,33.5,32.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.87,33.72a1.11,1.11,0,0,0-.21-.51c0-.07-.13-.14-.18-.13s-.1.15-.12.25c0-.11,0-.22.07-.33s.28,0,.37.07a1.55,1.55,0,0,1,.45.55.2.2,0,0,1-.09.27.21.21,0,0,1-.27-.1A.19.19,0,0,1,34.87,33.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.23,30.55a1.19,1.19,0,0,0-.12-.53c0-.08-.11-.16-.16-.16s-.12.13-.16.22a.37.37,0,0,1,.12-.31.33.33,0,0,1,.35.13,1.39,1.39,0,0,1,.37.61.2.2,0,0,1-.39.12A.19.19,0,0,1,32.23,30.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,31.16a1.05,1.05,0,0,0-.14-.53c0-.08-.11-.16-.16-.15s-.12.13-.16.22c0-.1,0-.22.12-.31s.27.05.35.11a1.49,1.49,0,0,1,.39.6.21.21,0,0,1-.13.26.21.21,0,0,1-.26-.13S33,31.18,33,31.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.08,31.75a1.13,1.13,0,0,0-.15-.53c0-.08-.11-.16-.16-.15s-.12.14-.15.23c0-.1,0-.22.11-.32s.27.05.35.12a1.3,1.3,0,0,1,.39.59.2.2,0,0,1-.12.26.21.21,0,0,1-.26-.12A.25.25,0,0,1,35.08,31.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.89,32.61a1.1,1.1,0,0,0-.17-.52c0-.08-.12-.16-.17-.15s-.11.14-.14.24c0-.1,0-.22.1-.32s.27,0,.36.1a1.45,1.45,0,0,1,.41.57.2.2,0,0,1-.11.26.21.21,0,0,1-.28-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,33.63a1.12,1.12,0,0,0-.2-.52c-.05-.07-.12-.14-.17-.13s-.11.14-.13.24a.39.39,0,0,1,.08-.33c.15-.08.28,0,.36.08a1.38,1.38,0,0,1,.44.56.2.2,0,0,1-.09.27.21.21,0,0,1-.27-.1A.19.19,0,0,1,36,33.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.07,31.33a1.4,1.4,0,0,0-.14-.53c0-.08-.11-.16-.17-.15s-.12.13-.15.23a.42.42,0,0,1,.11-.32c.16-.07.28.05.36.12a1.38,1.38,0,0,1,.38.6.19.19,0,0,1-.12.25.2.2,0,0,1-.26-.12A.19.19,0,0,1,34.07,31.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.69,32.63a1.35,1.35,0,0,0-.18-.52c0-.08-.12-.15-.17-.14s-.11.14-.14.24a.38.38,0,0,1,.09-.33c.15-.08.28,0,.36.09a1.44,1.44,0,0,1,.42.57.2.2,0,0,1-.1.27.21.21,0,0,1-.27-.11A.17.17,0,0,1,34.69,32.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.53,33.66a1.07,1.07,0,0,0-.21-.51c-.06-.07-.13-.14-.18-.13s-.1.15-.13.25a.39.39,0,0,1,.08-.33c.14-.09.27,0,.36.07a1.37,1.37,0,0,1,.46.54.2.2,0,1,1-.36.18A.19.19,0,0,1,33.53,33.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.53,34.94a1.1,1.1,0,0,0-.24-.5c0-.07-.13-.13-.18-.12s-.1.16-.11.26c0-.11,0-.22,0-.34a.35.35,0,0,1,.37,0,1.45,1.45,0,0,1,.49.52.2.2,0,0,1-.08.27.19.19,0,0,1-.27-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.79,35.15a1.2,1.2,0,0,0-.26-.48c-.06-.07-.14-.14-.19-.12s-.09.16-.1.26a.42.42,0,0,1,0-.34c.14-.1.28,0,.38,0a1.49,1.49,0,0,1,.5.5.21.21,0,0,1-.07.28.2.2,0,0,1-.28-.07A.19.19,0,0,1,32.79,35.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.28,36.28a1.13,1.13,0,0,0-.27-.47c-.07-.07-.15-.13-.2-.11s-.08.16-.09.26c0-.1-.06-.21,0-.33s.28,0,.37,0a1.42,1.42,0,0,1,.53.48.21.21,0,0,1-.06.28.2.2,0,0,1-.28-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.92,37.28a1.25,1.25,0,0,0-.29-.47c-.06-.06-.15-.12-.2-.09s-.07.16-.08.26a.38.38,0,0,1,0-.34c.13-.11.28,0,.37,0a1.39,1.39,0,0,1,.54.46.19.19,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.49,37.58a1.18,1.18,0,0,0-.3-.46C32.12,37.06,32,37,32,37s-.08.16-.08.26a.41.41,0,0,1,0-.34c.13-.11.28,0,.37,0a1.43,1.43,0,0,1,.55.44.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.21.21,0,0,1,32.49,37.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.21,39.25a1.11,1.11,0,0,0-.32-.44c-.07-.06-.16-.11-.21-.09s-.06.17-.06.27a.4.4,0,0,1,0-.34c.12-.12.28,0,.37,0a1.43,1.43,0,0,1,.57.42.2.2,0,0,1,0,.29.2.2,0,0,1-.28,0A.25.25,0,0,1,33.21,39.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,39.77a1.28,1.28,0,0,0-.34-.44c-.07-.06-.16-.11-.21-.08s-.06.17-.05.27a.4.4,0,0,1,0-.34c.12-.12.28,0,.38,0a1.56,1.56,0,0,1,.58.4.21.21,0,0,1-.31.27S32,39.79,32,39.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,41.05a1.14,1.14,0,0,0-.35-.42c-.07-.05-.16-.1-.21-.07s-.05.17,0,.27a.35.35,0,0,1,0-.33c.11-.13.27-.07.37,0a1.49,1.49,0,0,1,.59.39.19.19,0,0,1,0,.28.21.21,0,0,1-.29,0A.43.43,0,0,1,32,41.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.92,42.41a1.29,1.29,0,0,0-.37-.41c-.07,0-.16-.09-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.33s.26-.08.37-.06a1.42,1.42,0,0,1,.6.37.2.2,0,0,1,0,.28.22.22,0,0,1-.29,0A.16.16,0,0,1,31.92,42.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.81,43.38a1.08,1.08,0,0,0-.37-.4c-.08,0-.17-.1-.21-.06s-.05.17,0,.27c0-.1-.1-.19,0-.33s.26-.09.37-.07a1.51,1.51,0,0,1,.61.35.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.9,44.69a1.08,1.08,0,0,0-.38-.39c-.08,0-.17-.09-.21-.06s0,.18,0,.28a.39.39,0,0,1-.05-.34c.1-.13.26-.09.36-.07a1.45,1.45,0,0,1,.63.34.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.71,45.47a1.18,1.18,0,0,0-.39-.38c-.08-.05-.17-.08-.22-.05s0,.18,0,.28c0-.1-.12-.19-.07-.33s.27-.1.37-.09a1.51,1.51,0,0,1,.63.32.2.2,0,0,1,0,.29.2.2,0,0,1-.28,0A.15.15,0,0,1,29.71,45.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.48,46.72a1.2,1.2,0,0,0-.4-.37c-.08-.05-.18-.08-.22-.05s0,.18,0,.28c0-.1-.12-.19-.07-.33s.26-.1.36-.09a1.32,1.32,0,0,1,.64.31.2.2,0,1,1-.25.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.05,47.8a1.2,1.2,0,0,0-.4-.37c-.08,0-.18-.07-.22,0s0,.18,0,.28a.44.44,0,0,1-.08-.33.34.34,0,0,1,.37-.09,1.42,1.42,0,0,1,.64.29.2.2,0,1,1-.25.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.88,48a1.14,1.14,0,0,0-.41-.37c-.08,0-.17-.07-.21,0s0,.18,0,.28a.4.4,0,0,1-.08-.33c.09-.14.26-.11.36-.1a1.45,1.45,0,0,1,.65.29.2.2,0,1,1-.25.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.48,49.4a1.25,1.25,0,0,0-.42-.36c-.08,0-.18-.07-.22,0s0,.17,0,.27c-.05-.09-.13-.18-.08-.32s.25-.12.36-.11a1.48,1.48,0,0,1,.65.28.2.2,0,0,1-.24.32A.12.12,0,0,1,29.48,49.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.78,49.65a1.13,1.13,0,0,0-.42-.35c-.08,0-.18-.07-.22,0a.44.44,0,0,0,0,.28.43.43,0,0,1-.08-.33c.09-.14.25-.11.36-.1a1.46,1.46,0,0,1,.65.27.2.2,0,0,1,0,.28.2.2,0,0,1-.28.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.47,50.87a1.13,1.13,0,0,0-.42-.35c-.08,0-.18-.07-.22,0s0,.18,0,.27c0-.09-.13-.17-.09-.32s.26-.12.36-.11a1.47,1.47,0,0,1,.66.26.22.22,0,0,1,0,.29.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.17,52a1.14,1.14,0,0,0-.43-.34c-.08,0-.18-.07-.22,0s0,.18,0,.27a.37.37,0,0,1-.09-.32c.08-.15.25-.12.35-.11a1.33,1.33,0,0,1,.66.26.19.19,0,0,1,.05.28.2.2,0,0,1-.28.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.89,52.34a1.23,1.23,0,0,0-.42-.35c-.09,0-.18-.06-.22,0s0,.18,0,.27c-.05-.09-.14-.18-.1-.32s.25-.12.36-.12a1.47,1.47,0,0,1,.66.26.2.2,0,0,1,.05.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.81,53.76a1.35,1.35,0,0,0-.43-.34c-.09,0-.18-.06-.22,0s0,.18,0,.27c-.05-.09-.14-.17-.1-.32s.25-.12.36-.12a1.58,1.58,0,0,1,.66.25.2.2,0,0,1,.05.28.21.21,0,0,1-.28.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.08,53.58a1.16,1.16,0,0,0-.43-.34c-.08,0-.18-.06-.22,0s0,.17,0,.27a.39.39,0,0,1-.09-.32c.08-.15.25-.12.35-.12a1.53,1.53,0,0,1,.66.25.21.21,0,0,1,.06.29.21.21,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,55a1.23,1.23,0,0,0-.43-.34c-.09,0-.19-.06-.22,0s0,.18,0,.28c0-.09-.14-.18-.1-.32s.25-.13.35-.12a1.35,1.35,0,0,1,.67.25A.2.2,0,1,1,27,55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.26,54.54a1.16,1.16,0,0,0-.43-.34c-.08,0-.18-.06-.22,0s0,.17,0,.27a.39.39,0,0,1-.09-.32c.08-.15.25-.12.35-.12a1.43,1.43,0,0,1,.66.26.19.19,0,0,1,.05.28.2.2,0,0,1-.28.05A.15.15,0,0,1,24.26,54.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,55a1.16,1.16,0,0,0-.43-.35c-.08,0-.18-.06-.22,0a.39.39,0,0,0,0,.28.43.43,0,0,1-.1-.32.34.34,0,0,1,.36-.12,1.47,1.47,0,0,1,.66.26A.2.2,0,1,1,23,55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.59,56a1.23,1.23,0,0,0-.43-.34c-.09,0-.18-.07-.22,0s0,.18,0,.28c-.05-.09-.14-.18-.1-.33s.25-.12.36-.11a1.45,1.45,0,0,1,.66.25.22.22,0,0,1,.05.29.2.2,0,0,1-.28.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.47,56.81a1.25,1.25,0,0,0-.43-.35c-.09,0-.18-.06-.22,0s0,.18,0,.27c0-.09-.14-.17-.1-.32s.25-.12.36-.12a1.47,1.47,0,0,1,.66.26.2.2,0,1,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.57,56.85a1.21,1.21,0,0,0-.42-.34c-.08,0-.18-.07-.22,0s0,.18,0,.27c0-.09-.13-.18-.09-.32s.26-.12.36-.11a1.47,1.47,0,0,1,.66.26.2.2,0,0,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.81,57.21a1.14,1.14,0,0,0-.43-.34c-.08,0-.18-.07-.22,0s0,.18,0,.27a.41.41,0,0,1-.1-.32c.09-.15.26-.12.36-.11a1.35,1.35,0,0,1,.66.25.21.21,0,0,1,.05.28.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.74,58a1.27,1.27,0,0,0-.43-.35c-.08,0-.18-.06-.22,0s0,.17,0,.27a.39.39,0,0,1-.09-.32c.08-.15.25-.12.35-.12a1.43,1.43,0,0,1,.66.26.21.21,0,0,1,.05.29.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.9,59.07a1,1,0,0,0-.43-.34c-.08,0-.18-.07-.22,0s0,.18,0,.27c-.05-.09-.13-.17-.09-.32s.25-.12.35-.12a1.43,1.43,0,0,1,.66.26.21.21,0,0,1,.06.28.22.22,0,0,1-.29,0A.12.12,0,0,1,22.9,59.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.82,58.45a1.07,1.07,0,0,0-.43-.34c-.08,0-.18-.07-.22,0s0,.18,0,.27c-.05-.09-.13-.17-.09-.32s.25-.12.35-.11a1.31,1.31,0,0,1,.66.25.19.19,0,0,1,0,.28.2.2,0,0,1-.28.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.82,59.47a1.11,1.11,0,0,0-.42-.34c-.09,0-.19-.07-.22,0s0,.18,0,.27a.39.39,0,0,1-.09-.32c.09-.15.25-.12.36-.12a1.47,1.47,0,0,1,.66.26.21.21,0,0,1,.05.28.2.2,0,0,1-.28.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.8,59.15a1.23,1.23,0,0,0-.42-.35c-.08,0-.18-.06-.22,0s0,.17,0,.27c0-.09-.13-.18-.09-.32s.26-.12.36-.12a1.47,1.47,0,0,1,.66.26.22.22,0,0,1,.05.29.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.85,58.58a1.21,1.21,0,0,0-.42-.34c-.09,0-.19-.06-.22,0s0,.17,0,.27a.36.36,0,0,1-.1-.32c.08-.15.25-.12.35-.12a1.57,1.57,0,0,1,.67.25.21.21,0,0,1,.05.28.2.2,0,0,1-.28.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.87,57.88a1.23,1.23,0,0,0-.43-.34c-.08,0-.18-.07-.22,0s0,.18,0,.28c-.05-.09-.14-.18-.1-.33s.25-.12.36-.11a1.35,1.35,0,0,1,.66.25.2.2,0,0,1,.05.28.2.2,0,0,1-.28,0A.12.12,0,0,1,22.87,57.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.88,55.91a1.16,1.16,0,0,0-.43-.35c-.08,0-.18-.06-.22,0a.39.39,0,0,0,0,.28.4.4,0,0,1-.09-.33c.09-.14.25-.11.36-.11a1.4,1.4,0,0,1,.65.26.21.21,0,0,1,.05.29.19.19,0,0,1-.28,0A.12.12,0,0,1,20.88,55.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.57,54.67a1.13,1.13,0,0,0-.42-.35c-.09,0-.18-.07-.22,0s0,.18,0,.28a.4.4,0,0,1-.09-.33.34.34,0,0,1,.36-.11,1.47,1.47,0,0,1,.66.26.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,52.94a1.16,1.16,0,0,0-.43-.35c-.08,0-.18-.06-.22,0a.39.39,0,0,0,0,.28.43.43,0,0,1-.1-.32c.09-.15.26-.12.36-.12a1.47,1.47,0,0,1,.66.26.21.21,0,0,1,.05.28A.19.19,0,0,1,24,53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.47,50.65a1.05,1.05,0,0,0-.42-.35c-.08,0-.18-.07-.22,0a.39.39,0,0,0,0,.28.4.4,0,0,1-.09-.33c.09-.14.25-.11.36-.11a1.53,1.53,0,0,1,.65.27.21.21,0,0,1,0,.28.19.19,0,0,1-.28.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.82,48.48a1.21,1.21,0,0,0-.41-.36c-.08,0-.18-.07-.22,0s0,.17,0,.27A.43.43,0,0,1,25.1,48a.35.35,0,0,1,.36-.1,1.41,1.41,0,0,1,.65.29.2.2,0,1,1-.24.32A.15.15,0,0,1,25.82,48.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.39,46.26a1.11,1.11,0,0,0-.4-.37c-.08,0-.18-.08-.22,0a.43.43,0,0,0,0,.28.4.4,0,0,1-.07-.33c.1-.14.26-.1.37-.09a1.38,1.38,0,0,1,.63.31.2.2,0,1,1-.25.31A.15.15,0,0,1,26.39,46.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.65,44a1.08,1.08,0,0,0-.38-.39c-.08-.05-.17-.09-.21-.06s0,.18,0,.28a.39.39,0,0,1-.05-.34c.1-.13.26-.09.36-.07a1.45,1.45,0,0,1,.63.34.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.07,41.11a1.11,1.11,0,0,0-.34-.42c-.08-.06-.17-.11-.21-.08s-.06.18-.05.28c0-.1-.1-.21,0-.34a.32.32,0,0,1,.37,0,1.48,1.48,0,0,1,.6.39.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.11,38.1a1.18,1.18,0,0,0-.3-.46c-.07-.06-.15-.12-.2-.09s-.07.16-.08.26a.38.38,0,0,1,0-.33c.13-.12.28,0,.38,0a1.34,1.34,0,0,1,.54.44.21.21,0,0,1,0,.29.22.22,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.55,35.63a1.17,1.17,0,0,0-.26-.49c-.06-.07-.14-.13-.19-.11s-.09.16-.1.25A.4.4,0,0,1,25,35a.33.33,0,0,1,.37,0,1.4,1.4,0,0,1,.51.49.2.2,0,1,1-.34.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.51,33.35a1.34,1.34,0,0,0-.21-.5c-.06-.08-.13-.15-.18-.13s-.1.14-.13.24a.39.39,0,0,1,.08-.33c.14-.09.28,0,.36.07a1.47,1.47,0,0,1,.46.55.2.2,0,0,1-.36.18A.24.24,0,0,1,26.51,33.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.14,31.07a1.18,1.18,0,0,0-.15-.52c0-.08-.11-.16-.16-.16s-.12.14-.16.23c0-.1,0-.22.12-.32s.27.05.35.12a1.52,1.52,0,0,1,.39.59.2.2,0,1,1-.38.14A.19.19,0,0,1,27.14,31.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.77,28.59a1.3,1.3,0,0,0-.07-.54c0-.09-.08-.18-.13-.18s-.14.12-.19.2c0-.1,0-.22.16-.29s.26.09.33.17a1.39,1.39,0,0,1,.29.64.21.21,0,0,1-.16.24.2.2,0,0,1-.23-.16A.11.11,0,0,1,27.77,28.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.52,26.05a1.11,1.11,0,0,0,0-.55c0-.09-.05-.18-.1-.19s-.16.09-.22.16c0-.09.06-.21.2-.26s.25.13.31.22a1.53,1.53,0,0,1,.17.69.2.2,0,0,1-.2.2.19.19,0,0,1-.2-.2A.17.17,0,0,1,28.52,26.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.65,23.64a1.39,1.39,0,0,0,0-.55c0-.09-.05-.18-.11-.19s-.15.08-.22.16c.05-.09.07-.21.22-.26s.24.14.29.23a1.5,1.5,0,0,1,.17.69.2.2,0,0,1-.21.2.2.2,0,0,1-.2-.2A.19.19,0,0,1,29.65,23.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.68,21.69a1.25,1.25,0,0,0,.05-.55c0-.09-.05-.19-.1-.2s-.16.09-.23.16c.05-.09.08-.21.22-.26s.24.15.29.23a1.47,1.47,0,0,1,.16.7.2.2,0,1,1-.4,0A.17.17,0,0,1,30.68,21.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.63,20.24a1.22,1.22,0,0,0,.05-.55c0-.09-.05-.18-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.25s.24.14.3.23a1.42,1.42,0,0,1,.14.69.2.2,0,0,1-.2.2.21.21,0,0,1-.2-.21A.19.19,0,0,1,31.63,20.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.65,18.76a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.09-.2s-.16.08-.23.15c0-.09.08-.21.23-.25s.23.15.28.24a1.42,1.42,0,0,1,.14.69.21.21,0,1,1-.41,0S33.64,18.78,33.65,18.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.5,17.67a1.09,1.09,0,0,0,.07-.55c0-.09,0-.19-.09-.2s-.16.08-.23.15c0-.09.08-.2.22-.25s.24.15.29.24a1.46,1.46,0,0,1,.13.7.21.21,0,0,1-.22.19.2.2,0,0,1-.19-.21A.19.19,0,0,1,34.5,17.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.83,17.91a1.06,1.06,0,0,0,.07-.54c0-.09,0-.19-.09-.21s-.16.08-.23.15c.05-.09.09-.2.23-.24s.24.15.28.24a1.46,1.46,0,0,1,.13.7.21.21,0,0,1-.41,0A.19.19,0,0,1,35.83,17.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.71,16.73a1.21,1.21,0,0,0,.07-.55c0-.09,0-.19-.09-.2s-.16.08-.23.15c0-.09.08-.21.23-.25s.23.16.28.25a1.46,1.46,0,0,1,.12.7.19.19,0,0,1-.21.18.19.19,0,0,1-.19-.21A.14.14,0,0,1,35.71,16.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.43,15.86a1.33,1.33,0,0,0,.08-.54c0-.09,0-.19-.09-.21s-.16.08-.23.15c.05-.09.08-.21.23-.25s.23.16.28.25a1.59,1.59,0,0,1,.11.7.21.21,0,0,1-.22.19.2.2,0,0,1-.18-.22A.19.19,0,0,1,36.43,15.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.23,16.84a1.06,1.06,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c0-.09.09-.2.23-.24s.23.15.28.25a1.35,1.35,0,0,1,.11.7.19.19,0,0,1-.21.18.2.2,0,0,1-.19-.22A.19.19,0,0,1,37.23,16.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.72,17.9a1.14,1.14,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c.05-.08.09-.2.23-.24s.23.16.28.25a1.35,1.35,0,0,1,.11.7.19.19,0,0,1-.21.18A.19.19,0,0,1,37.7,18,.24.24,0,0,1,37.72,17.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.86,15.76a1,1,0,0,0,.08-.54c0-.09,0-.19-.08-.2s-.16.07-.23.14c0-.09.08-.2.23-.24s.23.16.28.25a1.46,1.46,0,0,1,.1.7.21.21,0,0,1-.22.19.2.2,0,0,1-.18-.22A.24.24,0,0,1,37.86,15.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.76,16.32a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c0-.09.09-.2.24-.24s.23.16.27.25a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.22.18.19.19,0,0,1-.18-.21A.19.19,0,0,1,38.76,16.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.66,17.18a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.17.08-.24.15c.06-.09.09-.21.24-.24s.23.16.27.25a1.35,1.35,0,0,1,.1.7.2.2,0,1,1-.4,0A.19.19,0,0,1,39.66,17.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.68,17.37a1.18,1.18,0,0,0,.08-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.16.27.25a1.35,1.35,0,0,1,.11.7.2.2,0,0,1-.22.18.2.2,0,0,1-.18-.22A.19.19,0,0,1,38.68,17.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.11,18.15a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.16.27.25a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.22.18.19.19,0,0,1-.18-.21A.19.19,0,0,1,40.11,18.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.08,18.4a1.06,1.06,0,0,0,.08-.54c0-.09,0-.19-.08-.21s-.17.07-.24.15c.06-.09.09-.21.24-.25s.23.16.27.25a1.35,1.35,0,0,1,.11.7.21.21,0,0,1-.22.19.2.2,0,0,1-.18-.22A.19.19,0,0,1,39.08,18.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.12,64.7a1.21,1.21,0,0,0,.46-.31c.06-.06.12-.15.09-.2s-.16-.07-.26-.07c.1,0,.2-.08.33,0s0,.28,0,.38a1.46,1.46,0,0,1-.45.55A.2.2,0,0,1,62,65a.22.22,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.41,66a1.21,1.21,0,0,0,.47-.3c.06-.07.12-.15.09-.2s-.16-.08-.26-.08a.38.38,0,0,1,.33,0c.12.13,0,.28,0,.38a1.43,1.43,0,0,1-.45.54.2.2,0,0,1-.24-.32A.09.09,0,0,1,61.41,66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.36,65.81a1.18,1.18,0,0,0,.47-.29c.06-.07.12-.15.1-.2s-.16-.08-.26-.08c.1,0,.21-.07.33,0s0,.27,0,.37a1.38,1.38,0,0,1-.46.54.2.2,0,1,1-.23-.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.17,65.08a1.15,1.15,0,0,0,.46-.29c.06-.07.12-.15.1-.2s-.16-.08-.26-.08a.38.38,0,0,1,.33,0c.12.13,0,.28,0,.38a1.56,1.56,0,0,1-.46.54.22.22,0,0,1-.29,0,.21.21,0,0,1,.05-.28A.15.15,0,0,1,63.17,65.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.52,65.64a1.11,1.11,0,0,0,.44-.32c.06-.07.12-.16.09-.2s-.17-.07-.27-.07c.1,0,.21-.08.34,0s.05.27,0,.37a1.44,1.44,0,0,1-.43.57.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.46,66.14a1.1,1.1,0,0,0,.46-.31c.06-.06.11-.15.09-.2s-.17-.07-.27-.07a.41.41,0,0,1,.34,0c.12.12,0,.27,0,.37a1.35,1.35,0,0,1-.44.55.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.18,64.8a1,1,0,0,0,.45-.31c.06-.07.12-.15.09-.2s-.16-.07-.26-.07c.1,0,.21-.08.33,0s.05.27,0,.37a1.29,1.29,0,0,1-.43.56.2.2,0,0,1-.25-.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.24,65a1.16,1.16,0,0,0,.45-.33c.06-.07.11-.15.08-.2s-.16-.07-.26-.07a.38.38,0,0,1,.33,0c.12.12,0,.27,0,.37a1.43,1.43,0,0,1-.42.57.21.21,0,0,1-.29,0,.21.21,0,0,1,0-.29S60.22,65,60.24,65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.21,63.9a1.18,1.18,0,0,0,.46-.3c.06-.07.12-.16.09-.2s-.16-.08-.26-.08a.38.38,0,0,1,.33,0c.12.13,0,.28,0,.37a1.57,1.57,0,0,1-.45.56.21.21,0,0,1-.28,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.18,63.2a1.14,1.14,0,0,0,.44-.33c.06-.07.12-.15.09-.2s-.17-.07-.27-.07c.1,0,.21-.08.34,0s0,.27,0,.37a1.43,1.43,0,0,1-.42.57.21.21,0,0,1-.29,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.07,62.18a1.14,1.14,0,0,0,.44-.33c.06-.07.11-.15.09-.2s-.17-.06-.27-.06a.37.37,0,0,1,.34,0c.12.12,0,.27,0,.37a1.54,1.54,0,0,1-.42.58.2.2,0,0,1-.28,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.07,61a1.11,1.11,0,0,0,.42-.34c.06-.07.11-.16.08-.21s-.17-.06-.27-.05a.4.4,0,0,1,.34,0c.12.11.06.27,0,.37a1.49,1.49,0,0,1-.39.59.21.21,0,1,1-.28-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.37,60a1.18,1.18,0,0,0,.41-.37c0-.07.09-.16.06-.21s-.17,0-.27,0c.1,0,.2-.1.33,0s.08.27.06.37a1.53,1.53,0,0,1-.37.6.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,58.84a1.25,1.25,0,0,0,.4-.37c0-.07.09-.17.06-.21a.36.36,0,0,0-.28,0,.39.39,0,0,1,.34,0c.13.11.08.27.06.37a1.41,1.41,0,0,1-.35.61.2.2,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.1,58.08a1.32,1.32,0,0,0,.39-.39c0-.08.08-.18,0-.22s-.18,0-.28,0c.1,0,.19-.11.33-.06a.32.32,0,0,1,.08.36,1.4,1.4,0,0,1-.32.63.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.59,57.09a1,1,0,0,0,.37-.4c0-.08.08-.18,0-.22s-.18,0-.28,0c.1,0,.19-.12.33-.07s.1.26.09.36a1.39,1.39,0,0,1-.3.64.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.23,56.54a1.16,1.16,0,0,0,.36-.42c0-.08.07-.18,0-.21s-.18,0-.27,0a.39.39,0,0,1,.32-.09c.15.09.12.26.11.36a1.41,1.41,0,0,1-.27.65.22.22,0,0,1-.29.05.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.42,55.58a1.16,1.16,0,0,0,.35-.43c0-.08.07-.18,0-.22s-.18,0-.27,0c.09,0,.17-.13.32-.09s.12.25.11.36a1.4,1.4,0,0,1-.26.65.2.2,0,1,1-.33-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.27,55a1.07,1.07,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.18,0-.28,0c.09-.05.18-.14.32-.11s.13.25.13.36a1.38,1.38,0,0,1-.23.67.21.21,0,0,1-.28.06.21.21,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.68,53.87a1.13,1.13,0,0,0,.31-.45c0-.09.06-.19,0-.23s-.18,0-.27,0c.09,0,.17-.14.32-.11s.14.24.14.34a1.46,1.46,0,0,1-.21.68.19.19,0,0,1-.27.07.2.2,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.64,52.64a1.11,1.11,0,0,0,.29-.47c0-.08,0-.18,0-.22s-.18,0-.27,0c.08-.06.16-.16.31-.14s.15.24.16.34a1.47,1.47,0,0,1-.17.69.19.19,0,0,1-.27.08.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,51.58a1,1,0,0,0,.25-.48c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.17.23.18.33a1.46,1.46,0,0,1-.12.7.2.2,0,0,1-.27.1.19.19,0,0,1-.1-.26A.16.16,0,0,1,61,51.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.63,50.36a1.2,1.2,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.26.06a.35.35,0,0,1,.29-.15c.17,0,.17.22.19.32a1.46,1.46,0,0,1-.12.7.21.21,0,1,1-.38-.16A.15.15,0,0,1,60.63,50.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.76,48.91a1.2,1.2,0,0,0,.25-.49c0-.08,0-.19,0-.22s-.18,0-.27.06c.08-.06.15-.16.3-.15s.16.22.18.33a1.33,1.33,0,0,1-.13.69.19.19,0,0,1-.26.11.21.21,0,0,1-.11-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.9,47.59a1.37,1.37,0,0,0,.25-.48c0-.09,0-.2,0-.23s-.17,0-.26.07c.07-.07.14-.17.29-.16s.17.22.19.33a1.33,1.33,0,0,1-.13.69.19.19,0,0,1-.26.11.21.21,0,0,1-.11-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.61,45.92a1.21,1.21,0,0,0,.26-.49c0-.08,0-.19,0-.22s-.18,0-.26.06.14-.16.29-.15.17.22.19.33a1.57,1.57,0,0,1-.13.69.2.2,0,1,1-.37-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.4,43.94a1.24,1.24,0,0,0,.26-.48c0-.09,0-.2,0-.23s-.18,0-.27.06c.08-.06.15-.16.3-.15s.17.23.18.33a1.47,1.47,0,0,1-.13.7.2.2,0,1,1-.37-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.19,42.58a1.28,1.28,0,0,0,.25-.48c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.17.23.19.33a1.38,1.38,0,0,1-.13.7.19.19,0,0,1-.26.1.2.2,0,0,1-.11-.26A.18.18,0,0,1,58.19,42.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,41.17a1.14,1.14,0,0,0,.25-.49c0-.09,0-.2,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.17.22.19.32a1.56,1.56,0,0,1-.11.7.2.2,0,0,1-.27.11.2.2,0,0,1-.11-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.82,39.21a1.09,1.09,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.06.15-.16.3-.15s.17.22.18.33a1.33,1.33,0,0,1-.13.69.2.2,0,0,1-.26.11.22.22,0,0,1-.11-.27A.15.15,0,0,1,57.82,39.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,38.78a1.11,1.11,0,0,0,.24-.5c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.22.19.32a1.57,1.57,0,0,1-.1.7.21.21,0,0,1-.27.11.2.2,0,0,1-.11-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.19,37.33a1.18,1.18,0,0,0,.24-.49c0-.09,0-.19,0-.22s-.18,0-.26.07.14-.17.29-.16.17.22.19.32a1.45,1.45,0,0,1-.11.7.2.2,0,0,1-.26.11.19.19,0,0,1-.11-.26A.1.1,0,0,1,56.19,37.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.08,36.87a1.28,1.28,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.14-.17.3-.16s.17.21.19.32a1.45,1.45,0,0,1-.09.7.2.2,0,0,1-.26.11.2.2,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.84,35.85a1.18,1.18,0,0,0,.24-.49c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.22.19.32a1.47,1.47,0,0,1-.11.7.2.2,0,0,1-.26.11.19.19,0,0,1-.11-.26A.16.16,0,0,1,55.84,35.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.09,35a1.17,1.17,0,0,0,.23-.49c0-.09,0-.2,0-.22s-.18,0-.26.07a.39.39,0,0,1,.29-.17c.16.05.17.22.19.32a1.43,1.43,0,0,1-.09.7.21.21,0,0,1-.27.12.2.2,0,0,1-.11-.26A.16.16,0,0,1,55.09,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.91,35a1.15,1.15,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.08c.08-.07.14-.18.29-.18s.18.22.2.32a1.43,1.43,0,0,1-.07.7.2.2,0,0,1-.26.12.19.19,0,0,1-.12-.25A.15.15,0,0,1,53.91,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.73,33.92a1.28,1.28,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.14-.17.29-.17s.18.22.2.32a1.43,1.43,0,0,1-.09.7.2.2,0,1,1-.38-.14S54.71,33.93,54.73,33.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.48,33.91a1.13,1.13,0,0,0,.21-.5c0-.09,0-.2,0-.22s-.18,0-.26.08.13-.18.28-.18.19.21.21.31a1.46,1.46,0,0,1-.07.71.2.2,0,0,1-.26.12.2.2,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.54,34.29a1.12,1.12,0,0,0,.21-.51c0-.09,0-.19,0-.21s-.18,0-.26.08.13-.18.28-.18.19.21.22.31a1.42,1.42,0,0,1-.06.7.19.19,0,0,1-.25.13.19.19,0,0,1-.13-.25A.21.21,0,0,1,52.54,34.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.58,35.33a1.17,1.17,0,0,0,.21-.51c0-.09,0-.19,0-.22s-.18,0-.26.09.13-.18.28-.18.19.2.21.3a1.34,1.34,0,0,1,0,.71.21.21,0,0,1-.25.13.19.19,0,0,1-.13-.25A.15.15,0,0,1,52.58,35.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.7,36.2a1.18,1.18,0,0,0,.22-.51c0-.09,0-.19,0-.22a.44.44,0,0,0-.27.08c.08-.07.14-.17.29-.17s.18.21.21.31a1.47,1.47,0,0,1-.08.71.19.19,0,0,1-.25.12.21.21,0,0,1-.13-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,36.9a1.16,1.16,0,0,0,.2-.51c0-.09,0-.2,0-.22s-.17,0-.26.09c.08-.08.14-.18.29-.19s.19.21.21.31a1.41,1.41,0,0,1-.05.7.22.22,0,0,1-.26.14.22.22,0,0,1-.13-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.15,38a1.21,1.21,0,0,0,.22-.5c0-.09,0-.19,0-.22s-.18,0-.27.08c.08-.07.14-.17.29-.17s.18.21.21.31a1.42,1.42,0,0,1-.08.7.2.2,0,1,1-.38-.13A.25.25,0,0,1,54.15,38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.49,40.71a1.17,1.17,0,0,0,.23-.49c0-.09,0-.2,0-.22s-.18,0-.26.07a.39.39,0,0,1,.29-.17c.16.05.18.21.2.32a1.45,1.45,0,0,1-.09.7.21.21,0,0,1-.26.12.2.2,0,0,1-.12-.26A.25.25,0,0,1,55.49,40.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.67,43.83a1.28,1.28,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.14-.17.29-.17s.18.22.2.32a1.33,1.33,0,0,1-.1.7.2.2,0,0,1-.26.12.2.2,0,0,1-.11-.26S56.65,43.84,56.67,43.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,45.64a1.28,1.28,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.17,0-.26.07c.08-.07.14-.17.29-.17s.18.22.2.32a1.49,1.49,0,0,1-.09.71.2.2,0,0,1-.26.11A.2.2,0,0,1,57,45.7S57,45.65,57,45.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.83,47a1.11,1.11,0,0,0,.24-.5c0-.08,0-.19,0-.22s-.18,0-.26.07.14-.17.29-.16.17.22.19.32a1.44,1.44,0,0,1-.1.7.2.2,0,0,1-.38-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.62,49.07a1.18,1.18,0,0,0,.24-.49c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.06.15-.16.3-.16s.17.22.19.33a1.47,1.47,0,0,1-.11.7.2.2,0,0,1-.26.11.21.21,0,0,1-.12-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.86,50.4a1.17,1.17,0,0,0,.23-.49c0-.09,0-.2,0-.22s-.18,0-.26.07a.39.39,0,0,1,.29-.17c.16,0,.18.22.2.32a1.45,1.45,0,0,1-.09.7.21.21,0,0,1-.26.12.2.2,0,0,1-.12-.26A.25.25,0,0,1,57.86,50.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.88,51a1.18,1.18,0,0,0,.24-.49c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.22.19.32a1.47,1.47,0,0,1-.11.7.2.2,0,0,1-.26.11.19.19,0,0,1-.11-.26A.1.1,0,0,1,58.88,51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.15,52.26a1.28,1.28,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.17,0-.26.07a.39.39,0,0,1,.29-.17c.16.05.18.22.2.32a1.43,1.43,0,0,1-.09.7.2.2,0,1,1-.38-.14S58.13,52.27,58.15,52.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.23,53.29a1.11,1.11,0,0,0,.26-.48c0-.09,0-.19,0-.22a.36.36,0,0,0-.27.06.38.38,0,0,1,.3-.15c.17.06.17.23.18.33a1.45,1.45,0,0,1-.14.69.19.19,0,0,1-.26.1.18.18,0,0,1-.1-.26A.16.16,0,0,1,59.23,53.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.31,54.4a1.25,1.25,0,0,0,.29-.47c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.16-.15.3-.13s.16.23.16.33a1.47,1.47,0,0,1-.17.69.2.2,0,0,1-.27.09.21.21,0,0,1-.09-.28A.12.12,0,0,1,59.31,54.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,55.83a1.24,1.24,0,0,0,.31-.45c0-.09,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.11c.16.07.14.24.14.34a1.44,1.44,0,0,1-.2.68.2.2,0,0,1-.28.07.19.19,0,0,1-.07-.27A.1.1,0,0,1,59,55.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.59,57.65a1.05,1.05,0,0,0,.35-.42c0-.08.07-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.32-.09c.15.09.12.25.11.36a1.35,1.35,0,0,1-.27.65.2.2,0,1,1-.33-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.46,59a1.22,1.22,0,0,0,.38-.4c0-.07.08-.17,0-.21s-.18,0-.28,0c.1,0,.19-.11.33-.06a.32.32,0,0,1,.08.36,1.36,1.36,0,0,1-.31.64.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28A.15.15,0,0,1,60.46,59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.91,60.5a1.1,1.1,0,0,0,.38-.39c.05-.07.09-.17.05-.21s-.17,0-.27,0c.09,0,.19-.12.33-.06s.09.26.08.36a1.51,1.51,0,0,1-.32.63.21.21,0,0,1-.29,0,.22.22,0,0,1,0-.29S58.89,60.52,58.91,60.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.85,61.55a1.15,1.15,0,0,0,.41-.36c.05-.08.09-.17.06-.21s-.17,0-.27,0a.38.38,0,0,1,.33,0c.14.11.08.27.06.37a1.54,1.54,0,0,1-.36.61.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28A.15.15,0,0,1,59.85,61.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.9,62.19a1.11,1.11,0,0,0,.42-.34c.06-.08.11-.17.08-.21s-.17-.06-.27,0a.37.37,0,0,1,.33,0,.32.32,0,0,1,0,.37,1.42,1.42,0,0,1-.39.6.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,62.31a1.29,1.29,0,0,0,.41-.37c0-.08.09-.17.06-.21s-.17,0-.27,0a.4.4,0,0,1,.33,0c.13.11.08.27.06.37a1.54,1.54,0,0,1-.36.61.22.22,0,0,1-.29,0,.21.21,0,0,1,0-.29S59,62.32,59,62.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.2,63.05a1,1,0,0,0,.42-.34c.06-.07.11-.16.08-.21s-.17,0-.27,0a.37.37,0,0,1,.33,0c.13.11.07.27,0,.37a1.49,1.49,0,0,1-.39.59.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28A.18.18,0,0,1,60.2,63.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,63.35a1.15,1.15,0,0,0,.41-.36c.06-.07.1-.16.07-.21s-.17-.05-.27,0c.1,0,.2-.1.34,0s.07.27,0,.37a1.45,1.45,0,0,1-.38.6.2.2,0,0,1-.28,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.22,61.3a1.1,1.1,0,0,0,.42-.36c.06-.07.1-.16.07-.21s-.17,0-.27,0a.38.38,0,0,1,.33,0,.34.34,0,0,1,0,.38,1.45,1.45,0,0,1-.38.6.2.2,0,0,1-.28,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.08,60.13a1,1,0,0,0,.39-.38c.05-.08.09-.17.05-.21s-.17,0-.27,0c.1,0,.19-.11.33,0s.09.26.07.36a1.42,1.42,0,0,1-.33.63.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.29S60.06,60.15,60.08,60.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.55,58.69a1.23,1.23,0,0,0,.35-.42c0-.08.07-.18,0-.22s-.18,0-.27,0A.39.39,0,0,1,59,58c.15.09.12.26.11.36a1.5,1.5,0,0,1-.27.66.2.2,0,0,1-.28,0,.19.19,0,0,1,0-.28A.15.15,0,0,1,58.55,58.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.65,57.34a1.2,1.2,0,0,0,.31-.46c0-.08,0-.18,0-.22s-.18,0-.27,0a.42.42,0,0,1,.31-.12c.16.08.14.24.14.35a1.36,1.36,0,0,1-.2.68.2.2,0,0,1-.35-.21A.12.12,0,0,1,57.65,57.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.61,55.87a1.08,1.08,0,0,0,.24-.49c0-.09,0-.2,0-.22s-.18,0-.27.07c.08-.07.14-.17.29-.17s.18.22.2.32a1.57,1.57,0,0,1-.1.7.2.2,0,0,1-.26.12.21.21,0,0,1-.12-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.58,54.79a1.06,1.06,0,0,0,.16-.52c0-.09,0-.2-.06-.22s-.17.05-.25.12.12-.19.26-.21.21.18.25.28A1.54,1.54,0,0,1,56,55a.19.19,0,0,1-.24.15.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.15,53.18a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.08.1-.2.25-.23s.22.18.26.27a1.4,1.4,0,0,1,.06.71.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.54,52.7a1.12,1.12,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.23.17c0-.1.08-.21.22-.26s.24.13.29.22a1.44,1.44,0,0,1,.17.69.2.2,0,0,1-.2.2.2.2,0,0,1-.21-.2A.19.19,0,0,1,53.54,52.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.58,51.18a1.14,1.14,0,0,0,0-.55c0-.09-.06-.18-.12-.19s-.15.1-.21.18c0-.09.06-.21.19-.28s.26.12.32.21a1.44,1.44,0,0,1,.22.67.21.21,0,1,1-.41,0S52.58,51.2,52.58,51.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,49.73a1.16,1.16,0,0,0,0-.55c0-.08-.07-.18-.12-.18s-.15.1-.2.19c0-.1,0-.22.17-.29s.26.11.33.19a1.45,1.45,0,0,1,.24.67.19.19,0,0,1-.17.22.19.19,0,0,1-.23-.17A.19.19,0,0,1,52,49.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.61,48.3a1.11,1.11,0,0,0,0-.54c0-.09-.08-.18-.13-.18s-.15.1-.2.19c0-.1,0-.22.18-.29s.26.1.32.18a1.48,1.48,0,0,1,.26.66.21.21,0,0,1-.18.23.19.19,0,0,1-.22-.17A.22.22,0,0,1,51.61,48.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.34,47.05a1.16,1.16,0,0,0,0-.55c0-.09-.08-.18-.13-.18s-.14.11-.2.19c0-.09,0-.21.18-.28s.26.1.32.18a1.43,1.43,0,0,1,.26.66.2.2,0,0,1-.17.23.22.22,0,0,1-.23-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.25,45.62a1.11,1.11,0,0,0,0-.54c0-.09-.07-.18-.13-.19s-.14.11-.2.19c0-.1.05-.22.18-.28s.26.1.32.19a1.42,1.42,0,0,1,.25.66.22.22,0,0,1-.18.23.2.2,0,0,1-.22-.18A.22.22,0,0,1,51.25,45.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.39,44.25a1.17,1.17,0,0,0,0-.55c0-.08-.08-.17-.13-.17s-.15.1-.2.19c0-.1,0-.22.17-.29s.26.1.33.18a1.52,1.52,0,0,1,.27.65.2.2,0,0,1-.4.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.92,42.12a1.32,1.32,0,0,0,0-.55c0-.08-.07-.18-.12-.18s-.15.11-.2.19c0-.09,0-.21.17-.28s.26.1.33.18a1.42,1.42,0,0,1,.25.66.2.2,0,0,1-.17.23.21.21,0,0,1-.23-.17A.19.19,0,0,1,49.92,42.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.19,40.52a1.32,1.32,0,0,0,0-.55c0-.09-.07-.18-.13-.18s-.14.11-.19.19c0-.1,0-.22.17-.29s.26.11.33.19a1.38,1.38,0,0,1,.25.66.2.2,0,0,1-.17.23.22.22,0,0,1-.23-.18A.17.17,0,0,1,49.19,40.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.54,39.31a1.05,1.05,0,0,0,0-.55c0-.08-.07-.18-.13-.18s-.14.11-.19.2c0-.1,0-.22.17-.29s.26.1.33.18a1.55,1.55,0,0,1,.26.66.21.21,0,0,1-.18.23.19.19,0,0,1-.22-.17A.21.21,0,0,1,48.54,39.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.45,38.19a1.2,1.2,0,0,0-.06-.55c0-.08-.08-.17-.13-.17s-.14.11-.19.2c0-.1,0-.22.16-.3s.26.09.33.17a1.49,1.49,0,0,1,.29.65.21.21,0,0,1-.17.24.2.2,0,0,1-.23-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.77,37.07a1.15,1.15,0,0,0,0-.55c0-.09-.07-.18-.12-.18s-.15.1-.21.18c0-.09,0-.21.19-.28s.25.11.32.19a1.58,1.58,0,0,1,.24.67.2.2,0,0,1-.18.22.22.22,0,0,1-.23-.18A.17.17,0,0,1,47.77,37.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.58,37a1,1,0,0,0-.07-.54c0-.09-.08-.18-.13-.18s-.14.12-.19.2c0-.09,0-.22.16-.29s.26.09.33.16A1.4,1.4,0,0,1,47,37a.2.2,0,0,1-.16.23.2.2,0,0,1-.24-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.31,35a1.26,1.26,0,0,0,0-.55c0-.09-.06-.19-.11-.19s-.16.09-.22.17c0-.1.06-.21.2-.27s.25.13.31.21a1.42,1.42,0,0,1,.19.69.2.2,0,0,1-.19.21.21.21,0,0,1-.21-.2A.17.17,0,0,1,47.31,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.75,35.9a1,1,0,0,0,0-.55c0-.09-.07-.18-.12-.18s-.15.1-.2.19c0-.1,0-.22.18-.29s.25.11.32.19a1.42,1.42,0,0,1,.25.66.2.2,0,1,1-.4,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.05,35.8a1.12,1.12,0,0,0,0-.55c0-.09,0-.18-.1-.19s-.16.09-.22.17c0-.1.06-.22.2-.27s.25.13.31.22a1.38,1.38,0,0,1,.18.68.2.2,0,0,1-.2.21.19.19,0,0,1-.2-.2A.17.17,0,0,1,48.05,35.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.75,34.85a1.2,1.2,0,0,0,.09-.55c0-.09,0-.19-.09-.2s-.16.07-.23.14c0-.08.08-.2.23-.24s.23.16.28.25a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.21.19.21.21,0,0,1-.19-.22A.19.19,0,0,1,48.75,34.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.35,35.79a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c.05-.09.08-.2.23-.24s.23.16.28.25a1.46,1.46,0,0,1,.1.7.19.19,0,0,1-.21.18.19.19,0,0,1-.19-.21A.24.24,0,0,1,49.35,35.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.26,37a1.12,1.12,0,0,0,.05-.55c0-.09-.05-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.15.3.24a1.4,1.4,0,0,1,.15.69.2.2,0,0,1-.2.2.22.22,0,0,1-.2-.21S49.26,37,49.26,37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.21,37.65a1.18,1.18,0,0,0,.08-.54c0-.09,0-.19-.09-.21S50,37,50,37.05c.05-.09.08-.2.23-.24s.23.15.28.24a1.46,1.46,0,0,1,.11.7.2.2,0,0,1-.21.19.21.21,0,0,1-.19-.22A.19.19,0,0,1,50.21,37.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.65,38.83a1.18,1.18,0,0,0,.07-.54c0-.09,0-.19-.09-.2s-.16.07-.23.15c.05-.09.08-.21.22-.25s.24.15.29.24a1.46,1.46,0,0,1,.13.7.22.22,0,0,1-.22.19.21.21,0,0,1-.19-.22A.19.19,0,0,1,50.65,38.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.34,40a1.06,1.06,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c0-.09.08-.2.23-.24s.23.15.28.24a1.46,1.46,0,0,1,.12.7.21.21,0,0,1-.41,0S51.33,40,51.34,40Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,41.55a1.21,1.21,0,0,0,.08-.55c0-.09,0-.19-.09-.2s-.16.08-.23.15c.05-.09.08-.21.23-.25s.23.16.28.25a1.46,1.46,0,0,1,.12.7.21.21,0,1,1-.41,0S52,41.57,52,41.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.5,42.86a1.18,1.18,0,0,0,.07-.54c0-.09,0-.19-.09-.21s-.16.08-.23.15c0-.09.09-.2.23-.25s.23.16.28.25a1.46,1.46,0,0,1,.12.7.21.21,0,0,1-.22.19.2.2,0,0,1-.18-.22A.19.19,0,0,1,52.5,42.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.6,44.06a1.12,1.12,0,0,0,.12-.53c0-.1,0-.2-.08-.21s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.26a1.52,1.52,0,0,1,.07.71.2.2,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,53.6,44.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.2,45.5a1.21,1.21,0,0,0,.08-.55c0-.09,0-.19-.1-.2s-.16.08-.23.15c0-.09.09-.2.23-.25s.24.15.28.25a1.4,1.4,0,0,1,.13.69.2.2,0,0,1-.21.19.19.19,0,0,1-.19-.21S53.2,45.52,53.2,45.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.06,46.52a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.1-.2.24-.24s.23.17.27.26a1.4,1.4,0,0,1,.08.71.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.68,47.68a1.09,1.09,0,0,0,.07-.55c0-.09,0-.19-.09-.2s-.16.08-.23.15c0-.09.08-.21.23-.25s.23.15.28.25a1.4,1.4,0,0,1,.13.69.21.21,0,0,1-.22.19.2.2,0,0,1-.19-.21A.19.19,0,0,1,53.68,47.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.51,49.46a1.19,1.19,0,0,0,.05-.54c0-.09-.05-.19-.1-.2s-.16.08-.23.16c.05-.09.08-.21.22-.26s.24.14.29.23a1.43,1.43,0,0,1,.16.69.21.21,0,0,1-.21.2.2.2,0,0,1-.2-.2A.19.19,0,0,1,53.51,49.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.22,51.67a1.09,1.09,0,0,0,.07-.55c0-.09,0-.18-.08-.2S54,51,54,51.07c.06-.09.09-.2.23-.25s.24.16.28.25a1.35,1.35,0,0,1,.12.7.2.2,0,0,1-.21.19.21.21,0,0,1-.19-.22A.19.19,0,0,1,54.22,51.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.5,54.17a1.11,1.11,0,0,0,0-.55c0-.09,0-.19-.1-.19s-.16.08-.22.16c0-.09.07-.21.21-.26s.24.14.3.22a1.43,1.43,0,0,1,.16.69.2.2,0,0,1-.2.2.19.19,0,0,1-.2-.2A.17.17,0,0,1,53.5,54.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.09,56.08a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c0-.09.09-.21.24-.24s.22.16.27.25a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.4,0A.19.19,0,0,1,54.09,56.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.83,58.29a1.19,1.19,0,0,0,.2-.5c0-.1,0-.2,0-.22s-.18,0-.26.09c.07-.08.13-.18.28-.19s.19.21.22.31a1.42,1.42,0,0,1-.06.7.19.19,0,0,1-.25.13.2.2,0,0,1-.13-.25A.25.25,0,0,1,54.83,58.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.23,60.64a1.21,1.21,0,0,0,.29-.47c0-.08,0-.18,0-.22s-.18,0-.28,0c.09-.06.16-.15.31-.13s.15.24.16.34a1.47,1.47,0,0,1-.17.69.2.2,0,0,1-.36-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.2,62.51a1.27,1.27,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.18,0-.28,0c.09,0,.18-.14.32-.11s.13.25.13.36a1.44,1.44,0,0,1-.24.66.2.2,0,0,1-.28.06.21.21,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.88,63.67a1.22,1.22,0,0,0,.38-.4c0-.08.08-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.33-.07.32.32,0,0,1,.08.36,1.39,1.39,0,0,1-.3.64.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.31,64.62a1.28,1.28,0,0,0,.39-.38c0-.08.09-.18.06-.22s-.18,0-.28,0a.48.48,0,0,1,.34-.05c.13.11.08.27.06.37a1.28,1.28,0,0,1-.34.62.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29S56.29,64.63,56.31,64.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.4,64.54a1.25,1.25,0,0,0,.41-.36c0-.08.1-.17.06-.22s-.17-.05-.27,0c.1,0,.2-.1.34,0s.07.27.05.37a1.42,1.42,0,0,1-.37.6.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29S57.38,64.55,57.4,64.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.1,63.14a1.21,1.21,0,0,0,.39-.39c0-.07.09-.17,0-.21s-.17,0-.27,0c.09,0,.19-.11.33,0s.09.26.07.37a1.45,1.45,0,0,1-.33.62.22.22,0,0,1-.29,0,.21.21,0,0,1,0-.29A.09.09,0,0,1,57.1,63.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.77,62.08a1.23,1.23,0,0,0,.37-.41c0-.08.08-.17,0-.21s-.17,0-.27,0a.4.4,0,0,1,.33-.08c.14.1.1.26.09.37a1.44,1.44,0,0,1-.3.64.2.2,0,0,1-.32-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.92,60.54a1.05,1.05,0,0,0,.35-.42c0-.08.07-.18,0-.22s-.18,0-.28,0a.4.4,0,0,1,.33-.09c.15.08.12.25.11.35a1.35,1.35,0,0,1-.27.66.2.2,0,1,1-.33-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,58.41a1.24,1.24,0,0,0,.31-.45c0-.09,0-.19,0-.22s-.18,0-.28,0c.09,0,.17-.14.32-.11s.14.24.14.34a1.44,1.44,0,0,1-.2.68.2.2,0,0,1-.28.07.19.19,0,0,1-.07-.27A.15.15,0,0,1,57,58.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.86,54.18a1.09,1.09,0,0,0,.25-.49c0-.08,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.16.22.18.33a1.42,1.42,0,0,1-.12.69.2.2,0,1,1-.37-.15A.43.43,0,0,1,57.86,54.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.87,48.67a1.17,1.17,0,0,0,.23-.49c0-.09,0-.2,0-.22s-.17,0-.26.07c.08-.07.14-.17.29-.17s.18.22.2.32a1.35,1.35,0,0,1-.1.7.2.2,0,1,1-.37-.14A.16.16,0,0,1,57.87,48.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.27,44.23a1.1,1.1,0,0,0,.25-.48c0-.09,0-.2,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.17.22.18.33a1.42,1.42,0,0,1-.12.69.19.19,0,0,1-.26.11.2.2,0,0,1-.11-.26A.43.43,0,0,1,58.27,44.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.66,41.27a1.11,1.11,0,0,0,.26-.48c0-.09,0-.19,0-.22s-.17,0-.26.06c.08-.07.15-.17.3-.15s.16.22.18.32a1.46,1.46,0,0,1-.14.7.2.2,0,0,1-.36-.16A.16.16,0,0,1,58.66,41.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.14,39.55a1.2,1.2,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.22.18.32a1.34,1.34,0,0,1-.11.7.21.21,0,0,1-.27.11.2.2,0,0,1-.1-.27S57.12,39.57,57.14,39.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.67,37.3a1.11,1.11,0,0,0,.26-.48c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.15s.17.22.18.32a1.36,1.36,0,0,1-.13.7.19.19,0,0,1-.26.1.2.2,0,0,1-.11-.26A.18.18,0,0,1,57.67,37.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.71,34.65a1.37,1.37,0,0,0,.25-.48c0-.09,0-.2,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.17.22.19.33a1.33,1.33,0,0,1-.13.69.19.19,0,0,1-.26.11.2.2,0,0,1-.11-.26A.18.18,0,0,1,56.71,34.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.2,32.5a1.17,1.17,0,0,0,.23-.49c0-.09,0-.19,0-.22s-.18,0-.26.07a.39.39,0,0,1,.29-.17c.16,0,.17.22.19.32a1.48,1.48,0,0,1-.1.71.2.2,0,0,1-.26.11.2.2,0,0,1-.12-.26A.18.18,0,0,1,55.2,32.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.51,30.93a1.32,1.32,0,0,0,.22-.51c0-.09,0-.19,0-.22a.44.44,0,0,0-.27.08c.08-.07.14-.17.29-.17s.18.21.2.31a1.35,1.35,0,0,1-.07.71.2.2,0,1,1-.38-.14S53.49,30.94,53.51,30.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.43,25.61a1.09,1.09,0,0,0,.22-.5c0-.09,0-.19,0-.22s-.18,0-.26.08.13-.17.28-.17.19.21.21.31a1.43,1.43,0,0,1-.08.7.2.2,0,0,1-.25.13.21.21,0,0,1-.13-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.83,23.86a1.15,1.15,0,0,0,.19-.52c0-.09,0-.19,0-.21s-.18,0-.26.09S51.85,23,52,23s.19.2.22.3a1.44,1.44,0,0,1,0,.71.19.19,0,0,1-.25.13.2.2,0,0,1-.14-.25S51.81,23.87,51.83,23.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.21,22.64a1.12,1.12,0,0,0,.19-.52c0-.09,0-.19,0-.22s-.17,0-.26.1a.43.43,0,0,1,.28-.2c.17,0,.2.2.22.3a1.32,1.32,0,0,1,0,.71.2.2,0,0,1-.39-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.3,21.9a1.18,1.18,0,0,0,.17-.52c0-.09,0-.19,0-.22s-.18.05-.26.11c.07-.08.12-.19.27-.2s.2.19.23.29a1.42,1.42,0,0,1,0,.71.2.2,0,0,1-.25.14.19.19,0,0,1-.14-.24A.21.21,0,0,1,50.3,21.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.15,20.74a1.18,1.18,0,0,0,.17-.52c0-.09,0-.19-.06-.21s-.17,0-.25.1c.07-.08.12-.19.27-.2s.2.19.23.29a1.42,1.42,0,0,1,0,.71.2.2,0,0,1-.24.15.21.21,0,0,1-.15-.25A.21.21,0,0,1,50.15,20.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.06,19.45a1.12,1.12,0,0,0,.13-.53c0-.1,0-.2-.06-.22s-.17.06-.25.12.11-.19.26-.21.21.18.25.27a1.52,1.52,0,0,1,0,.71.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.23,18.12a1.12,1.12,0,0,0,.12-.53c0-.1,0-.2-.08-.21s-.16.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.26a1.4,1.4,0,0,1,.06.71.19.19,0,0,1-.22.17.22.22,0,0,1-.18-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.74,17a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.08-.21s-.16.07-.24.13.1-.19.25-.22.22.17.26.26a1.52,1.52,0,0,1,.07.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,44.74,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.54,16a1.07,1.07,0,0,0,.11-.54c0-.09,0-.19-.07-.21s-.17.07-.24.13.1-.19.24-.23.23.17.27.27a1.47,1.47,0,0,1,.07.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.14.14,0,0,1,43.54,16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.22,14.66a1,1,0,0,0,.11-.53c0-.09,0-.2-.07-.21s-.17.06-.24.13c0-.08.09-.2.24-.23s.22.17.27.26a1.52,1.52,0,0,1,.07.71.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22A.19.19,0,0,1,42.22,14.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.2,15.69a1.19,1.19,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.1-.2.24-.24s.23.17.27.27a1.35,1.35,0,0,1,.08.7.2.2,0,0,1-.23.17.19.19,0,0,1-.17-.22A.19.19,0,0,1,42.2,15.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.87,14a1,1,0,0,0,.1-.54c0-.09,0-.19-.07-.2s-.17.06-.24.13c.05-.08.09-.2.24-.23s.22.16.27.26a1.46,1.46,0,0,1,.08.7.2.2,0,1,1-.4,0A.19.19,0,0,1,39.87,14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.84,15.09a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.24-.24s.23.17.28.26a1.47,1.47,0,0,1,.08.7.19.19,0,0,1-.22.18.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.82,15.83a1.07,1.07,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.16.07-.23.14c0-.09.09-.2.24-.24s.22.17.27.26a1.47,1.47,0,0,1,.08.7.2.2,0,0,1-.4,0A.14.14,0,0,1,40.82,15.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.81,14.74a1.19,1.19,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.17.27.26a1.4,1.4,0,0,1,.08.71A.19.19,0,0,1,41,15a.2.2,0,0,1-.18-.22A.14.14,0,0,1,40.81,14.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.46,16.84a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.09-.21.24-.24s.23.16.27.26a1.46,1.46,0,0,1,.09.7.2.2,0,0,1-.22.18.21.21,0,0,1-.16-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.34,18.79a1.12,1.12,0,0,0,.1-.53c0-.1,0-.2-.08-.21s-.17.07-.24.13.1-.2.24-.23.23.17.27.26a1.36,1.36,0,0,1,.08.7.2.2,0,1,1-.4,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.56,21.31a1.13,1.13,0,0,0,.12-.54c0-.09,0-.19-.08-.2s-.16.06-.24.12.1-.19.25-.22.22.17.26.26a1.4,1.4,0,0,1,.06.71.19.19,0,0,1-.22.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,46.56,21.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.86,23.51A1.26,1.26,0,0,0,49,23c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.18.25.28a1.41,1.41,0,0,1,0,.71.18.18,0,0,1-.23.15.2.2,0,0,1-.16-.23A.15.15,0,0,1,48.86,23.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.7,25.62a1.19,1.19,0,0,0,.18-.52c0-.09,0-.19,0-.21s-.18,0-.26.09.12-.18.27-.19.2.19.23.29a1.56,1.56,0,0,1,0,.71.21.21,0,0,1-.25.15.2.2,0,0,1-.14-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.92,26.76a1.15,1.15,0,0,0,.19-.51c0-.09,0-.2,0-.22s-.17,0-.26.09c.07-.08.13-.18.28-.19s.19.21.22.31a1.4,1.4,0,0,1,0,.7.22.22,0,0,1-.26.14.21.21,0,0,1-.13-.25A.16.16,0,0,1,51.92,26.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.17,27a1.22,1.22,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.26.08.13-.17.29-.17.17.21.19.32a1.33,1.33,0,0,1-.08.7.21.21,0,0,1-.26.12.2.2,0,0,1-.12-.26A.16.16,0,0,1,54.17,27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.18,28.11a1.17,1.17,0,0,0,.21-.51c0-.09,0-.19,0-.22a.39.39,0,0,0-.26.09c.07-.08.13-.18.29-.18s.18.21.2.31a1.32,1.32,0,0,1-.07.7.19.19,0,0,1-.25.13.19.19,0,0,1-.09-.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.36,28.09a1.28,1.28,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.14-.17.29-.16s.18.21.2.31a1.47,1.47,0,0,1-.09.71.2.2,0,0,1-.26.11.2.2,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.84,29a1.27,1.27,0,0,0,.22-.5c0-.09,0-.19,0-.22s-.18,0-.26.08.13-.18.28-.18.19.22.21.32a1.56,1.56,0,0,1-.08.7.2.2,0,0,1-.26.12A.19.19,0,0,1,53.8,29,.1.1,0,0,1,53.84,29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.79,29.24a1.31,1.31,0,0,0,.21-.51c0-.09,0-.19,0-.22s-.18,0-.26.08.13-.17.28-.18.19.21.21.31a1.46,1.46,0,0,1-.06.71.2.2,0,0,1-.25.13.2.2,0,0,1-.13-.26S52.77,29.25,52.79,29.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.09,26.82a1.32,1.32,0,0,0,.22-.51c0-.09,0-.19,0-.22a.39.39,0,0,0-.26.09A.37.37,0,0,1,53.3,26c.16,0,.18.21.2.31a1.42,1.42,0,0,1-.06.7.2.2,0,0,1-.26.13.21.21,0,0,1-.13-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,25.49a1.16,1.16,0,0,0,.2-.51c0-.09,0-.19,0-.22s-.18,0-.26.09c.07-.07.13-.18.28-.19s.19.21.22.31a1.46,1.46,0,0,1,0,.71.2.2,0,0,1-.25.13.19.19,0,0,1-.13-.25A.15.15,0,0,1,52,25.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.61,25.28a1.1,1.1,0,0,0,.16-.53c0-.09,0-.19-.05-.21s-.18.05-.26.11.12-.19.27-.21.21.19.24.29a1.42,1.42,0,0,1,0,.71.2.2,0,0,1-.39-.09A.15.15,0,0,1,49.61,25.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.66,24a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.36,1.36,0,0,1,0,.7.2.2,0,0,1-.23.17.21.21,0,0,1-.16-.24A.19.19,0,0,1,47.66,24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.29,22.71a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.24-.24s.23.17.27.26a1.47,1.47,0,0,1,.09.7.22.22,0,0,1-.23.18.19.19,0,0,1-.17-.22A.14.14,0,0,1,45.29,22.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.32,22.55a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.08.09-.2.24-.24s.23.16.28.26a1.46,1.46,0,0,1,.09.7.2.2,0,1,1-.4,0A.19.19,0,0,1,43.32,22.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.16,22.39a1.06,1.06,0,0,0,.08-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.16.27.25a1.35,1.35,0,0,1,.11.7.2.2,0,0,1-.22.18.19.19,0,0,1-.18-.21A.19.19,0,0,1,42.16,22.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.52,22.35a1.18,1.18,0,0,0,.08-.54c0-.09,0-.19-.09-.21s-.16.08-.23.15c0-.09.08-.2.23-.24s.23.15.28.24a1.35,1.35,0,0,1,.11.7.19.19,0,0,1-.21.19.21.21,0,0,1-.19-.22A.19.19,0,0,1,40.52,22.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.58,22.29a1.06,1.06,0,0,0,.07-.54c0-.1,0-.19-.09-.21s-.16.08-.23.15c.05-.09.08-.2.23-.25s.23.16.28.25a1.46,1.46,0,0,1,.13.7.21.21,0,0,1-.21.19.2.2,0,0,1-.19-.22A.17.17,0,0,1,38.58,22.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.23,21.65a1.06,1.06,0,0,0,.07-.54c0-.09,0-.19-.09-.2s-.16.08-.23.15c.05-.09.08-.21.22-.25s.24.15.29.24a1.46,1.46,0,0,1,.13.7.21.21,0,0,1-.21.19.2.2,0,0,1-.19-.21S37.23,21.67,37.23,21.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.68,21.37a1.21,1.21,0,0,0,.06-.55c0-.09,0-.19-.09-.2s-.16.08-.23.16c0-.09.08-.21.22-.26s.24.15.29.24a1.59,1.59,0,0,1,.14.7.2.2,0,0,1-.21.19.21.21,0,0,1-.2-.21A.19.19,0,0,1,35.68,21.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.6,21a1.06,1.06,0,0,0,.06-.54c0-.1,0-.19-.09-.2s-.16.07-.23.15c0-.09.08-.21.22-.25s.24.14.29.23a1.36,1.36,0,0,1,.14.7.19.19,0,0,1-.21.19.19.19,0,0,1-.19-.21A.17.17,0,0,1,34.6,21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.66,21.34a1.21,1.21,0,0,0,.06-.55c0-.09,0-.18-.1-.2s-.16.09-.22.16c0-.09.07-.21.22-.25s.23.14.29.23a1.59,1.59,0,0,1,.14.7.2.2,0,0,1-.21.19.19.19,0,0,1-.19-.21A.17.17,0,0,1,33.66,21.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.9,22.61a1.25,1.25,0,0,0,.06-.55c0-.09-.05-.19-.1-.2s-.16.08-.23.16c0-.09.08-.21.22-.26s.24.15.29.24a1.4,1.4,0,0,1,.15.69.21.21,0,1,1-.41,0S34.89,22.63,34.9,22.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.58,22.15a1.09,1.09,0,0,0,.05-.55c0-.09,0-.18-.1-.2s-.16.09-.22.16c0-.09.07-.21.22-.25s.24.14.29.23a1.4,1.4,0,0,1,.15.69.21.21,0,0,1-.21.2.19.19,0,0,1-.19-.21A.17.17,0,0,1,32.58,22.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.72,23.21a1.12,1.12,0,0,0,.05-.55c0-.09-.05-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.15.3.24a1.4,1.4,0,0,1,.15.69.2.2,0,1,1-.4,0A.17.17,0,0,1,32.72,23.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.63,22.61a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.1-.2s-.16.08-.23.16c0-.09.08-.21.22-.26s.24.14.29.23a1.47,1.47,0,0,1,.15.7.19.19,0,0,1-.2.19.19.19,0,0,1-.2-.2S33.63,22.63,33.63,22.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.79,23.67a1.07,1.07,0,0,0,.05-.54c0-.09,0-.19-.09-.2s-.16.08-.23.16c0-.09.08-.21.22-.26s.24.14.29.23a1.44,1.44,0,0,1,.15.7A.19.19,0,0,1,34,24a.2.2,0,0,1-.2-.2S33.79,23.7,33.79,23.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.9,23.8a1.23,1.23,0,0,0,.06-.55c0-.09-.05-.19-.1-.2s-.16.09-.23.16c0-.09.08-.21.22-.26s.24.15.29.24a1.4,1.4,0,0,1,.15.69.2.2,0,0,1-.2.2.2.2,0,0,1-.2-.21S34.9,23.82,34.9,23.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.58,23.61a1.06,1.06,0,0,0,.07-.54c0-.09,0-.19-.1-.2s-.16.08-.23.15c0-.09.08-.2.23-.25s.23.15.28.24a1.32,1.32,0,0,1,.14.69.21.21,0,0,1-.21.2.2.2,0,0,1-.19-.21A.22.22,0,0,1,37.58,23.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,23.94a1.06,1.06,0,0,0,.07-.54c0-.09,0-.19-.09-.2s-.16.07-.23.15c.05-.09.09-.21.23-.25s.23.15.28.24a1.46,1.46,0,0,1,.13.7A.21.21,0,0,1,40,24,.19.19,0,0,1,40,23.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.81,24.38a1.14,1.14,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c0-.08.09-.2.23-.24s.23.16.28.25a1.35,1.35,0,0,1,.11.7.2.2,0,0,1-.22.18.19.19,0,0,1-.18-.21A.24.24,0,0,1,42.81,24.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.55,25.08a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14.09-.2.24-.24.23.17.27.26a1.46,1.46,0,0,1,.1.7.2.2,0,1,1-.4,0A.19.19,0,0,1,44.55,25.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.32,26.74a1.12,1.12,0,0,0,.12-.53c0-.1,0-.2-.08-.21s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.26a1.52,1.52,0,0,1,.07.71.2.2,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,46.32,26.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,26.36a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.05-.25.12c.06-.09.11-.2.26-.22s.21.18.25.28a1.48,1.48,0,0,1,0,.7.22.22,0,0,1-.24.17.21.21,0,0,1-.16-.24A.11.11,0,0,1,48,26.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,27a1.22,1.22,0,0,0,.15-.53c0-.09,0-.19-.05-.21s-.18.05-.26.11.12-.19.27-.21.21.19.24.29a1.38,1.38,0,0,1,0,.7.21.21,0,0,1-.24.16A.19.19,0,0,1,49,27.1.15.15,0,0,1,49,27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.47,26.93a1,1,0,0,0,.18-.52c0-.09,0-.19,0-.21s-.17,0-.25.1.12-.19.27-.2.2.2.23.3a1.39,1.39,0,0,1,0,.7.2.2,0,1,1-.39-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.73,28.13a1.15,1.15,0,0,0,.18-.52c0-.09,0-.19,0-.21s-.17,0-.26.1c.07-.08.13-.19.28-.2s.19.2.22.3a1.33,1.33,0,0,1,0,.71.2.2,0,0,1-.24.14.21.21,0,0,1-.15-.25A.25.25,0,0,1,50.73,28.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.84,28a1.15,1.15,0,0,0,.19-.51c0-.09,0-.19,0-.22s-.17,0-.26.09c.07-.07.13-.18.28-.19s.2.21.22.31a1.45,1.45,0,0,1,0,.71.21.21,0,0,1-.25.13.2.2,0,0,1-.14-.25A.16.16,0,0,1,51.84,28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.68,29.49a1.15,1.15,0,0,0,.18-.52c0-.09,0-.19,0-.21s-.18,0-.26.1c.07-.08.12-.19.27-.2s.2.2.23.3a1.33,1.33,0,0,1,0,.71.2.2,0,0,1-.24.14.21.21,0,0,1-.15-.25A.25.25,0,0,1,50.68,29.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.55,29.12a1.15,1.15,0,0,0,.19-.51c0-.09,0-.19,0-.22a.37.37,0,0,0-.26.1c.07-.08.13-.19.28-.19s.19.2.22.3a1.53,1.53,0,0,1,0,.7.2.2,0,0,1-.39-.11A.25.25,0,0,1,51.55,29.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.5,28.55a1.38,1.38,0,0,0,.16-.52c0-.09,0-.2-.06-.22s-.17.05-.25.11.12-.19.27-.21.2.19.24.29a1.42,1.42,0,0,1,0,.71.2.2,0,0,1-.24.15.19.19,0,0,1-.15-.24A.11.11,0,0,1,49.5,28.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.43,28.31a1.16,1.16,0,0,0,.13-.54c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.18.25.28a1.36,1.36,0,0,1,0,.7.2.2,0,1,1-.4-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.05,28.29a1.33,1.33,0,0,0,.1-.54c0-.09,0-.19-.09-.21s-.16.07-.23.14.09-.2.24-.24.22.17.27.26a1.47,1.47,0,0,1,.09.7.2.2,0,0,1-.4,0A.19.19,0,0,1,45.05,28.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,28.29a1.18,1.18,0,0,0,.07-.54c0-.09,0-.19-.09-.2s-.16.07-.23.15c0-.09.08-.21.23-.25s.23.15.28.24a1.46,1.46,0,0,1,.12.7.2.2,0,0,1-.4,0A.19.19,0,0,1,43,28.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.11,28.22a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.1-.2s-.16.08-.22.15c0-.09.07-.2.22-.25s.23.15.29.24a1.42,1.42,0,0,1,.13.69.2.2,0,0,1-.21.2.21.21,0,0,1-.19-.21A.24.24,0,0,1,41.11,28.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,26.6a1.21,1.21,0,0,0,.06-.55c0-.09-.05-.18-.1-.2s-.16.08-.23.16c.05-.09.08-.21.23-.25s.23.14.28.23a1.36,1.36,0,0,1,.14.7.19.19,0,0,1-.21.19.2.2,0,0,1-.19-.21A.19.19,0,0,1,39,26.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.93,27.22a1.22,1.22,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.25s.24.14.3.23a1.4,1.4,0,0,1,.14.69.2.2,0,0,1-.2.2.21.21,0,0,1-.2-.21A.19.19,0,0,1,37.93,27.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,27.89a1.25,1.25,0,0,0,0-.55c0-.09-.06-.18-.12-.19s-.15.09-.21.17c0-.09.06-.21.2-.27s.25.13.31.22a1.47,1.47,0,0,1,.2.68.22.22,0,0,1-.2.21A.21.21,0,0,1,37,28S37,27.91,37,27.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.24,29.28a1.32,1.32,0,0,0,0-.55c0-.08-.08-.17-.13-.18s-.14.11-.2.2c0-.1,0-.22.18-.29s.26.1.32.18a1.33,1.33,0,0,1,.26.66.2.2,0,0,1-.17.23.19.19,0,0,1-.22-.17A.11.11,0,0,1,37.24,29.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.06,29a1.32,1.32,0,0,0,0-.55c0-.08-.08-.18-.13-.18s-.15.11-.2.2c0-.1,0-.22.17-.29s.26.1.33.18a1.39,1.39,0,0,1,.26.66.19.19,0,0,1-.17.22.2.2,0,0,1-.23-.17A.17.17,0,0,1,36.06,29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.81,28.49a1.16,1.16,0,0,0,0-.55c0-.08-.08-.18-.13-.18s-.14.11-.2.19c0-.1,0-.22.18-.29s.26.11.32.19a1.43,1.43,0,0,1,.26.66.22.22,0,0,1-.18.23.19.19,0,0,1-.22-.17A.21.21,0,0,1,34.81,28.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.13,27.59a1.14,1.14,0,0,0,0-.55c0-.09-.06-.18-.12-.19s-.15.1-.21.18c0-.09.06-.21.2-.27s.25.12.31.2a1.4,1.4,0,0,1,.21.68.19.19,0,0,1-.18.21.2.2,0,0,1-.22-.18A.19.19,0,0,1,34.13,27.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.52,27.71a1.09,1.09,0,0,0,0-.54c0-.09-.06-.19-.12-.19s-.15.09-.21.17c0-.09.06-.21.2-.27s.25.12.31.21a1.56,1.56,0,0,1,.21.67.21.21,0,0,1-.41,0S35.52,27.74,35.52,27.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.25,26.61a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.1-.2S35,26,35,26c.05-.09.07-.21.22-.26s.24.14.29.23a1.43,1.43,0,0,1,.16.69.21.21,0,0,1-.21.2.2.2,0,0,1-.2-.2A.19.19,0,0,1,35.25,26.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.43,26.8a1.19,1.19,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.16.08-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.43,1.43,0,0,1,.15.69.2.2,0,0,1-.21.2.19.19,0,0,1-.19-.2A.19.19,0,0,1,36.43,26.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.19,28.83a1.24,1.24,0,0,0,0-.54c0-.09-.07-.19-.12-.19s-.15.1-.21.18c0-.09,0-.21.19-.28s.26.12.32.2a1.48,1.48,0,0,1,.22.67.2.2,0,1,1-.4,0A.25.25,0,0,1,38.19,28.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.61,27.79a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.09-.2s-.16.08-.23.15c0-.09.08-.2.22-.25s.24.14.29.23a1.36,1.36,0,0,1,.14.7.19.19,0,0,1-.2.19.2.2,0,0,1-.2-.2A.19.19,0,0,1,39.61,27.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.18,26.5a1.1,1.1,0,0,0,.07-.55c0-.09,0-.19-.09-.2s-.16.08-.23.15c0-.09.08-.2.23-.25s.23.15.28.24a1.46,1.46,0,0,1,.13.7.2.2,0,0,1-.21.19.19.19,0,0,1-.19-.21S41.18,26.52,41.18,26.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,26.3a1.21,1.21,0,0,0,.07-.55c0-.09,0-.19-.08-.2s-.17.07-.24.15c0-.09.09-.21.23-.25s.24.16.28.25a1.46,1.46,0,0,1,.12.7.19.19,0,0,1-.21.18.19.19,0,0,1-.19-.21A.14.14,0,0,1,43,26.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.7,26.08a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.16.07-.23.14.09-.2.23-.23.23.16.28.25a1.46,1.46,0,0,1,.09.7.2.2,0,1,1-.4,0A.19.19,0,0,1,44.7,26.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.86,24.12a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.16.07-.24.13.1-.19.25-.23.22.17.26.26a1.4,1.4,0,0,1,.08.71.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22S45.84,24.14,45.86,24.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.33,24.53a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.08.1-.2.25-.23s.22.18.26.27a1.4,1.4,0,0,1,.06.71.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46,25.11a1.13,1.13,0,0,0,.11-.54c0-.09,0-.19-.08-.2s-.16.06-.24.13c.06-.09.1-.2.25-.23s.22.16.26.26a1.36,1.36,0,0,1,.08.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,46,25.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.32,23.79a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.16.27.26a1.46,1.46,0,0,1,.1.7.2.2,0,1,1-.4,0A.24.24,0,0,1,44.32,23.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.86,24.25a1.09,1.09,0,0,0,.07-.55c0-.09,0-.19-.09-.2s-.16.08-.23.15c0-.09.09-.2.23-.25s.23.16.28.25a1.35,1.35,0,0,1,.12.7.19.19,0,0,1-.21.18.19.19,0,0,1-.19-.21A.19.19,0,0,1,40.86,24.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.59,24.1a1.06,1.06,0,0,0,.06-.54c0-.09,0-.19-.1-.2s-.16.08-.22.15c.05-.09.08-.21.22-.25s.24.14.29.23a1.59,1.59,0,0,1,.14.7.19.19,0,0,1-.21.19.19.19,0,0,1-.19-.2A.19.19,0,0,1,36.59,24.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.44,25.06a1.11,1.11,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.22.16c.05-.09.07-.2.21-.25s.25.14.3.23a1.41,1.41,0,0,1,.16.69.21.21,0,0,1-.21.2.22.22,0,0,1-.2-.21A.19.19,0,0,1,33.44,25.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.34,25.93a1.24,1.24,0,0,0,0-.55c0-.09-.05-.18-.1-.19s-.16.08-.22.16c.05-.09.07-.21.21-.26s.24.14.3.22a1.56,1.56,0,0,1,.17.69.22.22,0,0,1-.2.21.21.21,0,0,1-.21-.21A.19.19,0,0,1,31.34,25.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,27.22a1.14,1.14,0,0,0,0-.55c0-.09-.06-.18-.12-.19s-.15.1-.2.18a.36.36,0,0,1,.19-.27c.17,0,.25.11.31.2a1.33,1.33,0,0,1,.22.67.2.2,0,1,1-.4,0A.19.19,0,0,1,31,27.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.47,27.47a1.11,1.11,0,0,0,0-.54c0-.09-.06-.19-.12-.19s-.15.1-.2.18a.4.4,0,0,1,.18-.28c.17,0,.26.12.32.2a1.45,1.45,0,0,1,.24.67.2.2,0,1,1-.4,0A.22.22,0,0,1,29.47,27.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.3,28a1.05,1.05,0,0,0,0-.55c0-.08-.07-.18-.13-.18s-.14.11-.19.2c0-.1,0-.22.17-.29s.26.1.33.18a1.39,1.39,0,0,1,.26.66.2.2,0,0,1-.4.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.77,26.24a1,1,0,0,0,0-.55c0-.09-.05-.18-.1-.19s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.13.3.22a1.5,1.5,0,0,1,.18.69.2.2,0,0,1-.2.2.2.2,0,0,1-.21-.2S29.77,26.26,29.77,26.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.52,24.65a1.25,1.25,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.14.29.23a1.44,1.44,0,0,1,.17.69.2.2,0,0,1-.21.2.2.2,0,0,1-.2-.2A.19.19,0,0,1,31.52,24.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.45,22.33a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.09-.2s-.16.08-.23.15c.05-.09.08-.21.22-.25s.24.15.29.24a1.52,1.52,0,0,1,.14.69.22.22,0,0,1-.21.2.21.21,0,0,1-.2-.21A.19.19,0,0,1,36.45,22.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.2,21a1.06,1.06,0,0,0,.08-.54c0-.09,0-.19-.09-.21s-.16.08-.23.15c0-.09.09-.2.23-.25s.23.16.28.25a1.35,1.35,0,0,1,.11.7.2.2,0,0,1-.21.19.21.21,0,0,1-.19-.22A.19.19,0,0,1,40.2,21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.59,20.78a1.16,1.16,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.16.07-.23.14c0-.09.09-.2.24-.23s.22.16.27.26a1.46,1.46,0,0,1,.08.7.21.21,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,44.59,20.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.37,20.92a1.21,1.21,0,0,0,.14-.52c0-.1,0-.2-.07-.22s-.17.06-.24.12.1-.19.25-.22.21.19.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,1,1-.39-.07A.11.11,0,0,1,48.37,20.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.34,21.73a1.12,1.12,0,0,0,.21-.51c0-.09,0-.19,0-.21s-.17,0-.26.08c.07-.07.13-.18.29-.18s.18.21.21.31a1.55,1.55,0,0,1-.07.7.2.2,0,0,1-.38-.12A.16.16,0,0,1,53.34,21.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.36,22.69a1.17,1.17,0,0,0,.24-.5c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.14-.17.29-.16s.18.22.2.32a1.47,1.47,0,0,1-.11.7.2.2,0,0,1-.26.11.2.2,0,0,1-.11-.26S55.34,22.7,55.36,22.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.64,26.64a1.18,1.18,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.16.3-.14s.16.22.18.33a1.46,1.46,0,0,1-.15.69.21.21,0,0,1-.27.1.2.2,0,0,1-.09-.27S57.62,26.65,57.64,26.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.89,30a1.17,1.17,0,0,0,.29-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.15.06.15.23.15.34a1.32,1.32,0,0,1-.17.68.2.2,0,0,1-.36-.18A.1.1,0,0,1,59.89,30Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.79,34a1.13,1.13,0,0,0,.29-.46c0-.09.05-.19,0-.22s-.18,0-.28,0c.09-.05.17-.15.31-.12s.15.23.16.34a1.35,1.35,0,0,1-.19.68.19.19,0,0,1-.27.08.2.2,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.35,38.9a1.13,1.13,0,0,0,.29-.46c0-.09,0-.19,0-.22s-.18,0-.27,0a.36.36,0,0,1,.31-.12c.15.06.14.23.15.34a1.35,1.35,0,0,1-.19.68A.2.2,0,0,1,64.3,39,.15.15,0,0,1,64.35,38.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.55,42.05a1.15,1.15,0,0,0,.3-.46c0-.09,0-.19,0-.22s-.18,0-.28,0c.09-.06.17-.16.31-.14s.15.24.16.34a1.37,1.37,0,0,1-.18.69.19.19,0,0,1-.27.08.2.2,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.58,44.44a1.11,1.11,0,0,0,.29-.47c0-.08,0-.19,0-.22s-.18,0-.27,0c.09-.06.16-.15.31-.13s.15.23.16.34a1.32,1.32,0,0,1-.17.68.2.2,0,0,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.38,48.75a1.08,1.08,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.06.15-.16.3-.14s.16.22.17.33a1.35,1.35,0,0,1-.15.69.2.2,0,0,1-.26.1.2.2,0,0,1-.1-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.17,54.56a1,1,0,0,0,.36-.4c0-.08.08-.18,0-.22s-.17,0-.27,0a.39.39,0,0,1,.33-.07c.14.09.1.25.09.36a1.42,1.42,0,0,1-.29.64.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,59.62a1.21,1.21,0,0,0,.44-.33c0-.07.1-.16.08-.21a.43.43,0,0,0-.27-.06c.1,0,.2-.08.33,0s.06.27,0,.37a1.35,1.35,0,0,1-.41.58.19.19,0,0,1-.28,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.4,62.12a1.15,1.15,0,0,0,.46-.29c.06-.07.12-.16.09-.2s-.16-.08-.26-.08a.41.41,0,0,1,.34,0c.11.13,0,.28,0,.37a1.37,1.37,0,0,1-.45.55.2.2,0,0,1-.24-.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.4,64.37a1.37,1.37,0,0,0,.48-.27C70,64,70,64,70,63.91s-.16-.09-.26-.1c.1,0,.22-.06.34,0s0,.28,0,.38a1.4,1.4,0,0,1-.49.51.2.2,0,0,1-.28-.06.21.21,0,0,1,.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,66.73a1.07,1.07,0,0,0,.49-.24c.07-.06.14-.14.12-.19s-.15-.09-.25-.11c.1,0,.21,0,.33.06s0,.28,0,.37a1.48,1.48,0,0,1-.51.48A.2.2,0,0,1,68.8,67a.2.2,0,0,1,.07-.27A.13.13,0,0,1,69,66.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.83,69.21a1.22,1.22,0,0,0,.5-.22c.08,0,.15-.13.13-.18s-.15-.1-.25-.12c.11,0,.22,0,.33.07s0,.28-.06.37a1.45,1.45,0,0,1-.54.46.2.2,0,0,1-.18-.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.35,70.79a1.26,1.26,0,0,0,.51-.2c.07-.06.14-.13.13-.18s-.15-.1-.25-.13c.11,0,.22,0,.33.08s0,.28-.07.36a1.47,1.47,0,0,1-.55.46.21.21,0,0,1-.27-.1.2.2,0,0,1,.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.29,71.15a1.21,1.21,0,0,0,.51-.2c.08-.06.15-.13.14-.18s-.15-.11-.25-.13c.11,0,.22,0,.33.08s0,.28-.08.37a1.43,1.43,0,0,1-.55.44.2.2,0,0,1-.17-.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.83,69.65a1.08,1.08,0,0,0,.5-.21c.08-.06.15-.13.13-.18s-.14-.1-.24-.13a.39.39,0,0,1,.33.08c.09.14,0,.28-.07.36a1.52,1.52,0,0,1-.54.46.21.21,0,0,1-.28-.09.2.2,0,0,1,.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.08,67.84a1,1,0,0,0,.49-.23c.07,0,.14-.13.13-.18s-.15-.1-.25-.12c.1,0,.21,0,.33.06s0,.28-.06.37a1.28,1.28,0,0,1-.52.48.19.19,0,0,1-.27-.08.2.2,0,0,1,.07-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,64.26a1.25,1.25,0,0,0,.47-.27c.07-.07.13-.15.11-.2s-.16-.08-.26-.09a.38.38,0,0,1,.34,0c.11.13,0,.28,0,.37a1.32,1.32,0,0,1-.48.52.2.2,0,1,1-.22-.33A.11.11,0,0,1,68,64.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.27,56.49a1.12,1.12,0,0,0,.39-.39c0-.07.09-.17,0-.21s-.17,0-.27,0c.1,0,.19-.11.33,0s.09.26.07.37a1.45,1.45,0,0,1-.33.62.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.66,49.42a1,1,0,0,0,.27-.47c0-.09,0-.19,0-.23s-.18,0-.27.06a.4.4,0,0,1,.31-.15c.16.06.16.23.17.33a1.5,1.5,0,0,1-.15.7.19.19,0,0,1-.26.09.19.19,0,0,1-.1-.26A.43.43,0,0,1,65.66,49.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.05,45.81a1.28,1.28,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.43.43,0,0,1,.31-.14c.16.07.15.23.16.34a1.37,1.37,0,0,1-.16.69.2.2,0,0,1-.27.09.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.23,43.11a1.2,1.2,0,0,0,.28-.47c0-.08,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.16.3-.14s.16.23.17.34a1.49,1.49,0,0,1-.17.69.2.2,0,0,1-.27.08.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.06,39.26a1.11,1.11,0,0,0,.29-.47c0-.08,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.15.23.16.34a1.44,1.44,0,0,1-.18.68.2.2,0,0,1-.36-.18A.15.15,0,0,1,63.06,39.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.25,32.74a1.13,1.13,0,0,0,.29-.46c0-.09.05-.19,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.13s.14.24.15.35a1.47,1.47,0,0,1-.18.68.21.21,0,0,1-.28.08.21.21,0,0,1-.08-.27A.15.15,0,0,1,61.25,32.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.69,27.94a1,1,0,0,0,.29-.46c0-.08.05-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.14.24.15.34a1.38,1.38,0,0,1-.19.69.21.21,0,0,1-.27.08.2.2,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.21,27a1.06,1.06,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.06.15.23.16.33a1.47,1.47,0,0,1-.17.69.2.2,0,0,1-.36-.18A.15.15,0,0,1,59.21,27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.57,24.15a1.13,1.13,0,0,0,.26-.47c0-.09,0-.19,0-.23s-.18,0-.27.06a.4.4,0,0,1,.31-.15c.16.06.16.23.17.33a1.49,1.49,0,0,1-.14.7.2.2,0,0,1-.27.09.19.19,0,0,1-.1-.26A.18.18,0,0,1,57.57,24.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.49,22.44a1.17,1.17,0,0,0,.26-.48c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.06.15-.16.3-.14s.16.22.18.33a1.45,1.45,0,0,1-.14.69.2.2,0,1,1-.37-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.53,22.83a1.06,1.06,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.07.15-.16.3-.14s.16.23.17.33a1.34,1.34,0,0,1-.16.69.2.2,0,0,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.5,24.5a1.12,1.12,0,0,0,.3-.46c0-.08.05-.18,0-.22s-.18,0-.27,0c.09-.06.16-.15.31-.13s.15.24.15.35a1.38,1.38,0,0,1-.19.68.21.21,0,0,1-.27.08.2.2,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.36,31.29a1.08,1.08,0,0,0,.32-.45c0-.08,0-.18,0-.22s-.18,0-.27,0a.37.37,0,0,1,.31-.12c.16.08.14.24.14.35a1.45,1.45,0,0,1-.21.67.2.2,0,0,1-.28.07.21.21,0,0,1-.07-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.08,36.24a1.15,1.15,0,0,0,.32-.45c0-.09,0-.19,0-.22s-.18,0-.27,0c.09-.05.17-.14.32-.11s.13.24.13.35a1.45,1.45,0,0,1-.21.67.2.2,0,0,1-.28.07.22.22,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,39.81a1.29,1.29,0,0,0,.31-.46c0-.08,0-.18,0-.22s-.18,0-.27,0a.36.36,0,0,1,.31-.12c.16.07.14.24.14.35a1.33,1.33,0,0,1-.2.67.2.2,0,0,1-.27.08.21.21,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.65,44.8a1.17,1.17,0,0,0,.29-.47c0-.09,0-.19,0-.22s-.18,0-.28,0c.09-.06.16-.15.31-.14s.15.24.16.34A1.36,1.36,0,0,1,70,45a.2.2,0,0,1-.36-.18S69.63,44.81,69.65,44.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.28,48.57a1.12,1.12,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.06.15-.16.3-.14s.17.23.18.33a1.44,1.44,0,0,1-.15.69.2.2,0,0,1-.27.1.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.27,53.11a1.25,1.25,0,0,0,.34-.43c0-.08.06-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.32-.09c.15.08.12.25.12.35a1.58,1.58,0,0,1-.25.66.21.21,0,0,1-.29,0,.2.2,0,0,1-.05-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.16,57.08a1.18,1.18,0,0,0,.41-.37c.05-.08.09-.17.06-.21s-.18,0-.28,0c.1,0,.2-.1.34,0s.08.27.06.37a1.43,1.43,0,0,1-.36.61.19.19,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.25,58.21a1.14,1.14,0,0,0,.42-.35c0-.07.1-.17.07-.21s-.18,0-.28,0a.43.43,0,0,1,.34,0,.34.34,0,0,1,0,.38,1.36,1.36,0,0,1-.38.59.19.19,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.22,58.6a1.05,1.05,0,0,0,.42-.35c0-.07.1-.16.07-.2s-.17-.06-.27,0c.1,0,.2-.1.34,0s.06.27,0,.37a1.59,1.59,0,0,1-.39.6.21.21,0,0,1-.28-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.58,56.43a1,1,0,0,0,.39-.38c0-.07.09-.17.06-.21s-.18,0-.28,0a.39.39,0,0,1,.34-.05c.13.1.09.27.07.37a1.59,1.59,0,0,1-.34.62.22.22,0,0,1-.29,0,.21.21,0,0,1,0-.29S71.56,56.45,71.58,56.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.83,53.42a1.11,1.11,0,0,0,.34-.42c0-.09.07-.18,0-.22s-.18,0-.27,0c.09,0,.18-.13.32-.09s.12.25.12.36a1.6,1.6,0,0,1-.26.66.22.22,0,0,1-.29,0,.21.21,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.51,42.79a1.15,1.15,0,0,0,.3-.46c0-.09,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.15.24.15.34a1.41,1.41,0,0,1-.18.68.2.2,0,0,1-.35-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.11,33.63a1.27,1.27,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.18,0-.27,0a.37.37,0,0,1,.32-.11c.15.08.12.25.12.36a1.33,1.33,0,0,1-.24.66.19.19,0,0,1-.27.06.2.2,0,0,1-.06-.28A.12.12,0,0,1,67.11,33.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.87,29.13a1.35,1.35,0,0,0,.34-.43c0-.09.06-.19,0-.22s-.18,0-.27,0c.09,0,.17-.14.32-.1s.12.25.12.35a1.57,1.57,0,0,1-.25.67.21.21,0,0,1-.28.05.2.2,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.62,25.68a1.35,1.35,0,0,0,.34-.43c0-.09.06-.19,0-.22s-.18,0-.27,0c.09-.05.17-.14.32-.1s.12.25.12.35a1.57,1.57,0,0,1-.25.67.2.2,0,0,1-.28.05.21.21,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,20.81a1.1,1.1,0,0,0,.33-.43c0-.09.07-.19,0-.23s-.18,0-.28,0c.09-.05.18-.14.32-.1s.13.25.13.35a1.51,1.51,0,0,1-.24.67.2.2,0,1,1-.34-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.86,11.87a1.22,1.22,0,0,0,.25-.48c0-.09,0-.19,0-.22s-.17,0-.26.06c.08-.07.15-.17.3-.16s.16.23.18.33a1.38,1.38,0,0,1-.13.7.2.2,0,0,1-.26.1.21.21,0,0,1-.11-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,8.08a1.07,1.07,0,0,0,.17-.52c0-.09,0-.19-.05-.22s-.17,0-.25.11.12-.19.27-.2.2.19.23.29a1.32,1.32,0,0,1,0,.71.19.19,0,0,1-.24.14.2.2,0,0,1-.15-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.44,7.21a1.09,1.09,0,0,0,.15-.52c0-.1,0-.2-.06-.22s-.17,0-.25.12c.06-.08.11-.19.26-.22s.21.19.25.29a1.49,1.49,0,0,1,0,.7.2.2,0,0,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.87,6.38A1.1,1.1,0,0,0,54,5.85c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.19.25.28a1.54,1.54,0,0,1,0,.71.2.2,0,1,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.35,7.17a1.18,1.18,0,0,0,.17-.52c0-.09,0-.19-.05-.21s-.18,0-.26.1.12-.19.27-.2.21.19.24.29a1.43,1.43,0,0,1,0,.71.2.2,0,0,1-.24.15.21.21,0,0,1-.15-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.93,9.89a1.15,1.15,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.17,0-.26.07a.39.39,0,0,1,.29-.17c.17,0,.18.22.2.32a1.45,1.45,0,0,1-.09.7.19.19,0,0,1-.25.12A.2.2,0,0,1,57.9,10,.11.11,0,0,1,57.93,9.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.28,12.82a1.28,1.28,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0A.39.39,0,0,1,60.6,12c.15.06.15.23.16.33a1.47,1.47,0,0,1-.17.69.2.2,0,0,1-.36-.18A.15.15,0,0,1,60.28,12.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,19.3a1.2,1.2,0,0,0,.33-.43c0-.09.06-.19,0-.22s-.18,0-.27,0c.09-.05.17-.14.32-.1s.13.25.12.35a1.45,1.45,0,0,1-.23.67.21.21,0,0,1-.28.06.2.2,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.12,24.23a1.23,1.23,0,0,0,.35-.42c0-.08.06-.18,0-.22s-.17,0-.27,0c.09-.05.18-.14.32-.1s.12.26.12.36a1.5,1.5,0,0,1-.27.66.2.2,0,1,1-.33-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,27.73a1.09,1.09,0,0,0,.35-.41c0-.09.07-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.32-.09c.15.09.12.26.11.36a1.41,1.41,0,0,1-.27.65.2.2,0,1,1-.33-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.47,31.34a1.27,1.27,0,0,0,.35-.43c0-.08.06-.18,0-.22a.39.39,0,0,0-.28,0,.4.4,0,0,1,.33-.09c.14.09.11.25.11.36a1.41,1.41,0,0,1-.27.65.2.2,0,0,1-.28.05.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.3,33.3a1.16,1.16,0,0,0,.35-.43c0-.08.06-.18,0-.22s-.18,0-.27,0a.43.43,0,0,1,.32-.1c.15.09.12.26.12.36a1.47,1.47,0,0,1-.26.66.2.2,0,0,1-.28.05.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,35.15a1.12,1.12,0,0,0,.33-.43c0-.08.07-.18,0-.22s-.18,0-.28,0a.44.44,0,0,1,.33-.1c.15.08.12.25.12.36a1.45,1.45,0,0,1-.25.66.2.2,0,1,1-.34-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.13,35.36a1.18,1.18,0,0,0,.34-.44c0-.08.06-.18,0-.22s-.18,0-.28,0a.36.36,0,0,1,.32-.1c.15.08.13.25.12.35a1.31,1.31,0,0,1-.25.66.2.2,0,0,1-.28.06.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.92,33.14a1.23,1.23,0,0,0,.35-.42c0-.08.07-.18,0-.22s-.18,0-.28,0a.4.4,0,0,1,.33-.09c.15.09.12.26.11.36a1.5,1.5,0,0,1-.27.66.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.52,29.27a1.11,1.11,0,0,0,.36-.41c.05-.08.08-.18,0-.22s-.17,0-.27,0c.09,0,.18-.12.32-.08s.11.26.1.37a1.42,1.42,0,0,1-.29.64.19.19,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.78,23.66a1.23,1.23,0,0,0,.37-.41c0-.08.07-.18,0-.22s-.17,0-.27,0a.39.39,0,0,1,.33-.07c.14.09.1.25.09.36a1.4,1.4,0,0,1-.28.64.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28A.12.12,0,0,1,66.78,23.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.75,13.89a1.21,1.21,0,0,0,.29-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.15.24.15.34a1.31,1.31,0,0,1-.18.68.2.2,0,1,1-.35-.19A.12.12,0,0,1,60.75,13.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.44,6a1,1,0,0,0,.14-.53c0-.09,0-.2-.06-.22s-.17.06-.25.12.11-.19.26-.21.21.18.25.27a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.23.16A.2.2,0,0,1,52.41,6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,4.56A1.26,1.26,0,0,0,47.15,4c0-.09,0-.2-.07-.21s-.17.05-.25.11.11-.19.26-.21.21.18.25.27a1.41,1.41,0,0,1,0,.71A.2.2,0,0,1,47,4.63.09.09,0,0,1,47,4.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.67,5.45a1.12,1.12,0,0,0,.13-.53c0-.09,0-.2-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.17.26.27a1.52,1.52,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23A.19.19,0,0,1,41.67,5.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.47,6.38a1.33,1.33,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.1-.2.24-.24s.23.17.27.27a1.46,1.46,0,0,1,.08.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,37.47,6.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.46,7.65a1.21,1.21,0,0,0,.08-.55c0-.09,0-.19-.08-.2s-.17.07-.24.14c.05-.08.09-.2.23-.24s.23.16.28.25a1.35,1.35,0,0,1,.11.7.21.21,0,0,1-.22.19.2.2,0,0,1-.18-.22A.19.19,0,0,1,33.46,7.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.4,9.11a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.09-.2s-.16.07-.23.15c.05-.09.08-.21.23-.25s.23.15.28.24a1.44,1.44,0,0,1,.13.69.2.2,0,1,1-.4,0A.19.19,0,0,1,30.4,9.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.4,11.5a1.12,1.12,0,0,0,.05-.55c0-.09,0-.19-.09-.2s-.16.08-.23.16c.05-.09.08-.21.22-.26s.24.15.29.24a1.3,1.3,0,0,1,.14.69.2.2,0,0,1-.2.2.21.21,0,0,1-.2-.21A.14.14,0,0,1,28.4,11.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.87,14.27a1.11,1.11,0,0,0,.05-.55c0-.09,0-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.25s.25.14.3.23a1.4,1.4,0,0,1,.15.69.2.2,0,0,1-.21.2.19.19,0,0,1-.19-.21A.17.17,0,0,1,26.87,14.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.12,17a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.16.09-.22.16c.05-.09.07-.21.21-.26s.25.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,1,1-.4,0A.22.22,0,0,1,25.12,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.46,20.21a1.25,1.25,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.22.17a.39.39,0,0,1,.21-.27c.17,0,.24.14.3.23a1.53,1.53,0,0,1,.17.69.2.2,0,0,1-.2.2.2.2,0,0,1-.21-.2A.19.19,0,0,1,23.46,20.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.46,23.47a1.07,1.07,0,0,0,0-.54c0-.09-.06-.19-.11-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.25.14.3.23a1.49,1.49,0,0,1,.17.68.21.21,0,0,1-.2.21.2.2,0,0,1-.2-.2A.19.19,0,0,1,22.46,23.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.63,27.08a1.11,1.11,0,0,0,0-.55c0-.09-.05-.18-.11-.19s-.15.09-.21.17c0-.1.06-.22.2-.27s.25.13.3.22a1.42,1.42,0,0,1,.18.69.19.19,0,0,1-.19.2.21.21,0,0,1-.2-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,31.39a1.18,1.18,0,0,0-.09-.54c0-.08-.09-.17-.14-.16s-.14.12-.18.21a.42.42,0,0,1,.14-.31c.17-.05.27.08.35.15a1.51,1.51,0,0,1,.32.63.21.21,0,0,1-.15.25.2.2,0,0,1-.24-.15A.19.19,0,0,1,20,31.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.45,35.73a1.1,1.1,0,0,0-.19-.52c-.05-.07-.12-.15-.18-.13s-.1.14-.13.24A.42.42,0,0,1,19,35c.15-.08.28,0,.37.08a1.47,1.47,0,0,1,.44.56.2.2,0,0,1-.1.27.21.21,0,0,1-.27-.1S19.46,35.75,19.45,35.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.71,40a1.14,1.14,0,0,0-.3-.46c-.06-.06-.15-.12-.2-.09a.38.38,0,0,0-.07.26.38.38,0,0,1,0-.33.31.31,0,0,1,.37,0,1.46,1.46,0,0,1,.55.45.2.2,0,1,1-.32.24A.16.16,0,0,1,18.71,40Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.07,42.22a1.14,1.14,0,0,0-.33-.44c-.07-.06-.16-.11-.21-.08s-.06.17-.06.27a.44.44,0,0,1,0-.34c.12-.12.27-.05.37,0a1.35,1.35,0,0,1,.58.41.19.19,0,0,1,0,.28.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.4,45.54a1.18,1.18,0,0,0-.37-.41c-.07-.05-.17-.09-.21-.06a.34.34,0,0,0,0,.27.37.37,0,0,1-.05-.33c.11-.13.27-.08.37-.06a1.47,1.47,0,0,1,.61.36.2.2,0,1,1-.28.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.35,49.24a1,1,0,0,0-.39-.38c-.08,0-.18-.09-.22,0s0,.17,0,.27a.37.37,0,0,1-.07-.33c.1-.14.26-.1.37-.08a1.4,1.4,0,0,1,.63.32.19.19,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.4,52.33A1.12,1.12,0,0,0,17,52c-.08,0-.17-.08-.21,0s0,.17,0,.27a.4.4,0,0,1-.08-.33c.1-.14.26-.1.37-.09a1.42,1.42,0,0,1,.64.29.2.2,0,1,1-.25.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,53.51a1.21,1.21,0,0,0-.41-.36c-.08,0-.18-.07-.22,0s0,.18,0,.28c-.05-.1-.13-.19-.08-.33s.25-.11.36-.1a1.42,1.42,0,0,1,.64.29.2.2,0,0,1-.24.32A.15.15,0,0,1,17,53.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.11,53.46a1.16,1.16,0,0,0-.42-.36c-.08,0-.17-.07-.21,0s0,.18,0,.28a.43.43,0,0,1-.08-.33c.09-.14.26-.11.36-.1a1.55,1.55,0,0,1,.65.28.21.21,0,0,1,0,.29.22.22,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.19,50.67a1.3,1.3,0,0,0-.4-.37c-.08,0-.18-.08-.22,0s0,.17,0,.27a.37.37,0,0,1-.07-.33c.09-.14.26-.1.36-.09a1.46,1.46,0,0,1,.64.31.2.2,0,0,1-.25.31A.15.15,0,0,1,17.19,50.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.93,40.41a1.19,1.19,0,0,0-.27-.47c-.07-.06-.15-.12-.2-.1s-.08.16-.09.26a.42.42,0,0,1,0-.34c.13-.11.27,0,.37,0a1.42,1.42,0,0,1,.53.48.2.2,0,1,1-.34.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,30.61a1.09,1.09,0,0,0,0-.54c0-.09-.06-.19-.11-.19s-.16.09-.21.17c0-.09.05-.21.19-.27s.25.12.31.21a1.39,1.39,0,0,1,.2.68.19.19,0,0,1-.19.21.2.2,0,0,1-.21-.19A.25.25,0,0,1,16,30.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.11,25.29a1.09,1.09,0,0,0,.05-.55c0-.09-.05-.18-.1-.19s-.16.08-.22.16c0-.1.07-.21.21-.26s.24.14.3.23a1.4,1.4,0,0,1,.15.69.2.2,0,0,1-.2.2.2.2,0,0,1-.2-.21S17.11,25.31,17.11,25.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.36,17.47a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.11-.2s-.15.09-.22.17c.05-.1.07-.21.21-.27s.25.14.3.23a1.41,1.41,0,0,1,.17.69.2.2,0,0,1-.4,0A.25.25,0,0,1,21.36,17.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.79,10.75a1.25,1.25,0,0,0,.05-.55c0-.09-.05-.19-.11-.2s-.15.09-.22.17c0-.1.07-.21.21-.26s.25.13.3.22a1.43,1.43,0,0,1,.16.69.2.2,0,1,1-.4,0S23.79,10.77,23.79,10.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.13,8.25a1.07,1.07,0,0,0,.05-.54c0-.09,0-.19-.1-.2s-.16.08-.22.16c.05-.09.07-.21.21-.26s.25.14.3.23a1.43,1.43,0,0,1,.15.69.2.2,0,0,1-.4,0S26.13,8.27,26.13,8.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.2,6.24a1.21,1.21,0,0,0,.06-.55c0-.09,0-.19-.09-.2s-.16.08-.23.15c0-.09.08-.2.23-.25s.23.15.28.25a1.3,1.3,0,0,1,.13.69.2.2,0,0,1-.4,0A.19.19,0,0,1,30.2,6.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.85,4.91a1.18,1.18,0,0,0,.09-.54c0-.1,0-.2-.08-.21s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.16.27.25a1.4,1.4,0,0,1,.1.71A.2.2,0,0,1,34,5.2.2.2,0,0,1,33.83,5,.14.14,0,0,1,33.85,4.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.05,4.85a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.07-.21s-.17.07-.24.13.09-.19.24-.23.22.17.27.26a1.52,1.52,0,0,1,.07.71.19.19,0,0,1-.22.17A.2.2,0,0,1,37,4.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.68,5.35a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13c.06-.08.1-.2.25-.23s.22.18.26.27a1.52,1.52,0,0,1,.06.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.14.14,0,0,1,39.68,5.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.12,5.75a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.18.26.27a1.53,1.53,0,0,1,0,.71.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.48,6.27a1.22,1.22,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.11-.19.25-.22.22.18.25.28a1.4,1.4,0,0,1,0,.71.2.2,0,1,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.06,9.53a1.16,1.16,0,0,0,.2-.51c0-.09,0-.19,0-.22a.37.37,0,0,0-.26.1c.07-.08.13-.18.28-.19s.19.2.22.3a1.58,1.58,0,0,1,0,.71.22.22,0,0,1-.26.13A.2.2,0,0,1,56,9.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.9,11.16a1.11,1.11,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.43.43,0,0,1,.31-.14c.16.07.15.24.16.34a1.47,1.47,0,0,1-.17.69.2.2,0,0,1-.36-.18S60.88,11.17,60.9,11.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.75,14.77a1.05,1.05,0,0,0,.35-.42c0-.08.07-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.32-.09c.15.08.12.25.11.35a1.43,1.43,0,0,1-.26.66.22.22,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.06,17.39a1,1,0,0,0,.38-.4c0-.08.08-.17,0-.21s-.18,0-.28,0c.1,0,.19-.11.33-.06s.1.26.09.36a1.58,1.58,0,0,1-.31.64.2.2,0,1,1-.31-.26A.12.12,0,0,1,67.06,17.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.21,20.51a1.12,1.12,0,0,0,.39-.39c.05-.08.09-.17.06-.21s-.18,0-.28,0c.1,0,.19-.11.33-.05s.09.26.08.36a1.45,1.45,0,0,1-.34.63.2.2,0,0,1-.28,0,.22.22,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,22.18a1.28,1.28,0,0,0,.39-.38c0-.08.09-.18,0-.22s-.17,0-.27,0c.1,0,.19-.11.33-.06s.09.27.07.37a1.39,1.39,0,0,1-.33.62.2.2,0,1,1-.3-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.71,22.91a1.16,1.16,0,0,0,.4-.37c0-.08.08-.18,0-.22s-.17,0-.27,0c.09,0,.19-.11.33-.05a.33.33,0,0,1,.07.37,1.52,1.52,0,0,1-.34.62.22.22,0,0,1-.29,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.06,19.59a1.09,1.09,0,0,0,.38-.39c0-.08.09-.18,0-.22s-.17,0-.27,0a.4.4,0,0,1,.33-.06c.14.1.1.26.08.37a1.51,1.51,0,0,1-.32.63.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.23,17.37a1.05,1.05,0,0,0,.35-.42c0-.08.07-.18,0-.22a.44.44,0,0,0-.28,0,.43.43,0,0,1,.33-.08c.14.09.11.26.1.36a1.33,1.33,0,0,1-.28.65.2.2,0,1,1-.32-.24A.15.15,0,0,1,65.23,17.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.4,14.65a1,1,0,0,0,.32-.44c0-.09.06-.19,0-.22s-.18,0-.28,0c.09,0,.17-.14.32-.11s.13.24.14.35a1.6,1.6,0,0,1-.22.67.2.2,0,1,1-.35-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.66,12.44A1.08,1.08,0,0,0,57.9,12c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.17s.17.22.19.32a1.57,1.57,0,0,1-.1.7.21.21,0,0,1-.27.12.2.2,0,0,1-.11-.26A.43.43,0,0,1,57.66,12.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.94,11.08a1.18,1.18,0,0,0,.17-.52c0-.09,0-.19-.06-.21s-.17,0-.25.1c.07-.08.12-.19.27-.2s.2.19.23.29a1.38,1.38,0,0,1,0,.7.21.21,0,0,1-.25.15.2.2,0,0,1-.15-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.9,10.46A1.26,1.26,0,0,0,50,9.93c0-.09,0-.2-.06-.21s-.17,0-.25.11.11-.19.26-.21.21.18.25.28a1.37,1.37,0,0,1,0,.7.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.22,9.29a1,1,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17,0-.25.12c.06-.08.11-.19.26-.22s.21.18.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.23.16.21.21,0,0,1-.16-.24A.19.19,0,0,1,48.22,9.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.1,9.5A1.09,1.09,0,0,0,47.23,9c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,1,1-.39-.07A.11.11,0,0,1,47.1,9.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.66,10.18a1.22,1.22,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.06-.25.12c.07-.08.11-.19.26-.22s.21.19.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,1,1-.39-.07A.11.11,0,0,1,48.66,10.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.19,11.26a1.1,1.1,0,0,0,.15-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.18.25.28a1.54,1.54,0,0,1,0,.71.21.21,0,0,1-.24.16.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.79,12.54A1.15,1.15,0,0,0,54,12c0-.09,0-.19,0-.21s-.17,0-.26.1c.07-.08.13-.19.28-.2s.2.2.22.3a1.33,1.33,0,0,1,0,.71.2.2,0,1,1-.39-.11A.25.25,0,0,1,53.79,12.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.71,15.24a1.08,1.08,0,0,0,.24-.49c0-.09,0-.19,0-.22s-.18,0-.26.07.14-.17.29-.16.17.21.19.32a1.46,1.46,0,0,1-.1.7.2.2,0,0,1-.26.11.2.2,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.54,16.63a1.2,1.2,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.06.15-.16.3-.15s.16.22.18.32a1.35,1.35,0,0,1-.13.7.2.2,0,0,1-.26.11.22.22,0,0,1-.11-.27A.15.15,0,0,1,57.54,16.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.55,17.37a1.08,1.08,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.16.3-.14s.16.22.17.33a1.35,1.35,0,0,1-.15.69.2.2,0,0,1-.26.1.2.2,0,0,1-.1-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.75,17.64a1.13,1.13,0,0,0,.29-.46c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.16-.16.31-.14s.15.24.16.34a1.48,1.48,0,0,1-.18.69.2.2,0,1,1-.35-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.31,16.86a1,1,0,0,0,.29-.46c0-.08,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.15.07.14.24.15.34a1.41,1.41,0,0,1-.18.68.2.2,0,0,1-.27.09.21.21,0,0,1-.09-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.19,16.07a1.2,1.2,0,0,0,.28-.48c0-.08,0-.19,0-.22s-.18,0-.27.05c.08-.06.16-.16.31-.14s.16.23.17.34a1.61,1.61,0,0,1-.16.69.2.2,0,1,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.85,14.49A1.1,1.1,0,0,0,58.1,14c0-.09,0-.19,0-.22s-.17,0-.26.06c.08-.07.14-.17.29-.16s.17.22.19.33a1.42,1.42,0,0,1-.12.69.19.19,0,0,1-.26.11.19.19,0,0,1-.11-.26A.43.43,0,0,1,57.85,14.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.87,14.88a1.24,1.24,0,0,0,.18-.52c0-.09,0-.19,0-.22s-.17,0-.25.1.12-.18.27-.19.2.19.23.29a1.44,1.44,0,0,1,0,.71.19.19,0,0,1-.24.14.21.21,0,0,1-.15-.24A.16.16,0,0,1,52.87,14.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,13.57a1,1,0,0,0,.14-.53c0-.09,0-.2-.06-.22s-.17.06-.25.12.11-.19.26-.21.21.18.25.27a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.61,11.67a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.17.26.27a1.52,1.52,0,0,1,.05.71.2.2,0,0,1-.4-.07A.19.19,0,0,1,44.61,11.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.93,10.67a1.12,1.12,0,0,0,.12-.53c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.09.1-.2.25-.23s.22.17.26.27a1.36,1.36,0,0,1,.06.7.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.38,10.75a1.12,1.12,0,0,0,.1-.53c0-.1,0-.2-.08-.21s-.17.07-.24.14c.05-.09.09-.2.24-.24s.22.16.27.26a1.46,1.46,0,0,1,.09.7.2.2,0,1,1-.4,0A.24.24,0,0,1,37.38,10.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.58,10.4a1.18,1.18,0,0,0,.09-.54c0-.1,0-.2-.09-.21s-.16.07-.23.14.09-.2.23-.24.23.16.28.25a1.59,1.59,0,0,1,.11.7.21.21,0,0,1-.41,0S34.57,10.42,34.58,10.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.17,10.66a1.18,1.18,0,0,0,.07-.54c0-.09,0-.19-.09-.2s-.17.07-.23.15c0-.09.08-.21.22-.25s.24.15.29.24a1.46,1.46,0,0,1,.12.7.2.2,0,1,1-.4,0A.19.19,0,0,1,31.17,10.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.2,10.3a1.21,1.21,0,0,0,.06-.55c0-.09,0-.19-.1-.2s-.16.08-.22.16c0-.09.07-.21.22-.26s.23.15.29.24a1.59,1.59,0,0,1,.14.7.21.21,0,0,1-.41,0S28.19,10.32,28.2,10.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.89,11.91a1.07,1.07,0,0,0,0-.54c0-.09-.05-.19-.1-.2s-.16.08-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.53,1.53,0,0,1,.16.69.21.21,0,0,1-.21.2.2.2,0,0,1-.2-.2A.19.19,0,0,1,24.89,11.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,13.55a1.12,1.12,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.22.17c0-.1.07-.21.21-.26s.24.13.3.22a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.4,0A.17.17,0,0,1,22,13.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.38,15.17a1.1,1.1,0,0,0,0-.55c0-.09-.05-.18-.1-.19s-.16.08-.23.16c.05-.09.07-.21.22-.26s.24.14.29.23a1.27,1.27,0,0,1,.17.68.2.2,0,0,1-.2.21.2.2,0,0,1-.2-.2A.19.19,0,0,1,20.38,15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.37,16.54a1.1,1.1,0,0,0,0-.55c0-.09-.05-.18-.11-.19s-.15.08-.22.16c.05-.09.07-.21.22-.26s.24.14.29.23a1.37,1.37,0,0,1,.17.68.2.2,0,0,1-.2.21.2.2,0,0,1-.2-.2A.19.19,0,0,1,19.37,16.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.72,18.89a1.07,1.07,0,0,0,0-.54c0-.09-.05-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.4,0A.19.19,0,0,1,18.72,18.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.85,26.15a1.12,1.12,0,0,0,0-.55c0-.09-.05-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.53,1.53,0,0,1,.16.69.2.2,0,0,1-.2.2.2.2,0,0,1-.21-.2A.19.19,0,0,1,18.85,26.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.57,32.77a1.34,1.34,0,0,0-.11-.54c0-.08-.1-.16-.16-.16s-.12.13-.17.22a.42.42,0,0,1,.14-.31c.16-.06.27.07.35.14a1.45,1.45,0,0,1,.34.61.2.2,0,0,1-.14.25.2.2,0,0,1-.25-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.18,38a1.06,1.06,0,0,0-.25-.48c-.07-.07-.15-.13-.2-.11s-.08.15-.1.25a.4.4,0,0,1,0-.33c.14-.11.28,0,.38,0a1.49,1.49,0,0,1,.5.5.21.21,0,0,1-.06.28.2.2,0,0,1-.28-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.38,43.52A1.1,1.1,0,0,0,18,43.1C18,43,17.86,43,17.81,43a.41.41,0,0,0,0,.28c0-.1-.1-.2,0-.34s.27-.07.37,0a1.45,1.45,0,0,1,.6.38.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0S18.38,43.54,18.38,43.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.44,47.73a1.18,1.18,0,0,0-.39-.38c-.08-.05-.17-.08-.21-.05s0,.18,0,.27a.4.4,0,0,1-.07-.33c.1-.14.27-.09.37-.08a1.53,1.53,0,0,1,.63.33.2.2,0,1,1-.26.3A.1.1,0,0,1,18.44,47.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.8,52.69a1.05,1.05,0,0,0-.42-.35c-.08-.05-.18-.08-.21,0s0,.18,0,.27c0-.09-.12-.18-.08-.32s.26-.11.36-.1a1.33,1.33,0,0,1,.65.28.2.2,0,1,1-.24.32A.15.15,0,0,1,18.8,52.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.56,55.25a1.21,1.21,0,0,0-.41-.36c-.09,0-.18-.07-.22,0s0,.18,0,.27c0-.09-.12-.18-.08-.32s.26-.12.36-.11a1.45,1.45,0,0,1,.65.29.2.2,0,1,1-.24.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.89,56.34a1.07,1.07,0,0,0-.42-.36c-.08,0-.17-.08-.21,0s0,.18,0,.28c0-.1-.12-.19-.08-.33s.26-.11.36-.1a1.33,1.33,0,0,1,.65.28.2.2,0,1,1-.24.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.06,56.42a1.36,1.36,0,0,0-.42-.36c-.08,0-.18-.07-.22,0s0,.18,0,.27a.39.39,0,0,1-.09-.32c.09-.15.26-.11.36-.11a1.52,1.52,0,0,1,.66.28.2.2,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.43,57.43a1.14,1.14,0,0,0-.41-.37c-.08,0-.18-.07-.22,0s0,.18,0,.28a.43.43,0,0,1-.08-.33.36.36,0,0,1,.37-.1,1.42,1.42,0,0,1,.64.29.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.12.12,0,0,1,17.43,57.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.55,58.94a1.25,1.25,0,0,0-.42-.36c-.08,0-.18-.07-.22,0s0,.17,0,.27a.36.36,0,0,1-.08-.33c.09-.14.25-.11.36-.1a1.48,1.48,0,0,1,.65.28.2.2,0,1,1-.24.32A.12.12,0,0,1,17.55,58.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,59.91a1.09,1.09,0,0,0-.41-.35c-.09,0-.18-.07-.22,0s0,.18,0,.28a.4.4,0,0,1-.09-.33c.09-.14.26-.11.36-.1a1.41,1.41,0,0,1,.65.27.2.2,0,0,1-.23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.4,58.77a1.13,1.13,0,0,0-.42-.35c-.08,0-.18-.07-.22,0s0,.18,0,.27c-.05-.09-.13-.17-.09-.32s.26-.12.36-.11a1.39,1.39,0,0,1,.66.27.2.2,0,0,1-.24.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.31,100.61a1.17,1.17,0,0,0-.1-.54c0-.09-.09-.17-.14-.17s-.14.12-.18.21c0-.1,0-.22.14-.3s.27.07.35.15a1.4,1.4,0,0,1,.32.63.2.2,0,1,1-.39.09A.09.09,0,0,1,28.31,100.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.26,101.26a1.37,1.37,0,0,0-.07-.55c0-.08-.09-.17-.14-.17s-.14.12-.19.21c0-.1,0-.22.16-.3s.26.08.33.16a1.31,1.31,0,0,1,.3.64.19.19,0,0,1-.15.24.21.21,0,0,1-.24-.16A.09.09,0,0,1,29.26,101.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.65,100.45a1.05,1.05,0,0,0-.08-.54c0-.08-.08-.17-.14-.17s-.13.12-.18.21c0-.1,0-.22.15-.3s.27.08.34.16a1.34,1.34,0,0,1,.31.63.21.21,0,0,1-.16.24.2.2,0,0,1-.24-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.06,98.54A1.37,1.37,0,0,0,29,98c0-.08-.1-.16-.15-.16s-.13.13-.17.22c0-.1,0-.22.13-.31s.27.07.35.14a1.44,1.44,0,0,1,.34.62.2.2,0,1,1-.39.11A.11.11,0,0,1,29.06,98.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.87,97a1,1,0,0,0-.12-.53c0-.08-.1-.17-.16-.16s-.12.13-.16.22a.37.37,0,0,1,.12-.31c.16-.07.28.06.36.13a1.48,1.48,0,0,1,.36.61.21.21,0,0,1-.13.25.22.22,0,0,1-.26-.13S28.87,97,28.87,97Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.48,95.89a1.21,1.21,0,0,0-.13-.53c0-.08-.1-.16-.16-.16s-.12.13-.16.23c0-.11,0-.23.13-.32s.27.06.35.13a1.38,1.38,0,0,1,.36.61.19.19,0,0,1-.13.25.2.2,0,0,1-.25-.13A.19.19,0,0,1,29.48,95.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.32,95.35a1.2,1.2,0,0,0-.16-.52c0-.08-.11-.16-.16-.15s-.12.14-.15.23c0-.1,0-.22.11-.32s.27,0,.35.11a1.41,1.41,0,0,1,.4.59.2.2,0,0,1-.12.26.21.21,0,0,1-.26-.12A.19.19,0,0,1,28.32,95.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.41,94.19a1.13,1.13,0,0,0-.16-.52c-.05-.08-.12-.16-.17-.15s-.12.14-.15.24c0-.11,0-.23.1-.33a.34.34,0,0,1,.36.1,1.36,1.36,0,0,1,.41.58.2.2,0,0,1-.11.26.19.19,0,0,1-.26-.11A.19.19,0,0,1,28.41,94.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.91,92.38a1.21,1.21,0,0,0-.2-.51c-.05-.07-.12-.15-.18-.13s-.1.14-.13.24c0-.1,0-.22.08-.33a.35.35,0,0,1,.37.08,1.43,1.43,0,0,1,.44.55.2.2,0,0,1-.09.27.2.2,0,0,1-.27-.1A.19.19,0,0,1,27.91,92.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.48,90.29a1.15,1.15,0,0,0-.23-.49c-.06-.07-.14-.14-.19-.12s-.09.15-.11.25A.4.4,0,0,1,27,89.6c.15-.1.28,0,.37.05a1.37,1.37,0,0,1,.49.52.2.2,0,0,1-.08.27.2.2,0,0,1-.27-.07S27.49,90.32,27.48,90.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.55,87.41a1.15,1.15,0,0,0-.29-.46c-.07-.07-.16-.12-.2-.1S26,87,26,87.11a.38.38,0,0,1,0-.33.31.31,0,0,1,.37,0,1.49,1.49,0,0,1,.55.46.21.21,0,0,1-.05.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.38,84.43a1.21,1.21,0,0,0-.32-.45c-.07-.06-.16-.11-.2-.08s-.07.16-.07.26c0-.1-.08-.2,0-.33s.27,0,.37,0a1.46,1.46,0,0,1,.57.43.2.2,0,0,1,0,.28.2.2,0,0,1-.28,0A.25.25,0,0,1,25.38,84.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.62,82.16a1.18,1.18,0,0,0-.31-.45c-.07-.06-.16-.11-.21-.08s-.06.16-.07.26a.42.42,0,0,1,0-.33c.13-.12.28,0,.38,0A1.51,1.51,0,0,1,25,82a.2.2,0,1,1-.31.26A.25.25,0,0,1,24.62,82.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.54,80.38a1.14,1.14,0,0,0-.33-.44c-.07-.06-.16-.11-.21-.08s-.06.17-.06.27a.44.44,0,0,1,0-.34c.12-.12.27-.05.37,0a1.35,1.35,0,0,1,.58.41.2.2,0,0,1-.3.26A.21.21,0,0,1,24.54,80.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.09,78.87a1.34,1.34,0,0,0-.34-.43c-.07,0-.16-.1-.21-.07s-.06.16-.05.26a.37.37,0,0,1,0-.33c.12-.13.27-.06.37,0a1.41,1.41,0,0,1,.59.4.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0A.25.25,0,0,1,24.09,78.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.84,78.3a1.14,1.14,0,0,0-.35-.42c-.07-.06-.16-.1-.21-.07s-.05.17-.05.27a.4.4,0,0,1,0-.34c.11-.13.27-.07.37,0a1.49,1.49,0,0,1,.59.39.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.25.25,0,0,1,24.84,78.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.74,78.81a1.14,1.14,0,0,0-.35-.42c-.07-.06-.16-.11-.21-.08a.38.38,0,0,0,0,.27.37.37,0,0,1,0-.33c.11-.13.27-.07.37,0a1.48,1.48,0,0,1,.6.39.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.27,79.83a1.21,1.21,0,0,0-.34-.42c-.08-.06-.17-.11-.21-.08s-.06.17,0,.27c0-.1-.1-.2,0-.33a.34.34,0,0,1,.38,0,1.49,1.49,0,0,1,.59.39.21.21,0,1,1-.3.28A.25.25,0,0,1,26.27,79.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.73,81.52a1.12,1.12,0,0,0-.34-.43c-.07-.06-.16-.1-.2-.08s-.06.17-.06.27c0-.1-.09-.2,0-.33s.28-.06.38,0a1.45,1.45,0,0,1,.58.4.2.2,0,0,1-.3.27A.25.25,0,0,1,27.73,81.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.59,83a1,1,0,0,0-.31-.45c-.07-.06-.16-.12-.2-.09s-.07.17-.07.27a.37.37,0,0,1,0-.34c.12-.12.27-.05.37,0a1.34,1.34,0,0,1,.56.43.2.2,0,0,1-.31.25A.21.21,0,0,1,28.59,83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.81,84.76a1.18,1.18,0,0,0-.29-.47c-.07-.06-.15-.12-.2-.1s-.08.16-.08.26c0-.1-.07-.21,0-.33s.28,0,.37,0a1.46,1.46,0,0,1,.54.46.2.2,0,0,1-.05.28.19.19,0,0,1-.28,0A.21.21,0,0,1,28.81,84.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.89,84.91a1.14,1.14,0,0,0-.26-.48c-.06-.07-.14-.13-.19-.11s-.09.15-.1.25c0-.1-.06-.21,0-.33s.28,0,.37,0a1.4,1.4,0,0,1,.51.49.2.2,0,0,1-.34.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.34,86.15a1.15,1.15,0,0,0-.23-.49c-.06-.07-.14-.14-.19-.12s-.09.15-.11.25a.42.42,0,0,1,.05-.34.33.33,0,0,1,.37.06,1.41,1.41,0,0,1,.49.51.21.21,0,0,1-.08.28.2.2,0,0,1-.27-.08A.11.11,0,0,1,30.34,86.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.19,88.28a1.18,1.18,0,0,0-.19-.51c-.05-.08-.12-.16-.17-.14s-.11.14-.14.24c0-.11,0-.23.09-.33s.28,0,.37.09a1.43,1.43,0,0,1,.42.56.2.2,0,0,1-.1.27.21.21,0,0,1-.27-.1A.25.25,0,0,1,31.19,88.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.64,89.93a1.21,1.21,0,0,0-.13-.53c0-.08-.11-.16-.16-.15s-.13.13-.16.22a.39.39,0,0,1,.12-.32c.16-.06.27.06.35.13a1.52,1.52,0,0,1,.37.6.21.21,0,0,1-.13.26.19.19,0,0,1-.25-.13A.11.11,0,0,1,32.64,89.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.06,91.57A1.17,1.17,0,0,0,33,91c0-.09-.09-.17-.15-.17s-.13.13-.17.22a.42.42,0,0,1,.14-.31c.16-.06.27.07.34.14a1.33,1.33,0,0,1,.34.63.19.19,0,0,1-.14.24.2.2,0,0,1-.25-.14A.11.11,0,0,1,33.06,91.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.78,92.67a1.21,1.21,0,0,0-.08-.55c0-.08-.09-.17-.14-.17s-.14.12-.18.21c0-.1,0-.22.15-.3s.26.08.34.16a1.41,1.41,0,0,1,.3.64.2.2,0,0,1-.15.24.21.21,0,0,1-.24-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,94a1.2,1.2,0,0,0-.06-.55c0-.08-.09-.17-.14-.17s-.14.11-.19.2c0-.1,0-.22.16-.29s.26.09.34.16a1.49,1.49,0,0,1,.29.65.21.21,0,0,1-.16.24A.2.2,0,0,1,34,94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.66,95.28a1.06,1.06,0,0,0,0-.55c0-.09-.07-.18-.13-.18s-.14.11-.19.2c0-.1,0-.22.17-.29s.26.1.33.17a1.56,1.56,0,0,1,.27.66.2.2,0,0,1-.4.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.66,96.73a1.12,1.12,0,0,0,0-.54c0-.09-.07-.18-.12-.19s-.15.11-.2.19c0-.09,0-.21.17-.28s.26.11.33.19a1.42,1.42,0,0,1,.25.66.22.22,0,0,1-.18.23.2.2,0,0,1-.22-.18S35.66,96.76,35.66,96.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.72,98.12a1.11,1.11,0,0,0,0-.54c0-.09-.07-.18-.12-.19s-.15.11-.2.19c0-.1,0-.22.18-.28s.25.1.32.18a1.46,1.46,0,0,1,.25.67.2.2,0,0,1-.18.22.19.19,0,0,1-.22-.17A.21.21,0,0,1,35.72,98.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.4,99.27a1.15,1.15,0,0,0,0-.55c0-.09-.07-.18-.12-.18s-.15.1-.21.18c0-.09.05-.21.19-.28s.25.11.32.19a1.58,1.58,0,0,1,.24.67.2.2,0,1,1-.4,0S36.4,99.29,36.4,99.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.06,101.05a1.15,1.15,0,0,0,0-.55c0-.08-.07-.18-.13-.18s-.14.1-.2.19c0-.1,0-.22.18-.29s.26.11.32.19a1.46,1.46,0,0,1,.25.67.2.2,0,0,1-.18.22.19.19,0,0,1-.22-.17A.21.21,0,0,1,37.06,101.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.53,102.53a1.11,1.11,0,0,0,0-.54c0-.09-.07-.18-.12-.19s-.15.1-.2.19c0-.1,0-.22.18-.28s.26.11.32.19a1.58,1.58,0,0,1,.24.67.2.2,0,1,1-.4,0S37.53,102.56,37.53,102.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.34,104.17a1.25,1.25,0,0,0,0-.55c0-.09-.06-.18-.11-.19s-.16.09-.22.17c0-.09.06-.21.2-.27s.25.13.31.22a1.38,1.38,0,0,1,.19.68.2.2,0,0,1-.4,0A.19.19,0,0,1,38.34,104.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.34,105.36a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.1-.2s-.16.08-.22.15c.05-.09.07-.2.22-.25s.24.15.29.24a1.52,1.52,0,0,1,.14.69.21.21,0,0,1-.21.2.2.2,0,0,1-.19-.21A.19.19,0,0,1,39.34,105.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.42,106.34a1.19,1.19,0,0,0,.12-.54c0-.09,0-.19-.08-.21s-.16.06-.24.13.1-.2.25-.23.22.17.26.27a1.47,1.47,0,0,1,.07.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.14.14,0,0,1,41.42,106.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.81,106.65a1.26,1.26,0,0,0,.15-.53c0-.09,0-.19-.06-.21s-.17,0-.25.11.11-.19.26-.21.21.18.24.28a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,105.9a1.23,1.23,0,0,0,.17-.53c0-.09,0-.19-.06-.21s-.17,0-.25.11a.4.4,0,0,1,.27-.21c.17,0,.2.19.23.29a1.42,1.42,0,0,1,0,.71A.2.2,0,1,1,44,106,.15.15,0,0,1,44,105.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.73,105a1,1,0,0,0,.17-.52c0-.09,0-.19,0-.21s-.17,0-.25.1.12-.18.27-.2.2.2.23.29a1.43,1.43,0,0,1,0,.71.2.2,0,1,1-.39-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.07,103.61a1.14,1.14,0,0,0,.17-.52c0-.09,0-.19-.06-.21s-.17,0-.25.1.12-.18.27-.2.2.19.23.29a1.42,1.42,0,0,1,0,.71.21.21,0,1,1-.4-.1A.25.25,0,0,1,45.07,103.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.19,103.41a1.3,1.3,0,0,0,.2-.51c0-.09,0-.2,0-.22s-.18,0-.26.09.12-.18.28-.19.19.2.21.31a1.4,1.4,0,0,1,0,.7.2.2,0,0,1-.25.14.22.22,0,0,1-.14-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.59,101.89a1.05,1.05,0,0,0,.2-.51c0-.09,0-.19,0-.22s-.17,0-.26.09c.08-.07.13-.18.29-.18s.19.2.21.3a1.34,1.34,0,0,1-.05.71.2.2,0,0,1-.25.13.19.19,0,0,1-.13-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.75,101.25a1.1,1.1,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.08c.08-.07.14-.17.29-.17s.18.21.21.31a1.49,1.49,0,0,1-.09.71.2.2,0,0,1-.25.12.21.21,0,0,1-.13-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.78,99.94a1.09,1.09,0,0,0,.22-.5c0-.09,0-.19,0-.22s-.17,0-.26.08a.39.39,0,0,1,.29-.17c.16,0,.18.21.2.31a1.47,1.47,0,0,1-.08.71.2.2,0,0,1-.26.12.21.21,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.43,98.86a1.08,1.08,0,0,0,.24-.49c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.21.19.32a1.46,1.46,0,0,1-.1.7.2.2,0,0,1-.26.11.2.2,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.93,97.29a1.24,1.24,0,0,0,.26-.48c0-.09,0-.2,0-.23s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.22.19.33a1.44,1.44,0,0,1-.13.69.19.19,0,0,1-.26.11.21.21,0,0,1-.11-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.9,95.79a1,1,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.07.15-.16.3-.14s.16.23.17.33a1.58,1.58,0,0,1-.16.69.2.2,0,0,1-.27.09.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.49,94a1.29,1.29,0,0,0,.31-.46c0-.09.05-.19,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.24.14.34a1.34,1.34,0,0,1-.2.68.2.2,0,0,1-.27.08.21.21,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.17,92.24a1.27,1.27,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.18,0-.27,0a.38.38,0,0,1,.32-.11.33.33,0,0,1,.12.36,1.44,1.44,0,0,1-.24.66.2.2,0,0,1-.28.06.21.21,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.07,89.58a1.11,1.11,0,0,0,.37-.4c.05-.08.08-.17.05-.21s-.18,0-.28,0a.4.4,0,0,1,.33-.07c.14.1.1.26.09.37a1.46,1.46,0,0,1-.31.64.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29S52.05,89.6,52.07,89.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.9,88.33a1.26,1.26,0,0,0,.4-.37c.05-.08.09-.17.06-.22s-.18,0-.28,0a.39.39,0,0,1,.34,0c.13.11.08.27.06.37a1.44,1.44,0,0,1-.35.62.2.2,0,0,1-.3-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.45,87.15a1.35,1.35,0,0,0,.42-.35c.05-.08.09-.17.06-.21s-.17-.06-.27,0a.43.43,0,0,1,.34,0c.13.11.07.27,0,.37a1.39,1.39,0,0,1-.38.6.19.19,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.7,85.51a1.18,1.18,0,0,0,.43-.33c.06-.08.11-.17.08-.21s-.17-.06-.27-.06c.1,0,.2-.09.33,0s.06.27,0,.37a1.38,1.38,0,0,1-.4.58.19.19,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.58,83.53a1.08,1.08,0,0,0,.46-.3c.06-.06.12-.15.09-.2S55,83,54.87,83c.1,0,.21-.08.34,0s0,.28,0,.38a1.46,1.46,0,0,1-.45.55.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.42,81.45a1.2,1.2,0,0,0,.48-.26C56,81.12,56,81,56,81s-.16-.08-.26-.09a.38.38,0,0,1,.34,0c.1.14,0,.28,0,.37a1.4,1.4,0,0,1-.48.52.21.21,0,0,1-.23-.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.47,79.41a1.09,1.09,0,0,0,.49-.25c.07-.06.13-.14.11-.19s-.15-.09-.25-.1a.4.4,0,0,1,.33,0c.1.14,0,.28,0,.38a1.47,1.47,0,0,1-.5.49.21.21,0,0,1-.28-.06.2.2,0,0,1,.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,77.22a1.13,1.13,0,0,0,.47-.27c.07-.06.13-.15.11-.2s-.16-.08-.26-.09c.1,0,.21-.06.33,0a.31.31,0,0,1,0,.37,1.48,1.48,0,0,1-.47.53.21.21,0,0,1-.23-.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.37,75.53a1,1,0,0,0,.45-.31c.06-.07.12-.15.09-.2s-.16-.07-.26-.07c.1,0,.21-.08.34,0a.35.35,0,0,1,0,.38,1.35,1.35,0,0,1-.44.55.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.4,75.85a1.33,1.33,0,0,0,.46-.28c.07-.07.13-.15.1-.2s-.16-.08-.26-.09a.45.45,0,0,1,.34,0c.11.13,0,.28,0,.38a1.57,1.57,0,0,1-.47.53.2.2,0,1,1-.23-.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.51,76.79a1.34,1.34,0,0,0,.46-.31c.06-.07.12-.15.09-.2s-.16-.07-.26-.08a.38.38,0,0,1,.33,0c.12.13,0,.28,0,.38a1.46,1.46,0,0,1-.45.55.2.2,0,1,1-.24-.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.56,78.59a1.08,1.08,0,0,0,.46-.3c.06-.06.12-.15.1-.19S55,78,54.86,78c.1,0,.21-.06.33,0s0,.28,0,.37a1.49,1.49,0,0,1-.46.55.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.83,80.38a1,1,0,0,0,.45-.31c.06-.06.12-.15.09-.2s-.16-.07-.26-.07c.1,0,.21-.08.34,0a.35.35,0,0,1,0,.38,1.35,1.35,0,0,1-.44.55.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,81.56a1.11,1.11,0,0,0,.43-.33c.06-.08.11-.17.08-.21s-.17-.06-.27-.06a.37.37,0,0,1,.34,0c.12.11.06.27,0,.37a1.41,1.41,0,0,1-.41.58.2.2,0,0,1-.27-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.46,83a1,1,0,0,0,.42-.35c0-.07.1-.17.07-.21a.38.38,0,0,0-.27,0,.38.38,0,0,1,.33,0c.13.12.07.27.05.38a1.36,1.36,0,0,1-.38.59A.2.2,0,0,1,52.4,83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.1,84.23a1,1,0,0,0,.38-.39c.05-.08.08-.18.05-.22s-.18,0-.28,0a.38.38,0,0,1,.33-.07c.15.1.1.26.09.36a1.58,1.58,0,0,1-.31.64.21.21,0,0,1-.29,0,.22.22,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.09,85.78a1,1,0,0,0,.38-.4c0-.08.08-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.33-.07c.14.1.1.26.09.36a1.41,1.41,0,0,1-.31.64.2.2,0,1,1-.31-.25A.12.12,0,0,1,51.09,85.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,86.5a1.07,1.07,0,0,0,.34-.43c0-.08.07-.18,0-.22s-.18,0-.28,0a.4.4,0,0,1,.33-.09c.15.08.12.25.12.35a1.43,1.43,0,0,1-.26.66.2.2,0,0,1-.28.06.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.39,88a1.37,1.37,0,0,0,.35-.43c0-.08.06-.18,0-.22s-.18,0-.27,0c.09,0,.18-.14.32-.1s.12.25.12.36a1.47,1.47,0,0,1-.26.66.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28A.12.12,0,0,1,50.39,88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.36,89.33a1.16,1.16,0,0,0,.31-.45c0-.09,0-.19,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.24.14.34a1.44,1.44,0,0,1-.2.68.2.2,0,0,1-.27.08.21.21,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.81,90.72a1.2,1.2,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.15.3-.13s.16.23.17.33a1.47,1.47,0,0,1-.17.69.2.2,0,0,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.23,92.11a1.11,1.11,0,0,0,.26-.48c0-.09,0-.19,0-.22a.36.36,0,0,0-.27.06.38.38,0,0,1,.3-.15c.16.05.16.22.18.33a1.57,1.57,0,0,1-.13.69.2.2,0,0,1-.27.1.19.19,0,0,1-.1-.26A.43.43,0,0,1,48.23,92.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.27,93.4a1,1,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.08c.08-.07.14-.17.29-.17s.18.21.2.32a1.45,1.45,0,0,1-.09.7.2.2,0,0,1-.26.12.22.22,0,0,1-.12-.26A.43.43,0,0,1,47.27,93.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.5,94.93a1.17,1.17,0,0,0,.21-.51c0-.09,0-.2,0-.22s-.17,0-.26.08a.41.41,0,0,1,.29-.18c.16,0,.18.21.21.31a1.47,1.47,0,0,1-.06.71.21.21,0,0,1-.25.13.2.2,0,0,1-.13-.26A.11.11,0,0,1,46.5,94.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.78,96.2a1.15,1.15,0,0,0,.18-.51c0-.1,0-.2,0-.22s-.18,0-.26.1c.07-.08.12-.19.27-.2s.2.2.23.3a1.44,1.44,0,0,1,0,.71.2.2,0,0,1-.25.14.21.21,0,0,1-.14-.25A.25.25,0,0,1,45.78,96.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,97.94a1.33,1.33,0,0,0,.16-.52c0-.09,0-.19-.06-.22s-.17,0-.25.11.11-.19.26-.2.21.18.24.28a1.31,1.31,0,0,1,0,.71A.2.2,0,0,1,45,98,.11.11,0,0,1,45,97.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.18,99.57a1.09,1.09,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.52,1.52,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.24,101.36a1.27,1.27,0,0,0,.11-.54c0-.09,0-.19-.08-.2s-.17.06-.24.13c.06-.09.1-.2.24-.23s.23.17.27.26a1.4,1.4,0,0,1,.07.71.19.19,0,0,1-.22.17.21.21,0,0,1-.18-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.41,102.59a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c0-.09.09-.2.24-.24s.22.16.27.25a1.52,1.52,0,0,1,.1.71.2.2,0,0,1-.22.18.2.2,0,0,1-.18-.22A.24.24,0,0,1,42.41,102.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.75,104a1,1,0,0,0,.08-.54c0-.09,0-.19-.08-.2s-.16.07-.23.14c0-.09.08-.2.23-.24s.23.16.28.25a1.46,1.46,0,0,1,.1.7.21.21,0,0,1-.22.19.2.2,0,0,1-.18-.22A.24.24,0,0,1,41.75,104Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.08,103.22a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.09-.2s-.16.08-.23.15c0-.09.08-.21.22-.25s.24.15.29.24a1.4,1.4,0,0,1,.14.69.22.22,0,0,1-.21.2.2.2,0,0,1-.19-.21A.19.19,0,0,1,41.08,103.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,103.72a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.15.09-.22.16c.05-.09.07-.21.21-.26s.25.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.4,0A.22.22,0,0,1,40,103.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.09,101.71a1.1,1.1,0,0,0,0-.54c0-.09-.06-.19-.12-.19s-.15.1-.2.18c0-.1.05-.22.19-.28s.25.12.31.2a1.41,1.41,0,0,1,.22.68.19.19,0,0,1-.18.21.2.2,0,0,1-.22-.18A.22.22,0,0,1,39.09,101.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,99a1.14,1.14,0,0,0,0-.55c0-.09-.06-.18-.11-.18s-.15.09-.21.18c0-.1,0-.22.19-.28s.25.12.31.2a1.33,1.33,0,0,1,.22.67.2.2,0,1,1-.4,0A.19.19,0,0,1,38,99Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.49,94.83a1.15,1.15,0,0,0,0-.55c0-.09-.07-.18-.12-.18s-.15.1-.2.18a.4.4,0,0,1,.18-.28c.17,0,.26.11.32.2a1.41,1.41,0,0,1,.23.66.2.2,0,1,1-.4,0A.19.19,0,0,1,36.49,94.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,90.94a1.2,1.2,0,0,0-.06-.55c0-.08-.09-.17-.14-.17s-.14.12-.19.2c0-.1,0-.22.16-.29s.27.09.34.16a1.49,1.49,0,0,1,.29.65.2.2,0,0,1-.16.23A.21.21,0,0,1,35,91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,86.79a1.2,1.2,0,0,0-.16-.52c0-.08-.12-.16-.17-.15s-.12.14-.15.24c0-.11,0-.22.1-.32a.31.31,0,0,1,.36.1,1.3,1.3,0,0,1,.41.58.2.2,0,0,1-.37.15A.31.31,0,0,1,33,86.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.65,82.21a1.21,1.21,0,0,0-.31-.46c-.06-.06-.15-.12-.2-.09s-.07.16-.07.26c0-.1-.08-.21,0-.34s.28,0,.38,0A1.46,1.46,0,0,1,31,82a.2.2,0,1,1-.32.24S30.65,82.23,30.65,82.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,79a1.17,1.17,0,0,0-.36-.42c-.07,0-.16-.1-.21-.07a.41.41,0,0,0,0,.28c0-.1-.1-.2,0-.34s.27-.07.37,0a1.45,1.45,0,0,1,.6.38.19.19,0,0,1,0,.28.21.21,0,0,1-.29,0S29,79,29,79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.67,76.27a1.29,1.29,0,0,0-.37-.41c-.07,0-.17-.09-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.33s.27-.08.37-.06A1.37,1.37,0,0,1,28,76a.22.22,0,0,1,0,.29.21.21,0,0,1-.29,0A.21.21,0,0,1,27.67,76.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.5,74.53a1.2,1.2,0,0,0-.38-.4c-.08,0-.17-.09-.22-.06s0,.18,0,.28a.39.39,0,0,1-.05-.34c.1-.13.26-.08.36-.06a1.28,1.28,0,0,1,.62.34.19.19,0,0,1,0,.28.22.22,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.07,73.08a1.29,1.29,0,0,0-.39-.38c-.08,0-.18-.09-.22,0s0,.17,0,.27a.4.4,0,0,1-.06-.33c.1-.14.26-.09.37-.08a1.42,1.42,0,0,1,.63.33.2.2,0,0,1,0,.28.2.2,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.73,71.31a1.2,1.2,0,0,0-.4-.37c-.08,0-.18-.09-.22,0s0,.18,0,.27a.36.36,0,0,1-.07-.33.32.32,0,0,1,.36-.08,1.36,1.36,0,0,1,.64.31.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.16,68.51a1.1,1.1,0,0,0-.4-.37c-.08,0-.18-.08-.22,0s0,.17,0,.27a.43.43,0,0,1-.08-.33c.1-.14.26-.1.37-.09a1.34,1.34,0,0,1,.64.3.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.93,61.94a1.23,1.23,0,0,0-.41-.37c-.08,0-.18-.07-.22,0a.44.44,0,0,0,0,.28.36.36,0,0,1-.08-.33c.09-.14.26-.11.36-.1a1.48,1.48,0,0,1,.65.3.19.19,0,0,1,0,.28A.2.2,0,0,1,16,62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.22,57a1.16,1.16,0,0,0-.39-.37c-.08,0-.18-.08-.22,0s0,.18,0,.28c0-.1-.11-.19-.06-.33s.26-.1.36-.09a1.58,1.58,0,0,1,.64.31.2.2,0,1,1-.26.31S13.24,57,13.22,57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,54.28a1.32,1.32,0,0,0-.39-.39c-.08,0-.18-.08-.22,0s0,.18,0,.28a.42.42,0,0,1-.06-.34c.1-.13.26-.09.36-.07a1.4,1.4,0,0,1,.63.32.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.5,51.24a1.12,1.12,0,0,0-.39-.39c-.08-.05-.17-.09-.22-.05s0,.17,0,.27a.4.4,0,0,1-.06-.33c.1-.14.27-.09.37-.08a1.53,1.53,0,0,1,.63.33.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0A.12.12,0,0,1,14.5,51.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.61,49a1.16,1.16,0,0,0-.39-.37c-.08,0-.18-.08-.22,0s0,.18,0,.28a.38.38,0,0,1-.07-.33c.09-.14.26-.1.36-.09a1.46,1.46,0,0,1,.64.31.2.2,0,1,1-.26.31S18.63,49,18.61,49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.6,44.81a1.11,1.11,0,0,0-.38-.4c-.07,0-.17-.09-.21-.06s0,.17,0,.27c0-.1-.11-.19-.05-.33a.31.31,0,0,1,.37-.06,1.44,1.44,0,0,1,.62.35.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.47,43a1.16,1.16,0,0,0-.35-.42c-.08-.06-.17-.11-.21-.08s-.06.18-.05.28a.44.44,0,0,1,0-.34c.12-.13.27-.07.38,0a1.36,1.36,0,0,1,.59.38.21.21,0,0,1,0,.29.22.22,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,26.48a1.19,1.19,0,0,0,.05-.54c0-.09-.05-.19-.1-.2s-.16.08-.23.16c.05-.09.08-.21.22-.26s.24.14.29.23a1.43,1.43,0,0,1,.16.69.2.2,0,0,1-.2.2.19.19,0,0,1-.2-.2A.22.22,0,0,1,17,26.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.45,22.81a1.19,1.19,0,0,0,.05-.54c0-.09-.05-.19-.1-.2s-.16.08-.23.16c0-.09.08-.21.22-.26s.24.14.29.23a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.4,0A.19.19,0,0,1,17.45,22.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.63,22.2a1.07,1.07,0,0,0,0-.54c0-.09-.05-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.53,1.53,0,0,1,.16.69.2.2,0,0,1-.2.2.2.2,0,0,1-.21-.2A.24.24,0,0,1,18.63,22.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.75,23.33a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.16.09-.22.16c.05-.09.07-.21.21-.26s.25.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,1,1-.4,0S18.75,23.36,18.75,23.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.63,23.59a1.07,1.07,0,0,0,0-.54c0-.09-.05-.19-.1-.2s-.16.09-.23.16c.05-.09.07-.21.22-.26s.24.14.29.23a1.41,1.41,0,0,1,.17.69.2.2,0,0,1-.2.2.21.21,0,0,1-.21-.2A.24.24,0,0,1,19.63,23.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.87,23.84a1.23,1.23,0,0,0,.05-.55c0-.09-.05-.19-.1-.19s-.16.08-.23.16c.05-.1.07-.21.22-.26s.24.14.29.23a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.2.2.21.21,0,0,1-.2-.21A.17.17,0,0,1,17.87,23.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.85,24.79a1.07,1.07,0,0,0,0-.54c0-.09-.05-.19-.1-.2s-.16.08-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.4,0A.19.19,0,0,1,18.85,24.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.1,29a1.07,1.07,0,0,0,0-.54c0-.09-.05-.19-.1-.2s-.16.09-.22.17a.39.39,0,0,1,.21-.27c.17,0,.24.14.3.23a1.49,1.49,0,0,1,.17.68.21.21,0,0,1-.2.21.2.2,0,0,1-.2-.2A.22.22,0,0,1,17.1,29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.46,32.51a1.15,1.15,0,0,0-.07-.54c0-.09-.08-.18-.13-.17s-.14.11-.19.2c0-.1,0-.22.16-.3s.26.09.33.17a1.39,1.39,0,0,1,.29.64.21.21,0,0,1-.16.24.2.2,0,0,1-.23-.16A.11.11,0,0,1,16.46,32.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,35.07a1.15,1.15,0,0,0-.13-.53c0-.08-.1-.17-.16-.16s-.12.13-.16.22c0-.1,0-.22.13-.31s.27.06.35.12a1.56,1.56,0,0,1,.37.61.2.2,0,0,1-.39.12A.17.17,0,0,1,16,35.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.64,37.52a1.09,1.09,0,0,0-.2-.51c0-.08-.12-.15-.17-.13s-.11.14-.13.24c0-.11,0-.22.08-.33s.28,0,.36.08a1.37,1.37,0,0,1,.45.55.2.2,0,1,1-.37.17S15.65,37.54,15.64,37.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.54,39.31a1.2,1.2,0,0,0-.25-.49c-.06-.07-.14-.14-.19-.12S15,38.86,15,39c0-.11,0-.22,0-.34s.28,0,.37.05a1.41,1.41,0,0,1,.5.5.2.2,0,1,1-.35.21A.19.19,0,0,1,15.54,39.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.39,38.17a1.12,1.12,0,0,0-.24-.49c-.06-.07-.14-.13-.19-.11s-.09.15-.11.25a.43.43,0,0,1,.06-.34c.14-.09.27,0,.37.05a1.6,1.6,0,0,1,.49.51.21.21,0,0,1-.08.28.21.21,0,0,1-.27-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.32,33.88a1.22,1.22,0,0,0-.17-.52c0-.08-.12-.16-.17-.14s-.12.13-.15.23a.41.41,0,0,1,.1-.32.31.31,0,0,1,.36.1,1.33,1.33,0,0,1,.41.57.2.2,0,1,1-.37.15A.17.17,0,0,1,20.32,33.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.82,31.52A1,1,0,0,0,21.7,31c0-.08-.1-.17-.15-.16s-.13.13-.17.22c0-.1,0-.22.13-.31s.27.06.35.13a1.48,1.48,0,0,1,.36.61.2.2,0,0,1-.14.25.19.19,0,0,1-.25-.13A.19.19,0,0,1,21.82,31.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.07,29.11a1.27,1.27,0,0,0-.05-.54c0-.09-.08-.18-.14-.18s-.14.11-.19.2c0-.1,0-.22.17-.29s.26.09.33.17a1.47,1.47,0,0,1,.27.65.2.2,0,1,1-.39.07A.11.11,0,0,1,23.07,29.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.18,25.45a1.12,1.12,0,0,0,0-.55c0-.09-.05-.19-.1-.2s-.16.09-.22.17c0-.09.07-.21.21-.26s.24.13.3.22a1.53,1.53,0,0,1,.17.69.2.2,0,0,1-.4,0A.17.17,0,0,1,24.18,25.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.91,22.85a1.25,1.25,0,0,0,0-.55c0-.09-.05-.19-.1-.2s-.16.09-.22.17a.39.39,0,0,1,.21-.27c.17,0,.24.14.3.23a1.53,1.53,0,0,1,.17.69.2.2,0,0,1-.2.2.21.21,0,0,1-.21-.2A.19.19,0,0,1,24.91,22.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.27,20.55a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.4,0S25.27,20.57,25.27,20.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.79,17.12a1.19,1.19,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.16.08-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.43,1.43,0,0,1,.15.69.2.2,0,0,1-.4,0A.19.19,0,0,1,26.79,17.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,12.84a1.23,1.23,0,0,0,.06-.55c0-.09,0-.19-.09-.2s-.16.08-.23.15c.05-.09.08-.2.22-.25s.24.15.29.24a1.46,1.46,0,0,1,.14.7.21.21,0,0,1-.41,0S30,12.86,30,12.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.37,10.08a1.33,1.33,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.17.07-.23.14c0-.09.08-.2.23-.24s.23.15.28.25a1.57,1.57,0,0,1,.11.69.2.2,0,0,1-.22.19.2.2,0,0,1-.18-.22A.19.19,0,0,1,33.37,10.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.53,9.64a1.13,1.13,0,0,0,.11-.54c0-.09,0-.19-.08-.2s-.17.06-.24.13.09-.2.24-.23.22.16.27.26a1.35,1.35,0,0,1,.08.7.2.2,0,0,1-.22.18.22.22,0,0,1-.18-.23S37.52,9.66,37.53,9.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.79,10.72a1.13,1.13,0,0,0,.13-.54c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.08.11-.2.25-.22s.22.17.26.27a1.36,1.36,0,0,1,0,.7.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.72,15.42a1.32,1.32,0,0,0,.22-.51c0-.09,0-.19,0-.22s-.18,0-.26.08.13-.17.28-.17.19.21.21.31a1.47,1.47,0,0,1-.08.71.2.2,0,0,1-.38-.14S55.7,15.43,55.72,15.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.28,19.38a1,1,0,0,0,.31-.45c0-.09.06-.19,0-.22s-.17,0-.27,0c.09-.06.17-.15.32-.12s.14.24.14.34a1.46,1.46,0,0,1-.21.68.19.19,0,0,1-.27.07.2.2,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.05,23.36a1.16,1.16,0,0,0,.33-.44c0-.08.07-.18,0-.22s-.18,0-.27,0a.34.34,0,0,1,.32-.1c.15.08.12.25.12.35a1.62,1.62,0,0,1-.25.67.21.21,0,0,1-.28.05.2.2,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.06,28.89a1.16,1.16,0,0,0,.35-.43c0-.08.07-.18,0-.21s-.18,0-.28,0c.1-.05.18-.13.33-.09s.12.25.11.36a1.32,1.32,0,0,1-.27.65.2.2,0,1,1-.32-.23A.12.12,0,0,1,67.06,28.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,34.2a1.18,1.18,0,0,0,.34-.44c0-.08.06-.18,0-.22s-.18,0-.27,0a.34.34,0,0,1,.32-.1c.15.08.12.25.12.35a1.45,1.45,0,0,1-.25.66.2.2,0,0,1-.28.06.21.21,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.48,37.06a1.27,1.27,0,0,0,.33-.44c0-.09,0-.19,0-.22s-.18,0-.27,0a.38.38,0,0,1,.32-.11c.15.08.13.24.13.35a1.49,1.49,0,0,1-.23.67.2.2,0,0,1-.34-.22A.12.12,0,0,1,69.48,37.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.84,39.82a1.2,1.2,0,0,0,.31-.46c0-.08,0-.18,0-.22s-.18,0-.28,0c.09-.05.17-.14.32-.12s.14.25.14.35a1.35,1.35,0,0,1-.21.68.2.2,0,0,1-.27.07.2.2,0,0,1-.07-.28A.12.12,0,0,1,69.84,39.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.22,43.17a1.13,1.13,0,0,0,.29-.46c0-.09,0-.19,0-.23a.42.42,0,0,0-.28,0c.09-.06.17-.15.31-.13s.15.24.16.34a1.48,1.48,0,0,1-.18.69.2.2,0,0,1-.36-.19S69.2,43.18,69.22,43.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.05,47.57a1.2,1.2,0,0,0,.28-.48c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.06.16-.16.31-.14s.15.23.17.33a1.45,1.45,0,0,1-.16.69.2.2,0,0,1-.27.1.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.2,52.52a1.22,1.22,0,0,0,.32-.44c0-.09.06-.19,0-.23s-.18,0-.28,0c.09-.06.17-.15.32-.11s.13.24.13.35a1.47,1.47,0,0,1-.22.67.21.21,0,0,1-.35-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.14,54.84a1.14,1.14,0,0,0,.37-.41c0-.08.07-.18,0-.22s-.17,0-.27,0a.36.36,0,0,1,.33-.08c.14.09.11.26.1.36a1.45,1.45,0,0,1-.29.65.2.2,0,1,1-.32-.25S66.12,54.86,66.14,54.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.4,57.75a1.29,1.29,0,0,0,.41-.37c0-.08.09-.17.06-.21s-.17-.05-.27,0a.4.4,0,0,1,.33,0c.13.11.08.27.06.37a1.54,1.54,0,0,1-.36.61.21.21,0,0,1-.29,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,58.57a1,1,0,0,0,.42-.35c.06-.07.11-.16.08-.21a.41.41,0,0,0-.28,0c.1,0,.2-.1.34,0s.07.27,0,.37a1.45,1.45,0,0,1-.38.6.22.22,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,59.25a1.28,1.28,0,0,0,.44-.34c0-.07.1-.16.07-.21s-.17-.06-.27-.05a.4.4,0,0,1,.34,0,.34.34,0,0,1,0,.37,1.51,1.51,0,0,1-.4.59.2.2,0,0,1-.28,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.05,56.49a1.08,1.08,0,0,0,.39-.38c0-.07.09-.17.06-.21s-.17,0-.27,0a.37.37,0,0,1,.33,0c.14.1.09.27.07.37a1.59,1.59,0,0,1-.34.62.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28A.15.15,0,0,1,70.05,56.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.05,54a1.36,1.36,0,0,0,.36-.42c0-.08.07-.18,0-.22s-.17,0-.27,0c.09,0,.18-.13.32-.08s.12.25.11.35a1.37,1.37,0,0,1-.28.66.2.2,0,0,1-.32-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.9,49.6a1.08,1.08,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.06.15-.16.3-.14s.16.23.17.33a1.44,1.44,0,0,1-.15.69.2.2,0,0,1-.27.1.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.37,45.44a1,1,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.37.37,0,0,1,.31-.14c.16.06.15.23.16.34a1.43,1.43,0,0,1-.17.68.2.2,0,0,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.78,42.11a1.25,1.25,0,0,0,.29-.46c0-.09.05-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.15.24.16.35a1.49,1.49,0,0,1-.19.68.2.2,0,0,1-.35-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.82,39a1.11,1.11,0,0,0,.3-.45c0-.09.06-.19,0-.23s-.18,0-.27,0c.09,0,.17-.14.31-.12s.15.24.15.35a1.47,1.47,0,0,1-.2.68.21.21,0,0,1-.28.07.2.2,0,0,1-.07-.27A.15.15,0,0,1,66.82,39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.31,35.17a1.14,1.14,0,0,0,.32-.44c0-.09.05-.19,0-.23s-.18,0-.28,0a.42.42,0,0,1,.32-.12c.16.08.14.25.14.35a1.46,1.46,0,0,1-.21.68.22.22,0,0,1-.28.07.2.2,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.55,31.72a1.18,1.18,0,0,0,.32-.45c0-.08,0-.18,0-.22s-.17,0-.27,0c.09-.06.17-.15.32-.12s.13.25.13.35a1.37,1.37,0,0,1-.22.68.21.21,0,0,1-.28.06.22.22,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.34,29.44a1.07,1.07,0,0,0,.33-.44c0-.09.06-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.1c.16.08.13.25.13.35a1.49,1.49,0,0,1-.23.67.2.2,0,1,1-.34-.22A.12.12,0,0,1,64.34,29.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.81,31.25a1.07,1.07,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.17,0-.27,0a.41.41,0,0,1,.32-.1c.15.08.13.25.13.35a1.55,1.55,0,0,1-.24.67.21.21,0,0,1-.28.06.2.2,0,0,1-.06-.28A.12.12,0,0,1,65.81,31.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.27,35.93a1.14,1.14,0,0,0,.32-.44c0-.09,0-.19,0-.22s-.18,0-.27,0a.38.38,0,0,1,.32-.11c.15.07.13.24.13.35a1.47,1.47,0,0,1-.22.67.21.21,0,0,1-.28.06.2.2,0,0,1-.07-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.61,42.11a1.27,1.27,0,0,0,.3-.46c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.05.16-.15.31-.12s.15.24.15.34a1.32,1.32,0,0,1-.19.68.19.19,0,0,1-.27.08.2.2,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.87,47.38a1.18,1.18,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.16-.16.31-.14s.16.23.17.33a1.45,1.45,0,0,1-.16.69.2.2,0,0,1-.27.1.2.2,0,0,1-.09-.27A.15.15,0,0,1,69.87,47.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.68,50.62a1.13,1.13,0,0,0,.29-.46c0-.09,0-.19,0-.23s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.15.24.16.34a1.48,1.48,0,0,1-.18.69.21.21,0,0,1-.28.08.2.2,0,0,1-.08-.27A.15.15,0,0,1,70.68,50.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,52.73a1.33,1.33,0,0,0,.33-.43c0-.09.06-.19,0-.22s-.18,0-.27,0c.09-.05.17-.14.32-.1s.12.25.12.35a1.47,1.47,0,0,1-.24.67.2.2,0,0,1-.28.05.21.21,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.58,55.19a1.22,1.22,0,0,0,.38-.4c0-.08.08-.18,0-.22s-.17,0-.27,0a.37.37,0,0,1,.33-.07c.14.09.1.26.09.36a1.58,1.58,0,0,1-.31.64.2.2,0,1,1-.31-.26A.1.1,0,0,1,68.58,55.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.05,53.55a1.16,1.16,0,0,0,.35-.43c0-.08.07-.18,0-.22s-.18,0-.27,0c.09,0,.17-.13.32-.09s.12.25.11.35a1.43,1.43,0,0,1-.26.66.21.21,0,0,1-.28.05.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.54,49.59a1.2,1.2,0,0,0,.28-.48c0-.08,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.16.3-.14s.16.23.17.33a1.44,1.44,0,0,1-.15.69.2.2,0,0,1-.27.1.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.74,41.82a1.15,1.15,0,0,0,.3-.46c0-.09,0-.19,0-.22s-.18,0-.28,0c.09-.06.17-.15.31-.13s.15.24.16.35a1.63,1.63,0,0,1-.19.68.2.2,0,0,1-.27.08.19.19,0,0,1-.08-.27A.15.15,0,0,1,67.74,41.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.05,37a1.15,1.15,0,0,0,.3-.46c0-.08.05-.19,0-.22s-.18,0-.27,0a.36.36,0,0,1,.31-.12c.16.07.14.24.15.35a1.44,1.44,0,0,1-.2.67.21.21,0,0,1-.28.08.21.21,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.11,30.68a1.17,1.17,0,0,0,.31-.46c0-.08,0-.19,0-.22s-.18,0-.27,0a.36.36,0,0,1,.31-.12c.16.07.14.24.15.34a1.44,1.44,0,0,1-.2.68.21.21,0,0,1-.28.08.2.2,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,25.71a1,1,0,0,0,.29-.46c0-.08.05-.19,0-.22s-.17,0-.27,0c.09-.06.16-.15.31-.13s.15.24.16.34a1.41,1.41,0,0,1-.18.68.21.21,0,0,1-.28.09.21.21,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.54,24.34a1,1,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.07.15-.16.3-.14s.16.23.17.33a1.58,1.58,0,0,1-.16.69.2.2,0,0,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.38,25.54a1.09,1.09,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.16-.16.31-.14s.15.23.16.33a1.44,1.44,0,0,1-.15.69.21.21,0,0,1-.27.1.2.2,0,0,1-.09-.27A.43.43,0,0,1,58.38,25.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.91,28.23a1.17,1.17,0,0,0,.29-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.06.15.23.16.34a1.44,1.44,0,0,1-.18.68.2.2,0,0,1-.27.09.21.21,0,0,1-.09-.28A.12.12,0,0,1,59.91,28.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.42,34.69a1.16,1.16,0,0,0,.31-.45c0-.09.05-.19,0-.22s-.18,0-.27,0c.09-.06.17-.15.31-.12s.15.24.15.34a1.44,1.44,0,0,1-.2.68.2.2,0,0,1-.27.08.21.21,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,40a1.09,1.09,0,0,0,.3-.46c0-.08,0-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.14.24.15.34a1.38,1.38,0,0,1-.19.69.21.21,0,0,1-.28.07.19.19,0,0,1-.07-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.4,44.71a1.17,1.17,0,0,0,.29-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.41.41,0,0,1,.3-.14.33.33,0,0,1,.16.34,1.45,1.45,0,0,1-.16.69.21.21,0,0,1-.28.09.2.2,0,0,1-.08-.27A.15.15,0,0,1,68.4,44.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.65,48.18a1.14,1.14,0,0,0,.27-.47c0-.09,0-.19,0-.23a.39.39,0,0,0-.27.05.41.41,0,0,1,.3-.14c.16.06.16.23.17.33a1.48,1.48,0,0,1-.15.7.2.2,0,0,1-.36-.18A.21.21,0,0,1,68.65,48.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.73,51a1.09,1.09,0,0,0,.3-.46c0-.08,0-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.14.24.15.34a1.63,1.63,0,0,1-.19.69.21.21,0,0,1-.28.07.2.2,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.7,50.72a1.1,1.1,0,0,0,.29-.46c0-.08.05-.18,0-.22s-.18,0-.28,0c.09-.06.17-.15.31-.13s.15.24.16.34A1.38,1.38,0,0,1,69,51a.21.21,0,0,1-.27.08.2.2,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.46,45.93a1.14,1.14,0,0,0,.27-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.43.43,0,0,1,.31-.14c.16.07.15.24.16.34a1.34,1.34,0,0,1-.16.69.2.2,0,0,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.55,37.33a1.07,1.07,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.18,0-.27,0a.37.37,0,0,1,.32-.1c.15.08.13.24.12.35a1.38,1.38,0,0,1-.23.67.21.21,0,0,1-.28.06.21.21,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.91,27.86a1.14,1.14,0,0,0,.37-.41c0-.08.07-.18,0-.22a.38.38,0,0,0-.28,0,.37.37,0,0,1,.33-.08c.14.09.11.26.1.36a1.48,1.48,0,0,1-.3.65.2.2,0,0,1-.28,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.62,21.71A1.21,1.21,0,0,0,66,21.3c0-.08.07-.18,0-.22s-.17,0-.27,0a.39.39,0,0,1,.32-.09.34.34,0,0,1,.11.36,1.47,1.47,0,0,1-.28.66.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.86,15.09a1.27,1.27,0,0,0,.3-.46c0-.09,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.15.24.15.34a1.32,1.32,0,0,1-.19.68.19.19,0,0,1-.27.08.2.2,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.34,11.51a1.12,1.12,0,0,0,.21-.51c0-.09,0-.19,0-.21s-.18,0-.26.08c.07-.07.13-.18.28-.18s.19.21.21.31a1.42,1.42,0,0,1-.06.7.2.2,0,0,1-.26.13.2.2,0,0,1-.13-.25A.43.43,0,0,1,56.34,11.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.73,10.09a1.06,1.06,0,0,0,.16-.52c0-.09,0-.19,0-.22s-.17.05-.25.11.12-.19.27-.2.2.19.23.29a1.42,1.42,0,0,1,0,.71.21.21,0,0,1-.25.14.19.19,0,0,1-.14-.24A.11.11,0,0,1,53.73,10.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.8,9.64A1.06,1.06,0,0,0,52,9.12c0-.09,0-.2-.06-.22s-.17.06-.25.12.11-.19.26-.22.21.19.25.29a1.37,1.37,0,0,1,0,.7.21.21,0,0,1-.24.16.19.19,0,0,1-.15-.24A.19.19,0,0,1,51.8,9.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.53,9.23a1.26,1.26,0,0,0,.14-.53c0-.1,0-.2-.07-.22s-.17.06-.24.12.1-.19.25-.22.21.19.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.39-.07A.09.09,0,0,1,50.53,9.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.08,8.13a1.26,1.26,0,0,0,.14-.53c0-.09,0-.2-.07-.21s-.17.05-.24.11.1-.19.25-.21.22.18.25.28a1.48,1.48,0,0,1,0,.7.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23A.15.15,0,0,1,50.08,8.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.17,8a1.26,1.26,0,0,0,.14-.53c0-.09,0-.2-.06-.21s-.18.05-.25.11.11-.19.25-.21.22.18.25.28a1.36,1.36,0,0,1,0,.7.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.38,7.18a1.22,1.22,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.18.06-.25.12.11-.19.25-.22.22.18.26.28a1.53,1.53,0,0,1,0,.71.2.2,0,1,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.6,7.31a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17,0-.24.12c.06-.08.1-.2.25-.22s.22.18.25.28a1.52,1.52,0,0,1,0,.71.2.2,0,1,1-.4-.08A.21.21,0,0,1,47.6,7.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.21,7.47a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17,0-.25.12.11-.2.26-.22.21.18.25.28a1.49,1.49,0,0,1,0,.7.21.21,0,0,1-.24.17.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,7.61a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17,0-.25.12c.06-.08.11-.2.26-.22s.21.18.25.27a1.53,1.53,0,0,1,0,.71.22.22,0,0,1-.24.17A.21.21,0,0,1,45,7.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.64,10.57a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.05-.25.12c.06-.09.11-.2.26-.22s.21.18.25.28a1.37,1.37,0,0,1,0,.7.2.2,0,1,1-.39-.07A.11.11,0,0,1,47.64,10.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.69,14.53a1,1,0,0,0,.19-.51c0-.09,0-.2,0-.22s-.18,0-.26.09.13-.18.28-.19.19.2.22.3a1.44,1.44,0,0,1,0,.71.2.2,0,1,1-.38-.11A.15.15,0,0,1,53.69,14.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.9,16.25a1.3,1.3,0,0,0,.2-.51c0-.09,0-.19,0-.22s-.18,0-.26.09c.07-.07.13-.18.28-.18s.19.2.21.3a1.44,1.44,0,0,1,0,.71.22.22,0,0,1-.26.13.21.21,0,0,1-.13-.25A.16.16,0,0,1,53.9,16.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.56,14.84a1.32,1.32,0,0,0,.22-.51c0-.09,0-.19,0-.22s-.17,0-.26.08c.07-.07.13-.17.29-.17s.18.2.2.31a1.42,1.42,0,0,1-.06.7.21.21,0,0,1-.26.13.22.22,0,0,1-.13-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.8,15.8a1.17,1.17,0,0,0,.21-.51c0-.09,0-.19,0-.22s-.17,0-.26.09A.37.37,0,0,1,55,15c.16,0,.18.2.21.31a1.32,1.32,0,0,1-.07.7.19.19,0,0,1-.25.13.21.21,0,0,1-.13-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.41,16.73a1.27,1.27,0,0,0,.22-.5c0-.09,0-.2,0-.22s-.18,0-.26.07.13-.17.28-.17.19.21.21.31a1.46,1.46,0,0,1-.08.71.2.2,0,1,1-.38-.14S55.39,16.74,55.41,16.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.79,13.63a1.16,1.16,0,0,0,.2-.51c0-.09,0-.19,0-.22s-.17,0-.26.09c.08-.08.14-.18.29-.19s.19.21.21.31a1.46,1.46,0,0,1,0,.71.21.21,0,0,1-.25.13.22.22,0,0,1-.14-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.82,13.17a1.18,1.18,0,0,0,.17-.52c0-.09,0-.19-.05-.21s-.17,0-.25.1.12-.19.27-.2.2.19.23.29a1.55,1.55,0,0,1,0,.71.21.21,0,0,1-.25.15.2.2,0,0,1-.14-.25A.11.11,0,0,1,52.82,13.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.43,12.76a1.1,1.1,0,0,0,.16-.52c0-.1,0-.2-.06-.22s-.17.05-.25.11.12-.19.27-.21.2.19.24.29a1.54,1.54,0,0,1,0,.71.2.2,0,0,1-.24.15.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.5,13.43a1.1,1.1,0,0,0,.15-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.12-.19.27-.21.2.19.24.29a1.37,1.37,0,0,1,0,.7.21.21,0,0,1-.24.16.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.55,12.31a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17,0-.25.11.11-.19.26-.21.21.18.25.28a1.49,1.49,0,0,1,0,.7.2.2,0,0,1-.24.16.2.2,0,0,1-.16-.23A.15.15,0,0,1,49.55,12.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.89,12.72a1.12,1.12,0,0,0,.13-.53C48,12.1,48,12,48,12s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.48,1.48,0,0,1,0,.7A.21.21,0,0,1,48,13a.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,12.15a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.25-.22.22.18.26.28a1.48,1.48,0,0,1,0,.7.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.89,11.8a1.13,1.13,0,0,0,.13-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13c.06-.09.1-.2.25-.22s.22.17.25.27a1.4,1.4,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.18,13.12a1.26,1.26,0,0,0,.13-.53c0-.09,0-.2-.07-.21s-.17,0-.25.12c.06-.08.11-.2.25-.22s.22.17.26.27a1.4,1.4,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23A.21.21,0,0,1,46.18,13.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.63,14.82a1,1,0,0,0,.14-.53c0-.1,0-.2-.06-.22s-.17.06-.25.12.11-.19.25-.22.22.18.26.28A1.41,1.41,0,0,1,49,15a.2.2,0,1,1-.39-.07A.14.14,0,0,1,48.63,14.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.51,17.82a1.3,1.3,0,0,0,.2-.51c0-.09,0-.19,0-.22s-.18,0-.26.09c.07-.07.13-.18.28-.18s.19.2.22.3a1.59,1.59,0,0,1,0,.71.22.22,0,0,1-.26.13.21.21,0,0,1-.13-.25A.16.16,0,0,1,53.51,17.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.34,20.82a1.11,1.11,0,0,0,.29-.47c0-.08,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.15.07.15.23.16.34a1.51,1.51,0,0,1-.18.69.21.21,0,0,1-.27.08.19.19,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.08,23.68a1.2,1.2,0,0,0,.33-.43c0-.09.06-.19,0-.22s-.18,0-.27,0c.08-.05.17-.14.31-.1s.13.24.13.35a1.49,1.49,0,0,1-.23.67.2.2,0,1,1-.34-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.75,25.72a1.2,1.2,0,0,0,.37-.4c0-.08.08-.18,0-.22s-.17,0-.27,0a.39.39,0,0,1,.33-.07c.14.09.1.26.09.36A1.46,1.46,0,0,1,69,26a.2.2,0,1,1-.31-.25A.15.15,0,0,1,68.75,25.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.28,27.57a1.09,1.09,0,0,0,.38-.39c0-.08.08-.18,0-.22s-.18,0-.28,0c.1,0,.19-.11.33-.06s.1.26.09.36a1.49,1.49,0,0,1-.32.64.2.2,0,0,1-.31-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.64,29.23a1.13,1.13,0,0,0,.38-.4c0-.08.08-.18,0-.22s-.18,0-.28,0c.1,0,.19-.12.33-.07s.1.26.09.37a1.4,1.4,0,0,1-.32.63.19.19,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,29.62a1,1,0,0,0,.38-.39c.05-.08.08-.17.05-.22s-.18,0-.27,0a.39.39,0,0,1,.33-.07c.14.1.09.26.08.37a1.4,1.4,0,0,1-.32.63A.2.2,0,0,1,74,30a.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.77,28.31a1.28,1.28,0,0,0,.39-.38c0-.08.09-.17,0-.22s-.17,0-.27,0a.4.4,0,0,1,.33-.06.33.33,0,0,1,.07.37,1.42,1.42,0,0,1-.33.63.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.29A.15.15,0,0,1,74.77,28.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.27,26.44a1.29,1.29,0,0,0,.41-.37c0-.08.09-.17.06-.22s-.18,0-.27,0a.37.37,0,0,1,.33-.05c.13.11.08.27.06.37a1.44,1.44,0,0,1-.35.62.21.21,0,0,1-.3-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.19,24.69a1.1,1.1,0,0,0,.42-.36c0-.07.1-.16.07-.21a.38.38,0,0,0-.27,0,.37.37,0,0,1,.33,0c.13.11.07.27.05.37a1.53,1.53,0,0,1-.37.6.21.21,0,0,1-.29-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.6,22.18a1,1,0,0,0,.43-.34c.06-.07.11-.16.08-.2s-.17-.06-.27-.06a.4.4,0,0,1,.34,0c.12.12.06.28,0,.38a1.56,1.56,0,0,1-.4.58.19.19,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.28,19.62a1.11,1.11,0,0,0,.44-.32c.06-.07.11-.16.09-.2s-.17-.07-.27-.07c.1,0,.21-.08.34,0s.05.27,0,.37a1.41,1.41,0,0,1-.42.57.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77,16.55a1.22,1.22,0,0,0,.45-.31c.06-.07.12-.16.09-.21s-.17-.06-.27-.07a.44.44,0,0,1,.34,0,.33.33,0,0,1,0,.38,1.51,1.51,0,0,1-.43.56.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.07,15.42a1.34,1.34,0,0,0,.46-.31c.06-.07.11-.16.09-.21a.41.41,0,0,0-.27-.07.41.41,0,0,1,.34,0c.12.12,0,.27,0,.37a1.65,1.65,0,0,1-.44.56.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.39,15.32a1.14,1.14,0,0,0,.45-.32c.06-.07.11-.16.08-.21s-.16-.06-.26-.06a.38.38,0,0,1,.33,0c.12.12.05.28,0,.38a1.48,1.48,0,0,1-.42.56.2.2,0,1,1-.26-.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.54,14.21a1.17,1.17,0,0,0,.42-.36c.05-.07.1-.16.07-.21a.38.38,0,0,0-.27,0c.1,0,.2-.1.33,0s.07.27,0,.37a1.69,1.69,0,0,1-.38.6.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.2,12.29a1.21,1.21,0,0,0,.36-.41c0-.08.08-.18,0-.22s-.18,0-.27,0c.09-.05.18-.13.32-.08s.11.25.1.36a1.42,1.42,0,0,1-.29.64.19.19,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.41,9a1.22,1.22,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.08c.08-.07.14-.17.29-.17s.18.21.2.32a1.33,1.33,0,0,1-.08.7.21.21,0,0,1-.26.12.2.2,0,0,1-.12-.26A.16.16,0,0,1,58.41,9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.66,8a1.13,1.13,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17,0-.25.11.11-.19.26-.21.21.18.25.28a1.37,1.37,0,0,1,0,.7.21.21,0,0,1-.24.16A.2.2,0,0,1,48.63,8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.87,9.31A1,1,0,0,0,40,8.77c0-.09,0-.19-.07-.21s-.17.07-.24.13.1-.19.24-.23.23.17.27.27a1.47,1.47,0,0,1,.07.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,39.87,9.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.63,11a1.09,1.09,0,0,0,.07-.55c0-.09,0-.19-.08-.2s-.17.07-.24.15c.06-.09.09-.21.23-.25s.24.15.28.25a1.35,1.35,0,0,1,.12.7.19.19,0,0,1-.21.18.19.19,0,0,1-.19-.21A.14.14,0,0,1,32.63,11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.45,12.25a1.19,1.19,0,0,0,.05-.54c0-.09,0-.19-.1-.2s-.16.08-.22.15c.05-.09.07-.2.22-.25s.24.14.29.23a1.47,1.47,0,0,1,.15.7.21.21,0,0,1-.41,0A.19.19,0,0,1,27.45,12.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.18,12.93a1.11,1.11,0,0,0,0-.55c0-.09,0-.18-.1-.19s-.16.08-.22.16c.05-.09.07-.21.21-.26s.24.14.3.22a1.43,1.43,0,0,1,.16.69.2.2,0,1,1-.4,0S23.18,13,23.18,12.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.54,13.94a1.25,1.25,0,0,0,0-.55c0-.09-.05-.19-.1-.2s-.16.09-.22.17a.39.39,0,0,1,.21-.27c.17,0,.24.14.3.23a1.53,1.53,0,0,1,.17.69.2.2,0,0,1-.2.2.22.22,0,0,1-.21-.2A.19.19,0,0,1,19.54,13.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.16,15.32a1.42,1.42,0,0,0,0-.55c0-.09-.05-.19-.11-.2s-.15.09-.22.17c.05-.09.07-.21.21-.26s.25.13.3.22a1.56,1.56,0,0,1,.17.69.21.21,0,0,1-.21.2.2.2,0,0,1-.2-.2A.19.19,0,0,1,16.16,15.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.11,17.06a1.07,1.07,0,0,0,.05-.54c0-.09,0-.19-.1-.2s-.16.08-.22.16c.05-.1.07-.21.22-.26s.24.14.29.23a1.43,1.43,0,0,1,.15.69.2.2,0,0,1-.21.2.19.19,0,0,1-.19-.2A.19.19,0,0,1,13.11,17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.92,18.29a1.21,1.21,0,0,0,.06-.55c0-.09-.05-.19-.1-.2s-.16.08-.22.16c0-.09.07-.21.22-.26s.23.15.29.24a1.55,1.55,0,0,1,.14.69.21.21,0,0,1-.21.2.19.19,0,0,1-.19-.21A.17.17,0,0,1,11.92,18.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.93,18a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.1-.2s-.16.08-.22.15c.05-.09.08-.2.22-.25s.24.15.29.24a1.52,1.52,0,0,1,.14.69.22.22,0,0,1-.21.2.2.2,0,0,1-.19-.21A.22.22,0,0,1,10.93,18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.06,19.1a1.21,1.21,0,0,0,.06-.55c0-.09,0-.19-.09-.2s-.17.08-.23.16c0-.09.08-.21.22-.26s.24.15.29.24a1.59,1.59,0,0,1,.14.7.2.2,0,0,1-.21.19.21.21,0,0,1-.2-.21A.19.19,0,0,1,11.06,19.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.38,21.9a1.18,1.18,0,0,0,.07-.54c0-.09,0-.19-.09-.2s-.17.07-.23.15c0-.09.08-.21.22-.25s.24.15.29.24a1.46,1.46,0,0,1,.12.7.2.2,0,0,1-.21.19.21.21,0,0,1-.19-.22A.19.19,0,0,1,10.38,21.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.24,26.45a1.18,1.18,0,0,0,.07-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c0-.08.08-.2.23-.24s.23.15.28.24a1.46,1.46,0,0,1,.12.7.2.2,0,1,1-.4,0A.19.19,0,0,1,10.24,26.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.51,29.72a1.06,1.06,0,0,0,.08-.54c0-.09,0-.19-.09-.21s-.16.08-.23.15c.05-.09.08-.2.23-.25s.23.16.28.25a1.46,1.46,0,0,1,.12.7.21.21,0,1,1-.41,0S10.5,29.74,10.51,29.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.74,34.38a1.12,1.12,0,0,0,0-.55c0-.09,0-.19-.1-.19s-.16.09-.22.17c0-.1.06-.22.2-.27s.25.13.31.22a1.38,1.38,0,0,1,.18.68.2.2,0,0,1-.19.21.21.21,0,0,1-.21-.2A.17.17,0,0,1,10.74,34.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.1,37.9a1,1,0,0,0-.11-.53c0-.09-.1-.17-.16-.16s-.12.12-.16.22c0-.11,0-.23.13-.31s.27.06.35.13a1.36,1.36,0,0,1,.35.61.2.2,0,0,1-.39.12S11.1,37.93,11.1,37.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.36,41.09a1.17,1.17,0,0,0-.22-.5c-.06-.08-.13-.15-.18-.13s-.1.15-.12.25a.37.37,0,0,1,.06-.33c.15-.1.28,0,.37.06a1.43,1.43,0,0,1,.47.53.21.21,0,0,1-.09.28.2.2,0,0,1-.27-.09A.19.19,0,0,1,11.36,41.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.32,40.11a1.18,1.18,0,0,0-.19-.51c-.05-.08-.12-.15-.18-.14s-.1.15-.13.24a.39.39,0,0,1,.08-.32c.15-.09.28,0,.37.08a1.36,1.36,0,0,1,.43.56.2.2,0,0,1-.09.27.21.21,0,0,1-.27-.1A.24.24,0,0,1,11.32,40.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.34,35.37a1.14,1.14,0,0,0-.06-.54c0-.09-.08-.18-.14-.18s-.14.11-.19.2c0-.1,0-.22.16-.3s.27.1.34.18a1.38,1.38,0,0,1,.28.64.2.2,0,0,1-.4.08A.19.19,0,0,1,12.34,35.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.51,28.66a1.21,1.21,0,0,0,.07-.55c0-.09,0-.19-.1-.2s-.16.08-.23.15c.05-.09.08-.2.23-.25s.23.15.28.24a1.46,1.46,0,0,1,.14.7.2.2,0,0,1-.22.19.2.2,0,0,1-.19-.21A.19.19,0,0,1,12.51,28.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.46,24.13a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.1-.2s-.16.08-.22.15c.05-.09.07-.2.22-.25s.24.15.29.24a1.42,1.42,0,0,1,.13.69.2.2,0,0,1-.21.2.2.2,0,0,1-.19-.21A.24.24,0,0,1,12.46,24.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.13,20.48a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.09-.2s-.16.08-.23.15c.05-.09.08-.2.22-.25s.24.15.29.24a1.52,1.52,0,0,1,.14.69.22.22,0,0,1-.21.2.2.2,0,0,1-.19-.21A.22.22,0,0,1,12.13,20.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.5,16a1.07,1.07,0,0,0,.05-.54c0-.09,0-.19-.09-.2s-.16.08-.23.16c0-.1.08-.21.22-.26s.24.14.29.23a1.47,1.47,0,0,1,.15.7.19.19,0,0,1-.21.19.19.19,0,0,1-.19-.2A.19.19,0,0,1,11.5,16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.83,14.78a1.19,1.19,0,0,0,.05-.54c0-.09-.05-.19-.1-.2s-.16.08-.23.16c0-.09.08-.21.22-.26s.24.14.29.23a1.41,1.41,0,0,1,.16.69.21.21,0,0,1-.21.2.2.2,0,0,1-.2-.2A.19.19,0,0,1,11.83,14.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.43,12.28a1.07,1.07,0,0,0,0-.54c0-.09-.05-.19-.11-.2s-.15.09-.22.17c0-.1.07-.21.21-.27s.25.14.3.23a1.31,1.31,0,0,1,.17.69.2.2,0,0,1-.4,0A.25.25,0,0,1,12.43,12.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,11.18a1.24,1.24,0,0,0,0-.55c0-.09-.05-.18-.1-.19s-.16.09-.22.16c0-.09.06-.21.2-.26s.25.13.31.22a1.53,1.53,0,0,1,.17.69.2.2,0,0,1-.2.2.19.19,0,0,1-.2-.2A.17.17,0,0,1,13,11.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15,9.63a1.26,1.26,0,0,0,0-.55c0-.09-.05-.19-.11-.19s-.15.09-.21.17c0-.1.06-.22.2-.27s.25.13.3.22a1.38,1.38,0,0,1,.19.68.22.22,0,0,1-.2.21.2.2,0,0,1-.2-.2A.09.09,0,0,1,15,9.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.8,7.62a1,1,0,0,0,0-.55c0-.09-.05-.19-.11-.19s-.15.09-.21.17c0-.1.06-.22.2-.27S19,6.91,19,7a1.54,1.54,0,0,1,.19.69.21.21,0,1,1-.41,0S18.8,7.64,18.8,7.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.7,6.59a1.11,1.11,0,0,0,0-.55c0-.08-.05-.18-.11-.19s-.15.09-.21.17c0-.09.06-.21.2-.27s.25.14.3.22a1.42,1.42,0,0,1,.18.69.19.19,0,0,1-.19.2.19.19,0,0,1-.21-.19S21.7,6.61,21.7,6.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.81,4.58A1.22,1.22,0,0,0,26.86,4c0-.09-.05-.18-.1-.19s-.16.08-.23.16c0-.1.08-.21.22-.26S27,3.88,27,4a1.41,1.41,0,0,1,.16.69.22.22,0,0,1-.21.2.2.2,0,0,1-.2-.21A.19.19,0,0,1,26.81,4.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.42,3.47a1.09,1.09,0,0,0,.07-.55c0-.09,0-.19-.08-.2s-.17.07-.24.15c.06-.09.09-.21.23-.25s.24.16.29.25a1.46,1.46,0,0,1,.11.7.19.19,0,0,1-.21.18.19.19,0,0,1-.19-.21A.14.14,0,0,1,31.42,3.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.07,2.89a1.33,1.33,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.16.07-.24.13.1-.2.25-.23.22.17.26.27a1.35,1.35,0,0,1,.08.7.2.2,0,0,1-.23.17A.19.19,0,0,1,37.05,3,.14.14,0,0,1,37.07,2.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.53,3.44a1.1,1.1,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.21.18.25.28a1.27,1.27,0,0,1,0,.7.2.2,0,0,1-.23.17.21.21,0,0,1-.16-.24A.19.19,0,0,1,42.53,3.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.75,4.38a1,1,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17,0-.25.12c.06-.09.11-.2.26-.22s.21.18.25.28a1.37,1.37,0,0,1,0,.7.19.19,0,0,1-.23.16.2.2,0,0,1-.16-.23A.19.19,0,0,1,49.75,4.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.69,6.82a1.08,1.08,0,0,0,.24-.49c0-.09,0-.2,0-.22s-.18,0-.26.07A.39.39,0,0,1,59.93,6c.16,0,.17.22.19.32A1.45,1.45,0,0,1,60,7a.2.2,0,0,1-.26.12.21.21,0,0,1-.12-.26A.43.43,0,0,1,59.69,6.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.42,9a1.11,1.11,0,0,0,.34-.42c0-.09.07-.19,0-.22s-.18,0-.28,0c.09-.05.18-.14.33-.1s.12.25.11.36a1.35,1.35,0,0,1-.25.66.2.2,0,0,1-.28.05.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.44,11a1.21,1.21,0,0,0,.39-.39c0-.08.09-.17,0-.22s-.17,0-.27,0c.1,0,.19-.11.33-.06s.09.27.08.37a1.53,1.53,0,0,1-.33.63.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.63,13.57a1.1,1.1,0,0,0,.42-.36c0-.07.09-.17.06-.21s-.17-.05-.27,0c.1,0,.2-.1.34,0s.07.27,0,.37a1.5,1.5,0,0,1-.37.61.21.21,0,0,1-.29-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.86,16.2a1.11,1.11,0,0,0,.42-.34c.06-.07.11-.16.08-.21s-.17-.06-.27-.05a.4.4,0,0,1,.34,0c.12.11.06.27,0,.37a1.49,1.49,0,0,1-.39.59.21.21,0,1,1-.28-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.8,18.56a1.11,1.11,0,0,0,.44-.32c.06-.07.11-.16.08-.21s-.16-.06-.26-.06a.38.38,0,0,1,.33,0,.32.32,0,0,1,0,.37,1.35,1.35,0,0,1-.41.58.22.22,0,0,1-.29,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.87,19.65a1.14,1.14,0,0,0,.44-.33c.06-.07.11-.16.08-.21s-.17-.06-.27-.06a.44.44,0,0,1,.34,0,.34.34,0,0,1,0,.37,1.54,1.54,0,0,1-.41.58.2.2,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.61,18.49a1.24,1.24,0,0,0,.43-.34c.06-.07.11-.16.08-.21s-.17-.06-.27-.05a.4.4,0,0,1,.34,0,.34.34,0,0,1,0,.37,1.44,1.44,0,0,1-.41.59.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.79,17.7a1,1,0,0,0,.43-.35c0-.07.1-.16.07-.2s-.17-.06-.27,0c.1,0,.2-.1.34,0s.06.27,0,.37A1.59,1.59,0,0,1,73,18a.21.21,0,0,1-.28-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.06,16a1.08,1.08,0,0,0,.4-.37c.05-.07.1-.17.06-.21s-.17,0-.27,0a.39.39,0,0,1,.34-.05c.13.11.08.27.06.37a1.47,1.47,0,0,1-.36.61.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28A.15.15,0,0,1,70.06,16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.5,13.91a1.23,1.23,0,0,0,.37-.41c0-.08.07-.18,0-.22a.43.43,0,0,0-.28,0c.1,0,.19-.12.33-.07s.11.26.09.36a1.45,1.45,0,0,1-.29.65.22.22,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.05,11.6a1.15,1.15,0,0,0,.3-.45c0-.09,0-.19,0-.23a.42.42,0,0,0-.28,0c.09-.06.17-.15.32-.13s.14.24.14.35a1.35,1.35,0,0,1-.19.68.21.21,0,0,1-.28.08.21.21,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.85,10.07A1.15,1.15,0,0,0,55,9.55c0-.09,0-.19-.05-.21s-.17,0-.25.1.12-.19.27-.2.2.2.23.3a1.44,1.44,0,0,1,0,.71.2.2,0,0,1-.24.14.21.21,0,0,1-.15-.25A.25.25,0,0,1,54.85,10.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.71,9.17a1.12,1.12,0,0,0,.13-.53c0-.09,0-.2-.07-.21s-.17.05-.24.12c.06-.08.1-.2.25-.22s.21.17.25.27a1.4,1.4,0,0,1,0,.71.2.2,0,0,1-.23.16.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.57,10.41a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.16.06-.24.13c.06-.08.1-.2.25-.23s.22.17.26.26a1.47,1.47,0,0,1,.09.7.22.22,0,0,1-.23.18.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,12.16a1.21,1.21,0,0,0,.08-.55c0-.09,0-.19-.09-.2s-.17.08-.23.15c0-.09.08-.21.22-.25s.24.16.29.25a1.59,1.59,0,0,1,.12.7.21.21,0,0,1-.41,0A.19.19,0,0,1,33,12.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.75,13.42a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.1-.2s-.16.08-.23.15c.05-.09.08-.21.22-.25s.24.14.29.23a1.47,1.47,0,0,1,.15.7.21.21,0,0,1-.41,0A.19.19,0,0,1,28.75,13.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,14.64a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.15.09-.22.16c0-.09.07-.21.22-.26s.24.14.29.23a1.43,1.43,0,0,1,.16.69.2.2,0,0,1-.2.2.19.19,0,0,1-.2-.2A.22.22,0,0,1,25,14.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.93,16a1.1,1.1,0,0,0,0-.55c0-.08,0-.18-.1-.19s-.16.08-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.4,0S22.93,16,22.93,16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.36,19.15a1.2,1.2,0,0,0,0-.54c0-.09-.05-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.38,1.38,0,0,1,.16.69.19.19,0,0,1-.2.2.2.2,0,0,1-.2-.2A.19.19,0,0,1,20.36,19.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.23,21.31a1.07,1.07,0,0,0,0-.54c0-.09-.05-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.4,0S20.23,21.34,20.23,21.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,27a1.25,1.25,0,0,0,0-.55c0-.09-.05-.19-.1-.2s-.16.09-.22.17c.05-.09.07-.21.21-.26s.25.13.3.22a1.53,1.53,0,0,1,.17.69.2.2,0,0,1-.2.2A.21.21,0,0,1,20,27,.19.19,0,0,1,20,27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.32,30.52a1.33,1.33,0,0,0-.05-.55c0-.08-.08-.17-.14-.17s-.14.11-.19.19c0-.1,0-.22.17-.29s.26.1.33.17a1.44,1.44,0,0,1,.27.66.2.2,0,0,1-.16.23.2.2,0,0,1-.23-.17A.09.09,0,0,1,19.32,30.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.55,36.3a1.22,1.22,0,0,0-.22-.5c0-.07-.13-.14-.18-.13s-.1.15-.12.25c0-.1,0-.22.07-.33s.28,0,.37.06a1.63,1.63,0,0,1,.46.54.2.2,0,0,1-.09.27.2.2,0,0,1-.27-.08A.19.19,0,0,1,18.55,36.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.87,42.71a1.14,1.14,0,0,0-.33-.44c-.07-.06-.16-.11-.21-.08s-.06.17-.06.27a.44.44,0,0,1,0-.34c.12-.12.27,0,.37,0a1.35,1.35,0,0,1,.58.41.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0S16.88,42.73,16.87,42.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,48.62a1.28,1.28,0,0,0-.38-.39c-.08,0-.18-.08-.22,0s0,.17,0,.27a.4.4,0,0,1-.06-.33c.1-.14.27-.09.37-.07a1.39,1.39,0,0,1,.62.33.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0A.15.15,0,0,1,16,48.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.55,55.1a1.11,1.11,0,0,0-.4-.37c-.08,0-.18-.07-.22,0s0,.18,0,.28a.4.4,0,0,1-.07-.33c.09-.14.25-.11.36-.09a1.42,1.42,0,0,1,.64.29.2.2,0,1,1-.25.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.49,57.79a1.16,1.16,0,0,0-.42-.36c-.08,0-.18-.07-.21,0s0,.17,0,.27c0-.09-.12-.18-.08-.32s.26-.12.36-.11a1.43,1.43,0,0,1,.65.28.2.2,0,1,1-.24.32A.12.12,0,0,1,15.49,57.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.72,59.54a1.14,1.14,0,0,0-.41-.37c-.08,0-.18-.07-.22,0s0,.17,0,.27a.39.39,0,0,1-.07-.33c.09-.14.25-.11.36-.1a1.42,1.42,0,0,1,.64.29.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.12.12,0,0,1,15.72,59.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.37,60.86A1.16,1.16,0,0,0,16,60.5c-.08,0-.18-.07-.21,0s0,.17,0,.27c0-.09-.12-.18-.08-.32s.26-.12.36-.11a1.55,1.55,0,0,1,.65.28.2.2,0,0,1,0,.28.2.2,0,0,1-.28.05Z"/>
      </g>}
      {r_type_hair >= 2 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,78.38,23,77.73l1,.51h0a.08.08,0,0,1,0,.11.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.29,79.37l-.92-.64,1,.49h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.51,77.1l-.92-.63,1,.48h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.73,77.37l-.9-.66,1,.52h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.23,79l-.89-.67,1,.53h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.19,77.4l-.92-.62,1,.48h0a.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.05,78.09l-.93-.62,1,.48h0a.08.08,0,1,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.3,79.11l-.89-.67,1,.53h0a.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.13,77.37l-.92-.65,1,.5h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.77,79.64l-.9-.65,1,.51h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.05,78.13l-.9-.66,1,.52h0a.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.43,79.59l-.91-.65,1,.51h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.75,78.75l-.9-.67,1,.53h0a.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,78l-.91-.64,1,.49h0A.08.08,0,0,1,27,78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,76.19,19,75.55l1,.5h0a.07.07,0,0,1,0,.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.37,77.21l-.92-.63,1,.49h0a.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.06,79.15l-.9-.66,1,.52h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,78.1l-.92-.63,1,.48h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.94,78.1l-.89-.68L26,78h0s0,.08,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.77,76.32l-.87-.69,1,.55h0s.06.08,0,.11a.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.63,77.22l-.87-.71.95.57h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.7,76.32l-.86-.72.94.58h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.43,76.31l-.87-.71,1,.57h0a.08.08,0,0,1,0,.11s-.08,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.81,76.11,22,75.4l.94.58h0a.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.43,76.36l-.86-.72.94.58h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.64,76.48l-.84-.74.93.6h0a.08.08,0,0,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.05,75.07l-.83-.75.92.62h0a.11.11,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.8,75.52,21,74.76l.92.63h0a.1.1,0,0,1,0,.12.11.11,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.9,75.1l-.79-.78L21,75h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,74.73,23.17,74l.9.64h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,74.51l-.77-.8.87.68h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.8,73.72,23,72.93l.88.67h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,73.87l-.76-.82.86.7h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.38,73.84,24.66,73l.83.74h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.65,72.78l-.73-.84.84.72h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.78,73.7l-.71-.85.82.74h0a.08.08,0,1,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,72.19l-.72-.85.83.73h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,72.82l-.66-.9.78.79h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.81,72.59l-.66-.9.77.79h0a.08.08,0,0,1-.12.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.08,71.14l-.66-.9.77.79h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,71.38l-.63-.92.75.81h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.4,71,20.8,70l.72.85h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.19,70.23l-.59-1,.71.85h0a.09.09,0,0,1-.13.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.75,70.14l-.53-1,.66.89h0a.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.3,69.56l-.53-1,.66.89h0a.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.85,68.82l-.52-1,.65.9h0a.08.08,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.52,68.37l-.51-1,.65.9h0a.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.31,67.53l-.45-1,.59.94h0a.09.09,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.92,67.07l-.46-1,.59.94h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.3,66.57l-.44-1,.58.95h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,66.2l-.4-1,.54,1h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.88,65.66l-.4-1,.54,1h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.19,65.42l-.4-1,.54,1h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,64.8l-.38-1,.52,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.69,64.43l-.4-1,.54,1h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.76,64.18l-.4-1.05.54,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.88,63.36l-.38-1,.52,1h0a.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,62.88l-.39-1,.53,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.19,62.34l-.4-1.05.54,1h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.14,61.85l-.41-1,.55,1h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.38,61.42l-.4-1,.54,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.24,60.77l-.41-1,.55,1h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.31,59.18l-.42-1,.56,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.32,59.76l-.43-1,.56,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.41,59.57l-.44-1,.58,1h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.77,58.84l-.45-1,.58.94h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.45,58.26l-.44-1,.58,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.22,58.18l-.45-1,.59.94h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.63,57.63l-.47-1,.6.93h0a.08.08,0,0,1,0,.11s-.08,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.73,57.1l-.46-1,.6.93h0a.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.49,56.6l-.48-1,.61.92h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.59,56.16l-.48-1,.61.92h0a.09.09,0,0,1,0,.11.11.11,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.46,55.71l-.49-1,.62.91h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,55l-.5-1,.63.9h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,54.67l-.5-1,.63.91h0a.09.09,0,0,1,0,.11s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.08,54l-.52-1,.65.9h0a.09.09,0,0,1,0,.12.11.11,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.41,53.64l-.52-1,.65.89h0a.09.09,0,0,1,0,.12.11.11,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.49,52.76l-.53-1,.66.88h0s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.35,52.57l-.53-1,.66.89h0a.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.65,51.89l-.54-1,.66.89h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.09,51.06l-.55-1,.68.87h0a.08.08,0,0,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.85,50.9l-.54-1,.67.88h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.78,50l-.56-1,.69.86h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.26,49.81l-.56-1,.68.87h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.07,48.82l-.57-1,.7.85h0a.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.75,48.77l-.56-1,.68.88h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.59,47.8,22,46.85l.7.85h0a.08.08,0,0,1,0,.11s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.14,47.54l-.57-1,.69.87h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.34,46.72l-.59-.95.71.85h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.67,45.89l-.6-.94.73.84h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.45,45.73l-.57-1,.7.86h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.88,45l-.6-1,.72.84h0A.09.09,0,0,1,24,45a.1.1,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.77,44.2l-.61-.94.73.83h0a.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.56,43.53,23,42.6l.73.83h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.65,43.66l-.59-.95.71.84h0a.09.09,0,0,1,0,.12.1.1,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.16,42.89l-.6-.94.72.84h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,42.27l-.62-.93.74.82h0a.08.08,0,0,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.91,41.37l-.64-.92.75.82h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.27,41.44l-.61-.93.73.83h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.67,40.46,24,39.54l.75.81h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.21,40.52l-.61-.93.73.83h0a.08.08,0,1,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.62,39.73,25,38.8l.74.82h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.71,38.75l-.63-.91.75.81h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,38.76l-.61-.93.73.82h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.75,37.7l-.63-.93.75.82h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,37.61l-.61-.94.73.83h0a.08.08,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.51,36.4l-.63-.92.75.82h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.35,35.85l-.62-.93.74.83h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.11,35.18l-.6-.94.73.83h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,34.13l-.61-.93.73.83h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,33.8l-.6-.94.72.84h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,32.69l-.59-.94.72.84h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.15,33.19l-.58-1,.7.86h0a.08.08,0,0,1-.13.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.16,32.93l-.55-1,.68.87h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.46,33.56l-.52-1,.65.9h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.17,32.42l-.52-1,.65.89h0s0,.09,0,.11a.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.22,32.12l-.49-1,.62.91h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.25,33.18l-.44-1,.58,1h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,33.83l-.41-1,.55,1h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.41,34.46l-.43-1,.57,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.38,33l-.48-1,.62.92h0a.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.22,34.31l-.48-1,.62.92h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.31,35.39l-.47-1,.6.93h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,34.92l-.53-1,.66.88h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.23,34.61l-.57-1,.69.86h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.87,35.51l-.57-1,.69.86h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.33,36.51l-.57-1,.7.86h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.39,36.89l-.59-1,.72.85h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.42,38.13l-.58-1,.7.86h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.19,39.24l-.57-1,.7.86h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,39.39l-.6-.94.72.84h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.21,40.44l-.58-1,.7.86h0a.1.1,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.49,41.43l-.56-1,.69.87h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.31,41.45l-.59-1,.71.84h0a.08.08,0,1,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.64,42.12l-.58-1,.71.86h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.52,43.25l-.55-1,.68.88h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.56,43.58l-.57-1,.69.86h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.28,44.75l-.54-1,.66.88h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.08,45.08l-.54-1,.67.88h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.3,46.06l-.56-1,.68.87h0a.08.08,0,0,1-.13.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.38,46.66l-.53-1,.66.89h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.17,47.41l-.53-1,.66.89h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.08,48.3l-.52-1,.65.89h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.07,49l-.52-1,.65.89h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.7,50l-.53-1,.65.9h0a.08.08,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.93,50.64l-.49-1,.62.91h0s0,.09,0,.11a.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,51.4l-.5-1,.63.91h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.28,52.36l-.5-1,.63.91h0a.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.13,53.08l-.48-1,.61.93h0a.1.1,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.14,53.92l-.45-1,.58.94h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,54.48l-.46-1,.6.93h0a.08.08,0,0,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.83,55.29l-.42-1,.56,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.08,55.56l-.39-1.05.53,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.3,56l-.43-1,.57,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.88,56.54l-.38-1,.53,1h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.8,57l-.41-1,.54,1h0a.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.1,57.73l-.43-1,.57.95h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.17,58l-.44-1,.58.94h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.08,56.44l-.41-1,.55,1h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,56.79l-.42-1,.56,1h0a.08.08,0,0,1,0,.11s-.08,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.37,57.52l-.44-1,.57.94h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.5,57.58l-.45-1,.58.94h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.62,56.75l-.46-1,.59.93h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.36,55.74l-.47-1,.6.93h0a.09.09,0,0,1,0,.11.1.1,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,54.64l-.48-1,.61.93h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.31,53.77l-.41-1,.55,1h0a.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.64,52.63l-.45-1,.59.94h0a.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.32,51.56l-.43-1,.57.95h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.41,50,28,49l.59.93h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.69,48.49l-.47-1,.61.92h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.1,47.13l-.47-1,.6.93h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,45.87l-.49-1,.62.92h0a.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.84,44.57l-.51-1,.64.91h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.9,43.85l-.5-1,.63.92h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.18,42.75l-.53-1,.66.89h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.29,42.59l-.5-1,.63.91h0a.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,41.85l-.5-1,.63.91h0a.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.22,41.19l-.53-1,.66.89h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.2,40.2l-.55-1,.67.88h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.07,39.66l-.53-1,.66.89h0s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,39.71l-.5-1,.63.91h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.87,38.11l-.55-1L31,38h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,38.2l-.52-1,.65.9h0a.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.54,37,31,36l.68.88h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,37.46l-.49-1,.63.92h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.08,36.5l-.5-1,.63.9h0a.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.3,35.6l-.53-1,.66.89h0s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.33,34l-.55-1,.67.88h0a.08.08,0,0,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.74,31.94l-.56-1,.69.87h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.52,30.9l-.56-1,.68.87h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.44,31l-.51-1,.64.91h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.83,30.17l-.53-1,.66.89h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.17,29.34l-.54-1,.67.89h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.3,28.86l-.5-1,.64.91h0a.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.35,29l-.47-1,.61.92h0a.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.37,29l-.44-1,.57.95h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.78,27.3l-.48-1,.62.92h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,27.61l-.44-1,.58.94h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.38,26.44l-.45-1,.59.94h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.17,25.36l-.45-1,.59.94h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,25.12l-.41-1,.55,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.33,26.48l-.42-1,.56,1h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.14,27.55l-.39-1,.54,1h0a.07.07,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.81,25.64l-.39-1.05.53,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.24,26.58l-.38-1,.53,1h0a.07.07,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.14,26.75l.07-1.12.09,1.1h0a.07.07,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.64,25.84l.07-1.12.09,1.11h0a.07.07,0,0,1-.07.08s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.42,27.5l.06-1.12.1,1.1h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.17,24.87l0-1.12.13,1.1h0a.08.08,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.93,24.9,47,23.78l.09,1.11h0A.07.07,0,0,1,47,25s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.61,25.75l0-1.12.14,1.1h0a.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.08,24.48l0-1.12.12,1.1h0a.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.4,26.84l0-1.12.13,1.1h0s0,.08-.07.09a.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.26,26.45l.05-1.11.11,1.1h0a.08.08,0,0,1-.07.09.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.78,25.56l0-1.12.11,1.11h0a.08.08,0,0,1-.07.08.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.45,27.83l0-1.12.13,1.1h0a.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,28.6l.06-1.11.1,1.1h0a.07.07,0,0,1-.07.08.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.83,25.82l.1-1.11.06,1.1h0s0,.08-.07.09a.1.1,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.36,27.83l.13-1.11,0,1.11h0a.07.07,0,0,1-.07.08.1.1,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.07,29.05l.13-1.11,0,1.11h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.24,29.08l.15-1.1v1.1h0a.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.71,30.16l.15-1.11v1.11h0s0,.08-.08.08a.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.09,31.08l.19-1.1,0,1.1h0a.08.08,0,0,1-.09.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.35,30.68l.24-1.09-.08,1.1h0a.08.08,0,0,1-.09.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.14,32.17l.28-1.08-.13,1.1h0a.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.41,31.69l.32-1.07-.16,1.09h0a.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.2,32.72l.35-1.06-.19,1.09h0a.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.53,33.58l.35-1.06-.2,1.09h0a.08.08,0,0,1-.09.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.24,33l.4-1-.24,1.08h0a.08.08,0,0,1-.1.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.82,34.06l.42-1L52,34.1h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.1,34.87l.41-1-.26,1.08h0a.08.08,0,0,1-.1.06.09.09,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.05,35.72l.46-1-.31,1.07h0a.08.08,0,0,1-.1,0,.09.09,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.05,37.09l.49-1-.34,1.06h0a.09.09,0,0,1-.11.05.09.09,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.8,38l.51-1-.36,1h0a.08.08,0,0,1-.1,0A.09.09,0,0,1,51.8,38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,38.19l.54-1-.39,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.63,39.39l.55-1-.4,1h0a.09.09,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.85,39.56l.57-1-.42,1h0a.07.07,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.32,40.78l.57-1-.43,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.63,40.94l.6-.95-.46,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.29,41.71l.61-.93-.47,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.3,42.39l.6-.94-.46,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.63,43l.63-.93-.49,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.9,44.23l.64-.92-.49,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.85,45.26l.65-.9-.51,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.38,46.41l.63-.92-.49,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.38,46.56l.66-.91-.52,1h0a.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.3,47.71l.64-.92-.5,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.23,47.62l.65-.9-.51,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.5,46.72l.65-.91-.51,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.79,45.6l.64-.91-.5,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.68,44.66l.63-.93-.48,1h0a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.78,43.86l.6-.94-.46,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.51,42.43l.57-1-.43,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.27,40.69l.53-1-.38,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.39,39.23l.48-1-.33,1.06h0a.08.08,0,0,1-.1.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.74,37.56l.43-1L49.9,37.6h0a.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,36.28l.38-1.05-.22,1.08h0a.08.08,0,0,1-.1.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.58,35.13l.32-1.07-.16,1.1h0a.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.52,34l.28-1.08L48.68,34h0a.08.08,0,0,1-.09.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.92,32.87l.21-1.1-.05,1.1h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.41,31.77l.14-1.11v1.1h0a.09.09,0,0,1-.08.09.1.1,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.93,30.4l.12-1.11,0,1.11h0a.08.08,0,0,1-.07.08.08.08,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.5,30.14,45.58,29l.08,1.11h0a.08.08,0,0,1-.07.08.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.38,28.6l.09-1.12.07,1.11h0a.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.43,27.1l.1-1.11.06,1.1h0a.08.08,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.3,26.66l.12-1.11,0,1.11h0a.07.07,0,0,1-.07.08.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,27.91l.1-1.11.06,1.1h0a.08.08,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.35,29.59l.1-1.11.06,1.11h0a.07.07,0,0,1-.07.08.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.09,31.65l.1-1.11.06,1.1h0a.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.71,33.16l.15-1.1v1.1h0a.07.07,0,0,1-.07.08.08.08,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.08,34.84l.23-1.09-.07,1.11h0a.09.09,0,0,1-.09.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.68,36.62,48,35.55l-.17,1.09h0a.08.08,0,0,1-.09.07s-.07,0-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.41,38.42l.4-1-.24,1.08h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.24,40.38l.47-1-.31,1.06h0a.08.08,0,0,1-.1.06.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.94,41.89l.52-1-.37,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.43,43.08l.54-1-.39,1h0a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.63,44.28l.56-1-.41,1h0a.1.1,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,45.55l.57-1-.42,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.17,47l.59-.95-.44,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.1,48.37l.6-.94-.46,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.48,49.26l.63-.93-.49,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.91,50.57l.63-.92-.49,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.15,51.58l.63-.92-.49,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.05,52.35l.65-.91-.51,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.28,53.67l.65-.91-.51,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.1,54.5l.65-.91-.51,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.93,55.46l.67-.9-.53,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.21,56.36l.65-.91-.51,1h0s-.07.06-.1,0a.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.45,57.74l.65-.9-.51,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.58,59.17l.65-.91-.51,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,60.43l.65-.91-.5,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.07,62l.64-.92-.5,1h0a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.74,63.4l.62-.93-.48,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,64.93l.63-.93-.49,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.42,66l.62-.93-.48,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.81,67.39l.63-.92-.49,1h0a.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,68.66l.65-.9-.51,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.28,69.7l.66-.9-.52,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.12,70.69l.69-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.3,72.1l.7-.87-.57,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.5,72.65l.71-.86-.57.95h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.42,73.77l.73-.85-.59.93h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.57,73.1l.71-.86-.58,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.34,73.52l.72-.85-.59.94h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.34,72l.69-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.07,71.26l.68-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.38,69.93,56,69l-.51,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.87,68.12l.63-.92-.48,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.43,64.84l.6-.94-.45,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.9,61.7l.62-.93-.48,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.34,59.44l.63-.93-.49,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.82,57.16l.63-.93-.49,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.27,54.92,53.9,54l-.49,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.79,52.94l.62-.93-.48,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.13,49.84l.61-.93-.46,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.78,47.18l.62-.94-.47,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.28,45.64l.61-.93-.46,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.49,46.08l.6-.94-.46,1h0a.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.08,46.05l.62-.93-.48,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.42,47.55l.63-.92-.49,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.25,48.73l.66-.9-.52,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,50.22l.67-.89-.53,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.64,52.69l.68-.88-.54,1h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.41,55l.69-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.47,57.82l.68-.89-.54,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.73,59.93,63.4,59l-.53,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,61.9l.67-.9-.53,1h0A.09.09,0,0,1,64,62a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.51,63.71l.66-.9-.52,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.4,65.94l.66-.9-.52,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,67.2l.67-.89-.53,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.6,69.46l.7-.88-.56,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.25,71.32l.71-.85-.58.94h0a.08.08,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,72.33l.73-.85-.59.93h0a.08.08,0,0,1-.11,0s0-.08,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.56,73.8,64.3,73l-.61.93h0a.11.11,0,0,1-.12,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.13,74.67l.75-.82-.62.91h0a.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.17,74.93l.76-.82L63.3,75h0a.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.74,73.78l.74-.84-.61.93h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,72.75l.73-.84-.6.93h0a.08.08,0,1,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.92,70.52l.71-.86-.57.94h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.17,68l.68-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.73,64.56l.66-.9-.52,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.15,60.63l.67-.89-.53,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.15,57.29l.68-.88-.54,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.94,54.57l.69-.87-.55.95h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.69,51.85l.7-.87-.56,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.29,48.82,64,48l-.56,1h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.71,45.54l.69-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.12,42.5l.68-.89-.54,1h0s-.07.06-.1,0a.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.29,40l.67-.89-.53,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.73,37.77l.65-.91-.51,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.52,36.11l.63-.92-.49,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.29,34.94l.62-.93-.48,1h0a.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.39,34.77l.6-.94-.46,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.5,35.79l.62-.93-.48,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.47,37.29l.63-.92-.49,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.47,39l.64-.91-.5,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.33,41.94,61,41l-.53,1h0s-.07.06-.11,0a.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.29,45.55l.68-.88-.54,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.72,50l.7-.87-.56,1h0s-.08.06-.11,0a.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.09,53l.69-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.5,56l.69-.88-.55,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.2,58.93l.68-.89-.54,1h0s-.07.06-.11,0a.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.16,62l.67-.9-.53,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.17,63.64l.66-.9-.52,1h0a.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,65.78l.66-.9-.52,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.08,67.44l.68-.89-.54,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.74,68.87l.69-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.71,70.5l.7-.87-.57,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.31,72l.72-.85-.58.94h0A.08.08,0,1,1,62.3,72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,73.12l.74-.84-.6.93h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.64,73.65l.75-.83-.62.92h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,74.68l.75-.83-.62.92h0a.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.17,75.19l.76-.82-.63.92h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.58,73.83l.74-.84-.61.93h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.87,72.34l.72-.85-.59.94h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.86,67.38l.66-.9-.52,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.12,64.12l.65-.91-.51,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,61.68l.66-.9-.51,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.74,59.81l.67-.9-.53,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.9,58.48l.68-.89-.54,1h0a.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.33,55.64l.67-.89-.53,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.35,53.67l.68-.89-.54,1h0s-.07.06-.1,0a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.69,51l.68-.88-.54,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.32,48.64l.67-.88-.54,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.84,44.37l.66-.89-.52,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.08,40.82l.65-.91-.5,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,38.8l.62-.93-.47,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.44,70.68l.71-.86-.57,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,70.94l.7-.87-.56,1h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.65,71.87l.71-.86-.57.94h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.84,71.28l.71-.87-.58,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.09,70.78l.7-.87-.56,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.79,69.8l.69-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.88,69.61l.69-.88-.56,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.91,67.51l.67-.9-.53,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.18,64.31l.66-.9-.51,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.52,60.76l.66-.9-.52,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.5,57.63l.68-.89-.54,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.31,55.23l.69-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.17,52.56l.69-.88-.56,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.9,49.8l.69-.88-.55,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.85,46.8l.69-.88-.55,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.44,44l.68-.89-.54,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,41.24l.67-.89-.53,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.24,39.47l.66-.9-.52,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.67,36.65l.62-.93-.47,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.77,33.25l.56-1-.41,1h0a.08.08,0,0,1-.1,0,.1.1,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.21,30.52l.42-1-.27,1.07h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.46,27.11,53.72,26l-.1,1.1h0a.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.84,25.64l.21-1.1,0,1.1h0a.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.08,25l.19-1.1,0,1.11h0a.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.69,23.89l.14-1.11,0,1.11h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.48,22l.07-1.12.09,1.1h0a.07.07,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.72,21V19.88L45.88,21h0a.07.07,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.11,20.47,43,19.36l.23,1.08h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.22,19.94l-.1-1.12.26,1.08h0a.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.16,19.87,39,18.77l.31,1.06h0a.1.1,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.1,19.83l-.24-1.09.39,1h0a.08.08,0,0,1,0,.11.1.1,0,0,1-.11-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.07,19.77l-.33-1.07.48,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.55,20.82l-.45-1,.58.94h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.79,22.32l-.56-1,.68.88h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.68,24.38l-.62-.93.74.82h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.09,26l-.67-.89.78.78h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.36,27.69l-.7-.88.81.76h0a.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.07,29.45l-.7-.87.81.75h0a.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.93,28.64l-.71-.87.81.75h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.07,28.21l-.71-.86.82.74h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.42,26.49l-.69-.88.81.76h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.46,23.9,25.83,23l.75.81h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.66,19.1l-.45-1,.59.93h0a.08.08,0,0,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.76,13.49l-.12-1.11.28,1.07h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.18,12.82l-.1-1.12.26,1.08h0a.09.09,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.28,12.39l-.1-1.11.26,1.07h0a.08.08,0,0,1-.06.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.35,13.56l-.05-1.12.21,1.09h0a.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.15,15.55l.06-1.12.1,1.11h0a.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.32,18.87l.19-1.1,0,1.11h0a.09.09,0,0,1-.09.08.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.37,21.11,59.7,20l-.17,1.09h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.7,23.19l.44-1-.28,1.07h0a.1.1,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.83,23.32l.47-1L63,23.37h0a.08.08,0,0,1-.1.05.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.45,22.16l.44-1L62.6,22.2h0a.08.08,0,0,1-.1.06.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.52,21.15l.37-1.06-.22,1.09h0a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.1,19.86l.28-1.08-.12,1.1h0a.1.1,0,0,1-.09.07s-.08-.05-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.57,17.92l.14-1.11,0,1.1h0a.08.08,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.12,15l-.06-1.12L47.27,15h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,14.87l-.1-1.11.25,1.08h0a.1.1,0,0,1-.06.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.08,14.7,41,13.58l.26,1.08h0a.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.26,15.07,36.08,14,36.41,15h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.63,16.88l-.33-1.07.47,1h0a.08.08,0,1,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.21,20.18l-.54-1,.67.88h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.4,22.15l-.63-.93.75.82h0a.08.08,0,0,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.33,22.78l-.66-.9.78.78h0a.07.07,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.4,23.83l-.67-.89.78.78h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.46,23.29l-.69-.88.8.77h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.4,22.9l-.65-.9.76.79h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.8,19.24l-.52-1,.65.9h0a.08.08,0,0,1,0,.11.11.11,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.34,12.49l-.11-1.11.26,1.08h0a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.78,8.27l-.1-1.11.25,1.07h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.15,8.08,49.05,7,49.31,8h0a.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,11l0-1.11.12,1.1h0s0,.08-.07.09A.1.1,0,0,1,54,11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.07,12.64l.16-1.11v1.11h0a.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.5,15.53l.32-1.07-.16,1.1h0a.08.08,0,0,1-.09.06.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.6,18.69l.44-1-.28,1.07h0a.08.08,0,0,1-.1.06.09.09,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.79,22l.55-1-.4,1h0a.09.09,0,0,1-.11.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.43,22.78l.57-1-.42,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.26,20.32l.5-1-.35,1.05h0a.09.09,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.05,14.93l.25-1.09-.09,1.1h0a.09.09,0,0,1-.09.08.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.77,11.73l-.07-1.12.23,1.09h0a.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.45,10.58l-.1-1.11.26,1.08h0a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,10.46l-.11-1.11.27,1.08h0a.08.08,0,0,1-.06.09.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.5,12l-.41-1,.55,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.65,16.85,22,15.94l.76.8h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,22.66l-.74-.84.84.72h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.18,24.14l-.74-.83.85.71h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.24,25.21l-.74-.84.84.72h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.47,25.74l-.75-.83.85.71h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.24,24.7l-.74-.83.85.7h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.88,21l-.73-.85.84.73h0A.07.07,0,0,1,19,21a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.4,16l-.66-.9.78.79h0a.08.08,0,0,1-.12.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.39,12.71l-.52-1,.65.89h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.18,10,29.9,8.94l.43,1h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.54,10.72l-.1-1.11.26,1.07h0a.08.08,0,0,1-.06.1.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42,12l-.11-1.12.26,1.08h0a.08.08,0,0,1-.06.1A.08.08,0,0,1,42,12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.08,15.66l0-1.12.19,1.09h0a.08.08,0,0,1-.06.1s-.09,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.37,21l.19-1.1,0,1.11h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.3,23.55l.31-1.07-.15,1.1h0a.1.1,0,0,1-.1.07.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.1,25.86l.43-1-.27,1.07h0a.08.08,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,26.13l.46-1-.3,1.07h0a.09.09,0,0,1-.1,0,.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.41,25.09,58.8,24l-.23,1.08h0a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.26,22.84l.26-1.09-.1,1.11h0a.08.08,0,0,1-.08.07.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.4,17.08l0-1.11.18,1.09h0a.1.1,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,15.07,38.92,14,39.18,15h0a.07.07,0,0,1,0,.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.78,16.49l-.44-1,.57.95h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.5,18.85l-.59-1,.71.85h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.81,22.33l-.7-.87.81.75h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.77,27.47,17,26.64l.85.71h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.18,30.54l-.75-.83.86.71h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.5,31.55l-.75-.83.86.7h0a.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.06,32.31l-.74-.83.85.71h0a.1.1,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.92,32.4l-.74-.83.85.71h0a.09.09,0,0,1-.11.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.69,29.42l-.75-.82.86.7h0s0,.07,0,.11a.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.7,17.74,21,16.85l.79.78h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.18,14.4l-.58-1,.71.85h0a.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.59,13.79l-.45-1,.58.94h0a.1.1,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,13.94l-.1-1.11.26,1.07h0a.08.08,0,0,1-.06.1A.1.1,0,0,1,38,14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.2,13.72l-.08-1.12.23,1.08h0a.08.08,0,0,1-.07.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.06,14.38l.07-1.11.09,1.1h0a.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.51,17.25l.23-1.1-.07,1.11h0a.07.07,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.92,21.08l.41-1-.25,1.08h0a.09.09,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.62,26.74l.56-1-.41,1h0a.08.08,0,0,1-.1.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.35,30.56l.62-.93-.48,1h0a.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.62,34.45l.67-.9-.53,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.16,37.59l.68-.88-.54,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.94,38.68l.69-.88-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,38.87,67.7,38l-.55,1h0A.08.08,0,0,1,67,39a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.49,35.39l.67-.89-.53,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.61,32l.64-.92-.49,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.47,26.7l.54-1-.39,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.6,21.1,57.88,20l-.12,1.1h0a.07.07,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.54,16.85l0-1.12.21,1.09h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.72,17.18l-.41-1,.55,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,16.43l-.62-.93.74.82h0a.08.08,0,0,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.77,19.34l-.7-.87.81.75h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.08,21.86,18.35,21l.83.73h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.25,25.43l-.75-.83.85.7h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.19,29.17l-.76-.82.86.7h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.52,31.62l-.75-.82.86.7h0s0,.07,0,.11a.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.57,35.62l-.73-.84.83.72h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.58,37.68l-.71-.86.82.74h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,39.25l-.7-.87.81.75h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.57,41.48l-.68-.89.8.78h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.48,42.69l-.67-.9.78.78h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.65,42.47l-.67-.9.78.79h0a.08.08,0,1,1-.12.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.67,45.43,12,44.51l.75.82h0a.08.08,0,1,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.22,47.77l-.6-.94.72.83h0a.08.08,0,1,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.49,48.26l-.59-.95.72.84h0s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.44,47.06l-.61-.94.73.84h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.69,40.93,12,40l.79.77h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.46,37l-.72-.85.83.73h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.61,34l-.74-.84.84.72h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.87,30.78l-.74-.84.85.72h0a.08.08,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,29l-.73-.84.83.72h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,29.24l-.73-.85.83.73h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,30.67l-.73-.85.84.73h0a.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.12,38.3l-.7-.87.81.75h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.27,40.77l-.68-.88.79.77h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.85,44l-.66-.9.77.79h0A.08.08,0,0,1,17,44a.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.22,48.3l-.6-.94.73.83h0s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.45,50.32l-.57-1,.7.85h0s0,.09,0,.11a.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,49.58l-.58-.95.71.85h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.71,44.05l-.65-.92.76.81h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.42,38.43l-.67-.89.78.78h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.42,35.43l-.67-.89.79.78h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,34.67l-.66-.91.78.8h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.71,33.92,25,33l.78.79h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.73,36.43l-.67-.89.79.78h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.51,41.27l-.66-.89.78.78h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.39,45.54l-.63-.92.75.81h0a.08.08,0,0,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.71,47l-.61-.93.73.82h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.49,47.35l-.6-.94.73.84h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,42.93l-.65-.9.76.79h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.81,36.41l-.66-.9.78.79h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.41,31.41l-.63-.93.74.82h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.49,27.15l-.55-1,.68.88h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,25.89l-.5-1,.63.91h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,24.09,35.58,23l.51,1h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.85,23.79l-.28-1.09.42,1h0a.09.09,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.32,24l-.21-1.1.36,1h0a.09.09,0,0,1,0,.1.1.1,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.72,24.42l-.14-1.11.29,1.07h0a.1.1,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.57,23.69l0-1.12.21,1.09h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.82,23.1l0-1.11L44,23.08h0a.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.74,23.14,47.81,22l.09,1.1h0s0,.08-.07.09a.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.3,24.26l.21-1.1,0,1.1h0a.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.22,27.23l.46-1-.31,1.07h0a.08.08,0,0,1-.1.05.07.07,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.37,32.38l.63-.92-.49,1h0a.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.61,36.39l.68-.88-.55,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,39.7l.7-.87-.56,1h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.71,43.21l.7-.86-.57.94h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.36,45.15l.71-.86-.57,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.14,47.73l.71-.87-.57,1h0a.09.09,0,0,1-.11,0s-.06-.08,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.88,48.36l.7-.87-.56.95h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.67,46.31l.71-.87-.57,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.19,42.37l.71-.86-.57,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.91,37.47l.68-.89-.54,1h0a.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.22,34.57l.66-.9-.52,1h0a.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.59,33.4l.64-.92-.5,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.67,33.91l.65-.91-.51,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,35.43l.66-.9-.52,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.31,39l.69-.89-.55,1h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,42l.7-.87-.56,1h0a.08.08,0,0,1-.11,0A.08.08,0,0,1,66,42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.48,44.94l.7-.87-.56,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.94,47.7l.7-.87-.57,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.57,49l.7-.87-.57.95h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.7,48.85l.71-.87-.57,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.18,45.79l.71-.87-.57.95h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.1,37l.66-.9-.52,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.74,31.88l.56-1-.42,1h0a.09.09,0,0,1-.1.05.08.08,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,29.42l.47-1-.32,1.06h0a.09.09,0,0,1-.11.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.84,28.93l.43-1L57,29h0a.09.09,0,0,1-.1.06.1.1,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,30.59l.53-1-.38,1h0a.09.09,0,0,1-.1.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.6,38.41l.67-.89-.53,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.38,46.09l.7-.87-.57,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,50.74l.7-.87-.57,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.21,53l.7-.87-.56.95h0A.08.08,0,1,1,69.2,53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.66,51.72l.7-.87-.57.95h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.69,45.91l.71-.86-.57,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.15,33.12l.66-.9-.52,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.31,26.77l.59-.95-.44,1h0a.08.08,0,0,1-.1.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.91,19,62.28,18l-.21,1.08h0a.09.09,0,0,1-.1.07.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,17.36l.27-1.09-.12,1.1h0a.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.7,16.5,59,15.41l-.09,1.11h0a.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,17.85l.32-1.07-.16,1.09h0A.08.08,0,0,1,61,18a.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.78,25.84l.59-.94-.45,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.06,31.38l.65-.9-.51,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.39,36.23l.68-.89-.54,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.12,41.58l.69-.87-.55,1h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.67,42.46l.69-.88-.56,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.45,39.31l.69-.88-.55,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.85,32.49l.66-.9-.51,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.35,24.5l.62-.93-.47,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.75,18.94l.47-1L65.9,19h0a.08.08,0,0,1-.1.06.1.1,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.92,15.11,58.14,14l-.06,1.11h0a.08.08,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,13.76V12.65l.15,1.09h0a.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.08,13.78,44,12.67l.25,1.07h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,15.66l-.16-1.11.32,1.06h0a.08.08,0,0,1-.06.1s-.08,0-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.17,21.1l-.61-.94.73.83h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.52,26.23l-.72-.85.83.73h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.19,33.63l-.74-.84.84.72h0a.08.08,0,0,1-.1.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.42,43.82l-.66-.9.77.79h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.37,50.07l-.58-1,.7.86h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.75,51.29l-.55-1,.68.87h0a.08.08,0,0,1,0,.12.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,43.18l-.66-.9.78.79h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.54,29,26.9,28l.76.81h0a.08.08,0,0,1,0,.12.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,21.14l-.23-1.09.38,1h0a.07.07,0,0,1,0,.1.1.1,0,0,1-.11-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.23,16.88l-.08-1.12.23,1.08h0a.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.45,16.12l0-1.12.11,1.1h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.87,19.9l.24-1.09L57,19.91h0a.09.09,0,0,1-.09.08.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.58,26.93l.51-1L61.73,27h0a.08.08,0,0,1-.1.05.09.09,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.34,35.88,68,35l-.54,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.82,44.09l.7-.86-.57,1h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.28,52.5l.69-.88-.56,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.34,58l.67-.9-.53,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.36,61.71,72,60.8l-.5,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.76,61.09l.65-.91-.51,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.27,56.39,72,55.5l-.54,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.29,45.32l.7-.86-.57.94h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.28,34.05l.67-.9-.53,1h0a.08.08,0,0,1-.11,0s-.06-.07,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.69,26.91l.6-.94-.46,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.42,16.43l.38-1-.23,1.08h0a.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.56,11.3l.19-1.1,0,1.1h0a.07.07,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.19,8.08l0-1.12.18,1.1h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.07,7.1,48,6l.26,1.08h0a.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.6,7.08,41.5,6,41.76,7h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.7,8.24l-.1-1.11.26,1.08h0a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.79,11l-.4-1,.54,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.56,17.88,19.87,17l.8.76h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.33,21.21l-.74-.84.84.72h0a.09.09,0,1,1-.11.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.31,26.63l-.75-.83.86.71h0a.08.08,0,0,1,0,.12s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.67,27.77,12.91,27l.86.7h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.52,27.52l-.75-.83.85.7h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,19.11l-.68-.88.79.77h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.63,10.89,31.38,9.8l.4,1h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.55,10.14,40.45,9l.26,1.08h0a.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.66,11.2l-.09-1.11.24,1.07h0a.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.83,14.09,55,13l0,1.1h0a.08.08,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.29,19.82l.37-1.05-.21,1.08h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.54,24.6l.54-1-.39,1h0a.08.08,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.19,30.1l.64-.92-.49,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.66,36.58l.69-.88-.55,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.14,40.88l.7-.87-.56,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.78,42.18l.71-.87-.57,1h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.47,39.72l.69-.87-.55,1h0a.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,33.08l.62-.93-.48,1h0a.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.54,26.87l.37-1.05L56.7,26.9h0c0,.05,0,.07-.09.07a.09.09,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.41,22.73l.17-1.11v1.11h0a.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.91,20.26,49,19.14l.1,1.11h0a.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.35,19l-.05-1.11L45.51,19h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.83,18.71l-.07-1.12L44,18.68h0a.08.08,0,0,1-.06.09.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,17.59l-.08-1.11.24,1.08h0a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.2,16.62V15.51l.15,1.09h0a.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.53,17.11l.1-1.11.06,1.1h0a.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.25,16.78l.2-1.1,0,1.11h0a.09.09,0,0,1-.08.08.1.1,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.52,15.7l.16-1.11V15.7h0a.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46,14.69l-.08-1.11.24,1.08h0a.09.09,0,0,1-.06.1.08.08,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.55,18,32.14,17l.55,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.65,21.23l-.65-.9.77.79h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.45,26.43l-.72-.86.83.74h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.3,33l-.73-.85.83.73h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.05,74l-.84-.74.93.6h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.75,74.64l-.87-.71.95.57h0a.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.12,72.89l-.81-.78.9.65h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.55,70.87l-.7-.87.81.75h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.79,68.87l-.57-1,.69.86h0s0,.09,0,.11a.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.77,65.86l-.39-1,.53,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.13,64l-.35-1.06.49,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.09,62.44l-.34-1.06.49,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.15,60.89l-.33-1.07.48,1h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.63,59.7l-.34-1.06.48,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.29,57.94,30,56.87l.47,1h0a.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.52,55.8l-.32-1.07.47,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.49,53.76l-.33-1.07.47,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.75,51.93l-.34-1.06.48,1h0a.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.22,50.3l-.33-1.07.48,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.53,49.09,31.2,48l.48,1h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,47.73l-.33-1.07.47,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.59,46l-.33-1.07.48,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.17,44.47l-.35-1.06.49,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.72,42.74l-.36-1.05.5,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,41l-.39-1.05.53,1h0a.07.07,0,0,1,0,.11A.08.08,0,0,1,34,41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,38.38l-.4-1.05.54,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.82,36.61l-.38-1,.53,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.15,32.25l-.39-1,.53,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.44,30.43l-.39-1.05.53,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.36,27.13,37,26.07l.48,1h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.32,25.05,37,24l.47,1h0a.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.32,24.9l-.27-1.08.42,1h0a.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.16,25.77l-.23-1.09.38,1h0a.09.09,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.29,26,38,24.91l.43,1h0a.08.08,0,0,1,0,.11.1.1,0,0,1-.11-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,29,36.65,28l.5,1h0a.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.6,35.61l-.36-1.06.5,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.76,39.54l-.38-1,.52,1h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.06,40.64l-.35-1.07.49,1h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,39.19l-.33-1.06.48,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.44,35.17l-.31-1.08.46,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.69,32.48l-.32-1.08.46,1h0a.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.91,30.32l-.31-1.07.45,1h0a.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.31,28.52,38,27.44l.43,1h0a.08.08,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.27,29,39,27.87l.38,1h0s0,.08-.05.1a.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.86,27.91l-.19-1.1L40,27.87h0a.09.09,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.81,27.26l-.13-1.11L41,27.22h0a.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.19,28.51l-.11-1.11.26,1.07h0a.08.08,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.93,26.52l.17-1.1v1.1h0a.08.08,0,0,1-.09.08.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.14,27.51l.19-1.1,0,1.1h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.69,36.07l.43-1-.28,1.08h0a.08.08,0,0,1-.1.06.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.08,44.27l.58-1-.43,1h0a.07.07,0,0,1-.1,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,49.39l.64-.91-.5,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.57,51.67l.66-.9-.52,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.44,52.88l.67-.9-.53,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,51.55l.67-.89-.53,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,43.21l.64-.91-.5,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.13,36.3l.5-1-.35,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.3,29.65l.29-1.08-.13,1.1h0a.08.08,0,0,1-.09.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.51,22.42l.12-1.11,0,1.1h0a.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.49,19.38l.08-1.11.08,1.1h0s0,.08-.07.09a.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.59,17.49l.08-1.11.08,1.1h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.13,16.1,54.26,15l0,1.11h0a.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.55,15.23l.13-1.11,0,1.11h0a.07.07,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.76,18.6l.13-1.11,0,1.1h0a.08.08,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.23,23.07l.19-1.1,0,1.1h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.06,29.13l.34-1.07-.18,1.1h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,33.19l.49-1-.34,1.05h0a.09.09,0,0,1-.11,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.47,36.92l.59-1-.45,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.5,37.18l.57-1-.42,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.94,35.94l.59-.95-.44,1h0a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.19,35.26l.56-1-.41,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.12,34.9l.57-1-.42,1h0a.09.09,0,0,1-.1.05.1.1,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.81,36.11l.57-1-.42,1h0a.08.08,0,1,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.75,36.41l.55-1-.4,1h0a.08.08,0,0,1-.1.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.37,34.1l.54-1-.39,1h0a.07.07,0,0,1-.1.05.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.52,34.43l.53-1-.38,1h0a.08.08,0,0,1-.1,0,.09.09,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.62,34.63l.52-1-.37,1h0a.09.09,0,0,1-.1,0c-.05,0-.07-.06-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.89,35.45l.54-1-.39,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.05,32.6l.51-1-.36,1h0a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.13,25.57l.31-1.07-.16,1.09h0a.07.07,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.22,21.94l.41-1L61.38,22h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.24,20.71l.48-1-.33,1.05h0a.07.07,0,0,1-.1.05.07.07,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.64,21.11l.52-1-.37,1h0s-.06.07-.1,0a.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.23,22.25l.51-1-.36,1.05h0a.07.07,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.75,27.73l.55-1-.4,1h0a.09.09,0,0,1-.1.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.76,31.14l.57-1-.42,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.06,32l.58-.95-.44,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.81,29.93l.56-1L62,30h0a.09.09,0,0,1-.1.05.08.08,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,25.61l.56-1-.41,1h0a.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,24.5l.58-.95-.44,1h0a.09.09,0,0,1-.11.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.88,24.56l.57-1-.43,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.7,25.94l.52-1-.37,1h0a.07.07,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.72,28.09l.47-1-.31,1.07h0a.07.07,0,0,1-.09.05.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.22,28l.42-1-.26,1.07h0a.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.28,27.81l.39-1-.23,1.09h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.05,23.88l.38-1.06-.22,1.09h0a.09.09,0,0,1-.1.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,19.05,60.31,18l-.16,1.1h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,18.05l.2-1.1,0,1.11h0a.08.08,0,0,1-.08.08.1.1,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.1,17.88l0-1.11.12,1.1h0a.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.08,17.49l0-1.11.14,1.09h0a.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.45,10.45V9.33l.16,1.09h0a.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.07,10.66l0-1.12.19,1.09h0a.08.08,0,0,1-.06.1.1.1,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.81,16l-.1-1.11L43,15.92h0a.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.46,18.8l-.13-1.11.28,1.07h0a.08.08,0,0,1-.06.09.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.05,17.79l-.26-1.08.4,1h0a.09.09,0,0,1-.05.1.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.49,17.07l-.49-1,.62.92h0a.09.09,0,0,1,0,.11.11.11,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.44,17.39l-.55-1,.67.88h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.32,17.48l-.58-.95.7.85h0a.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,16.5l-.57-1,.7.86h0a.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,16.44l-.55-1,.68.87h0a.09.09,0,0,1,0,.12s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.71,14.65l-.49-1,.62.91h0a.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.09,15l-.45-1,.59.93h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,13.08,30.61,12l.48,1h0a.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.22,12.17l-.31-1.08.45,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.77,12.52l-.38-1,.52,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.29,19.68l-.66-.9.78.79h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.69,23.85,20,23l.83.74h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.53,27.79,18.8,27l.83.72h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.21,31.33l-.74-.85.84.73h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,33.8,18.32,33l.83.73h0a.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.37,35.58l-.72-.86.83.74h0a.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.17,34.61l-.73-.85.84.73h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.4,29.67l-.74-.84.84.72h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.23,24.81l-.69-.88.8.76h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.36,19.35l-.56-1,.68.87h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,15.85l-.37-1,.51,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.26,14.2,33,13.12l.43,1h0a.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.91,12l-.23-1.09.38,1h0A.07.07,0,0,1,33,12s-.08,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.46,12.61l-.22-1.09.37,1h0a.08.08,0,0,1,0,.1.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.25,13.09,32,12l.43,1h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.49,14.29l-.35-1.06.5,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.5,15l-.41-1,.55,1h0s0,.08,0,.11-.08,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.48,13.91l-.38-1.05.52,1h0a.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.58,13.51l-.4-1,.54,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.52,15,26,14l.66.88h0s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,17.14l-.61-.94.73.83h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.6,30.49l.6-.94-.45,1h0a.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.4,31.34l.6-.94-.46,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.86,30.57l.61-.93-.47,1h0a.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.5,32l.65-.92-.51,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.25,36.34l.68-.89-.54,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.21,40.24l.7-.87-.56,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.07,47.34l.7-.86-.57,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,49l.71-.87-.57.95h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,38.77l.69-.87-.55,1h0s-.07.06-.1,0a.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.36,28.53l.53-1-.38,1h0a.08.08,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.59,20.55l.15-1.11v1.12a.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,18.05l0-1.12L47.17,18h0a.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.63,16.89l-.1-1.11.25,1.07h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.32,18.77l-.22-1.1.37,1h0a.07.07,0,0,1,0,.1s-.08,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.54,24.67l-.59-1,.71.85h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.66,31.6,21,30.74l.82.74h0a.08.08,0,1,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.75,35.3,16,34.45l.83.73h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.79,36.24l-.72-.85.82.73h0a.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.08,26.92l-.73-.84.84.72h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.53,21l-.67-.9.79.79h0a.07.07,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.92,20.06l-.63-.92L25,20h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.88,29.35l-.72-.86.82.75h0a.08.08,0,1,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,32.58l-.72-.86.83.74h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.31,33.34l-.71-.87.82.75h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.34,34.16l-.72-.86.82.74h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.71,35.35,19,34.49l.82.74h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.68,35.12,20,34.26l.82.74h0a.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.36,27.83l-.65-.9.77.79h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.79,26.84l-.63-.92.75.82h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.81,26.88,26.16,26l.76.8h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.55,27.68l-.64-.91.76.81h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.68,29.08,25,28.19l.78.78h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.93,32.7l-.69-.88.8.76h0a.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.2,37.4l-.69-.88.8.77h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.72,38.52,20,37.63l.8.77h0a.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.58,29.91,25.92,29l.77.8h0a.08.08,0,0,1-.12.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.59,22.9l-.54-1,.67.89h0a.09.09,0,0,1,0,.12s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.65,19.34l-.39-1.05.53,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.05,17.73l-.2-1.1.35,1.05h0a.08.08,0,0,1-.05.1.09.09,0,0,1-.11-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.65,21.7l-.43-1,.56.95h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.06,33.21l-.65-.91.77.8h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.14,42.72l-.63-.91.75.81h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.79,52.93l-.51-1,.64.9h0a.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.07,61.91l-.41-1,.55,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.07,67.56l-.49-1,.62.92h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.8,71.77,27,71l.86.69h0a.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.78,74.23l-.84-.74.93.6h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,76.34l-.88-.7,1,.56h0a.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.14,75.38l-.89-.67,1,.53h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,74.5l-.89-.68,1,.54h0a.08.08,0,0,1,0,.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.06,75.32l-.87-.7.95.56h0a.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.5,73.81l-.84-.73.93.6h0a.08.08,0,1,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.2,68.11l-.53-1,.66.89h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.89,66.11l-.38-1L19,66h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.77,64.9l-.36-1.05.5,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.5,68.29l-.45-1,.58.94h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,70.9l-.7-.87.81.76h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.12,72l-.76-.82.86.7h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,72.45l-.78-.8.88.68h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.27,69.9,25.6,69l.78.77h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.65,69.11l-.59-.94.72.84h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.2,67l-.55-1,.68.87h0a.08.08,0,0,1,0,.11s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.07,67.24l-.48-1,.61.92h0a.09.09,0,0,1,0,.11.1.1,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.44,66.35l-.47-1,.61.92h0a.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.64,66.12l-.42-1,.56,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.1,67.58l-.51-1,.64.91h0a.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,67.4l-.46-1,.59.94h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.39,66.71l-.54-1,.67.89h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.56,66.3l-.41-1,.55,1h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.59,65.21l-.43-1,.57.95h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.69,65.48l-.44-1,.58,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.06,64.44l-.42-1,.56,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,64.68l-.41-1,.55,1h0a.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.87,64.5l-.42-1,.56,1h0a.08.08,0,0,1,0,.11s-.08,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.77,63.26l-.41-1,.55,1h0a.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.84,61.26l-.42-1,.56,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.16,62l-.42-1,.56,1h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.3,61.49l-.42-1,.56,1h0s0,.08,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.51,60.88l-.43-1,.57.95h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,60.57l-.43-1,.56,1h0a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.74,59.62l-.43-1,.56,1h0a.08.08,0,0,1-.14.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.55,83.6l.82-.75-.7.85h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.67,83.7l.82-.75-.7.86h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.06,85.52l.83-.75-.71.85h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.29,87.89l.81-.76-.69.86h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.53,90.11l.77-.82-.64.91h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.22,93l.67-.9-.53,1h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.78,94.61l.57-1-.42,1h0a.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.89,98.06,53.21,97l-.16,1.09h0a.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.35,101.64l0-1.11.14,1.09h0a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.42,102.58l-.05-1.12.21,1.09h0a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.27,99.81l.06-1.12.09,1.11h0a.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.27,95.22l.34-1.06-.18,1.09h0a.09.09,0,0,1-.1.07.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.44,90.41l.66-.9-.52,1h0a.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.14,85.39l.82-.76-.69.86h0a.08.08,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.8,82.67l.83-.76-.7.86h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.74,81l.82-.76-.69.86h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.76,80.93l.81-.77-.69.87h0s-.07,0-.11,0a.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.26,84.41l.74-.84-.61.93h0a.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.68,87.68l.43-1-.28,1.07h0a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.19,91.09,52.47,90l-.12,1.1h0a.07.07,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.29,94.2l.12-1.11,0,1.1h0a.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.44,97.47l-.08-1.11.24,1.08h0a.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.75,102l-.36-1.06.5,1h0a.08.08,0,1,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.51,104.74l-.5-1,.63.91h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.27,105.61l-.55-1,.67.88h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.92,105.16l-.64-.92.75.81h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.64,102.79l-.79-.8.88.67h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.37,98.5l-.89-.68,1,.54h0a.08.08,0,1,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.22,94.05l-.93-.62,1,.48h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.88,89.82l-.95-.59,1,.45h0a.1.1,0,0,1,.05.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.74,86.6l-1-.58,1,.43h0a.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.56,84l-.95-.58,1,.44h0a.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.79,83.56l-1-.59,1,.44h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.41,85.41l-1-.57,1,.43h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.64,89.58l-1-.57,1,.42h0a.08.08,0,0,1-.06.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.07,93.37l-.95-.58,1,.43h0a.07.07,0,0,1,0,.1.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.92,99.51,31,98.87l1,.5h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.14,102.41l-.88-.69,1,.55h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,102.68l-.86-.71,1,.57h0a.08.08,0,0,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.21,98.7l-.91-.65,1,.51h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.3,93.06l-.95-.61,1,.46h0a.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.57,87.92l-1-.57,1,.42h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.06,86l-1-.57,1,.43h0a.09.09,0,0,1,.05.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.44,84l-1-.58,1,.43h0a.08.08,0,0,1-.06.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.82,83.24l-1-.58,1,.43h0a.08.08,0,0,1-.06.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.42,83.87l-1-.58,1,.43h0a.08.08,0,0,1-.07.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.41,88.46l-1-.56,1,.42h0a.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.12,97l-.94-.6,1,.45h0a.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.24,107l-.79-.8.89.67h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.8,110.43l-.6-1,.72.84h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.45,113.5l-.37-1,.51,1h0a.08.08,0,0,1-.15.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.31,115.94l-.2-1.1.35,1h0a.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.22,116.28l-.13-1.11.28,1.07h0a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.54,115.18l-.15-1.11.3,1.07h0s0,.08,0,.1a.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.45,113.43v-1.11l.15,1.09h0a.08.08,0,0,1-.07.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.37,111.92V110.8l.15,1.1h0a.1.1,0,0,1-.07.09s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.41,111.16l.07-1.12.09,1.1h0a.08.08,0,0,1-.08.09.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.07,112.67l-.14-1.11.29,1.07h0a.08.08,0,0,1-.06.09.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.58,114.4l-.51-1,.64.91h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.63,113.88l-.47-1,.6.92h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.1,112.19,43,111.07l.24,1.09h0a.08.08,0,0,1-.06.09.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.55,113.19l.08-1.11.07,1.1h0a.08.08,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.19,112.7l.2-1.1,0,1.11h0a.07.07,0,0,1-.08.07.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.73,111l.18-1.1,0,1.11h0a.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.11,106.23l.17-1.11v1.11h0a.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,100.8l.37-1-.22,1.08h0a.08.08,0,0,1-.1.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.78,89.34l.58-1-.44,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.87,89.28l.5-1-.35,1h0a.08.08,0,0,1-.1.05.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,88l.63-.92-.48,1h0a.07.07,0,0,1-.1,0A.08.08,0,0,1,55,88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.58,83.8l.8-.78-.67.88h0s-.07,0-.11,0a.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.48,78.11l.79-.79-.67.88h0a.08.08,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.13,73.34l.73-.85-.59.93h0a.08.08,0,0,1-.11,0,.1.1,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.26,65.3l.65-.92-.51,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.1,56.9l.68-.89-.54,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.07,57l.67-.89-.53,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.52,58.09l.66-.89-.52,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.13,62.78l.65-.91-.51,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.39,74.75l.75-.83-.62.92h0a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.6,82.06l.8-.78-.67.88h0a.08.08,0,1,1-.12-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.32,86.26l.64-.92-.49,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.67,88l.52-1-.37,1h0a.08.08,0,0,1-.1,0,.09.09,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.41,39.67l-.69-.87.8.75h0a.08.08,0,1,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.47,38.38l-.71-.87.82.75h0a.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.84,33.7l-.74-.83.85.71h0a.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.78,25.84,9,25l.86.7h0a.08.08,0,0,1-.1.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8,21.72l-.77-.82.87.69h0a.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.93,15.29l-.77-.81.87.69H5a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.29,13.58l-.77-.81.87.68h0a.08.08,0,0,1-.1.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.56,14.11l-.77-.81.87.69h0s0,.07,0,.11a.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.24,19.29l-.76-.82.86.69h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,23.61l-.76-.82.86.69h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.25,22.28l-.76-.82.86.7h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7,16.21l-.77-.81.87.69h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.08,13.23l-.77-.81.87.69h0s0,.07,0,.11a.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.25,14.27l-.77-.81.87.69h0a.09.09,0,1,1-.11.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.35,17.34l-.75-.83.86.7h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.39,16.17l-.76-.81.86.69h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.69,16l-.77-.81.87.68h0s0,.07,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.68,13.42l-.77-.81.87.69h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.48,12.71l-.74-.83.84.71h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.88,12.27l-.73-.84.84.72h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.85,11.78l-.71-.86.82.74h0a.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.13,11.39l-.72-.85.82.73h0a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.1,13.17l-.73-.85.83.73h0a.08.08,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.91,12.87,15.2,12l.82.74h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.29,13.35l-.69-.88.8.76h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.8,12.43l-.67-.89.78.78h0a.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,12.12l-.69-.88.8.77h0a.07.07,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.26,13.22l-.7-.87.81.75h0a.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.67,15.09l-.75-.82.86.7h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.72,18.5,5,17.7l.87.68h0a.09.09,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M3.36,20.32l-.77-.81.87.68h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M2.83,21.65l-.77-.81.87.69h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.23,19.67l-.77-.81.87.69h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.37,16.41l-.75-.83.85.7h0a.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.4,15.18l-.73-.85.84.73h0a.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.33,15.7l-.74-.84.84.72h0a.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.2,18.12l-.76-.82L9.3,18h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.24,17.81,6.47,17l.87.68h0a.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.54,17.33l-.76-.81.86.69h0a.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.63,16.69l-.76-.83.86.7h0a.08.08,0,0,1,0,.11.08.08,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.93,10.34l-.5-1,.63.9h0a.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.08,8.33,38,7.22l.26,1.07h0a.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,9.75l0-1.12.11,1.1h0a.08.08,0,0,1-.07.09A.08.08,0,0,1,55,9.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.52,11.92l.42-1L65.67,12h0a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.17,14.08l.66-.9-.52,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.39,16.48l.7-.87-.57.95h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.54,16.89l.71-.86-.57.94h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.61,15.7l.71-.86-.58.95h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.93,15l.69-.88-.55,1h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.37,12.68l.62-.92-.48,1h0a.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.64,12l.6-.94-.46,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.64,12.06l.65-.91-.5,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.72,13.79l.71-.86-.58.94h0a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.6,18.84l.7-.86-.56.95h0a.08.08,0,1,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.92,21.16l.69-.89-.55,1h0s-.08.05-.11,0-.06-.08,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.62,20.29l.69-.88-.56,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.09,20.83l.68-.89-.54,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.71,19.77l.69-.88-.55,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.1,18.87l.69-.88-.55,1h0a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.81,18.48l.68-.89-.55,1h0a.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.59,17.45l.69-.88-.55,1h0a.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.76,16.2l.66-.89-.52,1h0a.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.14,16.13l.65-.91-.5,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.9,16.84l.62-.93-.47,1h0a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.08,18.28l.62-.93-.48,1h0a.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.15,19.89l.62-.93-.48,1h0a.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.76,21.78l.63-.92-.48,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.15,24.38l.65-.91-.51,1h0a.08.08,0,1,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.05,26.23l.65-.91-.51,1h0a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79,27.75l.65-.91-.51,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.18,28.11l.65-.91-.51,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.66,27l.65-.91-.5,1h0a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
      </g>}
      {r_type_hair >= 2 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.81,108.49a11.28,11.28,0,0,1,.07-2.53,11.12,11.12,0,0,0,.16,2.51.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.93,110a11.25,11.25,0,0,1,.07-2.53,11,11,0,0,0,.15,2.5.11.11,0,0,1-.09.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.2,109.83a11.26,11.26,0,0,1,0-2.53,11,11,0,0,0,.22,2.5.11.11,0,0,1-.09.13.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.88,108.31a11.36,11.36,0,0,1,0-2.54,11.64,11.64,0,0,0,.23,2.5.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.71,108.66a11.25,11.25,0,0,1,0-2.53,11.61,11.61,0,0,0,.18,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.61,107.54a11.35,11.35,0,0,1,0-2.54,11.56,11.56,0,0,0,.19,2.5.11.11,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.5,108.12a10.79,10.79,0,0,1,0-2.54,11.52,11.52,0,0,0,.22,2.5.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.56,109.58a10.71,10.71,0,0,1,0-2.53,11.61,11.61,0,0,0,.19,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.14,106.36a11.35,11.35,0,0,1,0-2.54,12.42,12.42,0,0,0,.17,2.51.11.11,0,0,1-.09.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.34,107.6a11.26,11.26,0,0,1,0-2.53,11.61,11.61,0,0,0,.19,2.5.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.4,106.86a11.26,11.26,0,0,1,0-2.53,11,11,0,0,0,.22,2.5.11.11,0,0,1-.09.13.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.61,108.31a11.26,11.26,0,0,1,.08-2.53,11.11,11.11,0,0,0,.14,2.51.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.91,106.8a11.35,11.35,0,0,1,.07-2.54,11.08,11.08,0,0,0,.16,2.51.11.11,0,0,1-.09.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.54,105.37a11.24,11.24,0,0,1,.09-2.53,11,11,0,0,0,.14,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.83,104.69a11.25,11.25,0,0,1,.07-2.53,11,11,0,0,0,.16,2.5.12.12,0,0,1-.1.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.66,104a11.24,11.24,0,0,1,.08-2.53,11,11,0,0,0,.14,2.5.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.92,104.69a11.24,11.24,0,0,1,.09-2.53,11.59,11.59,0,0,0,.13,2.5.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.48,105.46a11.86,11.86,0,0,1,.1-2.53,11.71,11.71,0,0,0,.13,2.51.11.11,0,0,1-.1.13.12.12,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.33,103.09a11.24,11.24,0,0,1,.08-2.53,11,11,0,0,0,.14,2.5.11.11,0,0,1-.09.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.83,105.59a11.86,11.86,0,0,1,.1-2.53,11.71,11.71,0,0,0,.12,2.51.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.64,103.73a11.24,11.24,0,0,1,.08-2.53,11.59,11.59,0,0,0,.15,2.5.12.12,0,0,1-.1.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.6,102.31a11.25,11.25,0,0,1,0-2.53,11,11,0,0,0,.17,2.5.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.33,104.54a11.35,11.35,0,0,1,.06-2.54,11.69,11.69,0,0,0,.16,2.51.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.29,102.84a10.79,10.79,0,0,1,0-2.54,11.65,11.65,0,0,0,.19,2.51.11.11,0,0,1-.09.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.18,103.31a11.9,11.9,0,0,1,0-2.53,11.5,11.5,0,0,0,.25,2.49.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.61,101a11.27,11.27,0,0,1,0-2.53,10.91,10.91,0,0,0,.26,2.49.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.15,101.63A10.74,10.74,0,0,1,32,99.1a11.49,11.49,0,0,0,.34,2.49.12.12,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.22,99.23a11.38,11.38,0,0,1-.1-2.54,11.47,11.47,0,0,0,.32,2.49.11.11,0,0,1-.22.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.4,100a11.36,11.36,0,0,1-.19-2.53,12.23,12.23,0,0,0,.41,2.48.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.5,98.79a11.36,11.36,0,0,1-.19-2.53,11,11,0,0,0,.41,2.48.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.34,99.93A11.3,11.3,0,0,1,30,97.41a10.72,10.72,0,0,0,.54,2.45.13.13,0,0,1-.07.15.12.12,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.7,97.2a11.37,11.37,0,0,1-.25-2.53,10.89,10.89,0,0,0,.47,2.47.12.12,0,0,1-.08.14.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.94,98.33a12,12,0,0,1-.36-2.51,12,12,0,0,0,.58,2.44.12.12,0,0,1-.07.15.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.45,97.15a11.8,11.8,0,0,1-.37-2.5,12.06,12.06,0,0,0,.59,2.44.11.11,0,0,1-.07.14.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.14,98a11.27,11.27,0,0,1-.51-2.48,11.24,11.24,0,0,0,.72,2.4.11.11,0,0,1-.06.15.12.12,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,95.26a11.22,11.22,0,0,1-.39-2.5,11.24,11.24,0,0,0,.61,2.43.12.12,0,0,1-.07.15.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.5,95.41A11.71,11.71,0,0,1,31,92.93a11.72,11.72,0,0,0,.71,2.41.13.13,0,0,1-.06.15.12.12,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.52,96.39A11.07,11.07,0,0,1,27.84,94a11.83,11.83,0,0,0,.88,2.35.11.11,0,0,1,0,.15.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,94.92a11.79,11.79,0,0,1-.64-2.45,11.45,11.45,0,0,0,.85,2.36.11.11,0,0,1-.05.15.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.3,93.44A11.65,11.65,0,0,1,30.69,91a10.9,10.9,0,0,0,.82,2.37.1.1,0,0,1-.05.15.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.69,94a11.31,11.31,0,0,1-.81-2.4,11,11,0,0,0,1,2.3.11.11,0,0,1,0,.15.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.14,94.29a11.77,11.77,0,0,1-.95-2.36,11.55,11.55,0,0,0,1.15,2.24.12.12,0,0,1,0,.16.1.1,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.84,92.09A11.28,11.28,0,0,1,29,89.7,11.79,11.79,0,0,0,30,92a.11.11,0,1,1-.19.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,92.56a12.32,12.32,0,0,1-1.11-2.28,12.06,12.06,0,0,0,1.3,2.15.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,91.27A11.41,11.41,0,0,1,26.91,89a11.21,11.21,0,0,0,1.27,2.17.1.1,0,0,1,0,.15.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.22,90a11.17,11.17,0,0,1-1-2.31,11.16,11.16,0,0,0,1.24,2.18.11.11,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.53,89.84a11.37,11.37,0,0,1-1.37-2.13,11.42,11.42,0,0,0,1.54,2,.14.14,0,0,1,0,.17.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.35,88.19A11.93,11.93,0,0,1,27,86a11.4,11.4,0,0,0,1.49,2,.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.77,88.09A11.42,11.42,0,0,1,24.36,86a10.62,10.62,0,0,0,1.58,2,.12.12,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.24,86.62a10.89,10.89,0,0,1-1.4-2.11,11.44,11.44,0,0,0,1.57,2,.11.11,0,0,1-.16.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.07,86.05A11.43,11.43,0,0,1,23.63,84a11,11,0,0,0,1.6,1.93.11.11,0,1,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,84.59a12,12,0,0,1-1.43-2.1,11.09,11.09,0,0,0,1.6,1.94.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.89,83.41a11.94,11.94,0,0,1-1.52-2,11.44,11.44,0,0,0,1.68,1.86.11.11,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.25,82.72a11.58,11.58,0,0,1-1.55-2,11.49,11.49,0,0,0,1.7,1.85.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.59,81.31a11.93,11.93,0,0,1-1.53-2,11.91,11.91,0,0,0,1.69,1.85.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.6,80.77a11.56,11.56,0,0,1-1.52-2,11.11,11.11,0,0,0,1.68,1.87.11.11,0,1,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.08,79.56a11.45,11.45,0,0,1-1.51-2,11.05,11.05,0,0,0,1.67,1.87.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.55,78.37a11.51,11.51,0,0,1-1.51-2,11.45,11.45,0,0,0,1.67,1.87.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.67,77.62a11.06,11.06,0,0,1-1.54-2,11.49,11.49,0,0,0,1.7,1.85.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.42,76.57a11.57,11.57,0,0,1-1.56-2,11.88,11.88,0,0,0,1.71,1.83.13.13,0,0,1,0,.17.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.15,75.84a11.1,11.1,0,0,1-1.56-2,11,11,0,0,0,1.71,1.83.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.79,72.58a11.54,11.54,0,0,1-1.58-2,11,11,0,0,0,1.73,1.81.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.49,70.74a11.13,11.13,0,0,1-1.6-2,10.65,10.65,0,0,0,1.75,1.8.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.69,69.6a12,12,0,0,1-1.61-1.95,11.46,11.46,0,0,0,1.77,1.78.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.15,69a11.2,11.2,0,0,1-1.61-2,11,11,0,0,0,1.76,1.79.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,68.16a11.57,11.57,0,0,1-1.63-1.94A11.46,11.46,0,0,0,18.11,68a.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.8,66.14a12.06,12.06,0,0,1-1.63-1.94A11.39,11.39,0,0,0,22,66a.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.63,66.21A11.64,11.64,0,0,1,17,64.27,11.46,11.46,0,0,0,18.78,66a.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,65.55a11.25,11.25,0,0,1-1.63-1.94,11.46,11.46,0,0,0,1.78,1.77.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.1,64.24a12.05,12.05,0,0,1-1.64-1.93,11.91,11.91,0,0,0,1.79,1.76.11.11,0,1,1-.14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.17,62.84a11.17,11.17,0,0,1-1.64-1.93,11.85,11.85,0,0,0,1.78,1.76.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.69,62.9A11.63,11.63,0,0,1,14.05,61a11.91,11.91,0,0,0,1.78,1.77.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.84,61.35a11.17,11.17,0,0,1-1.64-1.93A11.46,11.46,0,0,0,17,61.18a.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.12,60.87a11.57,11.57,0,0,1-1.63-1.94,11.91,11.91,0,0,0,1.78,1.77.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.26,59.69a11.57,11.57,0,0,1-1.63-1.94,11.46,11.46,0,0,0,1.78,1.77.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.41,59.05A11.51,11.51,0,0,1,12.8,57.1a11,11,0,0,0,1.76,1.78.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.89,58.2a11.57,11.57,0,0,1-1.62-2A11.46,11.46,0,0,0,16,58a.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.63,57A11.19,11.19,0,0,1,19,55.06a11.46,11.46,0,0,0,1.77,1.78.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.69,56.31a11.65,11.65,0,0,1-1.62-2,11.46,11.46,0,0,0,1.77,1.78.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.33,56.45a11.58,11.58,0,0,1-1.61-2,11.92,11.92,0,0,0,1.76,1.79.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.13,56.21a11.13,11.13,0,0,1-1.61-2A11.4,11.4,0,0,0,23.28,56a.12.12,0,0,1,0,.17.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.13,57.3a11.13,11.13,0,0,1-1.61-1.95,11,11,0,0,0,1.76,1.78.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.62,55.86a11.58,11.58,0,0,1-1.61-2,11.52,11.52,0,0,0,1.77,1.79.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.44,56.51a11.58,11.58,0,0,1-1.61-2,12,12,0,0,0,1.77,1.79.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.82,56.29a11.51,11.51,0,0,1-1.61-1.95A11.4,11.4,0,0,0,27,56.12a.12.12,0,0,1,0,.17.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.44,56.39a11.51,11.51,0,0,1-1.61-2,11.46,11.46,0,0,0,1.77,1.78.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.72,57.47a11.5,11.5,0,0,1-1.62-1.94,11.85,11.85,0,0,0,1.77,1.77.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.78,57.81a11.12,11.12,0,0,1-1.62-2,11.46,11.46,0,0,0,1.77,1.78.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.91,58.48a11.5,11.5,0,0,1-1.62-1.94,11.39,11.39,0,0,0,1.77,1.77.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.36,59.3a11.57,11.57,0,0,1-1.62-1.95,11.46,11.46,0,0,0,1.77,1.78.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.64,59.89A11.57,11.57,0,0,1,24,58a11.91,11.91,0,0,0,1.78,1.77.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.56,60.54a11.19,11.19,0,0,1-1.62-1.95,11,11,0,0,0,1.77,1.78.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.86,61.45a11.17,11.17,0,0,1-1.64-1.93A11.46,11.46,0,0,0,25,61.28a.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.51,62.12a10.42,10.42,0,0,1-1.63-1.94A10.65,10.65,0,0,0,26.66,62a.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.88,62.94A11.57,11.57,0,0,1,26.26,61,11.46,11.46,0,0,0,28,62.77a.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.64,63.34A11.17,11.17,0,0,1,24,61.41a11.46,11.46,0,0,0,1.79,1.76.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,63.85a11.57,11.57,0,0,1-1.63-1.93,11.85,11.85,0,0,0,1.78,1.76.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.65,64.44A11.63,11.63,0,0,1,24,62.51a11.44,11.44,0,0,0,1.79,1.76.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.55,65.89a11.18,11.18,0,0,1-1.62-2,11.52,11.52,0,0,0,1.77,1.79.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.59,66.46A11.63,11.63,0,0,1,19,64.52a11.52,11.52,0,0,0,1.79,1.77.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.94,67.18a11.65,11.65,0,0,1-1.62-2A11.46,11.46,0,0,0,25.09,67a.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.65,68.37a12.14,12.14,0,0,1-1.62-2A12,12,0,0,0,26.8,68.2a.11.11,0,1,1-.14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.44,67.74a11.64,11.64,0,0,1-1.62-2,11.52,11.52,0,0,0,1.77,1.79.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.73,68.15a11.19,11.19,0,0,1-1.62-2A11,11,0,0,0,20.88,68a.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.46,67.9a11.51,11.51,0,0,1-1.61-2,11.4,11.4,0,0,0,1.76,1.78.11.11,0,1,1-.14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.87,68a11.2,11.2,0,0,1-1.61-2A11.92,11.92,0,0,0,27,67.8a.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.72,69.78a11.15,11.15,0,0,1-1.6-2,11.86,11.86,0,0,0,1.75,1.79.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.36,70.79a11.15,11.15,0,0,1-1.6-2,11.41,11.41,0,0,0,1.75,1.79.12.12,0,0,1,0,.17.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.57,71.46a11.59,11.59,0,0,1-1.6-2,11.93,11.93,0,0,0,1.75,1.8.11.11,0,1,1-.14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.66,71.62a11.53,11.53,0,0,1-1.59-2,11.4,11.4,0,0,0,1.74,1.8.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.69,71a11.6,11.6,0,0,1-1.59-2,11.11,11.11,0,0,0,1.75,1.81.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.43,71.5a11.7,11.7,0,0,1-1.59-2,11.48,11.48,0,0,0,1.74,1.81.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.2,74.25a11.61,11.61,0,0,1-1.58-2,11.94,11.94,0,0,0,1.73,1.82.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.67,73a11.53,11.53,0,0,1-1.59-2,11.91,11.91,0,0,0,1.74,1.81.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,75.73a11.63,11.63,0,0,1-1.57-2,11.5,11.5,0,0,0,1.72,1.83.1.1,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.52,73.17a12,12,0,0,1-1.57-2A11.47,11.47,0,0,0,21.68,73a.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.18,74.61a11.45,11.45,0,0,1-1.57-2,11.88,11.88,0,0,0,1.72,1.82.11.11,0,1,1-.14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.73,75.1a10.75,10.75,0,0,1-1.57-2,11.5,11.5,0,0,0,1.72,1.83.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.4,73.73a11.45,11.45,0,0,1-1.57-2,11,11,0,0,0,1.72,1.82.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.45,75.07a11.39,11.39,0,0,1-1.56-2A11.35,11.35,0,0,0,26.6,74.9a.13.13,0,0,1,0,.17.14.14,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.68,74.21a12,12,0,0,1-1.57-2A11.88,11.88,0,0,0,24.83,74a.11.11,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.19,73.14a11.17,11.17,0,0,1-1.58-2A11.47,11.47,0,0,0,26.34,73a.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.45,73.34a11.17,11.17,0,0,1-1.58-2,11,11,0,0,0,1.73,1.81.13.13,0,0,1,0,.17.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.49,72a11.53,11.53,0,0,1-1.59-2,11.4,11.4,0,0,0,1.74,1.8.11.11,0,1,1-.14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.78,71.51a11.54,11.54,0,0,1-1.58-2,11.05,11.05,0,0,0,1.74,1.81.11.11,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.52,69.9a11.61,11.61,0,0,1-1.6-2,11.86,11.86,0,0,0,1.75,1.79.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.82,67.16a11.65,11.65,0,0,1-1.62-2A11.46,11.46,0,0,0,29,67a.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,65.94A11.64,11.64,0,0,1,22.37,64a11.46,11.46,0,0,0,1.78,1.77A.11.11,0,0,1,24,66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.89,64.73a11.25,11.25,0,0,1-1.63-2A12,12,0,0,0,28,64.56a.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.49,64.17a11.56,11.56,0,0,1-1.64-1.93A11,11,0,0,0,22.64,64a.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.18,63.56a12.11,12.11,0,0,1-1.65-1.93,11.45,11.45,0,0,0,1.8,1.75.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.28,63.61a11.22,11.22,0,0,1-1.66-1.92,11.9,11.9,0,0,0,1.8,1.75.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.29,62.3a12,12,0,0,1-1.65-1.92,11.45,11.45,0,0,0,1.8,1.75.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.17,61.77a11.17,11.17,0,0,1-1.64-1.93,11,11,0,0,0,1.79,1.76.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.07,61.27a11.11,11.11,0,0,1-1.64-1.92,11,11,0,0,0,1.79,1.75.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.21,60.67a11.63,11.63,0,0,1-1.64-1.93,12.4,12.4,0,0,0,1.79,1.76.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.38,60.36a12.5,12.5,0,0,1-1.64-1.93,11.39,11.39,0,0,0,1.79,1.75.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,60a11.17,11.17,0,0,1-1.64-1.93,11.46,11.46,0,0,0,1.79,1.76A.11.11,0,0,1,20,60Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.38,59.19a11.17,11.17,0,0,1-1.64-1.93A11,11,0,0,0,22.53,59a.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,59.48a10.82,10.82,0,0,1-1.63-1.94,11.46,11.46,0,0,0,1.78,1.77.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.78,58.38a12.06,12.06,0,0,1-1.63-1.94,11.39,11.39,0,0,0,1.78,1.76.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.29,57.83a11.18,11.18,0,0,1-1.63-1.94,11,11,0,0,0,1.78,1.77.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.06,57.26a10.76,10.76,0,0,1-1.62-1.95,11,11,0,0,0,1.77,1.78.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.4,56.87a11.61,11.61,0,0,1-1.6-2,10.65,10.65,0,0,0,1.76,1.79.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.08,56.85a11.07,11.07,0,0,1-1.59-2,11,11,0,0,0,1.74,1.8.1.1,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.3,55.66a11.59,11.59,0,0,1-1.6-2,11.47,11.47,0,0,0,1.75,1.8.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.39,55.06a11.61,11.61,0,0,1-1.58-2,11.47,11.47,0,0,0,1.73,1.82.11.11,0,0,1,0,.16.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.89,54.41a11.53,11.53,0,0,1-1.59-2,10.65,10.65,0,0,0,1.75,1.8.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.36,54.1a11.07,11.07,0,0,1-1.59-2,11.47,11.47,0,0,0,1.74,1.81.11.11,0,1,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.83,53.8a11.66,11.66,0,0,1-1.57-2A11.54,11.54,0,0,0,16,53.64a.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.43,53.64a11.07,11.07,0,0,1-1.59-2,11.47,11.47,0,0,0,1.74,1.81.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.44,53.23a11.54,11.54,0,0,1-1.58-2,11.05,11.05,0,0,0,1.74,1.81.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,52.94a11,11,0,0,1-1.57-2,11.47,11.47,0,0,0,1.73,1.82.11.11,0,0,1,0,.16A.12.12,0,0,1,24,53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.4,51.7a11.51,11.51,0,0,1-1.55-2,11.48,11.48,0,0,0,1.71,1.84.11.11,0,1,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.54,52.1a11.58,11.58,0,0,1-1.55-2,11.07,11.07,0,0,0,1.71,1.84.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.76,51.1a11.93,11.93,0,0,1-1.53-2,11.91,11.91,0,0,0,1.69,1.85.12.12,0,0,1,0,.17.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,49.72a10.94,10.94,0,0,1-1.49-2,11.41,11.41,0,0,0,1.65,1.89.12.12,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.86,49.94a11.43,11.43,0,0,1-1.48-2.06A11.91,11.91,0,0,0,26,49.78a.12.12,0,0,1-.16.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.19,48.85a11.39,11.39,0,0,1-1.46-2.07,11.45,11.45,0,0,0,1.62,1.91.11.11,0,1,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.05,47.54a11.78,11.78,0,0,1-1.44-2.09,11.4,11.4,0,0,0,1.6,1.93.11.11,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.09,47.33a11.42,11.42,0,0,1-1.41-2.11,11.44,11.44,0,0,0,1.57,2,.1.1,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.41,44.85a11.73,11.73,0,0,1-1.33-2.16,11.43,11.43,0,0,0,1.5,2,.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.58,43.1a11.34,11.34,0,0,1-1.3-2.17,11.29,11.29,0,0,0,1.47,2,.11.11,0,1,1-.16.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.37,43.15A11.49,11.49,0,0,1,20.06,41a11.4,11.4,0,0,0,1.48,2,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.09,41.5a11.5,11.5,0,0,1-1.28-2.19,11.4,11.4,0,0,0,1.45,2,.12.12,0,0,1-.17.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.87,41.9a12.1,12.1,0,0,1-1.29-2.19,11.45,11.45,0,0,0,1.46,2,.11.11,0,0,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.42,40.63a11.15,11.15,0,0,1-1.27-2.19,11.34,11.34,0,0,0,1.45,2.05.12.12,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.07,42.44a11.48,11.48,0,0,1-1.26-2.2,11.36,11.36,0,0,0,1.44,2.06.12.12,0,0,1,0,.16.14.14,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.66,41.24A11.43,11.43,0,0,1,21.41,39a11.31,11.31,0,0,0,1.43,2.06.12.12,0,0,1-.18.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.13,45.9a11.21,11.21,0,0,1-1.25-2.21,11,11,0,0,0,1.43,2.06.11.11,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.27,43.88a12,12,0,0,1-1.21-2.23,11.87,11.87,0,0,0,1.39,2.09.11.11,0,0,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.94,45.74a11.72,11.72,0,0,1-1.13-2.27,11.2,11.2,0,0,0,1.32,2.14.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.36,44.59a11.42,11.42,0,0,1-1.18-2.24,12.26,12.26,0,0,0,1.36,2.11.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.1,44.11a11.08,11.08,0,0,1-1-2.34A11.77,11.77,0,0,0,30.29,44a.1.1,0,0,1,0,.15.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,44.65a10.89,10.89,0,0,1-1.06-2.3,11.42,11.42,0,0,0,1.25,2.18.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.66,46.13a11.16,11.16,0,0,1-1.22-2.22A11,11,0,0,0,28.84,46a.11.11,0,1,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.74,44.54a10.46,10.46,0,0,1-1.13-2.27,11.16,11.16,0,0,0,1.31,2.14.11.11,0,0,1-.18.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.26,40.55A11.7,11.7,0,0,1,23.1,38.3a12,12,0,0,0,1.34,2.12.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.81,42.48A11.73,11.73,0,0,1,24.7,40.2,11.8,11.8,0,0,0,26,42.36a.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.92,43a11.41,11.41,0,0,1-1.06-2.3,11.42,11.42,0,0,0,1.25,2.18.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.7,40.81a11.45,11.45,0,0,1-1.07-2.3,11,11,0,0,0,1.26,2.18.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.67,41.92a11.14,11.14,0,0,1-1.16-2.25,10.89,10.89,0,0,0,1.35,2.12.11.11,0,0,1-.18.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.26,41.82a11.37,11.37,0,0,1-.91-2.37,11.48,11.48,0,0,0,1.11,2.25.11.11,0,0,1,0,.16.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.92,41.44a11.1,11.1,0,0,1-1-2.33,11.43,11.43,0,0,0,1.18,2.21.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.56,42.67a11.74,11.74,0,0,1-.87-2.38,11.21,11.21,0,0,0,1.07,2.27.1.1,0,0,1,0,.15.1.1,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.3,43.16a11.69,11.69,0,0,1-1-2.33A11.73,11.73,0,0,0,28.49,43a.11.11,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.63,51.73a12,12,0,0,1-1.56-2,11.88,11.88,0,0,0,1.71,1.83.11.11,0,1,1-.14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.93,51.78a11.52,11.52,0,0,1-1.5-2,12,12,0,0,0,1.66,1.88.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.32,51.42a11.56,11.56,0,0,1-1.52-2,11.51,11.51,0,0,0,1.68,1.87.11.11,0,1,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.52,51.58a11.87,11.87,0,0,1-1.52-2,11.44,11.44,0,0,0,1.68,1.86.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.77,51.57a11.06,11.06,0,0,1-1.54-2,11.49,11.49,0,0,0,1.7,1.85.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15,50.25a11.51,11.51,0,0,1-1.47-2.06,11.49,11.49,0,0,0,1.64,1.9.12.12,0,0,1-.16.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.34,49.79a11,11,0,0,1-1.49-2,11,11,0,0,0,1.65,1.88.12.12,0,0,1,0,.17.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.14,49.12a12,12,0,0,1-1.47-2.07A11.56,11.56,0,0,0,17.31,49a.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.78,48.26a11.78,11.78,0,0,1-1.44-2.09,11.4,11.4,0,0,0,1.6,1.93.1.1,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.68,48.19a11.9,11.9,0,0,1-1.46-2.07A11.45,11.45,0,0,0,18.84,48a.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.8,47a11.37,11.37,0,0,1-1.4-2.11,11.44,11.44,0,0,0,1.57,2A.1.1,0,0,1,17,47a.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.5,47.42a11.35,11.35,0,0,1-1.44-2.08,11,11,0,0,0,1.6,1.92.12.12,0,0,1,0,.17.14.14,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.32,45.9a11.75,11.75,0,0,1-1.38-2.13,10.94,10.94,0,0,0,1.55,2,.12.12,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,44.8a11.68,11.68,0,0,1-1.32-2.16,11.76,11.76,0,0,0,1.49,2,.11.11,0,1,1-.16.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.54,45.89a11.07,11.07,0,0,1-1.39-2.12,11.09,11.09,0,0,0,1.56,2,.12.12,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,43.74a11.39,11.39,0,0,1-1.31-2.17,11.4,11.4,0,0,0,1.49,2,.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.5,45a10.82,10.82,0,0,1-1.34-2.14,11.79,11.79,0,0,0,1.51,2,.1.1,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.44,45.61a11.65,11.65,0,0,1-1.36-2.13,11.37,11.37,0,0,0,1.53,2,.11.11,0,1,1-.16.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.05,44.38a12.07,12.07,0,0,1-1.33-2.16,11.81,11.81,0,0,0,1.5,2,.11.11,0,1,1-.16.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.61,43.88a11.12,11.12,0,0,1-1.28-2.18,11.31,11.31,0,0,0,1.46,2,.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.74,39.94a12.32,12.32,0,0,1-1.25-2.21,11.46,11.46,0,0,0,1.43,2.06.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.86,39a11,11,0,0,1-1.21-2.23A10.85,10.85,0,0,0,23,38.84a.11.11,0,0,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.66,38.43a11.41,11.41,0,0,1-1.1-2.28,10.85,10.85,0,0,0,1.29,2.15.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.46,39.33a11.53,11.53,0,0,1-.82-2.4,12.12,12.12,0,0,0,1,2.3.1.1,0,0,1,0,.15.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.28,37.89a11.66,11.66,0,0,1-.89-2.37,11.22,11.22,0,0,0,1.09,2.26.11.11,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.29,37a12,12,0,0,1-1-2.34,11.51,11.51,0,0,0,1.18,2.22.12.12,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.38,35.53a11.09,11.09,0,0,1-1.05-2.3,11.46,11.46,0,0,0,1.24,2.18.12.12,0,0,1-.19.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.5,37.3a11.52,11.52,0,0,1-.73-2.43,11.25,11.25,0,0,0,.94,2.33.1.1,0,0,1-.05.15.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.88,37.86a11.06,11.06,0,0,1-.64-2.45,11.43,11.43,0,0,0,.84,2.36.11.11,0,0,1,0,.15.11.11,0,0,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.16,35.16a11.29,11.29,0,0,1-.91-2.36,11.48,11.48,0,0,0,1.11,2.25.12.12,0,0,1,0,.16.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.25,36.59a11.65,11.65,0,0,1-.61-2.46,11.75,11.75,0,0,0,.82,2.38.11.11,0,0,1-.06.15.12.12,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.58,36a12.08,12.08,0,0,1-.8-2.4,11.77,11.77,0,0,0,1,2.3.11.11,0,0,1,0,.16.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,35.69a11.68,11.68,0,0,1-.69-2.44,11.4,11.4,0,0,0,.89,2.34.11.11,0,1,1-.2.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.61,34.4A11.72,11.72,0,0,1,27.82,32a10.94,10.94,0,0,0,1,2.3.12.12,0,1,1-.2.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.71,33.36A11.17,11.17,0,0,1,25.77,31a11.51,11.51,0,0,0,1.14,2.24.12.12,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.8,32.45A11.93,11.93,0,0,1,27,30.06,11.51,11.51,0,0,0,28,32.34a.11.11,0,0,1,0,.16.1.1,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.26,32.67a11.7,11.7,0,0,1-.73-2.43,11.48,11.48,0,0,0,.94,2.33.13.13,0,0,1,0,.15.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.34,33.75a11.73,11.73,0,0,1-.58-2.46,11.66,11.66,0,0,0,.79,2.38.12.12,0,0,1-.05.15.13.13,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.77,32.26a10.94,10.94,0,0,1-.61-2.46A11,11,0,0,0,31,32.18a.11.11,0,0,1-.21.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.25,30.56a10.91,10.91,0,0,1-.71-2.43,11.47,11.47,0,0,0,.91,2.34.11.11,0,0,1,0,.15.12.12,0,0,1-.16-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.6,31.32a11.17,11.17,0,0,1-.47-2.49,11.33,11.33,0,0,0,.69,2.42.13.13,0,0,1-.07.15.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.8,29.43A11.67,11.67,0,0,1,30.22,27,11.27,11.27,0,0,0,31,29.34a.13.13,0,0,1-.05.16.13.13,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.82,29.46A11.12,11.12,0,0,1,32.38,27,11.26,11.26,0,0,0,33,29.39a.12.12,0,0,1-.07.15.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.19,28.13a11.17,11.17,0,0,1-.47-2.49,11.09,11.09,0,0,0,.69,2.41.12.12,0,0,1-.07.15.1.1,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.41,27a11.75,11.75,0,0,1-.51-2.48,11.47,11.47,0,0,0,.72,2.4.11.11,0,0,1-.06.15.1.1,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.12,27.78a12,12,0,0,1-.36-2.51,11.71,11.71,0,0,0,.57,2.44.11.11,0,0,1-.06.15.12.12,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.09,25.29a11.22,11.22,0,0,1-.5-2.49,11.16,11.16,0,0,0,.72,2.41.12.12,0,0,1-.07.15.11.11,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.74,26.5a11.27,11.27,0,0,1-.3-2.52A11.34,11.34,0,0,0,35,26.44a.11.11,0,1,1-.21.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.61,25.36a11.24,11.24,0,0,1-.4-2.5,11.24,11.24,0,0,0,.61,2.43.1.1,0,0,1-.06.14.11.11,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.05,25.07a11.9,11.9,0,0,1-.31-2.52A12,12,0,0,0,34.27,25a.11.11,0,0,1-.21.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.85,23.78a12.54,12.54,0,0,1-.35-2.51,12,12,0,0,0,.57,2.45.11.11,0,1,1-.21.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.56,22.59a11.24,11.24,0,0,1-.4-2.5,11.09,11.09,0,0,0,.61,2.43.11.11,0,0,1-.06.15.12.12,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.43,20.82a11.27,11.27,0,0,1-.31-2.51,11.37,11.37,0,0,0,.52,2.45.11.11,0,0,1-.21.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.74,20.3a11.88,11.88,0,0,1-.23-2.52A11.45,11.45,0,0,0,34,20.25a.12.12,0,0,1-.08.14.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.39,18.82a11.24,11.24,0,0,1-.21-2.52,11.41,11.41,0,0,0,.43,2.47.11.11,0,0,1-.07.14.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.65,20a12,12,0,0,1-.16-2.53,12.08,12.08,0,0,0,.38,2.48.11.11,0,0,1-.08.14.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.55,17.37a11.32,11.32,0,0,1-.15-2.53,11.52,11.52,0,0,0,.37,2.48.11.11,0,0,1-.22.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,15.79a11.39,11.39,0,0,1-.11-2.54,10.91,10.91,0,0,0,.34,2.49.13.13,0,0,1-.09.14A.12.12,0,0,1,35,15.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.87,17.66a12,12,0,0,1-.12-2.54,11.5,11.5,0,0,0,.35,2.49.13.13,0,0,1-.09.14.12.12,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.28,14.85a11.28,11.28,0,0,1-.08-2.53,11.54,11.54,0,0,0,.3,2.49.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.52,16.28a11.28,11.28,0,0,1-.08-2.53,11,11,0,0,0,.31,2.49.12.12,0,0,1-.23.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.16,18.72a10.84,10.84,0,0,1-.1-2.54,10.89,10.89,0,0,0,.32,2.49.12.12,0,0,1-.08.14.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.66,17.29a11.39,11.39,0,0,1-.08-2.54,11.46,11.46,0,0,0,.3,2.49.11.11,0,0,1-.09.14.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.89,15.59a12,12,0,0,1-.06-2.54,11.61,11.61,0,0,0,.28,2.5.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.28,19.69a11.29,11.29,0,0,1-.1-2.53,11.55,11.55,0,0,0,.32,2.49.12.12,0,0,1-.08.14.11.11,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.78,14.59a11.27,11.27,0,0,1,0-2.53A10.91,10.91,0,0,0,39,14.55a.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.13,17.81a12,12,0,0,1-.06-2.54,11,11,0,0,0,.29,2.5.12.12,0,0,1-.23.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.56,16.25a11.27,11.27,0,0,1,0-2.53,10.92,10.92,0,0,0,.27,2.49.12.12,0,0,1-.09.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.83,19.21a11.28,11.28,0,0,1-.07-2.53,12.19,12.19,0,0,0,.29,2.49.11.11,0,0,1-.08.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.54,19.11a11.89,11.89,0,0,1,0-2.53,11.52,11.52,0,0,0,.28,2.49.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.83,17.82a12,12,0,0,1,0-2.54,11.67,11.67,0,0,0,.27,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.07,16.47a11.37,11.37,0,0,1,0-2.54,11.06,11.06,0,0,0,.26,2.5.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.93,20.72a11.39,11.39,0,0,1-.08-2.54,11.46,11.46,0,0,0,.3,2.49.11.11,0,0,1-.08.14.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.29,20.4a10.71,10.71,0,0,1-.06-2.53,11.52,11.52,0,0,0,.28,2.49.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.51,15.25a11.37,11.37,0,0,1,0-2.54,11,11,0,0,0,.26,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.53,17.85a11.36,11.36,0,0,1-.05-2.54,11.54,11.54,0,0,0,.27,2.5.11.11,0,0,1-.08.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.55,106.21a11.25,11.25,0,0,1,.15-2.53,11.68,11.68,0,0,0,.07,2.51.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.87,107.51a11.31,11.31,0,0,1,.25-2.53,11.09,11.09,0,0,0,0,2.51.11.11,0,0,1-.1.13.13.13,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.15,107.87a11.77,11.77,0,0,1,.35-2.51,11.71,11.71,0,0,0-.12,2.51.13.13,0,0,1-.11.12.12.12,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.09,106a11.25,11.25,0,0,1,.14-2.53,11.09,11.09,0,0,0,.08,2.51.11.11,0,0,1-.09.13.12.12,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.21,106.66a11.83,11.83,0,0,1,.24-2.52,11.09,11.09,0,0,0,0,2.51.13.13,0,0,1-.11.13.12.12,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.58,104.68a11.15,11.15,0,0,1,.21-2.52,11.61,11.61,0,0,0,0,2.5.11.11,0,0,1-.1.13.11.11,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.41,104.51a11.79,11.79,0,0,1,.37-2.51,11.75,11.75,0,0,0-.14,2.51.11.11,0,0,1-.11.12.13.13,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.53,103.15a11.7,11.7,0,0,1,.37-2.51,11.11,11.11,0,0,0-.14,2.51.13.13,0,0,1-.11.12.12.12,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.78,101.89a11.65,11.65,0,0,1,.57-2.47,11.14,11.14,0,0,0-.34,2.49.13.13,0,0,1-.12.11.11.11,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.64,101.9a11,11,0,0,1,.79-2.41,11.39,11.39,0,0,0-.57,2.44.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.18,100a11.94,11.94,0,0,1,.88-2.38,10.91,10.91,0,0,0-.65,2.43.11.11,0,0,1-.13.09.11.11,0,0,1-.1-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.78,99.68a11.78,11.78,0,0,1,1.1-2.29A11.68,11.68,0,0,0,57,99.74a.12.12,0,0,1-.14.09.12.12,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.74,98.26a11.1,11.1,0,0,1,1.33-2.16A11.44,11.44,0,0,0,58,98.34a.12.12,0,0,1-.15.07.11.11,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.11,96.48a11.57,11.57,0,0,1,1.39-2.12,11.18,11.18,0,0,0-1.18,2.21.12.12,0,0,1-.15.07.13.13,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.6,95.9a11.63,11.63,0,0,1,1.59-2A12.11,12.11,0,0,0,58.79,96a.11.11,0,0,1-.15,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.74,94.08a11.25,11.25,0,0,1,1.7-1.88,11.17,11.17,0,0,0-1.51,2,.11.11,0,0,1-.16,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.14,93.28A11.23,11.23,0,0,1,62,91.51a11.36,11.36,0,0,0-1.63,1.91.12.12,0,0,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,91.18a11.58,11.58,0,0,1,1.84-1.73,11.34,11.34,0,0,0-1.66,1.87.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.4,89.56a11.23,11.23,0,0,1,1.93-1.65,11,11,0,0,0-1.75,1.8.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.68,87.63a11.66,11.66,0,0,1,1.89-1.69,11.45,11.45,0,0,0-1.71,1.84.11.11,0,0,1-.18-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.35,85.66a11.71,11.71,0,0,1,1.81-1.78,11.3,11.3,0,0,0-1.62,1.91.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.7,83.78A10.83,10.83,0,0,1,64.45,82a11,11,0,0,0-1.57,2,.1.1,0,0,1-.15,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.52,81.5a11.29,11.29,0,0,1,1.74-1.85,11.69,11.69,0,0,0-1.55,2,.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.9,79.44a11.29,11.29,0,0,1,1.73-1.86,11.44,11.44,0,0,0-1.55,2,.1.1,0,0,1-.15,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.64,77.45a11.32,11.32,0,0,1,1.74-1.85,11.3,11.3,0,0,0-1.55,2,.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.17,74.74a11.22,11.22,0,0,1,1.75-1.83,11.74,11.74,0,0,0-1.57,2,.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.87,73a12.19,12.19,0,0,1,1.77-1.82,11.34,11.34,0,0,0-1.58,2,.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.73,69.86a11.65,11.65,0,0,1,1.8-1.78A11.86,11.86,0,0,0,61.91,70a.11.11,0,0,1-.15,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.27,69a11.28,11.28,0,0,1,1.82-1.77,11,11,0,0,0-1.64,1.9.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.53,67a10.82,10.82,0,0,1,1.84-1.74,11,11,0,0,0-1.66,1.88.12.12,0,0,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.94,65.16a10.82,10.82,0,0,1,1.85-1.73,11,11,0,0,0-1.67,1.87.12.12,0,0,1-.19-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.85,63.14a11.2,11.2,0,0,1,1.87-1.71A11.45,11.45,0,0,0,61,63.28a.11.11,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.8,61.1a11.61,11.61,0,0,1,1.88-1.7A11,11,0,0,0,62,61.24a.12.12,0,1,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.77,58.7A11.27,11.27,0,0,1,63.66,57,11.45,11.45,0,0,0,62,58.84a.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.73,57a10.83,10.83,0,0,1,1.88-1.7,11,11,0,0,0-1.7,1.84.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.57,54.86a11.14,11.14,0,0,1,1.86-1.71A10.59,10.59,0,0,0,60.75,55a.12.12,0,0,1-.19-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,53.51a11.14,11.14,0,0,1,1.84-1.73,11.88,11.88,0,0,0-1.67,1.87.11.11,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,51.32a12.1,12.1,0,0,1,1.82-1.76,10.92,10.92,0,0,0-1.63,1.9.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.94,49.45a11.73,11.73,0,0,1,1.78-1.81,11.33,11.33,0,0,0-1.59,1.94.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.4,48a11.69,11.69,0,0,1,1.74-1.84,11.3,11.3,0,0,0-1.55,2,.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.75,47.53a10.85,10.85,0,0,1,1.72-1.86,11.26,11.26,0,0,0-1.53,2,.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.82,47.12a11.7,11.7,0,0,1,1.7-1.88,11.79,11.79,0,0,0-1.51,2,.1.1,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.48,45.86A11.73,11.73,0,0,1,62.15,44a11.34,11.34,0,0,0-1.48,2,.11.11,0,0,1-.15,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,45.69a11.29,11.29,0,0,1,1.65-1.93,11.75,11.75,0,0,0-1.46,2.05.12.12,0,1,1-.2-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.22,45.7a10.91,10.91,0,0,1,1.66-1.92,10.89,10.89,0,0,0-1.47,2,.11.11,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.68,48.6a11.22,11.22,0,0,1,1.75-1.83,11.74,11.74,0,0,0-1.57,2,.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.63,50.6a11.28,11.28,0,0,1,1.81-1.78,10.92,10.92,0,0,0-1.62,1.92.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.46,52a11.64,11.64,0,0,1,1.83-1.75,11.29,11.29,0,0,0-1.64,1.89.12.12,0,0,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.65,54a11.2,11.2,0,0,1,1.85-1.73,11,11,0,0,0-1.67,1.87.12.12,0,1,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.9,56.59a11.58,11.58,0,0,1,1.86-1.71,11.82,11.82,0,0,0-1.69,1.85.1.1,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.55,58.64a11.65,11.65,0,0,1,1.87-1.71,11.82,11.82,0,0,0-1.69,1.85.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.6,60.54a11.65,11.65,0,0,1,1.87-1.71,11.08,11.08,0,0,0-1.7,1.86.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.44,62a11.71,11.71,0,0,1,1.87-1.72,11,11,0,0,0-1.69,1.86.12.12,0,0,1-.19-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.75,63.31a11.64,11.64,0,0,1,1.86-1.72,11.39,11.39,0,0,0-1.68,1.86.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.48,64.4a11.2,11.2,0,0,1,1.85-1.73,11.4,11.4,0,0,0-1.67,1.87.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.61,65.87a11.67,11.67,0,0,1,1.84-1.74A11.35,11.35,0,0,0,65.79,66a.12.12,0,0,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.47,67.46a12.09,12.09,0,0,1,1.83-1.75,10.91,10.91,0,0,0-1.64,1.89.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.25,68.51a10.82,10.82,0,0,1,1.82-1.76,11,11,0,0,0-1.64,1.9.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.66,70a11.22,11.22,0,0,1,1.8-1.78,11.3,11.3,0,0,0-1.62,1.91.11.11,0,0,1-.16,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.43,71.2a11.22,11.22,0,0,1,1.79-1.79,11,11,0,0,0-1.61,1.93.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.38,72.45a12.19,12.19,0,0,1,1.77-1.82,11.34,11.34,0,0,0-1.58,2,.11.11,0,0,1-.16,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.81,73.37a11.68,11.68,0,0,1,1.75-1.83,11.68,11.68,0,0,0-1.56,2,.12.12,0,0,1-.2-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.8,74.87A12.12,12.12,0,0,1,64.54,73,12.28,12.28,0,0,0,63,75a.1.1,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.87,75.11a11.26,11.26,0,0,1,1.73-1.85,11.81,11.81,0,0,0-1.54,2,.1.1,0,0,1-.15,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.55,75.55a11.23,11.23,0,0,1,1.72-1.86,10.54,10.54,0,0,0-1.53,2,.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,75.91a11.67,11.67,0,0,1,1.73-1.85,11.31,11.31,0,0,0-1.54,2,.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.86,75.57a12.13,12.13,0,0,1,1.71-1.87,11.34,11.34,0,0,0-1.52,2,.11.11,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.64,74a11.73,11.73,0,0,1,1.74-1.85,11.37,11.37,0,0,0-1.55,2,.11.11,0,0,1-.16,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.89,72.34a11.3,11.3,0,0,1,1.76-1.83,11.74,11.74,0,0,0-1.57,2,.11.11,0,0,1-.16,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.53,69.87a11.29,11.29,0,0,1,1.79-1.8A11,11,0,0,0,57.71,70a.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.47,67.4a12.11,12.11,0,0,1,1.82-1.76,11.36,11.36,0,0,0-1.64,1.9.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.32,65.94a11.71,11.71,0,0,1,1.84-1.75,11,11,0,0,0-1.66,1.89.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.8,64.08a11.58,11.58,0,0,1,1.85-1.72A11.29,11.29,0,0,0,57,64.22a.12.12,0,0,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.27,63.18a11.58,11.58,0,0,1,1.85-1.72,11.46,11.46,0,0,0-1.68,1.87.1.1,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.61,61.53a10.82,10.82,0,0,1,1.86-1.72,11.46,11.46,0,0,0-1.69,1.86.11.11,0,1,1-.18-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.85,59.79a11.23,11.23,0,0,1,1.86-1.72A11.89,11.89,0,0,0,55,59.93a.1.1,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.71,57.87a11.2,11.2,0,0,1,1.86-1.72A11,11,0,0,0,54.89,58a.11.11,0,0,1-.16,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.78,56.29a11.29,11.29,0,0,1,1.85-1.74A11.42,11.42,0,0,0,54,56.43a.12.12,0,1,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.7,54.48a11.58,11.58,0,0,1,1.82-1.75,11.29,11.29,0,0,0-1.64,1.89.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.57,52.73a11.22,11.22,0,0,1,1.79-1.79,11,11,0,0,0-1.61,1.93.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.29,50.31A11.26,11.26,0,0,1,54,48.46a10.53,10.53,0,0,0-1.54,2,.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,46.65a11.65,11.65,0,0,1,1.62-1.95,11.74,11.74,0,0,0-1.43,2.07.11.11,0,0,1-.15,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.58,43.53a11.57,11.57,0,0,1,1.48-2.06,11,11,0,0,0-1.27,2.17.12.12,0,0,1-.15,0,.11.11,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.61,40.83a11.72,11.72,0,0,1,1.17-2.25,11.67,11.67,0,0,0-1,2.32.11.11,0,1,1-.22-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.66,38.05a11.9,11.9,0,0,1,.71-2.43,11.53,11.53,0,0,0-.49,2.46.11.11,0,0,1-.12.1.12.12,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.8,34.61a11.35,11.35,0,0,1,.06-2.54A11.69,11.69,0,0,0,50,34.58a.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.07,32a11.37,11.37,0,0,1,0-2.54,11.08,11.08,0,0,0,.28,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.8,29.69a11.38,11.38,0,0,1-.06-2.54,11.61,11.61,0,0,0,.28,2.5.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.82,28.37a11.9,11.9,0,0,1-.06-2.53,11.53,11.53,0,0,0,.29,2.49.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.74,26.89a11.9,11.9,0,0,1-.06-2.53A11.52,11.52,0,0,0,48,26.85a.11.11,0,0,1-.08.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.23,26.23a12,12,0,0,1-.06-2.54,11.61,11.61,0,0,0,.28,2.5.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,25.57A12,12,0,0,1,47.89,23a11.68,11.68,0,0,0,.28,2.5.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.38,27.74a11.9,11.9,0,0,1-.06-2.53,11.52,11.52,0,0,0,.28,2.49.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.51,29.78a12,12,0,0,1,0-2.54,11.54,11.54,0,0,0,.28,2.5.12.12,0,0,1-.23.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.67,32a12,12,0,0,1,0-2.54,11.65,11.65,0,0,0,.25,2.5.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.15,34.35a11.25,11.25,0,0,1,.28-2.52,11.11,11.11,0,0,0-.06,2.51.11.11,0,0,1-.1.13.11.11,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.54,38.26a11.66,11.66,0,0,1,1-2.33,11.29,11.29,0,0,0-.77,2.39.13.13,0,0,1-.14.09.12.12,0,0,1-.09-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,41.68a11.57,11.57,0,0,1,1.39-2.12,11.18,11.18,0,0,0-1.18,2.21.12.12,0,0,1-.15.07.11.11,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.13,44.51a11.21,11.21,0,0,1,1.57-2,12,12,0,0,0-1.38,2.09.1.1,0,0,1-.15.05.11.11,0,0,1-.05-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.87,48.84A11.23,11.23,0,0,1,57.61,47a11.36,11.36,0,0,0-1.56,2,.1.1,0,0,1-.15,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.32,50.07a11.35,11.35,0,0,1,1.79-1.81,11.39,11.39,0,0,0-1.6,1.94.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.56,51.07a11.29,11.29,0,0,1,1.8-1.79,11.86,11.86,0,0,0-1.62,1.92.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.45,50.28a11.67,11.67,0,0,1,1.77-1.81,11.27,11.27,0,0,0-1.58,1.94.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.27,48.55A11.28,11.28,0,0,1,56,46.68a11.65,11.65,0,0,0-1.52,2,.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.3,47.11a11.61,11.61,0,0,1,1.6-2,11.2,11.2,0,0,0-1.41,2.08.11.11,0,0,1-.15,0,.11.11,0,0,1-.05-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.3,44.89a11.26,11.26,0,0,1,1.46-2.08A11,11,0,0,0,50.51,45a.13.13,0,0,1-.15.06.11.11,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.39,42.34a11.39,11.39,0,0,1,1.22-2.22,10.9,10.9,0,0,0-1,2.3.11.11,0,0,1-.14.07.11.11,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.17,39.28a11.16,11.16,0,0,1,.72-2.43,11,11,0,0,0-.49,2.46.12.12,0,0,1-.13.1.11.11,0,0,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,37.14a11.67,11.67,0,0,1,.34-2.51,11,11,0,0,0-.11,2.5.11.11,0,0,1-.11.12.11.11,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.91,34.57a11.28,11.28,0,0,1,0-2.53,10.92,10.92,0,0,0,.27,2.49.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.35,31.88a11.28,11.28,0,0,1-.08-2.53,11.54,11.54,0,0,0,.3,2.49.12.12,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.09,29.54A11.39,11.39,0,0,1,45,27a11.54,11.54,0,0,0,.3,2.49.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.23,27.14a12,12,0,0,1-.08-2.54,11.46,11.46,0,0,0,.3,2.49.11.11,0,0,1-.08.14.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.7,25.49A11.9,11.9,0,0,1,43.63,23a11.54,11.54,0,0,0,.3,2.49.12.12,0,0,1-.23.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.62,26.15a10.73,10.73,0,0,1-.09-2.53,11.54,11.54,0,0,0,.31,2.49.12.12,0,0,1-.08.14.11.11,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.5,27.45a11.29,11.29,0,0,1-.1-2.53,12.22,12.22,0,0,0,.32,2.49.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.74,29.27a11.91,11.91,0,0,1-.09-2.53A11,11,0,0,0,44,29.23a.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.77,34.58a11.9,11.9,0,0,1-.06-2.53A10.93,10.93,0,0,0,47,34.54a.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.8,40a12,12,0,0,1,1-2.34A11.9,11.9,0,0,0,50,40a.11.11,0,0,1-.13.09A.13.13,0,0,1,49.8,40Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.69,43.91a11.87,11.87,0,0,1,1.44-2.08A11.77,11.77,0,0,0,51.89,44a.11.11,0,0,1-.15.06.11.11,0,0,1-.05-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.29,48.54A11.66,11.66,0,0,1,54,46.64a11.7,11.7,0,0,0-1.49,2,.12.12,0,0,1-.16.05.13.13,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.86,52.09a11.71,11.71,0,0,1,1.81-1.78,11,11,0,0,0-1.62,1.92.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.67,53.44a11.2,11.2,0,0,1,1.84-1.74,11.41,11.41,0,0,0-1.66,1.88.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.05,55.46a11.26,11.26,0,0,1,1.87-1.72,11,11,0,0,0-1.69,1.86.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.86,57.61a10.83,10.83,0,0,1,1.88-1.7A11.45,11.45,0,0,0,59,57.75a.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,59.55a11.27,11.27,0,0,1,1.89-1.7,11.45,11.45,0,0,0-1.71,1.84.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.48,63.35a11.65,11.65,0,0,1,1.87-1.71,11.46,11.46,0,0,0-1.69,1.86.11.11,0,1,1-.18-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.23,65.67a11.29,11.29,0,0,1,1.85-1.74,11.42,11.42,0,0,0-1.67,1.88.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.76,68.54a12.17,12.17,0,0,1,1.82-1.77A10.92,10.92,0,0,0,63,68.67a.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.87,70.44a12.12,12.12,0,0,1,1.79-1.79,11.87,11.87,0,0,0-1.61,1.93.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.93,71.86a11.29,11.29,0,0,1,1.78-1.81A11.39,11.39,0,0,0,64.11,72,.1.1,0,0,1,64,72a.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.13,71.38a11.73,11.73,0,0,1,1.78-1.81,11.33,11.33,0,0,0-1.59,1.94.11.11,0,0,1-.16,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.92,71a11.59,11.59,0,0,1,1.78-1.79,11.4,11.4,0,0,0-1.6,1.93.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.79,68.94a11.66,11.66,0,0,1,1.81-1.77A11.36,11.36,0,0,0,60,69.08a.12.12,0,0,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.64,65.06a11.2,11.2,0,0,1,1.85-1.73,11,11,0,0,0-1.67,1.87.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.84,61.86a11.14,11.14,0,0,1,1.87-1.7A11.49,11.49,0,0,0,58,62a.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,58.06a11.61,11.61,0,0,1,1.88-1.7,11,11,0,0,0-1.7,1.84.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,55.35a11.67,11.67,0,0,1,1.85-1.73,11.89,11.89,0,0,0-1.68,1.87.1.1,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.7,51.27a11.23,11.23,0,0,1,1.77-1.81,11.33,11.33,0,0,0-1.59,1.94.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.1,45.49a12.13,12.13,0,0,1,1.59-2,11.17,11.17,0,0,0-1.39,2.09.11.11,0,0,1-.15,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.59,39.78a11.39,11.39,0,0,1,1.16-2.25,11.07,11.07,0,0,0-.94,2.32.12.12,0,0,1-.15.08.11.11,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.47,30.66a11.27,11.27,0,0,1,0-2.53,11.64,11.64,0,0,0,.24,2.5.1.1,0,0,1-.08.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.75,24.12a11.27,11.27,0,0,1-.06-2.53A11.52,11.52,0,0,0,52,24.08a.11.11,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.7,20.64a11.27,11.27,0,0,1-.05-2.53,11.51,11.51,0,0,0,.27,2.49.11.11,0,0,1-.08.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.46,20.38a12,12,0,0,1,0-2.54,11.67,11.67,0,0,0,.27,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.84,22.12a11.37,11.37,0,0,1-.05-2.54,11.08,11.08,0,0,0,.28,2.5.12.12,0,0,1-.23.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.55,25.49A11.9,11.9,0,0,1,52.49,23a11.52,11.52,0,0,0,.28,2.49.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.71,31.78a11.35,11.35,0,0,1,0-2.54,11,11,0,0,0,.2,2.5.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.57,37.86a11.48,11.48,0,0,1,1-2.33,11.35,11.35,0,0,0-.79,2.39.11.11,0,0,1-.14.08.1.1,0,0,1-.08-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.46,44.78a11.74,11.74,0,0,1,1.62-2,11.45,11.45,0,0,0-1.42,2.06.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.55,51.45a11.65,11.65,0,0,1,1.8-1.78,11.86,11.86,0,0,0-1.62,1.92.1.1,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.84,56.52a11.64,11.64,0,0,1,1.84-1.74A11,11,0,0,0,68,56.66a.12.12,0,0,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.88,59a11.64,11.64,0,0,1,1.83-1.75,11.76,11.76,0,0,0-1.65,1.88.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.67,60.32a11.29,11.29,0,0,1,1.84-1.75,11.42,11.42,0,0,0-1.66,1.89.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.59,59.14a11.2,11.2,0,0,1,1.84-1.74,11.34,11.34,0,0,0-1.66,1.87.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.78,57.36a11.72,11.72,0,0,1,1.83-1.76A11,11,0,0,0,70,57.5a.12.12,0,1,1-.19-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.77,50.64a11.69,11.69,0,0,1,1.74-1.84,11.3,11.3,0,0,0-1.55,2,.1.1,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.06,40.57a11.42,11.42,0,0,1,1.3-2.18,11.74,11.74,0,0,0-1.09,2.26.1.1,0,0,1-.14.07.11.11,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.22,35.66a11.46,11.46,0,0,1,1-2.34,11.32,11.32,0,0,0-.75,2.4.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.64,33.13a12,12,0,0,1,1-2.32,12.12,12.12,0,0,0-.8,2.38.12.12,0,0,1-.14.09.11.11,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.86,31.92A11.81,11.81,0,0,1,77,29.64,11.34,11.34,0,0,0,76.08,32a.11.11,0,0,1-.14.08.11.11,0,0,1-.08-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.07,31.16A11.11,11.11,0,0,1,79.35,29a11.47,11.47,0,0,0-1.07,2.27.11.11,0,1,1-.21-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78,30a11.67,11.67,0,0,1,1.27-2.19A11.51,11.51,0,0,0,78.24,30a.11.11,0,0,1-.14.07A.12.12,0,0,1,78,30Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.27,32.42A12.31,12.31,0,0,1,78.5,30.2a11.48,11.48,0,0,0-1,2.3.11.11,0,0,1-.14.07.11.11,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.86,35.36a12,12,0,0,1,1.06-2.3,11.15,11.15,0,0,0-.84,2.37.1.1,0,0,1-.13.08.11.11,0,0,1-.09-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.74,39.38A11.72,11.72,0,0,1,70,37.15a11.78,11.78,0,0,0-1,2.31.11.11,0,0,1-.21-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.48,41.52a11.46,11.46,0,0,1,1.4-2.1,11.74,11.74,0,0,0-1.2,2.2.11.11,0,0,1-.15.06.12.12,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.19,41.4a11.42,11.42,0,0,1,1.41-2.11,10.77,10.77,0,0,0-1.2,2.21.12.12,0,0,1-.15.06.11.11,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.79,39.32A11.39,11.39,0,0,1,68,37.09a11.49,11.49,0,0,0-1,2.31.11.11,0,0,1-.14.07.11.11,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.9,32.78a11.9,11.9,0,0,1,.78-2.41,12.24,12.24,0,0,0-.56,2.45.11.11,0,0,1-.12.1.12.12,0,0,1-.1-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.94,24.77a11.59,11.59,0,0,1,1.27-2.19,12,12,0,0,0-1.06,2.27.11.11,0,1,1-.21-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.15,22.33a10.84,10.84,0,0,1,1.19-2.23,10.91,10.91,0,0,0-1,2.31.12.12,0,0,1-.14.08.13.13,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.38,23.73a11,11,0,0,1,1.16-2.26,10.93,10.93,0,0,0-.94,2.33.12.12,0,0,1-.14.08.11.11,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.56,24a11.4,11.4,0,0,1,1-2.32,11.46,11.46,0,0,0-.79,2.38.12.12,0,0,1-.14.09.1.1,0,0,1-.08-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.28,27.35a11.87,11.87,0,0,1,.77-2.41,11.41,11.41,0,0,0-.54,2.45.12.12,0,0,1-.13.1.11.11,0,0,1-.1-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.49,31.23a11.71,11.71,0,0,1,.38-2.51,11.67,11.67,0,0,0-.15,2.5.11.11,0,0,1-.11.12.11.11,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.18,33.54a11.73,11.73,0,0,1,.45-2.49,11.13,11.13,0,0,0-.22,2.5.12.12,0,0,1-.11.12.11.11,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.86,34.7a10.9,10.9,0,0,1,.59-2.46,11.61,11.61,0,0,0-.37,2.48.11.11,0,0,1-.11.11.11.11,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.82,33.43a11.64,11.64,0,0,1,.4-2.5,11.13,11.13,0,0,0-.17,2.51.11.11,0,0,1-.11.11.1.1,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.13,26.09a11.5,11.5,0,0,1,.65-2.45,11,11,0,0,0-.42,2.48.11.11,0,0,1-.12.1.11.11,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,22.57a11.37,11.37,0,0,1,.66-2.45,11,11,0,0,0-.43,2.47.11.11,0,0,1-.12.11.12.12,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.16,22.7a11.92,11.92,0,0,1,.68-2.44,11.66,11.66,0,0,0-.45,2.47.11.11,0,0,1-.13.1.11.11,0,0,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,21.46A11.21,11.21,0,0,1,72.67,19a11,11,0,0,0-.43,2.47.13.13,0,0,1-.12.11c-.07,0-.12-.06-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,20.25a11.39,11.39,0,0,1,.58-2.47,11.07,11.07,0,0,0-.35,2.48.11.11,0,0,1-.12.11.11.11,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.91,21.42a11.67,11.67,0,0,1,.42-2.5,11,11,0,0,0-.19,2.5.13.13,0,0,1-.11.12.11.11,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.39,26.31a11.21,11.21,0,0,1,.23-2.52,11.09,11.09,0,0,0,0,2.51.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.86,29.38a11.9,11.9,0,0,1,.21-2.53,11.08,11.08,0,0,0,0,2.51.12.12,0,0,1-.1.13.11.11,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.44,29.63a11.25,11.25,0,0,1,.15-2.53,11.68,11.68,0,0,0,.07,2.51.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.61,27.69a11.88,11.88,0,0,1,.17-2.53,11.7,11.7,0,0,0,.06,2.51.11.11,0,0,1-.1.12.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.72,19a11.24,11.24,0,0,1,.1-2.53A11.71,11.71,0,0,0,65.94,19a.11.11,0,0,1-.09.13.11.11,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.22,12.94a12,12,0,0,1,0-2.54,11.62,11.62,0,0,0,.21,2.5.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.29,10.14a11.89,11.89,0,0,1,0-2.53,11.63,11.63,0,0,0,.22,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.22,8.41a11.89,11.89,0,0,1,0-2.53,11,11,0,0,0,.25,2.5.11.11,0,0,1-.09.13.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.83,10.38a11.89,11.89,0,0,1,0-2.53,10.91,10.91,0,0,0,.25,2.49.12.12,0,0,1-.09.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.65,10a12,12,0,0,1,0-2.54,11.65,11.65,0,0,0,.25,2.5.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.65,13.24a10.71,10.71,0,0,1,0-2.53,11,11,0,0,0,.25,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,15.39a11.28,11.28,0,0,1,0-2.53,12.16,12.16,0,0,0,.26,2.49.11.11,0,0,1-.08.14A.12.12,0,0,1,55,15.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.54,17.46a11.37,11.37,0,0,1,0-2.54,11.67,11.67,0,0,0,.27,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.1,10.85a11.27,11.27,0,0,1,0-2.53,10.91,10.91,0,0,0,.25,2.49.12.12,0,0,1-.09.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.16,7.88a11.36,11.36,0,0,1,0-2.54,11.64,11.64,0,0,0,.24,2.5A.1.1,0,0,1,59.3,8a.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.09,5a11.27,11.27,0,0,1,0-2.53A11.64,11.64,0,0,0,62.31,5a.1.1,0,0,1-.08.13A.12.12,0,0,1,62.09,5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.42,6.42a11.36,11.36,0,0,1,0-2.54,10.94,10.94,0,0,0,.25,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.42,7.84a11.89,11.89,0,0,1,0-2.53,11.65,11.65,0,0,0,.25,2.5.1.1,0,0,1-.09.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.93,15.31a11.89,11.89,0,0,1,0-2.53,11.66,11.66,0,0,0,.26,2.5.12.12,0,0,1-.23.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.19,17.14a11.89,11.89,0,0,1,0-2.53,11.64,11.64,0,0,0,.23,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.64,19a11.89,11.89,0,0,1,0-2.53,11.61,11.61,0,0,0,.18,2.5.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.6,18.43a11.87,11.87,0,0,1,0-2.53,11,11,0,0,0,.2,2.5.11.11,0,0,1-.09.13.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.42,16.33a11.27,11.27,0,0,1,0-2.53,11.62,11.62,0,0,0,.21,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.74,17.68a12,12,0,0,1,0-2.54A11.65,11.65,0,0,0,65,17.65a.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,15.5A11.25,11.25,0,0,1,65.1,13a11.59,11.59,0,0,0,.15,2.5.11.11,0,0,1-.09.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.76,16.47a10.69,10.69,0,0,1,.06-2.53,11.6,11.6,0,0,0,.16,2.5.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.72,21.16a11.9,11.9,0,0,1,.06-2.53,11,11,0,0,0,.17,2.5.12.12,0,0,1-.09.14.11.11,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.5,24.2a10.7,10.7,0,0,1,0-2.53,11.61,11.61,0,0,0,.19,2.5.11.11,0,0,1-.09.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.11,25.52a11.89,11.89,0,0,1,0-2.53,10.89,10.89,0,0,0,.23,2.49.12.12,0,0,1-.09.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.25,24.85a11.27,11.27,0,0,1,0-2.53,11.62,11.62,0,0,0,.21,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.29,23.68a11.89,11.89,0,0,1,0-2.53,11.64,11.64,0,0,0,.24,2.5.11.11,0,0,1-.09.13.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.51,22.66a11.89,11.89,0,0,1,0-2.53,11,11,0,0,0,.23,2.5.11.11,0,0,1-.09.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.77,21.55a12,12,0,0,1,0-2.54,11.53,11.53,0,0,0,.25,2.5.12.12,0,1,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.9,19.42a11.27,11.27,0,0,1,0-2.53,11.06,11.06,0,0,0,.26,2.5.1.1,0,0,1-.08.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.4,15.83a11.28,11.28,0,0,1,0-2.53,11.5,11.5,0,0,0,.26,2.49.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.91,16a11.27,11.27,0,0,1,0-2.53,11.66,11.66,0,0,0,.26,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.27,13.92a11.89,11.89,0,0,1,0-2.53,11.65,11.65,0,0,0,.25,2.5.1.1,0,0,1-.08.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.61,15a11.89,11.89,0,0,1,0-2.53A10.91,10.91,0,0,0,52.84,15a.12.12,0,0,1-.09.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.12,26.74a12,12,0,0,1,.05-2.54,11.7,11.7,0,0,0,.18,2.51.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.93,36.12a11.12,11.12,0,0,1,.84-2.39,11,11,0,0,0-.61,2.43.11.11,0,0,1-.13.1.11.11,0,0,1-.1-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.44,42a11.89,11.89,0,0,1,1.4-2.11,11,11,0,0,0-1.19,2.21.11.11,0,0,1-.21-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.68,44.58a11.13,11.13,0,0,1,1.52-2,11.45,11.45,0,0,0-1.32,2.13.11.11,0,0,1-.15.05.11.11,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.15,46.53a11.22,11.22,0,0,1,1.55-2,11.52,11.52,0,0,0-1.35,2.12.12.12,0,0,1-.15,0,.12.12,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.87,47.86a10.91,10.91,0,0,1,1.61-2A11.54,11.54,0,0,0,74.07,48a.12.12,0,0,1-.16.05.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.19,47.82a11.66,11.66,0,0,1,1.57-2,12,12,0,0,0-1.37,2.1.11.11,0,0,1-.15,0,.1.1,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.83,41.93a11,11,0,0,1,1.36-2.13A11.41,11.41,0,0,0,75,42a.12.12,0,0,1-.15.07.12.12,0,0,1-.07-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.17,29.84A11.34,11.34,0,0,1,73,27.43a11,11,0,0,0-.56,2.45.12.12,0,0,1-.13.1.11.11,0,0,1-.1-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.33,18.87a11.77,11.77,0,0,1,.24-2.52,11.09,11.09,0,0,0,0,2.51.11.11,0,0,1-.1.12.12.12,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.69,14.32a12,12,0,0,1,0-2.54,11.52,11.52,0,0,0,.23,2.5.12.12,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,5.67a11.27,11.27,0,0,1,0-2.53,11,11,0,0,0,.23,2.5.11.11,0,0,1-.09.13A.11.11,0,0,1,57,5.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.52,4a11.27,11.27,0,0,1,0-2.53,11,11,0,0,0,.21,2.5.11.11,0,0,1-.09.13A.1.1,0,0,1,47.52,4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.3,3.4a11.89,11.89,0,0,1,0-2.53,11.64,11.64,0,0,0,.24,2.5.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.9,4.85a11.9,11.9,0,0,1,0-2.53,11.5,11.5,0,0,0,.25,2.49A.11.11,0,0,1,37,5a.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.22,8.77a12,12,0,0,1-.09-2.54,10.89,10.89,0,0,0,.32,2.49.12.12,0,0,1-.09.14.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.26,8.5A11.89,11.89,0,0,1,25.89,6a11.75,11.75,0,0,0,.59,2.44.12.12,0,0,1-.07.15.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.66,12.06A11.41,11.41,0,0,1,19.52,9.8a11.32,11.32,0,0,0,1.32,2.13.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.12,14.65a11.39,11.39,0,0,1-1.36-2.14,11.44,11.44,0,0,0,1.53,2,.11.11,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.23,19.49a11,11,0,0,1-1.5-2,11,11,0,0,0,1.66,1.88.12.12,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.64,22.47a11.13,11.13,0,0,1-1.6-2,11.47,11.47,0,0,0,1.75,1.8.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.66,26.09a11.54,11.54,0,0,1-1.58-2,11,11,0,0,0,1.73,1.81.11.11,0,0,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,29.45a11.14,11.14,0,0,1-1.53-2,11.5,11.5,0,0,0,1.69,1.86.12.12,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,31a11.08,11.08,0,0,1-1.48-2.06,11,11,0,0,0,1.64,1.9A.12.12,0,0,1,10,31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9,31.59A11.05,11.05,0,0,1,7.5,29.52a11.45,11.45,0,0,0,1.62,1.91.12.12,0,0,1,0,.17.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.7,28.63a11,11,0,0,1-1.55-2,11.42,11.42,0,0,0,1.7,1.84.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.88,27.19a11.54,11.54,0,0,1-1.58-2A11.47,11.47,0,0,0,10,27a.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.17,24.89a11.61,11.61,0,0,1-1.6-2,11.86,11.86,0,0,0,1.75,1.79.11.11,0,1,1-.14.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.37,20.92a11.51,11.51,0,0,1-1.59-2,11.47,11.47,0,0,0,1.74,1.81.1.1,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.77,19.58a11,11,0,0,1-1.54-2,11.86,11.86,0,0,0,1.7,1.84.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.48,18A11.56,11.56,0,0,1,14,16a11.94,11.94,0,0,0,1.68,1.87.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.49,17.08A11.53,11.53,0,0,1,16.06,15a11.09,11.09,0,0,0,1.6,1.94.12.12,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.57,22.05a11.37,11.37,0,0,1-1.52-2,11,11,0,0,0,1.68,1.86.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.37,30.38a11.73,11.73,0,0,1-1.47-2.06,11.36,11.36,0,0,0,1.63,1.9.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.67,33.74a11.07,11.07,0,0,1-1.39-2.12,11.09,11.09,0,0,0,1.56,2,.12.12,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.2,35.09a11.42,11.42,0,0,1-1.35-2.15,12.06,12.06,0,0,0,1.52,2,.11.11,0,1,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.61,34.72a11.39,11.39,0,0,1-1.36-2.14,11.44,11.44,0,0,0,1.53,2,.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,32.31a11.4,11.4,0,0,1-1.42-2.1,11,11,0,0,0,1.59,1.94.11.11,0,1,1-.16.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.84,18.36a11.34,11.34,0,0,1-1.38-2.12,10.94,10.94,0,0,0,1.55,2,.12.12,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.58,8.31A11.31,11.31,0,0,1,21.8,5.9a11.21,11.21,0,0,0,1,2.31.12.12,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.2,6.34A11.33,11.33,0,0,1,28,3.81a11.53,11.53,0,0,0,.38,2.48.11.11,0,0,1-.08.14.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.52,8.65a11.33,11.33,0,0,1-.16-2.53,10.94,10.94,0,0,0,.38,2.48.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.1,7A11.91,11.91,0,0,1,30,4.5,11,11,0,0,0,30.33,7a.12.12,0,1,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.18,7.43a11.29,11.29,0,0,1-.43-2.5,11.22,11.22,0,0,0,.64,2.42.11.11,0,0,1-.06.15.12.12,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.41,24.92A11.68,11.68,0,0,1,18,22.8a11.8,11.8,0,0,0,1.55,2,.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.9,36.73a11.24,11.24,0,0,1-1.29-2.19,11.45,11.45,0,0,0,1.46,2,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14,40.32a11,11,0,0,1-1.23-2.22,10.88,10.88,0,0,0,1.41,2.08.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.11,40a10.88,10.88,0,0,1-1.24-2.2,10.78,10.78,0,0,0,1.42,2.06.11.11,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.09,38.53a12,12,0,0,1-1.26-2.2,11.88,11.88,0,0,0,1.44,2.06.12.12,0,0,1-.18.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.48,26.9a11.43,11.43,0,0,1-1.25-2.2,11.31,11.31,0,0,0,1.43,2.06.11.11,0,1,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.22,20.14a11.17,11.17,0,0,1-1-2.32A11.19,11.19,0,0,0,24.42,20a.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.67,18a11.5,11.5,0,0,1-.81-2.4,11.48,11.48,0,0,0,1,2.3.1.1,0,0,1,0,.15.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.9,15.83a11.73,11.73,0,0,1-.58-2.46,11.08,11.08,0,0,0,.79,2.38.11.11,0,0,1-.05.15.11.11,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.77,14.12a10.71,10.71,0,0,1-.36-2.51A11.11,11.11,0,0,0,29,14.05a.11.11,0,0,1-.06.15.12.12,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.09,15.14a10.77,10.77,0,0,1-.45-2.5,11,11,0,0,0,.66,2.42.11.11,0,0,1-.21.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.55,22a11.64,11.64,0,0,1-1-2.31,12,12,0,0,0,1.22,2.19.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.46,28.92a11.48,11.48,0,0,1-1.26-2.2,11.36,11.36,0,0,0,1.44,2.06.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.45,31.49a11.68,11.68,0,0,1-1.32-2.16,11.83,11.83,0,0,0,1.49,2,.12.12,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.52,33.23a11.74,11.74,0,0,1-1.3-2.18,11.43,11.43,0,0,0,1.47,2,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.94,31.56a11.67,11.67,0,0,1-1.27-2.19,10.55,10.55,0,0,0,1.45,2.05.11.11,0,1,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.33,24.05a11.7,11.7,0,0,1-1-2.34,11.23,11.23,0,0,0,1.18,2.22.12.12,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.27,19.79a11.47,11.47,0,0,1-.8-2.4,12,12,0,0,0,1,2.3.1.1,0,0,1,0,.15.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.21,17.25a12.39,12.39,0,0,1-.5-2.48,10.91,10.91,0,0,0,.72,2.4.11.11,0,0,1-.06.15.11.11,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.53,11.91a12,12,0,0,1-.15-2.53,11.52,11.52,0,0,0,.37,2.48.11.11,0,1,1-.22.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.91,9.67a12,12,0,0,1,0-2.54,11.6,11.6,0,0,0,.27,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.79,7.6a11.28,11.28,0,0,1,0-2.53A10.91,10.91,0,0,0,38,7.56a.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.83,5.39a11.27,11.27,0,0,1,0-2.53,11.64,11.64,0,0,0,.24,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.54,3.77a11.26,11.26,0,0,1,0-2.53,11,11,0,0,0,.22,2.5.11.11,0,0,1-.09.13.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.66,3.17a12,12,0,0,1,0-2.54,11.62,11.62,0,0,0,.21,2.5.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.19,3.37a11.89,11.89,0,0,1,0-2.53,11.64,11.64,0,0,0,.23,2.5.11.11,0,0,1-.09.13.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.85,3.87a11.27,11.27,0,0,1,0-2.53,11.49,11.49,0,0,0,.24,2.49A.12.12,0,0,1,60,4a.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.25,10.9a10.71,10.71,0,0,1,0-2.53,11.5,11.5,0,0,0,.25,2.49.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.68,13.67a11.28,11.28,0,0,1,0-2.53,11.5,11.5,0,0,0,.26,2.49.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.28,5.84a12,12,0,0,1,0-2.54,10.94,10.94,0,0,0,.25,2.5.12.12,0,1,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.1,7.42a11.89,11.89,0,0,1,0-2.53,11,11,0,0,0,.23,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.18,9.38a11.27,11.27,0,0,1,0-2.53,11.64,11.64,0,0,0,.24,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.7,10.05a12,12,0,0,1,0-2.54,11.53,11.53,0,0,0,.25,2.5.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.32,11a11.27,11.27,0,0,1,0-2.53,11.65,11.65,0,0,0,.25,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.39,11.16a11.27,11.27,0,0,1,0-2.53,11.64,11.64,0,0,0,.24,2.5.1.1,0,0,1-.08.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.64,13.08a11.27,11.27,0,0,1,0-2.53,11.65,11.65,0,0,0,.25,2.5.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.83,17.23a11.28,11.28,0,0,1,0-2.53,11.5,11.5,0,0,0,.26,2.49.11.11,0,0,1-.08.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.16,17.07a11.27,11.27,0,0,1,0-2.53,11.06,11.06,0,0,0,.26,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.46,19.2a11.89,11.89,0,0,1,0-2.53,11.5,11.5,0,0,0,.25,2.49.12.12,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.85,18.35a11.27,11.27,0,0,1,0-2.53,12.34,12.34,0,0,0,.26,2.5.1.1,0,0,1-.08.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,10.68a11.27,11.27,0,0,1,0-2.53,11.49,11.49,0,0,0,.24,2.49.11.11,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.77,8.34a11.36,11.36,0,0,1,0-2.54A11,11,0,0,0,47,8.3a.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.83,9.4a12,12,0,0,1,0-2.54,11.52,11.52,0,0,0,.24,2.5A.13.13,0,0,1,49,9.5a.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.76,17.2a11.27,11.27,0,0,1,0-2.53A11.5,11.5,0,0,0,60,17.16a.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.65,27.09a11.53,11.53,0,0,1,.47-2.49,11.61,11.61,0,0,0-.24,2.49.11.11,0,0,1-.11.12.12.12,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.49,30.54a11.14,11.14,0,0,1,1.16-2.25,11.51,11.51,0,0,0-1,2.33.1.1,0,0,1-.14.07.12.12,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.49,33.38a11.43,11.43,0,0,1,1.32-2.17,12.06,12.06,0,0,0-1.11,2.25.11.11,0,0,1-.21-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.44,34.88a11.93,11.93,0,0,1,1.31-2.17A11.24,11.24,0,0,0,79.65,35a.11.11,0,0,1-.14.07.12.12,0,0,1-.07-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.39,27.7a12.25,12.25,0,0,1,1-2.34,11.27,11.27,0,0,0-.73,2.4.12.12,0,1,1-.23-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.49,13.54a11.89,11.89,0,0,1,0-2.53,10.91,10.91,0,0,0,.26,2.49.12.12,0,0,1-.09.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.52,10.62a11.36,11.36,0,0,1,0-2.54,11.58,11.58,0,0,0,.24,2.5.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.38,12.05a12,12,0,0,1,0-2.54A11.65,11.65,0,0,0,58.6,12a.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.61,18.73a11.72,11.72,0,0,1,.46-2.5,11.77,11.77,0,0,0-.24,2.5.1.1,0,0,1-.11.12.11.11,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.55,20.37a11.9,11.9,0,0,1,1.25-2.2,10.85,10.85,0,0,0-1,2.28.12.12,0,0,1-.15.07.1.1,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.32,18.85a11.93,11.93,0,0,1,1.31-2.17,11.43,11.43,0,0,0-1.1,2.25.11.11,0,1,1-.21-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.77,21a11.45,11.45,0,0,1,1-2.35A11.44,11.44,0,0,0,76,21a.11.11,0,0,1-.14.09.11.11,0,0,1-.09-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,12.52A11.87,11.87,0,0,1,67,10a11,11,0,0,0,.17,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.16,11.87a10.71,10.71,0,0,1,0-2.53,11.5,11.5,0,0,0,.25,2.49.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.17,13.78a11.36,11.36,0,0,1,0-2.54,11.64,11.64,0,0,0,.24,2.5.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.05,11.88a11.9,11.9,0,0,1,.21-2.53,11.08,11.08,0,0,0,0,2.51.12.12,0,0,1-.1.13.13.13,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.17,12.26a11.94,11.94,0,0,1,.76-2.42,11.52,11.52,0,0,0-.53,2.45.12.12,0,0,1-.13.11.13.13,0,0,1-.1-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.46,10.88a11.4,11.4,0,0,1,1-2.32,11.66,11.66,0,0,0-.79,2.38.11.11,0,0,1-.13.09.12.12,0,0,1-.09-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.28,11.46A11.44,11.44,0,0,1,81.2,9.1a12.07,12.07,0,0,0-.7,2.41.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.63,13.78a11.25,11.25,0,0,1,.14-2.53,11.7,11.7,0,0,0,.09,2.51.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.15,9.21a11.27,11.27,0,0,1,0-2.53,11,11,0,0,0,.23,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.52,11.24a11.27,11.27,0,0,1-.05-2.53,11.51,11.51,0,0,0,.27,2.49.11.11,0,0,1-.08.14.11.11,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.43,13.18a11.82,11.82,0,0,1-.54-2.48,11.73,11.73,0,0,0,.75,2.4.11.11,0,0,1-.06.15.12.12,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.13,15.1a11.14,11.14,0,0,1-1.25-2.2A11.31,11.31,0,0,0,20.31,15a.11.11,0,1,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.48,16A12,12,0,0,1,15,13.89a11.52,11.52,0,0,0,1.62,1.92.11.11,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.51,18.87a11.94,11.94,0,0,1-1.58-2,11.4,11.4,0,0,0,1.73,1.81.11.11,0,1,1-.14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.35,23.84a11.17,11.17,0,0,1-1.64-1.93A11.46,11.46,0,0,0,9.5,23.67a.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6,30.69a11.41,11.41,0,0,1-1.49-2.05A11.4,11.4,0,0,0,6.2,30.52a.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.91,33.73A12.26,12.26,0,0,1,4.54,31.6a11.81,11.81,0,0,0,1.54,2,.1.1,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.42,35A11.68,11.68,0,0,1,5.1,32.83a11.89,11.89,0,0,0,1.5,2,.11.11,0,0,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.19,36.22A12,12,0,0,1,4.93,34a11.88,11.88,0,0,0,1.44,2.06.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.14,30.2a11.58,11.58,0,0,1-1.51-2A11.52,11.52,0,0,0,8.3,30a.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.47,23.74a12,12,0,0,1-1.55-2,11.48,11.48,0,0,0,1.71,1.84.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.46,17.54a11.17,11.17,0,0,1-1.29-2.18,11,11,0,0,0,1.46,2,.11.11,0,0,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.38,11.94a11.61,11.61,0,0,1-.87-2.39,11,11,0,0,0,1.07,2.28.12.12,0,0,1-.2.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.7,11.91A11.07,11.07,0,0,1,24,9.47a11.4,11.4,0,0,0,.89,2.34.1.1,0,0,1-.05.15.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.57,13.77a11,11,0,0,1-.92-2.36,11.4,11.4,0,0,0,1.11,2.24.11.11,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.75,23.64a10.94,10.94,0,0,1-1.41-2.11,11,11,0,0,0,1.57,1.95.11.11,0,0,1-.16.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.23,26.22a11,11,0,0,1-1.45-2.07,11.44,11.44,0,0,0,1.62,1.92.11.11,0,0,1-.16.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.36,27.37a11.35,11.35,0,0,1-1.44-2.08,11.4,11.4,0,0,0,1.6,1.93.1.1,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.13,89.37a11.61,11.61,0,0,1,1.6-2,10.68,10.68,0,0,0-1.4,2.08.13.13,0,0,1-.16.05.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.85,89.27a12.09,12.09,0,0,1,1.48-2.06,12,12,0,0,0-1.28,2.16.11.11,0,0,1-.15.06.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.68,92a11.27,11.27,0,0,1,1.43-2.1,11.21,11.21,0,0,0-1.22,2.19.11.11,0,0,1-.15.06.1.1,0,0,1-.06-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.62,94a11.11,11.11,0,0,1,1.28-2.19,11.47,11.47,0,0,0-1.07,2.27.11.11,0,1,1-.21-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.33,96.77a11.75,11.75,0,0,1,.89-2.38,12.26,12.26,0,0,0-.67,2.42.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,98.89a11.69,11.69,0,0,1,.59-2.47,11.69,11.69,0,0,0-.36,2.49.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.25,101.87a11.23,11.23,0,0,1,.26-2.52,11.1,11.1,0,0,0,0,2.51.11.11,0,0,1-.1.13.11.11,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.87,104.75a11.35,11.35,0,0,1,.06-2.54,11.69,11.69,0,0,0,.16,2.51.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.46,108.37a11.89,11.89,0,0,1,0-2.53,11.65,11.65,0,0,0,.25,2.5.1.1,0,0,1-.08.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.72,109.66a10.71,10.71,0,0,1-.06-2.53,11.52,11.52,0,0,0,.28,2.49.12.12,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,108.44a11.9,11.9,0,0,1-.06-2.53,10.93,10.93,0,0,0,.29,2.49.12.12,0,0,1-.09.14.12.12,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.26,106.73a11.36,11.36,0,0,1,0-2.54,11,11,0,0,0,.25,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.1,99.64a11.17,11.17,0,0,1,.25-2.52,11.61,11.61,0,0,0,0,2.5.11.11,0,0,1-.1.13.11.11,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.84,96a11.62,11.62,0,0,1,.51-2.48,11.08,11.08,0,0,0-.28,2.5.12.12,0,0,1-.12.11.11.11,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.78,91.89a11.13,11.13,0,0,1,.9-2.37A11.47,11.47,0,0,0,50,91.94a.11.11,0,0,1-.13.09.12.12,0,0,1-.1-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.46,87.1a11.13,11.13,0,0,1,1.52-2,11.45,11.45,0,0,0-1.32,2.13.11.11,0,0,1-.15.05.11.11,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.79,83.93a11.19,11.19,0,0,1,1.69-1.88,11.31,11.31,0,0,0-1.5,2,.12.12,0,0,1-.16,0,.13.13,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.35,80.14A10.92,10.92,0,0,1,59,78.21a12.06,12.06,0,0,0-1.46,2,.11.11,0,0,1-.15,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.26,78.87A11.65,11.65,0,0,1,60.94,77a11.25,11.25,0,0,0-1.49,2,.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,77.66a12.13,12.13,0,0,1,1.71-1.87,11.72,11.72,0,0,0-1.52,2,.12.12,0,0,1-.2-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.32,77.13A11.7,11.7,0,0,1,61,75.25a11.74,11.74,0,0,0-1.51,2,.12.12,0,0,1-.16,0,.13.13,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.69,78.26a11.6,11.6,0,0,1,1.67-1.9,11.14,11.14,0,0,0-1.48,2,.12.12,0,0,1-.16.05.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.07,83.57a12.19,12.19,0,0,1,1.65-1.93,11.19,11.19,0,0,0-1.45,2,.13.13,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.05,88.6a11.16,11.16,0,0,1,1.22-2.22,11.48,11.48,0,0,0-1,2.3.11.11,0,0,1-.14.07.12.12,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.82,93.19a11.47,11.47,0,0,1,.93-2.36A11.3,11.3,0,0,0,51,93.24a.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.73,94.13a11.89,11.89,0,0,1,.74-2.42A11.48,11.48,0,0,0,50,94.16a.12.12,0,0,1-.12.11.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.53,90.9a11.44,11.44,0,0,1,1.15-2.26A11.45,11.45,0,0,0,51.75,91a.12.12,0,0,1-.14.08.11.11,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.17,81.63a11.66,11.66,0,0,1,1.57-2,11,11,0,0,0-1.37,2.1.11.11,0,0,1-.15.05.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.27,73.61A10.86,10.86,0,0,1,57,71.74a11.65,11.65,0,0,0-1.52,2,.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.07,69a11.22,11.22,0,0,1,1.8-1.78,11.44,11.44,0,0,0-1.62,1.92.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.7,61.42a10.83,10.83,0,0,1,1.88-1.7,11.45,11.45,0,0,0-1.71,1.84.11.11,0,0,1-.18-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.12,55.86A11.2,11.2,0,0,1,64,54.15,11.39,11.39,0,0,0,62.3,56a.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.93,52.22a11.72,11.72,0,0,1,1.83-1.76,10.62,10.62,0,0,0-1.65,1.9.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.63,46.36a11.69,11.69,0,0,1,1.65-1.92,11.68,11.68,0,0,0-1.46,2,.1.1,0,0,1-.15,0,.11.11,0,0,1-.05-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.38,41.83a12,12,0,0,1,1.34-2.16,11.29,11.29,0,0,0-1.13,2.25.1.1,0,0,1-.14.06.11.11,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.75,39.53a11.72,11.72,0,0,1,1.06-2.3A11.64,11.64,0,0,0,53,39.59a.1.1,0,0,1-.13.08.11.11,0,0,1-.09-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.72,36.57a11.55,11.55,0,0,1,.54-2.48A11.09,11.09,0,0,0,52,36.58a.12.12,0,0,1-.12.12.13.13,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.92,35.22a11.73,11.73,0,0,1,.31-2.51,11,11,0,0,0-.08,2.5.13.13,0,0,1-.11.13.12.12,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.56,35.77a11.5,11.5,0,0,1,.51-2.48,11.05,11.05,0,0,0-.28,2.49.11.11,0,0,1-.12.11.11.11,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.43,35.54A12.2,12.2,0,0,1,56,33.06a11.72,11.72,0,0,0-.32,2.49.11.11,0,0,1-.12.11.11.11,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.16,36.77A11.93,11.93,0,0,1,57,34.36a11.59,11.59,0,0,0-.56,2.45.12.12,0,1,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.82,41a11.26,11.26,0,0,1,1.36-2.14A11.21,11.21,0,0,0,58,41.05a.1.1,0,0,1-.14.06.11.11,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.75,43.93a12.15,12.15,0,0,1,1.58-2A11.45,11.45,0,0,0,60,44a.11.11,0,0,1-.15.05.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.82,49.18a11.67,11.67,0,0,1,1.77-1.81,11,11,0,0,0-1.58,2,.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.37,53.58a11.29,11.29,0,0,1,1.84-1.75,11,11,0,0,0-1.66,1.89.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.66,55.16a11.23,11.23,0,0,1,1.85-1.73,11,11,0,0,0-1.67,1.87.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.41,53.72A11.22,11.22,0,0,1,68.24,52a11.83,11.83,0,0,0-1.65,1.89.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.47,48.88A11.67,11.67,0,0,1,68.2,47a11.63,11.63,0,0,0-1.54,2,.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.68,45.08a11.68,11.68,0,0,1,1.6-2,11.06,11.06,0,0,0-1.4,2.09.12.12,0,0,1-.16,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.39,41a11.93,11.93,0,0,1,1.31-2.17,11.24,11.24,0,0,0-1.1,2.26.12.12,0,0,1-.15.06.11.11,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.17,38.52a11.72,11.72,0,0,1,1.19-2.24,11.7,11.7,0,0,0-1,2.31.11.11,0,1,1-.22-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.64,38.15a11.35,11.35,0,0,1,1.23-2.21,11.4,11.4,0,0,0-1,2.29.11.11,0,0,1-.14.08.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.84,40.75a11.93,11.93,0,0,1,1.31-2.17,11.24,11.24,0,0,0-1.1,2.26.12.12,0,0,1-.15.07.11.11,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.68,48.59a11.25,11.25,0,0,1,1.63-1.94,11.36,11.36,0,0,0-1.44,2.06.11.11,0,0,1-.15,0,.11.11,0,0,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.31,52A11.65,11.65,0,0,1,76,50.09a11,11,0,0,0-1.49,2,.11.11,0,0,1-.15,0,.11.11,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.48,53a11.28,11.28,0,0,1,1.71-1.87,11.65,11.65,0,0,0-1.52,2,.11.11,0,0,1-.2-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,50.47a12.09,12.09,0,0,1,1.67-1.9,11.34,11.34,0,0,0-1.48,2,.12.12,0,1,1-.2-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.54,41.67a11.54,11.54,0,0,1,1.4-2.11,11,11,0,0,0-1.19,2.21.11.11,0,1,1-.21-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.38,34.67A11.41,11.41,0,0,1,67,32.21a11.7,11.7,0,0,0-.42,2.48.12.12,0,0,1-.12.11.13.13,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.58,30.47a11.25,11.25,0,0,1,.14-2.53,11.09,11.09,0,0,0,.09,2.51.12.12,0,0,1-.1.13.13.13,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.14,23.21a11.36,11.36,0,0,1,0-2.54,11,11,0,0,0,.26,2.5.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.26,18.5a11.27,11.27,0,0,1,0-2.53,11.51,11.51,0,0,0,.27,2.49.12.12,0,0,1-.09.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.08,17.7a11.9,11.9,0,0,1,0-2.53,11.51,11.51,0,0,0,.27,2.49.12.12,0,0,1-.23.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,20.54A11.27,11.27,0,0,1,54,18a11.51,11.51,0,0,0,.27,2.49.12.12,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.41,27a11.86,11.86,0,0,1,.11-2.53A11.71,11.71,0,0,0,62.64,27a.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.24,33.66a12.18,12.18,0,0,1,.59-2.47,12.38,12.38,0,0,0-.37,2.49.11.11,0,0,1-.11.11c-.07,0-.12-.06-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.65,38.53a11.18,11.18,0,0,1,1.13-2.27,11,11,0,0,0-.92,2.34.11.11,0,0,1-.14.08.12.12,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,42.7a11.51,11.51,0,0,1,1.41-2.1,11.79,11.79,0,0,0-1.21,2.2.11.11,0,0,1-.15.06.12.12,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.82,44.77a11.62,11.62,0,0,1,1.49-2.06A11.27,11.27,0,0,0,75,44.87a.11.11,0,0,1-.15.06.11.11,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,45.7a12.14,12.14,0,0,1,1.55-2,11.12,11.12,0,0,0-1.35,2.11.12.12,0,0,1-.15.06.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.39,43A11.22,11.22,0,0,1,70.87,41a10.93,10.93,0,0,0-1.27,2.16.12.12,0,0,1-.15.06.11.11,0,0,1-.06-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.44,36.49a10.93,10.93,0,0,1,.87-2.38,11.4,11.4,0,0,0-.65,2.42.12.12,0,0,1-.13.1.11.11,0,0,1-.09-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.05,32.18a11.74,11.74,0,0,1,.18-2.52,11.08,11.08,0,0,0,.05,2.51.1.1,0,0,1-.1.12.11.11,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.53,29.35a11.35,11.35,0,0,1,0-2.54,11.66,11.66,0,0,0,.21,2.51.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.49,25.72a11.89,11.89,0,0,1,0-2.53,11.51,11.51,0,0,0,.27,2.49.12.12,0,0,1-.09.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.12,23.65a11.27,11.27,0,0,1,0-2.53,11.51,11.51,0,0,0,.27,2.49.11.11,0,0,1-.08.14.11.11,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.33,22.1a11.27,11.27,0,0,1,0-2.53,11.67,11.67,0,0,0,.27,2.5.1.1,0,0,1-.08.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.87,21.18a11.27,11.27,0,0,1,0-2.53,11.51,11.51,0,0,0,.27,2.49.11.11,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.72,21.11a11.37,11.37,0,0,1,0-2.54,11.67,11.67,0,0,0,.27,2.5.1.1,0,0,1-.08.13.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,22.1a11.31,11.31,0,0,1-.13-2.53,11.5,11.5,0,0,0,.35,2.48.11.11,0,0,1-.08.14.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.06,22.29a11.2,11.2,0,0,1-.31-2.51,11.27,11.27,0,0,0,.53,2.45.12.12,0,0,1-.08.15.11.11,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.41,26.69a11.93,11.93,0,0,1-.82-2.4,11.07,11.07,0,0,0,1,2.3.11.11,0,0,1,0,.15.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.11,30.9a11.64,11.64,0,0,1-1.21-2.22,11,11,0,0,0,1.4,2.08.13.13,0,0,1,0,.17.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.3,35A10.9,10.9,0,0,1,17,32.85a11.86,11.86,0,0,0,1.48,2,.11.11,0,0,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.56,40.49a11,11,0,0,1-1.21-2.23,10.85,10.85,0,0,0,1.39,2.09.12.12,0,0,1-.18.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,43.91a11.75,11.75,0,0,1-1.19-2.24,11.32,11.32,0,0,0,1.37,2.1.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.77,44.73a11.41,11.41,0,0,1-1.16-2.25A11.33,11.33,0,0,0,10,44.6a.11.11,0,0,1-.18.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,41.58a11.45,11.45,0,0,1-1.15-2.26,11.37,11.37,0,0,0,1.33,2.13.11.11,0,0,1-.18.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.69,33.32a11.47,11.47,0,0,1-1.39-2.13,11.09,11.09,0,0,0,1.56,2,.12.12,0,0,1,0,.16.1.1,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.86,30.66a11.43,11.43,0,0,1-1.44-2.09A11.09,11.09,0,0,0,16,30.51a.1.1,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.75,29a11.78,11.78,0,0,1-1.44-2.09,12,12,0,0,0,1.61,1.93.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.7,27.55a11.27,11.27,0,0,1-1.4-2.12,11.44,11.44,0,0,0,1.57,2,.12.12,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.17,26a11.75,11.75,0,0,1-1.35-2.15,11.91,11.91,0,0,0,1.53,2,.11.11,0,1,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.28,24.28A11.23,11.23,0,0,1,21,22.08a11.88,11.88,0,0,0,1.44,2.06.11.11,0,0,1-.17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,22.56a11.34,11.34,0,0,1-.79-2.41,11.28,11.28,0,0,0,1,2.31.11.11,0,0,1,0,.15.11.11,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.06,20.1a11.19,11.19,0,0,1-.37-2.5A11.43,11.43,0,0,0,31.28,20a.11.11,0,0,1-.07.14.12.12,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.89,22a11.24,11.24,0,0,1-.21-2.52,11.41,11.41,0,0,0,.43,2.47A.11.11,0,0,1,35,22a.12.12,0,0,1-.15-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.37,24a11.4,11.4,0,0,1-.23-2.53A11.44,11.44,0,0,0,35.59,24a.11.11,0,0,1-.07.14.1.1,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.82,29.61a11.93,11.93,0,0,1-.34-2.51A12,12,0,0,0,35,29.54a.12.12,0,0,1-.07.15.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.78,33.44a11.88,11.88,0,0,1-.42-2.5A11.15,11.15,0,0,0,34,33.37a.12.12,0,0,1-.07.15.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.7,37.67a11.17,11.17,0,0,1-.47-2.49,11.65,11.65,0,0,0,.68,2.41.11.11,0,0,1-.06.15.11.11,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.73,41.09a11.14,11.14,0,0,1-.5-2.48,11.63,11.63,0,0,0,.71,2.4.11.11,0,0,1-.06.15.11.11,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.4,45.06a11.5,11.5,0,0,1-.72-2.43A11.5,11.5,0,0,0,33.6,45a.11.11,0,0,1,0,.15.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.26,46.46a11.88,11.88,0,0,1-.86-2.38,11.46,11.46,0,0,0,1.06,2.27.11.11,0,0,1,0,.16.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.39,43.75a11.79,11.79,0,0,1-.53-2.48,11.15,11.15,0,0,0,.75,2.4.13.13,0,0,1-.06.15.12.12,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.15,35.18a11.28,11.28,0,0,1-.32-2.51,10.72,10.72,0,0,0,.54,2.45.11.11,0,0,1-.08.14.11.11,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,26.17a11.34,11.34,0,0,1-.17-2.53,11.55,11.55,0,0,0,.39,2.48.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.81,22a11.28,11.28,0,0,1-.08-2.53A11.54,11.54,0,0,0,41,22a.11.11,0,0,1-.08.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,15.74a11.37,11.37,0,0,1,0-2.54,11,11,0,0,0,.26,2.5.11.11,0,0,1-.09.13.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.31,14.56a12,12,0,0,1,0-2.54,11.06,11.06,0,0,0,.26,2.5.12.12,0,0,1-.23.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.3,14.89a11.36,11.36,0,0,1,0-2.54,11.53,11.53,0,0,0,.25,2.5.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.73,14a11.36,11.36,0,0,1,0-2.54A10.94,10.94,0,0,0,48,14a.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.73,24a11.75,11.75,0,0,1,.21-2.52A11.08,11.08,0,0,0,66,24a.11.11,0,0,1-.1.12.11.11,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.71,24.71a11.67,11.67,0,0,1,.42-2.5,11.1,11.1,0,0,0-.19,2.5.11.11,0,0,1-.11.12.12.12,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.71,25.86a11.64,11.64,0,0,1,.52-2.48,11.67,11.67,0,0,0-.29,2.49.11.11,0,0,1-.11.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.46,27.53a11.5,11.5,0,0,1,.61-2.46,11.75,11.75,0,0,0-.39,2.48.1.1,0,0,1-.11.11.1.1,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.45,28.26A11.59,11.59,0,0,1,70,25.79a11,11,0,0,0-.31,2.49.13.13,0,0,1-.12.11.11.11,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.64,25.4A11.09,11.09,0,0,1,68,22.89a11,11,0,0,0-.13,2.5.11.11,0,0,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.56,22.49a12,12,0,0,1,0-2.54,11.65,11.65,0,0,0,.19,2.51.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,18.09a12,12,0,0,1,0-2.54,11.54,11.54,0,0,0,.27,2.5.13.13,0,0,1-.09.14A.11.11,0,0,1,49,18.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.1,17.13a11.28,11.28,0,0,1,0-2.53,10.91,10.91,0,0,0,.26,2.49.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.41,18.36a11.27,11.27,0,0,1,0-2.53,10.91,10.91,0,0,0,.26,2.49.11.11,0,0,1-.08.14.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.44,22.82a11.28,11.28,0,0,1,0-2.53,10.92,10.92,0,0,0,.27,2.49.12.12,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.47,29.19a11.35,11.35,0,0,1,.05-2.54,12.37,12.37,0,0,0,.17,2.51.11.11,0,0,1-.09.13.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.34,33.35a11.59,11.59,0,0,1,.49-2.48,11.65,11.65,0,0,0-.27,2.49.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.72,37.44a12,12,0,0,1,1-2.32,12.12,12.12,0,0,0-.8,2.38.11.11,0,0,1-.13.09.12.12,0,0,1-.09-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.8,39.35A12.22,12.22,0,0,1,74,37.13a11.4,11.4,0,0,0-1,2.3.12.12,0,0,1-.14.08.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.15,34.88A12.08,12.08,0,0,1,73,32.5a11.37,11.37,0,0,0-.64,2.42.12.12,0,0,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.26,24.4a11.93,11.93,0,0,1,.82-2.4,10.86,10.86,0,0,0-.59,2.44.12.12,0,0,1-.13.1.11.11,0,0,1-.1-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.9,20.22a11.69,11.69,0,0,1,.44-2.5,11.73,11.73,0,0,0-.21,2.5.13.13,0,0,1-.11.12.12.12,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,18.3a11.67,11.67,0,0,1,.33-2.51,11,11,0,0,0-.1,2.5.1.1,0,0,1-.1.12.11.11,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.25,16.33a11.91,11.91,0,0,1,.23-2.53,11.7,11.7,0,0,0,0,2.51.12.12,0,0,1-.1.13.13.13,0,0,1-.13-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.14,15.17a11.73,11.73,0,0,1,.31-2.51,11.11,11.11,0,0,0-.08,2.51.12.12,0,1,1-.23,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.34,13a11.67,11.67,0,0,1,.33-2.51,11,11,0,0,0-.1,2.5.11.11,0,0,1-.11.12.11.11,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.32,10.08a12,12,0,0,1,.05-2.54,11.65,11.65,0,0,0,.18,2.51.11.11,0,0,1-.09.13.12.12,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.82,5a11.26,11.26,0,0,1,0-2.53,11,11,0,0,0,.22,2.5.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.53,4.47a11.35,11.35,0,0,1,0-2.54,11,11,0,0,0,.23,2.5.13.13,0,0,1-.09.14.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.23,7.46a10.71,10.71,0,0,1,0-2.53,11.5,11.5,0,0,0,.25,2.49.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.17,6.16a11.17,11.17,0,0,1-.47-2.49,11.92,11.92,0,0,0,.68,2.42.1.1,0,0,1-.06.14.11.11,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.74,8.94a11,11,0,0,1-1.21-2.23A10.85,10.85,0,0,0,17.92,8.8a.12.12,0,0,1,0,.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15,10.44a11.89,11.89,0,0,1-1.4-2.11,11.37,11.37,0,0,0,1.57,1.95.11.11,0,0,1-.16.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.07,13.52a11.59,11.59,0,0,1-1.6-2,11.93,11.93,0,0,0,1.75,1.8.11.11,0,1,1-.14.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.15,16.17a11.22,11.22,0,0,1-1.67-1.91A11.45,11.45,0,0,0,9.29,16a.11.11,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.54,19.31a11.61,11.61,0,0,1-1.68-1.9,11,11,0,0,0,1.82,1.72.11.11,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.78,25.15A11.25,11.25,0,0,1,7.15,23.2,11.52,11.52,0,0,0,8.93,25a.12.12,0,0,1-.15.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.55,28.65a11.42,11.42,0,0,1-1.53-2,11.06,11.06,0,0,0,1.69,1.86.12.12,0,0,1,0,.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.17,28.86a11,11,0,0,1-1.5-2,11.34,11.34,0,0,0,1.66,1.87.11.11,0,0,1-.15.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.92,22.32a11.39,11.39,0,0,1-1.36-2.14,11.44,11.44,0,0,0,1.53,2,.11.11,0,0,1-.16.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.63,10.86a11.82,11.82,0,0,1-.54-2.48,11.54,11.54,0,0,0,.75,2.4.11.11,0,0,1-.06.15.12.12,0,0,1-.15-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.39,9.86a12,12,0,0,1-.08-2.54,11.47,11.47,0,0,0,.31,2.49.11.11,0,0,1-.09.14.1.1,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.63,6.83a11.89,11.89,0,0,1,0-2.53,11.64,11.64,0,0,0,.24,2.5.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.43,5.68a11.88,11.88,0,0,1,0-2.53,11.63,11.63,0,0,0,.22,2.5.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.83,7.1a11.36,11.36,0,0,1,0-2.54,11,11,0,0,0,.23,2.5A.13.13,0,0,1,54,7.2a.11.11,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,10.51A11.27,11.27,0,0,1,59,8a10.91,10.91,0,0,0,.25,2.49.12.12,0,0,1-.09.14.13.13,0,0,1-.14-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.8,15.31a11.27,11.27,0,0,1,.1-2.53A11.71,11.71,0,0,0,67,15.29a.11.11,0,1,1-.22,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.44,17.53A11.26,11.26,0,0,1,67.6,15a11.09,11.09,0,0,0,.07,2.51.13.13,0,0,1-.1.13.12.12,0,0,1-.13-.1Z"/>
      </g>}
      {r_type_hair >= 3 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.62,104.27a7.85,7.85,0,0,0-2.44-.59,7.93,7.93,0,0,1,2.5.45.07.07,0,0,1,0,.09.08.08,0,0,1-.1.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.08,104.52a7.57,7.57,0,0,0-2.43-.62,7.63,7.63,0,0,1,2.49.47.08.08,0,0,1,0,.1.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.57,103.33a8,8,0,0,0-2.46-.52,8,8,0,0,1,2.51.37.08.08,0,0,1,.05.1.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.39,81.71a7.83,7.83,0,0,0-2.5.3,7.83,7.83,0,0,1,2.49-.46.09.09,0,0,1,.09.08.08.08,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.57,81.49a7.76,7.76,0,0,0-2.5.15,7.83,7.83,0,0,1,2.51-.31.08.08,0,0,1,0,.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,81.86a7.57,7.57,0,0,0-2.51.09A7.64,7.64,0,0,1,20,81.7a.09.09,0,0,1,.08.08.08.08,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,83.35a7.5,7.5,0,0,0-2.49.34A7.76,7.76,0,0,1,25,83.2a.08.08,0,0,1,.09.07.08.08,0,0,1-.07.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.76,82.29a7.83,7.83,0,0,0-2.5.22,7.88,7.88,0,0,1,2.51-.38s.07,0,.08.08,0,.07-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.12,82a7.54,7.54,0,0,0-2.5.28,7.8,7.8,0,0,1,2.49-.43.09.09,0,0,1,.09.07.08.08,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.68,83.59a7.49,7.49,0,0,0-2.49.29,7.56,7.56,0,0,1,2.49-.45.08.08,0,0,1,.08.07.09.09,0,0,1-.07.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,82.8a7.78,7.78,0,0,0-2.5.16A7.83,7.83,0,0,1,21,82.65a.08.08,0,0,1,.08.07.08.08,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.24,82.79a7.49,7.49,0,0,0-2.48.36,7.79,7.79,0,0,1,2.48-.52.08.08,0,0,1,.08.07.09.09,0,0,1-.07.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.25,83.5a7.51,7.51,0,0,0-2.5.23,7.83,7.83,0,0,1,2.5-.39.08.08,0,0,1,.08.08.08.08,0,0,1-.07.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.84,81.51a7.46,7.46,0,0,0-2.48.33,7.76,7.76,0,0,1,2.48-.49.08.08,0,0,1,.08.07.08.08,0,0,1-.07.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,80.87a7.78,7.78,0,0,0-2.5.21,7.57,7.57,0,0,1,2.5-.36.08.08,0,1,1,0,.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.33,80.5a7.5,7.5,0,0,0-2.5.08,7.61,7.61,0,0,1,2.52-.23.07.07,0,0,1,.07.08.08.08,0,0,1-.08.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,80.25a7.8,7.8,0,0,0-2.5.25,7.79,7.79,0,0,1,2.5-.4.08.08,0,0,1,.08.07.08.08,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.48,79.64a7.53,7.53,0,0,0-2.5.26,7.79,7.79,0,0,1,2.49-.42.1.1,0,0,1,.09.08.09.09,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.74,79.27a7.57,7.57,0,0,0-2.51.17,7.88,7.88,0,0,1,2.51-.33.09.09,0,0,1,.08.08.08.08,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,79.21a7.55,7.55,0,0,0-2.51.11A7.63,7.63,0,0,1,22,79.05a.08.08,0,0,1,.07.08.08.08,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.11,78.83a7.55,7.55,0,0,0-2.51,0,7.89,7.89,0,0,1,2.52-.18.08.08,0,0,1,.07.09.07.07,0,0,1-.08.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.58,78a7.77,7.77,0,0,0-2.5.18,7.55,7.55,0,0,1,2.5-.33.07.07,0,0,1,.08.07.07.07,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.77,77.48a7.82,7.82,0,0,0-2.51,0,7.89,7.89,0,0,1,2.52-.19.08.08,0,0,1,.07.08.08.08,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.79,77a7.57,7.57,0,0,0-2.51.09,7.38,7.38,0,0,1,2.52-.24.08.08,0,0,1,.08.08.08.08,0,0,1-.08.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.85,76.43a7.82,7.82,0,0,0-2.51-.09,7.41,7.41,0,0,1,2.53-.06.07.07,0,0,1,.07.08.07.07,0,0,1-.08.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.23,76.19a7.55,7.55,0,0,0-2.51.1A7.62,7.62,0,0,1,26.24,76a.08.08,0,0,1,.07.08.07.07,0,0,1-.08.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,75.39a7.56,7.56,0,0,0-2.51-.1A7.67,7.67,0,0,1,21,75.23a.08.08,0,1,1,0,.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.36,75.46a7.55,7.55,0,0,0-2.51,0,7.89,7.89,0,0,1,2.52-.18.1.1,0,0,1,.08.09.09.09,0,0,1-.09.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.6,75.52a7.83,7.83,0,0,0-2.51,0,7.94,7.94,0,0,1,2.53-.13.08.08,0,0,1,0,.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.17,74.76a7.8,7.8,0,0,0-2.5-.21,7.69,7.69,0,0,1,2.53.06.08.08,0,0,1,.06.09.08.08,0,0,1-.09.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.77,74.68a7.83,7.83,0,0,0-2.51,0,7.67,7.67,0,0,1,2.53-.18.08.08,0,0,1,.07.08.07.07,0,0,1-.08.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.56,74.23a7.55,7.55,0,0,0-2.51-.07,7.68,7.68,0,0,1,2.53-.08.08.08,0,0,1,.07.08.07.07,0,0,1-.08.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.39,74.62a7.83,7.83,0,0,0-2.51,0,7.62,7.62,0,0,1,2.52-.15.08.08,0,0,1,.08.08.08.08,0,0,1-.09.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,74.24a7.84,7.84,0,0,0-2.51-.12,8,8,0,0,1,2.53,0,.08.08,0,0,1,.07.09.08.08,0,0,1-.09.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.27,74a7.53,7.53,0,0,0-2.5-.22,8,8,0,0,1,2.53.06.08.08,0,0,1,0,.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.3,73.13A7.56,7.56,0,0,0,22.79,73a7.67,7.67,0,0,1,2.53,0c.05,0,.08,0,.07.09a.08.08,0,0,1-.08.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,72.41a7.76,7.76,0,0,0-2.49-.29,7.69,7.69,0,0,1,2.53.14.08.08,0,0,1,0,.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.73,71.79a7.55,7.55,0,0,0-2.5-.25,7.69,7.69,0,0,1,2.53.1.07.07,0,0,1,.06.09.07.07,0,0,1-.09.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.85,70.48a7.74,7.74,0,0,0-2.48-.43,7.94,7.94,0,0,1,2.52.28.08.08,0,0,1,0,.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.11,69.89a7.73,7.73,0,0,0-2.48-.42,7.66,7.66,0,0,1,2.52.27.08.08,0,0,1,.06.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.31,69.43a7.61,7.61,0,0,0-2.44-.61,7.83,7.83,0,0,1,2.49.46s.07.06.05.1a.08.08,0,0,1-.1.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.57,69a7.39,7.39,0,0,0-2.46-.5,7.66,7.66,0,0,1,2.51.35.07.07,0,0,1,.05.1.07.07,0,0,1-.09.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.42,68.56A7.65,7.65,0,0,0,20,67.93a7.53,7.53,0,0,1,2.48.48.08.08,0,0,1,0,.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.31,67.62a7.73,7.73,0,0,0-2.4-.71,7.74,7.74,0,0,1,2.46.57.08.08,0,0,1,.05.1.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.77,67.22a8,8,0,0,0-2.36-.87,7.62,7.62,0,0,1,2.42.73.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.44,66.53a7.9,7.9,0,0,0-2.34-.93,7.85,7.85,0,0,1,2.41.79.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.48,66a7.74,7.74,0,0,0-2.31-1,7.92,7.92,0,0,1,2.39.84.09.09,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.7,65.41a7.67,7.67,0,0,0-2.26-1.11,7.88,7.88,0,0,1,2.34,1,.08.08,0,0,1-.08.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.85,64.89a7.76,7.76,0,0,0-2.19-1.23,7.88,7.88,0,0,1,2.28,1.1.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.94,64.2a7.48,7.48,0,0,0-2.11-1.36A7.62,7.62,0,0,1,25,64.07a.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.18,63.09a7.43,7.43,0,0,0-1.94-1.59A7.45,7.45,0,0,1,22.29,63a.07.07,0,1,1-.1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,62.59a7.62,7.62,0,0,0-1.84-1.7,7.91,7.91,0,0,1,2,1.6.08.08,0,0,1,0,.11.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.39,61.57a7.61,7.61,0,0,0-1.57-2,7.57,7.57,0,0,1,1.7,1.87.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.9,60.69a7.53,7.53,0,0,0-1.4-2.08,7.79,7.79,0,0,1,1.54,2,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.24,60.18A7.62,7.62,0,0,0,23,58a7.71,7.71,0,0,1,1.36,2.14.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.08,59.24a7.5,7.5,0,0,0-1-2.32,7.91,7.91,0,0,1,1.11,2.28.09.09,0,0,1-.05.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.59,58.26a7.74,7.74,0,0,0-.68-2.41,7.68,7.68,0,0,1,.84,2.38.09.09,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.58,58a7.59,7.59,0,0,0-.5-2.45A7.81,7.81,0,0,1,24.74,58a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.79,56.65a7.5,7.5,0,0,0-.35-2.49,7.5,7.5,0,0,1,.5,2.48.07.07,0,0,1-.07.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.8,56.78a7.8,7.8,0,0,0-.16-2.5A8.16,8.16,0,0,1,26,56.79a.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.19,55.75a7.51,7.51,0,0,0-.22-2.5,7.54,7.54,0,0,1,.37,2.5.08.08,0,0,1-.07.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.91,55.51a7.53,7.53,0,0,0-.17-2.5,7.57,7.57,0,0,1,.32,2.51.08.08,0,0,1-.08.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.26,54.37a7.8,7.8,0,0,0-.25-2.5,7.79,7.79,0,0,1,.4,2.5.08.08,0,0,1-.07.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.08,54A7.83,7.83,0,0,0,25,51.45,7.85,7.85,0,0,1,25.23,54a.07.07,0,0,1-.08.08.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.78,52.84a7.58,7.58,0,0,0-.19-2.51,7.61,7.61,0,0,1,.34,2.51.08.08,0,0,1-.07.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.3,51.61a7.79,7.79,0,0,0-.24-2.5,7.84,7.84,0,0,1,.4,2.5.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.83,51.08a7.83,7.83,0,0,0-.12-2.51A7.63,7.63,0,0,1,25,51.09a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.54,49.78a7.57,7.57,0,0,0-.17-2.51,7.64,7.64,0,0,1,.33,2.51.07.07,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.44,49a7.84,7.84,0,0,0-.06-2.51A8,8,0,0,1,25.6,49a.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.45,46.25a7.56,7.56,0,0,0-.08-2.51,7.66,7.66,0,0,1,.23,2.52.07.07,0,0,1-.08.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.89,44.67a7.57,7.57,0,0,0-.13-2.51A7.38,7.38,0,0,1,24,44.68a.08.08,0,0,1-.07.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.53,44.1a7.55,7.55,0,0,0-.08-2.51,7.89,7.89,0,0,1,.24,2.52.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.19,42.7A7.32,7.32,0,0,0,24,40.19a7.61,7.61,0,0,1,.34,2.51.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.69,42.2a7.82,7.82,0,0,0-.09-2.51,7.89,7.89,0,0,1,.24,2.52.07.07,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.88,42.16a7.83,7.83,0,0,0,0-2.51A7.69,7.69,0,0,1,28,42.18a.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.9,40.41a7.84,7.84,0,0,0-.06-2.51,7.93,7.93,0,0,1,.22,2.52.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.62,40.55a7.55,7.55,0,0,0,0-2.51,7.91,7.91,0,0,1,.18,2.52.09.09,0,0,1-.09.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.81,40a7.83,7.83,0,0,0,0-2.51A7.62,7.62,0,0,1,29,40a.07.07,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.34,39a7.3,7.3,0,0,0,0-2.51A7.62,7.62,0,0,1,27.5,39a.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.76,37.57a7.57,7.57,0,0,0-.1-2.51,7.64,7.64,0,0,1,.25,2.52.08.08,0,0,1-.08.08.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.43,38a7.83,7.83,0,0,0,0-2.51A7.62,7.62,0,0,1,29.58,38a.08.08,0,0,1-.08.08.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.85,35.32a7.55,7.55,0,0,0,0-2.51A8,8,0,0,1,28,35.34a.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.46,33.53a7.89,7.89,0,0,0,0-2.52,8,8,0,0,1,.16,2.53.07.07,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.41,30a7.83,7.83,0,0,0,0-2.51A7.88,7.88,0,0,1,28.57,30a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.85,28.31a7.83,7.83,0,0,0,0-2.51A7.9,7.9,0,0,1,29,28.32a.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.1,27.22a7.83,7.83,0,0,0,0-2.51,8,8,0,0,1,.12,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.89,26a7.83,7.83,0,0,0,0-2.51A7.61,7.61,0,0,1,31,26a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.37,82.5a7.69,7.69,0,0,0,1.83-1.72,7.75,7.75,0,0,1-1.73,1.84.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.72,83.71a7.65,7.65,0,0,0,2-1.58,7.58,7.58,0,0,1-1.86,1.71.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.58,83.73a7.53,7.53,0,0,0,2-1.51,7.81,7.81,0,0,1-1.92,1.65.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.32,85.56a7.94,7.94,0,0,0,2.09-1.39,8,8,0,0,1-2,1.53.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.92,82.51a7.63,7.63,0,0,0,1.93-1.61A7.8,7.8,0,0,1,62,82.64s-.09,0-.11,0a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.81,81.52a7.56,7.56,0,0,0,2-1.48,8,8,0,0,1-2,1.62.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.09,82.94a7.48,7.48,0,0,0,1.78-1.77,7.58,7.58,0,0,1-1.68,1.89.08.08,0,0,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.83,85.64a7.72,7.72,0,0,0,2.1-1.38,7.6,7.6,0,0,1-2,1.52.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.9,84.71A7.57,7.57,0,0,0,61,83.3a7.73,7.73,0,0,1-2,1.55.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.92,83.94A7.76,7.76,0,0,0,58,82.51a7.8,7.8,0,0,1-2,1.57.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.49,81.92a7.88,7.88,0,0,0,1.88-1.67A7.8,7.8,0,0,1,57.58,82a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.27,80.87a7.77,7.77,0,0,0,2-1.49A8,8,0,0,1,58.35,81a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.6,81a7.72,7.72,0,0,0,2.1-1.38,7.6,7.6,0,0,1-2,1.52.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.56,81a7.49,7.49,0,0,0,2.12-1.35,7.76,7.76,0,0,1-2,1.49.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.34,79.81a7.48,7.48,0,0,0,2.07-1.42,7.6,7.6,0,0,1-2,1.56.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.94,79.23a7.63,7.63,0,0,0,2-1.55A7.83,7.83,0,0,1,60,79.36a.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.84,79.23a7.47,7.47,0,0,0,1.9-1.64,7.61,7.61,0,0,1-1.81,1.77.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.05,78.54a7.64,7.64,0,0,0,2.12-1.35,7.76,7.76,0,0,1-2.05,1.49.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.76,78.46A7.94,7.94,0,0,0,60.81,77a7.79,7.79,0,0,1-2,1.58.06.06,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.09,77.84a7.62,7.62,0,0,0,2.13-1.33A7.67,7.67,0,0,1,57.16,78a.08.08,0,1,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.85,77.32A7.64,7.64,0,0,0,63,76a7.67,7.67,0,0,1-2,1.48.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,76.55A7.74,7.74,0,0,0,59,75,8,8,0,0,1,57,76.69a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,76.23a7.72,7.72,0,0,0,2.08-1.41,7.78,7.78,0,0,1-2,1.55.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.81,75.74A7.88,7.88,0,0,0,64,74.42a8,8,0,0,1-2.08,1.46.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.38,75.06a7.84,7.84,0,0,0,2.14-1.31,7.5,7.5,0,0,1-2.07,1.45.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.5,74.61a8.1,8.1,0,0,0,2.11-1.36,8,8,0,0,1-2,1.5.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,74.09a8,8,0,0,0,2.06-1.44,8,8,0,0,1-2,1.57.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.41,73.54a7.57,7.57,0,0,0,2.14-1.3,7.62,7.62,0,0,1-2.07,1.44.08.08,0,1,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.35,72.71a7.49,7.49,0,0,0,2.15-1.3,7.9,7.9,0,0,1-2.08,1.44.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.82,71.89a7.69,7.69,0,0,0,2.12-1.34,7.72,7.72,0,0,1-2,1.48.08.08,0,1,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.32,71.6a7.68,7.68,0,0,0,2.16-1.3,7.72,7.72,0,0,1-2.09,1.44.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.77,70.94a7.63,7.63,0,0,0,2.15-1.3,8.06,8.06,0,0,1-2.08,1.44.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.28,70.28A7.69,7.69,0,0,0,63.43,69a7.7,7.7,0,0,1-2.08,1.43.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.91,69.79a7.49,7.49,0,0,0,2.15-1.3A7.52,7.52,0,0,1,58,69.93a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.88,69.23A7.62,7.62,0,0,0,65,68,7.91,7.91,0,0,1,63,69.38a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.73,68.81a7.46,7.46,0,0,0,2.15-1.29A7.28,7.28,0,0,1,59.8,69a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.61,68.38a7.83,7.83,0,0,0,2.15-1.29,7.91,7.91,0,0,1-2.09,1.43.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.24,68.17a7.49,7.49,0,0,0,2.15-1.3,7.52,7.52,0,0,1-2.08,1.44.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.8,67.54A7.59,7.59,0,0,0,61,66.25a7.91,7.91,0,0,1-2.09,1.43.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.65,67a7.89,7.89,0,0,0,2.16-1.29,7.91,7.91,0,0,1-2.09,1.43.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.42,66.6a7.83,7.83,0,0,0,2.15-1.29,7.91,7.91,0,0,1-2.09,1.43.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.67,66.09a7.46,7.46,0,0,0,2.15-1.29,7.48,7.48,0,0,1-2.08,1.43.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.72,65.53a7.69,7.69,0,0,0,2.15-1.29,7.7,7.7,0,0,1-2.08,1.43.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.73,64.47a7.83,7.83,0,0,0,2.15-1.29,7.91,7.91,0,0,1-2.09,1.43.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,64.52a7.46,7.46,0,0,0,2.15-1.29A7.48,7.48,0,0,1,63,64.66a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.33,64a7.63,7.63,0,0,0,2.15-1.3,7.72,7.72,0,0,1-2.09,1.44.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.33,63.35a7.37,7.37,0,0,0,2.15-1.29,7.72,7.72,0,0,1-2.09,1.44.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,62.82a7.72,7.72,0,0,0,2.15-1.3A7.74,7.74,0,0,1,63.07,63a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.41,62.26A8,8,0,0,0,62.56,61a7.91,7.91,0,0,1-2.09,1.43.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,61.84a7.49,7.49,0,0,0,2.15-1.3A7.52,7.52,0,0,1,58.07,62a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.74,61.34A8.28,8.28,0,0,0,63.89,60a8,8,0,0,1-2.09,1.44.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.84,60.64A7.49,7.49,0,0,0,61,59.34a7.52,7.52,0,0,1-2.08,1.44.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.33,60.65a7.57,7.57,0,0,0,2.14-1.3,7.74,7.74,0,0,1-2.08,1.44.07.07,0,0,1-.07-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.71,59.87a7.63,7.63,0,0,0,2.15-1.3A7.82,7.82,0,0,1,60.78,60a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.92,59.18a7.84,7.84,0,0,0,2.14-1.31A7.65,7.65,0,0,1,63,59.32a.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.66,58.65a7.49,7.49,0,0,0,2.15-1.3,7.74,7.74,0,0,1-2.08,1.44.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.58,58.05a7.84,7.84,0,0,0,2.14-1.31,8.12,8.12,0,0,1-2.07,1.45.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.48,57.77a7.57,7.57,0,0,0,2.14-1.3,7.84,7.84,0,0,1-2.07,1.44.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.75,57a7.64,7.64,0,0,0,2.14-1.32,7.53,7.53,0,0,1-2.07,1.46.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.57,56.33A7.64,7.64,0,0,0,64.7,55a7.6,7.6,0,0,1-2.06,1.45.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.78,56.19a7.64,7.64,0,0,0,2.14-1.32,7.75,7.75,0,0,1-2.07,1.46.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.26,55.88a7.45,7.45,0,0,0,2.13-1.32A7.75,7.75,0,0,1,59.32,56a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.15,54.63a7.48,7.48,0,0,0,2.13-1.33,7.67,7.67,0,0,1-2.06,1.47.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.87,54.46A7.88,7.88,0,0,0,63,53.14a7.91,7.91,0,0,1-2.07,1.46.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,54.67a7.48,7.48,0,0,0,2.13-1.33A7.37,7.37,0,0,1,58,54.81a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.19,53a7.9,7.9,0,0,0,2.12-1.33,7.58,7.58,0,0,1-2.06,1.47.08.08,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.15,53.12a7.84,7.84,0,0,0,2.12-1.34,7.37,7.37,0,0,1-2.06,1.47s-.08,0-.1,0a.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.26,51.62a7.51,7.51,0,0,0,2.13-1.34,7.71,7.71,0,0,1-2.06,1.48.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.47,51.33A7.49,7.49,0,0,0,61.59,50a7.76,7.76,0,0,1-2.05,1.49.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.37,50a7.67,7.67,0,0,0,2.11-1.35,7.71,7.71,0,0,1-2,1.49.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.62,50.84a7.65,7.65,0,0,0,2.1-1.36,7.69,7.69,0,0,1-2,1.5.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.08,50.77a7.72,7.72,0,0,0,2.1-1.38,7.82,7.82,0,0,1-2,1.52.08.08,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.64,48.21a7.94,7.94,0,0,0,2.09-1.39,7.71,7.71,0,0,1-2,1.52.08.08,0,1,1-.08-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.33,48.91A7.67,7.67,0,0,0,58.4,47.5a7.78,7.78,0,0,1-2,1.55.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.39,46.91a7.81,7.81,0,0,0,2-1.46,7.57,7.57,0,0,1-2,1.59.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.73,47.83a7.56,7.56,0,0,0,2-1.48A7.8,7.8,0,0,1,54.81,48a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.6,46.49a7.59,7.59,0,0,0,2-1.5,7.74,7.74,0,0,1-1.92,1.64.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.37,45a7.67,7.67,0,0,0,2-1.56,7.45,7.45,0,0,1-1.89,1.69.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.84,45.5a8.07,8.07,0,0,0,1.93-1.61,7.56,7.56,0,0,1-1.84,1.73.08.08,0,1,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.78,43.71a7.86,7.86,0,0,0,1.9-1.64,7.8,7.8,0,0,1-1.81,1.77.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.17,43.81A7.64,7.64,0,0,0,56,42.11a7.79,7.79,0,0,1-1.75,1.83.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.69,43.67a7.67,7.67,0,0,0,1.76-1.79,7.57,7.57,0,0,1-1.66,1.91.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.81,41.86a7.44,7.44,0,0,0,1.77-1.77A7.39,7.39,0,0,1,55.9,42a.08.08,0,0,1-.11,0s0-.09,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.37,41.63a7.69,7.69,0,0,0,1.68-1.87,7.58,7.58,0,0,1-1.58,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.85,41.69a7.49,7.49,0,0,0,1.58-2A7.85,7.85,0,0,1,53,41.8a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.77,39.82a7.78,7.78,0,0,0,1.48-2,7.72,7.72,0,0,1-1.36,2.13.07.07,0,0,1-.11,0s0-.07,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.06,40.3a7.63,7.63,0,0,0,1.3-2.15,7.73,7.73,0,0,1-1.17,2.24.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.38,38.7a7.5,7.5,0,0,0,1.17-2.22,7.63,7.63,0,0,1-1,2.31.08.08,0,0,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.76,38.47a7.58,7.58,0,0,0,.93-2.33,7.88,7.88,0,0,1-.79,2.4.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.67,35a7.68,7.68,0,0,0,.57-2.45,7.9,7.9,0,0,1-.42,2.5.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.37,36.75a7.46,7.46,0,0,0,.41-2.48,7.65,7.65,0,0,1-.26,2.52.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,35.15a7.71,7.71,0,0,0,.4-2.48,7.92,7.92,0,0,1-.25,2.52.08.08,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.35,31.84a7.78,7.78,0,0,0,.42-2.48,7.93,7.93,0,0,1-.27,2.52.08.08,0,0,1-.1.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.29,33.57a7.53,7.53,0,0,0,.34-2.49,7.66,7.66,0,0,1-.19,2.52.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.18,32.17a7.77,7.77,0,0,0,.35-2.49,7.72,7.72,0,0,1-.2,2.53.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.1,28.76a7.76,7.76,0,0,0,.33-2.49,7.45,7.45,0,0,1-.18,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.46,33.88a7.75,7.75,0,0,0,.28-2.49,7.69,7.69,0,0,1-.13,2.53.08.08,0,0,1-.09.06.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.53,29.57a7.83,7.83,0,0,0,.3-2.5,7.44,7.44,0,0,1-.15,2.53.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.6,31.58a7.54,7.54,0,0,0,.28-2.5,7.69,7.69,0,0,1-.12,2.53.08.08,0,0,1-.09.06.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.93,31.92a7.49,7.49,0,0,0,.22-2.5A8,8,0,0,1,47.09,32,.08.08,0,0,1,47,32a.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.49,32.4a7.47,7.47,0,0,0,.26-2.49,7.63,7.63,0,0,1-.11,2.52.07.07,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.17,33.27a7.82,7.82,0,0,0,.24-2.5,8,8,0,0,1-.09,2.53.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,31.06a7.54,7.54,0,0,0,.23-2.5,7.41,7.41,0,0,1-.08,2.53.06.06,0,0,1-.08.06.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.12,30.31a7.46,7.46,0,0,0,.24-2.49,7.43,7.43,0,0,1-.09,2.53.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.15,28.12a7.83,7.83,0,0,0,.26-2.5,8,8,0,0,1-.11,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.76,29.87A7.83,7.83,0,0,0,47,27.36a7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.09.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.18,26.8a7.81,7.81,0,0,0,.22-2.5,7.67,7.67,0,0,1-.07,2.53.1.1,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,25.41a7.8,7.8,0,0,0,.21-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.09.06.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.86,28.59a7.51,7.51,0,0,0,.18-2.5,7.42,7.42,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.42,26.22a7.6,7.6,0,0,0,.19-2.51,8,8,0,0,1,0,2.53.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.65,22.87a7.58,7.58,0,0,0,.19-2.51,7.67,7.67,0,0,1,0,2.53.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.26,24.17a7.85,7.85,0,0,0,.19-2.51,7.67,7.67,0,0,1,0,2.53.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.3,23.17a7.79,7.79,0,0,0,.18-2.5,8,8,0,0,1,0,2.53.08.08,0,0,1-.09.06.06.06,0,0,1-.06-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.88,26.75a7.6,7.6,0,0,0,.19-2.51,8,8,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.45,24.28a7.79,7.79,0,0,0,.18-2.5,8,8,0,0,1,0,2.53.07.07,0,0,1-.09.06.06.06,0,0,1-.06-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.41,25.26a7.51,7.51,0,0,0,.18-2.5,7.42,7.42,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.5,25.33a7.52,7.52,0,0,0,.19-2.5,8,8,0,0,1,0,2.53.08.08,0,0,1-.09.06.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.86,27.53a8.08,8.08,0,0,0,.19-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.09.06.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.86,23.82a7.6,7.6,0,0,0,.19-2.51,7.67,7.67,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.73,26.92a7.8,7.8,0,0,0,.2-2.5,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.47,25.46a7.53,7.53,0,0,0,.22-2.5,7.67,7.67,0,0,1-.07,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.2,28.35a7.52,7.52,0,0,0,.2-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.21,26.82a7.48,7.48,0,0,0,.28-2.49,7.69,7.69,0,0,1-.13,2.53.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.93,28.94a7.55,7.55,0,0,0,.25-2.5,7.7,7.7,0,0,1-.1,2.53A.07.07,0,0,1,50,29a.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.8,30.39a7.82,7.82,0,0,0,.28-2.5A7.69,7.69,0,0,1,51,30.42a.08.08,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.13,30a7.76,7.76,0,0,0,.36-2.48,8,8,0,0,1-.21,2.52.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.82,32.88a7.72,7.72,0,0,0,.42-2.47A7.37,7.37,0,0,1,53,32.92a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.11,33a7.64,7.64,0,0,0,.54-2.45,7.61,7.61,0,0,1-.4,2.5.07.07,0,0,1-.09,0,.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.16,36a7.61,7.61,0,0,0,.65-2.43,7.82,7.82,0,0,1-.5,2.48.07.07,0,0,1-.1,0,.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.07,34.76a7.69,7.69,0,0,0,.8-2.38,7.82,7.82,0,0,1-.66,2.45.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.47,36.07a7.87,7.87,0,0,0,.94-2.33,7.65,7.65,0,0,1-.81,2.4.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.2,37.66a7.61,7.61,0,0,0,1.19-2.21,7.88,7.88,0,0,1-1.06,2.3.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.07,37.55a8.12,8.12,0,0,0,1.35-2.12,7.86,7.86,0,0,1-1.23,2.21.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.36,38.48a7.75,7.75,0,0,0,1.56-2,7.59,7.59,0,0,1-1.45,2.07.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.37,40A7.67,7.67,0,0,0,59,38.07a7.94,7.94,0,0,1-1.56,2,.09.09,0,0,1-.12,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.2,40A7.48,7.48,0,0,0,61,38.18a7.57,7.57,0,0,1-1.66,1.91.07.07,0,1,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.82,41.36a7.3,7.3,0,0,0,1.82-1.73,7.59,7.59,0,0,1-1.72,1.86.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.66,41.89a7.65,7.65,0,0,0,1.91-1.63A8,8,0,0,1,59.75,42a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.1,43.29a7.61,7.61,0,0,0,2-1.57,7.44,7.44,0,0,1-1.88,1.7.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.64,44.7a7.75,7.75,0,0,0,2-1.51,7.74,7.74,0,0,1-1.92,1.64.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.54,45.17a7.81,7.81,0,0,0,2.05-1.46,7.57,7.57,0,0,1-2,1.59.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.8,46.84a7.94,7.94,0,0,0,2.07-1.42,7.9,7.9,0,0,1-2,1.55.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.19,48.61a7.51,7.51,0,0,0,2.09-1.4,7.91,7.91,0,0,1-2,1.54.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.57,48.65a7.72,7.72,0,0,0,2.1-1.38,7.71,7.71,0,0,1-2,1.52.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.9,64.74a7.56,7.56,0,0,0-2-1.48A7.72,7.72,0,0,1,17,64.62a.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.2,64.52a7.5,7.5,0,0,0-2.06-1.42,7.68,7.68,0,0,1,2.16,1.3.08.08,0,1,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.73,64a7.83,7.83,0,0,0-1.86-1.68,7.54,7.54,0,0,1,2,1.58.08.08,0,0,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.8,62.75a7.67,7.67,0,0,0-1.68-1.87,7.84,7.84,0,0,1,1.81,1.78.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.2,63.67a7.77,7.77,0,0,0-2-1.53,7.78,7.78,0,0,1,2.1,1.42.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.24,63.27a7.64,7.64,0,0,0-1.85-1.69,7.91,7.91,0,0,1,2,1.59.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.56,62.48a7.67,7.67,0,0,0-1.77-1.78,7.74,7.74,0,0,1,1.89,1.68.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.32,61.37a7.56,7.56,0,0,0-1.48-2,7.8,7.8,0,0,1,1.62,1.95.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.23,59.92a7.68,7.68,0,0,0-1.07-2.28,7.89,7.89,0,0,1,1.22,2.22.08.08,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.94,58.49a7.49,7.49,0,0,0-.88-2.35,7.63,7.63,0,0,1,1,2.31.09.09,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.27,57.58a7.51,7.51,0,0,0-.7-2.41,7.88,7.88,0,0,1,.86,2.38.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.38,56.05a7.92,7.92,0,0,0-.59-2.44A7.63,7.63,0,0,1,17.53,56a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.3,54.64a7.75,7.75,0,0,0-.45-2.47,7.73,7.73,0,0,1,.61,2.46.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.26,54.13a7.68,7.68,0,0,0-.36-2.48,7.67,7.67,0,0,1,.52,2.47.09.09,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.73,52.86a7.71,7.71,0,0,0-.4-2.48,7.71,7.71,0,0,1,.55,2.47.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.15,51.36a7.46,7.46,0,0,0-.41-2.48,7.72,7.72,0,0,1,.56,2.47.07.07,0,0,1-.07.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.7,50.52A7.76,7.76,0,0,0,19.34,48a7.52,7.52,0,0,1,.51,2.48.07.07,0,0,1-.07.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,48.86a7.8,7.8,0,0,0-.38-2.49,8.1,8.1,0,0,1,.54,2.48.08.08,0,0,1-.07.08s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.74,47.59a7.76,7.76,0,0,0-.33-2.49,7.76,7.76,0,0,1,.49,2.48.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.12,45.48A7.51,7.51,0,0,0,19.8,43a7.86,7.86,0,0,1,.48,2.49.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.75,43.38a7.46,7.46,0,0,0-.28-2.49,7.5,7.5,0,0,1,.44,2.49.08.08,0,0,1-.07.08.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.51,41.2a7.52,7.52,0,0,0-.24-2.5,7.88,7.88,0,0,1,.4,2.5.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.65,40.51A7.56,7.56,0,0,0,23.51,38a7.89,7.89,0,0,1,.3,2.51.07.07,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,39a7.57,7.57,0,0,0-.17-2.51A7.88,7.88,0,0,1,23.16,39,.08.08,0,1,1,23,39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.75,38.76a7.5,7.5,0,0,0-.1-2.5,7.59,7.59,0,0,1,.26,2.51.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.87,37.36a7.56,7.56,0,0,0-.05-2.51,7.62,7.62,0,0,1,.2,2.52.07.07,0,0,1-.08.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,36.07a7.84,7.84,0,0,0-.06-2.51,7.62,7.62,0,0,1,.21,2.52.08.08,0,0,1-.08.08.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,35.16a7.82,7.82,0,0,0-.09-2.51,7.61,7.61,0,0,1,.24,2.52.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.6,36.53a7.56,7.56,0,0,0,0-2.51,7.89,7.89,0,0,1,.12,2.52.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.9,35.72a7.83,7.83,0,0,0,0-2.51,7.41,7.41,0,0,1,.12,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.2,33.16a7.56,7.56,0,0,0,0-2.51,7.42,7.42,0,0,1,.19,2.53.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.3,34a7.83,7.83,0,0,0,0-2.51A8,8,0,0,1,30.45,34a.07.07,0,0,1-.08.07A.07.07,0,0,1,30.3,34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.67,31.77a7.55,7.55,0,0,0,0-2.51,7.88,7.88,0,0,1,.15,2.52.08.08,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.11,32.4a7.84,7.84,0,0,0,.06-2.51,7.67,7.67,0,0,1,.09,2.53.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.08,31.49a7.83,7.83,0,0,0,0-2.51,7.61,7.61,0,0,1,.11,2.52.08.08,0,0,1-.08.08.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.49,30a7.84,7.84,0,0,0,.07-2.51,7.67,7.67,0,0,1,.08,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.93,29A7.84,7.84,0,0,0,32,26.46,8,8,0,0,1,32.08,29a.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.79,27.65a7.84,7.84,0,0,0,.09-2.51A8,8,0,0,1,33,27.67a.1.1,0,0,1-.09.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.29,25.85a7.56,7.56,0,0,0,.08-2.51,7.68,7.68,0,0,1,.08,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.82,25.28a7.84,7.84,0,0,0,.1-2.51,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.17,23.71a7.57,7.57,0,0,0,.11-2.51,7.67,7.67,0,0,1,0,2.53.09.09,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.22,22.68a7.55,7.55,0,0,0,.11-2.51,7.67,7.67,0,0,1,0,2.53c0,.05,0,.08-.09.07a.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.71,22.22a7.57,7.57,0,0,0,.13-2.51,7.67,7.67,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.42,21.75a7.5,7.5,0,0,0,.14-2.5,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.53,23.15a7.25,7.25,0,0,0,.15-2.5,7.67,7.67,0,0,1,0,2.53.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.5,21.3a7.58,7.58,0,0,0,.16-2.51,7.67,7.67,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.79,20.74A7.86,7.86,0,0,0,43,18.23a7.68,7.68,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.45,22.48a7.78,7.78,0,0,0,.16-2.5,7.67,7.67,0,0,1,0,2.53.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.2,19.66a7.84,7.84,0,0,0,.17-2.51,7.67,7.67,0,0,1,0,2.53.07.07,0,0,1-.09.07.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.81,19.57A7.59,7.59,0,0,0,46,17.06a7.67,7.67,0,0,1,0,2.53.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.72,21a7.59,7.59,0,0,0,.17-2.51,7.68,7.68,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46,20.87a7.59,7.59,0,0,0,.17-2.51,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.92,22.35a7.78,7.78,0,0,0,.17-2.5,7.68,7.68,0,0,1,0,2.53.09.09,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.39,19.16a7.78,7.78,0,0,0,.17-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.8,19.77a7.77,7.77,0,0,0,.15-2.5,8,8,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.86,20.06A7.56,7.56,0,0,0,37,17.55a7.73,7.73,0,0,1,0,2.54.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.76,20.7a7.77,7.77,0,0,0,.09-2.5,7.41,7.41,0,0,1,.06,2.53.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.43,21a7.55,7.55,0,0,0,.07-2.51A8,8,0,0,1,31.59,21a.07.07,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.49,21.55a7.83,7.83,0,0,0,0-2.51,7.42,7.42,0,0,1,.15,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.36,22.65a7.56,7.56,0,0,0,0-2.51,7.68,7.68,0,0,1,.2,2.53.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.16,22.78A7.55,7.55,0,0,0,25,20.27a7.85,7.85,0,0,1,.28,2.51.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.93,23.91a7.77,7.77,0,0,0-.18-2.5,7.57,7.57,0,0,1,.33,2.51s0,.07-.07.07a.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.39,24.26a7.53,7.53,0,0,0-.26-2.5,7.85,7.85,0,0,1,.42,2.5.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.72,25.71a7.82,7.82,0,0,0-.29-2.5,7.56,7.56,0,0,1,.44,2.5.07.07,0,0,1-.07.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.35,25.2A7.77,7.77,0,0,0,20,22.72a7.75,7.75,0,0,1,.53,2.48.08.08,0,0,1-.07.08.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.24,27.79a7.71,7.71,0,0,0-.4-2.48,7.71,7.71,0,0,1,.55,2.47.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.75,29.08a7.79,7.79,0,0,0-.39-2.48,7.55,7.55,0,0,1,.54,2.48.07.07,0,0,1-.07.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.86,28a7.54,7.54,0,0,0-.31-2.49A7.85,7.85,0,0,1,21,28,.08.08,0,0,1,21,28a.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.66,27.31a7.78,7.78,0,0,0-.22-2.5,7.77,7.77,0,0,1,.37,2.5.08.08,0,0,1-.07.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.71,26.57a7.79,7.79,0,0,0-.14-2.5,7.58,7.58,0,0,1,.29,2.51.08.08,0,0,1-.08.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.5,25.13a7.55,7.55,0,0,0,0-2.51,7.62,7.62,0,0,1,.2,2.52.07.07,0,0,1-.08.07.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.81,23.3a7.55,7.55,0,0,0,0-2.51A7.68,7.68,0,0,1,30,23.32a.09.09,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.58,19.42a7.31,7.31,0,0,0,.09-2.51,7.67,7.67,0,0,1,.07,2.53.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,16.53A7.57,7.57,0,0,0,35.1,14a7.67,7.67,0,0,1,0,2.53.09.09,0,0,1-.09.07.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.75,14.69a7.33,7.33,0,0,0,.18-2.51,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.06,13.4a7.52,7.52,0,0,0,.19-2.5,8,8,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,13.06a7.52,7.52,0,0,0,.19-2.5,7.68,7.68,0,0,1,0,2.53.06.06,0,0,1-.08.06.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.39,13.34a7.52,7.52,0,0,0,.19-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.17,13.63a7.79,7.79,0,0,0,.19-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.13,14.14a7.52,7.52,0,0,0,.2-2.5,8,8,0,0,1,0,2.53.08.08,0,0,1-.09.06.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.72,16a7.56,7.56,0,0,0,.2-2.51,7.67,7.67,0,0,1-.05,2.53.07.07,0,0,1-.09.07.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.65,16.41a7.8,7.8,0,0,0,.21-2.5,8,8,0,0,1-.06,2.53.07.07,0,0,1-.09.06.06.06,0,0,1-.06-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.58,16.74a7.8,7.8,0,0,0,.21-2.5,7.42,7.42,0,0,1-.06,2.53.08.08,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.09,17.19a7.74,7.74,0,0,0,.25-2.49,7.63,7.63,0,0,1-.1,2.52.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.74,19a7.79,7.79,0,0,0,.33-2.49A7.91,7.91,0,0,1,58.89,19a.07.07,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.55,18.78a7.54,7.54,0,0,0,.28-2.5,7.69,7.69,0,0,1-.13,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.32,18.47A7.86,7.86,0,0,0,55.53,16a8,8,0,0,1-.06,2.53.07.07,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.81,16.72A7.85,7.85,0,0,0,51,14.21a7.67,7.67,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.92,15.45a7.6,7.6,0,0,0,.19-2.51,7.68,7.68,0,0,1,0,2.53.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.78,15.08a7.79,7.79,0,0,0,.18-2.5,8,8,0,0,1,0,2.53.07.07,0,0,1-.09.06.06.06,0,0,1-.06-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.68,14.6a7.32,7.32,0,0,0,.15-2.51,7.67,7.67,0,0,1,0,2.53s-.05.08-.09.07a.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.19,16.07a7.56,7.56,0,0,0,0-2.51,7.67,7.67,0,0,1,.11,2.53.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.8,17.4a7.84,7.84,0,0,0-.09-2.51A7.64,7.64,0,0,1,26,17.41a.08.08,0,0,1-.08.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.1,19.92a7.76,7.76,0,0,0-.34-2.49,8,8,0,0,1,.5,2.48.1.1,0,0,1-.07.09.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.86,23.48A7.64,7.64,0,0,0,17.32,21,7.87,7.87,0,0,1,18,23.46a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.46,26.52a7.83,7.83,0,0,0-.72-2.4,8,8,0,0,1,.88,2.37.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.1,29.45a8,8,0,0,0-.82-2.38,7.73,7.73,0,0,1,1,2.34.08.08,0,0,1-.06.1.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.72,32.05a7.8,7.8,0,0,0-.78-2.39A7.93,7.93,0,0,1,9.88,32a.08.08,0,0,1-.06.09.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.49,34.75a7.73,7.73,0,0,0-.71-2.4,7.66,7.66,0,0,1,.86,2.37.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.64,36.27A7.5,7.5,0,0,0,7,33.85a7.75,7.75,0,0,1,.82,2.4.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.52,37.4A7.54,7.54,0,0,0,7.88,35a7.7,7.7,0,0,1,.79,2.4.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,36.22a7.75,7.75,0,0,0-.69-2.41,7.68,7.68,0,0,1,.84,2.38.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.65,34.31a7.58,7.58,0,0,0-.73-2.4,7.7,7.7,0,0,1,.88,2.37.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.74,31.92A7.54,7.54,0,0,0,11,29.53a7.65,7.65,0,0,1,.93,2.36.07.07,0,0,1-.05.09.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.94,28.74a7.54,7.54,0,0,0-.72-2.41,7.66,7.66,0,0,1,.88,2.38.08.08,0,0,1-.06.09.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.73,18.24a7.79,7.79,0,0,0-.23-2.5,7.59,7.59,0,0,1,.38,2.5.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.57,12.15a7.55,7.55,0,0,0,.11-2.51,7.67,7.67,0,0,1,0,2.53.07.07,0,0,1-.09.07.06.06,0,0,1-.06-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,9.45a7.52,7.52,0,0,0,.2-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.81,8.18a7.8,7.8,0,0,0,.2-2.5,7.67,7.67,0,0,1,0,2.53s0,.07-.09.07a.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,8.18a7.86,7.86,0,0,0,.21-2.51,7.67,7.67,0,0,1-.06,2.53.07.07,0,0,1-.09.07A.08.08,0,0,1,49,8.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.81,8.78A7.53,7.53,0,0,0,54,6.28,8,8,0,0,1,54,8.81a.08.08,0,0,1-.09.06.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.07,10.33a7.49,7.49,0,0,0,.23-2.5,7.67,7.67,0,0,1-.07,2.53.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.05,13.88a7.74,7.74,0,0,0,.3-2.49,7.44,7.44,0,0,1-.15,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.4,17.83a7.48,7.48,0,0,0,.75-2.39,7.42,7.42,0,0,1-.61,2.45.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.84,21.37a7.62,7.62,0,0,0,1.23-2.19A7.51,7.51,0,0,1,67,21.46a.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.66,23.51a7.63,7.63,0,0,0,1.51-2,7.57,7.57,0,0,1-1.39,2.11.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.08,26.69a7.68,7.68,0,0,0,1.7-1.85,7.56,7.56,0,0,1-1.6,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,28.56a7.68,7.68,0,0,0,1.7-1.85,7.78,7.78,0,0,1-1.6,2,.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.12,29.78A7.44,7.44,0,0,0,71.91,28a7.51,7.51,0,0,1-1.69,1.87.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.51,29.83A7.67,7.67,0,0,0,70.27,28,7.57,7.57,0,0,1,68.61,30a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.34,27.22A7.67,7.67,0,0,0,70,25.29a7.68,7.68,0,0,1-1.5,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.37,23.51a7.83,7.83,0,0,0,1.29-2.15,7.61,7.61,0,0,1-1.17,2.24.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.26,17.2a7.71,7.71,0,0,0,.46-2.47,7.66,7.66,0,0,1-.31,2.51.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,12.41a7.57,7.57,0,0,0,.22-2.51,8,8,0,0,1-.06,2.53.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.2,10.79a7.53,7.53,0,0,0,.21-2.5,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.15,9.48A7.52,7.52,0,0,0,44.34,7a7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,9.16a7.52,7.52,0,0,0,.19-2.5,7.67,7.67,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.82,9.6A7.84,7.84,0,0,0,32,7.09a7.67,7.67,0,0,1,0,2.53.07.07,0,0,1-.09.07.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.63,10.91a7.84,7.84,0,0,0-.09-2.51,7.66,7.66,0,0,1,.24,2.52.08.08,0,0,1-.08.08.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.13,13.32a7.43,7.43,0,0,0-.38-2.48,7.7,7.7,0,0,1,.54,2.47.1.1,0,0,1-.07.09.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,16a7.6,7.6,0,0,0-.64-2.43,7.86,7.86,0,0,1,.8,2.41.09.09,0,0,1-.07.09A.08.08,0,0,1,17,16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.41,18.57a7.87,7.87,0,0,0-.89-2.35,7.77,7.77,0,0,1,1,2.31.07.07,0,0,1-.05.09.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.39,22.41a7.85,7.85,0,0,0-1-2.31,7.91,7.91,0,0,1,1.13,2.27.09.09,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8,25.17a7.5,7.5,0,0,0-1-2.32,7.61,7.61,0,0,1,1.11,2.27.07.07,0,0,1-.05.1s-.08,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.48,25.79a7.45,7.45,0,0,0-.94-2.32,7.86,7.86,0,0,1,1.09,2.28.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.08,24.24a7.57,7.57,0,0,0-1-2.32,7.66,7.66,0,0,1,1.13,2.27.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.79,22.86a7.73,7.73,0,0,0-1-2.3,7.58,7.58,0,0,1,1.14,2.25.07.07,0,0,1,0,.1.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,21a7.75,7.75,0,0,0-1-2.3A7.63,7.63,0,0,1,10.16,21a.08.08,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.6,20.17a7.58,7.58,0,0,0-.93-2.33,7.76,7.76,0,0,1,1.08,2.29.07.07,0,0,1-.05.1.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.14,14.69a7.76,7.76,0,0,0-.34-2.49,7.77,7.77,0,0,1,.5,2.48.08.08,0,0,1-.07.08s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.49,10.85a7.82,7.82,0,0,0,.1-2.51,7.68,7.68,0,0,1,.05,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.77,11A7.6,7.6,0,0,0,38,8.47a7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,11.19a7.52,7.52,0,0,0,.2-2.5,7.67,7.67,0,0,1,0,2.53.09.09,0,0,1-.09.07A.1.1,0,0,1,47,11.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.2,11.9a7.53,7.53,0,0,0,.22-2.5,8,8,0,0,1-.06,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.91,14.12a7.76,7.76,0,0,0,.24-2.5,7.68,7.68,0,0,1-.09,2.53.07.07,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.78,18.54a7.91,7.91,0,0,0,1.06-2.28,7.5,7.5,0,0,1-.93,2.36.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.32,23.28A7.68,7.68,0,0,0,74,21.39a7.52,7.52,0,0,1-1.55,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.08,28.19A7.68,7.68,0,0,0,78,26.54a7.84,7.84,0,0,1-1.81,1.78.08.08,0,1,1-.09-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.16,31.68a7.26,7.26,0,0,0,2-1.56,7.58,7.58,0,0,1-1.88,1.69.07.07,0,0,1-.11,0,.06.06,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.84,34.26a7.64,7.64,0,0,0,2-1.54,7.63,7.63,0,0,1-1.91,1.67.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.89,36a7.85,7.85,0,0,0,2-1.51A7.61,7.61,0,0,1,79,36.15a.06.06,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.05,36.86a7.59,7.59,0,0,0,2-1.5A7.57,7.57,0,0,1,78.13,37,.07.07,0,0,1,78,37a.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.31,34.69a7.6,7.6,0,0,0,2-1.54,7.79,7.79,0,0,1-1.9,1.67.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.75,32.12a7.88,7.88,0,0,0,1.93-1.61,8,8,0,0,1-1.84,1.74.08.08,0,0,1-.09-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.1,29.84a7.48,7.48,0,0,0,1.84-1.71A7.8,7.8,0,0,1,72.2,30a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.45,27.68a7.94,7.94,0,0,0,1.45-2.05,7.87,7.87,0,0,1-1.33,2.15.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.55,25.85a7.44,7.44,0,0,0,1.09-2.27,7.74,7.74,0,0,1-1,2.35.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.92,25.53a7.59,7.59,0,0,0,.88-2.35,7.39,7.39,0,0,1-.74,2.42.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.29,24.37a7.42,7.42,0,0,0,.9-2.34,7.69,7.69,0,0,1-.76,2.41.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.39,24.38a7.41,7.41,0,0,0,1.12-2.25,7.7,7.7,0,0,1-1,2.33.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.73,26.27a7.86,7.86,0,0,0,1.33-2.13,7.86,7.86,0,0,1-1.21,2.22.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.7,32a7.44,7.44,0,0,0,1.77-1.77,7.39,7.39,0,0,1-1.68,1.89.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.17,37.46a7.77,7.77,0,0,0,2-1.49,7.79,7.79,0,0,1-1.94,1.63.08.08,0,0,1-.09-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75,42.53a7.94,7.94,0,0,0,2.07-1.42,7.56,7.56,0,0,1-2,1.55.08.08,0,1,1-.08-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.76,45.39A7.76,7.76,0,0,0,77.84,44a7.54,7.54,0,0,1-2,1.53.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.79,48a7.68,7.68,0,0,0,2.1-1.37,8.13,8.13,0,0,1-2,1.51.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.41,49.37A7.62,7.62,0,0,0,77.52,48a7.91,7.91,0,0,1-2,1.5.08.08,0,1,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.45,49.07a7.85,7.85,0,0,0,2.11-1.36,7.91,7.91,0,0,1-2,1.5.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.18,46.88a7.68,7.68,0,0,0,2.1-1.37,7.78,7.78,0,0,1-2,1.51.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.35,42.1a7.57,7.57,0,0,0,2.08-1.41,7.61,7.61,0,0,1-2,1.55.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,38.23a7.63,7.63,0,0,0,1.88-1.66,7.8,7.8,0,0,1-1.79,1.79.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.67,35.42a7.81,7.81,0,0,0,1.54-2,7.68,7.68,0,0,1-1.43,2.09.08.08,0,1,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.73,33a7.87,7.87,0,0,0,1.09-2.26,7.37,7.37,0,0,1-1,2.34.07.07,0,0,1-.1,0s-.06-.07,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.41,31.81a7.6,7.6,0,0,0,1-2.28,7.46,7.46,0,0,1-.91,2.36.07.07,0,1,1-.13-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.21,33.58a7.9,7.9,0,0,0,1.31-2.15,7.66,7.66,0,0,1-1.19,2.24.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.15,36a7.44,7.44,0,0,0,1.75-1.79,7.53,7.53,0,0,1-1.65,1.91.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.74,39.82a7.59,7.59,0,0,0,2.05-1.46,7.74,7.74,0,0,1-2,1.59.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.39,45.81a7.72,7.72,0,0,0,2.1-1.38,7.76,7.76,0,0,1-2,1.52.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.19,50.33A7.72,7.72,0,0,0,76.31,49a7.76,7.76,0,0,1-2,1.49.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.82,54.19A7.48,7.48,0,0,0,77,52.86a7.58,7.58,0,0,1-2.06,1.47.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.76,57.13A7.53,7.53,0,0,0,76.9,55.8a7.57,7.57,0,0,1-2.07,1.47.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.88,63.59A7.68,7.68,0,0,0,78,62.27a7.75,7.75,0,0,1-2.07,1.46.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,62.26a7.45,7.45,0,0,0,2.13-1.32A7.75,7.75,0,0,1,74,62.4a.08.08,0,0,1-.07-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.48,60.16a7.73,7.73,0,0,0,2.14-1.32,7.75,7.75,0,0,1-2.07,1.46.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.12,61.4a7.68,7.68,0,0,0,2.13-1.32,7.7,7.7,0,0,1-2.06,1.46.08.08,0,1,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75,59.19a7.68,7.68,0,0,0,2.13-1.32,7.86,7.86,0,0,1-2.06,1.46.08.08,0,1,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.51,56.29A8,8,0,0,0,75.64,55a7.8,7.8,0,0,1-2.06,1.47.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.5,53.55a7.43,7.43,0,0,0,2.12-1.33,7.47,7.47,0,0,1-2.05,1.47.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.57,50a7.46,7.46,0,0,0,2.12-1.34,7.5,7.5,0,0,1-2,1.48.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.05,48.18a7.62,7.62,0,0,0,2.11-1.36,7.74,7.74,0,0,1-2,1.5.08.08,0,1,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.58,44.34A7.7,7.7,0,0,0,70.67,43a7.75,7.75,0,0,1-2,1.53.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.17,42a7.78,7.78,0,0,0,2-1.45,7.78,7.78,0,0,1-2,1.59.08.08,0,1,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.17,39.36a7.61,7.61,0,0,0,2-1.53,7.59,7.59,0,0,1-1.91,1.66.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.64,36.27a7.83,7.83,0,0,0,1.86-1.68,8,8,0,0,1-1.77,1.81.08.08,0,0,1-.11,0s0-.09,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.36,34a7.82,7.82,0,0,0,1.71-1.83,7.52,7.52,0,0,1-1.61,1.95.07.07,0,1,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.29,31a7.74,7.74,0,0,0,1.43-2.07,7.91,7.91,0,0,1-1.31,2.17.08.08,0,1,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.92,28.09a7.48,7.48,0,0,0,1.2-2.2,7.6,7.6,0,0,1-1.07,2.29.08.08,0,0,1-.11,0,.06.06,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.27,22.67a7.69,7.69,0,0,0,.8-2.38,7.69,7.69,0,0,1-.66,2.44.09.09,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.87,20.38a7.64,7.64,0,0,0,.54-2.45,7.87,7.87,0,0,1-.39,2.5.09.09,0,0,1-.1.05.07.07,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.74,17.17a7.51,7.51,0,0,0,.32-2.49,7.92,7.92,0,0,1-.16,2.52.08.08,0,0,1-.1.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.4,14.75a7.53,7.53,0,0,0,.22-2.5,7.69,7.69,0,0,1-.07,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,15.28a7.74,7.74,0,0,0,.3-2.49,8,8,0,0,1-.15,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.43,16.3a7.64,7.64,0,0,0,.66-2.42,7.46,7.46,0,0,1-.51,2.47.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.77,20.07A7.4,7.4,0,0,0,69,17.89a7.63,7.63,0,0,1-1.12,2.27.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.22,23.6a7.39,7.39,0,0,0,1.59-1.93,7.61,7.61,0,0,1-1.48,2,.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.12,27.85a7.62,7.62,0,0,0,1.79-1.75A7.71,7.71,0,0,1,72.22,28a.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.58,31.84a7.63,7.63,0,0,0,1.88-1.66A7.38,7.38,0,0,1,72.67,32a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.45,36.21a7.75,7.75,0,0,0,2-1.51,8,8,0,0,1-1.92,1.65.08.08,0,0,1-.09-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.72,37.48a7.78,7.78,0,0,0,2-1.48,7.76,7.76,0,0,1-2,1.61.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.24,34.85a7.6,7.6,0,0,0,2-1.54A7.59,7.59,0,0,1,75.32,35a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.16,31.27a7.8,7.8,0,0,0,1.93-1.6,7.62,7.62,0,0,1-1.85,1.73.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.74,24.48a7.48,7.48,0,0,0,1.8-1.75,7.75,7.75,0,0,1-1.7,1.87.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.36,17.48a7.53,7.53,0,0,0,1.43-2.06,7.48,7.48,0,0,1-1.31,2.16.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,14.86a7.62,7.62,0,0,0,1.23-2.19A7.88,7.88,0,0,1,74.09,15,.07.07,0,0,1,74,15a.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,11.9a7.63,7.63,0,0,0,1-2.31A7.68,7.68,0,0,1,74.11,12a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,10.1a7.69,7.69,0,0,0,.83-2.37,7.68,7.68,0,0,1-.69,2.43.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.57,9.85a7.48,7.48,0,0,0,.75-2.39,7.72,7.72,0,0,1-.6,2.45.08.08,0,0,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.49,9.49A7.39,7.39,0,0,0,70,7a7.61,7.61,0,0,1-.4,2.5.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.63,8.53A7.5,7.5,0,0,0,64.89,6a7.68,7.68,0,0,1-.11,2.53.09.09,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.72,7.22A7.82,7.82,0,0,0,59,4.72a7.7,7.7,0,0,1-.09,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.22,6a7.53,7.53,0,0,0,.22-2.5A7.68,7.68,0,0,1,52.37,6a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.2,5.41a7.27,7.27,0,0,0,.21-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.35,5.12a7.53,7.53,0,0,0,.21-2.5,7.68,7.68,0,0,1-.05,2.53.08.08,0,0,1-.09.06.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.34,5.08a7.52,7.52,0,0,0,.2-2.5,7.68,7.68,0,0,1,0,2.53.1.1,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.14,5.47A7.84,7.84,0,0,0,32.35,3a8,8,0,0,1-.05,2.53.08.08,0,0,1-.09.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,6.05a7.5,7.5,0,0,0,.08-2.5,7.61,7.61,0,0,1,.08,2.52.09.09,0,0,1-.09.08A.1.1,0,0,1,27,6.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.07,6.79a7.76,7.76,0,0,0-.16-2.5,7.84,7.84,0,0,1,.32,2.51.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.75,8.21a7.9,7.9,0,0,0-.48-2.46,7.91,7.91,0,0,1,.63,2.44.07.07,0,0,1-.07.09.06.06,0,0,1-.08-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.3,10a7.78,7.78,0,0,0-.77-2.39,7.89,7.89,0,0,1,.92,2.36.08.08,0,0,1-.06.09.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.1,11.58a7.76,7.76,0,0,0-.94-2.33,7.86,7.86,0,0,1,1.09,2.28.08.08,0,0,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.44,12.61a7.72,7.72,0,0,0-1-2.29,7.72,7.72,0,0,1,1.17,2.24.08.08,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.53,14a8,8,0,0,0-1-2.27,7.94,7.94,0,0,1,1.2,2.22.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.94,16.62a7.59,7.59,0,0,0-1.08-2.26,8,8,0,0,1,1.23,2.21.09.09,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.22,21.65a7.77,7.77,0,0,0-1-2.29A7.91,7.91,0,0,1,6.37,21.6a.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.49,27.37A7.85,7.85,0,0,0,4.58,25a7.9,7.9,0,0,1,1.06,2.3.09.09,0,0,1,0,.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.45,30.72a7.71,7.71,0,0,0-.81-2.38,7.88,7.88,0,0,1,1,2.34.09.09,0,0,1-.06.1.1.1,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5,35a7.52,7.52,0,0,0-.67-2.42,7.53,7.53,0,0,1,.82,2.39.08.08,0,0,1-.06.1A.08.08,0,0,1,5,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.43,39.14a7.38,7.38,0,0,0-.54-2.45,7.62,7.62,0,0,1,.7,2.43.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.08,40.12a7.88,7.88,0,0,0-.56-2.44,7.51,7.51,0,0,1,.71,2.42.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,38.39A7.9,7.9,0,0,0,10.31,36a7.74,7.74,0,0,1,.81,2.4.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.73,34.83a7.71,7.71,0,0,0-.55-2.45,7.61,7.61,0,0,1,.7,2.44.06.06,0,0,1-.06.08.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.18,32a7.8,7.8,0,0,0-.26-2.5,8.09,8.09,0,0,1,.42,2.5s0,.07-.08.08a.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.33,30.13a7.57,7.57,0,0,0-.14-2.51,7.65,7.65,0,0,1,.3,2.52.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.07,29.16A7.55,7.55,0,0,0,25,26.65a7.87,7.87,0,0,1,.27,2.51.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,31.15a7.51,7.51,0,0,0-.18-2.5,7.85,7.85,0,0,1,.34,2.51s0,.07-.08.08a.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.73,35a7.76,7.76,0,0,0-.33-2.49A7.74,7.74,0,0,1,19.88,35a.09.09,0,0,1-.07.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.69,39a7.74,7.74,0,0,0-.48-2.47,7.72,7.72,0,0,1,.64,2.45.08.08,0,0,1-.07.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.8,42.33a7.39,7.39,0,0,0-.59-2.44A7.83,7.83,0,0,1,14,42.31a.09.09,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.79,45.6a7.57,7.57,0,0,0-.58-2.44,7.72,7.72,0,0,1,.73,2.42.08.08,0,0,1-.06.09.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.22,49.4a7.94,7.94,0,0,0-.51-2.46,7.81,7.81,0,0,1,.66,2.44.08.08,0,0,1-.06.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9,51.78a7.75,7.75,0,0,0-.45-2.47,7.73,7.73,0,0,1,.61,2.46.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,53.25a7.69,7.69,0,0,0-.49-2.46,7.66,7.66,0,0,1,.65,2.45.09.09,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.41,53.7A7.43,7.43,0,0,0,8,51.23a7.72,7.72,0,0,1,.6,2.46s0,.08-.07.09a.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.58,54.63a7.66,7.66,0,0,0-.51-2.46,8,8,0,0,1,.66,2.44.08.08,0,0,1-.06.09c-.05,0-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.56,51.7A7.73,7.73,0,0,0,11,49.24a8,8,0,0,1,.68,2.44.1.1,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.21,50.12a7.59,7.59,0,0,0-.55-2.45,7.55,7.55,0,0,1,.7,2.43.08.08,0,0,1-.06.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.58,48.64A7.6,7.6,0,0,0,12,46.19a7.56,7.56,0,0,1,.71,2.43.08.08,0,0,1-.06.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.24,44.13a7.61,7.61,0,0,0-.56-2.44,7.86,7.86,0,0,1,.72,2.42s0,.09-.07.09a.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.82,41a7.61,7.61,0,0,0-.56-2.44A7.51,7.51,0,0,1,15,40.93a.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.06,37.32a7.67,7.67,0,0,0-.52-2.46,7.56,7.56,0,0,1,.67,2.44.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.54,34.4a7.43,7.43,0,0,0-.44-2.47,8,8,0,0,1,.6,2.46.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.63,32.31a7.7,7.7,0,0,0-.45-2.47,7.92,7.92,0,0,1,.6,2.46.06.06,0,0,1-.06.08.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,33.13a7.79,7.79,0,0,0-.37-2.49,7.8,7.8,0,0,1,.53,2.48.08.08,0,0,1-.07.08c-.05,0-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.12,31.41a7.69,7.69,0,0,0-.37-2.48,7.67,7.67,0,0,1,.52,2.47.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.69,29.05a7.53,7.53,0,0,0-.25-2.5,7.53,7.53,0,0,1,.41,2.5.08.08,0,0,1-.07.08.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,24a7.57,7.57,0,0,0-.1-2.51,7.64,7.64,0,0,1,.25,2.52.08.08,0,0,1-.08.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.39,15.69a7.83,7.83,0,0,0,.12-2.51,7.67,7.67,0,0,1,0,2.53.07.07,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.38,12.64a7.83,7.83,0,0,0,.19-2.51,7.67,7.67,0,0,1,0,2.53.07.07,0,0,1-.08.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.43,11.62a7.6,7.6,0,0,0,.19-2.51,7.67,7.67,0,0,1,0,2.53.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.72,11.81a7.8,7.8,0,0,0,.21-2.5,8,8,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.13,14.7a7.8,7.8,0,0,0,.21-2.5,7.69,7.69,0,0,1-.06,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.06,21.39a7.74,7.74,0,0,0,.38-2.48,7.68,7.68,0,0,1-.23,2.52.08.08,0,0,1-.1.06.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.82,27.31A7.32,7.32,0,0,0,61.66,25a7.7,7.7,0,0,1-.7,2.43.08.08,0,1,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.76,33.6a7.85,7.85,0,0,0,1.47-2.05,8,8,0,0,1-1.35,2.15.08.08,0,1,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.32,40.69a7.53,7.53,0,0,0,2-1.51,7.78,7.78,0,0,1-1.92,1.65.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.93,45.83A7.65,7.65,0,0,0,72,44.46,7.56,7.56,0,0,1,70,46a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.48,52.76a7.8,7.8,0,0,0,2.12-1.33,7.58,7.58,0,0,1-2.06,1.47.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.44,58.21a7.71,7.71,0,0,0,2.13-1.33,7.9,7.9,0,0,1-2.06,1.47.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.71,61.66a7.33,7.33,0,0,0,2.14-1.31,7.5,7.5,0,0,1-2.07,1.45.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.9,64a7.69,7.69,0,0,0,2.15-1.29A7.7,7.7,0,0,1,72,64.17.07.07,0,1,1,71.9,64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.25,68.05a7.89,7.89,0,0,0,2.16-1.29,7.91,7.91,0,0,1-2.09,1.43.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.06,71.59a7.62,7.62,0,0,0,2.16-1.28,7.65,7.65,0,0,1-2.09,1.42.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.24,73.41a7.8,7.8,0,0,0,2.15-1.28,7.65,7.65,0,0,1-2.09,1.42.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.28,75.63a7.83,7.83,0,0,0,2.15-1.29,7.91,7.91,0,0,1-2.09,1.43.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,81.13a7.75,7.75,0,0,0,2.15-1.31,7.93,7.93,0,0,1-2.08,1.45.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.61,87.31A7.57,7.57,0,0,0,65.75,86a7.62,7.62,0,0,1-2.07,1.44.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.53,84.11a7.69,7.69,0,0,0,2.15-1.29,7.7,7.7,0,0,1-2.08,1.43.08.08,0,0,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.74,72a7.68,7.68,0,0,0,2.16-1.3,7.72,7.72,0,0,1-2.09,1.44.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.21,68.89a7.83,7.83,0,0,0,2.15-1.29A7.75,7.75,0,0,1,73.27,69a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.69,60.84a7.68,7.68,0,0,0,2.13-1.32A7.7,7.7,0,0,1,76.76,61a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,51a7.6,7.6,0,0,0,2.12-1.34,7.5,7.5,0,0,1-2,1.48.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.86,43.79A7.33,7.33,0,0,0,74,42.4a7.54,7.54,0,0,1-2,1.53.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.54,38.36a7.79,7.79,0,0,0,2-1.52,8,8,0,0,1-1.92,1.65.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.1,33a7.64,7.64,0,0,0,1.54-2,7.47,7.47,0,0,1-1.43,2.09.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.52,30.35a7.62,7.62,0,0,0,1.11-2.25,7.41,7.41,0,0,1-1,2.34.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.31,29.76a7.45,7.45,0,0,0,.86-2.36,7.73,7.73,0,0,1-.72,2.43s-.06.05-.1,0a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.31,28.59A7.54,7.54,0,0,0,59,26.17a7.81,7.81,0,0,1-.49,2.48.08.08,0,0,1-.1.05.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.9,26a7.72,7.72,0,0,0,.41-2.48A7.65,7.65,0,0,1,56.05,26a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.53,23.1a7.27,7.27,0,0,0,.24-2.5,7.68,7.68,0,0,1-.08,2.53.1.1,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.89,20.51a7.52,7.52,0,0,0,.19-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.91,18.23a7.83,7.83,0,0,0,.18-2.51,7.73,7.73,0,0,1,0,2.54.06.06,0,0,1-.08.06.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41,18.14a7.86,7.86,0,0,0,.17-2.51,7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.68,18a7.5,7.5,0,0,0,.16-2.5,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.28,18.39a7.77,7.77,0,0,0,.14-2.5,8,8,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.81,17.85a7.5,7.5,0,0,0,.1-2.5A7.67,7.67,0,0,1,33,17.88a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.8,18.79a7.83,7.83,0,0,0,0-2.51A7.61,7.61,0,0,1,30,18.8a.08.08,0,0,1-.08.08c-.05,0-.08,0-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.43,20.81a7.56,7.56,0,0,0-.05-2.51,7.92,7.92,0,0,1,.21,2.52.09.09,0,0,1-.09.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.49,26.34a7.83,7.83,0,0,0-.07-2.51,7.91,7.91,0,0,1,.23,2.52.1.1,0,0,1-.09.08.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,32.08a7.55,7.55,0,0,0-.11-2.51,7.62,7.62,0,0,1,.26,2.52.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.53,37.37a7.77,7.77,0,0,0-.19-2.5,7.83,7.83,0,0,1,.35,2.5.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.5,42.39a7.49,7.49,0,0,0-.16-2.5,7.31,7.31,0,0,1,.31,2.51.07.07,0,0,1-.07.07.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.85,45a7.54,7.54,0,0,0-.23-2.5A7.87,7.87,0,0,1,22,45a.07.07,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.17,47.69a7.3,7.3,0,0,0-.12-2.51,7.63,7.63,0,0,1,.28,2.52.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.78,46.71a7.5,7.5,0,0,0-.2-2.5,7.53,7.53,0,0,1,.36,2.5.07.07,0,0,1-.08.08s-.07,0-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.75,47.84a7.56,7.56,0,0,0-.16-2.51,7.6,7.6,0,0,1,.32,2.51.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.34,47.07a7.51,7.51,0,0,0-.27-2.49,7.8,7.8,0,0,1,.43,2.49.09.09,0,0,1-.08.09.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.21,48.23a7.78,7.78,0,0,0-.22-2.5,8.11,8.11,0,0,1,.38,2.5.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.28,46.06a7.56,7.56,0,0,0,0-2.51,7.62,7.62,0,0,1,.2,2.52.09.09,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.34,44a7.55,7.55,0,0,0,0-2.51A7.89,7.89,0,0,1,27.5,44a.09.09,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.9,35.56a7.57,7.57,0,0,0-.17-2.51,7.88,7.88,0,0,1,.33,2.51.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.15,28.62a7.5,7.5,0,0,0-.19-2.5,7.53,7.53,0,0,1,.35,2.5.08.08,0,0,1-.08.08s-.07,0-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.82,22.57a7.74,7.74,0,0,0-.3-2.49A7.78,7.78,0,0,1,22,22.57a.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.74,19.1a7.78,7.78,0,0,0-.21-2.5,7.81,7.81,0,0,1,.37,2.5.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,14.64a7.49,7.49,0,0,0-.07-2.5,7.61,7.61,0,0,1,.22,2.52.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.31,13a7.3,7.3,0,0,0,0-2.51A7.62,7.62,0,0,1,26.47,13a.09.09,0,0,1-.08.08.1.1,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,15.08a7.56,7.56,0,0,0-.16-2.51,7.66,7.66,0,0,1,.32,2.52.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.77,21.78a7.61,7.61,0,0,0-.56-2.44,7.86,7.86,0,0,1,.72,2.42.07.07,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.26,33.32a7.56,7.56,0,0,0-.69-2.41,7.75,7.75,0,0,1,.85,2.39.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.37,42.42A7.6,7.6,0,0,0,9.77,40a7.79,7.79,0,0,1,.76,2.41.09.09,0,0,1-.07.1.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.49,44.53a7.88,7.88,0,0,0-.56-2.45,7.56,7.56,0,0,1,.71,2.43.08.08,0,0,1-.06.09.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.31,46.77a7.67,7.67,0,0,0-.52-2.46,7.84,7.84,0,0,1,.68,2.44.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9,48.15a7.71,7.71,0,0,0-.5-2.47,7.66,7.66,0,0,1,.65,2.45.08.08,0,0,1-.06.09A.08.08,0,0,1,9,48.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.71,47.79a7.59,7.59,0,0,0-.55-2.45,7.62,7.62,0,0,1,.7,2.43.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.57,44.37a7.6,7.6,0,0,0-.6-2.44,7.79,7.79,0,0,1,.76,2.41.09.09,0,0,1-.07.1.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.71,37.84a7.84,7.84,0,0,0-.62-2.43,7.55,7.55,0,0,1,.77,2.41.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.59,28.47A7.57,7.57,0,0,0,15,26a7.83,7.83,0,0,1,.78,2.41.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.88,20.34a8,8,0,0,0-.5-2.46A7.66,7.66,0,0,1,19,20.33a.07.07,0,0,1-.07.08.06.06,0,0,1-.08-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,16.44a7.79,7.79,0,0,0-.24-2.5,7.88,7.88,0,0,1,.4,2.5.09.09,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,13.26a7.83,7.83,0,0,0,0-2.51,7.66,7.66,0,0,1,.12,2.53.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.37,12.43a7.5,7.5,0,0,0,.14-2.5,7.67,7.67,0,0,1,0,2.53.09.09,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.07,10.85a7.52,7.52,0,0,0,.19-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.93,10.48A7.79,7.79,0,0,0,41.12,8a7.68,7.68,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.18,9.86a7.58,7.58,0,0,0,.2-2.51,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,13.52A7.84,7.84,0,0,0,55.25,11a7.41,7.41,0,0,1-.06,2.53.07.07,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.45,18.25A7.64,7.64,0,0,0,63,15.8a7.87,7.87,0,0,1-.39,2.5.09.09,0,0,1-.1.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,20.15a7.79,7.79,0,0,0,1-2.32,7.75,7.75,0,0,1-.85,2.39.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.67,24.74a7.85,7.85,0,0,0,1.55-2,7.72,7.72,0,0,1-1.44,2.09.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.94,31.06a7.67,7.67,0,0,0,1.76-1.79A7.57,7.57,0,0,1,70,31.18a.08.08,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.61,36.29a7.67,7.67,0,0,0,2-1.56,7.78,7.78,0,0,1-1.88,1.69.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.78,41.7a7.94,7.94,0,0,0,2.07-1.42,7.78,7.78,0,0,1-2,1.55.08.08,0,0,1-.1,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.87,45.67A7.52,7.52,0,0,0,70,44.29a7.71,7.71,0,0,1-2,1.52.08.08,0,1,1-.08-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.11,47a7.62,7.62,0,0,0,2.11-1.36,7.74,7.74,0,0,1-2,1.5.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.31,43.08a7.92,7.92,0,0,0,2.08-1.4,7.54,7.54,0,0,1-2,1.53.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.38,34.28a7.39,7.39,0,0,0,1.94-1.58,7.58,7.58,0,0,1-1.86,1.71.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75,25.83a7.68,7.68,0,0,0,1.82-1.73A7.55,7.55,0,0,1,75.12,26a.08.08,0,1,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.51,23.29a7.52,7.52,0,0,0,1.8-1.76,7.61,7.61,0,0,1-1.7,1.88.08.08,0,0,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.08,20.57a7.91,7.91,0,0,0,1.74-1.82,7.93,7.93,0,0,1-1.63,1.93.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.38,18A7.87,7.87,0,0,0,84,16a7.89,7.89,0,0,1-1.51,2,.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M84.44,16.53a7.48,7.48,0,0,0,1.54-2,7.85,7.85,0,0,1-1.42,2.1.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M85.11,14.82a7.6,7.6,0,0,0,1.42-2.08,7.78,7.78,0,0,1-1.3,2.18.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.26,14.4a7.33,7.33,0,0,0,1.39-2.09,7.71,7.71,0,0,1-1.26,2.19.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.59,14.06a7.71,7.71,0,0,0,1.33-2.13,8.05,8.05,0,0,1-1.21,2.23.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.91,13.06a7.77,7.77,0,0,0,1-2.29,8,8,0,0,1-.9,2.36.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.26,11.94a7.66,7.66,0,0,0,.67-2.42A7.48,7.48,0,0,1,68.4,12a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.66,10a7.82,7.82,0,0,0,.24-2.5A7.42,7.42,0,0,1,60.81,10a.07.07,0,0,1-.09.06.06.06,0,0,1-.06-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.37,8.81a7.8,7.8,0,0,0,.21-2.5,8,8,0,0,1-.06,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,9.22a7.87,7.87,0,0,0,.19-2.51,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.09.07A.1.1,0,0,1,40,9.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.56,13.63a7.56,7.56,0,0,0,.07-2.51,7.67,7.67,0,0,1,.08,2.53.08.08,0,0,1-.08.07c-.05,0-.08,0-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.25,16.56a7.48,7.48,0,0,0-.32-2.49,7.53,7.53,0,0,1,.47,2.49.07.07,0,0,1-.07.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.14,20.59a7.83,7.83,0,0,0-.69-2.41,7.73,7.73,0,0,1,.84,2.39.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.78,23.75a7.87,7.87,0,0,0-.68-2.42,7.55,7.55,0,0,1,.83,2.39.07.07,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.17,26.34a7.69,7.69,0,0,0-.49-2.46,7.93,7.93,0,0,1,.65,2.45.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,26.54a7.49,7.49,0,0,0-.33-2.49,7.76,7.76,0,0,1,.49,2.48.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.13,21.11A7.56,7.56,0,0,0,25,18.6a7.64,7.64,0,0,1,.29,2.52.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.37,20.06a7.83,7.83,0,0,0,0-2.51,7.67,7.67,0,0,1,.12,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.7,17.71a7.79,7.79,0,0,0,.15-2.5,8,8,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.08,16.29a7.51,7.51,0,0,0,.17-2.5,7.68,7.68,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.79,18A7.87,7.87,0,0,0,48,15.53a8,8,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.81,20.86a7.8,7.8,0,0,0,.2-2.5,8,8,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.56,23a7.75,7.75,0,0,0,.32-2.49A8,8,0,0,1,55.71,23a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.24,24.35a7.43,7.43,0,0,0,.44-2.47,7.64,7.64,0,0,1-.29,2.51.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.69,25.72a7.57,7.57,0,0,0,.58-2.44,7.58,7.58,0,0,1-.43,2.49.07.07,0,0,1-.1.05.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.43,34.51A7.3,7.3,0,0,0,35.49,32a7.66,7.66,0,0,1,.1,2.53.09.09,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.83,32.38a7.84,7.84,0,0,0,.06-2.51A7.61,7.61,0,0,1,34,32.39a.08.08,0,0,1-.08.08.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.29,33.64a7.3,7.3,0,0,0,.06-2.51,7.68,7.68,0,0,1,.1,2.53c0,.05,0,.08-.09.07a.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.84,33.27a7.3,7.3,0,0,0,.06-2.51,7.67,7.67,0,0,1,.1,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.16,32a7.83,7.83,0,0,0,.06-2.51,7.68,7.68,0,0,1,.09,2.53.07.07,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.18,31a7.56,7.56,0,0,0,.07-2.51A8,8,0,0,1,34.34,31a.09.09,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.78,32.16a7.55,7.55,0,0,0,.07-2.51,8,8,0,0,1,.09,2.53s0,.08-.09.07a.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.2,30.58a7.84,7.84,0,0,0,.07-2.51,7.67,7.67,0,0,1,.08,2.53.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.6,29.15a7.56,7.56,0,0,0,.07-2.51,7.66,7.66,0,0,1,.08,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.31,28.37a7.77,7.77,0,0,0,.09-2.5,8,8,0,0,1,.07,2.53.09.09,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.51,26.65a7.83,7.83,0,0,0,.09-2.51,8,8,0,0,1,.06,2.53.07.07,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.78,26.28a7.5,7.5,0,0,0,.1-2.5,7.67,7.67,0,0,1,0,2.53.06.06,0,0,1-.08.06.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.89,24.43A7.3,7.3,0,0,0,38,21.92a7.73,7.73,0,0,1,0,2.54.07.07,0,0,1-.09.06.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,24.29a7.56,7.56,0,0,0,.13-2.51,8,8,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.24,23.3a7.57,7.57,0,0,0,.13-2.51,8,8,0,0,1,0,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41,25.17a7.84,7.84,0,0,0,.12-2.51,7.41,7.41,0,0,1,0,2.53.08.08,0,0,1-.09.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.79,26.49a7.78,7.78,0,0,0,.12-2.5,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.23,25.61a7.78,7.78,0,0,0,.12-2.5,7.67,7.67,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.39,27.57a7.55,7.55,0,0,0,.11-2.51,7.73,7.73,0,0,1,0,2.54.07.07,0,0,1-.09.06.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.2,29.38a7.56,7.56,0,0,0,.09-2.51,7.41,7.41,0,0,1,.06,2.53.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.43,30.92a7.83,7.83,0,0,0,.09-2.51,8,8,0,0,1,.07,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.58,35.72a7.84,7.84,0,0,0,.07-2.51,7.66,7.66,0,0,1,.08,2.53.08.08,0,0,1-.08.07.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.38,37.47A7.55,7.55,0,0,0,37.45,35a8,8,0,0,1,.09,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.67,39.31a7.82,7.82,0,0,0,.06-2.51,7.68,7.68,0,0,1,.09,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,41A7.56,7.56,0,0,0,36,38.49a7.67,7.67,0,0,1,.1,2.53.08.08,0,0,1-.08.07A.07.07,0,0,1,36,41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.68,43.35a7.56,7.56,0,0,0,0-2.51,7.89,7.89,0,0,1,.12,2.52.09.09,0,0,1-.09.08.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.82,45.74a7.83,7.83,0,0,0,0-2.51A8,8,0,0,1,35,45.76a.07.07,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.29,47.75a7.83,7.83,0,0,0,0-2.51,7.66,7.66,0,0,1,.13,2.53.08.08,0,0,1-.08.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.75,48.45a7.55,7.55,0,0,0,0-2.51,7.66,7.66,0,0,1,.14,2.53.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.15,49.7a7.55,7.55,0,0,0,0-2.51,7.9,7.9,0,0,1,.15,2.52.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.49,51.29a7.83,7.83,0,0,0,0-2.51,7.62,7.62,0,0,1,.15,2.52.08.08,0,0,1-.08.08.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.33,50.79a7.55,7.55,0,0,0,0-2.51,7.67,7.67,0,0,1,.17,2.53.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.25,52.62a7.55,7.55,0,0,0,0-2.51,7.41,7.41,0,0,1,.18,2.53.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.07,53a7.55,7.55,0,0,0,0-2.51A8,8,0,0,1,30.23,53a.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,51.87a7.55,7.55,0,0,0,0-2.51,7.61,7.61,0,0,1,.18,2.52A.08.08,0,0,1,29,52a.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.19,48.08a7.3,7.3,0,0,0,0-2.51,7.61,7.61,0,0,1,.16,2.52.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.68,45.93a7.83,7.83,0,0,0,0-2.51A8,8,0,0,1,31.84,46a.09.09,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.1,44.88a7.83,7.83,0,0,0,0-2.51,7.89,7.89,0,0,1,.13,2.52.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.82,43.23a7.56,7.56,0,0,0,0-2.51A8,8,0,0,1,34,43.25a.08.08,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.55,41.19a7.83,7.83,0,0,0,0-2.51,7.67,7.67,0,0,1,.11,2.53.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.27,39.44a7.83,7.83,0,0,0,.06-2.51,8,8,0,0,1,.1,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.77,38.18a7.55,7.55,0,0,0,.06-2.51,7.68,7.68,0,0,1,.1,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.19,37.24a7.3,7.3,0,0,0,.06-2.51,7.61,7.61,0,0,1,.1,2.52.09.09,0,0,1-.09.08.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.76,36a7.84,7.84,0,0,0,.07-2.51,7.67,7.67,0,0,1,.08,2.53.08.08,0,0,1-.08.07s-.08-.05-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.51,34.61a7.56,7.56,0,0,0,.07-2.51,7.67,7.67,0,0,1,.08,2.53.08.08,0,0,1-.08.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.82,34.17a7.55,7.55,0,0,0,.07-2.51A8,8,0,0,1,40,34.19a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.13,32.86a7.56,7.56,0,0,0,.08-2.51,7.68,7.68,0,0,1,.08,2.53.08.08,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.4,34.08a7.84,7.84,0,0,0,.07-2.51,7.67,7.67,0,0,1,.08,2.53.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.39,36.78a7.83,7.83,0,0,0,.06-2.51,7.68,7.68,0,0,1,.1,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.64,42.75a7.55,7.55,0,0,0,0-2.51,8,8,0,0,1,.12,2.53s0,.08-.09.07a.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.77,45.89a7.55,7.55,0,0,0,0-2.51,7.68,7.68,0,0,1,.14,2.53.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.45,47a7.83,7.83,0,0,0,0-2.51A7.68,7.68,0,0,1,28.6,47a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.53,48.9a7.55,7.55,0,0,0,0-2.51,7.63,7.63,0,0,1,.17,2.52.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.9,50.44a7.55,7.55,0,0,0,0-2.51,7.69,7.69,0,0,1,.17,2.53.08.08,0,0,1-.15,0Z"/>
      </g>}
      {r_type_hair >= 3 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.6,109.35c.72-.4,1.35-1,2.06-1.44-.68.5-1.24,1.13-2,1.6a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.35,106.12c.72-.42,1.33-1,2-1.49-.67.52-1.21,1.17-1.92,1.66a.1.1,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.35,109.74c.71-.42,1.31-1,2-1.51-.66.52-1.2,1.18-1.9,1.68a.1.1,0,1,1-.11-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.75,106.94c.68-.48,1.22-1.14,1.88-1.67-.61.58-1.1,1.27-1.75,1.83a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.68,108.6c.73-.39,1.36-1,2.08-1.42-.68.5-1.25,1.12-2,1.59a.1.1,0,1,1-.11-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.27,107.36c.73-.4,1.35-1,2.06-1.43-.67.5-1.24,1.13-2,1.6a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.15,109.63c.67-.49,1.22-1.15,1.87-1.68-.61.58-1.09,1.27-1.75,1.83a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.85,107.6c.71-.42,1.31-1,2-1.51-.66.53-1.2,1.18-1.91,1.67a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.62,108.35c.7-.44,1.28-1.07,2-1.56-.64.54-1.16,1.21-1.85,1.73a.11.11,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.42,108.15c.65-.52,1.16-1.21,1.78-1.77-.58.61-1,1.33-1.65,1.92a.1.1,0,0,1-.14-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.92,106.22c.69-.46,1.27-1.1,1.94-1.6-.63.55-1.14,1.23-1.82,1.76a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.26,109c.59-.57,1-1.31,1.61-1.93-.52.66-.9,1.42-1.47,2.06a.09.09,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.77,104.93c.69-.46,1.26-1.11,1.92-1.62-.62.56-1.13,1.24-1.8,1.78a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.59,106.55c.59-.58,1-1.31,1.6-1.94-.52.67-.89,1.43-1.45,2.08a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.26,104.89c.62-.55,1.11-1.26,1.71-1.84-.56.63-1,1.37-1.57,2a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.3,107c.46-.69.75-1.49,1.18-2.22-.37.76-.59,1.57-1,2.32a.1.1,0,0,1-.18-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.31,107.67c.34-.75.48-1.6.78-2.38-.24.8-.31,1.65-.6,2.46a.09.09,0,0,1-.12.06.1.1,0,0,1-.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.76,104.81c.46-.68.75-1.49,1.19-2.21-.38.75-.6,1.57-1,2.32a.1.1,0,0,1-.13,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.57,105.8c.06-.82-.1-1.66-.08-2.51.05.84.27,1.66.28,2.52a.11.11,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.68,104.44c.14-.82.06-1.67.14-2.51,0,.84.13,1.68.06,2.53a.1.1,0,0,1-.11.1.12.12,0,0,1-.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.93,103.14c.23-.79.24-1.65.42-2.47-.12.83-.06,1.67-.22,2.52a.11.11,0,0,1-.12.08.1.1,0,0,1-.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.89,104.76c-.42-.71-1-1.32-1.49-2,.52.66,1.16,1.21,1.65,1.92a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.07,103.41c-.34-.75-.89-1.42-1.28-2.16.45.71,1,1.32,1.46,2.07a.1.1,0,0,1,0,.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.31,101.67c0-.83-.3-1.65-.39-2.48.16.82.48,1.61.59,2.46a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.61,102.75c-.71-.42-1.53-.65-2.28-1,.77.33,1.6.49,2.38.87a.11.11,0,0,1,0,.13.1.1,0,0,1-.13.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,101.49c-.49-.67-1.15-1.21-1.68-1.86.58.61,1.28,1.08,1.84,1.74a.1.1,0,1,1-.15.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.26,101c-.76-.33-1.61-.45-2.4-.73.81.22,1.66.27,2.47.54a.11.11,0,0,1,.07.13.1.1,0,0,1-.13.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.32,99.83c-.75-.34-1.59-.48-2.38-.79.81.24,1.65.31,2.46.61a.09.09,0,0,1,.06.12.1.1,0,0,1-.13.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.57,99.17c-.69-.45-1.51-.73-2.23-1.15.75.37,1.58.57,2.33,1a.09.09,0,0,1,0,.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.23,100.28c-.82-.07-1.67.08-2.51.06.84,0,1.66-.26,2.52-.26a.11.11,0,0,1,.1.1.1.1,0,0,1-.1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.19,99.16c-.83-.06-1.67.1-2.51.08.84,0,1.65-.27,2.51-.28a.1.1,0,0,1,.1.1.09.09,0,0,1-.09.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.23,98c-.83,0-1.67.11-2.51.1.84-.06,1.65-.29,2.51-.3a.1.1,0,0,1,.1.1.09.09,0,0,1-.09.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.53,96.9c-.83-.08-1.67.06-2.51,0,.84,0,1.66-.24,2.52-.22a.09.09,0,0,1,.09.1.09.09,0,0,1-.1.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.79,97.08c-.82.11-1.61.45-2.43.62.8-.24,1.55-.63,2.39-.82a.1.1,0,0,1,.12.08.09.09,0,0,1-.07.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.87,95.83c-.82.12-1.61.45-2.43.62.8-.23,1.55-.62,2.39-.81a.09.09,0,0,1,.12.07.1.1,0,0,1-.07.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.25,94.56c-.82.11-1.61.43-2.44.58.81-.21,1.57-.59,2.41-.77a.1.1,0,0,1,.12.08.1.1,0,0,1-.08.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.56,93.94c-.8.21-1.54.64-2.34.91.77-.33,1.47-.81,2.28-1.1a.08.08,0,0,1,.12.06.09.09,0,0,1-.05.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.3,92.53c-.81.19-1.56.6-2.37.84.78-.3,1.49-.76,2.31-1a.1.1,0,0,1,.13.06.11.11,0,0,1-.07.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.57,91.26c-.79.21-1.54.64-2.34.91.78-.33,1.47-.81,2.28-1.1a.1.1,0,0,1,.13.06.1.1,0,0,1-.06.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.88,89.75c-.8.18-1.56.59-2.37.83.79-.31,1.5-.76,2.32-1a.1.1,0,0,1,.13.06.1.1,0,0,1-.07.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.05,89c-.8.2-1.56.62-2.36.87.78-.32,1.49-.78,2.3-1.06a.11.11,0,0,1,.13.07.09.09,0,0,1-.06.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.61,88.11c-.8.19-1.56.6-2.36.85.78-.31,1.49-.77,2.3-1a.1.1,0,0,1,.13.06.09.09,0,0,1-.06.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,86.9c-.81.17-1.58.56-2.39.78.79-.28,1.52-.73,2.34-1a.09.09,0,0,1,.12.07.09.09,0,0,1-.06.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.69,85.62c-.81.15-1.59.51-2.41.7.8-.25,1.54-.67,2.37-.89a.1.1,0,0,1,.12.07.09.09,0,0,1-.07.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.08,84c-.82.13-1.61.48-2.43.65.81-.24,1.55-.64,2.39-.84a.11.11,0,0,1,.12.07.1.1,0,0,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.45,82.69c-.82.1-1.61.42-2.44.57.81-.22,1.57-.59,2.41-.77a.11.11,0,0,1,.12.08.1.1,0,0,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.15,81.14c-.83.06-1.64.34-2.47.45.82-.18,1.59-.52,2.44-.64a.1.1,0,0,1,.12.08.1.1,0,0,1-.08.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.85,80c-.83,0-1.65.27-2.49.34.83-.14,1.62-.45,2.47-.54a.09.09,0,0,1,.11.09.1.1,0,0,1-.09.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.47,78.49c-.82,0-1.66.18-2.5.21.84-.09,1.64-.36,2.5-.41a.1.1,0,0,1,.1.1.09.09,0,0,1-.09.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.59,76.46c-.83-.08-1.67.06-2.51,0,.84,0,1.66-.24,2.52-.22a.1.1,0,0,1,0,.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.2,74.61c-.82-.14-1.67-.06-2.51-.15.84,0,1.67-.12,2.53,0a.09.09,0,0,1,.09.1.09.09,0,0,1-.11.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.88,72.8c-.8-.19-1.66-.18-2.49-.33.84.09,1.68,0,2.53.14a.1.1,0,0,1,.08.11.11.11,0,0,1-.11.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.48,71.05c-.79-.26-1.64-.31-2.46-.52.83.15,1.68.13,2.51.33a.1.1,0,0,1,.08.12.11.11,0,0,1-.12.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.08,68.34c-.74-.36-1.59-.53-2.36-.85.8.26,1.64.35,2.44.67a.09.09,0,0,1,.06.12.1.1,0,0,1-.13.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.25,65.2c-.71-.43-1.53-.67-2.27-1.07.77.34,1.6.51,2.37.9a.1.1,0,0,1-.09.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.56,62.85c-.71-.42-1.53-.66-2.28-1,.77.32,1.6.49,2.38.86a.1.1,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.8,60.77c-.71-.42-1.54-.65-2.29-1,.78.32,1.61.48,2.38.86a.1.1,0,0,1,.05.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.21,58.84c-.71-.42-1.53-.66-2.28-1.05.78.33,1.6.5,2.38.88a.11.11,0,0,1,0,.13.1.1,0,0,1-.13.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.29,56.88c-.7-.43-1.52-.68-2.26-1.08.77.34,1.59.52,2.36.91a.09.09,0,0,1,0,.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.44,55.08c-.7-.45-1.51-.71-2.25-1.12.77.35,1.59.54,2.35.95a.1.1,0,1,1-.09.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.37,53.38c-.69-.45-1.5-.73-2.23-1.16.76.37,1.58.58,2.33,1a.09.09,0,0,1,0,.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.67,51.79c-.68-.48-1.48-.78-2.19-1.22.74.38,1.56.61,2.3,1a.11.11,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.41,50.31c-.65-.5-1.44-.83-2.14-1.31.73.42,1.54.68,2.26,1.15a.11.11,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.32,48.64c-.64-.52-1.42-.88-2.1-1.38.72.45,1.51.73,2.22,1.22a.11.11,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.09,46.86c-.61-.55-1.38-.93-2-1.46.7.47,1.48.79,2.17,1.31a.1.1,0,1,1-.12.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,45.39c-.58-.59-1.31-1-1.93-1.61.66.52,1.42.9,2.06,1.47a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.29,44.13c-.56-.61-1.29-1.07-1.89-1.66.65.54,1.4.93,2,1.52a.1.1,0,0,1-.14.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.28,42.6c-.49-.66-1.16-1.2-1.69-1.85.58.6,1.28,1.08,1.84,1.73a.1.1,0,0,1-.15.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.62,41.09c-.46-.69-1.1-1.26-1.6-1.93.56.63,1.23,1.14,1.76,1.82a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,40.15c-.34-.75-.89-1.41-1.29-2.15.46.71,1.05,1.31,1.47,2.06a.1.1,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.06,38.87c-.33-.76-.86-1.44-1.24-2.19.44.72,1,1.34,1.42,2.1a.11.11,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.63,37.68c-.25-.79-.71-1.52-1-2.31.36.76.87,1.44,1.19,2.24a.1.1,0,0,1,0,.13.1.1,0,0,1-.13-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.24,37.76c-.18-.81-.57-1.57-.8-2.38.29.79.74,1.51,1,2.33a.1.1,0,0,1-.07.12.09.09,0,0,1-.12-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.52,35.7c-.21-.8-.64-1.54-.91-2.34.33.77.81,1.47,1.1,2.28a.09.09,0,0,1-.06.12.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.89,36.07c-.17-.81-.55-1.58-.78-2.39.29.79.73,1.51,1,2.34a.09.09,0,0,1-.06.12.1.1,0,0,1-.13-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.17,34.27c-.2-.8-.61-1.56-.85-2.36.31.78.77,1.49,1,2.31a.09.09,0,0,1-.07.12.09.09,0,0,1-.12-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.48,34.58c-.15-.81-.52-1.58-.72-2.4.26.8.69,1.53.92,2.36a.1.1,0,0,1-.07.12.11.11,0,0,1-.13-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.34,36c-.13-.82-.47-1.6-.65-2.43.24.81.65,1.55.85,2.39a.1.1,0,0,1-.08.12.11.11,0,0,1-.12-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.57,32c-.12-.81-.45-1.6-.62-2.43.23.81.62,1.56.81,2.4a.1.1,0,0,1-.07.12.1.1,0,0,1-.12-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.15,34.13c-.07-.83-.37-1.63-.5-2.46.2.82.55,1.58.7,2.43a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,34.53c-.1-.82-.43-1.61-.59-2.44.22.81.61,1.56.79,2.41a.09.09,0,0,1-.08.11.11.11,0,0,1-.12-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.26,32.91c-.16-.81-.53-1.58-.74-2.4.27.8.7,1.53.93,2.36a.09.09,0,0,1-.06.12.11.11,0,0,1-.13-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.46,33.35c-.12-.82-.46-1.61-.63-2.43.23.81.63,1.55.82,2.39a.09.09,0,0,1-.07.12.1.1,0,0,1-.12-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.73,32.74c-.09-.82-.39-1.62-.53-2.45.2.81.57,1.58.72,2.42a.1.1,0,1,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.62,35.85c-.09-.82-.4-1.62-.54-2.45.21.81.57,1.57.74,2.42a.1.1,0,0,1-.08.11.1.1,0,0,1-.12-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.75,34c.58-.6,1-1.34,1.55-2-.5.68-.85,1.45-1.4,2.11a.1.1,0,0,1-.15-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.87,35.2c.68-.46,1.25-1.1,1.92-1.61-.63.56-1.13,1.24-1.81,1.77a.1.1,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.61,32.58c.52-.65.87-1.43,1.35-2.12-.43.73-.71,1.52-1.19,2.24a.11.11,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.05,34c.61-.57,1.06-1.29,1.64-1.9-.53.65-.92,1.4-1.5,2a.1.1,0,0,1-.14-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.87,32.92c.57-.6,1-1.35,1.53-2-.49.68-.84,1.45-1.38,2.12a.1.1,0,0,1-.16-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.31,35.3c.65-.51,1.17-1.19,1.8-1.75-.59.61-1,1.32-1.67,1.9a.1.1,0,0,1-.14-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.33,32.81c.49-.67.8-1.46,1.26-2.17-.4.74-.65,1.55-1.1,2.28a.1.1,0,0,1-.14,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.1,36.42c.71-.42,1.31-1,2-1.5-.66.52-1.2,1.17-1.91,1.66a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.74,35.1c.57-.59,1-1.34,1.55-2-.5.67-.86,1.44-1.41,2.11a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.39,33.89c.52-.64.88-1.42,1.38-2.1-.44.72-.73,1.51-1.22,2.22a.11.11,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.59,35.13c.67-.49,1.2-1.16,1.85-1.7-.6.59-1.08,1.29-1.72,1.86a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,35.2c.61-.55,1.09-1.27,1.68-1.86-.55.64-1,1.38-1.55,2a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.63,33.92c.63-.53,1.13-1.23,1.75-1.8-.57.62-1,1.35-1.62,2a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.39,36.43c.67-.48,1.22-1.13,1.88-1.66-.61.57-1.1,1.27-1.76,1.82a.1.1,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.77,36.3c.64-.53,1.14-1.22,1.75-1.8-.57.62-1,1.35-1.62,2a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.78,36.58c.7-.43,1.29-1.06,2-1.54-.64.53-1.17,1.2-1.86,1.71a.1.1,0,0,1-.12-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.19,37.45c.7-.45,1.27-1.09,2-1.58-.64.54-1.15,1.21-1.84,1.74a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.5,38.12c.73-.38,1.36-1,2.09-1.39-.69.49-1.26,1.11-2,1.56a.1.1,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.18,38.64c.76-.33,1.43-.86,2.18-1.23-.72.43-1.34,1-2.1,1.41a.1.1,0,0,1-.09-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,39.78c.77-.3,1.47-.8,2.23-1.15-.73.41-1.38.95-2.15,1.33a.1.1,0,0,1-.13-.05.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.29,40.49c.78-.3,1.47-.8,2.24-1.15-.74.41-1.38,1-2.16,1.33a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.32,41.46c.79-.25,1.51-.7,2.3-1-.76.36-1.44.87-2.24,1.18a.1.1,0,0,1-.13,0,.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,41.94c.8-.2,1.55-.62,2.35-.88-.78.32-1.48.79-2.29,1.07a.1.1,0,0,1-.13-.06A.09.09,0,0,1,61,42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.45,43.05c.8-.21,1.55-.63,2.35-.89-.78.32-1.48.79-2.29,1.08a.1.1,0,0,1-.13-.06.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.42,43.58c.81-.17,1.58-.55,2.39-.77-.79.28-1.51.72-2.34,1a.1.1,0,0,1-.12-.07.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.05,44.38c.81-.17,1.58-.55,2.39-.76-.79.28-1.52.71-2.34,1A.12.12,0,0,1,59,44.5a.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.54,44.44c.82-.13,1.6-.47,2.43-.65-.81.24-1.55.65-2.39.85a.11.11,0,0,1-.12-.07.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.19,45.79c.82-.11,1.61-.45,2.43-.61-.8.23-1.55.62-2.39.81a.1.1,0,0,1-.12-.08.11.11,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.17,46c.82-.09,1.62-.39,2.45-.53-.81.2-1.58.57-2.42.73a.1.1,0,0,1-.12-.08.11.11,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.13,47.7c.82-.08,1.62-.38,2.46-.51-.82.2-1.59.55-2.44.7a.09.09,0,0,1-.11-.07.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.13,47.86c.83,0,1.64-.31,2.48-.4-.83.16-1.61.48-2.46.6A.1.1,0,0,1,63,48a.09.09,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.33,49.12c.82,0,1.64-.3,2.48-.4-.83.16-1.61.49-2.47.6a.1.1,0,0,1-.11-.09.11.11,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.78,49.86c.83,0,1.65-.26,2.49-.32-.83.13-1.62.43-2.47.52a.1.1,0,0,1-.11-.09.09.09,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,51.11c.83,0,1.65-.26,2.49-.33-.83.14-1.62.44-2.48.53a.1.1,0,0,1-.11-.09.12.12,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.06,52.29c.83,0,1.66-.21,2.5-.26-.83.11-1.63.39-2.49.46A.11.11,0,0,1,62,52.4a.1.1,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.47,53c.83,0,1.65-.24,2.49-.31-.83.13-1.62.43-2.48.51a.1.1,0,0,1-.11-.09.12.12,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.67,54.18c.83,0,1.65-.26,2.49-.33-.83.13-1.62.43-2.48.53a.12.12,0,0,1-.11-.09.11.11,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.52,54.46c.83,0,1.66-.21,2.5-.25-.83.11-1.63.38-2.49.44a.09.09,0,0,1-.11-.09.1.1,0,0,1,.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.65,55.37c.83,0,1.66-.17,2.5-.19-.83.09-1.64.35-2.5.39a.1.1,0,0,1-.1-.09.11.11,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.33,55.86c.83,0,1.65-.24,2.49-.29-.83.12-1.62.41-2.48.49a.1.1,0,1,1,0-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,56.53c.82,0,1.66-.19,2.5-.23-.84.1-1.64.37-2.5.43a.1.1,0,0,1-.1-.09.09.09,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.67,57.16c.83,0,1.65-.27,2.49-.34-.83.14-1.62.44-2.48.54a.12.12,0,0,1-.11-.09.11.11,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.15,58c.83,0,1.65-.23,2.49-.29-.83.12-1.62.41-2.48.49a.12.12,0,0,1-.11-.09.11.11,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.61,58.88c.83,0,1.66-.22,2.5-.27-.83.11-1.63.39-2.49.46a.1.1,0,0,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,59.51c.83,0,1.65-.29,2.48-.37-.83.15-1.61.46-2.46.57a.09.09,0,0,1-.11-.09.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.93,60.33c.82,0,1.65-.26,2.48-.33-.83.13-1.61.44-2.47.53a.1.1,0,1,1,0-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.43,61.31c.82-.05,1.64-.32,2.47-.42-.82.16-1.6.49-2.45.61a.09.09,0,0,1-.11-.08.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.36,62c.83,0,1.65-.3,2.49-.39-.83.16-1.61.48-2.47.59a.11.11,0,0,1-.11-.09.1.1,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.17,63.22c.82-.07,1.63-.36,2.46-.47-.82.18-1.59.53-2.44.66a.1.1,0,1,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.46,64c.82-.1,1.62-.41,2.45-.56-.82.21-1.58.58-2.42.75a.1.1,0,0,1-.12-.08.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.25,65.26c.82-.1,1.62-.43,2.44-.58-.81.22-1.56.6-2.41.77a.09.09,0,0,1-.11-.07.09.09,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.85,65.85c.81-.15,1.59-.51,2.41-.7-.8.26-1.54.67-2.37.89a.09.09,0,0,1-.12-.07.11.11,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.39,67.07c.82-.14,1.6-.49,2.42-.68-.8.25-1.54.66-2.38.87a.11.11,0,0,1-.12-.07.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,68.12c.82-.16,1.59-.54,2.4-.75-.79.27-1.52.71-2.35.94a.09.09,0,0,1-.12-.06.1.1,0,0,1,.07-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,69.74c.81-.17,1.57-.57,2.38-.79-.78.29-1.5.73-2.33,1a.09.09,0,0,1-.12-.06.09.09,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.06,71.24c.8-.21,1.55-.63,2.35-.89-.78.32-1.48.79-2.3,1.07a.09.09,0,0,1-.12-.06.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.37,70.33c.79-.25,1.51-.71,2.3-1-.76.37-1.44.88-2.23,1.2a.1.1,0,1,1-.08-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.07,72c.8-.21,1.55-.63,2.35-.9-.78.33-1.48.81-2.29,1.09a.09.09,0,0,1-.12-.06.09.09,0,0,1,.05-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.38,70.45c.77-.31,1.46-.82,2.22-1.18-.73.42-1.37,1-2.14,1.36a.11.11,0,0,1-.13,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.63,71.56c.77-.29,1.47-.79,2.24-1.13-.74.4-1.39.95-2.17,1.31a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.81,70c.78-.29,1.48-.78,2.25-1.11-.74.39-1.39.93-2.17,1.29a.09.09,0,0,1-.13,0s0-.1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.88,72.72c.79-.24,1.52-.68,2.31-1-.76.35-1.45.85-2.25,1.16a.1.1,0,1,1-.07-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.71,71.2c.78-.26,1.49-.74,2.27-1-.75.37-1.41.9-2.2,1.24a.1.1,0,0,1-.13,0,.09.09,0,0,1,.05-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.43,72.28c.79-.26,1.51-.73,2.29-1-.76.37-1.42.89-2.21,1.23a.09.09,0,0,1-.13-.06.08.08,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.36,69.73c.77-.31,1.46-.82,2.22-1.18-.73.42-1.37,1-2.14,1.36a.1.1,0,1,1-.09-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.75,71.82c.78-.26,1.5-.73,2.28-1-.75.37-1.42.89-2.21,1.23a.1.1,0,0,1-.13-.06.1.1,0,0,1,.05-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.65,70.38c.8-.22,1.54-.65,2.33-.93-.77.34-1.46.82-2.27,1.12a.09.09,0,0,1-.12-.06.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.62,69.17c.8-.22,1.54-.65,2.34-.91-.77.32-1.47.8-2.28,1.09a.1.1,0,0,1-.13-.06.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.83,68.51c.8-.18,1.56-.58,2.37-.82-.79.3-1.5.75-2.32,1a.09.09,0,0,1-.12-.06.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,67.82c.8-.19,1.56-.59,2.37-.83-.79.3-1.5.76-2.32,1a.1.1,0,0,1-.13-.06.12.12,0,0,1,.07-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,66.93c.82-.14,1.6-.5,2.42-.69-.8.25-1.54.67-2.37.88a.11.11,0,0,1-.13-.07.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.19,65.47c.81-.16,1.58-.54,2.4-.75-.8.27-1.52.71-2.35.94a.09.09,0,0,1-.12-.07.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.73,63.18c.81-.15,1.59-.51,2.41-.71-.8.26-1.54.68-2.37.9a.09.09,0,0,1-.12-.07.11.11,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.44,60.91c.82-.12,1.61-.46,2.43-.63-.81.23-1.55.63-2.39.83a.1.1,0,0,1-.12-.07.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,59c.83-.09,1.63-.4,2.45-.55-.81.21-1.57.58-2.42.75a.1.1,0,0,1-.11-.08A.1.1,0,0,1,56,59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.34,57c.82-.07,1.63-.37,2.46-.5-.82.19-1.59.55-2.43.7a.1.1,0,0,1-.12-.08.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.69,55.21c.82-.09,1.62-.4,2.45-.54-.81.2-1.57.57-2.42.73a.09.09,0,0,1-.11-.07.09.09,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.58,53.58c.82-.1,1.61-.41,2.44-.56-.81.21-1.57.58-2.41.75a.1.1,0,1,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.77,51.48c.82-.07,1.63-.35,2.46-.47-.82.18-1.59.53-2.44.67a.1.1,0,0,1-.11-.08.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.57,50.32c.82-.1,1.62-.42,2.44-.57-.81.22-1.57.6-2.41.77a.11.11,0,0,1-.12-.08.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.63,48.8c.82-.12,1.61-.45,2.44-.62-.81.23-1.56.63-2.4.82a.11.11,0,0,1-.12-.08.11.11,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.82,47.39c.81-.16,1.58-.53,2.4-.74-.8.27-1.53.7-2.35.93a.11.11,0,0,1-.13-.07.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.62,46.24c.81-.21,1.55-.63,2.36-.89-.78.32-1.48.79-2.3,1.07a.08.08,0,0,1-.12-.06.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.19,44.93c.8-.22,1.54-.65,2.34-.93-.77.34-1.47.82-2.28,1.12a.09.09,0,0,1-.12-.06.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.06,43.38c.78-.29,1.48-.78,2.25-1.13-.74.4-1.39,1-2.17,1.32a.1.1,0,0,1-.08-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.82,41.9c.75-.34,1.42-.89,2.16-1.29-.71.46-1.32,1-2.07,1.47a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.2,39.9c.7-.44,1.28-1.08,2-1.57-.64.54-1.16,1.21-1.84,1.74a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.24,36.81c.52-.64.88-1.42,1.38-2.1-.44.72-.73,1.51-1.23,2.22a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.13,31.46c.2-.81.19-1.66.34-2.49-.09.83,0,1.68-.15,2.53a.1.1,0,0,1-.11.08.1.1,0,0,1-.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.49,28.32c.1-.82,0-1.67,0-2.51,0,.84.2,1.67.16,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.13,26.39c.09-.82,0-1.67,0-2.51,0,.84.21,1.67.17,2.53a.09.09,0,0,1-.1.09.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.12,24.41c.09-.82,0-1.67,0-2.51,0,.84.21,1.66.19,2.52a.11.11,0,0,1-.11.1.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.79,23.78c.09-.82,0-1.67,0-2.51,0,.84.22,1.67.2,2.53a.1.1,0,0,1-.11.09.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.48,22.37c.09-.82,0-1.67,0-2.51,0,.84.23,1.67.21,2.53a.11.11,0,0,1-.11.09.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.55,21c.08-.82,0-1.67,0-2.51,0,.84.23,1.66.21,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,19.48c.08-.83-.05-1.67,0-2.51,0,.84.23,1.66.22,2.52a.12.12,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.69,20.85c.09-.82,0-1.67,0-2.51,0,.84.23,1.66.21,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.72,19.94c.08-.82,0-1.67,0-2.51,0,.84.23,1.66.22,2.53a.1.1,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.13,20.79c.08-.82-.05-1.67,0-2.51,0,.84.23,1.66.21,2.52a.09.09,0,0,1-.1.1.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.79,21.45c.09-.82,0-1.67,0-2.51,0,.84.23,1.67.21,2.53a.1.1,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,22.18c.09-.83,0-1.67,0-2.51,0,.84.23,1.66.21,2.52a.11.11,0,0,1-.1.1.11.11,0,0,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.51,22.79c.09-.82,0-1.67,0-2.51,0,.84.22,1.67.2,2.53a.09.09,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.23,23.93c.09-.82,0-1.67,0-2.51,0,.84.22,1.67.19,2.53a.1.1,0,1,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,25.73c.1-.82,0-1.67,0-2.51,0,.84.21,1.66.18,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.5,26c.09-.82,0-1.67,0-2.51,0,.84.2,1.66.17,2.52a.09.09,0,0,1-.1.1.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.24,27.8c.09-.82,0-1.67,0-2.51,0,.84.21,1.66.17,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.36,30.15c.14-.82.06-1.67.15-2.51,0,.85.12,1.68.05,2.54a.11.11,0,0,1-.11.09.12.12,0,0,1-.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,33.85c.44-.71.69-1.53,1.09-2.27-.34.77-.52,1.6-.92,2.36a.1.1,0,0,1-.13.05.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.73,36.32c.58-.59,1-1.33,1.57-2-.51.67-.87,1.44-1.43,2.09a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.81,38.29c.68-.48,1.23-1.14,1.89-1.66-.62.57-1.1,1.26-1.76,1.82a.1.1,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.75,40.48c.75-.35,1.41-.9,2.15-1.3-.71.45-1.31,1-2.06,1.48a.1.1,0,0,1-.13,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.57,42.35c.78-.27,1.5-.74,2.28-1.05-.76.37-1.42.89-2.21,1.23a.09.09,0,0,1-.13-.05.1.1,0,0,1,.05-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.71,45.32c.81-.17,1.58-.54,2.4-.76-.8.28-1.52.71-2.35.95a.1.1,0,0,1-.12-.07.09.09,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.73,47.13c.82-.11,1.62-.44,2.44-.6-.81.23-1.56.61-2.4.79a.09.09,0,0,1-.12-.07.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.62,47.48c.83-.07,1.64-.35,2.47-.47-.82.18-1.59.53-2.44.67a.11.11,0,0,1-.12-.09.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,50c.83,0,1.65-.32,2.48-.41-.82.16-1.6.49-2.46.61a.11.11,0,0,1-.11-.09A.1.1,0,0,1,60,50Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.78,51c.83,0,1.66-.21,2.5-.26-.83.11-1.63.39-2.49.45a.1.1,0,1,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.23,52.59c.83,0,1.67-.17,2.51-.19-.84.09-1.64.34-2.5.38a.1.1,0,0,1-.11-.09.1.1,0,0,1,.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.59,54.13c.83,0,1.67-.13,2.51-.14-.84.08-1.65.32-2.51.34a.1.1,0,0,1-.1-.09.11.11,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,55.64c.82,0,1.66-.12,2.5-.13-.83.07-1.65.31-2.51.33a.1.1,0,0,1,0-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.38,57.54c.83,0,1.67-.16,2.51-.17-.84.08-1.65.33-2.51.37a.1.1,0,0,1-.1-.1.1.1,0,0,1,.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.55,59.56c.83,0,1.66-.21,2.5-.25-.83.11-1.63.38-2.49.45a.1.1,0,0,1-.1-.09.09.09,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.83,61.3c.83,0,1.65-.26,2.49-.33-.83.14-1.62.44-2.47.52a.1.1,0,0,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.65,63.17c.83,0,1.65-.31,2.48-.41-.82.16-1.6.49-2.46.61a.1.1,0,0,1-.11-.08.1.1,0,0,1,.09-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.49,65.11c.83-.08,1.63-.37,2.46-.5-.82.19-1.58.55-2.43.7a.1.1,0,1,1,0-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.69,66.27c.82-.1,1.62-.41,2.45-.56-.82.21-1.58.59-2.42.76a.1.1,0,0,1,0-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.44,67.68c.82-.12,1.61-.46,2.43-.63-.81.24-1.55.63-2.39.83a.1.1,0,0,1-.12-.08.09.09,0,0,1,.07-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.06,69.17c.82-.14,1.59-.51,2.41-.7-.8.26-1.53.68-2.37.9A.11.11,0,0,1,67,69.3a.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.53,69.64c.81-.15,1.58-.53,2.4-.73-.8.27-1.53.69-2.36.92a.09.09,0,0,1-.12-.07.11.11,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.14,71.34c.81-.18,1.57-.58,2.37-.81-.78.29-1.5.74-2.32,1a.1.1,0,0,1-.12-.07.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.47,72c.8-.2,1.55-.61,2.36-.86-.79.31-1.49.77-2.31,1a.1.1,0,0,1-.12-.06.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.9,73.08c.8-.21,1.55-.63,2.35-.89-.78.32-1.48.8-2.29,1.08a.1.1,0,0,1-.13-.06.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,73.67c.8-.23,1.54-.67,2.33-1-.77.34-1.46.84-2.26,1.14A.1.1,0,0,1,64,73.8a.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.35,74.71c.8-.23,1.53-.68,2.32-1-.77.34-1.45.84-2.25,1.15a.1.1,0,0,1-.13-.06.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.77,75.05c.79-.26,1.51-.73,2.29-1-.75.37-1.42.89-2.22,1.23a.1.1,0,1,1-.07-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.93,75.75c.78-.25,1.5-.72,2.28-1-.75.37-1.42.89-2.21,1.22a.1.1,0,0,1-.13,0,.09.09,0,0,1,.05-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.18,77.34c.77-.28,1.48-.76,2.25-1.1-.74.39-1.4.93-2.18,1.29a.1.1,0,0,1-.08-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.61,76.76c.78-.26,1.5-.73,2.28-1-.75.37-1.42.89-2.21,1.23a.1.1,0,1,1-.08-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.11,77.49c.77-.3,1.46-.82,2.22-1.17-.73.41-1.37,1-2.14,1.35a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.15,76.59c.78-.28,1.48-.77,2.25-1.11-.74.4-1.39.94-2.17,1.3a.1.1,0,0,1-.13,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.73,76.94c.77-.32,1.45-.84,2.2-1.21-.72.43-1.35,1-2.11,1.39a.1.1,0,1,1-.1-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.65,76.14c.76-.33,1.44-.85,2.19-1.23-.72.43-1.34,1-2.1,1.41a.12.12,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,76.05c.78-.29,1.48-.79,2.25-1.13-.74.4-1.39,1-2.17,1.31a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.83,75.26c.78-.29,1.47-.8,2.24-1.14-.74.4-1.38.95-2.16,1.32a.09.09,0,0,1-.13,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.31,74.31c.79-.26,1.51-.73,2.29-1-.76.37-1.42.89-2.22,1.23a.1.1,0,0,1-.13,0,.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.42,72.69c.79-.22,1.53-.66,2.33-.93-.77.34-1.47.82-2.27,1.12a.1.1,0,1,1-.07-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.56,69.68c.81-.17,1.58-.54,2.4-.76-.8.28-1.52.71-2.35,1a.1.1,0,0,1-.12-.07.09.09,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,66.18c.82-.11,1.61-.43,2.44-.59-.81.22-1.57.6-2.41.78a.1.1,0,0,1-.12-.07.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.88,64.12c.82-.07,1.63-.36,2.46-.47-.82.18-1.59.53-2.44.67a.1.1,0,0,1-.11-.08.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.6,60.87c.83-.07,1.63-.36,2.47-.48-.83.18-1.6.53-2.44.67a.1.1,0,0,1-.12-.08.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.82,58.71c.82-.06,1.63-.34,2.47-.45-.82.18-1.6.52-2.45.65a.1.1,0,0,1-.11-.08.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.83,56.36c.83,0,1.64-.32,2.48-.42-.83.17-1.61.5-2.46.62a.11.11,0,0,1-.11-.09.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.15,54.81c.83-.06,1.64-.34,2.47-.46-.82.18-1.59.52-2.44.66a.1.1,0,0,1-.12-.08.11.11,0,0,1,.09-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,53c.83-.08,1.63-.37,2.46-.49-.82.19-1.58.54-2.43.68a.1.1,0,0,1-.12-.08A.1.1,0,0,1,57,53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.11,51.47c.82-.12,1.61-.45,2.43-.63-.81.24-1.55.64-2.39.83a.1.1,0,0,1-.12-.08.09.09,0,0,1,.07-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.66,49.89c.81-.15,1.59-.51,2.41-.71-.8.26-1.54.68-2.37.9a.1.1,0,1,1-.05-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.65,47.69c.81-.2,1.56-.62,2.36-.87-.78.31-1.49.78-2.3,1.06a.1.1,0,0,1-.13-.06.11.11,0,0,1,.07-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.74,44.84c.78-.28,1.48-.78,2.25-1.12-.74.4-1.39.94-2.17,1.3a.1.1,0,0,1-.14,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,41.72c.72-.41,1.33-1,2-1.47-.67.51-1.22,1.15-1.93,1.64a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.89,39c.63-.54,1.12-1.24,1.73-1.83-.56.63-1,1.36-1.59,2a.1.1,0,0,1-.14-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.1,35.92c.51-.65.85-1.43,1.33-2.12-.43.72-.7,1.52-1.17,2.24a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.87,35.31c.41-.72.64-1.55,1-2.3-.31.78-.47,1.61-.84,2.39a.1.1,0,1,1-.17-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.17,33.3c.32-.76.43-1.61.71-2.41-.21.82-.26,1.66-.52,2.48a.1.1,0,0,1-.13.06.09.09,0,0,1-.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.86,33.1c.23-.8.26-1.65.45-2.47-.13.83-.08,1.67-.26,2.52a.1.1,0,0,1-.12.07.09.09,0,0,1-.07-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.17,31.34c.12-.82,0-1.67.09-2.51,0,.84.16,1.67.1,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.32,31c.1-.82,0-1.67,0-2.51,0,.84.19,1.67.16,2.53a.1.1,0,0,1-.11.09.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.27,29.37c.1-.82,0-1.67,0-2.51,0,.84.19,1.66.16,2.52a.11.11,0,0,1-.11.1.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.58,28.68c.1-.82,0-1.67,0-2.51,0,.84.2,1.67.16,2.53a.09.09,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,26.91c.1-.82,0-1.67,0-2.51,0,.84.2,1.67.17,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.15,24.62c.09-.82,0-1.67,0-2.51,0,.84.21,1.66.19,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.29,24.15c.09-.82,0-1.67,0-2.51,0,.84.21,1.67.19,2.53a.1.1,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.5,25.43c.1-.82,0-1.67,0-2.51,0,.84.21,1.66.18,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.9,22.89c.09-.83,0-1.67,0-2.51,0,.84.22,1.66.2,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.35,26.7c.1-.82,0-1.67,0-2.51,0,.84.2,1.67.17,2.53a.1.1,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.08,25.53c.09-.82,0-1.67,0-2.51,0,.84.21,1.66.18,2.52a.11.11,0,0,1-.11.1.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.38,23.09c.09-.82,0-1.67,0-2.51,0,.84.22,1.66.19,2.52a.09.09,0,0,1-.1.1.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.67,24.24c.09-.82,0-1.67,0-2.51,0,.84.21,1.67.19,2.53a.11.11,0,0,1-.11.09.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.7,21.7c.08-.82-.05-1.67,0-2.51,0,.84.22,1.66.2,2.52a.09.09,0,0,1-.1.1.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.79,19.49c.08-.83-.06-1.67,0-2.51,0,.84.23,1.66.21,2.52a.1.1,0,0,1-.1.1.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.54,20.33c.08-.83-.06-1.67,0-2.52,0,.84.24,1.66.23,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.89,19.21c.09-.82,0-1.67,0-2.51,0,.84.23,1.66.21,2.53a.09.09,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.35,20.7c.08-.83-.06-1.67,0-2.51,0,.84.23,1.66.21,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.18,21.52c.08-.82-.06-1.67,0-2.51,0,.84.24,1.66.23,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.82,22.61c.07-.82-.08-1.67-.06-2.51.05.84.26,1.66.25,2.52a.09.09,0,0,1-.1.1.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.6,25c.08-.82-.07-1.67,0-2.51,0,.84.25,1.66.24,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.44,26.66c.07-.83-.07-1.67,0-2.51,0,.84.25,1.66.24,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.39,28.27c.06-.83-.09-1.67-.07-2.51,0,.84.26,1.65.26,2.52a.09.09,0,0,1-.09.09.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,29.58c.05-.82-.11-1.67-.11-2.51.06.84.3,1.66.31,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.62,32.53c.06-.82-.1-1.67-.09-2.51,0,.84.28,1.66.29,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,34.4c.05-.83-.12-1.67-.12-2.51.07.84.3,1.65.32,2.51a.1.1,0,0,1-.09.1.11.11,0,0,1-.11-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.85,36.11c0-.83-.11-1.67-.11-2.51.06.84.29,1.65.3,2.51a.09.09,0,0,1-.09.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.42,37.34c0-.83-.14-1.67-.15-2.51.07.84.32,1.65.35,2.51a.1.1,0,0,1-.09.1.11.11,0,0,1-.11-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.94,39.53c.05-.82-.12-1.66-.11-2.51.06.84.29,1.65.31,2.52a.1.1,0,0,1-.09.1.12.12,0,0,1-.11-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.44,40.1c0-.83-.15-1.67-.17-2.51.08.84.33,1.65.37,2.51a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.19,40.26c0-.83-.18-1.66-.21-2.5.09.84.36,1.64.41,2.5a.1.1,0,0,1-.09.1.1.1,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.88,40.14c0-.83-.22-1.66-.28-2.5.12.84.41,1.63.48,2.49a.1.1,0,0,1-.09.11.12.12,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.49,42.34c0-.83-.26-1.65-.33-2.49.13.83.44,1.62.53,2.48a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.05,43.24c-.11-.82-.44-1.61-.6-2.43.23.81.61,1.56.8,2.4a.11.11,0,0,1-.08.12.11.11,0,0,1-.12-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.38,44.3c-.06-.82-.34-1.63-.46-2.47.18.83.52,1.6.66,2.45a.1.1,0,0,1-.09.11.09.09,0,0,1-.11-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.87,44.79c-.15-.81-.51-1.59-.7-2.41.26.8.67,1.54.89,2.37a.1.1,0,1,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.86,45.6c-.1-.82-.42-1.62-.57-2.45.22.82.59,1.57.76,2.42a.09.09,0,0,1-.07.11.1.1,0,0,1-.12-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.53,45.16c-.22-.8-.65-1.54-.92-2.34.33.78.82,1.47,1.11,2.28a.1.1,0,0,1-.19.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.3,46.48c-.2-.8-.62-1.55-.87-2.35.31.78.78,1.48,1.06,2.3a.09.09,0,0,1-.07.12.09.09,0,0,1-.12-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.88,47.58c-.35-.74-.9-1.4-1.3-2.14.46.7,1.05,1.31,1.48,2.05a.1.1,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.06,49.9C26.6,49.21,26,48.65,25.44,48c.56.63,1.24,1.13,1.78,1.8a.1.1,0,1,1-.15.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.78,51.86c-.56-.61-1.29-1.07-1.89-1.66.65.54,1.39.94,2,1.52a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,55.09c-.6-.57-1.35-1-2-1.53.68.49,1.45.84,2.12,1.38a.1.1,0,1,1-.13.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.23,58c-.68-.46-1.49-.75-2.22-1.17.76.37,1.58.58,2.33,1a.11.11,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.91,60.71c-.71-.44-1.53-.69-2.26-1.09.76.34,1.59.52,2.35.92a.1.1,0,0,1,.05.13.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.29,63.54c-.71-.41-1.54-.64-2.3-1,.78.31,1.62.47,2.39.83.05,0,.08.08.05.13a.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.72,66c-.72-.42-1.55-.64-2.3-1,.78.32,1.61.47,2.39.84a.1.1,0,0,1-.09.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.33,67.9c-.73-.39-1.57-.59-2.33-.94.79.29,1.62.41,2.41.76a.09.09,0,0,1,.05.13.1.1,0,0,1-.13.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.32,70.24c-.78-.29-1.63-.38-2.43-.63.82.18,1.66.2,2.49.44a.1.1,0,0,1,.07.12.11.11,0,0,1-.13.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.46,69.81c-.77-.3-1.63-.4-2.43-.66.82.2,1.67.23,2.49.48a.1.1,0,0,1,.07.12.11.11,0,0,1-.13.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.64,69.16c-.76-.34-1.6-.48-2.39-.77.81.23,1.65.3,2.46.58a.1.1,0,0,1,.06.13.09.09,0,0,1-.12.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,68.11c-.74-.37-1.58-.55-2.36-.88.8.27,1.64.38,2.44.7a.1.1,0,1,1-.08.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.37,66.57c-.71-.42-1.53-.67-2.28-1.06.78.34,1.6.51,2.37.89a.1.1,0,1,1-.08.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.22,63.33c-.7-.44-1.52-.69-2.26-1.09.77.34,1.6.53,2.36.92a.09.09,0,0,1,0,.13.1.1,0,0,1-.13.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.08,59c-.67-.49-1.47-.8-2.18-1.25.74.4,1.55.63,2.29,1.08a.1.1,0,1,1-.11.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.26,55.15c-.66-.49-1.46-.81-2.16-1.28.74.41,1.54.66,2.27,1.12a.1.1,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.57,51.06c-.6-.57-1.35-1-2-1.52.68.49,1.45.83,2.12,1.37a.1.1,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.47,49.34c-.55-.63-1.26-1.11-1.85-1.71.63.55,1.37,1,2,1.57a.1.1,0,0,1-.14.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.77,46.55c-.45-.7-1.08-1.27-1.58-1.95.55.64,1.22,1.15,1.74,1.83a.1.1,0,0,1,0,.14.08.08,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.77,44.53c-.36-.74-.93-1.39-1.34-2.12.47.7,1.08,1.29,1.52,2a.1.1,0,0,1,0,.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.49,42.88c-.25-.79-.71-1.52-1-2.3.36.76.87,1.43,1.2,2.23a.1.1,0,0,1-.06.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.78,39.5c-.08-.83-.39-1.63-.52-2.46.2.82.56,1.58.72,2.43a.1.1,0,0,1-.08.11.1.1,0,0,1-.12-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.31,36.62c0-.83-.23-1.66-.29-2.5.12.83.41,1.63.49,2.49a.09.09,0,0,1-.09.1.1.1,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.4,34.45c0-.83-.23-1.66-.28-2.5.12.83.41,1.63.48,2.49a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.49,32.19c0-.83-.22-1.66-.27-2.5.12.84.4,1.63.47,2.49a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.09,32.11c0-.82-.14-1.66-.15-2.5.08.83.32,1.64.35,2.5a.11.11,0,0,1-.1.11.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.24,32.48c.07-.83-.08-1.67-.05-2.51,0,.84.25,1.66.25,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.08,30.7c.06-.83-.1-1.67-.08-2.51,0,.84.27,1.65.27,2.51a.09.09,0,0,1-.09.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,29.85c.07-.83-.08-1.67-.06-2.51.05.84.26,1.66.25,2.52a.09.09,0,0,1-.09.09.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.07,31.2c.09-.83,0-1.67,0-2.51,0,.84.23,1.66.21,2.52a.09.09,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.89,30.82c.08-.82-.06-1.67,0-2.51,0,.84.25,1.66.24,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.89,32.68c.08-.82-.06-1.67,0-2.51,0,.84.24,1.66.22,2.52a.09.09,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.86,34.75c.07-.82-.07-1.67,0-2.51,0,.84.25,1.66.25,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.18,38.27c.06-.82-.09-1.67-.08-2.51,0,.84.28,1.66.28,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.23,43.18c0-.83-.16-1.66-.17-2.51.08.84.33,1.65.37,2.51a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.48,47.71c-.2-.8-.61-1.55-.86-2.36.31.78.78,1.49,1.05,2.3a.1.1,0,0,1-.06.13.1.1,0,0,1-.13-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.55,49.87c-.36-.75-.92-1.4-1.33-2.13.47.7,1.07,1.29,1.5,2a.09.09,0,0,1,0,.13.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.51,52.26c-.51-.65-1.19-1.17-1.75-1.8.61.59,1.32,1,1.9,1.67a.1.1,0,0,1-.14.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.79,53.75c-.62-.54-1.4-.91-2.07-1.42.71.45,1.5.76,2.2,1.26a.11.11,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,57.06c-.66-.5-1.46-.82-2.16-1.28.73.41,1.54.65,2.27,1.11a.1.1,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.32,60.56c-.69-.47-1.49-.76-2.21-1.19.75.37,1.56.59,2.31,1a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.41,62.8c-.68-.46-1.49-.75-2.21-1.18.75.37,1.57.59,2.32,1a.1.1,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.45,64.16c-.69-.46-1.51-.73-2.23-1.15.75.36,1.58.56,2.33,1a.09.09,0,0,1,0,.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.92,65.68c-.69-.47-1.49-.75-2.22-1.18.76.37,1.57.59,2.32,1a.1.1,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.69,66.91c-.71-.43-1.53-.67-2.28-1.06.78.33,1.61.5,2.38.89a.1.1,0,1,1-.09.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.51,67.69c-.7-.43-1.52-.68-2.26-1.08.76.34,1.59.52,2.36.91a.11.11,0,0,1,0,.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.87,67.52c-.72-.41-1.55-.63-2.3-1,.78.31,1.61.46,2.39.82a.1.1,0,0,1,.05.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.9,66c-.67-.49-1.46-.81-2.16-1.28.73.41,1.54.66,2.27,1.12a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.94,63c-.66-.51-1.44-.84-2.14-1.32.73.42,1.53.69,2.25,1.16a.1.1,0,1,1-.11.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.41,58.71c-.63-.53-1.41-.89-2.09-1.39.72.45,1.51.74,2.21,1.23a.1.1,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.34,53.31c-.56-.6-1.29-1.05-1.91-1.63.66.53,1.41.91,2.05,1.49a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.88,47.67c-.44-.69-1.07-1.27-1.57-2,.55.65,1.22,1.17,1.74,1.85a.11.11,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.28,44.57c-.3-.77-.8-1.47-1.14-2.24.4.74.95,1.38,1.32,2.16a.1.1,0,0,1-.05.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.8,41.29c-.16-.81-.53-1.58-.74-2.4.27.8.7,1.52.93,2.35a.09.09,0,0,1-.06.12.1.1,0,0,1-.13-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.12,38.64c0-.83-.29-1.65-.37-2.49.15.83.46,1.62.56,2.47a.1.1,0,0,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.45,37.53c0-.83-.2-1.66-.24-2.5.11.83.38,1.63.44,2.49a.1.1,0,0,1-.09.11.12.12,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.14,34.29c0-.82-.18-1.66-.2-2.5.09.84.35,1.64.39,2.5a.09.09,0,0,1-.09.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.62,29.6c0-.83-.17-1.66-.2-2.51.09.84.35,1.64.4,2.5a.1.1,0,0,1-.09.11.11.11,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.1,26.46c0-.82-.13-1.66-.13-2.5.07.84.31,1.65.33,2.51a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.9,26.34c.08-.82,0-1.67,0-2.51,0,.84.23,1.66.22,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.66,23.81c.08-.82,0-1.67,0-2.51,0,.84.23,1.66.21,2.52a.09.09,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.47,22.6c.09-.83,0-1.67,0-2.51,0,.84.22,1.66.2,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.51,20c.09-.82,0-1.67,0-2.51,0,.84.23,1.66.21,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.53,20.17c.08-.82-.06-1.67,0-2.51,0,.84.23,1.66.21,2.52a.09.09,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.9,21c.09-.82,0-1.67,0-2.51,0,.84.23,1.66.21,2.52a.09.09,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,22.27c.08-.82-.05-1.67,0-2.51,0,.84.23,1.66.2,2.52a.09.09,0,0,1-.1.1.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.31,23.37c.09-.82,0-1.67,0-2.51,0,.84.22,1.66.2,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.84,21.4c.09-.82,0-1.67,0-2.51,0,.84.23,1.66.21,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.26,27.43c.09-.82,0-1.67,0-2.51,0,.84.21,1.66.19,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.58,28.69c.08-.82-.06-1.67,0-2.51,0,.84.23,1.66.22,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.55,30.51c0-.82-.15-1.66-.16-2.5.08.83.33,1.64.36,2.5a.09.09,0,0,1-.09.1.09.09,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.57,32.82c0-.83-.19-1.66-.22-2.5.1.84.36,1.64.41,2.5a.1.1,0,0,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.85,36.74c0-.83-.29-1.64-.38-2.48.15.83.47,1.61.58,2.46a.09.09,0,0,1-.08.11.1.1,0,0,1-.12-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.18,41c-.23-.8-.66-1.54-.94-2.33.34.77.83,1.46,1.13,2.26a.11.11,0,0,1-.06.13.1.1,0,0,1-.13-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.06,44.85c-.46-.68-1.11-1.25-1.62-1.92.56.63,1.24,1.13,1.78,1.81a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.3,48.55c-.59-.59-1.33-1-2-1.58.67.51,1.44.87,2.09,1.43a.1.1,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.59,52.48c-.64-.53-1.42-.89-2.09-1.39.71.44,1.5.74,2.21,1.23a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.88,57.18c-.62-.55-1.39-.94-2.05-1.46.7.47,1.49.79,2.17,1.3a.09.09,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.49,61.2c-.62-.55-1.39-.93-2.06-1.44.71.46,1.49.77,2.19,1.28a.1.1,0,1,1-.12.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.2,64.33c-.63-.53-1.4-.91-2.07-1.41.71.45,1.5.75,2.2,1.26a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.78,66.77c-.65-.51-1.44-.84-2.13-1.32.73.42,1.53.69,2.25,1.16a.11.11,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.21,69c-.75-.34-1.6-.49-2.39-.79.81.24,1.65.31,2.46.6a.11.11,0,0,1,.07.13.1.1,0,0,1-.13.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.2,70.42c-.77-.3-1.62-.39-2.43-.65.82.19,1.67.22,2.49.46a.1.1,0,0,1-.05.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.78,70.46c-.78-.28-1.63-.35-2.44-.59.82.17,1.67.18,2.5.41a.1.1,0,0,1,.07.12.11.11,0,0,1-.12.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,69.44c-.76-.34-1.6-.49-2.39-.79.81.24,1.65.31,2.46.61a.1.1,0,1,1-.07.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.3,67.44c-.71-.43-1.53-.67-2.27-1.07.77.34,1.6.51,2.37.89a.12.12,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.83,61.78c-.67-.49-1.47-.81-2.17-1.26.74.4,1.55.64,2.28,1.09a.1.1,0,1,1-.11.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.21,55.75c-.64-.52-1.42-.88-2.1-1.37.72.44,1.51.72,2.22,1.22a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,50.87c-.46-.69-1.1-1.26-1.61-1.93.56.63,1.24,1.13,1.77,1.81a.1.1,0,1,1-.15.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.17,47.63c-.29-.77-.79-1.46-1.14-2.23.41.74.95,1.38,1.33,2.16a.11.11,0,0,1-.05.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,42.1c0-.83-.16-1.67-.18-2.51.08.84.34,1.64.38,2.5a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.69,37.94c.05-.82-.11-1.66-.11-2.51.06.84.29,1.65.31,2.52a.1.1,0,0,1-.09.1.12.12,0,0,1-.11-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.29,34.3c.06-.83-.1-1.67-.08-2.51,0,.84.27,1.65.28,2.51a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.15,28.09c0-.83-.13-1.67-.14-2.51.07.84.31,1.65.34,2.51a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.23,24.54c.05-.83-.11-1.67-.1-2.51.06.84.29,1.65.29,2.51a.1.1,0,1,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.59,23.6c.08-.82-.07-1.67,0-2.51,0,.84.25,1.66.24,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.68,22.52c.08-.82,0-1.67,0-2.51,0,.84.23,1.66.22,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.53,23.94c.09-.82,0-1.67,0-2.51,0,.84.22,1.67.2,2.53a.1.1,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.74,20.94c.08-.83-.05-1.67,0-2.51,0,.84.23,1.66.21,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.51,22.44c.08-.83,0-1.67,0-2.52,0,.85.23,1.67.2,2.53a.09.09,0,0,1-.1.09.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.5,18.49c.08-.82-.06-1.67,0-2.51,0,.84.23,1.66.22,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.29,18.62c.08-.82-.06-1.67,0-2.51,0,.84.23,1.66.22,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,19.56c.09-.82-.05-1.67,0-2.51,0,.84.23,1.66.21,2.52a.09.09,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46,18.46c.08-.83-.06-1.67,0-2.51,0,.84.23,1.66.22,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.34,18c.08-.82-.06-1.67,0-2.51,0,.84.24,1.66.23,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.91,18c.08-.82-.06-1.67,0-2.51,0,.84.24,1.66.23,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.52,17.91c.08-.83-.06-1.67,0-2.51,0,.84.23,1.66.22,2.52a.09.09,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.09,109.05c.69-.46,1.26-1.1,1.93-1.61-.63.56-1.14,1.23-1.81,1.76a.1.1,0,1,1-.13-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.81,107.26c.69-.46,1.26-1.1,1.93-1.61-.63.56-1.14,1.24-1.82,1.77a.1.1,0,0,1-.12-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.28,82.61c.76-.34,1.42-.88,2.17-1.27-.72.44-1.33,1-2.08,1.44a.09.09,0,0,1-.13,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.24,107.18c.7-.45,1.28-1.08,2-1.58-.64.55-1.16,1.22-1.84,1.74a.1.1,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.31,106.43c.7-.44,1.28-1.07,2-1.56-.65.55-1.17,1.21-1.86,1.72a.09.09,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.52,88.23c.82-.1,1.62-.42,2.44-.57-.81.22-1.57.59-2.41.76a.1.1,0,1,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.41,89.28c.82-.08,1.63-.38,2.46-.52-.82.2-1.58.56-2.43.72a.1.1,0,1,1,0-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.85,90.19c.82-.13,1.6-.48,2.43-.66-.81.24-1.55.65-2.39.85a.1.1,0,0,1-.12-.07.11.11,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.61,91.82c.81-.2,1.55-.62,2.36-.87-.78.31-1.48.78-2.3,1.06a.09.09,0,0,1-.12-.06.08.08,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.73,93.94c.8-.22,1.54-.66,2.34-.93-.77.33-1.47.82-2.28,1.11a.09.09,0,1,1-.06-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.28,95.35c.83,0,1.66-.19,2.5-.23-.84.1-1.64.37-2.5.42a.09.09,0,0,1-.1-.09.09.09,0,0,1,.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.71,97.78c.56-.61,1-1.37,1.49-2-.48.69-.81,1.47-1.34,2.15a.1.1,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.58,99.57c.67-.47,1.22-1.13,1.88-1.65-.61.57-1.1,1.26-1.76,1.81a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.58,101.35c.7-.44,1.28-1.07,2-1.56-.65.54-1.17,1.2-1.86,1.72a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.65,102.88c.7-.44,1.28-1.07,2-1.56-.65.54-1.17,1.21-1.86,1.72a.11.11,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.07,104.7c.69-.45,1.27-1.08,2-1.58-.64.55-1.15,1.22-1.84,1.74a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.79,105.79c.69-.45,1.27-1.09,2-1.58-.64.55-1.15,1.22-1.84,1.74a.1.1,0,1,1-.12-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.65,108.79c.68-.46,1.24-1.11,1.91-1.62-.62.56-1.12,1.24-1.8,1.78a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,110c.69-.45,1.26-1.09,1.94-1.59-.64.55-1.15,1.22-1.83,1.75a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.14,112c.69-.46,1.26-1.1,1.93-1.61-.63.56-1.14,1.24-1.81,1.77a.1.1,0,0,1-.13-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.71,112c.69-.45,1.27-1.08,1.95-1.58-.64.55-1.15,1.22-1.84,1.74a.1.1,0,1,1-.12-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.17,113.42c.7-.44,1.29-1.07,2-1.56-.64.54-1.17,1.2-1.85,1.72a.11.11,0,0,1-.14,0,.09.09,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.84,112.77c.71-.43,1.3-1,2-1.53-.66.53-1.19,1.19-1.89,1.69a.09.09,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.63,113.16c.71-.41,1.32-1,2-1.49-.66.52-1.21,1.17-1.92,1.66a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.69,111.67c.71-.42,1.32-1,2-1.49-.67.52-1.21,1.16-1.92,1.66a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.81,110.39c.71-.43,1.31-1,2-1.52-.65.53-1.19,1.18-1.89,1.68a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.32,107.4c.71-.43,1.3-1.05,2-1.53-.65.53-1.18,1.19-1.88,1.7a.1.1,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.63,103.43c.7-.43,1.3-1.05,2-1.53-.65.53-1.19,1.19-1.89,1.69a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.81,101.16c.71-.43,1.3-1,2-1.52-.66.53-1.19,1.18-1.89,1.68a.09.09,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.44,98.75c.69-.46,1.26-1.1,1.94-1.61-.64.56-1.14,1.24-1.82,1.77a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,92.83c.8-.21,1.54-.64,2.34-.91-.78.33-1.47.81-2.28,1.1a.1.1,0,0,1-.13-.06.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.13,89.42c.8-.22,1.54-.65,2.34-.92-.78.33-1.47.82-2.28,1.11a.1.1,0,0,1-.13-.06.1.1,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.68,86.64c.81-.17,1.58-.54,2.4-.75-.8.27-1.53.7-2.35.94a.11.11,0,0,1-.13-.07.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.12,84.83c.79-.26,1.5-.73,2.28-1-.75.38-1.41.9-2.21,1.24a.1.1,0,0,1-.13,0,.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.21,83c.71-.42,1.32-1,2-1.49-.67.52-1.21,1.17-1.92,1.66a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.91,82c.71-.42,1.32-1,2-1.49-.66.52-1.21,1.16-1.92,1.66a.09.09,0,0,1-.13,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.83,81.32c.7-.44,1.29-1.06,2-1.55-.65.54-1.18,1.2-1.87,1.71a.09.09,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.59,81.37c.68-.47,1.24-1.12,1.91-1.63-.63.56-1.12,1.24-1.79,1.79a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.19,21.08c-.51-.64-1.2-1.15-1.77-1.78.61.58,1.33,1,1.92,1.65a.1.1,0,0,1-.14.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.14,17.47c-.5-.66-1.17-1.19-1.72-1.83.6.6,1.3,1.06,1.87,1.7a.1.1,0,0,1-.14.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.26,19.45c-.5-.66-1.18-1.19-1.73-1.82.6.59,1.31,1.05,1.88,1.69a.1.1,0,1,1-.14.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.45,20.67C10,20,9.27,19.49,8.72,18.85c.6.6,1.31,1.06,1.88,1.7a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.22,18.5c-.51-.64-1.2-1.16-1.76-1.78.61.58,1.32,1,1.91,1.65a.1.1,0,1,1-.14.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.81,19.82C8.29,19.18,7.6,18.67,7,18.05c.62.57,1.34,1,1.93,1.64a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.08,18.36c-.49-.66-1.16-1.2-1.7-1.85.59.6,1.29,1.08,1.85,1.73a.1.1,0,0,1-.15.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.47,19.32c-.47-.68-1.12-1.24-1.64-1.9.57.62,1.25,1.12,1.8,1.78a.1.1,0,0,1-.16.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.74,17.78c-.42-.71-1-1.31-1.51-2,.53.66,1.18,1.2,1.67,1.9a.09.09,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.38,16.4C16,15.66,15.43,15,15,14.29c.48.69,1.09,1.28,1.54,2a.1.1,0,0,1-.17.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.27,15.73c-.31-.77-.82-1.46-1.18-2.22.42.73,1,1.37,1.36,2.14a.11.11,0,0,1,0,.13.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.9,15.51c-.23-.79-.66-1.53-.94-2.33.34.77.83,1.46,1.12,2.27a.1.1,0,0,1,0,.13.1.1,0,0,1-.13-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,14c-.15-.82-.52-1.59-.72-2.41.27.8.69,1.53.91,2.36a.11.11,0,0,1-.07.13A.1.1,0,0,1,21,14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.2,13.16c-.08-.83-.38-1.63-.51-2.46.19.82.55,1.58.7,2.43a.1.1,0,0,1-.08.12.1.1,0,0,1-.11-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.5,13.57c0-.82-.3-1.64-.39-2.48.15.83.47,1.61.58,2.46a.09.09,0,0,1-.08.11.09.09,0,0,1-.11-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.5,15.27c-.14-.82-.5-1.6-.69-2.42.26.8.67,1.54.88,2.38a.11.11,0,0,1-.07.12.1.1,0,0,1-.12-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,17.91c-.29-.77-.79-1.47-1.13-2.24.4.74.94,1.39,1.31,2.17a.1.1,0,0,1-.05.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.32,20.67c-.4-.72-1-1.35-1.43-2.06.5.68,1.13,1.24,1.6,2a.1.1,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.81,23.69c-.45-.7-1.09-1.27-1.58-2,.55.63,1.22,1.15,1.74,1.83a.11.11,0,0,1,0,.14.09.09,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,25.9c-.46-.69-1.1-1.26-1.6-1.94.55.63,1.23,1.14,1.76,1.82a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.94,27.37c-.47-.68-1.11-1.25-1.62-1.91.56.62,1.24,1.13,1.78,1.8a.1.1,0,0,1-.16.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.13,28.83c-.47-.68-1.11-1.25-1.63-1.91.57.62,1.25,1.12,1.78,1.8a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.58,30.34c-.45-.7-1.08-1.28-1.57-2,.54.64,1.21,1.16,1.73,1.85a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.23,29.26c-.43-.7-1.05-1.29-1.53-2,.53.66,1.19,1.19,1.69,1.88a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.08,27.62c-.41-.71-1-1.32-1.48-2,.52.67,1.16,1.22,1.65,1.93a.1.1,0,0,1-.17.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.06,26.06c-.37-.74-.94-1.38-1.37-2.11.48.7,1.1,1.28,1.55,2a.11.11,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.78,23.65c-.27-.78-.74-1.5-1.05-2.28.37.76.89,1.42,1.23,2.21a.1.1,0,1,1-.18.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.58,21.85c-.18-.8-.58-1.56-.81-2.37.29.78.74,1.5,1,2.32a.11.11,0,0,1-.07.13.1.1,0,0,1-.12-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.11,20.42c-.11-.82-.44-1.61-.61-2.44.23.81.62,1.56.81,2.4a.1.1,0,0,1-.2.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.68,19.09c0-.82-.27-1.64-.35-2.48.14.83.45,1.61.55,2.47a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.81,17.31c.05-.83-.13-1.67-.14-2.51.07.84.32,1.65.34,2.51a.1.1,0,0,1-.09.1.11.11,0,0,1-.11-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.54,16.22c.07-.82-.08-1.67-.06-2.51.05.84.26,1.66.26,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.72,15c.07-.82-.08-1.66-.06-2.51,0,.84.26,1.66.25,2.52a.09.09,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.48,17.28c.06-.83-.1-1.67-.09-2.51.05.84.28,1.65.29,2.52a.12.12,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.74,22.24c0-.83-.21-1.66-.26-2.5.11.83.39,1.63.46,2.49a.09.09,0,0,1-.09.1.1.1,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.74,26.35c-.1-.82-.43-1.61-.59-2.44.22.82.61,1.57.79,2.41a.1.1,0,0,1-.08.12.09.09,0,0,1-.11-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.78,28.85c-.21-.8-.64-1.54-.9-2.34.32.77.8,1.47,1.09,2.28a.1.1,0,0,1-.19.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.57,33.13c-.34-.76-.88-1.42-1.28-2.17.45.71,1,1.32,1.45,2.08a.1.1,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.94,35.53c-.43-.7-1-1.3-1.52-2,.53.66,1.18,1.2,1.68,1.9a.09.09,0,0,1,0,.13.09.09,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.65,36.53c-.44-.7-1.07-1.28-1.56-2,.54.65,1.21,1.17,1.72,1.86a.09.09,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.73,35.43c-.38-.74-1-1.37-1.4-2.09.49.68,1.11,1.26,1.57,2a.09.09,0,1,1-.16.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.48,33.83c-.35-.75-.9-1.41-1.3-2.15.45.71,1.05,1.31,1.47,2.06a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.07,29c-.28-.78-.77-1.49-1.1-2.26.39.74.92,1.4,1.28,2.18a.1.1,0,0,1-.05.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.62,24.66c-.11-.82-.44-1.61-.6-2.44.22.81.61,1.57.79,2.41a.11.11,0,0,1-.07.12.1.1,0,0,1-.12-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.9,21.11c0-.83-.24-1.65-.3-2.49.12.83.42,1.62.5,2.48a.12.12,0,0,1-.09.11.1.1,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.69,19.7c0-.83-.14-1.66-.14-2.51.07.84.31,1.65.34,2.51a.09.09,0,0,1-.1.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.36,17.93c.07-.83-.08-1.67,0-2.51,0,.84.25,1.65.25,2.51a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.14,14.94c.07-.82-.07-1.67,0-2.51,0,.84.25,1.66.25,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.27,13c.06-.82-.09-1.66-.07-2.51,0,.84.27,1.66.27,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41,12.74c.06-.83-.09-1.67-.08-2.51,0,.84.27,1.66.28,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.58,13.13c.06-.83-.09-1.67-.08-2.51,0,.84.27,1.65.28,2.51a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.19,14.62c.06-.82-.09-1.67-.07-2.51.05.84.27,1.66.26,2.52a.09.09,0,0,1-.09.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.44,16.19c.07-.82-.07-1.67,0-2.51,0,.84.24,1.66.23,2.52a.09.09,0,0,1-.1.1.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.1,21.13c.09-.82,0-1.67,0-2.51,0,.84.22,1.67.2,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.56,24.65c.16-.81.1-1.66.22-2.5-.05.84.07,1.68,0,2.53a.09.09,0,0,1-.1.09.09.09,0,0,1-.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,27.39c.29-.78.36-1.63.6-2.44-.17.82-.18,1.67-.41,2.5A.1.1,0,1,1,66,27.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.09,28.92c.45-.7.72-1.51,1.14-2.24-.36.76-.56,1.58-1,2.34a.11.11,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,30.84c.58-.6,1-1.34,1.54-2-.49.68-.84,1.45-1.39,2.11A.1.1,0,0,1,70,31a.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.37,31.12c.62-.56,1.08-1.28,1.67-1.88-.54.64-.94,1.39-1.53,2a.1.1,0,0,1-.14,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.51,32c.64-.52,1.15-1.21,1.77-1.78-.57.62-1,1.33-1.64,1.93a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.75,31.54c.64-.54,1.13-1.24,1.73-1.82-.56.63-1,1.36-1.59,2a.1.1,0,1,1-.14-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.92,29.18c.53-.63.9-1.41,1.41-2.08-.46.71-.75,1.5-1.26,2.2a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.51,26.72c.36-.75.52-1.59.83-2.37-.25.8-.34,1.64-.65,2.44a.09.09,0,0,1-.12.06.1.1,0,0,1-.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.33,22.25c.19-.8.16-1.66.3-2.49-.08.84,0,1.68-.1,2.53a.1.1,0,0,1-.12.08.09.09,0,0,1-.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.45,18.49c.08-.83-.06-1.67,0-2.51,0,.84.23,1.66.21,2.52a.09.09,0,0,1-.1.09.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.52,15.83c.08-.82-.06-1.67,0-2.51,0,.84.23,1.66.22,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,13c.07-.82-.08-1.67-.06-2.51,0,.84.26,1.66.26,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,12.25c.06-.82-.09-1.67-.08-2.51,0,.84.27,1.66.27,2.52a.1.1,0,0,1-.09.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.56,11.53c.06-.83-.1-1.67-.09-2.51.06.84.28,1.65.29,2.51a.09.09,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.88,11.29c.06-.83-.1-1.67-.09-2.51.06.84.28,1.65.29,2.51a.09.09,0,0,1-.09.1.1.1,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.72,10.68c.05-.82-.11-1.67-.1-2.51.06.84.28,1.66.29,2.52a.1.1,0,0,1-.09.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.92,11.78c.06-.83-.09-1.67-.08-2.51,0,.84.27,1.65.28,2.51a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,15.46c.08-.82-.06-1.67,0-2.51,0,.84.24,1.66.23,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.2,19.94c.08-.82-.05-1.67,0-2.51,0,.84.23,1.66.21,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.73,23.3c.12-.82,0-1.67.12-2.51,0,.84.14,1.67.07,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.64,27.08c.23-.8.26-1.65.46-2.47-.13.83-.09,1.67-.27,2.52a.1.1,0,0,1-.2-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.2,30.74c.52-.65.87-1.43,1.35-2.12-.43.72-.71,1.52-1.19,2.23a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.49,32.93c.65-.51,1.16-1.2,1.79-1.76-.58.61-1,1.32-1.66,1.91a.1.1,0,0,1-.14-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.38,34c.69-.45,1.27-1.09,2-1.59-.64.55-1.16,1.22-1.84,1.75a.11.11,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,32.8c.66-.5,1.19-1.17,1.83-1.72-.6.59-1.07,1.3-1.71,1.87a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.74,30.23c.52-.64.87-1.43,1.35-2.12-.43.73-.7,1.53-1.19,2.24a.1.1,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.21,26.41c.31-.77.41-1.62.67-2.42-.2.82-.23,1.66-.48,2.49a.1.1,0,0,1-.13.06.09.09,0,0,1-.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,23.43c.18-.81.14-1.66.27-2.5-.07.84,0,1.68-.07,2.54a.1.1,0,0,1-.11.08.11.11,0,0,1-.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.47,21.24c.1-.82,0-1.67.05-2.51,0,.84.19,1.67.15,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.62,18.13c.08-.82,0-1.67,0-2.51,0,.84.24,1.67.22,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.17,15.22c.08-.83-.06-1.67,0-2.51,0,.84.25,1.66.24,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,13.84c.07-.82-.08-1.66-.06-2.51,0,.84.26,1.66.26,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.47,13.34c.06-.83-.09-1.67-.07-2.51.05.84.27,1.65.27,2.51a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.35,13.77c.06-.82-.09-1.67-.07-2.51,0,.84.27,1.66.27,2.52a.11.11,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.15,13.72c.07-.83-.08-1.67-.06-2.52,0,.85.26,1.66.26,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.9,15.7c0-.83-.18-1.67-.21-2.51.09.84.36,1.64.4,2.5a.09.09,0,0,1-.09.11.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.29,17.24c-.06-.82-.33-1.63-.44-2.47.17.82.51,1.6.63,2.45a.1.1,0,0,1-.08.12c-.06,0-.11,0-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.37,20.05c-.23-.8-.67-1.53-1-2.33.35.77.84,1.46,1.15,2.26a.1.1,0,0,1-.19.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.88,22.43c-.35-.75-.9-1.41-1.31-2.14.46.7,1.06,1.3,1.49,2.05a.1.1,0,0,1,0,.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.41,23.41c-.42-.71-1-1.31-1.5-2,.52.66,1.17,1.2,1.67,1.91a.09.09,0,0,1,0,.13.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.66,22.75c-.4-.72-1-1.35-1.43-2.06.5.68,1.13,1.24,1.6,2a.1.1,0,1,1-.17.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.25,20.5c-.34-.76-.88-1.42-1.27-2.17.45.71,1,1.33,1.44,2.08a.1.1,0,1,1-.17.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.73,14.63c-.08-.82-.37-1.63-.5-2.46.19.82.55,1.59.7,2.43a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.49,8.84c.06-.83-.1-1.67-.08-2.51.05.84.27,1.65.28,2.51a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.52,5.45c0-.83-.12-1.67-.12-2.51.07.84.3,1.65.32,2.51a.09.09,0,0,1-.1.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.56,3.5c0-.83-.14-1.67-.15-2.51.08.84.32,1.65.35,2.51a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.62,3.05c0-.83-.14-1.67-.15-2.51.07.84.32,1.65.35,2.51a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.25,2.61c0-.83-.15-1.67-.16-2.51.08.84.33,1.65.35,2.51a.09.09,0,0,1-.09.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.67,2.51c0-.83-.14-1.67-.15-2.51.08.84.32,1.65.35,2.51a.1.1,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.78,3c0-.83-.13-1.67-.13-2.51.07.84.31,1.65.33,2.51a.09.09,0,0,1-.1.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.9,4.88c.05-.82-.11-1.67-.11-2.51.06.84.29,1.66.3,2.52A.09.09,0,0,1,56,5a.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.4,7.19c.06-.82-.1-1.66-.08-2.51,0,.84.27,1.66.28,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.51,8.85c.07-.83-.07-1.67,0-2.51,0,.84.26,1.65.25,2.51a.09.09,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.78,12.08c.08-.83-.07-1.67,0-2.51,0,.84.25,1.66.24,2.52a.1.1,0,0,1-.1.09.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.94,16c.18-.81.13-1.67.26-2.5-.06.84,0,1.67-.06,2.53a.1.1,0,0,1-.11.08.1.1,0,0,1-.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,21c.41-.72.64-1.55,1-2.3-.31.78-.47,1.61-.83,2.39a.11.11,0,0,1-.14.05A.11.11,0,0,1,74,21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.79,24.21c.52-.65.86-1.43,1.34-2.12-.43.72-.7,1.52-1.18,2.24a.1.1,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.09,27.47c.57-.6,1-1.35,1.53-2-.49.68-.84,1.45-1.38,2.12a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.2,30.71c.62-.55,1.08-1.27,1.68-1.87-.55.65-.95,1.39-1.54,2a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.25,35.52c.71-.43,1.3-1.05,2-1.53-.65.53-1.18,1.19-1.88,1.69a.09.09,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.37,38.14c.74-.37,1.39-.94,2.12-1.35-.7.47-1.29,1.08-2,1.52a.1.1,0,1,1-.11-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.66,36.86c.73-.39,1.36-1,2.08-1.41-.68.49-1.26,1.12-2,1.58a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.36,32.53c.66-.5,1.18-1.18,1.82-1.72-.59.59-1.06,1.3-1.7,1.87a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,25.4c.42-.71.65-1.54,1-2.29-.32.78-.48,1.61-.86,2.38a.1.1,0,0,1-.13.05.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.18,24.3c.34-.76.48-1.6.78-2.39-.24.81-.31,1.65-.6,2.46a.09.09,0,0,1-.12.06.09.09,0,0,1-.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.26,23.09c.25-.78.3-1.64.51-2.46-.15.83-.12,1.68-.32,2.52a.1.1,0,1,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.75,24.22c.25-.79.3-1.65.52-2.46-.15.83-.13,1.67-.33,2.51a.1.1,0,0,1-.12.07.1.1,0,0,1-.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.26,27c.43-.71.66-1.53,1-2.28-.33.78-.49,1.6-.87,2.38a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.32,36.75c.75-.35,1.4-.91,2.14-1.31-.71.46-1.3,1.06-2,1.49a.1.1,0,0,1-.1-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.56,43.88c.79-.25,1.51-.71,2.3-1-.76.36-1.44.87-2.23,1.19A.09.09,0,0,1,78.5,44a.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.14,47.2c.8-.22,1.54-.65,2.34-.91-.78.32-1.47.81-2.28,1.1a.1.1,0,0,1-.13-.06.11.11,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79,49.37c.8-.19,1.56-.6,2.36-.84-.78.3-1.49.76-2.31,1a.09.09,0,0,1-.12-.06.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79,52.24c.81-.17,1.57-.56,2.38-.79-.79.29-1.51.73-2.33,1a.1.1,0,0,1-.12-.07.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.53,54.16c.81-.16,1.58-.53,2.4-.75-.8.28-1.53.71-2.35,1a.11.11,0,0,1-.13-.07.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.78,55.33c.81-.14,1.59-.5,2.41-.69-.8.25-1.54.67-2.37.89a.1.1,0,0,1-.12-.07.11.11,0,0,1,.07-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.94,52.11c.82-.13,1.6-.47,2.43-.65-.81.24-1.55.64-2.39.84a.1.1,0,1,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.88,46.42c.82-.14,1.6-.5,2.42-.69-.81.25-1.54.67-2.38.88a.11.11,0,0,1-.12-.07.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.15,41.56c.8-.2,1.55-.62,2.35-.88-.78.32-1.48.79-2.29,1.07a.1.1,0,0,1-.13-.06.11.11,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.88,38c.78-.29,1.48-.79,2.24-1.13-.73.4-1.38,1-2.16,1.31a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.63,36.68c.76-.33,1.43-.87,2.18-1.25-.71.44-1.33,1-2.09,1.43a.11.11,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.41,35c.72-.4,1.34-1,2-1.44-.67.5-1.23,1.13-1.95,1.61A.1.1,0,1,1,73.4,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.78,33.89c.7-.44,1.29-1.06,2-1.55-.64.54-1.17,1.2-1.86,1.72a.1.1,0,1,1-.12-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.79,35.16c.73-.38,1.37-1,2.09-1.4-.69.49-1.26,1.11-2,1.58a.1.1,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.32,37.34c.76-.33,1.44-.85,2.2-1.22-.73.43-1.35,1-2.12,1.39a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.83,40.1c.8-.24,1.53-.7,2.31-1-.76.36-1.44.86-2.24,1.17a.1.1,0,0,1-.13-.05.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.28,43.17c.81-.17,1.57-.56,2.38-.78-.79.28-1.51.73-2.33,1a.1.1,0,0,1-.12-.07.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.6,43.05c.81-.19,1.56-.6,2.37-.84-.79.3-1.49.77-2.31,1a.1.1,0,0,1-.13-.06.09.09,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.27,39.82c.78-.26,1.5-.73,2.28-1-.75.37-1.42.89-2.21,1.23a.1.1,0,0,1-.13-.06.1.1,0,0,1,.05-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.78,30.48c.59-.57,1-1.31,1.61-1.92-.52.66-.9,1.41-1.47,2.06a.1.1,0,1,1-.15-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.93,22.79c.3-.77.4-1.62.66-2.42-.2.81-.23,1.66-.48,2.48a.09.09,0,0,1-.12.07.11.11,0,0,1-.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.71,20.64c.15-.82.08-1.67.18-2.51,0,.84.1,1.68,0,2.53a.09.09,0,0,1-.11.09.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.56,19.49c.09-.82,0-1.67,0-2.51,0,.84.21,1.66.17,2.52a.09.09,0,0,1-.1.1.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.69,17.19c.08-.82-.06-1.67,0-2.51,0,.84.24,1.66.23,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.64,16.35c.1-.83,0-1.67,0-2.52,0,.85.2,1.67.17,2.53a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.49,16.77c.23-.8.25-1.65.44-2.47-.12.83-.08,1.67-.25,2.51a.1.1,0,0,1-.12.08.09.09,0,0,1-.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.85,15.64c.28-.77.37-1.63.62-2.43-.19.82-.2,1.66-.44,2.49a.1.1,0,1,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.09,15.7c.35-.75.51-1.59.82-2.37-.25.8-.34,1.64-.64,2.45a.1.1,0,1,1-.18-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78,14.69c.34-.75.48-1.6.79-2.38-.24.8-.32,1.65-.61,2.45a.09.09,0,0,1-.13.06A.1.1,0,0,1,78,14.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.46,13c.3-.77.39-1.63.65-2.43-.2.82-.22,1.67-.46,2.49a.1.1,0,0,1-.13.07.09.09,0,0,1-.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.61,11.38c.25-.79.29-1.64.49-2.46-.14.83-.1,1.68-.29,2.51a.11.11,0,0,1-.12.08.1.1,0,0,1-.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.17,11c.2-.81.18-1.66.33-2.49-.08.83,0,1.68-.14,2.53a.09.09,0,0,1-.11.08.1.1,0,0,1-.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.84,10c.1-.82,0-1.67,0-2.51,0,.84.19,1.67.16,2.53a.12.12,0,0,1-.11.1.11.11,0,0,1-.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.24,8.43c.07-.82-.08-1.67,0-2.51,0,.84.25,1.66.24,2.52a.09.09,0,0,1-.1.1.09.09,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.64,8.31c.07-.83-.08-1.67,0-2.51,0,.84.25,1.65.25,2.51a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.29,8c.07-.82-.07-1.67-.05-2.51,0,.84.25,1.66.25,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.27,7.42c.07-.82-.08-1.67-.05-2.51,0,.84.25,1.66.25,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.22,6c.06-.83-.09-1.67-.08-2.51,0,.84.28,1.65.28,2.51a.09.09,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.87,5.14c.05-.83-.12-1.67-.11-2.51.06.84.29,1.65.31,2.51a.09.09,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.55,4.23c0-.83-.14-1.67-.14-2.51.07.84.31,1.65.33,2.51a.1.1,0,0,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.24,4.06c0-.83-.14-1.66-.14-2.51.07.84.31,1.65.34,2.51a.09.09,0,0,1-.1.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,4.27c0-.83-.13-1.67-.13-2.51.06.84.3,1.65.32,2.51a.1.1,0,0,1-.09.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.1,6.13c.06-.82-.11-1.67-.1-2.51.06.84.29,1.66.3,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.48,7.43c.05-.83-.11-1.67-.11-2.51.06.84.29,1.65.31,2.51a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.71,8.91c0-.82-.28-1.65-.36-2.48.15.83.46,1.61.56,2.47a.11.11,0,0,1-.09.11.12.12,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.16,11.26c-.28-.78-.77-1.48-1.11-2.26.4.75.94,1.4,1.29,2.18a.1.1,0,1,1-.17.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15,13.1c-.38-.74-.95-1.38-1.37-2.11.48.7,1.09,1.28,1.54,2a.1.1,0,0,1-.17.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.46,15c-.43-.71-1.05-1.31-1.52-2,.52.65,1.18,1.19,1.68,1.89a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.13,17.48c-.45-.7-1.08-1.27-1.58-2,.55.64,1.22,1.15,1.74,1.83a.1.1,0,0,1,0,.14.09.09,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.71,25.68C10.22,25,9.56,24.47,9,23.82c.59.61,1.29,1.08,1.85,1.74a.1.1,0,0,1-.15.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.62,34.24C9.16,33.55,8.51,33,8,32.31c.55.63,1.23,1.14,1.77,1.81a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.53,37.74C9,37.1,8.32,36.59,7.75,36c.62.57,1.34,1,1.93,1.64a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.42,42.16c-.61-.56-1.37-.95-2-1.48.69.47,1.47.8,2.15,1.33a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.62,46.47C9,46,8.15,45.67,7.44,45.22c.74.39,1.55.63,2.29,1.08a.11.11,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.59,48.92c-.69-.45-1.5-.73-2.23-1.15.76.36,1.58.57,2.33,1a.1.1,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.68,49.67C8,49.22,7.17,49,6.43,48.55c.76.35,1.59.55,2.35,1a.1.1,0,0,1,0,.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.24,47.17C7.56,46.69,6.76,46.39,6,46c.75.39,1.56.61,2.31,1a.11.11,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.93,38.75c-.55-.62-1.27-1.09-1.87-1.68.65.54,1.39,1,2,1.54a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.33,34c-.49-.67-1.15-1.21-1.69-1.86.59.61,1.28,1.08,1.85,1.74a.11.11,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.05,30.25c-.49-.67-1.16-1.21-1.69-1.86.58.61,1.28,1.08,1.84,1.73a.1.1,0,0,1,0,.14.09.09,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.23,25.76C4.69,25.13,4,24.64,3.41,24c.63.56,1.36,1,2,1.6a.1.1,0,1,1-.14.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.66,22.31c-.56-.62-1.28-1.08-1.88-1.67.65.54,1.39.94,2,1.53a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.39,19.28c-.57-.61-1.29-1.07-1.9-1.65.65.53,1.4.92,2,1.51a.1.1,0,1,1-.13.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.06,15.3c-.56-.61-1.28-1.07-1.88-1.66.65.54,1.39.94,2,1.52a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M3.25,11C2.72,10.41,2,9.92,1.44,9.3c.62.57,1.35,1,2,1.61a.12.12,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M2.72,9.56C2.2,8.91,1.51,8.41,1,7.78c.61.58,1.33,1,1.92,1.65a.1.1,0,0,1-.14.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M2.89,8.31c-.5-.66-1.17-1.19-1.72-1.83.59.59,1.3,1.06,1.87,1.7a.1.1,0,0,1-.15.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4,7.68C3.49,7,2.84,6.45,2.31,5.8c.58.61,1.27,1.1,1.82,1.76a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.51,9.09C4,8.43,3.34,7.9,2.8,7.25c.59.6,1.29,1.07,1.86,1.72a.1.1,0,1,1-.15.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.19,8.19C6.74,7.5,6.11,6.92,5.61,6.24c.55.64,1.22,1.16,1.74,1.84a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.49,7.74c-.4-.72-1-1.33-1.45-2,.5.67,1.14,1.22,1.62,1.94a.1.1,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.36,7.21C12,6.45,11.49,5.78,11.11,5c.44.71,1,1.33,1.43,2.08a.1.1,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.89,6.59c-.22-.8-.65-1.54-.92-2.33.33.77.81,1.46,1.11,2.27a.1.1,0,0,1-.19.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.26,6.25c-.07-.83-.36-1.63-.48-2.47.19.82.53,1.6.67,2.44a.1.1,0,0,1-.08.12.1.1,0,0,1-.11-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.14,5.76c0-.83-.17-1.67-.19-2.51.09.84.34,1.64.39,2.5a.11.11,0,0,1-.1.11.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.05,5.17c.05-.83-.11-1.67-.1-2.51.06.84.29,1.65.29,2.51a.1.1,0,1,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.38,4.86c.06-.83-.11-1.67-.11-2.51.06.84.3,1.65.31,2.51a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.72,5c.05-.83-.13-1.67-.13-2.51.07.84.31,1.65.33,2.51a.09.09,0,0,1-.1.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.21,4.68c0-.83-.14-1.67-.14-2.51.07.84.31,1.65.33,2.51a.09.09,0,0,1-.09.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.66,4.65c0-.82-.13-1.66-.13-2.5.07.84.3,1.65.33,2.51a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.61,4.63c.06-.83-.11-1.67-.11-2.51.07.84.3,1.65.31,2.51a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.65,4.59c.06-.82-.1-1.67-.09-2.51,0,.84.28,1.66.29,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.3,6c.07-.82-.08-1.67-.06-2.51,0,.84.26,1.66.26,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.46,9.57c.07-.82-.07-1.67,0-2.51,0,.84.26,1.66.25,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.87,11c.15-.81.08-1.67.18-2.5,0,.84.1,1.67,0,2.53a.11.11,0,0,1-.11.09.12.12,0,0,1-.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.33,12.53c.25-.79.3-1.64.51-2.46-.15.83-.12,1.68-.32,2.51a.1.1,0,0,1-.12.08.09.09,0,0,1-.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.1,13.72c.36-.75.52-1.59.84-2.37-.26.8-.35,1.64-.66,2.44a.1.1,0,1,1-.18-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.22,15.47c.43-.71.67-1.54,1.06-2.28-.34.77-.5,1.6-.89,2.37a.1.1,0,0,1-.13,0,.11.11,0,0,1-.05-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82,14.26c.4-.72.62-1.55,1-2.31-.31.78-.45,1.62-.82,2.4a.1.1,0,0,1-.13.05.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.17,15.65c.46-.69.74-1.5,1.16-2.22-.36.75-.57,1.57-1,2.32a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.6,17c.5-.66.82-1.45,1.28-2.16-.4.74-.66,1.55-1.11,2.27a.1.1,0,0,1-.14,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.16,19.24c.54-.63.9-1.4,1.41-2.07-.45.71-.75,1.5-1.25,2.2a.11.11,0,0,1-.14,0,.09.09,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.85,23c.59-.59,1-1.33,1.58-2-.51.67-.87,1.44-1.43,2.09a.1.1,0,1,1-.15-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.9,26.47c.62-.55,1.09-1.27,1.69-1.86-.55.64-1,1.38-1.55,2a.1.1,0,0,1-.15-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.91,30.47c.64-.52,1.14-1.22,1.76-1.79-.57.62-1,1.34-1.63,1.94a.1.1,0,0,1-.14,0,.11.11,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.53,33.16c.65-.51,1.16-1.2,1.79-1.75-.58.6-1,1.32-1.67,1.9a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81,33.77c.67-.49,1.21-1.16,1.86-1.69-.61.58-1.08,1.28-1.73,1.84a.1.1,0,0,1-.14,0c0-.05,0-.11,0-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.66,35c.71-.43,1.3-1.05,2-1.53-.65.53-1.18,1.19-1.88,1.69a.1.1,0,1,1-.12-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.35,39.25c.77-.3,1.46-.81,2.22-1.16-.73.41-1.37,1-2.14,1.34a.11.11,0,0,1-.14,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.52,44.43c.8-.19,1.56-.59,2.37-.82-.79.3-1.5.75-2.32,1a.12.12,0,0,1-.13-.07.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.78,48.63c.82-.11,1.61-.44,2.44-.6-.81.22-1.57.61-2.41.79a.11.11,0,0,1-.12-.07.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.32,52.44c.82-.07,1.63-.36,2.46-.48-.82.18-1.59.53-2.44.67a.1.1,0,0,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.84,54.54c.83-.06,1.64-.33,2.48-.43-.83.17-1.61.5-2.46.62a.1.1,0,0,1-.11-.08.09.09,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.32,55.55c.82-.07,1.63-.35,2.47-.47-.83.18-1.6.53-2.45.67a.1.1,0,0,1-.11-.08.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.25,51.22c.82,0,1.64-.33,2.47-.44-.82.17-1.6.51-2.45.64a.1.1,0,0,1-.11-.08.1.1,0,0,1,.08-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.79,49.16c.83-.07,1.64-.36,2.47-.48-.82.19-1.59.53-2.44.68a.1.1,0,0,1-.12-.08.11.11,0,0,1,.09-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.19,47.25c.82-.08,1.62-.39,2.45-.53-.82.21-1.58.57-2.43.73a.1.1,0,0,1-.11-.08.09.09,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.49,45.63c.81-.11,1.6-.45,2.43-.62-.81.23-1.56.63-2.4.82a.1.1,0,1,1,0-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.24,44.08c.82-.13,1.6-.48,2.42-.67-.8.25-1.54.66-2.38.86a.09.09,0,0,1-.12-.07.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.05,43.05c.82-.15,1.59-.51,2.41-.71-.8.26-1.53.68-2.36.9a.09.09,0,0,1-.12-.07.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.25,42c.8-.17,1.57-.56,2.38-.78-.79.28-1.51.73-2.33,1a.1.1,0,0,1-.13-.06.09.09,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.61,40.93c.8-.19,1.55-.6,2.36-.85-.78.31-1.49.77-2.31,1a.09.09,0,1,1-.06-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.59,39.65c.79-.24,1.52-.69,2.31-1-.76.35-1.44.85-2.25,1.16a.09.09,0,0,1-.12,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,40.69c.8-.2,1.55-.62,2.35-.88-.78.32-1.48.79-2.29,1.07a.1.1,0,0,1-.13-.06A.09.09,0,0,1,67,40.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.74,40.46c.8-.22,1.54-.65,2.34-.92-.78.33-1.47.81-2.28,1.1a.1.1,0,0,1-.13-.06.09.09,0,0,1,.06-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.58,42.05c.81-.16,1.57-.54,2.39-.76-.8.28-1.52.71-2.35,1a.09.09,0,0,1-.12-.06.12.12,0,0,1,.07-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.76,44.66c.83-.1,1.62-.43,2.45-.58-.81.22-1.57.6-2.41.77a.1.1,0,1,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.83,46.76c.82-.06,1.63-.34,2.46-.46-.82.18-1.59.53-2.44.66a.09.09,0,0,1-.11-.08.09.09,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.4,49.36c.83,0,1.65-.28,2.49-.35-.83.14-1.62.45-2.47.54a.1.1,0,1,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.77,51.83c.82,0,1.65-.22,2.49-.27-.83.12-1.63.4-2.49.46a.1.1,0,0,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.26,53.2c.83,0,1.66-.21,2.5-.26-.84.11-1.63.39-2.49.46a.11.11,0,0,1-.11-.09.1.1,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.47,55.75c.82,0,1.65-.21,2.49-.26-.83.11-1.63.39-2.49.45a.1.1,0,0,1,0-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.65,58.84c.83,0,1.65-.25,2.49-.32-.83.13-1.62.43-2.47.52a.1.1,0,0,1-.11-.09.1.1,0,0,1,.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.06,61.65c.82,0,1.63-.33,2.47-.44-.82.17-1.6.51-2.45.64a.1.1,0,0,1-.11-.08.09.09,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.91,64.12c.82-.09,1.62-.4,2.45-.54-.81.2-1.58.57-2.42.73a.1.1,0,0,1-.12-.08.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.8,63.86c.83-.08,1.63-.37,2.46-.5-.82.19-1.58.55-2.43.69a.1.1,0,0,1-.12-.08.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.46,61.25c.82,0,1.64-.29,2.48-.37-.83.15-1.61.46-2.47.57a.1.1,0,0,1-.11-.08.11.11,0,0,1,.09-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.34,56.45c.83,0,1.67-.16,2.51-.18-.84.09-1.65.34-2.51.38a.1.1,0,0,1-.1-.1.1.1,0,0,1,.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.17,52c.83,0,1.66-.17,2.5-.19-.83.09-1.64.35-2.5.39a.1.1,0,0,1-.1-.1.1.1,0,0,1,.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.7,47.78c.83,0,1.64-.3,2.48-.39-.83.16-1.61.48-2.46.58a.09.09,0,0,1-.11-.08.1.1,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,43.16c.81-.14,1.59-.49,2.41-.68-.8.25-1.54.66-2.37.88a.1.1,0,0,1-.12-.08.1.1,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.54,37c.75-.36,1.41-.91,2.14-1.32-.7.46-1.3,1.06-2.05,1.49a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.56,30.1c.37-.74.53-1.58.86-2.36-.27.8-.36,1.64-.68,2.44a.09.09,0,0,1-.12.06.1.1,0,0,1-.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.22,26.72c.14-.81.06-1.67.16-2.5,0,.84.11,1.67,0,2.53a.1.1,0,0,1-.11.09.12.12,0,0,1-.09-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.46,22.07c.09-.82,0-1.67,0-2.51,0,.84.22,1.66.2,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.92,15.19c.07-.82-.08-1.67-.06-2.51,0,.84.26,1.66.26,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.16,13.27c.07-.82-.09-1.66-.07-2.51,0,.84.27,1.66.27,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.5,13.62c0-.83-.13-1.67-.14-2.51.07.84.31,1.65.34,2.51a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.71,17.59c-.16-.82-.52-1.59-.73-2.41.27.8.69,1.53.92,2.36a.1.1,0,0,1-.07.12.09.09,0,0,1-.12-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.24,24.64c-.43-.71-1-1.31-1.51-2,.53.66,1.18,1.2,1.67,1.9a.1.1,0,0,1-.16.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.42,32.13c-.44-.7-1.06-1.29-1.55-2,.54.64,1.2,1.17,1.71,1.86a.09.09,0,0,1,0,.14.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.38,44.91c-.65-.51-1.44-.85-2.13-1.33.72.42,1.52.69,2.24,1.17a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.74,52.09C9,51.67,8.2,51.43,7.45,51c.78.33,1.61.5,2.38.88a.1.1,0,0,1,.05.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.5,56.41c-.73-.4-1.56-.61-2.32-1,.79.3,1.62.44,2.41.79a.1.1,0,0,1,.05.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.09,58.49c-.73-.39-1.56-.59-2.33-1,.79.3,1.63.43,2.41.77a.1.1,0,0,1,.06.13c0,.05-.08.08-.13.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.63,59.3c-.72-.4-1.56-.6-2.32-1,.79.3,1.62.43,2.41.78a.1.1,0,1,1-.08.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.71,48.36c-.67-.49-1.47-.81-2.18-1.26.74.4,1.55.64,2.29,1.09a.1.1,0,1,1-.11.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.55,38.05c-.4-.72-1-1.34-1.45-2,.5.67,1.14,1.23,1.62,1.94a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.74,30.66c-.21-.79-.64-1.54-.9-2.34.32.78.8,1.47,1.09,2.29a.09.09,0,0,1-.06.12.1.1,0,0,1-.13-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.13,22.27c0-.83-.29-1.65-.37-2.49.15.83.46,1.61.56,2.47a.1.1,0,0,1-.08.11.09.09,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,15c.06-.82-.11-1.66-.1-2.5.06.83.29,1.65.3,2.51a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.85,13.13c.07-.83-.08-1.67-.06-2.51,0,.84.26,1.65.26,2.51a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.75,12.43c.07-.82-.08-1.67-.06-2.51.05.84.26,1.66.26,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.78,15.16c.08-.82-.07-1.67,0-2.51,0,.84.25,1.66.24,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.13,25c0-.83-.18-1.66-.21-2.51.1.84.36,1.64.41,2.5a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.6,30.72c0-.83-.25-1.65-.32-2.49.13.83.43,1.62.51,2.48a.1.1,0,0,1-.08.11.1.1,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.34,40.18c-.05-.83-.32-1.64-.42-2.47.16.82.5,1.6.62,2.45a.1.1,0,0,1-.08.11.09.09,0,0,1-.11-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.44,42c-.12-.82-.46-1.61-.63-2.43.23.8.63,1.55.82,2.39a.1.1,0,0,1-.07.12.1.1,0,0,1-.12-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.71,41.28c-.05-.83-.31-1.65-.4-2.48.16.82.48,1.61.59,2.46a.1.1,0,0,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.94,36.11c0-.83-.18-1.66-.21-2.5.1.83.36,1.64.41,2.5a.09.09,0,0,1-.09.1.11.11,0,0,1-.11-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.09,28.47c0-.83-.2-1.66-.23-2.5.1.83.37,1.63.42,2.49a.09.09,0,0,1-.09.11.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.71,25.72c.07-.83-.09-1.67-.08-2.51,0,.84.28,1.65.28,2.51a.09.09,0,0,1-.1.1.1.1,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.37,25.29c.08-.82,0-1.67,0-2.51,0,.84.23,1.66.21,2.52a.09.09,0,0,1-.1.1.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.88,25.6c.09-.82,0-1.67,0-2.51,0,.84.21,1.66.19,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.63,25c.1-.82,0-1.67,0-2.51,0,.84.21,1.66.18,2.52a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.52,26.44c.09-.82,0-1.67,0-2.51,0,.84.2,1.67.17,2.53a.1.1,0,1,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.27,27.56c.1-.82,0-1.67,0-2.51,0,.84.2,1.67.17,2.53a.1.1,0,0,1-.11.09.1.1,0,0,1-.09-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.33,28.79c.1-.82,0-1.67,0-2.51,0,.85.2,1.67.16,2.53a.1.1,0,0,1-.1.1.11.11,0,0,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.54,29.8c.11-.82,0-1.67.05-2.51,0,.84.19,1.66.15,2.52a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,31.32c.11-.82,0-1.67,0-2.51,0,.84.19,1.67.15,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.46,31.81c.11-.82,0-1.67,0-2.51,0,.84.19,1.67.15,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.73,33.6c.11-.82,0-1.67.06-2.51,0,.84.18,1.67.14,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.42,35.1c.17-.81.13-1.66.25-2.5-.06.84,0,1.68-.05,2.53a.1.1,0,1,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.81,36.9c.32-.77.43-1.62.7-2.42-.21.82-.25,1.66-.51,2.48a.1.1,0,0,1-.12.07.1.1,0,0,1-.07-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.75,37.9c.44-.7.7-1.52,1.1-2.26-.35.77-.53,1.59-.93,2.36a.1.1,0,0,1-.13,0,.09.09,0,0,1,0-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.08,33.52c.18-.81.15-1.67.29-2.5-.07.84,0,1.68-.1,2.53a.09.09,0,0,1-.11.09.1.1,0,0,1-.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.23,28.92c.11-.82,0-1.67,0-2.51,0,.84.2,1.67.16,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.66,19.35c.08-.82,0-1.67,0-2.51,0,.84.24,1.66.22,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.28,16.72c.07-.82-.07-1.67-.05-2.51,0,.84.25,1.66.25,2.52a.1.1,0,0,1-.2,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.46,16.83c.08-.83-.06-1.67,0-2.51,0,.84.24,1.66.23,2.52a.11.11,0,0,1-.1.1.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.12,18.85c0-.82-.21-1.65-.26-2.49.11.83.39,1.63.45,2.49a.1.1,0,1,1-.19,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.77,21.83c-.1-.83-.42-1.62-.56-2.45.21.81.58,1.57.75,2.41a.1.1,0,0,1-.08.12.09.09,0,0,1-.11-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.79,25.06c-.22-.8-.64-1.54-.91-2.34.33.78.81,1.47,1.1,2.28a.11.11,0,0,1-.06.13.1.1,0,0,1-.13-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.86,30.65c-.37-.74-.94-1.38-1.37-2.1.48.69,1.1,1.27,1.54,2a.09.09,0,0,1-.16.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.08,39.5c-.54-.64-1.24-1.12-1.83-1.73.63.56,1.36,1,2,1.59a.1.1,0,0,1-.14.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.47,45.07c-.64-.52-1.42-.87-2.1-1.36.72.43,1.51.72,2.22,1.21a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.42,49.79c-.68-.46-1.5-.73-2.22-1.16.75.37,1.57.57,2.33,1a.11.11,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.46,52.38c-.7-.43-1.52-.69-2.26-1.09.77.34,1.59.53,2.36.92a.09.09,0,0,1,0,.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.76,50.58c-.69-.46-1.5-.73-2.23-1.15.76.36,1.58.56,2.34,1a.1.1,0,0,1,0,.13.09.09,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.88,41.46c-.57-.59-1.31-1-1.93-1.6.66.51,1.42.89,2.07,1.46a.1.1,0,1,1-.13.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.64,33.05c-.39-.73-1-1.36-1.41-2.08.49.68,1.11,1.25,1.58,2a.1.1,0,1,1-.17.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.54,25.88c-.32-.77-.84-1.45-1.2-2.21.42.73,1,1.36,1.38,2.12a.11.11,0,0,1,0,.14.11.11,0,0,1-.13-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.9,22.38c-.25-.78-.72-1.5-1-2.28.37.75.89,1.42,1.22,2.21a.1.1,0,0,1-.05.13.09.09,0,0,1-.13-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.34,17.89c-.09-.83-.4-1.63-.54-2.46.21.82.57,1.58.73,2.43a.09.09,0,0,1-.07.11.1.1,0,0,1-.12-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.13,11c.07-.82-.09-1.66-.07-2.51.05.84.27,1.66.27,2.52a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.68,10.22c.06-.83-.1-1.67-.08-2.51,0,.84.27,1.65.28,2.51a.1.1,0,0,1-.1.1.09.09,0,0,1-.1-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.49,10.6c0-.82-.11-1.67-.1-2.51.06.84.28,1.66.29,2.52a.1.1,0,0,1-.09.1.11.11,0,0,1-.11-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.69,15.06c.07-.82-.08-1.67-.06-2.51,0,.84.26,1.66.26,2.52a.11.11,0,0,1-.1.1.09.09,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.94,26.82c.1-.82,0-1.67,0-2.51,0,.84.2,1.67.17,2.53a.1.1,0,0,1-.1.09.1.1,0,0,1-.1-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.64,37c.43-.71.67-1.53,1-2.28-.33.78-.49,1.61-.88,2.38a.1.1,0,1,1-.17-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.51,49.47c.79-.24,1.53-.68,2.32-1-.77.34-1.45.84-2.26,1.14a.1.1,0,0,1-.13,0,.1.1,0,0,1,.06-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.44,57.29c.81-.14,1.59-.5,2.41-.69-.8.26-1.54.67-2.37.88a.09.09,0,0,1-.12-.07.11.11,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.85,61.68c.82-.12,1.6-.46,2.43-.63-.81.23-1.56.63-2.4.82a.09.09,0,0,1-.11-.07.09.09,0,0,1,.07-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.5,62.87c.83-.09,1.63-.4,2.45-.55-.81.21-1.57.58-2.41.75a.1.1,0,0,1-.12-.08.09.09,0,0,1,.08-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.26,63.18c.82-.11,1.61-.45,2.43-.61-.81.23-1.56.62-2.4.81a.1.1,0,0,1,0-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.47,82.41c-.82.09-1.62.39-2.45.53.81-.2,1.58-.57,2.42-.72a.1.1,0,0,1,.12.07.1.1,0,0,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.16,82.48c-.83.08-1.63.38-2.46.51.82-.19,1.58-.55,2.43-.7a.1.1,0,0,1,.12.08.1.1,0,0,1-.08.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.27,83.69c-.82.11-1.62.44-2.44.6.81-.22,1.56-.61,2.4-.79a.1.1,0,0,1,.12.07.1.1,0,0,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,83.47c-.82.12-1.61.45-2.43.61.81-.23,1.56-.62,2.4-.8a.09.09,0,0,1,.11.07.09.09,0,0,1-.07.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.05,84.74c-.82.14-1.6.49-2.42.68.8-.25,1.54-.66,2.38-.87a.09.09,0,0,1,.12.07.11.11,0,0,1-.07.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.58,84.92c-.82.14-1.6.51-2.41.7.8-.26,1.53-.68,2.36-.9a.1.1,0,0,1,.12.07.09.09,0,0,1-.07.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.61,85c-.81.15-1.59.51-2.41.71.8-.26,1.53-.68,2.37-.9a.09.09,0,0,1,.12.07.11.11,0,0,1-.07.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.24,84.44c-.81.13-1.6.48-2.42.67.81-.25,1.55-.66,2.38-.86a.09.09,0,0,1,.12.07.09.09,0,0,1-.07.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.81,83.4c-.82.1-1.62.42-2.45.57.82-.21,1.57-.59,2.42-.76a.09.09,0,0,1,.11.07.1.1,0,0,1-.07.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.72,82.33c-.82.08-1.63.38-2.46.51.82-.19,1.59-.55,2.43-.71a.1.1,0,0,1,0,.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.29,80.74c-.83,0-1.65.27-2.49.35.83-.14,1.62-.45,2.47-.55.06,0,.11,0,.11.09a.1.1,0,0,1-.08.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.37,79.67c-.82,0-1.65.2-2.5.25.84-.11,1.64-.39,2.5-.45a.1.1,0,0,1,.1.1.09.09,0,0,1-.09.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.49,79.42c-.83,0-1.66.22-2.5.27.83-.11,1.63-.4,2.49-.47a.1.1,0,0,1,.1.09.09.09,0,0,1-.09.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.09,78.42c-.83-.06-1.67.11-2.51.1.84-.06,1.65-.29,2.51-.3a.09.09,0,0,1,.1.1.1.1,0,0,1-.09.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.71,78c-.83-.09-1.67,0-2.52,0,.85,0,1.67-.21,2.53-.18a.1.1,0,1,1,0,.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,76.07c-.82-.14-1.67-.06-2.51-.15.84,0,1.67-.12,2.53,0a.1.1,0,0,1,0,.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.39,74.9c-.8-.21-1.66-.22-2.48-.39.83.11,1.68,0,2.52.2a.1.1,0,0,1,0,.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.52,73.36c-.78-.27-1.64-.33-2.45-.56.83.16,1.67.16,2.51.37a.1.1,0,0,1,.07.12.1.1,0,0,1-.12.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,72.41c-.74-.37-1.58-.54-2.35-.87.79.27,1.63.37,2.43.69a.1.1,0,0,1,.06.13.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.24,71.67c-.67-.48-1.47-.78-2.18-1.23.74.39,1.55.63,2.29,1.07a.1.1,0,1,1-.1.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.93,70.54c-.63-.53-1.4-.9-2.08-1.4.71.45,1.5.74,2.2,1.24a.1.1,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.39,70.78c-.7-.43-1.52-.68-2.27-1.08.77.34,1.6.52,2.37.91a.12.12,0,0,1,0,.14.11.11,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.68,69.12c-.66-.5-1.46-.83-2.16-1.29.74.41,1.54.66,2.27,1.13a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.63,67.14c-.57-.6-1.3-1-1.92-1.62.66.53,1.41.91,2.05,1.48a.09.09,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,64.6c-.53-.64-1.23-1.13-1.8-1.75.62.57,1.35,1,1.95,1.62a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.25,62.18c-.53-.64-1.22-1.14-1.8-1.76.62.57,1.35,1,1.94,1.63a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.06,60.51c-.48-.67-1.14-1.22-1.66-1.88.57.62,1.27,1.1,1.82,1.76a.1.1,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.73,58.52c-.43-.71-1.06-1.3-1.54-2,.53.64,1.19,1.17,1.71,1.86a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.69,56.3c-.43-.7-1.06-1.29-1.54-2,.53.64,1.2,1.17,1.71,1.86a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,53c-.41-.72-1-1.33-1.46-2,.51.67,1.15,1.23,1.62,1.94a.1.1,0,0,1,0,.14A.1.1,0,0,1,31,53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.33,52.05c-.29-.77-.78-1.47-1.12-2.24.4.74.94,1.38,1.3,2.16a.1.1,0,0,1,0,.14.1.1,0,0,1-.13,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.68,53.74c-.4-.72-1-1.35-1.44-2.06.5.68,1.13,1.24,1.61,2a.11.11,0,0,1,0,.14.11.11,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.47,58.13c-.59-.58-1.33-1-2-1.55.68.5,1.45.86,2.11,1.41a.11.11,0,0,1,0,.14.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.73,64.05c-.67-.49-1.47-.8-2.18-1.26.74.4,1.55.64,2.29,1.1a.09.09,0,0,1,0,.13.1.1,0,0,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.17,70.18c-.76-.34-1.6-.48-2.39-.78.81.24,1.65.31,2.46.6a.1.1,0,0,1,.06.13.1.1,0,0,1-.12.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.38,73.41c-.8-.19-1.66-.18-2.49-.32.84.08,1.68,0,2.53.13a.1.1,0,0,1,0,.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.8,75.72c-.82-.11-1.67,0-2.51-.08.84,0,1.67-.16,2.53-.11a.09.09,0,0,1,.09.1.1.1,0,0,1-.1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.83,77.73c-.82,0-1.66.11-2.51.1.84,0,1.66-.28,2.52-.3a.11.11,0,0,1,.1.1.09.09,0,0,1-.1.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.7,80c-.82,0-1.66.19-2.5.22.84-.1,1.64-.36,2.5-.42a.1.1,0,0,1,.1.1.09.09,0,0,1-.09.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.11,82.26c-.82.06-1.63.34-2.47.45.83-.18,1.6-.52,2.45-.65a.09.09,0,0,1,.11.08.1.1,0,0,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.51,82.05c-.83,0-1.65.28-2.49.37.83-.15,1.62-.47,2.47-.57a.09.09,0,0,1,.11.09.1.1,0,0,1-.08.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.42,83.54c-.83.07-1.63.36-2.47.49.82-.19,1.59-.54,2.44-.69a.09.09,0,0,1,.11.08.1.1,0,0,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.34,84.57c-.82.08-1.63.38-2.46.51.82-.19,1.59-.55,2.43-.71a.1.1,0,0,1,0,.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.17,83.14c-.82,0-1.64.29-2.48.37.83-.15,1.61-.46,2.47-.57a.1.1,0,1,1,0,.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.15,81.73c-.82,0-1.65.21-2.49.26.83-.11,1.63-.39,2.49-.45a.1.1,0,1,1,0,.19Z"/>
      </g>}
      {r_type_hair >= 4 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,44.17a1,1,0,0,1-.37-.68.29.29,0,0,1,.21-.32.74.74,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.25.84.84,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.83,45.9a1,1,0,0,1-.36-.68.28.28,0,0,1,.21-.32.87.87,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.11.24.86.86,0,0,0,.38.49h0a.11.11,0,0,1,.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.27,43.15a1,1,0,0,1-.4-.67.28.28,0,0,1,.2-.32.62.62,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.09.24.8.8,0,0,0,.39.47h0a.14.14,0,0,1,.06.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,48.09a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.88.88,0,0,1,.36,0,.85.85,0,0,0-.33.09.18.18,0,0,0-.06.25.8.8,0,0,0,.45.42h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.22,48.16a1,1,0,0,1-.45-.63.3.3,0,0,1,.17-.34.63.63,0,0,1,.36,0,.7.7,0,0,0-.33.06.21.21,0,0,0-.08.26.88.88,0,0,0,.44.43h0a.14.14,0,0,1,.07.17.14.14,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.14,43.68a1,1,0,0,1-.38-.67.28.28,0,0,1,.2-.32.87.87,0,0,1,.36,0,.76.76,0,0,0-.34,0,.18.18,0,0,0-.1.24.81.81,0,0,0,.39.48h0a.12.12,0,0,1,.06.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.5,48.07a1,1,0,0,1-.42-.64.3.3,0,0,1,.19-.34.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.18.18,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.13.13,0,0,1,.06.17.14.14,0,0,1-.17.07S28.51,48.08,28.5,48.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,47.68a1,1,0,0,1-.35-.69.29.29,0,0,1,.22-.31.73.73,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.79.79,0,0,0,.36.49h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.44,46.73a1,1,0,0,1-.48-.6.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.6.6,0,0,0-.33.09.18.18,0,0,0-.06.25.8.8,0,0,0,.46.41h0a.13.13,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,45.78a1,1,0,0,1-.47-.61.28.28,0,0,1,.15-.35.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.09.18.18,0,0,0-.06.25.75.75,0,0,0,.45.41h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.86,47a1,1,0,0,1-.39-.67.28.28,0,0,1,.2-.32.86.86,0,0,1,.36,0,.76.76,0,0,0-.34,0,.18.18,0,0,0-.09.24.77.77,0,0,0,.39.47h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.17,44.31a1,1,0,0,1-.42-.64.3.3,0,0,1,.18-.34.86.86,0,0,1,.36,0,.72.72,0,0,0-.33,0,.2.2,0,0,0-.09.25.83.83,0,0,0,.41.45h0a.13.13,0,0,1,.07.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.85,47.87a1,1,0,0,1-.38-.67.29.29,0,0,1,.2-.32.74.74,0,0,1,.36,0,.64.64,0,0,0-.34,0,.19.19,0,0,0-.1.25.8.8,0,0,0,.39.47h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,45.48a1,1,0,0,1-.44-.63.28.28,0,0,1,.16-.34.88.88,0,0,1,.36,0,.75.75,0,0,0-.33.07.18.18,0,0,0-.07.25.84.84,0,0,0,.43.44h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.06,46.69A1,1,0,0,1,29.7,46a.31.31,0,0,1,.22-.32,1,1,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.87.87,0,0,0,.36.49h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.05S30.06,46.7,30.06,46.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.14,47.51a1,1,0,0,1-.49-.6.3.3,0,0,1,.15-.35.74.74,0,0,1,.36,0,.74.74,0,0,0-.33.09.19.19,0,0,0-.06.25.87.87,0,0,0,.46.41h0a.14.14,0,0,1,.08.16.12.12,0,0,1-.16.08S25.14,47.52,25.14,47.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.57,47.18a1,1,0,0,1-.42-.64.31.31,0,0,1,.19-.34.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.76.76,0,0,0,.41.45h0a.12.12,0,0,1,.06.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,44.71a1,1,0,0,1-.45-.62.28.28,0,0,1,.16-.35.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.08.18.18,0,0,0-.07.25.82.82,0,0,0,.43.43h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.17.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.92,45a1,1,0,0,1-.37-.68.31.31,0,0,1,.21-.32.85.85,0,0,1,.36,0,1,1,0,0,0-.34,0,.18.18,0,0,0-.1.24.84.84,0,0,0,.37.49h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,45a1,1,0,0,1-.41-.65.29.29,0,0,1,.18-.33.77.77,0,0,1,.37,0,.76.76,0,0,0-.34.05.2.2,0,0,0-.09.25.83.83,0,0,0,.41.45h0a.12.12,0,0,1,.06.16A.11.11,0,0,1,26,45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.74,46.14a1,1,0,0,1-.4-.66.28.28,0,0,1,.2-.32.73.73,0,0,1,.36,0,.94.94,0,0,0-.34,0,.2.2,0,0,0-.09.25.84.84,0,0,0,.39.47h0a.13.13,0,0,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.53,46.14a1,1,0,0,1-.42-.64.29.29,0,0,1,.18-.34.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.81.81,0,0,0,.42.45h0a.11.11,0,0,1,.07.16.14.14,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.4,47.2a1,1,0,0,1-.45-.63.28.28,0,0,1,.17-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.21.21,0,0,0-.07.26.86.86,0,0,0,.43.43h0a.12.12,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.34,43.69a1,1,0,0,1-.42-.64.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.61.61,0,0,0-.33.06A.18.18,0,0,0,24,43a.82.82,0,0,0,.42.44h0a.12.12,0,0,1,.06.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.91,45.25a1,1,0,0,1-.39-.67.27.27,0,0,1,.19-.32.62.62,0,0,1,.36,0,.65.65,0,0,0-.34,0,.18.18,0,0,0-.09.24A.82.82,0,0,0,27,45h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.45,46.39a1.05,1.05,0,0,1-.45-.63.29.29,0,0,1,.17-.34.87.87,0,0,1,.36,0,.6.6,0,0,0-.33.07.19.19,0,0,0-.08.25.84.84,0,0,0,.44.43h0a.13.13,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.72,46.78a1,1,0,0,1-.31-.71.28.28,0,0,1,.23-.3.81.81,0,0,1,.35.06,1,1,0,0,0-.34,0,.22.22,0,0,0-.12.24.84.84,0,0,0,.34.51h0a.14.14,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.24,45.34a1,1,0,0,1-.32-.7.28.28,0,0,1,.23-.3.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.8.8,0,0,0,.34.51h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.57,45.41a1,1,0,0,1-.28-.72c0-.13.1-.29.24-.29a.73.73,0,0,1,.36.07.83.83,0,0,0-.35,0,.2.2,0,0,0-.13.23.83.83,0,0,0,.32.52h0a.13.13,0,1,1-.15.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.7,45.84a1,1,0,0,1-.24-.73.29.29,0,0,1,.26-.28.83.83,0,0,1,.35.1.67.67,0,0,0-.34,0,.2.2,0,0,0-.15.22.9.9,0,0,0,.29.54h0a.13.13,0,1,1-.16.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,44.93a1,1,0,0,1-.24-.73.3.3,0,0,1,.26-.28.83.83,0,0,1,.35.1.66.66,0,0,0-.34,0c-.11,0-.15.11-.14.22a.84.84,0,0,0,.28.54h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,43.26a1,1,0,0,1-.28-.72c0-.13.11-.29.25-.29a.86.86,0,0,1,.36.08.84.84,0,0,0-.35,0,.2.2,0,0,0-.13.23.83.83,0,0,0,.31.53h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0S30,43.27,30,43.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.09,43.89a1,1,0,0,1-.24-.74c0-.13.12-.28.26-.27a.71.71,0,0,1,.35.1.8.8,0,0,0-.34,0,.2.2,0,0,0-.15.22.8.8,0,0,0,.29.54h0a.12.12,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.27,43.13A1,1,0,0,1,31,42.4a.29.29,0,0,1,.26-.28.83.83,0,0,1,.35.1.81.81,0,0,0-.34,0,.21.21,0,0,0-.15.23.89.89,0,0,0,.28.54h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.17,43.16a1,1,0,0,1-.2-.75.29.29,0,0,1,.27-.26.81.81,0,0,1,.35.12.68.68,0,0,0-.34-.06.21.21,0,0,0-.16.22.8.8,0,0,0,.26.55h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.23,41.58a1,1,0,0,1-.24-.73.3.3,0,0,1,.27-.28,1,1,0,0,1,.35.1.68.68,0,0,0-.34,0,.19.19,0,0,0-.15.22.8.8,0,0,0,.28.54h0a.11.11,0,0,1,0,.17.13.13,0,0,1-.18,0S30.23,41.59,30.23,41.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.42,41.77a1,1,0,0,1-.2-.74.28.28,0,0,1,.27-.26.76.76,0,0,1,.34.11.67.67,0,0,0-.34-.05.19.19,0,0,0-.15.21.84.84,0,0,0,.26.56h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,40.62a1,1,0,0,1-.2-.74.29.29,0,0,1,.28-.26.8.8,0,0,1,.34.11.8.8,0,0,0-.34,0,.2.2,0,0,0-.16.21.84.84,0,0,0,.26.56h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.18,0A0,0,0,0,1,31,40.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.42,41.23a1,1,0,0,1-.18-.75.31.31,0,0,1,.29-.26.83.83,0,0,1,.34.13.86.86,0,0,0-.34-.07.2.2,0,0,0-.16.21.78.78,0,0,0,.24.57h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.56,41.78a1,1,0,0,1-.17-.75.3.3,0,0,1,.29-.25.93.93,0,0,1,.34.13.68.68,0,0,0-.34-.06.18.18,0,0,0-.16.2.82.82,0,0,0,.23.57h0a.12.12,0,1,1-.17.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.88,39.74a1,1,0,0,1-.17-.75.29.29,0,0,1,.29-.25.64.64,0,0,1,.33.13A.78.78,0,0,0,32,38.8a.21.21,0,0,0-.17.21.86.86,0,0,0,.23.56h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.47,40.32a1,1,0,0,1-.15-.75.31.31,0,0,1,.3-.25.75.75,0,0,1,.33.14.66.66,0,0,0-.33-.07.19.19,0,0,0-.17.2.78.78,0,0,0,.21.57h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0A0,0,0,0,1,33.47,40.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.55,40.7a1,1,0,0,1-.14-.75c0-.13.16-.27.3-.24a.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08.2.2,0,0,0-.17.2.85.85,0,0,0,.2.58h0a.12.12,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.16,39.18a1,1,0,0,1-.14-.76.29.29,0,0,1,.3-.24.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08.2.2,0,0,0-.18.2.88.88,0,0,0,.21.58h0a.11.11,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.47,38a1,1,0,0,1-.12-.76.28.28,0,0,1,.3-.23.67.67,0,0,1,.33.15.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.19.78.78,0,0,0,.2.58h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,38.22a1.05,1.05,0,0,1-.11-.77.29.29,0,0,1,.31-.22.78.78,0,0,1,.33.16.84.84,0,0,0-.33-.1.19.19,0,0,0-.18.19.82.82,0,0,0,.18.59h0a.13.13,0,1,1-.19.17A0,0,0,0,0,35,38.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.87,36.51a1,1,0,0,1-.1-.76.3.3,0,0,1,.31-.22.78.78,0,0,1,.33.16.84.84,0,0,0-.33-.1c-.11,0-.17.09-.19.19a.84.84,0,0,0,.18.58h0a.12.12,0,1,1-.19.16S34.87,36.52,34.87,36.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.6,35.13a1,1,0,0,1-.1-.76c0-.13.18-.26.31-.23a.92.92,0,0,1,.33.16.82.82,0,0,0-.33-.09c-.11,0-.17.08-.18.19a.79.79,0,0,0,.17.58h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.26,34.45a1,1,0,0,1-.06-.77.29.29,0,0,1,.32-.2.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.76.76,0,0,0,.15.59h0a.12.12,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.36,33.52a1,1,0,0,1-.08-.77.29.29,0,0,1,.31-.21.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.19.87.87,0,0,0,.16.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S34.36,33.53,34.36,33.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.12,32.74A1,1,0,0,1,32,32c0-.13.18-.26.31-.23a.8.8,0,0,1,.33.17.7.7,0,0,0-.33-.1.19.19,0,0,0-.18.19.79.79,0,0,0,.17.58h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.23,32.61a1,1,0,0,1-.08-.76.29.29,0,0,1,.31-.22.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11.2.2,0,0,0-.19.19.81.81,0,0,0,.17.59h0a.12.12,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.36,31.61a1,1,0,0,1-.11-.76c0-.13.18-.26.31-.23a.92.92,0,0,1,.33.16.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.19.79.79,0,0,0,.18.58h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S31.36,31.62,31.36,31.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,32.12a1,1,0,0,1-.06-.77.27.27,0,0,1,.31-.21.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.78.78,0,0,0,.15.6h0a.12.12,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.46,31.44a1,1,0,0,1-.09-.77.28.28,0,0,1,.31-.22.71.71,0,0,1,.32.17.63.63,0,0,0-.32-.1.18.18,0,0,0-.19.18.8.8,0,0,0,.17.59h0a.13.13,0,1,1-.2.16A0,0,0,0,0,32.46,31.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,30.32a1,1,0,0,1-.09-.77.29.29,0,0,1,.31-.22.76.76,0,0,1,.32.17.7.7,0,0,0-.33-.1c-.11,0-.17.08-.18.19a.79.79,0,0,0,.17.58h0a.13.13,0,1,1-.2.16S32,30.33,32,30.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.93,32a1,1,0,0,1-.07-.77.29.29,0,0,1,.31-.21.82.82,0,0,1,.32.17.76.76,0,0,0-.32-.11.2.2,0,0,0-.19.19.85.85,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.31,32.14a1,1,0,0,1-.06-.77.28.28,0,0,1,.32-.2.73.73,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.76.76,0,0,0,.15.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.34,31.48a1,1,0,0,1-.06-.77.28.28,0,0,1,.32-.2.76.76,0,0,1,.32.17.82.82,0,0,0-.32-.11c-.12,0-.18.08-.2.18a.81.81,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.65,30.36a1,1,0,0,1-.06-.77.29.29,0,0,1,.33-.2.73.73,0,0,1,.31.17.76.76,0,0,0-.32-.11.2.2,0,0,0-.2.18.85.85,0,0,0,.15.59h0a.13.13,0,0,1-.21.15A0,0,0,0,1,37.65,30.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.35,27.07a1,1,0,0,1-.05-.76.3.3,0,0,1,.33-.21.79.79,0,0,1,.31.18.76.76,0,0,0-.32-.11.19.19,0,0,0-.2.17.88.88,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.47,26.73a1,1,0,0,1-.06-.76.29.29,0,0,1,.32-.21.83.83,0,0,1,.32.18,1,1,0,0,0-.32-.12.2.2,0,0,0-.2.18.89.89,0,0,0,.15.6h0a.13.13,0,1,1-.2.16S37.47,26.74,37.47,26.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.24,30.89a1,1,0,0,1-.06-.77.29.29,0,0,1,.31-.21,1,1,0,0,1,.32.17.76.76,0,0,0-.32-.11.2.2,0,0,0-.19.19.81.81,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0S35.25,30.9,35.24,30.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.16,28.56a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.2.79.79,0,0,1,.31.18,1,1,0,0,0-.32-.12.19.19,0,0,0-.19.18.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.53,26.47a1,1,0,0,1-.05-.77.28.28,0,0,1,.32-.21.79.79,0,0,1,.31.18.76.76,0,0,0-.32-.11.19.19,0,0,0-.2.17.88.88,0,0,0,.15.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.94,31.54a1,1,0,0,1-.08-.76.3.3,0,0,1,.31-.22.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11c-.11,0-.17.09-.19.19a.85.85,0,0,0,.16.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0A0,0,0,0,1,33.94,31.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.14,28.28a1,1,0,0,1-.07-.77.29.29,0,0,1,.32-.21.83.83,0,0,1,.32.18.66.66,0,0,0-.32-.12.2.2,0,0,0-.2.18.88.88,0,0,0,.15.6h0a.13.13,0,1,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.26,28.87a1,1,0,0,1-.06-.77.3.3,0,0,1,.32-.21.92.92,0,0,1,.32.18.86.86,0,0,0-.33-.11c-.11,0-.17.08-.19.18a.81.81,0,0,0,.14.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.77,27.79a1,1,0,0,1-.06-.77.28.28,0,0,1,.32-.2.76.76,0,0,1,.32.17.82.82,0,0,0-.32-.11c-.12,0-.18.08-.2.18a.85.85,0,0,0,.15.59h0a.13.13,0,1,1-.2.15S37.77,27.8,37.77,27.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.85,29.86a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.79.79,0,0,1,.31.18.66.66,0,0,0-.32-.12.2.2,0,0,0-.2.18.84.84,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.11,28.71a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.2.83.83,0,0,1,.32.18.7.7,0,0,0-.32-.12.2.2,0,0,0-.2.18.8.8,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S39.12,28.72,39.11,28.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.17,29.3a1,1,0,0,1-.08-.76.3.3,0,0,1,.32-.22.9.9,0,0,1,.32.17.7.7,0,0,0-.33-.1c-.11,0-.17.08-.19.18a.85.85,0,0,0,.16.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.86,29.38a1,1,0,0,1-.07-.77.29.29,0,0,1,.32-.21.83.83,0,0,1,.32.18.66.66,0,0,0-.32-.12.2.2,0,0,0-.2.18.88.88,0,0,0,.15.6h0a.13.13,0,0,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.77,29.9a1,1,0,0,1-.07-.77.29.29,0,0,1,.32-.21.76.76,0,0,1,.32.17A.82.82,0,0,0,35,29a.2.2,0,0,0-.2.18.88.88,0,0,0,.16.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S34.78,29.9,34.77,29.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.05,27.24a.92.92,0,0,1-.06-.76.28.28,0,0,1,.32-.21.65.65,0,0,1,.31.18.66.66,0,0,0-.32-.12.19.19,0,0,0-.19.18.8.8,0,0,0,.14.59h0a.13.13,0,1,1-.2.16A0,0,0,0,1,36.05,27.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.64,31.15a1,1,0,0,1-.07-.76.29.29,0,0,1,.32-.21.71.71,0,0,1,.32.17.82.82,0,0,0-.32-.11.2.2,0,0,0-.2.18.81.81,0,0,0,.16.59h0a.13.13,0,1,1-.2.16S36.64,31.16,36.64,31.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.69,30.38a1,1,0,0,1-.07-.76.27.27,0,0,1,.31-.21.71.71,0,0,1,.32.17.65.65,0,0,0-.32-.11.19.19,0,0,0-.19.18.81.81,0,0,0,.16.59h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,28.26a1,1,0,0,1-.07-.77.29.29,0,0,1,.32-.21,1.09,1.09,0,0,1,.32.17.82.82,0,0,0-.32-.11.2.2,0,0,0-.2.18.88.88,0,0,0,.15.6h0a.13.13,0,1,1-.2.16A0,0,0,0,0,35,28.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.16,28.66a1,1,0,0,1-.07-.77.28.28,0,0,1,.32-.21.76.76,0,0,1,.32.17.86.86,0,0,0-.33-.11.19.19,0,0,0-.19.18.85.85,0,0,0,.15.59h0a.13.13,0,1,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.92,26.11a1,1,0,0,1-.06-.77.3.3,0,0,1,.32-.21.92.92,0,0,1,.32.18.82.82,0,0,0-.32-.11c-.12,0-.18.08-.2.18a.86.86,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0S36.92,26.12,36.92,26.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.74,29.81a1,1,0,0,1-.06-.77.28.28,0,0,1,.32-.2.73.73,0,0,1,.31.17A.76.76,0,0,0,37,28.9a.19.19,0,0,0-.19.18.8.8,0,0,0,.14.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S36.74,29.82,36.74,29.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.16,29.05a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.21.87.87,0,0,1,.31.18.76.76,0,0,0-.32-.11.2.2,0,0,0-.2.18.86.86,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0A0,0,0,0,0,38.16,29.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.08,30.77A1,1,0,0,1,39,30a.29.29,0,0,1,.33-.2.69.69,0,0,1,.31.18.66.66,0,0,0-.32-.12.2.2,0,0,0-.2.18.84.84,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.44,33.48a1,1,0,0,1-.07-.77.31.31,0,0,1,.32-.21.83.83,0,0,1,.32.18.82.82,0,0,0-.32-.11c-.12,0-.18.08-.2.18a.85.85,0,0,0,.15.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,36.44,33.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.3,34.77a1,1,0,0,1-.07-.76.29.29,0,0,1,.31-.22.82.82,0,0,1,.32.17.75.75,0,0,0-.32-.1.18.18,0,0,0-.19.18.81.81,0,0,0,.16.59h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.19,35.36a1,1,0,0,1-.13-.76.29.29,0,0,1,.3-.23.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08.19.19,0,0,0-.17.2.77.77,0,0,0,.2.57h0a.12.12,0,1,1-.18.17S31.2,35.37,31.19,35.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.94,36.67a1,1,0,0,1-.13-.76.29.29,0,0,1,.3-.24.91.91,0,0,1,.33.15.8.8,0,0,0-.33-.08c-.12,0-.17.09-.18.19a.82.82,0,0,0,.2.58h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.7,37.21a1,1,0,0,1-.16-.76.28.28,0,0,1,.29-.24.78.78,0,0,1,.34.13.83.83,0,0,0-.34-.07.2.2,0,0,0-.17.2.86.86,0,0,0,.23.57h0a.13.13,0,1,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.2,37.16a1,1,0,0,1-.18-.75.28.28,0,0,1,.28-.25.66.66,0,0,1,.34.12.66.66,0,0,0-.33-.06c-.12,0-.16.11-.17.21a.83.83,0,0,0,.25.56h0a.13.13,0,0,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.9,38.36a1,1,0,0,1-.18-.74.28.28,0,0,1,.28-.26.82.82,0,0,1,.34.12.68.68,0,0,0-.34-.06.2.2,0,0,0-.16.21.83.83,0,0,0,.24.56h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.44,38.47a1,1,0,0,1-.22-.74.3.3,0,0,1,.27-.27.76.76,0,0,1,.34.11.64.64,0,0,0-.33,0,.2.2,0,0,0-.16.21.83.83,0,0,0,.27.55h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.39,39.53a1,1,0,0,1-.22-.74.28.28,0,0,1,.27-.26.83.83,0,0,1,.35.1.81.81,0,0,0-.34,0,.18.18,0,0,0-.15.21.8.8,0,0,0,.26.56h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.56,39.4a1,1,0,0,1-.26-.73.3.3,0,0,1,.26-.28.82.82,0,0,1,.35.09,1,1,0,0,0-.34,0,.2.2,0,0,0-.14.23.85.85,0,0,0,.29.53h0a.14.14,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.7,40.56a1,1,0,0,1-.25-.73c0-.14.12-.29.26-.28a.82.82,0,0,1,.35.09.67.67,0,0,0-.34,0,.18.18,0,0,0-.14.22.77.77,0,0,0,.29.54h0a.12.12,0,1,1-.15.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.56,40.63a1,1,0,0,1-.28-.72.29.29,0,0,1,.25-.29.82.82,0,0,1,.35.08.66.66,0,0,0-.34,0,.2.2,0,0,0-.14.23.83.83,0,0,0,.32.52h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.49,40.87a1,1,0,0,1-.31-.7.29.29,0,0,1,.24-.3.68.68,0,0,1,.35.06.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.82.82,0,0,0,.33.51h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.53,42a1,1,0,0,1-.28-.71A.28.28,0,0,1,28.5,41a.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.78.78,0,0,0,.31.52h0a.13.13,0,1,1-.15.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.18,42.06a1,1,0,0,1-.32-.7.3.3,0,0,1,.24-.31.81.81,0,0,1,.35.06,1,1,0,0,0-.34,0,.19.19,0,0,0-.12.24.88.88,0,0,0,.34.51h0a.13.13,0,1,1-.14.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.3,43.24a1,1,0,0,1-.31-.7.29.29,0,0,1,.23-.31,1,1,0,0,1,.35.06,1,1,0,0,0-.34,0,.2.2,0,0,0-.12.24.84.84,0,0,0,.34.51h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.23,44.43a1,1,0,0,1-.32-.7.28.28,0,0,1,.23-.3.81.81,0,0,1,.35,0,.78.78,0,0,0-.34,0,.19.19,0,0,0-.12.23.88.88,0,0,0,.34.51h0a.13.13,0,0,1-.13.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,47.3a1,1,0,0,1-.29-.72c0-.13.1-.29.24-.29a.75.75,0,0,1,.36.07.83.83,0,0,0-.35,0,.19.19,0,0,0-.12.23.83.83,0,0,0,.32.52h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.15,48.64a1,1,0,0,1-.32-.7.28.28,0,0,1,.23-.3.74.74,0,0,1,.36.06.65.65,0,0,0-.34,0,.21.21,0,0,0-.13.24.83.83,0,0,0,.35.5h0a.12.12,0,1,1-.13.21A0,0,0,0,1,33.15,48.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.83,49.37a1,1,0,0,1-.36-.68.29.29,0,0,1,.2-.32.72.72,0,0,1,.36,0,.78.78,0,0,0-.34,0,.21.21,0,0,0-.1.25.84.84,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.82,49.86a1,1,0,0,1-.43-.64.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.22.22,0,0,0-.08.26.82.82,0,0,0,.42.44h0a.15.15,0,0,1,.07.17.14.14,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.32,50.81a1,1,0,0,1-.41-.65.28.28,0,0,1,.19-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.83.83,0,0,0,.41.45h0a.12.12,0,0,1,.06.16.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.9,50.7a1,1,0,0,1-.49-.6.3.3,0,0,1,.15-.35.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.06.26.87.87,0,0,0,.46.41h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.58,51.66a1,1,0,0,1-.47-.61.31.31,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.89.89,0,0,0,.45.41h0a.14.14,0,0,1,.08.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.13,51.83a1,1,0,0,1-.52-.57.29.29,0,0,1,.13-.36.86.86,0,0,1,.35-.06.72.72,0,0,0-.32.11.19.19,0,0,0,0,.26.85.85,0,0,0,.48.38h0a.13.13,0,0,1,.09.16.14.14,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.6,53a1,1,0,0,1-.51-.57.28.28,0,0,1,.12-.36.75.75,0,0,1,.36-.05.71.71,0,0,0-.32.1.2.2,0,0,0-.05.26.81.81,0,0,0,.48.38h0a.11.11,0,0,1,.09.15.13.13,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.12,53a1,1,0,0,1-.56-.54.29.29,0,0,1,.1-.36A.78.78,0,0,1,26,52a.66.66,0,0,0-.32.13.19.19,0,0,0,0,.26.84.84,0,0,0,.51.34h0a.12.12,0,0,1,.1.14.12.12,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.81,53.9a1,1,0,0,1-.56-.53.28.28,0,0,1,.1-.37.72.72,0,0,1,.35-.08.79.79,0,0,0-.31.13.19.19,0,0,0,0,.26.8.8,0,0,0,.51.34h0a.12.12,0,0,1,.1.14.12.12,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.61,54.79a1,1,0,0,1-.55-.54.28.28,0,0,1,.09-.36.78.78,0,0,1,.36-.08.66.66,0,0,0-.32.13.2.2,0,0,0,0,.26.84.84,0,0,0,.51.34h0a.12.12,0,1,1,0,.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.11,55.2a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.64.64,0,0,1,.35-.1.8.8,0,0,0-.31.14.2.2,0,0,0,0,.27.82.82,0,0,0,.53.31h0a.14.14,0,0,1,.1.15.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.16,55.88a1,1,0,0,1-.57-.52.29.29,0,0,1,.1-.37.72.72,0,0,1,.35-.08.9.9,0,0,0-.32.13.21.21,0,0,0,0,.27.85.85,0,0,0,.51.33h0a.13.13,0,0,1,.11.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,56.12a1,1,0,0,1-.58-.5.29.29,0,0,1,.08-.37.74.74,0,0,1,.35-.1.69.69,0,0,0-.31.15.19.19,0,0,0,0,.26.85.85,0,0,0,.52.32h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.94,48.84a1,1,0,0,1,.43-.64.28.28,0,0,1,.38,0,.73.73,0,0,1,.14.33,1,1,0,0,0-.18-.29.21.21,0,0,0-.27,0,.83.83,0,0,0-.25.56h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.89,52.22a1,1,0,0,1,.47-.62.29.29,0,0,1,.37.06.78.78,0,0,1,.12.34.79.79,0,0,0-.16-.3.21.21,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.89,49.25a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.76.76,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.13.13,0,0,1-.14.11.13.13,0,0,1-.11-.15S59.88,49.25,59.89,49.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.73,49.45a1,1,0,0,1,.48-.6.29.29,0,0,1,.38.06.89.89,0,0,1,.11.35.83.83,0,0,0-.16-.31.2.2,0,0,0-.26,0,.85.85,0,0,0-.3.54h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,50.29a1,1,0,0,1,.51-.58.3.3,0,0,1,.37.09.73.73,0,0,1,.09.35.8.8,0,0,0-.14-.31.21.21,0,0,0-.27,0,.85.85,0,0,0-.32.52h0a.13.13,0,1,1-.25,0A0,0,0,0,1,60,50.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,49.39a1,1,0,0,1,.33-.7.29.29,0,0,1,.38,0,.84.84,0,0,1,.19.31.8.8,0,0,0-.22-.26.2.2,0,0,0-.26.06.87.87,0,0,0-.17.59h0a.13.13,0,0,1-.25,0A0,0,0,0,1,55,49.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,51.29a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14A0,0,0,0,1,59,51.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.22,51a1,1,0,0,1,.47-.61.3.3,0,0,1,.38.06.89.89,0,0,1,.11.35.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.38,49.67A1,1,0,0,1,57.8,49a.3.3,0,0,1,.38,0,.85.85,0,0,1,.14.34.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.8.8,0,0,0-.24.56h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.62,48.81a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.76.76,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.82.82,0,0,0-.33.52h0a.14.14,0,0,1-.15.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.61,50.38a1,1,0,0,1,.46-.62.29.29,0,0,1,.38.06.82.82,0,0,1,.12.34.87.87,0,0,0-.17-.3.2.2,0,0,0-.26,0,.82.82,0,0,0-.28.54h0a.13.13,0,0,1-.14.12.12.12,0,0,1-.12-.13A0,0,0,0,0,58.61,50.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,51.23a1,1,0,0,1,.56-.53.28.28,0,0,1,.36.12.76.76,0,0,1,.06.36.64.64,0,0,0-.11-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.48h0a.14.14,0,0,1-.16.1.15.15,0,0,1-.09-.16A0,0,0,0,0,61,51.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.35,51.34a1,1,0,0,1,.27-.72.29.29,0,0,1,.38,0,1,1,0,0,1,.21.29.82.82,0,0,0-.25-.24.19.19,0,0,0-.25.09.85.85,0,0,0-.11.6h0a.14.14,0,0,1-.1.16.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.06,49.69a1,1,0,0,1,.54-.55.28.28,0,0,1,.36.11.72.72,0,0,1,.08.35.9.9,0,0,0-.13-.32.19.19,0,0,0-.26,0,.77.77,0,0,0-.35.5h0a.13.13,0,0,1-.25-.05A0,0,0,0,1,61.06,49.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.68,48.36a.94.94,0,0,1,.48-.6.28.28,0,0,1,.37.06.76.76,0,0,1,.11.35.83.83,0,0,0-.16-.31.2.2,0,0,0-.26,0,.89.89,0,0,0-.3.54h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14A0,0,0,0,1,59.68,48.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.84,50.29a1,1,0,0,1,.56-.52.28.28,0,0,1,.36.12.72.72,0,0,1,.06.35.72.72,0,0,0-.11-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.49h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.15A0,0,0,0,1,61.84,50.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.7,52.29a1,1,0,0,1,.45-.63.29.29,0,0,1,.37.05.65.65,0,0,1,.13.34.7.7,0,0,0-.17-.29.2.2,0,0,0-.27,0,.81.81,0,0,0-.26.55h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.74,52.72a1,1,0,0,1,.4-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.77.77,0,0,0-.19-.28.19.19,0,0,0-.26,0,.78.78,0,0,0-.23.57h0a.12.12,0,0,1-.13.12.11.11,0,0,1-.12-.12S54.74,52.73,54.74,52.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.92,51.6A1,1,0,0,1,58.4,51a.29.29,0,0,1,.38.07.74.74,0,0,1,.1.34.66.66,0,0,0-.15-.3.19.19,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,0,1-.25,0A0,0,0,0,1,57.92,51.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.89,51.6a1,1,0,0,1,.4-.65.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32,1,1,0,0,0-.2-.28.2.2,0,0,0-.26,0,.82.82,0,0,0-.23.56h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.36,51.76A1,1,0,0,1,52.6,51,.29.29,0,0,1,53,51a.74.74,0,0,1,.22.28.65.65,0,0,0-.25-.22.2.2,0,0,0-.25.09.82.82,0,0,0-.08.61h0a.13.13,0,0,1-.09.16.11.11,0,0,1-.15-.09A0,0,0,0,1,52.36,51.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.37,50.35a1,1,0,0,1,.33-.7.3.3,0,0,1,.38,0,.76.76,0,0,1,.18.31.67.67,0,0,0-.22-.25.19.19,0,0,0-.26.06.77.77,0,0,0-.16.58h0a.12.12,0,0,1-.11.14.12.12,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,51.39a1,1,0,0,1,.39-.66.29.29,0,0,1,.38,0,.84.84,0,0,1,.16.33.75.75,0,0,0-.2-.28.18.18,0,0,0-.26,0,.79.79,0,0,0-.22.57h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.19,49.1a1,1,0,0,1,.38-.67.29.29,0,0,1,.38,0,.61.61,0,0,1,.16.32.8.8,0,0,0-.2-.28.2.2,0,0,0-.26,0,.85.85,0,0,0-.21.58h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.17,52.58a1,1,0,0,1,.36-.68.28.28,0,0,1,.38,0,.79.79,0,0,1,.17.31.78.78,0,0,0-.21-.26.19.19,0,0,0-.26,0,.8.8,0,0,0-.19.58h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12S53.16,52.59,53.17,52.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.88,52.18a1,1,0,0,1,.28-.72.3.3,0,0,1,.38,0,.78.78,0,0,1,.21.3.72.72,0,0,0-.24-.25.19.19,0,0,0-.25.08.81.81,0,0,0-.13.6h0a.13.13,0,0,1-.1.15.12.12,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.4,50.21a1,1,0,0,1,.4-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.63.63,0,0,0-.19-.28.19.19,0,0,0-.26,0,.78.78,0,0,0-.23.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.13-.12A0,0,0,0,1,56.4,50.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.75,52.13A1,1,0,0,1,54,51.4a.27.27,0,0,1,.37-.07.68.68,0,0,1,.22.29.68.68,0,0,0-.25-.23.19.19,0,0,0-.25.09.8.8,0,0,0-.09.6h0a.12.12,0,0,1-.09.15.11.11,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,50.27a1,1,0,0,1,.33-.69.29.29,0,0,1,.38,0,.7.7,0,0,1,.18.31.7.7,0,0,0-.22-.26.19.19,0,0,0-.26.06.81.81,0,0,0-.16.59h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.79,48a1,1,0,0,1,.47-.6.29.29,0,0,1,.38.06.9.9,0,0,1,.11.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.26,0A.85.85,0,0,0,61,48h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14S60.79,48,60.79,48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.86,50.23a1.05,1.05,0,0,1,.35-.69.29.29,0,0,1,.38,0,.76.76,0,0,1,.18.31.7.7,0,0,0-.22-.26.19.19,0,0,0-.26.06.84.84,0,0,0-.18.58h0a.14.14,0,0,1-.11.14.12.12,0,0,1-.14-.11A0,0,0,0,1,52.86,50.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.94,47.16a1,1,0,0,1,.46-.62.28.28,0,0,1,.37.05.75.75,0,0,1,.13.34,1,1,0,0,0-.17-.3.21.21,0,0,0-.27,0,.85.85,0,0,0-.27.55h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.73,50.54a1,1,0,0,1,.37-.67.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.71.71,0,0,0-.21-.27.19.19,0,0,0-.26,0,.81.81,0,0,0-.2.57h0a.13.13,0,0,1-.12.14.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.49,47.75a1,1,0,0,1,.42-.65.29.29,0,0,1,.38,0,.75.75,0,0,1,.14.33.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.13,48.29a1,1,0,0,1,.35-.68.29.29,0,0,1,.38,0,.81.81,0,0,1,.18.31.7.7,0,0,0-.22-.26.19.19,0,0,0-.26.05.84.84,0,0,0-.18.58h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.71,48.28a1,1,0,0,1,.25-.73.29.29,0,0,1,.38-.06.74.74,0,0,1,.22.28.9.9,0,0,0-.25-.23.2.2,0,0,0-.25.09.81.81,0,0,0-.1.6h0a.13.13,0,0,1-.09.16.13.13,0,0,1-.15-.09S52.71,48.29,52.71,48.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.56,46.06a1,1,0,0,1,.38-.68.29.29,0,0,1,.38,0,.69.69,0,0,1,.16.32.68.68,0,0,0-.2-.27.19.19,0,0,0-.26,0,.85.85,0,0,0-.21.58h0a.11.11,0,0,1-.11.13.13.13,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.37,46.36a1,1,0,0,1,.34-.69.29.29,0,0,1,.38,0,.76.76,0,0,1,.18.31.78.78,0,0,0-.22-.26.18.18,0,0,0-.25.06.83.83,0,0,0-.18.59h0a.12.12,0,0,1-.11.14.13.13,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.79,48.53A1,1,0,0,1,53,47.8a.28.28,0,0,1,.37-.06.74.74,0,0,1,.22.28.78.78,0,0,0-.25-.23.2.2,0,0,0-.25.09.82.82,0,0,0-.09.61h0a.13.13,0,1,1-.25.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.08,47.35a1,1,0,0,1,.28-.72.28.28,0,0,1,.38,0,1,1,0,0,1,.21.29.78.78,0,0,0-.24-.24.21.21,0,0,0-.26.08.84.84,0,0,0-.12.59h0a.13.13,0,0,1-.1.15.12.12,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.76,46.36a1,1,0,0,1,.25-.73.31.31,0,0,1,.38-.07,1,1,0,0,1,.22.29.77.77,0,0,0-.25-.23.18.18,0,0,0-.25.09.76.76,0,0,0-.1.6h0a.13.13,0,0,1-.25.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.61,46.72a1,1,0,0,1,.22-.74.3.3,0,0,1,.37-.08.77.77,0,0,1,.23.28.68.68,0,0,0-.26-.22.19.19,0,0,0-.24.1.82.82,0,0,0-.08.61h0a.14.14,0,0,1-.09.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.59,47.31a1,1,0,0,1,.16-.75.29.29,0,0,1,.37-.1.78.78,0,0,1,.25.26.71.71,0,0,0-.28-.21.19.19,0,0,0-.23.12.81.81,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.13.13,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,44.82a1,1,0,0,1,.22-.74.28.28,0,0,1,.37-.07.74.74,0,0,1,.23.27.7.7,0,0,0-.26-.22.19.19,0,0,0-.24.1.82.82,0,0,0-.08.61h0a.13.13,0,0,1-.25.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.15,45.74a1,1,0,0,1,.16-.75.28.28,0,0,1,.36-.11.89.89,0,0,1,.26.25.82.82,0,0,0-.28-.19.19.19,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.13.13,0,0,1-.16-.07A0,0,0,0,1,52.15,45.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,45.79a1,1,0,0,1,.12-.76.3.3,0,0,1,.36-.13.78.78,0,0,1,.26.25.67.67,0,0,0-.28-.19.19.19,0,0,0-.23.13.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.07.17.11.11,0,0,1-.16-.07S51,45.8,51,45.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.85,45.52a1,1,0,0,1,.08-.77.29.29,0,0,1,.35-.14.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.11,0-.18,0-.22.14a.8.8,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.18,44.12a1,1,0,0,1,.11-.76.28.28,0,0,1,.35-.13.7.7,0,0,1,.27.24.64.64,0,0,0-.29-.18.18.18,0,0,0-.22.13.8.8,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.06A0,0,0,0,1,51.18,44.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.59,43.18a1,1,0,0,1,.06-.77.29.29,0,0,1,.34-.16.82.82,0,0,1,.29.22.8.8,0,0,0-.3-.16.2.2,0,0,0-.22.15.85.85,0,0,0,.06.61h0a.11.11,0,0,1,0,.17.13.13,0,0,1-.17,0S50.6,43.19,50.59,43.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.05,42.83a1,1,0,0,1,.06-.77.29.29,0,0,1,.35-.15.67.67,0,0,1,.28.22.63.63,0,0,0-.3-.16c-.11,0-.18.05-.22.15a.82.82,0,0,0,.06.6h0a.13.13,0,0,1-.22.12A0,0,0,0,1,52.05,42.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.58,41.71a1,1,0,0,1,.08-.77A.3.3,0,0,1,53,40.8a.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17.2.2,0,0,0-.22.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17-.06S52.58,41.72,52.58,41.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.12,41.49a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.7.7,0,0,1,.29.21.61.61,0,0,0-.3-.15.2.2,0,0,0-.22.16.87.87,0,0,0,.08.6h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.53,40.47a1,1,0,0,1,0-.77.29.29,0,0,1,.35-.15.77.77,0,0,1,.29.22.71.71,0,0,0-.31-.16.18.18,0,0,0-.21.15.85.85,0,0,0,0,.61h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.83,40.07a1,1,0,0,1,.06-.77.3.3,0,0,1,.35-.15.88.88,0,0,1,.28.22.73.73,0,0,0-.3-.16c-.11,0-.18,0-.22.15a.85.85,0,0,0,.06.61h0a.13.13,0,0,1-.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.9,39.26A1,1,0,0,1,54,38.5a.29.29,0,0,1,.35-.15.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17.2.2,0,0,0-.22.14.8.8,0,0,0,.05.61h0a.13.13,0,0,1-.05.17.13.13,0,0,1-.17-.05A0,0,0,0,1,53.9,39.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.52,39.21a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.7.7,0,0,1,.29.21.85.85,0,0,0-.3-.15.21.21,0,0,0-.22.16.9.9,0,0,0,.08.61h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,39.36a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.9.9,0,0,1,.3.21.69.69,0,0,0-.31-.15.19.19,0,0,0-.21.16.85.85,0,0,0,.08.61h0a.13.13,0,0,1-.22.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.4,37.45a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.81.81,0,0,1,.3.21,1,1,0,0,0-.31-.15.2.2,0,0,0-.21.16.85.85,0,0,0,.08.61h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.71,38.27a1,1,0,0,1,0-.77.3.3,0,0,1,.34-.18.75.75,0,0,1,.3.2.67.67,0,0,0-.31-.14.21.21,0,0,0-.21.16.81.81,0,0,0,.11.61h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S49.71,38.28,49.71,38.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.28,36.07a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.18.81.81,0,0,1,.3.21.69.69,0,0,0-.31-.15.2.2,0,0,0-.21.17.82.82,0,0,0,.1.6h0a.13.13,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.65,36.33a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.18.81.81,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.16.86.86,0,0,0,.11.61h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,50.65,36.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.11,34.81a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.72.72,0,0,1,.3.2.79.79,0,0,0-.31-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.1.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.18,34.17a1,1,0,0,1,0-.78.29.29,0,0,1,.33-.18.81.81,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.66,35.19a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.81.81,0,0,1,.31.19.87.87,0,0,0-.32-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0S49.66,35.2,49.66,35.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,33.64a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.81.81,0,0,1,.31.19,1,1,0,0,0-.32-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.13.13,0,1,1-.21.14S50,33.65,50,33.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,35.33a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.78.78,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.61,34.08a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.81.81,0,0,1,.31.19.8.8,0,0,0-.32-.13c-.11,0-.17.07-.2.17a.88.88,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S48.61,34.09,48.61,34.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.89,32.09a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.83.83,0,0,1,.31.2.68.68,0,0,0-.32-.13c-.11,0-.17.07-.2.17a.82.82,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,29.73A1,1,0,0,1,50,29a.29.29,0,0,1,.33-.19.79.79,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.87.87,0,0,0,.11.6h0a.13.13,0,1,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,32.2a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.51,33.28a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.93,30.26a1.08,1.08,0,0,1,0-.77.29.29,0,0,1,.33-.18.81.81,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.07,30.83a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.85.85,0,0,1,.3.19.76.76,0,0,0-.31-.13.2.2,0,0,0-.21.17.82.82,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S48.08,30.84,48.07,30.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.51,33.55a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.71.71,0,0,1,.31.19.68.68,0,0,0-.32-.13.19.19,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.13.13,0,0,1-.21.14S47.51,33.56,47.51,33.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.76,34.6a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.71.71,0,0,1,.31.19.87.87,0,0,0-.32-.13.19.19,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.14,31.48a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.81.81,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.13,28.84a1,1,0,0,1,0-.78.31.31,0,0,1,.34-.18.88.88,0,0,1,.3.2.79.79,0,0,0-.31-.14c-.12,0-.18.07-.21.17a.82.82,0,0,0,.1.6h0a.13.13,0,0,1-.21.14A0,0,0,0,1,51.13,28.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.83,31.8a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.81.81,0,0,1,.31.19,1,1,0,0,0-.32-.13c-.11,0-.17.08-.2.18a.86.86,0,0,0,.12.59h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.5,30.94a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.83.83,0,0,1,.31.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.74,32.78a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.12.6h0a.12.12,0,1,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,30.53a1,1,0,0,1,0-.77.3.3,0,0,1,.34-.19,1,1,0,0,1,.3.2.65.65,0,0,0-.31-.13.18.18,0,0,0-.21.16.82.82,0,0,0,.1.6h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,32.67a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.18.77.77,0,0,1,.3.19.76.76,0,0,0-.31-.13.2.2,0,0,0-.21.17.82.82,0,0,0,.12.6h0a.13.13,0,0,1,0,.17.14.14,0,0,1-.18,0S51,32.68,51,32.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,30.86a1.05,1.05,0,0,1,0-.78.29.29,0,0,1,.34-.18.75.75,0,0,1,.3.2.79.79,0,0,0-.31-.14c-.12,0-.18.07-.21.17a.82.82,0,0,0,.11.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S53.05,30.86,53,30.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.6,32.24a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.18.64.64,0,0,1,.3.2.73.73,0,0,0-.31-.14.21.21,0,0,0-.21.16.85.85,0,0,0,.1.61h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.54,33.65a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.79.79,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.82.82,0,0,0,.11.6h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,52.54,33.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.65,34.54a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.18.74.74,0,0,1,.3.21.81.81,0,0,0-.31-.15.2.2,0,0,0-.21.17.89.89,0,0,0,.09.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S53.66,34.55,53.65,34.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.89,37.13a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.18.64.64,0,0,1,.3.2.73.73,0,0,0-.31-.14.18.18,0,0,0-.2.16.85.85,0,0,0,.09.61h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.1,38.19a1,1,0,0,1,0-.77.28.28,0,0,1,.34-.18.66.66,0,0,1,.29.2.73.73,0,0,0-.31-.14.18.18,0,0,0-.2.16.8.8,0,0,0,.09.61h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0S51.11,38.19,51.1,38.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.51,37.89a1,1,0,0,1,0-.76A.31.31,0,0,1,54.9,37a.93.93,0,0,1,.29.22.84.84,0,0,0-.3-.15.19.19,0,0,0-.22.15.85.85,0,0,0,.06.61h0a.14.14,0,0,1-.05.18.13.13,0,0,1-.17,0A0,0,0,0,1,54.51,37.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.06,38.66a1,1,0,0,1,.06-.77.29.29,0,0,1,.34-.16.74.74,0,0,1,.29.23.75.75,0,0,0-.3-.17.2.2,0,0,0-.22.15.85.85,0,0,0,.05.61h0a.13.13,0,0,1-.22.12A0,0,0,0,1,56.06,38.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.25,41.09a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.16.8.8,0,0,1,.29.22.88.88,0,0,0-.3-.16.2.2,0,0,0-.22.16.82.82,0,0,0,.06.6h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.94,42a1,1,0,0,1,.11-.76.3.3,0,0,1,.36-.14.93.93,0,0,1,.27.25.77.77,0,0,0-.29-.19.2.2,0,0,0-.23.14.85.85,0,0,0,0,.61h0A.13.13,0,0,1,54,42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.93,44a1,1,0,0,1,.09-.77.3.3,0,0,1,.35-.14.91.91,0,0,1,.28.23.83.83,0,0,0-.3-.17.19.19,0,0,0-.22.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06A0,0,0,0,1,49.93,44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.76,44.43a1,1,0,0,1,.15-.76.28.28,0,0,1,.36-.11.89.89,0,0,1,.26.25,1,1,0,0,0-.28-.2.21.21,0,0,0-.24.13.86.86,0,0,0,0,.61h0a.14.14,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.37,47.38a1,1,0,0,1,.12-.76.3.3,0,0,1,.37-.12.88.88,0,0,1,.26.25.77.77,0,0,0-.29-.19.19.19,0,0,0-.23.13.85.85,0,0,0,0,.61h0a.14.14,0,0,1-.07.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.89,46a1,1,0,0,1,.21-.74.3.3,0,0,1,.37-.08.8.8,0,0,1,.24.28.77.77,0,0,0-.26-.22.2.2,0,0,0-.25.1.87.87,0,0,0-.07.61h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.09,46.77a1.07,1.07,0,0,1,.32-.71.29.29,0,0,1,.38,0,.7.7,0,0,1,.19.3.76.76,0,0,0-.22-.25.19.19,0,0,0-.26.06.86.86,0,0,0-.16.59h0a.12.12,0,0,1-.1.15.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.09,47.81a1,1,0,0,1,.36-.68.3.3,0,0,1,.38,0,.85.85,0,0,1,.18.32.82.82,0,0,0-.21-.27.19.19,0,0,0-.26,0,.79.79,0,0,0-.2.58h0a.12.12,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,51.52a1,1,0,0,1,.53-.56.3.3,0,0,1,.37.11.72.72,0,0,1,.08.35.9.9,0,0,0-.13-.32.19.19,0,0,0-.26,0,.77.77,0,0,0-.35.5h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15S60,51.52,60,51.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,52.87a1,1,0,0,1,.56-.52.29.29,0,0,1,.36.12.91.91,0,0,1,.06.36.86.86,0,0,0-.11-.33.2.2,0,0,0-.27,0,.84.84,0,0,0-.37.48h0a.12.12,0,0,1-.15.09A.11.11,0,0,1,60,52.9,0,0,0,0,1,60,52.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.61,54a1,1,0,0,1,.5-.58.28.28,0,0,1,.37.08.74.74,0,0,1,.1.35.64.64,0,0,0-.15-.31.19.19,0,0,0-.26,0,.85.85,0,0,0-.32.52h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.26,54.53a1,1,0,0,1,.39-.67.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32,1,1,0,0,0-.2-.28.22.22,0,0,0-.27,0,.84.84,0,0,0-.21.57h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12A0,0,0,0,1,53.26,54.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.42,54.79a1,1,0,0,1,.48-.61.29.29,0,0,1,.37.07.72.72,0,0,1,.11.34.71.71,0,0,0-.15-.3.2.2,0,0,0-.27,0,.81.81,0,0,0-.29.54h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.26,55a1,1,0,0,1,.54-.55.28.28,0,0,1,.36.1.74.74,0,0,1,.08.35.79.79,0,0,0-.13-.31.19.19,0,0,0-.26,0,.85.85,0,0,0-.35.51h0a.14.14,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,55.53a1,1,0,0,1,.56-.52.28.28,0,0,1,.36.12.72.72,0,0,1,.06.35.72.72,0,0,0-.11-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.49h0a.14.14,0,0,1-.16.09.13.13,0,0,1-.09-.15S58,55.54,58,55.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.56,55.92A1,1,0,0,1,55,55.3a.3.3,0,0,1,.37.06.77.77,0,0,1,.12.35.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.65,56.44a1,1,0,0,1,.52-.57.3.3,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.33.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14S55.65,56.45,55.65,56.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.69,57.11a1,1,0,0,1,.46-.62.28.28,0,0,1,.37.06.83.83,0,0,1,.13.34.87.87,0,0,0-.17-.3.2.2,0,0,0-.27,0,.81.81,0,0,0-.27.54h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.85,58a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,54.85,58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.6,58.24a1,1,0,0,1,.57-.52.29.29,0,0,1,.36.13.71.71,0,0,1,0,.35.86.86,0,0,0-.1-.32.2.2,0,0,0-.27,0,.77.77,0,0,0-.37.48h0a.13.13,0,1,1-.24-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.64,59.23a1,1,0,0,1,.56-.53.28.28,0,0,1,.36.12.84.84,0,0,1,.07.35.88.88,0,0,0-.12-.32.2.2,0,0,0-.26,0,.84.84,0,0,0-.37.49h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.66,59.78a1,1,0,0,1,.59-.5.28.28,0,0,1,.35.13.64.64,0,0,1,0,.36.74.74,0,0,0-.09-.33.2.2,0,0,0-.26,0,.81.81,0,0,0-.39.47h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.55,60a1,1,0,0,1,.61-.47.3.3,0,0,1,.35.15.89.89,0,0,1,0,.36.76.76,0,0,0-.08-.33.2.2,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.14.14,0,0,1-.16.08.12.12,0,0,1-.08-.16A0,0,0,0,1,58.55,60Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.61,59.51a1,1,0,0,1,.62-.46.3.3,0,0,1,.35.16.89.89,0,0,1,0,.36.73.73,0,0,0-.08-.33.18.18,0,0,0-.25-.07.88.88,0,0,0-.43.44h0a.12.12,0,0,1-.16.07.12.12,0,0,1-.08-.16S59.61,59.52,59.61,59.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.65,60a1.06,1.06,0,0,1,.6-.49.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.85.85,0,0,0-.4.46h0a.13.13,0,1,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.39,59a1,1,0,0,1,.61-.47.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.73.73,0,0,0-.08-.33.2.2,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.13.13,0,0,1-.24-.08S58.39,59,58.39,59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,58.24a1,1,0,0,1,.62-.45.28.28,0,0,1,.34.16.74.74,0,0,1,0,.36.88.88,0,0,0-.07-.33.19.19,0,0,0-.26-.07.77.77,0,0,0-.42.43h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.83,57.62a1,1,0,0,1,.61-.48.28.28,0,0,1,.34.15.75.75,0,0,1,0,.36.76.76,0,0,0-.08-.33.2.2,0,0,0-.26-.06.85.85,0,0,0-.41.45h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.16S58.83,57.63,58.83,57.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.76,56.32a1,1,0,0,1,.61-.48.3.3,0,0,1,.34.16.74.74,0,0,1,0,.36.7.7,0,0,0-.08-.33A.21.21,0,0,0,60.4,56a.85.85,0,0,0-.41.45h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.07-.16S59.75,56.33,59.76,56.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.27,54.33a1,1,0,0,1,.57-.52.3.3,0,0,1,.36.13.75.75,0,0,1,0,.36.85.85,0,0,0-.1-.33.2.2,0,0,0-.27,0,.77.77,0,0,0-.37.48h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.22,52.64a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.1.86.86,0,0,1,.09.35.68.68,0,0,0-.14-.31.18.18,0,0,0-.26,0,.84.84,0,0,0-.34.51h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14S58.22,52.65,58.22,52.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.75,44.81a1,1,0,0,1,.28-.72.3.3,0,0,1,.38-.05.83.83,0,0,1,.2.3.78.78,0,0,0-.24-.24.19.19,0,0,0-.25.08.81.81,0,0,0-.12.6h0a.13.13,0,0,1-.1.15.14.14,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,42.85a1,1,0,0,1,.19-.74.3.3,0,0,1,.37-.1.76.76,0,0,1,.24.27.86.86,0,0,0-.26-.21.2.2,0,0,0-.25.11.93.93,0,0,0,0,.61h0a.14.14,0,0,1-.08.16.12.12,0,0,1-.16-.08S56,42.86,56,42.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.58,41.14a1,1,0,0,1,.13-.76.3.3,0,0,1,.37-.12,1,1,0,0,1,.26.25.77.77,0,0,0-.29-.19.2.2,0,0,0-.23.13.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06S55.58,41.15,55.58,41.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.26,39.73a1,1,0,0,1,.08-.76.28.28,0,0,1,.35-.14.7.7,0,0,1,.28.23.67.67,0,0,0-.29-.17.19.19,0,0,0-.23.14.8.8,0,0,0,0,.61h0a.11.11,0,0,1-.06.16.11.11,0,0,1-.16-.05A0,0,0,0,1,55.26,39.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.71,36.76a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.78.78,0,0,1,.29.21.69.69,0,0,0-.3-.14.2.2,0,0,0-.22.15.9.9,0,0,0,.08.61h0a.14.14,0,0,1,0,.17.13.13,0,0,1-.18,0S54.71,36.77,54.71,36.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.31,35.55a1,1,0,0,1,0-.77.27.27,0,0,1,.33-.17.69.69,0,0,1,.3.2.79.79,0,0,0-.31-.14c-.11,0-.18.06-.21.16a.82.82,0,0,0,.1.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0A0,0,0,0,1,53.31,35.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.17,33.25a.94.94,0,0,1,0-.77.28.28,0,0,1,.33-.18.79.79,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.82.82,0,0,0,.1.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0A0,0,0,0,1,53.17,33.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.37,31.86a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.83.83,0,0,1,.31.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.1.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0S51.38,31.86,51.37,31.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,29.87a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.18.75.75,0,0,1,.3.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.1.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0A0,0,0,0,1,51,29.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,30.33a1,1,0,0,1,0-.77.27.27,0,0,1,.32-.19.71.71,0,0,1,.31.19.65.65,0,0,0-.31-.13c-.12,0-.18.07-.21.17a.88.88,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S47,30.34,47,30.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.08,28.42a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.85.85,0,0,1,.3.19.76.76,0,0,0-.31-.13.2.2,0,0,0-.21.17.88.88,0,0,0,.12.6h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0S49.09,28.42,49.08,28.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.79,28.09a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.81.81,0,0,1,.31.19,1,1,0,0,0-.32-.13.2.2,0,0,0-.2.18A.88.88,0,0,0,48,28h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0A0,0,0,0,1,47.79,28.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.28,26.6a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.81.81,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.18.88.88,0,0,0,.12.6h0a.13.13,0,0,1-.21.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.57,27.05a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.19.79.79,0,0,1,.31.18.74.74,0,0,0-.31-.12.18.18,0,0,0-.2.17.82.82,0,0,0,.12.6h0a.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.75,25.93a1,1,0,0,1,0-.77A.29.29,0,0,1,47,25a.71.71,0,0,1,.31.19A.76.76,0,0,0,47,25a.19.19,0,0,0-.2.17.81.81,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S46.75,25.94,46.75,25.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.15,24.93a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.2.81.81,0,0,1,.31.19.66.66,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.12.12,0,0,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.48,23.09a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12c-.11,0-.17.07-.2.17a.88.88,0,0,0,.13.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S46.48,23.1,46.48,23.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.54,21a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2.81.81,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.18.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S46.54,21,46.54,21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.65,22a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12c-.11,0-.17.07-.2.17a.88.88,0,0,0,.13.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S46.65,22,46.65,22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.4,21.19a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2,1,1,0,0,1,.31.18.84.84,0,0,0-.32-.12.2.2,0,0,0-.2.18.8.8,0,0,0,.13.59h0a.13.13,0,0,1-.21.15A0,0,0,0,0,45.4,21.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.84,23.85a1,1,0,0,1-.05-.77.29.29,0,0,1,.33-.2.87.87,0,0,1,.31.18.66.66,0,0,0-.32-.12.2.2,0,0,0-.2.18.8.8,0,0,0,.14.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.54,21.94a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.69.69,0,0,1,.31.18.66.66,0,0,0-.32-.12c-.11,0-.17.07-.19.17a.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.56,25.05a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2.87.87,0,0,1,.31.18.78.78,0,0,0-.32-.12.2.2,0,0,0-.2.18.8.8,0,0,0,.13.59h0a.13.13,0,1,1-.21.15A0,0,0,0,0,45.56,25.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.54,22.3a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.79.79,0,0,1,.31.18.74.74,0,0,0-.31-.12c-.12,0-.18.08-.2.18a.8.8,0,0,0,.13.59h0a.13.13,0,1,1-.2.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.11,24.91a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12.2.2,0,0,0-.2.18.8.8,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0A0,0,0,0,1,44.11,24.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.21,23.26a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.19.18.83.83,0,0,0,.13.6h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.1,22.88a1,1,0,0,1-.05-.77.3.3,0,0,1,.33-.2.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.18.83.83,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0A0,0,0,0,1,44.1,22.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.77,25.73a1.08,1.08,0,0,1,0-.77.3.3,0,0,1,.33-.2.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12.2.2,0,0,0-.2.18.85.85,0,0,0,.13.59h0a.13.13,0,1,1-.2.15S44.78,25.73,44.77,25.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.94,24.19a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.69.69,0,0,1,.31.18.63.63,0,0,0-.31-.12.18.18,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.24,24.12a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.79.79,0,0,1,.31.18.74.74,0,0,0-.31-.12.18.18,0,0,0-.2.17.82.82,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.45,23.84a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.12.12,0,0,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.62,22.55a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13c-.11,0-.17.07-.2.17a.83.83,0,0,0,.13.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.55,23.38a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.71.71,0,0,1,.31.19.87.87,0,0,0-.32-.13.19.19,0,0,0-.2.17.78.78,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.15,21a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12.19.19,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.13.13,0,0,1-.21.14S48.15,21,48.15,21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.06,21.7a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0S49.06,21.71,49.06,21.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.87,22.84a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.88.88,0,0,1,.3.2.65.65,0,0,0-.31-.13c-.12,0-.18.07-.21.17a.89.89,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.13,20.92a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.81.81,0,0,1,.31.19.87.87,0,0,0-.32-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.87,21.85a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.79.79,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.82.82,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S50.87,21.86,50.87,21.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.71,22.92a1,1,0,0,1,0-.77A.29.29,0,0,1,52,22a.88.88,0,0,1,.3.2A.65.65,0,0,0,52,22c-.11,0-.18.06-.21.16a.82.82,0,0,0,.11.6h0a.14.14,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.29,21.86a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19,1,1,0,0,1,.31.2.68.68,0,0,0-.32-.13.17.17,0,0,0-.2.16.82.82,0,0,0,.1.6h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.64,21a1,1,0,0,1,0-.77A.29.29,0,0,1,53,20a.83.83,0,0,1,.31.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.1.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.81,20.63a1,1,0,0,1,0-.77.3.3,0,0,1,.34-.18.81.81,0,0,1,.3.21.81.81,0,0,0-.31-.15.2.2,0,0,0-.21.17.82.82,0,0,0,.09.6h0a.14.14,0,0,1,0,.17.13.13,0,0,1-.18,0S53.81,20.64,53.81,20.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.27,22.56a1,1,0,0,1,0-.77.31.31,0,0,1,.34-.18.81.81,0,0,1,.3.21.67.67,0,0,0-.31-.14c-.11,0-.18.06-.21.16a.82.82,0,0,0,.1.6h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S53.27,22.57,53.27,22.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.78,21.68a1.05,1.05,0,0,1,0-.78.29.29,0,0,1,.34-.17.79.79,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.16.9.9,0,0,0,.09.61h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.63,19.24a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.18.81.81,0,0,1,.3.21.69.69,0,0,0-.31-.15.21.21,0,0,0-.21.16.85.85,0,0,0,.09.61h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A0,0,0,0,0,53.63,19.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.79,19.89a1,1,0,0,1,0-.77.3.3,0,0,1,.34-.18.93.93,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.82.82,0,0,0,.1.6h0A.12.12,0,0,1,53,20a.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.5,18.8a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.18.79.79,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.84.84,0,0,0,.1.6h0a.13.13,0,0,1-.22.13A0,0,0,0,0,52.5,18.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.53,19.91a1.08,1.08,0,0,1,0-.77.3.3,0,0,1,.34-.18.85.85,0,0,1,.3.19.76.76,0,0,0-.31-.13.2.2,0,0,0-.21.17.83.83,0,0,0,.11.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0A0,0,0,0,0,51.53,19.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.72,19a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.69.69,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.87.87,0,0,0,.11.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S50.72,19,50.72,19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.62,19.34a1.05,1.05,0,0,1,0-.78.29.29,0,0,1,.33-.18.71.71,0,0,1,.31.19.68.68,0,0,0-.32-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.54,18.89a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2,1,1,0,0,1,.3.19.74.74,0,0,0-.31-.12.19.19,0,0,0-.2.17.78.78,0,0,0,.12.6h0a.13.13,0,0,1-.21.15S48.55,18.89,48.54,18.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.38,18.9a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.71.71,0,0,1,.31.19.87.87,0,0,0-.32-.13.19.19,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S47.38,18.91,47.38,18.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.69,18a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.83.83,0,0,1,.3.18.74.74,0,0,0-.31-.12.18.18,0,0,0-.2.17.82.82,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S46.69,18,46.69,18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.86,19.06a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0S45.86,19.07,45.86,19.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,16.8a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.79.79,0,0,1,.31.18.66.66,0,0,0-.32-.12c-.11,0-.17.07-.19.17a.83.83,0,0,0,.13.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0A0,0,0,0,1,45,16.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.22,18.29a1,1,0,0,1,0-.77.31.31,0,0,1,.33-.2.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.18.83.83,0,0,0,.14.6h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,44.22,18.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.47,16.87a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12.19.19,0,0,0-.19.18.79.79,0,0,0,.13.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,19.92a1,1,0,0,1-.05-.77.29.29,0,0,1,.33-.2.75.75,0,0,1,.31.18.7.7,0,0,0-.32-.12.2.2,0,0,0-.2.18.8.8,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0S43,19.93,43,19.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.35,17.05a1,1,0,0,1-.05-.77.3.3,0,0,1,.33-.2.87.87,0,0,1,.31.18.76.76,0,0,0-.32-.11.19.19,0,0,0-.2.17.83.83,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42,19a1,1,0,0,1-.05-.77.28.28,0,0,1,.32-.2.69.69,0,0,1,.31.18.66.66,0,0,0-.32-.12c-.11,0-.17.08-.19.18a.8.8,0,0,0,.14.59h0a.14.14,0,0,1,0,.18A.13.13,0,0,1,42,19S42,19,42,19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.47,17.57a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.2.79.79,0,0,1,.31.18,1,1,0,0,0-.32-.12.19.19,0,0,0-.19.18.83.83,0,0,0,.14.6h0a.13.13,0,1,1-.2.16S41.47,17.58,41.47,17.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.35,20.08a1,1,0,0,1-.05-.77.28.28,0,0,1,.32-.2.61.61,0,0,1,.31.18.66.66,0,0,0-.32-.12.19.19,0,0,0-.19.18.8.8,0,0,0,.14.59h0a.13.13,0,1,1-.2.15S41.35,20.09,41.35,20.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.83,18.26a1,1,0,0,1-.06-.76.29.29,0,0,1,.32-.21.92.92,0,0,1,.32.18,1,1,0,0,0-.32-.12.2.2,0,0,0-.2.18.88.88,0,0,0,.14.6h0a.13.13,0,1,1-.2.16A0,0,0,0,1,40.83,18.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.5,21.1a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.2.79.79,0,0,1,.31.18.66.66,0,0,0-.32-.12.19.19,0,0,0-.19.18.8.8,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.22,21.72a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.2.92.92,0,0,1,.32.18.7.7,0,0,0-.32-.12.2.2,0,0,0-.2.18.8.8,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S41.23,21.73,41.22,21.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.71,19.19a1,1,0,0,1-.06-.77.29.29,0,0,1,.32-.2.72.72,0,0,1,.32.18.66.66,0,0,0-.32-.12.2.2,0,0,0-.2.18.85.85,0,0,0,.15.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.26,20.11a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.65.65,0,0,1,.31.18.66.66,0,0,0-.32-.12.2.2,0,0,0-.2.18.85.85,0,0,0,.15.59h0a.12.12,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,40.26,20.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.08,20.89a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.2.79.79,0,0,1,.31.18.66.66,0,0,0-.32-.12.19.19,0,0,0-.19.18.83.83,0,0,0,.14.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,18.11a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.92.92,0,0,1,.32.18.84.84,0,0,0-.32-.12.2.2,0,0,0-.2.18.8.8,0,0,0,.14.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S43,18.12,43,18.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.18,19.32a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2,1,1,0,0,1,.31.18.7.7,0,0,0-.32-.12.2.2,0,0,0-.2.18.81.81,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.35,17.75a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19A.69.69,0,0,1,46,17a.66.66,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.13.13,0,0,1-.21.14A0,0,0,0,1,45.35,17.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.4,16.68a.94.94,0,0,1,0-.77.29.29,0,0,1,.32-.19.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12c-.11,0-.17.07-.2.17a.88.88,0,0,0,.13.6h0a.13.13,0,0,1,0,.17.14.14,0,0,1-.18,0S46.4,16.69,46.4,16.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.75,17.09a1,1,0,0,1,0-.77.27.27,0,0,1,.33-.19,1,1,0,0,1,.31.18.84.84,0,0,0-.32-.12.19.19,0,0,0-.2.17A.82.82,0,0,0,48,17h0a.13.13,0,0,1-.21.14S47.75,17.1,47.75,17.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.88,17.38a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.81.81,0,0,1,.31.19.87.87,0,0,0-.32-.13.19.19,0,0,0-.2.17.78.78,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,48.88,17.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,17.24a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.77.77,0,0,1,.3.19.76.76,0,0,0-.31-.13.2.2,0,0,0-.21.17.88.88,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0S50,17.24,50,17.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.2,15.7a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.72.72,0,0,1,.31.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.12.12,0,0,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,16.21a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.18.77.77,0,0,1,.3.19.76.76,0,0,0-.31-.13.2.2,0,0,0-.21.17.89.89,0,0,0,.11.6h0a.14.14,0,0,1,0,.18.12.12,0,0,1-.17,0S51.05,16.22,51,16.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.13,17.26a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.18.85.85,0,0,1,.3.19.76.76,0,0,0-.31-.13.2.2,0,0,0-.21.17.84.84,0,0,0,.11.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,0,51.13,17.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,15.88a.94.94,0,0,1,0-.77.29.29,0,0,1,.33-.19.88.88,0,0,1,.3.2.65.65,0,0,0-.31-.13c-.12,0-.18.07-.21.17a.88.88,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0A0,0,0,0,1,49,15.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,15.41a1,1,0,0,1,0-.77.27.27,0,0,1,.33-.19,1,1,0,0,1,.31.18.84.84,0,0,0-.32-.12.19.19,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.82,15.26a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2,1,1,0,0,1,.31.18,1,1,0,0,0-.32-.12.2.2,0,0,0-.2.18.79.79,0,0,0,.13.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.18,15.21a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.2.92.92,0,0,1,.32.18,1,1,0,0,0-.32-.12.2.2,0,0,0-.2.18.83.83,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.89,15.73a1,1,0,0,1-.06-.76.3.3,0,0,1,.33-.21.87.87,0,0,1,.31.18,1,1,0,0,0-.32-.12A.2.2,0,0,0,40,15a.89.89,0,0,0,.15.6h0a.13.13,0,1,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.34,16.85a1,1,0,0,1-.06-.77.29.29,0,0,1,.32-.2.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.76.76,0,0,0,.15.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.15,15.27a1,1,0,0,1-.07-.77.29.29,0,0,1,.32-.21.76.76,0,0,1,.32.17.82.82,0,0,0-.32-.11.2.2,0,0,0-.2.18.88.88,0,0,0,.15.6h0a.13.13,0,1,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.68,16.05a1,1,0,0,1-.08-.77.27.27,0,0,1,.31-.21.71.71,0,0,1,.32.17.65.65,0,0,0-.32-.11c-.11,0-.17.08-.19.19a.83.83,0,0,0,.16.58h0a.13.13,0,1,1-.2.16S34.68,16.06,34.68,16.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.53,18.15a1,1,0,0,1-.09-.77.28.28,0,0,1,.32-.21.74.74,0,0,1,.32.16.84.84,0,0,0-.33-.1.19.19,0,0,0-.19.18.84.84,0,0,0,.17.59h0a.13.13,0,1,1-.2.16S33.53,18.16,33.53,18.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,16.36a1,1,0,0,1-.09-.76.28.28,0,0,1,.31-.22.69.69,0,0,1,.32.16.75.75,0,0,0-.32-.1.19.19,0,0,0-.19.19.83.83,0,0,0,.17.58h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S33,16.37,33,16.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.7,15.53a1,1,0,0,1-.08-.76.28.28,0,0,1,.31-.22.88.88,0,0,1,.32.16.79.79,0,0,0-.32-.1.19.19,0,0,0-.19.19.81.81,0,0,0,.17.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.18,0S33.71,15.54,33.7,15.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.74,17a1,1,0,0,1-.09-.77A.3.3,0,0,1,34,16a.83.83,0,0,1,.32.17.7.7,0,0,0-.33-.1.19.19,0,0,0-.19.18.86.86,0,0,0,.17.59h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0A0,0,0,0,0,33.74,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.69,17.56a1,1,0,0,1-.09-.77.28.28,0,0,1,.31-.22.88.88,0,0,1,.32.16.84.84,0,0,0-.33-.1.19.19,0,0,0-.18.19.8.8,0,0,0,.17.59h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.69,18.92a1,1,0,0,1-.09-.77.3.3,0,0,1,.31-.22.8.8,0,0,1,.33.17.7.7,0,0,0-.33-.1.19.19,0,0,0-.19.18.9.9,0,0,0,.17.59h0a.12.12,0,0,1-.19.16S32.7,18.92,32.69,18.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.64,19.32a1,1,0,0,1-.08-.77.28.28,0,0,1,.31-.22.9.9,0,0,1,.32.17.7.7,0,0,0-.33-.1.18.18,0,0,0-.18.18.79.79,0,0,0,.16.59h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,33.64,19.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.18,20.22a1,1,0,0,1-.08-.76.28.28,0,0,1,.31-.22.76.76,0,0,1,.32.17.82.82,0,0,0-.32-.11.19.19,0,0,0-.19.19.85.85,0,0,0,.16.59h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S33.18,20.23,33.18,20.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.61,18.27a1,1,0,0,1-.08-.77.3.3,0,0,1,.32-.21.76.76,0,0,1,.32.17.86.86,0,0,0-.33-.11c-.11,0-.17.09-.19.19a.85.85,0,0,0,.16.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0A0,0,0,0,1,34.61,18.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.59,18.89a1,1,0,0,1-.07-.77.29.29,0,0,1,.31-.21.82.82,0,0,1,.32.17.76.76,0,0,0-.32-.11.2.2,0,0,0-.19.19.85.85,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.68,17.81a1,1,0,0,1-.07-.76.28.28,0,0,1,.32-.21.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.81.81,0,0,0,.16.59h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S35.68,17.82,35.68,17.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.09,14.64a1,1,0,0,1-.07-.77.3.3,0,0,1,.32-.21,1.09,1.09,0,0,1,.32.17.86.86,0,0,0-.33-.11c-.11,0-.17.09-.19.19a.81.81,0,0,0,.16.59h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.76,15.67a1,1,0,0,1-.07-.77.29.29,0,0,1,.32-.21.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11c-.11,0-.17.09-.19.19a.82.82,0,0,0,.16.59h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.42,20.34a1,1,0,0,1-.07-.77.3.3,0,0,1,.32-.21.92.92,0,0,1,.31.17.76.76,0,0,0-.32-.11c-.11,0-.17.09-.19.19a.81.81,0,0,0,.16.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0A0,0,0,0,1,35.42,20.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.28,19.67a1,1,0,0,1-.06-.76.27.27,0,0,1,.31-.21.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.8.8,0,0,0,.15.59h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.93,14.94a1,1,0,0,1-.08-.77.27.27,0,0,1,.31-.21.8.8,0,0,1,.32.16.75.75,0,0,0-.32-.1.19.19,0,0,0-.19.18.84.84,0,0,0,.16.59h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,34.93,14.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.8,17.13a1,1,0,0,1-.07-.77.27.27,0,0,1,.31-.21.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.19A.78.78,0,0,0,35,17h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.7,17.48a1,1,0,0,1-.06-.77A.3.3,0,0,1,37,16.5a.87.87,0,0,1,.31.18.65.65,0,0,0-.32-.11.18.18,0,0,0-.19.18.8.8,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.33,20.54a1,1,0,0,1-.08-.76.3.3,0,0,1,.32-.22,1.09,1.09,0,0,1,.32.17.84.84,0,0,0-.33-.1.19.19,0,0,0-.19.18.85.85,0,0,0,.16.59h0a.13.13,0,1,1-.2.16S34.33,20.55,34.33,20.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.69,18.58a1,1,0,0,1-.06-.76.28.28,0,0,1,.32-.21.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.85.85,0,0,0,.15.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.84,16.69a1,1,0,0,1-.07-.77.28.28,0,0,1,.32-.21.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.83.83,0,0,0,.16.6h0a.12.12,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,35.84,16.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.71,19.48a1,1,0,0,1-.08-.77A.28.28,0,0,1,35,18.5a.76.76,0,0,1,.32.17.72.72,0,0,0-.33-.11.19.19,0,0,0-.19.18.84.84,0,0,0,.16.59h0a.13.13,0,1,1-.2.16A0,0,0,0,0,34.71,19.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.61,13.77a1,1,0,0,1-.07-.77.3.3,0,0,1,.32-.21.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11c-.11,0-.17.09-.19.19a.82.82,0,0,0,.16.59h0a.12.12,0,0,1,0,.18.11.11,0,0,1-.17,0S35.62,13.78,35.61,13.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,16.23a1,1,0,0,1-.07-.76.29.29,0,0,1,.32-.21.76.76,0,0,1,.32.17.82.82,0,0,0-.32-.11.2.2,0,0,0-.2.18.81.81,0,0,0,.16.59h0a.13.13,0,1,1-.2.16S37,16.24,37,16.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,14.19a1,1,0,0,1-.06-.77.28.28,0,0,1,.32-.2.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.8.8,0,0,0,.14.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S38,14.2,38,14.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.35,13.42a1,1,0,0,1-.05-.77.29.29,0,0,1,.32-.2.79.79,0,0,1,.31.18,1,1,0,0,0-.32-.12.19.19,0,0,0-.19.18.83.83,0,0,0,.14.6h0a.13.13,0,0,1-.2.16A0,0,0,0,1,40.35,13.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.72,12.74a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.2.71.71,0,0,1,.31.19.66.66,0,0,0-.32-.12c-.11,0-.17.07-.19.17a.83.83,0,0,0,.13.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.6,12.15a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.2,1,1,0,0,1,.31.19.7.7,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.92,12.3a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.2.81.81,0,0,1,.31.19.8.8,0,0,0-.32-.13c-.11,0-.17.08-.2.18a.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,12.44a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.2.17.78.78,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0A0,0,0,0,0,49,12.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.32,12.34a1,1,0,0,1,0-.77.3.3,0,0,1,.34-.19.88.88,0,0,1,.3.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.49,12.88a1,1,0,0,1,0-.77.31.31,0,0,1,.34-.18.81.81,0,0,1,.3.21.67.67,0,0,0-.31-.14c-.11,0-.18.06-.21.16a.82.82,0,0,0,.1.6h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.48,12.39a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.18.78.78,0,0,1,.29.21,1,1,0,0,0-.31-.15.21.21,0,0,0-.21.16.85.85,0,0,0,.09.61h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0S52.49,12.39,52.48,12.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.14,13.36a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.93.93,0,0,1,.29.22.61.61,0,0,0-.3-.15.2.2,0,0,0-.22.15.9.9,0,0,0,.08.61h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A0,0,0,0,1,53.14,13.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.37,12.84a1,1,0,0,1,.06-.77.29.29,0,0,1,.34-.16.74.74,0,0,1,.29.23.83.83,0,0,0-.3-.17.2.2,0,0,0-.22.15.85.85,0,0,0,.05.61h0a.13.13,0,1,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.4,13.45a1.06,1.06,0,0,1,.08-.77.3.3,0,0,1,.36-.15.7.7,0,0,1,.27.24.74.74,0,0,0-.29-.18.19.19,0,0,0-.22.15.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.23.11S55.41,13.45,55.4,13.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.78,13.6a1,1,0,0,1,.11-.76.29.29,0,0,1,.36-.13.78.78,0,0,1,.27.24.74.74,0,0,0-.29-.18.19.19,0,0,0-.23.13.8.8,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,13.65a1,1,0,0,1,.16-.76.29.29,0,0,1,.36-.11.81.81,0,0,1,.26.26.76.76,0,0,0-.28-.2.2.2,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.08.16.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.45,13.78a1,1,0,0,1,.2-.75A.3.3,0,0,1,60,13a.78.78,0,0,1,.24.27A.71.71,0,0,0,60,13a.19.19,0,0,0-.24.11.82.82,0,0,0-.06.61h0a.13.13,0,1,1-.24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,13.83a1,1,0,0,1,.27-.72.28.28,0,0,1,.37-.06.86.86,0,0,1,.22.29.68.68,0,0,0-.25-.23.19.19,0,0,0-.25.08.86.86,0,0,0-.11.6h0a.12.12,0,0,1-.24.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.29,14.15a1,1,0,0,1,.31-.7.29.29,0,0,1,.38,0,.8.8,0,0,1,.19.3.69.69,0,0,0-.23-.25.18.18,0,0,0-.25.07.8.8,0,0,0-.15.59h0a.12.12,0,0,1-.11.14.12.12,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.68,13.83a1,1,0,0,1,.37-.67.28.28,0,0,1,.38,0,.83.83,0,0,1,.17.32.82.82,0,0,0-.21-.27.19.19,0,0,0-.26,0,.86.86,0,0,0-.2.58h0a.12.12,0,0,1-.12.13.11.11,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.33,13.59a.94.94,0,0,1,.44-.63.27.27,0,0,1,.37,0,.73.73,0,0,1,.14.33A.79.79,0,0,0,66.1,13a.19.19,0,0,0-.26,0,.79.79,0,0,0-.26.55h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.73,13.53a1,1,0,0,1,.49-.59.28.28,0,0,1,.37.07.76.76,0,0,1,.11.35.83.83,0,0,0-.16-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,0,1-.25,0A0,0,0,0,1,66.73,13.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.42,13.77a1,1,0,0,1,.55-.54.31.31,0,0,1,.37.11.76.76,0,0,1,.06.36.65.65,0,0,0-.12-.32.19.19,0,0,0-.26,0,.8.8,0,0,0-.36.49h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.46,15.27a1,1,0,0,1,.57-.52.3.3,0,0,1,.36.12.9.9,0,0,1,0,.36.72.72,0,0,0-.11-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.38.48h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.9,13.17a1,1,0,0,1,.59-.5.29.29,0,0,1,.35.14.64.64,0,0,1,0,.36.74.74,0,0,0-.09-.33.2.2,0,0,0-.26-.05.84.84,0,0,0-.4.47h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.82,14.16a1,1,0,0,1,.6-.48.29.29,0,0,1,.35.14.74.74,0,0,1,0,.36.6.6,0,0,0-.09-.33.19.19,0,0,0-.26,0,.82.82,0,0,0-.4.46h0a.13.13,0,0,1-.16.09.14.14,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.7,14.09a1,1,0,0,1,.58-.51.3.3,0,0,1,.36.14.84.84,0,0,1,0,.35.6.6,0,0,0-.1-.32.2.2,0,0,0-.26-.05.83.83,0,0,0-.38.47h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.37,14.77a1,1,0,0,1,.51-.58.28.28,0,0,1,.37.08.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.19.19,0,0,0-.26,0,.79.79,0,0,0-.32.52h0a.13.13,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.63,15.3a1,1,0,0,1,.44-.64.3.3,0,0,1,.38,0,.83.83,0,0,1,.13.34.62.62,0,0,0-.18-.29.18.18,0,0,0-.26,0,.86.86,0,0,0-.26.55h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.21,15.55a1,1,0,0,1,.37-.68.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.82.82,0,0,0-.21-.27.19.19,0,0,0-.26,0,.84.84,0,0,0-.2.58h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,17a1,1,0,0,1,.31-.71.28.28,0,0,1,.37,0,.66.66,0,0,1,.2.3.62.62,0,0,0-.23-.25.2.2,0,0,0-.26.07.84.84,0,0,0-.14.59h0a.12.12,0,0,1-.1.15.12.12,0,0,1-.14-.1A0,0,0,0,1,63,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.56,19.37a1,1,0,0,1,.25-.73.29.29,0,0,1,.38-.06.85.85,0,0,1,.22.28.9.9,0,0,0-.25-.23.19.19,0,0,0-.25.09.81.81,0,0,0-.1.6h0a.13.13,0,0,1-.09.16.13.13,0,0,1-.15-.09A0,0,0,0,0,62.56,19.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.28,21.63a1,1,0,0,1,.21-.75.3.3,0,0,1,.37-.08,1,1,0,0,1,.24.27.79.79,0,0,0-.27-.21.19.19,0,0,0-.24.1.87.87,0,0,0-.07.61h0a.12.12,0,0,1-.09.15.11.11,0,0,1-.15-.08A0,0,0,0,1,62.28,21.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.26,24.52a1,1,0,0,1,.15-.76.28.28,0,0,1,.36-.11.69.69,0,0,1,.26.25.69.69,0,0,0-.28-.2.19.19,0,0,0-.23.13.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.06.16.12.12,0,0,1-.17-.07S61.27,24.52,61.26,24.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.84,25.94a1,1,0,0,1,.12-.76.29.29,0,0,1,.36-.13.93.93,0,0,1,.27.25.77.77,0,0,0-.29-.19.19.19,0,0,0-.23.13.85.85,0,0,0,0,.61h0A.15.15,0,0,1,61,26a.14.14,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.92,26.81a1,1,0,0,1,.15-.76.29.29,0,0,1,.36-.11.72.72,0,0,1,.26.26.86.86,0,0,0-.28-.2.2.2,0,0,0-.24.12.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.65,29.67a1,1,0,0,1,.19-.75.27.27,0,0,1,.36-.09.77.77,0,0,1,.25.26.9.9,0,0,0-.27-.21.19.19,0,0,0-.24.11.82.82,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.12.12,0,0,1-.16-.08S63.65,29.68,63.65,29.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.65,31.2a1,1,0,0,1,.22-.74.28.28,0,0,1,.37-.08.77.77,0,0,1,.23.28.68.68,0,0,0-.26-.22.18.18,0,0,0-.24.1.8.8,0,0,0-.08.6h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.28,32.26a1,1,0,0,1,.32-.7.29.29,0,0,1,.38,0,.77.77,0,0,1,.19.31.71.71,0,0,0-.22-.26.19.19,0,0,0-.26.06.81.81,0,0,0-.16.59h0a.12.12,0,0,1-.11.14.12.12,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.37,33.62a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.77.77,0,0,0-.19-.28.19.19,0,0,0-.26,0,.82.82,0,0,0-.23.57h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.72,34.41a1,1,0,0,1,.47-.61.29.29,0,0,1,.38.06.89.89,0,0,1,.11.35.83.83,0,0,0-.16-.31.21.21,0,0,0-.27,0,.83.83,0,0,0-.29.53h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.57,35.37a1,1,0,0,1,.48-.6.29.29,0,0,1,.38.06.89.89,0,0,1,.11.35.83.83,0,0,0-.16-.31.21.21,0,0,0-.27,0,.83.83,0,0,0-.29.54h0a.12.12,0,0,1-.14.11.14.14,0,0,1-.12-.14S68.57,35.38,68.57,35.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.42,33a1,1,0,0,1,.44-.64.29.29,0,0,1,.38,0,.84.84,0,0,1,.13.33.79.79,0,0,0-.18-.29.19.19,0,0,0-.26,0,.77.77,0,0,0-.26.55h0a.13.13,0,0,1-.13.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.45,30.67a1,1,0,0,1,.31-.7.29.29,0,0,1,.38,0,.87.87,0,0,1,.2.31.8.8,0,0,0-.23-.25.19.19,0,0,0-.26.06.89.89,0,0,0-.15.6h0a.12.12,0,0,1-.1.14.12.12,0,0,1-.14-.1A0,0,0,0,1,66.45,30.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.19,27.18a1,1,0,0,1,.23-.74.28.28,0,0,1,.37-.07.77.77,0,0,1,.23.28.8.8,0,0,0-.26-.23.2.2,0,0,0-.25.1.87.87,0,0,0-.07.61h0a.12.12,0,0,1-.09.15.12.12,0,0,1-.15-.08S64.19,27.19,64.19,27.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.39,25.26a1,1,0,0,1,.18-.75.29.29,0,0,1,.37-.1.85.85,0,0,1,.24.26.73.73,0,0,0-.27-.2.19.19,0,0,0-.24.11.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.68,22.87a.94.94,0,0,1,.17-.75.3.3,0,0,1,.37-.1.74.74,0,0,1,.24.26.66.66,0,0,0-.27-.2.19.19,0,0,0-.24.11.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,20.35a1,1,0,0,1,.18-.75.3.3,0,0,1,.37-.1.77.77,0,0,1,.24.27.68.68,0,0,0-.27-.21.19.19,0,0,0-.24.11.86.86,0,0,0,0,.61h0a.12.12,0,0,1-.23.09A0,0,0,0,1,61,20.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.89,17.8a1,1,0,0,1,.21-.75.29.29,0,0,1,.37-.08.67.67,0,0,1,.23.27.77.77,0,0,0-.26-.22.19.19,0,0,0-.24.11.79.79,0,0,0-.06.6h0a.13.13,0,0,1-.08.16.12.12,0,0,1-.16-.08A0,0,0,0,0,60.89,17.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.94,17.3a1,1,0,0,1,.17-.76.3.3,0,0,1,.37-.09.69.69,0,0,1,.25.26.9.9,0,0,0-.27-.21.2.2,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.13.13,0,0,1-.07.17.14.14,0,0,1-.17-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.58,18.83a1,1,0,0,1,.18-.75.29.29,0,0,1,.37-.1.78.78,0,0,1,.24.27A.66.66,0,0,0,61.1,18a.19.19,0,0,0-.24.11.86.86,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.14.14,0,0,1-.17-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.26,21.78A1,1,0,0,1,60.4,21a.31.31,0,0,1,.37-.12,1,1,0,0,1,.26.25.77.77,0,0,0-.29-.19.19.19,0,0,0-.23.12.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.07S60.27,21.78,60.26,21.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.58,27.44a1,1,0,0,1,.08-.77.29.29,0,0,1,.36-.14.75.75,0,0,1,.27.23.72.72,0,0,0-.29-.17.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.05.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.31,34a1,1,0,0,1,.14-.75.28.28,0,0,1,.36-.12.67.67,0,0,1,.26.24,1,1,0,0,0-.28-.19.21.21,0,0,0-.24.13.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.14.14,0,0,1-.17-.07A0,0,0,0,0,61.31,34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.83,39.08a1,1,0,0,1,.29-.72.3.3,0,0,1,.38,0,.93.93,0,0,1,.2.3.78.78,0,0,0-.24-.24.18.18,0,0,0-.25.07.87.87,0,0,0-.14.6h0a.12.12,0,0,1-.24,0S61.82,39.08,61.83,39.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.46,40.87a1,1,0,0,1,.37-.68.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.82.82,0,0,0-.21-.27.19.19,0,0,0-.26,0,.84.84,0,0,0-.2.58h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,41.86a1,1,0,0,1,.42-.65.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33.84.84,0,0,0-.18-.29.2.2,0,0,0-.27,0,.83.83,0,0,0-.24.56h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,41.93a1,1,0,0,1,.45-.62.3.3,0,0,1,.38,0,.73.73,0,0,1,.12.34.69.69,0,0,0-.17-.3.2.2,0,0,0-.26,0,.86.86,0,0,0-.28.55h0A.13.13,0,1,1,64,42,0,0,0,0,1,64,41.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.66,39.89a1,1,0,0,1,.39-.66.28.28,0,0,1,.38,0,.84.84,0,0,1,.16.33.75.75,0,0,0-.2-.28.19.19,0,0,0-.26,0,.81.81,0,0,0-.22.57h0a.14.14,0,0,1-.12.14.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.55,33.18a1,1,0,0,1,.16-.75.29.29,0,0,1,.36-.11.81.81,0,0,1,.26.26.76.76,0,0,0-.28-.2.2.2,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.11.11,0,0,1-.16-.07A0,0,0,0,1,62.55,33.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,24.75a1,1,0,0,1,.07-.77.29.29,0,0,1,.35-.15.76.76,0,0,1,.28.22.73.73,0,0,0-.3-.16.19.19,0,0,0-.22.15.78.78,0,0,0,.05.6h0a.13.13,0,1,1-.22.12S58,24.76,58,24.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.64,18.58a1,1,0,0,1,.07-.77.29.29,0,0,1,.35-.15.91.91,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.11,0-.18.05-.22.15a.85.85,0,0,0,0,.61h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.17-.06S56.64,18.59,56.64,18.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.92,15.7a1,1,0,0,1,0-.77.29.29,0,0,1,.35-.15.67.67,0,0,1,.28.22.63.63,0,0,0-.3-.16c-.11,0-.18.05-.22.15a.85.85,0,0,0,.06.61h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0A0,0,0,0,1,54.92,15.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.87,16a1,1,0,0,1,.07-.77A.3.3,0,0,1,56.3,15a.79.79,0,0,1,.28.23.83.83,0,0,0-.3-.17.19.19,0,0,0-.22.15.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.46,17a.94.94,0,0,1,.06-.77.27.27,0,0,1,.34-.15.72.72,0,0,1,.29.22.8.8,0,0,0-.3-.16.19.19,0,0,0-.22.15.82.82,0,0,0,0,.6h0a.13.13,0,0,1,0,.17.11.11,0,0,1-.17-.05S55.46,17,55.46,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.15,20a1,1,0,0,1,.08-.77.29.29,0,0,1,.35-.15.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.11,0-.18.05-.22.15a.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.05S57.16,20,57.15,20Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.76,25.26a1.07,1.07,0,0,1,.08-.77.3.3,0,0,1,.35-.14.71.71,0,0,1,.28.23.79.79,0,0,0-.29-.17.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06A0,0,0,0,0,58.76,25.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.53,34.3a1,1,0,0,1,.22-.74.29.29,0,0,1,.38-.07.85.85,0,0,1,.23.27.9.9,0,0,0-.26-.22.2.2,0,0,0-.25.1.85.85,0,0,0-.08.6h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.85,39a1,1,0,0,1,.49-.59.29.29,0,0,1,.37.07.76.76,0,0,1,.11.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.3.53h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.3,43.25a1,1,0,0,1,.6-.49.29.29,0,0,1,.35.15.89.89,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.84.84,0,0,0-.41.46h0a.13.13,0,1,1-.24-.08A0,0,0,0,1,68.3,43.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,47.73a1,1,0,0,1,.64-.42.3.3,0,0,1,.34.18.72.72,0,0,1,0,.36.72.72,0,0,0,0-.33.2.2,0,0,0-.25-.09.81.81,0,0,0-.45.42h0a.11.11,0,0,1-.16.07.14.14,0,0,1-.07-.17S70,47.74,70,47.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.88,49.32a1,1,0,0,1,.65-.41.29.29,0,0,1,.33.19.72.72,0,0,1,0,.36.76.76,0,0,0,0-.34.19.19,0,0,0-.25-.08.83.83,0,0,0-.46.4h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.17A0,0,0,0,1,71.88,49.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.18,50.72a1,1,0,0,1,.66-.4.28.28,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.87.87,0,0,0-.46.4h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,49.89a1,1,0,0,1,.65-.4.28.28,0,0,1,.33.19A.73.73,0,0,1,74,50a.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.81.81,0,0,0-.46.41h0a.12.12,0,0,1-.22-.1A0,0,0,0,1,73,49.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.31,47.29a1,1,0,0,1,.65-.41.29.29,0,0,1,.33.18.72.72,0,0,1,0,.36.72.72,0,0,0,0-.33A.2.2,0,0,0,74,47a.79.79,0,0,0-.45.41h0a.12.12,0,0,1-.16.06.13.13,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,43a1,1,0,0,1,.63-.44.28.28,0,0,1,.34.17.87.87,0,0,1,0,.36.77.77,0,0,0-.07-.34.19.19,0,0,0-.25-.07.84.84,0,0,0-.44.43h0a.13.13,0,0,1-.16.07A.12.12,0,0,1,74,43S74,43,74,43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.31,35.66a1,1,0,0,1,.58-.51.29.29,0,0,1,.36.13.89.89,0,0,1,0,.36.61.61,0,0,0-.1-.33.19.19,0,0,0-.26,0,.8.8,0,0,0-.38.47h0a.11.11,0,0,1-.15.09.13.13,0,0,1-.09-.16S75.31,35.67,75.31,35.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.75,26.44a1,1,0,0,1,.54-.56.28.28,0,0,1,.36.11.72.72,0,0,1,.08.35A.9.9,0,0,0,76.6,26a.2.2,0,0,0-.26,0,.83.83,0,0,0-.35.5h0a.13.13,0,0,1-.25-.05A0,0,0,0,1,75.75,26.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.12,22.12a1,1,0,0,1,.58-.51.28.28,0,0,1,.35.13.75.75,0,0,1,0,.36.71.71,0,0,0-.1-.33.19.19,0,0,0-.26,0,.77.77,0,0,0-.38.47h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16S76.12,22.13,76.12,22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.88,18.79a1,1,0,0,1,.61-.48.29.29,0,0,1,.35.16.73.73,0,0,1,0,.36.77.77,0,0,0-.08-.34.2.2,0,0,0-.26-.06.86.86,0,0,0-.41.45h0A.13.13,0,0,1,76,19a.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.51,17.39a1.07,1.07,0,0,1,.63-.46.29.29,0,0,1,.34.17.73.73,0,0,1,0,.36.77.77,0,0,0-.08-.34.2.2,0,0,0-.25-.07.88.88,0,0,0-.43.44h0a.12.12,0,0,1-.16.07.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.45,17.78a1,1,0,0,1,.62-.46.28.28,0,0,1,.34.16.74.74,0,0,1,0,.36.88.88,0,0,0-.07-.33.19.19,0,0,0-.26-.07.82.82,0,0,0-.42.44h0a.12.12,0,0,1-.16.07.11.11,0,0,1-.07-.16S77.44,17.78,77.45,17.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77,18.92a1,1,0,0,1,.61-.47.3.3,0,0,1,.35.15A.89.89,0,0,1,78,19a.76.76,0,0,0-.08-.33.2.2,0,0,0-.26-.06.82.82,0,0,0-.41.45h0a.12.12,0,0,1-.23-.09S77,18.93,77,18.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.6,20.4a1,1,0,0,1,.59-.49.29.29,0,0,1,.36.14.74.74,0,0,1,0,.36.7.7,0,0,0-.09-.33.2.2,0,0,0-.26-.06.88.88,0,0,0-.4.47h0a.13.13,0,0,1-.24-.08A0,0,0,0,1,75.6,20.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.24,23.79a1,1,0,0,1,.56-.54.29.29,0,0,1,.36.12.7.7,0,0,1,.06.35.72.72,0,0,0-.11-.32.2.2,0,0,0-.27,0,.84.84,0,0,0-.36.49h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15A0,0,0,0,0,74.24,23.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.78,27.18a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.88.88,0,0,1,.1.35.75.75,0,0,0-.15-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,0,1-.14.11.15.15,0,0,1-.11-.14A0,0,0,0,0,70.78,27.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.71,29.6a1,1,0,0,1,.46-.62.29.29,0,0,1,.38.06.87.87,0,0,1,.12.34.81.81,0,0,0-.17-.3.2.2,0,0,0-.26,0,.82.82,0,0,0-.28.54h0a.13.13,0,0,1-.26,0S69.71,29.61,69.71,29.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.93,30.3a1,1,0,0,1,.43-.63.28.28,0,0,1,.38,0,.73.73,0,0,1,.14.33.84.84,0,0,0-.18-.29.2.2,0,0,0-.27,0,.82.82,0,0,0-.25.55h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.1,28.57a1,1,0,0,1,.44-.64.29.29,0,0,1,.37,0,.73.73,0,0,1,.14.33.68.68,0,0,0-.18-.28.18.18,0,0,0-.26,0,.81.81,0,0,0-.26.55h0a.13.13,0,0,1-.12.13.14.14,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.4,25.7a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.08.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.19.19,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.56,22.26a1,1,0,0,1,.54-.54.3.3,0,0,1,.37.11.87.87,0,0,1,.07.35.75.75,0,0,0-.13-.32.2.2,0,0,0-.26,0,.83.83,0,0,0-.35.5h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15A0,0,0,0,1,70.56,22.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.86,18.66a1,1,0,0,1,.6-.49.29.29,0,0,1,.35.15.86.86,0,0,1,0,.35.85.85,0,0,0-.09-.32.19.19,0,0,0-.26-.06.81.81,0,0,0-.4.46h0a.14.14,0,0,1-.16.09.13.13,0,0,1-.09-.16A0,0,0,0,0,72.86,18.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.54,15a1,1,0,0,1,.62-.46.29.29,0,0,1,.34.17.73.73,0,0,1,0,.36.77.77,0,0,0-.07-.34.2.2,0,0,0-.26-.06.79.79,0,0,0-.42.44h0a.14.14,0,0,1-.17.07.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.5,13.69a1,1,0,0,1,.62-.46.3.3,0,0,1,.35.17.87.87,0,0,1,0,.36.65.65,0,0,0-.07-.34.2.2,0,0,0-.25-.07.84.84,0,0,0-.43.44h0a.14.14,0,0,1-.17.08.14.14,0,0,1-.07-.17S73.5,13.7,73.5,13.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.39,14.37a1,1,0,0,1,.62-.44.28.28,0,0,1,.34.16.75.75,0,0,1,0,.36.88.88,0,0,0-.07-.33.19.19,0,0,0-.26-.07.82.82,0,0,0-.43.43h0a.13.13,0,0,1-.17.07.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.07,15.67a1,1,0,0,1,.6-.48.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.06.82.82,0,0,0-.41.45h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16S72.06,15.67,72.07,15.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.5,17.5a1,1,0,0,1,.52-.56.29.29,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.18.18,0,0,0-.26,0,.8.8,0,0,0-.34.51h0a.11.11,0,0,1-.14.1.12.12,0,0,1-.11-.14S68.5,17.51,68.5,17.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.61,19.15a1,1,0,0,1,.44-.64.29.29,0,0,1,.37,0,.73.73,0,0,1,.14.34.71.71,0,0,0-.18-.29.18.18,0,0,0-.26,0,.81.81,0,0,0-.26.55h0a.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.94,20.21a1,1,0,0,1,.39-.66.28.28,0,0,1,.38,0,.81.81,0,0,1,.16.32.8.8,0,0,0-.2-.28.2.2,0,0,0-.26,0,.86.86,0,0,0-.22.58h0a.13.13,0,0,1-.12.13.14.14,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,20.34a1,1,0,0,1,.44-.64.29.29,0,0,1,.38,0,.84.84,0,0,1,.13.33.79.79,0,0,0-.18-.29.2.2,0,0,0-.26,0,.91.91,0,0,0-.26.56h0a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.68,19.43a1,1,0,0,1,.51-.57.28.28,0,0,1,.37.08.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.52h0a.13.13,0,0,1-.25,0S68.68,19.44,68.68,19.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,17.47a1,1,0,0,1,.59-.5.29.29,0,0,1,.35.14.64.64,0,0,1,0,.36.7.7,0,0,0-.09-.33.2.2,0,0,0-.26-.05.84.84,0,0,0-.4.47h0a.12.12,0,0,1-.15.08A.12.12,0,0,1,71,17.5S71,17.48,71,17.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.85,15.94a1,1,0,0,1,.62-.46.3.3,0,0,1,.35.17.87.87,0,0,1,0,.36.77.77,0,0,0-.07-.34.2.2,0,0,0-.25-.07.84.84,0,0,0-.43.44h0a.13.13,0,0,1-.17.07.12.12,0,0,1-.07-.16A0,0,0,0,1,74.85,15.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.65,14.82a1,1,0,0,1,.63-.44.29.29,0,0,1,.34.17.87.87,0,0,1,0,.36.77.77,0,0,0-.07-.34.19.19,0,0,0-.25-.07.84.84,0,0,0-.44.43h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.48,16.28a1,1,0,0,1,.61-.47.28.28,0,0,1,.35.16.73.73,0,0,1,0,.36.94.94,0,0,0-.07-.34.2.2,0,0,0-.26-.06.8.8,0,0,0-.42.45h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.08-.16A0,0,0,0,1,73.48,16.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.12,18.21a1,1,0,0,1,.57-.52.29.29,0,0,1,.36.12.75.75,0,0,1,0,.36.85.85,0,0,0-.1-.33.2.2,0,0,0-.27,0,.82.82,0,0,0-.37.48h0a.13.13,0,0,1-.16.09.12.12,0,0,1-.09-.15A0,0,0,0,1,70.12,18.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,22.47a1,1,0,0,1,.45-.63.29.29,0,0,1,.38.05.83.83,0,0,1,.13.34.75.75,0,0,0-.18-.3.19.19,0,0,0-.26,0,.81.81,0,0,0-.27.54h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.2,24.79a1,1,0,0,1,.37-.67.29.29,0,0,1,.38,0,.75.75,0,0,1,.17.33.67.67,0,0,0-.21-.28.2.2,0,0,0-.26,0,.81.81,0,0,0-.2.58h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.6,26A1,1,0,0,1,68,25.3a.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32.79.79,0,0,0-.2-.27.18.18,0,0,0-.26,0,.74.74,0,0,0-.21.57h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.81,24.82a1,1,0,0,1,.46-.62.3.3,0,0,1,.38.05.78.78,0,0,1,.12.34.7.7,0,0,0-.17-.29.19.19,0,0,0-.26,0,.84.84,0,0,0-.28.55h0a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.23,23.2a1,1,0,0,1,.55-.54.28.28,0,0,1,.36.11.92.92,0,0,1,.07.36.76.76,0,0,0-.12-.32.19.19,0,0,0-.26,0,.85.85,0,0,0-.36.5h0a.11.11,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.88,21.73a1,1,0,0,1,.57-.51.29.29,0,0,1,.36.13.71.71,0,0,1,0,.35.71.71,0,0,0-.1-.32.2.2,0,0,0-.26-.05.81.81,0,0,0-.38.48h0a.11.11,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.86,22.84a1.05,1.05,0,0,1,.57-.53.3.3,0,0,1,.36.12.91.91,0,0,1,.06.36.82.82,0,0,0-.12-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.48h0A.14.14,0,0,1,74,23a.14.14,0,0,1-.1-.16S73.86,22.85,73.86,22.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,26.85a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.18.18,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.22,35.23a1,1,0,0,1,.54-.55.28.28,0,0,1,.36.1.74.74,0,0,1,.08.35.66.66,0,0,0-.13-.31.19.19,0,0,0-.26,0,.9.9,0,0,0-.35.51h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,0,70.22,35.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.23,41.79a1,1,0,0,1,.6-.49.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.7.7,0,0,0-.08-.33.21.21,0,0,0-.26-.06.87.87,0,0,0-.41.46h0a.13.13,0,1,1-.24-.08S69.23,41.8,69.23,41.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.69,45a1,1,0,0,1,.62-.45.3.3,0,0,1,.35.17.87.87,0,0,1,0,.36.7.7,0,0,0-.07-.33.21.21,0,0,0-.26-.08.88.88,0,0,0-.43.44h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.16A0,0,0,0,1,68.69,45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.46,47a1,1,0,0,1,.64-.44.32.32,0,0,1,.34.18.73.73,0,0,1,0,.36.72.72,0,0,0-.06-.33.19.19,0,0,0-.25-.08.88.88,0,0,0-.45.42h0a.12.12,0,0,1-.16.06.11.11,0,0,1-.07-.16A0,0,0,0,1,68.46,47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,47.89a1,1,0,0,1,.64-.43.29.29,0,0,1,.34.18.87.87,0,0,1,0,.36.93.93,0,0,0-.06-.34.21.21,0,0,0-.25-.08.92.92,0,0,0-.45.42h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.25,45.89a1,1,0,0,1,.64-.44.29.29,0,0,1,.34.18.87.87,0,0,1,0,.36.76.76,0,0,0-.06-.34.19.19,0,0,0-.25-.07.79.79,0,0,0-.44.42h0a.14.14,0,0,1-.16.07.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.51,40.86a1,1,0,0,1,.61-.48.29.29,0,0,1,.34.16.74.74,0,0,1,0,.36.7.7,0,0,0-.08-.33.2.2,0,0,0-.26-.07.85.85,0,0,0-.41.45h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.07-.16S70.5,40.87,70.51,40.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.49,36.29a1,1,0,0,1,.57-.52.29.29,0,0,1,.36.12.75.75,0,0,1,0,.36.72.72,0,0,0-.11-.33.2.2,0,0,0-.26,0,.82.82,0,0,0-.37.49h0a.13.13,0,1,1-.25-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.47,34.49A1,1,0,0,1,74,34a.28.28,0,0,1,.36.12.66.66,0,0,1,.06.36.72.72,0,0,0-.11-.33.2.2,0,0,0-.26,0,.77.77,0,0,0-.37.48h0a.13.13,0,0,1-.25-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.55,34.08a1,1,0,0,1,.55-.54.3.3,0,0,1,.37.12.7.7,0,0,1,.06.35.73.73,0,0,0-.12-.32.19.19,0,0,0-.26,0,.8.8,0,0,0-.36.49h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.71,35.34a1,1,0,0,1,.56-.53.3.3,0,0,1,.36.13.72.72,0,0,1,.06.35.72.72,0,0,0-.11-.32.19.19,0,0,0-.26,0,.89.89,0,0,0-.38.48h0a.12.12,0,0,1-.15.09.11.11,0,0,1-.09-.15S72.7,35.34,72.71,35.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.08,37.17a1,1,0,0,1,.58-.5.28.28,0,0,1,.35.13.75.75,0,0,1,0,.36.85.85,0,0,0-.1-.33.2.2,0,0,0-.26,0,.87.87,0,0,0-.39.48h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.15A0,0,0,0,1,73.08,37.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.54,40.87a1,1,0,0,1,.61-.47.31.31,0,0,1,.35.16.89.89,0,0,1,0,.36.73.73,0,0,0-.08-.33.19.19,0,0,0-.26-.06.86.86,0,0,0-.42.44h0a.13.13,0,0,1-.16.08.14.14,0,0,1-.08-.17S72.53,40.88,72.54,40.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.28,46.22a1,1,0,0,1,.65-.42.28.28,0,0,1,.33.18.73.73,0,0,1,0,.36A.65.65,0,0,0,72.2,46a.19.19,0,0,0-.25-.08.82.82,0,0,0-.44.42h0a.12.12,0,0,1-.16.07.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.86,50.62a1,1,0,0,1,.65-.41.28.28,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.79.79,0,0,0-.45.41h0a.12.12,0,0,1-.16.06.14.14,0,0,1-.07-.17S69.85,50.62,69.86,50.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.28,51.56a1,1,0,0,1,.65-.4.28.28,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.81.81,0,0,0-.45.4h0a.13.13,0,0,1-.23-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.18,49.62a1,1,0,0,1,.65-.41.29.29,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0-.05-.34.2.2,0,0,0-.25-.09.87.87,0,0,0-.45.41h0a.12.12,0,0,1-.16.06.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.11,47.59a1,1,0,0,1,.65-.42.29.29,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.19.19,0,0,0-.25-.08.81.81,0,0,0-.46.41h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.66,43.21a1,1,0,0,1,.63-.44.27.27,0,0,1,.34.17.73.73,0,0,1,0,.36.94.94,0,0,0-.07-.34.19.19,0,0,0-.26-.07.82.82,0,0,0-.43.43h0a.13.13,0,0,1-.17.07.14.14,0,0,1-.07-.16A0,0,0,0,0,71.66,43.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.88,37.66a1,1,0,0,1,.58-.5.28.28,0,0,1,.35.13.75.75,0,0,1,0,.36.85.85,0,0,0-.1-.33.2.2,0,0,0-.26,0,.87.87,0,0,0-.39.48h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.15A0,0,0,0,1,71.88,37.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.39,32.86a1,1,0,0,1,.55-.54.31.31,0,0,1,.37.11.92.92,0,0,1,.07.36.76.76,0,0,0-.12-.32.2.2,0,0,0-.27,0,.84.84,0,0,0-.36.49h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.49,30.43a.94.94,0,0,1,.54-.55.28.28,0,0,1,.36.1.77.77,0,0,1,.08.35.66.66,0,0,0-.13-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.35.5h0a.13.13,0,1,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,29.66a1,1,0,0,1,.54-.55.27.27,0,0,1,.36.1.72.72,0,0,1,.08.35.75.75,0,0,0-.13-.32.2.2,0,0,0-.26,0,.84.84,0,0,0-.34.51h0a.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.75,31.14a1,1,0,0,1,.54-.55.28.28,0,0,1,.36.11.72.72,0,0,1,.08.35.9.9,0,0,0-.13-.32.2.2,0,0,0-.26,0,.77.77,0,0,0-.35.5h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.15A0,0,0,0,1,73.75,31.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.58,33.51a1.07,1.07,0,0,1,.54-.55.31.31,0,0,1,.37.11.92.92,0,0,1,.07.36.76.76,0,0,0-.12-.32.2.2,0,0,0-.27,0,.84.84,0,0,0-.35.5h0a.11.11,0,0,1-.15.09.12.12,0,0,1-.1-.14S71.58,33.52,71.58,33.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.14,38.3a1,1,0,0,1,.55-.54.27.27,0,0,1,.36.11.61.61,0,0,1,.07.35A.73.73,0,0,0,70,37.9a.2.2,0,0,0-.26,0,.85.85,0,0,0-.36.5h0a.13.13,0,0,1-.15.1.14.14,0,0,1-.1-.15A0,0,0,0,0,69.14,38.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.36,43.56a1,1,0,0,1,.53-.55.28.28,0,0,1,.37.1.74.74,0,0,1,.08.35.79.79,0,0,0-.13-.31.19.19,0,0,0-.26,0,.85.85,0,0,0-.35.51h0a.14.14,0,0,1-.15.1.14.14,0,0,1-.1-.15A0,0,0,0,0,65.36,43.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.28,46.28a1,1,0,0,1,.55-.54.3.3,0,0,1,.37.12.72.72,0,0,1,.06.35.73.73,0,0,0-.12-.32.19.19,0,0,0-.26,0,.8.8,0,0,0-.36.49h0a.13.13,0,0,1-.15.1.14.14,0,0,1-.1-.15S64.28,46.29,64.28,46.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.9,47.38a1,1,0,0,1,.56-.53.28.28,0,0,1,.36.12.76.76,0,0,1,.06.36.64.64,0,0,0-.11-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.49h0a.12.12,0,1,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.32,45.4a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.88.88,0,0,1,.1.35.75.75,0,0,0-.15-.31.19.19,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.83,37.7a1,1,0,0,1,.46-.62.28.28,0,0,1,.37.06.83.83,0,0,1,.13.34,1,1,0,0,0-.17-.3.21.21,0,0,0-.27,0,.85.85,0,0,0-.27.54h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.35,20.64a1,1,0,0,1,.58-.51.29.29,0,0,1,.36.13.9.9,0,0,1,0,.36.72.72,0,0,0-.11-.33.19.19,0,0,0-.26,0,.83.83,0,0,0-.38.47h0a.13.13,0,0,1-.16.09.12.12,0,0,1-.09-.15A0,0,0,0,0,72.35,20.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.43,12.79a1,1,0,0,1,.63-.44.3.3,0,0,1,.34.17.87.87,0,0,1,0,.36.7.7,0,0,0-.07-.33.19.19,0,0,0-.25-.08.84.84,0,0,0-.44.43h0a.13.13,0,0,1-.16.07.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.12,11.36a1,1,0,0,1,.64-.44.29.29,0,0,1,.34.18.87.87,0,0,1,0,.36.93.93,0,0,0-.06-.34.18.18,0,0,0-.25-.07.81.81,0,0,0-.45.42h0a.14.14,0,0,1-.16.07.13.13,0,0,1-.07-.17A0,0,0,0,1,75.12,11.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.56,12.69a1,1,0,0,1,.64-.43.3.3,0,0,1,.34.17.73.73,0,0,1,0,.36.72.72,0,0,0-.06-.33.19.19,0,0,0-.25-.08.82.82,0,0,0-.44.42h0a.12.12,0,0,1-.16.06.11.11,0,0,1-.07-.16A0,0,0,0,1,75.56,12.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.09,15.53a1,1,0,0,1,.59-.49.31.31,0,0,1,.36.15.74.74,0,0,1,0,.36.7.7,0,0,0-.09-.33.19.19,0,0,0-.26-.06.89.89,0,0,0-.4.46h0a.13.13,0,0,1-.24-.07A0,0,0,0,1,71.09,15.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.3,21.31a1,1,0,0,1,.44-.64.28.28,0,0,1,.38,0,.84.84,0,0,1,.13.33.79.79,0,0,0-.18-.29.2.2,0,0,0-.26,0,.84.84,0,0,0-.26.56h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.72,24.88a1,1,0,0,1,.27-.72.29.29,0,0,1,.38-.05.68.68,0,0,1,.21.29.69.69,0,0,0-.24-.24.21.21,0,0,0-.26.08.86.86,0,0,0-.11.6h0a.12.12,0,1,1-.24.06S64.72,24.89,64.72,24.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.64,28a1,1,0,0,1,.16-.75.29.29,0,0,1,.37-.1.78.78,0,0,1,.25.26.76.76,0,0,0-.27-.21.2.2,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.11.11,0,0,1-.16-.07A0,0,0,0,1,62.64,28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.38,30.2a1,1,0,0,1,.14-.76.29.29,0,0,1,.36-.11.78.78,0,0,1,.26.25.82.82,0,0,0-.28-.19c-.11,0-.19,0-.24.12a.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.51,30.94a1,1,0,0,1,.11-.76.31.31,0,0,1,.36-.13.93.93,0,0,1,.27.25.77.77,0,0,0-.29-.19.19.19,0,0,0-.23.14.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.83,29a1,1,0,0,1,.13-.75.28.28,0,0,1,.36-.12.67.67,0,0,1,.26.24.77.77,0,0,0-.28-.19.19.19,0,0,0-.23.13.81.81,0,0,0,0,.61h0a.13.13,0,1,1-.24.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.87,28.39a1,1,0,0,1,.11-.76.31.31,0,0,1,.36-.13.9.9,0,0,1,.27.24.88.88,0,0,0-.29-.18.19.19,0,0,0-.23.13.88.88,0,0,0,0,.62h0a.13.13,0,0,1-.23.11S60.87,28.4,60.87,28.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.44,30.52a1,1,0,0,1,.09-.76.29.29,0,0,1,.36-.14.87.87,0,0,1,.27.23.79.79,0,0,0-.29-.17.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.11.11,0,0,1-.05.16.12.12,0,0,1-.17-.05A0,0,0,0,1,60.44,30.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.24,29.8a1,1,0,0,1,.12-.77.29.29,0,0,1,.35-.13.82.82,0,0,1,.27.25.77.77,0,0,0-.29-.19.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.23.11A0,0,0,0,0,61.24,29.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.17,29.34a1,1,0,0,1,.09-.76.3.3,0,0,1,.36-.14.87.87,0,0,1,.27.23.72.72,0,0,0-.29-.17.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.14.14,0,0,1-.06.17.13.13,0,0,1-.17-.06S60.17,29.35,60.17,29.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,29.17a1,1,0,0,1,.07-.77.29.29,0,0,1,.35-.15.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.11,0-.18.05-.22.15a.81.81,0,0,0,0,.61h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0S59.14,29.18,59.14,29.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.1,28.21a1,1,0,0,1,.06-.77.3.3,0,0,1,.35-.16.88.88,0,0,1,.28.22.73.73,0,0,0-.3-.16.2.2,0,0,0-.22.15.85.85,0,0,0,.06.61h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18-.05S58.11,28.22,58.1,28.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.91,27.79a1,1,0,0,1,0-.77.31.31,0,0,1,.35-.17.77.77,0,0,1,.29.22.84.84,0,0,0-.31-.16.2.2,0,0,0-.21.16.82.82,0,0,0,.07.6h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0A0,0,0,0,1,56.91,27.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.16,26.61a.94.94,0,0,1,0-.77.29.29,0,0,1,.34-.17.86.86,0,0,1,.29.21,1,1,0,0,0-.31-.15.2.2,0,0,0-.21.16.8.8,0,0,0,.08.61h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17-.05S56.16,26.62,56.16,26.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,27.09a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.18.81.81,0,0,1,.3.21,1,1,0,0,0-.31-.15.21.21,0,0,0-.21.16.85.85,0,0,0,.09.61h0a.12.12,0,1,1-.21.13A0,0,0,0,1,55,27.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.2,25.87a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.18.88.88,0,0,1,.3.2.79.79,0,0,0-.31-.14.21.21,0,0,0-.21.16.85.85,0,0,0,.09.61h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,0,54.2,25.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.23,26.79a1,1,0,0,1,0-.77.3.3,0,0,1,.34-.18.88.88,0,0,1,.3.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.1.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0A0,0,0,0,1,53.23,26.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.47,26a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.18.69.69,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.82.82,0,0,0,.11.6h0a.13.13,0,0,1,0,.17.14.14,0,0,1-.18,0S52.47,26,52.47,26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.05,25.91a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.83.83,0,0,1,.31.2.68.68,0,0,0-.32-.13.19.19,0,0,0-.2.16.86.86,0,0,0,.11.61h0a.14.14,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.61,25.62a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.73.73,0,0,1,.3.19.76.76,0,0,0-.31-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.47,27.24a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0S45.47,27.25,45.47,27.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.52,27.11a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.69.69,0,0,1,.31.18.66.66,0,0,0-.32-.12.19.19,0,0,0-.2.17.88.88,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S43.52,27.12,43.52,27.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.08,26.23a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2.87.87,0,0,1,.31.18.66.66,0,0,0-.32-.12.2.2,0,0,0-.2.18.8.8,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0S42.08,26.24,42.08,26.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.82,25.23a1,1,0,0,1,0-.77.31.31,0,0,1,.33-.21.87.87,0,0,1,.31.18.76.76,0,0,0-.32-.11.2.2,0,0,0-.2.17.87.87,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.65,24.63a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.21.79.79,0,0,1,.31.18,1,1,0,0,0-.32-.12.19.19,0,0,0-.19.18.82.82,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.53,23.9a1,1,0,0,1-.05-.77.29.29,0,0,1,.32-.21.87.87,0,0,1,.31.18.76.76,0,0,0-.32-.11.2.2,0,0,0-.2.18.86.86,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.58,24.17a1,1,0,0,1-.06-.76.29.29,0,0,1,.32-.21.78.78,0,0,1,.32.18.74.74,0,0,0-.33-.12.19.19,0,0,0-.19.18.85.85,0,0,0,.15.59h0a.12.12,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.58,23.88a1,1,0,0,1-.06-.77.27.27,0,0,1,.31-.21.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.79.79,0,0,0,.15.6h0a.13.13,0,0,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.61,24.17a1,1,0,0,1-.07-.77.28.28,0,0,1,.32-.21.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.88.88,0,0,0,.15.6h0a.13.13,0,1,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.59,24.35a1,1,0,0,1-.07-.77.29.29,0,0,1,.32-.21.76.76,0,0,1,.32.17.86.86,0,0,0-.33-.11.19.19,0,0,0-.19.18.83.83,0,0,0,.16.6h0a.12.12,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,34.59,24.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,25.42a1,1,0,0,1-.07-.77.31.31,0,0,1,.32-.21.92.92,0,0,1,.32.18.72.72,0,0,0-.33-.11c-.11,0-.17.08-.19.18a.85.85,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.71,24.55a1,1,0,0,1-.07-.77.28.28,0,0,1,.32-.21.76.76,0,0,1,.32.17.86.86,0,0,0-.33-.11c-.11,0-.17.09-.19.19a.87.87,0,0,0,.16.59h0a.13.13,0,1,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.07,25.85a1,1,0,0,1-.07-.76.28.28,0,0,1,.32-.21.76.76,0,0,1,.32.17.86.86,0,0,0-.33-.11.19.19,0,0,0-.19.18.85.85,0,0,0,.16.59h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,25.94a1,1,0,0,1-.08-.77.3.3,0,0,1,.32-.21.76.76,0,0,1,.32.17.86.86,0,0,0-.33-.11c-.11,0-.17.09-.19.19a.87.87,0,0,0,.16.59h0a.14.14,0,0,1,0,.18A.13.13,0,0,1,33,26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.21,24.65a1,1,0,0,1-.09-.77.28.28,0,0,1,.32-.21.88.88,0,0,1,.32.16.84.84,0,0,0-.33-.1.19.19,0,0,0-.19.18.84.84,0,0,0,.17.59h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S32.21,24.66,32.21,24.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.31,25.62a1,1,0,0,1-.09-.76.3.3,0,0,1,.32-.22.76.76,0,0,1,.32.17.86.86,0,0,0-.33-.11c-.11,0-.17.09-.19.19a.91.91,0,0,0,.17.59h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.09,25.84a1,1,0,0,1-.11-.76.28.28,0,0,1,.31-.22.68.68,0,0,1,.32.15.73.73,0,0,0-.32-.09c-.12,0-.17.09-.19.19a.83.83,0,0,0,.19.58h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S29.09,25.85,29.09,25.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.92,26a1,1,0,0,1-.16-.76.28.28,0,0,1,.29-.24.74.74,0,0,1,.33.13.78.78,0,0,0-.33-.07.2.2,0,0,0-.17.2.86.86,0,0,0,.23.57h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.25,26.66a1,1,0,0,1-.21-.74.29.29,0,0,1,.27-.27.76.76,0,0,1,.34.11.78.78,0,0,0-.33-.05.21.21,0,0,0-.16.22.89.89,0,0,0,.26.55h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S24.25,26.67,24.25,26.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.94,27.18a1,1,0,0,1-.26-.73.3.3,0,0,1,.26-.28.7.7,0,0,1,.35.09.67.67,0,0,0-.34,0,.19.19,0,0,0-.14.22.85.85,0,0,0,.29.54h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.88,26.16a1,1,0,0,1-.25-.72.27.27,0,0,1,.25-.28.68.68,0,0,1,.35.09.66.66,0,0,0-.34,0c-.11,0-.14.12-.14.22a.81.81,0,0,0,.3.54h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.69,27.48a1,1,0,0,1-.38-.67.31.31,0,0,1,.21-.33.87.87,0,0,1,.36,0,.94.94,0,0,0-.34,0,.2.2,0,0,0-.1.25.79.79,0,0,0,.38.47h0a.12.12,0,0,1,.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,28.24a1.07,1.07,0,0,1-.5-.59.28.28,0,0,1,.15-.35.61.61,0,0,1,.35,0,.7.7,0,0,0-.32.09.19.19,0,0,0-.06.26.88.88,0,0,0,.47.4h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.49,29a1,1,0,0,1-.56-.53.29.29,0,0,1,.1-.36.74.74,0,0,1,.35-.08.58.58,0,0,0-.31.13.18.18,0,0,0,0,.26.81.81,0,0,0,.5.34h0a.13.13,0,0,1,.11.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.26,30.06a1,1,0,0,1-.57-.51.29.29,0,0,1,.09-.37.76.76,0,0,1,.35-.09.68.68,0,0,0-.31.14.2.2,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.13.13,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.59,32.42A1,1,0,0,1,15,31.9a.28.28,0,0,1,.1-.37.72.72,0,0,1,.35-.08.66.66,0,0,0-.31.13.19.19,0,0,0,0,.26.84.84,0,0,0,.51.34h0a.13.13,0,0,1,.11.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.2,34.75a1,1,0,0,1-.56-.54.28.28,0,0,1,.11-.36.72.72,0,0,1,.35-.08.75.75,0,0,0-.32.13.2.2,0,0,0,0,.26.87.87,0,0,0,.5.35h0a.13.13,0,0,1,.1.15.14.14,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.4,36.45a1,1,0,0,1-.53-.56.28.28,0,0,1,.12-.36.77.77,0,0,1,.36-.07.87.87,0,0,0-.33.12.19.19,0,0,0,0,.26.82.82,0,0,0,.48.37h0a.14.14,0,0,1,.1.15.14.14,0,0,1-.16.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.39,37.66a1,1,0,0,1-.54-.55.3.3,0,0,1,.12-.37.72.72,0,0,1,.35-.06.73.73,0,0,0-.32.12.19.19,0,0,0,0,.26.8.8,0,0,0,.49.36h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.52,37.09a1,1,0,0,1-.56-.53.27.27,0,0,1,.1-.36.72.72,0,0,1,.35-.08.66.66,0,0,0-.31.13.19.19,0,0,0,0,.26.84.84,0,0,0,.51.34h0a.13.13,0,0,1,.1.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.58,34.64a1,1,0,0,1-.51-.57.3.3,0,0,1,.13-.36.75.75,0,0,1,.36-.05.91.91,0,0,0-.33.1.19.19,0,0,0,0,.26.81.81,0,0,0,.47.39h0a.13.13,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.27,31.46a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.87.87,0,0,1,.36,0,.65.65,0,0,0-.34.07.2.2,0,0,0-.07.25.84.84,0,0,0,.44.43h0a.14.14,0,0,1,.07.17.14.14,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,29.38a1,1,0,0,1-.41-.65.29.29,0,0,1,.18-.33.87.87,0,0,1,.36,0,.88.88,0,0,0-.33.05.2.2,0,0,0-.09.25.81.81,0,0,0,.41.46h0a.13.13,0,0,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.57,27.65a1,1,0,0,1-.35-.69.29.29,0,0,1,.22-.31.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.79.79,0,0,0,.36.49h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.26,25.43a1,1,0,0,1-.31-.71.28.28,0,0,1,.23-.3.81.81,0,0,1,.35.06,1,1,0,0,0-.34,0,.22.22,0,0,0-.12.24.84.84,0,0,0,.34.51h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.44,26.56a1,1,0,0,1-.31-.71.3.3,0,0,1,.24-.3.81.81,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.12.24.82.82,0,0,0,.33.51h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0S21.44,26.57,21.44,26.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,32.39a1,1,0,0,1-.32-.71.28.28,0,0,1,.23-.3.85.85,0,0,1,.36.06.78.78,0,0,0-.34,0,.19.19,0,0,0-.12.24.8.8,0,0,0,.34.51h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.82,35.89a1,1,0,0,1-.37-.68.28.28,0,0,1,.21-.31.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.19.19,0,0,0-.11.24.88.88,0,0,0,.38.48h0a.14.14,0,0,1,.05.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.69,38.63a1,1,0,0,1-.42-.65.3.3,0,0,1,.19-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.05.18.18,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.12.12,0,0,1,.07.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.54,40a1,1,0,0,1-.44-.63.29.29,0,0,1,.17-.34.87.87,0,0,1,.36,0,.7.7,0,0,0-.33.07.19.19,0,0,0-.08.25.84.84,0,0,0,.43.44h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.42,39.72a1,1,0,0,1-.41-.66.29.29,0,0,1,.19-.33.72.72,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.87.87,0,0,0,.4.46h0a.13.13,0,0,1-.1.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,35.55a1,1,0,0,1-.33-.7.3.3,0,0,1,.23-.31.72.72,0,0,1,.36.06.78.78,0,0,0-.34,0,.19.19,0,0,0-.12.24.83.83,0,0,0,.34.5h0a.12.12,0,1,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.27,31.73A1,1,0,0,1,24,31a.29.29,0,0,1,.26-.28.82.82,0,0,1,.35.09,1,1,0,0,0-.34,0,.19.19,0,0,0-.14.22.79.79,0,0,0,.28.54h0a.13.13,0,1,1-.16.2A0,0,0,0,0,24.27,31.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,29.39a1,1,0,0,1-.2-.74.28.28,0,0,1,.27-.27.66.66,0,0,1,.34.12.66.66,0,0,0-.33-.06.2.2,0,0,0-.16.22.8.8,0,0,0,.26.55h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.47,27a1,1,0,0,1-.15-.76.28.28,0,0,1,.29-.24.83.83,0,0,1,.34.13.83.83,0,0,0-.34-.07.2.2,0,0,0-.17.2.81.81,0,0,0,.22.57h0a.13.13,0,0,1-.18.18S26.48,27,26.47,27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.85,23.91a1,1,0,0,1-.14-.76.3.3,0,0,1,.3-.24.91.91,0,0,1,.33.15A.67.67,0,0,0,27,23a.2.2,0,0,0-.18.2.87.87,0,0,0,.21.57h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S26.86,23.92,26.85,23.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.83,23.87a1,1,0,0,1-.13-.76.31.31,0,0,1,.3-.23.77.77,0,0,1,.33.15.68.68,0,0,0-.33-.09.2.2,0,0,0-.18.2.86.86,0,0,0,.21.58h0a.12.12,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.3,25.12a1,1,0,0,1-.13-.76.28.28,0,0,1,.3-.23.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08.2.2,0,0,0-.18.2.82.82,0,0,0,.21.57h0a.12.12,0,1,1-.18.17S27.31,25.13,27.3,25.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.4,29.49a1,1,0,0,1-.17-.75.29.29,0,0,1,.29-.25.78.78,0,0,1,.34.13.83.83,0,0,0-.34-.07.2.2,0,0,0-.16.21.79.79,0,0,0,.23.56h0a.13.13,0,1,1-.18.18S26.41,29.5,26.4,29.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.73,33.59a1,1,0,0,1-.25-.73.3.3,0,0,1,.26-.28.82.82,0,0,1,.35.09.66.66,0,0,0-.34,0,.19.19,0,0,0-.14.22.77.77,0,0,0,.29.54h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.8,36.74a1,1,0,0,1-.31-.7.28.28,0,0,1,.23-.3.68.68,0,0,1,.35.06.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.88.88,0,0,0,.34.51h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.74,37.51a1,1,0,0,1-.36-.68.29.29,0,0,1,.21-.32.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.25.85.85,0,0,0,.37.48h0a.13.13,0,0,1,.05.18.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.79,37.88a1,1,0,0,1-.33-.7.29.29,0,0,1,.22-.31.85.85,0,0,1,.36.05,1,1,0,0,0-.34,0,.2.2,0,0,0-.12.24.81.81,0,0,0,.36.5h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,35.31a.94.94,0,0,1-.26-.72.28.28,0,0,1,.25-.28.82.82,0,0,1,.35.08.78.78,0,0,0-.34,0,.18.18,0,0,0-.14.22.86.86,0,0,0,.3.54h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,28.66a1,1,0,0,1-.13-.76.29.29,0,0,1,.3-.24.77.77,0,0,1,.33.15.71.71,0,0,0-.33-.09.2.2,0,0,0-.18.2.84.84,0,0,0,.2.58h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.72,21.9a1,1,0,0,1-.11-.76.29.29,0,0,1,.3-.23.78.78,0,0,1,.33.16.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.19.85.85,0,0,0,.19.58h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,29.72,21.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.17,18.48a1,1,0,0,1-.12-.76.31.31,0,0,1,.3-.24.78.78,0,0,1,.33.16.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.19.78.78,0,0,0,.2.58h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,30.17,18.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,19.43a1,1,0,0,1-.12-.76.29.29,0,0,1,.31-.23.68.68,0,0,1,.32.15.65.65,0,0,0-.32-.09c-.12,0-.17.09-.19.2a.85.85,0,0,0,.2.58h0a.13.13,0,0,1-.19.17S30,19.44,30,19.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,17.75a1,1,0,0,1-.14-.76.3.3,0,0,1,.3-.24.91.91,0,0,1,.33.15.67.67,0,0,0-.33-.08A.2.2,0,0,0,29,17a.87.87,0,0,0,.21.57h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S29,17.76,29,17.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.26,15.88a1,1,0,0,1-.22-.74.28.28,0,0,1,.27-.27.83.83,0,0,1,.35.1.83.83,0,0,0-.34,0,.19.19,0,0,0-.15.22.84.84,0,0,0,.26.55h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.91,14.45a1,1,0,0,1-.35-.68.3.3,0,0,1,.22-.32.87.87,0,0,1,.36.05.82.82,0,0,0-.35,0,.2.2,0,0,0-.11.24.93.93,0,0,0,.36.5h0a.13.13,0,1,1-.12.22A0,0,0,0,1,22.91,14.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.77,14.8a1,1,0,0,1-.42-.65.3.3,0,0,1,.19-.34.87.87,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.81.81,0,0,0,.41.46h0a.12.12,0,0,1,.07.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.73,15.08a1,1,0,0,1-.48-.6.29.29,0,0,1,.15-.35.75.75,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.82.82,0,0,0,.45.41h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.33,15.86a1.07,1.07,0,0,1-.54-.55.3.3,0,0,1,.12-.37.84.84,0,0,1,.35-.07.88.88,0,0,0-.32.12.2.2,0,0,0,0,.27.87.87,0,0,0,.49.36h0a.13.13,0,0,1,.1.15.12.12,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.4,15.35a1,1,0,0,1-.58-.5.29.29,0,0,1,.08-.37.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.19.19,0,0,0,0,.26.85.85,0,0,0,.52.32h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,18.07a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.19.19,0,0,0,0,.26.87.87,0,0,0,.53.32h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12,18.68a1,1,0,0,1-.59-.5.28.28,0,0,1,.07-.37.64.64,0,0,1,.35-.1.69.69,0,0,0-.31.15.19.19,0,0,0,0,.26.79.79,0,0,0,.52.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.92,17.6a1,1,0,0,1-.6-.49.3.3,0,0,1,.08-.38.88.88,0,0,1,.34-.1.77.77,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.13.13,0,0,1,.11.14.14.14,0,0,1-.14.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,15.76a1,1,0,0,1-.59-.49.28.28,0,0,1,.07-.37.76.76,0,0,1,.35-.11,1,1,0,0,0-.31.15.2.2,0,0,0,0,.27.88.88,0,0,0,.52.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.35,16.63a1,1,0,0,1-.6-.5.29.29,0,0,1,.08-.37.88.88,0,0,1,.34-.1.77.77,0,0,0-.3.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.43,17.12a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.64.64,0,0,1,.35-.1.8.8,0,0,0-.31.14.2.2,0,0,0,0,.27.82.82,0,0,0,.53.31h0a.14.14,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.16,16.55a1,1,0,0,1-.58-.51.28.28,0,0,1,.09-.37.83.83,0,0,1,.34-.1.81.81,0,0,0-.31.15.2.2,0,0,0,0,.26.81.81,0,0,0,.52.32h0a.13.13,0,0,1,.11.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.48,20.44a1,1,0,0,1-.58-.5.28.28,0,0,1,.08-.37.88.88,0,0,1,.34-.1.71.71,0,0,0-.3.15.18.18,0,0,0,0,.26.83.83,0,0,0,.52.32h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.61,23.08a1,1,0,0,1-.59-.49.29.29,0,0,1,.08-.38.83.83,0,0,1,.34-.1.71.71,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.52.31h0a.13.13,0,1,1,0,.25S13.61,23.09,13.61,23.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.06,24.44a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.2.2,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.35,22.43a1,1,0,0,1-.57-.51.28.28,0,0,1,.08-.37.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.19.19,0,0,0,0,.26.76.76,0,0,0,.52.32h0a.12.12,0,0,1,.11.14.11.11,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.74,23.63a1,1,0,0,1-.58-.52.29.29,0,0,1,.09-.37,1,1,0,0,1,.35-.09,1,1,0,0,0-.31.14.2.2,0,0,0,0,.27.82.82,0,0,0,.51.33h0a.11.11,0,0,1,.1.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.2,24.52a1,1,0,0,1-.52-.57.29.29,0,0,1,.12-.36.86.86,0,0,1,.35-.06.88.88,0,0,0-.32.12.19.19,0,0,0,0,.26.86.86,0,0,0,.49.37h0a.12.12,0,0,1,.09.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.23,24.85a1,1,0,0,1-.57-.53.31.31,0,0,1,.1-.37.74.74,0,0,1,.35-.08.79.79,0,0,0-.31.13.19.19,0,0,0,0,.27.82.82,0,0,0,.51.33h0a.12.12,0,1,1-.05.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.77,23.59a1,1,0,0,1-.54-.54.28.28,0,0,1,.11-.37.84.84,0,0,1,.35-.07.88.88,0,0,0-.32.12.2.2,0,0,0,0,.27.8.8,0,0,0,.5.35h0a.12.12,0,1,1-.06.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.53,22.27a1,1,0,0,1-.55-.54.3.3,0,0,1,.11-.37.87.87,0,0,1,.35-.07.77.77,0,0,0-.31.12.2.2,0,0,0,0,.27.84.84,0,0,0,.5.35h0a.12.12,0,0,1,.1.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.62,23.22a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.88.88,0,0,1,.36,0,1.21,1.21,0,0,0-.34.08.2.2,0,0,0-.06.26.8.8,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,22.11a1,1,0,0,1-.5-.59.29.29,0,0,1,.14-.35.64.64,0,0,1,.36,0,.74.74,0,0,0-.33.09.2.2,0,0,0-.05.26.88.88,0,0,0,.47.4h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.05,20.17a1,1,0,0,1-.53-.56.3.3,0,0,1,.11-.36.91.91,0,0,1,.36-.06.69.69,0,0,0-.32.12.19.19,0,0,0,0,.26.86.86,0,0,0,.49.37h0a.12.12,0,0,1-.06.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.69,17a1,1,0,0,1-.46-.62.3.3,0,0,1,.16-.35,1.09,1.09,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.3,20a1,1,0,0,1-.49-.59.3.3,0,0,1,.14-.36.75.75,0,0,1,.36,0,.74.74,0,0,0-.33.09.2.2,0,0,0-.05.26.89.89,0,0,0,.46.4h0a.13.13,0,0,1,.08.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.23,19.5a1,1,0,0,1-.37-.67.3.3,0,0,1,.21-.33.88.88,0,0,1,.36,0,.94.94,0,0,0-.34,0,.2.2,0,0,0-.11.25.85.85,0,0,0,.39.48h0a.12.12,0,0,1,.06.17.12.12,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.6,18.28a1,1,0,0,1-.45-.63.29.29,0,0,1,.16-.34.78.78,0,0,1,.37,0,.77.77,0,0,0-.34.08.18.18,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.14.14,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.08,22.54a1.07,1.07,0,0,1-.41-.66.3.3,0,0,1,.19-.33.92.92,0,0,1,.37,0,.76.76,0,0,0-.34.05.2.2,0,0,0-.09.25.83.83,0,0,0,.4.46h0a.13.13,0,1,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.08,19.26a1,1,0,0,1-.43-.64.32.32,0,0,1,.18-.34.87.87,0,0,1,.36,0,.92.92,0,0,0-.33.06.19.19,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.13.13,0,1,1-.1.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.39,20.13A1,1,0,0,1,18,19.5a.28.28,0,0,1,.16-.34.93.93,0,0,1,.37,0,.77.77,0,0,0-.34.07.18.18,0,0,0-.07.25.79.79,0,0,0,.43.43h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.2,22.16a1,1,0,0,1-.44-.62.29.29,0,0,1,.16-.35.93.93,0,0,1,.37,0,.77.77,0,0,0-.34.07.19.19,0,0,0-.07.26.82.82,0,0,0,.43.43h0a.13.13,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.88,21.27a1,1,0,0,1-.41-.65.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.05.19.19,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.13.13,0,0,1,.06.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.81,21.09a1,1,0,0,1-.46-.61.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.06.25.82.82,0,0,0,.44.42h0A.14.14,0,0,1,18,21a.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.46,20.36a1,1,0,0,1-.4-.66.3.3,0,0,1,.19-.33.72.72,0,0,1,.36,0,.74.74,0,0,0-.33.05c-.11,0-.12.15-.1.25a.87.87,0,0,0,.4.46h0a.13.13,0,1,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.82,21.62a1,1,0,0,1-.37-.67.3.3,0,0,1,.21-.32.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.81.81,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.8,17.52a1,1,0,0,1-.48-.59.28.28,0,0,1,.14-.35.74.74,0,0,1,.36,0,.9.9,0,0,0-.33.09.19.19,0,0,0,0,.26.79.79,0,0,0,.46.4h0a.12.12,0,0,1,.09.15.14.14,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.63,19.18a1,1,0,0,1-.51-.58.29.29,0,0,1,.12-.36.9.9,0,0,1,.36,0,.68.68,0,0,0-.32.11.2.2,0,0,0-.05.26.85.85,0,0,0,.48.38h0a.13.13,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.89,19.08a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.2.2,0,0,0-.07.26.82.82,0,0,0,.45.41h0a.12.12,0,0,1,.08.16.14.14,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.14,18.29a1,1,0,0,1-.5-.58.3.3,0,0,1,.14-.36.89.89,0,0,1,.36,0,.71.71,0,0,0-.33.1.2.2,0,0,0,0,.26.82.82,0,0,0,.47.39h0a.14.14,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.73,21a1,1,0,0,1-.51-.58.29.29,0,0,1,.13-.36.75.75,0,0,1,.36-.05.85.85,0,0,0-.33.1.2.2,0,0,0,0,.27.8.8,0,0,0,.47.38h0a.14.14,0,0,1,.09.16.13.13,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.49,17.62a1,1,0,0,1-.43-.64.29.29,0,0,1,.18-.34.87.87,0,0,1,.36,0,.93.93,0,0,0-.34.06.19.19,0,0,0-.08.25.81.81,0,0,0,.42.45h0a.13.13,0,0,1,.06.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.92,18.55a1,1,0,0,1-.4-.66.28.28,0,0,1,.19-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.05.2.2,0,0,0-.09.25.83.83,0,0,0,.4.46h0a.13.13,0,1,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.49,20.58a1,1,0,0,1-.35-.68.31.31,0,0,1,.22-.32.87.87,0,0,1,.36.05.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.25.82.82,0,0,0,.36.49h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17.05S20.5,20.59,20.49,20.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.57,16.78a1,1,0,0,1-.37-.68.3.3,0,0,1,.21-.32.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.18.18,0,0,0-.1.24.84.84,0,0,0,.37.49h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.27,15.92a1,1,0,0,1-.34-.68.28.28,0,0,1,.21-.32,1,1,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.24.87.87,0,0,0,.36.49h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.52,17.41a1,1,0,0,1-.32-.7.28.28,0,0,1,.23-.3.87.87,0,0,1,.36.05.77.77,0,0,0-.34,0,.18.18,0,0,0-.12.23.8.8,0,0,0,.34.51h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0S22.52,17.42,22.52,17.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.76,15.8a1,1,0,0,1-.27-.73.3.3,0,0,1,.26-.28.82.82,0,0,1,.35.08.8.8,0,0,0-.34,0,.2.2,0,0,0-.14.23.81.81,0,0,0,.3.53h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.15,14.71a1,1,0,0,1-.27-.72.3.3,0,0,1,.26-.28.82.82,0,0,1,.35.08.8.8,0,0,0-.34,0A.19.19,0,0,0,25,14a.81.81,0,0,0,.3.53h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.87,16.7a1,1,0,0,1-.22-.73.28.28,0,0,1,.27-.27.71.71,0,0,1,.35.1.81.81,0,0,0-.34,0,.19.19,0,0,0-.15.22.81.81,0,0,0,.27.54h0a.12.12,0,1,1-.16.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.68,14.28a1,1,0,0,1-.22-.73c0-.13.13-.28.26-.27a.68.68,0,0,1,.35.1.67.67,0,0,0-.34,0c-.11,0-.15.11-.15.22a.82.82,0,0,0,.28.54h0a.12.12,0,1,1-.16.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.69,13a1,1,0,0,1-.21-.74.29.29,0,0,1,.27-.27.8.8,0,0,1,.35.11.8.8,0,0,0-.34-.05.19.19,0,0,0-.15.22.8.8,0,0,0,.26.55h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,14.67a1,1,0,0,1-.18-.75.29.29,0,0,1,.28-.26,1,1,0,0,1,.34.12.8.8,0,0,0-.34,0c-.11,0-.16.1-.16.21a.83.83,0,0,0,.25.56h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.68,13.71A1,1,0,0,1,28.5,13a.31.31,0,0,1,.29-.26.83.83,0,0,1,.34.13.68.68,0,0,0-.34-.06c-.11,0-.16.1-.16.21a.82.82,0,0,0,.24.56h0a.13.13,0,1,1-.17.19S28.69,13.71,28.68,13.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.15,12.08a1,1,0,0,1-.19-.74.3.3,0,0,1,.29-.26,1,1,0,0,1,.34.12,1,1,0,0,0-.34-.06.2.2,0,0,0-.16.21.83.83,0,0,0,.24.56h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.7,13a1,1,0,0,1-.16-.75.29.29,0,0,1,.29-.25.75.75,0,0,1,.33.14.66.66,0,0,0-.33-.07.2.2,0,0,0-.17.2.84.84,0,0,0,.23.57h0a.11.11,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.89,12.26a1,1,0,0,1-.14-.76.29.29,0,0,1,.3-.24.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08.2.2,0,0,0-.18.2.83.83,0,0,0,.21.58h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.16,11.87a1,1,0,0,1-.12-.76.29.29,0,0,1,.3-.23.91.91,0,0,1,.33.15,1,1,0,0,0-.33-.09.2.2,0,0,0-.18.2.85.85,0,0,0,.19.58h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0A0,0,0,0,1,32.16,11.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.76,12.92a.94.94,0,0,1-.12-.76.28.28,0,0,1,.3-.23.77.77,0,0,1,.33.15.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.2.85.85,0,0,0,.19.58h0A.14.14,0,0,1,32,13a.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.37,14.37a1,1,0,0,1-.13-.76.29.29,0,0,1,.29-.24,1,1,0,0,1,.34.15.71.71,0,0,0-.34-.08c-.11,0-.16.09-.17.2a.82.82,0,0,0,.21.57h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.51,18.52a1,1,0,0,1-.17-.75.29.29,0,0,1,.29-.25.93.93,0,0,1,.34.13.82.82,0,0,0-.33-.07.2.2,0,0,0-.17.21.84.84,0,0,0,.23.57h0a.13.13,0,0,1,0,.18.11.11,0,0,1-.17,0A0,0,0,0,1,27.51,18.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.49,21.59a1,1,0,0,1-.19-.75c0-.13.15-.27.28-.25a.63.63,0,0,1,.34.12.65.65,0,0,0-.33-.06.2.2,0,0,0-.16.21.85.85,0,0,0,.24.56h0a.11.11,0,0,1,0,.17.11.11,0,0,1-.17,0A0,0,0,0,1,25.49,21.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.26,25.16a1,1,0,0,1-.2-.74.28.28,0,0,1,.27-.26.76.76,0,0,1,.34.11.76.76,0,0,0-.33-.05.2.2,0,0,0-.16.21.84.84,0,0,0,.26.56h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S24.27,25.17,24.26,25.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.78,28.57a1,1,0,0,1-.27-.72.32.32,0,0,1,.26-.29.82.82,0,0,1,.35.09.67.67,0,0,0-.34,0,.18.18,0,0,0-.14.22.83.83,0,0,0,.3.53h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S22.79,28.58,22.78,28.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.14,30.15a1,1,0,0,1-.29-.71.29.29,0,0,1,.23-.3.86.86,0,0,1,.36.07.79.79,0,0,0-.34,0,.21.21,0,0,0-.13.24.82.82,0,0,0,.33.51h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S22.15,30.16,22.14,30.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.69,31.63a1,1,0,0,1-.36-.68.29.29,0,0,1,.21-.32.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.21.21,0,0,0-.11.25.85.85,0,0,0,.37.48h0a.12.12,0,0,1,.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.91,33.77a1,1,0,0,1-.41-.65.3.3,0,0,1,.19-.34.86.86,0,0,1,.36,0,.76.76,0,0,0-.34.05.19.19,0,0,0-.08.25.83.83,0,0,0,.4.46h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.88,35.83a1,1,0,0,1-.47-.6.29.29,0,0,1,.15-.35.75.75,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.82.82,0,0,0,.45.41h0a.12.12,0,0,1,.08.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.36,39a1,1,0,0,1-.55-.54.3.3,0,0,1,.11-.37.87.87,0,0,1,.35-.07.88.88,0,0,0-.32.12.21.21,0,0,0,0,.27.84.84,0,0,0,.5.35h0a.12.12,0,0,1,.09.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.38,41.06a1,1,0,0,1-.57-.52.31.31,0,0,1,.1-.37.86.86,0,0,1,.35-.09,1,1,0,0,0-.31.14.18.18,0,0,0,0,.26.84.84,0,0,0,.51.34h0a.13.13,0,0,1,.1.15.14.14,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.41,42.61a1,1,0,0,1-.58-.51.29.29,0,0,1,.09-.37.73.73,0,0,1,.35-.09.68.68,0,0,0-.31.14.2.2,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.88,43.5a1,1,0,0,1-.57-.51.28.28,0,0,1,.09-.37.73.73,0,0,1,.35-.09.67.67,0,0,0-.32.14.19.19,0,0,0,0,.26.82.82,0,0,0,.52.33h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.84,39.12a1.08,1.08,0,0,1-.48-.6.31.31,0,0,1,.15-.36.88.88,0,0,1,.36,0,.85.85,0,0,0-.33.09.19.19,0,0,0-.06.26.82.82,0,0,0,.46.4h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.64,22.35a1,1,0,0,1-.23-.74.29.29,0,0,1,.27-.27.75.75,0,0,1,.34.1.8.8,0,0,0-.34,0,.2.2,0,0,0-.15.22.89.89,0,0,0,.28.55h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,23.64,22.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.52,12.55a1,1,0,0,1-.25-.73.3.3,0,0,1,.26-.28.82.82,0,0,1,.35.09.68.68,0,0,0-.34,0,.19.19,0,0,0-.14.22.77.77,0,0,0,.29.54h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S26.53,12.55,26.52,12.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.75,10.11a1,1,0,0,1-.18-.75.27.27,0,0,1,.28-.25.77.77,0,0,1,.34.12.68.68,0,0,0-.34-.06.19.19,0,0,0-.16.21.87.87,0,0,0,.24.56h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.18,0S29.75,10.12,29.75,10.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.91,9.05a1,1,0,0,1-.1-.76.3.3,0,0,1,.31-.22.78.78,0,0,1,.33.16.84.84,0,0,0-.33-.1.19.19,0,0,0-.18.19.81.81,0,0,0,.17.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.69,8.82a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.61.61,0,0,1,.31.18A.66.66,0,0,0,38,7.91a.18.18,0,0,0-.19.18.8.8,0,0,0,.14.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.47,8.64a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.2.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.18.85.85,0,0,0,.13.59h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.09,8.71a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.19.69.69,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.82.82,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.3,9.72a1,1,0,0,1,0-.76.29.29,0,0,1,.35-.16A.86.86,0,0,1,54,9a.81.81,0,0,0-.31-.15.18.18,0,0,0-.21.15.8.8,0,0,0,.06.61h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17-.05A0,0,0,0,1,53.3,9.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.27,12.05a1,1,0,0,1,.11-.76.29.29,0,0,1,.36-.13.78.78,0,0,1,.27.24.74.74,0,0,0-.29-.18.19.19,0,0,0-.23.13.8.8,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.48,19.17a1,1,0,0,1,.14-.76A.28.28,0,0,1,60,18.3a.63.63,0,0,1,.26.25.86.86,0,0,0-.28-.2.21.21,0,0,0-.24.13.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.89,23.35a1.07,1.07,0,0,1,.21-.75.31.31,0,0,1,.38-.08.77.77,0,0,1,.23.28.77.77,0,0,0-.26-.22.2.2,0,0,0-.25.1.82.82,0,0,0-.06.61h0a.12.12,0,0,1-.08.15.12.12,0,0,1-.16-.08A0,0,0,0,0,62.89,23.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.42,29.08a1,1,0,0,1,.22-.74.3.3,0,0,1,.37-.08.92.92,0,0,1,.24.28.77.77,0,0,0-.26-.22.2.2,0,0,0-.25.1.79.79,0,0,0-.07.6h0a.13.13,0,0,1-.24.08A0,0,0,0,0,64.42,29.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,33.87a1,1,0,0,1,.28-.72.3.3,0,0,1,.38,0,.9.9,0,0,1,.21.3.82.82,0,0,0-.24-.25.21.21,0,0,0-.26.08.81.81,0,0,0-.12.6h0a.13.13,0,0,1-.1.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.75,37a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.78.78,0,0,1,.16.33.88.88,0,0,0-.2-.29.2.2,0,0,0-.26,0A.83.83,0,0,0,66,37h0a.14.14,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.49,40.52a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.5,40.34a1,1,0,0,1,.53-.55.28.28,0,0,1,.37.1.73.73,0,0,1,.07.35.65.65,0,0,0-.12-.31.2.2,0,0,0-.27,0,.81.81,0,0,0-.34.5h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,67.5,40.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.64,39.38a1,1,0,0,1,.46-.62.29.29,0,0,1,.38.05.7.7,0,0,1,.12.34.74.74,0,0,0-.17-.3.2.2,0,0,0-.26,0,.84.84,0,0,0-.28.55h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.07,38.28a1,1,0,0,1,.36-.68.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.68.68,0,0,0-.2-.27.2.2,0,0,0-.27.05.82.82,0,0,0-.19.57h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.42,37.45a1,1,0,0,1,.27-.73.3.3,0,0,1,.38,0,1,1,0,0,1,.21.29.68.68,0,0,0-.25-.23.19.19,0,0,0-.25.08.86.86,0,0,0-.11.6h0a.13.13,0,0,1-.1.15.14.14,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.94,37.76a1,1,0,0,1,.21-.74.31.31,0,0,1,.38-.08.77.77,0,0,1,.23.28A.77.77,0,0,0,61.5,37a.2.2,0,0,0-.25.1.87.87,0,0,0-.07.61h0a.12.12,0,0,1-.08.15.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.61,36.56a1,1,0,0,1,.17-.75.29.29,0,0,1,.37-.1.78.78,0,0,1,.25.26.79.79,0,0,0-.28-.21.19.19,0,0,0-.23.12.81.81,0,0,0,0,.61h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.07S60.61,36.57,60.61,36.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.43,37.12a1,1,0,0,1,.14-.76.28.28,0,0,1,.36-.11.69.69,0,0,1,.26.25.69.69,0,0,0-.28-.2.19.19,0,0,0-.23.13.86.86,0,0,0,0,.61h0a.14.14,0,0,1-.07.16.11.11,0,0,1-.16-.07S59.43,37.13,59.43,37.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.69,35.56a1,1,0,0,1,.12-.76.29.29,0,0,1,.36-.12.78.78,0,0,1,.27.24.77.77,0,0,0-.29-.19.2.2,0,0,0-.23.14.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06S59.69,35.57,59.69,35.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.38,35.68a1,1,0,0,1,.09-.77.29.29,0,0,1,.35-.14.81.81,0,0,1,.28.23.79.79,0,0,0-.29-.17.21.21,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.23.11A0,0,0,0,0,58.38,35.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.1,34.22a1,1,0,0,1,.09-.77.28.28,0,0,1,.35-.14.79.79,0,0,1,.28.23.83.83,0,0,0-.3-.17.18.18,0,0,0-.22.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.23.11S59.11,34.23,59.1,34.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,34.29a1,1,0,0,1,.06-.76.3.3,0,0,1,.35-.16.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.11,0-.18.06-.22.15a.85.85,0,0,0,.06.61h0a.13.13,0,0,1-.22.12A0,0,0,0,1,58,34.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.86,34.13a1,1,0,0,1,0-.77.29.29,0,0,1,.35-.16.86.86,0,0,1,.29.21.81.81,0,0,0-.31-.15.18.18,0,0,0-.21.15.85.85,0,0,0,.06.61h0a.14.14,0,0,1-.05.18.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.78,32.71a1,1,0,0,1,0-.77.3.3,0,0,1,.35-.16.88.88,0,0,1,.28.22.87.87,0,0,0-.3-.16.21.21,0,0,0-.22.16.82.82,0,0,0,.06.6h0a.13.13,0,0,1,0,.17.11.11,0,0,1-.17-.05S57.78,32.72,57.78,32.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.7,33a1,1,0,0,1,0-.76.3.3,0,0,1,.35-.17.89.89,0,0,1,.29.22.84.84,0,0,0-.31-.16.2.2,0,0,0-.21.16.77.77,0,0,0,.07.6h0a.13.13,0,0,1-.22.13S56.7,33,56.7,33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.72,32a1,1,0,0,1,0-.77.31.31,0,0,1,.35-.17,1.06,1.06,0,0,1,.29.22.69.69,0,0,0-.31-.15.18.18,0,0,0-.21.15.85.85,0,0,0,.07.61h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.87,31.37a1,1,0,0,1,.05-.77.29.29,0,0,1,.34-.16.72.72,0,0,1,.29.22.73.73,0,0,0-.3-.16.2.2,0,0,0-.22.15.85.85,0,0,0,.06.61h0a.13.13,0,1,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.9,30.92a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.16.64.64,0,0,1,.29.22.73.73,0,0,0-.3-.16c-.11,0-.18.06-.22.16a.83.83,0,0,0,.07.6h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.06,32.77a1,1,0,0,1,.07-.77.29.29,0,0,1,.35-.15.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.11,0-.18.05-.22.15a.85.85,0,0,0,0,.61h0a.13.13,0,1,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.77,35.73A1,1,0,0,1,62,35a.29.29,0,0,1,.37-.1.88.88,0,0,1,.24.27A.79.79,0,0,0,62.3,35a.19.19,0,0,0-.24.11.81.81,0,0,0-.05.61h0a.12.12,0,0,1-.08.16.14.14,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,41a1,1,0,0,1,.47-.61.28.28,0,0,1,.37.06.7.7,0,0,1,.12.34.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.13.13,0,0,1-.26,0S65,41.05,65,41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.78,44.3a1,1,0,0,1,.58-.5.28.28,0,0,1,.35.13.75.75,0,0,1,.05.36.85.85,0,0,0-.1-.33.19.19,0,0,0-.26,0,.82.82,0,0,0-.39.47h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.15A0,0,0,0,1,66.78,44.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,46.82a1,1,0,0,1,.62-.46.29.29,0,0,1,.34.16.75.75,0,0,1,0,.36.72.72,0,0,0-.07-.33.2.2,0,0,0-.26-.07.82.82,0,0,0-.42.44h0a.14.14,0,0,1-.17.08.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.49,49.54a1,1,0,0,1,.64-.42.3.3,0,0,1,.34.18.72.72,0,0,1,0,.36.72.72,0,0,0,0-.33.2.2,0,0,0-.25-.09.81.81,0,0,0-.45.42h0a.12.12,0,0,1-.16.06.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.41,51.37a1,1,0,0,1,.65-.42.29.29,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.84.84,0,0,0-.45.42h0a.11.11,0,0,1-.16.07.14.14,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.45,53.1a1,1,0,0,1,.65-.41.28.28,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.83.83,0,0,0-.45.41h0a.12.12,0,0,1-.16.06.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.32,54.71A1.07,1.07,0,0,1,69,54.3a.3.3,0,0,1,.33.19.72.72,0,0,1,0,.36.78.78,0,0,0,0-.34.19.19,0,0,0-.25-.08.83.83,0,0,0-.46.4h0a.13.13,0,1,1-.23-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.33,54.92a1,1,0,0,1,.66-.41.28.28,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.84.84,0,0,0-.46.41h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.61,53.67a1,1,0,0,1,.65-.41.3.3,0,0,1,.33.19.72.72,0,0,1,0,.36.88.88,0,0,0,0-.33.19.19,0,0,0-.25-.09.77.77,0,0,0-.46.4h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.6,51.63a1,1,0,0,1,.65-.41.28.28,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.85.85,0,0,0-.46.41h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.43,43.54a1,1,0,0,1,.63-.45.3.3,0,0,1,.34.17.87.87,0,0,1,0,.36.7.7,0,0,0-.07-.33.19.19,0,0,0-.25-.08.9.9,0,0,0-.44.44h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.17A0,0,0,0,1,70.43,43.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,39.48a1,1,0,0,1,.6-.49.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36.7.7,0,0,0-.09-.33.19.19,0,0,0-.26-.06.89.89,0,0,0-.4.46h0A.13.13,0,1,1,71,39.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.26,32.52a1,1,0,0,1,.53-.55.29.29,0,0,1,.37.1.85.85,0,0,1,.08.35.9.9,0,0,0-.13-.32.21.21,0,0,0-.27,0,.84.84,0,0,0-.34.51h0a.14.14,0,0,1-.15.1.13.13,0,0,1-.1-.15A0,0,0,0,1,71.26,32.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.25,28.6a1,1,0,0,1,.52-.58.3.3,0,0,1,.37.09.86.86,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.85.85,0,0,0-.32.52h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.42,25.61a1,1,0,0,1,.53-.56.29.29,0,0,1,.37.09.74.74,0,0,1,.08.35.79.79,0,0,0-.13-.31.19.19,0,0,0-.27,0,.82.82,0,0,0-.33.51h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.92,22.29a1,1,0,0,1,.56-.53.29.29,0,0,1,.36.11.76.76,0,0,1,.06.36.64.64,0,0,0-.11-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.49h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.1-.15S71.92,22.3,71.92,22.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.25,17.81a1,1,0,0,1,.59-.49.3.3,0,0,1,.36.14.75.75,0,0,1,0,.36.63.63,0,0,0-.09-.33.2.2,0,0,0-.26,0,.89.89,0,0,0-.4.46h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16A0,0,0,0,0,72.25,17.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.86,12.94a1,1,0,0,1,.61-.47.3.3,0,0,1,.35.15.75.75,0,0,1,0,.36.76.76,0,0,0-.08-.33.2.2,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.13.13,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.34,11.44a1,1,0,0,1,.6-.48.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.74.74,0,0,0-.09-.33.19.19,0,0,0-.25-.06.84.84,0,0,0-.41.46h0a.12.12,0,0,1-.15.08.12.12,0,0,1-.08-.16A0,0,0,0,1,71.34,11.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.49,10a1,1,0,0,1,.6-.48.29.29,0,0,1,.35.15.89.89,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.8.8,0,0,0-.41.46h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.27,8.79a1,1,0,0,1,.59-.5.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.8.8,0,0,0-.4.47h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16S71.26,8.79,71.27,8.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.44,8.43A1,1,0,0,1,70,7.91a.3.3,0,0,1,.36.13.76.76,0,0,1,.06.36.72.72,0,0,0-.11-.33.19.19,0,0,0-.26,0,.85.85,0,0,0-.38.48h0a.12.12,0,0,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.37,7.66a1,1,0,0,1,.53-.56.3.3,0,0,1,.37.1.89.89,0,0,1,.08.35.79.79,0,0,0-.13-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.34.51h0a.13.13,0,0,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.29,6.85a1,1,0,0,1,.46-.61.3.3,0,0,1,.38,0,.91.91,0,0,1,.12.35.74.74,0,0,0-.17-.3.19.19,0,0,0-.26,0,.88.88,0,0,0-.29.54h0A.12.12,0,0,1,65.4,7a.14.14,0,0,1-.12-.14S65.28,6.85,65.29,6.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.06,6a1,1,0,0,1,.39-.66.3.3,0,0,1,.38,0,.92.92,0,0,1,.16.33.75.75,0,0,0-.2-.28.19.19,0,0,0-.26,0,.79.79,0,0,0-.22.57h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.28,5.42a1,1,0,0,1,.28-.72.28.28,0,0,1,.38,0,1,1,0,0,1,.21.29.78.78,0,0,0-.24-.24.21.21,0,0,0-.26.08.83.83,0,0,0-.13.59h0a.14.14,0,0,1-.1.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.82,5.07A1,1,0,0,1,58,4.33a.29.29,0,0,1,.37-.09.66.66,0,0,1,.24.26.86.86,0,0,0-.26-.21.2.2,0,0,0-.25.11.87.87,0,0,0,0,.61h0a.14.14,0,0,1-.08.16.13.13,0,0,1-.16-.08A0,0,0,0,1,57.82,5.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.29,5.36a1,1,0,0,1,0-.77.28.28,0,0,1,.34-.16.7.7,0,0,1,.29.21.71.71,0,0,0-.3-.15.2.2,0,0,0-.22.15.85.85,0,0,0,.07.61h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.44,5.65a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.81.81,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.13.13,0,0,1-.21.15A0,0,0,0,1,49.44,5.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.38,5.72a1,1,0,0,1,0-.77c0-.12.2-.24.33-.19a.79.79,0,0,1,.31.18.78.78,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.12.12,0,0,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.67,6a1,1,0,0,1,0-.77A.29.29,0,0,1,43,5a.84.84,0,0,1,.31.19A.87.87,0,0,0,43,5.05a.2.2,0,0,0-.2.18.85.85,0,0,0,.12.59h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,42.67,6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.12,7a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.2.89.89,0,0,1,.31.19.7.7,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,8.11A1,1,0,0,1,36,7.35a.29.29,0,0,1,.32-.21.76.76,0,0,1,.32.17.86.86,0,0,0-.33-.11.19.19,0,0,0-.19.18.85.85,0,0,0,.15.59h0a.13.13,0,1,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.38,9.83a1,1,0,0,1-.14-.76.28.28,0,0,1,.29-.24.75.75,0,0,1,.33.14.76.76,0,0,0-.33-.08.2.2,0,0,0-.17.2.84.84,0,0,0,.21.58h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.24,13.1a1,1,0,0,1-.29-.72.29.29,0,0,1,.25-.29.81.81,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.83.83,0,0,0,.32.52h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.12,16.65a1,1,0,0,1-.28-.72.28.28,0,0,1,.25-.29.82.82,0,0,1,.35.08.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.78.78,0,0,0,.31.52h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.4,18a1,1,0,0,1-.25-.73.29.29,0,0,1,.26-.28,1,1,0,0,1,.35.09,1,1,0,0,0-.34,0,.22.22,0,0,0-.15.23.9.9,0,0,0,.29.54h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.61,19.29a1,1,0,0,1-.26-.73.3.3,0,0,1,.26-.28.7.7,0,0,1,.35.09.68.68,0,0,0-.34,0,.2.2,0,0,0-.14.23.85.85,0,0,0,.29.53h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S23.62,19.29,23.61,19.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.33,26.16a1,1,0,0,1-.39-.67.28.28,0,0,1,.2-.32.63.63,0,0,1,.36,0,.76.76,0,0,0-.34,0,.21.21,0,0,0-.1.25.82.82,0,0,0,.4.47h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.44,33.7a1,1,0,0,1-.46-.61.29.29,0,0,1,.15-.35.89.89,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.86.86,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.71,37.43a1,1,0,0,1-.5-.59.29.29,0,0,1,.14-.36.86.86,0,0,1,.35,0,.71.71,0,0,0-.32.1.18.18,0,0,0-.05.26.83.83,0,0,0,.46.39h0a.13.13,0,1,1-.07.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.35,39.9a1,1,0,0,1-.53-.56.29.29,0,0,1,.12-.36.7.7,0,0,1,.35-.06A.72.72,0,0,0,18,39a.2.2,0,0,0,0,.26.88.88,0,0,0,.49.37h0a.13.13,0,1,1-.05.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,43.18a1,1,0,0,1-.55-.54.28.28,0,0,1,.11-.36.72.72,0,0,1,.35-.08.9.9,0,0,0-.32.13.2.2,0,0,0,0,.26.81.81,0,0,0,.51.35h0a.12.12,0,0,1,.1.14.12.12,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.07,46.21a1,1,0,0,1-.58-.51.29.29,0,0,1,.08-.37.76.76,0,0,1,.35-.09.8.8,0,0,0-.31.14.2.2,0,0,0,0,.27.85.85,0,0,0,.52.32h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,48.13a1,1,0,0,1-.6-.48.3.3,0,0,1,.08-.38.85.85,0,0,1,.34-.11.72.72,0,0,0-.3.16.19.19,0,0,0,0,.26.84.84,0,0,0,.54.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.83,49.39a1,1,0,0,1-.6-.48.3.3,0,0,1,.07-.38.89.89,0,0,1,.35-.11.83.83,0,0,0-.31.16.21.21,0,0,0,0,.27.87.87,0,0,0,.53.3h0a.13.13,0,0,1,.12.13.14.14,0,0,1-.14.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.75,50.67a1,1,0,0,1-.6-.49.29.29,0,0,1,.07-.37.76.76,0,0,1,.35-.11.83.83,0,0,0-.31.16.2.2,0,0,0,0,.26.87.87,0,0,0,.54.3h0a.14.14,0,0,1,.12.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.83,50.81a1,1,0,0,1-.61-.47.29.29,0,0,1,.06-.38.82.82,0,0,1,.34-.12.87.87,0,0,0-.3.17.19.19,0,0,0,0,.26.88.88,0,0,0,.54.29h0a.13.13,0,0,1,.12.13.13.13,0,0,1-.14.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,47.74a1,1,0,0,1-.58-.51.29.29,0,0,1,.08-.37.76.76,0,0,1,.35-.09.68.68,0,0,0-.31.14.19.19,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.86,44.09a1,1,0,0,1-.54-.55.29.29,0,0,1,.11-.37.87.87,0,0,1,.35-.07.75.75,0,0,0-.32.13.2.2,0,0,0,0,.26.89.89,0,0,0,.5.36h0a.12.12,0,1,1-.06.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.8,41.3a1,1,0,0,1-.51-.58.29.29,0,0,1,.13-.35.75.75,0,0,1,.36,0,.67.67,0,0,0-.32.1.18.18,0,0,0-.05.26.79.79,0,0,0,.47.38h0a.12.12,0,0,1,.09.15.12.12,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.27,35.92a1,1,0,0,1-.42-.65.29.29,0,0,1,.18-.33.73.73,0,0,1,.36,0,.61.61,0,0,0-.33.06.2.2,0,0,0-.09.25.88.88,0,0,0,.42.45h0a.13.13,0,0,1,.06.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,30a1,1,0,0,1-.33-.69.27.27,0,0,1,.22-.31.59.59,0,0,1,.35.05.65.65,0,0,0-.34,0,.2.2,0,0,0-.11.24.82.82,0,0,0,.35.49h0a.13.13,0,0,1,.05.17A.13.13,0,0,1,21,30Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.87,24.26a1,1,0,0,1-.25-.73.3.3,0,0,1,.26-.28.82.82,0,0,1,.35.09.67.67,0,0,0-.34,0,.19.19,0,0,0-.14.22.77.77,0,0,0,.29.54h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.76,22.89a1,1,0,0,1-.19-.74.29.29,0,0,1,.27-.26.7.7,0,0,1,.35.12.7.7,0,0,0-.34-.06.2.2,0,0,0-.16.21.79.79,0,0,0,.25.56h0a.13.13,0,0,1,0,.18.11.11,0,0,1-.17,0A0,0,0,0,1,24.76,22.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.45,21.93a1,1,0,0,1-.16-.75.28.28,0,0,1,.29-.25.74.74,0,0,1,.33.13.78.78,0,0,0-.33-.07c-.11,0-.16.1-.17.21a.86.86,0,0,0,.23.56h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.47,21.5a1,1,0,0,1-.13-.76.28.28,0,0,1,.3-.23.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08c-.11,0-.17.09-.18.2a.86.86,0,0,0,.2.57h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S28.47,21.51,28.47,21.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.07,14.15a1,1,0,0,1-.09-.77.3.3,0,0,1,.31-.22.8.8,0,0,1,.33.17.86.86,0,0,0-.33-.11.21.21,0,0,0-.19.19.86.86,0,0,0,.17.59h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.73,12.94a1,1,0,0,1,0-.77A.3.3,0,0,1,39,12a.79.79,0,0,1,.31.18A.76.76,0,0,0,39,12c-.11,0-.17.08-.19.18a.81.81,0,0,0,.14.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0S38.74,12.94,38.73,12.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.38,12.87a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.79.79,0,0,1,.31.18.66.66,0,0,0-.32-.12.2.2,0,0,0-.2.18.86.86,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.05,13.41a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2.84.84,0,0,1,.31.19.87.87,0,0,0-.32-.13.2.2,0,0,0-.2.18.83.83,0,0,0,.13.6h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.21,14.31a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.81.81,0,0,1,.31.19.76.76,0,0,0-.31-.13.22.22,0,0,0-.21.17.88.88,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.58,16.42a1.05,1.05,0,0,1,0-.78.29.29,0,0,1,.34-.17.79.79,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.16.9.9,0,0,0,.09.61h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0S52.59,16.42,52.58,16.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.11,19.74a1,1,0,0,1,.06-.77.3.3,0,0,1,.35-.16.88.88,0,0,1,.28.22.87.87,0,0,0-.3-.16c-.11,0-.18.06-.22.15a.85.85,0,0,0,.06.61h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S56.12,19.75,56.11,19.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.12,21.18a1,1,0,0,1,.09-.76.27.27,0,0,1,.35-.14.68.68,0,0,1,.27.23.72.72,0,0,0-.29-.17.18.18,0,0,0-.22.14.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.17.12.12,0,0,1-.16-.05A0,0,0,0,1,58.12,21.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.17,22.3a1,1,0,0,1,.11-.76.29.29,0,0,1,.36-.14,1.05,1.05,0,0,1,.27.24,1,1,0,0,0-.29-.18.21.21,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.23.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.4,23.47a1,1,0,0,1,.13-.76.28.28,0,0,1,.36-.12.67.67,0,0,1,.26.24.77.77,0,0,0-.28-.19.19.19,0,0,0-.23.13.76.76,0,0,0,0,.61h0a.13.13,0,1,1-.23.1A0,0,0,0,1,60.4,23.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.14,24.54a1,1,0,0,1,.21-.74.3.3,0,0,1,.37-.08A.88.88,0,0,1,64,24a.82.82,0,0,0-.27-.22.19.19,0,0,0-.24.11.84.84,0,0,0-.07.6h0a.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.08A0,0,0,0,1,63.14,24.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.55,26.13a1,1,0,0,1,.25-.73.28.28,0,0,1,.37-.06.7.7,0,0,1,.22.29.68.68,0,0,0-.25-.23.19.19,0,0,0-.25.09.8.8,0,0,0-.09.6h0a.12.12,0,0,1-.09.15.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.81,27.83a1,1,0,0,1,.28-.72.29.29,0,0,1,.38-.05.86.86,0,0,1,.21.3.78.78,0,0,0-.24-.24.19.19,0,0,0-.25.08.79.79,0,0,0-.13.59h0A.12.12,0,0,1,66,28a.12.12,0,0,1-.14-.1S65.81,27.84,65.81,27.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.34,28.84a1,1,0,0,1,.29-.71.28.28,0,0,1,.38,0,.75.75,0,0,1,.2.3.8.8,0,0,0-.23-.25.2.2,0,0,0-.26.07.82.82,0,0,0-.13.6h0a.13.13,0,0,1-.1.15.13.13,0,0,1-.15-.11Z"/>
      </g>}
      {r_type_hair >= 5 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.19,60.29a1,1,0,0,1-.33-.7.29.29,0,0,1,.23-.31.74.74,0,0,1,.36.06.65.65,0,0,0-.34,0,.19.19,0,0,0-.12.24.83.83,0,0,0,.34.5h0a.12.12,0,1,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.19,60.11a1,1,0,0,1-.3-.71.28.28,0,0,1,.23-.3.73.73,0,0,1,.36.07.79.79,0,0,0-.34,0,.2.2,0,0,0-.13.23.82.82,0,0,0,.33.52h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.58,58.89a1.07,1.07,0,0,1-.25-.73c0-.14.12-.29.26-.28a.82.82,0,0,1,.35.09.66.66,0,0,0-.34,0,.18.18,0,0,0-.14.22.77.77,0,0,0,.29.54h0a.12.12,0,0,1-.15.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.9,61.37a1,1,0,0,1-.39-.67.28.28,0,0,1,.2-.32.72.72,0,0,1,.36,0,.94.94,0,0,0-.34,0,.21.21,0,0,0-.1.25.84.84,0,0,0,.39.47h0a.12.12,0,1,1-.11.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.56,58.17a1,1,0,0,1-.22-.74.29.29,0,0,1,.27-.27.85.85,0,0,1,.35.11.8.8,0,0,0-.34-.05.19.19,0,0,0-.15.22.8.8,0,0,0,.26.55h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,59.34a1,1,0,0,1-.28-.71.28.28,0,0,1,.24-.29.73.73,0,0,1,.36.07.83.83,0,0,0-.35,0,.2.2,0,0,0-.13.23.86.86,0,0,0,.32.52h0a.12.12,0,1,1-.14.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.57,58.1a1,1,0,0,1-.22-.74.29.29,0,0,1,.27-.27.9.9,0,0,1,.34.1.8.8,0,0,0-.34,0,.2.2,0,0,0-.15.22.85.85,0,0,0,.27.55h0a.13.13,0,1,1-.16.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.6,58.52a1,1,0,0,1-.24-.73.3.3,0,0,1,.26-.28.79.79,0,0,1,.35.1.8.8,0,0,0-.34,0,.19.19,0,0,0-.14.22.84.84,0,0,0,.28.55h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.85,61.32a1,1,0,0,1-.37-.68.28.28,0,0,1,.2-.32.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.1.25.82.82,0,0,0,.38.47h0a.12.12,0,0,1,.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.58,59.24a1,1,0,0,1-.27-.72.29.29,0,0,1,.25-.29.82.82,0,0,1,.35.08.8.8,0,0,0-.34,0,.2.2,0,0,0-.14.23.83.83,0,0,0,.31.53h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.25,60.18a1,1,0,0,1-.32-.7.3.3,0,0,1,.23-.31.68.68,0,0,1,.35.06.78.78,0,0,0-.34,0,.22.22,0,0,0-.12.24.84.84,0,0,0,.35.5h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.4,60.36a1,1,0,0,1-.34-.69.29.29,0,0,1,.23-.31.81.81,0,0,1,.35,0,.78.78,0,0,0-.34,0,.2.2,0,0,0-.11.24.87.87,0,0,0,.35.5h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.56,59.24a1,1,0,0,1-.25-.73.28.28,0,0,1,.25-.28,1,1,0,0,1,.35.09.78.78,0,0,0-.34,0,.18.18,0,0,0-.14.22.86.86,0,0,0,.3.54h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.92,61.26a1,1,0,0,1-.39-.67.28.28,0,0,1,.2-.32.86.86,0,0,1,.36,0,.78.78,0,0,0-.34,0,.18.18,0,0,0-.09.24.84.84,0,0,0,.38.48h0a.13.13,0,0,1-.11.23S21.92,61.27,21.92,61.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.94,60.79a1,1,0,0,1-.34-.69.28.28,0,0,1,.22-.31.73.73,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.83.83,0,0,0,.36.49h0a.12.12,0,1,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.35,59.66a1,1,0,0,1-.29-.72.29.29,0,0,1,.24-.29,1,1,0,0,1,.35.07.78.78,0,0,0-.34,0,.2.2,0,0,0-.13.23.81.81,0,0,0,.32.52h0a.13.13,0,0,1-.14.21S19.35,59.67,19.35,59.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.51,58.48a1,1,0,0,1-.24-.73.29.29,0,0,1,.26-.28.83.83,0,0,1,.35.1.8.8,0,0,0-.34,0,.21.21,0,0,0-.15.22.85.85,0,0,0,.29.55h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0S21.51,58.49,21.51,58.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.62,57.71a1,1,0,0,1-.21-.74.29.29,0,0,1,.27-.27.85.85,0,0,1,.35.11.83.83,0,0,0-.34-.05.21.21,0,0,0-.16.22.89.89,0,0,0,.26.55h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.78,57a1.06,1.06,0,0,1-.2-.74c0-.13.14-.28.28-.26a.76.76,0,0,1,.34.11.78.78,0,0,0-.33,0,.2.2,0,0,0-.16.21.86.86,0,0,0,.25.56h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,56.28a1,1,0,0,1-.21-.74.3.3,0,0,1,.28-.26.76.76,0,0,1,.34.11.8.8,0,0,0-.34,0,.19.19,0,0,0-.15.21.8.8,0,0,0,.26.56h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,55.45a1,1,0,0,1-.22-.74.3.3,0,0,1,.27-.27.76.76,0,0,1,.34.11.67.67,0,0,0-.34,0,.19.19,0,0,0-.15.21.83.83,0,0,0,.27.55h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.65,55.13a1,1,0,0,1-.23-.74.29.29,0,0,1,.27-.27.71.71,0,0,1,.35.1.81.81,0,0,0-.34,0,.19.19,0,0,0-.15.22.81.81,0,0,0,.27.54h0a.13.13,0,0,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.07,54.11a1,1,0,0,1-.25-.73.3.3,0,0,1,.26-.28.83.83,0,0,1,.35.1.8.8,0,0,0-.34,0,.2.2,0,0,0-.14.23.85.85,0,0,0,.29.54h0a.13.13,0,1,1-.16.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.5,53.42a1,1,0,0,1-.26-.73.3.3,0,0,1,.26-.28.7.7,0,0,1,.35.09.66.66,0,0,0-.34,0,.18.18,0,0,0-.14.22.81.81,0,0,0,.3.54h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.08,52.09a1,1,0,0,1-.28-.71.28.28,0,0,1,.24-.29.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.86.86,0,0,0,.32.52h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.83,51.76a1,1,0,0,1-.28-.72.28.28,0,0,1,.24-.29.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.83.83,0,0,0,.32.52h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.23,50.67a.93.93,0,0,1-.31-.7.28.28,0,0,1,.23-.3.68.68,0,0,1,.35.06.65.65,0,0,0-.34,0A.19.19,0,0,0,20,50a.77.77,0,0,0,.34.51h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.63,49.53a.92.92,0,0,1-.34-.69.28.28,0,0,1,.22-.31.81.81,0,0,1,.35,0,.65.65,0,0,0-.34,0,.2.2,0,0,0-.11.24.84.84,0,0,0,.36.5h0a.14.14,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.22,49.23a1,1,0,0,1-.34-.69.29.29,0,0,1,.22-.31.85.85,0,0,1,.36.05.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.83.83,0,0,0,.35.5h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.17,0S21.22,49.24,21.22,49.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.6,48a1,1,0,0,1-.37-.68.3.3,0,0,1,.21-.32.85.85,0,0,1,.36,0,.78.78,0,0,0-.34,0,.18.18,0,0,0-.1.24.79.79,0,0,0,.37.48h0a.13.13,0,0,1-.12.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.65,48.29a1,1,0,0,1-.34-.69.29.29,0,0,1,.22-.31.85.85,0,0,1,.36.05.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.84.84,0,0,0,.36.5h0a.13.13,0,0,1-.12.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.49,46.76a1,1,0,0,1-.39-.67.29.29,0,0,1,.2-.32.72.72,0,0,1,.36,0,.78.78,0,0,0-.34,0,.18.18,0,0,0-.09.24.79.79,0,0,0,.38.47h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.68,46.74a1,1,0,0,1-.37-.68.29.29,0,0,1,.2-.32.85.85,0,0,1,.36,0,1,1,0,0,0-.34,0,.21.21,0,0,0-.1.25.87.87,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.9,46.42a1,1,0,0,1-.37-.68.3.3,0,0,1,.21-.32.85.85,0,0,1,.36,0,.94.94,0,0,0-.34,0,.2.2,0,0,0-.1.25.83.83,0,0,0,.38.48h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.3,45.32a1,1,0,0,1-.39-.66.28.28,0,0,1,.19-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.83.83,0,0,0,.4.46h0a.13.13,0,0,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.58,45.3a1,1,0,0,1-.39-.66.3.3,0,0,1,.2-.33.88.88,0,0,1,.36,0,.94.94,0,0,0-.34,0,.21.21,0,0,0-.1.25.82.82,0,0,0,.4.47h0a.13.13,0,0,1-.12.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.83,44.32a1,1,0,0,1-.41-.65.3.3,0,0,1,.18-.34.86.86,0,0,1,.36,0,.72.72,0,0,0-.33,0,.2.2,0,0,0-.09.25.81.81,0,0,0,.41.46h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.82,44.81a1,1,0,0,1-.39-.67.28.28,0,0,1,.2-.32.63.63,0,0,1,.36,0,.64.64,0,0,0-.34,0,.2.2,0,0,0-.1.24.82.82,0,0,0,.4.47h0a.12.12,0,0,1,.05.16.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.92,43.05a1,1,0,0,1-.43-.64.3.3,0,0,1,.17-.34.73.73,0,0,1,.36,0,.72.72,0,0,0-.33.06.19.19,0,0,0-.08.25.82.82,0,0,0,.42.44h0a.13.13,0,0,1,.07.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.6,43.57a1,1,0,0,1-.41-.66.29.29,0,0,1,.19-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.18.18,0,0,0-.08.25.87.87,0,0,0,.4.46h0a.12.12,0,0,1,.06.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.06,42.52a1,1,0,0,1-.43-.64.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.93.93,0,0,0-.34.06.21.21,0,0,0-.08.26.84.84,0,0,0,.43.44h0a.12.12,0,0,1,.07.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.07,41.62a1,1,0,0,1-.45-.62.3.3,0,0,1,.17-.35.87.87,0,0,1,.36,0,.77.77,0,0,0-.34.07.21.21,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.78,42.83a1,1,0,0,1-.41-.65.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.81.81,0,0,0,.41.46h0a.13.13,0,0,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,41.72a1,1,0,0,1-.44-.64.3.3,0,0,1,.17-.34.77.77,0,0,1,.37,0,.65.65,0,0,0-.34.06.19.19,0,0,0-.08.25.84.84,0,0,0,.43.44h0a.13.13,0,0,1-.1.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.58,42a1,1,0,0,1-.42-.64.31.31,0,0,1,.18-.34.77.77,0,0,1,.37,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.76.76,0,0,0,.41.45h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.41,40.52A1,1,0,0,1,25,39.9a.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.77.77,0,0,0-.34.08.2.2,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.17.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.6,39.51a1,1,0,0,1-.48-.61.3.3,0,0,1,.16-.35.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.84.84,0,0,0,.46.41h0a.13.13,0,0,1,.08.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.32,40.49a1,1,0,0,1-.44-.63.28.28,0,0,1,.17-.34.87.87,0,0,1,.36,0,.77.77,0,0,0-.34.07.18.18,0,0,0-.07.25.84.84,0,0,0,.43.44h0a.13.13,0,0,1-.1.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.31,39.54a1,1,0,0,1-.46-.61.29.29,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.18.18,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.12.12,0,0,1,.08.16.14.14,0,0,1-.17.08S26.32,39.55,26.31,39.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.62,39.23a1,1,0,0,1-.45-.63.29.29,0,0,1,.16-.34.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.18.18,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.14.14,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.54,38.36a1,1,0,0,1-.47-.61.3.3,0,0,1,.15-.35.73.73,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.86.86,0,0,0,.45.41h0a.14.14,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.73,37.36a1.06,1.06,0,0,1-.49-.6.28.28,0,0,1,.14-.35.74.74,0,0,1,.36,0,.9.9,0,0,0-.33.09.2.2,0,0,0-.05.26.76.76,0,0,0,.46.4h0a.12.12,0,0,1,.08.15.13.13,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.78,36.77a1,1,0,0,1-.48-.6.29.29,0,0,1,.14-.35.75.75,0,0,1,.36,0,.74.74,0,0,0-.33.09.19.19,0,0,0,0,.26.82.82,0,0,0,.46.4h0a.13.13,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.26,37.42a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.2.2,0,0,0-.07.26.86.86,0,0,0,.45.41h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.36,37.47a1,1,0,0,1-.46-.62.28.28,0,0,1,.16-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.19.19,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1-.09.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.12,36.15a1,1,0,0,1-.48-.6.3.3,0,0,1,.15-.35.75.75,0,0,1,.36,0,.74.74,0,0,0-.33.09.2.2,0,0,0-.06.26.85.85,0,0,0,.46.4h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.5,35.08a1.07,1.07,0,0,1-.5-.59.29.29,0,0,1,.15-.36.84.84,0,0,1,.35,0,.71.71,0,0,0-.32.1.19.19,0,0,0-.06.26.82.82,0,0,0,.47.39h0a.12.12,0,0,1,.08.15.13.13,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.54,35.87a1,1,0,0,1-.48-.61.3.3,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.25.87.87,0,0,0,.45.42h0a.13.13,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.94,34.65a1.06,1.06,0,0,1-.49-.6.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.85.85,0,0,0-.33.09.2.2,0,0,0-.06.26.85.85,0,0,0,.46.4h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,35.28a1,1,0,0,1-.45-.62.28.28,0,0,1,.16-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.2.2,0,0,0-.06.26.79.79,0,0,0,.44.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.39,34.17a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.75.75,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.84.84,0,0,0,.45.42h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.71,32.87a1.06,1.06,0,0,1-.49-.6.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.85.85,0,0,0-.33.09.19.19,0,0,0-.06.26.81.81,0,0,0,.46.4h0a.12.12,0,0,1,.08.15.13.13,0,0,1-.16.09S29.72,32.87,29.71,32.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.17,33.25a1,1,0,0,1-.47-.62.28.28,0,0,1,.16-.34.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.84.84,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.38,33.21a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.87.87,0,0,1,.36,0,.77.77,0,0,0-.34.07.19.19,0,0,0-.07.25.82.82,0,0,0,.43.43h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.39,31.88a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.88.88,0,0,1,.36,0,1.21,1.21,0,0,0-.34.08.2.2,0,0,0-.06.26.84.84,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.27,30.78a1,1,0,0,1-.49-.6.29.29,0,0,1,.14-.35.75.75,0,0,1,.36,0,.66.66,0,0,0-.33.09.2.2,0,0,0-.05.26.89.89,0,0,0,.46.4h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.61,30.65a1,1,0,0,1-.47-.61.29.29,0,0,1,.16-.35.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.86.86,0,0,0,.45.41h0a.12.12,0,1,1-.09.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.65,29.57a1.06,1.06,0,0,1-.49-.6.28.28,0,0,1,.14-.35.76.76,0,0,1,.36,0,.85.85,0,0,0-.32.09.19.19,0,0,0-.06.26.78.78,0,0,0,.46.4h0a.12.12,0,0,1,.08.15.13.13,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.85,30.46a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.87.87,0,0,1,.36,0,.77.77,0,0,0-.34.07.2.2,0,0,0-.07.25.82.82,0,0,0,.43.43h0a.11.11,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.56,28.39a1,1,0,0,1-.49-.6.29.29,0,0,1,.14-.35.89.89,0,0,1,.36,0,.9.9,0,0,0-.33.09.2.2,0,0,0,0,.26.81.81,0,0,0,.46.4h0a.12.12,0,0,1,.08.15.12.12,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.88,29.12a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.25.87.87,0,0,0,.45.42h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.14,30.42a1,1,0,0,1-.42-.64.31.31,0,0,1,.18-.34.92.92,0,0,1,.37,0,.93.93,0,0,0-.34.06.18.18,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.13.13,0,0,1,.06.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.76,28a1,1,0,0,1-.47-.6.29.29,0,0,1,.15-.35.76.76,0,0,1,.36,0,.76.76,0,0,0-.33.08.2.2,0,0,0-.06.26.82.82,0,0,0,.45.41h0A.13.13,0,0,1,32,28a.14.14,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.9,29a1.05,1.05,0,0,1-.45-.63.3.3,0,0,1,.17-.34A.63.63,0,0,1,33,28a.72.72,0,0,0-.33.06.19.19,0,0,0-.07.26.82.82,0,0,0,.43.43h0a.14.14,0,0,1,.07.17.14.14,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.4,26.88a1,1,0,0,1-.48-.6.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.06.26.82.82,0,0,0,.45.41h0a.13.13,0,0,1,.08.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,29.31a1,1,0,0,1-.43-.64.32.32,0,0,1,.18-.34.92.92,0,0,1,.37,0,.93.93,0,0,0-.34.06.19.19,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.14.14,0,0,1,.07.17.15.15,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.47,27a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.34.78.78,0,0,1,.37,0,.94.94,0,0,0-.34.07.21.21,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.44,27.63A1,1,0,0,1,33,27a.29.29,0,0,1,.18-.34.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.06.19.19,0,0,0-.08.25.84.84,0,0,0,.43.44h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.5,28.23a1,1,0,0,1-.42-.65.31.31,0,0,1,.19-.34.86.86,0,0,1,.36,0,.78.78,0,0,0-.34.05.19.19,0,0,0-.08.25.83.83,0,0,0,.4.46h0a.14.14,0,0,1,.07.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.69,25.42a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.22.22,0,0,0-.07.26.88.88,0,0,0,.44.43h0a.14.14,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.69,25.94a1,1,0,0,1-.43-.64.28.28,0,0,1,.17-.33.77.77,0,0,1,.37,0,.76.76,0,0,0-.34.05.21.21,0,0,0-.08.26.82.82,0,0,0,.42.44h0a.12.12,0,0,1,.06.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.42,24.18a1,1,0,0,1-.43-.63.29.29,0,0,1,.18-.34.73.73,0,0,1,.36,0,.65.65,0,0,0-.34.06.19.19,0,0,0-.08.25.86.86,0,0,0,.42.44h0a.13.13,0,0,1,.07.17.14.14,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.76,26.44a1,1,0,0,1-.4-.66.28.28,0,0,1,.19-.33.86.86,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.83.83,0,0,0,.4.46h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.21,24.84a1,1,0,0,1-.41-.65.29.29,0,0,1,.19-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.05.2.2,0,0,0-.09.25.87.87,0,0,0,.41.45h0a.13.13,0,0,1,.06.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.84,27.54a1,1,0,0,1-.37-.67.29.29,0,0,1,.2-.32.72.72,0,0,1,.36,0,.66.66,0,0,0-.34,0,.19.19,0,0,0-.1.24.84.84,0,0,0,.38.48h0a.12.12,0,0,1,.05.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.83,26.56a1,1,0,0,1-.36-.68.29.29,0,0,1,.21-.32.82.82,0,0,1,.35,0,1,1,0,0,0-.34,0,.21.21,0,0,0-.1.25.87.87,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.32,25.42a1,1,0,0,1-.38-.68.29.29,0,0,1,.2-.32.72.72,0,0,1,.36,0,.76.76,0,0,0-.34,0,.21.21,0,0,0-.1.25.83.83,0,0,0,.39.47h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.39,24.46a1,1,0,0,1-.37-.67.29.29,0,0,1,.2-.32.72.72,0,0,1,.36,0,.78.78,0,0,0-.34,0,.21.21,0,0,0-.1.25.88.88,0,0,0,.38.48h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17.06S35.4,24.47,35.39,24.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,23.43a1,1,0,0,1-.38-.67.28.28,0,0,1,.2-.32.86.86,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.1.24.89.89,0,0,0,.39.48h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,26.22a1,1,0,0,1-.33-.7.28.28,0,0,1,.23-.3.87.87,0,0,1,.36.05.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.86.86,0,0,0,.34.5h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0S37,26.23,37,26.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,25.07a1,1,0,0,1-.32-.7.29.29,0,0,1,.23-.3.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.77.77,0,0,0,.34.51h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.77,23.7a1,1,0,0,1-.32-.7.3.3,0,0,1,.24-.3.68.68,0,0,1,.35.06.65.65,0,0,0-.34,0,.19.19,0,0,0-.12.24.75.75,0,0,0,.34.5h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.88,22.59a1,1,0,0,1-.3-.71.3.3,0,0,1,.24-.3.75.75,0,0,1,.36.07.83.83,0,0,0-.35,0,.2.2,0,0,0-.12.24.78.78,0,0,0,.33.51h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.33,24.3a1,1,0,0,1-.25-.73.28.28,0,0,1,.25-.28.79.79,0,0,1,.35.09.66.66,0,0,0-.34,0,.19.19,0,0,0-.14.23.85.85,0,0,0,.29.53h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.24,22.85a1,1,0,0,1-.24-.74.27.27,0,0,1,.26-.27.82.82,0,0,1,.35.09.81.81,0,0,0-.34,0,.2.2,0,0,0-.15.22.83.83,0,0,0,.29.54h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.07,22.07a1,1,0,0,1-.19-.75.31.31,0,0,1,.28-.26.86.86,0,0,1,.34.12,1,1,0,0,0-.33-.06.2.2,0,0,0-.16.22.87.87,0,0,0,.24.56h0a.13.13,0,0,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.78,23.51a1,1,0,0,1-.17-.76.3.3,0,0,1,.29-.25.93.93,0,0,1,.34.13.82.82,0,0,0-.34-.06.18.18,0,0,0-.16.2.82.82,0,0,0,.23.57h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0S39.79,23.51,39.78,23.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.39,21.88a1,1,0,0,1-.1-.76.29.29,0,0,1,.3-.23.92.92,0,0,1,.33.16.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.19.84.84,0,0,0,.18.58h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.82,24a1,1,0,0,1-.11-.76A.29.29,0,0,1,41,23a.78.78,0,0,1,.33.16.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.19.85.85,0,0,0,.19.58h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S40.83,24,40.82,24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.42,22.54a1,1,0,0,1-.05-.77.3.3,0,0,1,.32-.21.79.79,0,0,1,.31.18.76.76,0,0,0-.32-.11c-.11,0-.17.07-.19.17a.82.82,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.85,23.68a1,1,0,0,1-.05-.76.31.31,0,0,1,.33-.21.71.71,0,0,1,.31.19.66.66,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0S41.85,23.69,41.85,23.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.25,21.81a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.17.74.74,0,0,1,.3.21.69.69,0,0,0-.31-.15.2.2,0,0,0-.21.16.85.85,0,0,0,.09.61h0a.15.15,0,0,1,0,.18.13.13,0,0,1-.18,0S42.25,21.82,42.25,21.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.07,22.5a1,1,0,0,1,0-.77.29.29,0,0,1,.35-.16,1.06,1.06,0,0,1,.29.22,1.1,1.1,0,0,0-.3-.16.19.19,0,0,0-.22.15.8.8,0,0,0,0,.61h0a.13.13,0,1,1-.22.12A0,0,0,0,1,43.07,22.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.18,23.91a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.78.78,0,0,1,.29.21.85.85,0,0,0-.3-.15.21.21,0,0,0-.22.16.9.9,0,0,0,.08.61h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.17-.05S43.18,23.92,43.18,23.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.38,25.14a1,1,0,0,1,0-.77.31.31,0,0,1,.34-.18.9.9,0,0,1,.3.21.67.67,0,0,0-.31-.14c-.11,0-.18.06-.21.16a.87.87,0,0,0,.09.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A0,0,0,0,1,43.38,25.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.34,22.56a1,1,0,0,1,.13-.76.29.29,0,0,1,.36-.12.93.93,0,0,1,.27.25.77.77,0,0,0-.29-.19.19.19,0,0,0-.23.13.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06A0,0,0,0,0,44.34,22.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.36,22.21a1,1,0,0,1,.19-.75.29.29,0,0,1,.37-.09.88.88,0,0,1,.24.27.68.68,0,0,0-.27-.21.19.19,0,0,0-.24.11.79.79,0,0,0,0,.6h0a.12.12,0,0,1-.08.16.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.5,25.15a1,1,0,0,1,.08-.76.29.29,0,0,1,.35-.15.76.76,0,0,1,.28.22.8.8,0,0,0-.3-.16.18.18,0,0,0-.22.14.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.06.17.13.13,0,0,1-.17-.05A0,0,0,0,0,44.5,25.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.23,23.24a1,1,0,0,1,.21-.74.27.27,0,0,1,.37-.08.78.78,0,0,1,.24.27.71.71,0,0,0-.27-.22.2.2,0,0,0-.24.1.88.88,0,0,0-.07.61h0a.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.08A0,0,0,0,1,46.23,23.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.74,25.35a1,1,0,0,1,.13-.76.29.29,0,0,1,.36-.12.78.78,0,0,1,.26.25.67.67,0,0,0-.28-.19.19.19,0,0,0-.23.13.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.93,24.32a1,1,0,0,1,.22-.74.28.28,0,0,1,.37-.07.67.67,0,0,1,.23.27.62.62,0,0,0-.26-.22.19.19,0,0,0-.24.1.82.82,0,0,0-.07.61h0a.12.12,0,0,1-.08.15.12.12,0,0,1-.16-.08S46.93,24.33,46.93,24.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.92,25.59a1,1,0,0,1,.18-.75.3.3,0,0,1,.37-.1.77.77,0,0,1,.24.27.79.79,0,0,0-.27-.21.19.19,0,0,0-.24.11.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.07,25a1,1,0,0,1,.25-.73.29.29,0,0,1,.38-.06.86.86,0,0,1,.22.29.77.77,0,0,0-.25-.23.19.19,0,0,0-.25.08.84.84,0,0,0-.1.61h0a.14.14,0,0,1-.09.15.14.14,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.38,26.71a1,1,0,0,1,.18-.75.28.28,0,0,1,.36-.1.69.69,0,0,1,.25.26.73.73,0,0,0-.27-.2.19.19,0,0,0-.24.11.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.24.08A0,0,0,0,0,47.38,26.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.38,26.18a1,1,0,0,1,.23-.73.27.27,0,0,1,.37-.07.77.77,0,0,1,.23.28.8.8,0,0,0-.26-.23.2.2,0,0,0-.25.1.85.85,0,0,0-.08.6h0a.13.13,0,0,1-.08.16.14.14,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.39,26.44a1,1,0,0,1,.27-.73.29.29,0,0,1,.38-.05.72.72,0,0,1,.21.29.66.66,0,0,0-.24-.23.19.19,0,0,0-.25.08.81.81,0,0,0-.12.6h0a.12.12,0,0,1-.09.15.13.13,0,0,1-.15-.1A0,0,0,0,0,49.39,26.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.51,27.92a1,1,0,0,1,.19-.75.3.3,0,0,1,.37-.09.77.77,0,0,1,.24.27.64.64,0,0,0-.26-.21.2.2,0,0,0-.25.11.84.84,0,0,0,0,.6h0a.13.13,0,0,1-.08.16.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.66,27.7A1,1,0,0,1,49.9,27a.31.31,0,0,1,.38-.07.86.86,0,0,1,.22.29.68.68,0,0,0-.25-.23.2.2,0,0,0-.25.09.8.8,0,0,0-.09.6h0a.13.13,0,0,1-.09.16.14.14,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.79,27.43a1,1,0,0,1,.3-.72.29.29,0,0,1,.37,0,.86.86,0,0,1,.21.3.78.78,0,0,0-.24-.24.18.18,0,0,0-.25.07.82.82,0,0,0-.14.6h0a.12.12,0,0,1-.1.15.11.11,0,0,1-.14-.1A0,0,0,0,1,50.79,27.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,28.36a1,1,0,0,1,.27-.72.29.29,0,0,1,.38-.05,1,1,0,0,1,.21.29.78.78,0,0,0-.24-.24.21.21,0,0,0-.26.08.87.87,0,0,0-.11.6h0a.13.13,0,0,1-.1.15.13.13,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.2,29.24a1,1,0,0,1,.22-.74.3.3,0,0,1,.37-.08.77.77,0,0,1,.23.28.7.7,0,0,0-.26-.22.19.19,0,0,0-.24.1.82.82,0,0,0-.08.61h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.9,29.23a1,1,0,0,1,.28-.72.29.29,0,0,1,.38-.05.9.9,0,0,1,.21.3.72.72,0,0,0-.24-.25.21.21,0,0,0-.26.08.81.81,0,0,0-.12.6h0a.13.13,0,0,1-.1.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,30.44a1,1,0,0,1,.18-.75.29.29,0,0,1,.37-.09.74.74,0,0,1,.24.26.79.79,0,0,0-.27-.21.19.19,0,0,0-.24.11.86.86,0,0,0,0,.61h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.44,30.33a.94.94,0,0,1,.24-.73.27.27,0,0,1,.37-.07.66.66,0,0,1,.22.28.78.78,0,0,0-.25-.23.2.2,0,0,0-.25.1.85.85,0,0,0-.09.6h0a.12.12,0,0,1-.08.15.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.1,31.64a1,1,0,0,1,.16-.75.29.29,0,0,1,.36-.11.69.69,0,0,1,.25.26.73.73,0,0,0-.27-.2c-.11,0-.19,0-.24.12a.81.81,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.16-.07A0,0,0,0,1,50.1,31.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.4,31.46a1,1,0,0,1,.21-.74.3.3,0,0,1,.37-.08.88.88,0,0,1,.24.27A.68.68,0,0,0,52,30.7a.18.18,0,0,0-.24.1.87.87,0,0,0-.07.61h0a.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.08A0,0,0,0,1,51.4,31.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.7,31.34a1,1,0,0,1,.26-.72.28.28,0,0,1,.37-.06.74.74,0,0,1,.22.28.78.78,0,0,0-.25-.23.19.19,0,0,0-.25.09.8.8,0,0,0-.1.6h0a.13.13,0,0,1-.09.15.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.6,32.62a1,1,0,0,1,.19-.75.3.3,0,0,1,.37-.09.69.69,0,0,1,.24.27.6.6,0,0,0-.27-.21.19.19,0,0,0-.24.11.86.86,0,0,0,0,.61h0a.12.12,0,0,1-.08.16.12.12,0,0,1-.16-.07A0,0,0,0,0,51.6,32.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.83,33.55a1,1,0,0,1,.14-.76.29.29,0,0,1,.36-.12.78.78,0,0,1,.26.25,1,1,0,0,0-.28-.19.21.21,0,0,0-.24.13.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06S50.83,33.56,50.83,33.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.12,32.85a1,1,0,0,1,.23-.74.31.31,0,0,1,.38-.07.74.74,0,0,1,.22.28.65.65,0,0,0-.25-.22.2.2,0,0,0-.25.09.82.82,0,0,0-.08.61h0a.12.12,0,0,1-.09.15.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.66,34a1,1,0,0,1,.18-.75.3.3,0,0,1,.37-.1.77.77,0,0,1,.24.27.86.86,0,0,0-.26-.21.2.2,0,0,0-.25.11.86.86,0,0,0,0,.61h0a.13.13,0,1,1-.24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.58,35.09a1,1,0,0,1,.12-.76.29.29,0,0,1,.36-.13.78.78,0,0,1,.26.25.82.82,0,0,0-.28-.19.19.19,0,0,0-.23.13.8.8,0,0,0,0,.61h0a.13.13,0,1,1-.23.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,35.06a1,1,0,0,1,.18-.75.28.28,0,0,1,.36-.1.69.69,0,0,1,.25.26.73.73,0,0,0-.27-.2.19.19,0,0,0-.24.11.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.08.16.12.12,0,0,1-.16-.07A0,0,0,0,0,53,35.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.27,35a1,1,0,0,1,.22-.74.27.27,0,0,1,.37-.08.67.67,0,0,1,.23.27.77.77,0,0,0-.26-.22.2.2,0,0,0-.24.1.78.78,0,0,0-.07.61h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.09S54.27,35,54.27,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.69,36.56a1,1,0,0,1,.09-.77.29.29,0,0,1,.35-.14.91.91,0,0,1,.28.23.75.75,0,0,0-.3-.17.2.2,0,0,0-.22.14.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.05.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.2,36.34a1,1,0,0,1,.15-.75.29.29,0,0,1,.36-.12.78.78,0,0,1,.26.25,1,1,0,0,0-.28-.19.2.2,0,0,0-.24.12.86.86,0,0,0,0,.61h0a.12.12,0,0,1-.07.17.14.14,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.63,36.38a1,1,0,0,1,.19-.75.29.29,0,0,1,.37-.09.74.74,0,0,1,.24.26.79.79,0,0,0-.27-.21.19.19,0,0,0-.24.11.87.87,0,0,0-.05.61h0a.13.13,0,0,1-.08.16.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.3,37.61a1.06,1.06,0,0,1,.08-.77.28.28,0,0,1,.36-.14.67.67,0,0,1,.27.23.72.72,0,0,0-.29-.17.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.05.17.13.13,0,0,1-.17-.06A0,0,0,0,0,52.3,37.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.69,37.62a1,1,0,0,1,.13-.76.29.29,0,0,1,.36-.12.67.67,0,0,1,.26.24.79.79,0,0,0-.28-.18.2.2,0,0,0-.24.13.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.12.12,0,0,1-.16-.06S53.69,37.63,53.69,37.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,37.81a1,1,0,0,1,.16-.75.29.29,0,0,1,.36-.11.81.81,0,0,1,.26.26.86.86,0,0,0-.28-.2c-.11,0-.19,0-.24.12a.86.86,0,0,0,0,.61h0a.14.14,0,0,1-.07.16.14.14,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.69,38.94a1,1,0,0,1,.06-.77A.3.3,0,0,1,53.1,38a.79.79,0,0,1,.28.23.63.63,0,0,0-.3-.16c-.11,0-.18.05-.22.14a.9.9,0,0,0,0,.61h0a.13.13,0,0,1-.22.12A0,0,0,0,1,52.69,38.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.84,39a1,1,0,0,1,.13-.76.28.28,0,0,1,.36-.12.67.67,0,0,1,.26.24.67.67,0,0,0-.28-.19.19.19,0,0,0-.23.13.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.93,39.66a1,1,0,0,1,.08-.77.3.3,0,0,1,.35-.14.81.81,0,0,1,.28.23.79.79,0,0,0-.29-.17.21.21,0,0,0-.23.14.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.17.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.31,40.8a1,1,0,0,1,0-.77.28.28,0,0,1,.34-.16.7.7,0,0,1,.29.21.71.71,0,0,0-.3-.15c-.11,0-.18,0-.22.15a.85.85,0,0,0,.07.61h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.71,41.11a1,1,0,0,1,.08-.77.28.28,0,0,1,.35-.14.7.7,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.11,0-.18,0-.22.14a.85.85,0,0,0,0,.61h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,41.31a1,1,0,0,1,.11-.76.29.29,0,0,1,.36-.13.78.78,0,0,1,.27.24.64.64,0,0,0-.29-.18.19.19,0,0,0-.23.13.8.8,0,0,0,0,.61h0a.14.14,0,0,1-.07.17.13.13,0,0,1-.16-.07S56,41.32,56,41.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.88,42.59a1,1,0,0,1,.06-.77.29.29,0,0,1,.35-.15.76.76,0,0,1,.28.22.73.73,0,0,0-.3-.16c-.11,0-.18.05-.22.15a.86.86,0,0,0,.06.61h0a.12.12,0,0,1,0,.17.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.92,43.54a1,1,0,0,1,.07-.77.29.29,0,0,1,.35-.14.76.76,0,0,1,.28.22.73.73,0,0,0-.3-.16c-.11,0-.18.05-.22.14a.8.8,0,0,0,0,.61h0a.13.13,0,0,1-.22.12A0,0,0,0,1,55.92,43.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.36,44.57a1,1,0,0,1,0-.77.3.3,0,0,1,.35-.16,1.06,1.06,0,0,1,.29.22.84.84,0,0,0-.31-.16.2.2,0,0,0-.21.16.77.77,0,0,0,.07.6h0a.13.13,0,0,1-.22.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.59,45.08a1,1,0,0,1,.07-.76.29.29,0,0,1,.35-.15.76.76,0,0,1,.28.22.73.73,0,0,0-.3-.16c-.11,0-.18.05-.22.14a.8.8,0,0,0,0,.61h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0S56.59,45.09,56.59,45.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.69,46.24a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.93.93,0,0,1,.29.22.61.61,0,0,0-.3-.15.2.2,0,0,0-.22.15.8.8,0,0,0,.08.61h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0A0,0,0,0,1,55.69,46.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.74,46.92a1.07,1.07,0,0,1,0-.77.3.3,0,0,1,.35-.16.93.93,0,0,1,.29.22,1.1,1.1,0,0,0-.3-.16.21.21,0,0,0-.22.15.8.8,0,0,0,.06.61h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.1,48.19a1,1,0,0,1,0-.77.28.28,0,0,1,.34-.16.81.81,0,0,1,.3.21.69.69,0,0,0-.31-.15.19.19,0,0,0-.21.15.8.8,0,0,0,.08.61h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0A0,0,0,0,0,56.1,48.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.34,48.72A1,1,0,0,1,57.4,48a.29.29,0,0,1,.34-.16A.82.82,0,0,1,58,48a.87.87,0,0,0-.3-.16.21.21,0,0,0-.22.16.82.82,0,0,0,.06.6h0a.13.13,0,0,1,0,.17.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.75,49.83a1,1,0,0,1,.06-.77.3.3,0,0,1,.35-.16.88.88,0,0,1,.28.22.87.87,0,0,0-.3-.16.2.2,0,0,0-.22.15.85.85,0,0,0,.06.61h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.23,50.88a1,1,0,0,1,0-.77.29.29,0,0,1,.35-.16.78.78,0,0,1,.29.21A.81.81,0,0,0,57.6,50a.18.18,0,0,0-.21.15.85.85,0,0,0,.07.61h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.41,51.58a1,1,0,0,1,.07-.76.29.29,0,0,1,.35-.15.76.76,0,0,1,.28.22.73.73,0,0,0-.3-.16c-.11,0-.18.05-.22.14a.9.9,0,0,0,0,.61h0a.12.12,0,0,1-.06.17.13.13,0,0,1-.17,0A0,0,0,0,0,58.41,51.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.22,52.63a1,1,0,0,1,.09-.77.3.3,0,0,1,.35-.14.91.91,0,0,1,.28.23.75.75,0,0,0-.3-.17.2.2,0,0,0-.22.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.55,53.78a1,1,0,0,1,.08-.76.29.29,0,0,1,.35-.15.79.79,0,0,1,.28.23.83.83,0,0,0-.3-.17.19.19,0,0,0-.22.14.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.05.17.13.13,0,0,1-.17,0S58.55,53.79,58.55,53.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.76,54.46a1.07,1.07,0,0,1,.11-.77.3.3,0,0,1,.36-.13.78.78,0,0,1,.27.24.82.82,0,0,0-.29-.18.19.19,0,0,0-.23.13.83.83,0,0,0,0,.62h0a.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.06S59.77,54.46,59.76,54.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.42,55.82a1,1,0,0,1,.11-.76.29.29,0,0,1,.36-.14.9.9,0,0,1,.27.24,1,1,0,0,0-.29-.18.2.2,0,0,0-.23.14.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.16.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.81,56.24a1.06,1.06,0,0,1,.15-.76.3.3,0,0,1,.37-.11.85.85,0,0,1,.25.25.77.77,0,0,0-.28-.19.19.19,0,0,0-.23.12.81.81,0,0,0,0,.61h0a.11.11,0,0,1-.07.16.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.66,57.36a1,1,0,0,1,.13-.76.29.29,0,0,1,.36-.12.78.78,0,0,1,.26.25.82.82,0,0,0-.28-.19.19.19,0,0,0-.23.13.76.76,0,0,0,0,.61h0a.11.11,0,0,1-.07.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.73,57.94a1,1,0,0,1,.17-.75.29.29,0,0,1,.36-.11.77.77,0,0,1,.25.26.73.73,0,0,0-.27-.2.2.2,0,0,0-.24.12.86.86,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.13.13,0,0,1-.16-.07A0,0,0,0,1,60.73,57.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.05,58.47a1,1,0,0,1,.21-.74.27.27,0,0,1,.37-.08.67.67,0,0,1,.23.27.62.62,0,0,0-.26-.22.2.2,0,0,0-.24.1.87.87,0,0,0-.07.61h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.6,59.32a1,1,0,0,1,.18-.75.29.29,0,0,1,.36-.1.8.8,0,0,1,.25.27.9.9,0,0,0-.27-.21.19.19,0,0,0-.24.11.82.82,0,0,0,0,.61h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.05,59.2a1,1,0,0,1,.26-.72.29.29,0,0,1,.38-.06.61.61,0,0,1,.21.29.69.69,0,0,0-.24-.24.19.19,0,0,0-.25.09.81.81,0,0,0-.11.6h0a.12.12,0,0,1-.09.15.11.11,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.68,60.13a1,1,0,0,1,.22-.74.29.29,0,0,1,.38-.07.89.89,0,0,1,.23.28.8.8,0,0,0-.26-.23.2.2,0,0,0-.25.1.87.87,0,0,0-.08.61h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.31,60.4a1,1,0,0,1,.28-.72.29.29,0,0,1,.38,0,.86.86,0,0,1,.21.3.78.78,0,0,0-.24-.24.19.19,0,0,0-.25.07.82.82,0,0,0-.13.6h0a.13.13,0,0,1-.1.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.28,61.31a1,1,0,0,1,.23-.73.28.28,0,0,1,.37-.08.86.86,0,0,1,.23.28.9.9,0,0,0-.26-.22.2.2,0,0,0-.25.09.88.88,0,0,0-.08.61h0a.12.12,0,0,1-.08.15.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.58,61.82a1,1,0,0,1,.28-.71.29.29,0,0,1,.38,0,.9.9,0,0,1,.21.3.82.82,0,0,0-.24-.25.21.21,0,0,0-.26.08.81.81,0,0,0-.12.6h0a.13.13,0,0,1-.25.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.89,62.22a1,1,0,0,1,.32-.69.28.28,0,0,1,.38,0,.84.84,0,0,1,.19.31.8.8,0,0,0-.22-.26.2.2,0,0,0-.26.07.83.83,0,0,0-.17.58h0a.13.13,0,0,1-.11.15.12.12,0,0,1-.14-.11A0,0,0,0,1,63.89,62.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.39,63.31a1,1,0,0,1,.3-.71.29.29,0,0,1,.38,0,.75.75,0,0,1,.2.3.8.8,0,0,0-.23-.25.19.19,0,0,0-.26.07.87.87,0,0,0-.14.6h0a.12.12,0,0,1-.1.14.12.12,0,0,1-.14-.1S62.39,63.32,62.39,63.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.8,64.16a1,1,0,0,1,.35-.68.29.29,0,0,1,.38,0,.79.79,0,0,1,.17.31.78.78,0,0,0-.21-.26.19.19,0,0,0-.26,0,.84.84,0,0,0-.18.58h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.7,65.2a1,1,0,0,1,.33-.69.29.29,0,0,1,.38,0,.7.7,0,0,1,.18.31.7.7,0,0,0-.22-.26.18.18,0,0,0-.25.06.77.77,0,0,0-.17.59h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.13,65.82a1,1,0,0,1,.38-.66.28.28,0,0,1,.38,0,.71.71,0,0,1,.17.32,1,1,0,0,0-.21-.28.2.2,0,0,0-.26,0,.85.85,0,0,0-.21.58h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.57,67.2a1,1,0,0,1,.38-.66.28.28,0,0,1,.38,0,.61.61,0,0,1,.16.32.8.8,0,0,0-.2-.28.2.2,0,0,0-.26,0,.85.85,0,0,0-.21.58h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12S63.57,67.21,63.57,67.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.38,69a1,1,0,0,1,.33-.69.29.29,0,0,1,.38,0,.65.65,0,0,1,.18.31.63.63,0,0,0-.22-.26.19.19,0,0,0-.26.06.8.8,0,0,0-.16.59h0a.13.13,0,0,1-.11.15.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.43,69.13a1,1,0,0,1,.43-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33.9.9,0,0,0-.18-.28.2.2,0,0,0-.27,0,.83.83,0,0,0-.25.56h0a.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,69.53a1,1,0,0,1,.41-.66.29.29,0,0,1,.38,0,.89.89,0,0,1,.15.33.65.65,0,0,0-.2-.28.19.19,0,0,0-.26,0,.88.88,0,0,0-.23.57h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.86,70.64a1,1,0,0,1,.39-.66.29.29,0,0,1,.38,0,.92.92,0,0,1,.16.33.75.75,0,0,0-.2-.28.2.2,0,0,0-.27,0,.81.81,0,0,0-.21.57h0a.13.13,0,0,1-.26,0A0,0,0,0,1,61.86,70.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.93,70.64a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.81,70.24a1,1,0,0,1,.37-.68.3.3,0,0,1,.38,0,.83.83,0,0,1,.17.32.82.82,0,0,0-.21-.27.2.2,0,0,0-.26,0,.86.86,0,0,0-.2.58h0a.11.11,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,70.2a1,1,0,0,1,.43-.64.28.28,0,0,1,.37,0,.73.73,0,0,1,.14.33.62.62,0,0,0-.18-.29.2.2,0,0,0-.26,0,.85.85,0,0,0-.25.56h0a.13.13,0,0,1-.13.13.14.14,0,0,1-.13-.13A0,0,0,0,0,64,70.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.82,69.51a1,1,0,0,1,.37-.67.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.82.82,0,0,0-.21-.27.19.19,0,0,0-.26,0,.79.79,0,0,0-.2.57h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.31,68.44a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.71.71,0,0,1,.15.33.73.73,0,0,0-.19-.28.2.2,0,0,0-.27,0,.85.85,0,0,0-.22.57h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.67,67.92a1,1,0,0,1,.42-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.77.77,0,0,0-.19-.28.19.19,0,0,0-.26,0,.85.85,0,0,0-.25.56h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.59,68.12a1,1,0,0,1,.35-.68.28.28,0,0,1,.38,0,.75.75,0,0,1,.18.31.63.63,0,0,0-.22-.26.18.18,0,0,0-.26,0,.84.84,0,0,0-.18.58h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.3,67.18a1,1,0,0,1,.35-.69.29.29,0,0,1,.38,0,.83.83,0,0,1,.17.32.82.82,0,0,0-.21-.27.2.2,0,0,0-.26,0,.86.86,0,0,0-.18.59h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.77,67.62a1,1,0,0,1,.29-.72.3.3,0,0,1,.38,0,.83.83,0,0,1,.2.3.78.78,0,0,0-.24-.24.18.18,0,0,0-.25.07.87.87,0,0,0-.14.6h0a.14.14,0,0,1-.1.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.12,66.82a1,1,0,0,1,.31-.71.3.3,0,0,1,.38,0,.84.84,0,0,1,.19.31.67.67,0,0,0-.22-.25.19.19,0,0,0-.26.06.8.8,0,0,0-.15.59h0a.13.13,0,0,1-.11.15.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.87,66.2a1,1,0,0,1,.27-.73.3.3,0,0,1,.38,0,.9.9,0,0,1,.21.3.61.61,0,0,0-.24-.24.21.21,0,0,0-.26.08.86.86,0,0,0-.11.6h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.82,65.15a1,1,0,0,1,.27-.72.29.29,0,0,1,.38-.05.69.69,0,0,1,.21.3.72.72,0,0,0-.24-.25.19.19,0,0,0-.25.08.87.87,0,0,0-.13.6h0a.11.11,0,0,1-.09.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.82,65.22a1,1,0,0,1,.23-.73.28.28,0,0,1,.37-.08.77.77,0,0,1,.23.28.77.77,0,0,0-.26-.22c-.1,0-.19,0-.25.1a.85.85,0,0,0-.08.6h0a.13.13,0,0,1-.25.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.74,63.82a1,1,0,0,1,.23-.73.28.28,0,0,1,.37-.08.86.86,0,0,1,.23.28.75.75,0,0,0-.25-.22.2.2,0,0,0-.25.1.8.8,0,0,0-.08.6h0a.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.54,63.66a1,1,0,0,1,.2-.75.29.29,0,0,1,.37-.09.88.88,0,0,1,.24.27.76.76,0,0,0-.27-.21.19.19,0,0,0-.24.11.81.81,0,0,0-.05.61h0a.12.12,0,0,1-.08.16.12.12,0,0,1-.16-.07A0,0,0,0,0,58.54,63.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.2,63.64a1,1,0,0,1,.16-.75.27.27,0,0,1,.36-.11A.78.78,0,0,1,58,63a1,1,0,0,0-.28-.2.2.2,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.07.17.13.13,0,0,1-.16-.07A0,0,0,0,1,57.2,63.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.56,62.23a1,1,0,0,1,.16-.75.3.3,0,0,1,.37-.11,1,1,0,0,1,.25.26.73.73,0,0,0-.27-.2.2.2,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.13.13,0,0,1-.24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.17,62.67a1.05,1.05,0,0,1,.11-.76.31.31,0,0,1,.36-.13.93.93,0,0,1,.27.25.77.77,0,0,0-.29-.19.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.07.17.12.12,0,0,1-.16-.06A0,0,0,0,1,56.17,62.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,61.76a1,1,0,0,1,.12-.76.3.3,0,0,1,.36-.13.78.78,0,0,1,.26.25.82.82,0,0,0-.28-.19.19.19,0,0,0-.23.13.88.88,0,0,0,0,.62h0a.12.12,0,0,1-.07.17.12.12,0,0,1-.16-.06A0,0,0,0,1,57,61.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.23,61.75A1,1,0,0,1,55.3,61a.28.28,0,0,1,.36-.14.73.73,0,0,1,.27.22.69.69,0,0,0-.29-.16.18.18,0,0,0-.22.14.8.8,0,0,0,0,.61h0a.13.13,0,0,1-.05.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.67,60.73a1,1,0,0,1,.12-.77.28.28,0,0,1,.36-.12.75.75,0,0,1,.26.24.74.74,0,0,0-.29-.18c-.1,0-.18,0-.23.13a.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.12.12,0,0,1-.16-.06A0,0,0,0,1,57.67,60.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,60.63a1,1,0,0,1,.08-.76.29.29,0,0,1,.35-.15.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17.2.2,0,0,0-.22.14.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.05.17.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.05,59.5a1,1,0,0,1,.09-.77.3.3,0,0,1,.35-.14.91.91,0,0,1,.28.23.75.75,0,0,0-.3-.17.2.2,0,0,0-.22.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.42,59.55a1,1,0,0,1,.06-.77.28.28,0,0,1,.34-.16.72.72,0,0,1,.29.22.8.8,0,0,0-.3-.16.2.2,0,0,0-.22.15.85.85,0,0,0,.06.61h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0S55.43,59.55,55.42,59.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.26,59.49a.94.94,0,0,1,0-.77.29.29,0,0,1,.34-.17,1.06,1.06,0,0,1,.29.22.69.69,0,0,0-.31-.15.19.19,0,0,0-.21.15.8.8,0,0,0,.08.61h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A0,0,0,0,1,54.26,59.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.29,58.36a1,1,0,0,1,.06-.77.28.28,0,0,1,.34-.16.82.82,0,0,1,.29.22.73.73,0,0,0-.3-.16.2.2,0,0,0-.22.15.85.85,0,0,0,.06.61h0a.12.12,0,0,1-.05.17.13.13,0,0,1-.17,0A0,0,0,0,0,56.29,58.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.45,57.86a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.18.81.81,0,0,1,.3.21.69.69,0,0,0-.31-.15.21.21,0,0,0-.21.16.85.85,0,0,0,.09.61h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A0,0,0,0,0,54.45,57.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.58,56.91a1,1,0,0,1,0-.77A.29.29,0,0,1,56,56a.78.78,0,0,1,.29.21.81.81,0,0,0-.31-.15.2.2,0,0,0-.21.16.85.85,0,0,0,.08.61h0a.14.14,0,0,1-.05.18.13.13,0,0,1-.17,0S55.58,56.92,55.58,56.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,56.56a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.18.72.72,0,0,1,.31.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.1.6h0a.13.13,0,0,1-.21.14A0,0,0,0,1,54,56.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.56,55.36a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.69.69,0,0,1,.3.2.79.79,0,0,0-.31-.14c-.11,0-.18.06-.21.16a.77.77,0,0,0,.09.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0S55.56,55.37,55.56,55.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.43,55a1.08,1.08,0,0,1,0-.77.3.3,0,0,1,.34-.19.88.88,0,0,1,.3.2.65.65,0,0,0-.31-.13c-.12,0-.18.06-.21.16a.82.82,0,0,0,.11.6h0a.14.14,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.92,55.12a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.79.79,0,0,1,.31.18.91.91,0,0,0-.31-.12.2.2,0,0,0-.2.18.79.79,0,0,0,.13.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,52.92,55.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.29,54a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.2.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.18.88.88,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0S53.29,54,53.29,54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.89,53a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.18.85.85,0,0,1,.3.19.76.76,0,0,0-.31-.13c-.12,0-.18.07-.21.17a.82.82,0,0,0,.11.6h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0A0,0,0,0,0,54.89,53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.66,52.55a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.17.83.83,0,0,0,.13.6h0a.13.13,0,0,1-.21.15S53.66,52.56,53.66,52.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.25,52.65a1,1,0,0,1-.06-.77.28.28,0,0,1,.32-.2.73.73,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.8.8,0,0,0,.14.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.3,51.34a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13c-.11,0-.17.07-.2.17a.88.88,0,0,0,.12.6h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.36,51.18a1,1,0,0,1-.06-.77.29.29,0,0,1,.32-.21.79.79,0,0,1,.31.18.76.76,0,0,0-.32-.11c-.11,0-.17.08-.19.18a.8.8,0,0,0,.15.59h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.18,0S52.36,51.19,52.36,51.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.51,50.32a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.19.19,0,0,0-.19.18.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S53.51,50.33,53.51,50.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.4,49.77a1,1,0,0,1-.06-.77.29.29,0,0,1,.32-.21A.87.87,0,0,1,53,49a.76.76,0,0,0-.32-.11.18.18,0,0,0-.19.18.8.8,0,0,0,.14.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.34,49.32a1,1,0,0,1-.08-.76.29.29,0,0,1,.31-.22.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11c-.11,0-.17.09-.19.19a.85.85,0,0,0,.16.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.08,48.39a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.2.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.18.83.83,0,0,0,.14.6h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S53.08,48.4,53.08,48.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.66,47.69a1,1,0,0,1-.07-.77.3.3,0,0,1,.32-.21.78.78,0,0,1,.31.17.76.76,0,0,0-.32-.11c-.11,0-.17.09-.19.19a.82.82,0,0,0,.16.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0S51.66,47.7,51.66,47.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.63,46.79a1,1,0,0,1-.05-.77.3.3,0,0,1,.33-.2.71.71,0,0,1,.31.19.66.66,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.14.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.3,46.13a1,1,0,0,1-.06-.77.29.29,0,0,1,.31-.21.83.83,0,0,1,.32.18.65.65,0,0,0-.32-.11.18.18,0,0,0-.19.18.8.8,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.24,45a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13c-.11,0-.17.08-.2.18a.83.83,0,0,0,.14.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.61,44.52a1,1,0,0,1-.07-.76.29.29,0,0,1,.31-.22,1,1,0,0,1,.32.17.75.75,0,0,0-.32-.1.18.18,0,0,0-.19.18.81.81,0,0,0,.16.59h0a.12.12,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,43.48a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.2.81.81,0,0,1,.31.19.66.66,0,0,0-.32-.12c-.11,0-.17.07-.2.17a.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.9,42.88a1,1,0,0,1,0-.77.28.28,0,0,1,.32-.2.68.68,0,0,1,.32.18.7.7,0,0,0-.32-.12c-.12,0-.18.08-.2.18a.81.81,0,0,0,.14.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.65,42.41a1,1,0,0,1-.07-.77.27.27,0,0,1,.31-.21.71.71,0,0,1,.32.17.65.65,0,0,0-.32-.11.19.19,0,0,0-.19.18.79.79,0,0,0,.16.59h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.46,41.36a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.2.77.77,0,0,1,.31.19.7.7,0,0,0-.32-.12.19.19,0,0,0-.2.17.83.83,0,0,0,.14.6h0a.12.12,0,0,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.15,40.4a1,1,0,0,1-.06-.77.29.29,0,0,1,.32-.21.79.79,0,0,1,.31.18.65.65,0,0,0-.32-.11c-.11,0-.17.08-.19.18a.86.86,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.8,39.49a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13c-.11,0-.17.07-.2.17a.88.88,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S49.8,39.5,49.8,39.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.4,38.32a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.19.71.71,0,0,1,.31.19.76.76,0,0,0-.31-.13c-.12,0-.18.07-.21.17a.88.88,0,0,0,.12.6h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.63,37.38a1,1,0,0,1,0-.77.27.27,0,0,1,.33-.19.83.83,0,0,1,.3.18.74.74,0,0,0-.31-.12.19.19,0,0,0-.2.17.82.82,0,0,0,.12.6h0a.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.39,36.44a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.18.78.78,0,0,1,.29.21.69.69,0,0,0-.31-.15.21.21,0,0,0-.21.16.9.9,0,0,0,.09.61h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S49.4,36.44,49.39,36.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.47,35.55a1,1,0,0,1,0-.77.3.3,0,0,1,.34-.18,1,1,0,0,1,.3.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.87.87,0,0,0,.09.6h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0A0,0,0,0,1,48.47,35.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.72,34.37a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.82.82,0,0,1,.29.22.61.61,0,0,0-.3-.15.19.19,0,0,0-.22.15.85.85,0,0,0,.07.61h0a.14.14,0,0,1-.05.18.13.13,0,0,1-.17,0S48.72,34.38,48.72,34.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,33.13a1.07,1.07,0,0,1,.08-.77.29.29,0,0,1,.35-.14.63.63,0,0,1,.28.23.62.62,0,0,0-.29-.17.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.06.17.14.14,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,32.43a1,1,0,0,1,.05-.77.29.29,0,0,1,.35-.16,1.06,1.06,0,0,1,.29.22.88.88,0,0,0-.3-.16.2.2,0,0,0-.22.15.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.3,31.14a1,1,0,0,1,.1-.76.29.29,0,0,1,.35-.14.7.7,0,0,1,.27.24.88.88,0,0,0-.29-.18.2.2,0,0,0-.22.14.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.17.12.12,0,0,1-.16-.06A0,0,0,0,1,48.3,31.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.67,29.79a1,1,0,0,1,.15-.76.29.29,0,0,1,.36-.11.78.78,0,0,1,.26.25.73.73,0,0,0-.28-.19.18.18,0,0,0-.23.12.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.23.09S48.67,29.8,48.67,29.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.4,29.87a1,1,0,0,1,.09-.76.29.29,0,0,1,.36-.14.87.87,0,0,1,.27.23.72.72,0,0,0-.29-.17.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.11.11,0,0,1-.05.16.13.13,0,0,1-.17-.05A0,0,0,0,1,47.4,29.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.34,28.64a1,1,0,0,1,.12-.76.29.29,0,0,1,.36-.12.67.67,0,0,1,.26.24.71.71,0,0,0-.28-.18.19.19,0,0,0-.23.13.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.16-.06A0,0,0,0,1,47.34,28.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.26,27.84a1,1,0,0,1,.09-.76.28.28,0,0,1,.35-.14.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17.18.18,0,0,0-.22.13.88.88,0,0,0,0,.62h0a.12.12,0,0,1-.06.17.12.12,0,0,1-.16-.05A0,0,0,0,1,46.26,27.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.46,26.69a1,1,0,0,1,.08-.77.29.29,0,0,1,.35-.14.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17.2.2,0,0,0-.22.14.8.8,0,0,0,0,.61h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17-.06S45.46,26.7,45.46,26.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.46,24a1,1,0,0,1,.15-.76.29.29,0,0,1,.36-.11.78.78,0,0,1,.26.25.73.73,0,0,0-.28-.19c-.11,0-.19,0-.24.12a.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.43,23.5a1,1,0,0,1,.11-.76.29.29,0,0,1,.36-.13.78.78,0,0,1,.27.24.64.64,0,0,0-.29-.18.19.19,0,0,0-.23.13.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.07A0,0,0,0,1,44.43,23.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,21.17a1,1,0,0,1,.14-.76.31.31,0,0,1,.37-.12.78.78,0,0,1,.25.26.69.69,0,0,0-.28-.2.19.19,0,0,0-.23.13.86.86,0,0,0,0,.61h0a.14.14,0,0,1-.07.17.11.11,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.85,20.65a1,1,0,0,1,.08-.76.29.29,0,0,1,.35-.15.79.79,0,0,1,.28.23.83.83,0,0,0-.3-.17.19.19,0,0,0-.22.14.8.8,0,0,0,0,.61h0a.13.13,0,1,1-.22.12A0,0,0,0,1,42.85,20.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.81,20a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.16.72.72,0,0,1,.29.22.8.8,0,0,0-.3-.16.2.2,0,0,0-.22.15.85.85,0,0,0,.06.61h0a.13.13,0,0,1,0,.17.11.11,0,0,1-.17-.05S41.81,20,41.81,20Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.9,19.53a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2,1,1,0,0,1,.31.19.84.84,0,0,0-.32-.12.19.19,0,0,0-.2.17.88.88,0,0,0,.12.6h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,0,40.9,19.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.86,20.26a1,1,0,0,1-.1-.76.29.29,0,0,1,.31-.23.74.74,0,0,1,.32.16.84.84,0,0,0-.33-.1.2.2,0,0,0-.18.2.84.84,0,0,0,.18.58h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.92,19.51a1,1,0,0,1-.14-.76.28.28,0,0,1,.29-.24.85.85,0,0,1,.34.14.84.84,0,0,0-.34-.08.2.2,0,0,0-.17.2.83.83,0,0,0,.22.58h0a.13.13,0,1,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.88,19.72a1.06,1.06,0,0,1-.22-.74c0-.13.14-.28.27-.27a.8.8,0,0,1,.35.11.8.8,0,0,0-.34-.05.2.2,0,0,0-.15.22.83.83,0,0,0,.27.55h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,20.35a1.06,1.06,0,0,1-.27-.72.3.3,0,0,1,.26-.29.82.82,0,0,1,.35.08.8.8,0,0,0-.34,0,.2.2,0,0,0-.14.23.86.86,0,0,0,.3.53h0a.13.13,0,0,1-.15.21A0,0,0,0,0,37,20.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.28,19.18a1,1,0,0,1-.29-.71.29.29,0,0,1,.24-.3.81.81,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.82.82,0,0,0,.33.52h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.66,20.1a1,1,0,0,1-.33-.69.3.3,0,0,1,.23-.31.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.83.83,0,0,0,.35.5h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S35.67,20.11,35.66,20.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.79,19.39a1,1,0,0,1-.36-.68.28.28,0,0,1,.21-.31.72.72,0,0,1,.36,0,.64.64,0,0,0-.34,0,.2.2,0,0,0-.11.24.84.84,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.14.14,0,0,1-.18.05S34.8,19.4,34.79,19.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.63,21a1,1,0,0,1-.37-.68.29.29,0,0,1,.2-.32.74.74,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.1.25.82.82,0,0,0,.38.47h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.56,20.26a1,1,0,0,1-.41-.65.28.28,0,0,1,.18-.33.72.72,0,0,1,.36,0,.78.78,0,0,0-.34.05.19.19,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.12.12,0,0,1,.06.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,21.27a1,1,0,0,1-.43-.64.3.3,0,0,1,.17-.34.73.73,0,0,1,.36,0,.59.59,0,0,0-.33.06.19.19,0,0,0-.08.25.82.82,0,0,0,.42.44h0a.11.11,0,0,1,.07.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.06,22.05a1,1,0,0,1-.47-.62.31.31,0,0,1,.16-.35.89.89,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.07.26.92.92,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,22.76a1,1,0,0,1-.49-.6.29.29,0,0,1,.14-.35.74.74,0,0,1,.36,0,.6.6,0,0,0-.33.09.19.19,0,0,0-.05.26.82.82,0,0,0,.46.4h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,23.66a1,1,0,0,1-.51-.58.29.29,0,0,1,.13-.36.74.74,0,0,1,.36,0,.64.64,0,0,0-.33.1.19.19,0,0,0,0,.26.83.83,0,0,0,.47.38h0a.11.11,0,0,1,.09.15.13.13,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.15,25a1,1,0,0,1-.52-.57.29.29,0,0,1,.13-.36.72.72,0,0,1,.35-.06.72.72,0,0,0-.32.11.19.19,0,0,0,0,.26.85.85,0,0,0,.48.38h0a.12.12,0,0,1,.09.15.14.14,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,26.12a1,1,0,0,1-.53-.56.29.29,0,0,1,.12-.36.72.72,0,0,1,.35-.06.72.72,0,0,0-.32.11.2.2,0,0,0,0,.27.84.84,0,0,0,.49.36h0a.13.13,0,0,1,.09.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,26.92a1,1,0,0,1-.54-.54.3.3,0,0,1,.11-.37.91.91,0,0,1,.35-.07.77.77,0,0,0-.31.12.2.2,0,0,0,0,.27.87.87,0,0,0,.5.35h0a.13.13,0,0,1,.1.15.13.13,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.16,27.74a1,1,0,0,1-.55-.54.29.29,0,0,1,.1-.37.92.92,0,0,1,.36-.07.76.76,0,0,0-.32.12.2.2,0,0,0,0,.27.87.87,0,0,0,.5.35h0a.13.13,0,1,1-.05.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.11,28.36a1,1,0,0,1-.55-.54.29.29,0,0,1,.1-.37.87.87,0,0,1,.35-.07.66.66,0,0,0-.31.13.19.19,0,0,0,0,.26.81.81,0,0,0,.5.34h0a.12.12,0,0,1,0,.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.92,28a1,1,0,0,1-.56-.53.28.28,0,0,1,.1-.37.72.72,0,0,1,.35-.08.9.9,0,0,0-.32.13.21.21,0,0,0,0,.27.82.82,0,0,0,.51.33h0a.14.14,0,0,1,.11.15A.13.13,0,0,1,24,28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.11,29a1.06,1.06,0,0,1-.57-.53.31.31,0,0,1,.1-.37A.89.89,0,0,1,23,28a.79.79,0,0,0-.31.13.19.19,0,0,0,0,.27.82.82,0,0,0,.51.33h0a.12.12,0,0,1,.1.14.12.12,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.28,28.12a1,1,0,0,1-.57-.51.29.29,0,0,1,.09-.37.73.73,0,0,1,.35-.09.68.68,0,0,0-.31.14.19.19,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.14.14,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.59,29.38a1,1,0,0,1-.57-.51.29.29,0,0,1,.09-.37.73.73,0,0,1,.35-.09.68.68,0,0,0-.31.14.2.2,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.14.14,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.15,28.39a1,1,0,0,1-.58-.51.29.29,0,0,1,.09-.37.73.73,0,0,1,.35-.09.68.68,0,0,0-.31.14.19.19,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.06,26.93a1,1,0,0,1-.58-.51.29.29,0,0,1,.08-.37.73.73,0,0,1,.35-.09.8.8,0,0,0-.31.14.2.2,0,0,0,0,.27.9.9,0,0,0,.52.32h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.58,26.2a1,1,0,0,1-.58-.5.28.28,0,0,1,.08-.37.88.88,0,0,1,.34-.1.77.77,0,0,0-.3.15.19.19,0,0,0,0,.26.85.85,0,0,0,.52.32h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.77,25.07a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.83.83,0,0,1,.34-.1.61.61,0,0,0-.3.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.13,24.68a1.05,1.05,0,0,1-.6-.49.3.3,0,0,1,.08-.38.83.83,0,0,1,.34-.1.71.71,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.94,22.51a1,1,0,0,1-.6-.48.3.3,0,0,1,.07-.38.89.89,0,0,1,.35-.11.83.83,0,0,0-.31.16.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.12.12,0,0,1,.11.13.12.12,0,0,1-.13.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.76,22.83a1,1,0,0,1-.6-.49.29.29,0,0,1,.07-.37.76.76,0,0,1,.35-.11.83.83,0,0,0-.31.16.19.19,0,0,0,0,.26.8.8,0,0,0,.53.3h0a.14.14,0,0,1,.12.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.61,20.8a1,1,0,0,1-.6-.48.28.28,0,0,1,.06-.37.76.76,0,0,1,.35-.11.81.81,0,0,0-.31.15.21.21,0,0,0,0,.27.89.89,0,0,0,.54.3h0a.11.11,0,0,1,.11.13.13.13,0,0,1-.14.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.48,21.49a1,1,0,0,1-.6-.48.3.3,0,0,1,.06-.38.89.89,0,0,1,.35-.11.72.72,0,0,0-.3.16A.19.19,0,0,0,13,21a.83.83,0,0,0,.53.29h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.13.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.41,21.3a1,1,0,0,1-.61-.48.29.29,0,0,1,.07-.37.78.78,0,0,1,.34-.12.79.79,0,0,0-.3.16.2.2,0,0,0,0,.27.83.83,0,0,0,.54.29h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.61,20a1,1,0,0,1-.61-.48.29.29,0,0,1,.07-.37.87.87,0,0,1,.34-.12.72.72,0,0,0-.3.16.2.2,0,0,0,0,.27.81.81,0,0,0,.54.29h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.31,19.52A1,1,0,0,1,10.7,19a.28.28,0,0,1,.06-.37.78.78,0,0,1,.34-.12.87.87,0,0,0-.3.16.21.21,0,0,0,0,.27.83.83,0,0,0,.53.29h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.3,20.53a1,1,0,0,1-.6-.48.29.29,0,0,1,.06-.38.9.9,0,0,1,.34-.11.79.79,0,0,0-.3.16.2.2,0,0,0,0,.27.88.88,0,0,0,.54.29h0a.12.12,0,0,1,.11.14.11.11,0,0,1-.13.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.91,18.59a1,1,0,0,1-.61-.47.29.29,0,0,1,.06-.38.76.76,0,0,1,.35-.11.83.83,0,0,0-.31.16.2.2,0,0,0,0,.26.79.79,0,0,0,.53.29h0a.13.13,0,0,1,0,.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,18.71a1,1,0,0,1-.61-.48.3.3,0,0,1,.06-.38.89.89,0,0,1,.35-.11.72.72,0,0,0-.3.16.2.2,0,0,0,0,.27.83.83,0,0,0,.53.29h0a.14.14,0,0,1,.12.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.89,17.77a1,1,0,0,1-.61-.48.29.29,0,0,1,.07-.37.76.76,0,0,1,.34-.11.77.77,0,0,0-.3.15.2.2,0,0,0,0,.27.83.83,0,0,0,.54.29h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.77,16.75a1,1,0,0,1-.6-.48.28.28,0,0,1,.07-.37.68.68,0,0,1,.34-.11.71.71,0,0,0-.3.15.2.2,0,0,0,0,.27.79.79,0,0,0,.53.29h0a.12.12,0,0,1,.12.13.14.14,0,0,1-.14.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.8,15.63a1,1,0,0,1-.6-.49.28.28,0,0,1,.07-.37.72.72,0,0,1,.34-.11.72.72,0,0,0-.3.16.19.19,0,0,0,0,.26.8.8,0,0,0,.53.3h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.16,14.69a1.05,1.05,0,0,1-.6-.49.3.3,0,0,1,.08-.38.83.83,0,0,1,.34-.1.71.71,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.24,14.56a1,1,0,0,1-.59-.5.28.28,0,0,1,.07-.37.64.64,0,0,1,.35-.1.69.69,0,0,0-.31.15.19.19,0,0,0,0,.26.79.79,0,0,0,.53.31h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,13.61a1,1,0,0,1-.58-.5.28.28,0,0,1,.08-.37.63.63,0,0,1,.34-.1.7.7,0,0,0-.3.14.19.19,0,0,0,0,.27.79.79,0,0,0,.52.31h0a.13.13,0,0,1,.11.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.1,13.92a1,1,0,0,1-.59-.5.28.28,0,0,1,.09-.37.74.74,0,0,1,.34-.1.71.71,0,0,0-.3.15.19.19,0,0,0,0,.26.87.87,0,0,0,.53.32h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.33,13.11a1.08,1.08,0,0,1-.57-.52.29.29,0,0,1,.09-.37.73.73,0,0,1,.35-.09.68.68,0,0,0-.31.14.19.19,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.13.13,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.6,12.79a1,1,0,0,1-.57-.52.28.28,0,0,1,.1-.37.73.73,0,0,1,.35-.09.77.77,0,0,0-.32.14.2.2,0,0,0,0,.26.89.89,0,0,0,.52.34h0a.12.12,0,0,1,.1.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.46,12a1,1,0,0,1-.55-.54.28.28,0,0,1,.11-.36.74.74,0,0,1,.35-.08.9.9,0,0,0-.32.13.19.19,0,0,0,0,.26.77.77,0,0,0,.5.35h0a.13.13,0,0,1,.09.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.47,11.21a1.05,1.05,0,0,1-.53-.57.3.3,0,0,1,.13-.36.71.71,0,0,1,.35,0,.72.72,0,0,0-.32.11.19.19,0,0,0,0,.26.82.82,0,0,0,.48.37h0a.13.13,0,0,1,.09.16.11.11,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.3,10.5a1,1,0,0,1-.47-.61A.28.28,0,0,1,29,9.54a.73.73,0,0,1,.36,0A.7.7,0,0,0,29,9.6a.18.18,0,0,0-.07.25.8.8,0,0,0,.45.42h0a.12.12,0,1,1-.09.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.9,10.43a1,1,0,0,1-.42-.64.29.29,0,0,1,.18-.34.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.83.83,0,0,0,.41.45h0a.11.11,0,0,1,.07.16.14.14,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.9,10.24a1,1,0,0,1-.34-.69.29.29,0,0,1,.22-.31.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24A.87.87,0,0,0,33,10h0a.13.13,0,0,1,.05.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.46,10.84a1,1,0,0,1-.22-.75.3.3,0,0,1,.28-.26.8.8,0,0,1,.34.11.8.8,0,0,0-.34,0c-.11,0-.15.11-.15.22a.8.8,0,0,0,.26.55h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.4,10.85a.93.93,0,0,1-.09-.76.28.28,0,0,1,.31-.22.88.88,0,0,1,.32.16.84.84,0,0,0-.33-.1.19.19,0,0,0-.18.19.83.83,0,0,0,.17.59h0a.12.12,0,1,1-.19.16S37.4,10.86,37.4,10.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.22,11.88a1,1,0,0,1,.11-.76.3.3,0,0,1,.36-.13.78.78,0,0,1,.27.24.88.88,0,0,0-.29-.18.19.19,0,0,0-.23.13.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.23.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.77,12a1.07,1.07,0,0,1,.22-.74.29.29,0,0,1,.38-.07.77.77,0,0,1,.23.28.8.8,0,0,0-.26-.23.2.2,0,0,0-.25.1.82.82,0,0,0-.07.61h0a.12.12,0,0,1-.09.15.12.12,0,0,1-.15-.08A0,0,0,0,1,41.77,12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.23,12.17a1,1,0,0,1,.35-.69.29.29,0,0,1,.38,0,.73.73,0,0,1,.18.32.73.73,0,0,0-.22-.27.2.2,0,0,0-.26.05.88.88,0,0,0-.18.59h0a.12.12,0,0,1-.11.14.14.14,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.31,12.9a1,1,0,0,1,.42-.65.28.28,0,0,1,.37,0,.76.76,0,0,1,.15.33.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.93,13.06a1,1,0,0,1,.45-.63.3.3,0,0,1,.38.05.83.83,0,0,1,.13.34.83.83,0,0,0-.18-.3.19.19,0,0,0-.26,0,.83.83,0,0,0-.27.55h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.36,13.18a1,1,0,0,1,.47-.61.29.29,0,0,1,.37.06.77.77,0,0,1,.12.35.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14S50.36,13.19,50.36,13.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.81,13.23a1,1,0,0,1,.49-.6.29.29,0,0,1,.37.07.76.76,0,0,1,.11.35.83.83,0,0,0-.16-.31.2.2,0,0,0-.26,0,.8.8,0,0,0-.3.53h0a.13.13,0,0,1-.26,0A0,0,0,0,0,52.81,13.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.92,13.21a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.83.83,0,0,1,.1.34.71.71,0,0,0-.15-.3.19.19,0,0,0-.26,0,.79.79,0,0,0-.31.53h0a.13.13,0,0,1-.15.11c-.06,0-.11-.07-.11-.14S54.92,13.22,54.92,13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.58,13.61a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.93.93,0,0,1,.1.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.26,0,.85.85,0,0,0-.32.53h0a.13.13,0,0,1-.25,0S56.57,13.61,56.58,13.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,13.67a1,1,0,0,1,.5-.58.3.3,0,0,1,.38.08.76.76,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.85.85,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.05,13.79a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.08.76.76,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.8.8,0,0,0-.32.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14A0,0,0,0,1,60.05,13.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.85,13.75a1,1,0,0,1,.52-.57.28.28,0,0,1,.37.09.73.73,0,0,1,.09.35.63.63,0,0,0-.14-.31.18.18,0,0,0-.26,0,.84.84,0,0,0-.33.52h0a.14.14,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.74,12.87a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.61,13a1.08,1.08,0,0,1,.52-.57.29.29,0,0,1,.37.09.76.76,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.36,14a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.18.18,0,0,0-.26,0,.82.82,0,0,0-.33.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.35,14.27a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.78.78,0,0,1,.1.35,1,1,0,0,0-.14-.31.2.2,0,0,0-.27,0,.77.77,0,0,0-.32.51h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.82,13.93a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.08.76.76,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.85.85,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.43,14.25a1,1,0,0,1,.51-.58.28.28,0,0,1,.37.08.88.88,0,0,1,.1.34.71.71,0,0,0-.15-.3.19.19,0,0,0-.27,0,.81.81,0,0,0-.31.52h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.84,14.7a1,1,0,0,1,.49-.59.29.29,0,0,1,.38.07.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.3.53h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.53,13.76a1,1,0,0,1,.49-.6.3.3,0,0,1,.37.07.76.76,0,0,1,.11.35.84.84,0,0,0-.15-.3.2.2,0,0,0-.27,0,.87.87,0,0,0-.3.54h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.59,13.85a1,1,0,0,1,.48-.6.29.29,0,0,1,.37.06.77.77,0,0,1,.12.35.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.83.83,0,0,0-.29.53h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.51,14.86a1,1,0,0,1,.48-.6.28.28,0,0,1,.37.06.76.76,0,0,1,.11.34.67.67,0,0,0-.16-.3.19.19,0,0,0-.26,0,.83.83,0,0,0-.29.54h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77,15.67a1,1,0,0,1,.47-.61.29.29,0,0,1,.37.07.7.7,0,0,1,.12.34.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.88.88,0,0,0-.29.54h0a.12.12,0,0,1-.13.12A.14.14,0,0,1,77,15.7,0,0,0,0,1,77,15.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.56,16a1,1,0,0,1,.48-.61.3.3,0,0,1,.38.07.89.89,0,0,1,.11.35.83.83,0,0,0-.16-.31.21.21,0,0,0-.27,0,.81.81,0,0,0-.29.54h0a.13.13,0,0,1-.14.11.14.14,0,0,1-.12-.14A0,0,0,0,0,75.56,16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.67,17a1,1,0,0,1,.48-.6.29.29,0,0,1,.38.07.8.8,0,0,1,.11.34.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.8.8,0,0,0-.3.53h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.52,17.16a1.05,1.05,0,0,1,.49-.6.29.29,0,0,1,.37.07.76.76,0,0,1,.11.35.71.71,0,0,0-.15-.3.2.2,0,0,0-.27,0,.84.84,0,0,0-.3.53h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.45,18.45a1,1,0,0,1,.48-.6.3.3,0,0,1,.38.07.88.88,0,0,1,.1.35.69.69,0,0,0-.15-.31A.2.2,0,0,0,75,18a.84.84,0,0,0-.3.53h0a.13.13,0,1,1-.25,0A0,0,0,0,1,74.45,18.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.62,20a1,1,0,0,1,.48-.59.3.3,0,0,1,.38.06.74.74,0,0,1,.1.35.61.61,0,0,0-.15-.3.2.2,0,0,0-.27,0,.87.87,0,0,0-.3.54h0a.12.12,0,0,1-.13.11.14.14,0,0,1-.12-.14S73.62,20,73.62,20Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.88,22a1,1,0,0,1,.48-.6.3.3,0,0,1,.38.07.9.9,0,0,1,.11.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.26,0,.8.8,0,0,0-.3.53h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.3,23.84a1,1,0,0,1,.48-.6.28.28,0,0,1,.37.06.91.91,0,0,1,.12.35.83.83,0,0,0-.16-.31.21.21,0,0,0-.27,0,.79.79,0,0,0-.29.53h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.68,25.45a1,1,0,0,1,.47-.61.29.29,0,0,1,.38.06.72.72,0,0,1,.11.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.26,0,.83.83,0,0,0-.29.54h0a.13.13,0,0,1-.26,0A0,0,0,0,0,71.68,25.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.49,26.62A1,1,0,0,1,72,26a.28.28,0,0,1,.37.06.73.73,0,0,1,.12.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.27,0,.78.78,0,0,0-.28.54h0a.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.49,26.11a1,1,0,0,1,.48-.6.28.28,0,0,1,.37.06.76.76,0,0,1,.11.34.87.87,0,0,0-.16-.3.2.2,0,0,0-.26,0,.83.83,0,0,0-.29.54h0a.13.13,0,0,1-.26,0A0,0,0,0,0,70.49,26.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.57,24.18a1,1,0,0,1,.49-.6.28.28,0,0,1,.37.07.76.76,0,0,1,.11.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.26,0,.84.84,0,0,0-.3.53h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.66,21.68a1,1,0,0,1,.49-.59.28.28,0,0,1,.37.07.76.76,0,0,1,.11.35.83.83,0,0,0-.16-.31.19.19,0,0,0-.26,0,.84.84,0,0,0-.3.53h0a.12.12,0,0,1-.13.11.14.14,0,0,1-.12-.14S71.66,21.69,71.66,21.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.83,18.87a1,1,0,0,1,.49-.59.28.28,0,0,1,.37.07.76.76,0,0,1,.11.35,1,1,0,0,0-.15-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.31.52h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.17,15.63a1,1,0,0,1,.49-.59.3.3,0,0,1,.38.08.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.27,0,.86.86,0,0,0-.31.53h0a.13.13,0,1,1-.25,0A0,0,0,0,1,73.17,15.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.68,12.93a1,1,0,0,1,.5-.58.27.27,0,0,1,.37.08.63.63,0,0,1,.1.34.7.7,0,0,0-.14-.3.19.19,0,0,0-.27,0,.77.77,0,0,0-.31.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.66,14.07a1,1,0,0,1,.49-.59.28.28,0,0,1,.38.08.83.83,0,0,1,.1.34.71.71,0,0,0-.15-.3.19.19,0,0,0-.27,0,.88.88,0,0,0-.31.52h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.75,13.14a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.63.63,0,0,1,.1.34.66.66,0,0,0-.15-.3.19.19,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.84,16.68a1,1,0,0,1,.49-.59.28.28,0,0,1,.38.08.83.83,0,0,1,.1.34.81.81,0,0,0-.15-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.31.52h0a.13.13,0,0,1-.25,0S72.83,16.68,72.84,16.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,19.43a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.31.52h0a.13.13,0,0,1-.25,0S71,19.43,71,19.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.48,22a1.09,1.09,0,0,1,.5-.59.29.29,0,0,1,.37.08.64.64,0,0,1,.1.35.8.8,0,0,0-.14-.31.21.21,0,0,0-.27,0,.86.86,0,0,0-.31.53h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.27,24a1,1,0,0,1,.49-.59.3.3,0,0,1,.38.08.88.88,0,0,1,.1.34.77.77,0,0,0-.15-.3.2.2,0,0,0-.27,0,.91.91,0,0,0-.31.53h0a.13.13,0,1,1-.25,0A0,0,0,0,1,68.27,24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.67,25.54a1,1,0,0,1,.5-.59.28.28,0,0,1,.37.07.88.88,0,0,1,.1.35.69.69,0,0,0-.15-.31.19.19,0,0,0-.26,0,.79.79,0,0,0-.31.53h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.69,25.4a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14S66.69,25.41,66.69,25.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.79,24a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.64.64,0,0,1,.1.35.8.8,0,0,0-.14-.31.21.21,0,0,0-.27,0,.82.82,0,0,0-.31.53h0a.13.13,0,0,1-.14.11.14.14,0,0,1-.11-.15S66.79,24,66.79,24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.46,21.63a1,1,0,0,1,.51-.58.28.28,0,0,1,.37.09.88.88,0,0,1,.1.34.71.71,0,0,0-.15-.3.19.19,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.94,16.74a1,1,0,0,1,.51-.57.28.28,0,0,1,.37.09.73.73,0,0,1,.09.35.63.63,0,0,0-.14-.31.18.18,0,0,0-.26,0,.84.84,0,0,0-.33.52h0a.13.13,0,0,1-.25,0S67.94,16.75,67.94,16.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,12.61a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.08.76.76,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.87,12a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15S68.87,12,68.87,12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.93,13.37a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.08.76.76,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.8.8,0,0,0-.32.51h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,15.74a1,1,0,0,1,.51-.57.28.28,0,0,1,.37.09.73.73,0,0,1,.09.35.67.67,0,0,0-.14-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.13.13,0,0,1-.15.11.13.13,0,0,1-.1-.14A0,0,0,0,1,68,15.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.48,19.16a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.08.91.91,0,0,1,.09.35,1,1,0,0,0-.14-.31.2.2,0,0,0-.27,0,.82.82,0,0,0-.33.51h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15S66.48,19.17,66.48,19.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.51,21.63a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.86.86,0,0,1,.09.35.94.94,0,0,0-.14-.32.2.2,0,0,0-.27,0,.79.79,0,0,0-.32.52h0a.14.14,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.52,23.34a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.08,1,1,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.83.83,0,0,0-.32.52h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.61,24a1,1,0,0,1,.51-.58.28.28,0,0,1,.37.09.73.73,0,0,1,.09.35.67.67,0,0,0-.14-.32.19.19,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.1A.12.12,0,0,1,62.6,24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.92,24.93a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.08.76.76,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15S62.92,24.94,62.92,24.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.17,22.7a1,1,0,0,1,.52-.58.29.29,0,0,1,.37.09.91.91,0,0,1,.09.35,1,1,0,0,0-.14-.31.2.2,0,0,0-.27,0,.77.77,0,0,0-.32.51h0a.14.14,0,0,1-.15.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.14,20.81a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.09.76.76,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,63.14,20.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.15,17a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09,1,1,0,0,1,.09.35A1,1,0,0,0,65,16.6a.21.21,0,0,0-.27,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14S64.15,17.05,64.15,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.45,15.24a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.74.74,0,0,1,.08.35.79.79,0,0,0-.13-.31.21.21,0,0,0-.27,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.1.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.73,13.88a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.2.2,0,0,0-.27,0,.82.82,0,0,0-.33.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15S64.73,13.89,64.73,13.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.79,14.1a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.91.91,0,0,1,.09.35,1,1,0,0,0-.14-.31.2.2,0,0,0-.27,0,.82.82,0,0,0-.33.51h0a.11.11,0,0,1-.14.1.12.12,0,0,1-.11-.14A0,0,0,0,0,63.79,14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.4,15.6a1,1,0,0,1,.52-.57.28.28,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.19.19,0,0,0-.27,0,.82.82,0,0,0-.33.51h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,17.45a1,1,0,0,1,.52-.56.28.28,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.19.19,0,0,0-.27,0,.82.82,0,0,0-.33.51h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14S62,17.46,62,17.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.45,19.94a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.09.79.79,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,60.45,19.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.44,20.65a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.1.73.73,0,0,1,.09.35.71.71,0,0,0-.14-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,58.44,20.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.78,21.58A1,1,0,0,1,58.3,21a.3.3,0,0,1,.37.09.72.72,0,0,1,.08.35.79.79,0,0,0-.13-.31.21.21,0,0,0-.27,0,.87.87,0,0,0-.33.52h0a.13.13,0,0,1-.25,0S57.78,21.59,57.78,21.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.36,20.65a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.21.21,0,0,0-.27,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14A0,0,0,0,0,57.36,20.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.07,16.18a1,1,0,0,1,.52-.57.3.3,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.33.52h0a.13.13,0,0,1-.25,0A0,0,0,0,0,59.07,16.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.67,11.65a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.08.73.73,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.87.87,0,0,0-.32.52h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.74,9.6a1,1,0,0,1,.5-.58.28.28,0,0,1,.37.08.63.63,0,0,1,.1.34.7.7,0,0,0-.14-.3.2.2,0,0,0-.27,0,.79.79,0,0,0-.31.52h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.68,10a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.08.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.19.19,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.12,10.56a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.67.67,0,0,1,.1.35.8.8,0,0,0-.14-.31.21.21,0,0,0-.27,0,.82.82,0,0,0-.31.53h0a.13.13,0,0,1-.14.11.14.14,0,0,1-.11-.15S60.12,10.57,60.12,10.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.13,12a1.05,1.05,0,0,1,.5-.59.3.3,0,0,1,.38.08.76.76,0,0,1,.09.35.8.8,0,0,0-.14-.31.21.21,0,0,0-.27,0,.82.82,0,0,0-.31.53h0a.11.11,0,0,1-.14.1.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.93,16.4a1,1,0,0,1,.5-.58.28.28,0,0,1,.37.08.64.64,0,0,1,.1.35.74.74,0,0,0-.14-.31.2.2,0,0,0-.27,0,.83.83,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14S53.93,16.41,53.93,16.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.31,17.92a1,1,0,0,1,.49-.59.28.28,0,0,1,.37.07.76.76,0,0,1,.11.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.26,0,.82.82,0,0,0-.3.53h0a.12.12,0,0,1-.14.11A.14.14,0,0,1,51.3,18,0,0,0,0,0,51.31,17.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.16,18.47a1,1,0,0,1,.46-.62.28.28,0,0,1,.37.06.7.7,0,0,1,.12.34A.72.72,0,0,0,51,18a.21.21,0,0,0-.27,0,.86.86,0,0,0-.28.54h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.13A0,0,0,0,1,50.16,18.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.15,18.78a1,1,0,0,1,.43-.64.3.3,0,0,1,.38,0,.72.72,0,0,1,.13.33.77.77,0,0,0-.17-.29.21.21,0,0,0-.27,0,.91.91,0,0,0-.26.56h0a.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13S49.14,18.79,49.15,18.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.15,16.89a1,1,0,0,1,.48-.6.29.29,0,0,1,.38.07.8.8,0,0,1,.11.34.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.79.79,0,0,0-.29.53h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.54,14a1,1,0,0,1,.48-.6.3.3,0,0,1,.38.07.76.76,0,0,1,.11.35.83.83,0,0,0-.16-.31.2.2,0,0,0-.26,0,.82.82,0,0,0-.3.54h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.5,11.85a1,1,0,0,1,.48-.61.31.31,0,0,1,.38.07.85.85,0,0,1,.11.34.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.88.88,0,0,0-.29.54h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.09,9.8a1,1,0,0,1,.46-.62.28.28,0,0,1,.37.05.89.89,0,0,1,.13.34,1,1,0,0,0-.17-.3.21.21,0,0,0-.27,0,.87.87,0,0,0-.27.55h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.08,10.3a1,1,0,0,1,.46-.61.29.29,0,0,1,.38.06.87.87,0,0,1,.12.34.64.64,0,0,0-.17-.3.18.18,0,0,0-.26,0,.83.83,0,0,0-.29.54h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13A0,0,0,0,1,53.08,10.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.62,10.87a1,1,0,0,1,.47-.62.28.28,0,0,1,.37.06.7.7,0,0,1,.12.34.72.72,0,0,0-.16-.3.21.21,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.81,14.46a1,1,0,0,1,.47-.62.28.28,0,0,1,.37.06.73.73,0,0,1,.12.34.79.79,0,0,0-.16-.3.21.21,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.4,17a1,1,0,0,1,.37-.67.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32.68.68,0,0,0-.2-.27.19.19,0,0,0-.26,0,.81.81,0,0,0-.2.58h0a.14.14,0,0,1-.12.14A.12.12,0,0,1,46.4,17S46.4,17,46.4,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.81,19a1,1,0,0,1,.3-.71.3.3,0,0,1,.38,0,.87.87,0,0,1,.2.31.72.72,0,0,0-.24-.25.18.18,0,0,0-.25.07.84.84,0,0,0-.14.59h0a.13.13,0,0,1-.1.15.14.14,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.91,18.55a1,1,0,0,1,.26-.73.29.29,0,0,1,.38-.05.78.78,0,0,1,.21.29.91.91,0,0,0-.24-.24.2.2,0,0,0-.26.08.88.88,0,0,0-.11.61h0a.14.14,0,0,1-.1.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.86,17.24a1,1,0,0,1,.3-.71.29.29,0,0,1,.38,0,.75.75,0,0,1,.2.3.72.72,0,0,0-.24-.25.18.18,0,0,0-.25.07.87.87,0,0,0-.14.6h0a.12.12,0,0,1-.1.14.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.77,14a1,1,0,0,1,.39-.66.29.29,0,0,1,.38,0,.92.92,0,0,1,.16.33.65.65,0,0,0-.2-.28.19.19,0,0,0-.26,0A.85.85,0,0,0,46,14h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.14,11.34a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.67.67,0,0,1,.15.32.92.92,0,0,0-.19-.28.22.22,0,0,0-.27,0,.82.82,0,0,0-.22.57h0a.14.14,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.27,10.39a1,1,0,0,1,.39-.67.29.29,0,0,1,.38,0,.84.84,0,0,1,.16.33.75.75,0,0,0-.2-.28.2.2,0,0,0-.27,0,.82.82,0,0,0-.21.57h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.83,10.38a1,1,0,0,1,.36-.68.28.28,0,0,1,.38,0,.68.68,0,0,1,.17.31.67.67,0,0,0-.21-.26.18.18,0,0,0-.26.05.83.83,0,0,0-.19.58h0a.11.11,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.64,11.36a1,1,0,0,1,.28-.72.29.29,0,0,1,.38-.05.86.86,0,0,1,.21.29.94.94,0,0,0-.25-.24.19.19,0,0,0-.25.08.81.81,0,0,0-.12.6h0a.12.12,0,0,1-.09.15.13.13,0,0,1-.15-.1S42.64,11.37,42.64,11.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.85,14.9a1,1,0,0,1,0-.78.28.28,0,0,1,.34-.16.7.7,0,0,1,.29.21.61.61,0,0,0-.3-.15.2.2,0,0,0-.22.15.9.9,0,0,0,.08.61h0A.12.12,0,0,1,40,15a.13.13,0,0,1-.18,0S39.86,14.9,39.85,14.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,17.15a1,1,0,0,1-.14-.76.28.28,0,0,1,.29-.24.75.75,0,0,1,.33.14.66.66,0,0,0-.33-.07c-.11,0-.16.09-.17.2a.82.82,0,0,0,.21.57h0a.12.12,0,0,1-.18.17S38,17.16,38,17.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.86,17.16a1,1,0,0,1-.22-.74.29.29,0,0,1,.27-.27.79.79,0,0,1,.34.1.8.8,0,0,0-.34,0,.2.2,0,0,0-.15.22A.85.85,0,0,0,37,17h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,15.9a1,1,0,0,1-.18-.75.28.28,0,0,1,.28-.25.66.66,0,0,1,.34.12.66.66,0,0,0-.33-.06c-.12,0-.16.11-.17.21a.83.83,0,0,0,.25.56h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.85,14.31a1,1,0,0,1-.1-.76.29.29,0,0,1,.31-.23.69.69,0,0,1,.32.16.75.75,0,0,0-.32-.1c-.12,0-.17.09-.19.2a.84.84,0,0,0,.18.58h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.27,12.61a1.07,1.07,0,0,1-.1-.76.29.29,0,0,1,.31-.22.77.77,0,0,1,.33.15.82.82,0,0,0-.33-.09c-.11,0-.17.09-.19.19a.89.89,0,0,0,.18.58h0a.12.12,0,1,1-.19.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.52,11.86a1,1,0,0,1-.16-.75.29.29,0,0,1,.29-.25A.83.83,0,0,1,37,11a.83.83,0,0,0-.34-.07c-.11,0-.16.1-.17.21a.86.86,0,0,0,.23.56h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.51,11a1,1,0,0,1-.32-.7.32.32,0,0,1,.23-.31.85.85,0,0,1,.36.06.77.77,0,0,0-.34,0,.18.18,0,0,0-.12.23.8.8,0,0,0,.34.51h0a.13.13,0,1,1-.14.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.25,11.36a1,1,0,0,1-.5-.58.3.3,0,0,1,.14-.36.89.89,0,0,1,.36,0,.71.71,0,0,0-.33.1.19.19,0,0,0-.05.26.83.83,0,0,0,.46.39h0a.13.13,0,0,1,.09.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.07,14.14a1,1,0,0,1-.55-.53.29.29,0,0,1,.1-.37.85.85,0,0,1,.35-.08.9.9,0,0,0-.32.13.21.21,0,0,0,0,.27.81.81,0,0,0,.5.34h0a.12.12,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.55,15.74a1,1,0,0,1-.58-.51.29.29,0,0,1,.09-.37.73.73,0,0,1,.35-.09.68.68,0,0,0-.31.14.2.2,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.89,16.84a1.05,1.05,0,0,1-.59-.5.3.3,0,0,1,.08-.38.73.73,0,0,1,.35-.09.74.74,0,0,0-.31.14.2.2,0,0,0,0,.27.84.84,0,0,0,.52.31h0a.13.13,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,19.67a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.7.7,0,0,1,.34-.1.61.61,0,0,0-.3.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.18,19.05a1,1,0,0,1-.59-.49.29.29,0,0,1,.07-.38.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.2.2,0,0,0,0,.27.82.82,0,0,0,.53.3h0a.14.14,0,0,1,.12.14.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.18,18.46a1,1,0,0,1-.59-.49.3.3,0,0,1,.08-.38.88.88,0,0,1,.35-.1.75.75,0,0,0-.31.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.94,19.25a1,1,0,0,1-.59-.5.27.27,0,0,1,.08-.37.61.61,0,0,1,.34-.1.61.61,0,0,0-.3.15.18.18,0,0,0,0,.26A.79.79,0,0,0,21,19h0a.13.13,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.15,19.52a1,1,0,0,1-.6-.49.28.28,0,0,1,.07-.37.76.76,0,0,1,.35-.11.83.83,0,0,0-.31.16.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.12.12,0,0,1,.11.14.14.14,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.17,18.4a1.05,1.05,0,0,1-.6-.49.31.31,0,0,1,.08-.38.83.83,0,0,1,.34-.1.71.71,0,0,0-.3.15.2.2,0,0,0,0,.27.87.87,0,0,0,.53.3h0a.14.14,0,0,1,.12.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.51,17.32a1,1,0,0,1-.59-.5.28.28,0,0,1,.07-.37.88.88,0,0,1,.35-.1.69.69,0,0,0-.31.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.62,16.59a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.63.63,0,0,1,.34-.1.66.66,0,0,0-.3.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.47,17.57a1,1,0,0,1-.59-.5A.29.29,0,0,1,19,16.7a.83.83,0,0,1,.34-.1.71.71,0,0,0-.3.15A.19.19,0,0,0,19,17a.82.82,0,0,0,.53.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.44,17.93a1,1,0,0,1-.58-.5.28.28,0,0,1,.08-.37.88.88,0,0,1,.34-.1.71.71,0,0,0-.3.15.18.18,0,0,0,0,.26.85.85,0,0,0,.52.32h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.51,17.12a1,1,0,0,1-.58-.51.28.28,0,0,1,.08-.37.73.73,0,0,1,.35-.09.68.68,0,0,0-.31.14.2.2,0,0,0,0,.27.81.81,0,0,0,.52.31h0a.12.12,0,0,1,.1.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.19,18.34a1,1,0,0,1-.57-.51.28.28,0,0,1,.08-.37.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.19.19,0,0,0,0,.26.79.79,0,0,0,.52.32h0a.14.14,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,17.62a1,1,0,0,1-.58-.52.3.3,0,0,1,.09-.37.91.91,0,0,1,.35-.09,1,1,0,0,0-.31.14.2.2,0,0,0,0,.27.77.77,0,0,0,.51.32h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.22,17a1,1,0,0,1-.57-.53.3.3,0,0,1,.1-.36.73.73,0,0,1,.35-.09.68.68,0,0,0-.31.14.18.18,0,0,0,0,.26.88.88,0,0,0,.51.34h0a.12.12,0,0,1,.1.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.59,15.46a1,1,0,0,1-.56-.53.29.29,0,0,1,.1-.37.74.74,0,0,1,.35-.08.79.79,0,0,0-.31.13.19.19,0,0,0,0,.26.85.85,0,0,0,.51.35h0a.13.13,0,0,1,.1.15.14.14,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.45,16.44a1,1,0,0,1-.54-.56.3.3,0,0,1,.11-.36.92.92,0,0,1,.36-.07.76.76,0,0,0-.32.12.2.2,0,0,0,0,.27.89.89,0,0,0,.5.36h0a.12.12,0,1,1-.06.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.65,14.78a1,1,0,0,1-.53-.56.29.29,0,0,1,.12-.36.76.76,0,0,1,.36-.06.64.64,0,0,0-.32.11.19.19,0,0,0,0,.26.82.82,0,0,0,.49.37h0a.14.14,0,0,1,.09.16.13.13,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.27,13.89a1,1,0,0,1-.53-.56.3.3,0,0,1,.11-.36.76.76,0,0,1,.36-.06.81.81,0,0,0-.32.11.2.2,0,0,0,0,.27.87.87,0,0,0,.49.36h0a.14.14,0,0,1,.1.16.13.13,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.58,15.49a1,1,0,0,1-.51-.58.27.27,0,0,1,.12-.35.76.76,0,0,1,.36-.06.68.68,0,0,0-.32.11.2.2,0,0,0-.05.26.89.89,0,0,0,.48.38h0a.12.12,0,0,1,.09.15.11.11,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.91,13.76a1,1,0,0,1-.51-.58.31.31,0,0,1,.14-.36.84.84,0,0,1,.35,0,.71.71,0,0,0-.32.1.19.19,0,0,0-.05.26.82.82,0,0,0,.47.39h0a.14.14,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.4,12.25a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.11.11,0,0,1,.07.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.79,12.11a1,1,0,0,1-.41-.65.29.29,0,0,1,.18-.33.77.77,0,0,1,.37,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.79.79,0,0,0,.41.45h0a.12.12,0,0,1,.06.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.67,13.2a1,1,0,0,1-.38-.67.29.29,0,0,1,.2-.32.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.21.21,0,0,0-.1.25.87.87,0,0,0,.39.47h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.67,12.4a1,1,0,0,1-.34-.69.29.29,0,0,1,.22-.31.85.85,0,0,1,.36,0,.78.78,0,0,0-.34,0,.19.19,0,0,0-.11.24.83.83,0,0,0,.35.5h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.76,13.44a1,1,0,0,1-.33-.7.29.29,0,0,1,.22-.31.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.84.84,0,0,0,.35.5h0a.12.12,0,1,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.61,14.43a1,1,0,0,1-.35-.68.27.27,0,0,1,.21-.31.85.85,0,0,1,.36,0,1,1,0,0,0-.34,0,.2.2,0,0,0-.11.25.84.84,0,0,0,.37.49h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0S33.62,14.44,33.61,14.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.57,15a1,1,0,0,1-.4-.66.27.27,0,0,1,.19-.32.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.83.83,0,0,0,.4.46h0a.11.11,0,0,1,.06.16.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.68,17.05a1,1,0,0,1-.46-.62.31.31,0,0,1,.17-.35.87.87,0,0,1,.36,0,.7.7,0,0,0-.33.07.19.19,0,0,0-.07.26.77.77,0,0,0,.43.42h0a.11.11,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.6,19.15a1,1,0,0,1-.51-.58.29.29,0,0,1,.13-.36.75.75,0,0,1,.36-.05.85.85,0,0,0-.33.1.2.2,0,0,0,0,.27.8.8,0,0,0,.47.38h0a.13.13,0,0,1,.09.16.15.15,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.69,22.05a1,1,0,0,1-.54-.55.27.27,0,0,1,.11-.36.71.71,0,0,1,.35-.07.88.88,0,0,0-.32.12.19.19,0,0,0,0,.26.82.82,0,0,0,.5.36h0a.12.12,0,0,1,.1.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.55,23.78a1,1,0,0,1-.55-.54.28.28,0,0,1,.1-.36.78.78,0,0,1,.36-.08.77.77,0,0,0-.32.13.19.19,0,0,0,0,.26.91.91,0,0,0,.5.35h0a.12.12,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.55,25.17a.93.93,0,0,1-.55-.53.28.28,0,0,1,.09-.36.78.78,0,0,1,.36-.08.66.66,0,0,0-.32.13.2.2,0,0,0,0,.26.84.84,0,0,0,.51.34h0a.14.14,0,0,1,.1.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.65,26.89a1,1,0,0,1-.56-.52.28.28,0,0,1,.1-.37.72.72,0,0,1,.35-.08.79.79,0,0,0-.31.13.19.19,0,0,0,0,.26.84.84,0,0,0,.51.34h0a.12.12,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.7,30.49a1,1,0,0,1-.55-.54.29.29,0,0,1,.1-.37.87.87,0,0,1,.35-.07.77.77,0,0,0-.31.12.2.2,0,0,0,0,.27.84.84,0,0,0,.51.34h0a.12.12,0,0,1,0,.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.43,31.75a1,1,0,0,1-.56-.53.29.29,0,0,1,.1-.37.74.74,0,0,1,.35-.08.79.79,0,0,0-.31.13.19.19,0,0,0,0,.26.85.85,0,0,0,.51.35h0a.13.13,0,0,1,.1.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.87,33.18a1,1,0,0,1-.55-.54.28.28,0,0,1,.11-.36.74.74,0,0,1,.35-.08.9.9,0,0,0-.32.13.2.2,0,0,0,0,.26.77.77,0,0,0,.5.35h0a.13.13,0,0,1,.1.15.12.12,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,35.08a1,1,0,0,1-.54-.55.27.27,0,0,1,.11-.36A.84.84,0,0,1,21,34.1a.88.88,0,0,0-.32.12.19.19,0,0,0,0,.26.82.82,0,0,0,.5.36h0a.13.13,0,0,1,.09.15.12.12,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.85,37.2a1,1,0,0,1-.53-.56.29.29,0,0,1,.11-.36.76.76,0,0,1,.36-.06.64.64,0,0,0-.32.11.19.19,0,0,0,0,.26.82.82,0,0,0,.49.37h0a.12.12,0,0,1,.09.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.18,38.73a1,1,0,0,1-.52-.57.28.28,0,0,1,.12-.36.91.91,0,0,1,.36-.06,1.07,1.07,0,0,0-.33.11.2.2,0,0,0,0,.26.89.89,0,0,0,.48.38h0a.13.13,0,0,1-.06.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,40.1a1,1,0,0,1-.51-.58.3.3,0,0,1,.14-.36.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.09.21.21,0,0,0-.05.27.83.83,0,0,0,.47.38h0a.12.12,0,0,1,.09.15.13.13,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.31,41.34a1,1,0,0,1-.49-.59A.29.29,0,0,1,18,40.4a.64.64,0,0,1,.36,0,.7.7,0,0,0-.33.09.19.19,0,0,0,0,.26.89.89,0,0,0,.46.4h0a.12.12,0,0,1,.08.16.12.12,0,0,1-.15.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.26,41.56a1,1,0,0,1-.49-.59.28.28,0,0,1,.14-.35.64.64,0,0,1,.36,0,.7.7,0,0,0-.33.09.19.19,0,0,0-.05.26.85.85,0,0,0,.46.4h0a.13.13,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.37,39.88a1,1,0,0,1-.51-.57A.28.28,0,0,1,17,39a.75.75,0,0,1,.36-.05A.85.85,0,0,0,17,39a.2.2,0,0,0,0,.26.84.84,0,0,0,.48.38h0a.13.13,0,0,1,.09.16.12.12,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.87,38.66a1,1,0,0,1-.53-.56.29.29,0,0,1,.11-.36.92.92,0,0,1,.36-.07.76.76,0,0,0-.32.12.19.19,0,0,0,0,.26.84.84,0,0,0,.49.37h0a.13.13,0,0,1,.1.15.12.12,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.08,37a1,1,0,0,1-.55-.54.28.28,0,0,1,.1-.36A.74.74,0,0,1,15,36a.79.79,0,0,0-.31.13.19.19,0,0,0,0,.26.83.83,0,0,0,.5.35h0a.12.12,0,0,1,.1.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.71,34.88a1,1,0,0,1-.57-.53.28.28,0,0,1,.09-.36.8.8,0,0,1,.36-.09.67.67,0,0,0-.32.14.2.2,0,0,0,0,.26.82.82,0,0,0,.51.33h0a.12.12,0,1,1,0,.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.72,33.73a1,1,0,0,1-.57-.51.28.28,0,0,1,.09-.37.73.73,0,0,1,.35-.09.73.73,0,0,0-.31.13.19.19,0,0,0,0,.27.82.82,0,0,0,.51.33h0a.13.13,0,0,1,.11.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.74,32.63a1,1,0,0,1-.57-.51.28.28,0,0,1,.08-.37.76.76,0,0,1,.35-.09.68.68,0,0,0-.31.14.19.19,0,0,0,0,.26.82.82,0,0,0,.51.33h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.17,31.74a1,1,0,0,1-.58-.51.29.29,0,0,1,.08-.37.76.76,0,0,1,.35-.09.68.68,0,0,0-.31.14.2.2,0,0,0,0,.27.9.9,0,0,0,.52.32h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,29.14a1,1,0,0,1-.59-.49.3.3,0,0,1,.08-.38.88.88,0,0,1,.34-.1.71.71,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.52.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.63,26.6A1,1,0,0,1,9,26.11a.29.29,0,0,1,.07-.38.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.69,25.43a1.05,1.05,0,0,1-.6-.49.29.29,0,0,1,.07-.37.76.76,0,0,1,.35-.11.71.71,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.13.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.8,24.16a1.05,1.05,0,0,1-.6-.49.28.28,0,0,1,.07-.37.76.76,0,0,1,.34-.11.77.77,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.24,22.32a1,1,0,0,1-.61-.48.29.29,0,0,1,.07-.37A.76.76,0,0,1,8,21.36a.79.79,0,0,0-.3.16.19.19,0,0,0,0,.26.87.87,0,0,0,.54.3h0a.12.12,0,0,1,.11.13.14.14,0,0,1-.14.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.7,23.1a1,1,0,0,1-.6-.48.29.29,0,0,1,.07-.38.8.8,0,0,1,.34-.11.72.72,0,0,0-.3.16.19.19,0,0,0,0,.26.8.8,0,0,0,.53.3h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.13,23.48a1,1,0,0,1-.6-.48.28.28,0,0,1,.07-.37.72.72,0,0,1,.34-.11.71.71,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.12.12,0,0,1,.11.13.13.13,0,0,1-.13.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.26,25.24a1,1,0,0,1-.6-.48.29.29,0,0,1,.07-.38.88.88,0,0,1,.34-.1.66.66,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.13.13,0,1,1,0,.25S10.26,25.25,10.26,25.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.54,27.84a1,1,0,0,1-.6-.5A.29.29,0,0,1,11,27a.88.88,0,0,1,.35-.1.75.75,0,0,0-.31.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.58,30.36a1,1,0,0,1-.59-.51.29.29,0,0,1,.08-.37.93.93,0,0,1,.35-.1.81.81,0,0,0-.31.15.21.21,0,0,0,0,.27.86.86,0,0,0,.53.31h0a.13.13,0,0,1,.1.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.05,32.7a1,1,0,0,1-.57-.51.28.28,0,0,1,.09-.37.73.73,0,0,1,.35-.09.63.63,0,0,0-.31.14.18.18,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.12.12,0,0,1,.1.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.71,34.43a1,1,0,0,1-.56-.52.29.29,0,0,1,.09-.37.74.74,0,0,1,.35-.08.79.79,0,0,0-.31.13.21.21,0,0,0,0,.27.82.82,0,0,0,.51.33h0a.13.13,0,0,1,.11.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.25,35.47a1,1,0,0,1-.57-.53.31.31,0,0,1,.1-.37.74.74,0,0,1,.35-.08.79.79,0,0,0-.31.13.18.18,0,0,0,0,.26.8.8,0,0,0,.51.34h0a.12.12,0,1,1-.05.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.29,35.59a1,1,0,0,1-.56-.53.3.3,0,0,1,.11-.37.85.85,0,0,1,.35-.08.77.77,0,0,0-.32.13.21.21,0,0,0,0,.27.81.81,0,0,0,.5.34h0a.12.12,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.11,34a1,1,0,0,1-.57-.52.28.28,0,0,1,.1-.37A.86.86,0,0,1,17,33a1,1,0,0,0-.32.14.19.19,0,0,0,0,.26.8.8,0,0,0,.51.34h0a.12.12,0,0,1,.1.14.13.13,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.14,31.21a1,1,0,0,1-.58-.51.29.29,0,0,1,.09-.37.73.73,0,0,1,.35-.09.68.68,0,0,0-.31.14.19.19,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.4,28.27a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.64.64,0,0,1,.35-.1.69.69,0,0,0-.31.15.19.19,0,0,0,0,.26.79.79,0,0,0,.53.31h0a.14.14,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.17,26.32a1,1,0,0,1-.59-.5.29.29,0,0,1,.07-.37.78.78,0,0,1,.35-.1.61.61,0,0,0-.3.15.18.18,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.54,23.25a1,1,0,0,1-.6-.48A.29.29,0,0,1,13,22.4a.76.76,0,0,1,.35-.11.81.81,0,0,0-.31.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.73,16a1,1,0,0,1-.61-.48.29.29,0,0,1,.06-.37.91.91,0,0,1,.35-.12.72.72,0,0,0-.3.16.2.2,0,0,0,0,.27.79.79,0,0,0,.53.29h0a.14.14,0,0,1,.12.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.84,13.41a1,1,0,0,1-.61-.48.29.29,0,0,1,.07-.37.76.76,0,0,1,.34-.11.77.77,0,0,0-.3.15.2.2,0,0,0,0,.27.87.87,0,0,0,.54.3h0a.12.12,0,0,1,.12.13.14.14,0,0,1-.14.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.37,12a1,1,0,0,1-.6-.49.29.29,0,0,1,.08-.37.72.72,0,0,1,.34-.11.72.72,0,0,0-.3.16.19.19,0,0,0,0,.26.82.82,0,0,0,.53.3h0a.13.13,0,0,1,.12.13.14.14,0,0,1-.14.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.75,9.36a1,1,0,0,1-.57-.52.29.29,0,0,1,.09-.37.86.86,0,0,1,.34-.09,1,1,0,0,0-.31.14.2.2,0,0,0,0,.27.77.77,0,0,0,.51.32h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.91,7.1a1,1,0,0,1-.53-.56.28.28,0,0,1,.12-.36.76.76,0,0,1,.36-.06.72.72,0,0,0-.33.11.19.19,0,0,0,0,.26.82.82,0,0,0,.48.37h0a.14.14,0,0,1,.1.15.14.14,0,0,1-.16.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,5.66A1,1,0,0,1,25.58,5a.29.29,0,0,1,.17-.34.87.87,0,0,1,.36,0,.84.84,0,0,0-.33.06A.2.2,0,0,0,25.7,5a.86.86,0,0,0,.42.44h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.49,4.73A1,1,0,0,1,31.24,4a.3.3,0,0,1,.26-.28,1,1,0,0,1,.35.09.78.78,0,0,0-.34,0,.18.18,0,0,0-.14.22.81.81,0,0,0,.29.54h0a.13.13,0,1,1-.16.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.82,3.82a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.78.78,0,0,1,.29.21,1.05,1.05,0,0,0-.3-.15A.2.2,0,0,0,39,3.1a.81.81,0,0,0,.08.61h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.92,3.33a1,1,0,0,1,.29-.72.3.3,0,0,1,.38,0,.75.75,0,0,1,.2.3.72.72,0,0,0-.24-.25.19.19,0,0,0-.25.08.81.81,0,0,0-.13.6h0a.12.12,0,0,1-.1.14.13.13,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.45,3.51a1,1,0,0,1,.37-.67.28.28,0,0,1,.38,0,.71.71,0,0,1,.17.32.79.79,0,0,0-.2-.27.2.2,0,0,0-.27,0,.86.86,0,0,0-.2.58h0a.11.11,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.46,3.7a1,1,0,0,1,.4-.66.3.3,0,0,1,.38,0,.92.92,0,0,1,.16.33,1,1,0,0,0-.2-.28.18.18,0,0,0-.26,0,.82.82,0,0,0-.23.56h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,4.26a1,1,0,0,1,.43-.64.3.3,0,0,1,.38,0A.73.73,0,0,1,58,4a1,1,0,0,0-.18-.29.21.21,0,0,0-.27,0,.85.85,0,0,0-.25.56h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.08,4.45a1,1,0,0,1,.45-.62.29.29,0,0,1,.38,0,.7.7,0,0,1,.12.34.74.74,0,0,0-.17-.3.2.2,0,0,0-.26,0,.8.8,0,0,0-.27.55h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.12-.13S59.08,4.46,59.08,4.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.74,5a1,1,0,0,1,.48-.6.29.29,0,0,1,.37.06.91.91,0,0,1,.12.35.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.79.79,0,0,0-.29.53h0a.14.14,0,0,1-.14.12A.12.12,0,0,1,63.74,5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,5.7a1,1,0,0,1,.49-.6.29.29,0,0,1,.37.07.76.76,0,0,1,.11.35.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.84.84,0,0,0-.3.53h0a.12.12,0,0,1-.13.11A.14.14,0,0,1,68,5.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.44,6.59a1,1,0,0,1,.49-.6.31.31,0,0,1,.38.08.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.21.21,0,0,0-.27,0,.91.91,0,0,0-.31.53h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14A0,0,0,0,1,71.44,6.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.94,8.69a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.07.93.93,0,0,1,.1.35.61.61,0,0,0-.15-.3.18.18,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.12,11.64a1,1,0,0,1,.48-.6.29.29,0,0,1,.38.07.85.85,0,0,1,.11.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.26,0,.8.8,0,0,0-.3.53h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.88,17.35a1,1,0,0,1,.46-.62.3.3,0,0,1,.38.05.78.78,0,0,1,.12.34.77.77,0,0,0-.17-.29.18.18,0,0,0-.26,0,.82.82,0,0,0-.28.54h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.28,22.08a1.06,1.06,0,0,1,.45-.63.3.3,0,0,1,.38.05.89.89,0,0,1,.13.34,1,1,0,0,0-.17-.3.2.2,0,0,0-.27,0,.85.85,0,0,0-.27.55h0a.13.13,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.7,26.67a1,1,0,0,1,.43-.63.3.3,0,0,1,.38,0,.85.85,0,0,1,.14.34,1,1,0,0,0-.18-.3.21.21,0,0,0-.27,0,.89.89,0,0,0-.26.56h0a.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13A0,0,0,0,1,76.7,26.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.1,29.83a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.34.71.71,0,0,0-.18-.29.19.19,0,0,0-.26,0,.8.8,0,0,0-.25.56h0a.12.12,0,0,1-.13.12.11.11,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.36,33a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.7.7,0,0,0-.19-.28.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.11,36.24a1,1,0,0,1,.41-.66.3.3,0,0,1,.38,0,.89.89,0,0,1,.15.33.92.92,0,0,0-.19-.28.2.2,0,0,0-.27,0,.92.92,0,0,0-.23.57h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.16,40.19a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.77.77,0,0,0-.19-.28.19.19,0,0,0-.26,0,.78.78,0,0,0-.23.57h0a.14.14,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.91,42.35a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.67.67,0,0,1,.15.33.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.91,43.47a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33.84.84,0,0,0-.18-.29.2.2,0,0,0-.27,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.53,40.77a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.67.67,0,0,1,.15.33.63.63,0,0,0-.19-.28.2.2,0,0,0-.27,0,.82.82,0,0,0-.22.57h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.13-.12S70.52,40.77,70.53,40.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.07,36.26a1,1,0,0,1,.42-.65.3.3,0,0,1,.38,0A.87.87,0,0,1,70,36a.7.7,0,0,0-.19-.28.2.2,0,0,0-.26,0,.84.84,0,0,0-.24.57h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.6,31.46a1,1,0,0,1,.46-.62.29.29,0,0,1,.38.06.82.82,0,0,1,.12.34.74.74,0,0,0-.17-.3.18.18,0,0,0-.26,0,.82.82,0,0,0-.28.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.05,29.28a1,1,0,0,1,.48-.61.28.28,0,0,1,.37.06.91.91,0,0,1,.12.35.85.85,0,0,0-.17-.31.2.2,0,0,0-.26,0,.79.79,0,0,0-.29.54h0a.13.13,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.79,29.93a1,1,0,0,1,.47-.61.28.28,0,0,1,.37.06.7.7,0,0,1,.12.34.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0A.82.82,0,0,0,68,30h0a.13.13,0,0,1-.14.12.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.54,30.19a1,1,0,0,1,.47-.61.29.29,0,0,1,.38.06.68.68,0,0,1,.11.34.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.83.83,0,0,0-.29.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.82,31.18a1,1,0,0,1,.46-.62.28.28,0,0,1,.37.06.83.83,0,0,1,.13.34.87.87,0,0,0-.17-.3.21.21,0,0,0-.27,0,.85.85,0,0,0-.27.55h0a.13.13,0,0,1-.14.12.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.2,33.17a1.06,1.06,0,0,1,.44-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33,1,1,0,0,0-.18-.29.2.2,0,0,0-.27,0,.86.86,0,0,0-.26.55h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,37.66a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.89.89,0,0,1,.15.33.73.73,0,0,0-.19-.28.2.2,0,0,0-.27,0,.87.87,0,0,0-.23.56h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,43.16a1,1,0,0,1,.41-.66.3.3,0,0,1,.38,0,.87.87,0,0,1,.14.33.63.63,0,0,0-.19-.28.2.2,0,0,0-.26,0,.82.82,0,0,0-.23.57h0a.14.14,0,0,1-.13.13.13.13,0,0,1-.13-.13S71,43.16,71,43.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,47.32a1,1,0,0,1,.45-.62.29.29,0,0,1,.38,0,.83.83,0,0,1,.13.34.75.75,0,0,0-.18-.3.2.2,0,0,0-.26,0,.85.85,0,0,0-.27.55h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.29,49.38a1,1,0,0,1,.46-.62.29.29,0,0,1,.38.06.78.78,0,0,1,.12.34.69.69,0,0,0-.17-.3.2.2,0,0,0-.26,0,.88.88,0,0,0-.29.54h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14S72.28,49.38,72.29,49.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.37,50.13a1,1,0,0,1,.47-.61.28.28,0,0,1,.37.06.7.7,0,0,1,.12.34.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.13.13,0,0,1-.14.12.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.67,48.69a1,1,0,0,1,.46-.62.29.29,0,0,1,.38.06.7.7,0,0,1,.12.34.64.64,0,0,0-.17-.3.19.19,0,0,0-.26,0,.82.82,0,0,0-.28.54h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.7,45.77a1,1,0,0,1,.43-.65.29.29,0,0,1,.38,0,.87.87,0,0,1,.14.33.7.7,0,0,0-.19-.28.19.19,0,0,0-.26,0,.85.85,0,0,0-.25.56h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.67,39.25a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.67.67,0,0,1,.15.32.92.92,0,0,0-.19-.28.22.22,0,0,0-.27,0,.82.82,0,0,0-.22.57h0a.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.78,33.85a1,1,0,0,1,.44-.63.3.3,0,0,1,.38,0,1,1,0,0,1,.13.34.7.7,0,0,0-.17-.29.2.2,0,0,0-.27,0,.8.8,0,0,0-.26.55h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.61,31.05a1,1,0,0,1,.47-.61.29.29,0,0,1,.38.06.87.87,0,0,1,.12.34.87.87,0,0,0-.17-.3.19.19,0,0,0-.26,0,.88.88,0,0,0-.29.54h0a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.35,28.42a1,1,0,0,1,.49-.59.29.29,0,0,1,.37.08.64.64,0,0,1,.1.35.64.64,0,0,0-.15-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,1,1-.25,0A0,0,0,0,1,62.35,28.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,27.68a1,1,0,0,1,.49-.59.3.3,0,0,1,.38.08.88.88,0,0,1,.1.34.77.77,0,0,0-.15-.3.2.2,0,0,0-.27,0,.83.83,0,0,0-.31.53h0a.12.12,0,0,1-.14.11A.12.12,0,0,1,61,27.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.1,26.29a1,1,0,0,1,.5-.58.28.28,0,0,1,.37.07.93.93,0,0,1,.1.35.69.69,0,0,0-.15-.31.19.19,0,0,0-.26,0,.77.77,0,0,0-.31.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14A0,0,0,0,0,59.1,26.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.25,26.91a1,1,0,0,1,.48-.6.3.3,0,0,1,.38.07.76.76,0,0,1,.11.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.26,0,.8.8,0,0,0-.3.53h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.11,30.11a1,1,0,0,1,.45-.62.29.29,0,0,1,.38,0,.83.83,0,0,1,.13.34.71.71,0,0,0-.18-.29.19.19,0,0,0-.26,0,.83.83,0,0,0-.27.55h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.68,35.59a1,1,0,0,1,.37-.67.28.28,0,0,1,.38,0,.71.71,0,0,1,.17.32.64.64,0,0,0-.21-.27.19.19,0,0,0-.26,0,.86.86,0,0,0-.2.58h0a.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.05,38.87a1,1,0,0,1,.33-.7.29.29,0,0,1,.38,0,.65.65,0,0,1,.18.31.63.63,0,0,0-.22-.26.19.19,0,0,0-.26.06.81.81,0,0,0-.16.59h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.9,41.52a1,1,0,0,1,.34-.7.29.29,0,0,1,.38,0,.84.84,0,0,1,.19.31,1,1,0,0,0-.22-.26.19.19,0,0,0-.26.06.84.84,0,0,0-.18.58h0a.14.14,0,0,1-.11.14.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.88,45.13a1,1,0,0,1,.39-.66.28.28,0,0,1,.38,0,.81.81,0,0,1,.16.32.79.79,0,0,0-.2-.27.2.2,0,0,0-.27,0,.84.84,0,0,0-.21.57h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.07,48.06a1,1,0,0,1,.39-.67.29.29,0,0,1,.38,0,.92.92,0,0,1,.16.33.75.75,0,0,0-.2-.28.19.19,0,0,0-.26,0,.81.81,0,0,0-.22.57h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.43,50.19a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.34.84.84,0,0,0-.18-.29.2.2,0,0,0-.27,0,.83.83,0,0,0-.24.56h0a.13.13,0,1,1-.26,0A0,0,0,0,1,69.43,50.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.74,49.12a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33.68.68,0,0,0-.18-.28.19.19,0,0,0-.26,0,.8.8,0,0,0-.25.56h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.47,44.59a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.77.77,0,0,0-.19-.28A.19.19,0,0,0,71,44a.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.58,35a1,1,0,0,1,.43-.65.3.3,0,0,1,.38,0,.84.84,0,0,1,.13.33.59.59,0,0,0-.18-.28.18.18,0,0,0-.26,0,.8.8,0,0,0-.25.55h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,28.86a1,1,0,0,1,.49-.6.28.28,0,0,1,.37.07.68.68,0,0,1,.11.34.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.85.85,0,0,0-.3.54h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.42,24.81a1,1,0,0,1,.51-.57.28.28,0,0,1,.37.08.76.76,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.32.52h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14A0,0,0,0,1,61.42,24.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.44,22.16a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.52h0a.13.13,0,0,1-.15.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.28,16.81a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.72.72,0,0,1,.09.34.6.6,0,0,0-.14-.3.2.2,0,0,0-.27,0,.9.9,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14S55.28,16.82,55.28,16.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.69,15a1,1,0,0,1,.49-.59.28.28,0,0,1,.37.07.64.64,0,0,1,.1.35.69.69,0,0,0-.15-.31.19.19,0,0,0-.26,0,.79.79,0,0,0-.31.52h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.8,12.2a1,1,0,0,1,.47-.61.3.3,0,0,1,.38.05.89.89,0,0,1,.11.35.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.14.14,0,0,1-.14.12.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,11.41a1.06,1.06,0,0,1,.45-.63.3.3,0,0,1,.38.05,1,1,0,0,1,.13.34.82.82,0,0,0-.17-.29.19.19,0,0,0-.27,0,.85.85,0,0,0-.27.55h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.23,15a1,1,0,0,1,.49-.59.28.28,0,0,1,.37.07.72.72,0,0,1,.11.34.84.84,0,0,0-.15-.3.2.2,0,0,0-.27,0,.87.87,0,0,0-.3.54h0a.14.14,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.16,17.79a1,1,0,0,1,.51-.58.28.28,0,0,1,.37.09.88.88,0,0,1,.1.34.81.81,0,0,0-.15-.31.2.2,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.82,22.56a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.2.2,0,0,0-.27,0,.87.87,0,0,0-.33.52h0a.13.13,0,0,1-.25,0A0,0,0,0,0,56.82,22.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.43,24.79a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15A0,0,0,0,0,59.43,24.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.65,26.66a1,1,0,0,1,.51-.59.3.3,0,0,1,.37.09.88.88,0,0,1,.1.34.77.77,0,0,0-.15-.3.19.19,0,0,0-.26,0,.83.83,0,0,0-.32.53h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.06,29.58a1,1,0,0,1,.48-.6.28.28,0,0,1,.37.07.68.68,0,0,1,.11.34.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.84.84,0,0,0-.3.53h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14S61.06,29.59,61.06,29.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.19,30.72a1,1,0,0,1,.47-.61.28.28,0,0,1,.37.06.7.7,0,0,1,.12.34.72.72,0,0,0-.16-.3.19.19,0,0,0-.27,0,.8.8,0,0,0-.28.54h0a.12.12,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.35,31.79a1,1,0,0,1,.46-.61.29.29,0,0,1,.38.06.7.7,0,0,1,.12.34.74.74,0,0,0-.17-.3.19.19,0,0,0-.26,0,.82.82,0,0,0-.28.55h0a.13.13,0,0,1-.26,0S64.35,31.8,64.35,31.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.53,34.13a1,1,0,0,1,.43-.65.3.3,0,0,1,.38,0,.87.87,0,0,1,.14.33.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.8.8,0,0,0-.25.55h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.66,39.73a1,1,0,0,1,.41-.66.29.29,0,0,1,.38,0,.78.78,0,0,1,.15.32.92.92,0,0,0-.19-.28.2.2,0,0,0-.27,0,.9.9,0,0,0-.23.57h0a.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.58,42a1,1,0,0,1,.43-.64.28.28,0,0,1,.38,0,.87.87,0,0,1,.14.33.84.84,0,0,0-.18-.29.2.2,0,0,0-.27,0,.85.85,0,0,0-.25.56h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.34,38.37a1,1,0,0,1,.4-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.63.63,0,0,0-.19-.28.19.19,0,0,0-.26,0,.78.78,0,0,0-.23.56h0a.13.13,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.49,30.4a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.63.63,0,0,0-.19-.28.19.19,0,0,0-.26,0,.86.86,0,0,0-.23.57h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.13,26.35a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.92.92,0,0,1,.16.33.75.75,0,0,0-.2-.28.19.19,0,0,0-.26,0,.83.83,0,0,0-.23.57h0a.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M84.05,24.42a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.92.92,0,0,1,.16.33.75.75,0,0,0-.2-.28.19.19,0,0,0-.26,0,.83.83,0,0,0-.23.57h0a.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M86,21.46a1,1,0,0,1,.4-.67.29.29,0,0,1,.38,0,.67.67,0,0,1,.15.32.77.77,0,0,0-.19-.28.21.21,0,0,0-.27,0,.91.91,0,0,0-.22.57h0a.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M86.77,19.31a1,1,0,0,1,.38-.67.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32.79.79,0,0,0-.2-.27.18.18,0,0,0-.26,0,.74.74,0,0,0-.21.57h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M85.86,18.69a1.07,1.07,0,0,1,.39-.67.3.3,0,0,1,.38,0,.92.92,0,0,1,.16.33.75.75,0,0,0-.2-.28.19.19,0,0,0-.26,0,.81.81,0,0,0-.22.57h0a.13.13,0,0,1-.11.14.14.14,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M84.56,20.72a1,1,0,0,1,.4-.66.28.28,0,0,1,.38,0,.76.76,0,0,1,.15.33.63.63,0,0,0-.19-.28.2.2,0,0,0-.27,0,.85.85,0,0,0-.22.57h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.77,25a1,1,0,0,1,.41-.66.3.3,0,0,1,.38,0,.89.89,0,0,1,.15.33.86.86,0,0,0-.19-.28.2.2,0,0,0-.27,0A.78.78,0,0,0,82,25h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.18,29.28a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.7.7,0,0,0-.19-.28.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.28,32.88a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.77.77,0,0,0-.19-.28.19.19,0,0,0-.26,0,.8.8,0,0,0-.23.57h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.88,36.49a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32,1,1,0,0,0-.2-.28.2.2,0,0,0-.26,0,.83.83,0,0,0-.23.57h0c0,.06,0,.12-.12.13a.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.09,38.69a1,1,0,0,1,.4-.65.29.29,0,0,1,.38,0,.67.67,0,0,1,.15.32.77.77,0,0,0-.19-.28.22.22,0,0,0-.27,0,.81.81,0,0,0-.22.56h0a.13.13,0,0,1-.13.13.13.13,0,0,1-.13-.12S74.09,38.7,74.09,38.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.82,37.82a1,1,0,0,1,.4-.65.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32,1,1,0,0,0-.2-.28.19.19,0,0,0-.26,0,.82.82,0,0,0-.23.56h0A.12.12,0,0,1,75,38a.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.54,31.94a1,1,0,0,1,.4-.66.3.3,0,0,1,.39,0,1.09,1.09,0,0,1,.15.33.75.75,0,0,0-.2-.28.19.19,0,0,0-.26,0,.83.83,0,0,0-.23.57h0a.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.27,24.45a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.87.87,0,0,1,.14.33.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.83.83,0,0,0-.25.56h0a.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12A0,0,0,0,1,80.27,24.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.86,20.71a1,1,0,0,1,.43-.64.29.29,0,0,1,.38,0,.87.87,0,0,1,.14.33.7.7,0,0,0-.19-.28.19.19,0,0,0-.26,0,.85.85,0,0,0-.25.56h0a.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.5,20a1,1,0,0,1,.42-.65.29.29,0,0,1,.38,0,.65.65,0,0,1,.14.33.62.62,0,0,0-.18-.29.19.19,0,0,0-.26,0,.83.83,0,0,0-.25.56h0a.12.12,0,0,1-.12.12A.12.12,0,0,1,81.5,20Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81,21.72a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33.84.84,0,0,0-.18-.29.2.2,0,0,0-.27,0,.81.81,0,0,0-.24.56h0a.12.12,0,0,1-.13.12.11.11,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.92,22.36a1.06,1.06,0,0,1,.44-.64.3.3,0,0,1,.38,0,.83.83,0,0,1,.13.34.62.62,0,0,0-.18-.29.18.18,0,0,0-.26,0,.86.86,0,0,0-.26.55h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.74,21.49a1,1,0,0,1,.47-.61.28.28,0,0,1,.37.06.7.7,0,0,1,.12.34.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.88.88,0,0,0-.29.54h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14S74.73,21.49,74.74,21.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.56,18.57a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.76.76,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14S65.55,18.57,65.56,18.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.17,15.16a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.08.93.93,0,0,1,.1.35A.81.81,0,0,0,57,14.7a.19.19,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.8,14.1a1,1,0,0,1,.47-.61.29.29,0,0,1,.38.06.72.72,0,0,1,.11.34.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.83.83,0,0,0-.29.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.35,13.16a1.05,1.05,0,0,1,.35-.69.3.3,0,0,1,.38,0,.73.73,0,0,1,.18.32.73.73,0,0,0-.22-.27.19.19,0,0,0-.26.06.84.84,0,0,0-.18.58h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.67,11.64a1,1,0,0,1,0-.77.3.3,0,0,1,.34-.19,1,1,0,0,1,.3.2.76.76,0,0,0-.31-.13.18.18,0,0,0-.21.16.82.82,0,0,0,.1.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.7,15.71a1.06,1.06,0,0,1-.36-.68.3.3,0,0,1,.21-.32.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.18.18,0,0,0-.1.24.81.81,0,0,0,.37.49h0a.13.13,0,1,1-.13.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.35,17.16a1,1,0,0,1-.49-.59.31.31,0,0,1,.15-.36.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.09.19.19,0,0,0-.06.26.89.89,0,0,0,.46.4h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.09,19.63a1,1,0,0,1-.55-.54.28.28,0,0,1,.11-.36.74.74,0,0,1,.35-.08.79.79,0,0,0-.31.13.19.19,0,0,0,0,.26.77.77,0,0,0,.5.35h0a.12.12,0,0,1-.05.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.22,22.44a1,1,0,0,1-.57-.52.29.29,0,0,1,.09-.37.76.76,0,0,1,.35-.09.8.8,0,0,0-.31.14.2.2,0,0,0,0,.26.87.87,0,0,0,.52.33h0a.13.13,0,0,1,.1.15.12.12,0,0,1-.14.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.43,24.71a1,1,0,0,1-.58-.51.29.29,0,0,1,.08-.37.78.78,0,0,1,.35-.1.81.81,0,0,0-.31.15.19.19,0,0,0,0,.26.81.81,0,0,0,.52.32h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.23,28.79a1,1,0,0,1-.58-.5.3.3,0,0,1,.09-.38.72.72,0,0,1,.34-.09.7.7,0,0,0-.3.14.2.2,0,0,0,0,.27.85.85,0,0,0,.52.32h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.13,33.77a1,1,0,0,1-.57-.52.28.28,0,0,1,.1-.37.86.86,0,0,1,.35-.09.77.77,0,0,0-.32.14.2.2,0,0,0,0,.26.86.86,0,0,0,.51.34h0a.13.13,0,0,1,.11.15.14.14,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.06,40.85a1,1,0,0,1-.51-.58.3.3,0,0,1,.12-.36.92.92,0,0,1,.36,0,.72.72,0,0,0-.32.11.2.2,0,0,0-.05.26.85.85,0,0,0,.48.38h0a.13.13,0,0,1,.09.16.15.15,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.5,43.58A1,1,0,0,1,17,43a.28.28,0,0,1,.15-.34.75.75,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.13.13,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.05,45.5a1,1,0,0,1-.43-.64.29.29,0,0,1,.18-.34.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.06.19.19,0,0,0-.07.25.84.84,0,0,0,.42.45h0a.12.12,0,0,1,.06.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,46a1,1,0,0,1-.41-.65.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.13.13,0,0,1,.06.17A.13.13,0,0,1,19,46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.43,43.87a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.22.22,0,0,0-.07.26.88.88,0,0,0,.44.43h0a.13.13,0,1,1-.1.24S19.44,43.87,19.43,43.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.33,39.91a1,1,0,0,1-.49-.6A.3.3,0,0,1,22,39a.74.74,0,0,1,.36,0A.6.6,0,0,0,22,39a.19.19,0,0,0-.06.25.87.87,0,0,0,.46.41h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.27,35.64a1,1,0,0,1-.52-.58.29.29,0,0,1,.13-.36.9.9,0,0,1,.36-.05.72.72,0,0,0-.33.11.19.19,0,0,0,0,.26.82.82,0,0,0,.48.38h0a.13.13,0,1,1-.07.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.24,32.57a1,1,0,0,1-.53-.56.29.29,0,0,1,.12-.36.72.72,0,0,1,.35-.06.72.72,0,0,0-.32.11.19.19,0,0,0,0,.26.82.82,0,0,0,.49.37h0a.14.14,0,0,1,.09.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.37,29.54a1,1,0,0,1-.54-.55.28.28,0,0,1,.11-.36.92.92,0,0,1,.36-.07.76.76,0,0,0-.32.12.19.19,0,0,0,0,.26.85.85,0,0,0,.5.36h0a.11.11,0,0,1,.09.15.12.12,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,24.57a1,1,0,0,1-.54-.55.3.3,0,0,1,.12-.37.72.72,0,0,1,.35-.06.73.73,0,0,0-.32.12.2.2,0,0,0,0,.26.8.8,0,0,0,.49.36h0a.14.14,0,0,1,.1.15.14.14,0,0,1-.16.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.87,20.9a1,1,0,0,1-.49-.59.28.28,0,0,1,.15-.35.74.74,0,0,1,.36,0,.85.85,0,0,0-.33.09.19.19,0,0,0-.06.26.85.85,0,0,0,.46.4h0a.13.13,0,0,1,.08.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.4,16a1,1,0,0,1-.09-.77.28.28,0,0,1,.31-.22,1,1,0,0,1,.32.16.84.84,0,0,0-.33-.1.19.19,0,0,0-.18.19.8.8,0,0,0,.17.59h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0A0,0,0,0,0,38.4,16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.92,13.38a1,1,0,0,1,.28-.72.29.29,0,0,1,.38-.05,1,1,0,0,1,.21.29.94.94,0,0,0-.25-.24.19.19,0,0,0-.25.08.81.81,0,0,0-.12.6h0a.12.12,0,0,1-.09.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.8,12.08a1,1,0,0,1,.44-.62.29.29,0,0,1,.38,0,.89.89,0,0,1,.13.34.82.82,0,0,0-.17-.29.2.2,0,0,0-.27,0,.87.87,0,0,0-.27.55h0a.13.13,0,0,1-.25,0A0,0,0,0,1,48.8,12.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.3,12.3a1.09,1.09,0,0,1,.5-.59.3.3,0,0,1,.38.08.88.88,0,0,1,.1.35,1,1,0,0,0-.15-.31.2.2,0,0,0-.27,0,.86.86,0,0,0-.31.53h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.6,15.22a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.84.84,0,0,0-.33.52h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.84,15.91a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.08.76.76,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.83.83,0,0,0-.32.52h0a.13.13,0,0,1-.25,0A0,0,0,0,1,69.84,15.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.81,16.47a1,1,0,0,1,.51-.58.28.28,0,0,1,.37.09.88.88,0,0,1,.1.34.81.81,0,0,0-.15-.31.2.2,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.23,17.42a1,1,0,0,1,.5-.58.29.29,0,0,1,.37.08.64.64,0,0,1,.1.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.85.85,0,0,0-.32.52h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,18a1,1,0,0,1,.52-.57.28.28,0,0,1,.37.08.86.86,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.8.8,0,0,0-.32.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.94,20.06a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15S66.94,20.07,66.94,20.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.89,23.91a1,1,0,0,1,.5-.58.3.3,0,0,1,.38.08.91.91,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.85.85,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14A0,0,0,0,1,64.89,23.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.14,27.67a1,1,0,0,1,.49-.6.29.29,0,0,1,.37.08.76.76,0,0,1,.11.35.76.76,0,0,0-.16-.31.2.2,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,1,1-.25,0S64.13,27.67,64.14,27.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.68,30.53a1,1,0,0,1,.48-.61.28.28,0,0,1,.37.07.68.68,0,0,1,.11.34.62.62,0,0,0-.16-.3.19.19,0,0,0-.26,0,.79.79,0,0,0-.29.54h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.57,29.77a1,1,0,0,1,.49-.6.29.29,0,0,1,.37.07.76.76,0,0,1,.11.35.83.83,0,0,0-.16-.31.19.19,0,0,0-.26,0,.84.84,0,0,0-.3.53h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.07,28.11a1,1,0,0,1,.48-.6.31.31,0,0,1,.38.08.79.79,0,0,1,.1.34.71.71,0,0,0-.15-.3.2.2,0,0,0-.27,0,.87.87,0,0,0-.3.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14A0,0,0,0,1,60.07,28.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,25.39a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.67.67,0,0,1,.1.35.8.8,0,0,0-.14-.31.21.21,0,0,0-.27,0,.82.82,0,0,0-.31.53h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.08,24.37a1,1,0,0,1,.49-.59.29.29,0,0,1,.38.07.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.3.53h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.94,23.89a1,1,0,0,1,.48-.6.29.29,0,0,1,.37.06.91.91,0,0,1,.12.35.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.79.79,0,0,0-.29.53h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.49,25.08a1,1,0,0,1,.45-.63.3.3,0,0,1,.38.05.7.7,0,0,1,.12.34.69.69,0,0,0-.17-.3.19.19,0,0,0-.26,0,.81.81,0,0,0-.27.54h0a.13.13,0,0,1-.14.12.12.12,0,0,1-.12-.13A0,0,0,0,1,54.49,25.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.89,26a1,1,0,0,1,.47-.61.29.29,0,0,1,.37,0,.78.78,0,0,1,.12.34.79.79,0,0,0-.16-.3.21.21,0,0,0-.27,0,.82.82,0,0,0-.28.55h0a.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.82,29.29a1,1,0,0,1,.42-.65.29.29,0,0,1,.38,0,.87.87,0,0,1,.14.33.7.7,0,0,0-.19-.28.2.2,0,0,0-.26,0,.84.84,0,0,0-.24.57h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.63,32.18A1,1,0,0,1,58,31.5a.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32.79.79,0,0,0-.2-.27.19.19,0,0,0-.26,0,.85.85,0,0,0-.21.58h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.63,33.25a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.67.67,0,0,1,.15.32.77.77,0,0,0-.19-.28.22.22,0,0,0-.27,0,.83.83,0,0,0-.22.57h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.88,34.33a1.06,1.06,0,0,1,.36-.68.29.29,0,0,1,.38,0,.85.85,0,0,1,.18.32.73.73,0,0,0-.22-.27.2.2,0,0,0-.26.05.92.92,0,0,0-.19.58h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.59,33.36a1,1,0,0,1,.37-.67.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.71.71,0,0,0-.21-.27.2.2,0,0,0-.26,0,.86.86,0,0,0-.2.58h0a.11.11,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.72,31.08a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33.9.9,0,0,0-.18-.28.2.2,0,0,0-.27,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,27.75a1,1,0,0,1,.45-.63.29.29,0,0,1,.38.05.83.83,0,0,1,.13.34.75.75,0,0,0-.18-.3.19.19,0,0,0-.26,0,.81.81,0,0,0-.27.55h0a.13.13,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.32,26.79a1.06,1.06,0,0,1,.44-.64.3.3,0,0,1,.38,0,.71.71,0,0,1,.13.34.7.7,0,0,0-.17-.29.19.19,0,0,0-.27,0,.86.86,0,0,0-.26.55h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.75,23.85a1,1,0,0,1,.46-.62.29.29,0,0,1,.38.05.7.7,0,0,1,.12.34.69.69,0,0,0-.17-.3.2.2,0,0,0-.26,0,.82.82,0,0,0-.28.55h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.83,21.32a1.09,1.09,0,0,1,.48-.61.29.29,0,0,1,.37.07.93.93,0,0,1,.12.34.79.79,0,0,0-.16-.3.2.2,0,0,0-.27,0,.83.83,0,0,0-.29.54h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,20.52a1,1,0,0,1,.47-.61.28.28,0,0,1,.37.06.7.7,0,0,1,.12.34.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.77,19.63a1,1,0,0,1,.48-.61.29.29,0,0,1,.37.07.72.72,0,0,1,.11.34.94.94,0,0,0-.15-.3.2.2,0,0,0-.27,0,.84.84,0,0,0-.3.54h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.74,19.88a1,1,0,0,1,.45-.63.3.3,0,0,1,.38.05.78.78,0,0,1,.12.34.61.61,0,0,0-.17-.29.18.18,0,0,0-.26,0,.82.82,0,0,0-.28.54h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13S50.73,19.88,50.74,19.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.75,20.25a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33,1,1,0,0,0-.18-.29.2.2,0,0,0-.27,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.26,0S49.75,20.26,49.75,20.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.84,21a1,1,0,0,1,.44-.64.3.3,0,0,1,.38,0,.83.83,0,0,1,.13.34.71.71,0,0,0-.18-.29.18.18,0,0,0-.26,0,.77.77,0,0,0-.26.55h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.75,21.77a1,1,0,0,1,.45-.63.29.29,0,0,1,.38.05.7.7,0,0,1,.12.33.7.7,0,0,0-.17-.29.19.19,0,0,0-.26,0,.85.85,0,0,0-.27.55h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.24,22.93a1,1,0,0,1,.44-.63.29.29,0,0,1,.38,0,.84.84,0,0,1,.13.33.79.79,0,0,0-.18-.29.2.2,0,0,0-.26,0,.89.89,0,0,0-.26.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.32,21.39a1,1,0,0,1,.39-.67.3.3,0,0,1,.38,0,.92.92,0,0,1,.16.33.75.75,0,0,0-.2-.28.2.2,0,0,0-.26,0,.81.81,0,0,0-.22.57h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.16,23.27a1,1,0,0,1,.37-.67.29.29,0,0,1,.38,0,.83.83,0,0,1,.17.32.82.82,0,0,0-.21-.27.2.2,0,0,0-.26,0,.81.81,0,0,0-.2.58h0a.12.12,0,0,1-.12.13.11.11,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.48,22a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.87.87,0,0,1,.14.33.77.77,0,0,0-.19-.28.19.19,0,0,0-.26,0,.78.78,0,0,0-.23.56h0a.13.13,0,0,1-.13.13.13.13,0,0,1-.13-.12S50.48,22,50.48,22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.17,22.83a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.7.7,0,0,0-.19-.28.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.06,24a1,1,0,0,1,.38-.67.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32.68.68,0,0,0-.2-.27.18.18,0,0,0-.26,0,.82.82,0,0,0-.21.57h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.14-.12S51.05,24,51.06,24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.49,22.39a1,1,0,0,1,.37-.67.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.82.82,0,0,0-.21-.27.19.19,0,0,0-.26,0,.86.86,0,0,0-.2.58h0a.12.12,0,0,1-.12.13.11.11,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.14,24a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.77.77,0,0,1,.15.33.73.73,0,0,0-.19-.29.2.2,0,0,0-.27,0,.82.82,0,0,0-.23.56h0a.13.13,0,1,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.29,22.26a1,1,0,0,1,.47-.61.3.3,0,0,1,.38.07.68.68,0,0,1,.11.34.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.83.83,0,0,0-.29.54h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.41,20a1,1,0,0,1,.51-.58.3.3,0,0,1,.37.09.73.73,0,0,1,.09.35.8.8,0,0,0-.14-.31.21.21,0,0,0-.27,0,.85.85,0,0,0-.32.52h0a.13.13,0,0,1-.14.11A.13.13,0,0,1,54.4,20,0,0,0,0,1,54.41,20Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.54,19.62a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.86.86,0,0,1,.09.35.89.89,0,0,0-.14-.31.19.19,0,0,0-.27,0,.79.79,0,0,0-.32.52h0a.14.14,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.66,19.44a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.76.76,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.13.13,0,0,1-.15.11.14.14,0,0,1-.1-.15S56.65,19.44,56.66,19.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.8,18.75a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.18.18,0,0,0-.26,0,.82.82,0,0,0-.33.52h0a.13.13,0,0,1-.15.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,18.92a1,1,0,0,1,.52-.57.3.3,0,0,1,.37.1.86.86,0,0,1,.09.35.94.94,0,0,0-.14-.32.2.2,0,0,0-.27,0,.82.82,0,0,0-.33.51h0a.11.11,0,0,1-.14.1A.12.12,0,0,1,59,19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.28,20.93a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.74.74,0,0,1,.08.35.79.79,0,0,0-.13-.31.21.21,0,0,0-.27,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.1.13.13,0,0,1-.11-.14S60.28,20.94,60.28,20.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.5,23.27A1,1,0,0,1,61,22.7a.3.3,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.21.21,0,0,0-.27,0,.81.81,0,0,0-.32.52h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14A0,0,0,0,0,60.5,23.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.36,27a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.26,0,.87.87,0,0,0-.32.53h0a.13.13,0,0,1-.14.11.13.13,0,0,1-.11-.15A0,0,0,0,0,62.36,27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.37,33.11a1,1,0,0,1,.44-.63.29.29,0,0,1,.38,0,.62.62,0,0,1,.13.34.7.7,0,0,0-.17-.29.2.2,0,0,0-.27,0,.89.89,0,0,0-.26.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.18,35.55a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,1.09,1.09,0,0,1,.15.33,1,1,0,0,0-.2-.28.19.19,0,0,0-.26,0,.78.78,0,0,0-.23.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.78,38.11a1,1,0,0,1,.37-.67.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.71.71,0,0,0-.21-.27.2.2,0,0,0-.26,0,.86.86,0,0,0-.2.58h0a.12.12,0,0,1-.12.13.11.11,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,40a1,1,0,0,1,.35-.69.3.3,0,0,1,.38,0,1,1,0,0,1,.18.32,1,1,0,0,0-.22-.27.19.19,0,0,0-.26.06.84.84,0,0,0-.18.58h0A.13.13,0,1,1,64,40Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.32,43.06a1,1,0,0,1,.33-.7.29.29,0,0,1,.38,0,.7.7,0,0,1,.18.31.7.7,0,0,0-.22-.26.19.19,0,0,0-.26.06.81.81,0,0,0-.16.59h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.57,44.57a1,1,0,0,1,.32-.7.3.3,0,0,1,.38,0,.84.84,0,0,1,.19.31.72.72,0,0,0-.23-.26A.19.19,0,0,0,66,44a.81.81,0,0,0-.16.59h0a.12.12,0,1,1-.24,0A0,0,0,0,1,65.57,44.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.44,45.36a1,1,0,0,1,.36-.68.29.29,0,0,1,.38,0,.83.83,0,0,1,.17.32.82.82,0,0,0-.21-.27.19.19,0,0,0-.26.05.8.8,0,0,0-.19.58h0a.12.12,0,0,1-.11.14.13.13,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.1,46.1a1,1,0,0,1,.29-.71.28.28,0,0,1,.37,0,.69.69,0,0,1,.21.3.72.72,0,0,0-.24-.25.19.19,0,0,0-.25.08.82.82,0,0,0-.13.6h0a.13.13,0,0,1-.25.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.64,47.23a1,1,0,0,1,.36-.69.29.29,0,0,1,.38,0,.79.79,0,0,1,.17.31.78.78,0,0,0-.21-.26.19.19,0,0,0-.26,0,.85.85,0,0,0-.19.58h0a.14.14,0,0,1-.12.14.13.13,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.11,46.39a1,1,0,0,1,.34-.69.29.29,0,0,1,.38,0A.65.65,0,0,1,68,46a.63.63,0,0,0-.22-.26.19.19,0,0,0-.25,0,.83.83,0,0,0-.18.59h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.33,46.31a1,1,0,0,1,.38-.67.29.29,0,0,1,.38,0,.69.69,0,0,1,.16.32.68.68,0,0,0-.2-.27.2.2,0,0,0-.26,0,.87.87,0,0,0-.21.58h0a.11.11,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.69,47.75a1,1,0,0,1,.32-.7.29.29,0,0,1,.38,0,.77.77,0,0,1,.19.31.73.73,0,0,0-.23-.26.2.2,0,0,0-.26.06.85.85,0,0,0-.15.59h0a.13.13,0,0,1-.11.15.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.22,45.73a.94.94,0,0,1,.32-.7.28.28,0,0,1,.38,0,.84.84,0,0,1,.19.31.83.83,0,0,0-.23-.26.21.21,0,0,0-.26.07.91.91,0,0,0-.16.59h0a.13.13,0,0,1-.11.14.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.05,46.81a1,1,0,0,1,.3-.71.3.3,0,0,1,.38,0,.83.83,0,0,1,.2.3,1.1,1.1,0,0,0-.23-.25.2.2,0,0,0-.26.07.85.85,0,0,0-.15.59h0a.13.13,0,0,1-.11.15.12.12,0,0,1-.14-.1S66,46.82,66.05,46.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.54,47.76a1,1,0,0,1,.29-.72.28.28,0,0,1,.37,0,.75.75,0,0,1,.21.3.78.78,0,0,0-.24-.24.18.18,0,0,0-.25.07.82.82,0,0,0-.13.6h0a.12.12,0,0,1-.1.15.14.14,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.74,47.07a1,1,0,0,1,.27-.73.28.28,0,0,1,.37-.05.77.77,0,0,1,.22.29.94.94,0,0,0-.25-.24.19.19,0,0,0-.25.09A.86.86,0,0,0,65,47h0a.12.12,0,0,1-.09.15.13.13,0,0,1-.15-.1S64.74,47.08,64.74,47.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.48,44.15a1,1,0,0,1,.37-.68.3.3,0,0,1,.38,0,.83.83,0,0,1,.17.32.82.82,0,0,0-.21-.27.19.19,0,0,0-.26,0,.84.84,0,0,0-.2.58h0a.13.13,0,0,1-.12.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.59,43.69a1.05,1.05,0,0,1,.35-.69.3.3,0,0,1,.38,0,.73.73,0,0,1,.18.32.73.73,0,0,0-.22-.27.19.19,0,0,0-.26.06.84.84,0,0,0-.18.58h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.45,43.64a1,1,0,0,1,.38-.67.28.28,0,0,1,.38,0,.81.81,0,0,1,.16.32.68.68,0,0,0-.2-.27.18.18,0,0,0-.26,0,.82.82,0,0,0-.21.57h0a.13.13,0,0,1-.25,0A0,0,0,0,1,68.45,43.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.33,42.73a1,1,0,0,1,.39-.67.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32,1,1,0,0,0-.2-.28.22.22,0,0,0-.27,0,.84.84,0,0,0-.21.57h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.14,42.59a1,1,0,0,1,.37-.68.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.71.71,0,0,0-.21-.27.2.2,0,0,0-.26,0,.86.86,0,0,0-.2.58h0a.12.12,0,0,1-.11.14.14.14,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.3,42.42a1,1,0,0,1,.31-.71.3.3,0,0,1,.38,0,.84.84,0,0,1,.19.31.62.62,0,0,0-.23-.25.18.18,0,0,0-.25.06.8.8,0,0,0-.15.59h0a.13.13,0,0,1-.11.15.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.13,41.15a1,1,0,0,1,.37-.67.28.28,0,0,1,.38,0,.79.79,0,0,1,.17.31.82.82,0,0,0-.21-.27.2.2,0,0,0-.26,0,.86.86,0,0,0-.2.58h0a.11.11,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.93,40.46a1.07,1.07,0,0,1,.39-.67.3.3,0,0,1,.38,0,.92.92,0,0,1,.16.33.84.84,0,0,0-.2-.28.2.2,0,0,0-.26,0,.81.81,0,0,0-.22.57h0a.13.13,0,0,1-.12.14.14.14,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.72,41a1,1,0,0,1,.33-.7.3.3,0,0,1,.38,0,.84.84,0,0,1,.19.31.7.7,0,0,0-.23-.25.19.19,0,0,0-.26.06A.86.86,0,0,0,64,41h0a.12.12,0,0,1-.1.14.13.13,0,0,1-.15-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.32,40.43a1,1,0,0,1,.36-.68.29.29,0,0,1,.38,0,.93.93,0,0,1,.18.32.73.73,0,0,0-.22-.27.2.2,0,0,0-.26.05.85.85,0,0,0-.19.58h0a.12.12,0,0,1-.11.14.14.14,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,73.43a1,1,0,0,1-.72-.29.29.29,0,0,1,0-.37.72.72,0,0,1,.29-.21.78.78,0,0,0-.24.24.19.19,0,0,0,.08.25.82.82,0,0,0,.6.13h0a.13.13,0,0,1,.15.1.12.12,0,0,1-.1.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.82,72.85a1,1,0,0,1-.71-.29.29.29,0,0,1,0-.38.75.75,0,0,1,.3-.2.72.72,0,0,0-.25.24.19.19,0,0,0,.08.25.84.84,0,0,0,.59.14h0a.14.14,0,0,1,.15.1.13.13,0,0,1-.1.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.77,73.38a1,1,0,0,1-.71-.29.29.29,0,0,1,0-.38.83.83,0,0,1,.3-.2.78.78,0,0,0-.24.24.18.18,0,0,0,.07.25.87.87,0,0,0,.6.13h0a.13.13,0,0,1,.15.09.13.13,0,0,1-.1.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,74a1,1,0,0,1-.72-.27.28.28,0,0,1-.05-.38.66.66,0,0,1,.29-.21.78.78,0,0,0-.24.24.19.19,0,0,0,.08.25.81.81,0,0,0,.6.12h0A.13.13,0,1,1,24,74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.91,75.27a1,1,0,0,1-.72-.26.29.29,0,0,1-.06-.38.68.68,0,0,1,.29-.21.69.69,0,0,0-.24.24.18.18,0,0,0,.09.25.81.81,0,0,0,.6.11h0a.12.12,0,0,1,.15.09.11.11,0,0,1-.09.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.54,76.74a1,1,0,0,1-.73-.25.29.29,0,0,1-.07-.37A.77.77,0,0,1,22,75.9a.68.68,0,0,0-.23.25.19.19,0,0,0,.09.25.8.8,0,0,0,.6.09h0a.13.13,0,0,1,.16.09.14.14,0,0,1-.09.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.73,78.42a1,1,0,0,1-.73-.23.28.28,0,0,1-.08-.37.77.77,0,0,1,.28-.23.75.75,0,0,0-.22.25.2.2,0,0,0,.1.25.8.8,0,0,0,.6.08h0a.13.13,0,0,1,.16.09.12.12,0,0,1-.09.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.51,80.53a1,1,0,0,1-.74-.22.3.3,0,0,1-.08-.37.77.77,0,0,1,.28-.23.62.62,0,0,0-.22.26.19.19,0,0,0,.1.24.82.82,0,0,0,.61.07h0a.12.12,0,0,1,.15.08.12.12,0,0,1-.08.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.61,83.16a1,1,0,0,1-.74-.2.31.31,0,0,1-.08-.38.74.74,0,0,1,.27-.23.77.77,0,0,0-.22.26.2.2,0,0,0,.11.25.84.84,0,0,0,.6.06h0a.12.12,0,0,1,.16.08.13.13,0,0,1-.08.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.41,86.74a1,1,0,0,1-.7-.33.29.29,0,0,1,0-.38.8.8,0,0,1,.3-.19.79.79,0,0,0-.25.23.18.18,0,0,0,.06.25.87.87,0,0,0,.59.17h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,89.05a1,1,0,0,1-.61-.47.29.29,0,0,1,.06-.38.9.9,0,0,1,.34-.11.79.79,0,0,0-.3.16.2.2,0,0,0,0,.27.83.83,0,0,0,.53.29h0a.12.12,0,0,1,.12.13.14.14,0,0,1-.14.12S28,89.06,28,89.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.7,90.46a1,1,0,0,1-.55-.55.31.31,0,0,1,.11-.37.92.92,0,0,1,.36-.07.77.77,0,0,0-.32.13.19.19,0,0,0,0,.26.83.83,0,0,0,.49.36h0a.12.12,0,0,1,.1.15.11.11,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.77,92a1,1,0,0,1-.43-.63.29.29,0,0,1,.17-.34.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.07.18.18,0,0,0-.08.25.82.82,0,0,0,.42.44h0a.12.12,0,0,1,.07.17.14.14,0,0,1-.17.07S29.78,92,29.77,92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.31,93.46a1,1,0,0,1-.35-.68.3.3,0,0,1,.22-.32.87.87,0,0,1,.36.05.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.87.87,0,0,0,.37.5h0a.13.13,0,0,1-.12.22A0,0,0,0,1,30.31,93.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.15,94.59a1,1,0,0,1-.24-.74.29.29,0,0,1,.27-.27.75.75,0,0,1,.34.09.79.79,0,0,0-.34,0,.18.18,0,0,0-.14.22.82.82,0,0,0,.28.54h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.74,96.11a1,1,0,0,1-.14-.76.3.3,0,0,1,.3-.24.91.91,0,0,1,.33.15.67.67,0,0,0-.33-.08.17.17,0,0,0-.17.19.82.82,0,0,0,.2.58h0a.13.13,0,0,1-.19.17S31.74,96.12,31.74,96.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.61,98.84a1,1,0,0,1,0-.77A.29.29,0,0,1,33,97.9a.7.7,0,0,1,.29.22A.84.84,0,0,0,33,98a.2.2,0,0,0-.21.16.8.8,0,0,0,.08.61h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S32.62,98.85,32.61,98.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.73,100.77a1,1,0,0,1,.17-.75.3.3,0,0,1,.37-.1.8.8,0,0,1,.25.27.66.66,0,0,0-.27-.21.19.19,0,0,0-.24.11.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.13.13,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.21,99.74a1,1,0,0,1,.18-.75.3.3,0,0,1,.37-.1.8.8,0,0,1,.25.27,1,1,0,0,0-.27-.21.19.19,0,0,0-.24.11.82.82,0,0,0,0,.61h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.72,97.62a1,1,0,0,1,.09-.76.27.27,0,0,1,.35-.14.79.79,0,0,1,.28.23.83.83,0,0,0-.3-.17.18.18,0,0,0-.22.14.8.8,0,0,0,0,.61h0a.11.11,0,0,1-.05.16.12.12,0,0,1-.17,0S33.72,97.63,33.72,97.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.38,95.22a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2.81.81,0,0,1,.31.19.87.87,0,0,0-.32-.13.2.2,0,0,0-.2.18.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.69,92.59a1,1,0,0,1-.21-.75.3.3,0,0,1,.28-.26.8.8,0,0,1,.34.11.8.8,0,0,0-.34,0c-.11,0-.15.11-.15.22a.83.83,0,0,0,.25.55h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0A0,0,0,0,0,32.69,92.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.2,90.9a1,1,0,0,1-.32-.71.3.3,0,0,1,.24-.3.83.83,0,0,1,.35.06.78.78,0,0,0-.34,0,.19.19,0,0,0-.12.24.88.88,0,0,0,.34.51h0a.13.13,0,1,1-.14.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.38,88.72a1,1,0,0,1-.46-.62.3.3,0,0,1,.16-.34.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.18.18,0,0,0-.07.25.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.53,86.1a1.06,1.06,0,0,1-.55-.55.3.3,0,0,1,.11-.36.78.78,0,0,1,.36-.08.77.77,0,0,0-.32.13.19.19,0,0,0,0,.26.77.77,0,0,0,.5.35h0a.12.12,0,0,1-.06.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.7,84.21a1,1,0,0,1-.63-.45.29.29,0,0,1,0-.38.83.83,0,0,1,.34-.13.71.71,0,0,0-.29.18.19.19,0,0,0,0,.26.83.83,0,0,0,.55.27h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,81.93a1,1,0,0,1-.69-.34.29.29,0,0,1,0-.38.76.76,0,0,1,.31-.18.7.7,0,0,0-.26.22.19.19,0,0,0,.06.26.87.87,0,0,0,.59.17h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.63,80.05a1,1,0,0,1-.73-.23.28.28,0,0,1-.08-.37.89.89,0,0,1,.29-.23.8.8,0,0,0-.23.26.2.2,0,0,0,.1.25.84.84,0,0,0,.6.08h0a.12.12,0,0,1,.15.08.13.13,0,0,1-.08.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.06,78.36a1,1,0,0,1-.73-.24.29.29,0,0,1-.07-.37.77.77,0,0,1,.28-.23.9.9,0,0,0-.22.26.19.19,0,0,0,.09.25.91.91,0,0,0,.6.09h0a.13.13,0,0,1,.07.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.74,76a1,1,0,0,1-.73-.26.28.28,0,0,1-.05-.37.7.7,0,0,1,.29-.22.71.71,0,0,0-.24.25.19.19,0,0,0,.09.25.86.86,0,0,0,.6.11h0a.14.14,0,0,1,.15.1.13.13,0,0,1-.09.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.78,73.6a1,1,0,0,1-.72-.29.3.3,0,0,1,0-.38.83.83,0,0,1,.3-.2.88.88,0,0,0-.24.23.19.19,0,0,0,.07.26.81.81,0,0,0,.6.13h0a.13.13,0,0,1,.15.1.12.12,0,0,1-.1.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.89,73.23a1,1,0,0,1-.71-.29.28.28,0,0,1,0-.38.66.66,0,0,1,.3-.2.62.62,0,0,0-.25.23.21.21,0,0,0,.07.26.82.82,0,0,0,.6.13h0a.13.13,0,0,1,.15.1.14.14,0,0,1-.1.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.77,75.09a1,1,0,0,1-.72-.27.3.3,0,0,1-.05-.38.75.75,0,0,1,.3-.21.78.78,0,0,0-.24.24.19.19,0,0,0,.08.25.81.81,0,0,0,.6.12h0a.13.13,0,0,1,.05.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.44,77.32a1,1,0,0,1-.73-.25.29.29,0,0,1-.06-.38.75.75,0,0,1,.29-.21.78.78,0,0,0-.24.24.22.22,0,0,0,.09.26.86.86,0,0,0,.6.1h0a.11.11,0,0,1,.15.09.12.12,0,0,1-.09.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.85,79.75a1,1,0,0,1-.73-.24.29.29,0,0,1-.07-.38.74.74,0,0,1,.28-.22.78.78,0,0,0-.23.25.2.2,0,0,0,.1.25.8.8,0,0,0,.6.09h0a.12.12,0,0,1,.15.09.11.11,0,0,1-.09.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.54,82a1,1,0,0,1-.72-.28.29.29,0,0,1,0-.37.72.72,0,0,1,.29-.21.78.78,0,0,0-.24.24.19.19,0,0,0,.08.25.87.87,0,0,0,.6.13h0a.13.13,0,0,1,.15.1.13.13,0,0,1-.1.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,83.51a1.05,1.05,0,0,1-.69-.35.3.3,0,0,1,0-.38,1,1,0,0,1,.32-.18.84.84,0,0,0-.27.22.19.19,0,0,0,.06.26.84.84,0,0,0,.58.18h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.57,85.15a1,1,0,0,1-.64-.43.29.29,0,0,1,0-.38.87.87,0,0,1,.33-.14.77.77,0,0,0-.28.19.19.19,0,0,0,0,.26.85.85,0,0,0,.56.25h0a.12.12,0,0,1,.12.12.12.12,0,0,1-.12.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.84,87.23a1,1,0,0,1-.54-.55.3.3,0,0,1,.11-.36.92.92,0,0,1,.36-.07.76.76,0,0,0-.32.12.19.19,0,0,0,0,.26.8.8,0,0,0,.49.36h0a.14.14,0,0,1,.1.15.13.13,0,0,1-.16.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.77,89.84a1,1,0,0,1-.33-.7.3.3,0,0,1,.22-.31.85.85,0,0,1,.36,0,.78.78,0,0,0-.34,0,.22.22,0,0,0-.12.24.84.84,0,0,0,.35.5h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,93.41a1,1,0,0,1-.06-.77.3.3,0,0,1,.32-.21.79.79,0,0,1,.31.18.76.76,0,0,0-.32-.11c-.11,0-.17.08-.19.18a.8.8,0,0,0,.15.59h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.18,0A0,0,0,0,1,34,93.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.86,97.65a1,1,0,0,1,.24-.73.29.29,0,0,1,.38-.06.7.7,0,0,1,.22.29.77.77,0,0,0-.25-.23.19.19,0,0,0-.25.08.89.89,0,0,0-.1.61h0a.12.12,0,0,1-.24.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.85,101.37a1,1,0,0,1,.39-.67.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32,1,1,0,0,0-.2-.28.22.22,0,0,0-.27,0,.84.84,0,0,0-.21.57h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.72,104a1,1,0,0,1,.46-.61.3.3,0,0,1,.38,0,.78.78,0,0,1,.12.34.79.79,0,0,0-.16-.3.21.21,0,0,0-.27,0A.82.82,0,0,0,38,104h0a.12.12,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.91,105.61a1,1,0,0,1,.52-.58.3.3,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31.21.21,0,0,0-.27,0,.81.81,0,0,0-.32.52h0a.12.12,0,0,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.9,107a1,1,0,0,1,.54-.55.3.3,0,0,1,.37.11.84.84,0,0,1,.07.35.88.88,0,0,0-.12-.32.21.21,0,0,0-.27,0,.91.91,0,0,0-.35.5h0a.12.12,0,0,1-.15.1.13.13,0,0,1-.1-.15S39.9,107,39.9,107Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.07,108a1,1,0,0,1,.56-.53.29.29,0,0,1,.36.12.76.76,0,0,1,.06.36.72.72,0,0,0-.11-.33.21.21,0,0,0-.27,0,.84.84,0,0,0-.36.49h0a.14.14,0,0,1-.16.09.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.34,107.7a1,1,0,0,1,.57-.52.3.3,0,0,1,.36.13.75.75,0,0,1,0,.36.8.8,0,0,0-.1-.32.19.19,0,0,0-.26,0,.83.83,0,0,0-.39.48h0a.14.14,0,0,1-.15.09.13.13,0,0,1-.09-.16S42.33,107.7,42.34,107.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,106.27a1,1,0,0,1,.59-.49.28.28,0,0,1,.35.14.76.76,0,0,1,0,.36.74.74,0,0,0-.09-.33.2.2,0,0,0-.26,0,.85.85,0,0,0-.4.46h0a.13.13,0,1,1-.24-.08A0,0,0,0,1,44,106.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.05,104a1,1,0,0,1,.61-.47.29.29,0,0,1,.35.15.88.88,0,0,1,0,.36.9.9,0,0,0-.09-.33.18.18,0,0,0-.25-.06.82.82,0,0,0-.42.44h0a.13.13,0,0,1-.16.08A.12.12,0,0,1,47,104,0,0,0,0,1,47.05,104Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.65,100.53a1,1,0,0,1,.61-.47.29.29,0,0,1,.35.15.88.88,0,0,1,0,.36.9.9,0,0,0-.09-.33.19.19,0,0,0-.25-.06.84.84,0,0,0-.42.45h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16S48.65,100.54,48.65,100.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.59,97.73a1,1,0,0,1,.6-.47.28.28,0,0,1,.35.15.74.74,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.06.82.82,0,0,0-.41.45h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.07-.16A0,0,0,0,1,50.59,97.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.08,94.68a1.05,1.05,0,0,1,.6-.48.3.3,0,0,1,.35.15.76.76,0,0,1,0,.36.76.76,0,0,0-.08-.33.2.2,0,0,0-.26-.06.84.84,0,0,0-.41.46h0a.14.14,0,0,1-.16.08.12.12,0,0,1-.08-.16A0,0,0,0,1,52.08,94.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.78,93.13a1,1,0,0,1,.61-.48.3.3,0,0,1,.35.15.73.73,0,0,1,0,.36.73.73,0,0,0-.08-.33.2.2,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16A0,0,0,0,1,53.78,93.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.81,91.42a1,1,0,0,1,.61-.48.3.3,0,0,1,.35.15.9.9,0,0,1,0,.36.9.9,0,0,0-.09-.33.2.2,0,0,0-.26-.06.87.87,0,0,0-.41.46h0a.12.12,0,0,1-.16.07.12.12,0,0,1-.08-.15S54.81,91.43,54.81,91.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.56,89.61a1,1,0,0,1,.6-.49.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.6.6,0,0,0-.09-.33.18.18,0,0,0-.25-.06.87.87,0,0,0-.41.46h0a.13.13,0,1,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.5,90.83a1,1,0,0,1,.59-.48.3.3,0,0,1,.36.14.74.74,0,0,1,0,.36.7.7,0,0,0-.09-.33.19.19,0,0,0-.26,0,.82.82,0,0,0-.4.46h0a.13.13,0,0,1-.16.09.13.13,0,0,1-.08-.16A0,0,0,0,1,53.5,90.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.6,93.07a1,1,0,0,1,.59-.5.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.8.8,0,0,0-.4.47h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16S50.59,93.07,50.6,93.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.39,95.05a1,1,0,0,1,.6-.49.29.29,0,0,1,.35.14.91.91,0,0,1,0,.36.85.85,0,0,0-.09-.32.19.19,0,0,0-.26-.06.81.81,0,0,0-.4.46h0a.12.12,0,0,1-.15.08.13.13,0,0,1-.09-.16S49.39,95.06,49.39,95.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.71,97.45a1,1,0,0,1,.6-.48.29.29,0,0,1,.35.14.75.75,0,0,1,0,.36.78.78,0,0,0-.09-.33.2.2,0,0,0-.26,0,.8.8,0,0,0-.4.45h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.16A0,0,0,0,1,48.71,97.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.78,99.42a1,1,0,0,1,.6-.48.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.06.82.82,0,0,0-.41.45h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16S47.77,99.42,47.78,99.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,101.71a1,1,0,0,1,.61-.47.29.29,0,0,1,.35.16.73.73,0,0,1,0,.36.77.77,0,0,0-.08-.34.2.2,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.14.14,0,0,1-.16.08.12.12,0,0,1-.08-.16A0,0,0,0,1,47,101.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.6,103.77a1,1,0,0,1,.61-.47.3.3,0,0,1,.35.15.73.73,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.06.82.82,0,0,0-.41.45h0a.12.12,0,0,1-.16.07.12.12,0,0,1-.08-.16A0,0,0,0,0,45.6,103.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.49,103.76a1,1,0,0,1,.61-.48.3.3,0,0,1,.35.15.88.88,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.82.82,0,0,0-.4.46h0a.13.13,0,0,1-.16.08.14.14,0,0,1-.08-.16A0,0,0,0,0,44.49,103.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.22,101.9a1,1,0,0,1,.6-.48.29.29,0,0,1,.35.15.75.75,0,0,1,0,.36.73.73,0,0,0-.08-.33.2.2,0,0,0-.26-.06.82.82,0,0,0-.41.45h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16S45.21,101.9,45.22,101.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.11,97a1,1,0,0,1,.6-.5.29.29,0,0,1,.35.15.86.86,0,0,1,0,.35.85.85,0,0,0-.09-.32.19.19,0,0,0-.26-.06.81.81,0,0,0-.4.46h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16A0,0,0,0,0,47.11,97Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.23,91.6a1,1,0,0,1,.57-.52.3.3,0,0,1,.36.13.91.91,0,0,1,.06.36.76.76,0,0,0-.11-.32.19.19,0,0,0-.26,0,.85.85,0,0,0-.38.48h0a.13.13,0,0,1-.16.09.12.12,0,0,1-.09-.15S49.23,91.61,49.23,91.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.11,88.71a1.05,1.05,0,0,1,.57-.53.29.29,0,0,1,.36.12.91.91,0,0,1,.06.36.76.76,0,0,0-.12-.32.19.19,0,0,0-.26,0,.87.87,0,0,0-.37.48h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.1-.15S51.11,88.72,51.11,88.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,85.7a1,1,0,0,1,.56-.53.29.29,0,0,1,.36.12.84.84,0,0,1,.07.35.88.88,0,0,0-.12-.32.2.2,0,0,0-.26,0,.86.86,0,0,0-.37.5h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.83,83.88a1,1,0,0,1,.54-.55.28.28,0,0,1,.36.1.74.74,0,0,1,.08.35.66.66,0,0,0-.13-.31.19.19,0,0,0-.26,0,.91.91,0,0,0-.35.5h0a.12.12,0,0,1-.15.1.13.13,0,0,1-.1-.14A0,0,0,0,0,54.83,83.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.57,81.53a1,1,0,0,1,.5-.58.28.28,0,0,1,.37.08.88.88,0,0,1,.1.34.71.71,0,0,0-.15-.3.18.18,0,0,0-.26,0,.79.79,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14A0,0,0,0,1,55.57,81.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.31,80.13a1,1,0,0,1,.48-.6.29.29,0,0,1,.38.06.88.88,0,0,1,.1.35.69.69,0,0,0-.15-.31.21.21,0,0,0-.27,0,.83.83,0,0,0-.29.54h0a.11.11,0,0,1-.13.11.14.14,0,0,1-.12-.14A0,0,0,0,1,56.31,80.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.38,79.66a1,1,0,0,1,.48-.59.29.29,0,0,1,.38.07.83.83,0,0,1,.1.34.61.61,0,0,0-.15-.3.2.2,0,0,0-.27,0,.87.87,0,0,0-.3.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14A0,0,0,0,1,57.38,79.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.63,78.2a1,1,0,0,1,.44-.64.3.3,0,0,1,.38,0,1,1,0,0,1,.13.34.71.71,0,0,0-.18-.29.19.19,0,0,0-.26,0,.77.77,0,0,0-.26.55h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.67,77.5a1,1,0,0,1,.39-.66.3.3,0,0,1,.38,0,.92.92,0,0,1,.16.33,1,1,0,0,0-.2-.28.19.19,0,0,0-.26,0,.78.78,0,0,0-.22.57h0a.15.15,0,0,1-.13.14.14.14,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,78.48a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.63.63,0,0,0-.19-.28.2.2,0,0,0-.27,0,.87.87,0,0,0-.22.57h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.15,80.23a1,1,0,0,1,.43-.65.3.3,0,0,1,.38,0,.87.87,0,0,1,.14.33.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.8.8,0,0,0-.25.55h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.38,81.65a1,1,0,0,1,.45-.63.29.29,0,0,1,.38,0,.84.84,0,0,1,.13.33.79.79,0,0,0-.18-.29.2.2,0,0,0-.26,0,.85.85,0,0,0-.27.55h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.78,83a1,1,0,0,1,.49-.6.29.29,0,0,1,.37.08.72.72,0,0,1,.11.34.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0A.82.82,0,0,0,53,83h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14A0,0,0,0,0,52.78,83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.52,85.34a1,1,0,0,1,.53-.56.31.31,0,0,1,.37.1.77.77,0,0,1,.08.35.79.79,0,0,0-.13-.31.19.19,0,0,0-.27,0,.86.86,0,0,0-.34.5h0a.12.12,0,0,1-.15.1.13.13,0,0,1-.1-.14S52.52,85.35,52.52,85.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.17,87.1a1,1,0,0,1,.57-.52.29.29,0,0,1,.36.12.91.91,0,0,1,.06.36.87.87,0,0,0-.12-.33.2.2,0,0,0-.26,0,.82.82,0,0,0-.37.49h0a.13.13,0,0,1-.15.09.14.14,0,0,1-.1-.15A0,0,0,0,0,53.17,87.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.09,89.42a1,1,0,0,1,.59-.5.29.29,0,0,1,.35.14.73.73,0,0,1,0,.35.86.86,0,0,0-.1-.32.19.19,0,0,0-.26,0,.78.78,0,0,0-.39.47h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16A0,0,0,0,0,53.09,89.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.65,92.61a1,1,0,0,1,.6-.49.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.6.6,0,0,0-.09-.33.19.19,0,0,0-.25-.06.87.87,0,0,0-.41.46h0a.14.14,0,0,1-.16.08.12.12,0,0,1-.08-.16S52.64,92.61,52.65,92.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.2,95.29a1,1,0,0,1,.61-.47.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36A.73.73,0,0,0,54.1,95a.2.2,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.16,98.73a1,1,0,0,1,.61-.47.3.3,0,0,1,.35.16.73.73,0,0,1,0,.36.7.7,0,0,0-.08-.33.18.18,0,0,0-.25-.07.84.84,0,0,0-.42.45h0a.14.14,0,0,1-.16.08.14.14,0,0,1-.08-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.89,102.68a1,1,0,0,1,.61-.47.28.28,0,0,1,.35.16.88.88,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.07.82.82,0,0,0-.41.45h0a.12.12,0,0,1-.16.08.14.14,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.12,101.7a1,1,0,0,1,.61-.47.3.3,0,0,1,.35.16.73.73,0,0,1,0,.36.7.7,0,0,0-.08-.33.18.18,0,0,0-.25-.07.84.84,0,0,0-.42.45h0a.12.12,0,0,1-.23-.09S53.11,101.71,53.12,101.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.37,95.87A1,1,0,0,1,56,95.4a.3.3,0,0,1,.34.16.74.74,0,0,1,0,.36.72.72,0,0,0-.07-.33.21.21,0,0,0-.26-.07.84.84,0,0,0-.42.45h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.08-.16S55.37,95.88,55.37,95.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.72,86.38a1,1,0,0,1,.59-.5.3.3,0,0,1,.36.14.89.89,0,0,1,0,.36.71.71,0,0,0-.1-.33.2.2,0,0,0-.26,0,.82.82,0,0,0-.39.47h0a.13.13,0,0,1-.25-.07A0,0,0,0,0,57.72,86.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.24,81.85a1,1,0,0,1,.53-.55.29.29,0,0,1,.37.1.73.73,0,0,1,.07.35.65.65,0,0,0-.12-.31.2.2,0,0,0-.27,0,.81.81,0,0,0-.34.5h0a.11.11,0,0,1-.14.1.12.12,0,0,1-.1-.15A0,0,0,0,1,58.24,81.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.49,78.65a1,1,0,0,1,.47-.6.29.29,0,0,1,.38.06.76.76,0,0,1,.11.34.87.87,0,0,0-.16-.3.19.19,0,0,0-.26,0,.79.79,0,0,0-.29.53h0a.13.13,0,0,1-.26,0S58.49,78.66,58.49,78.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.83,76.88a.94.94,0,0,1,.41-.65.29.29,0,0,1,.38,0,.89.89,0,0,1,.15.33.77.77,0,0,0-.19-.28.2.2,0,0,0-.27,0,.82.82,0,0,0-.23.56h0a.13.13,0,0,1-.26,0S57.82,76.88,57.83,76.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.65,76.31a1,1,0,0,1,.37-.67.29.29,0,0,1,.38,0,.71.71,0,0,1,.17.32.74.74,0,0,0-.21-.28.19.19,0,0,0-.26,0,.83.83,0,0,0-.2.57h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.42,75a1,1,0,0,1,.33-.7.29.29,0,0,1,.38,0,.77.77,0,0,1,.19.31.73.73,0,0,0-.23-.26.19.19,0,0,0-.26.06.86.86,0,0,0-.16.59h0a.13.13,0,0,1-.1.15.12.12,0,0,1-.14-.11A0,0,0,0,1,56.42,75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,74.18a1,1,0,0,1,.34-.7.29.29,0,0,1,.38,0,1,1,0,0,1,.18.31.8.8,0,0,0-.22-.26.19.19,0,0,0-.26.06.78.78,0,0,0-.17.58h0a.13.13,0,0,1-.11.14A.12.12,0,0,1,58,74.2S58,74.18,58,74.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.79,72.83a1,1,0,0,1,.32-.71.3.3,0,0,1,.38,0,.84.84,0,0,1,.19.31.7.7,0,0,0-.23-.25.19.19,0,0,0-.26.06.85.85,0,0,0-.15.59h0a.13.13,0,0,1-.1.15.13.13,0,0,1-.15-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.67,70.12A1,1,0,0,1,58,69.4a.29.29,0,0,1,.38-.05.75.75,0,0,1,.2.3.61.61,0,0,0-.24-.24.18.18,0,0,0-.25.07.87.87,0,0,0-.13.6h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.8,66.3a1,1,0,0,1,.22-.74.31.31,0,0,1,.38-.08.89.89,0,0,1,.23.28.77.77,0,0,0-.26-.22.2.2,0,0,0-.25.1.81.81,0,0,0-.07.61h0a.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.08A0,0,0,0,1,57.8,66.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.73,56.9a1,1,0,0,1,.22-.74.28.28,0,0,1,.37-.08.8.8,0,0,1,.24.28.77.77,0,0,0-.26-.22.19.19,0,0,0-.25.1.79.79,0,0,0-.07.6h0a.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.7,52.39a1,1,0,0,1,.15-.76.3.3,0,0,1,.37-.11.85.85,0,0,1,.25.25.67.67,0,0,0-.28-.19.18.18,0,0,0-.23.12.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.11.11,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,45.43a1.07,1.07,0,0,1,.21-.75.3.3,0,0,1,.37-.08,1,1,0,0,1,.24.27.86.86,0,0,0-.26-.21.2.2,0,0,0-.25.1.82.82,0,0,0-.06.61h0a.12.12,0,0,1-.08.15.12.12,0,0,1-.16-.08A0,0,0,0,0,62,45.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.27,46.39a1,1,0,0,1,.2-.74.29.29,0,0,1,.37-.09.88.88,0,0,1,.24.27.76.76,0,0,0-.27-.21.18.18,0,0,0-.24.1.82.82,0,0,0-.06.61h0a.13.13,0,0,1-.24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.1,45a1,1,0,0,1,.19-.74.3.3,0,0,1,.37-.1,1,1,0,0,1,.24.27.79.79,0,0,0-.27-.21.19.19,0,0,0-.24.11.82.82,0,0,0,0,.61h0a.12.12,0,0,1-.08.16A.13.13,0,0,1,61.1,45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.58,45.91a1,1,0,0,1,.16-.75.28.28,0,0,1,.36-.1.78.78,0,0,1,.26.25.9.9,0,0,0-.28-.2.2.2,0,0,0-.24.12.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.56,47.53a1,1,0,0,1,.17-.75.29.29,0,0,1,.37-.1.88.88,0,0,1,.25.26.73.73,0,0,0-.27-.2.19.19,0,0,0-.24.11.82.82,0,0,0,0,.61h0a.12.12,0,0,1-.08.16.12.12,0,0,1-.16-.07S61.56,47.54,61.56,47.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,46.87a1,1,0,0,1,.16-.76.28.28,0,0,1,.36-.1.78.78,0,0,1,.26.25.9.9,0,0,0-.28-.2.2.2,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.83,43.65a1,1,0,0,1,.23-.73.3.3,0,0,1,.38-.08.86.86,0,0,1,.22.29.68.68,0,0,0-.25-.23.2.2,0,0,0-.25.09.82.82,0,0,0-.08.61h0a.11.11,0,0,1-.09.15.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.63,42.32a1,1,0,0,1,.28-.72.29.29,0,0,1,.38-.05.9.9,0,0,1,.21.3.71.71,0,0,0-.25-.24.18.18,0,0,0-.25.07.81.81,0,0,0-.12.6h0a.12.12,0,0,1-.1.15.13.13,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.29,38.08a1,1,0,0,1,.41-.66.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.73.73,0,0,0-.19-.28.2.2,0,0,0-.27,0,.88.88,0,0,0-.23.57h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,36.53a1,1,0,0,1,.42-.65.29.29,0,0,1,.38,0,.87.87,0,0,1,.14.33.7.7,0,0,0-.19-.28.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.91,36.24a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33.8.8,0,0,0-.18-.28.19.19,0,0,0-.27,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.4,37.41a1,1,0,0,1,.41-.66.29.29,0,0,1,.38,0,.89.89,0,0,1,.15.33.92.92,0,0,0-.19-.28.2.2,0,0,0-.27,0,.88.88,0,0,0-.23.57h0a.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.75,39.15a1,1,0,0,1,.38-.67.3.3,0,0,1,.38,0,.92.92,0,0,1,.16.33.65.65,0,0,0-.2-.28.2.2,0,0,0-.26,0,.84.84,0,0,0-.21.57h0a.13.13,0,0,1-.26,0A0,0,0,0,1,65.75,39.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.29,44.82a1,1,0,0,1,.28-.72.29.29,0,0,1,.38-.05.9.9,0,0,1,.21.3.61.61,0,0,0-.24-.24.19.19,0,0,0-.26.07.86.86,0,0,0-.12.6h0a.12.12,0,1,1-.24.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.46,49.76a1,1,0,0,1,.27-.72.29.29,0,0,1,.38-.06.9.9,0,0,1,.21.3.78.78,0,0,0-.24-.24.2.2,0,0,0-.26.08.86.86,0,0,0-.11.6h0a.13.13,0,1,1-.25.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.09,52.26a1,1,0,0,1,.3-.71.29.29,0,0,1,.38,0,.83.83,0,0,1,.2.3.79.79,0,0,0-.23-.25.21.21,0,0,0-.26.07.88.88,0,0,0-.14.6h0a.12.12,0,0,1-.1.14.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.58,53.86a1,1,0,0,1,.37-.67.28.28,0,0,1,.38,0,.81.81,0,0,1,.16.32.68.68,0,0,0-.2-.27.19.19,0,0,0-.26,0,.81.81,0,0,0-.2.58h0a.13.13,0,0,1-.26,0A0,0,0,0,1,67.58,53.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.59,53.91a1,1,0,0,1,.33-.7.29.29,0,0,1,.38,0,.84.84,0,0,1,.19.31.83.83,0,0,0-.23-.26.18.18,0,0,0-.25.06.82.82,0,0,0-.17.59h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.2,50.29a1,1,0,0,1,.37-.67.3.3,0,0,1,.39,0,.88.88,0,0,1,.16.32.79.79,0,0,0-.2-.27.21.21,0,0,0-.27,0,.86.86,0,0,0-.2.58h0a.12.12,0,0,1-.12.13.11.11,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.16,45.82a1,1,0,0,1,.43-.63.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.33,1,1,0,0,0-.18-.29.21.21,0,0,0-.27,0,.83.83,0,0,0-.25.56h0a.14.14,0,0,1-.13.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.23,44a1,1,0,0,1,.42-.65.3.3,0,0,1,.38,0,.73.73,0,0,1,.14.33.79.79,0,0,0-.18-.29.19.19,0,0,0-.26,0,.76.76,0,0,0-.25.55h0a.14.14,0,0,1-.13.13.13.13,0,0,1-.13-.13S73.22,44,73.23,44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.56,40.27a1,1,0,0,1,.4-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.77.77,0,0,0-.19-.28.19.19,0,0,0-.26,0,.78.78,0,0,0-.23.57h0a.13.13,0,0,1-.13.12.12.12,0,0,1-.13-.12A0,0,0,0,1,73.56,40.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.85,29.06a1,1,0,0,1,.46-.61.29.29,0,0,1,.37.05,1,1,0,0,1,.13.34,1,1,0,0,0-.17-.3.21.21,0,0,0-.27,0,.92.92,0,0,0-.28.54h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13A0,0,0,0,1,69.85,29.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.29,18.81a1,1,0,0,1,.52-.57.28.28,0,0,1,.36.09.79.79,0,0,1,.09.35.79.79,0,0,0-.13-.31.21.21,0,0,0-.27,0,.87.87,0,0,0-.33.52h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,60.29,18.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.45,41.78a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.73.73,0,0,1,.36,0,.77.77,0,0,0-.34.08.19.19,0,0,0-.06.25.8.8,0,0,0,.45.42h0a.14.14,0,0,1,.08.17.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.87,42.58a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.35.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.17.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.22,42.38a1,1,0,0,1-.46-.62.28.28,0,0,1,.17-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.21.21,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.11.11,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.9,43.67a1,1,0,0,1-.43-.63.29.29,0,0,1,.17-.34.87.87,0,0,1,.36,0,.7.7,0,0,0-.33.07.19.19,0,0,0-.08.25.82.82,0,0,0,.42.44h0a.12.12,0,0,1,.07.17.14.14,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.07,44.5a1,1,0,0,1-.43-.64.29.29,0,0,1,.18-.34.87.87,0,0,1,.36,0,.93.93,0,0,0-.34.06.21.21,0,0,0-.08.25.92.92,0,0,0,.42.45h0a.12.12,0,0,1,.07.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.8,45.36a1,1,0,0,1-.41-.65.29.29,0,0,1,.19-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.83.83,0,0,0,.41.45h0a.12.12,0,0,1,.06.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.41,47.15a1,1,0,0,1-.37-.68.29.29,0,0,1,.21-.32.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.77.77,0,0,0,.38.48h0a.12.12,0,0,1,.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.41,49.26a1,1,0,0,1-.33-.7.28.28,0,0,1,.22-.3.72.72,0,0,1,.36.05.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.86.86,0,0,0,.35.51h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17.05S22.41,49.27,22.41,49.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.41,51.31a1,1,0,0,1-.29-.71.3.3,0,0,1,.25-.3.82.82,0,0,1,.35.08.78.78,0,0,0-.34,0,.2.2,0,0,0-.13.23.86.86,0,0,0,.32.53h0a.13.13,0,1,1-.15.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.71,52.49a1,1,0,0,1-.27-.73.28.28,0,0,1,.25-.28.82.82,0,0,1,.35.08.78.78,0,0,0-.34,0,.21.21,0,0,0-.14.23.87.87,0,0,0,.31.53h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.79,54.53a1,1,0,0,1-.23-.73c0-.13.12-.28.26-.27a.71.71,0,0,1,.35.1.67.67,0,0,0-.34,0,.2.2,0,0,0-.15.22.82.82,0,0,0,.28.54h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.12,56.65a1,1,0,0,1-.2-.75.3.3,0,0,1,.28-.26.92.92,0,0,1,.34.12.8.8,0,0,0-.34,0c-.11,0-.15.11-.15.21a.83.83,0,0,0,.25.56h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,59.35a1,1,0,0,1-.27-.72.29.29,0,0,1,.25-.29.82.82,0,0,1,.35.08.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.86.86,0,0,0,.3.53h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.39,60.42a1,1,0,0,1-.33-.7.29.29,0,0,1,.22-.31.85.85,0,0,1,.36.05,1,1,0,0,0-.34,0,.19.19,0,0,0-.12.24.81.81,0,0,0,.36.5h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,59.26a1,1,0,0,1-.28-.72c0-.14.11-.29.25-.29a.86.86,0,0,1,.36.08.84.84,0,0,0-.35,0,.2.2,0,0,0-.13.23.78.78,0,0,0,.31.52h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.71,57.68a1,1,0,0,1-.2-.74.28.28,0,0,1,.27-.27,1,1,0,0,1,.35.12.69.69,0,0,0-.34,0,.19.19,0,0,0-.16.21.84.84,0,0,0,.26.55h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.64,57.17a1,1,0,0,1-.19-.75.3.3,0,0,1,.28-.26.92.92,0,0,1,.34.12.76.76,0,0,0-.33-.05.19.19,0,0,0-.16.21.87.87,0,0,0,.24.56h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.7,56.14a1,1,0,0,1-.2-.74.29.29,0,0,1,.27-.27.81.81,0,0,1,.35.12.67.67,0,0,0-.34,0,.19.19,0,0,0-.16.21.84.84,0,0,0,.26.55h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.44,54.46a1,1,0,0,1-.23-.74.3.3,0,0,1,.27-.27.85.85,0,0,1,.35.11.83.83,0,0,0-.34-.05.2.2,0,0,0-.15.22.83.83,0,0,0,.27.55h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.19,52.45a1,1,0,0,1-.26-.72.3.3,0,0,1,.26-.28.82.82,0,0,1,.35.08.8.8,0,0,0-.34,0,.18.18,0,0,0-.14.22.85.85,0,0,0,.29.54h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.07,51.14a1,1,0,0,1-.27-.72c0-.13.11-.29.24-.29a.83.83,0,0,1,.36.08.8.8,0,0,0-.34,0,.2.2,0,0,0-.14.23.82.82,0,0,0,.31.52h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.88,49.61a1,1,0,0,1-.3-.71.3.3,0,0,1,.24-.3.86.86,0,0,1,.36.07.79.79,0,0,0-.34,0,.2.2,0,0,0-.13.24.78.78,0,0,0,.33.51h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.71,48.18a1,1,0,0,1-.31-.7.29.29,0,0,1,.23-.31.81.81,0,0,1,.35.06,1,1,0,0,0-.34,0,.22.22,0,0,0-.12.24.88.88,0,0,0,.34.51h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.45,46.47a1,1,0,0,1-.34-.7.29.29,0,0,1,.22-.31.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.85.85,0,0,0,.36.5h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.93,44.23a1,1,0,0,1-.37-.68.29.29,0,0,1,.21-.32.87.87,0,0,1,.36,0,.94.94,0,0,0-.34,0,.19.19,0,0,0-.11.24.81.81,0,0,0,.38.48h0a.13.13,0,0,1-.12.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.56,41.64a1,1,0,0,1-.4-.66.3.3,0,0,1,.2-.33.88.88,0,0,1,.36,0,.94.94,0,0,0-.34,0,.2.2,0,0,0-.09.25.8.8,0,0,0,.39.47h0a.13.13,0,0,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.18,39.78a1,1,0,0,1-.41-.65c0-.13.05-.31.19-.33a.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.85.85,0,0,0,.41.46h0a.13.13,0,0,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,38.22a1,1,0,0,1-.39-.67.28.28,0,0,1,.2-.32.86.86,0,0,1,.36,0,.76.76,0,0,0-.34,0,.21.21,0,0,0-.1.25.91.91,0,0,0,.39.47h0a.13.13,0,0,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.69,36.74a1,1,0,0,1-.38-.67.29.29,0,0,1,.2-.33.85.85,0,0,1,.36,0,.76.76,0,0,0-.34,0,.21.21,0,0,0-.1.25.8.8,0,0,0,.39.47h0a.13.13,0,0,1-.12.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.43,35.18a1,1,0,0,1-.37-.68.3.3,0,0,1,.21-.32.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.1.25.85.85,0,0,0,.37.48h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.25,34.12a1,1,0,0,1-.35-.69.29.29,0,0,1,.22-.31.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.79.79,0,0,0,.36.49h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0S37.25,34.13,37.25,34.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.79,35.08a1,1,0,0,1-.33-.69.29.29,0,0,1,.22-.31.85.85,0,0,1,.36.05.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.87.87,0,0,0,.35.5h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.1,36.71a1,1,0,0,1-.35-.69.28.28,0,0,1,.21-.31.72.72,0,0,1,.36,0,.65.65,0,0,0-.34,0,.2.2,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.34,39.72a1,1,0,0,1-.37-.67.29.29,0,0,1,.21-.32.74.74,0,0,1,.36,0,.64.64,0,0,0-.34,0,.19.19,0,0,0-.11.24.81.81,0,0,0,.38.48h0a.13.13,0,1,1-.12.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.35,41.7A1,1,0,0,1,34,41a.29.29,0,0,1,.21-.32.74.74,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.25.87.87,0,0,0,.38.48h0a.13.13,0,1,1-.13.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.94,43.44a1,1,0,0,1-.36-.69.29.29,0,0,1,.22-.31.73.73,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.79.79,0,0,0,.36.49h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.05S33.94,43.45,33.94,43.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.14,45.81a1,1,0,0,1-.33-.7.28.28,0,0,1,.22-.3.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.87.87,0,0,0,.35.5h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.39,48.16a1,1,0,0,1-.31-.7.3.3,0,0,1,.24-.3.68.68,0,0,1,.35.06.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.82.82,0,0,0,.33.51h0a.13.13,0,1,1-.14.21S31.4,48.17,31.39,48.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.33,49.78a1,1,0,0,1-.29-.72.3.3,0,0,1,.24-.29,1,1,0,0,1,.36.07.79.79,0,0,0-.34,0,.19.19,0,0,0-.13.23.78.78,0,0,0,.31.52h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.61,51.46a1,1,0,0,1-.27-.72.28.28,0,0,1,.25-.29.68.68,0,0,1,.35.09.66.66,0,0,0-.34,0,.2.2,0,0,0-.13.23.81.81,0,0,0,.3.53h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.2,53.83A1,1,0,0,1,30,53.1a.3.3,0,0,1,.27-.28.9.9,0,0,1,.34.1.8.8,0,0,0-.34,0,.19.19,0,0,0-.14.22.83.83,0,0,0,.27.55h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.82,55.16a1,1,0,0,1-.21-.74.29.29,0,0,1,.27-.27.76.76,0,0,1,.34.11.67.67,0,0,0-.34,0,.19.19,0,0,0-.15.21A.83.83,0,0,0,30,55h0a.12.12,0,1,1-.16.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.7,57.19a1.09,1.09,0,0,1-.19-.75.31.31,0,0,1,.29-.26,1,1,0,0,1,.34.12.83.83,0,0,0-.34,0c-.11,0-.16.1-.16.21a.87.87,0,0,0,.24.56h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S28.71,57.19,28.7,57.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.8,59.06a1,1,0,0,1-.21-.74c0-.13.14-.28.28-.27a.8.8,0,0,1,.34.11.67.67,0,0,0-.34,0c-.11,0-.15.11-.15.21a.8.8,0,0,0,.26.55h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.36,61.41a1,1,0,0,1-.38-.67.3.3,0,0,1,.21-.32.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.81.81,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17,0S28.36,61.42,28.36,61.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.31,63.32a1,1,0,0,1-.5-.59.29.29,0,0,1,.14-.35.89.89,0,0,1,.36,0,.7.7,0,0,0-.33.09.19.19,0,0,0-.05.26.81.81,0,0,0,.46.4h0a.13.13,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.66,65.54a1,1,0,0,1-.6-.49.29.29,0,0,1,.07-.37.76.76,0,0,1,.35-.11.83.83,0,0,0-.31.16.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.65,67a1,1,0,0,1-.63-.44.28.28,0,0,1,0-.38.84.84,0,0,1,.33-.13.62.62,0,0,0-.29.18.19.19,0,0,0,0,.26.86.86,0,0,0,.55.26h0a.12.12,0,0,1,.12.13.12.12,0,0,1-.12.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.77,66.62a1,1,0,0,1-.62-.45.3.3,0,0,1,.05-.38,1,1,0,0,1,.34-.13.82.82,0,0,0-.29.17.19.19,0,0,0,0,.27.81.81,0,0,0,.54.27h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.67,64.65a1,1,0,0,1-.56-.53.28.28,0,0,1,.1-.37.72.72,0,0,1,.35-.08.9.9,0,0,0-.32.13.21.21,0,0,0,0,.27.88.88,0,0,0,.51.34h0a.13.13,0,0,1,.1.15.14.14,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.79,60a1,1,0,0,1-.28-.72.3.3,0,0,1,.24-.29.83.83,0,0,1,.36.08.8.8,0,0,0-.34,0,.2.2,0,0,0-.14.23.86.86,0,0,0,.32.53h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.68,56.46a1,1,0,0,1-.2-.74.3.3,0,0,1,.28-.27.92.92,0,0,1,.34.12.78.78,0,0,0-.33,0c-.12,0-.16.11-.16.21a.83.83,0,0,0,.25.56h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.21,52.39a1,1,0,0,1-.25-.73.29.29,0,0,1,.26-.28.75.75,0,0,1,.34.09,1,1,0,0,0-.34,0,.21.21,0,0,0-.14.23.85.85,0,0,0,.29.53h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.17,47.55a1,1,0,0,1-.31-.71.28.28,0,0,1,.23-.3.88.88,0,0,1,.36.06,1,1,0,0,0-.35,0,.22.22,0,0,0-.12.24.88.88,0,0,0,.34.51h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.74,43.9a1,1,0,0,1-.36-.68.31.31,0,0,1,.22-.32.82.82,0,0,1,.35,0,.78.78,0,0,0-.34,0,.2.2,0,0,0-.1.25.81.81,0,0,0,.37.48h0a.11.11,0,0,1,0,.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.56,40.55a1,1,0,0,1-.39-.67.29.29,0,0,1,.2-.32.86.86,0,0,1,.36,0,.76.76,0,0,0-.34,0,.21.21,0,0,0-.1.25.91.91,0,0,0,.39.47h0a.13.13,0,0,1-.11.23S33.56,40.56,33.56,40.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.89,38.08a1,1,0,0,1-.4-.66.28.28,0,0,1,.19-.33.88.88,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.83.83,0,0,0,.4.46h0a.12.12,0,1,1-.11.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.59,35.93a1,1,0,0,1-.4-.66.29.29,0,0,1,.19-.32.72.72,0,0,1,.36,0,1,1,0,0,0-.34,0,.2.2,0,0,0-.09.25.8.8,0,0,0,.4.46h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17.06S34.6,35.94,34.59,35.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,33.24a1,1,0,0,1-.38-.67.28.28,0,0,1,.2-.32.72.72,0,0,1,.36,0,.64.64,0,0,0-.34,0,.2.2,0,0,0-.1.24.85.85,0,0,0,.39.48h0a.13.13,0,0,1,.06.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.53,31.17a1.07,1.07,0,0,1-.33-.7.28.28,0,0,1,.23-.3.74.74,0,0,1,.36,0,.82.82,0,0,0-.35,0,.19.19,0,0,0-.11.24.83.83,0,0,0,.35.5h0a.13.13,0,0,1,0,.17.14.14,0,0,1-.18.05S37.54,31.18,37.53,31.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,29.35a1,1,0,0,1-.31-.71c0-.13.1-.29.24-.29a.74.74,0,0,1,.36.06.69.69,0,0,0-.35,0,.19.19,0,0,0-.12.23.82.82,0,0,0,.33.51h0a.13.13,0,0,1-.14.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.84,30.36a1,1,0,0,1-.29-.71.29.29,0,0,1,.25-.29.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.85.85,0,0,0,.31.52h0a.12.12,0,1,1-.14.2A0,0,0,0,1,38.84,30.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.29,29.36a1,1,0,0,1-.26-.73.3.3,0,0,1,.26-.28.7.7,0,0,1,.35.09.68.68,0,0,0-.34,0,.19.19,0,0,0-.14.22.81.81,0,0,0,.3.54h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.41,28.11a1,1,0,0,1-.24-.73.3.3,0,0,1,.26-.28.83.83,0,0,1,.35.1.8.8,0,0,0-.34,0,.21.21,0,0,0-.15.23.9.9,0,0,0,.29.54h0a.13.13,0,1,1-.16.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.06,27.09a1,1,0,0,1-.2-.75.28.28,0,0,1,.27-.26.66.66,0,0,1,.34.12.65.65,0,0,0-.33-.06.21.21,0,0,0-.16.22.83.83,0,0,0,.25.55h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.13,26.8a1,1,0,0,1-.14-.75.28.28,0,0,1,.3-.24.75.75,0,0,1,.33.14.67.67,0,0,0-.33-.08.2.2,0,0,0-.18.2.83.83,0,0,0,.22.57h0a.12.12,0,1,1-.18.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.23,27.68a1,1,0,0,1-.1-.76.28.28,0,0,1,.3-.22.77.77,0,0,1,.33.15.82.82,0,0,0-.33-.09.19.19,0,0,0-.18.19.79.79,0,0,0,.18.58h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S42.23,27.69,42.23,27.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.91,29.18a1,1,0,0,1-.1-.76.29.29,0,0,1,.31-.23.71.71,0,0,1,.32.17.63.63,0,0,0-.32-.1c-.11,0-.17.09-.19.19a.84.84,0,0,0,.18.58h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,42.91,29.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.18,30.49a1,1,0,0,1-.07-.77.29.29,0,0,1,.32-.21.83.83,0,0,1,.32.18.7.7,0,0,0-.32-.12.21.21,0,0,0-.2.19.86.86,0,0,0,.15.59h0a.13.13,0,1,1-.2.16A0,0,0,0,0,44.18,30.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.06,32.2a1,1,0,0,1-.06-.77.29.29,0,0,1,.32-.2.76.76,0,0,1,.32.17.82.82,0,0,0-.32-.11.2.2,0,0,0-.2.18.85.85,0,0,0,.15.59h0a.13.13,0,1,1-.2.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.66,31.36a1,1,0,0,1,0-.77.28.28,0,0,1,.33-.18.67.67,0,0,1,.3.19.65.65,0,0,0-.31-.13.19.19,0,0,0-.2.17.82.82,0,0,0,.11.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.44,29.14a1,1,0,0,1-.16-.75.3.3,0,0,1,.29-.25.75.75,0,0,1,.33.14.66.66,0,0,0-.33-.07.2.2,0,0,0-.17.2.82.82,0,0,0,.23.57h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.77,28.27a1,1,0,0,1-.35-.69.29.29,0,0,1,.22-.31.73.73,0,0,1,.36,0,.77.77,0,0,0-.34,0,.18.18,0,0,0-.11.24.79.79,0,0,0,.36.49h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.26,32.38a1,1,0,0,1-.43-.63.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.59.59,0,0,0-.33.06.19.19,0,0,0-.08.25.82.82,0,0,0,.42.44h0a.12.12,0,0,1,.07.17.14.14,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.89,36.84a1,1,0,0,1-.45-.63.28.28,0,0,1,.16-.34.63.63,0,0,1,.36,0,.72.72,0,0,0-.33.06.19.19,0,0,0-.07.26.82.82,0,0,0,.43.43h0a.12.12,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.57,40.11a1,1,0,0,1-.44-.64.3.3,0,0,1,.17-.34.77.77,0,0,1,.37,0,.65.65,0,0,0-.34.06.19.19,0,0,0-.08.25.88.88,0,0,0,.43.44h0a.13.13,0,0,1-.1.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.14,44.1a1,1,0,0,1-.39-.67.28.28,0,0,1,.19-.32.72.72,0,0,1,.36,0,.88.88,0,0,0-.33,0,.19.19,0,0,0-.1.25.82.82,0,0,0,.4.47h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.47,46.77a1,1,0,0,1-.35-.68.29.29,0,0,1,.21-.32.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.72,49.18a1.06,1.06,0,0,1-.32-.71.3.3,0,0,1,.23-.3.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.19.19,0,0,0-.12.24.83.83,0,0,0,.34.5h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.73,53.56a1.07,1.07,0,0,1-.25-.73c0-.14.12-.29.26-.28a.75.75,0,0,1,.35.09.79.79,0,0,0-.34,0,.18.18,0,0,0-.14.22.77.77,0,0,0,.29.54h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.7,53.73a1,1,0,0,1-.24-.73.29.29,0,0,1,.26-.28.75.75,0,0,1,.34.1.8.8,0,0,0-.34,0,.21.21,0,0,0-.14.23.85.85,0,0,0,.29.54h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0S23.7,53.74,23.7,53.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.74,50a1,1,0,0,1-.32-.71.3.3,0,0,1,.24-.3A.81.81,0,0,1,24,49a1,1,0,0,0-.34,0,.2.2,0,0,0-.12.24.8.8,0,0,0,.34.51h0a.13.13,0,0,1-.13.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.24,38.46a1,1,0,0,1-.51-.58.28.28,0,0,1,.13-.35.75.75,0,0,1,.36-.05.71.71,0,0,0-.33.1.2.2,0,0,0-.05.26.87.87,0,0,0,.48.39h0a.12.12,0,0,1,.08.15.11.11,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.06,33.72a1,1,0,0,1-.56-.53.31.31,0,0,1,.1-.37.94.94,0,0,1,.36-.08.77.77,0,0,0-.32.13.2.2,0,0,0,0,.27.81.81,0,0,0,.5.34h0a.13.13,0,1,1-.05.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.15,27.81a1,1,0,0,1-.58-.5.29.29,0,0,1,.08-.37.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.19.19,0,0,0,0,.26.85.85,0,0,0,.52.32h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.28,20.73a1,1,0,0,1-.59-.5.28.28,0,0,1,.08-.37.63.63,0,0,1,.34-.1.7.7,0,0,0-.3.14.2.2,0,0,0,0,.27.79.79,0,0,0,.52.31h0a.13.13,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.58,18.84a1,1,0,0,1-.57-.52A.3.3,0,0,1,24.1,18a.74.74,0,0,1,.35-.08.79.79,0,0,0-.31.13.2.2,0,0,0,0,.27.82.82,0,0,0,.51.33h0a.12.12,0,0,1,.1.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.54,15.85a1,1,0,0,1-.55-.54.3.3,0,0,1,.11-.37.87.87,0,0,1,.35-.07.66.66,0,0,0-.31.13.19.19,0,0,0,0,.26.87.87,0,0,0,.5.35h0a.13.13,0,1,1-.05.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,15.23a1,1,0,0,1-.46-.62.3.3,0,0,1,.16-.34.74.74,0,0,1,.36,0,.88.88,0,0,0-.33.07.2.2,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.7,14.22a1,1,0,0,1-.18-.74.29.29,0,0,1,.28-.26.78.78,0,0,1,.34.13.82.82,0,0,0-.33-.07.2.2,0,0,0-.17.21.83.83,0,0,0,.24.56h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.63,15a1,1,0,0,1,.28-.71.29.29,0,0,1,.38-.05.75.75,0,0,1,.2.3.8.8,0,0,0-.23-.25.21.21,0,0,0-.26.08.87.87,0,0,0-.13.6h0a.13.13,0,0,1-.1.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.18,15.53a1,1,0,0,1,.46-.62A.27.27,0,0,1,49,15a.84.84,0,0,1,.13.33A.92.92,0,0,0,49,15a.2.2,0,0,0-.27,0,.87.87,0,0,0-.27.55h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.17,18.17a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.08.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.31.52h0a.12.12,0,0,1-.14.11.14.14,0,0,1-.11-.14A0,0,0,0,0,53.17,18.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.77,22.21a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.63.63,0,0,0-.14-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.53,23.44a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,57.53,23.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.45,20.92a1,1,0,0,1,.52-.57.28.28,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.18.18,0,0,0-.26,0,.78.78,0,0,0-.33.51h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.64,20.76a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.88.88,0,0,1,.1.35.81.81,0,0,0-.15-.31.19.19,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.46,17.72a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.65.65,0,0,0-.2-.28.19.19,0,0,0-.26,0,.85.85,0,0,0-.22.57h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.93,15.49a1,1,0,0,1,.1-.76.3.3,0,0,1,.36-.14.78.78,0,0,1,.27.24.64.64,0,0,0-.29-.18.19.19,0,0,0-.23.14.91.91,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,14.61a1,1,0,0,1-.48-.6.29.29,0,0,1,.14-.35.75.75,0,0,1,.36,0,.63.63,0,0,0-.33.09.19.19,0,0,0-.05.25.8.8,0,0,0,.46.41h0a.13.13,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.92,18.24a1,1,0,0,1-.56-.53.29.29,0,0,1,.1-.37.85.85,0,0,1,.35-.08.9.9,0,0,0-.32.13.21.21,0,0,0,0,.27A.88.88,0,0,0,26,18h0a.13.13,0,0,1,.1.15.14.14,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,24a1,1,0,0,1-.59-.49.28.28,0,0,1,.07-.37.76.76,0,0,1,.35-.11,1,1,0,0,0-.31.15.2.2,0,0,0,0,.27.86.86,0,0,0,.53.31h0a.13.13,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.07,23a1,1,0,0,1-.59-.49.29.29,0,0,1,.07-.38.88.88,0,0,1,.34-.1.66.66,0,0,0-.3.15.2.2,0,0,0,0,.27.82.82,0,0,0,.53.3h0a.14.14,0,0,1,.12.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.4,21.84a1,1,0,0,1-.59-.49.28.28,0,0,1,.07-.37.76.76,0,0,1,.35-.11,1,1,0,0,0-.31.15.2.2,0,0,0,0,.27.84.84,0,0,0,.52.31h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.82,21a1.05,1.05,0,0,1-.6-.49.31.31,0,0,1,.08-.38.83.83,0,0,1,.34-.1.71.71,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.12.12,0,0,1,.11.14.11.11,0,0,1-.13.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,21.89a1.05,1.05,0,0,1-.6-.49.3.3,0,0,1,.08-.38.88.88,0,0,1,.34-.1.77.77,0,0,0-.3.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.3,22.93a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.63.63,0,0,1,.34-.1.66.66,0,0,0-.3.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,0,1,.11.14.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.39,21a1,1,0,0,1-.59-.49.29.29,0,0,1,.07-.38.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.35,21.53a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.88.88,0,0,1,.35-.1.75.75,0,0,0-.31.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,0,1,.11.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.8,22.43a1,1,0,0,1-.59-.5.28.28,0,0,1,.07-.37.74.74,0,0,1,.35-.1.69.69,0,0,0-.31.15.19.19,0,0,0,0,.26.79.79,0,0,0,.53.31h0a.14.14,0,0,1,.12.14.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.88,22.59a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.88.88,0,0,1,.35-.1.75.75,0,0,0-.31.15.19.19,0,0,0,0,.26.79.79,0,0,0,.53.31h0a.12.12,0,0,1,.11.14.13.13,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.75,21.89a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.64.64,0,0,1,.35-.1.69.69,0,0,0-.31.15.19.19,0,0,0,0,.26.82.82,0,0,0,.53.31h0a.13.13,0,0,1,.11.14.14.14,0,0,1-.15.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.25,20.82a1,1,0,0,1-.59-.5.29.29,0,0,1,.08-.37.67.67,0,0,1,.35-.1.8.8,0,0,0-.31.14.21.21,0,0,0,0,.27.87.87,0,0,0,.53.32h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.22,19.66a1,1,0,0,1-.58-.5.29.29,0,0,1,.09-.37.74.74,0,0,1,.35-.1,1,1,0,0,0-.31.14.2.2,0,0,0,0,.27.8.8,0,0,0,.52.32h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.45,12.76a1,1,0,0,1-.53-.55.29.29,0,0,1,.11-.37.76.76,0,0,1,.36-.06.76.76,0,0,0-.32.11.2.2,0,0,0,0,.27.86.86,0,0,0,.5.36h0a.14.14,0,0,1,.1.15.13.13,0,0,1-.15.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.17,12.43a1,1,0,0,1-.51-.57.29.29,0,0,1,.13-.36.75.75,0,0,1,.36-.05.85.85,0,0,0-.33.1.2.2,0,0,0-.05.26.83.83,0,0,0,.48.39h0a.13.13,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.6,12.68a1,1,0,0,1-.48-.6.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.06.26.82.82,0,0,0,.45.41h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.34,13.29a1,1,0,0,1-.43-.63.29.29,0,0,1,.17-.34.88.88,0,0,1,.36,0,.88.88,0,0,0-.33.06.21.21,0,0,0-.08.26.82.82,0,0,0,.42.44h0a.13.13,0,0,1-.1.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.17,13.26a1,1,0,0,1-.26-.73.3.3,0,0,1,.26-.28.82.82,0,0,1,.35.09,1,1,0,0,0-.34,0,.2.2,0,0,0-.14.23.81.81,0,0,0,.3.53h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.18,13a1,1,0,0,1-.06-.77.3.3,0,0,1,.32-.21.92.92,0,0,1,.32.18.86.86,0,0,0-.33-.11c-.11,0-.17.08-.19.18a.86.86,0,0,0,.15.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.33,13.58a1,1,0,0,1,.17-.75.3.3,0,0,1,.37-.11.88.88,0,0,1,.25.26.76.76,0,0,0-.28-.2c-.11,0-.19,0-.24.12a.86.86,0,0,0,0,.61h0a.14.14,0,0,1-.08.17.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.91,14.44a1,1,0,0,1,.5-.59.29.29,0,0,1,.37.08.64.64,0,0,1,.1.35.69.69,0,0,0-.15-.31.2.2,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.22,15.65a1,1,0,0,1,.51-.57.28.28,0,0,1,.37.09.73.73,0,0,1,.09.35.63.63,0,0,0-.14-.31.18.18,0,0,0-.26,0,.84.84,0,0,0-.33.52h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14A0,0,0,0,1,58.22,15.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.46,16.7a1,1,0,0,1,.51-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.14.14,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,60.46,16.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.14,18.7a1,1,0,0,1,.52-.57.28.28,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.18.18,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.14.14,0,0,1-.15.11.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.44,18.5a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.86.86,0,0,1,.09.35,1,1,0,0,0-.14-.31A.21.21,0,0,0,65,18a.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.1.13.13,0,0,1-.11-.14A0,0,0,0,0,64.44,18.5Z"/>
      </g>}
      {r_type_hair >= 6 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.48,66a1.49,1.49,0,0,0,.66-.79c0-.16,0-.37-.19-.39s-.29.19-.32.37c0-.18.09-.41.32-.43s.34.27.32.46a1.72,1.72,0,0,1-.63,1,.13.13,0,1,1-.17-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.39,65.25a1.58,1.58,0,0,0,.65-.8c0-.16,0-.37-.21-.39s-.28.2-.31.38c0-.18.09-.42.32-.44s.34.27.32.46a1.69,1.69,0,0,1-.61,1,.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.22,67.13a1.51,1.51,0,0,0,.69-.77.28.28,0,0,0-.18-.39c-.17,0-.29.17-.33.35,0-.17.11-.41.34-.41s.33.28.3.47a1.72,1.72,0,0,1-.67,1,.12.12,0,0,1-.16-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,66.47a1.51,1.51,0,0,0,.68-.78.29.29,0,0,0-.18-.39c-.17,0-.3.18-.33.36,0-.17.1-.41.33-.42s.34.28.31.47a1.71,1.71,0,0,1-.66,1,.12.12,0,0,1-.16-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.37,69.16a1.53,1.53,0,0,0,.76-.7.3.3,0,0,0-.14-.41c-.17,0-.31.15-.36.32,0-.17.14-.39.37-.38s.31.31.25.5a1.67,1.67,0,0,1-.75.88.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.85,69.1a1.58,1.58,0,0,0,.77-.69.3.3,0,0,0-.14-.41c-.17,0-.32.14-.37.32,0-.18.15-.4.38-.38s.3.32.25.51a1.7,1.7,0,0,1-.77.86.12.12,0,0,1-.14-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.66,64.65a1.58,1.58,0,0,0,.63-.82c0-.16,0-.36-.21-.38s-.29.2-.31.38c0-.17.08-.41.31-.44s.35.26.33.45a1.69,1.69,0,0,1-.59,1,.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.13,67.49a1.61,1.61,0,0,0,.71-.75c0-.16,0-.37-.17-.4s-.31.17-.34.34c0-.17.11-.4.34-.4a.38.38,0,0,1,.29.48,1.67,1.67,0,0,1-.69.93.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.56,68.58a1.52,1.52,0,0,0,.74-.72c0-.15,0-.37-.16-.41s-.31.16-.35.34c0-.18.13-.4.36-.4s.31.31.27.5a1.67,1.67,0,0,1-.73.9.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.37,64.27a1.47,1.47,0,0,0,.61-.83c0-.16,0-.36-.21-.37s-.28.2-.3.38c0-.17.07-.42.3-.45s.35.25.34.45a1.77,1.77,0,0,1-.57,1,.13.13,0,1,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.15,69.27a1.58,1.58,0,0,0,.77-.69.3.3,0,0,0-.14-.41c-.17,0-.31.14-.37.32,0-.17.15-.4.38-.38s.3.32.25.51a1.68,1.68,0,0,1-.77.87.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.48,68.54a1.53,1.53,0,0,0,.76-.7.3.3,0,0,0-.15-.41c-.17,0-.31.15-.36.33,0-.18.14-.4.37-.39s.31.31.26.5a1.73,1.73,0,0,1-.75.89.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.05,64.35a1.55,1.55,0,0,0,.62-.82c0-.16,0-.36-.21-.38s-.28.21-.3.38c0-.17.07-.41.3-.44s.35.25.34.45a1.71,1.71,0,0,1-.58,1,.13.13,0,1,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.33,66A1.53,1.53,0,0,0,60,65.2c0-.16,0-.37-.18-.39s-.3.18-.33.36c0-.18.1-.41.33-.43s.34.28.31.48a1.64,1.64,0,0,1-.65,1,.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.81,68a1.59,1.59,0,0,0,.73-.72c.06-.16,0-.37-.15-.41s-.31.16-.35.33c0-.17.13-.4.36-.39s.31.31.27.5a1.75,1.75,0,0,1-.73.9.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.58,67.91a1.48,1.48,0,0,0,.74-.72c0-.15,0-.36-.16-.4s-.31.16-.35.33c0-.17.13-.4.36-.39s.32.3.27.49a1.69,1.69,0,0,1-.73.91.13.13,0,0,1-.17,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.83,65.49a1.56,1.56,0,0,0,.66-.8c0-.15,0-.36-.2-.38s-.29.19-.32.37c0-.18.09-.41.32-.43s.35.27.32.46a1.75,1.75,0,0,1-.63,1,.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.07,65.4a1.58,1.58,0,0,0,.65-.8c0-.16,0-.37-.2-.38s-.29.19-.31.36c0-.17.08-.41.31-.43s.35.27.32.46a1.66,1.66,0,0,1-.61,1,.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.59,67.17a1.52,1.52,0,0,0,.72-.74c0-.15,0-.36-.17-.4s-.3.17-.34.34c0-.17.12-.4.35-.4s.32.29.28.49a1.7,1.7,0,0,1-.7.92.14.14,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.52,65.52a1.49,1.49,0,0,0,.66-.79c0-.16,0-.36-.19-.38s-.29.18-.32.36c0-.17.09-.41.32-.43s.34.27.32.47a1.69,1.69,0,0,1-.63,1,.13.13,0,0,1-.17-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.76,68.41a1.56,1.56,0,0,0,.72-.74c0-.16,0-.37-.16-.4s-.31.16-.35.34c0-.18.12-.4.36-.4s.32.29.27.48a1.66,1.66,0,0,1-.7.92.12.12,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,66.73a1.52,1.52,0,0,0,.7-.76c.05-.15,0-.36-.17-.39s-.31.17-.34.35c0-.17.11-.41.34-.41s.33.29.29.48a1.67,1.67,0,0,1-.68.94.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,68a1.52,1.52,0,0,0,.73-.73c.06-.15,0-.37-.15-.4s-.31.16-.35.33c0-.17.12-.4.35-.4a.39.39,0,0,1,.27.5,1.69,1.69,0,0,1-.71.91.13.13,0,0,1-.18,0A.13.13,0,0,1,63,68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.44,67.65a1.54,1.54,0,0,0,.73-.74c0-.15,0-.36-.17-.39s-.3.16-.34.33c0-.17.12-.4.35-.4a.39.39,0,0,1,.28.49,1.68,1.68,0,0,1-.71.92.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.4,66.59a1.51,1.51,0,0,0,.69-.77c0-.15,0-.36-.18-.39s-.3.18-.33.36c0-.18.1-.41.33-.42s.33.28.3.47a1.66,1.66,0,0,1-.66,1,.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.2,66.8a1.52,1.52,0,0,0,.7-.76c.05-.15,0-.36-.17-.39s-.3.17-.34.35c0-.18.11-.41.34-.41s.33.29.3.48a1.71,1.71,0,0,1-.68.94.14.14,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.87,64.49a1.53,1.53,0,0,0,.62-.83c0-.16,0-.36-.22-.38s-.28.21-.3.39c0-.18.07-.42.3-.45s.36.25.34.45a1.8,1.8,0,0,1-.57,1,.15.15,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.8,67.3a1.6,1.6,0,0,0,.68-.78c0-.15,0-.36-.19-.39s-.3.18-.33.36c0-.17.1-.41.33-.42s.34.28.31.47a1.7,1.7,0,0,1-.65,1,.12.12,0,1,1-.16-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.33,65.78A1.51,1.51,0,0,0,67,65c0-.16,0-.36-.2-.38s-.29.2-.31.37c0-.17.08-.41.31-.43s.35.26.33.45a1.73,1.73,0,0,1-.6,1,.13.13,0,0,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.36,63.76a1.58,1.58,0,0,0,.57-.86c0-.16-.06-.36-.23-.36s-.27.21-.28.39a.39.39,0,0,1,.28-.45c.23,0,.36.23.36.43a1.74,1.74,0,0,1-.53,1,.13.13,0,1,1-.19-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.76,64.49a1.56,1.56,0,0,0,.59-.85c0-.16,0-.36-.23-.37s-.27.22-.28.4c0-.18,0-.42.28-.46s.36.24.35.43a1.68,1.68,0,0,1-.54,1,.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.37,66.15a1.53,1.53,0,0,0,.62-.83c0-.15,0-.36-.21-.37s-.29.2-.3.38c0-.17.07-.41.3-.44s.35.25.34.45a1.76,1.76,0,0,1-.58,1,.13.13,0,0,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.54,63a1.5,1.5,0,0,0,.54-.88c0-.16-.07-.35-.25-.35s-.26.23-.26.41a.39.39,0,0,1,.26-.47c.23,0,.37.22.37.41a1.73,1.73,0,0,1-.48,1.06.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.28,64.78a1.58,1.58,0,0,0,.57-.86c0-.16-.06-.36-.23-.36s-.27.21-.28.39a.41.41,0,0,1,.28-.46c.23,0,.36.24.36.43a1.77,1.77,0,0,1-.53,1,.13.13,0,1,1-.19-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.91,65.05a1.52,1.52,0,0,0,.56-.87c0-.15-.07-.35-.25-.35s-.26.22-.27.4c0-.17,0-.42.27-.46s.37.22.37.42a1.76,1.76,0,0,1-.5,1,.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,63.63a1.46,1.46,0,0,0,.53-.88c0-.16-.07-.36-.25-.35s-.26.23-.26.41c0-.18,0-.42.25-.47s.38.21.39.41a1.7,1.7,0,0,1-.47,1,.12.12,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.38,63.66a1.59,1.59,0,0,0,.51-.9c0-.16-.09-.35-.26-.34s-.26.23-.25.41c0-.17,0-.42.24-.48s.39.21.39.4a1.66,1.66,0,0,1-.44,1.07.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,62.18a1.51,1.51,0,0,0,.5-.91c0-.16-.09-.35-.26-.34s-.25.24-.25.42c0-.17,0-.42.24-.48s.39.2.4.4a1.74,1.74,0,0,1-.44,1.07.11.11,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.71,62.14a1.49,1.49,0,0,0,.47-.91c0-.16-.1-.35-.27-.34s-.24.25-.23.43a.41.41,0,0,1,.22-.49c.23,0,.39.19.41.39a1.7,1.7,0,0,1-.41,1.08.12.12,0,0,1-.2-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.41,61a1.49,1.49,0,0,0,.45-.92c0-.16-.1-.35-.27-.33s-.24.25-.23.43c0-.17,0-.42.21-.49s.4.18.42.37a1.68,1.68,0,0,1-.39,1.1.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.69,60.65a1.51,1.51,0,0,0,.43-.94c0-.16-.11-.34-.28-.32s-.24.26-.22.44a.42.42,0,0,1,.2-.5c.23-.06.4.17.43.37a1.79,1.79,0,0,1-.36,1.1.14.14,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.83,60.67a1.46,1.46,0,0,0,.43-.94c0-.16-.11-.34-.28-.32s-.24.26-.22.44c0-.17,0-.42.2-.5s.4.18.43.37a1.67,1.67,0,0,1-.36,1.1.13.13,0,1,1-.21-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.23,59.29a1.58,1.58,0,0,0,.44-.93c0-.16-.11-.35-.29-.33s-.23.26-.21.44a.4.4,0,0,1,.2-.5c.23,0,.4.18.42.37a1.65,1.65,0,0,1-.37,1.1.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.65,59.71a1.53,1.53,0,0,0,.44-.93c0-.17-.12-.35-.29-.33s-.23.26-.21.44a.4.4,0,0,1,.2-.5c.23-.06.4.18.42.37a1.67,1.67,0,0,1-.36,1.1.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.85,59.15a1.53,1.53,0,0,0,.44-.93c0-.16-.11-.35-.28-.33s-.24.26-.22.44c0-.17,0-.42.2-.5s.4.18.43.37a1.7,1.7,0,0,1-.37,1.1.12.12,0,0,1-.21-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.83,57.9a1.52,1.52,0,0,0,.45-.93c0-.16-.11-.34-.28-.32s-.24.25-.22.43a.41.41,0,0,1,.21-.5c.23,0,.4.19.42.38A1.7,1.7,0,0,1,74,58.05a.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.54,57.4a1.45,1.45,0,0,0,.46-.92c0-.16-.1-.35-.28-.33s-.24.25-.22.43c0-.17,0-.42.21-.49s.4.18.42.37a1.74,1.74,0,0,1-.39,1.1.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.36,57.11a1.56,1.56,0,0,0,.46-.92c0-.16-.11-.35-.28-.33s-.24.25-.23.43a.4.4,0,0,1,.22-.49c.22,0,.39.18.41.38a1.66,1.66,0,0,1-.39,1.09.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.77,56.4a1.49,1.49,0,0,0,.47-.92c0-.16-.1-.34-.27-.33s-.25.25-.23.43c0-.17,0-.42.21-.49s.4.19.42.38a1.76,1.76,0,0,1-.4,1.09.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.35,55.66a1.6,1.6,0,0,0,.47-.92c0-.16-.1-.35-.28-.34s-.24.25-.23.43a.41.41,0,0,1,.22-.49c.23-.05.39.19.41.39a1.69,1.69,0,0,1-.4,1.08.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.62,55.13a1.51,1.51,0,0,0,.48-.91c0-.16-.1-.35-.27-.34s-.25.24-.23.42c0-.17,0-.42.22-.48s.39.19.4.39a1.7,1.7,0,0,1-.41,1.08.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.07,54.67a1.55,1.55,0,0,0,.49-.91c0-.16-.1-.35-.27-.34s-.25.25-.24.43a.41.41,0,0,1,.23-.49c.23,0,.39.2.4.39a1.72,1.72,0,0,1-.42,1.08.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.07,53.89a1.51,1.51,0,0,0,.48-.91c0-.16-.09-.35-.27-.34s-.24.24-.23.42c0-.17,0-.42.22-.48s.39.19.41.39a1.77,1.77,0,0,1-.42,1.08.14.14,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.37,53.38a1.46,1.46,0,0,0,.5-.9c0-.16-.08-.35-.26-.34s-.25.24-.24.42a.4.4,0,0,1,.23-.48c.23,0,.39.2.4.4a1.69,1.69,0,0,1-.44,1.07.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.13,52.86a1.58,1.58,0,0,0,.51-.91c0-.16-.09-.35-.27-.34s-.25.24-.24.42c0-.17,0-.42.23-.48s.39.2.4.4A1.69,1.69,0,0,1,72.32,53a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.3,52.27a1.55,1.55,0,0,0,.52-.89c0-.16-.08-.35-.26-.35s-.25.24-.25.42a.4.4,0,0,1,.25-.48c.23,0,.38.21.38.41a1.72,1.72,0,0,1-.45,1.06.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.51,51.68a1.59,1.59,0,0,0,.51-.9c0-.16-.09-.36-.26-.35s-.26.24-.25.42c0-.17,0-.42.24-.48s.38.21.39.4a1.62,1.62,0,0,1-.45,1.07.12.12,0,1,1-.19-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.47,51.19A1.56,1.56,0,0,0,74,50.3c0-.16-.08-.35-.25-.35s-.26.23-.26.41c0-.17,0-.42.25-.47s.38.21.38.41a1.69,1.69,0,0,1-.47,1.06.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.23,50.4a1.62,1.62,0,0,0,.53-.89c0-.16-.08-.36-.26-.35s-.26.23-.25.41c0-.17,0-.42.24-.47s.39.21.39.4a1.65,1.65,0,0,1-.47,1.06.12.12,0,1,1-.19-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.6,49.84a1.53,1.53,0,0,0,.54-.88c0-.16-.07-.35-.24-.35s-.26.22-.26.4c0-.17,0-.42.25-.47s.38.23.38.42a1.69,1.69,0,0,1-.49,1,.14.14,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.51,49.26a1.51,1.51,0,0,0,.55-.88.29.29,0,0,0-.25-.35c-.17,0-.26.22-.26.4a.39.39,0,0,1,.26-.46c.23,0,.37.22.37.41a1.64,1.64,0,0,1-.49,1,.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.23,49.07a1.51,1.51,0,0,0,.53-.88c0-.16-.08-.36-.25-.35s-.26.23-.26.41c0-.18,0-.42.25-.47s.38.21.39.41a1.71,1.71,0,0,1-.48,1.05.11.11,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.11,48.06a1.51,1.51,0,0,0,.55-.88c0-.15-.07-.35-.25-.35s-.26.23-.26.41a.39.39,0,0,1,.26-.47c.23,0,.37.22.37.42a1.64,1.64,0,0,1-.49,1,.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.49,47.73a1.59,1.59,0,0,0,.56-.87c0-.16-.07-.36-.24-.36s-.27.22-.27.4c0-.17,0-.42.26-.46s.38.23.37.42a1.67,1.67,0,0,1-.51,1,.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.3,46.75a1.52,1.52,0,0,0,.58-.86c0-.16-.06-.36-.23-.36s-.27.21-.28.39c0-.17,0-.41.27-.45s.37.23.36.43a1.69,1.69,0,0,1-.53,1,.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.46,46.65a1.52,1.52,0,0,0,.55-.87c0-.16-.07-.36-.25-.36s-.26.23-.26.41a.39.39,0,0,1,.26-.47c.23,0,.37.22.37.42a1.65,1.65,0,0,1-.5,1,.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.87,45.61a1.49,1.49,0,0,0,.58-.85c0-.16-.06-.36-.23-.37s-.28.22-.28.4c0-.17,0-.42.27-.46s.37.24.36.43a1.65,1.65,0,0,1-.52,1,.13.13,0,0,1-.19-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.75,44.68a1.5,1.5,0,0,0,.6-.83c0-.16,0-.36-.22-.37s-.28.2-.29.38c0-.17.06-.41.29-.45s.36.25.35.45a1.7,1.7,0,0,1-.56,1,.13.13,0,0,1-.18,0,.11.11,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.7,44.68a1.5,1.5,0,0,0,.57-.86c0-.16-.06-.36-.23-.36s-.27.22-.28.4c0-.18,0-.42.27-.46s.37.23.37.42a1.73,1.73,0,0,1-.52,1,.13.13,0,1,1-.19-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.07,43.49a1.57,1.57,0,0,0,.61-.83c0-.16-.05-.36-.22-.37s-.28.2-.29.38a.39.39,0,0,1,.29-.44c.23,0,.36.25.34.44a1.7,1.7,0,0,1-.56,1,.13.13,0,0,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.27,43.34a1.55,1.55,0,0,0,.58-.86c0-.16-.06-.36-.24-.36s-.27.21-.27.39c0-.17,0-.42.27-.46s.37.24.36.43a1.7,1.7,0,0,1-.52,1,.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.29,42.2a1.47,1.47,0,0,0,.61-.83c0-.16,0-.36-.21-.37s-.28.2-.3.38c0-.17.07-.41.29-.44s.36.25.35.44a1.74,1.74,0,0,1-.57,1,.13.13,0,0,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.24,42.46a1.55,1.55,0,0,0,.57-.86c0-.16-.07-.36-.24-.36s-.27.22-.28.4c0-.18,0-.42.27-.46s.38.23.37.42a1.69,1.69,0,0,1-.51,1,.13.13,0,1,1-.19-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.81,40.76a1.55,1.55,0,0,0,.62-.82c0-.16,0-.37-.21-.38s-.28.2-.3.38c0-.17.07-.41.3-.44s.35.25.34.45A1.72,1.72,0,0,1,72,41a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.48,40.67a1.49,1.49,0,0,0,.58-.85.29.29,0,0,0-.23-.37c-.17,0-.27.22-.28.4,0-.18,0-.42.28-.46s.37.24.36.44a1.68,1.68,0,0,1-.54,1,.12.12,0,1,1-.18-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,40.93a1.52,1.52,0,0,0,.55-.87c0-.16-.07-.35-.24-.35s-.27.22-.27.4c0-.17,0-.42.26-.47s.38.23.38.42a1.76,1.76,0,0,1-.5,1,.13.13,0,0,1-.18,0A.12.12,0,0,1,69,41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.69,39.52a1.6,1.6,0,0,0,.58-.86c0-.16-.06-.36-.24-.36s-.27.21-.27.39c0-.17,0-.41.27-.45s.37.23.36.43a1.69,1.69,0,0,1-.53,1,.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.91,40a1.5,1.5,0,0,0,.54-.88.29.29,0,0,0-.25-.35c-.17,0-.26.23-.26.41a.4.4,0,0,1,.26-.47c.23,0,.37.22.38.41a1.73,1.73,0,0,1-.48,1.06.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.51,40.46a1.5,1.5,0,0,0,.51-.9c0-.16-.08-.35-.26-.34s-.25.23-.24.41a.39.39,0,0,1,.24-.48c.23,0,.38.21.39.41a1.72,1.72,0,0,1-.45,1.06.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.54,38.28a1.52,1.52,0,0,0,.55-.87c0-.16-.07-.36-.24-.36s-.27.22-.27.4c0-.17,0-.42.26-.46s.38.22.38.42a1.69,1.69,0,0,1-.51,1,.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.31,37.67a1.46,1.46,0,0,0,.53-.88c0-.16-.07-.36-.25-.35s-.26.23-.26.41c0-.18,0-.42.25-.47s.38.21.39.4a1.7,1.7,0,0,1-.47,1.06.12.12,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.52,38.88A1.55,1.55,0,0,0,66,38c0-.16-.1-.35-.27-.33s-.25.24-.24.42a.42.42,0,0,1,.23-.49c.23,0,.39.2.4.4A1.72,1.72,0,0,1,65.71,39a.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.87,37.12a1.51,1.51,0,0,0,.5-.91c0-.16-.09-.35-.26-.34s-.26.24-.25.42c0-.17,0-.42.24-.48s.38.2.39.4a1.67,1.67,0,0,1-.43,1.07.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.05,39.35a1.53,1.53,0,0,0,.46-.93c0-.16-.1-.35-.28-.33s-.24.25-.22.43c0-.17,0-.42.21-.49s.4.19.42.38a1.75,1.75,0,0,1-.39,1.09.14.14,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.34,37.92A1.56,1.56,0,0,0,64.8,37c0-.16-.1-.35-.28-.33s-.24.25-.22.43c0-.17,0-.42.21-.49s.4.18.41.38a1.66,1.66,0,0,1-.39,1.09.12.12,0,1,1-.2-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.17,36.58a1.52,1.52,0,0,0,.45-.93c0-.16-.1-.34-.28-.32s-.24.25-.22.43c0-.17,0-.42.21-.49s.4.18.42.37a1.73,1.73,0,0,1-.38,1.1.14.14,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.83,38a1.6,1.6,0,0,0,.43-.94c0-.16-.12-.34-.29-.32s-.24.26-.21.44c0-.17,0-.42.19-.5s.4.17.43.36A1.73,1.73,0,0,1,63,38.11a.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.89,36.6a1.49,1.49,0,0,0,.42-.94c0-.16-.11-.35-.29-.32s-.23.26-.2.44c0-.17,0-.42.19-.5s.4.17.43.36a1.69,1.69,0,0,1-.35,1.11.13.13,0,0,1-.17,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.53,37.31a1.48,1.48,0,0,0,.39-1c0-.16-.13-.34-.3-.31s-.22.27-.19.44a.39.39,0,0,1,.17-.5c.23-.07.41.15.44.34a1.7,1.7,0,0,1-.3,1.12.13.13,0,1,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.79,35.75a1.54,1.54,0,0,0,.39-1c0-.16-.14-.34-.31-.3s-.22.26-.19.44c0-.17,0-.42.18-.5s.41.15.44.34a1.7,1.7,0,0,1-.3,1.12.13.13,0,0,1-.17,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.43,36a1.56,1.56,0,0,0,.35-1c0-.16-.15-.33-.32-.29s-.21.28-.17.45c0-.17-.06-.42.15-.51a.38.38,0,0,1,.46.32,1.74,1.74,0,0,1-.25,1.14.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.9,35a1.51,1.51,0,0,0,.35-1c0-.16-.14-.33-.31-.29s-.21.27-.18.45c0-.17,0-.42.16-.51s.41.13.45.33a1.68,1.68,0,0,1-.25,1.12.13.13,0,0,1-.17.06.14.14,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,35.91a1.59,1.59,0,0,0,.26-1c0-.16-.18-.32-.34-.26s-.19.29-.13.46c-.07-.16-.1-.41.1-.52s.43.1.49.28A1.69,1.69,0,0,1,58.21,36a.11.11,0,0,1-.16.07.14.14,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.23,35.12a1.57,1.57,0,0,0,.29-1c0-.15-.16-.32-.33-.27s-.2.29-.15.46a.4.4,0,0,1,.13-.52c.21-.09.42.11.47.3a1.72,1.72,0,0,1-.19,1.14.14.14,0,0,1-.17.07.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.07,36.35a1.5,1.5,0,0,0,.3-1c0-.16-.16-.33-.33-.28s-.19.28-.15.46c-.06-.17-.07-.42.13-.52s.42.12.47.3a1.7,1.7,0,0,1-.2,1.15.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,37.35a1.62,1.62,0,0,0,.34-1c0-.16-.15-.33-.32-.29s-.21.28-.17.46A.41.41,0,0,1,60,36c.22-.08.42.14.46.33a1.72,1.72,0,0,1-.25,1.13.13.13,0,0,1-.17.05.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.67,37.23a1.59,1.59,0,0,0,.26-1c0-.15-.17-.32-.34-.26s-.18.29-.13.46a.39.39,0,0,1,.11-.52c.21-.1.42.1.48.29a1.69,1.69,0,0,1-.16,1.15.13.13,0,0,1-.23-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.78,37.88a1.56,1.56,0,0,0,.3-1c0-.15-.16-.32-.33-.28s-.19.29-.15.46a.4.4,0,0,1,.13-.51c.22-.09.42.12.47.3A1.61,1.61,0,0,1,59,38a.12.12,0,0,1-.22-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.83,38.8a1.59,1.59,0,0,0,.29-1c0-.16-.17-.33-.34-.28s-.19.29-.14.47c-.06-.17-.09-.42.12-.53s.42.11.48.3a1.7,1.7,0,0,1-.19,1.15.12.12,0,0,1-.16.06.13.13,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.22,39.14a1.47,1.47,0,0,0,.32-1c0-.16-.15-.33-.32-.29s-.2.28-.16.45c-.05-.16-.07-.41.14-.51s.42.13.47.32a1.72,1.72,0,0,1-.24,1.13.13.13,0,0,1-.17.06.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.44,38.94a1.53,1.53,0,0,0,.36-1c0-.16-.14-.34-.31-.3s-.21.27-.18.45c-.05-.17-.05-.42.16-.51s.42.14.45.33a1.71,1.71,0,0,1-.27,1.13.13.13,0,0,1-.17.05.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.42,39.71a1.56,1.56,0,0,0,.39-1c0-.16-.12-.34-.3-.31s-.22.27-.19.45a.41.41,0,0,1,.18-.51c.22-.07.41.16.44.35a1.71,1.71,0,0,1-.32,1.12.12.12,0,0,1-.21-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.51,40.69a1.51,1.51,0,0,0,.35-1c0-.16-.14-.34-.31-.3s-.21.28-.18.46a.42.42,0,0,1,.16-.52c.22-.08.41.14.45.33a1.71,1.71,0,0,1-.25,1.13.13.13,0,0,1-.17,0,.12.12,0,0,1-.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.93,41.22a1.54,1.54,0,0,0,.41-.95c0-.16-.12-.34-.29-.31s-.23.26-.21.44c0-.17,0-.42.19-.5s.41.16.43.35a1.66,1.66,0,0,1-.33,1.11.12.12,0,1,1-.21-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.26,42a1.48,1.48,0,0,0,.37-1c0-.16-.13-.34-.31-.3s-.21.27-.18.45a.4.4,0,0,1,.17-.51c.22-.07.41.15.44.34a1.63,1.63,0,0,1-.28,1.12.13.13,0,1,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.4,42.87a1.55,1.55,0,0,0,.4-1c0-.16-.13-.34-.3-.31s-.22.27-.2.45a.41.41,0,0,1,.19-.51c.22-.06.4.16.43.36A1.63,1.63,0,0,1,61.6,43a.12.12,0,1,1-.21-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.67,43.87a1.52,1.52,0,0,0,.36-1c0-.16-.13-.34-.31-.3s-.21.27-.17.45c0-.17-.06-.42.16-.51s.41.14.45.33A1.71,1.71,0,0,1,59.88,44a.13.13,0,1,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.35,44.44a1.55,1.55,0,0,0,.42-.95c0-.16-.12-.34-.29-.31s-.23.26-.21.43c0-.17,0-.42.19-.49s.41.16.43.36a1.66,1.66,0,0,1-.34,1.1.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.74,45.68a1.51,1.51,0,0,0,.37-1c0-.16-.14-.33-.31-.3s-.21.27-.18.45c0-.17,0-.42.16-.51s.42.15.45.34A1.69,1.69,0,0,1,60,45.81a.11.11,0,0,1-.17,0,.13.13,0,0,1-.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.15,46.58a1.49,1.49,0,0,0,.4-.95c0-.16-.12-.34-.3-.31s-.22.26-.19.44a.4.4,0,0,1,.18-.51c.22-.06.41.16.44.36a1.74,1.74,0,0,1-.32,1.11.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.1,47.11a1.59,1.59,0,0,0,.37-1c0-.16-.14-.33-.32-.3s-.21.27-.17.45c0-.17-.06-.42.16-.51s.41.14.45.34a1.65,1.65,0,0,1-.28,1.12.13.13,0,1,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.32,47.74a1.54,1.54,0,0,0,.39-1c0-.16-.13-.33-.3-.3s-.23.27-.2.44c0-.17,0-.42.18-.5s.41.15.44.34a1.7,1.7,0,0,1-.3,1.12.13.13,0,1,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,48.88a1.56,1.56,0,0,0,.37-1c0-.16-.14-.34-.31-.3s-.22.27-.18.44a.39.39,0,0,1,.16-.5c.23-.08.41.14.45.33A1.73,1.73,0,0,1,59.25,49a.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,49.26a1.61,1.61,0,0,0,.4-1c0-.16-.13-.34-.3-.31s-.22.27-.2.45a.42.42,0,0,1,.18-.51c.23-.06.41.16.44.35a1.7,1.7,0,0,1-.32,1.12.12.12,0,1,1-.21-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.91,50.3a1.54,1.54,0,0,0,.39-1c0-.16-.14-.34-.31-.3s-.22.26-.19.44c0-.17,0-.42.18-.5s.41.15.44.34a1.7,1.7,0,0,1-.3,1.12.13.13,0,0,1-.17.05.14.14,0,0,1-.05-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.28,51a1.56,1.56,0,0,0,.41-.95c0-.16-.13-.34-.3-.31s-.23.26-.2.44c0-.17,0-.42.19-.5s.4.16.43.35a1.73,1.73,0,0,1-.32,1.11.14.14,0,0,1-.18,0,.15.15,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.53,51.83a1.57,1.57,0,0,0,.38-1c0-.16-.13-.34-.3-.31s-.22.27-.19.45a.41.41,0,0,1,.17-.51c.23-.07.41.16.45.35a1.7,1.7,0,0,1-.3,1.12.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.67,52.77a1.58,1.58,0,0,0,.38-1c0-.16-.14-.33-.31-.3s-.22.27-.18.45c-.05-.17-.05-.42.16-.51s.42.15.45.34a1.69,1.69,0,0,1-.29,1.12.13.13,0,1,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.55,53.58a1.52,1.52,0,0,0,.36-1c0-.16-.14-.33-.31-.3s-.21.27-.18.45a.4.4,0,0,1,.17-.51c.22-.07.41.15.45.34a1.74,1.74,0,0,1-.28,1.12.12.12,0,0,1-.17,0,.13.13,0,0,1-.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.09,54.31a1.48,1.48,0,0,0,.39-1c0-.16-.13-.34-.3-.31s-.22.27-.19.44a.39.39,0,0,1,.17-.5c.22-.07.41.15.44.34a1.7,1.7,0,0,1-.3,1.12.13.13,0,0,1-.17,0,.13.13,0,0,1-.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.92,55.32a1.5,1.5,0,0,0,.36-1c0-.16-.14-.33-.31-.3s-.21.28-.18.45A.41.41,0,0,1,57,54c.23-.07.42.15.46.34a1.63,1.63,0,0,1-.28,1.12.12.12,0,0,1-.17,0,.13.13,0,0,1-.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.19,55.76a1.55,1.55,0,0,0,.38-1c0-.16-.13-.34-.3-.3s-.22.27-.19.44a.38.38,0,0,1,.17-.5c.22-.08.41.15.44.34a1.64,1.64,0,0,1-.29,1.12.13.13,0,0,1-.17.05.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.8,56.28a1.54,1.54,0,0,0,.39-1c0-.16-.13-.34-.3-.31s-.22.26-.19.44a.38.38,0,0,1,.18-.5c.22-.07.4.15.44.35A1.69,1.69,0,0,1,60,56.42a.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.84,57.21a1.54,1.54,0,0,0,.37-1c0-.16-.14-.34-.31-.3s-.22.27-.19.45c0-.17-.05-.42.17-.51s.41.14.45.33a1.71,1.71,0,0,1-.28,1.13.13.13,0,0,1-.17,0,.13.13,0,0,1-.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.06,57.52a1.55,1.55,0,0,0,.38-1c0-.16-.13-.34-.3-.3s-.22.27-.19.44c-.05-.17-.05-.42.17-.5s.41.15.44.34a1.64,1.64,0,0,1-.29,1.12.13.13,0,0,1-.17.05.14.14,0,0,1-.05-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.36,57.65a1.54,1.54,0,0,0,.39-1c0-.16-.13-.34-.3-.31s-.22.27-.19.45a.41.41,0,0,1,.17-.51c.23-.06.41.16.45.35a1.77,1.77,0,0,1-.31,1.12.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.67,58.7a1.55,1.55,0,0,0,.36-1c0-.16-.13-.34-.31-.31s-.21.28-.18.45a.4.4,0,0,1,.17-.51c.22-.07.41.15.45.34a1.74,1.74,0,0,1-.29,1.13.12.12,0,1,1-.21-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.27,58.88a1.54,1.54,0,0,0,.39-1c0-.16-.13-.34-.3-.31s-.22.27-.2.44a.41.41,0,0,1,.18-.5c.23-.07.41.16.44.35A1.71,1.71,0,0,1,59.47,59a.14.14,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,59.37a1.61,1.61,0,0,0,.4-.95c0-.16-.13-.34-.3-.31s-.23.26-.2.44c0-.17,0-.42.18-.5s.41.16.44.35a1.67,1.67,0,0,1-.32,1.11.13.13,0,0,1-.17.05.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.16,60.43a1.53,1.53,0,0,0,.44-.93c0-.16-.11-.35-.29-.33s-.23.26-.21.44a.4.4,0,0,1,.2-.5c.23-.06.4.18.43.37a1.74,1.74,0,0,1-.37,1.1.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.73,60.62a1.48,1.48,0,0,0,.46-.93c0-.16-.1-.34-.27-.32s-.25.25-.23.42c0-.17,0-.42.21-.49s.4.19.42.38a1.69,1.69,0,0,1-.39,1.09.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.72,59.19a1.54,1.54,0,0,0,.41-.95c0-.16-.12-.34-.29-.31s-.23.26-.21.44a.41.41,0,0,1,.19-.5c.23-.07.41.16.44.36a1.71,1.71,0,0,1-.34,1.1.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.22,60.67a1.52,1.52,0,0,0,.47-.92c0-.16-.1-.35-.27-.33s-.24.24-.23.42c0-.17,0-.42.22-.48s.39.19.41.38a1.7,1.7,0,0,1-.4,1.09.13.13,0,1,1-.21-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.92,59.79a1.53,1.53,0,0,0,.44-.94c0-.16-.11-.34-.28-.32s-.24.25-.22.43c0-.17,0-.42.2-.49s.4.18.42.37a1.67,1.67,0,0,1-.36,1.1.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.34,58.51a1.53,1.53,0,0,0,.42-.94c0-.16-.12-.34-.3-.32s-.22.26-.2.44c0-.17,0-.42.19-.5s.4.17.43.36a1.69,1.69,0,0,1-.34,1.11.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.17,57.79a1.51,1.51,0,0,0,.41-.94c0-.16-.13-.35-.3-.32s-.22.26-.2.44c0-.17,0-.42.19-.5s.4.16.43.36a1.69,1.69,0,0,1-.33,1.11.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.53,56.85a1.51,1.51,0,0,0,.41-.94c0-.16-.12-.34-.29-.32s-.23.26-.2.44c0-.17,0-.42.19-.5s.4.17.43.36A1.72,1.72,0,0,1,62.73,57a.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.5,56a1.51,1.51,0,0,0,.41-.94c0-.16-.12-.34-.29-.32s-.23.27-.2.44c0-.17,0-.42.18-.5s.41.17.44.36a1.8,1.8,0,0,1-.33,1.11.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.29,54.61a1.61,1.61,0,0,0,.42-.94c0-.16-.13-.35-.3-.32s-.23.26-.2.44c0-.17,0-.42.19-.5s.4.17.43.36a1.76,1.76,0,0,1-.34,1.11.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.72,53.92a1.49,1.49,0,0,0,.4-1c0-.16-.12-.34-.29-.31s-.23.26-.2.44c0-.17,0-.42.18-.5s.41.16.44.35a1.73,1.73,0,0,1-.32,1.11.13.13,0,1,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.89,52.43a1.56,1.56,0,0,0,.41-1c0-.16-.13-.34-.3-.31s-.23.27-.2.44a.41.41,0,0,1,.18-.5c.23-.06.41.16.44.36a1.74,1.74,0,0,1-.32,1.11.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.75,51.06a1.56,1.56,0,0,0,.35-1c0-.16-.14-.34-.31-.3s-.21.27-.18.45c0-.17,0-.42.16-.51s.42.14.45.33A1.66,1.66,0,0,1,58,51.19a.13.13,0,0,1-.17.05.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.69,49.4a1.48,1.48,0,0,0,.34-1c0-.16-.14-.33-.31-.29s-.21.27-.17.45a.39.39,0,0,1,.15-.51c.22-.08.42.14.46.33a1.73,1.73,0,0,1-.26,1.13.13.13,0,0,1-.17.05.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.46,47.51a1.53,1.53,0,0,0,.33-1c0-.16-.15-.33-.31-.29s-.21.28-.17.46a.4.4,0,0,1,.15-.52c.22-.08.41.13.46.32a1.73,1.73,0,0,1-.24,1.14.13.13,0,0,1-.23-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,45.9a1.51,1.51,0,0,0,.31-1c0-.16-.15-.33-.32-.28s-.2.28-.16.45a.39.39,0,0,1,.14-.51c.21-.09.41.12.46.31A1.69,1.69,0,0,1,57.24,46a.12.12,0,0,1-.16,0,.11.11,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.62,44.61a1.54,1.54,0,0,0,.29-1c0-.16-.16-.33-.33-.28s-.19.29-.15.46a.41.41,0,0,1,.13-.52c.22-.09.42.11.47.3a1.65,1.65,0,0,1-.19,1.14.13.13,0,0,1-.17.07.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.63,44.08a1.49,1.49,0,0,0,.26-1c0-.16-.17-.32-.33-.27s-.19.29-.14.46c-.06-.16-.09-.41.11-.52s.43.1.48.29a1.69,1.69,0,0,1-.16,1.15.12.12,0,0,1-.16.06.11.11,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.71,43A1.55,1.55,0,0,0,55,42c0-.16-.19-.31-.35-.26s-.18.3-.12.47a.4.4,0,0,1,.09-.52c.22-.11.43.09.5.27a1.69,1.69,0,0,1-.13,1.15.11.11,0,0,1-.16.07.11.11,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.94,41.75a1.53,1.53,0,0,0,.23-1c0-.15-.19-.31-.35-.25s-.17.3-.12.47a.42.42,0,0,1,.09-.53c.21-.1.43.09.5.27a1.75,1.75,0,0,1-.12,1.15.13.13,0,0,1-.16.08.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.59,40.81a1.54,1.54,0,0,0,.18-1c-.05-.15-.2-.3-.36-.23s-.16.31-.09.47a.4.4,0,0,1,.06-.53c.2-.11.43.07.5.25a1.63,1.63,0,0,1-.06,1.15.11.11,0,0,1-.15.08.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,38.79a1.52,1.52,0,0,0,.19-1c0-.16-.19-.31-.36-.24s-.16.3-.1.47a.4.4,0,0,1,.08-.53c.2-.11.43.07.5.25a1.77,1.77,0,0,1-.08,1.16.14.14,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,37.74a1.47,1.47,0,0,0,.14-1c0-.16-.2-.3-.36-.23s-.15.31-.08.48a.39.39,0,0,1,.05-.53c.2-.13.43.05.51.23a1.68,1.68,0,0,1,0,1.15.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.61,36.13a1.6,1.6,0,0,0,.14-1c0-.16-.21-.3-.37-.23s-.15.31-.08.48c-.08-.15-.14-.4.05-.53a.38.38,0,0,1,.51.23,1.63,1.63,0,0,1,0,1.15.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,34.84a1.48,1.48,0,0,0,.1-1c0-.15-.22-.29-.37-.21s-.14.32-.06.48a.39.39,0,0,1,0-.53c.19-.13.43,0,.52.21a1.71,1.71,0,0,1,0,1.16.13.13,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.78,33.45a1.54,1.54,0,0,0,.08-1,.3.3,0,0,0-.39-.2c-.15.09-.12.33,0,.48a.39.39,0,0,1,0-.53.39.39,0,0,1,.53.19A1.72,1.72,0,0,1,54,33.52a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.64,32.33a1.52,1.52,0,0,0,0-1c-.06-.14-.23-.27-.38-.19s-.12.33,0,.49c-.09-.15-.17-.39,0-.54a.38.38,0,0,1,.53.18,1.72,1.72,0,0,1,.09,1.16.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.32,31a1.53,1.53,0,0,0,0-1c-.06-.15-.24-.28-.39-.19a.37.37,0,0,0,0,.48.39.39,0,0,1,0-.53.38.38,0,0,1,.53.18A1.69,1.69,0,0,1,54.56,31a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.94,30.51a1.52,1.52,0,0,0,0-1c-.07-.14-.25-.27-.39-.17s-.11.33,0,.48a.4.4,0,0,1,0-.53c.18-.15.44,0,.54.15a1.73,1.73,0,0,1,.13,1.16.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.35,29.13a1.52,1.52,0,0,0,0-1c-.07-.15-.25-.27-.4-.17s-.1.33,0,.48a.41.41,0,0,1,0-.53c.18-.15.44,0,.54.15a1.72,1.72,0,0,1,.15,1.15.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.1,28.07a1.54,1.54,0,0,0,0-1c-.07-.14-.25-.26-.4-.16s-.1.33,0,.48a.4.4,0,0,1,0-.53.39.39,0,0,1,.55.15,1.68,1.68,0,0,1,.15,1.14.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,26.78a1.51,1.51,0,0,0,0-1c-.08-.14-.26-.25-.4-.15s-.1.33,0,.48a.4.4,0,0,1,0-.53c.17-.16.43,0,.54.13a1.71,1.71,0,0,1,.18,1.15.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.17,25.5a1.51,1.51,0,0,0-.08-1c-.08-.14-.26-.25-.4-.14s-.08.34,0,.48a.4.4,0,0,1-.06-.53c.17-.16.43,0,.55.12a1.72,1.72,0,0,1,.22,1.13c0,.07-.07.13-.13.12a.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.48,24.22a1.51,1.51,0,0,0-.1-1,.29.29,0,0,0-.41-.13c-.13.11-.07.34,0,.48a.4.4,0,0,1-.08-.53.39.39,0,0,1,.55.1,1.78,1.78,0,0,1,.25,1.14.14.14,0,0,1-.13.12.13.13,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.89,22.89a1.54,1.54,0,0,0-.12-1c-.09-.14-.28-.24-.41-.13s-.07.34,0,.48a.4.4,0,0,1-.08-.53c.16-.16.43-.05.55.1a1.63,1.63,0,0,1,.26,1.13A.12.12,0,0,1,50,23a.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.35,21.53a1.63,1.63,0,0,0-.13-1c-.09-.13-.28-.23-.42-.12s-.06.34.05.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.08,1.71,1.71,0,0,1,.29,1.13.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.16,21.21a1.57,1.57,0,0,0-.14-1c-.09-.13-.28-.23-.41-.11s-.06.34,0,.48c-.12-.13-.24-.35-.1-.53a.39.39,0,0,1,.56.09,1.66,1.66,0,0,1,.28,1.12.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.5,19.69a1.48,1.48,0,0,0-.14-1,.3.3,0,0,0-.42-.12c-.12.12,0,.35.06.48a.4.4,0,0,1-.1-.52.38.38,0,0,1,.55.08,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.16,19.75a1.48,1.48,0,0,0-.14-1c-.09-.13-.29-.22-.42-.11s-.05.34.06.48a.4.4,0,0,1-.1-.52.37.37,0,0,1,.55.07,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.8,18.06a1.54,1.54,0,0,0-.15-1c-.09-.13-.29-.22-.42-.11s0,.35.06.48a.4.4,0,0,1-.1-.52.38.38,0,0,1,.55.07,1.68,1.68,0,0,1,.31,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.31,18.72a1.54,1.54,0,0,0-.15-1c-.1-.13-.29-.22-.42-.11s-.06.34.06.48c-.12-.12-.25-.34-.11-.52a.39.39,0,0,1,.56.07,1.74,1.74,0,0,1,.31,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.68,17.4a1.54,1.54,0,0,0-.16-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08a1.71,1.71,0,0,1,.31,1.11.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46,16.39a1.49,1.49,0,0,0-.16-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48c-.12-.12-.25-.34-.11-.52a.38.38,0,0,1,.56.07,1.68,1.68,0,0,1,.31,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.71,18a1.53,1.53,0,0,0-.15-1c-.09-.13-.29-.23-.42-.11s0,.34.07.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08A1.7,1.7,0,0,1,45,18a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,14.71a1.6,1.6,0,0,0-.16-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08a1.7,1.7,0,0,1,.3,1.11.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.12S45,14.71,45,14.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.47,16.3a1.57,1.57,0,0,0-.16-1c-.09-.13-.28-.22-.41-.1s-.06.34.06.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.07a1.74,1.74,0,0,1,.31,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.78,15.21a1.55,1.55,0,0,0-.16-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07A1.74,1.74,0,0,1,44,15.23a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.17,17.26a1.53,1.53,0,0,0-.15-1c-.1-.13-.29-.23-.42-.11s-.05.34.06.48c-.12-.12-.25-.34-.11-.52a.38.38,0,0,1,.56.07,1.67,1.67,0,0,1,.31,1.11.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.89,14.27a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.27,16.2a1.54,1.54,0,0,0-.15-1c-.09-.13-.29-.22-.42-.11s-.05.35.07.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.55.07,1.68,1.68,0,0,1,.31,1.12.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.62,14.89a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.33,14.41a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.42-.11s0,.34.07.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08a1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.44,16.47a1.56,1.56,0,0,0-.15-1c-.09-.13-.29-.22-.42-.11s0,.35.06.48a.4.4,0,0,1-.1-.52c.15-.17.43-.07.55.07a1.68,1.68,0,0,1,.31,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,15.48a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.13A.13.13,0,0,1,39,15.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.35,16.52a1.53,1.53,0,0,0-.15-1c-.09-.14-.28-.23-.41-.12s-.06.35.06.48a.39.39,0,0,1-.11-.52c.16-.17.43-.07.56.08a1.63,1.63,0,0,1,.3,1.11.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,18.74a1.53,1.53,0,0,0-.15-1c-.09-.14-.28-.23-.41-.12s-.06.34.05.48a.4.4,0,0,1-.1-.52c.16-.17.43-.07.56.08a1.73,1.73,0,0,1,.3,1.12.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.39,18a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08a1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.92,19.63a1.51,1.51,0,0,0-.14-1c-.09-.13-.28-.23-.41-.11s-.06.34.05.48a.41.41,0,0,1-.1-.53.39.39,0,0,1,.56.08,1.77,1.77,0,0,1,.29,1.13.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.67,19.57a1.48,1.48,0,0,0-.13-1c-.09-.13-.28-.23-.42-.12s-.06.34.06.48a.4.4,0,0,1-.1-.52c.16-.17.43-.07.56.08a1.66,1.66,0,0,1,.28,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.05,21a1.48,1.48,0,0,0-.13-1c-.09-.14-.28-.23-.41-.12s-.07.34.05.48c-.12-.13-.24-.35-.1-.53a.39.39,0,0,1,.56.09A1.73,1.73,0,0,1,37.3,21a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.19,22.26a1.57,1.57,0,0,0-.12-1,.29.29,0,0,0-.41-.13c-.13.12-.07.34.05.49-.12-.13-.24-.35-.09-.53s.43-.06.55.09a1.69,1.69,0,0,1,.27,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.5,22.41a1.54,1.54,0,0,0-.11-1c-.09-.14-.28-.24-.41-.13s-.07.34,0,.48a.4.4,0,0,1-.09-.52.39.39,0,0,1,.56.09,1.74,1.74,0,0,1,.26,1.13.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,24.09a1.57,1.57,0,0,0-.09-1,.3.3,0,0,0-.41-.14c-.13.12-.07.34,0,.49-.11-.13-.22-.36-.07-.53A.38.38,0,0,1,36,23a1.67,1.67,0,0,1,.24,1.13.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.19,24.72a1.57,1.57,0,0,0-.09-1c-.09-.14-.27-.25-.41-.14s-.08.34,0,.48a.39.39,0,0,1-.07-.53c.17-.16.43,0,.55.11a1.7,1.7,0,0,1,.23,1.14.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,25.52a1.51,1.51,0,0,0-.07-1c-.08-.14-.27-.25-.41-.14s-.08.34,0,.48a.4.4,0,0,1-.07-.53.39.39,0,0,1,.55.12,1.69,1.69,0,0,1,.21,1.14.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,26.68a1.57,1.57,0,0,0,0-1c-.08-.14-.26-.26-.4-.16s-.09.34,0,.49a.4.4,0,0,1,0-.53c.17-.16.43,0,.54.13a1.63,1.63,0,0,1,.19,1.14.11.11,0,0,1-.13.11A.12.12,0,0,1,37,26.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,27.55a1.61,1.61,0,0,0,0-1c-.07-.14-.25-.26-.4-.16s-.1.33,0,.48a.4.4,0,0,1,0-.53.39.39,0,0,1,.55.15,1.7,1.7,0,0,1,.15,1.15.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.63,28.41a1.52,1.52,0,0,0,0-1c-.07-.14-.25-.26-.39-.17s-.1.34,0,.49c-.1-.14-.2-.37,0-.53s.43,0,.54.14a1.71,1.71,0,0,1,.15,1.15.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.2,29a1.58,1.58,0,0,0,0-1c-.08-.14-.26-.26-.4-.17s-.1.34,0,.49c-.11-.14-.21-.37,0-.53s.44,0,.54.14a1.71,1.71,0,0,1,.16,1.15.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.22,30a1.54,1.54,0,0,0,0-1c-.07-.14-.25-.26-.4-.16s-.1.33,0,.48a.4.4,0,0,1,0-.53.38.38,0,0,1,.54.15A1.63,1.63,0,0,1,37.47,30a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.72,31.18a1.58,1.58,0,0,0,0-1c-.08-.14-.26-.26-.4-.16s-.1.33,0,.48c-.11-.14-.21-.37,0-.53s.43,0,.54.14A1.71,1.71,0,0,1,38,31.23a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.06,31.14a1.51,1.51,0,0,0-.07-1c-.09-.14-.27-.25-.41-.14s-.08.33,0,.48c-.11-.13-.22-.36-.06-.53s.43,0,.55.11a1.7,1.7,0,0,1,.22,1.14.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.62,32.71a1.52,1.52,0,0,0-.06-1c-.07-.14-.26-.25-.4-.15s-.09.34,0,.49a.41.41,0,0,1-.05-.54.4.4,0,0,1,.55.13,1.7,1.7,0,0,1,.19,1.14.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.26,33.06a1.48,1.48,0,0,0-.13-1c-.09-.14-.28-.23-.41-.12s-.06.34.05.48c-.12-.13-.24-.35-.1-.53a.39.39,0,0,1,.56.09,1.66,1.66,0,0,1,.28,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,34.33a1.52,1.52,0,0,0-.13-1c-.08-.13-.28-.23-.41-.11s-.06.34,0,.48c-.12-.13-.24-.35-.09-.53s.43-.06.55.09a1.66,1.66,0,0,1,.28,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.53,35.43a1.6,1.6,0,0,0-.13-1,.3.3,0,0,0-.42-.12c-.13.12-.06.34.05.48-.12-.13-.24-.35-.09-.53s.43-.06.55.09a1.72,1.72,0,0,1,.29,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.06,36.22a1.58,1.58,0,0,0-.25-1,.3.3,0,0,0-.42-.07c-.12.13,0,.35.1.47a.4.4,0,0,1-.15-.51c.14-.19.42-.11.56,0a1.67,1.67,0,0,1,.41,1.08.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.7,37.5a1.56,1.56,0,0,0-.24-1c-.11-.12-.31-.2-.43-.07s0,.34.1.47c-.13-.11-.27-.32-.15-.51a.38.38,0,0,1,.56,0A1.72,1.72,0,0,1,37,37.5a.12.12,0,0,1-.12.13.11.11,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.47,38.76a1.49,1.49,0,0,0-.28-1c-.11-.11-.31-.18-.43,0s0,.35.12.47c-.14-.11-.29-.31-.17-.51s.42-.13.56,0a1.67,1.67,0,0,1,.45,1.07.12.12,0,0,1-.1.14.13.13,0,0,1-.15-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.18,39.18a1.54,1.54,0,0,0-.37-1,.29.29,0,0,0-.43,0c-.1.14,0,.34.16.45-.14-.09-.31-.28-.21-.49a.39.39,0,0,1,.56,0,1.67,1.67,0,0,1,.53,1,.13.13,0,0,1-.09.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.88,40.5a1.54,1.54,0,0,0-.36-1c-.11-.11-.32-.17-.43,0s0,.35.16.46a.39.39,0,0,1-.21-.49.39.39,0,0,1,.56,0,1.63,1.63,0,0,1,.52,1,.11.11,0,0,1-.09.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.38,41A1.55,1.55,0,0,0,34,40c-.12-.1-.34-.14-.43,0s0,.34.19.44A.34.34,0,1,1,34,39.9a1.74,1.74,0,0,1,.61,1,.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,42.24a1.55,1.55,0,0,0-.43-.94c-.13-.1-.34-.14-.43,0s0,.34.19.44c-.15-.08-.33-.26-.25-.47s.39-.19.56-.09a1.74,1.74,0,0,1,.6,1,.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.52,43.09a1.51,1.51,0,0,0-.51-.91c-.13-.09-.34-.11-.42,0s.07.34.22.42c-.16-.07-.35-.23-.28-.45s.38-.22.55-.13a1.77,1.77,0,0,1,.68.94.12.12,0,0,1-.08.16.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.26,44.28a1.54,1.54,0,0,0-.49-.91.3.3,0,0,0-.43,0c-.08.16.07.34.22.43-.16-.07-.35-.23-.28-.45a.38.38,0,0,1,.55-.12,1.67,1.67,0,0,1,.67.94.12.12,0,1,1-.23.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.8,45.05a1.54,1.54,0,0,0-.55-.88c-.13-.08-.35-.09-.42.07s.08.33.25.41c-.17-.06-.37-.21-.31-.43a.39.39,0,0,1,.54-.16A1.7,1.7,0,0,1,33,45a.12.12,0,0,1-.06.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.27,46.55a1.5,1.5,0,0,0-.54-.87.29.29,0,0,0-.43.06c-.06.16.09.34.25.42-.16-.07-.36-.22-.3-.44s.36-.24.54-.16a1.7,1.7,0,0,1,.72.91.13.13,0,0,1-.06.17.14.14,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.93,47.27a1.55,1.55,0,0,0-.58-.86.29.29,0,0,0-.42.09c-.06.16.1.33.26.4-.16-.06-.37-.2-.32-.42a.38.38,0,0,1,.53-.18,1.65,1.65,0,0,1,.76.87.13.13,0,0,1,0,.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.94,48.15a1.58,1.58,0,0,0-.6-.84c-.15-.07-.36-.07-.43.1s.11.32.28.39c-.17,0-.38-.18-.34-.41a.38.38,0,0,1,.53-.19,1.68,1.68,0,0,1,.79.85.13.13,0,0,1-.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.78,49.45a1.64,1.64,0,0,0-.59-.85.3.3,0,0,0-.43.09c-.06.16.11.33.27.4-.16-.06-.37-.2-.33-.42a.39.39,0,0,1,.54-.18,1.66,1.66,0,0,1,.77.86.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.39,51.41a1.53,1.53,0,0,0-.61-.83c-.15-.08-.36-.07-.42.09s.11.33.28.4c-.17,0-.38-.19-.34-.41a.38.38,0,0,1,.52-.2,1.72,1.72,0,0,1,.8.84.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.76,53.11a1.53,1.53,0,0,0-.61-.83.29.29,0,0,0-.42.09c-.05.16.11.33.28.4-.17-.06-.38-.19-.34-.42s.35-.26.53-.19A1.7,1.7,0,0,1,31,53a.12.12,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.68,54.16a1.53,1.53,0,0,0-.61-.83c-.15-.07-.36-.07-.42.1s.11.32.28.39c-.17,0-.38-.18-.34-.41s.34-.27.52-.2a1.7,1.7,0,0,1,.8.85.13.13,0,0,1-.05.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.84,55.36a1.59,1.59,0,0,0-.62-.83c-.14-.07-.36-.06-.42.1s.12.33.28.4c-.16-.05-.38-.18-.34-.41s.34-.27.53-.2a1.72,1.72,0,0,1,.8.84.13.13,0,0,1-.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.52,56.46a1.54,1.54,0,0,0-.61-.84.29.29,0,0,0-.42.09c0,.17.11.33.28.4-.17,0-.38-.19-.34-.42A.4.4,0,0,1,29,55.5a1.74,1.74,0,0,1,.79.86.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.43,57.2a1.47,1.47,0,0,0-.58-.85.29.29,0,0,0-.42.08c-.06.17.1.33.26.41-.16-.06-.37-.2-.32-.43s.35-.25.53-.17a1.65,1.65,0,0,1,.76.87.11.11,0,0,1-.05.16.12.12,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.17,57.13a1.5,1.5,0,0,0-.62-.83.29.29,0,0,0-.42.09c-.05.17.12.33.28.4-.16,0-.37-.18-.34-.41s.35-.27.53-.2a1.72,1.72,0,0,1,.8.84.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.72,58.43a1.51,1.51,0,0,0-.59-.84c-.14-.08-.36-.07-.42.09s.1.33.27.4c-.17,0-.38-.19-.33-.42s.35-.26.53-.19a1.74,1.74,0,0,1,.77.86.12.12,0,0,1-.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,59.4a1.52,1.52,0,0,0-.57-.86.29.29,0,0,0-.42.08c-.07.16.09.33.26.41-.17-.06-.37-.21-.32-.43s.36-.25.53-.17a1.66,1.66,0,0,1,.75.88.12.12,0,0,1-.06.17.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.61,59.77a1.51,1.51,0,0,0-.59-.84.3.3,0,0,0-.43.09c-.05.16.11.33.28.4-.17-.05-.38-.19-.33-.42s.35-.26.53-.19a1.7,1.7,0,0,1,.77.86.13.13,0,1,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.06,60.94a1.56,1.56,0,0,0-.57-.87.29.29,0,0,0-.42.08c-.07.16.09.33.25.41-.16-.06-.36-.21-.31-.43s.36-.25.53-.17a1.68,1.68,0,0,1,.75.89.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.19,62.75a1.52,1.52,0,0,0-.54-.88c-.13-.09-.35-.1-.42.06s.08.34.24.42c-.16-.07-.36-.22-.3-.44a.38.38,0,0,1,.54-.15,1.68,1.68,0,0,1,.71.91.13.13,0,0,1-.06.17.14.14,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,61.69a1.59,1.59,0,0,0-.55-.88.3.3,0,0,0-.43.07c-.06.16.09.33.25.41-.16-.06-.36-.21-.3-.44s.36-.24.54-.15a1.64,1.64,0,0,1,.72.9.13.13,0,0,1-.06.17A.13.13,0,0,1,30,61.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.84,61a1.5,1.5,0,0,0-.59-.85c-.14-.08-.36-.07-.42.09s.1.33.27.4c-.17,0-.38-.19-.33-.42s.35-.26.53-.18a1.66,1.66,0,0,1,.77.86.12.12,0,1,1-.22.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.74,61.3a1.58,1.58,0,0,0-.6-.84c-.15-.07-.36-.07-.43.09s.11.33.28.4c-.17-.05-.38-.19-.33-.42a.37.37,0,0,1,.52-.18,1.72,1.72,0,0,1,.79.85.13.13,0,0,1-.23.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,61.57a1.55,1.55,0,0,0-.58-.86.29.29,0,0,0-.42.08c-.06.16.1.33.26.4-.16,0-.37-.2-.32-.42a.38.38,0,0,1,.54-.17,1.66,1.66,0,0,1,.75.88.12.12,0,0,1-.06.16.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,62.35a1.58,1.58,0,0,0-.58-.86.29.29,0,0,0-.42.08c-.06.17.1.34.26.41-.16-.06-.37-.2-.32-.43a.39.39,0,0,1,.53-.17,1.68,1.68,0,0,1,.76.88.12.12,0,0,1-.06.17.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,63a1.52,1.52,0,0,0-.54-.88c-.14-.08-.35-.09-.43.06s.08.34.25.42c-.17-.06-.37-.21-.31-.44a.38.38,0,0,1,.54-.15,1.77,1.77,0,0,1,.73.91.14.14,0,0,1-.07.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.93,63a1.54,1.54,0,0,0-.56-.87.3.3,0,0,0-.43.07c-.06.16.09.34.26.41-.17-.06-.37-.21-.31-.43a.37.37,0,0,1,.53-.16,1.7,1.7,0,0,1,.74.89.13.13,0,1,1-.23.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.8,62.87a1.54,1.54,0,0,0-.59-.85.29.29,0,0,0-.42.08c-.06.17.1.33.27.41-.17-.06-.37-.2-.33-.43s.36-.25.54-.17a1.7,1.7,0,0,1,.76.87.13.13,0,1,1-.23.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.74,64.63a1.56,1.56,0,0,0-.53-.88.29.29,0,0,0-.43.06c-.07.16.08.33.24.42-.16-.07-.36-.22-.3-.45s.37-.23.54-.14a1.64,1.64,0,0,1,.71.91.13.13,0,0,1-.06.17.14.14,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.83,64.31a1.52,1.52,0,0,0-.57-.86.29.29,0,0,0-.42.08c-.06.16.09.33.26.41-.17-.06-.37-.21-.32-.43s.36-.25.53-.17a1.72,1.72,0,0,1,.76.88.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.9,66.58a1.52,1.52,0,0,0-.54-.88c-.13-.09-.35-.1-.42.06s.08.34.24.42c-.16-.07-.36-.22-.3-.44a.38.38,0,0,1,.54-.15,1.68,1.68,0,0,1,.72.9.12.12,0,0,1-.07.17.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,65.82a1.54,1.54,0,0,0-.56-.87A.3.3,0,0,0,23,65c-.06.16.09.33.26.41-.17-.06-.37-.21-.32-.43a.39.39,0,0,1,.54-.16,1.7,1.7,0,0,1,.74.89.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.53,65.56a1.48,1.48,0,0,0-.55-.87c-.13-.09-.35-.1-.42.06s.09.34.25.42c-.17-.06-.37-.21-.31-.44a.39.39,0,0,1,.54-.16,1.77,1.77,0,0,1,.73.91.12.12,0,0,1-.07.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.11,66.74a1.59,1.59,0,0,0-.53-.89.29.29,0,0,0-.43.06c-.07.16.08.34.24.42-.16-.07-.36-.22-.3-.44a.38.38,0,0,1,.54-.15,1.7,1.7,0,0,1,.72.91.14.14,0,0,1-.07.17.11.11,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.27,66.75a1.57,1.57,0,0,0-.53-.89c-.13-.08-.35-.1-.42.06s.07.34.23.42c-.16-.07-.35-.22-.29-.44a.38.38,0,0,1,.54-.15,1.74,1.74,0,0,1,.71.92.14.14,0,0,1-.07.17.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,65.53a1.52,1.52,0,0,0-.54-.88c-.14-.08-.35-.09-.43.06s.08.34.25.42c-.17-.06-.37-.22-.31-.44a.38.38,0,0,1,.54-.15,1.66,1.66,0,0,1,.72.91.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.23,66a1.53,1.53,0,0,0-.53-.89.3.3,0,0,0-.43,0c-.07.16.08.34.24.43-.16-.07-.36-.23-.3-.45a.39.39,0,0,1,.55-.14,1.64,1.64,0,0,1,.7.92.12.12,0,0,1-.06.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.55,64.73a1.6,1.6,0,0,0-.58-.86c-.14-.08-.36-.08-.42.08s.09.33.26.41c-.17-.06-.37-.2-.32-.43s.35-.25.53-.17a1.7,1.7,0,0,1,.76.87.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.3,64.45a1.54,1.54,0,0,0-.56-.87.28.28,0,0,0-.42.07c-.07.16.09.33.25.41-.17-.06-.37-.21-.31-.43s.36-.25.54-.16a1.69,1.69,0,0,1,.73.89.13.13,0,0,1-.06.17.14.14,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.83,63.21a1.57,1.57,0,0,0-.6-.85c-.14-.07-.35-.07-.42.09s.11.33.27.4c-.16-.05-.37-.19-.33-.42a.38.38,0,0,1,.53-.18,1.72,1.72,0,0,1,.78.86.13.13,0,0,1-.06.17.14.14,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.12,62a1.53,1.53,0,0,0-.61-.83c-.14-.08-.36-.07-.42.09s.11.33.27.4c-.16,0-.37-.19-.33-.42s.34-.26.52-.19a1.64,1.64,0,0,1,.79.85.12.12,0,0,1-.05.17.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.81,59.9a1.58,1.58,0,0,0-.63-.82c-.14-.07-.35-.06-.41.1s.11.33.28.39c-.17,0-.38-.17-.34-.4s.34-.28.52-.21a1.64,1.64,0,0,1,.8.84A.12.12,0,0,1,25,60a.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.65,58.28a1.56,1.56,0,0,0-.64-.82.29.29,0,0,0-.41.11c-.05.17.12.32.29.39-.17-.05-.39-.18-.35-.41s.34-.27.52-.21a1.72,1.72,0,0,1,.81.83.12.12,0,0,1-.05.17.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.88,56.5a1.57,1.57,0,0,0-.64-.81c-.15-.06-.36,0-.42.12s.13.32.3.38c-.17,0-.39-.17-.36-.4s.34-.28.52-.21a1.72,1.72,0,0,1,.83.81.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.9,54.65a1.53,1.53,0,0,0-.66-.79c-.14-.07-.36,0-.41.12s.13.32.3.37c-.17,0-.39-.16-.36-.39s.33-.29.51-.22a1.64,1.64,0,0,1,.84.8.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.91,53a1.5,1.5,0,0,0-.67-.79c-.15-.07-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.16-.36-.39s.33-.29.51-.23a1.68,1.68,0,0,1,.85.79.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.4,51.77a1.51,1.51,0,0,0-.69-.76.3.3,0,0,0-.41.14c0,.17.15.32.32.37-.17,0-.39-.15-.38-.38s.32-.3.5-.25a1.69,1.69,0,0,1,.88.76.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.9,50a1.55,1.55,0,0,0-.71-.76c-.15-.05-.36,0-.4.15s.15.31.32.36c-.17,0-.4-.14-.39-.37a.4.4,0,0,1,.5-.26,1.69,1.69,0,0,1,.89.75.12.12,0,0,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.75,47.66a1.55,1.55,0,0,0-.72-.73c-.15,0-.36,0-.4.15s.15.31.33.36c-.17,0-.4-.13-.4-.37s.31-.31.5-.26a1.65,1.65,0,0,1,.9.72.13.13,0,1,1-.2.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.81,45a1.52,1.52,0,0,0-.74-.72.29.29,0,0,0-.4.16c0,.17.16.3.34.34-.17,0-.4-.12-.4-.35s.3-.32.49-.28a1.77,1.77,0,0,1,.92.71A.13.13,0,0,1,21,45a.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.75,44a1.57,1.57,0,0,0-.73-.73c-.15,0-.36,0-.4.16s.16.31.33.35c-.17,0-.4-.13-.39-.36s.3-.32.49-.27a1.68,1.68,0,0,1,.91.72.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.92,42.49a1.54,1.54,0,0,0-.74-.73c-.15,0-.36,0-.4.16s.16.31.34.35c-.17,0-.4-.13-.4-.36s.3-.32.49-.27a1.62,1.62,0,0,1,.91.71.12.12,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.87,40.41a1.52,1.52,0,0,0-.74-.72c-.15,0-.36,0-.4.16s.17.31.34.35c-.17,0-.4-.13-.4-.36s.3-.32.49-.28a1.77,1.77,0,0,1,.92.71.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.29,39a1.52,1.52,0,0,0-.74-.72c-.15,0-.36,0-.4.16s.16.3.34.34c-.17,0-.4-.12-.4-.35s.3-.32.49-.28a1.77,1.77,0,0,1,.92.71.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,37.85a1.5,1.5,0,0,0-.72-.73c-.15-.06-.37,0-.4.15s.15.31.33.35c-.18,0-.4-.13-.4-.36s.31-.31.5-.27a1.72,1.72,0,0,1,.9.73.13.13,0,0,1-.2.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.6,36.52a1.51,1.51,0,0,0-.71-.74c-.15-.06-.37,0-.41.15s.16.31.33.35c-.17,0-.4-.13-.39-.36s.31-.31.5-.26a1.71,1.71,0,0,1,.9.73.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.35,35.54a1.59,1.59,0,0,0-.71-.76.29.29,0,0,0-.4.15c0,.17.15.31.32.36-.17,0-.4-.14-.38-.38s.31-.3.5-.25a1.71,1.71,0,0,1,.88.75.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.66,34a1.53,1.53,0,0,0-.69-.77c-.14-.06-.36,0-.41.13s.14.32.32.37c-.17,0-.4-.15-.38-.38s.32-.3.51-.24a1.63,1.63,0,0,1,.86.77.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.55,33.16a1.53,1.53,0,0,0-.66-.79c-.14-.07-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.17-.36-.4s.33-.28.51-.22a1.72,1.72,0,0,1,.84.8.12.12,0,1,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.63,31.61A1.56,1.56,0,0,0,21,30.8c-.15-.07-.36-.06-.42.1s.12.33.29.39c-.17,0-.38-.17-.35-.4s.34-.28.52-.21a1.68,1.68,0,0,1,.81.83.12.12,0,0,1,0,.17.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.74,31a1.5,1.5,0,0,0-.59-.85c-.14-.07-.36-.07-.42.09s.1.33.27.4c-.17-.05-.38-.19-.33-.42s.35-.26.53-.18a1.66,1.66,0,0,1,.77.86.12.12,0,0,1,0,.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.05,30.27a1.59,1.59,0,0,0-.55-.88.3.3,0,0,0-.43.07c-.06.16.09.33.25.41-.16-.06-.36-.21-.31-.43a.38.38,0,0,1,.54-.16,1.65,1.65,0,0,1,.73.9.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.09,28.15a1.47,1.47,0,0,0-.45-.93c-.13-.1-.34-.13-.43,0s.05.34.2.44a.42.42,0,0,1-.26-.47c.1-.21.39-.2.56-.1a1.73,1.73,0,0,1,.63,1,.12.12,0,0,1-.08.15.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.92,29.49a1.56,1.56,0,0,0-.56-.87c-.13-.08-.35-.09-.42.07s.09.34.25.42c-.16-.06-.36-.21-.31-.44s.36-.24.54-.16a1.74,1.74,0,0,1,.74.89.13.13,0,0,1-.07.17.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.75,28a1.51,1.51,0,0,0-.49-.91c-.13-.09-.34-.11-.42,0s.06.34.22.43c-.16-.07-.35-.24-.28-.46s.38-.21.55-.12a1.68,1.68,0,0,1,.66.95.12.12,0,0,1-.07.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.24,29.1a1.57,1.57,0,0,0-.51-.9c-.13-.09-.35-.11-.43.05s.08.34.23.42c-.16-.07-.35-.22-.28-.45s.37-.22.54-.13a1.68,1.68,0,0,1,.69.93.13.13,0,0,1-.07.17.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.55,28.42a1.52,1.52,0,0,0-.54-.88c-.13-.09-.35-.1-.42.06s.08.34.24.42c-.16-.07-.36-.22-.3-.44a.38.38,0,0,1,.54-.15,1.7,1.7,0,0,1,.72.91.12.12,0,0,1-.07.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.55,29.32a1.52,1.52,0,0,0-.47-.91c-.13-.1-.35-.12-.43,0s.06.34.21.43a.4.4,0,0,1-.27-.46c.09-.21.38-.21.55-.11a1.7,1.7,0,0,1,.66,1,.14.14,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.33,29.65a1.5,1.5,0,0,0-.59-.85c-.15-.07-.36-.07-.43.09s.11.33.28.4c-.17-.05-.38-.19-.34-.41a.38.38,0,0,1,.53-.19,1.64,1.64,0,0,1,.78.86.12.12,0,1,1-.22.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.93,30a1.53,1.53,0,0,0-.64-.81c-.15-.07-.36-.06-.42.11s.12.32.29.39c-.17-.05-.38-.18-.35-.41s.34-.27.52-.21a1.72,1.72,0,0,1,.82.82.13.13,0,0,1-.22.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.14,31a1.5,1.5,0,0,0-.67-.79c-.15-.06-.36,0-.41.13s.13.32.31.37c-.18,0-.39-.16-.37-.39s.33-.29.51-.23a1.68,1.68,0,0,1,.85.79.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.86,32.29a1.49,1.49,0,0,0-.69-.77.3.3,0,0,0-.41.14c0,.17.15.31.32.36-.17,0-.39-.14-.38-.38s.32-.3.51-.24a1.71,1.71,0,0,1,.87.76.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.55,31.94a1.53,1.53,0,0,0-.71-.75c-.15-.06-.36,0-.4.14s.15.31.32.36c-.17,0-.4-.14-.39-.37s.32-.3.51-.25a1.69,1.69,0,0,1,.88.74.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.34,33.44a1.48,1.48,0,0,0-.72-.74c-.15-.05-.36,0-.4.16s.16.31.33.35c-.17,0-.4-.13-.39-.36a.38.38,0,0,1,.49-.27,1.69,1.69,0,0,1,.91.73.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.63,34.86a1.52,1.52,0,0,0-.74-.72c-.15,0-.36,0-.4.16s.17.31.34.35c-.17,0-.4-.12-.4-.36s.3-.32.49-.27a1.7,1.7,0,0,1,.92.7.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,36.5a1.54,1.54,0,0,0-.75-.7c-.16,0-.37,0-.4.17s.17.3.35.34c-.18,0-.41-.12-.41-.35s.29-.32.48-.29a1.73,1.73,0,0,1,.94.69.13.13,0,0,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.68,38.47a1.51,1.51,0,0,0-.77-.69.29.29,0,0,0-.39.18c0,.17.18.3.35.33-.17,0-.4-.11-.41-.34s.28-.33.48-.3a1.7,1.7,0,0,1,.94.67.12.12,0,1,1-.19.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.79,39.54a1.51,1.51,0,0,0-.78-.68.29.29,0,0,0-.39.18c0,.18.18.3.36.33-.17,0-.41-.1-.42-.33s.28-.34.47-.31a1.71,1.71,0,0,1,1,.66.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.19,41a1.56,1.56,0,0,0-.78-.68c-.15,0-.36,0-.39.19s.18.29.36.32c-.17,0-.41-.1-.42-.33s.28-.33.47-.3a1.7,1.7,0,0,1,1,.65.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.94,42.41a1.52,1.52,0,0,0-.79-.67c-.15,0-.36,0-.38.19s.18.29.36.32c-.18,0-.41-.1-.43-.33s.28-.34.47-.31a1.68,1.68,0,0,1,1,.65.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14,43.32a1.52,1.52,0,0,0-.79-.67c-.15,0-.36,0-.38.2s.18.29.36.32c-.17,0-.41-.1-.42-.33s.27-.34.46-.31a1.76,1.76,0,0,1,1,.64.13.13,0,1,1-.19.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15,44.44a1.53,1.53,0,0,0-.78-.67.29.29,0,0,0-.39.18c0,.18.18.3.36.33-.17,0-.41-.1-.42-.33s.28-.34.47-.31a1.75,1.75,0,0,1,1,.65.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.75,45.28A1.6,1.6,0,0,0,13,44.6c-.15,0-.36,0-.39.19s.19.3.36.33c-.17,0-.41-.1-.42-.33s.28-.34.47-.31a1.7,1.7,0,0,1,1,.65.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.41,46.78a1.54,1.54,0,0,0-.78-.69c-.15,0-.36,0-.39.19s.18.3.36.33c-.17,0-.41-.1-.42-.34s.28-.33.48-.3a1.69,1.69,0,0,1,.95.66.12.12,0,1,1-.19.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.78,48a1.55,1.55,0,0,0-.77-.69c-.16,0-.37,0-.39.18s.17.3.35.33c-.17,0-.41-.1-.42-.33a.39.39,0,0,1,.48-.3,1.66,1.66,0,0,1,1,.66A.13.13,0,0,1,14,48a.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.85,49.5a1.52,1.52,0,0,0-.76-.7.29.29,0,0,0-.39.18c0,.17.17.3.35.33-.17,0-.41-.11-.41-.34s.28-.33.47-.29a1.72,1.72,0,0,1,1,.67.13.13,0,1,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.5,51a1.61,1.61,0,0,0-.75-.71c-.16,0-.37,0-.4.17s.17.3.35.34c-.18,0-.41-.11-.41-.34a.38.38,0,0,1,.48-.29,1.66,1.66,0,0,1,.93.68.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.2,53.24a1.54,1.54,0,0,0-.73-.72c-.15-.05-.37,0-.4.16s.16.31.34.35c-.18,0-.41-.12-.4-.35s.29-.32.48-.28a1.64,1.64,0,0,1,.92.71.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.12,54.93a1.5,1.5,0,0,0-.72-.73.29.29,0,0,0-.4.15c0,.17.15.31.33.35-.17,0-.4-.13-.4-.36s.31-.31.5-.27a1.72,1.72,0,0,1,.9.73.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.72,56.51a1.53,1.53,0,0,0-.71-.75.3.3,0,0,0-.41.14c0,.18.16.32.33.36-.17,0-.4-.13-.39-.36s.31-.31.5-.26a1.67,1.67,0,0,1,.89.74.12.12,0,1,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.62,57.53a1.53,1.53,0,0,0-.7-.76c-.15-.05-.36,0-.4.15s.14.31.32.36c-.17,0-.4-.14-.38-.38s.31-.3.5-.25a1.75,1.75,0,0,1,.88.75.13.13,0,1,1-.21.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.87,59.2a1.56,1.56,0,0,0-.69-.78c-.14-.06-.36,0-.41.13s.14.32.32.37c-.17,0-.4-.15-.38-.38s.32-.29.51-.24a1.67,1.67,0,0,1,.86.77.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.83,60.57a1.5,1.5,0,0,0-.67-.79c-.15-.06-.36,0-.41.13s.13.32.3.37c-.17,0-.38-.16-.36-.39s.32-.29.51-.23a1.68,1.68,0,0,1,.85.79.12.12,0,1,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.91,61.82a1.53,1.53,0,0,0-.65-.8.3.3,0,0,0-.42.11c0,.17.13.33.3.39-.17-.05-.38-.17-.36-.4s.34-.28.52-.22a1.7,1.7,0,0,1,.83.8.13.13,0,0,1,0,.18.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.25,63.54a1.58,1.58,0,0,0-.63-.82c-.14-.07-.36-.06-.42.11s.12.32.29.39c-.17,0-.38-.18-.35-.41s.34-.27.53-.2a1.74,1.74,0,0,1,.81.82.14.14,0,0,1-.05.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.16,65a1.58,1.58,0,0,0-.6-.84c-.14-.08-.36-.07-.42.09s.1.33.27.4a.4.4,0,0,1-.33-.42c.06-.23.35-.26.53-.19a1.76,1.76,0,0,1,.78.86.12.12,0,0,1-.06.17.12.12,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.66,64.8A1.5,1.5,0,0,0,14,64a.3.3,0,0,0-.42.1c0,.16.12.32.28.39-.16,0-.37-.18-.34-.41s.35-.27.53-.2a1.72,1.72,0,0,1,.79.85.12.12,0,0,1-.05.17.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.78,64.25a1.53,1.53,0,0,0-.61-.83c-.15-.07-.36-.07-.42.1s.11.33.28.39c-.17,0-.38-.18-.34-.41a.38.38,0,0,1,.52-.2,1.72,1.72,0,0,1,.8.84.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.06,65.33a1.58,1.58,0,0,0-.6-.84c-.14-.08-.36-.07-.42.09s.1.33.27.4c-.17-.06-.38-.19-.33-.42s.35-.26.53-.19a1.76,1.76,0,0,1,.78.86.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.26,62.15a1.51,1.51,0,0,0-.65-.81.29.29,0,0,0-.41.12c0,.16.13.32.29.38-.16,0-.38-.17-.35-.4s.33-.28.52-.22a1.76,1.76,0,0,1,.83.81.14.14,0,0,1-.05.18.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.64,58.84a1.53,1.53,0,0,0-.69-.77c-.15-.06-.36,0-.41.13s.14.32.32.37c-.18,0-.4-.15-.38-.38s.32-.3.51-.24a1.66,1.66,0,0,1,.86.77.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.34,56.44a1.49,1.49,0,0,0-.7-.76.3.3,0,0,0-.41.15c0,.17.15.31.33.36-.18,0-.4-.14-.39-.37s.31-.31.5-.26a1.68,1.68,0,0,1,.89.75.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,54.2a1.61,1.61,0,0,0-.72-.74c-.15-.05-.36,0-.4.16s.15.31.33.35c-.17,0-.4-.13-.39-.36s.3-.31.49-.27a1.72,1.72,0,0,1,.9.73.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.42,52.62a1.48,1.48,0,0,0-.73-.73c-.15,0-.36,0-.4.16s.16.31.34.35c-.18,0-.4-.13-.4-.36s.3-.32.49-.27a1.62,1.62,0,0,1,.91.71.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.71,51.45a1.52,1.52,0,0,0-.73-.73c-.15,0-.36,0-.4.16s.16.31.34.35c-.17,0-.4-.12-.4-.36s.3-.31.49-.27a1.64,1.64,0,0,1,.91.72.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.28,50a1.57,1.57,0,0,0-.73-.73c-.15,0-.37,0-.4.16s.16.31.34.35c-.18,0-.41-.12-.4-.35s.3-.32.49-.28a1.69,1.69,0,0,1,.91.71.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.33,48.74a1.59,1.59,0,0,0-.74-.73c-.15,0-.36,0-.4.16s.16.31.34.35c-.17,0-.4-.12-.4-.35s.3-.32.49-.28a1.64,1.64,0,0,1,.91.72.11.11,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.73,47.91a1.52,1.52,0,0,0-.72-.74.29.29,0,0,0-.4.15c0,.17.15.31.33.35-.18,0-.4-.13-.39-.36S21.86,47,22,47a1.7,1.7,0,0,1,.9.74.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.82,46.27a1.46,1.46,0,0,0-.72-.73c-.16-.06-.37,0-.41.15s.16.31.34.35c-.17,0-.4-.12-.4-.36s.31-.31.5-.27a1.67,1.67,0,0,1,.9.73.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.85,46a1.53,1.53,0,0,0-.71-.75c-.15-.06-.36,0-.4.14s.15.31.32.36c-.17,0-.4-.14-.39-.37a.4.4,0,0,1,.5-.26,1.74,1.74,0,0,1,.89.75.12.12,0,0,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.36,44.75a1.57,1.57,0,0,0-.71-.75.3.3,0,0,0-.41.15c0,.17.16.31.33.36-.17,0-.4-.14-.39-.37s.31-.31.5-.26a1.71,1.71,0,0,1,.89.74.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,43.86a1.58,1.58,0,0,0-.69-.77.3.3,0,0,0-.41.14c0,.17.15.32.32.37-.17,0-.39-.15-.38-.38s.32-.3.51-.25a1.68,1.68,0,0,1,.87.77.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,43.7a1.57,1.57,0,0,0-.66-.79c-.15-.07-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.16-.36-.39s.33-.29.51-.23a1.64,1.64,0,0,1,.84.8.12.12,0,0,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.11,42.38a1.57,1.57,0,0,0-.67-.78c-.15-.07-.36-.05-.41.12s.13.32.3.38c-.17,0-.39-.16-.37-.39a.4.4,0,0,1,.52-.23,1.74,1.74,0,0,1,.85.78.13.13,0,0,1-.22.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.21,40.54a1.49,1.49,0,0,0-.69-.77c-.14-.06-.36,0-.4.14s.14.31.31.36c-.17,0-.39-.15-.38-.38s.32-.3.51-.24a1.68,1.68,0,0,1,.87.77.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.08,41.26a1.49,1.49,0,0,0-.62-.82.29.29,0,0,0-.42.1c-.05.17.12.33.28.39-.16,0-.38-.18-.34-.41s.34-.27.52-.2a1.72,1.72,0,0,1,.81.83.13.13,0,0,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.39,39.51a1.53,1.53,0,0,0-.64-.81.3.3,0,0,0-.42.12c0,.16.12.32.29.38-.16,0-.38-.17-.35-.4s.33-.28.52-.22a1.72,1.72,0,0,1,.82.82.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.09,40.07a1.61,1.61,0,0,0-.57-.87.3.3,0,0,0-.43.08c-.06.16.1.33.26.41-.16-.06-.37-.21-.31-.43s.35-.25.53-.17a1.68,1.68,0,0,1,.75.89.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.77,39.56a1.51,1.51,0,0,0-.59-.84.3.3,0,0,0-.43.09c-.05.16.11.33.27.4-.16-.05-.37-.19-.33-.42a.39.39,0,0,1,.53-.19,1.71,1.71,0,0,1,.78.86.13.13,0,1,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.81,32.93a1.56,1.56,0,0,0-.53-.88.29.29,0,0,0-.43.06c-.07.16.08.34.24.42-.16-.07-.36-.22-.3-.44a.38.38,0,0,1,.54-.15,1.7,1.7,0,0,1,.72.91A.12.12,0,0,1,27,33a.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.51,35.66a1.48,1.48,0,0,0-.66-.79c-.14-.07-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.17-.36-.4a.38.38,0,0,1,.51-.22,1.6,1.6,0,0,1,.84.8.12.12,0,0,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.73,32.27a1.49,1.49,0,0,0-.49-.91.3.3,0,0,0-.43,0c-.08.16.07.34.22.43a.42.42,0,0,1-.28-.46c.09-.21.38-.21.55-.12a1.69,1.69,0,0,1,.67,1,.12.12,0,0,1-.07.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.14,35.85a1.51,1.51,0,0,0-.65-.81c-.14-.07-.36,0-.41.12s.12.32.29.38c-.17,0-.38-.17-.35-.4s.33-.28.51-.22a1.68,1.68,0,0,1,.83.81.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.28,35.13a1.57,1.57,0,0,0-.61-.83c-.15-.07-.36-.06-.42.1s.11.33.28.4c-.17,0-.38-.19-.34-.41s.34-.27.52-.2a1.68,1.68,0,0,1,.8.84.13.13,0,0,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.74,33.89a1.52,1.52,0,0,0-.43-.94.3.3,0,0,0-.43,0c-.09.15,0,.35.19.45-.15-.09-.33-.26-.24-.48s.39-.19.55-.08a1.67,1.67,0,0,1,.61,1,.12.12,0,0,1-.09.15.12.12,0,0,1-.15-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.6,36.32a1.5,1.5,0,0,0-.46-.92c-.13-.1-.34-.13-.43,0s.05.34.21.44c-.16-.08-.34-.25-.26-.47s.38-.2.55-.1a1.71,1.71,0,0,1,.63,1,.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.48,37.27a1.59,1.59,0,0,0-.66-.8c-.14-.06-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.16-.36-.39s.33-.29.51-.23a1.72,1.72,0,0,1,.84.8.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,34.3a1.59,1.59,0,0,0-.61-.84c-.14-.07-.36-.06-.42.1s.11.33.28.39c-.17-.05-.38-.18-.34-.41a.38.38,0,0,1,.53-.19,1.74,1.74,0,0,1,.79.84.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,32.9a1.5,1.5,0,0,0-.43-.94.3.3,0,0,0-.43,0c-.09.15,0,.35.19.45-.15-.09-.33-.26-.24-.48s.39-.19.55-.08a1.67,1.67,0,0,1,.61,1,.12.12,0,0,1-.09.15.12.12,0,0,1-.15-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.44,34.59a1.54,1.54,0,0,0-.55-.88.3.3,0,0,0-.43.07c-.06.17.09.34.25.42-.16-.06-.36-.21-.31-.44a.4.4,0,0,1,.54-.16,1.68,1.68,0,0,1,.74.9.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.73,38.67a1.53,1.53,0,0,0-.61-.83c-.14-.08-.36-.07-.42.09s.11.33.28.4c-.17,0-.38-.19-.34-.41s.35-.27.53-.2a1.76,1.76,0,0,1,.79.85.13.13,0,0,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.1,38.7a1.52,1.52,0,0,0-.52-.89c-.13-.09-.35-.11-.43,0s.08.34.24.42c-.16-.07-.36-.22-.29-.44s.37-.23.54-.14a1.72,1.72,0,0,1,.7.92.13.13,0,1,1-.24.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.46,34a1.52,1.52,0,0,0-.57-.86.29.29,0,0,0-.42.08c-.06.16.09.33.26.4a.39.39,0,0,1-.32-.43A.38.38,0,0,1,26,33a1.75,1.75,0,0,1,.75.88.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.62,33.07a1.55,1.55,0,0,0-.58-.86.29.29,0,0,0-.42.08c-.06.16.1.33.26.4-.16,0-.37-.2-.32-.43s.36-.25.54-.17a1.72,1.72,0,0,1,.75.89.12.12,0,0,1-.06.16.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.84,33.6a1.57,1.57,0,0,0-.52-.89c-.14-.09-.35-.11-.43,0s.08.34.23.42c-.16-.07-.35-.22-.29-.44a.37.37,0,0,1,.54-.14,1.71,1.71,0,0,1,.71.92.14.14,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.5,37.53a1.58,1.58,0,0,0-.48-.92c-.13-.09-.35-.11-.43,0s.06.34.22.43c-.16-.07-.35-.24-.28-.46s.38-.21.55-.11a1.68,1.68,0,0,1,.66,1,.14.14,0,0,1-.07.16.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.09,37.3a1.54,1.54,0,0,0-.59-.85.29.29,0,0,0-.42.08c-.06.16.1.33.26.4-.16,0-.37-.19-.32-.42a.38.38,0,0,1,.53-.18,1.72,1.72,0,0,1,.77.87.13.13,0,0,1-.23.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.75,36.61a1.56,1.56,0,0,0-.62-.82c-.15-.07-.36-.06-.42.11s.12.32.28.39c-.16,0-.38-.18-.34-.41s.34-.27.52-.2a1.72,1.72,0,0,1,.81.83.13.13,0,0,1-.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.05,38a1.55,1.55,0,0,0-.62-.83c-.14-.07-.36-.07-.42.1s.11.33.28.39c-.17,0-.38-.18-.34-.41s.35-.27.53-.2a1.74,1.74,0,0,1,.79.84.12.12,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.12,36a1.54,1.54,0,0,0-.56-.87.3.3,0,0,0-.43.07c-.06.16.1.34.26.41-.17-.06-.37-.2-.32-.43a.39.39,0,0,1,.54-.16,1.66,1.66,0,0,1,.74.89.12.12,0,0,1-.06.16.11.11,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.29,37.45a1.47,1.47,0,0,0-.52-.89.3.3,0,0,0-.43,0c-.07.16.08.34.24.42-.16-.06-.36-.22-.3-.44a.4.4,0,0,1,.55-.14,1.64,1.64,0,0,1,.7.92.11.11,0,0,1-.07.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.67,34.55a1.58,1.58,0,0,0-.64-.81.3.3,0,0,0-.42.11c-.05.17.12.33.29.39-.17-.05-.38-.17-.35-.4s.34-.28.52-.22a1.72,1.72,0,0,1,.82.82.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.24,35.82a1.57,1.57,0,0,0-.53-.89c-.13-.08-.34-.1-.42.06s.08.34.24.42c-.17-.07-.36-.22-.3-.45s.37-.23.54-.14a1.74,1.74,0,0,1,.71.92.13.13,0,0,1-.07.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.14,33.73a1.53,1.53,0,0,0-.48-.91c-.13-.1-.35-.12-.43,0s.06.34.22.43c-.16-.07-.35-.23-.28-.45a.38.38,0,0,1,.55-.12,1.68,1.68,0,0,1,.66.95.12.12,0,0,1-.07.16.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.64,35.11a1.56,1.56,0,0,0-.59-.86c-.14-.07-.35-.07-.42.09s.1.33.27.4a.4.4,0,0,1-.33-.42.39.39,0,0,1,.54-.18,1.7,1.7,0,0,1,.76.87.12.12,0,0,1-.06.17.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.43,36.21a1.55,1.55,0,0,0-.5-.91.3.3,0,0,0-.43,0c-.07.16.07.34.23.43-.16-.07-.35-.23-.29-.45s.38-.22.55-.13a1.76,1.76,0,0,1,.68,1,.13.13,0,0,1-.24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.64,34.68a1.5,1.5,0,0,0-.52-.89c-.13-.08-.35-.1-.43.06s.08.33.24.42c-.16-.07-.36-.22-.29-.45s.37-.23.54-.14a1.67,1.67,0,0,1,.7.93.12.12,0,0,1-.07.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.3,35.07a1.51,1.51,0,0,0-.44-.93c-.12-.1-.34-.13-.43,0s.05.35.2.45c-.16-.09-.34-.26-.25-.48s.39-.19.55-.08a1.62,1.62,0,0,1,.61,1,.12.12,0,0,1-.08.15.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.29,33.51a1.53,1.53,0,0,0-.61-.83c-.15-.07-.36-.07-.42.1s.11.33.27.39c-.16-.05-.37-.18-.33-.41a.38.38,0,0,1,.52-.2,1.74,1.74,0,0,1,.8.85.13.13,0,0,1-.23.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.9,38.12a1.57,1.57,0,0,0-.57-.86.29.29,0,0,0-.42.08c-.07.16.09.33.26.41-.17-.06-.37-.2-.32-.43s.35-.25.53-.17a1.72,1.72,0,0,1,.76.88.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.87,32.42a1.62,1.62,0,0,0-.46-.93c-.13-.09-.34-.12-.43,0s.05.34.2.43c-.15-.07-.34-.24-.26-.46a.37.37,0,0,1,.55-.1,1.7,1.7,0,0,1,.64,1,.13.13,0,0,1-.08.16.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.13,36.92a1.59,1.59,0,0,0-.55-.88.3.3,0,0,0-.43.07c-.06.16.09.34.25.42-.16-.07-.36-.22-.3-.44s.36-.24.53-.16a1.69,1.69,0,0,1,.73.9.13.13,0,0,1-.06.17.11.11,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.59,38.1a1.56,1.56,0,0,0-.65-.81.3.3,0,0,0-.42.12c0,.16.13.32.3.38-.17,0-.39-.17-.36-.4s.34-.28.52-.22a1.72,1.72,0,0,1,.83.81.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,38.66a1.6,1.6,0,0,0-.55-.87.3.3,0,0,0-.43.07c-.06.16.09.33.25.41-.16-.06-.36-.21-.31-.43a.38.38,0,0,1,.54-.16,1.62,1.62,0,0,1,.73.9.12.12,0,0,1-.22.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.26,36.51a1.52,1.52,0,0,0-.6-.85.29.29,0,0,0-.42.09c-.06.16.11.33.27.4-.16,0-.37-.19-.33-.42a.38.38,0,0,1,.53-.18,1.64,1.64,0,0,1,.78.86.13.13,0,0,1-.23.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,34.8a1.58,1.58,0,0,0-.48-.92c-.13-.09-.35-.12-.43,0s.06.34.21.43c-.15-.08-.34-.24-.27-.46s.38-.21.55-.11a1.68,1.68,0,0,1,.66.95.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.4,37.19a1.58,1.58,0,0,0-.69-.77.3.3,0,0,0-.41.14c0,.17.14.32.32.37-.18,0-.4-.15-.38-.38s.32-.3.5-.25a1.68,1.68,0,0,1,.87.77.12.12,0,0,1-.2.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.3,39.4a1.48,1.48,0,0,0-.72-.74.29.29,0,0,0-.4.15c0,.17.16.31.33.35-.17,0-.4-.13-.39-.36s.31-.31.49-.26a1.64,1.64,0,0,1,.9.73.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.24,41a1.48,1.48,0,0,0-.73-.73.29.29,0,0,0-.4.15c0,.17.16.31.33.35-.17,0-.4-.13-.39-.36s.3-.31.49-.27a1.69,1.69,0,0,1,.91.73.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.27,42.36a1.56,1.56,0,0,0-.75-.71.3.3,0,0,0-.4.17c0,.17.17.3.35.34-.18,0-.41-.12-.41-.35s.29-.32.49-.28a1.68,1.68,0,0,1,.92.69.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.81,44.7a1.61,1.61,0,0,0-.75-.71c-.16,0-.37,0-.4.17s.17.31.35.34c-.18,0-.41-.11-.41-.34a.38.38,0,0,1,.48-.29,1.67,1.67,0,0,1,.93.69.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.17,46.61a1.56,1.56,0,0,0-.75-.71c-.16,0-.37,0-.4.17s.17.31.35.34c-.17,0-.41-.11-.41-.34a.38.38,0,0,1,.48-.29,1.68,1.68,0,0,1,.94.69.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.33,48.61a1.58,1.58,0,0,0-.76-.71c-.15,0-.36,0-.39.17s.17.3.34.34c-.17,0-.4-.11-.4-.35s.29-.32.48-.28a1.69,1.69,0,0,1,.93.68.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.15,50.83a1.54,1.54,0,0,0-.74-.71c-.16,0-.37,0-.4.17s.17.3.34.34c-.17,0-.4-.12-.4-.35s.29-.32.48-.28a1.63,1.63,0,0,1,.93.69.13.13,0,0,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.12,53a1.54,1.54,0,0,0-.73-.72c-.16,0-.37,0-.4.16s.16.31.34.35c-.18,0-.41-.13-.4-.36s.29-.32.48-.27a1.66,1.66,0,0,1,.92.7.13.13,0,0,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.37,55.45a1.64,1.64,0,0,0-.72-.75c-.15-.05-.36,0-.4.16s.15.31.33.35c-.18,0-.4-.13-.4-.36a.39.39,0,0,1,.5-.27,1.76,1.76,0,0,1,.9.73.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.12,58.66a1.49,1.49,0,0,0-.69-.77c-.15-.06-.36,0-.41.13s.14.32.32.37c-.18,0-.4-.15-.38-.38s.32-.3.51-.24a1.79,1.79,0,0,1,.87.76.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.75,65a1.53,1.53,0,0,0-.6-.84c-.14-.07-.36-.06-.42.1s.11.33.27.4c-.16,0-.37-.19-.33-.42s.35-.26.53-.19a1.66,1.66,0,0,1,.78.85.12.12,0,0,1-.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,67.28a1.54,1.54,0,0,0-.56-.87c-.14-.08-.36-.08-.43.08s.1.33.26.41c-.17-.06-.37-.21-.32-.43s.36-.25.54-.17a1.72,1.72,0,0,1,.75.89.13.13,0,0,1-.23.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.67,67.67a1.54,1.54,0,0,0-.56-.87c-.14-.08-.36-.09-.43.07s.09.33.26.41c-.17-.06-.37-.21-.32-.43a.39.39,0,0,1,.54-.16,1.66,1.66,0,0,1,.74.89.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.76,69.11a1.52,1.52,0,0,0-.54-.88.29.29,0,0,0-.42,0c-.07.17.08.34.24.43-.16-.07-.36-.22-.3-.45s.37-.23.54-.14A1.68,1.68,0,0,1,15,69a.13.13,0,0,1-.06.17.14.14,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.58,70.51a1.55,1.55,0,0,0-.52-.89c-.14-.09-.35-.1-.43,0s.08.34.24.43c-.17-.07-.36-.23-.3-.45s.37-.23.55-.14a1.78,1.78,0,0,1,.7.92.14.14,0,0,1-.07.17.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.82,71.44a1.47,1.47,0,0,0-.52-.89c-.13-.09-.34-.11-.42,0s.07.34.23.42c-.16-.07-.36-.22-.29-.45s.37-.22.54-.13a1.67,1.67,0,0,1,.7.93.14.14,0,0,1-.07.16.14.14,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.81,70.72a1.55,1.55,0,0,0-.5-.9c-.13-.09-.35-.11-.43,0s.07.34.23.43c-.16-.07-.35-.23-.29-.45s.38-.22.55-.13a1.77,1.77,0,0,1,.68.94.13.13,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.94,71.45a1.55,1.55,0,0,0-.52-.89.29.29,0,0,0-.43.06c-.07.16.08.33.24.42-.16-.07-.36-.22-.3-.45s.37-.23.54-.14a1.74,1.74,0,0,1,.71.92.12.12,0,0,1-.07.17.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.24,70.44a1.5,1.5,0,0,0-.5-.9c-.14-.09-.35-.11-.43,0s.07.34.23.42c-.16-.07-.36-.23-.29-.45s.38-.22.55-.13a1.65,1.65,0,0,1,.68.94.12.12,0,0,1-.07.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.82,69.47a1.55,1.55,0,0,0-.52-.9c-.14-.08-.35-.1-.43.06s.08.34.24.42c-.16-.07-.36-.22-.3-.45a.39.39,0,0,1,.55-.14,1.8,1.8,0,0,1,.7.93.14.14,0,0,1-.07.16.11.11,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.4,69a1.58,1.58,0,0,0-.54-.89c-.13-.08-.35-.09-.43.06s.09.34.25.42c-.17-.06-.36-.22-.3-.44s.37-.23.54-.15a1.74,1.74,0,0,1,.71.92.12.12,0,0,1-.06.16A.13.13,0,0,1,16.4,69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.64,67.64a1.53,1.53,0,0,0-.56-.87c-.13-.08-.35-.09-.42.07s.09.34.25.42c-.16-.07-.36-.21-.31-.44s.36-.24.54-.16a1.74,1.74,0,0,1,.74.89.12.12,0,0,1-.07.17.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.39,65a1.47,1.47,0,0,0-.6-.83c-.14-.08-.36-.07-.42.09s.11.33.27.4c-.16-.05-.37-.19-.33-.42s.34-.26.53-.19a1.7,1.7,0,0,1,.78.85.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.65,62.6a1.53,1.53,0,0,0-.64-.81.3.3,0,0,0-.42.11c0,.17.13.33.3.39-.17,0-.39-.17-.36-.4s.34-.28.52-.22a1.78,1.78,0,0,1,.83.82.13.13,0,0,1-.05.17.14.14,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.59,63.71a1.54,1.54,0,0,0-.63-.82c-.14-.07-.36-.06-.42.1s.12.33.29.39c-.17,0-.38-.18-.35-.4a.39.39,0,0,1,.52-.21,1.68,1.68,0,0,1,.81.83.12.12,0,0,1-.05.17.11.11,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.82,63.37a1.49,1.49,0,0,0-.63-.82c-.14-.07-.36,0-.41.11s.11.33.28.39c-.17,0-.38-.18-.35-.41s.34-.27.53-.2a1.66,1.66,0,0,1,.81.82.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.39,62.57a1.53,1.53,0,0,0-.64-.81.3.3,0,0,0-.42.11c-.05.17.12.33.29.39-.16,0-.38-.17-.35-.4s.34-.28.52-.22a1.72,1.72,0,0,1,.82.82.12.12,0,0,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.16,62.3a1.56,1.56,0,0,0-.64-.82.3.3,0,0,0-.42.11c-.05.17.12.33.29.39-.17,0-.38-.17-.35-.4s.34-.28.52-.21a1.68,1.68,0,0,1,.82.82.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.36,62.11a1.59,1.59,0,0,0-.63-.82.3.3,0,0,0-.42.11c0,.17.12.33.29.39-.17,0-.38-.18-.35-.41s.34-.27.52-.21a1.74,1.74,0,0,1,.82.83.13.13,0,0,1-.05.17.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.09,59.78a1.59,1.59,0,0,0-.67-.79c-.15-.06-.36,0-.41.13s.13.32.3.37c-.17,0-.39-.15-.37-.38a.4.4,0,0,1,.52-.24,1.76,1.76,0,0,1,.85.79.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.33,60.76a1.55,1.55,0,0,0-.66-.8c-.15-.06-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.16-.37-.39a.4.4,0,0,1,.52-.23,1.76,1.76,0,0,1,.84.8.12.12,0,0,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.6,60.89a1.57,1.57,0,0,0-.65-.8.3.3,0,0,0-.42.12c0,.17.13.32.3.38-.17,0-.38-.17-.36-.4s.33-.28.52-.22a1.68,1.68,0,0,1,.83.81.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.21,60.22a1.47,1.47,0,0,0-.66-.8c-.14-.07-.36-.05-.41.11s.13.33.3.38c-.17,0-.39-.16-.36-.39a.38.38,0,0,1,.52-.22,1.7,1.7,0,0,1,.83.8.12.12,0,0,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.85,58.39a1.54,1.54,0,0,0-.68-.78c-.14-.06-.36,0-.41.13s.14.32.31.37c-.17,0-.39-.15-.37-.39s.33-.29.51-.23a1.72,1.72,0,0,1,.86.78.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.92,59.78a1.51,1.51,0,0,0-.64-.8.3.3,0,0,0-.42.11c-.05.17.13.33.3.39-.17,0-.39-.17-.36-.4s.33-.28.52-.22a1.76,1.76,0,0,1,.83.81.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.1,58.68a1.54,1.54,0,0,0-.67-.79c-.14-.06-.36,0-.41.12s.13.33.3.38c-.17,0-.39-.16-.36-.39s.33-.29.51-.23a1.7,1.7,0,0,1,.85.8.13.13,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.51,58.53a1.53,1.53,0,0,0-.66-.79c-.14-.07-.36-.05-.41.12s.13.32.3.38c-.17-.05-.39-.17-.36-.4s.33-.28.51-.22a1.64,1.64,0,0,1,.84.8.12.12,0,1,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.64,56.85a1.49,1.49,0,0,0-.69-.77c-.15-.06-.36,0-.41.13s.14.32.31.37c-.17,0-.39-.15-.37-.38s.32-.3.5-.24a1.71,1.71,0,0,1,.87.76.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,57.36a1.52,1.52,0,0,0-.67-.78c-.15-.07-.37,0-.42.13s.14.32.31.37c-.17,0-.39-.15-.37-.39s.33-.29.51-.23a1.64,1.64,0,0,1,.86.78.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.65,55.75A1.57,1.57,0,0,0,20,55c-.14-.06-.36,0-.4.13s.14.32.31.37c-.17,0-.39-.15-.37-.38s.31-.3.5-.24a1.67,1.67,0,0,1,.87.76.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,57.1a1.53,1.53,0,0,0-.66-.79c-.14-.07-.36-.05-.41.11s.13.33.3.38c-.17,0-.39-.16-.36-.39s.33-.29.51-.22a1.64,1.64,0,0,1,.84.8.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,56a1.59,1.59,0,0,0-.68-.78c-.15-.06-.37,0-.41.14s.14.31.31.37c-.17,0-.39-.15-.38-.39s.33-.29.51-.23a1.63,1.63,0,0,1,.86.77.12.12,0,0,1,0,.17A.12.12,0,0,1,22,56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.85,56.82a1.5,1.5,0,0,0-.67-.79c-.14-.06-.36,0-.41.13s.14.32.31.37c-.17,0-.39-.16-.37-.39s.33-.29.51-.23a1.68,1.68,0,0,1,.85.79.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.7,55.57a1.52,1.52,0,0,0-.67-.78c-.15-.07-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.16-.37-.39a.4.4,0,0,1,.52-.23,1.74,1.74,0,0,1,.85.78.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.33,54.29a1.56,1.56,0,0,0-.69-.76c-.15-.06-.37,0-.41.13s.14.32.31.37c-.17,0-.39-.15-.37-.38s.31-.3.5-.24a1.62,1.62,0,0,1,.87.76.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.12,54.05a1.52,1.52,0,0,0-.67-.78c-.15-.06-.37,0-.41.13s.13.32.31.37c-.18,0-.4-.15-.38-.38s.33-.29.51-.24a1.68,1.68,0,0,1,.86.78.13.13,0,0,1-.21.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.16,53a1.53,1.53,0,0,0-.7-.76c-.15-.06-.36,0-.4.14s.14.32.32.37c-.18,0-.4-.15-.39-.38s.32-.3.51-.25a1.71,1.71,0,0,1,.88.75.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.76,51.93a1.61,1.61,0,0,0-.68-.77c-.15-.06-.37,0-.41.13s.14.32.31.37c-.17,0-.39-.15-.37-.38s.32-.3.5-.24a1.68,1.68,0,0,1,.87.77.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.39,50.41a1.64,1.64,0,0,0-.7-.77c-.15,0-.36,0-.4.14s.14.32.31.37c-.17,0-.39-.15-.38-.38s.32-.3.51-.25a1.68,1.68,0,0,1,.87.77.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.86,49.83a1.54,1.54,0,0,0-.68-.78c-.15-.06-.36,0-.41.13s.14.32.31.37c-.17,0-.39-.15-.37-.38s.32-.29.51-.24a1.72,1.72,0,0,1,.86.78.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,48.26a1.56,1.56,0,0,0-.69-.76c-.15-.06-.37,0-.41.14s.14.31.31.36c-.17,0-.39-.15-.37-.38s.31-.3.5-.24a1.67,1.67,0,0,1,.87.76.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.85,46.93a1.49,1.49,0,0,0-.68-.78.3.3,0,0,0-.41.14c0,.17.14.32.31.37-.17,0-.39-.15-.37-.38s.32-.3.51-.24a1.66,1.66,0,0,1,.86.77A.13.13,0,0,1,26,47a.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.58,45.45a1.56,1.56,0,0,0-.68-.77.3.3,0,0,0-.41.14c0,.17.14.31.31.36-.17,0-.39-.15-.37-.38s.32-.29.5-.24a1.68,1.68,0,0,1,.87.77.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.44,42.3a1.47,1.47,0,0,0-.7-.75.3.3,0,0,0-.41.14c0,.17.15.31.33.36-.18,0-.4-.14-.39-.37s.31-.31.5-.25a1.71,1.71,0,0,1,.89.74.13.13,0,0,1-.21.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.09,41a1.54,1.54,0,0,0-.68-.78.29.29,0,0,0-.41.13c0,.17.14.31.31.37-.17,0-.39-.16-.37-.39s.32-.29.51-.23a1.72,1.72,0,0,1,.86.78.13.13,0,0,1-.22.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.09,38.36a1.58,1.58,0,0,0-.69-.77.3.3,0,0,0-.41.14c0,.17.14.32.31.37-.17,0-.39-.15-.37-.38a.38.38,0,0,1,.5-.24,1.62,1.62,0,0,1,.87.76.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.49,31.35a1.56,1.56,0,0,0-.53-.88c-.14-.08-.35-.09-.43.06s.08.34.24.42c-.16-.06-.36-.22-.3-.44a.38.38,0,0,1,.54-.15,1.7,1.7,0,0,1,.72.91.12.12,0,0,1-.07.17.14.14,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.83,29.36a1.47,1.47,0,0,0-.4-.95.29.29,0,0,0-.43,0c-.09.15,0,.35.18.45-.15-.09-.32-.27-.23-.48s.4-.18.56-.07a1.78,1.78,0,0,1,.57,1,.13.13,0,1,1-.24.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.53,27.93a1.62,1.62,0,0,0-.36-1c-.12-.11-.33-.16-.43,0s0,.35.16.46c-.15-.1-.32-.29-.21-.49a.38.38,0,0,1,.56-.05,1.73,1.73,0,0,1,.52,1,.12.12,0,0,1-.24.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,27.43a1.53,1.53,0,0,0-.27-1c-.11-.12-.31-.19-.43-.06s0,.34.12.47c-.14-.11-.29-.31-.17-.51a.39.39,0,0,1,.56,0,1.7,1.7,0,0,1,.44,1.08.12.12,0,0,1-.11.14.13.13,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.64,26.1a1.57,1.57,0,0,0-.23-1c-.1-.12-.3-.2-.42-.08s0,.35.09.48a.4.4,0,0,1-.14-.52c.14-.18.42-.1.56,0a1.71,1.71,0,0,1,.39,1.09.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.76,25a1.53,1.53,0,0,0-.22-1c-.1-.12-.3-.2-.42-.08s0,.35.09.48c-.13-.12-.27-.33-.14-.52a.39.39,0,0,1,.56,0A1.68,1.68,0,0,1,28,25a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.28,23.73a1.56,1.56,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.62,24.39a1.52,1.52,0,0,0-.13-1c-.08-.13-.28-.23-.41-.12s-.06.34.05.48A.39.39,0,0,1,29,23.2a.37.37,0,0,1,.55.09,1.66,1.66,0,0,1,.28,1.12.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.31,22.7a1.64,1.64,0,0,0-.1-1c-.09-.13-.28-.23-.41-.13s-.08.34,0,.49c-.11-.13-.23-.36-.08-.53a.39.39,0,0,1,.56.1,1.74,1.74,0,0,1,.25,1.13.14.14,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,23.21a1.57,1.57,0,0,0-.08-1,.3.3,0,0,0-.41-.14c-.13.11-.08.34,0,.49a.4.4,0,0,1-.07-.53c.17-.17.43-.05.55.11a1.74,1.74,0,0,1,.23,1.13c0,.07-.07.13-.13.12a.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.67,22.05a1.54,1.54,0,0,0-.08-1c-.08-.14-.27-.25-.41-.14s-.08.34,0,.48a.4.4,0,0,1-.07-.53.38.38,0,0,1,.55.11,1.76,1.76,0,0,1,.23,1.14.12.12,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.57,20.76a1.56,1.56,0,0,0-.09-1c-.09-.14-.27-.24-.41-.13s-.07.33,0,.48a.41.41,0,0,1-.07-.53c.16-.17.43-.05.55.1a1.71,1.71,0,0,1,.24,1.14.12.12,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.3,19.72a1.54,1.54,0,0,0-.11-1c-.09-.14-.28-.24-.41-.13s-.07.34,0,.48a.41.41,0,0,1-.09-.53.4.4,0,0,1,.56.1,1.74,1.74,0,0,1,.26,1.13.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.32,19a1.48,1.48,0,0,0-.13-1,.29.29,0,0,0-.41-.13c-.13.12-.06.35,0,.49-.12-.13-.24-.35-.09-.53s.43-.06.55.09A1.76,1.76,0,0,1,32.57,19a.13.13,0,0,1-.13.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.55,17.54a1.54,1.54,0,0,0-.13-1c-.09-.13-.28-.23-.42-.12s-.06.34,0,.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.08,1.77,1.77,0,0,1,.29,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.07,16.12a1.57,1.57,0,0,0-.14-1,.3.3,0,0,0-.42-.11c-.12.12,0,.34.06.48a.41.41,0,0,1-.1-.53c.16-.17.43-.07.56.08a1.77,1.77,0,0,1,.29,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.17,16.41a1.48,1.48,0,0,0-.14-1c-.09-.13-.28-.23-.41-.11s-.06.34.05.48c-.12-.13-.24-.35-.1-.53s.43-.07.56.08a1.72,1.72,0,0,1,.29,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.51,14.55a1.56,1.56,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.34.34,0,1,1,.45-.45,1.73,1.73,0,0,1,.3,1.12.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.74,12.91a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34,0,.48a.41.41,0,0,1-.1-.53c.16-.17.43-.07.56.08A1.67,1.67,0,0,1,38,12.93a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.79,12.09a1.48,1.48,0,0,0-.14-1c-.09-.14-.28-.23-.41-.12s-.06.34,0,.48a.4.4,0,0,1-.1-.52c.16-.18.43-.07.56.08A1.72,1.72,0,0,1,40,12.11a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.48,12a1.54,1.54,0,0,0-.13-1c-.09-.13-.28-.23-.42-.12s-.06.35.06.49a.39.39,0,0,1-.1-.53.38.38,0,0,1,.55.08,1.61,1.61,0,0,1,.29,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.57,12.14a1.6,1.6,0,0,0-.14-1c-.09-.14-.29-.23-.42-.12s-.06.34.06.48a.33.33,0,1,1,.45-.44,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.3,11.53a1.47,1.47,0,0,0-.14-1c-.09-.14-.28-.23-.41-.12s-.06.34.06.48a.38.38,0,0,1-.1-.52c.16-.18.43-.07.55.08a1.67,1.67,0,0,1,.29,1.12.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.5,13.31a1.53,1.53,0,0,0-.15-1c-.1-.14-.29-.23-.42-.11s-.06.34.06.48c-.12-.13-.25-.34-.11-.53a.39.39,0,0,1,.56.08,1.71,1.71,0,0,1,.31,1.11.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.31,17.17a1.48,1.48,0,0,0-.14-1c-.09-.13-.28-.23-.41-.11s-.06.34.05.48c-.12-.13-.24-.35-.1-.53a.39.39,0,0,1,.56.08,1.72,1.72,0,0,1,.29,1.12.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,19.31a1.48,1.48,0,0,0-.1-1,.3.3,0,0,0-.41-.14c-.13.12-.07.34,0,.49a.4.4,0,0,1-.08-.53c.16-.17.43-.06.55.1a1.68,1.68,0,0,1,.25,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.79,27.18a1.55,1.55,0,0,0,.18-1c0-.15-.2-.3-.36-.24s-.16.31-.1.48a.4.4,0,0,1,.07-.53c.2-.12.43.06.5.24A1.65,1.65,0,0,1,60,27.27a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,30.41a1.56,1.56,0,0,0,.37-1c0-.16-.14-.33-.31-.3s-.22.28-.18.45a.41.41,0,0,1,.16-.51c.22-.07.41.15.45.34a1.69,1.69,0,0,1-.28,1.12.11.11,0,0,1-.17.05.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.89,32.45a1.57,1.57,0,0,0,.45-.93c0-.16-.11-.35-.29-.33s-.23.26-.21.44a.4.4,0,0,1,.2-.5c.23-.05.4.18.42.38a1.69,1.69,0,0,1-.37,1.09.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,38.64a1.55,1.55,0,0,0,.52-.89c0-.16-.08-.35-.25-.35s-.26.24-.26.42a.4.4,0,0,1,.25-.48c.23,0,.38.21.39.41a1.75,1.75,0,0,1-.47,1.06.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.65,42.89a1.53,1.53,0,0,0,.51-.9c0-.16-.09-.35-.26-.34s-.25.24-.25.42c0-.18,0-.42.24-.48s.39.2.39.4a1.69,1.69,0,0,1-.44,1.07.14.14,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.18,46.05a1.62,1.62,0,0,0,.53-.89c0-.16-.08-.35-.25-.35s-.26.23-.26.41c0-.17,0-.42.25-.47s.38.21.38.41a1.69,1.69,0,0,1-.47,1.06.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.52,49.19a1.59,1.59,0,0,0,.51-.9c0-.16-.08-.35-.26-.34s-.25.23-.25.41c0-.17,0-.42.24-.48s.39.21.4.41a1.68,1.68,0,0,1-.46,1.06.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,52.15a1.51,1.51,0,0,0,.5-.91c0-.16-.09-.35-.27-.34s-.25.25-.24.42c0-.17,0-.42.23-.48s.39.2.4.4a1.67,1.67,0,0,1-.43,1.07.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.87,55.2a1.49,1.49,0,0,0,.47-.92c0-.16-.1-.35-.28-.33s-.24.25-.22.43a.39.39,0,0,1,.21-.49c.23,0,.4.19.41.38a1.65,1.65,0,0,1-.4,1.09.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.12,57.35a1.5,1.5,0,0,0,.46-.93c0-.16-.11-.34-.28-.32s-.24.25-.22.43a.38.38,0,0,1,.21-.49c.22-.06.39.18.41.37a1.73,1.73,0,0,1-.38,1.1.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.7,59.18a1.5,1.5,0,0,0,.44-.93c0-.16-.11-.35-.28-.32s-.24.25-.22.43c0-.17,0-.42.21-.49s.4.17.42.37a1.74,1.74,0,0,1-.37,1.1.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.58,61.44A1.53,1.53,0,0,0,74,60.5c0-.16-.11-.34-.29-.32s-.23.26-.2.44c0-.17,0-.42.19-.5s.4.17.43.36a1.73,1.73,0,0,1-.35,1.11.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.35,63.44a1.59,1.59,0,0,0,.46-.93c0-.16-.1-.34-.28-.33s-.24.25-.22.43c0-.17,0-.42.21-.49s.4.19.42.38a1.7,1.7,0,0,1-.4,1.09.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.21,62.58a1.46,1.46,0,0,0,.43-.94c0-.16-.12-.34-.29-.32s-.23.26-.21.44a.4.4,0,0,1,.2-.5c.22-.06.4.17.42.37a1.66,1.66,0,0,1-.34,1.1.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.77,62.47a1.6,1.6,0,0,0,.45-.93c0-.16-.11-.35-.29-.33s-.23.26-.22.44a.41.41,0,0,1,.21-.5c.23,0,.4.18.42.38A1.65,1.65,0,0,1,72,62.62a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.07,61.1a1.57,1.57,0,0,0,.43-.94c0-.16-.12-.34-.29-.32s-.23.26-.21.44a.4.4,0,0,1,.2-.5c.22-.06.4.17.42.37a1.67,1.67,0,0,1-.35,1.1.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.29,59.29a1.53,1.53,0,0,0,.44-.93c0-.16-.11-.35-.28-.32s-.24.25-.22.43a.4.4,0,0,1,.2-.5c.23,0,.4.18.42.38a1.63,1.63,0,0,1-.36,1.09.12.12,0,1,1-.21-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.47,58.05a1.58,1.58,0,0,0,.44-.93c0-.16-.11-.35-.28-.33s-.24.26-.22.43c0-.17,0-.42.2-.49s.4.18.42.37a1.67,1.67,0,0,1-.37,1.1.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.58,56.16a1.54,1.54,0,0,0,.45-.93c0-.16-.11-.35-.28-.33s-.24.25-.22.43c0-.17,0-.42.2-.49s.4.18.42.37a1.67,1.67,0,0,1-.37,1.1.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.47,54.63a1.52,1.52,0,0,0,.45-.93c0-.16-.11-.34-.29-.32s-.23.25-.21.43c0-.17,0-.42.2-.49s.4.17.42.37a1.68,1.68,0,0,1-.37,1.1.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.38,53.82a1.52,1.52,0,0,0,.45-.93c0-.16-.11-.34-.28-.32s-.24.25-.22.43a.42.42,0,0,1,.21-.5c.23,0,.4.19.42.38a1.76,1.76,0,0,1-.39,1.1.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.73,53.53a1.54,1.54,0,0,0,.45-.93c0-.16-.12-.35-.29-.32s-.24.25-.22.43a.42.42,0,0,1,.21-.5c.23-.05.4.18.42.37a1.7,1.7,0,0,1-.37,1.1.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.14,52.61a1.61,1.61,0,0,0,.46-.93c0-.16-.11-.35-.28-.33s-.24.25-.22.43a.39.39,0,0,1,.21-.49c.23,0,.39.18.41.38a1.7,1.7,0,0,1-.38,1.09.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.51,50.35a1.52,1.52,0,0,0,.47-.92c0-.16-.1-.35-.28-.33s-.24.24-.23.42c0-.17,0-.42.22-.48s.4.19.41.38a1.67,1.67,0,0,1-.4,1.09.13.13,0,1,1-.2-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.8,48.08a1.55,1.55,0,0,0,.47-.92c0-.16-.1-.35-.27-.33s-.25.24-.24.42c0-.17,0-.42.22-.48s.4.19.41.38a1.7,1.7,0,0,1-.4,1.09.14.14,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.3,47.65a1.6,1.6,0,0,0,.45-.93c0-.16-.11-.35-.28-.33s-.24.25-.22.43c0-.17,0-.42.2-.49s.4.18.42.38a1.65,1.65,0,0,1-.38,1.09.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.59,46.4a1.58,1.58,0,0,0,.44-.94c0-.16-.11-.34-.28-.32s-.24.25-.22.43c0-.17,0-.42.2-.49s.4.18.42.37a1.68,1.68,0,0,1-.37,1.1.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.37,45a1.61,1.61,0,0,0,.4-.95c0-.16-.13-.34-.3-.31s-.22.26-.2.44c0-.17,0-.42.19-.5s.4.16.43.35a1.67,1.67,0,0,1-.32,1.11.13.13,0,0,1-.17.05.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,42.75a1.51,1.51,0,0,0,.31-1c0-.16-.15-.33-.32-.29s-.2.29-.16.46a.39.39,0,0,1,.14-.51c.22-.09.42.12.46.31a1.69,1.69,0,0,1-.22,1.14.12.12,0,0,1-.16,0,.11.11,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.22,40.6a1.58,1.58,0,0,0,.25-1c0-.16-.18-.32-.35-.26s-.18.29-.12.46a.39.39,0,0,1,.1-.52c.21-.1.43.09.49.28a1.75,1.75,0,0,1-.15,1.15.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,39.36a1.55,1.55,0,0,0,.18-1c0-.15-.2-.3-.36-.23s-.16.31-.1.47a.39.39,0,0,1,.07-.53.38.38,0,0,1,.5.24,1.71,1.71,0,0,1,0,1.16.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.51,37.86a1.54,1.54,0,0,0,.11-1c-.06-.15-.22-.29-.38-.21s-.14.32-.06.48a.4.4,0,0,1,0-.53c.2-.13.43,0,.52.21a1.71,1.71,0,0,1,0,1.16.14.14,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,36.57a1.54,1.54,0,0,0,.1-1c-.06-.15-.22-.29-.38-.21s-.13.32,0,.48a.39.39,0,0,1,0-.53.38.38,0,0,1,.52.21,1.71,1.71,0,0,1,0,1.16.14.14,0,0,1-.15.09.14.14,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.14,35.34a1.54,1.54,0,0,0,.06-1c-.06-.15-.23-.28-.38-.2s-.13.33,0,.49a.41.41,0,0,1,0-.54.38.38,0,0,1,.53.19,1.69,1.69,0,0,1,.08,1.15.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.23,34a1.52,1.52,0,0,0,0-1c-.07-.15-.24-.27-.39-.18s-.11.33,0,.49c-.1-.15-.19-.38,0-.54a.4.4,0,0,1,.54.16,1.73,1.73,0,0,1,.12,1.16.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.18,32.43a1.52,1.52,0,0,0,0-1c-.07-.14-.25-.26-.4-.17s-.1.33,0,.49a.42.42,0,0,1,0-.54.38.38,0,0,1,.54.16,1.64,1.64,0,0,1,.14,1.15.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,30.78a1.54,1.54,0,0,0,0-1c-.08-.14-.26-.26-.4-.16s-.1.33,0,.48a.41.41,0,0,1,0-.53.38.38,0,0,1,.54.15,1.68,1.68,0,0,1,.15,1.14.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,28.71a1.58,1.58,0,0,0,0-1c-.07-.15-.25-.27-.4-.17s-.1.33,0,.48a.39.39,0,0,1,0-.53.38.38,0,0,1,.54.15,1.7,1.7,0,0,1,.15,1.15.13.13,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.19,27.2a1.45,1.45,0,0,0,0-1c-.07-.14-.26-.26-.4-.16s-.09.34,0,.49c-.11-.14-.21-.37,0-.53a.39.39,0,0,1,.55.13,1.74,1.74,0,0,1,.19,1.14.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.32,25.72a1.57,1.57,0,0,0-.08-1c-.09-.14-.27-.25-.41-.14s-.08.34,0,.48a.39.39,0,0,1-.07-.53c.17-.16.43,0,.55.11a1.76,1.76,0,0,1,.23,1.14.14.14,0,0,1-.14.12.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.39,24a1.57,1.57,0,0,0-.11-1c-.08-.13-.27-.24-.41-.13s-.07.34,0,.49a.4.4,0,0,1-.08-.53c.16-.17.43-.06.55.1A1.74,1.74,0,0,1,47.64,24a.14.14,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.78,24.88a1.57,1.57,0,0,0-.09-1c-.09-.14-.28-.24-.41-.13s-.08.34,0,.48a.4.4,0,0,1-.08-.53.4.4,0,0,1,.56.11A1.67,1.67,0,0,1,47,24.91a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.07,26.9a1.51,1.51,0,0,0-.06-1c-.08-.14-.27-.25-.41-.15s-.08.34,0,.48a.41.41,0,0,1-.06-.53.38.38,0,0,1,.55.12,1.69,1.69,0,0,1,.21,1.14.13.13,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.72,30.13a1.52,1.52,0,0,0,0-1c-.07-.14-.25-.26-.39-.17s-.1.34,0,.49a.41.41,0,0,1,0-.54c.18-.15.44,0,.54.15A1.72,1.72,0,0,1,47,30.18a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.91,32.68a1.54,1.54,0,0,0,0-1c-.07-.14-.25-.26-.4-.16s-.1.33,0,.48a.4.4,0,0,1,0-.53.38.38,0,0,1,.54.15,1.65,1.65,0,0,1,.15,1.15.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.81,34.67a1.54,1.54,0,0,0,0-1c-.07-.14-.25-.26-.4-.16s-.1.33,0,.48a.4.4,0,0,1,0-.53.38.38,0,0,1,.54.15,1.65,1.65,0,0,1,.15,1.15.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.71,37.69a1.52,1.52,0,0,0,0-1c-.07-.14-.25-.26-.4-.17s-.1.33,0,.49a.42.42,0,0,1,0-.54c.18-.15.44,0,.54.16A1.7,1.7,0,0,1,48,37.75a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.51,40.61a1.59,1.59,0,0,0,0-1c-.07-.14-.24-.27-.39-.18s-.12.32,0,.48a.4.4,0,0,1,0-.53c.19-.15.44,0,.54.17a1.72,1.72,0,0,1,.09,1.16.14.14,0,0,1-.15.1.14.14,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.76,44.21a1.49,1.49,0,0,0,.11-1c0-.15-.21-.29-.37-.21s-.14.31-.06.48c-.09-.15-.16-.4,0-.54s.43,0,.52.22a1.74,1.74,0,0,1,0,1.15.12.12,0,0,1-.15.09.11.11,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.06,45.7a1.55,1.55,0,0,0,.2-1c0-.15-.2-.3-.36-.24s-.17.31-.1.48c-.08-.16-.12-.41.07-.53s.43.07.5.25a1.65,1.65,0,0,1-.08,1.16.12.12,0,0,1-.23-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.59,47.19a1.52,1.52,0,0,0,.25-1c0-.16-.18-.32-.34-.26s-.18.29-.13.47a.41.41,0,0,1,.1-.53c.21-.1.43.1.49.28a1.74,1.74,0,0,1-.14,1.15.12.12,0,0,1-.17.07.14.14,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,49.58a1.55,1.55,0,0,0,.28-1c0-.16-.17-.32-.33-.27s-.19.29-.14.46a.39.39,0,0,1,.11-.52c.22-.09.43.11.48.3a1.66,1.66,0,0,1-.18,1.14.13.13,0,0,1-.16.07.14.14,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.73,51.87a1.55,1.55,0,0,0,.28-1c0-.15-.17-.32-.34-.27s-.19.29-.14.47c-.06-.16-.09-.41.12-.52s.42.1.48.29A1.66,1.66,0,0,1,53,52a.11.11,0,0,1-.16.07.14.14,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.59,53.07a1.55,1.55,0,0,0,.28-1c0-.16-.17-.32-.33-.27s-.19.29-.14.46a.39.39,0,0,1,.11-.52c.22-.09.43.11.48.3a1.66,1.66,0,0,1-.18,1.14.13.13,0,0,1-.16.07.13.13,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,51a1.59,1.59,0,0,0,.29-1c0-.16-.16-.33-.33-.28s-.19.29-.15.46a.4.4,0,0,1,.13-.52c.21-.09.42.11.47.3a1.7,1.7,0,0,1-.19,1.15.13.13,0,0,1-.17.06A.13.13,0,0,1,54,51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.67,48.28a1.58,1.58,0,0,0,.28-1c0-.16-.17-.33-.34-.28s-.19.29-.14.47c-.06-.17-.08-.42.12-.52s.42.1.48.29a1.76,1.76,0,0,1-.18,1.15.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.21,43.85a1.53,1.53,0,0,0,.15-1c0-.15-.21-.3-.36-.23s-.16.32-.08.48a.4.4,0,0,1,0-.53.39.39,0,0,1,.52.23,1.71,1.71,0,0,1,0,1.16.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.6,38.6a1.55,1.55,0,0,0,0-1c-.07-.14-.25-.26-.39-.17s-.12.33,0,.48a.39.39,0,0,1,0-.53c.18-.15.44,0,.54.16a1.7,1.7,0,0,1,.11,1.15.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.59,34.46a1.52,1.52,0,0,0,0-1c-.07-.15-.25-.26-.39-.17s-.1.33,0,.49c-.1-.14-.2-.38,0-.54s.44,0,.54.15a1.7,1.7,0,0,1,.15,1.15.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.71,31a1.54,1.54,0,0,0,0-1c-.08-.14-.25-.26-.4-.16s-.1.33,0,.48a.41.41,0,0,1,0-.53.38.38,0,0,1,.54.15A1.7,1.7,0,0,1,46,31a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.57,26.8a1.63,1.63,0,0,0-.07-1c-.08-.14-.27-.25-.41-.14s-.08.33,0,.48c-.11-.13-.22-.36-.06-.53s.43,0,.55.12a1.69,1.69,0,0,1,.21,1.14.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.62,22.19a1.52,1.52,0,0,0-.13-1,.29.29,0,0,0-.41-.12c-.13.12-.06.35.05.49-.12-.13-.24-.35-.09-.53a.37.37,0,0,1,.55.09,1.66,1.66,0,0,1,.28,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.6,19.73a1.56,1.56,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.73,1.73,0,0,1,.3,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.9,18.55a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.43,17.49a1.53,1.53,0,0,0-.15-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08a1.65,1.65,0,0,1,.3,1.11.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.62,16.64a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08a1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.09,18.71a1.54,1.54,0,0,0-.13-1c-.09-.13-.29-.23-.42-.12s-.06.35.05.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.08,1.72,1.72,0,0,1,.29,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.5,20a1.5,1.5,0,0,0-.13-1c-.09-.13-.28-.23-.41-.12s-.06.34,0,.48c-.12-.13-.24-.35-.09-.53s.43-.06.55.09A1.76,1.76,0,0,1,34.75,20a.12.12,0,0,1-.13.12A.12.12,0,0,1,34.5,20Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.43,21.69a1.57,1.57,0,0,0-.11-1c-.09-.13-.28-.23-.42-.12s-.06.34.05.48c-.12-.13-.24-.35-.09-.53a.37.37,0,0,1,.55.1,1.63,1.63,0,0,1,.26,1.13.13.13,0,0,1-.13.12.14.14,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.67,21a1.5,1.5,0,0,0-.13-1c-.08-.13-.28-.23-.41-.12s-.06.34,0,.48c-.12-.13-.24-.35-.09-.53s.43-.06.55.09A1.69,1.69,0,0,1,35.91,21a.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.4,21a1.5,1.5,0,0,0-.13-1c-.09-.13-.28-.23-.41-.12s-.06.34,0,.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.08A1.7,1.7,0,0,1,38.65,21a.12.12,0,0,1-.12.12A.13.13,0,0,1,38.4,21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.88,22.73a1.57,1.57,0,0,0-.12-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.41.41,0,0,1-.09-.53.39.39,0,0,1,.56.1,1.72,1.72,0,0,1,.27,1.12.14.14,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.87,21.64a1.48,1.48,0,0,0-.13-1c-.09-.14-.28-.23-.41-.12s-.06.34.05.48c-.12-.13-.24-.35-.1-.53a.39.39,0,0,1,.56.09,1.66,1.66,0,0,1,.28,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.9,23.58a1.57,1.57,0,0,0-.11-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.41.41,0,0,1-.09-.53.39.39,0,0,1,.56.1,1.74,1.74,0,0,1,.26,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.33,23.61a1.56,1.56,0,0,0-.11-1c-.09-.14-.28-.24-.42-.13s-.06.34.05.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.09,1.69,1.69,0,0,1,.27,1.13.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.38,25.29a1.57,1.57,0,0,0-.09-1,.3.3,0,0,0-.41-.14c-.13.12-.08.34,0,.49-.11-.13-.22-.36-.07-.53s.43,0,.55.1a1.71,1.71,0,0,1,.24,1.14.13.13,0,0,1-.13.12.13.13,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.4,26.71a1.57,1.57,0,0,0-.07-1c-.08-.14-.27-.25-.41-.14s-.08.34,0,.48a.41.41,0,0,1-.06-.53.39.39,0,0,1,.55.12,1.69,1.69,0,0,1,.21,1.14.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.54,28.65a1.52,1.52,0,0,0,0-1c-.07-.14-.25-.26-.4-.16s-.1.33,0,.48a.4.4,0,0,1,0-.53.39.39,0,0,1,.55.15,1.68,1.68,0,0,1,.15,1.14.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.67,26.12a1.57,1.57,0,0,0-.08-1,.3.3,0,0,0-.41-.14c-.13.11-.08.34,0,.48a.4.4,0,0,1-.07-.53c.17-.16.43,0,.55.12a1.72,1.72,0,0,1,.23,1.13.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.91,27.78a1.51,1.51,0,0,0,0-1c-.08-.14-.26-.26-.4-.16s-.09.34,0,.49c-.11-.14-.21-.37,0-.53a.38.38,0,0,1,.54.13,1.71,1.71,0,0,1,.18,1.15.13.13,0,0,1-.13.11.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,27.18a1.57,1.57,0,0,0-.06-1c-.08-.14-.27-.25-.41-.15s-.08.34,0,.48c-.11-.13-.22-.36-.06-.53a.39.39,0,0,1,.55.13,1.63,1.63,0,0,1,.2,1.14.11.11,0,0,1-.13.11A.12.12,0,0,1,43,27.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.08,23.72a1.48,1.48,0,0,0-.12-1,.29.29,0,0,0-.41-.13c-.13.12-.06.34.05.48-.12-.13-.24-.35-.09-.53a.39.39,0,0,1,.55.1,1.69,1.69,0,0,1,.27,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,24.88a1.64,1.64,0,0,0-.1-1c-.09-.14-.28-.24-.41-.13s-.08.34,0,.48c-.11-.13-.23-.35-.08-.53a.4.4,0,0,1,.56.1,1.71,1.71,0,0,1,.24,1.14.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.25,25.09a1.51,1.51,0,0,0-.1-1c-.08-.13-.27-.24-.41-.13s-.07.34,0,.48a.4.4,0,0,1-.08-.53.38.38,0,0,1,.55.11,1.74,1.74,0,0,1,.25,1.13.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.28,19a1.54,1.54,0,0,0-.14-1c-.09-.14-.28-.23-.41-.12s-.06.34.05.48a.4.4,0,0,1-.1-.52c.16-.17.43-.07.56.08A1.72,1.72,0,0,1,52.53,19a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.37,13.69a1.51,1.51,0,0,0-.1-1c-.09-.13-.27-.23-.41-.13s-.07.34,0,.49c-.12-.13-.23-.36-.08-.53s.43-.06.55.1a1.68,1.68,0,0,1,.25,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.29,13.31a1.55,1.55,0,0,0,0-1c-.06-.14-.24-.27-.39-.18s-.11.33,0,.49a.4.4,0,0,1,0-.54c.18-.14.43,0,.53.17a1.7,1.7,0,0,1,.11,1.15.13.13,0,0,1-.15.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.36,14.46a1.53,1.53,0,0,0,.14-1c0-.16-.21-.3-.37-.23a.37.37,0,0,0-.07.48.39.39,0,0,1,0-.53c.2-.12.43.05.51.23a1.68,1.68,0,0,1,0,1.15.13.13,0,0,1-.16.09.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.45,17.78a1.54,1.54,0,0,0,.43-.94c0-.16-.12-.35-.29-.32s-.23.26-.21.44c0-.17,0-.42.19-.5s.41.17.43.36a1.74,1.74,0,0,1-.34,1.11.13.13,0,0,1-.22-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.24,19a1.54,1.54,0,0,0,.52-.9c0-.16-.09-.35-.26-.34s-.26.23-.25.41c0-.17,0-.42.24-.48s.38.21.39.41a1.7,1.7,0,0,1-.45,1.06.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.94,21.15a1.49,1.49,0,0,0,.65-.8c0-.16,0-.36-.2-.38s-.29.19-.31.37c0-.18.09-.41.32-.43s.34.26.32.46a1.73,1.73,0,0,1-.62,1,.14.14,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.56,23a1.49,1.49,0,0,0,.77-.69c.06-.15,0-.36-.13-.41s-.32.14-.37.31c0-.17.15-.39.38-.37a.38.38,0,0,1,.24.5,1.71,1.71,0,0,1-.76.87.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.26,23.53a1.49,1.49,0,0,0,.82-.63.3.3,0,0,0-.11-.42c-.17-.05-.33.12-.39.29,0-.17.17-.38.4-.35s.28.34.21.52a1.68,1.68,0,0,1-.82.82.13.13,0,0,1-.17-.05.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.13,22.79a1.5,1.5,0,0,0,.83-.62.3.3,0,0,0-.1-.42c-.17-.05-.33.12-.39.29,0-.17.18-.38.4-.35a.4.4,0,0,1,.21.53,1.72,1.72,0,0,1-.84.8.13.13,0,0,1-.17-.05.13.13,0,0,1,.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.76,24.74a1.59,1.59,0,0,0,.82-.63c.07-.15.06-.36-.11-.42s-.32.12-.39.29c0-.17.18-.38.41-.35s.27.34.21.52a1.74,1.74,0,0,1-.83.82.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78,24.58a1.47,1.47,0,0,0,.83-.6.29.29,0,0,0-.09-.42c-.16-.06-.33.11-.4.28,0-.17.19-.38.42-.34s.26.35.19.53a1.7,1.7,0,0,1-.85.78.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.91,22.13a1.54,1.54,0,0,0,.79-.67c.06-.14,0-.36-.12-.41s-.33.13-.38.3c0-.17.16-.38.39-.36a.39.39,0,0,1,.23.51,1.7,1.7,0,0,1-.8.85.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.49,20.87a1.57,1.57,0,0,0,.74-.72c0-.16,0-.37-.16-.41s-.31.16-.35.34c0-.18.13-.4.36-.4s.31.31.27.5a1.67,1.67,0,0,1-.73.9.12.12,0,1,1-.14-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.76,18.85a1.56,1.56,0,0,0,.67-.79c0-.15,0-.36-.19-.38s-.29.18-.32.36c0-.18.09-.41.32-.43s.34.28.32.47a1.76,1.76,0,0,1-.64,1,.14.14,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.72,17a1.52,1.52,0,0,0,.57-.87c0-.15-.06-.35-.24-.36s-.27.22-.27.4c0-.17,0-.42.27-.46s.37.23.37.43a1.73,1.73,0,0,1-.52,1,.12.12,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.73,15.05a1.52,1.52,0,0,0,.38-1c0-.16-.13-.34-.3-.31s-.22.27-.19.45a.41.41,0,0,1,.17-.51c.23-.07.41.16.45.35a1.7,1.7,0,0,1-.3,1.12.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.39,13.12a1.55,1.55,0,0,0,.13-1c0-.15-.21-.29-.37-.22s-.15.32-.07.48c-.09-.15-.15-.4,0-.53s.43,0,.51.22a1.65,1.65,0,0,1,0,1.16.13.13,0,1,1-.24-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.45,11.7a1.57,1.57,0,0,0-.09-1c-.08-.13-.27-.24-.41-.13s-.08.34,0,.48a.41.41,0,0,1-.07-.53.38.38,0,0,1,.55.11,1.67,1.67,0,0,1,.24,1.13.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.83,11.68a1.57,1.57,0,0,0-.12-1c-.09-.13-.28-.23-.42-.12s-.06.34.05.48a.4.4,0,0,1-.09-.52c.16-.17.43-.07.56.08a1.75,1.75,0,0,1,.27,1.13.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.82,10.36a1.6,1.6,0,0,0-.13-1c-.09-.14-.28-.24-.42-.12s-.06.34,0,.48c-.12-.13-.24-.35-.09-.53s.43-.06.55.09a1.72,1.72,0,0,1,.29,1.12.13.13,0,1,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.9,10.89a1.57,1.57,0,0,0-.11-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48c-.12-.13-.24-.35-.09-.53a.39.39,0,0,1,.56.1,1.74,1.74,0,0,1,.26,1.13A.12.12,0,0,1,64,11a.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.72,16.55a1.54,1.54,0,0,0,.39-1c0-.16-.13-.34-.3-.3s-.22.26-.19.44c-.05-.17,0-.42.17-.5s.41.15.44.34a1.7,1.7,0,0,1-.3,1.12.13.13,0,0,1-.17,0,.14.14,0,0,1-.05-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.91,24.35a1.48,1.48,0,0,0,.74-.72c0-.15,0-.36-.15-.4s-.31.16-.36.33c0-.17.13-.4.37-.39a.38.38,0,0,1,.26.49,1.69,1.69,0,0,1-.73.91.13.13,0,0,1-.14-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.65,26.71a1.49,1.49,0,0,0,.78-.68c.06-.14,0-.36-.13-.41s-.32.14-.37.31c0-.17.15-.39.38-.37s.3.32.24.51a1.68,1.68,0,0,1-.78.86.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.7,29.59a1.53,1.53,0,0,0,.83-.61c.08-.14.07-.36-.09-.42s-.33.11-.4.28c0-.17.18-.38.41-.34s.27.34.2.53a1.72,1.72,0,0,1-.85.79.13.13,0,0,1-.17-.05.13.13,0,0,1,.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78,32.85a1.51,1.51,0,0,0,.84-.59.29.29,0,0,0-.08-.42c-.17-.06-.33.1-.4.27a.4.4,0,0,1,.42-.33.39.39,0,0,1,.18.53,1.72,1.72,0,0,1-.87.77.12.12,0,1,1-.11-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.65,32.86a1.59,1.59,0,0,0,.88-.55c.08-.14.09-.36-.07-.43s-.34.09-.41.25c.06-.16.21-.36.43-.31a.4.4,0,0,1,.16.54,1.66,1.66,0,0,1-.9.73.12.12,0,0,1-.16-.06.13.13,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79,31.87a1.54,1.54,0,0,0,.87-.56.31.31,0,0,0-.07-.43c-.17-.06-.34.1-.42.26.06-.16.21-.37.44-.31s.24.36.16.53a1.65,1.65,0,0,1-.89.74A.12.12,0,0,1,78.9,32a.12.12,0,0,1,.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.67,30.74a1.56,1.56,0,0,0,.82-.64.3.3,0,0,0-.11-.42c-.17-.05-.33.12-.39.29,0-.17.17-.38.4-.35s.28.34.21.52a1.68,1.68,0,0,1-.82.82.12.12,0,0,1-.17-.05.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.77,28.61a1.52,1.52,0,0,0,.73-.73.29.29,0,0,0-.15-.4c-.17,0-.31.16-.35.33,0-.17.13-.4.36-.39s.31.3.27.49a1.73,1.73,0,0,1-.73.91.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.36,27.18A1.5,1.5,0,0,0,72,26.4a.29.29,0,0,0-.18-.39c-.18,0-.3.19-.33.36,0-.17.1-.41.33-.42s.34.28.31.47a1.75,1.75,0,0,1-.65,1,.13.13,0,1,1-.17-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,26.78a1.55,1.55,0,0,0,.57-.87.3.3,0,0,0-.24-.36c-.17,0-.27.22-.27.4,0-.17,0-.42.27-.46s.37.23.36.43a1.67,1.67,0,0,1-.51,1A.13.13,0,0,1,69,27a.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.49,26.94a1.54,1.54,0,0,0,.45-.93c0-.16-.12-.35-.29-.33s-.23.26-.21.44a.4.4,0,0,1,.2-.5c.23-.05.4.18.42.37a1.7,1.7,0,0,1-.37,1.1.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.8,26.29a1.55,1.55,0,0,0,.28-1c0-.15-.16-.32-.33-.27s-.19.29-.14.46c-.06-.16-.09-.41.12-.52s.42.11.48.3A1.66,1.66,0,0,1,63,26.4a.11.11,0,0,1-.16.07.14.14,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.58,25.62a1.54,1.54,0,0,0,.22-1c0-.15-.19-.31-.35-.25s-.17.3-.11.47a.4.4,0,0,1,.08-.53c.21-.1.43.09.5.27a1.74,1.74,0,0,1-.11,1.15.13.13,0,1,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.67,24.77a1.53,1.53,0,0,0,.16-1c0-.15-.2-.3-.36-.23s-.16.31-.09.48a.4.4,0,0,1,.06-.53c.2-.12.43.06.51.23a1.65,1.65,0,0,1-.05,1.16.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.41,24.19a1.48,1.48,0,0,0,.17-1,.3.3,0,0,0-.36-.24c-.15.08-.16.31-.09.48a.39.39,0,0,1,.07-.53c.2-.12.43.06.5.24a1.71,1.71,0,0,1-.06,1.16.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.29,21.36a1.53,1.53,0,0,0,.06-1c-.07-.15-.24-.28-.39-.19s-.12.32,0,.48c-.09-.15-.17-.38,0-.53s.43,0,.53.18a1.78,1.78,0,0,1,.07,1.16.13.13,0,0,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.92,23.31a1.52,1.52,0,0,0,.25-1c0-.15-.17-.31-.34-.26s-.18.29-.13.47a.42.42,0,0,1,.11-.53c.21-.1.43.1.48.29a1.65,1.65,0,0,1-.15,1.15.12.12,0,0,1-.16.06.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.77,17.47a1.52,1.52,0,0,0,0-1,.29.29,0,0,0-.39-.18c-.14.09-.11.32,0,.48a.4.4,0,0,1,0-.53c.18-.15.43,0,.53.17A1.7,1.7,0,0,1,64,17.53a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.54,19.23a1.54,1.54,0,0,0,.37-1c0-.16-.15-.34-.32-.3s-.21.27-.17.45c0-.17-.06-.42.16-.51s.41.14.45.33a1.71,1.71,0,0,1-.27,1.13.13.13,0,0,1-.17.05.11.11,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.16,17.88a1.55,1.55,0,0,0,.18-1c0-.15-.2-.3-.36-.24s-.16.31-.1.48a.4.4,0,0,1,.07-.53c.2-.12.43.06.5.24A1.65,1.65,0,0,1,66.39,18a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,18.74a1.6,1.6,0,0,0,.26-1c0-.16-.18-.32-.35-.27s-.18.3-.12.47c-.07-.16-.1-.41.1-.52s.43.09.48.28a1.68,1.68,0,0,1-.14,1.15.13.13,0,1,1-.24-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.35,20.26a1.54,1.54,0,0,0,.27-1c0-.16-.17-.32-.33-.27s-.19.29-.14.47a.42.42,0,0,1,.11-.53c.22-.09.43.11.48.3a1.67,1.67,0,0,1-.17,1.14.12.12,0,0,1-.16.07.14.14,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,21.69a1.55,1.55,0,0,0,.47-.92c0-.16-.1-.35-.27-.34s-.24.25-.23.43a.41.41,0,0,1,.22-.49c.23-.05.39.19.41.39a1.69,1.69,0,0,1-.4,1.08.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.83,21a1.57,1.57,0,0,0,.38-1c0-.16-.14-.34-.31-.31s-.22.27-.19.45c0-.17,0-.42.17-.51s.41.15.45.34A1.74,1.74,0,0,1,68,21.16a.12.12,0,0,1-.17,0,.13.13,0,0,1-.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.15,16.52a1.52,1.52,0,0,0,.11-1c-.06-.15-.22-.29-.38-.22s-.13.32-.06.48a.4.4,0,0,1,0-.53c.2-.13.44,0,.52.21a1.71,1.71,0,0,1,0,1.16.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,20.42a1.5,1.5,0,0,0,.42-.94c0-.16-.11-.34-.28-.32s-.24.26-.21.44c0-.17,0-.42.19-.5s.4.17.43.37a1.72,1.72,0,0,1-.35,1.1.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.91,18.42a1.52,1.52,0,0,0,.13-1c-.05-.15-.22-.29-.37-.22s-.15.32-.07.48a.39.39,0,0,1,0-.53c.2-.13.43,0,.51.22a1.65,1.65,0,0,1,0,1.16.13.13,0,0,1-.24-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.07,17.16a1.59,1.59,0,0,0,.08-1c-.06-.14-.23-.28-.38-.2s-.13.32,0,.49a.4.4,0,0,1,0-.54.39.39,0,0,1,.53.2,1.72,1.72,0,0,1,0,1.16.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.68,21.44a1.58,1.58,0,0,0,.32-1c0-.16-.15-.33-.32-.29s-.2.28-.16.45a.39.39,0,0,1,.14-.51c.22-.08.42.13.46.32a1.65,1.65,0,0,1-.23,1.13.12.12,0,1,1-.22-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.75,22.89a1.52,1.52,0,0,0,.49-.91c0-.16-.1-.35-.27-.34s-.25.24-.24.42c0-.17,0-.42.23-.48s.39.2.4.39A1.68,1.68,0,0,1,69,23.05a.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.18,25.28a1.5,1.5,0,0,0,.4-1c0-.16-.13-.34-.3-.31s-.22.27-.19.45a.41.41,0,0,1,.17-.51c.23-.06.41.16.44.35a1.71,1.71,0,0,1-.31,1.12.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.1,23.22a1.58,1.58,0,0,0,.4-.95c0-.16-.13-.34-.3-.31s-.23.27-.2.44c0-.17,0-.42.18-.5s.41.16.44.35a1.71,1.71,0,0,1-.32,1.12.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.26,23.32a1.56,1.56,0,0,0,.31-1c0-.16-.16-.33-.33-.28s-.2.28-.15.45c-.06-.16-.08-.41.13-.51s.42.12.47.31a1.69,1.69,0,0,1-.22,1.14.12.12,0,0,1-.16.05.11.11,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.89,19.17a1.53,1.53,0,0,0,.21-1,.29.29,0,0,0-.35-.25c-.16.07-.17.3-.11.47a.41.41,0,0,1,.09-.53c.21-.1.43.09.49.27a1.63,1.63,0,0,1-.11,1.15.12.12,0,0,1-.16.08.14.14,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,18.05a1.57,1.57,0,0,0,.29-1c0-.16-.17-.33-.34-.28s-.19.29-.14.47c-.06-.17-.09-.42.12-.52s.42.11.48.29a1.7,1.7,0,0,1-.19,1.15.12.12,0,0,1-.16.06.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.34,24.89a1.54,1.54,0,0,0,.45-.93c0-.16-.11-.35-.28-.33s-.24.25-.22.43c0-.17,0-.42.2-.49s.4.18.42.37a1.7,1.7,0,0,1-.37,1.1.14.14,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.25,17.12a1.56,1.56,0,0,0,.21-1c0-.15-.2-.31-.36-.24s-.17.3-.1.47a.4.4,0,0,1,.07-.53c.21-.11.43.08.5.26a1.62,1.62,0,0,1-.09,1.15.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.44,19.88a1.56,1.56,0,0,0,.32-1c0-.16-.16-.33-.33-.28s-.2.28-.15.46c-.06-.17-.08-.42.13-.52s.42.12.47.31A1.72,1.72,0,0,1,67.66,20a.13.13,0,1,1-.23-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.85,22.1a1.59,1.59,0,0,0,.26-1c0-.16-.17-.32-.34-.26s-.18.29-.13.46a.39.39,0,0,1,.11-.52c.21-.1.42.1.48.29a1.67,1.67,0,0,1-.16,1.14.11.11,0,0,1-.16.07.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.68,22.18a1.56,1.56,0,0,0,.41-.95c0-.16-.13-.35-.3-.32s-.23.27-.2.45a.42.42,0,0,1,.18-.51c.23-.06.41.16.44.36a1.74,1.74,0,0,1-.32,1.11.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.07,25.79a1.52,1.52,0,0,0,.36-1c0-.16-.14-.34-.31-.3s-.21.27-.18.45c0-.17,0-.42.16-.51a.39.39,0,0,1,.46.33,1.66,1.66,0,0,1-.28,1.13.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.15,22.48a1.53,1.53,0,0,0,.32-1c0-.16-.15-.33-.32-.29s-.2.28-.16.45a.39.39,0,0,1,.14-.51c.22-.08.42.13.47.32a1.72,1.72,0,0,1-.24,1.13.13.13,0,0,1-.17.06.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.79,19.87a1.49,1.49,0,0,0,.19-1c0-.15-.19-.31-.35-.24s-.17.31-.1.47a.4.4,0,0,1,.07-.53c.21-.11.43.07.5.26A1.63,1.63,0,0,1,65,20a.12.12,0,1,1-.23-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.26,24a1.46,1.46,0,0,0,.37-1c0-.16-.13-.33-.3-.3s-.22.27-.19.45a.4.4,0,0,1,.17-.51c.22-.07.41.15.45.34a1.75,1.75,0,0,1-.29,1.12.13.13,0,0,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,24.48a1.52,1.52,0,0,0,.32-1c0-.16-.15-.33-.32-.29s-.2.28-.16.45c0-.16-.07-.41.14-.51s.42.13.47.32a1.72,1.72,0,0,1-.24,1.13.13.13,0,0,1-.17.06.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.34,21a1.52,1.52,0,0,0,.25-1c0-.16-.18-.32-.34-.26s-.18.29-.13.46c-.07-.16-.1-.41.1-.52s.43.1.49.28a1.69,1.69,0,0,1-.15,1.15.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.12,24a1.55,1.55,0,0,0,.47-.91c0-.16-.1-.35-.27-.34s-.25.25-.23.43a.4.4,0,0,1,.22-.49c.23,0,.39.19.4.39a1.69,1.69,0,0,1-.4,1.08.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.27,24.35a1.52,1.52,0,0,0,.25-1c0-.16-.17-.32-.34-.27s-.18.3-.13.47c-.06-.16-.09-.41.11-.52s.42.09.48.28a1.63,1.63,0,0,1-.15,1.15.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.21,20a1.55,1.55,0,0,0,.11-1c-.06-.15-.22-.29-.38-.21s-.13.32-.06.48a.4.4,0,0,1,0-.53.38.38,0,0,1,.52.2,1.71,1.71,0,0,1,0,1.16.12.12,0,0,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.9,25.31a1.58,1.58,0,0,0,.3-1c0-.16-.16-.33-.33-.28s-.19.29-.15.46a.4.4,0,0,1,.13-.52c.22-.09.42.12.47.31a1.73,1.73,0,0,1-.2,1.14.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.67,23.29a1.57,1.57,0,0,0,.2-1c0-.15-.19-.31-.35-.24s-.17.3-.11.47c-.07-.16-.11-.41.08-.53s.43.08.5.26a1.74,1.74,0,0,1-.09,1.15.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.72,18a1.52,1.52,0,0,0,0-1c-.07-.14-.25-.26-.4-.17s-.1.33,0,.48a.4.4,0,0,1,0-.53c.18-.15.43,0,.54.16A1.69,1.69,0,0,1,63,18.1a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.14,19a1.58,1.58,0,0,0,0-1c-.07-.15-.25-.27-.39-.18s-.11.33,0,.49a.41.41,0,0,1,0-.54c.18-.14.44,0,.54.16a1.73,1.73,0,0,1,.12,1.16.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.39,22.19a1.54,1.54,0,0,0,.21-1c0-.16-.2-.31-.36-.25s-.17.31-.1.48c-.08-.16-.12-.41.07-.53a.38.38,0,0,1,.5.26,1.63,1.63,0,0,1-.09,1.15.12.12,0,0,1-.16.08.14.14,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.88,20.17a1.53,1.53,0,0,0,0-1c-.07-.15-.24-.28-.39-.19s-.12.33,0,.48a.4.4,0,0,1,0-.53c.19-.14.44,0,.54.18a1.69,1.69,0,0,1,.09,1.15.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.68,18.9a1.53,1.53,0,0,0,.09-1c-.06-.15-.23-.28-.38-.2s-.13.32,0,.48a.41.41,0,0,1,0-.53.39.39,0,0,1,.53.2,1.63,1.63,0,0,1,0,1.15.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.9,22.43a1.53,1.53,0,0,0,.15-1c0-.15-.21-.3-.37-.23s-.15.32-.08.48a.4.4,0,0,1,0-.53.38.38,0,0,1,.51.23,1.71,1.71,0,0,1,0,1.16.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.61,21.23a1.59,1.59,0,0,0,.13-1c-.05-.15-.21-.3-.37-.22s-.15.31-.07.48a.4.4,0,0,1,0-.54c.2-.12.43.05.51.23a1.71,1.71,0,0,1,0,1.16.12.12,0,0,1-.15.08.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.92,21a1.6,1.6,0,0,0,.19-1c0-.15-.2-.3-.36-.24s-.17.31-.1.48c-.08-.16-.12-.41.07-.53s.43.07.5.25a1.71,1.71,0,0,1-.07,1.16.13.13,0,0,1-.16.07.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.42,15.74a1.52,1.52,0,0,0,0-1c-.07-.14-.25-.26-.39-.17s-.11.33,0,.49a.4.4,0,0,1,0-.54c.18-.15.44,0,.54.15a1.65,1.65,0,0,1,.14,1.15.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,13.92a1.54,1.54,0,0,0-.13-1c-.09-.14-.28-.23-.41-.12s-.07.34.05.48a.4.4,0,0,1-.1-.53c.16-.17.43-.06.56.09a1.72,1.72,0,0,1,.28,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.65,10.49a1.48,1.48,0,0,0-.14-1c-.09-.13-.28-.23-.42-.12s-.05.35.06.48a.4.4,0,0,1-.1-.52c.16-.17.43-.07.56.08a1.72,1.72,0,0,1,.29,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.33,9a1.62,1.62,0,0,0-.13-1c-.09-.13-.28-.23-.42-.12s-.06.34,0,.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.08A1.77,1.77,0,0,1,51.58,9a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.91,8.93a1.57,1.57,0,0,0-.14-1c-.09-.13-.29-.22-.42-.11s-.06.34.06.48c-.12-.12-.25-.34-.1-.52s.43-.07.55.07a1.69,1.69,0,0,1,.3,1.12.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.32,7.28a1.6,1.6,0,0,0-.13-1c-.09-.13-.28-.23-.42-.12s-.06.34,0,.48a.41.41,0,0,1-.09-.53c.16-.17.43-.06.56.09a1.75,1.75,0,0,1,.27,1.13.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,7.44a1.59,1.59,0,0,0-.14-1c-.09-.14-.29-.23-.42-.12s-.06.34.06.48a.38.38,0,0,1-.1-.52c.16-.18.43-.07.55.08a1.61,1.61,0,0,1,.29,1.12.12.12,0,0,1-.12.12A.12.12,0,0,1,56,7.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.48,8.28a1.54,1.54,0,0,0-.14-1c-.09-.13-.29-.23-.42-.12s-.06.35.06.49a.39.39,0,0,1-.1-.53.38.38,0,0,1,.55.08,1.67,1.67,0,0,1,.3,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.06,8.34a1.54,1.54,0,0,0-.14-1c-.09-.14-.28-.23-.41-.12s-.06.34.05.48a.4.4,0,0,1-.1-.52c.16-.18.43-.07.56.08a1.72,1.72,0,0,1,.29,1.12.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.08,10.24a1.56,1.56,0,0,0-.15-1c-.09-.13-.29-.22-.42-.11s0,.34.06.48a.4.4,0,0,1-.1-.52.37.37,0,0,1,.55.07,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.59,11.56a1.54,1.54,0,0,0-.14-1c-.09-.13-.29-.23-.42-.12s-.06.35.06.49a.39.39,0,0,1-.1-.53.38.38,0,0,1,.55.08,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.67,7.17a1.54,1.54,0,0,0-.14-1c-.09-.14-.28-.23-.41-.12s-.06.34.05.48a.41.41,0,0,1-.1-.53.39.39,0,0,1,.56.09,1.66,1.66,0,0,1,.28,1.12.11.11,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,12.46a1.53,1.53,0,0,0-.15-1c-.09-.13-.29-.23-.42-.11s-.06.34.06.48c-.12-.13-.25-.34-.1-.53s.43-.07.55.08a1.74,1.74,0,0,1,.31,1.12.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.73,9.85a1.6,1.6,0,0,0-.14-1c-.09-.13-.29-.23-.42-.11s-.06.34.06.48a.39.39,0,0,1-.1-.53.38.38,0,0,1,.55.08A1.67,1.67,0,0,1,54,9.87a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.67,11.38a1.56,1.56,0,0,0-.15-1,.3.3,0,0,0-.42-.11c-.12.12-.05.34.06.48a.4.4,0,0,1-.1-.52.39.39,0,0,1,.56.07,1.73,1.73,0,0,1,.3,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.44,10a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48c-.13-.13-.25-.35-.11-.53a.39.39,0,0,1,.56.08,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.05,10a1.48,1.48,0,0,0-.14-1c-.09-.13-.28-.23-.41-.12s-.06.34,0,.48a.4.4,0,0,1-.1-.52c.16-.17.43-.07.56.08A1.72,1.72,0,0,1,52.3,10a.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.66,10.19a1.48,1.48,0,0,0-.13-1,.3.3,0,0,0-.42-.12c-.12.12-.06.34.06.48a.39.39,0,0,1-.1-.52c.16-.18.43-.07.55.08a1.61,1.61,0,0,1,.29,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,12a1.54,1.54,0,0,0-.15-1,.3.3,0,0,0-.42-.11c-.12.12,0,.34.07.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.13A.13.13,0,0,1,54,12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,11.15a1.56,1.56,0,0,0-.15-1A.3.3,0,0,0,56.4,10c-.12.12,0,.34.06.48a.4.4,0,0,1-.1-.52.38.38,0,0,1,.56.07,1.73,1.73,0,0,1,.3,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.71,8.76a1.57,1.57,0,0,0-.14-1c-.09-.13-.28-.23-.41-.12s-.06.35.05.49c-.12-.13-.24-.35-.1-.53a.39.39,0,0,1,.56.08A1.77,1.77,0,0,1,53,8.78a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.7,11.26a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.41-.11s-.06.34,0,.48a.41.41,0,0,1-.1-.53c.16-.17.43-.07.56.08a1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.46,12.9a1.57,1.57,0,0,0-.14-1c-.09-.13-.29-.22-.42-.11s-.06.34.06.48c-.12-.12-.25-.34-.1-.52a.37.37,0,0,1,.55.07,1.69,1.69,0,0,1,.3,1.12.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.7,13.31a1.48,1.48,0,0,0-.14-1c-.1-.13-.29-.23-.42-.11s-.06.34.06.48a.41.41,0,0,1-.11-.53.39.39,0,0,1,.56.08,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.44,13.92a1.49,1.49,0,0,0-.16-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48c-.12-.12-.25-.34-.11-.52a.38.38,0,0,1,.56.07,1.68,1.68,0,0,1,.31,1.12.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.92,11.76a1.57,1.57,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34,0,.48a.4.4,0,0,1-.1-.52.38.38,0,0,1,.56.07,1.75,1.75,0,0,1,.3,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.43,12.28a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.42-.11s0,.34.06.48a.41.41,0,0,1-.1-.53c.16-.17.43-.07.56.08a1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.13.12.11.11,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.77,14a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.42-.11s-.05.34.06.48a.41.41,0,0,1-.1-.53c.16-.17.43-.07.56.08A1.72,1.72,0,0,1,57,14a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.71,10.86a1.56,1.56,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.63,12.37a1.53,1.53,0,0,0-.15-1c-.09-.13-.28-.23-.42-.11s-.05.34.07.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08a1.7,1.7,0,0,1,.3,1.11.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.14,11A1.54,1.54,0,0,0,53,10c-.1-.13-.29-.23-.42-.11s-.06.34.06.48a.4.4,0,0,1-.11-.53.39.39,0,0,1,.56.08,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.07,9.66a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.42-.11s0,.34.06.48a.41.41,0,0,1-.1-.53c.16-.17.43-.07.56.08a1.67,1.67,0,0,1,.29,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.5,15.09a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08a1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.6,13.4a1.54,1.54,0,0,0-.15-1c-.09-.13-.29-.22-.42-.11s-.05.34.06.48a.4.4,0,0,1-.1-.52.37.37,0,0,1,.55.07,1.68,1.68,0,0,1,.31,1.12.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.8,13a1.53,1.53,0,0,0-.15-1c-.09-.14-.28-.23-.41-.12s-.06.35.06.49a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.07a1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.13A.13.13,0,0,1,53.8,13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.64,13.8a1.53,1.53,0,0,0-.15-1c-.09-.13-.29-.23-.42-.11s0,.34.06.48c-.12-.13-.25-.34-.1-.53s.43-.07.55.08a1.68,1.68,0,0,1,.31,1.12.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.11,8A1.54,1.54,0,0,0,57,7c-.09-.13-.28-.23-.41-.11s-.06.34.06.48a.4.4,0,0,1-.11-.53.39.39,0,0,1,.56.08A1.73,1.73,0,0,1,57.36,8a.13.13,0,0,1-.13.13A.12.12,0,0,1,57.11,8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.54,8.91a1.54,1.54,0,0,0-.15-1c-.09-.14-.28-.23-.41-.12s-.06.35.05.48a.4.4,0,0,1-.1-.52c.16-.17.43-.07.56.08a1.67,1.67,0,0,1,.29,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.44,14.23a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.25,15.51a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48a.4.4,0,0,1-.11-.53c.16-.17.43-.07.56.08a1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.28,12.71a1.53,1.53,0,0,0-.15-1c-.09-.13-.29-.23-.42-.11s-.05.34.06.48a.41.41,0,0,1-.1-.53.38.38,0,0,1,.55.08,1.68,1.68,0,0,1,.31,1.12.13.13,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.06,7.8a1.48,1.48,0,0,0-.13-1c-.08-.14-.28-.24-.41-.13s-.06.35,0,.49c-.12-.13-.24-.35-.09-.53s.43-.06.55.09a1.72,1.72,0,0,1,.28,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.7,14.68a1.54,1.54,0,0,0-.15-1c-.09-.13-.28-.23-.42-.11s-.05.34.06.48c-.12-.13-.24-.35-.1-.53s.43-.07.56.08A1.73,1.73,0,0,1,56,14.7a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.11,15a1.54,1.54,0,0,0-.15-1c-.1-.13-.29-.22-.42-.11s0,.35.06.48c-.12-.12-.25-.34-.11-.52a.39.39,0,0,1,.56.07A1.68,1.68,0,0,1,52.36,15a.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.22,10.66a1.64,1.64,0,0,0-.13-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.39.39,0,0,1-.1-.52.38.38,0,0,1,.56.09,1.72,1.72,0,0,1,.28,1.12.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.89,13.32a1.57,1.57,0,0,0-.12-1c-.09-.13-.28-.23-.41-.12s-.07.34.05.48a.39.39,0,0,1-.09-.53c.16-.17.43-.06.55.09a1.69,1.69,0,0,1,.27,1.13.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.19,17.59a1.51,1.51,0,0,0-.07-1c-.08-.13-.27-.24-.41-.14s-.08.34,0,.48a.41.41,0,0,1-.06-.53.39.39,0,0,1,.55.12,1.76,1.76,0,0,1,.22,1.14.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.87,17.88a1.64,1.64,0,0,0-.1-1c-.09-.13-.27-.24-.41-.13s-.07.34,0,.48a.4.4,0,0,1-.07-.52c.16-.17.43-.06.55.1a1.67,1.67,0,0,1,.24,1.13.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,18A1.57,1.57,0,0,0,23,17c-.08-.13-.27-.24-.41-.14s-.08.34,0,.49a.4.4,0,0,1-.07-.53.38.38,0,0,1,.55.11,1.76,1.76,0,0,1,.23,1.14.12.12,0,0,1-.14.11A.11.11,0,0,1,23,18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.62,17.66a1.53,1.53,0,0,0-.18-1c-.09-.13-.29-.22-.42-.1s0,.34.08.48A.39.39,0,0,1,19,16.5a.37.37,0,0,1,.55.06,1.64,1.64,0,0,1,.34,1.1.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.72,18.08a1.5,1.5,0,0,0-.14-1,.3.3,0,0,0-.42-.11c-.12.12-.05.34.06.48a.4.4,0,0,1-.1-.52.37.37,0,0,1,.55.07,1.67,1.67,0,0,1,.3,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.24,15a1.48,1.48,0,0,0-.1-1,.3.3,0,0,0-.41-.14c-.13.12-.07.34,0,.49-.12-.13-.23-.36-.08-.53a.37.37,0,0,1,.55.1,1.68,1.68,0,0,1,.25,1.13.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.52,12.88a1.52,1.52,0,0,0-.06-1c-.08-.14-.26-.25-.4-.15s-.09.34,0,.49a.4.4,0,0,1-.05-.53.37.37,0,0,1,.54.12,1.69,1.69,0,0,1,.21,1.14.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,13a1.54,1.54,0,0,0,0-1c-.08-.14-.26-.25-.4-.15s-.1.33,0,.48a.33.33,0,1,1,.49-.4,1.78,1.78,0,0,1,.19,1.15A.13.13,0,1,1,19,13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.7,12.54a1.51,1.51,0,0,0-.07-1,.3.3,0,0,0-.41-.14c-.13.11-.08.34,0,.48a.39.39,0,0,1-.07-.53c.17-.16.43,0,.55.12A1.7,1.7,0,0,1,22,12.58a.12.12,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.29,15.13a1.57,1.57,0,0,0-.08-1c-.08-.13-.26-.24-.4-.14s-.08.34,0,.48a.41.41,0,0,1-.07-.53.38.38,0,0,1,.55.12,1.64,1.64,0,0,1,.22,1.14.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.78,13.19a1.57,1.57,0,0,0-.09-1,.3.3,0,0,0-.41-.14c-.13.11-.07.34,0,.48a.41.41,0,0,1-.07-.53.39.39,0,0,1,.55.11A1.65,1.65,0,0,1,23,13.22a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.66,15.43a1.58,1.58,0,0,0-.05-1,.3.3,0,0,0-.41-.15c-.13.11-.08.34,0,.48-.11-.13-.22-.36-.06-.53a.39.39,0,0,1,.55.13,1.68,1.68,0,0,1,.19,1.14.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.54,16.51a1.53,1.53,0,0,0-.18-1c-.09-.13-.29-.22-.42-.1s0,.34.08.48c-.13-.12-.26-.34-.12-.52s.43-.09.56.06a1.7,1.7,0,0,1,.33,1.1.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.93,16.4a1.57,1.57,0,0,0-.09-1,.3.3,0,0,0-.41-.14c-.13.12-.07.34,0,.49-.11-.13-.22-.36-.07-.53a.38.38,0,0,1,.55.11,1.67,1.67,0,0,1,.24,1.13.14.14,0,0,1-.14.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.94,13.81a1.51,1.51,0,0,0-.1-1c-.09-.13-.28-.24-.41-.13s-.07.34,0,.48a.39.39,0,0,1-.08-.52c.16-.17.43-.06.55.1a1.68,1.68,0,0,1,.25,1.13.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.13,15.46a1.57,1.57,0,0,0-.08-1c-.09-.13-.27-.24-.41-.13s-.08.33,0,.48a.39.39,0,0,1-.07-.53.38.38,0,0,1,.55.11,1.76,1.76,0,0,1,.23,1.14.12.12,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.5,15.18a1.54,1.54,0,0,0,0-1c-.08-.14-.27-.25-.41-.15s-.09.34,0,.49a.41.41,0,0,1,0-.54.39.39,0,0,1,.55.13,1.72,1.72,0,0,1,.19,1.15.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,13a1.51,1.51,0,0,0-.1-1,.29.29,0,0,0-.41-.13c-.13.11-.07.34,0,.48a.4.4,0,0,1-.08-.53.39.39,0,0,1,.55.1A1.72,1.72,0,0,1,24.26,13a.13.13,0,0,1-.13.12A.14.14,0,0,1,24,13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.87,14a1.51,1.51,0,0,0,0-1c-.07-.14-.26-.26-.4-.16s-.09.34,0,.49a.41.41,0,0,1-.05-.54.39.39,0,0,1,.55.14A1.68,1.68,0,0,1,20.12,14a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.24,14a1.57,1.57,0,0,0-.06-1c-.08-.14-.27-.25-.41-.15s-.08.34,0,.49c-.11-.13-.22-.36-.06-.53a.39.39,0,0,1,.55.12,1.63,1.63,0,0,1,.2,1.14.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.73,12a1.51,1.51,0,0,0-.05-1c-.08-.14-.26-.25-.4-.15s-.09.33,0,.48a.4.4,0,0,1-.05-.53c.17-.16.43,0,.54.13A1.69,1.69,0,0,1,20,12a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.45,16.6a1.63,1.63,0,0,0-.07-1c-.08-.14-.27-.25-.41-.15s-.08.34,0,.49a.41.41,0,0,1-.06-.54c.17-.16.44,0,.55.12a1.69,1.69,0,0,1,.21,1.14.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.13,15.35a1.54,1.54,0,0,0-.14-1c-.09-.13-.29-.23-.42-.12s-.06.35.06.48c-.12-.12-.25-.34-.1-.52a.38.38,0,0,1,.55.08,1.67,1.67,0,0,1,.3,1.12.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,11.24a1.54,1.54,0,0,0-.08-1c-.09-.14-.27-.25-.41-.14s-.08.34,0,.48a.34.34,0,1,1,.48-.42,1.76,1.76,0,0,1,.23,1.14.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.39,14.34a1.52,1.52,0,0,0-.07-1c-.08-.13-.27-.24-.41-.14s-.08.34,0,.49a.4.4,0,0,1-.07-.53.38.38,0,0,1,.55.11,1.7,1.7,0,0,1,.22,1.14.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.74,16.35a1.57,1.57,0,0,0-.11-1,.29.29,0,0,0-.41-.13c-.13.11-.07.34,0,.48a.4.4,0,0,1-.08-.53.39.39,0,0,1,.55.1A1.72,1.72,0,0,1,20,16.38a.13.13,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.19,16.62a1.57,1.57,0,0,0-.05-1c-.08-.14-.27-.26-.41-.15s-.08.33,0,.48c-.11-.13-.22-.36-.06-.53a.39.39,0,0,1,.55.12,1.73,1.73,0,0,1,.2,1.15.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.73,14.27a1.51,1.51,0,0,0-.08-1c-.09-.14-.27-.24-.41-.14s-.08.34,0,.49a.39.39,0,0,1-.07-.53c.16-.16.43-.05.55.11A1.72,1.72,0,0,1,24,14.3a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.81,11.41a1.51,1.51,0,0,0-.07-1c-.08-.14-.27-.25-.41-.15s-.08.34,0,.49a.4.4,0,0,1-.07-.53.38.38,0,0,1,.55.11,1.7,1.7,0,0,1,.22,1.14.14.14,0,0,1-.14.12.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.9,15.45a1.51,1.51,0,0,0-.07-1c-.08-.14-.27-.25-.41-.14s-.08.33,0,.48c-.11-.13-.22-.36-.06-.53a.39.39,0,0,1,.55.12,1.73,1.73,0,0,1,.22,1.13.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.47,14.09a1.51,1.51,0,0,0-.07-1c-.08-.13-.27-.24-.41-.14s-.08.34,0,.49c-.12-.14-.23-.36-.07-.53s.43-.05.55.11a1.7,1.7,0,0,1,.22,1.14.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.93,16.63a1.57,1.57,0,0,0-.08-1,.29.29,0,0,0-.4-.14c-.14.11-.08.34,0,.48a.4.4,0,0,1-.06-.53c.17-.16.43,0,.55.12a1.76,1.76,0,0,1,.22,1.14.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,12a1.56,1.56,0,0,0-.09-1c-.09-.14-.27-.24-.41-.14a.37.37,0,0,0,0,.48.38.38,0,0,1-.07-.52c.16-.17.43-.05.55.1A1.71,1.71,0,0,1,23.24,12a.12.12,0,0,1-.13.11A.12.12,0,0,1,23,12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.07,19.75a1.54,1.54,0,0,0-.56-.87c-.14-.08-.36-.08-.43.08s.09.33.26.41c-.17-.06-.37-.21-.32-.43s.36-.25.54-.17a1.7,1.7,0,0,1,.74.89.12.12,0,0,1-.22.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.83,25.09a1.57,1.57,0,0,0-.72-.74.29.29,0,0,0-.4.15c0,.17.15.31.32.35a.4.4,0,0,1-.39-.36c0-.23.31-.31.5-.26A1.67,1.67,0,0,1,12,25a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.67,23.8a1.57,1.57,0,0,0-.71-.75.3.3,0,0,0-.41.15c0,.17.15.31.33.36-.17,0-.4-.14-.39-.38s.31-.3.5-.25a1.71,1.71,0,0,1,.89.74.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.93,24.46a1.51,1.51,0,0,0-.69-.76.3.3,0,0,0-.41.14c0,.17.15.31.32.36-.17,0-.39-.14-.38-.38s.32-.3.51-.24a1.67,1.67,0,0,1,.87.76.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.31,23.23a1.52,1.52,0,0,0-.72-.74.29.29,0,0,0-.4.15c0,.17.15.31.33.36-.18,0-.4-.14-.39-.37s.3-.31.49-.26a1.7,1.7,0,0,1,.9.72.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.46,21.89a1.49,1.49,0,0,0-.7-.76c-.15-.06-.36,0-.4.14s.14.32.32.37c-.17,0-.4-.14-.39-.38s.32-.3.5-.25a1.69,1.69,0,0,1,.89.75.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.53,22.55a1.56,1.56,0,0,0-.69-.76c-.15-.06-.37,0-.41.13s.14.32.32.37c-.18,0-.4-.15-.38-.38s.31-.3.5-.24a1.68,1.68,0,0,1,.88.76.13.13,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.79,23.13a1.54,1.54,0,0,0-.68-.78c-.15-.06-.36,0-.41.13s.14.32.31.37c-.17,0-.39-.15-.37-.39s.32-.29.51-.23A1.72,1.72,0,0,1,13,23a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.55,21.11a1.51,1.51,0,0,0-.67-.78c-.14-.06-.36,0-.41.13s.14.32.31.37c-.17,0-.39-.16-.37-.39s.33-.29.51-.23a1.64,1.64,0,0,1,.85.79.12.12,0,0,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.61,24.5a1.52,1.52,0,0,0-.73-.73.29.29,0,0,0-.4.15c0,.18.16.31.33.35-.17,0-.4-.12-.39-.35s.3-.32.49-.27a1.69,1.69,0,0,1,.91.71.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.63,21.8A1.53,1.53,0,0,0,12,21a.3.3,0,0,0-.42.12c0,.17.13.32.31.38-.17,0-.39-.17-.37-.4s.33-.28.52-.22a1.68,1.68,0,0,1,.84.8.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.28,23.18a1.6,1.6,0,0,0-.65-.81c-.14-.07-.36-.05-.41.12s.12.32.29.38c-.17,0-.38-.17-.35-.4s.33-.28.51-.22a1.7,1.7,0,0,1,.83.82.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18,21.65a1.53,1.53,0,0,0-.48-.92.29.29,0,0,0-.43,0c-.08.15.06.34.22.43-.16-.07-.35-.24-.27-.46s.38-.21.55-.11a1.77,1.77,0,0,1,.65,1,.13.13,0,1,1-.24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.77,20.78a1.54,1.54,0,0,0-.39-1c-.13-.1-.34-.14-.44,0s0,.34.18.45c-.15-.09-.32-.27-.23-.48s.4-.18.56-.07a1.72,1.72,0,0,1,.57,1,.12.12,0,1,1-.24.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.69,21.59a1.57,1.57,0,0,0-.39-.95c-.13-.11-.34-.15-.44,0s0,.34.18.45c-.15-.09-.32-.27-.23-.48a.38.38,0,0,1,.56-.06,1.68,1.68,0,0,1,.56,1,.12.12,0,1,1-.24.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.45,20a1.52,1.52,0,0,0-.27-1c-.1-.12-.31-.19-.42-.06s0,.35.11.47a.4.4,0,0,1-.16-.51.39.39,0,0,1,.56,0A1.75,1.75,0,0,1,20.7,20a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.12,19.4a1.48,1.48,0,0,0-.16-1c-.09-.13-.28-.23-.41-.11s-.06.34.06.48c-.12-.12-.25-.34-.11-.52a.38.38,0,0,1,.56.07,1.64,1.64,0,0,1,.31,1.11.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.61,11.62a1.57,1.57,0,0,0-.11-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.4.4,0,0,1-.08-.53c.16-.17.43-.06.55.1a1.74,1.74,0,0,1,.26,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.83,10.21a1.57,1.57,0,0,0-.11-1c-.08-.13-.27-.24-.41-.13s-.07.34,0,.48A.39.39,0,0,1,23.27,9c.16-.17.43-.06.55.09a1.79,1.79,0,0,1,.26,1.14.14.14,0,0,1-.13.12c-.07,0-.13-.07-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,11a1.54,1.54,0,0,0-.12-1c-.08-.14-.28-.24-.41-.13s-.07.34.05.48a.39.39,0,0,1-.09-.52c.16-.17.43-.06.55.09a1.65,1.65,0,0,1,.27,1.12.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.22,9.79a1.51,1.51,0,0,0-.13-1c-.08-.13-.28-.23-.41-.12s-.06.34.05.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.08,1.7,1.7,0,0,1,.28,1.13.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.45,11.89a1.57,1.57,0,0,0-.13-1c-.09-.13-.28-.23-.42-.12s-.06.35.06.49a.4.4,0,0,1-.1-.53.38.38,0,0,1,.55.08,1.71,1.71,0,0,1,.29,1.13.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.68,21a1.51,1.51,0,0,0-.1-1c-.09-.13-.28-.23-.41-.13s-.07.34,0,.49c-.12-.13-.24-.35-.09-.53a.39.39,0,0,1,.56.1A1.68,1.68,0,0,1,26.93,21a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.69,24.37a1.48,1.48,0,0,0-.31-1c-.11-.11-.32-.17-.43,0s0,.35.14.46a.4.4,0,0,1-.19-.5.39.39,0,0,1,.56,0,1.65,1.65,0,0,1,.48,1.05.13.13,0,0,1-.1.15.14.14,0,0,1-.15-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.74,25.27a1.54,1.54,0,0,0-.42-.95c-.12-.1-.34-.14-.43,0s0,.35.19.45c-.15-.09-.33-.26-.25-.48a.39.39,0,0,1,.56-.08,1.74,1.74,0,0,1,.6,1,.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.13,26.55a1.48,1.48,0,0,0-.55-.87.29.29,0,0,0-.43.07c-.06.16.09.33.25.41-.16-.06-.36-.21-.31-.44a.4.4,0,0,1,.54-.16,1.72,1.72,0,0,1,.74.9.13.13,0,0,1-.07.17.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.73,26.77a1.58,1.58,0,0,0-.6-.84c-.14-.08-.36-.07-.42.09s.1.33.27.4a.4.4,0,0,1-.33-.42c.06-.22.35-.26.53-.19a1.76,1.76,0,0,1,.78.86.13.13,0,0,1-.05.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.74,25a1.53,1.53,0,0,0-.53-.89.29.29,0,0,0-.42.05c-.08.16.07.34.23.42a.39.39,0,0,1-.29-.44c.08-.22.37-.23.54-.14A1.64,1.64,0,0,1,20,25a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.6,23.06a1.54,1.54,0,0,0-.37-1c-.12-.11-.33-.16-.43,0s0,.35.16.46c-.14-.09-.31-.28-.21-.49s.4-.17.56-.05a1.71,1.71,0,0,1,.54,1,.14.14,0,0,1-.1.16.14.14,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.61,20.1a1.48,1.48,0,0,0-.12-1,.29.29,0,0,0-.41-.13c-.13.12-.07.34.05.48a.39.39,0,0,1-.09-.52c.16-.17.43-.06.55.09a1.68,1.68,0,0,1,.27,1.12.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.5,15.73a1.56,1.56,0,0,0-.11-1c-.09-.14-.28-.24-.42-.13s-.06.34.05.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.09,1.69,1.69,0,0,1,.27,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.93,10.32a1.56,1.56,0,0,0-.14-1c-.09-.13-.28-.23-.41-.12s-.06.35.05.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.08,1.77,1.77,0,0,1,.29,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.83,8.29a1.6,1.6,0,0,0-.13-1c-.09-.14-.29-.23-.42-.12s-.06.34,0,.48a.39.39,0,0,1-.09-.52c.16-.18.43-.07.55.08a1.72,1.72,0,0,1,.29,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.06,6.15a1.54,1.54,0,0,0-.11-1c-.09-.14-.28-.24-.42-.13s-.06.34,0,.49c-.12-.13-.24-.35-.09-.53s.43-.06.55.09a1.69,1.69,0,0,1,.27,1.13.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.32,4.4a1.51,1.51,0,0,0-.07-1c-.08-.14-.26-.25-.4-.14s-.09.33,0,.48a.39.39,0,0,1-.06-.53.37.37,0,0,1,.54.12,1.7,1.7,0,0,1,.22,1.14.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.56,4a1.45,1.45,0,0,0-.05-1c-.07-.14-.26-.26-.4-.16s-.09.34,0,.49c-.11-.14-.21-.37,0-.53a.38.38,0,0,1,.55.12,1.72,1.72,0,0,1,.19,1.15.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,4.68a1.57,1.57,0,0,0-.09-1c-.08-.13-.27-.24-.41-.13s-.07.34,0,.48a.41.41,0,0,1-.07-.53.38.38,0,0,1,.55.11,1.67,1.67,0,0,1,.24,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.32,5.81a1.51,1.51,0,0,0-.13-1c-.09-.13-.28-.23-.41-.12s-.06.34,0,.48a.39.39,0,0,1-.09-.52.38.38,0,0,1,.55.08,1.7,1.7,0,0,1,.28,1.13.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.9,7.41a1.56,1.56,0,0,0-.1-1c-.09-.14-.28-.24-.42-.13s-.07.34,0,.48a.4.4,0,0,1-.08-.53.39.39,0,0,1,.55.1,1.68,1.68,0,0,1,.26,1.13.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.77,12.18a1.56,1.56,0,0,0-.15-1c-.09-.13-.28-.22-.41-.11s-.06.34.06.48a.39.39,0,0,1-.11-.52.38.38,0,0,1,.56.07,1.67,1.67,0,0,1,.3,1.12.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.61,15.81a1.55,1.55,0,0,0-.43-.94c-.13-.1-.34-.14-.43,0s0,.34.19.44a.4.4,0,0,1-.25-.47.39.39,0,0,1,.56-.08,1.73,1.73,0,0,1,.61,1,.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.85,16.74a1.53,1.53,0,0,0-.51-.9c-.14-.09-.35-.11-.43,0s.07.34.23.43c-.16-.07-.35-.23-.29-.45s.38-.22.55-.13a1.73,1.73,0,0,1,.69.93.12.12,0,0,1-.08.16.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.8,14a1.54,1.54,0,0,0-.27-1c-.11-.12-.31-.19-.43-.06s0,.35.12.47a.41.41,0,0,1-.17-.51c.14-.19.42-.12.57,0a1.64,1.64,0,0,1,.43,1.07.12.12,0,0,1-.1.14.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.91,9.93a1.57,1.57,0,0,0-.12-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.41.41,0,0,1-.09-.53.39.39,0,0,1,.56.09A1.68,1.68,0,0,1,25.15,10a.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.05,11.5a1.57,1.57,0,0,0-.14-1c-.09-.13-.29-.22-.42-.11s-.06.34.06.48a.38.38,0,0,1-.1-.52.37.37,0,0,1,.55.07,1.72,1.72,0,0,1,.3,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.44,9.71a1.51,1.51,0,0,0-.13-1,.29.29,0,0,0-.41-.12c-.13.12-.06.34.05.48-.12-.13-.24-.35-.09-.53s.43-.06.55.09a1.76,1.76,0,0,1,.28,1.13.13.13,0,0,1-.13.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.18,9.11a1.56,1.56,0,0,0-.11-1A.29.29,0,0,0,42.66,8c-.13.11-.07.34,0,.48a.4.4,0,0,1-.08-.53.39.39,0,0,1,.55.1,1.74,1.74,0,0,1,.26,1.13.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.2,8.75a1.57,1.57,0,0,0-.11-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.41.41,0,0,1-.09-.53.39.39,0,0,1,.56.1,1.74,1.74,0,0,1,.26,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.14,8.38a1.51,1.51,0,0,0-.11-1c-.09-.13-.28-.23-.42-.12s-.06.34,0,.48a.39.39,0,0,1-.09-.52.37.37,0,0,1,.55.09,1.65,1.65,0,0,1,.27,1.12.13.13,0,0,1-.12.13.14.14,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.42,9.37a1.57,1.57,0,0,0-.14-1c-.09-.13-.28-.23-.42-.11s0,.34.06.48c-.12-.13-.24-.35-.1-.53s.43-.07.56.08a1.77,1.77,0,0,1,.29,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,12a1.57,1.57,0,0,0-.12-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.41.41,0,0,1-.09-.53.39.39,0,0,1,.56.09A1.68,1.68,0,0,1,63.28,12a.12.12,0,0,1-.12.12A.13.13,0,0,1,63,12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.92,13.61a1.57,1.57,0,0,0,0-1c-.08-.15-.26-.26-.4-.17s-.1.34,0,.49a.4.4,0,0,1,0-.54c.18-.15.43,0,.54.15a1.65,1.65,0,0,1,.16,1.15.14.14,0,0,1-.14.11.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.5,15.37a1.52,1.52,0,0,0,.25-1c0-.16-.17-.32-.34-.26s-.18.29-.13.46a.38.38,0,0,1,.11-.52c.21-.1.42.1.48.28a1.69,1.69,0,0,1-.15,1.15.12.12,0,1,1-.23-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,17.51a1.52,1.52,0,0,0,.5-.9c0-.16-.09-.36-.26-.35s-.25.24-.24.42a.4.4,0,0,1,.23-.48c.23,0,.39.2.4.4a1.66,1.66,0,0,1-.44,1.07.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.27,20.15a1.56,1.56,0,0,0,.6-.84c0-.16-.05-.36-.22-.37s-.28.21-.29.39c0-.18.06-.42.28-.45s.37.24.35.44a1.69,1.69,0,0,1-.55,1,.14.14,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,22.41a1.54,1.54,0,0,0,.64-.81c0-.15,0-.36-.2-.38s-.29.2-.31.38c0-.18.08-.42.31-.44s.35.26.32.46a1.7,1.7,0,0,1-.6,1,.13.13,0,0,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.22,24.1a1.48,1.48,0,0,0,.64-.81c0-.16,0-.36-.2-.38s-.29.2-.31.38c0-.18.08-.42.31-.44s.35.26.33.45a1.7,1.7,0,0,1-.6,1,.13.13,0,0,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.91,25.31a1.52,1.52,0,0,0,.6-.84c0-.16-.06-.36-.23-.37s-.28.21-.29.39c0-.17.06-.42.29-.45s.36.24.35.44a1.74,1.74,0,0,1-.55,1,.13.13,0,0,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.31,28.45a1.49,1.49,0,0,0,.5-.9c0-.16-.09-.35-.26-.34s-.25.24-.24.42a.39.39,0,0,1,.23-.48c.23,0,.39.2.4.39a1.77,1.77,0,0,1-.44,1.08.13.13,0,1,1-.2-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.85,29.39a1.53,1.53,0,0,0,.44-.93c0-.16-.11-.35-.28-.33s-.24.25-.22.43c0-.17,0-.42.21-.49s.4.18.42.37a1.73,1.73,0,0,1-.38,1.1.12.12,0,1,1-.2-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.51,28.6a1.56,1.56,0,0,0,.35-1c0-.16-.14-.33-.31-.3s-.21.28-.18.46c0-.17,0-.42.16-.51s.42.13.46.33a1.69,1.69,0,0,1-.27,1.12.12.12,0,1,1-.22-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.08,27.11a1.58,1.58,0,0,0,.23-1c0-.16-.18-.32-.35-.26s-.17.3-.12.47c-.07-.16-.1-.41.1-.52s.43.08.49.27a1.69,1.69,0,0,1-.13,1.15.12.12,0,0,1-.16.07.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.24,23.45a1.56,1.56,0,0,0,0-1,.29.29,0,0,0-.39-.18c-.14.09-.12.32,0,.48a.4.4,0,0,1,0-.54.38.38,0,0,1,.53.18,1.73,1.73,0,0,1,.09,1.16.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.15,17.45a1.57,1.57,0,0,0-.12-1,.29.29,0,0,0-.41-.13c-.13.12-.07.34,0,.49a.4.4,0,0,1-.08-.53c.16-.17.43-.06.55.09a1.69,1.69,0,0,1,.27,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.25,15.75a1.57,1.57,0,0,0-.12-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.4.4,0,0,1-.09-.52.39.39,0,0,1,.56.09,1.65,1.65,0,0,1,.27,1.12.11.11,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.53,13.82a1.57,1.57,0,0,0-.12-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.41.41,0,0,1-.09-.53.39.39,0,0,1,.56.09,1.75,1.75,0,0,1,.27,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.22,10a1.56,1.56,0,0,0-.11-1,.29.29,0,0,0-.41-.13c-.13.11-.07.34,0,.48a.4.4,0,0,1-.08-.53.39.39,0,0,1,.55.1A1.68,1.68,0,0,1,65.46,10a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.87,9.89a1.52,1.52,0,0,0,0-1c-.07-.15-.25-.27-.39-.18s-.11.33,0,.49a.4.4,0,0,1,0-.54.39.39,0,0,1,.54.16,1.71,1.71,0,0,1,.13,1.15.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.67,10.62a1.52,1.52,0,0,0,.19-1c0-.16-.2-.31-.36-.24s-.16.3-.1.47a.4.4,0,0,1,.07-.53c.21-.11.43.07.5.25a1.71,1.71,0,0,1-.07,1.16.13.13,0,0,1-.16.08.14.14,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.37,11.76a1.53,1.53,0,0,0,.46-.93c0-.16-.11-.34-.28-.33s-.24.26-.22.44c0-.18,0-.43.2-.5s.4.19.42.38a1.7,1.7,0,0,1-.38,1.09.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.3,12.87a1.56,1.56,0,0,0,.59-.85c0-.16-.06-.36-.23-.37s-.28.22-.29.4c0-.18,0-.42.28-.46s.37.24.36.43a1.68,1.68,0,0,1-.54,1,.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.52,14.66a1.51,1.51,0,0,0,.69-.77c0-.15,0-.36-.18-.39s-.3.18-.33.35a.39.39,0,0,1,.34-.41c.23,0,.33.28.29.47a1.66,1.66,0,0,1-.66.95.13.13,0,1,1-.17-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.16,16.12a1.52,1.52,0,0,0,.77-.68c.06-.15,0-.37-.13-.41s-.32.14-.37.31c0-.17.15-.39.38-.38s.3.32.24.51a1.68,1.68,0,0,1-.77.87.12.12,0,0,1-.13-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.18,18.14a1.6,1.6,0,0,0,.83-.62c.08-.14.07-.36-.09-.42s-.33.11-.4.28c0-.17.18-.38.41-.34s.27.35.2.53a1.74,1.74,0,0,1-.84.79.12.12,0,0,1-.17-.05.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.47,23.33a1.53,1.53,0,0,0,.9-.51.29.29,0,0,0,0-.43c-.16-.07-.34.07-.43.23a.4.4,0,0,1,.46-.28c.21.08.22.37.12.54a1.68,1.68,0,0,1-.93.69.12.12,0,0,1-.16-.08.12.12,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.63,27.37a1.47,1.47,0,0,0,.92-.47c.09-.13.12-.34,0-.43s-.34.06-.44.21c.08-.15.25-.34.47-.26s.21.38.11.55a1.8,1.8,0,0,1-1,.65.13.13,0,0,1-.16-.08.12.12,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83.1,31.58A1.55,1.55,0,0,0,84,31.1c.1-.13.12-.35,0-.43s-.34.06-.43.22c.07-.16.23-.35.45-.28a.38.38,0,0,1,.12.55,1.68,1.68,0,0,1-1,.66.12.12,0,0,1-.09-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.5,34.74a1.57,1.57,0,0,0,.9-.51c.09-.14.1-.35-.05-.43s-.34.07-.43.23c.07-.16.23-.35.45-.29s.23.37.14.55a1.73,1.73,0,0,1-.93.69.11.11,0,0,1-.16-.07.11.11,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.58,36.65a1.6,1.6,0,0,0,.87-.55.3.3,0,0,0-.07-.43c-.16-.07-.33.09-.41.25.06-.16.21-.36.43-.31a.38.38,0,0,1,.16.54,1.66,1.66,0,0,1-.9.73.12.12,0,0,1-.16-.06.12.12,0,0,1,.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.35,38a1.5,1.5,0,0,0,.85-.59c.08-.14.08-.36-.09-.42s-.33.1-.4.26c.06-.16.2-.37.42-.32s.26.35.19.53a1.72,1.72,0,0,1-.87.77.12.12,0,0,1-.17-.06.12.12,0,0,1,.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.07,36.45a1.53,1.53,0,0,0,.86-.57c.08-.14.08-.36-.08-.43s-.33.1-.41.26c.06-.16.2-.37.43-.32s.25.36.17.54a1.64,1.64,0,0,1-.87.75.13.13,0,0,1-.17,0,.13.13,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.72,34.17a1.52,1.52,0,0,0,.85-.58c.08-.14.08-.36-.08-.42s-.33.09-.41.26c.06-.17.2-.37.43-.32s.25.35.17.53a1.7,1.7,0,0,1-.87.76.12.12,0,0,1-.17-.06.12.12,0,0,1,.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.53,31.55a1.5,1.5,0,0,0,.85-.59c.08-.14.07-.36-.09-.42s-.33.1-.4.27c0-.17.19-.38.42-.33s.26.35.18.53a1.66,1.66,0,0,1-.86.77.13.13,0,0,1-.17-.05.13.13,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.42,29.66a1.55,1.55,0,0,0,.86-.57c.09-.14.09-.36-.07-.43s-.33.1-.41.26c.06-.16.2-.37.43-.32s.25.36.17.54a1.68,1.68,0,0,1-.89.75.12.12,0,0,1-.16-.06.12.12,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.46,28.37a1.54,1.54,0,0,0,.88-.55c.08-.13.09-.35-.07-.42s-.33.09-.41.25c.06-.17.21-.37.44-.31a.38.38,0,0,1,.15.54,1.71,1.71,0,0,1-.9.73.14.14,0,0,1-.17-.07.13.13,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.17,27a1.52,1.52,0,0,0,.88-.54c.08-.13.1-.35-.06-.43s-.34.09-.42.25c.06-.17.22-.36.44-.3s.24.36.15.54a1.68,1.68,0,0,1-.91.71.13.13,0,0,1-.17-.06.14.14,0,0,1,.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81,25.7a1.55,1.55,0,0,0,.89-.52c.08-.14.1-.35-.06-.43s-.34.08-.42.24c.07-.16.22-.36.44-.3a.38.38,0,0,1,.15.54,1.7,1.7,0,0,1-.92.71.12.12,0,0,1-.17-.07.14.14,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.92,24.78a1.53,1.53,0,0,0,.9-.52c.08-.13.1-.34,0-.42s-.34.07-.43.23c.07-.16.23-.36.45-.29s.23.37.14.54A1.7,1.7,0,0,1,82,25a.13.13,0,0,1-.16-.07.11.11,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.77,23.41a1.53,1.53,0,0,0,.87-.55c.08-.14.09-.36-.07-.43s-.33.09-.41.25c.06-.16.21-.36.43-.31a.38.38,0,0,1,.16.54,1.66,1.66,0,0,1-.9.73.12.12,0,0,1-.16-.06.12.12,0,0,1,.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.37,26.26a1.54,1.54,0,0,0,.84-.61.3.3,0,0,0-.1-.42c-.16-.05-.32.11-.39.28,0-.17.18-.38.41-.34a.38.38,0,0,1,.19.53,1.68,1.68,0,0,1-.85.79.13.13,0,0,1-.17,0,.12.12,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.69,27.71a1.51,1.51,0,0,0,.81-.65.29.29,0,0,0-.11-.41c-.17,0-.32.12-.38.29,0-.17.17-.38.4-.35s.28.33.21.52a1.68,1.68,0,0,1-.82.82.12.12,0,0,1-.17,0,.14.14,0,0,1,.05-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.77,29.26a1.54,1.54,0,0,0,.79-.67c.06-.14,0-.36-.13-.41s-.32.13-.37.3c0-.17.16-.38.39-.36s.29.33.23.51a1.7,1.7,0,0,1-.8.85.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.77,31.44a1.49,1.49,0,0,0,.77-.69.3.3,0,0,0-.14-.41c-.17,0-.31.15-.37.32,0-.17.15-.4.38-.38s.3.32.25.5a1.73,1.73,0,0,1-.76.88.13.13,0,1,1-.14-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,33a1.49,1.49,0,0,0,.77-.69.3.3,0,0,0-.14-.41c-.17,0-.31.14-.36.31,0-.17.14-.39.38-.37s.29.32.24.5a1.71,1.71,0,0,1-.76.87.13.13,0,0,1-.18,0A.12.12,0,0,1,74,33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.11,34.35a1.57,1.57,0,0,0,.76-.7.3.3,0,0,0-.14-.41c-.17,0-.32.15-.37.32,0-.17.15-.39.38-.38s.3.32.25.51a1.67,1.67,0,0,1-.76.87.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.12,35.21a1.52,1.52,0,0,0,.78-.67c.06-.15,0-.37-.13-.42s-.32.14-.37.31c0-.17.15-.39.38-.37s.3.33.24.51a1.72,1.72,0,0,1-.78.86.13.13,0,0,1-.18,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.06,35.53a1.5,1.5,0,0,0,.82-.63c.07-.14.06-.36-.1-.42s-.33.12-.39.29c0-.17.17-.38.4-.35a.39.39,0,0,1,.21.52,1.68,1.68,0,0,1-.83.81.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.14,36.93a1.56,1.56,0,0,0,.82-.62c.07-.14.06-.36-.1-.42s-.33.12-.39.28c0-.16.18-.38.41-.34a.39.39,0,0,1,.2.52,1.78,1.78,0,0,1-.84.81.13.13,0,0,1-.12-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.86,36a1.54,1.54,0,0,0,.85-.59c.07-.14.07-.36-.09-.42s-.33.1-.4.27c0-.17.19-.38.42-.33s.26.35.18.53a1.66,1.66,0,0,1-.86.77.13.13,0,0,1-.17,0,.12.12,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.94,34.52a1.58,1.58,0,0,0,.88-.54.29.29,0,0,0-.06-.43c-.16-.07-.34.08-.42.24.07-.16.22-.36.44-.3a.38.38,0,0,1,.15.54,1.7,1.7,0,0,1-.91.72.12.12,0,0,1-.16-.06.13.13,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.12,31.52A1.51,1.51,0,0,0,82,31c.09-.14.11-.35-.05-.43s-.34.07-.42.23a.39.39,0,0,1,.44-.29c.22.08.23.37.14.55a1.69,1.69,0,0,1-.93.69.12.12,0,0,1-.16-.07.13.13,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.56,30.18a1.59,1.59,0,0,0,.88-.55c.08-.13.09-.35-.07-.42s-.33.08-.41.24c.06-.16.21-.36.43-.3a.39.39,0,0,1,.16.54,1.7,1.7,0,0,1-.91.72.12.12,0,0,1-.16-.06.13.13,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.12,27.7a1.61,1.61,0,0,0,.77-.68c.06-.15,0-.37-.14-.41s-.32.14-.37.31c0-.17.15-.39.38-.37s.3.31.25.5a1.68,1.68,0,0,1-.77.87.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.33,16a1.51,1.51,0,0,0-.09-1c-.08-.14-.27-.24-.4-.14s-.08.34,0,.49c-.12-.13-.23-.36-.08-.53a.38.38,0,0,1,.55.11A1.67,1.67,0,0,1,62.58,16a.13.13,0,0,1-.14.12.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.65,14.44a1.56,1.56,0,0,0-.15-1,.3.3,0,0,0-.42-.11c-.12.13,0,.35.07.48a.39.39,0,0,1-.11-.52c.16-.17.43-.07.56.07a1.73,1.73,0,0,1,.3,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.23,9a1.48,1.48,0,0,0-.1-1c-.09-.14-.28-.24-.41-.13s-.07.34,0,.48c-.12-.13-.24-.35-.09-.53a.4.4,0,0,1,.56.1,1.68,1.68,0,0,1,.25,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.79,9.75a1.54,1.54,0,0,0-.13-1c-.09-.13-.28-.23-.42-.12s-.06.34.06.48a.39.39,0,0,1-.1-.52c.16-.17.43-.07.56.08A1.66,1.66,0,0,1,34,9.77a.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.2,12.66a1.48,1.48,0,0,0-.13-1c-.09-.13-.28-.23-.41-.12s-.06.34.05.48a.4.4,0,0,1-.1-.52c.16-.17.43-.07.56.08a1.66,1.66,0,0,1,.28,1.12.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.21,18.14a1.54,1.54,0,0,0-.29-1c-.11-.12-.32-.18-.43,0s0,.35.13.47c-.14-.11-.3-.31-.18-.51s.41-.13.56,0a1.73,1.73,0,0,1,.46,1.06.12.12,0,0,1-.11.14.12.12,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.92,21.74a1.51,1.51,0,0,0-.59-.84c-.14-.08-.36-.08-.43.08s.11.34.27.41c-.16-.06-.37-.2-.33-.42s.36-.26.54-.19a1.72,1.72,0,0,1,.77.87.11.11,0,0,1-.06.16.11.11,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.9,27.72a1.48,1.48,0,0,0-.75-.71c-.15-.05-.36,0-.39.16s.16.31.34.35c-.17,0-.4-.12-.41-.35s.3-.33.49-.29a1.72,1.72,0,0,1,.93.7.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,31.12a1.51,1.51,0,0,0-.77-.69c-.15,0-.36,0-.39.18s.18.3.35.33c-.17,0-.4-.1-.41-.33s.28-.34.47-.3a1.66,1.66,0,0,1,1,.66.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,37.61a1.58,1.58,0,0,0-.8-.67c-.15,0-.36,0-.38.2s.19.29.36.32c-.17,0-.41-.09-.42-.33s.27-.34.46-.31a1.69,1.69,0,0,1,1,.63.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.32,43.19a1.53,1.53,0,0,0-.8-.65c-.16,0-.36,0-.38.2s.19.29.37.31c-.18,0-.41-.08-.43-.31s.26-.35.45-.32a1.66,1.66,0,0,1,1,.61.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.62,47.14a1.49,1.49,0,0,0-.79-.68c-.15,0-.36,0-.38.19s.18.29.36.32c-.18,0-.41-.09-.43-.33s.28-.33.47-.3a1.62,1.62,0,0,1,1,.65.12.12,0,1,1-.18.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.71,49.74A1.56,1.56,0,0,0,10,49c-.16,0-.36,0-.39.18s.17.3.35.34c-.18,0-.41-.11-.42-.34s.29-.33.48-.3a1.76,1.76,0,0,1,.95.67.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.77,52.15a1.54,1.54,0,0,0-.74-.71c-.16,0-.37,0-.4.16s.17.31.34.35c-.17,0-.4-.12-.4-.35a.38.38,0,0,1,.48-.29A1.69,1.69,0,0,1,11,52a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.83,58.11a1.51,1.51,0,0,0-.69-.76.3.3,0,0,0-.41.14c0,.17.15.31.32.36-.17,0-.4-.14-.38-.38a.38.38,0,0,1,.5-.24,1.64,1.64,0,0,1,.88.76.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,61.74a1.53,1.53,0,0,0-.65-.8c-.15-.07-.36,0-.41.12s.12.32.29.38c-.17,0-.38-.17-.36-.4s.34-.28.52-.22a1.68,1.68,0,0,1,.83.81.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.79,67.34a1.54,1.54,0,0,0-.56-.87.31.31,0,0,0-.43.07c-.06.17.1.34.26.41-.16,0-.37-.2-.31-.43s.36-.24.53-.16a1.68,1.68,0,0,1,.75.89.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.4,71.88a1.5,1.5,0,0,0-.5-.9c-.13-.09-.35-.11-.43,0s.07.34.23.43c-.16-.07-.35-.23-.28-.45s.37-.22.54-.13a1.69,1.69,0,0,1,.68.94.11.11,0,0,1-.07.16.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.31,74.74a1.57,1.57,0,0,0-.57-.86c-.14-.08-.36-.08-.43.08s.1.34.27.41c-.17-.06-.37-.2-.33-.43s.36-.25.54-.17a1.68,1.68,0,0,1,.76.88.12.12,0,0,1-.06.16.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.77,76.56a1.56,1.56,0,0,0-.63-.83.29.29,0,0,0-.41.11c-.06.16.11.32.28.39-.17,0-.38-.18-.35-.41s.35-.27.53-.2a1.66,1.66,0,0,1,.8.83.11.11,0,0,1,0,.17.12.12,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.71,79.32a1.51,1.51,0,0,0-.69-.76.3.3,0,0,0-.41.14c0,.17.15.31.32.36-.17,0-.39-.14-.38-.37a.38.38,0,0,1,.51-.25,1.7,1.7,0,0,1,.87.76.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.75,81.71A1.56,1.56,0,0,0,19,81a.3.3,0,0,0-.4.17c0,.17.17.3.35.34-.17,0-.41-.12-.41-.35s.29-.32.49-.28a1.69,1.69,0,0,1,.93.69.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.08,85.49a1.54,1.54,0,0,0-.81-.64c-.16,0-.36,0-.38.21s.2.29.38.31c-.18,0-.42-.08-.44-.31s.26-.35.45-.33a1.76,1.76,0,0,1,1,.6.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,88.16a1.56,1.56,0,0,0-.84-.6c-.16,0-.36,0-.37.23s.21.27.39.28c-.18,0-.42,0-.45-.28s.24-.36.44-.35a1.69,1.69,0,0,1,1,.55.12.12,0,1,1-.17.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.58,89.58a1.51,1.51,0,0,0-.85-.59c-.16,0-.36.05-.36.22s.21.28.39.29c-.18,0-.42,0-.46-.28s.25-.37.44-.36a1.69,1.69,0,0,1,1,.55.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.37,89a1.52,1.52,0,0,0-.86-.58.29.29,0,0,0-.36.23c0,.17.21.27.39.28a.39.39,0,0,1-.45-.28c0-.23.24-.37.43-.36a1.68,1.68,0,0,1,1,.54.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.64,89.86a1.52,1.52,0,0,0-.86-.58c-.16,0-.36.06-.36.23s.21.27.39.28a.39.39,0,0,1-.45-.28c0-.23.23-.36.43-.36a1.7,1.7,0,0,1,1,.54.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.31,91.06a1.6,1.6,0,0,0-.79-.67c-.16,0-.36,0-.39.2s.19.29.37.32c-.18,0-.41-.09-.43-.33s.27-.34.47-.31a1.69,1.69,0,0,1,1,.64.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.14,94.08a1.53,1.53,0,0,0-.7-.76c-.15-.06-.36,0-.4.14s.14.32.32.37c-.18,0-.4-.15-.39-.38s.32-.3.51-.25a1.75,1.75,0,0,1,.88.75.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.3,94.78a1.53,1.53,0,0,0-.61-.83c-.14-.07-.36-.07-.42.1s.11.33.28.39c-.17,0-.38-.18-.34-.41a.38.38,0,0,1,.52-.2,1.74,1.74,0,0,1,.8.85.13.13,0,1,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.85,96.44a1.48,1.48,0,0,0-.55-.87.28.28,0,0,0-.42.07c-.07.16.08.33.25.41-.17-.06-.37-.21-.31-.44s.36-.24.54-.15a1.72,1.72,0,0,1,.73.89.13.13,0,1,1-.23.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.86,98.32a1.53,1.53,0,0,0-.46-.92c-.13-.1-.34-.13-.43,0s.05.35.21.44c-.16-.08-.34-.25-.27-.46a.39.39,0,0,1,.56-.11,1.71,1.71,0,0,1,.63,1,.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.19,100.8a1.54,1.54,0,0,0-.26-1c-.11-.12-.31-.19-.43-.07s0,.35.11.47a.4.4,0,0,1-.16-.51.38.38,0,0,1,.56,0,1.75,1.75,0,0,1,.43,1.08.13.13,0,0,1-.11.14.14.14,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.11,102.84a1.51,1.51,0,0,0-.11-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.41.41,0,0,1-.09-.53.39.39,0,0,1,.56.1,1.7,1.7,0,0,1,.26,1.12.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.67,104.34a1.5,1.5,0,0,0-.1-1,.3.3,0,0,0-.41-.14c-.13.12-.07.34,0,.49-.12-.13-.23-.36-.08-.53a.38.38,0,0,1,.55.1,1.68,1.68,0,0,1,.25,1.13.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.24,103.93a1.55,1.55,0,0,0,.12-1c-.06-.15-.22-.29-.38-.22s-.14.32-.06.48a.4.4,0,0,1,0-.53c.2-.13.44,0,.52.22a1.77,1.77,0,0,1,0,1.16.13.13,0,0,1-.16.08.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.61,100.48a1.59,1.59,0,0,0,.26-1c0-.15-.18-.32-.34-.26s-.19.29-.13.46c-.07-.16-.1-.41.1-.52s.43.1.49.29a1.67,1.67,0,0,1-.16,1.14.11.11,0,0,1-.16.07.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.41,93.72a1.51,1.51,0,0,0,.37-1c0-.16-.13-.33-.31-.3s-.21.27-.18.45a.4.4,0,0,1,.17-.51c.22-.07.41.15.45.34a1.69,1.69,0,0,1-.29,1.12.12.12,0,0,1-.17.05.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.78,91.39a1.54,1.54,0,0,0,.19-1c0-.15-.2-.3-.36-.24s-.16.31-.1.48a.4.4,0,0,1,.07-.53c.21-.11.43.07.5.25A1.68,1.68,0,0,1,34,91.48a.13.13,0,1,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.82,90a1.59,1.59,0,0,0,0-1c-.07-.14-.24-.27-.39-.18s-.12.32,0,.48a.4.4,0,0,1,0-.53.39.39,0,0,1,.54.17,1.72,1.72,0,0,1,.09,1.16.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.34,88.08a1.57,1.57,0,0,0-.23-1c-.11-.12-.31-.2-.43-.08a.38.38,0,0,0,.1.48.42.42,0,0,1-.15-.52.4.4,0,0,1,.56,0,1.67,1.67,0,0,1,.4,1.09.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.06,85.75a1.58,1.58,0,0,0-.6-.84c-.14-.07-.36-.07-.42.09s.1.33.27.4A.4.4,0,0,1,28,85c.06-.22.35-.26.53-.18a1.74,1.74,0,0,1,.78.85.13.13,0,0,1-.06.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.59,82.91a1.47,1.47,0,0,0-.81-.63c-.15,0-.36,0-.38.2s.2.29.38.31c-.18,0-.41-.08-.44-.31s.26-.35.46-.33a1.73,1.73,0,0,1,1,.6.14.14,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,75.45a1.58,1.58,0,0,0-.67-.79c-.14-.06-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.16-.36-.39s.33-.29.51-.23a1.66,1.66,0,0,1,.85.8.14.14,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.25,72.13a1.52,1.52,0,0,0-.58-.85.29.29,0,0,0-.42.08c-.06.16.1.33.26.4-.16-.05-.37-.2-.32-.42a.38.38,0,0,1,.53-.18,1.68,1.68,0,0,1,.76.88.13.13,0,0,1-.05.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.1,71.45a1.54,1.54,0,0,0-.56-.87.28.28,0,0,0-.42.07c-.07.16.09.33.25.41-.17-.06-.37-.21-.31-.44s.36-.24.53-.16a1.72,1.72,0,0,1,.74.9.12.12,0,0,1-.06.16.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.87,74a1.53,1.53,0,0,0-.61-.83c-.14-.08-.36-.07-.42.09s.11.33.28.4c-.17,0-.38-.18-.34-.41s.34-.27.53-.2a1.74,1.74,0,0,1,.79.84.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.51,77a1.54,1.54,0,0,0-.68-.78c-.15-.06-.36,0-.41.13s.14.32.31.38c-.17,0-.39-.16-.37-.39s.32-.29.51-.24a1.77,1.77,0,0,1,.86.78.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.24,79.12a1.52,1.52,0,0,0-.73-.73c-.15,0-.37,0-.4.16s.16.3.33.35c-.17,0-.4-.13-.4-.36a.39.39,0,0,1,.5-.27,1.69,1.69,0,0,1,.91.71.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.83,81.49a1.51,1.51,0,0,0-.78-.68c-.16,0-.37,0-.39.19s.18.3.36.32c-.17,0-.41-.09-.42-.32s.27-.34.47-.31a1.73,1.73,0,0,1,1,.64.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.84,85.13a1.54,1.54,0,0,0-.77-.68c-.16,0-.37,0-.39.19s.18.29.36.32c-.18,0-.41-.09-.42-.33s.27-.33.47-.3A1.64,1.64,0,0,1,27,85a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.34,89.47a1.57,1.57,0,0,0-.45-.94.29.29,0,0,0-.43,0c-.08.15.05.34.2.44-.15-.08-.34-.25-.25-.47a.38.38,0,0,1,.55-.09,1.7,1.7,0,0,1,.62,1,.12.12,0,0,1-.08.15.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,93.1a1.54,1.54,0,0,0-.18-1c-.09-.13-.29-.22-.42-.1s0,.35.07.48a.41.41,0,0,1-.12-.52.38.38,0,0,1,.56.06,1.66,1.66,0,0,1,.34,1.11.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,96.94a1.57,1.57,0,0,0-.05-1c-.08-.14-.26-.25-.4-.15s-.09.33,0,.48a.4.4,0,0,1-.05-.53c.17-.16.43,0,.54.13a1.69,1.69,0,0,1,.2,1.14.12.12,0,0,1-.14.11A.11.11,0,0,1,32,97Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.61,100.94a1.54,1.54,0,0,0,.06-1c-.07-.15-.24-.28-.39-.2s-.12.33,0,.49c-.09-.15-.17-.39,0-.54a.38.38,0,0,1,.53.19,1.63,1.63,0,0,1,.08,1.15.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.84,105.08a1.53,1.53,0,0,0,0-1c-.06-.15-.23-.28-.38-.19s-.13.32,0,.48a.4.4,0,0,1,0-.53A.38.38,0,0,1,35,104a1.72,1.72,0,0,1,.08,1.16.13.13,0,0,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.18,108.26a1.48,1.48,0,0,0,.17-1c0-.15-.2-.3-.36-.23s-.16.31-.09.47A.4.4,0,0,1,38,107a.39.39,0,0,1,.51.24,1.77,1.77,0,0,1,0,1.16.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.34,110.54a1.55,1.55,0,0,0,.24-1c0-.16-.18-.31-.34-.26s-.18.3-.12.47c-.07-.16-.11-.41.09-.52s.43.09.49.27a1.68,1.68,0,0,1-.13,1.15.12.12,0,0,1-.16.07.11.11,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.2,110.82a1.53,1.53,0,0,0,.31-1c0-.16-.16-.33-.33-.28s-.2.29-.15.46a.4.4,0,0,1,.13-.52c.21-.09.42.12.47.31a1.73,1.73,0,0,1-.21,1.14.12.12,0,1,1-.22-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.25,111.46a1.56,1.56,0,0,0,.35-1c0-.16-.14-.33-.31-.3s-.21.28-.18.46a.42.42,0,0,1,.16-.52c.22-.07.41.14.45.33a1.65,1.65,0,0,1-.26,1.13.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.82,111a1.53,1.53,0,0,0,.44-.94c0-.16-.11-.34-.29-.32s-.23.25-.21.43c0-.17,0-.42.2-.49s.4.18.42.37a1.73,1.73,0,0,1-.36,1.1.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46,109.77a1.51,1.51,0,0,0,.48-.91c0-.16-.1-.35-.27-.34s-.25.25-.23.43a.4.4,0,0,1,.22-.49c.23,0,.39.19.41.39a1.71,1.71,0,0,1-.42,1.08.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,108.68a1.57,1.57,0,0,0,.56-.86c0-.16-.06-.36-.24-.36s-.27.22-.27.4c0-.18,0-.42.27-.47s.37.23.37.43a1.76,1.76,0,0,1-.52,1,.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.5,107.12a1.56,1.56,0,0,0,.64-.8c0-.16,0-.37-.21-.38s-.28.19-.3.37c0-.18.07-.41.3-.44s.35.27.33.46a1.69,1.69,0,0,1-.61,1,.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,105.5a1.56,1.56,0,0,0,.72-.74c.05-.15,0-.36-.16-.4s-.31.16-.35.34c0-.17.12-.4.36-.4s.32.3.27.49a1.68,1.68,0,0,1-.7.91.12.12,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.44,103.86a1.54,1.54,0,0,0,.79-.67c.06-.14,0-.36-.12-.41s-.33.13-.38.3c0-.17.16-.38.39-.36s.29.33.23.51a1.7,1.7,0,0,1-.8.85.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.52,100.16a1.61,1.61,0,0,0,.87-.56c.08-.14.09-.35-.07-.42s-.34.09-.42.25c.06-.17.21-.37.44-.31s.24.36.16.53a1.68,1.68,0,0,1-.9.74.12.12,0,0,1-.16-.06.13.13,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.54,98.6a1.54,1.54,0,0,0,.89-.52c.09-.14.11-.35,0-.43s-.34.08-.42.24c.06-.17.22-.36.44-.3s.23.37.14.54a1.71,1.71,0,0,1-.92.71.14.14,0,0,1-.16-.07.11.11,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.56,93.5a1.55,1.55,0,0,0,.94-.43c.1-.13.14-.34,0-.43s-.34,0-.44.19a.4.4,0,0,1,.47-.25.39.39,0,0,1,.08.56,1.71,1.71,0,0,1-1,.6.12.12,0,0,1-.15-.09.12.12,0,0,1,.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.47,90.05a1.54,1.54,0,0,0,1-.37.29.29,0,0,0,0-.43c-.14-.1-.34,0-.45.16a.42.42,0,0,1,.49-.22.39.39,0,0,1,.05.56,1.72,1.72,0,0,1-1,.55.12.12,0,0,1-.15-.09.11.11,0,0,1,.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,85.2a1.54,1.54,0,0,0,1-.37c.11-.12.16-.33,0-.43s-.35,0-.46.16a.41.41,0,0,1,.49-.22.38.38,0,0,1,0,.56,1.68,1.68,0,0,1-1,.54.12.12,0,0,1-.15-.09.13.13,0,0,1,.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.56,82.93a1.51,1.51,0,0,0,1-.38c.11-.12.16-.33,0-.43s-.35,0-.46.17c.09-.15.28-.32.49-.23a.39.39,0,0,1,0,.56,1.63,1.63,0,0,1-1,.56.13.13,0,0,1-.15-.1.12.12,0,0,1,.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.74,79.38a1.51,1.51,0,0,0,.94-.44c.1-.13.13-.34,0-.43s-.34,0-.44.2c.08-.16.25-.34.47-.26a.39.39,0,0,1,.09.56,1.75,1.75,0,0,1-1,.62.14.14,0,0,1-.16-.09.12.12,0,0,1,.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,76.46a1.51,1.51,0,0,0,.89-.51.3.3,0,0,0,0-.43c-.16-.07-.34.08-.43.23.07-.16.23-.35.45-.28A.37.37,0,0,1,59,76a1.65,1.65,0,0,1-.93.69.11.11,0,0,1-.16-.07.12.12,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,75.39a1.59,1.59,0,0,0,.89-.53.29.29,0,0,0-.06-.43c-.16-.07-.34.08-.42.24.07-.16.22-.36.44-.3a.38.38,0,0,1,.15.54,1.66,1.66,0,0,1-.92.71.12.12,0,0,1-.16-.06.12.12,0,0,1,.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.86,76.38a1.55,1.55,0,0,0,.9-.5c.09-.14.11-.35,0-.43s-.34.07-.42.23c.07-.16.23-.36.45-.29s.22.38.13.55a1.74,1.74,0,0,1-.94.68.12.12,0,0,1-.16-.07.12.12,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.41,78.31a1.57,1.57,0,0,0,.92-.47c.09-.13.12-.35,0-.43s-.34,0-.44.21c.08-.16.25-.35.47-.27s.21.38.11.55a1.75,1.75,0,0,1-1,.65.12.12,0,0,1-.16-.08.12.12,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.21,81.26a1.6,1.6,0,0,0,.94-.42c.1-.13.14-.34,0-.44s-.34,0-.44.19c.08-.15.26-.33.47-.24a.39.39,0,0,1,.08.56,1.67,1.67,0,0,1-1,.59.13.13,0,0,1-.16-.08.13.13,0,0,1,.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,84.61a1.51,1.51,0,0,0,1-.41.29.29,0,0,0,0-.43c-.15-.09-.35,0-.45.18a.4.4,0,0,1,.48-.23c.21.11.18.4.07.56a1.64,1.64,0,0,1-1,.57.12.12,0,0,1-.15-.08.13.13,0,0,1,.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.12,87.24a1.53,1.53,0,0,0,1-.41.3.3,0,0,0,0-.43c-.15-.09-.35,0-.45.18a.4.4,0,0,1,.48-.23.38.38,0,0,1,.07.55,1.66,1.66,0,0,1-1,.58A.13.13,0,0,1,53,87.4a.13.13,0,0,1,.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.88,89.39a1.52,1.52,0,0,0,.94-.43.29.29,0,0,0,0-.43c-.15-.09-.34,0-.44.19.08-.15.26-.33.47-.25a.39.39,0,0,1,.09.56,1.67,1.67,0,0,1-1,.61.12.12,0,0,1-.15-.09.12.12,0,0,1,.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.22,91.53a1.55,1.55,0,0,0,.93-.45c.09-.13.12-.35,0-.44s-.35.06-.44.21c.08-.16.24-.34.46-.26a.37.37,0,0,1,.1.55,1.72,1.72,0,0,1-1,.64.12.12,0,0,1-.15-.08.12.12,0,0,1,.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.09,94.31a1.57,1.57,0,0,0,.9-.51.29.29,0,0,0-.05-.43c-.16-.07-.34.08-.42.23.07-.16.23-.35.45-.28s.22.37.13.54a1.7,1.7,0,0,1-.94.69.14.14,0,0,1-.16-.07.14.14,0,0,1,.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.29,98.55a1.53,1.53,0,0,0,.84-.6.29.29,0,0,0-.09-.42c-.16-.06-.33.11-.4.27,0-.16.19-.37.42-.33s.26.35.19.53a1.72,1.72,0,0,1-.86.78.13.13,0,0,1-.17,0,.12.12,0,0,1,.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.53,101.34a1.58,1.58,0,0,0,.79-.67c.06-.15,0-.36-.13-.41s-.32.13-.37.3c0-.17.16-.39.39-.36s.29.32.23.51a1.72,1.72,0,0,1-.79.85.13.13,0,1,1-.14-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.11,102.49a1.54,1.54,0,0,0,.82-.63.3.3,0,0,0-.11-.42c-.17,0-.33.12-.39.29,0-.17.18-.38.41-.35s.27.34.21.52a1.74,1.74,0,0,1-.83.82.13.13,0,0,1-.17,0,.13.13,0,0,1,.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.35,97.22a1.6,1.6,0,0,0,.9-.49c.1-.13.12-.35,0-.43s-.34.06-.43.22c.07-.16.23-.35.45-.28a.38.38,0,0,1,.12.55,1.7,1.7,0,0,1-.94.67.12.12,0,0,1-.16-.08.12.12,0,0,1,.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.07,91.19a1.48,1.48,0,0,0,1-.36c.11-.12.16-.33,0-.43s-.35,0-.46.16a.4.4,0,0,1,.49-.21.38.38,0,0,1,0,.56,1.69,1.69,0,0,1-1,.53.13.13,0,0,1-.15-.09.13.13,0,0,1,.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.54,86.5a1.59,1.59,0,0,0,1-.32c.11-.11.17-.32,0-.43s-.35,0-.47.14c.1-.14.3-.3.5-.19s.15.41,0,.56a1.7,1.7,0,0,1-1,.48.12.12,0,1,1,0-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.42,81.37a1.58,1.58,0,0,0,.95-.4c.1-.13.14-.34,0-.44s-.35,0-.45.18c.09-.15.27-.32.48-.23s.18.4.07.56a1.64,1.64,0,0,1-1,.57.12.12,0,1,1-.06-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.14,77.23a1.59,1.59,0,0,0,.91-.48c.09-.13.12-.35,0-.43s-.34.06-.43.22c.08-.16.24-.35.46-.28s.21.38.12.55a1.71,1.71,0,0,1-.95.66.12.12,0,0,1-.09-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.94,74a1.58,1.58,0,0,0,.87-.56.3.3,0,0,0-.08-.43c-.16-.06-.33.1-.41.26.06-.17.21-.37.43-.32a.39.39,0,0,1,.17.54,1.7,1.7,0,0,1-.89.74.12.12,0,0,1-.17-.06.12.12,0,0,1,.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.78,70a1.49,1.49,0,0,0,.77-.69c.06-.15,0-.36-.13-.41s-.32.14-.37.32c0-.17.15-.4.38-.38s.3.32.24.51a1.68,1.68,0,0,1-.77.87.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.68,58.22a1.46,1.46,0,0,0,.43-.94c0-.16-.11-.34-.28-.32s-.24.26-.22.44c0-.17,0-.42.2-.5s.4.17.43.37a1.67,1.67,0,0,1-.36,1.1.13.13,0,0,1-.17,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.69,50.67a1.53,1.53,0,0,0,.44-.93c0-.16-.11-.35-.29-.33s-.23.26-.21.44a.4.4,0,0,1,.2-.5c.23-.05.4.18.42.37a1.67,1.67,0,0,1-.36,1.1.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.55,49.59a1.52,1.52,0,0,0,.47-.92c0-.16-.11-.35-.28-.33s-.24.25-.23.43a.4.4,0,0,1,.22-.49c.23-.05.39.18.41.38a1.68,1.68,0,0,1-.39,1.09.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.16,49.62a1.53,1.53,0,0,0,.44-.93c0-.16-.11-.35-.28-.33s-.24.26-.22.43c0-.17,0-.42.21-.49s.39.18.42.37a1.72,1.72,0,0,1-.37,1.1.14.14,0,0,1-.18,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,50.68a1.54,1.54,0,0,0,.45-.93c0-.16-.11-.35-.28-.33s-.24.25-.22.43c0-.17,0-.42.21-.49s.4.18.42.37a1.71,1.71,0,0,1-.38,1.1.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,45.91a1.48,1.48,0,0,0,.51-.89c0-.16-.08-.36-.25-.35s-.26.24-.25.42a.39.39,0,0,1,.24-.48c.23,0,.38.21.39.4a1.73,1.73,0,0,1-.45,1.07.14.14,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.91,44.81a1.6,1.6,0,0,0,.53-.88c0-.16-.08-.36-.25-.35s-.26.22-.26.4c0-.17,0-.42.25-.47s.38.22.38.41A1.65,1.65,0,0,1,69.09,45a.13.13,0,1,1-.2-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.57,42.07a1.55,1.55,0,0,0,.52-.89c0-.16-.08-.35-.25-.35s-.26.23-.26.41c0-.17,0-.42.25-.47s.38.21.38.41a1.68,1.68,0,0,1-.46,1.06.13.13,0,1,1-.2-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.62,33.65a1.49,1.49,0,0,0,.39-1c0-.15-.13-.33-.3-.3s-.22.26-.19.44c0-.17,0-.42.18-.5s.41.15.44.34a1.75,1.75,0,0,1-.31,1.12.13.13,0,0,1-.17.05.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.26,32a1.55,1.55,0,0,0,.25-1c0-.15-.17-.31-.34-.26s-.18.3-.13.47a.42.42,0,0,1,.11-.53c.21-.09.42.1.48.29a1.7,1.7,0,0,1-.14,1.15.13.13,0,0,1-.17.06.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.37,29.36a1.51,1.51,0,0,0,.12-1c-.05-.15-.21-.29-.37-.22s-.14.32-.07.48a.39.39,0,0,1,0-.53c.2-.13.43,0,.52.22a1.77,1.77,0,0,1,0,1.16.13.13,0,0,1-.16.08.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.36,28a1.53,1.53,0,0,0,.06-1c-.07-.15-.24-.28-.39-.19s-.12.32,0,.48a.39.39,0,0,1,0-.53c.19-.14.43,0,.53.18a1.72,1.72,0,0,1,.07,1.16.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.37,26.28a1.52,1.52,0,0,0,0-1c-.07-.15-.24-.28-.39-.19s-.12.33,0,.49A.41.41,0,0,1,56,25a.39.39,0,0,1,.54.17,1.78,1.78,0,0,1,.11,1.16.14.14,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.55,22.69a1.6,1.6,0,0,0-.1-1c-.09-.14-.28-.24-.42-.14s-.07.34,0,.49a.4.4,0,0,1-.08-.53.38.38,0,0,1,.55.1,1.68,1.68,0,0,1,.26,1.13.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.71,20.53a1.54,1.54,0,0,0-.12-1c-.09-.14-.28-.24-.42-.13s-.06.35,0,.49c-.12-.13-.24-.35-.09-.53s.43-.06.55.09A1.7,1.7,0,0,1,54,20.56a.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.29,19.39a1.6,1.6,0,0,0-.13-1,.3.3,0,0,0-.42-.12c-.13.11-.06.34,0,.48-.12-.13-.24-.35-.09-.53s.43-.06.55.09a1.72,1.72,0,0,1,.29,1.12.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.31,21.52a1.56,1.56,0,0,0-.11-1,.32.32,0,0,0-.42-.13c-.13.12-.06.35,0,.49-.12-.13-.24-.35-.09-.53s.43-.06.55.09a1.69,1.69,0,0,1,.27,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.85,18.68a1.48,1.48,0,0,0-.13-1,.29.29,0,0,0-.41-.13c-.13.12-.06.34,0,.48a.39.39,0,0,1-.09-.52c.16-.17.43-.06.55.09a1.72,1.72,0,0,1,.28,1.12.13.13,0,1,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.81,21.05a1.57,1.57,0,0,0-.11-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.41.41,0,0,1-.09-.53.39.39,0,0,1,.56.1,1.68,1.68,0,0,1,.25,1.13.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.31,19.34a1.51,1.51,0,0,0-.11-1,.29.29,0,0,0-.41-.12c-.13.11-.07.34,0,.48a.4.4,0,0,1-.08-.53.38.38,0,0,1,.55.1,1.63,1.63,0,0,1,.26,1.13.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.83,20.9a1.48,1.48,0,0,0-.1-1c-.08-.14-.27-.25-.41-.14s-.07.34,0,.48c-.12-.13-.23-.35-.08-.52a.37.37,0,0,1,.55.1,1.68,1.68,0,0,1,.25,1.13.13.13,0,0,1-.14.12.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,22.58a1.51,1.51,0,0,0-.08-1,.3.3,0,0,0-.41-.14c-.13.11-.08.34,0,.49a.4.4,0,0,1-.07-.53.38.38,0,0,1,.55.11,1.67,1.67,0,0,1,.23,1.13.12.12,0,0,1-.13.12A.12.12,0,0,1,57,22.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.12,20.44a1.54,1.54,0,0,0-.11-1c-.09-.14-.28-.24-.41-.13s-.07.34,0,.48c-.12-.12-.24-.35-.09-.52a.39.39,0,0,1,.56.09,1.74,1.74,0,0,1,.26,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.17,23.14a1.51,1.51,0,0,0-.09-1,.3.3,0,0,0-.41-.14c-.13.12-.07.34,0,.49-.11-.13-.22-.36-.07-.53a.38.38,0,0,1,.55.11,1.67,1.67,0,0,1,.24,1.13.14.14,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.41,23.38a1.58,1.58,0,0,0,0-1c-.08-.14-.26-.25-.4-.15s-.09.33,0,.48a.4.4,0,0,1,0-.53c.17-.16.43,0,.54.13a1.7,1.7,0,0,1,.2,1.14.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.35,20.39a1.54,1.54,0,0,0-.12-1c-.09-.14-.28-.24-.41-.13s-.07.34,0,.48a.41.41,0,0,1-.09-.53.4.4,0,0,1,.56.1,1.75,1.75,0,0,1,.27,1.13.13.13,0,0,1-.13.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.64,21.5a1.56,1.56,0,0,0-.11-1c-.09-.14-.28-.24-.41-.13s-.07.34,0,.48a.4.4,0,0,1-.09-.52.39.39,0,0,1,.56.09,1.74,1.74,0,0,1,.26,1.13.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.52,22a1.57,1.57,0,0,0-.08-1c-.08-.13-.27-.24-.41-.13s-.08.33,0,.48a.39.39,0,0,1-.07-.53.38.38,0,0,1,.55.11A1.76,1.76,0,0,1,57.77,22a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.38,23.65a1.57,1.57,0,0,0-.09-1,.3.3,0,0,0-.41-.14c-.13.12-.07.34,0,.49-.11-.13-.22-.36-.07-.53a.38.38,0,0,1,.55.11,1.61,1.61,0,0,1,.23,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.81,19.26a1.57,1.57,0,0,0-.12-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48a.41.41,0,0,1-.09-.53.39.39,0,0,1,.56.09,1.69,1.69,0,0,1,.27,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.36,24.33a1.57,1.57,0,0,0,0-1c-.08-.14-.27-.26-.41-.16s-.08.34,0,.49c-.11-.14-.22-.36-.06-.53a.38.38,0,0,1,.55.12,1.79,1.79,0,0,1,.2,1.15.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.22,24.44a1.63,1.63,0,0,0-.07-1c-.08-.14-.27-.25-.41-.14s-.08.33,0,.48c-.11-.13-.22-.36-.06-.53a.39.39,0,0,1,.55.12,1.69,1.69,0,0,1,.21,1.14.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,17a1.53,1.53,0,0,0-.15-1c-.1-.14-.29-.23-.42-.11s-.05.34.06.48c-.12-.13-.25-.34-.11-.53a.39.39,0,0,1,.56.08,1.65,1.65,0,0,1,.31,1.11.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,7.42a1.57,1.57,0,0,0-.08-1c-.08-.13-.27-.24-.41-.14s-.08.34,0,.49a.39.39,0,0,1-.07-.53.38.38,0,0,1,.55.11,1.76,1.76,0,0,1,.23,1.14.13.13,0,0,1-.14.11A.11.11,0,0,1,44,7.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.86,5.92a1.57,1.57,0,0,0-.08-1,.29.29,0,0,0-.4-.14c-.14.11-.09.34,0,.48a.4.4,0,0,1-.07-.53.38.38,0,0,1,.55.12A1.64,1.64,0,0,1,39.1,6a.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.72,4.15a1.51,1.51,0,0,0-.1-1A.29.29,0,0,0,33.21,3c-.13.11-.07.34,0,.48a.4.4,0,0,1-.08-.53.39.39,0,0,1,.55.1A1.72,1.72,0,0,1,34,4.18a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.64,3.33a1.54,1.54,0,0,0-.12-1c-.09-.14-.28-.24-.41-.13s-.07.35,0,.49c-.12-.13-.24-.35-.09-.53a.39.39,0,0,1,.56.09,1.68,1.68,0,0,1,.26,1.13.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.68,3.51a1.54,1.54,0,0,0-.12-1c-.09-.14-.28-.24-.42-.12s-.06.34.05.48c-.12-.13-.24-.35-.09-.53s.43-.06.56.09a1.75,1.75,0,0,1,.27,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.63,3.67a1.57,1.57,0,0,0-.12-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48c-.12-.13-.24-.35-.09-.53a.39.39,0,0,1,.56.09,1.75,1.75,0,0,1,.27,1.13.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.25,4.44a1.57,1.57,0,0,0-.11-1c-.09-.13-.28-.23-.41-.12s-.07.34,0,.48c-.12-.13-.24-.35-.09-.53a.39.39,0,0,1,.56.1,1.68,1.68,0,0,1,.25,1.13.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.45,6a1.51,1.51,0,0,0-.08-1A.3.3,0,0,0,20,4.86c-.13.11-.07.34,0,.48-.11-.13-.22-.35-.07-.52a.38.38,0,0,1,.55.11,1.61,1.61,0,0,1,.23,1.13.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.48,9.59a1.51,1.51,0,0,0,0-1A.29.29,0,0,0,16,8.4c-.14.1-.09.33,0,.48-.11-.14-.21-.37-.05-.53a.38.38,0,0,1,.55.14,1.67,1.67,0,0,1,.17,1.14.12.12,0,0,1-.14.11c-.06,0-.11-.07-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.23,14.78a1.52,1.52,0,0,0-.45-.93c-.13-.1-.34-.13-.43,0s.05.34.2.44c-.15-.08-.34-.25-.26-.47a.39.39,0,0,1,.55-.1,1.69,1.69,0,0,1,.63,1,.12.12,0,1,1-.23.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.14,18.76A1.48,1.48,0,0,0,9.48,18c-.14-.07-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.17-.36-.4s.33-.28.51-.22a1.6,1.6,0,0,1,.84.8.12.12,0,1,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.77,23.1A1.56,1.56,0,0,0,7,22.39c-.16-.05-.37,0-.4.17s.17.3.35.34c-.18,0-.41-.11-.41-.35s.29-.32.48-.28A1.63,1.63,0,0,1,8,23a.12.12,0,1,1-.19.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7,26.31a1.58,1.58,0,0,0-.78-.69c-.15,0-.36,0-.39.19s.18.29.36.32c-.18,0-.41-.1-.42-.33s.28-.33.48-.3a1.69,1.69,0,0,1,1,.66.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.55,30.65A1.53,1.53,0,0,0,5.77,30c-.16,0-.37,0-.39.19s.18.29.36.32c-.17,0-.41-.09-.42-.32s.27-.34.47-.31a1.64,1.64,0,0,1,1,.64.13.13,0,0,1-.19.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.32,33.11a1.58,1.58,0,0,0-.79-.66.3.3,0,0,0-.39.19c0,.18.19.29.37.32-.18,0-.41-.09-.43-.32s.27-.34.47-.31a1.73,1.73,0,0,1,1,.63.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.8,34.17A1.54,1.54,0,0,0,5,33.51c-.16,0-.36,0-.38.19s.18.3.36.32c-.17,0-.41-.09-.43-.32s.28-.34.47-.31A1.69,1.69,0,0,1,6,34a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.39,35.08a1.52,1.52,0,0,0-.79-.67c-.15,0-.36,0-.38.2s.18.29.36.31a.4.4,0,0,1-.43-.32c0-.23.28-.34.47-.31a1.69,1.69,0,0,1,1,.63.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.85,34.18a1.6,1.6,0,0,0-.79-.67c-.16,0-.36,0-.39.2S6.86,34,7,34c-.18,0-.41-.1-.43-.33s.28-.34.47-.31A1.73,1.73,0,0,1,8,34a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.78,34.68A1.53,1.53,0,0,0,9,34c-.15,0-.36,0-.39.18s.19.3.36.33c-.17,0-.41-.1-.42-.33s.28-.34.47-.31a1.7,1.7,0,0,1,1,.65.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,33.23a1.51,1.51,0,0,0-.77-.69c-.15,0-.36,0-.39.18s.18.3.35.33c-.17,0-.4-.1-.41-.33s.28-.33.47-.3a1.66,1.66,0,0,1,1,.66.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.94,30.22a1.48,1.48,0,0,0-.74-.72c-.15-.05-.36,0-.4.16s.17.31.34.35c-.17,0-.4-.12-.4-.35s.3-.32.49-.28a1.7,1.7,0,0,1,.92.7.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.16,27.67a1.59,1.59,0,0,0-.66-.8c-.14-.06-.36,0-.41.12s.13.32.3.38c-.17,0-.39-.16-.36-.39s.33-.29.51-.23a1.7,1.7,0,0,1,.84.81.12.12,0,0,1,0,.17.14.14,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.23,25a1.54,1.54,0,0,0-.09-1,.3.3,0,0,0-.41-.14c-.13.12-.07.34,0,.48a.4.4,0,0,1-.07-.52c.17-.17.43-.05.55.1A1.7,1.7,0,0,1,31.48,25a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.65,26.49a1.57,1.57,0,0,0-.06-1c-.08-.14-.27-.25-.41-.15s-.08.34,0,.48a.41.41,0,0,1-.06-.53.39.39,0,0,1,.55.13,1.69,1.69,0,0,1,.21,1.14.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.45,28.31a1.46,1.46,0,0,0,0-1c-.07-.14-.25-.26-.39-.16s-.1.33,0,.48c-.11-.13-.21-.37,0-.53a.39.39,0,0,1,.54.14,1.71,1.71,0,0,1,.16,1.15.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.89,29.15a1.52,1.52,0,0,0,0-1c-.07-.15-.25-.27-.39-.17s-.1.33,0,.48c-.1-.13-.2-.37,0-.53a.38.38,0,0,1,.54.15,1.7,1.7,0,0,1,.15,1.15.13.13,0,0,1-.14.11.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.28,30.51a1.52,1.52,0,0,0,0-1c-.07-.14-.25-.26-.4-.17s-.1.34,0,.49a.41.41,0,0,1,0-.54c.18-.15.44,0,.55.15a1.7,1.7,0,0,1,.15,1.15.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.68,32.13a1.61,1.61,0,0,0,.11-1c-.06-.15-.22-.29-.38-.21s-.14.32-.06.48a.4.4,0,0,1,0-.53.38.38,0,0,1,.52.21,1.65,1.65,0,0,1,0,1.16.13.13,0,0,1-.15.09.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.41,33.35a1.47,1.47,0,0,0,.2-1c0-.16-.19-.31-.35-.25s-.17.31-.11.48c-.07-.16-.11-.41.08-.53s.43.07.5.25a1.71,1.71,0,0,1-.09,1.16.12.12,0,0,1-.16.07.11.11,0,0,1-.07-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.31,33.45a1.59,1.59,0,0,0,.31-1c0-.16-.16-.32-.33-.28s-.2.28-.15.46a.4.4,0,0,1,.13-.52c.22-.08.42.12.47.31a1.67,1.67,0,0,1-.22,1.14.12.12,0,0,1-.16.06.12.12,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.62,34.61a1.46,1.46,0,0,0,.43-.94c0-.16-.11-.34-.29-.32s-.23.26-.21.44c0-.17,0-.42.2-.5s.4.17.43.37a1.67,1.67,0,0,1-.36,1.1.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.52,34.74a1.48,1.48,0,0,0,.48-.91c0-.16-.1-.35-.27-.33s-.25.24-.23.42a.41.41,0,0,1,.22-.49c.23,0,.39.2.4.39a1.66,1.66,0,0,1-.41,1.08.12.12,0,1,1-.2-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.49,34.61a1.53,1.53,0,0,0,.54-.88c0-.16-.08-.36-.25-.35s-.26.23-.26.41c0-.18,0-.42.25-.47s.38.21.38.41a1.64,1.64,0,0,1-.47,1.05.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.13,34.68a1.58,1.58,0,0,0,.6-.85c0-.16,0-.36-.23-.37s-.27.21-.29.39c0-.17.06-.41.29-.45s.36.25.35.44a1.72,1.72,0,0,1-.55,1,.13.13,0,0,1-.18,0,.11.11,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.57,33.33a1.55,1.55,0,0,0,.57-.86.28.28,0,0,0-.23-.36c-.17,0-.27.22-.28.4,0-.18.05-.42.28-.46s.37.23.36.43a1.74,1.74,0,0,1-.53,1,.13.13,0,0,1-.19-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.41,35a1.51,1.51,0,0,0,.68-.78c0-.15,0-.36-.18-.39s-.3.18-.33.36c0-.17.1-.41.33-.42s.34.28.3.47a1.7,1.7,0,0,1-.65,1A.12.12,0,1,1,71.4,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,33.82A1.53,1.53,0,0,0,71.7,33a.3.3,0,0,0-.19-.39c-.17,0-.29.18-.32.36,0-.17.09-.41.32-.42s.34.28.31.47a1.68,1.68,0,0,1-.64,1A.13.13,0,0,1,71,34a.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.55,32.8a1.6,1.6,0,0,0,.66-.8c0-.16,0-.36-.2-.38s-.29.18-.31.36a.39.39,0,0,1,.32-.43c.23,0,.34.27.31.47a1.69,1.69,0,0,1-.63,1,.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.5,33a1.54,1.54,0,0,0,.73-.74c0-.15,0-.36-.16-.4s-.31.17-.35.34c0-.17.12-.4.36-.4s.31.3.27.49a1.68,1.68,0,0,1-.71.92.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
      </g>}
      {r_type_hair >= 7 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.45,53.45a3.18,3.18,0,0,1-.31-1.3.87.87,0,0,1,.19-.64c.21-.18.46,0,.62.08-.18-.09-.43-.2-.57,0a.8.8,0,0,0-.12.59,2.82,2.82,0,0,0,.41,1.18h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.53,52.4a3,3,0,0,1-.4-1.28.86.86,0,0,1,.15-.65c.19-.19.45,0,.62,0-.18-.07-.45-.17-.58,0a.79.79,0,0,0-.07.59,2.93,2.93,0,0,0,.48,1.15h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.44,54.15a3,3,0,0,1-.31-1.3.87.87,0,0,1,.19-.64c.21-.18.45,0,.62.09-.18-.1-.43-.21-.57,0a.77.77,0,0,0-.12.58,3,3,0,0,0,.4,1.18h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.2,55.58a3.16,3.16,0,0,1-.36-1.29.89.89,0,0,1,.17-.65c.2-.18.46,0,.63.06-.19-.08-.45-.18-.58,0a.78.78,0,0,0-.09.59,2.77,2.77,0,0,0,.44,1.16h0a.13.13,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.69,56.52a3.2,3.2,0,0,1-.06-1.34.93.93,0,0,1,.31-.59c.24-.13.45.07.6.2-.16-.12-.39-.28-.56-.14a.76.76,0,0,0-.22.55,2.87,2.87,0,0,0,.16,1.24h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.66,55.51a3,3,0,0,1,0-1.34.92.92,0,0,1,.34-.58c.24-.12.44.09.58.23-.15-.13-.37-.3-.55-.17a.78.78,0,0,0-.24.54,3,3,0,0,0,.11,1.24h0a.14.14,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.78,57.13a3,3,0,0,1-.24-1.31.87.87,0,0,1,.23-.63c.21-.17.45,0,.61.12-.17-.1-.42-.23-.57-.07a.81.81,0,0,0-.15.58A3.08,3.08,0,0,0,26,57h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.65,54.21a3.16,3.16,0,0,1-.21-1.32.92.92,0,0,1,.25-.62c.21-.16.45,0,.61.13-.17-.1-.42-.23-.57-.08a.77.77,0,0,0-.16.58,2.87,2.87,0,0,0,.3,1.2h0a.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.25,54.86a3,3,0,0,1-.1-1.33.88.88,0,0,1,.29-.6c.23-.14.45.06.6.18-.16-.12-.4-.27-.56-.13a.8.8,0,0,0-.21.57,2.78,2.78,0,0,0,.21,1.22h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.13,53.93a3.08,3.08,0,0,1-.39-1.27.84.84,0,0,1,.15-.65c.19-.19.45,0,.63,0-.19-.08-.45-.17-.58,0a.8.8,0,0,0-.08.59,2.92,2.92,0,0,0,.48,1.16h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.54,54.12a3.19,3.19,0,0,1,0-1.34.91.91,0,0,1,.33-.58c.24-.13.45.08.59.22-.16-.13-.38-.29-.56-.16a.77.77,0,0,0-.23.54A2.86,2.86,0,0,0,29.78,54h0a.12.12,0,0,1-.07.16.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.54,54a3,3,0,0,1-.26-1.31.86.86,0,0,1,.22-.63c.21-.17.45,0,.61.11-.17-.1-.42-.22-.57-.06a.81.81,0,0,0-.14.58,2.85,2.85,0,0,0,.36,1.19h0a.13.13,0,0,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,55.71a3,3,0,0,1-.24-1.31.87.87,0,0,1,.23-.63c.21-.17.45,0,.61.12-.17-.1-.42-.23-.57-.07a.81.81,0,0,0-.15.58,2.92,2.92,0,0,0,.34,1.2h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.4,55.46a3,3,0,0,1-.31-1.3.88.88,0,0,1,.2-.64c.2-.17.45,0,.62.09-.18-.09-.44-.21-.58,0a.84.84,0,0,0-.12.59,3.06,3.06,0,0,0,.4,1.18h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.36,54.75A3.27,3.27,0,0,1,22,53.47a.89.89,0,0,1,.16-.65c.19-.19.45,0,.62,0-.18-.08-.45-.18-.58,0a.85.85,0,0,0-.08.59,3.05,3.05,0,0,0,.47,1.16h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.36,55.69a3.2,3.2,0,0,1-.15-1.33.84.84,0,0,1,.27-.61c.22-.15.45,0,.6.15-.16-.11-.41-.25-.57-.1a.78.78,0,0,0-.18.57,3,3,0,0,0,.26,1.22h0a.12.12,0,0,1-.06.17.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,53.55a2.94,2.94,0,0,1-.33-1.29.82.82,0,0,1,.18-.64c.2-.18.45,0,.62.07-.18-.09-.44-.19-.58,0a.79.79,0,0,0-.1.59,2.88,2.88,0,0,0,.42,1.18h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.36,57.15a3,3,0,0,1-.14-1.33.91.91,0,0,1,.28-.61c.22-.15.45,0,.6.16-.16-.11-.4-.25-.56-.11a.76.76,0,0,0-.19.57,2.8,2.8,0,0,0,.24,1.22h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.12,56.9a3,3,0,0,1-.31-1.3A.87.87,0,0,1,24,55c.2-.18.45,0,.62.08-.18-.09-.44-.2-.58,0a.82.82,0,0,0-.11.58,2.82,2.82,0,0,0,.41,1.18h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,53.67a3.18,3.18,0,0,1-.24-1.32.9.9,0,0,1,.23-.62c.22-.17.46,0,.62.12-.18-.1-.43-.23-.58-.07a.75.75,0,0,0-.14.57,2.8,2.8,0,0,0,.33,1.21h0a.13.13,0,1,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.47,53.54a3,3,0,0,1-.41-1.27.83.83,0,0,1,.14-.65c.18-.2.45-.06.62,0-.18-.08-.45-.17-.58,0a.79.79,0,0,0-.06.59,2.77,2.77,0,0,0,.5,1.15h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,53.5a3,3,0,0,1-.13-1.33.89.89,0,0,1,.28-.61c.22-.15.45,0,.6.17-.16-.12-.4-.26-.57-.12a.85.85,0,0,0-.19.57,3.12,3.12,0,0,0,.24,1.22h0a.12.12,0,0,1-.06.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.18,52.28A3,3,0,0,1,20.76,51a.88.88,0,0,1,.14-.65c.19-.19.45-.06.63,0-.19-.08-.45-.16-.58,0a.75.75,0,0,0-.06.59,2.89,2.89,0,0,0,.49,1.15h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.25,51.39a3.22,3.22,0,0,1-.44-1.26.83.83,0,0,1,.14-.65c.18-.2.44-.07.62,0-.19-.07-.45-.16-.58,0a.75.75,0,0,0,0,.59,2.88,2.88,0,0,0,.51,1.14h0a.12.12,0,1,1-.19.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.53,52.34A2.87,2.87,0,0,1,26.32,51a.84.84,0,0,1,.24-.62c.21-.16.45,0,.61.13-.17-.11-.42-.24-.57-.08a.76.76,0,0,0-.16.57,2.93,2.93,0,0,0,.31,1.21h0a.11.11,0,0,1-.05.16.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.44,50.24A3.14,3.14,0,0,1,21,49a.88.88,0,0,1,.14-.65c.19-.2.45-.06.62,0-.18-.07-.45-.16-.57,0a.75.75,0,0,0-.06.59,2.86,2.86,0,0,0,.5,1.14h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,50.91a3,3,0,0,1-.29-1.31.84.84,0,0,1,.21-.63c.2-.17.45,0,.61.09-.17-.09-.43-.21-.57,0a.72.72,0,0,0-.12.58,2.87,2.87,0,0,0,.38,1.19h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.62,50.41a3.12,3.12,0,0,1-.36-1.29.82.82,0,0,1,.16-.64c.2-.19.45,0,.62.05-.18-.08-.44-.18-.57,0a.79.79,0,0,0-.09.59,2.93,2.93,0,0,0,.45,1.16h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.86,51.6a3.16,3.16,0,0,1-.09-1.33.94.94,0,0,1,.3-.6c.23-.14.45.06.6.19-.16-.12-.4-.28-.57-.14a.83.83,0,0,0-.2.56,2.82,2.82,0,0,0,.19,1.23h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.39,49A3.11,3.11,0,0,1,22,47.68a.91.91,0,0,1,.14-.66c.19-.19.45-.05.62,0-.18-.08-.45-.17-.57,0a.79.79,0,0,0-.07.59,2.86,2.86,0,0,0,.5,1.14h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.19,50A3,3,0,0,1,27,48.62a.85.85,0,0,1,.26-.61c.22-.16.45,0,.6.14-.16-.1-.41-.24-.57-.09a.83.83,0,0,0-.17.57,2.9,2.9,0,0,0,.27,1.21h0a.13.13,0,1,1-.23.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.62,48.79a3.27,3.27,0,0,1-.32-1.29.88.88,0,0,1,.2-.64c.2-.18.45,0,.62.08-.18-.09-.44-.2-.58,0a.8.8,0,0,0-.11.59,2.87,2.87,0,0,0,.41,1.18h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.83,47.34a3.27,3.27,0,0,1-.41-1.27.92.92,0,0,1,.15-.66c.19-.19.45,0,.62,0-.18-.08-.45-.17-.57,0a.75.75,0,0,0-.07.59A3,3,0,0,0,23,47.2h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.89,50a3,3,0,0,1,0-1.34.83.83,0,0,1,.33-.58c.23-.13.44.08.58.21-.15-.13-.38-.29-.55-.16a.82.82,0,0,0-.24.55A3,3,0,0,0,30.13,50h0a.12.12,0,1,1-.23.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.69,48.36A3,3,0,0,1,26.5,47a.81.81,0,0,1,.25-.62c.21-.16.45,0,.61.14-.17-.11-.42-.24-.57-.09a.81.81,0,0,0-.17.57,2.77,2.77,0,0,0,.3,1.21h0a.12.12,0,0,1-.06.17.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.56,47.38a3,3,0,0,1-.26-1.31.84.84,0,0,1,.22-.63c.21-.17.45,0,.61.11-.17-.1-.43-.22-.57-.06a.82.82,0,0,0-.14.58,2.94,2.94,0,0,0,.36,1.2h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.85,48.45a3,3,0,0,1-.07-1.33.86.86,0,0,1,.31-.59c.23-.14.44.06.59.19-.16-.12-.39-.28-.56-.14a.81.81,0,0,0-.22.56,3,3,0,0,0,.19,1.23h0a.14.14,0,0,1-.07.17.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.53,46.19a3.13,3.13,0,0,1-.33-1.3.9.9,0,0,1,.19-.64c.2-.18.45,0,.62.08-.18-.09-.44-.2-.58,0a.78.78,0,0,0-.1.59,3,3,0,0,0,.41,1.17h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.1,48.67a3,3,0,0,1,0-1.33.9.9,0,0,1,.36-.57c.24-.11.44.11.57.25-.15-.14-.36-.31-.54-.19a.78.78,0,0,0-.27.53,3.08,3.08,0,0,0,.07,1.25h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.8,45a3,3,0,0,1-.36-1.29.84.84,0,0,1,.16-.65c.2-.18.46,0,.63.06-.19-.08-.45-.18-.58,0a.79.79,0,0,0-.09.59A2.79,2.79,0,0,0,24,44.85h0A.12.12,0,0,1,24,45a.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.9,46.7a3,3,0,0,1-.1-1.33.82.82,0,0,1,.29-.6c.22-.15.45,0,.6.17-.17-.11-.4-.26-.57-.12a.82.82,0,0,0-.2.56,2.86,2.86,0,0,0,.22,1.23h0a.13.13,0,0,1-.07.17.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.76,47a3,3,0,0,1,0-1.34.86.86,0,0,1,.33-.57c.24-.13.45.08.59.22-.15-.13-.38-.3-.55-.17a.83.83,0,0,0-.25.55A3.06,3.06,0,0,0,30,46.94h0a.13.13,0,1,1-.24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.22,45.13a3,3,0,0,1-.2-1.32.87.87,0,0,1,.24-.62c.22-.16.45,0,.61.13-.17-.1-.42-.23-.57-.08a.81.81,0,0,0-.16.58,2.74,2.74,0,0,0,.31,1.2h0a.12.12,0,0,1-.06.17.11.11,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.52,47a3,3,0,0,1,.08-1.33.87.87,0,0,1,.37-.55c.24-.11.44.11.57.26-.15-.14-.36-.32-.54-.21a.79.79,0,0,0-.28.53,2.78,2.78,0,0,0,0,1.25h0a.13.13,0,0,1-.09.16.13.13,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.91,45.1a3.22,3.22,0,0,1-.09-1.34.89.89,0,0,1,.3-.59c.23-.14.45,0,.6.18-.16-.12-.4-.27-.56-.13a.76.76,0,0,0-.21.56,2.85,2.85,0,0,0,.2,1.23h0a.14.14,0,0,1-.07.17.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.56,45.18a3.14,3.14,0,0,1,0-1.33.92.92,0,0,1,.34-.58c.24-.12.45.09.59.23-.15-.13-.38-.3-.55-.17a.75.75,0,0,0-.25.54,2.72,2.72,0,0,0,.11,1.24h0a.14.14,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.16,43.19a3,3,0,0,1-.26-1.31.85.85,0,0,1,.22-.64c.21-.16.45,0,.61.11-.17-.1-.43-.22-.57-.06a.83.83,0,0,0-.14.58,3,3,0,0,0,.36,1.2h0a.12.12,0,0,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.48,45a3,3,0,0,1,.1-1.33A.83.83,0,0,1,32,43.1c.24-.11.43.12.56.27-.14-.14-.35-.33-.54-.21a.79.79,0,0,0-.28.52,2.93,2.93,0,0,0,0,1.25h0a.13.13,0,0,1-.1.15.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.35,42.89a3,3,0,0,1-.1-1.33.83.83,0,0,1,.3-.6c.23-.14.45,0,.59.18-.16-.12-.39-.27-.56-.13a.77.77,0,0,0-.2.56,3,3,0,0,0,.2,1.23h0a.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,43a3,3,0,0,1,0-1.34.87.87,0,0,1,.34-.57c.24-.12.44.09.58.23-.15-.13-.37-.3-.55-.18a.88.88,0,0,0-.25.55,3.05,3.05,0,0,0,.11,1.24h0a.13.13,0,0,1-.08.16A.12.12,0,0,1,29,43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,41.54a3,3,0,0,1-.18-1.33.92.92,0,0,1,.25-.62c.22-.15.46,0,.61.15-.17-.11-.41-.25-.57-.1a.82.82,0,0,0-.17.58,2.91,2.91,0,0,0,.29,1.21h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.35,40.18a3,3,0,0,1-.2-1.32.89.89,0,0,1,.25-.62c.22-.16.45,0,.61.13-.17-.1-.42-.24-.57-.08a.75.75,0,0,0-.16.57,2.77,2.77,0,0,0,.3,1.21h0a.11.11,0,0,1-.06.16.11.11,0,0,1-.16-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.38,40.65a3.2,3.2,0,0,1-.06-1.34.9.9,0,0,1,.32-.59c.23-.13.44.07.59.2-.16-.12-.39-.28-.56-.14a.76.76,0,0,0-.22.55,2.73,2.73,0,0,0,.17,1.24h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.85,42.87A3.09,3.09,0,0,1,32,41.55a.94.94,0,0,1,.41-.53c.26-.09.43.15.55.31-.13-.15-.33-.35-.52-.25a.78.78,0,0,0-.32.51,2.75,2.75,0,0,0,0,1.24h0A.13.13,0,0,1,32,43a.14.14,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.07,40.54a3,3,0,0,1,.09-1.33.85.85,0,0,1,.37-.55c.25-.11.44.11.57.26-.15-.14-.36-.32-.54-.21a.79.79,0,0,0-.28.53,2.93,2.93,0,0,0,0,1.25h0a.13.13,0,0,1-.09.15.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.5,41.46a3.14,3.14,0,0,1,.15-1.33.85.85,0,0,1,.4-.53c.25-.1.43.14.55.29-.13-.15-.34-.34-.53-.23a.76.76,0,0,0-.3.51,2.78,2.78,0,0,0,0,1.25h0a.13.13,0,0,1-.1.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.11,37.88A3.15,3.15,0,0,1,26,36.55a.9.9,0,0,1,.31-.6c.23-.13.44.07.59.2-.16-.12-.39-.28-.56-.14a.75.75,0,0,0-.21.55,2.88,2.88,0,0,0,.18,1.24h0a.12.12,0,0,1-.07.16.11.11,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.54,38.83a3,3,0,0,1,0-1.33.87.87,0,0,1,.34-.57c.24-.13.44.09.58.23-.15-.13-.37-.3-.55-.18a.79.79,0,0,0-.25.54,2.71,2.71,0,0,0,.1,1.24h0a.13.13,0,0,1-.24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.47,40.75a3.19,3.19,0,0,1,.31-1.3.85.85,0,0,1,.45-.48c.26-.07.42.18.52.35-.12-.16-.3-.38-.5-.29a.79.79,0,0,0-.36.47,2.94,2.94,0,0,0-.17,1.24h0a.12.12,0,0,1-.11.14.14.14,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.83,41.84a3.19,3.19,0,0,1,.32-1.3c.11-.19.23-.41.47-.47s.41.19.51.36c-.11-.17-.29-.38-.49-.3a.77.77,0,0,0-.37.47,2.89,2.89,0,0,0-.19,1.23h0A.14.14,0,0,1,35,42a.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.1,38.54a3.12,3.12,0,0,1,.14-1.33.88.88,0,0,1,.4-.54c.25-.1.43.13.56.29-.14-.15-.35-.34-.53-.23s-.25.32-.31.51a2.93,2.93,0,0,0,0,1.25h0a.13.13,0,0,1-.1.15.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.54,42.17a3.08,3.08,0,0,1,.27-1.31.9.9,0,0,1,.45-.49c.26-.08.42.17.53.34-.13-.16-.31-.37-.51-.28a.79.79,0,0,0-.35.48,2.78,2.78,0,0,0-.14,1.24h0a.12.12,0,0,1-.11.14.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.45,37.34A3,3,0,0,1,27.52,36a.82.82,0,0,1,.36-.56c.24-.12.44.1.57.25-.15-.14-.36-.32-.54-.2a.8.8,0,0,0-.27.53,2.91,2.91,0,0,0,.06,1.25h0a.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.07,40.81a3.05,3.05,0,0,1,.24-1.31.9.9,0,0,1,.44-.5c.26-.08.42.16.53.32-.12-.15-.31-.36-.51-.27a.83.83,0,0,0-.34.5,2.91,2.91,0,0,0-.12,1.24h0a.13.13,0,0,1-.11.14.12.12,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.61,39.54a2.93,2.93,0,0,1,.26-1.31.83.83,0,0,1,.44-.5c.26-.07.42.17.53.34-.13-.16-.32-.37-.51-.28a.78.78,0,0,0-.35.49,2.77,2.77,0,0,0-.12,1.24h0a.12.12,0,0,1-.11.14.12.12,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.27,39.32A3.1,3.1,0,0,1,30.46,38a.87.87,0,0,1,.42-.52c.25-.09.43.15.54.31-.13-.15-.33-.35-.52-.25a.79.79,0,0,0-.32.5,2.91,2.91,0,0,0-.07,1.24h0a.14.14,0,0,1-.11.15.13.13,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.11,40.24A3,3,0,0,1,35.48,39,.84.84,0,0,1,36,38.5c.26-.06.4.2.5.37-.11-.16-.28-.39-.49-.31s-.3.27-.38.45a2.78,2.78,0,0,0-.23,1.23h0a.14.14,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.46,39.26A3,3,0,0,1,33.79,38a.92.92,0,0,1,.47-.48c.27-.06.41.2.51.37-.11-.17-.29-.38-.49-.31a.77.77,0,0,0-.37.47,2.77,2.77,0,0,0-.2,1.23h0a.13.13,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.21,37.68a3.16,3.16,0,0,1,.28-1.3c.11-.19.22-.42.46-.49s.41.17.52.34c-.12-.16-.31-.37-.51-.28a.77.77,0,0,0-.35.48,2.88,2.88,0,0,0-.15,1.23h0a.12.12,0,0,1-.11.14.13.13,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.26,36.73a3.08,3.08,0,0,1,.2-1.32.87.87,0,0,1,.42-.52c.26-.09.43.15.54.31-.13-.15-.32-.35-.52-.25a.74.74,0,0,0-.32.5,2.89,2.89,0,0,0-.07,1.24h0a.13.13,0,0,1-.1.14.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.14,37.57a3,3,0,0,1,.36-1.29.84.84,0,0,1,.48-.46c.27-.06.41.2.5.37-.11-.17-.28-.39-.48-.31s-.3.27-.39.46a2.87,2.87,0,0,0-.22,1.22h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,38a3.07,3.07,0,0,1,.41-1.27.89.89,0,0,1,.5-.45c.26,0,.39.22.49.39-.11-.17-.27-.4-.48-.33a.81.81,0,0,0-.4.45A3,3,0,0,0,35.23,38h0a.12.12,0,0,1-.12.13A.14.14,0,0,1,35,38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.3,35.17a3,3,0,0,1,.19-1.32.87.87,0,0,1,.41-.53c.25-.09.43.15.55.3-.14-.14-.34-.34-.53-.24a.78.78,0,0,0-.32.51,2.89,2.89,0,0,0-.05,1.24h0a.12.12,0,0,1-.1.14.12.12,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.42,35.5a3.06,3.06,0,0,1,.29-1.31.88.88,0,0,1,.46-.49c.26-.07.41.19.52.35-.12-.16-.31-.37-.51-.29a.79.79,0,0,0-.35.48,2.93,2.93,0,0,0-.16,1.24h0a.14.14,0,0,1-.11.14.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.34,35.92a3,3,0,0,1,.36-1.29c.12-.18.25-.4.48-.46s.41.21.5.38c-.11-.17-.28-.39-.48-.32a.77.77,0,0,0-.38.46,2.76,2.76,0,0,0-.23,1.22h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,35.89a3.13,3.13,0,0,1,.42-1.27.87.87,0,0,1,.5-.44c.27,0,.4.22.48.4-.1-.18-.26-.41-.47-.34a.79.79,0,0,0-.4.44,2.9,2.9,0,0,0-.28,1.22h0a.12.12,0,0,1-.12.13A.13.13,0,0,1,34,35.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.89,33.39a2.93,2.93,0,0,1,.2-1.32.87.87,0,0,1,.42-.52c.25-.09.42.15.54.31-.13-.15-.33-.35-.52-.25a.74.74,0,0,0-.32.5,2.75,2.75,0,0,0-.08,1.24h0a.12.12,0,0,1-.1.14.12.12,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.85,36.2a3.09,3.09,0,0,1,.46-1.26.84.84,0,0,1,.52-.42c.26,0,.38.23.47.41-.1-.18-.25-.41-.46-.35s-.32.25-.42.42a2.91,2.91,0,0,0-.32,1.21h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.63,33.59a3,3,0,0,1,.3-1.3.9.9,0,0,1,.45-.49c.26-.07.42.19.52.35-.12-.16-.3-.37-.5-.29a.85.85,0,0,0-.36.48,2.91,2.91,0,0,0-.16,1.24h0a.12.12,0,0,1-.11.14.14.14,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.09,34.19a3,3,0,0,1,.39-1.28.9.9,0,0,1,.49-.45c.26-.05.4.21.49.39-.11-.17-.27-.4-.48-.33s-.3.27-.39.45a2.93,2.93,0,0,0-.25,1.22h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.17,33.16a3.11,3.11,0,0,1,.37-1.28.88.88,0,0,1,.49-.46c.26-.06.4.21.5.38-.11-.17-.28-.39-.49-.32s-.3.27-.38.45a2.79,2.79,0,0,0-.24,1.23h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.88,31.89a3,3,0,0,1,.29-1.31.85.85,0,0,1,.45-.48c.26-.07.42.18.52.34-.12-.16-.3-.37-.5-.28a.79.79,0,0,0-.36.47,2.79,2.79,0,0,0-.15,1.24h0A.13.13,0,0,1,29,32a.12.12,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.74,34.4a3,3,0,0,1,.46-1.25.92.92,0,0,1,.52-.43c.27,0,.39.24.47.42-.1-.18-.25-.41-.46-.35s-.32.25-.42.42A2.91,2.91,0,0,0,35,34.42h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.8,31.78a3.12,3.12,0,0,1,.38-1.28c.12-.18.25-.4.49-.46s.4.21.49.39c-.1-.17-.27-.4-.48-.32a.76.76,0,0,0-.39.45,3,3,0,0,0-.24,1.22h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.77,33.38a3.09,3.09,0,0,1,.46-1.26.86.86,0,0,1,.51-.42c.27,0,.39.23.47.41-.1-.18-.25-.41-.46-.35s-.32.25-.42.43a3,3,0,0,0-.31,1.2h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.24,30.51a3,3,0,0,1,.34-1.29c.11-.19.23-.41.47-.47s.41.19.51.36c-.12-.16-.29-.38-.5-.3s-.29.27-.37.46a2.92,2.92,0,0,0-.2,1.23h0a.13.13,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.81,32.19a3.23,3.23,0,0,1,.45-1.26.91.91,0,0,1,.51-.43c.27,0,.39.23.48.41-.11-.17-.26-.41-.47-.35a.84.84,0,0,0-.41.44,2.85,2.85,0,0,0-.31,1.2h0a.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.21,33.84a3.23,3.23,0,0,1,.51-1.24.83.83,0,0,1,.53-.4c.27,0,.38.25.45.43-.09-.18-.23-.42-.44-.37s-.33.24-.44.41a3,3,0,0,0-.36,1.2h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.2,30.33a3,3,0,0,1,.42-1.27.87.87,0,0,1,.5-.44c.26,0,.39.22.48.4-.1-.17-.26-.4-.47-.34a.79.79,0,0,0-.4.44,2.76,2.76,0,0,0-.28,1.22h0a.13.13,0,0,1-.13.13.14.14,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.67,31.9a3,3,0,0,1,.5-1.24.82.82,0,0,1,.53-.41c.27,0,.38.24.46.42-.1-.17-.24-.41-.45-.36s-.33.24-.43.41a2.74,2.74,0,0,0-.36,1.2h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.14,29.11a3.15,3.15,0,0,1,.4-1.28.9.9,0,0,1,.49-.45c.27,0,.4.22.49.39-.1-.17-.27-.39-.47-.33a.81.81,0,0,0-.4.45,2.88,2.88,0,0,0-.26,1.22h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.07,28a3,3,0,0,1,.45-1.25.84.84,0,0,1,.51-.43c.27,0,.39.23.47.4-.09-.17-.25-.4-.46-.34s-.31.25-.41.43a2.86,2.86,0,0,0-.31,1.21h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.15,30.59a3.29,3.29,0,0,1,.48-1.25.89.89,0,0,1,.52-.41c.27,0,.39.24.47.42-.1-.18-.25-.42-.46-.36a.78.78,0,0,0-.42.42,2.85,2.85,0,0,0-.34,1.2h0a.13.13,0,0,1-.13.12.14.14,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.33,28.84a3.16,3.16,0,0,1,.48-1.25.91.91,0,0,1,.52-.42c.27,0,.39.24.47.42-.1-.17-.25-.41-.46-.35s-.32.24-.42.42a2.85,2.85,0,0,0-.34,1.2h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.79,29.25A3.18,3.18,0,0,1,34.3,28a.94.94,0,0,1,.53-.41c.27,0,.38.25.46.43-.09-.17-.24-.41-.45-.36a.75.75,0,0,0-.43.41A2.81,2.81,0,0,0,34,29.28h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.81,32a3.17,3.17,0,0,1,.54-1.23.9.9,0,0,1,.54-.39c.27,0,.37.26.44.44-.08-.18-.22-.42-.43-.37s-.34.22-.45.4a2.79,2.79,0,0,0-.39,1.18h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.27,29.89a3,3,0,0,1,.54-1.22.81.81,0,0,1,.54-.39c.26,0,.37.25.44.44-.09-.18-.23-.43-.44-.38a.81.81,0,0,0-.44.4,2.84,2.84,0,0,0-.39,1.18h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,26.94a3,3,0,0,1,.52-1.23.86.86,0,0,1,.53-.4c.27,0,.38.25.45.43-.09-.18-.23-.42-.44-.37s-.33.23-.44.41A2.81,2.81,0,0,0,33.21,27h0a.12.12,0,0,1-.13.12A.14.14,0,0,1,33,27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.73,30.08a3.11,3.11,0,0,1,.57-1.22.88.88,0,0,1,.54-.38c.27,0,.37.27.44.45-.09-.18-.22-.43-.43-.39a.82.82,0,0,0-.45.39A3,3,0,0,0,37,30.11h0a.14.14,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.65,27.49a3.08,3.08,0,0,1,.55-1.22.85.85,0,0,1,.54-.39c.27,0,.37.26.44.44-.08-.18-.22-.42-.43-.38a.8.8,0,0,0-.45.4,3,3,0,0,0-.4,1.18h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.25,30.86a3,3,0,0,1,.58-1.2.86.86,0,0,1,.55-.38c.27,0,.37.27.43.46-.08-.19-.21-.44-.42-.4a.83.83,0,0,0-.46.39,2.82,2.82,0,0,0-.43,1.17h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.58,25.24A3.13,3.13,0,0,1,34.12,24a.89.89,0,0,1,.54-.39c.27,0,.37.26.44.44-.08-.18-.22-.42-.43-.38a.8.8,0,0,0-.45.4,2.84,2.84,0,0,0-.39,1.18h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.64,28.23A3,3,0,0,1,37.22,27a.86.86,0,0,1,.55-.37c.27,0,.36.27.43.45-.08-.18-.21-.43-.42-.39s-.34.22-.46.39a2.77,2.77,0,0,0-.43,1.17h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,26.68a3.16,3.16,0,0,1,.58-1.21.9.9,0,0,1,.55-.38c.27,0,.37.27.43.46-.08-.18-.21-.43-.42-.39s-.34.22-.46.38a3,3,0,0,0-.43,1.17h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.3,25.33a3.14,3.14,0,0,1,.57-1.21.91.91,0,0,1,.55-.38c.27,0,.37.27.43.46-.08-.19-.21-.43-.42-.39s-.34.22-.46.38a3,3,0,0,0-.42,1.18h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.14,29.56a3,3,0,0,1,.61-1.2.87.87,0,0,1,.56-.36c.26,0,.35.28.41.46-.07-.18-.2-.43-.41-.4a.83.83,0,0,0-.47.38,3,3,0,0,0-.45,1.16h0a.13.13,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.41,23.94A3.09,3.09,0,0,1,36,22.73a.86.86,0,0,1,.55-.37c.27,0,.36.26.43.45-.08-.18-.21-.43-.42-.39s-.34.22-.46.38A2.87,2.87,0,0,0,35.66,24h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,28.08a3.08,3.08,0,0,1,.62-1.19.87.87,0,0,1,.56-.36c.27,0,.35.29.42.47-.08-.18-.2-.44-.42-.4s-.34.21-.47.37a2.93,2.93,0,0,0-.46,1.15h0a.13.13,0,0,1-.14.11.14.14,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.3,26.92a3.21,3.21,0,0,1,.61-1.19.91.91,0,0,1,.57-.36c.27,0,.35.28.41.47-.07-.19-.2-.44-.41-.4a.74.74,0,0,0-.47.37A2.92,2.92,0,0,0,38.55,27h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.8,25.48a2.93,2.93,0,0,1,.61-1.19.87.87,0,0,1,.56-.36c.27,0,.35.28.42.47-.08-.19-.2-.44-.42-.4s-.34.2-.46.37A2.79,2.79,0,0,0,38,25.53h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.44,24.05a3.13,3.13,0,0,1,.61-1.19.88.88,0,0,1,.56-.36c.27,0,.36.28.42.46-.08-.18-.2-.43-.41-.4a.79.79,0,0,0-.47.38,2.87,2.87,0,0,0-.46,1.15h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.83,22.53a3.09,3.09,0,0,1,.6-1.2A.92.92,0,0,1,38,21c.27,0,.36.28.42.46-.07-.18-.2-.43-.41-.4a.83.83,0,0,0-.47.38,3,3,0,0,0-.45,1.16h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.13,21.91a3.08,3.08,0,0,1,.62-1.19.86.86,0,0,1,.56-.35c.27,0,.36.28.42.47-.08-.19-.2-.44-.41-.41a.81.81,0,0,0-.47.37A3,3,0,0,0,38.37,22h0a.12.12,0,0,1-.14.1.11.11,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.73,25.36a3,3,0,0,1,.64-1.17.81.81,0,0,1,.57-.34c.27,0,.35.28.4.47-.07-.19-.18-.44-.4-.41a.81.81,0,0,0-.48.36A2.89,2.89,0,0,0,40,25.41h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.24,23.34a3,3,0,0,1,.63-1.18.91.91,0,0,1,.57-.34c.27,0,.35.29.41.47-.07-.18-.19-.44-.4-.41a.78.78,0,0,0-.48.36,3,3,0,0,0-.49,1.15h0a.13.13,0,0,1-.15.11.14.14,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.07,26.91a3.08,3.08,0,0,1,.65-1.16.83.83,0,0,1,.58-.34c.27,0,.34.29.4.48-.07-.19-.19-.45-.4-.42a.78.78,0,0,0-.48.36,2.77,2.77,0,0,0-.5,1.14h0a.11.11,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.07,21.91a2.92,2.92,0,0,1,.65-1.17.83.83,0,0,1,.57-.34c.27,0,.35.29.4.48-.07-.19-.18-.45-.4-.42a.81.81,0,0,0-.48.36,3.06,3.06,0,0,0-.5,1.14h0a.12.12,0,0,1-.14.1.11.11,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41,24.18A3,3,0,0,1,41.71,23a.87.87,0,0,1,.57-.34c.27,0,.35.3.4.49-.07-.19-.18-.45-.4-.42s-.35.19-.48.35a2.82,2.82,0,0,0-.51,1.13h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.26,20.9a3.21,3.21,0,0,1,.67-1.16.86.86,0,0,1,.58-.33c.27,0,.34.3.39.49-.07-.19-.18-.45-.39-.42a.77.77,0,0,0-.49.35A2.69,2.69,0,0,0,41.51,21h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42,22.39a3.11,3.11,0,0,1,.68-1.15.89.89,0,0,1,.58-.32c.27,0,.34.3.39.49-.06-.19-.17-.45-.39-.43a.79.79,0,0,0-.48.35,2.75,2.75,0,0,0-.53,1.12h0a.12.12,0,0,1-.14.1.14.14,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.77,25.83a3.11,3.11,0,0,1,.68-1.15.85.85,0,0,1,.59-.32c.27,0,.34.3.38.49-.06-.19-.17-.45-.38-.43s-.36.19-.49.34A2.79,2.79,0,0,0,43,25.89h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.91,24.06a3.14,3.14,0,0,1,.69-1.14.85.85,0,0,1,.59-.32c.27,0,.34.3.38.49-.06-.19-.17-.45-.38-.43s-.36.19-.49.34a2.77,2.77,0,0,0-.54,1.12h0a.11.11,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.45,20.79a3,3,0,0,1,.7-1.14.89.89,0,0,1,.59-.32c.27,0,.33.31.38.5-.06-.19-.17-.45-.38-.44a.86.86,0,0,0-.5.34,2.91,2.91,0,0,0-.54,1.12h0a.12.12,0,0,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.86,22.43a3,3,0,0,1,.71-1.13.82.82,0,0,1,.59-.31c.26,0,.33.31.37.5-.06-.19-.16-.46-.38-.44a.78.78,0,0,0-.49.33,2.75,2.75,0,0,0-.56,1.12h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.08,27.21a3.14,3.14,0,0,1,.69-1.14.85.85,0,0,1,.59-.32c.26,0,.33.3.38.49-.06-.19-.17-.45-.39-.43a.81.81,0,0,0-.49.34,2.89,2.89,0,0,0-.53,1.12h0a.13.13,0,0,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.65,24a3.1,3.1,0,0,1,.71-1.13.89.89,0,0,1,.59-.3c.27,0,.33.31.38.5-.06-.19-.16-.45-.38-.44a.83.83,0,0,0-.5.33,2.91,2.91,0,0,0-.56,1.11h0a.15.15,0,0,1-.16.09.14.14,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.82,25.63a3.05,3.05,0,0,1,.71-1.14.88.88,0,0,1,.59-.31c.27,0,.33.31.38.5-.06-.19-.17-.45-.38-.44a.8.8,0,0,0-.5.34,3,3,0,0,0-.56,1.11h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.4,20.72a3.27,3.27,0,0,1,.74-1.12.89.89,0,0,1,.59-.3c.27,0,.33.32.37.51-.06-.19-.15-.45-.37-.44a.75.75,0,0,0-.5.32,3,3,0,0,0-.59,1.1h0a.13.13,0,0,1-.24-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.23,21.18A3.11,3.11,0,0,1,46,20.06a.83.83,0,0,1,.59-.3c.27,0,.33.31.37.5-.06-.19-.16-.45-.37-.44a.78.78,0,0,0-.5.32,3,3,0,0,0-.58,1.11h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.79,22.69a3,3,0,0,1,.73-1.11.85.85,0,0,1,.59-.3c.27,0,.33.31.37.51-.06-.2-.15-.46-.37-.45a.81.81,0,0,0-.5.32,2.85,2.85,0,0,0-.58,1.1h0a.11.11,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.19,22.47a3.12,3.12,0,0,1,.75-1.1.88.88,0,0,1,.61-.29c.26,0,.31.32.35.51,0-.19-.14-.46-.36-.45a.79.79,0,0,0-.51.32,2.92,2.92,0,0,0-.6,1.09h0a.12.12,0,0,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.71,24.22a3,3,0,0,1,.74-1.11.85.85,0,0,1,.6-.3c.26,0,.32.32.36.51-.06-.19-.15-.45-.37-.44a.75.75,0,0,0-.5.31,2.74,2.74,0,0,0-.59,1.1h0a.13.13,0,0,1-.25-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.41,21.83a3,3,0,0,1,.76-1.1.92.92,0,0,1,.61-.28c.26,0,.31.33.35.52-.05-.19-.14-.46-.36-.45a.8.8,0,0,0-.51.3,2.88,2.88,0,0,0-.61,1.09h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.7,26a3.11,3.11,0,0,1,.73-1.12.87.87,0,0,1,.59-.3c.27,0,.33.31.37.5-.06-.19-.16-.45-.37-.44a.8.8,0,0,0-.5.33,2.85,2.85,0,0,0-.58,1.1h0a.14.14,0,0,1-.15.09A.12.12,0,0,1,46.7,26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.29,24.26a3,3,0,0,1,.76-1.1.9.9,0,0,1,.6-.29c.27,0,.32.33.36.52-.06-.2-.15-.46-.36-.45a.77.77,0,0,0-.51.31,2.81,2.81,0,0,0-.61,1.09h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.63,27.71a3.07,3.07,0,0,1,.7-1.13.86.86,0,0,1,.59-.31c.27,0,.33.3.38.5-.06-.2-.16-.46-.38-.44a.83.83,0,0,0-.5.33,2.86,2.86,0,0,0-.55,1.12h0a.13.13,0,0,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.24,26.12A3.13,3.13,0,0,1,49,25a.88.88,0,0,1,.59-.29c.27,0,.33.32.37.51-.06-.2-.15-.46-.37-.45a.78.78,0,0,0-.5.32,2.87,2.87,0,0,0-.59,1.1h0a.14.14,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.81,22.92a3.07,3.07,0,0,1,.78-1.09.82.82,0,0,1,.61-.27c.27,0,.31.32.34.52,0-.2-.13-.46-.35-.46a.76.76,0,0,0-.51.3A2.82,2.82,0,0,0,50.05,23h0a.13.13,0,0,1-.16.09.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.81,23.67a3.06,3.06,0,0,1,.76-1.1.87.87,0,0,1,.61-.28c.26,0,.31.32.35.51-.05-.19-.14-.46-.36-.45a.77.77,0,0,0-.51.31,2.77,2.77,0,0,0-.61,1.08h0a.14.14,0,0,1-.16.09.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,24.91a3.06,3.06,0,0,1,.76-1.11.83.83,0,0,1,.6-.28c.27,0,.32.32.35.51,0-.19-.14-.46-.36-.45a.77.77,0,0,0-.51.31,3,3,0,0,0-.6,1.09h0a.12.12,0,0,1-.15.08.11.11,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.85,24.79a3.14,3.14,0,0,1,.74-1.11.89.89,0,0,1,.6-.3c.27,0,.32.32.36.52,0-.2-.14-.46-.36-.45a.82.82,0,0,0-.51.31,3,3,0,0,0-.59,1.1h0a.13.13,0,0,1-.16.09.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.47,26.28a3.13,3.13,0,0,1,.74-1.12.88.88,0,0,1,.59-.29c.27,0,.33.32.37.51-.06-.19-.15-.46-.37-.45a.78.78,0,0,0-.5.32,2.74,2.74,0,0,0-.59,1.1h0a.14.14,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.61,28.25a3.19,3.19,0,0,1,.71-1.14,1,1,0,0,1,.59-.31c.27,0,.34.31.38.5-.06-.19-.16-.45-.38-.43a.83.83,0,0,0-.5.33,3,3,0,0,0-.56,1.11h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,27.83a3,3,0,0,1,.72-1.13.89.89,0,0,1,.59-.3c.27,0,.33.31.37.5-.06-.19-.15-.45-.37-.44a.83.83,0,0,0-.5.33,2.8,2.8,0,0,0-.57,1.11h0A.12.12,0,0,1,50,28a.11.11,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.86,26.41a3.22,3.22,0,0,1,.72-1.12.89.89,0,0,1,.6-.3c.26,0,.32.31.37.5-.06-.19-.16-.45-.38-.44a.78.78,0,0,0-.5.33,2.83,2.83,0,0,0-.57,1.1h0a.13.13,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.36,28.31a3.05,3.05,0,0,1,.71-1.14.87.87,0,0,1,.59-.3c.27,0,.33.31.38.5-.06-.2-.17-.46-.38-.44a.83.83,0,0,0-.5.33,3.06,3.06,0,0,0-.56,1.11h0a.12.12,0,0,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.94,27.86a3.07,3.07,0,0,1,.7-1.13.86.86,0,0,1,.6-.31c.26,0,.33.31.37.5-.06-.19-.16-.45-.38-.44a.76.76,0,0,0-.49.34,2.66,2.66,0,0,0-.56,1.11h0a.13.13,0,1,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.26,30.13A3.13,3.13,0,0,1,50,29a.89.89,0,0,1,.58-.32c.27,0,.34.3.39.49-.06-.19-.17-.45-.39-.43a.84.84,0,0,0-.49.34,2.93,2.93,0,0,0-.53,1.13h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.76,30.11A3.27,3.27,0,0,1,51.45,29a.89.89,0,0,1,.59-.32c.27,0,.33.3.38.49-.06-.19-.17-.44-.38-.43a.81.81,0,0,0-.49.35A2.71,2.71,0,0,0,51,30.17h0a.14.14,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.49,29.34a3.27,3.27,0,0,1,.69-1.15.89.89,0,0,1,.59-.32c.27,0,.33.3.38.49-.06-.19-.17-.45-.38-.43a.89.89,0,0,0-.5.34,3,3,0,0,0-.54,1.13h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.41,31.89a3,3,0,0,1,.66-1.16.86.86,0,0,1,.58-.33c.27,0,.34.29.39.48-.07-.19-.18-.45-.39-.42a.81.81,0,0,0-.49.35A2.93,2.93,0,0,0,48.65,32h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.3,30.81A3.09,3.09,0,0,1,53,29.66a.91.91,0,0,1,.58-.33c.27,0,.34.3.39.49-.06-.19-.17-.45-.39-.42s-.35.19-.48.34a2.79,2.79,0,0,0-.53,1.13h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.2,30.92a3,3,0,0,1,.66-1.16.91.91,0,0,1,.58-.33c.27,0,.35.3.4.48-.07-.18-.18-.44-.4-.42a.79.79,0,0,0-.48.35A3,3,0,0,0,54.44,31h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.07,32.46a3.2,3.2,0,0,1,.65-1.17A.88.88,0,0,1,51.3,31c.27,0,.34.29.4.48-.07-.19-.18-.45-.4-.42a.83.83,0,0,0-.48.35,2.93,2.93,0,0,0-.51,1.14h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.62,32.61a3,3,0,0,1,.65-1.16.83.83,0,0,1,.57-.34c.27,0,.35.29.4.48-.07-.19-.18-.44-.4-.42a.81.81,0,0,0-.48.36,3.06,3.06,0,0,0-.5,1.14h0a.12.12,0,0,1-.15.1.13.13,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.49,32.47a3.05,3.05,0,0,1,.65-1.17.83.83,0,0,1,.58-.34c.27,0,.34.29.4.48-.07-.19-.19-.45-.4-.42a.78.78,0,0,0-.48.36,2.77,2.77,0,0,0-.5,1.14h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.87,34.23a3,3,0,0,1,.63-1.18.86.86,0,0,1,.57-.35c.27,0,.35.28.41.47-.08-.19-.2-.44-.41-.41a.74.74,0,0,0-.47.37,2.91,2.91,0,0,0-.48,1.15h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,34.3a3,3,0,0,1,.63-1.18.86.86,0,0,1,.57-.35c.27,0,.35.28.41.47-.08-.19-.2-.44-.41-.41a.81.81,0,0,0-.47.37,2.77,2.77,0,0,0-.48,1.15h0a.13.13,0,0,1-.15.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.34,36a3,3,0,0,1,.6-1.19.88.88,0,0,1,.56-.37c.27,0,.35.28.42.47-.08-.19-.21-.44-.42-.4s-.34.21-.46.37a2.82,2.82,0,0,0-.46,1.17h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,35.81a3.09,3.09,0,0,1,.6-1.2.91.91,0,0,1,.57-.36c.26,0,.35.28.41.47-.07-.19-.2-.44-.41-.41a.83.83,0,0,0-.47.38,3,3,0,0,0-.45,1.16h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.2,34.18A3.1,3.1,0,0,1,54.83,33a.9.9,0,0,1,.57-.35c.26,0,.35.29.41.47-.08-.18-.2-.44-.41-.4a.76.76,0,0,0-.47.36,3,3,0,0,0-.48,1.15h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.65,35.17A3.08,3.08,0,0,1,53.27,34a.87.87,0,0,1,.56-.36c.27,0,.36.28.42.47-.08-.18-.2-.44-.42-.4s-.34.2-.46.37a2.81,2.81,0,0,0-.47,1.15h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.1,35.76a3.11,3.11,0,0,1,.6-1.19.83.83,0,0,1,.56-.36c.27,0,.36.28.42.46-.08-.18-.2-.43-.42-.4a.78.78,0,0,0-.46.38,3,3,0,0,0-.46,1.16h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.65,36.44a3.07,3.07,0,0,1,.59-1.2.89.89,0,0,1,.56-.36c.27,0,.36.27.42.46-.08-.18-.2-.43-.42-.4s-.34.22-.46.38a3,3,0,0,0-.45,1.16h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,38.05a3,3,0,0,1,.57-1.21.84.84,0,0,1,.55-.38c.27,0,.37.26.43.45-.08-.18-.21-.43-.42-.39s-.34.22-.46.39a2.94,2.94,0,0,0-.42,1.17h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.63,35.82a3,3,0,0,1,.6-1.19.88.88,0,0,1,.56-.37c.26,0,.35.28.42.46-.08-.18-.21-.43-.42-.39s-.34.21-.46.37a2.68,2.68,0,0,0-.45,1.16h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.88,38.09a3.14,3.14,0,0,1,.57-1.21.86.86,0,0,1,.55-.37c.27,0,.36.26.43.45-.08-.18-.22-.43-.43-.39a.78.78,0,0,0-.45.39,2.8,2.8,0,0,0-.42,1.17h0a.14.14,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.64,37.68a3,3,0,0,1,.57-1.2.86.86,0,0,1,.55-.38c.27,0,.37.27.43.46-.08-.19-.21-.44-.42-.4a.83.83,0,0,0-.46.39,2.8,2.8,0,0,0-.42,1.17h0a.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.23,37.73a3,3,0,0,1,.58-1.21.86.86,0,0,1,.55-.37c.27,0,.36.26.43.45-.09-.18-.22-.43-.43-.39s-.34.22-.45.39a2.77,2.77,0,0,0-.43,1.17h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,39.45a3,3,0,0,1,.55-1.22.88.88,0,0,1,.54-.38c.27,0,.37.26.44.44-.08-.18-.22-.43-.43-.38s-.34.23-.45.4a2.91,2.91,0,0,0-.4,1.17h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.85,38.15a3.14,3.14,0,0,1,.57-1.21.91.91,0,0,1,.55-.38c.26,0,.36.27.43.45-.08-.18-.22-.43-.43-.38s-.34.22-.45.39a2.94,2.94,0,0,0-.42,1.17h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.44,39.56A3.13,3.13,0,0,1,53,38.34a.89.89,0,0,1,.54-.39c.27,0,.37.26.45.44-.09-.18-.23-.42-.44-.38s-.33.23-.45.4a3,3,0,0,0-.4,1.18h0a.12.12,0,0,1-.13.11.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.06,39.51a3.13,3.13,0,0,1,.54-1.22.89.89,0,0,1,.54-.39c.27,0,.37.26.44.44-.08-.18-.22-.42-.43-.38s-.33.23-.45.4a3,3,0,0,0-.4,1.18h0a.11.11,0,0,1-.13.11.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.4,41.39a3,3,0,0,1,.51-1.23.86.86,0,0,1,.53-.4c.27,0,.38.25.46.43-.1-.18-.24-.42-.45-.37a.8.8,0,0,0-.44.41,2.79,2.79,0,0,0-.36,1.19h0a.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.84,41.5a3,3,0,0,1,.52-1.24.86.86,0,0,1,.53-.4c.27,0,.38.25.45.43-.09-.18-.23-.42-.44-.37s-.33.24-.44.41a3.05,3.05,0,0,0-.37,1.19h0a.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.82,40.93a3.08,3.08,0,0,1,.52-1.23.88.88,0,0,1,.54-.4c.27,0,.37.26.45.44-.09-.18-.23-.43-.44-.38s-.33.24-.44.41A2.82,2.82,0,0,0,54.07,41h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.22,42.06a3.06,3.06,0,0,1,.5-1.24.86.86,0,0,1,.53-.4c.27,0,.38.25.46.43-.1-.18-.24-.42-.45-.37s-.33.24-.43.41a2.9,2.9,0,0,0-.37,1.19h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.51,41.51A3.06,3.06,0,0,1,56,40.28a.86.86,0,0,1,.53-.4c.27,0,.38.25.45.43-.09-.18-.23-.42-.44-.37s-.33.24-.44.41a3,3,0,0,0-.37,1.19h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.06,42.64a3,3,0,0,1,.5-1.24.82.82,0,0,1,.53-.41c.26,0,.38.24.45.42-.09-.17-.23-.41-.44-.36s-.33.24-.44.41a2.86,2.86,0,0,0-.35,1.2h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.56,43.78A2.89,2.89,0,0,1,50,42.53a.82.82,0,0,1,.52-.42c.26,0,.38.24.46.42-.1-.18-.25-.41-.45-.36a.8.8,0,0,0-.43.42,3.08,3.08,0,0,0-.33,1.2h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.1,43.92a3.16,3.16,0,0,1,.48-1.25.87.87,0,0,1,.52-.42c.27,0,.38.24.46.42-.09-.18-.24-.41-.45-.36s-.32.25-.42.42a2.94,2.94,0,0,0-.34,1.21h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.82,43.91a3.06,3.06,0,0,1,.47-1.25.91.91,0,0,1,.52-.42c.27,0,.39.24.47.42-.1-.17-.25-.41-.46-.35s-.32.24-.42.42a2.7,2.7,0,0,0-.33,1.2h0c0,.07-.07.13-.13.12a.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.69,45.31a2.94,2.94,0,0,1,.45-1.26.86.86,0,0,1,.51-.42c.27,0,.39.23.47.4-.09-.17-.25-.4-.46-.34s-.31.25-.41.43a2.85,2.85,0,0,0-.31,1.2h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.72,43.69a3.16,3.16,0,0,1,.48-1.25.88.88,0,0,1,.53-.41c.27,0,.38.24.46.42-.09-.18-.24-.42-.45-.36a.77.77,0,0,0-.43.42,2.85,2.85,0,0,0-.34,1.2h0a.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.44,45.85a3.06,3.06,0,0,1,.44-1.26.9.9,0,0,1,.51-.43c.27,0,.39.23.48.41-.1-.18-.26-.41-.46-.35s-.32.25-.42.43a3,3,0,0,0-.3,1.21h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,45.19a3.09,3.09,0,0,1,.46-1.26.88.88,0,0,1,.52-.42c.26,0,.38.23.47.41-.1-.17-.25-.41-.46-.35s-.32.25-.42.43a2.86,2.86,0,0,0-.32,1.2h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.92,47.38a2.94,2.94,0,0,1,.42-1.27.87.87,0,0,1,.5-.44c.27-.05.39.22.48.39-.1-.17-.26-.4-.47-.33s-.31.26-.4.44a2.89,2.89,0,0,0-.28,1.21h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.88,45.46a3.09,3.09,0,0,1,.46-1.26.91.91,0,0,1,.52-.42c.27,0,.39.24.47.41-.1-.17-.25-.41-.46-.35s-.32.25-.42.43a2.86,2.86,0,0,0-.32,1.2h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.29,46.88a2.92,2.92,0,0,1,.44-1.26.87.87,0,0,1,.5-.44c.27,0,.39.23.48.4-.1-.17-.26-.4-.47-.34a.84.84,0,0,0-.41.44,2.87,2.87,0,0,0-.29,1.21h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.74,47.85a3,3,0,0,1,.42-1.27.87.87,0,0,1,.5-.44c.26,0,.39.22.48.4-.1-.17-.26-.4-.47-.34a.78.78,0,0,0-.4.45A2.86,2.86,0,0,0,52,47.86h0a.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.16,47.26A3.06,3.06,0,0,1,55.6,46a.82.82,0,0,1,.5-.43c.27,0,.4.22.48.4-.1-.17-.26-.4-.46-.34s-.32.25-.41.43a2.77,2.77,0,0,0-.3,1.21h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.24,49.47a3.15,3.15,0,0,1,.4-1.28.9.9,0,0,1,.49-.45c.27-.05.4.22.49.39-.1-.17-.27-.4-.47-.33s-.31.27-.4.45a2.94,2.94,0,0,0-.26,1.22h0a.12.12,0,0,1-.12.13.14.14,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.05,48.66a3,3,0,0,1,.42-1.27A.84.84,0,0,1,55,47c.26,0,.39.22.48.4-.1-.17-.27-.4-.47-.34a.79.79,0,0,0-.4.44,2.9,2.9,0,0,0-.28,1.22h0a.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.76,51a3.12,3.12,0,0,1,.38-1.28.89.89,0,0,1,.49-.46c.26,0,.4.21.49.38-.11-.16-.28-.39-.48-.32a.82.82,0,0,0-.39.46A2.86,2.86,0,0,0,51,51h0a.14.14,0,0,1-.12.13.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.66,48.3A3.06,3.06,0,0,1,57.1,47a.81.81,0,0,1,.51-.43c.26,0,.39.22.47.4-.1-.17-.25-.41-.46-.34a.76.76,0,0,0-.41.43,2.92,2.92,0,0,0-.3,1.21h0a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.89,50.49a3.3,3.3,0,0,1,.39-1.28.89.89,0,0,1,.5-.45c.26,0,.39.22.49.39-.11-.17-.28-.4-.48-.33s-.3.27-.4.45a2.93,2.93,0,0,0-.25,1.22h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.34,50.34a3.07,3.07,0,0,1,.41-1.27.84.84,0,0,1,.49-.45c.27,0,.4.22.49.39-.11-.17-.27-.4-.47-.33a.75.75,0,0,0-.4.45,2.88,2.88,0,0,0-.27,1.21h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.91,50.12a3.29,3.29,0,0,1,.42-1.27.87.87,0,0,1,.5-.44c.27,0,.4.22.49.4-.11-.18-.27-.4-.48-.34a.79.79,0,0,0-.4.44,2.9,2.9,0,0,0-.28,1.22h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.49,52.15a3.15,3.15,0,0,1,.4-1.28.87.87,0,0,1,.49-.45c.27-.05.4.22.49.39-.11-.17-.27-.39-.48-.33s-.3.27-.39.45a2.91,2.91,0,0,0-.26,1.22h0a.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.65,50a2.92,2.92,0,0,1,.44-1.26.82.82,0,0,1,.5-.43c.27,0,.39.22.48.4-.1-.17-.26-.41-.46-.34s-.32.25-.42.43A2.9,2.9,0,0,0,57.9,50h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.51,51.41A3.1,3.1,0,0,1,58,50.16a.89.89,0,0,1,.52-.43c.26,0,.38.24.47.41-.1-.17-.26-.4-.46-.34s-.32.25-.42.42a2.91,2.91,0,0,0-.32,1.21h0a.13.13,0,0,1-.13.13.14.14,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.93,52.05a2.92,2.92,0,0,1,.44-1.26.85.85,0,0,1,.5-.43c.27,0,.39.23.48.4-.1-.17-.26-.4-.46-.34s-.32.26-.42.43a2.9,2.9,0,0,0-.29,1.21h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.48,52.69a3.09,3.09,0,0,1,.43-1.27.87.87,0,0,1,.5-.44c.27,0,.39.23.48.4-.1-.17-.26-.4-.47-.34a.79.79,0,0,0-.4.44,2.77,2.77,0,0,0-.29,1.22h0a.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.5,53.78a3.09,3.09,0,0,1,.43-1.27.87.87,0,0,1,.5-.44c.27,0,.39.23.48.4-.1-.17-.26-.4-.47-.34a.79.79,0,0,0-.4.44,2.76,2.76,0,0,0-.29,1.21h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.43,54.73a3.08,3.08,0,0,1,.42-1.27.92.92,0,0,1,.51-.44c.26,0,.39.23.48.4-.1-.17-.26-.4-.47-.34a.84.84,0,0,0-.41.44,2.89,2.89,0,0,0-.28,1.21h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.48,53.52A3,3,0,0,1,58,52.27a.94.94,0,0,1,.53-.41c.27,0,.38.24.46.42-.09-.17-.24-.41-.45-.36a.77.77,0,0,0-.43.42,3,3,0,0,0-.35,1.2h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.84,54.48a3,3,0,0,1,.47-1.25.84.84,0,0,1,.52-.42c.27,0,.38.23.46.41-.09-.17-.24-.41-.45-.35a.75.75,0,0,0-.42.42,2.74,2.74,0,0,0-.33,1.2h0a.14.14,0,0,1-.13.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.66,55.68a3.06,3.06,0,0,1,.47-1.25.91.91,0,0,1,.52-.42c.27,0,.39.24.47.42-.1-.18-.25-.41-.46-.36s-.32.25-.42.43a2.7,2.7,0,0,0-.33,1.2h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,56.77a3,3,0,0,1,.47-1.25A.86.86,0,0,1,54,55.1c.27,0,.39.24.47.42-.1-.18-.25-.42-.46-.36s-.32.25-.42.43a2.83,2.83,0,0,0-.32,1.2h0a.14.14,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.9,55.67a3.06,3.06,0,0,1,.51-1.23.9.9,0,0,1,.53-.41c.27,0,.38.25.46.44-.09-.18-.24-.42-.45-.37a.76.76,0,0,0-.43.4,2.75,2.75,0,0,0-.37,1.19h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.39,56.07A3.16,3.16,0,0,1,59,54.85a.88.88,0,0,1,.54-.38c.27,0,.37.26.44.45-.08-.18-.22-.43-.43-.39a.8.8,0,0,0-.45.4,2.78,2.78,0,0,0-.41,1.17h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.69,57.58a2.85,2.85,0,0,1,.51-1.23.88.88,0,0,1,.53-.41c.27,0,.38.25.45.43-.09-.17-.23-.42-.44-.36s-.33.23-.44.41a2.79,2.79,0,0,0-.36,1.19h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.89,57.44a3,3,0,0,1,.55-1.22.89.89,0,0,1,.54-.39c.27,0,.37.26.44.45-.08-.19-.22-.43-.43-.39a.8.8,0,0,0-.45.4,2.81,2.81,0,0,0-.4,1.18h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.24,59.26A3,3,0,0,1,54.77,58a.91.91,0,0,1,.54-.4c.27,0,.38.26.45.44-.09-.18-.23-.42-.44-.37s-.33.23-.44.4a2.7,2.7,0,0,0-.39,1.18h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.17,59.05a3.11,3.11,0,0,1,.57-1.22.86.86,0,0,1,.55-.37c.27,0,.36.26.43.45-.08-.19-.21-.43-.43-.39a.78.78,0,0,0-.45.39,2.94,2.94,0,0,0-.42,1.17h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,58.61a3.08,3.08,0,0,1,.62-1.19.86.86,0,0,1,.56-.35c.27,0,.36.28.42.47-.08-.19-.2-.44-.41-.41a.81.81,0,0,0-.47.37,2.81,2.81,0,0,0-.47,1.15h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.08,59.85a3.18,3.18,0,0,1,.62-1.18.86.86,0,0,1,.57-.35c.27,0,.35.28.41.46-.08-.18-.2-.43-.41-.4a.74.74,0,0,0-.47.37,2.75,2.75,0,0,0-.47,1.15h0a.14.14,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.64,61.19A3,3,0,0,1,57.25,60a.88.88,0,0,1,.56-.36c.27,0,.36.28.42.47-.08-.19-.2-.44-.41-.41a.81.81,0,0,0-.47.37,3,3,0,0,0-.47,1.16h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.56,62.16A3,3,0,0,1,56.17,61a.88.88,0,0,1,.56-.36c.27,0,.36.28.42.46-.08-.18-.2-.43-.42-.4a.83.83,0,0,0-.46.37,2.83,2.83,0,0,0-.46,1.16h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.55,62a3.25,3.25,0,0,1,.66-1.16.88.88,0,0,1,.58-.33c.27,0,.34.29.4.48-.07-.19-.18-.44-.4-.42a.79.79,0,0,0-.48.35,2.79,2.79,0,0,0-.51,1.14h0a.11.11,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.34,63A3,3,0,0,1,60,61.89a.87.87,0,0,1,.58-.32c.27,0,.34.31.39.49-.07-.18-.17-.44-.39-.43a.84.84,0,0,0-.49.34,3,3,0,0,0-.54,1.13h0a.13.13,0,0,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.32,63.78a3.09,3.09,0,0,1,.74-1.12.84.84,0,0,1,.6-.29c.27,0,.32.32.36.51-.05-.2-.15-.46-.36-.45a.79.79,0,0,0-.51.32,3,3,0,0,0-.59,1.1h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.1,64.72a3.05,3.05,0,0,1,.69-1.15.83.83,0,0,1,.58-.32c.27,0,.34.3.39.49-.07-.19-.17-.45-.39-.43a.76.76,0,0,0-.49.34,2.73,2.73,0,0,0-.53,1.13h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.59,65.61a3.1,3.1,0,0,1,.66-1.16.83.83,0,0,1,.57-.34c.27,0,.35.29.4.48-.07-.19-.18-.44-.4-.42a.84.84,0,0,0-.48.36,2.91,2.91,0,0,0-.5,1.14h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.29,65.58A3,3,0,0,1,60,64.45a.84.84,0,0,1,.59-.31c.27,0,.33.31.37.5-.06-.19-.16-.45-.37-.44a.8.8,0,0,0-.5.33,2.78,2.78,0,0,0-.56,1.11h0a.11.11,0,0,1-.15.09.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.51,65.86a3,3,0,0,1,.68-1.14.9.9,0,0,1,.59-.33c.27,0,.33.31.38.5-.06-.19-.17-.45-.38-.43a.76.76,0,0,0-.49.34,2.91,2.91,0,0,0-.54,1.12h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.67,67.4a3.2,3.2,0,0,1,.7-1.14A.86.86,0,0,1,59,66c.27,0,.34.3.38.49-.06-.19-.16-.45-.38-.43a.76.76,0,0,0-.49.34,2.86,2.86,0,0,0-.55,1.12h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.39,66.72a3.11,3.11,0,0,1,.73-1.11.89.89,0,0,1,.6-.3c.27,0,.33.32.37.51-.06-.19-.15-.46-.37-.45a.83.83,0,0,0-.51.32,2.85,2.85,0,0,0-.58,1.1h0a.13.13,0,1,1-.25-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.51,67a3,3,0,0,1,.68-1.16.85.85,0,0,1,.58-.32c.27,0,.34.3.39.49-.07-.19-.18-.45-.39-.43a.81.81,0,0,0-.49.35,2.75,2.75,0,0,0-.53,1.12h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.91,66.64a3.05,3.05,0,0,1,.71-1.14.89.89,0,0,1,.59-.3c.27,0,.33.31.38.5-.06-.19-.16-.46-.38-.44a.83.83,0,0,0-.5.33,3,3,0,0,0-.56,1.11h0a.13.13,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.39,65A3,3,0,0,1,55,63.85a.86.86,0,0,1,.57-.35c.26,0,.35.29.4.47-.07-.18-.19-.44-.4-.41a.79.79,0,0,0-.48.37,2.91,2.91,0,0,0-.48,1.15h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.49,65.23a3,3,0,0,1,.75-1.1.88.88,0,0,1,.6-.29c.27,0,.32.32.36.51-.05-.19-.15-.46-.36-.45a.82.82,0,0,0-.51.31,2.9,2.9,0,0,0-.6,1.1h0a.13.13,0,0,1-.16.09.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.19,63.89a3.25,3.25,0,0,1,.66-1.16.92.92,0,0,1,.58-.34c.27,0,.35.3.4.49-.07-.19-.18-.45-.4-.42s-.35.19-.48.35a3,3,0,0,0-.51,1.13h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.38,62.21a3.05,3.05,0,0,1,.71-1.14.82.82,0,0,1,.59-.31c.26,0,.33.3.37.49-.06-.19-.16-.45-.38-.43a.78.78,0,0,0-.49.33,2.86,2.86,0,0,0-.55,1.12h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.94,61.64a3.15,3.15,0,0,1,.73-1.12.94.94,0,0,1,.6-.3c.27,0,.33.32.37.51-.06-.19-.16-.46-.37-.45a.85.85,0,0,0-.51.33,3,3,0,0,0-.58,1.1h0a.12.12,0,1,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.94,60.67a2.89,2.89,0,0,1,.69-1.14.89.89,0,0,1,.59-.32c.27,0,.33.31.38.5-.06-.19-.17-.45-.38-.43a.83.83,0,0,0-.5.33,2.91,2.91,0,0,0-.54,1.12h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.59,59.86a3.06,3.06,0,0,1,.73-1.13.89.89,0,0,1,.59-.3c.27,0,.33.32.37.51-.06-.19-.16-.46-.37-.44a.75.75,0,0,0-.5.32,2.85,2.85,0,0,0-.58,1.1h0a.12.12,0,1,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.16,58.33a2.89,2.89,0,0,1,.69-1.14.89.89,0,0,1,.59-.32c.27,0,.33.31.38.5-.06-.19-.17-.45-.38-.44a.89.89,0,0,0-.5.34,2.91,2.91,0,0,0-.54,1.12h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.59,57.35a3.13,3.13,0,0,1,.67-1.16.86.86,0,0,1,.57-.33c.27,0,.35.3.4.49-.07-.19-.18-.45-.4-.43a.86.86,0,0,0-.48.35,3,3,0,0,0-.52,1.14h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.45,55.71a3.16,3.16,0,0,1,.66-1.16.88.88,0,0,1,.58-.34c.27,0,.35.3.4.49-.07-.19-.18-.45-.4-.42s-.35.19-.48.35a2.84,2.84,0,0,0-.52,1.13h0a.12.12,0,0,1-.14.09.11.11,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.36,55.16A2.94,2.94,0,0,1,61,54a.83.83,0,0,1,.55-.36c.27,0,.36.27.43.45-.08-.18-.21-.43-.42-.39a.78.78,0,0,0-.46.38,3,3,0,0,0-.45,1.16h0a.12.12,0,0,1-.14.11.14.14,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,54a3.15,3.15,0,0,1,.62-1.19.86.86,0,0,1,.57-.35c.27,0,.35.28.41.47-.08-.19-.2-.44-.41-.41s-.35.21-.47.37a2.75,2.75,0,0,0-.47,1.15h0a.13.13,0,0,1-.15.1A.12.12,0,0,1,62,54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.27,52.53a3.07,3.07,0,0,1,.57-1.21.84.84,0,0,1,.55-.38c.27,0,.36.27.43.45-.08-.18-.21-.43-.43-.39a.81.81,0,0,0-.45.39,2.85,2.85,0,0,0-.42,1.17h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.59,51.5a3,3,0,0,1,.54-1.22.86.86,0,0,1,.53-.4c.27,0,.38.26.45.44-.09-.18-.23-.42-.44-.37s-.33.23-.44.4a3,3,0,0,0-.39,1.18h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.64,50.08a3.17,3.17,0,0,1,.54-1.23.88.88,0,0,1,.54-.38c.27,0,.38.25.45.44-.09-.18-.23-.43-.44-.38s-.33.23-.44.4a2.81,2.81,0,0,0-.4,1.18h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.48,49.23A3,3,0,0,1,61,48a.86.86,0,0,1,.53-.42c.27,0,.38.25.46.43-.1-.18-.24-.42-.45-.36s-.33.24-.43.41a2.86,2.86,0,0,0-.35,1.2h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.43,47.92a3.17,3.17,0,0,1,.51-1.24.86.86,0,0,1,.53-.4c.27,0,.38.25.46.43-.1-.18-.24-.42-.45-.37s-.33.24-.43.41a2.9,2.9,0,0,0-.37,1.19h0a.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.55,46.88a3.21,3.21,0,0,1,.5-1.24.89.89,0,0,1,.52-.41c.27,0,.38.25.46.43-.09-.18-.24-.42-.45-.37a.84.84,0,0,0-.43.42,3,3,0,0,0-.35,1.19h0a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,45.94a3.22,3.22,0,0,1,.53-1.23.86.86,0,0,1,.53-.4c.27,0,.38.26.45.44-.09-.18-.23-.42-.44-.37a.81.81,0,0,0-.44.4A3,3,0,0,0,62.25,46h0a.12.12,0,0,1-.13.12A.14.14,0,0,1,62,46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.7,45a3,3,0,0,1,.5-1.24.94.94,0,0,1,.53-.41c.27,0,.38.25.46.43-.09-.18-.24-.42-.45-.37a.8.8,0,0,0-.43.42A2.66,2.66,0,0,0,61,45h0a.13.13,0,0,1-.14.12A.11.11,0,0,1,60.7,45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.24,43.9a3,3,0,0,1,.53-1.23.93.93,0,0,1,.54-.39c.27,0,.38.26.45.44-.09-.18-.23-.42-.44-.38s-.33.23-.44.4a2.74,2.74,0,0,0-.39,1.19h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.08,43.05a3.09,3.09,0,0,1,.53-1.23.85.85,0,0,1,.54-.4c.26,0,.37.26.44.44-.09-.18-.23-.42-.44-.38s-.33.24-.44.41a3,3,0,0,0-.38,1.19h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.05,41.51a3,3,0,0,1,.54-1.22.85.85,0,0,1,.54-.4c.27,0,.37.26.44.44-.09-.18-.23-.42-.44-.37s-.33.23-.44.4a2.79,2.79,0,0,0-.39,1.18h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.49,39.82a3.16,3.16,0,0,1,.56-1.22.87.87,0,0,1,.55-.38c.27,0,.36.27.43.45-.08-.18-.22-.43-.43-.39s-.33.23-.45.4a2.78,2.78,0,0,0-.41,1.17h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.17,38a2.92,2.92,0,0,1,.58-1.2.88.88,0,0,1,.55-.37c.27,0,.36.27.43.46-.08-.19-.21-.44-.42-.4a.77.77,0,0,0-.46.39A2.79,2.79,0,0,0,62.41,38h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.94,36.9a3.07,3.07,0,0,1,.59-1.2.81.81,0,0,1,.56-.37c.26,0,.36.27.42.45-.08-.18-.21-.43-.42-.39a.78.78,0,0,0-.46.38,2.78,2.78,0,0,0-.44,1.17h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.38,35.57A3.21,3.21,0,0,1,63,34.38a.92.92,0,0,1,.56-.36c.27,0,.36.28.42.47-.08-.19-.2-.44-.41-.41a.79.79,0,0,0-.47.38,3,3,0,0,0-.46,1.16h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.84,34.86a3,3,0,0,1,.61-1.19.87.87,0,0,1,.56-.36c.27,0,.35.28.41.47-.07-.19-.2-.44-.41-.4s-.35.21-.47.37a3,3,0,0,0-.46,1.16h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.22,33.61a3,3,0,0,1,.62-1.18.9.9,0,0,1,.57-.35c.27,0,.35.29.41.47-.08-.18-.2-.44-.41-.41a.81.81,0,0,0-.47.37,3,3,0,0,0-.48,1.15h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.41,32.07a3.18,3.18,0,0,1,.64-1.17.88.88,0,0,1,.58-.35c.27,0,.34.29.4.48-.07-.19-.19-.44-.4-.41a.81.81,0,0,0-.48.36,2.89,2.89,0,0,0-.49,1.14h0a.13.13,0,0,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.41,32.48A3,3,0,0,1,61,31.31a.86.86,0,0,1,.57-.35c.27,0,.35.28.41.47-.08-.19-.19-.44-.41-.41a.76.76,0,0,0-.47.36,2.83,2.83,0,0,0-.48,1.15h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.61,31.38a3,3,0,0,1,.64-1.17.88.88,0,0,1,.58-.35c.26,0,.34.29.4.48-.07-.19-.19-.44-.4-.41a.81.81,0,0,0-.48.36,2.89,2.89,0,0,0-.49,1.14h0a.12.12,0,0,1-.14.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.84,30.57a3,3,0,0,1,.65-1.17.83.83,0,0,1,.57-.34c.27,0,.35.29.4.48-.07-.19-.18-.44-.4-.42a.81.81,0,0,0-.48.36,3.06,3.06,0,0,0-.5,1.14h0a.13.13,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.19,29.56a3,3,0,0,1,.66-1.16.86.86,0,0,1,.58-.33c.27,0,.34.29.39.48-.07-.19-.18-.44-.39-.42a.81.81,0,0,0-.49.35,3.08,3.08,0,0,0-.51,1.14h0a.12.12,0,0,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.27,28.43a2.91,2.91,0,0,1,.66-1.16.89.89,0,0,1,.58-.34c.27,0,.34.3.4.49-.07-.19-.18-.45-.4-.42s-.35.19-.48.35a2.84,2.84,0,0,0-.52,1.13h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.84,28.71a3,3,0,0,1,.66-1.16.91.91,0,0,1,.58-.33c.27,0,.34.29.4.48-.07-.19-.18-.44-.4-.42a.79.79,0,0,0-.48.35,3,3,0,0,0-.52,1.14h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.83,27.62a2.93,2.93,0,0,1,.67-1.16.86.86,0,0,1,.58-.33c.27,0,.34.3.39.49-.07-.19-.17-.45-.39-.42s-.36.19-.49.34a3,3,0,0,0-.52,1.13h0a.13.13,0,0,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.16,26.38a3.13,3.13,0,0,1,.67-1.16.89.89,0,0,1,.58-.32c.27,0,.34.3.39.48-.06-.18-.17-.44-.39-.42s-.35.19-.48.35a2.73,2.73,0,0,0-.53,1.13h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.21,25.17A3.11,3.11,0,0,1,59.89,24a.91.91,0,0,1,.58-.33c.27,0,.34.31.39.49-.06-.18-.17-.44-.39-.42s-.36.19-.49.34a3,3,0,0,0-.53,1.13h0a.12.12,0,1,1-.24-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.7,24.64a3,3,0,0,1,.69-1.14.89.89,0,0,1,.59-.32c.27,0,.33.3.38.49-.06-.19-.17-.45-.38-.43s-.36.19-.5.34a3.06,3.06,0,0,0-.54,1.12h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.92,23.11A3,3,0,0,1,58.61,22a.91.91,0,0,1,.58-.33c.27,0,.34.31.39.5-.06-.19-.17-.45-.39-.43a.76.76,0,0,0-.49.34,2.89,2.89,0,0,0-.53,1.12h0a.11.11,0,0,1-.14.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.51,22.42a2.89,2.89,0,0,1,.69-1.14.89.89,0,0,1,.59-.32c.27,0,.33.31.38.5-.06-.19-.17-.45-.38-.44s-.36.19-.49.34a2.73,2.73,0,0,0-.55,1.12h0a.14.14,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.32,20.69A2.91,2.91,0,0,1,58,19.54a.86.86,0,0,1,.58-.33c.27,0,.34.3.39.49-.07-.19-.18-.45-.39-.43a.81.81,0,0,0-.49.35,3,3,0,0,0-.53,1.13h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.89,20.61a3,3,0,0,1,.69-1.14.89.89,0,0,1,.59-.32c.27,0,.33.31.38.5-.06-.19-.17-.45-.38-.43a.8.8,0,0,0-.5.34,2.84,2.84,0,0,0-.54,1.12h0a.13.13,0,0,1-.15.1.14.14,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.8,19.74a3.05,3.05,0,0,1,.71-1.14.89.89,0,0,1,.59-.32c.26,0,.33.31.37.5-.06-.19-.16-.45-.38-.43a.78.78,0,0,0-.49.33,2.86,2.86,0,0,0-.55,1.12h0a.14.14,0,0,1-.16.1.14.14,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,16.86a3.07,3.07,0,0,1,.68-1.16.86.86,0,0,1,.58-.33c.27,0,.34.3.39.49-.07-.19-.18-.44-.39-.42s-.36.19-.49.34a3,3,0,0,0-.52,1.13h0a.14.14,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.51,15.17A3,3,0,0,1,55.17,14a.83.83,0,0,1,.57-.34c.27,0,.35.3.4.49-.07-.19-.18-.45-.4-.43a.81.81,0,0,0-.48.36,2.89,2.89,0,0,0-.51,1.13h0a.13.13,0,0,1-.15.1.14.14,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.75,17.73a3.05,3.05,0,0,1,.71-1.14.84.84,0,0,1,.59-.31c.27,0,.33.31.37.5-.06-.19-.16-.45-.37-.44a.78.78,0,0,0-.5.34A2.82,2.82,0,0,0,53,17.79h0a.11.11,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.76,14.1a3,3,0,0,1,.66-1.16A.83.83,0,0,1,54,12.6c.27,0,.34.3.39.48-.07-.18-.18-.44-.39-.42a.78.78,0,0,0-.48.36A2.79,2.79,0,0,0,53,14.16h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.25,15.17A3.15,3.15,0,0,1,51.93,14a.85.85,0,0,1,.58-.32c.27,0,.34.3.39.49-.07-.19-.17-.45-.39-.43a.81.81,0,0,0-.49.35,3,3,0,0,0-.53,1.12h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.92,12.38a3.1,3.1,0,0,1,.64-1.17.86.86,0,0,1,.57-.35c.27,0,.35.29.41.48-.08-.19-.19-.44-.41-.42a.78.78,0,0,0-.47.37,2.91,2.91,0,0,0-.5,1.14h0a.12.12,0,0,1-.14.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.76,18.09A3.13,3.13,0,0,1,49.48,17a.86.86,0,0,1,.59-.31c.27,0,.33.32.37.51,0-.19-.15-.46-.37-.44s-.36.17-.5.32A2.94,2.94,0,0,0,49,18.16h0a.14.14,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.2,16.59a3,3,0,0,1,.7-1.14.84.84,0,0,1,.59-.31c.27,0,.33.3.38.49-.06-.19-.17-.45-.38-.43a.83.83,0,0,0-.5.33,2.91,2.91,0,0,0-.54,1.12h0a.11.11,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,15a3.09,3.09,0,0,1,.67-1.15.9.9,0,0,1,.59-.33c.26,0,.33.3.39.49-.07-.19-.18-.44-.39-.42s-.36.19-.49.34a2.79,2.79,0,0,0-.53,1.13h0a.12.12,0,0,1-.15.09A.12.12,0,0,1,48,15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.71,13.56a3,3,0,0,1,.66-1.17.86.86,0,0,1,.57-.33c.27,0,.35.29.4.48-.07-.19-.18-.45-.4-.42a.79.79,0,0,0-.48.35,2.77,2.77,0,0,0-.5,1.14h0a.12.12,0,0,1-.14.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.32,14.57A3.17,3.17,0,0,1,47,13.4a.86.86,0,0,1,.58-.33c.26,0,.34.3.39.49-.07-.19-.18-.45-.39-.43a.83.83,0,0,0-.49.36,2.82,2.82,0,0,0-.51,1.13h0a.13.13,0,0,1-.15.09.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.81,16.27a3,3,0,0,1,.69-1.15.85.85,0,0,1,.58-.32c.27,0,.34.3.39.49-.07-.19-.18-.45-.39-.43s-.36.19-.49.34a2.79,2.79,0,0,0-.53,1.13h0a.12.12,0,0,1-.14.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.29,15.48A3,3,0,0,1,45,14.32a.87.87,0,0,1,.58-.34c.27,0,.34.3.39.49-.06-.19-.18-.45-.39-.42a.73.73,0,0,0-.48.35,3,3,0,0,0-.52,1.13h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.26,10.84a3.16,3.16,0,0,1,.58-1.21.88.88,0,0,1,.56-.37c.27,0,.36.28.42.46-.08-.18-.21-.43-.42-.39a.78.78,0,0,0-.46.38,2.92,2.92,0,0,0-.44,1.17h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.71,15.18A3.08,3.08,0,0,1,41.33,14a.9.9,0,0,1,.57-.35c.27,0,.35.28.41.47-.08-.19-.2-.44-.41-.41a.81.81,0,0,0-.47.37A2.81,2.81,0,0,0,41,15.22h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.65,17.2A3.11,3.11,0,0,1,40.27,16a.87.87,0,0,1,.57-.36c.27,0,.35.29.41.47-.07-.18-.19-.43-.41-.4a.76.76,0,0,0-.47.36,3,3,0,0,0-.48,1.16h0a.11.11,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.25,16.86a3,3,0,0,1,.58-1.2.91.91,0,0,1,.56-.37c.27,0,.36.28.42.46-.08-.18-.2-.43-.42-.39a.78.78,0,0,0-.46.38,2.94,2.94,0,0,0-.44,1.16h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.41,11a3.18,3.18,0,0,1,.48-1.24.86.86,0,0,1,.53-.42c.27,0,.38.24.46.42-.1-.17-.24-.41-.45-.36a.8.8,0,0,0-.43.42,2.89,2.89,0,0,0-.34,1.2h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.45,11.63a3.1,3.1,0,0,1,.47-1.26.86.86,0,0,1,.51-.42c.27,0,.39.23.47.41-.1-.17-.25-.41-.46-.35s-.32.25-.42.43a3,3,0,0,0-.32,1.2h0a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.72,12.92a3.06,3.06,0,0,1,.44-1.26c.13-.18.27-.39.51-.43s.39.23.48.4c-.1-.17-.26-.4-.47-.34s-.31.25-.41.43A2.89,2.89,0,0,0,30,12.93h0a.12.12,0,0,1-.12.13.14.14,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.17,14.77a2.94,2.94,0,0,1,.45-1.26.84.84,0,0,1,.51-.43c.26,0,.39.23.47.41-.1-.18-.25-.41-.46-.35s-.32.25-.42.43a2.88,2.88,0,0,0-.3,1.21h0a.14.14,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.73,14.64a3,3,0,0,1,.43-1.26.87.87,0,0,1,.5-.44c.27,0,.4.22.48.4-.1-.17-.26-.4-.47-.34a.79.79,0,0,0-.4.44A2.9,2.9,0,0,0,28,14.65h0a.12.12,0,0,1-.12.12.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.62,16.79a3,3,0,0,1,.43-1.26.86.86,0,0,1,.51-.44c.26,0,.39.23.48.41-.11-.18-.26-.41-.47-.34s-.31.25-.41.43a2.9,2.9,0,0,0-.29,1.21h0a.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.56,17.72A3,3,0,0,1,28,16.46.92.92,0,0,1,28.5,16c.27,0,.39.23.48.41-.1-.18-.26-.41-.47-.34s-.31.25-.41.43a2.87,2.87,0,0,0-.29,1.21h0a.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,21.13a3.15,3.15,0,0,1,.4-1.28.87.87,0,0,1,.49-.45c.26,0,.4.22.49.39-.11-.17-.27-.39-.48-.32a.77.77,0,0,0-.39.44,2.79,2.79,0,0,0-.26,1.22h0a.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.15,16.34a3,3,0,0,1,.39-1.28.9.9,0,0,1,.49-.45c.26,0,.4.21.49.38-.11-.16-.27-.39-.48-.32s-.3.27-.39.45a2.93,2.93,0,0,0-.25,1.22h0a.13.13,0,0,1-.12.13.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.92,14.55a3.06,3.06,0,0,1,.38-1.29.85.85,0,0,1,.48-.45c.27-.06.4.21.5.38-.11-.17-.28-.39-.48-.32s-.3.27-.39.45a2.77,2.77,0,0,0-.24,1.22h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.82,21.17A3.08,3.08,0,0,1,24,19.85a.89.89,0,0,1,.43-.52c.25-.08.42.16.54.32-.13-.16-.33-.36-.52-.26a.8.8,0,0,0-.33.5,2.75,2.75,0,0,0-.08,1.24h0a.12.12,0,0,1-.1.14.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.22,17.22a3,3,0,0,1,.28-1.3c.1-.19.22-.42.45-.49s.42.18.53.34c-.13-.16-.31-.37-.51-.28a.77.77,0,0,0-.35.48,2.89,2.89,0,0,0-.16,1.23h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.84,15.1a3.16,3.16,0,0,1,.32-1.3.88.88,0,0,1,.47-.48c.26-.07.41.19.51.36-.12-.17-.3-.38-.5-.3a.81.81,0,0,0-.37.47,3.07,3.07,0,0,0-.18,1.23h0a.11.11,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.55,15.8a3.1,3.1,0,0,1,.24-1.32c.1-.19.2-.42.44-.5s.42.16.53.32c-.13-.15-.32-.36-.51-.27a.79.79,0,0,0-.34.5,2.76,2.76,0,0,0-.11,1.24h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.25,18.25a3,3,0,0,1,0-1.33.85.85,0,0,1,.35-.57c.24-.12.44.1.58.24-.15-.13-.37-.31-.55-.18a.77.77,0,0,0-.26.53,2.91,2.91,0,0,0,.08,1.25h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.12,20.38A3.18,3.18,0,0,1,17,19.05a.86.86,0,0,1,.27-.61c.22-.15.45,0,.61.16-.17-.11-.41-.25-.57-.11a.8.8,0,0,0-.18.57,2.81,2.81,0,0,0,.25,1.22h0a.11.11,0,0,1-.06.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.55,22.08a3.18,3.18,0,0,1-.24-1.32.86.86,0,0,1,.23-.62c.21-.17.45,0,.62.11-.18-.1-.43-.22-.58-.07a.83.83,0,0,0-.14.58,2.86,2.86,0,0,0,.33,1.2h0a.13.13,0,0,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.21,23.7a3,3,0,0,1-.3-1.3.82.82,0,0,1,.19-.63c.2-.18.45,0,.62.08-.18-.09-.43-.2-.58,0a.84.84,0,0,0-.11.59,2.85,2.85,0,0,0,.4,1.18h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.9,25.46a3.11,3.11,0,0,1-.41-1.28,1,1,0,0,1,.15-.65c.19-.19.45-.05.63,0-.19-.07-.45-.17-.58,0a.79.79,0,0,0-.07.59,2.93,2.93,0,0,0,.48,1.15h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.62,29.07a3.23,3.23,0,0,1-.47-1.25.9.9,0,0,1,.11-.66c.18-.2.45-.08.63,0-.19-.07-.46-.14-.58,0a.79.79,0,0,0,0,.59,2.8,2.8,0,0,0,.55,1.12h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.87,31.25A3.1,3.1,0,0,1,15.41,30a.83.83,0,0,1,.11-.65c.18-.2.45-.08.63,0-.19-.07-.46-.14-.58,0a.75.75,0,0,0,0,.59,2.92,2.92,0,0,0,.54,1.12h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.54,34.85a2.94,2.94,0,0,1-.5-1.23.85.85,0,0,1,.09-.66c.18-.21.45-.09.63,0-.19-.06-.46-.13-.58.05a.78.78,0,0,0,0,.6,2.93,2.93,0,0,0,.57,1.11h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.82,36a3,3,0,0,1-.49-1.24.85.85,0,0,1,.11-.66c.18-.2.44-.08.62,0-.19-.07-.46-.14-.57,0a.77.77,0,0,0,0,.6A2.84,2.84,0,0,0,17,35.85h0A.13.13,0,0,1,17,36a.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.07,37.23a3,3,0,0,1-.5-1.24.85.85,0,0,1,.09-.66c.17-.21.44-.09.62,0-.19-.06-.46-.13-.57.06a.75.75,0,0,0,0,.59,2.81,2.81,0,0,0,.58,1.11h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.42,37.49a3.12,3.12,0,0,1-.49-1.25.91.91,0,0,1,.11-.66c.18-.2.45-.08.63,0-.19-.06-.46-.14-.58,0a.82.82,0,0,0,0,.6,2.76,2.76,0,0,0,.55,1.11h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.61,35.79a3.07,3.07,0,0,1-.44-1.26.86.86,0,0,1,.12-.66c.19-.2.45-.06.63,0-.19-.07-.46-.16-.58,0a.78.78,0,0,0-.05.6,2.85,2.85,0,0,0,.52,1.13h0a.13.13,0,0,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.05,35.05a3.09,3.09,0,0,1-.4-1.27.84.84,0,0,1,.15-.65c.18-.19.44-.05.62,0-.19-.07-.45-.16-.58,0a.79.79,0,0,0-.07.59,2.89,2.89,0,0,0,.49,1.15h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.91,33.25A3.17,3.17,0,0,1,19.52,32a.89.89,0,0,1,.16-.65c.19-.19.45-.05.62.05-.18-.09-.44-.18-.58,0a.79.79,0,0,0-.07.59,2.76,2.76,0,0,0,.47,1.16h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.59,32.14a3.16,3.16,0,0,1-.3-1.3.87.87,0,0,1,.19-.64c.21-.18.46,0,.62.08-.17-.09-.43-.2-.57,0a.8.8,0,0,0-.12.59,3,3,0,0,0,.4,1.18h0a.12.12,0,1,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.58,30.45a3.16,3.16,0,0,1-.18-1.32.9.9,0,0,1,.26-.62c.22-.15.45,0,.61.15-.17-.11-.41-.25-.57-.1a.76.76,0,0,0-.17.57,2.83,2.83,0,0,0,.28,1.22h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,28.13a3.15,3.15,0,0,1-.06-1.33.86.86,0,0,1,.31-.59c.23-.14.45.06.59.19-.15-.12-.39-.28-.55-.14a.76.76,0,0,0-.22.55,2.73,2.73,0,0,0,.17,1.24h0a.13.13,0,0,1-.07.17.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.33,25.9a3.08,3.08,0,0,1,.2-1.32.87.87,0,0,1,.42-.52c.25-.09.43.15.54.31-.13-.15-.32-.35-.52-.25a.74.74,0,0,0-.32.5,2.89,2.89,0,0,0-.07,1.24h0a.13.13,0,0,1-.1.14.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.81,23.91a3.09,3.09,0,0,1,.39-1.28.85.85,0,0,1,.49-.46c.26-.05.4.21.49.38-.11-.16-.27-.39-.48-.32s-.3.27-.39.45a2.93,2.93,0,0,0-.25,1.22h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.16,22.65a3.12,3.12,0,0,1,.45-1.25.86.86,0,0,1,.52-.43c.26,0,.38.23.47.41-.1-.18-.26-.41-.46-.35s-.32.25-.42.43a2.86,2.86,0,0,0-.31,1.21h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.54,20.91A3,3,0,0,1,31,19.67a.84.84,0,0,1,.53-.41c.27,0,.38.24.46.42-.09-.18-.24-.42-.45-.36s-.33.24-.43.41a2.86,2.86,0,0,0-.35,1.2h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.28,19a3.06,3.06,0,0,1,.5-1.24.86.86,0,0,1,.53-.4c.27,0,.38.25.46.43-.1-.18-.24-.42-.45-.37s-.33.24-.43.41a2.74,2.74,0,0,0-.36,1.2h0a.14.14,0,0,1-.14.12.13.13,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,18.13a3,3,0,0,1,.53-1.22.85.85,0,0,1,.54-.4c.26,0,.37.26.44.44-.09-.18-.23-.43-.44-.38a.8.8,0,0,0-.44.41,2.82,2.82,0,0,0-.38,1.18h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.54,15a3,3,0,0,1,.5-1.23.84.84,0,0,1,.53-.41c.27,0,.38.25.46.43-.1-.18-.24-.42-.45-.37a.78.78,0,0,0-.43.41,2.74,2.74,0,0,0-.36,1.2h0a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.57,15.54a3.13,3.13,0,0,1,.54-1.22.85.85,0,0,1,.54-.39c.27,0,.37.25.44.44-.08-.18-.22-.43-.43-.38a.8.8,0,0,0-.45.4,3,3,0,0,0-.39,1.18h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.49,10.94A2.89,2.89,0,0,1,36,9.7a.86.86,0,0,1,.53-.4c.27,0,.38.25.45.43-.09-.18-.23-.42-.44-.37s-.33.24-.44.41A2.79,2.79,0,0,0,35.74,11h0a.13.13,0,0,1-.14.12.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.84,12.86a3,3,0,0,1,.55-1.22.88.88,0,0,1,.54-.38c.27,0,.37.26.44.44-.08-.18-.22-.43-.43-.38a.82.82,0,0,0-.45.39,2.86,2.86,0,0,0-.4,1.18h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.52,10.27a3.13,3.13,0,0,1,.54-1.22.85.85,0,0,1,.54-.39c.27,0,.38.26.45.44-.09-.18-.23-.43-.44-.38s-.33.23-.44.4a2.86,2.86,0,0,0-.4,1.18h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.17,10.52a3,3,0,0,1,.56-1.22.91.91,0,0,1,.55-.38c.27,0,.36.27.43.45-.08-.18-.21-.43-.43-.38s-.33.22-.45.39a2.78,2.78,0,0,0-.41,1.17h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.66,13.48a3.06,3.06,0,0,1,.61-1.19.92.92,0,0,1,.56-.36c.27,0,.36.28.42.47-.08-.18-.2-.44-.41-.4s-.35.21-.47.37a2.81,2.81,0,0,0-.47,1.15h0a.12.12,0,0,1-.14.11.14.14,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.94,11.7a3,3,0,0,1,.6-1.19.91.91,0,0,1,.57-.36c.27,0,.35.28.41.47-.07-.19-.2-.44-.41-.4s-.35.21-.47.37a2.92,2.92,0,0,0-.46,1.16h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.53,11.93a3,3,0,0,1,.62-1.18.87.87,0,0,1,.57-.36c.27,0,.35.29.41.47-.08-.18-.2-.44-.41-.4a.76.76,0,0,0-.47.36A3,3,0,0,0,45.77,12h0a.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.4,12.27A3,3,0,0,1,49,11.1a.86.86,0,0,1,.57-.35c.27,0,.35.29.4.48-.07-.19-.19-.44-.4-.41a.81.81,0,0,0-.48.36,2.87,2.87,0,0,0-.48,1.14h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.15,18.65a2.94,2.94,0,0,1,.73-1.12.82.82,0,0,1,.6-.3c.27,0,.32.31.36.5-.05-.19-.15-.45-.37-.44a.81.81,0,0,0-.5.32,3,3,0,0,0-.58,1.11h0a.12.12,0,0,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.06,16.47a3.13,3.13,0,0,1,.69-1.15.89.89,0,0,1,.58-.32c.27,0,.34.31.39.5-.06-.19-.17-.45-.39-.43a.76.76,0,0,0-.49.34,2.89,2.89,0,0,0-.53,1.12h0a.12.12,0,0,1-.14.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.65,15.3a2.92,2.92,0,0,1,.65-1.17.86.86,0,0,1,.57-.35c.27,0,.35.29.4.48-.07-.19-.19-.44-.4-.42a.85.85,0,0,0-.48.36,3,3,0,0,0-.49,1.15h0a.13.13,0,0,1-.15.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,18.31a3.1,3.1,0,0,1,.64-1.17.89.89,0,0,1,.57-.35c.27,0,.35.29.41.48-.07-.19-.19-.44-.41-.42a.85.85,0,0,0-.47.37,2.75,2.75,0,0,0-.49,1.14h0a.14.14,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.71,18.85a3.06,3.06,0,0,1,.63-1.18.9.9,0,0,1,.57-.35c.27,0,.35.29.41.48-.07-.19-.19-.44-.41-.41a.76.76,0,0,0-.47.36A3,3,0,0,0,62,18.9h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.94,22.32a3.09,3.09,0,0,1,.67-1.15.87.87,0,0,1,.58-.34c.27,0,.34.3.39.49-.07-.19-.18-.45-.39-.42a.77.77,0,0,0-.49.35,2.84,2.84,0,0,0-.52,1.13h0a.12.12,0,0,1-.24-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.31,22.46A3,3,0,0,1,65,21.31a.86.86,0,0,1,.58-.33c.27,0,.34.3.39.49-.06-.19-.17-.45-.39-.43a.79.79,0,0,0-.48.35,2.73,2.73,0,0,0-.53,1.13h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.25,25.29a3.11,3.11,0,0,1,.68-1.15.89.89,0,0,1,.59-.32c.26,0,.33.3.38.49-.06-.19-.17-.45-.39-.43a.84.84,0,0,0-.49.35,3,3,0,0,0-.53,1.12h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.38,24.44a3,3,0,0,1,.7-1.14.85.85,0,0,1,.58-.32c.27,0,.34.3.39.49-.07-.19-.17-.45-.39-.43s-.36.19-.49.34a2.86,2.86,0,0,0-.55,1.12h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.25,22.72a3.2,3.2,0,0,1,.7-1.14.9.9,0,0,1,.59-.31c.27,0,.34.31.38.5-.06-.19-.16-.45-.38-.44a.76.76,0,0,0-.49.34,2.73,2.73,0,0,0-.55,1.12h0a.13.13,0,0,1-.15.1.14.14,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,23.34a3.11,3.11,0,0,1,.68-1.15.86.86,0,0,1,.58-.33c.27,0,.34.3.39.49-.07-.19-.18-.45-.39-.43a.81.81,0,0,0-.49.35,2.91,2.91,0,0,0-.52,1.13h0a.13.13,0,0,1-.15.09.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,21.72a2.93,2.93,0,0,1,.67-1.16.86.86,0,0,1,.58-.33c.27,0,.34.3.39.49-.07-.19-.18-.45-.39-.43a.81.81,0,0,0-.49.35,3,3,0,0,0-.52,1.13h0a.13.13,0,0,1-.25-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.73,21.38a3,3,0,0,1,.74-1.11.89.89,0,0,1,.6-.3c.27,0,.32.32.36.51-.06-.19-.15-.45-.37-.44s-.36.17-.5.31a3,3,0,0,0-.59,1.1h0a.12.12,0,1,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.38,18.61a3.05,3.05,0,0,1,.71-1.14.84.84,0,0,1,.59-.31c.27,0,.33.31.37.5-.06-.19-.16-.45-.38-.44a.78.78,0,0,0-.49.34,3,3,0,0,0-.56,1.11h0a.11.11,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.38,18.68A2.91,2.91,0,0,1,41,17.51a.89.89,0,0,1,.57-.35c.27,0,.35.29.41.48-.08-.18-.19-.44-.41-.41a.76.76,0,0,0-.47.36,2.77,2.77,0,0,0-.5,1.14h0a.14.14,0,0,1-.15.1.14.14,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.07,18.68a3,3,0,0,1,.58-1.21.86.86,0,0,1,.55-.37c.27,0,.36.27.43.45-.08-.18-.21-.43-.43-.39a.78.78,0,0,0-.45.39,2.79,2.79,0,0,0-.44,1.16h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.57,19.73a3.08,3.08,0,0,1,.52-1.23.91.91,0,0,1,.54-.4c.27,0,.38.25.45.44-.09-.18-.23-.43-.44-.38s-.33.23-.44.41a2.82,2.82,0,0,0-.38,1.18h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.74,20.56a3,3,0,0,1,.46-1.25.89.89,0,0,1,.52-.43c.26,0,.38.24.47.42-.1-.18-.26-.41-.46-.35s-.32.25-.42.42A2.91,2.91,0,0,0,29,20.58h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.33,21.47a3,3,0,0,1,.3-1.3.92.92,0,0,1,.46-.49c.26-.06.41.19.52.36-.12-.17-.3-.38-.5-.3a.77.77,0,0,0-.36.48,2.75,2.75,0,0,0-.17,1.23h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.22,22.94a3.18,3.18,0,0,1,.11-1.34.85.85,0,0,1,.39-.54c.24-.11.43.12.56.27-.14-.14-.35-.33-.54-.21a.77.77,0,0,0-.29.52,3,3,0,0,0,0,1.24h0a.13.13,0,0,1-.1.15.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,25a3,3,0,0,1-.2-1.32.89.89,0,0,1,.25-.62c.22-.16.45,0,.61.14-.17-.11-.41-.24-.57-.09a.8.8,0,0,0-.16.57,2.77,2.77,0,0,0,.3,1.21h0a.12.12,0,0,1-.06.17A.12.12,0,0,1,20,25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.88,27.31A3,3,0,0,1,17.54,26a.82.82,0,0,1,.18-.64c.2-.18.45,0,.62.07-.18-.09-.44-.2-.58,0a.79.79,0,0,0-.1.59,3,3,0,0,0,.43,1.17h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,30.6a3.13,3.13,0,0,1-.53-1.23.9.9,0,0,1,.07-.66c.17-.21.45-.1.63,0-.19-.06-.47-.12-.58.07a.83.83,0,0,0,0,.6,2.87,2.87,0,0,0,.6,1.08h0a.13.13,0,0,1-.19.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.42,33.52a3,3,0,0,1-.57-1.21.87.87,0,0,1,.06-.67c.16-.21.44-.1.63,0-.2-.05-.47-.11-.58.08a.83.83,0,0,0,0,.6,2.81,2.81,0,0,0,.63,1.07h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.94,36.35a3.07,3.07,0,0,1-.57-1.21.84.84,0,0,1,.05-.66c.16-.22.44-.11.63,0-.2,0-.47-.11-.58.08a.78.78,0,0,0,0,.6,2.71,2.71,0,0,0,.64,1.07h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.89,39.57a2.93,2.93,0,0,1-.57-1.21.85.85,0,0,1,.06-.66c.16-.21.43-.11.62-.05-.19,0-.47-.1-.57.09a.76.76,0,0,0,0,.59,2.93,2.93,0,0,0,.63,1.08h0a.12.12,0,1,1-.18.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.65,43.71a2.93,2.93,0,0,1-.57-1.21.9.9,0,0,1,.07-.66c.16-.22.44-.11.62-.05-.19,0-.46-.11-.57.08a.78.78,0,0,0,0,.6,2.91,2.91,0,0,0,.62,1.08h0a.13.13,0,1,1-.19.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.43,45.27a3,3,0,0,1-.55-1.22A.9.9,0,0,1,9,43.39c.16-.21.44-.1.62,0-.19-.05-.46-.11-.57.08A.8.8,0,0,0,9,44a3,3,0,0,0,.62,1.09h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.9,47a3,3,0,0,1-.53-1.23.85.85,0,0,1,.08-.66c.17-.21.44-.1.62,0-.19,0-.46-.12-.57.07a.78.78,0,0,0,0,.6,2.91,2.91,0,0,0,.6,1.09h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.05,49a3,3,0,0,1-.51-1.23.85.85,0,0,1,.09-.66c.18-.21.45-.09.63,0-.19-.06-.46-.13-.58.06a.79.79,0,0,0,0,.59,2.81,2.81,0,0,0,.58,1.11h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.14,51.24A3,3,0,0,1,9.66,50a.88.88,0,0,1,.11-.65c.18-.21.45-.08.63,0-.19-.07-.46-.15-.58,0a.79.79,0,0,0,0,.59,3,3,0,0,0,.56,1.12h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.46,54.53A2.93,2.93,0,0,1,12,53.27a.87.87,0,0,1,.13-.65c.19-.2.45-.07.63,0-.19-.08-.46-.16-.58,0a.75.75,0,0,0,0,.59,2.8,2.8,0,0,0,.51,1.14h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.39,56.44A3,3,0,0,1,14,55.17a.87.87,0,0,1,.15-.66c.18-.19.44,0,.62,0-.19-.08-.45-.17-.58,0a.79.79,0,0,0-.07.59,3,3,0,0,0,.49,1.15h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.51,58a2.92,2.92,0,0,1-.38-1.28.89.89,0,0,1,.15-.65c.2-.18.45,0,.63,0-.19-.08-.45-.17-.58,0a.75.75,0,0,0-.08.59,2.87,2.87,0,0,0,.47,1.16h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.33,59.79A2.93,2.93,0,0,1,16,58.5a.88.88,0,0,1,.17-.64c.2-.18.45,0,.62.06-.18-.08-.44-.19-.57,0a.8.8,0,0,0-.1.59,3.12,3.12,0,0,0,.44,1.17h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.8,61.33A3,3,0,0,1,19.48,60a.82.82,0,0,1,.18-.64c.2-.18.45,0,.62.07-.18-.09-.44-.2-.58,0a.79.79,0,0,0-.1.59A2.79,2.79,0,0,0,20,61.2h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.71,62.08a3,3,0,0,1-.32-1.3.83.83,0,0,1,.19-.64c.2-.18.45,0,.62.08-.18-.09-.44-.2-.58,0a.84.84,0,0,0-.11.59A3,3,0,0,0,17.92,62h0a.13.13,0,0,1-.22.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.42,61.9a3.18,3.18,0,0,1-.31-1.3A.87.87,0,0,1,14.3,60c.2-.18.45,0,.62.08-.18-.09-.43-.2-.57,0a.8.8,0,0,0-.12.59,2.82,2.82,0,0,0,.41,1.18h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.08,63.55a3.09,3.09,0,0,1-.3-1.3.88.88,0,0,1,.2-.64c.21-.17.45,0,.62.09-.18-.09-.43-.2-.58,0a.79.79,0,0,0-.11.59,2.75,2.75,0,0,0,.39,1.18h0a.14.14,0,0,1,0,.18.15.15,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.92,63.75a3.32,3.32,0,0,1-.29-1.3.89.89,0,0,1,.21-.64c.2-.17.45,0,.61.09-.17-.09-.43-.2-.57,0a.76.76,0,0,0-.12.58,2.87,2.87,0,0,0,.38,1.19h0a.12.12,0,1,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,62.43a3.1,3.1,0,0,1-.31-1.3.92.92,0,0,1,.19-.64c.21-.17.46,0,.62.09-.17-.1-.43-.21-.57,0a.73.73,0,0,0-.11.58,2.67,2.67,0,0,0,.4,1.18h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.41,59.81a3.16,3.16,0,0,1-.36-1.29.89.89,0,0,1,.17-.65c.2-.18.46,0,.63.07-.18-.09-.45-.19-.58,0a.79.79,0,0,0-.09.59,2.77,2.77,0,0,0,.44,1.16h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.71,56.06a3,3,0,0,1-.42-1.27.89.89,0,0,1,.15-.65c.19-.19.45,0,.62,0-.18-.07-.45-.16-.58,0a.81.81,0,0,0-.06.6,2.91,2.91,0,0,0,.49,1.14h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.91,53.76a2.93,2.93,0,0,1-.44-1.26.82.82,0,0,1,.12-.65c.19-.2.45-.07.63,0-.19-.07-.46-.15-.58,0a.75.75,0,0,0-.05.59,2.85,2.85,0,0,0,.52,1.13h0a.12.12,0,1,1-.19.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.72,52.05a3.23,3.23,0,0,1-.47-1.25.9.9,0,0,1,.12-.66c.18-.2.45-.07.62,0-.18-.07-.45-.15-.57,0a.77.77,0,0,0,0,.6,2.91,2.91,0,0,0,.54,1.12h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.07,50a3.16,3.16,0,0,1-.49-1.24.89.89,0,0,1,.1-.66c.18-.21.45-.08.63,0-.19-.06-.46-.14-.58.05a.75.75,0,0,0,0,.59,2.78,2.78,0,0,0,.56,1.11h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.44,47.73a3,3,0,0,1-.51-1.23.85.85,0,0,1,.09-.66c.17-.21.44-.09.62,0-.19-.06-.46-.13-.57.06a.75.75,0,0,0,0,.59,2.73,2.73,0,0,0,.59,1.1h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.37,45.62a3.2,3.2,0,0,1-.53-1.23.89.89,0,0,1,.09-.66c.17-.21.44-.1.62,0-.19-.06-.46-.12-.57.06a.78.78,0,0,0,0,.6,2.86,2.86,0,0,0,.59,1.1h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.3,43.81a3.09,3.09,0,0,1-.52-1.23.88.88,0,0,1,.09-.67c.17-.2.44-.09.62,0-.19-.06-.46-.12-.57.06a.78.78,0,0,0,0,.6,3,3,0,0,0,.59,1.1h0a.13.13,0,1,1-.19.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.24,42.37a3,3,0,0,1-.49-1.24.85.85,0,0,1,.09-.66c.18-.2.45-.08.63,0-.19-.06-.46-.13-.58,0a.78.78,0,0,0,0,.6,3,3,0,0,0,.57,1.1h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.9,39.92a3.1,3.1,0,0,1-.48-1.25.86.86,0,0,1,.1-.66c.18-.2.45-.08.63,0-.19-.07-.46-.14-.58,0a.82.82,0,0,0,0,.6,2.84,2.84,0,0,0,.56,1.11h0a.12.12,0,0,1-.19.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.77,37.92a3.08,3.08,0,0,1-.45-1.26.9.9,0,0,1,.11-.66c.19-.2.45-.07.63,0-.19-.07-.46-.16-.58,0a.82.82,0,0,0,0,.6A2.87,2.87,0,0,0,19,37.77h0A.14.14,0,0,1,19,38a.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.82,34.36a3,3,0,0,1-.45-1.25.86.86,0,0,1,.12-.66c.19-.2.45-.07.63,0-.19-.07-.46-.15-.58,0a.82.82,0,0,0-.05.6A3,3,0,0,0,18,34.22h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.36,31.44A3.14,3.14,0,0,1,19,30.15a.83.83,0,0,1,.16-.64c.19-.19.45,0,.62,0-.18-.08-.44-.18-.57,0a.8.8,0,0,0-.09.59,2.89,2.89,0,0,0,.46,1.16h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.48,29.68a3,3,0,0,1-.31-1.3.88.88,0,0,1,.2-.64c.2-.18.45,0,.62.09-.18-.1-.44-.21-.58,0a.76.76,0,0,0-.11.58,2.79,2.79,0,0,0,.39,1.18h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.48,26.24a3,3,0,0,1,0-1.34.9.9,0,0,1,.36-.56c.24-.12.44.1.57.24-.15-.13-.37-.31-.54-.19a.81.81,0,0,0-.27.54,3,3,0,0,0,.08,1.24h0a.12.12,0,0,1-.08.16.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.89,23.37a3,3,0,0,1,.3-1.3.9.9,0,0,1,.45-.49c.26-.07.42.19.52.35-.12-.16-.3-.37-.5-.29a.85.85,0,0,0-.36.48,2.91,2.91,0,0,0-.16,1.24h0a.12.12,0,0,1-.11.14.14.14,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.23,19.31a3,3,0,0,1,.46-1.25.87.87,0,0,1,.52-.42c.27,0,.39.23.47.41-.1-.17-.25-.41-.46-.35a.78.78,0,0,0-.42.42,3,3,0,0,0-.33,1.2h0a.12.12,0,0,1-.13.12.13.13,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.4,17.16a3.15,3.15,0,0,1,.5-1.24.82.82,0,0,1,.53-.41c.27,0,.38.24.46.42-.1-.17-.24-.41-.45-.36A.78.78,0,0,0,32,16a2.88,2.88,0,0,0-.36,1.2h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.57,14.13a3,3,0,0,1,.53-1.23.88.88,0,0,1,.54-.4c.27,0,.37.26.44.44-.09-.18-.22-.42-.44-.37s-.33.23-.44.4a2.82,2.82,0,0,0-.38,1.18h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.82,11.7a3.11,3.11,0,0,1,.54-1.23.84.84,0,0,1,.54-.39c.27,0,.37.26.44.44-.09-.18-.22-.42-.44-.38a.81.81,0,0,0-.44.4,3,3,0,0,0-.39,1.19h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.89,13.86a3.07,3.07,0,0,1,.59-1.2.88.88,0,0,1,.55-.37c.27,0,.36.27.43.46-.08-.19-.21-.43-.42-.4a.77.77,0,0,0-.46.39,2.79,2.79,0,0,0-.44,1.16h0A.13.13,0,0,1,39,14a.13.13,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.23,12.05a3.09,3.09,0,0,1,.58-1.21.88.88,0,0,1,.55-.37c.27,0,.37.27.43.46-.08-.19-.21-.44-.42-.4s-.34.22-.46.39a2.78,2.78,0,0,0-.43,1.16h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.73,40.87a3.12,3.12,0,0,1-.52-1.23A.9.9,0,0,1,15.3,39c.17-.21.44-.09.62,0-.19-.06-.46-.13-.57.06a.78.78,0,0,0,0,.6,2.87,2.87,0,0,0,.58,1.09h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.3,42a3,3,0,0,1-.54-1.23.85.85,0,0,1,.08-.66c.17-.21.44-.1.62,0-.19-.05-.46-.12-.57.07a.83.83,0,0,0,0,.6,2.93,2.93,0,0,0,.61,1.09h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.7,41.89a3.12,3.12,0,0,1-.52-1.23.85.85,0,0,1,.09-.66c.17-.21.44-.09.62,0-.19-.06-.46-.13-.57.06a.75.75,0,0,0,0,.59,2.84,2.84,0,0,0,.58,1.1h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15,39.6a3.13,3.13,0,0,1-.53-1.23.9.9,0,0,1,.09-.66c.17-.21.44-.1.62,0-.19-.06-.46-.13-.57.06a.78.78,0,0,0,0,.6,2.75,2.75,0,0,0,.59,1.09h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.34,37.23A3.09,3.09,0,0,1,13.81,36a.84.84,0,0,1,.08-.66c.17-.21.44-.1.62,0-.19-.06-.46-.12-.57.06a.78.78,0,0,0,0,.6,2.91,2.91,0,0,0,.6,1.09h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.14,33.7a3.2,3.2,0,0,1-.53-1.23.89.89,0,0,1,.08-.66c.17-.21.45-.1.63,0-.19-.06-.47-.12-.58.06a.83.83,0,0,0,0,.6,2.91,2.91,0,0,0,.6,1.09h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.22,31.35a3,3,0,0,1-.51-1.23.89.89,0,0,1,.09-.66c.18-.21.45-.09.63,0-.19-.06-.46-.13-.58,0a.88.88,0,0,0,0,.6,2.85,2.85,0,0,0,.59,1.1h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.21,29.32a3.2,3.2,0,0,1-.43-1.26.91.91,0,0,1,.14-.66c.18-.19.45-.06.62,0-.18-.08-.45-.16-.57,0a.82.82,0,0,0-.06.6,2.8,2.8,0,0,0,.51,1.14h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.53,27.55a3,3,0,0,1-.46-1.25,1,1,0,0,1,.12-.66c.19-.2.45-.07.63,0-.19-.07-.46-.15-.58,0a.82.82,0,0,0,0,.6,2.77,2.77,0,0,0,.53,1.12h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.22,27.19a3.08,3.08,0,0,1-.39-1.28.84.84,0,0,1,.15-.65c.19-.19.45-.05.62,0-.18-.08-.44-.18-.57,0a.78.78,0,0,0-.08.6,2.93,2.93,0,0,0,.48,1.15h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,25.73a3.12,3.12,0,0,1-.33-1.29.87.87,0,0,1,.19-.64c.19-.19.45,0,.62.07-.18-.09-.44-.2-.58,0a.79.79,0,0,0-.1.59,2.79,2.79,0,0,0,.42,1.17h0a.12.12,0,0,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.39,24.58a3,3,0,0,1-.25-1.31.87.87,0,0,1,.23-.63c.21-.16.45,0,.61.12-.17-.1-.42-.23-.57-.07a.77.77,0,0,0-.14.58,2.77,2.77,0,0,0,.34,1.2h0a.11.11,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.76,22.76a3,3,0,0,1-.13-1.33.87.87,0,0,1,.28-.61c.23-.15.45,0,.6.17-.16-.12-.4-.26-.56-.12a.8.8,0,0,0-.2.57A2.8,2.8,0,0,0,20,22.66h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.23,19.7a3.11,3.11,0,0,1,.21-1.32.9.9,0,0,1,.43-.51c.25-.09.42.16.54.31-.13-.15-.33-.35-.52-.25a.75.75,0,0,0-.33.5,2.75,2.75,0,0,0-.08,1.24h0a.13.13,0,0,1-.11.15.13.13,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.46,17.36a3,3,0,0,1,.39-1.27.85.85,0,0,1,.49-.46c.26-.05.4.22.49.39-.11-.17-.27-.4-.48-.33a.83.83,0,0,0-.39.45,2.93,2.93,0,0,0-.25,1.22h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.32,17.62a2.94,2.94,0,0,1,.45-1.26.84.84,0,0,1,.51-.43c.26,0,.39.23.47.41-.1-.17-.25-.41-.46-.35s-.32.26-.42.43a2.88,2.88,0,0,0-.3,1.21h0a.13.13,0,0,1-.13.12.13.13,0,0,1-.13-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.15,15.94a3.11,3.11,0,0,1,.47-1.25,1,1,0,0,1,.52-.42c.27,0,.38.24.46.42-.09-.17-.24-.41-.45-.35a.75.75,0,0,0-.42.42A2.74,2.74,0,0,0,30.4,16h0a.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,16.72a3,3,0,0,1,.52-1.24.86.86,0,0,1,.54-.39c.27,0,.37.25.45.43-.09-.18-.23-.42-.45-.37a.76.76,0,0,0-.43.4,2.91,2.91,0,0,0-.38,1.19h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.08,12.51a2.89,2.89,0,0,1,.51-1.24.83.83,0,0,1,.53-.4c.27,0,.38.25.45.43-.09-.18-.23-.42-.44-.37s-.33.24-.44.41a2.84,2.84,0,0,0-.36,1.19h0a.13.13,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.59,15.15a3.14,3.14,0,0,1,.57-1.21.88.88,0,0,1,.54-.38c.27,0,.37.27.44.45-.09-.18-.22-.43-.43-.39a.82.82,0,0,0-.45.39,2.71,2.71,0,0,0-.42,1.18h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.59,15.82a3,3,0,0,1,.6-1.19.88.88,0,0,1,.56-.37c.26,0,.35.28.42.46-.08-.18-.21-.43-.42-.4a.78.78,0,0,0-.46.38,2.83,2.83,0,0,0-.46,1.16h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42,16.8a3.07,3.07,0,0,1,.65-1.17.89.89,0,0,1,.57-.34c.27,0,.35.29.4.48-.07-.19-.18-.44-.4-.42a.81.81,0,0,0-.48.36,3.06,3.06,0,0,0-.5,1.14h0a.13.13,0,0,1-.15.1.14.14,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.38,14.15A3,3,0,0,1,45,13a.91.91,0,0,1,.57-.34c.27,0,.35.29.41.48-.07-.19-.19-.44-.4-.42a.78.78,0,0,0-.48.36,2.81,2.81,0,0,0-.5,1.15h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.63,10.42a3,3,0,0,1,.6-1.19.88.88,0,0,1,.56-.36c.27,0,.36.28.42.46-.08-.18-.2-.43-.41-.4a.79.79,0,0,0-.47.38,2.87,2.87,0,0,0-.46,1.15h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.94,13.8a3.1,3.1,0,0,1,.66-1.16.88.88,0,0,1,.58-.33c.27,0,.34.29.4.48-.07-.19-.18-.44-.4-.42a.79.79,0,0,0-.48.35,2.79,2.79,0,0,0-.51,1.14h0A.11.11,0,0,1,50,14a.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.72,19.39a3.22,3.22,0,0,1,.72-1.12A.85.85,0,0,1,54,18c.27,0,.32.31.37.5-.06-.19-.16-.45-.38-.44a.81.81,0,0,0-.5.32A3.09,3.09,0,0,0,53,19.46h0a.13.13,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.71,17.68a3.25,3.25,0,0,1,.66-1.16.91.91,0,0,1,.58-.33c.27,0,.35.29.4.48-.07-.19-.18-.45-.4-.42a.79.79,0,0,0-.48.35A3,3,0,0,0,57,17.73h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.14,17.63a3.18,3.18,0,0,1,.62-1.18.86.86,0,0,1,.57-.35c.27,0,.35.28.41.47-.07-.19-.19-.44-.41-.41s-.35.21-.47.37a2.75,2.75,0,0,0-.47,1.15h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.51,19.59a3,3,0,0,1,.64-1.17.81.81,0,0,1,.57-.34c.27,0,.35.28.4.47-.07-.19-.19-.44-.4-.41a.81.81,0,0,0-.48.36,3,3,0,0,0-.49,1.14h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.93,25.42a3,3,0,0,1,.73-1.12.8.8,0,0,1,.59-.3c.27,0,.33.31.37.5-.06-.19-.16-.45-.37-.44a.78.78,0,0,0-.5.32,3,3,0,0,0-.58,1.11h0a.13.13,0,1,1-.24-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.19,29.07a3,3,0,0,1,.75-1.1.88.88,0,0,1,.6-.29c.27,0,.32.32.36.51-.05-.19-.15-.45-.36-.45a.82.82,0,0,0-.51.31,2.83,2.83,0,0,0-.6,1.1h0a.13.13,0,0,1-.16.09.14.14,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.77,32.58a3,3,0,0,1,.8-1.06.9.9,0,0,1,.62-.26c.27,0,.3.34.33.53,0-.19-.12-.46-.34-.47a.81.81,0,0,0-.52.29A2.87,2.87,0,0,0,70,32.67h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.39,36.21a3,3,0,0,1,.84-1,.84.84,0,0,1,.62-.24c.26,0,.29.35.31.54,0-.2-.1-.47-.32-.48a.83.83,0,0,0-.53.27,3,3,0,0,0-.69,1h0a.12.12,0,0,1-.16.07.11.11,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.24,39.47a3,3,0,0,1,.77-1.1.91.91,0,0,1,.61-.27c.26,0,.31.32.34.52,0-.2-.13-.46-.35-.46a.8.8,0,0,0-.51.3,2.74,2.74,0,0,0-.62,1.08h0a.12.12,0,0,1-.15.08.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.68,42.8a2.94,2.94,0,0,1,.76-1.1.93.93,0,0,1,.6-.29c.27,0,.32.33.36.52-.06-.19-.14-.46-.36-.45a.77.77,0,0,0-.51.31,2.77,2.77,0,0,0-.61,1.08h0a.14.14,0,0,1-.16.09.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.86,45.23a3,3,0,0,1,.74-1.11.84.84,0,0,1,.6-.29c.26,0,.32.31.36.51-.06-.2-.15-.46-.37-.45a.75.75,0,0,0-.5.32,2.64,2.64,0,0,0-.59,1.09h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,48.79a3.16,3.16,0,0,1,.75-1.11.93.93,0,0,1,.61-.29c.26,0,.31.33.35.52,0-.2-.14-.46-.36-.45a.77.77,0,0,0-.51.31,3,3,0,0,0-.6,1.09h0a.13.13,0,0,1-.15.09A.13.13,0,0,1,67,48.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.9,52.69a3,3,0,0,1,.84-1,.87.87,0,0,1,.62-.23c.27,0,.29.35.31.54,0-.2-.1-.47-.32-.48a.78.78,0,0,0-.53.27,2.87,2.87,0,0,0-.69,1h0a.13.13,0,1,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.86,55.77a3,3,0,0,1,.9-1,.89.89,0,0,1,.63-.2c.26.07.27.36.28.56,0-.2-.08-.47-.29-.5a.86.86,0,0,0-.55.24,2.87,2.87,0,0,0-.74,1h0a.12.12,0,0,1-.16.06.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.53,59.38a3,3,0,0,1,.89-1,.87.87,0,0,1,.64-.21c.26.07.27.36.28.56,0-.2-.08-.47-.3-.5a.82.82,0,0,0-.54.25,2.78,2.78,0,0,0-.74,1h0a.11.11,0,0,1-.16.07.14.14,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.39,62.1a3.19,3.19,0,0,1,.85-1,.87.87,0,0,1,.62-.23c.27.05.29.35.31.54,0-.19-.1-.47-.32-.48a.83.83,0,0,0-.53.27,2.87,2.87,0,0,0-.69,1h0a.12.12,0,0,1-.16.08.14.14,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.78,61.64a3.15,3.15,0,0,1,.87-1,.87.87,0,0,1,.64-.21c.26.06.28.35.29.55,0-.2-.09-.47-.31-.49a.82.82,0,0,0-.54.25,2.84,2.84,0,0,0-.72,1h0a.13.13,0,1,1-.24-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.09,60.35a3.15,3.15,0,0,1,.85-1,.91.91,0,0,1,.63-.23c.26.06.29.36.31.55,0-.2-.1-.47-.32-.48a.81.81,0,0,0-.54.26,3,3,0,0,0-.7,1h0a.12.12,0,0,1-.16.07.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.84,58.15a3,3,0,0,1,.87-1,.88.88,0,0,1,.63-.22c.26.06.28.36.3.55,0-.2-.1-.47-.31-.49a.79.79,0,0,0-.54.26,2.79,2.79,0,0,0-.72,1h0a.12.12,0,0,1-.17.07.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.31,55A3,3,0,0,1,68.15,54a.88.88,0,0,1,.62-.24c.26.05.29.35.32.54,0-.2-.11-.47-.33-.48a.8.8,0,0,0-.53.28,2.84,2.84,0,0,0-.68,1h0a.12.12,0,0,1-.16.08.14.14,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,51.61a3.18,3.18,0,0,1,.79-1.08.88.88,0,0,1,.62-.26c.26,0,.3.33.33.52,0-.19-.13-.46-.34-.46a.77.77,0,0,0-.52.29,2.81,2.81,0,0,0-.64,1.07h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.27,47.77a2.93,2.93,0,0,1,.8-1.07.87.87,0,0,1,.61-.26c.27,0,.31.34.34.53,0-.19-.13-.46-.34-.47a.86.86,0,0,0-.53.29,3,3,0,0,0-.65,1.07h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.27,45.17a3.07,3.07,0,0,1,.89-1A.87.87,0,0,1,71.8,44c.26.07.27.36.28.56,0-.2-.08-.48-.3-.5a.82.82,0,0,0-.54.25,2.78,2.78,0,0,0-.74,1h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.7,43a2.94,2.94,0,0,1,1-.93.92.92,0,0,1,.65-.16c.25.09.24.38.24.58,0-.2,0-.48-.26-.52a.82.82,0,0,0-.56.2,2.82,2.82,0,0,0-.81,1h0a.12.12,0,1,1-.22-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.74,40.89a3.09,3.09,0,0,1,1.09-.77.84.84,0,0,1,.66,0c.24.12.19.41.16.6,0-.2,0-.48-.18-.55a.79.79,0,0,0-.59.12,2.76,2.76,0,0,0-.95.81h0a.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76,37a3.23,3.23,0,0,1,1.21-.58.89.89,0,0,1,.66.06c.22.16.11.44.05.62,0-.19.11-.47-.09-.57a.76.76,0,0,0-.59,0,2.71,2.71,0,0,0-1.07.64h0a.13.13,0,0,1-.18-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.54,34a3,3,0,0,1,1.22-.55.85.85,0,0,1,.66.06c.21.17.11.44,0,.63.05-.19.11-.47-.08-.58a.76.76,0,0,0-.59,0,2.71,2.71,0,0,0-1.08.62h0a.13.13,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.55,27a3.07,3.07,0,0,1,1.21-.57.85.85,0,0,1,.66.06c.22.16.11.44,0,.62,0-.19.11-.47-.08-.57a.78.78,0,0,0-.6,0,2.83,2.83,0,0,0-1.07.64h0a.11.11,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.67,23.64a3,3,0,0,1,1.07-.8.85.85,0,0,1,.66-.08c.25.12.2.41.17.6,0-.2,0-.48-.19-.54a.73.73,0,0,0-.58.13,2.79,2.79,0,0,0-.93.84h0a.13.13,0,0,1-.21-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.2,22.45a2.94,2.94,0,0,1,1-.92.87.87,0,0,1,.65-.15c.25.09.24.38.24.58,0-.2,0-.48-.26-.52a.81.81,0,0,0-.56.19,3,3,0,0,0-.83.94h0a.12.12,0,0,1-.17.06.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.46,21.17a3.07,3.07,0,0,1,.87-1,.85.85,0,0,1,.63-.21c.26.06.28.35.3.55,0-.2-.1-.48-.31-.49a.78.78,0,0,0-.54.25,2.79,2.79,0,0,0-.72,1h0a.12.12,0,0,1-.17.07.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.21,19.9A3.15,3.15,0,0,1,71,18.8a.87.87,0,0,1,.61-.28c.27,0,.31.33.35.52-.05-.2-.14-.46-.36-.46a.82.82,0,0,0-.51.31A2.88,2.88,0,0,0,70.45,20h0a.13.13,0,0,1-.16.09.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.53,16.61a2.85,2.85,0,0,1,.62-1.18.88.88,0,0,1,.56-.36c.27,0,.36.29.41.47-.07-.18-.19-.43-.4-.4a.81.81,0,0,0-.48.36,3.16,3.16,0,0,0-.47,1.16h0a.13.13,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.4,11.13a3,3,0,0,1,.49-1.25.88.88,0,0,1,.53-.41c.26,0,.38.24.46.42-.1-.17-.24-.41-.45-.36A.77.77,0,0,0,66,10a2.86,2.86,0,0,0-.35,1.2h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.12,11.4a2.94,2.94,0,0,1,.52-1.23.88.88,0,0,1,.54-.4c.26,0,.37.25.44.44-.08-.18-.23-.43-.44-.38s-.33.24-.44.41a2.81,2.81,0,0,0-.37,1.19h0a.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.08,15.69a3,3,0,0,1,.57-1.21.86.86,0,0,1,.55-.37c.27,0,.36.26.43.45-.08-.18-.22-.43-.43-.39s-.34.22-.45.39a2.82,2.82,0,0,0-.43,1.17h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.13,16.27a3.07,3.07,0,0,1,.59-1.2.85.85,0,0,1,.56-.36c.27,0,.36.27.42.46-.08-.19-.2-.44-.42-.4a.81.81,0,0,0-.46.38,3,3,0,0,0-.45,1.16h0a.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.58,9.47a3.14,3.14,0,0,1,.57-1.21.86.86,0,0,1,.55-.37c.27,0,.36.26.43.45-.08-.19-.22-.43-.43-.39s-.34.22-.45.39a2.67,2.67,0,0,0-.42,1.17h0a.12.12,0,0,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.59,3.07a3,3,0,0,1,.49-1.24.87.87,0,0,1,.52-.42c.27,0,.38.25.46.43-.09-.18-.24-.42-.45-.36s-.32.24-.43.41a2.89,2.89,0,0,0-.34,1.2h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.94,5.45a3,3,0,0,1,.53-1.22.85.85,0,0,1,.54-.4c.27,0,.37.26.44.44-.09-.18-.22-.42-.44-.37a.81.81,0,0,0-.44.4,3,3,0,0,0-.39,1.18h0a.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.72,6.43a3.06,3.06,0,0,1,.54-1.22.89.89,0,0,1,.55-.39c.26,0,.37.26.44.44-.09-.18-.23-.42-.44-.38s-.33.23-.45.4A3,3,0,0,0,48,6.46h0a.12.12,0,0,1-.13.11.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.34,7.16a3.15,3.15,0,0,1,.55-1.22.87.87,0,0,1,.55-.38c.26,0,.37.26.44.44-.09-.18-.23-.42-.44-.38s-.33.23-.45.4a3,3,0,0,0-.4,1.17h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.59,9.57a3,3,0,0,1,.57-1.21A.88.88,0,0,1,43.72,8c.26,0,.36.27.43.45-.09-.18-.22-.43-.43-.39s-.34.22-.45.39a2.82,2.82,0,0,0-.43,1.17h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.89,7.35a3,3,0,0,1,.47-1.25.87.87,0,0,1,.52-.42c.27,0,.39.24.47.42-.1-.18-.25-.42-.46-.36s-.32.25-.42.42a2.74,2.74,0,0,0-.33,1.2h0a.13.13,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.32,6.78a3.15,3.15,0,0,1,.4-1.28.9.9,0,0,1,.49-.45c.27,0,.4.22.49.39-.1-.17-.27-.4-.47-.33a.81.81,0,0,0-.4.45,3,3,0,0,0-.26,1.22h0a.12.12,0,0,1-.12.12.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.58,7.46a3,3,0,0,1,.35-1.29.87.87,0,0,1,.47-.47c.26-.06.41.2.51.37-.12-.16-.29-.38-.49-.31a.79.79,0,0,0-.38.47,2.72,2.72,0,0,0-.21,1.22h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.51,12.71a3.21,3.21,0,0,1,.34-1.3.94.94,0,0,1,.47-.47c.27-.06.41.2.51.37-.11-.16-.29-.38-.49-.31s-.29.28-.38.47a2.9,2.9,0,0,0-.2,1.23h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.67,20.83a3,3,0,0,1,.06-1.34.89.89,0,0,1,.35-.56c.25-.11.44.11.58.25-.15-.14-.37-.31-.55-.19a.75.75,0,0,0-.26.53,2.91,2.91,0,0,0,.06,1.25h0a.14.14,0,0,1-.09.16.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.21,17.69a3,3,0,0,1-.08-1.34.88.88,0,0,1,.31-.59c.23-.14.44.06.59.19-.16-.12-.39-.27-.56-.14a.79.79,0,0,0-.21.56,2.81,2.81,0,0,0,.18,1.23h0a.14.14,0,0,1-.07.16.13.13,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.09,11.36a3,3,0,0,1,.2-1.32.87.87,0,0,1,.42-.52c.26-.08.43.16.54.31-.13-.15-.32-.35-.52-.25a.79.79,0,0,0-.32.5,2.8,2.8,0,0,0-.08,1.25h0a.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.33,10.14a3.14,3.14,0,0,1,.15-1.33.93.93,0,0,1,.4-.53c.26-.1.44.14.56.29-.14-.15-.34-.34-.53-.23s-.25.32-.31.51a2.93,2.93,0,0,0,0,1.25h0a.13.13,0,0,1-.1.15.12.12,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.05,13.15a3.17,3.17,0,0,1-.22-1.32.87.87,0,0,1,.24-.62c.21-.17.45,0,.61.12-.17-.1-.42-.23-.57-.07a.8.8,0,0,0-.15.57A2.94,2.94,0,0,0,9.28,13h0a.13.13,0,0,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.52,16.67a3,3,0,0,1-.45-1.26.89.89,0,0,1,.13-.65c.18-.2.45-.07.62,0-.18-.07-.45-.16-.57,0a.75.75,0,0,0,0,.59,2.9,2.9,0,0,0,.52,1.14h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.18,19.61A3,3,0,0,1,5.6,18.4a.84.84,0,0,1,.05-.66c.16-.22.44-.12.62,0-.19-.05-.47-.11-.57.08a.78.78,0,0,0,0,.6,2.83,2.83,0,0,0,.64,1.07h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.74,23.38a3,3,0,0,1-.69-1.14.86.86,0,0,1,0-.67c.14-.23.43-.16.62-.11-.2,0-.48-.06-.56.14a.73.73,0,0,0,.07.59,2.75,2.75,0,0,0,.74,1h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.16,22.25a3,3,0,0,1-.66-1.16.86.86,0,0,1,0-.67c.15-.22.43-.14.62-.09-.2,0-.47-.07-.56.12a.79.79,0,0,0,.05.6,3,3,0,0,0,.72,1h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.4,21.64a3,3,0,0,1-.62-1.18.91.91,0,0,1,0-.67c.16-.22.44-.12.62-.07-.19,0-.47-.09-.56.11a.76.76,0,0,0,0,.59,2.89,2.89,0,0,0,.68,1h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.41,18.8a3.06,3.06,0,0,1-.49-1.25A.87.87,0,0,1,8,16.89c.17-.2.44-.08.62,0-.19-.06-.46-.14-.58,0a.82.82,0,0,0,0,.6,2.91,2.91,0,0,0,.56,1.11h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.93,13.78a2.85,2.85,0,0,1,0-1.33.84.84,0,0,1,.33-.58c.24-.13.45.09.59.22-.16-.13-.38-.29-.56-.17a.82.82,0,0,0-.24.55,2.86,2.86,0,0,0,.12,1.24h0a.12.12,0,0,1-.08.15.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.45,15.13a3,3,0,0,1,.06-1.33.89.89,0,0,1,.35-.57c.25-.11.44.11.58.25-.15-.13-.37-.31-.55-.19a.79.79,0,0,0-.26.53,2.77,2.77,0,0,0,.07,1.25h0a.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.86,17.09A3.18,3.18,0,0,1,20,15.75a.92.92,0,0,1,.39-.54c.25-.1.43.12.56.27-.14-.14-.35-.33-.53-.21a.77.77,0,0,0-.3.52,3,3,0,0,0,0,1.24h0a.13.13,0,0,1-.1.15.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.59,19.26A3,3,0,0,1,24.9,18a.88.88,0,0,1,.47-.48c.26-.07.41.19.51.35-.12-.16-.3-.38-.5-.29A.79.79,0,0,0,25,18a2.75,2.75,0,0,0-.18,1.23h0a.12.12,0,0,1-.12.13.11.11,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.28,11.62a3,3,0,0,1,.39-1.27.85.85,0,0,1,.5-.45c.26-.05.4.21.49.38-.11-.17-.27-.39-.48-.32s-.3.26-.4.45a2.86,2.86,0,0,0-.25,1.21h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.62,9.33a3,3,0,0,1,.48-1.25.82.82,0,0,1,.51-.42c.27,0,.39.24.47.41-.1-.17-.25-.41-.46-.35s-.32.25-.42.42a3.08,3.08,0,0,0-.33,1.2h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.57,12.35a2.93,2.93,0,0,1,.61-1.19.83.83,0,0,1,.56-.36c.27,0,.35.28.42.46-.08-.18-.21-.43-.42-.4a.82.82,0,0,0-.47.38,2.86,2.86,0,0,0-.45,1.15h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.88,10.66a3.08,3.08,0,0,1,.62-1.19.86.86,0,0,1,.56-.35c.27,0,.36.28.42.46-.08-.18-.2-.43-.42-.4s-.34.21-.46.37a2.81,2.81,0,0,0-.47,1.15h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,12.55a3.13,3.13,0,0,1,.63-1.18.86.86,0,0,1,.57-.35c.27,0,.35.28.41.47-.07-.19-.19-.44-.41-.41a.74.74,0,0,0-.47.37,2.87,2.87,0,0,0-.48,1.14h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.69,16.91a3,3,0,0,1,.62-1.18.88.88,0,0,1,.56-.36c.27,0,.36.28.42.47-.08-.18-.2-.44-.41-.41a.81.81,0,0,0-.47.37A2.85,2.85,0,0,0,59.94,17h0a.14.14,0,0,1-.15.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,17.27a3,3,0,0,1,.6-1.19.91.91,0,0,1,.56-.37c.27,0,.36.28.42.47-.08-.19-.2-.44-.42-.4s-.34.21-.46.37a3,3,0,0,0-.45,1.16h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.31,18.71A3.18,3.18,0,0,1,66,17.54a.86.86,0,0,1,.57-.35c.27,0,.35.29.41.48-.08-.19-.19-.44-.41-.41a.76.76,0,0,0-.47.36,2.91,2.91,0,0,0-.5,1.14h0a.12.12,0,0,1-.14.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,21.64a3,3,0,0,1,.73-1.12.83.83,0,0,1,.59-.3c.27,0,.33.31.37.5-.06-.19-.16-.45-.37-.44a.78.78,0,0,0-.5.32,3,3,0,0,0-.58,1.11h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.91,24.63a2.93,2.93,0,0,1,.88-1,.85.85,0,0,1,.63-.21c.27.06.28.36.29.55,0-.2-.08-.47-.3-.49a.8.8,0,0,0-.54.25,2.87,2.87,0,0,0-.74,1h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.16,27.53a3.22,3.22,0,0,1,1.09-.78.89.89,0,0,1,.66-.06c.24.12.19.41.16.6,0-.2,0-.48-.18-.54a.79.79,0,0,0-.59.12,2.86,2.86,0,0,0-.94.82h0a.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.28,31.77a3,3,0,0,1,1.23-.51.85.85,0,0,1,.66.09c.21.17.09.44,0,.62.06-.19.13-.46-.06-.57a.75.75,0,0,0-.59,0,2.84,2.84,0,0,0-1.1.58h0a.12.12,0,0,1-.16-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.58,36.48a3.09,3.09,0,0,1,1.28-.4.86.86,0,0,1,.65.15c.19.19,0,.45,0,.62.08-.18.17-.45,0-.58a.79.79,0,0,0-.59-.07,3,3,0,0,0-1.15.49h0a.13.13,0,1,1-.15-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.56,41.65a3.06,3.06,0,0,1,1.25-.46.9.9,0,0,1,.66.11c.2.18.07.45,0,.63.07-.19.15-.46,0-.58a.82.82,0,0,0-.6,0,2.91,2.91,0,0,0-1.12.54h0a.12.12,0,0,1-.18,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76,44.88a3,3,0,0,1,1.2-.6.87.87,0,0,1,.67,0c.21.15.12.43,0,.62,0-.2.1-.47-.09-.57a.78.78,0,0,0-.6,0,2.8,2.8,0,0,0-1.05.66h0a.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.18,48.42a2.88,2.88,0,0,1,1.11-.75.84.84,0,0,1,.66,0c.24.13.18.42.14.61,0-.2,0-.48-.17-.56a.81.81,0,0,0-.58.1,3,3,0,0,0-1,.79h0a.14.14,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.84,53.42a3.11,3.11,0,0,1,1.09-.79.88.88,0,0,1,.66-.06c.24.12.19.41.16.6,0-.2,0-.48-.19-.55a.83.83,0,0,0-.58.13,2.86,2.86,0,0,0-.94.82h0a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.41,60.64a3.15,3.15,0,0,1,1-.9.87.87,0,0,1,.65-.13c.26.09.24.38.23.58,0-.2,0-.48-.25-.52a.76.76,0,0,0-.56.18,2.73,2.73,0,0,0-.84.92h0a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,65.36a2.93,2.93,0,0,1,.88-1,.89.89,0,0,1,.64-.21c.26.06.27.36.29.55,0-.19-.09-.47-.3-.49a.82.82,0,0,0-.55.25,2.9,2.9,0,0,0-.74,1h0a.12.12,0,0,1-.16.06.11.11,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.31,68a3.18,3.18,0,0,1,.82-1.06.89.89,0,0,1,.62-.24c.27,0,.3.34.32.53,0-.19-.11-.46-.33-.47a.8.8,0,0,0-.53.28,3.08,3.08,0,0,0-.67,1h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.2,70.66a3.06,3.06,0,0,1,.76-1.1.87.87,0,0,1,.61-.28c.26,0,.31.32.35.52,0-.2-.14-.46-.36-.46a.77.77,0,0,0-.51.31,2.77,2.77,0,0,0-.61,1.08h0a.13.13,0,0,1-.25-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.23,72.38A3,3,0,0,1,64,71.26a.89.89,0,0,1,.59-.3c.27,0,.33.32.37.51-.06-.19-.15-.46-.37-.45a.83.83,0,0,0-.5.33,2.85,2.85,0,0,0-.58,1.1h0a.13.13,0,0,1-.16.09.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.32,74.39a3.15,3.15,0,0,1,.7-1.15.82.82,0,0,1,.58-.31c.27,0,.34.3.38.49-.06-.19-.16-.45-.38-.43a.76.76,0,0,0-.49.34,2.71,2.71,0,0,0-.54,1.12h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.3,77a3.27,3.27,0,0,1,.63-1.18.89.89,0,0,1,.57-.35c.27,0,.35.28.41.47-.07-.18-.19-.44-.41-.41s-.35.21-.47.37A2.91,2.91,0,0,0,63.55,77h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.08,78.46a3.07,3.07,0,0,1,.59-1.2.92.92,0,0,1,.56-.36c.27,0,.36.28.42.46-.07-.18-.2-.43-.41-.4a.83.83,0,0,0-.47.38,3,3,0,0,0-.45,1.16h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,80a3.16,3.16,0,0,1,.56-1.22.91.91,0,0,1,.55-.38c.26,0,.36.27.43.45-.08-.18-.22-.43-.43-.38s-.34.22-.45.39A2.78,2.78,0,0,0,63.2,80h0A.13.13,0,1,1,63,80Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.22,81.43a3,3,0,0,1,.53-1.23.86.86,0,0,1,.53-.4c.27,0,.38.26.45.44-.09-.18-.23-.42-.44-.38s-.33.24-.44.41a2.82,2.82,0,0,0-.38,1.19h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.55,81.8a3,3,0,0,1,.52-1.24.84.84,0,0,1,.53-.39c.27,0,.38.25.45.43-.09-.18-.23-.42-.44-.37a.81.81,0,0,0-.44.4,2.9,2.9,0,0,0-.37,1.19h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.85,82.05a3,3,0,0,1,.52-1.24.9.9,0,0,1,.53-.4c.27,0,.38.25.45.43-.09-.17-.23-.42-.44-.37a.84.84,0,0,0-.44.41,3.05,3.05,0,0,0-.37,1.19h0a.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.62,80a3,3,0,0,1,.56-1.21.84.84,0,0,1,.55-.38c.27,0,.37.26.43.45-.08-.18-.21-.43-.43-.39a.85.85,0,0,0-.45.39A2.82,2.82,0,0,0,60.87,80h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.18,75.33a3,3,0,0,1,.67-1.16.85.85,0,0,1,.58-.32c.27,0,.34.29.39.48-.07-.19-.18-.44-.39-.42a.77.77,0,0,0-.49.35,2.84,2.84,0,0,0-.52,1.13h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.13,69.24a3.18,3.18,0,0,1,.79-1.08.88.88,0,0,1,.62-.26c.26,0,.3.33.33.52,0-.19-.12-.46-.34-.46a.77.77,0,0,0-.52.29,2.81,2.81,0,0,0-.64,1.07h0a.14.14,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,58.81a3,3,0,0,1,1-.9.85.85,0,0,1,.66-.13c.25.09.23.38.22.58,0-.2,0-.48-.24-.52A.77.77,0,0,0,73,58a2.83,2.83,0,0,0-.85.91h0A.13.13,0,0,1,72,59a.13.13,0,0,1-.05-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.46,52.66a3,3,0,0,1,1-.89.88.88,0,0,1,.65-.14c.25.1.23.39.22.59,0-.2,0-.48-.24-.53a.83.83,0,0,0-.57.18,3,3,0,0,0-.84.92h0a.13.13,0,0,1-.18.05.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.07,49.81a3.13,3.13,0,0,1,.92-1,.94.94,0,0,1,.65-.19c.25.08.26.37.26.57,0-.2-.07-.48-.28-.51a.8.8,0,0,0-.55.23,2.94,2.94,0,0,0-.78,1h0a.12.12,0,0,1-.16.06.11.11,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.44,46.93a3.16,3.16,0,0,1,.92-1,.92.92,0,0,1,.64-.19c.26.08.27.37.27.57,0-.2-.07-.48-.28-.51a.89.89,0,0,0-.56.23,2.92,2.92,0,0,0-.76,1h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.9,40.65a3.07,3.07,0,0,1,1-.94.87.87,0,0,1,.65-.15c.26.08.25.37.24.57,0-.2,0-.48-.26-.51a.78.78,0,0,0-.56.2,3,3,0,0,0-.81,1h0a.13.13,0,0,1-.17.06.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.47,34.58a3.15,3.15,0,0,1,1-.92.92.92,0,0,1,.65-.16c.25.09.24.38.24.58,0-.2,0-.48-.26-.52a.81.81,0,0,0-.56.2,3,3,0,0,0-.82,1h0a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.36,28A2.9,2.9,0,0,1,70.15,27a.86.86,0,0,1,.61-.27c.27,0,.31.33.34.53,0-.2-.13-.46-.35-.47a.85.85,0,0,0-.52.3,3,3,0,0,0-.64,1.07h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.88,21.23a3.25,3.25,0,0,1,.66-1.16.91.91,0,0,1,.58-.33c.27,0,.34.29.4.48-.07-.19-.18-.45-.4-.42a.79.79,0,0,0-.48.35,3,3,0,0,0-.52,1.13h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.7,18.94a3.15,3.15,0,0,1,.64-1.18.91.91,0,0,1,.57-.34c.27,0,.35.29.41.47-.07-.18-.19-.44-.41-.41a.79.79,0,0,0-.47.36A2.79,2.79,0,0,0,60,19h0a.11.11,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.41,18.46a3,3,0,0,1,.69-1.15.85.85,0,0,1,.58-.32c.27,0,.34.3.39.49-.07-.19-.18-.45-.39-.43s-.36.19-.49.34a3,3,0,0,0-.54,1.13h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,16.32a3,3,0,0,1,.7-1.14.89.89,0,0,1,.59-.32c.27,0,.33.31.38.5-.06-.19-.17-.45-.38-.43a.83.83,0,0,0-.5.33,2.91,2.91,0,0,0-.54,1.12h0a.11.11,0,0,1-.15.09.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.32,10.3a3.07,3.07,0,0,1,.59-1.2.83.83,0,0,1,.56-.36c.27,0,.36.27.42.46-.08-.19-.21-.44-.42-.4a.78.78,0,0,0-.46.38,3,3,0,0,0-.45,1.16h0a.13.13,0,0,1-.14.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.66,13.59a3,3,0,0,1,.49-1.25.84.84,0,0,1,.52-.42c.27,0,.38.24.46.42-.09-.17-.24-.41-.45-.36s-.32.25-.43.42a2.89,2.89,0,0,0-.34,1.2h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.92,19.48a2.94,2.94,0,0,1,.42-1.27.83.83,0,0,1,.5-.45c.26,0,.39.22.48.4-.1-.17-.27-.4-.47-.34a.78.78,0,0,0-.4.45,2.86,2.86,0,0,0-.28,1.21h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.75,22.64a3,3,0,0,1,0-1.34.86.86,0,0,1,.33-.58c.24-.12.45.09.59.22-.16-.13-.38-.29-.56-.16a.78.78,0,0,0-.24.54A2.72,2.72,0,0,0,22,22.56h0a.14.14,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.65,27.93a3,3,0,0,1-.29-1.31.86.86,0,0,1,.2-.63c.21-.18.45,0,.62.09-.18-.1-.43-.21-.58,0a.84.84,0,0,0-.12.59,2.79,2.79,0,0,0,.39,1.18h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.11,32.85a2.91,2.91,0,0,1-.43-1.26.91.91,0,0,1,.13-.66c.19-.19.45-.06.63,0-.19-.07-.45-.16-.58,0a.82.82,0,0,0-.06.6,2.94,2.94,0,0,0,.51,1.14h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.48,41.89A3.1,3.1,0,0,1,18,40.64a.9.9,0,0,1,.1-.66c.18-.2.45-.08.63,0-.19-.06-.46-.14-.58,0a.82.82,0,0,0,0,.6,2.76,2.76,0,0,0,.55,1.11h0a.13.13,0,0,1-.2.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.25,47.84a3.08,3.08,0,0,1-.5-1.23.85.85,0,0,1,.09-.66c.18-.21.45-.09.63,0-.19-.06-.46-.13-.58,0a.82.82,0,0,0,0,.6,2.81,2.81,0,0,0,.58,1.11h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.82,56.45a2.94,2.94,0,0,1-.39-1.28.86.86,0,0,1,.15-.65c.19-.18.45,0,.62,0-.18-.08-.44-.18-.57,0a.75.75,0,0,0-.08.59A2.92,2.92,0,0,0,20,56.31h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.5,61.61a3,3,0,0,1-.31-1.3.84.84,0,0,1,.19-.64c.2-.18.45,0,.62.08-.18-.09-.44-.2-.58,0a.79.79,0,0,0-.11.59,2.83,2.83,0,0,0,.41,1.17h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.54,66.74a3.15,3.15,0,0,1-.25-1.31.87.87,0,0,1,.23-.63c.21-.17.45,0,.61.11-.17-.1-.43-.22-.57-.07a.8.8,0,0,0-.14.59,2.88,2.88,0,0,0,.35,1.19h0a.13.13,0,0,1-.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.11,71.06a2.91,2.91,0,0,1-.18-1.33.84.84,0,0,1,.25-.61c.22-.16.45,0,.61.14-.17-.11-.41-.24-.57-.09a.78.78,0,0,0-.17.57A2.9,2.9,0,0,0,19.33,71h0a.11.11,0,0,1,0,.16.12.12,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.75,75.37A3,3,0,0,1,19.62,74a.88.88,0,0,1,.29-.61c.22-.14.45,0,.6.17-.17-.11-.4-.26-.57-.12a.79.79,0,0,0-.19.57A2.79,2.79,0,0,0,20,75.27h0a.12.12,0,0,1-.07.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.49,78.12a3,3,0,0,1-.08-1.33.83.83,0,0,1,.3-.6c.23-.14.45.06.59.19-.16-.12-.39-.28-.56-.14a.84.84,0,0,0-.21.56A3,3,0,0,0,19.72,78h0a.14.14,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.75,79.89a3.2,3.2,0,0,1-.06-1.34A.86.86,0,0,1,20,78c.23-.13.45.07.6.2-.16-.12-.39-.28-.56-.15a.8.8,0,0,0-.22.56A2.69,2.69,0,0,0,20,79.8h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,80.82a3,3,0,0,1-.06-1.34.88.88,0,0,1,.31-.59c.23-.13.45.07.59.2-.16-.12-.39-.28-.56-.14a.81.81,0,0,0-.22.55,3,3,0,0,0,.17,1.23h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.81,79.65a3,3,0,0,1-.08-1.33.83.83,0,0,1,.3-.6c.23-.14.45.06.59.19-.16-.12-.39-.28-.56-.14a.79.79,0,0,0-.21.56A3,3,0,0,0,22,79.56h0a.14.14,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.83,77.28a3,3,0,0,1-.1-1.33.84.84,0,0,1,.29-.6c.23-.14.45.05.6.18-.17-.12-.4-.27-.57-.13a.84.84,0,0,0-.2.57,2.81,2.81,0,0,0,.22,1.22h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.16-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.19,72.21A3,3,0,0,1,20,70.89a.85.85,0,0,1,.26-.62c.22-.15.45,0,.61.15-.17-.11-.42-.25-.57-.1a.81.81,0,0,0-.18.57,3,3,0,0,0,.27,1.22h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.93,68.82a3,3,0,0,1-.22-1.32.84.84,0,0,1,.23-.62c.22-.17.45,0,.61.12-.17-.1-.42-.23-.57-.07a.78.78,0,0,0-.15.57,2.94,2.94,0,0,0,.32,1.21h0a.12.12,0,0,1-.06.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.35,65.79a3,3,0,0,1-.23-1.31.87.87,0,0,1,.23-.63c.21-.17.45,0,.61.12-.17-.1-.42-.23-.57-.07a.76.76,0,0,0-.15.58,2.9,2.9,0,0,0,.33,1.2h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.69,62.25a3,3,0,0,1-.2-1.32.87.87,0,0,1,.24-.62c.22-.16.45,0,.61.13-.17-.1-.41-.23-.57-.08a.81.81,0,0,0-.16.58,2.91,2.91,0,0,0,.3,1.2h0a.13.13,0,0,1-.06.17.14.14,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.3,62.94a3.19,3.19,0,0,1-.16-1.33A.89.89,0,0,1,26.4,61c.23-.16.46,0,.61.15-.17-.11-.41-.25-.57-.1a.75.75,0,0,0-.17.57,3,3,0,0,0,.26,1.22h0a.12.12,0,1,1-.22.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,62.55a3.14,3.14,0,0,1-.28-1.3.88.88,0,0,1,.2-.64c.21-.17.46,0,.62.1-.17-.1-.43-.21-.57-.05a.77.77,0,0,0-.13.58,2.87,2.87,0,0,0,.38,1.19h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.35,64.27A3.15,3.15,0,0,1,23.07,63a.89.89,0,0,1,.21-.63c.21-.18.45,0,.62.1-.18-.1-.43-.22-.58-.06a.84.84,0,0,0-.12.59,2.72,2.72,0,0,0,.37,1.19h0a.12.12,0,1,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.7,68.71a3,3,0,0,1-.22-1.32.84.84,0,0,1,.24-.62c.22-.16.45,0,.61.13-.17-.11-.42-.24-.57-.08a.78.78,0,0,0-.15.57,2.78,2.78,0,0,0,.31,1.21h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.08,72.08a3.16,3.16,0,0,1-.18-1.32.85.85,0,0,1,.26-.62c.22-.16.45,0,.6.15-.16-.11-.41-.25-.57-.1a.86.86,0,0,0-.17.57A3,3,0,0,0,22.31,72h0a.13.13,0,1,1-.23.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.09,74A3,3,0,0,1,19,72.63a.82.82,0,0,1,.27-.61c.23-.15.45,0,.61.16-.17-.11-.41-.25-.57-.11a.79.79,0,0,0-.19.57,2.8,2.8,0,0,0,.24,1.22h0a.13.13,0,0,1-.06.17A.12.12,0,0,1,19.1,74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.29,74.42a3,3,0,0,1-.14-1.33.86.86,0,0,1,.27-.61c.22-.15.45,0,.6.16-.16-.11-.4-.25-.56-.11a.76.76,0,0,0-.19.57,3,3,0,0,0,.25,1.22h0a.12.12,0,1,1-.22.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.56,73a3,3,0,0,1-.13-1.33.86.86,0,0,1,.27-.61c.23-.15.45,0,.61.17-.17-.12-.41-.26-.57-.12a.81.81,0,0,0-.19.57,2.8,2.8,0,0,0,.24,1.22h0a.12.12,0,0,1-.22.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.21,68A3,3,0,0,1,19,66.73a.91.91,0,0,1,.23-.63c.22-.16.46,0,.62.12-.17-.1-.42-.23-.58-.07a.76.76,0,0,0-.14.58,2.89,2.89,0,0,0,.32,1.2h0a.12.12,0,0,1-.05.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.36,59.17A3,3,0,0,1,20,57.88a.86.86,0,0,1,.17-.64c.2-.19.45,0,.62.06-.18-.09-.44-.19-.57,0a.79.79,0,0,0-.1.59A3,3,0,0,0,20.57,59h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.06,51.15a3.1,3.1,0,0,1-.48-1.25.85.85,0,0,1,.11-.66c.17-.2.44-.08.62,0-.19-.07-.46-.14-.57,0a.78.78,0,0,0,0,.6A3,3,0,0,0,12.26,51h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.75,41.85a3.07,3.07,0,0,1-.59-1.2A.84.84,0,0,1,8.21,40c.16-.22.44-.12.62-.06-.19,0-.47-.1-.57.09a.78.78,0,0,0,0,.6,3,3,0,0,0,.65,1.06h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.53,33.32a3,3,0,0,1-.7-1.14.86.86,0,0,1,0-.67c.14-.23.42-.16.62-.11-.2,0-.48-.06-.56.14a.82.82,0,0,0,.07.59,3,3,0,0,0,.75,1h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.16,26.35a3.15,3.15,0,0,1-.73-1.12.87.87,0,0,1,0-.67c.13-.23.42-.17.61-.13-.2,0-.48,0-.56.16a.79.79,0,0,0,.1.59,2.83,2.83,0,0,0,.77,1h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M2.92,21.84a2.93,2.93,0,0,1-.71-1.13.86.86,0,0,1,0-.67c.13-.23.42-.16.61-.12-.2,0-.48-.05-.56.15a.78.78,0,0,0,.08.59,3,3,0,0,0,.75,1h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M3,18.25a3.16,3.16,0,0,1-.62-1.19.8.8,0,0,1,0-.66c.15-.23.43-.13.62-.08-.2,0-.48-.08-.57.11a.79.79,0,0,0,0,.6,2.69,2.69,0,0,0,.68,1h0a.13.13,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M3.23,14.66a2.9,2.9,0,0,1-.5-1.24.85.85,0,0,1,.1-.66c.17-.21.44-.09.62,0-.19-.07-.46-.14-.57.05a.79.79,0,0,0,0,.59,2.93,2.93,0,0,0,.57,1.11h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.58,15.21A3.1,3.1,0,0,1,4.1,14a.85.85,0,0,1,.11-.66c.17-.2.44-.08.62,0-.19-.07-.46-.14-.57,0a.78.78,0,0,0,0,.6,3,3,0,0,0,.56,1.11h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.53,13.82a3,3,0,0,1-.43-1.27.9.9,0,0,1,.14-.65c.19-.19.45-.06.62,0-.18-.08-.45-.17-.57,0a.82.82,0,0,0-.06.6,2.82,2.82,0,0,0,.5,1.13h0a.12.12,0,0,1,0,.17.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M2.79,16.15a3.15,3.15,0,0,1-.56-1.22.85.85,0,0,1,.06-.66c.16-.21.44-.11.62,0-.19-.06-.46-.11-.57.08a.76.76,0,0,0,0,.59A2.93,2.93,0,0,0,3,16H3a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M4.18,17.08a2.8,2.8,0,0,1-.56-1.21.85.85,0,0,1,.06-.66c.16-.22.44-.11.62-.05-.19-.05-.46-.11-.57.08a.78.78,0,0,0,0,.6,2.91,2.91,0,0,0,.62,1.08h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M3.92,20.75a3.09,3.09,0,0,1-.67-1.16.91.91,0,0,1,0-.67c.15-.23.43-.14.62-.09-.2,0-.48-.07-.56.12a.79.79,0,0,0,.05.6,2.86,2.86,0,0,0,.72,1h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M5.3,28.28a3.18,3.18,0,0,1-.73-1.13.86.86,0,0,1,0-.67c.13-.23.42-.16.61-.12-.2,0-.48-.05-.56.15a.77.77,0,0,0,.09.59,2.75,2.75,0,0,0,.77,1h0a.12.12,0,0,1-.15.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.94,31.7a3.14,3.14,0,0,1-.7-1.14.86.86,0,0,1,0-.67c.14-.23.42-.16.61-.11-.19,0-.47-.06-.56.14a.78.78,0,0,0,.08.59,2.75,2.75,0,0,0,.75,1h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.94,36.27A3,3,0,0,1,8.3,35.1a.87.87,0,0,1,0-.67c.15-.22.43-.13.62-.08-.2,0-.47-.08-.57.12a.77.77,0,0,0,.05.59,3,3,0,0,0,.69,1h0a.13.13,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.45,40a3.13,3.13,0,0,1-.59-1.2.89.89,0,0,1,.05-.66c.16-.22.44-.12.63-.06-.2,0-.47-.1-.57.09a.83.83,0,0,0,0,.6,3,3,0,0,0,.65,1.06h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12,41.24A3.15,3.15,0,0,1,11.46,40a.85.85,0,0,1,.06-.66c.16-.22.44-.11.62,0-.19-.06-.46-.11-.57.07a.78.78,0,0,0,0,.6,2.93,2.93,0,0,0,.63,1.08h0a.12.12,0,1,1-.18.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.42,41.7a3,3,0,0,1-.58-1.21.85.85,0,0,1,.06-.66c.16-.22.43-.12.62,0-.19-.05-.47-.11-.57.08a.83.83,0,0,0,0,.6,3,3,0,0,0,.64,1.07h0a.14.14,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.94,38.61a3,3,0,0,1-.6-1.19.92.92,0,0,1,0-.67c.16-.21.44-.12.62-.06-.19,0-.47-.09-.57.1a.84.84,0,0,0,0,.6,2.8,2.8,0,0,0,.66,1h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.65,30.87A3.07,3.07,0,0,1,8,29.71.87.87,0,0,1,8,29c.14-.23.43-.14.61-.09-.19,0-.47-.08-.56.12a.79.79,0,0,0,.06.6,2.93,2.93,0,0,0,.71,1h0a.13.13,0,1,1-.17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.77,21.27a3,3,0,0,1-.54-1.22.92.92,0,0,1,.07-.67c.17-.21.44-.1.63,0-.2-.05-.47-.12-.58.07a.83.83,0,0,0,0,.6A2.93,2.93,0,0,0,9,21.11H9a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.37,14.92a3,3,0,0,1-.29-1.3.88.88,0,0,1,.2-.64c.2-.17.45,0,.62.09-.18-.09-.44-.21-.58,0a.81.81,0,0,0-.12.58,2.89,2.89,0,0,0,.39,1.19h0a.12.12,0,1,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.13,10.4a3,3,0,0,1-.07-1.33.86.86,0,0,1,.31-.59c.23-.14.45.06.59.19-.16-.12-.39-.28-.56-.14a.81.81,0,0,0-.22.56,2.86,2.86,0,0,0,.18,1.23h0a.12.12,0,0,1-.07.17.14.14,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.91,8.22A3,3,0,0,1,10,6.89a.83.83,0,0,1,.38-.55c.24-.11.43.12.56.27-.14-.14-.35-.33-.54-.21a.75.75,0,0,0-.28.52,2.78,2.78,0,0,0,0,1.25h0a.12.12,0,0,1-.09.15.11.11,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.06,6.88a3.12,3.12,0,0,1,.18-1.33A.87.87,0,0,1,10.66,5c.25-.09.42.14.54.3-.13-.15-.33-.35-.52-.24a.79.79,0,0,0-.32.5,2.79,2.79,0,0,0,0,1.25h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.46,8.26a2.88,2.88,0,0,1,0-1.34.85.85,0,0,1,.35-.57c.24-.12.44.1.57.24-.15-.13-.37-.31-.54-.18A.8.8,0,0,0,8.61,7,2.88,2.88,0,0,0,8.7,8.19h0a.13.13,0,0,1-.24.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.07,12.07A3.18,3.18,0,0,1,10,10.74a.88.88,0,0,1,.29-.6c.23-.15.45,0,.6.17-.16-.11-.4-.26-.56-.12a.75.75,0,0,0-.2.56A2.83,2.83,0,0,0,10.3,12h0a.12.12,0,0,1-.06.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.35,20.54a3.17,3.17,0,0,1-.47-1.25.86.86,0,0,1,.12-.66c.18-.2.44-.07.62,0-.19-.07-.45-.15-.57,0a.78.78,0,0,0,0,.6,2.66,2.66,0,0,0,.54,1.12h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.14,27.73a2.94,2.94,0,0,1-.61-1.19.84.84,0,0,1,0-.66c.15-.22.43-.13.62-.07-.19,0-.47-.09-.57.1a.84.84,0,0,0,0,.6,2.82,2.82,0,0,0,.67,1h0a.13.13,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13,35.65a3,3,0,0,1-.55-1.22.81.81,0,0,1,.07-.66c.16-.22.44-.11.62,0-.19-.06-.47-.11-.57.07a.78.78,0,0,0,0,.6,2.91,2.91,0,0,0,.62,1.08h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.58,44.23A3,3,0,0,1,16.07,43a.89.89,0,0,1,.1-.66c.17-.21.44-.09.62,0-.19-.06-.46-.13-.57,0a.78.78,0,0,0,0,.6,2.82,2.82,0,0,0,.57,1.1h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.38,46.63a3.18,3.18,0,0,1-.5-1.24.89.89,0,0,1,.1-.66c.17-.21.44-.09.62,0-.19-.06-.46-.14-.57.05a.82.82,0,0,0,0,.6,2.84,2.84,0,0,0,.58,1.1h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.17,48.67a3.06,3.06,0,0,1-.49-1.25.91.91,0,0,1,.1-.66c.18-.2.45-.08.63,0-.19-.07-.46-.14-.58,0a.82.82,0,0,0,0,.6,2.93,2.93,0,0,0,.57,1.11h0a.13.13,0,0,1-.19.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.24,47.81a3,3,0,0,1-.48-1.24.85.85,0,0,1,.1-.66c.18-.2.44-.08.62,0-.19-.07-.46-.14-.57,0a.8.8,0,0,0,0,.59,2.82,2.82,0,0,0,.56,1.12h0a.12.12,0,1,1-.19.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.8,46a3,3,0,0,1-.49-1.24.85.85,0,0,1,.11-.66c.17-.21.44-.08.62,0-.19-.06-.46-.14-.57,0a.71.71,0,0,0,0,.59A2.88,2.88,0,0,0,18,45.87h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.19,33.1a3,3,0,0,1-.48-1.25.91.91,0,0,1,.11-.66c.18-.2.45-.07.63,0-.19-.07-.46-.15-.58,0a.82.82,0,0,0,0,.6A2.87,2.87,0,0,0,16.39,33h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.49,13.36A3.11,3.11,0,0,1,18.7,12a.88.88,0,0,1,.42-.51c.26-.09.43.15.54.31-.13-.15-.32-.35-.51-.25a.75.75,0,0,0-.33.49,3,3,0,0,0-.09,1.25h0a.13.13,0,0,1-.11.15.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.56,18.45a3,3,0,0,1,0-1.33.87.87,0,0,1,.32-.59c.23-.14.44.07.59.2-.16-.12-.39-.28-.56-.15a.81.81,0,0,0-.23.55,2.88,2.88,0,0,0,.17,1.24h0a.14.14,0,0,1-.07.16.14.14,0,0,1-.17-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.93,16A3,3,0,0,1,18,14.69a.87.87,0,0,1,.37-.56c.25-.11.44.11.57.26-.14-.14-.36-.32-.54-.2a.81.81,0,0,0-.28.53A2.88,2.88,0,0,0,18.17,16h0a.12.12,0,1,1-.24.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,20.2a3,3,0,0,1-.06-1.33.82.82,0,0,1,.31-.59c.23-.14.44.06.59.19-.16-.12-.39-.28-.56-.14a.77.77,0,0,0-.22.56,3,3,0,0,0,.17,1.23h0a.13.13,0,1,1-.24.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.22,13.78a3.07,3.07,0,0,1,.26-1.31.9.9,0,0,1,.44-.5c.26-.08.42.17.53.33-.12-.15-.31-.36-.51-.27a.8.8,0,0,0-.34.49,2.91,2.91,0,0,0-.13,1.24h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.76,15.34A3.09,3.09,0,0,1,19.94,14a.9.9,0,0,1,.41-.53c.26-.09.43.15.55.3-.13-.15-.33-.34-.52-.24a.78.78,0,0,0-.32.51A2.89,2.89,0,0,0,20,15.3h0a.12.12,0,0,1-.1.14.12.12,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.47,18.15a2.94,2.94,0,0,1,.14-1.32.84.84,0,0,1,.4-.54c.25-.1.43.13.55.28-.14-.14-.34-.33-.53-.22a.79.79,0,0,0-.3.51,2.78,2.78,0,0,0,0,1.25h0a.11.11,0,0,1-.09.15.12.12,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.53,20a3.18,3.18,0,0,1,0-1.34.87.87,0,0,1,.35-.57c.24-.12.44.1.58.24-.15-.14-.37-.31-.55-.18a.75.75,0,0,0-.25.54,2.88,2.88,0,0,0,.09,1.24h0a.12.12,0,0,1-.09.15.12.12,0,0,1-.15-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.09,25.33A3.15,3.15,0,0,1,22,24a.86.86,0,0,1,.31-.6c.23-.13.44.07.59.2-.16-.12-.39-.28-.56-.14a.76.76,0,0,0-.22.55,2.88,2.88,0,0,0,.18,1.24h0a.12.12,0,0,1-.07.16.11.11,0,0,1-.16-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.38,27.92a3,3,0,0,1,.07-1.33.87.87,0,0,1,.37-.56c.25-.11.44.11.57.26-.14-.14-.36-.32-.54-.2a.81.81,0,0,0-.28.53,2.88,2.88,0,0,0,.05,1.24h0a.12.12,0,0,1-.09.15.12.12,0,0,1-.15-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.62,29.28A3.13,3.13,0,0,1,24.68,28a.85.85,0,0,1,.36-.56c.25-.12.44.1.58.25-.15-.14-.37-.32-.55-.2a.81.81,0,0,0-.27.54,2.88,2.88,0,0,0,.06,1.24h0a.12.12,0,0,1-.08.15.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,25.07a3,3,0,0,1,.32-1.3.88.88,0,0,1,.47-.48c.26-.06.41.2.51.36-.12-.16-.29-.38-.49-.3a.89.89,0,0,0-.38.47,2.77,2.77,0,0,0-.18,1.23h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.26,13.69a3,3,0,0,1,.5-1.23.84.84,0,0,1,.53-.41c.27,0,.38.25.46.43-.1-.18-.24-.42-.45-.37a.78.78,0,0,0-.43.41,2.89,2.89,0,0,0-.37,1.2h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.13,14.58a3,3,0,0,1,.63-1.18.86.86,0,0,1,.57-.35c.27,0,.35.28.41.47-.08-.19-.19-.44-.41-.41a.74.74,0,0,0-.47.37,2.73,2.73,0,0,0-.48,1.14h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.68,12.41a3,3,0,0,1,.63-1.17.89.89,0,0,1,.57-.35c.27,0,.35.29.41.47-.07-.18-.19-.44-.41-.41a.79.79,0,0,0-.47.36,3,3,0,0,0-.49,1.15h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,9.23A2.94,2.94,0,0,1,51.61,8a.83.83,0,0,1,.56-.36c.26,0,.36.27.42.46-.08-.19-.21-.44-.42-.4a.78.78,0,0,0-.46.38,3,3,0,0,0-.45,1.16h0a.12.12,0,0,1-.14.11A.14.14,0,0,1,51,9.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.66,14.85a3.06,3.06,0,0,1,.61-1.19.91.91,0,0,1,.57-.36c.27,0,.35.29.41.47-.07-.18-.2-.44-.41-.4s-.35.21-.47.37a2.79,2.79,0,0,0-.46,1.15h0a.12.12,0,0,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.86,13.61a3,3,0,0,1,.55-1.22A.84.84,0,0,1,64,12c.27,0,.37.26.44.44-.09-.18-.22-.42-.43-.38a.8.8,0,0,0-.45.4,2.81,2.81,0,0,0-.4,1.18h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.18,9.36a3.09,3.09,0,0,1,.46-1.26.88.88,0,0,1,.52-.42c.26,0,.38.24.47.41-.1-.17-.26-.41-.46-.35s-.32.25-.42.43a2.86,2.86,0,0,0-.32,1.2h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.21,14.94a3.14,3.14,0,0,1,.57-1.21.86.86,0,0,1,.55-.37c.27,0,.36.26.43.45-.08-.19-.21-.43-.43-.39s-.33.22-.45.39A2.94,2.94,0,0,0,67.46,15h0a.14.14,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.83,22.58a2.94,2.94,0,0,1,.78-1.08.85.85,0,0,1,.62-.27c.26,0,.3.33.34.52,0-.19-.14-.46-.35-.46a.78.78,0,0,0-.52.3,2.78,2.78,0,0,0-.63,1.07h0a.12.12,0,0,1-.15.08.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,27a3.15,3.15,0,0,1,.85-1,.91.91,0,0,1,.63-.23c.26.06.29.36.3.55,0-.2-.09-.47-.31-.48a.81.81,0,0,0-.54.26,3,3,0,0,0-.7,1h0a.12.12,0,0,1-.17.07A.13.13,0,0,1,71,27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.12,35.29a3,3,0,0,1,.69-1.15.88.88,0,0,1,.59-.31c.26,0,.33.3.38.49-.06-.19-.17-.45-.39-.43a.78.78,0,0,0-.49.34,2.84,2.84,0,0,0-.54,1.12h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.52,39.71a3.13,3.13,0,0,1,.61-1.19.84.84,0,0,1,.56-.37c.26,0,.35.28.42.47-.08-.19-.21-.44-.42-.41a.83.83,0,0,0-.47.38,3,3,0,0,0-.45,1.16h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.62,41.69a3.14,3.14,0,0,1,.57-1.21.86.86,0,0,1,.55-.37c.27,0,.37.26.43.45-.08-.18-.21-.43-.42-.39s-.34.22-.46.39a2.75,2.75,0,0,0-.42,1.17h0a.12.12,0,0,1-.13.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,41a3.11,3.11,0,0,1,.6-1.19.92.92,0,0,1,.56-.36c.27,0,.36.28.42.47-.07-.19-.2-.44-.41-.41a.79.79,0,0,0-.47.38,3,3,0,0,0-.46,1.16h0a.12.12,0,0,1-.14.11A.12.12,0,0,1,65,41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.59,38.83a2.85,2.85,0,0,1,.85-1,.83.83,0,0,1,.62-.23c.27,0,.29.35.31.54,0-.19-.1-.47-.32-.48a.77.77,0,0,0-.53.26,2.74,2.74,0,0,0-.7,1h0a.12.12,0,0,1-.16.07.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.91,38.58a3.07,3.07,0,0,1,1.1-.76.89.89,0,0,1,.66,0c.24.13.19.42.15.61,0-.2,0-.48-.17-.55a.78.78,0,0,0-.59.11,2.73,2.73,0,0,0-1,.8h0a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.8,39.14a3.11,3.11,0,0,1,1.2-.58.92.92,0,0,1,.67.06c.21.16.11.44,0,.62,0-.19.1-.47-.09-.57a.83.83,0,0,0-.6,0,3,3,0,0,0-1.06.64h0a.13.13,0,0,1-.18,0,.14.14,0,0,1,0-.18Z"/>
      </g>}
      {r_type_hair >= 8 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.13,100.93a5,5,0,0,1-.36-1.07,4.38,4.38,0,0,0,.5,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.71,102.53a4.44,4.44,0,0,1-.44-1,4.07,4.07,0,0,0,.57.94.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.9,101.39a4.68,4.68,0,0,1-.32-1.07,4.5,4.5,0,0,0,.45,1,.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,100.33a5.25,5.25,0,0,1-.18-1.11,4.83,4.83,0,0,0,.33,1.06.08.08,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,101.22a4.67,4.67,0,0,1-.22-1.1,4.74,4.74,0,0,0,.36,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.14,100.5a5,5,0,0,1-.49-1,4.74,4.74,0,0,0,.61.92.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.23,101.39a4.71,4.71,0,0,1-.51-1,4.91,4.91,0,0,0,.63.91.07.07,0,1,1-.11.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.79,102.1a4.52,4.52,0,0,1-.5-1,4.43,4.43,0,0,0,.62.91.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.52,102.06a4.62,4.62,0,0,1-.29-1.09,4.59,4.59,0,0,0,.43,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.11,100.77a4.59,4.59,0,0,1-.43-1,4,4,0,0,0,.56,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.62,101.63a4.45,4.45,0,0,1-.42-1,4.13,4.13,0,0,0,.55,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.06,100.07A4.42,4.42,0,0,1,31.73,99a4.15,4.15,0,0,0,.47,1,.08.08,0,0,1,0,.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.61,102.38a4.18,4.18,0,0,1-.37-1.06,4.31,4.31,0,0,0,.5,1,.06.06,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.07,100.42a4.15,4.15,0,0,1-.27-1.09,4.18,4.18,0,0,0,.41,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.87,99.76a5.15,5.15,0,0,1-.42-1,4.28,4.28,0,0,0,.55,1,.07.07,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.36,99.38a4.27,4.27,0,0,1-.21-1.11,4.18,4.18,0,0,0,.35,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.55,98.85a4.45,4.45,0,0,1-.34-1.07,4.82,4.82,0,0,0,.47,1,.07.07,0,0,1-.12.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.64,98.38a4.5,4.5,0,0,1-.23-1.09,4.11,4.11,0,0,0,.38,1,.09.09,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.44,97.38a4.16,4.16,0,0,1-.13-1.11,4.33,4.33,0,0,0,.28,1.07.1.1,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.54,96.7a4.8,4.8,0,0,1-.2-1.11,4.61,4.61,0,0,0,.34,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.07,96.75a4.62,4.62,0,0,1-.32-1.08,4.61,4.61,0,0,0,.46,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.76,95.64a4.69,4.69,0,0,1-.23-1.1,4.33,4.33,0,0,0,.38,1,.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.15,95.68a4.45,4.45,0,0,1-.37-1.06,4.6,4.6,0,0,0,.5,1,.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.17,94.45a4.13,4.13,0,0,1-.17-1.1,4.7,4.7,0,0,0,.31,1.05.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.39,94.54a4.54,4.54,0,0,1-.32-1.07,4.12,4.12,0,0,0,.46,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.88,93.57a4.13,4.13,0,0,1-.26-1.09,4.16,4.16,0,0,0,.4,1,.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.48,92.84a4.18,4.18,0,0,1-.2-1.1,4.22,4.22,0,0,0,.34,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,93.45a4.46,4.46,0,0,1-.4-1,4.78,4.78,0,0,0,.53,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.1,92.55a5.27,5.27,0,0,1-.3-1.08,4.76,4.76,0,0,0,.44,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.66,92.45a4.2,4.2,0,0,1-.4-1,3.91,3.91,0,0,0,.53,1,.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.91,91.35a4.67,4.67,0,0,1-.22-1.1,4.41,4.41,0,0,0,.36,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.3,91.38A4.45,4.45,0,0,1,29,90.31a4.39,4.39,0,0,0,.48,1,.07.07,0,0,1,0,.1.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,90.46a4.73,4.73,0,0,1-.28-1.09,5.06,5.06,0,0,0,.41,1,.07.07,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.77,90.93a4.48,4.48,0,0,1-.43-1,3.77,3.77,0,0,0,.56,1s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.22,89.33A4.5,4.5,0,0,1,30,88.24a4.51,4.51,0,0,0,.37,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.75,89.9a4.55,4.55,0,0,1-.35-1.06,4.53,4.53,0,0,0,.48,1,.06.06,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.85,89.9a4.42,4.42,0,0,1-.41-1,4.31,4.31,0,0,0,.54,1,.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.39,88.73a4.19,4.19,0,0,1-.29-1.09,4.56,4.56,0,0,0,.42,1,.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.9,89.54a4.44,4.44,0,0,1-.44-1,4.29,4.29,0,0,0,.57.94.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.82,88.54a4.17,4.17,0,0,1-.39-1.05,4.53,4.53,0,0,0,.52,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.41,87.57a4.45,4.45,0,0,1-.34-1.07,4.6,4.6,0,0,0,.48,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.8,87.8a4.38,4.38,0,0,1-.4-1,4.71,4.71,0,0,0,.53,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,87.27a4.6,4.6,0,0,1-.39-1,4.53,4.53,0,0,0,.52,1,.06.06,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.31,86.47A4.57,4.57,0,0,1,27,85.41a4.28,4.28,0,0,0,.49,1,.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.55,85.39a4.62,4.62,0,0,1-.32-1.08,4.61,4.61,0,0,0,.46,1,.08.08,0,0,1,0,.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.68,85.19a4.67,4.67,0,0,1-.29-1.08,4.27,4.27,0,0,0,.43,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,84.1A4.58,4.58,0,0,1,26.77,83a4.61,4.61,0,0,0,.41,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.08,83.6a4.67,4.67,0,0,1-.22-1.1,4.74,4.74,0,0,0,.36,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.26,82.94a4.14,4.14,0,0,1-.16-1.11,4.17,4.17,0,0,0,.3,1.06.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.66,81.85a4.18,4.18,0,0,1-.15-1.11,4.61,4.61,0,0,0,.29,1.06.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.6,80.94a4.61,4.61,0,0,1-.07-1.11,4.41,4.41,0,0,0,.22,1.08.07.07,0,0,1-.05.09.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.53,80.43a4.67,4.67,0,0,1,0-1.12,4.37,4.37,0,0,0,.19,1.08.07.07,0,0,1-.05.1.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.92,79.43a4.67,4.67,0,0,1,0-1.12,4.37,4.37,0,0,0,.19,1.09.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.4,79.14a4.67,4.67,0,0,1,0-1.12,4.37,4.37,0,0,0,.19,1.09.09.09,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.78,78.14A4.23,4.23,0,0,1,25.73,77a4.38,4.38,0,0,0,.2,1.08.09.09,0,0,1-.05.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.32,77.78a4.21,4.21,0,0,1-.05-1.12,4.38,4.38,0,0,0,.2,1.08.08.08,0,0,1-.06.1.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.12,76.76a4.22,4.22,0,0,1-.06-1.12,4.3,4.3,0,0,0,.2,1.08.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.69,76.65a4.68,4.68,0,0,1-.05-1.12,4.3,4.3,0,0,0,.2,1.08.07.07,0,0,1-.05.1.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.05,75.67A4.22,4.22,0,0,1,24,74.55a4,4,0,0,0,.21,1.08.08.08,0,0,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.09,75.05A4.7,4.7,0,0,1,25,73.93,4.43,4.43,0,0,0,25.24,75a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.08,74.87A4.22,4.22,0,0,1,23,73.75a4.47,4.47,0,0,0,.21,1.09.07.07,0,0,1-.05.09s-.08,0-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.77,73.74a4.64,4.64,0,0,1-.1-1.11,4.28,4.28,0,0,0,.25,1.07.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.59,73.56a4.23,4.23,0,0,1-.08-1.12,4.33,4.33,0,0,0,.23,1.08.08.08,0,0,1,0,.1.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.7,72.8a4.62,4.62,0,0,1-.08-1.11,4.43,4.43,0,0,0,.23,1.08.07.07,0,0,1-.05.09.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.67,72a4.65,4.65,0,0,1-.11-1.11,4.29,4.29,0,0,0,.26,1.07.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.46,71.52a4.17,4.17,0,0,1-.09-1.11,3.87,3.87,0,0,0,.24,1.07.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.55,70.91a4.7,4.7,0,0,1-.08-1.12,4.33,4.33,0,0,0,.23,1.08.07.07,0,0,1-.05.1.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.07,69.83a4.18,4.18,0,0,1-.15-1.11,4.15,4.15,0,0,0,.29,1.06.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.13,69.2A4.71,4.71,0,0,1,22,68.08a4.34,4.34,0,0,0,.29,1.07.09.09,0,0,1-.05.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,68.75a4.65,4.65,0,0,1-.11-1.11,4.52,4.52,0,0,0,.26,1.07.08.08,0,0,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.56,67.64a4.19,4.19,0,0,1-.16-1.11,4.29,4.29,0,0,0,.3,1.06.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.48,67.11A4.69,4.69,0,0,1,20.34,66a4.39,4.39,0,0,0,.29,1.06.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.6,66a4.29,4.29,0,0,1-.18-1.11,3.78,3.78,0,0,0,.33,1s0,.09,0,.1a.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.48,65.52a4.09,4.09,0,0,1-.24-1.09,4.11,4.11,0,0,0,.38,1,.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.73,64.83a4.71,4.71,0,0,1-.2-1.11,4.61,4.61,0,0,0,.34,1,.07.07,0,0,1,0,.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.38,63.93a4.13,4.13,0,0,1-.26-1.09,4.16,4.16,0,0,0,.4,1,.08.08,0,0,1-.14.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.47,63.13A4.67,4.67,0,0,1,19.21,62a4.09,4.09,0,0,0,.4,1,.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,62.22a4.62,4.62,0,0,1-.32-1.08,4.58,4.58,0,0,0,.45,1,.07.07,0,0,1,0,.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.41,61.17a4,4,0,0,1-.33-1.07,4.15,4.15,0,0,0,.47,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.76,60.09A4.4,4.4,0,0,1,19.38,59a4.31,4.31,0,0,0,.52,1,.09.09,0,0,1,0,.11s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.34,59.16a4.94,4.94,0,0,1-.4-1,4.78,4.78,0,0,0,.53,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.35,57.89a4.26,4.26,0,0,1-.44-1,4.75,4.75,0,0,0,.56,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.53,56.49a4,4,0,0,1-.48-1,3.84,3.84,0,0,0,.6.92.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.84,55.38a4.71,4.71,0,0,1-.51-1,4.91,4.91,0,0,0,.63.91.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.93,54.11a4.3,4.3,0,0,1-.53-1,4.16,4.16,0,0,0,.65.89.08.08,0,0,1-.12.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.2,53a4.54,4.54,0,0,1-.55-1,4.4,4.4,0,0,0,.66.88.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.47,51.89a4.14,4.14,0,0,1-.56-1,4.45,4.45,0,0,0,.67.88.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.45,50.51a4.27,4.27,0,0,1-.57-1,4.68,4.68,0,0,0,.68.86.07.07,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.58,49.9a4.06,4.06,0,0,1-.57-1,4.09,4.09,0,0,0,.68.86.07.07,0,1,1-.1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.51,48.84a4.07,4.07,0,0,1-.59-1,4.8,4.8,0,0,0,.7.85.07.07,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,47.9a4.48,4.48,0,0,1-.6-.95,4.11,4.11,0,0,0,.71.84.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.79,46.93a4.46,4.46,0,0,1-.61-.94,4.44,4.44,0,0,0,.72.84.07.07,0,0,1,0,.1.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.87,45.6a4.41,4.41,0,0,1-.63-.92,4.43,4.43,0,0,0,.74.82.07.07,0,0,1,0,.1.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.09,44.25a4.09,4.09,0,0,1-.65-.91,4.39,4.39,0,0,0,.75.8.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.69,42.78a4.22,4.22,0,0,1-.67-.9,4.12,4.12,0,0,0,.77.79.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.81,41.27a4.52,4.52,0,0,1-.69-.89,4.43,4.43,0,0,0,.79.77.08.08,0,1,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,40a4.46,4.46,0,0,1-.69-.88,4.37,4.37,0,0,0,.79.76A.08.08,0,1,1,24,40Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.91,38.62a4.18,4.18,0,0,1-.7-.87,4.12,4.12,0,0,0,.8.76.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.86,37.29a4.27,4.27,0,0,1-.72-.87,4.15,4.15,0,0,0,.82.75.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.05,37a4.58,4.58,0,0,1-.71-.87,4.1,4.1,0,0,0,.81.75.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.63,35.77a4.51,4.51,0,0,1-.72-.86,4.43,4.43,0,0,0,.82.74.08.08,0,0,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.43,35a4.51,4.51,0,0,1-.72-.86,4.43,4.43,0,0,0,.82.74.08.08,0,0,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.79,34.25a4.51,4.51,0,0,1-.72-.86,4.43,4.43,0,0,0,.82.74.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.15,33.53a4.77,4.77,0,0,1-.7-.87,4.74,4.74,0,0,0,.8.75.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.53,33.47a4.51,4.51,0,0,1-.72-.86,4.43,4.43,0,0,0,.82.74.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.35,33.08a4.45,4.45,0,0,1-.71-.86,4.37,4.37,0,0,0,.81.74.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.32,34.12a4.85,4.85,0,0,1-.71-.86,4.37,4.37,0,0,0,.81.74.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.68,34.26a4.51,4.51,0,0,1-.71-.87,4.72,4.72,0,0,0,.8.75.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,35a4.17,4.17,0,0,1-.71-.87,4.72,4.72,0,0,0,.8.75.07.07,0,0,1,0,.11A.09.09,0,0,1,28,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.83,35.57a4.59,4.59,0,0,1-.7-.88,4.84,4.84,0,0,0,.8.77.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.07,35.73a4.17,4.17,0,0,1-.71-.87,4.37,4.37,0,0,0,.8.75.07.07,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.32,36.5a4.86,4.86,0,0,1-.7-.87,4.72,4.72,0,0,0,.8.75.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.37,36.72a4.51,4.51,0,0,1-.71-.87,4.72,4.72,0,0,0,.8.75.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.87,37.8a4.86,4.86,0,0,1-.7-.87,5.22,5.22,0,0,0,.8.76s0,.07,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,38.46a4.46,4.46,0,0,1-.69-.88,4.37,4.37,0,0,0,.79.76.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.28,39.19a4.46,4.46,0,0,1-.69-.88,4.37,4.37,0,0,0,.79.76.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.74,40.36a4.88,4.88,0,0,1-.68-.89,4.37,4.37,0,0,0,.78.77.08.08,0,0,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.73,40.93a4.47,4.47,0,0,1-.68-.89,4.37,4.37,0,0,0,.78.77.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.84,42.07a4.26,4.26,0,0,1-.68-.9,4.49,4.49,0,0,0,.78.79.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,43.21a4.23,4.23,0,0,1-.66-.91,4.17,4.17,0,0,0,.77.8.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,43.82a4.56,4.56,0,0,1-.65-.91,4.44,4.44,0,0,0,.76.8.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.6,45.05a4.46,4.46,0,0,1-.64-.92,4.81,4.81,0,0,0,.75.81.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.56,45.59a4.76,4.76,0,0,1-.63-.93,4.43,4.43,0,0,0,.74.82.07.07,0,0,1,0,.1.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,46.6a4.14,4.14,0,0,1-.62-.94,4.14,4.14,0,0,0,.73.83.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.1,47.66a4.87,4.87,0,0,1-.59-.95,4.45,4.45,0,0,0,.71.85.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.84,47.78a4.07,4.07,0,0,1-.59-1,4.4,4.4,0,0,0,.7.85.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.36,48.9a4.67,4.67,0,0,1-.57-1,5.2,5.2,0,0,0,.69.86.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.13,49.21a4.74,4.74,0,0,1-.57-1,3.84,3.84,0,0,0,.69.86.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.4,50.12a4.73,4.73,0,0,1-.55-1,4.45,4.45,0,0,0,.67.88.07.07,0,1,1-.11.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.53,50.78a5,5,0,0,1-.55-1,4.1,4.1,0,0,0,.67.88.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.42,51.14a4.66,4.66,0,0,1-.55-1,4,4,0,0,0,.67.87.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.69,52.12a4.45,4.45,0,0,1-.54-1,4.4,4.4,0,0,0,.66.88.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.21,52.66a4.54,4.54,0,0,1-.55-1,4.82,4.82,0,0,0,.66.88.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.24,53.49a4.3,4.3,0,0,1-.53-1,4.72,4.72,0,0,0,.65.89.07.07,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.26,54.25a4.3,4.3,0,0,1-.53-1,4.16,4.16,0,0,0,.65.89.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.89,54.75a4.68,4.68,0,0,1-.52-1,4.44,4.44,0,0,0,.64.9.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.11,55.36a4.68,4.68,0,0,1-.52-1,4.44,4.44,0,0,0,.64.9.07.07,0,1,1-.11.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.2,55.89a4.26,4.26,0,0,1-.52-1,4.44,4.44,0,0,0,.64.9.08.08,0,0,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.92,56.63a4.25,4.25,0,0,1-.5-1,4.72,4.72,0,0,0,.62.91.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.88,57.11a4.25,4.25,0,0,1-.5-1A4.72,4.72,0,0,0,23,57a.07.07,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.46,58.13a4.48,4.48,0,0,1-.49-1,4.74,4.74,0,0,0,.61.92.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.06,58.52a4.06,4.06,0,0,1-.48-1,4.28,4.28,0,0,0,.6.92.08.08,0,0,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.32,59.18a4.29,4.29,0,0,1-.47-1,4.11,4.11,0,0,0,.6.93.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.43,59.74a4.26,4.26,0,0,1-.46-1,4.75,4.75,0,0,0,.58.94.06.06,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,60.41a4.47,4.47,0,0,1-.45-1,4.33,4.33,0,0,0,.58.94.09.09,0,0,1,0,.11s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.2,61.23a4.67,4.67,0,0,1-.43-1,4.75,4.75,0,0,0,.56.95.08.08,0,0,1,0,.11s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.36,61.7a4.46,4.46,0,0,1-.4-1.05,4.78,4.78,0,0,0,.53,1,.07.07,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.5,62a4.05,4.05,0,0,1-.42-1,4.49,4.49,0,0,0,.54,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.38,62.55a4.63,4.63,0,0,1-.4-1,4.78,4.78,0,0,0,.53,1,.08.08,0,0,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.21,63.12a4.4,4.4,0,0,1-.38-1,4.07,4.07,0,0,0,.51,1,.07.07,0,0,1,0,.11.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.17,63.46a4.15,4.15,0,0,1-.36-1.06,4.56,4.56,0,0,0,.49,1,.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.34,64.13A4.47,4.47,0,0,1,24,63.06a4.32,4.32,0,0,0,.48,1,.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.18,64a5.1,5.1,0,0,1-.36-1.06,3.94,3.94,0,0,0,.5,1,.09.09,0,0,1,0,.11s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.2,63.84a4.61,4.61,0,0,1-.37-1.06,4.34,4.34,0,0,0,.51,1,.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.61,62.93a4.63,4.63,0,0,1-.4-1,4.78,4.78,0,0,0,.53,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.62,62.47a4.46,4.46,0,0,1-.4-1,4,4,0,0,0,.53,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.31,61.39a4.45,4.45,0,0,1-.42-1,4.28,4.28,0,0,0,.55.95.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.84,60.39a4.26,4.26,0,0,1-.44-1,4.75,4.75,0,0,0,.56,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.46,59.08a4.91,4.91,0,0,1-.46-1,4.72,4.72,0,0,0,.59.93.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.41,58.48a4.51,4.51,0,0,1-.46-1,4.75,4.75,0,0,0,.58.94.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.29,57.34a4.41,4.41,0,0,1-.47-1,4.61,4.61,0,0,0,.6.93.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.21,56.1a4.68,4.68,0,0,1-.48-1,3.87,3.87,0,0,0,.61.92.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.23,55.48a4.91,4.91,0,0,1-.48-1,4.74,4.74,0,0,0,.61.92.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.29,54.38a4.52,4.52,0,0,1-.5-1,4.43,4.43,0,0,0,.62.91.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.33,53.54a4.25,4.25,0,0,1-.5-1,4.72,4.72,0,0,0,.62.91.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.33,52.63a4.33,4.33,0,0,1-.52-1,4.09,4.09,0,0,0,.64.9.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.6,52a4.3,4.3,0,0,1-.53-1,4.49,4.49,0,0,0,.65.9.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.54,51.08a4.45,4.45,0,0,1-.54-1,4.13,4.13,0,0,0,.66.88.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,50.73a4.48,4.48,0,0,1-.55-1,4.45,4.45,0,0,0,.67.88.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.91,49.79a4.9,4.9,0,0,1-.56-1,4.43,4.43,0,0,0,.68.87.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.33,49.21a4.74,4.74,0,0,1-.57-1,4.89,4.89,0,0,0,.69.87.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.11,48.52a4.09,4.09,0,0,1-.58-1,4.14,4.14,0,0,0,.69.86.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.09,47.82a4.29,4.29,0,0,1-.59-1,4.8,4.8,0,0,0,.7.85.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,47.13a4.07,4.07,0,0,1-.59-1,4.47,4.47,0,0,0,.7.85.07.07,0,1,1-.1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.65,46.52a4.48,4.48,0,0,1-.6-1,4.15,4.15,0,0,0,.72.84.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.83,46.14a4.48,4.48,0,0,1-.6-1,3.86,3.86,0,0,0,.71.84.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.1,45.08a4.23,4.23,0,0,1-.63-.93,4.46,4.46,0,0,0,.74.82.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.32,45.05a4.93,4.93,0,0,1-.62-.94,4.48,4.48,0,0,0,.73.83.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.4,44.53a4.48,4.48,0,0,1-.63-.93,4.41,4.41,0,0,0,.73.82.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.74,43.59a4.73,4.73,0,0,1-.65-.91,4.39,4.39,0,0,0,.75.8.07.07,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.32,43.36a4.46,4.46,0,0,1-.64-.92,4.81,4.81,0,0,0,.75.81.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.32,42.59a4.86,4.86,0,0,1-.65-.91,4.12,4.12,0,0,0,.76.8.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,42.49a4.46,4.46,0,0,1-.64-.92,4.45,4.45,0,0,0,.75.81.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,41.69a4.43,4.43,0,0,1-.66-.9,4.43,4.43,0,0,0,.77.79.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,41.35a4.43,4.43,0,0,1-.66-.9,4.43,4.43,0,0,0,.77.79.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.14,41a4.34,4.34,0,0,1-.65-.91,4.12,4.12,0,0,0,.76.8s0,.07,0,.11a.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.44,40.07a5.26,5.26,0,0,1-.67-.9,4.43,4.43,0,0,0,.78.78.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.47,39.7a4.48,4.48,0,0,1-.67-.9,4.43,4.43,0,0,0,.77.79.07.07,0,0,1,0,.1s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.42,39.22a4.23,4.23,0,0,1-.66-.91,4.5,4.5,0,0,0,.77.8.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.76,38.24a4.43,4.43,0,0,1-.66-.9,4.12,4.12,0,0,0,.77.79.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.54,37.82a4.42,4.42,0,0,1-.67-.89,4.74,4.74,0,0,0,.77.78.08.08,0,1,1-.09.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.16,36.91a4.42,4.42,0,0,1-.67-.89,4.43,4.43,0,0,0,.78.78.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.29,36.59a4.43,4.43,0,0,1-.66-.9,4.12,4.12,0,0,0,.77.79.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.24,35.7a4.42,4.42,0,0,1-.67-.89,4.74,4.74,0,0,0,.77.78.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,34.7a4.88,4.88,0,0,1-.68-.89,4.8,4.8,0,0,0,.78.78s0,.07,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.48,34.84a4.17,4.17,0,0,1-.66-.9,4.12,4.12,0,0,0,.77.79.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,33.85a4.78,4.78,0,0,1-.67-.9,4.06,4.06,0,0,0,.77.78.07.07,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.35,32.77a4.88,4.88,0,0,1-.68-.89,4.8,4.8,0,0,0,.78.78.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.91,31.73a4.52,4.52,0,0,1-.69-.89,4.43,4.43,0,0,0,.79.77.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.08,32.54a5.21,5.21,0,0,1-.65-.91,4.38,4.38,0,0,0,.76.79.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.49,31.41a4.43,4.43,0,0,1-.66-.9,4.74,4.74,0,0,0,.76.79.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.87,30.55a3.9,3.9,0,0,1-.67-.9,4.12,4.12,0,0,0,.77.79.08.08,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.92,31.54a4.46,4.46,0,0,1-.64-.92,4.43,4.43,0,0,0,.75.81.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.23,29.54a4.54,4.54,0,0,1-.67-.91,4.44,4.44,0,0,0,.77.79.07.07,0,0,1,0,.11.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.68,30.05a4.46,4.46,0,0,1-.64-.92,4.43,4.43,0,0,0,.75.81.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.72,30a4.48,4.48,0,0,1-.63-.93,4.13,4.13,0,0,0,.74.82.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.66,28.8a4.11,4.11,0,0,1-.64-.92,4.37,4.37,0,0,0,.74.81.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.17,27.78a5.07,5.07,0,0,1-.64-.92,4.43,4.43,0,0,0,.75.81.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35,28.5a4.14,4.14,0,0,1-.62-.94,4.14,4.14,0,0,0,.73.83.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,28.93a4.07,4.07,0,0,1-.59-.95,4.11,4.11,0,0,0,.71.84.08.08,0,0,1-.11.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.72,26.58a4.76,4.76,0,0,1-.63-.93,4.76,4.76,0,0,0,.73.82.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.84,26.69a4.11,4.11,0,0,1-.6-1,4.11,4.11,0,0,0,.71.84.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,27.25a4.72,4.72,0,0,1-.58-1,4.18,4.18,0,0,0,.7.86.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.74,25.16a4.35,4.35,0,0,1-.59-1,4.12,4.12,0,0,0,.7.85.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,25.91a4.29,4.29,0,0,1-.56-1,4.43,4.43,0,0,0,.68.87.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.78,24.83a4.29,4.29,0,0,1-.56-1,4.43,4.43,0,0,0,.68.87.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.45,23.8a4.14,4.14,0,0,1-.56-1,4.45,4.45,0,0,0,.67.88.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.17,25.87a4,4,0,0,1-.53-1,4.42,4.42,0,0,0,.65.89.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.83,22.81a4.3,4.3,0,0,1-.53-1,4.16,4.16,0,0,0,.65.89.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.69,22.13a4.52,4.52,0,0,1-.5-1,4.5,4.5,0,0,0,.62.92.07.07,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.77,24.09a4.75,4.75,0,0,1-.52-1,4.87,4.87,0,0,0,.64.9.07.07,0,1,1-.11.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.8,23.16a4.71,4.71,0,0,1-.51-1,4.4,4.4,0,0,0,.63.9.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.23,26.39a5,5,0,0,1-.51-1,4.4,4.4,0,0,0,.63.9.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.6,22.23a4.29,4.29,0,0,1-.47-1,4.45,4.45,0,0,0,.59.93s0,.09,0,.11a.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.85,23.34a4,4,0,0,1-.48-1,4.11,4.11,0,0,0,.6.93.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.39,24.13a4.29,4.29,0,0,1-.47-1,4.11,4.11,0,0,0,.6.93.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.07,25.3a4.25,4.25,0,0,1-.5-1,4.43,4.43,0,0,0,.62.91.07.07,0,1,1-.11.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.6,24.53a5,5,0,0,1-.51-1,4.4,4.4,0,0,0,.63.9.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,25.28a4.48,4.48,0,0,1-.47-1,4.35,4.35,0,0,0,.6.93.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.14,26.41a4.32,4.32,0,0,1-.48-1,4.17,4.17,0,0,0,.6.92.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.6,22.36a4.59,4.59,0,0,1-.43-1,4.32,4.32,0,0,0,.56,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.88,21.48a4.38,4.38,0,0,1-.4-1,3.91,3.91,0,0,0,.53,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.46,23a4.42,4.42,0,0,1-.41-1,4.54,4.54,0,0,0,.54,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,21.6a4.47,4.47,0,0,1-.35-1.07,4.15,4.15,0,0,0,.49,1,.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.88,24a4.27,4.27,0,0,1-.42-1,4.13,4.13,0,0,0,.55,1A.09.09,0,0,1,40,24a.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.39,22.44A5.1,5.1,0,0,1,40,21.38a4.31,4.31,0,0,0,.5,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.29,25a4.93,4.93,0,0,1-.42-1,4.13,4.13,0,0,0,.55,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.11,23A4.52,4.52,0,0,1,40.77,22a4.53,4.53,0,0,0,.48,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.93,23.92a4.57,4.57,0,0,1-.36-1.06,3.94,3.94,0,0,0,.5,1,.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.43,21.83a4.62,4.62,0,0,1-.29-1.09,4.59,4.59,0,0,0,.43,1,.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.12,22.58a4.52,4.52,0,0,1-.28-1.08,4.56,4.56,0,0,0,.42,1,.07.07,0,0,1-.13.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.4,25.32A4.56,4.56,0,0,1,41,24.27a4.49,4.49,0,0,0,.51,1s0,.09,0,.11a.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.15,24.51a4,4,0,0,1-.33-1.07,4.33,4.33,0,0,0,.46,1,.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,23.6a4.15,4.15,0,0,1-.27-1.09,4.18,4.18,0,0,0,.41,1,.08.08,0,0,1-.14.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.94,26.7a4.07,4.07,0,0,1-.38-1.06,4.34,4.34,0,0,0,.51,1,.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.77,25.8a4.21,4.21,0,0,1-.33-1.08,4.33,4.33,0,0,0,.46,1,.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,26.87a4.78,4.78,0,0,1-.35-1.07,4.32,4.32,0,0,0,.48,1,.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.21,24.48a4.27,4.27,0,0,1-.25-1.1,4.13,4.13,0,0,0,.39,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.11,25.39a4.24,4.24,0,0,1-.27-1.09,4.11,4.11,0,0,0,.41,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.37,26.42a4.54,4.54,0,0,1-.29-1.08,4.59,4.59,0,0,0,.43,1,.07.07,0,0,1-.13.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.62,24.87a4.27,4.27,0,0,1-.21-1.11,4.18,4.18,0,0,0,.35,1,.08.08,0,0,1,0,.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.37,25.9a4.18,4.18,0,0,1-.24-1.09,4.25,4.25,0,0,0,.38,1,.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.58,27.4a4.73,4.73,0,0,1-.31-1.08,5.33,5.33,0,0,0,.44,1,.07.07,0,0,1,0,.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.88,26.92a5.28,5.28,0,0,1-.24-1.1,5.07,5.07,0,0,0,.38,1A.08.08,0,0,1,46,27a.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.05,28.48a4.14,4.14,0,0,1-.3-1.08,4.33,4.33,0,0,0,.43,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.19,27.08A4.66,4.66,0,0,1,47,26,4.79,4.79,0,0,0,47.33,27a.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.57,28.35a4.27,4.27,0,0,1-.21-1.11,4.18,4.18,0,0,0,.35,1.05.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.79,28.18a4.23,4.23,0,0,1-.12-1.12,3.91,3.91,0,0,0,.27,1.07.08.08,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.48,29.51a4.12,4.12,0,0,1-.21-1.1,4.18,4.18,0,0,0,.35,1.05.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.31,29a4.24,4.24,0,0,1-.09-1.12A4.43,4.43,0,0,0,48.45,29a.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.62,30.18a5.33,5.33,0,0,1-.12-1.12,4.8,4.8,0,0,0,.27,1.07.08.08,0,0,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.05,31.1A4.18,4.18,0,0,1,46.9,30a3.8,3.8,0,0,0,.3,1.06.09.09,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.89,30.23a4.2,4.2,0,0,1,0-1.12A4.44,4.44,0,0,0,49,30.2a.09.09,0,0,1,0,.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,31.69a4.7,4.7,0,0,1-.08-1.12,4.43,4.43,0,0,0,.23,1.08.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.44,31.42a4.19,4.19,0,0,1,0-1.12,4.4,4.4,0,0,0,.13,1.09.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.41,32.85a4.77,4.77,0,0,1,0-1.13,4.37,4.37,0,0,0,.19,1.09.09.09,0,0,1-.05.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.85,32.46a4.17,4.17,0,0,1,.06-1.12,4.5,4.5,0,0,0,.09,1.1.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,33.7a4.2,4.2,0,0,1,0-1.12,4.54,4.54,0,0,0,.14,1.1.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.39,33.83a4.63,4.63,0,0,1,.11-1.12,4.49,4.49,0,0,0,0,1.1.09.09,0,0,1-.07.09.06.06,0,0,1-.08-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.53,35a4.2,4.2,0,0,1,.07-1.12,4.5,4.5,0,0,0,.08,1.1.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.12,35.06A4.59,4.59,0,0,1,51.29,34a4.51,4.51,0,0,0,0,1.1.1.1,0,0,1-.07.09.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.44,36.1A4.56,4.56,0,0,1,50.58,35a4.5,4.5,0,0,0,0,1.1.08.08,0,0,1-.07.08.06.06,0,0,1-.08-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.72,36.23A5,5,0,0,1,52,35.14a4.49,4.49,0,0,0-.07,1.1.08.08,0,0,1-.07.08.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.08,37.27a4.56,4.56,0,0,1,.14-1.11,4,4,0,0,0,0,1.1.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.3,37.46a4.63,4.63,0,0,1,.21-1.11,4.55,4.55,0,0,0-.06,1.1.08.08,0,0,1-.07.09.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.55,37.39a4.42,4.42,0,0,1,.29-1.08,4.51,4.51,0,0,0-.13,1.09.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.55,38.3a4.54,4.54,0,0,1,.29-1.08,4.54,4.54,0,0,0-.14,1.1.07.07,0,0,1-.07.07.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.87,38.44a4.45,4.45,0,0,1,.19-1.1,4.49,4.49,0,0,0,0,1.1.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.79,38.88A4.54,4.54,0,0,1,52,37.79a4.51,4.51,0,0,0-.1,1.1.08.08,0,0,1-.07.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.66,39.45a4.62,4.62,0,0,1,.2-1.11,4.59,4.59,0,0,0,0,1.11.08.08,0,0,1-.07.08.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.15,40.14a4.4,4.4,0,0,1,.28-1.08,4.51,4.51,0,0,0-.13,1.09.08.08,0,0,1-.07.08.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.54,41.1A4.45,4.45,0,0,1,51.8,40a4.07,4.07,0,0,0-.11,1.1.07.07,0,0,1-.07.07.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.38,42.07A4.46,4.46,0,0,1,52.69,41a4.09,4.09,0,0,0-.15,1.09.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.24,42.69a4.47,4.47,0,0,1,.35-1.07,4.55,4.55,0,0,0-.2,1.09.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.57,43.71a4.35,4.35,0,0,1,.32-1.08,4.56,4.56,0,0,0-.16,1.09s0,.08-.08.08-.08,0-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.45,44.53a4.91,4.91,0,0,1,.36-1.06,4.49,4.49,0,0,0-.21,1.08.08.08,0,0,1-.08.08.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.88,45.72a4.42,4.42,0,0,1,.33-1.07A4.52,4.52,0,0,0,53,45.74a.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.85,46.26a4.47,4.47,0,0,1,.38-1.06A4.15,4.15,0,0,0,54,46.28a.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.05,47.34a4.25,4.25,0,0,1,.38-1.05,4.43,4.43,0,0,0-.23,1.08.08.08,0,0,1-.08.07s-.08,0-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.62,48.16a4.37,4.37,0,0,1,.37-1,4.41,4.41,0,0,0-.22,1.08.08.08,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.37,49.05A4.8,4.8,0,0,1,54.76,48a4.55,4.55,0,0,0-.24,1.08.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.58,50.32a4.72,4.72,0,0,1,.39-1,4,4,0,0,0-.24,1.07.07.07,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.48,51.16a4.72,4.72,0,0,1,.42-1,4.52,4.52,0,0,0-.26,1.07.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.32,52.37a4.25,4.25,0,0,1,.41-1,4.08,4.08,0,0,0-.26,1.07.08.08,0,0,1-.08.07s-.08,0-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,53.56a4.53,4.53,0,0,1,.42-1,4.31,4.31,0,0,0-.27,1.07.06.06,0,0,1-.08.06.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.08,55.26a4.32,4.32,0,0,1,.41-1,4.52,4.52,0,0,0-.26,1.07s0,.08-.09.07a.06.06,0,0,1-.06-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.34,56.76a4.42,4.42,0,0,1,.41-1,4.52,4.52,0,0,0-.26,1.07.09.09,0,0,1-.09.07.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.74,58.21a4.25,4.25,0,0,1,.41-1,4.52,4.52,0,0,0-.26,1.07.09.09,0,0,1-.09.07.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.53,59.52a4.3,4.3,0,0,1,.4-1.05,4.46,4.46,0,0,0-.25,1.08.09.09,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.13,60.9a4.27,4.27,0,0,1,.39-1,4.47,4.47,0,0,0-.24,1.07.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.66,62A4.32,4.32,0,0,1,57,60.94,4.53,4.53,0,0,0,56.81,62a.08.08,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.34,63a4.25,4.25,0,0,1,.38-1A4.43,4.43,0,0,0,57.49,63a.09.09,0,0,1-.09.07.1.1,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.81,64.4a4.75,4.75,0,0,1,.37-1.06A4.51,4.51,0,0,0,57,64.42a.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.15,65.07A4.47,4.47,0,0,1,59.53,64a4.63,4.63,0,0,0-.23,1.08.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.14,66.17a4.73,4.73,0,0,1,.36-1.06,4.4,4.4,0,0,0-.21,1.08.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.6,66.37A4.57,4.57,0,0,1,60,65.31a4.12,4.12,0,0,0-.21,1.09.08.08,0,0,1-.08.07c-.05,0-.08,0-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.87,67.11a4.73,4.73,0,0,1,.36-1.06,4,4,0,0,0-.2,1.08.08.08,0,0,1-.09.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.91,67.09A4.4,4.4,0,0,1,58.26,66a4.47,4.47,0,0,0-.2,1.08.08.08,0,0,1-.08.08s-.08,0-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.47,65.9a4.28,4.28,0,0,1,.36-1.06,4.05,4.05,0,0,0-.21,1.08.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.14,64.82a4.25,4.25,0,0,1,.38-1,4.43,4.43,0,0,0-.23,1.08.08.08,0,0,1-.08.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.62,63.7A4.35,4.35,0,0,1,59,62.64a4.11,4.11,0,0,0-.24,1.08.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.49,63.26a4.27,4.27,0,0,1,.39-1,4,4,0,0,0-.24,1.07.08.08,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.48,62.12a4.25,4.25,0,0,1,.41-1,4.52,4.52,0,0,0-.26,1.07.08.08,0,0,1-.08.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.61,60.78a4.93,4.93,0,0,1,.42-1,4.1,4.1,0,0,0-.27,1.07.09.09,0,0,1-.09.07.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.89,60a4.48,4.48,0,0,1,.43-1A4.57,4.57,0,0,0,59,60a.07.07,0,0,1-.08.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.63,59a4.26,4.26,0,0,1,.44-1A4.39,4.39,0,0,0,59.78,59a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.16,58a4.26,4.26,0,0,1,.44-1A4.47,4.47,0,0,0,59.31,58a.07.07,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.91,56.81a4.73,4.73,0,0,1,.45-1,4.41,4.41,0,0,0-.3,1.06.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.72,56a4.69,4.69,0,0,1,.46-1A4.44,4.44,0,0,0,59.87,56a.07.07,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.75,55.42a4.09,4.09,0,0,1,.46-1,4.36,4.36,0,0,0-.31,1.05s0,.08-.09.07a.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.85,54.19a4.51,4.51,0,0,1,.47-1,4.39,4.39,0,0,0-.32,1,.08.08,0,0,1-.08.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.76,53.57a4.11,4.11,0,0,1,.49-1,4.3,4.3,0,0,0-.34,1,.09.09,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.73,52.92a4.47,4.47,0,0,1,.48-1A4.27,4.27,0,0,0,58.88,53a.07.07,0,0,1-.09.06.09.09,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.56,52.05a4.5,4.5,0,0,1,.49-1,4.44,4.44,0,0,0-.34,1.05.08.08,0,0,1-.09.06.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.58,50.78a4.5,4.5,0,0,1,.49-1,4.3,4.3,0,0,0-.34,1,.09.09,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.37,50.06a4.41,4.41,0,0,1,.51-1,4.74,4.74,0,0,0-.36,1,.09.09,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.48,49a5.19,5.19,0,0,1,.5-1,4.25,4.25,0,0,0-.35,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.48,47.69a4.49,4.49,0,0,1,.51-1,4.93,4.93,0,0,0-.36,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.2,46.94a4.45,4.45,0,0,1,.54-1,4,4,0,0,0-.39,1,.09.09,0,0,1-.1.06.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.19,46.19a4.45,4.45,0,0,1,.52-1,4.3,4.3,0,0,0-.37,1,.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.19,44.88a4.27,4.27,0,0,1,.53-1,4.25,4.25,0,0,0-.38,1,.08.08,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,43.61a4.48,4.48,0,0,1,.53-1,4.33,4.33,0,0,0-.38,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,42.33a4.68,4.68,0,0,1,.53-1,4.41,4.41,0,0,0-.38,1,.09.09,0,0,1-.1.06.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.38,41.45a4.52,4.52,0,0,1,.52-1,4.3,4.3,0,0,0-.37,1,.08.08,0,0,1-.1.06.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.61,40.09a4.41,4.41,0,0,1,.53-1,4.25,4.25,0,0,0-.38,1,.09.09,0,0,1-.1.06.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,38.91a4.75,4.75,0,0,1,.52-1,4.62,4.62,0,0,0-.37,1,.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.74,37.59a5.19,5.19,0,0,1,.5-1,4.25,4.25,0,0,0-.35,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,37a4.69,4.69,0,0,1,.46-1A5,5,0,0,0,56.12,37a.06.06,0,0,1-.08.06A.09.09,0,0,1,56,37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.1,35.78a4.11,4.11,0,0,1,.47-1,4.39,4.39,0,0,0-.32,1.05.09.09,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.06,34.51a4.26,4.26,0,0,1,.46-1,4,4,0,0,0-.31,1.06.08.08,0,0,1-.09.06s-.07,0-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.71,33a4.26,4.26,0,0,1,.44-1,4,4,0,0,0-.29,1.07.08.08,0,0,1-.09.06A.08.08,0,0,1,55.7,33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.5,31.21a4.48,4.48,0,0,1,.46-1,4.89,4.89,0,0,0-.32,1,.06.06,0,0,1-.08.06.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.2,30.71a4.46,4.46,0,0,1,.4-1.05,4.46,4.46,0,0,0-.25,1.08.08.08,0,0,1-.08.07.09.09,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.15,29.43a4.24,4.24,0,0,1,.43-1,3.93,3.93,0,0,0-.28,1.07.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.86,28.25a4.73,4.73,0,0,1,.45-1,4.49,4.49,0,0,0-.3,1.07.06.06,0,0,1-.08.06.07.07,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.14,28.11A4.09,4.09,0,0,1,55.5,27a4.12,4.12,0,0,0-.21,1.09.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.15,27.53a4.3,4.3,0,0,1,.4-1.05,4.49,4.49,0,0,0-.25,1.07.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,28.45a4.32,4.32,0,0,1,.41-1.05,4.52,4.52,0,0,0-.26,1.07.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.85,29.06A3.9,3.9,0,0,1,55.21,28,4.05,4.05,0,0,0,55,29.08a.07.07,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.3,30.24a4.45,4.45,0,0,1,.34-1.07,4.62,4.62,0,0,0-.19,1.09.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.54,31.52a4.42,4.42,0,0,1,.36-1.06,4.05,4.05,0,0,0-.21,1.08.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.84,31.79a4.7,4.7,0,0,1,.44-1A4.39,4.39,0,0,0,56,31.82a.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.25,32.18a4.41,4.41,0,0,1,.51-1,4.74,4.74,0,0,0-.36,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.91,33.24a4.27,4.27,0,0,1,.39-1.05,4.44,4.44,0,0,0-.24,1.08.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.08,33.19a4.71,4.71,0,0,1,.51-1,4.15,4.15,0,0,0-.36,1,.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.3,34.38a4.64,4.64,0,0,1,.42-1,4.35,4.35,0,0,0-.27,1.06.08.08,0,0,1-.09.07.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.79,34.24a4.45,4.45,0,0,1,.54-1,4.75,4.75,0,0,0-.39,1,.08.08,0,0,1-.1.05.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.58,35.39a4.45,4.45,0,0,1,.54-1,4.56,4.56,0,0,0-.39,1,.07.07,0,0,1-.09.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.39,36.14a4.49,4.49,0,0,1,.57-1,3.92,3.92,0,0,0-.42,1,.09.09,0,0,1-.1.05.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.06,37.19a4.63,4.63,0,0,1,.56-1,4.27,4.27,0,0,0-.41,1,.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,37.94a4.07,4.07,0,0,1,.59-.95,4.79,4.79,0,0,0-.45,1,.08.08,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.66,38.68a5,5,0,0,1,.61-1,4.3,4.3,0,0,0-.46,1s-.06.07-.1.05a.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.05,39.56a4.27,4.27,0,0,1,.59-1,4.33,4.33,0,0,0-.45,1,.07.07,0,0,1-.09,0,.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.38,40.24a3.87,3.87,0,0,1,.62-.93,3.78,3.78,0,0,0-.48,1,.09.09,0,0,1-.1.05.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.23,41.35a4.63,4.63,0,0,1,.58-1,4.27,4.27,0,0,0-.43,1s-.06.07-.1.05a.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.13,41.79a4.6,4.6,0,0,1,.61-.94,4.12,4.12,0,0,0-.46,1,.08.08,0,0,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.19,42.39a4.91,4.91,0,0,1,.63-.93,4.8,4.8,0,0,0-.49,1,.07.07,0,0,1-.09,0,.07.07,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.18,43.37a4.48,4.48,0,0,1,.6-.95,3.89,3.89,0,0,0-.45,1,.1.1,0,0,1-.1.06.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.31,44.37a4.87,4.87,0,0,1,.59-.95,4.06,4.06,0,0,0-.44,1,.08.08,0,0,1-.1.06.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.47,44.63a4.21,4.21,0,0,1,.61-.94,4.15,4.15,0,0,0-.47,1,.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.43,45.76a4.23,4.23,0,0,1,.58-1,4.3,4.3,0,0,0-.44,1,.07.07,0,0,1-.09,0,.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.5,46.12a5,5,0,0,1,.61-.95,4.37,4.37,0,0,0-.46,1,.09.09,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,47.27a4.87,4.87,0,0,1,.59-1,4.79,4.79,0,0,0-.45,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.93,48a4.63,4.63,0,0,1,.56-1,4.77,4.77,0,0,0-.42,1,.07.07,0,0,1-.09,0,.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.73,48.68a4.42,4.42,0,0,1,.57-1,4.27,4.27,0,0,0-.43,1,.08.08,0,0,1-.1.06.09.09,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.25,49.75a4.86,4.86,0,0,1,.57-1,4.69,4.69,0,0,0-.42,1,.09.09,0,0,1-.1.06.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.64,50.82a4.48,4.48,0,0,1,.55-1,4.31,4.31,0,0,0-.4,1,.08.08,0,0,1-.1.05.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.79,51.65a4.48,4.48,0,0,1,.55-1,4.31,4.31,0,0,0-.4,1,.08.08,0,0,1-.1,0,.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.88,52.58a4.27,4.27,0,0,1,.53-1,4.41,4.41,0,0,0-.38,1,.08.08,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.91,53.36a4.45,4.45,0,0,1,.54-1,4,4,0,0,0-.39,1,.09.09,0,0,1-.1.06.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.16,54a4.49,4.49,0,0,1,.51-1,3.89,3.89,0,0,0-.36,1,.08.08,0,0,1-.1.06.09.09,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,54.81a4.68,4.68,0,0,1,.5-1,4.39,4.39,0,0,0-.35,1,.08.08,0,0,1-.1.06.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,55.49a4.5,4.5,0,0,1,.49-1,4.44,4.44,0,0,0-.34,1.05.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,55.77a4.25,4.25,0,0,1,.5-1,4.39,4.39,0,0,0-.35,1,.07.07,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.25,56.47a5,5,0,0,1,.49-1,4.44,4.44,0,0,0-.34,1,.07.07,0,0,1-.09.06.06.06,0,0,1-.06-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.58,57.65a4.91,4.91,0,0,1,.47-1,4.25,4.25,0,0,0-.32,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.74,58.77a4.73,4.73,0,0,1,.45-1,4.55,4.55,0,0,0-.3,1.06.09.09,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.42,59.91a4.7,4.7,0,0,1,.44-1,4.39,4.39,0,0,0-.29,1.06c0,.05,0,.07-.09.07a.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.3,61.28a4.45,4.45,0,0,1,.42-1,4.1,4.1,0,0,0-.27,1.07.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.91,62.32a4.1,4.1,0,0,1,.41-1,4.29,4.29,0,0,0-.26,1.07.06.06,0,0,1-.08.06.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.23,63.66a4.3,4.3,0,0,1,.4-1,4.46,4.46,0,0,0-.25,1.08.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.85,65.13a4.32,4.32,0,0,1,.38-1.06A4.53,4.53,0,0,0,63,65.15a.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.72,66.74a3.9,3.9,0,0,1,.36-1.06,4.05,4.05,0,0,0-.21,1.08.07.07,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.34,68.26a4.47,4.47,0,0,1,.35-1.07,4,4,0,0,0-.19,1.08s0,.08-.08.08a.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.88,69.37a4,4,0,0,1,.33-1.07A4,4,0,0,0,61,69.39a.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.06,70.65a4.73,4.73,0,0,1,.36-1.06,4.49,4.49,0,0,0-.21,1.08.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.33,71.6a4.24,4.24,0,0,1,.43-1,4.37,4.37,0,0,0-.28,1.06.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.4,72.35a4.1,4.1,0,0,1,.44-1,4.39,4.39,0,0,0-.29,1.06.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.13,73a4.45,4.45,0,0,1,.52-1,4.3,4.3,0,0,0-.37,1,.09.09,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.58,74.19a4.42,4.42,0,0,1,.57-1,4.23,4.23,0,0,0-.42,1,.09.09,0,0,1-.1.06.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.36,74.9A4.23,4.23,0,0,1,60,74a4.15,4.15,0,0,0-.49,1,.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.63,75.89a4.62,4.62,0,0,1,.65-.92,4.81,4.81,0,0,0-.51,1,.07.07,0,0,1-.1,0,.06.06,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.72,76.5a4.54,4.54,0,0,1,.69-.88,4.28,4.28,0,0,0-.55.95.08.08,0,0,1-.11,0,.1.1,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.56,77.33a5,5,0,0,1,.72-.87,4.58,4.58,0,0,0-.58.94.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.74,77.7a5,5,0,0,1,.75-.84,4.37,4.37,0,0,0-.62.92.07.07,0,1,1-.13-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.66,76.93a5,5,0,0,1,.73-.85,5,5,0,0,0-.59.93.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.73,77a4.55,4.55,0,0,1,.74-.84,4.32,4.32,0,0,0-.61.92.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.27,78.63a4.6,4.6,0,0,1,.8-.79,4.36,4.36,0,0,0-.67.88.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,79.15a4.21,4.21,0,0,1,.82-.76,4.4,4.4,0,0,0-.7.85.08.08,0,0,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.77,80.27a4.62,4.62,0,0,1,.86-.73,4.84,4.84,0,0,0-.74.83.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.3,80.36a3.92,3.92,0,0,1,.87-.71,4.1,4.1,0,0,0-.75.81.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.2,81.48a4.12,4.12,0,0,1,.89-.68,4.8,4.8,0,0,0-.78.78.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,81.72a4.43,4.43,0,0,1,.9-.66,4.38,4.38,0,0,0-.79.76.08.08,0,1,1-.12-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.64,82.67a4.46,4.46,0,0,1,.92-.64,4.45,4.45,0,0,0-.81.75.08.08,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.75,83.76a4.39,4.39,0,0,1,.93-.61,5.17,5.17,0,0,0-.83.72s-.07,0-.11,0a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.47,84.08a4.48,4.48,0,0,1,1-.6,3.86,3.86,0,0,0-.84.72.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.09,85.18a4.35,4.35,0,0,1,1-.59,4.12,4.12,0,0,0-.85.7.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.69,85.7a4.07,4.07,0,0,1,1-.59,4.4,4.4,0,0,0-.85.7.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,86.84a4.37,4.37,0,0,1,1-.58,4.49,4.49,0,0,0-.86.69.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.86,87.84a4.08,4.08,0,0,1,1-.56A4.11,4.11,0,0,0,55,88a.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.58,88.38a4.7,4.7,0,0,1,1-.56,4.43,4.43,0,0,0-.87.68.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.14,89.77a4.54,4.54,0,0,1,1-.55,4.1,4.1,0,0,0-.88.67.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,90.81a4,4,0,0,1,1-.53,4.72,4.72,0,0,0-.89.65.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.7,91a4.54,4.54,0,0,1,1-.55,4.45,4.45,0,0,0-.88.67.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.9,92.21a4.3,4.3,0,0,1,1-.53,4.72,4.72,0,0,0-.89.65.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.64,92.18a4.11,4.11,0,0,1,1-.55,4.1,4.1,0,0,0-.88.67.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.75,93.24a4.45,4.45,0,0,1,1-.53,4.36,4.36,0,0,0-.88.65.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.19,94.34a4.22,4.22,0,0,1,1-.51,4.4,4.4,0,0,0-.9.63.07.07,0,1,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.94,94.81a4.76,4.76,0,0,1,1-.54,4.89,4.89,0,0,0-.89.66.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.27,95.88a5,5,0,0,1,1-.5,4.43,4.43,0,0,0-.91.62.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.07,96.23a4.61,4.61,0,0,1,1-.52,4.67,4.67,0,0,0-.89.64.07.07,0,1,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,97.26a4.3,4.3,0,0,1,1-.53,4.16,4.16,0,0,0-.89.65.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.71,97.86a3.94,3.94,0,0,1,1-.51,4,4,0,0,0-.9.63.08.08,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.71,97.63a4.25,4.25,0,0,1,.95-.58,4.35,4.35,0,0,0-.85.69.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.35,98.65a4.3,4.3,0,0,1,1-.53,4.16,4.16,0,0,0-.89.65.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.44,98.19a4.29,4.29,0,0,1,1-.56,4.79,4.79,0,0,0-.87.67.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.77,99.28a4.11,4.11,0,0,1,.95-.6,4.39,4.39,0,0,0-.84.71.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.31,101.44a4.42,4.42,0,0,1,.89-.67,4.12,4.12,0,0,0-.78.78.08.08,0,1,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.82,102.94a4.26,4.26,0,0,1,.84-.75,4.11,4.11,0,0,0-.72.84.06.06,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.75,104.53a4.6,4.6,0,0,1,.82-.77,4.34,4.34,0,0,0-.69.86.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.28,104.5a4,4,0,0,1,.7-.88,4.36,4.36,0,0,0-.57.95.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,105a4.6,4.6,0,0,1,.77-.82,4.56,4.56,0,0,0-.63.9.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,105a5,5,0,0,1,.72-.87,4.18,4.18,0,0,0-.58.94.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.53,104.14a4.55,4.55,0,0,1,.78-.8,4.72,4.72,0,0,0-.65.89.08.08,0,1,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.87,103.56a4.23,4.23,0,0,1,.77-.81,4.32,4.32,0,0,0-.65.89.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.42,103.72a4.26,4.26,0,0,1,.84-.75,3.77,3.77,0,0,0-.71.84.07.07,0,0,1-.11,0,.06.06,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.86,103.68a4.23,4.23,0,0,1,.71-.86,4.75,4.75,0,0,0-.58.94.07.07,0,0,1-.1,0s0-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.21,102.81A4.57,4.57,0,0,1,47,102a4.29,4.29,0,0,0-.63.9.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.23,102.36a4.29,4.29,0,0,1,.83-.76,4.39,4.39,0,0,0-.71.85.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.83,102.85a4.73,4.73,0,0,1,.9-.66A4.06,4.06,0,0,0,50,103a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.43,101.68a4.15,4.15,0,0,1,1-.61,4.15,4.15,0,0,0-.84.72.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.42,100.75a4.25,4.25,0,0,1,1-.55,4,4,0,0,0-.87.67.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.62,99.27a4.22,4.22,0,0,1,1-.49,4,4,0,0,0-.91.61.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.11,97.54a4.26,4.26,0,0,1,1-.46,4.37,4.37,0,0,0-.94.59.07.07,0,0,1-.1,0,.06.06,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,95.74a5.16,5.16,0,0,1,1-.46,5,5,0,0,0-.93.59.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,94a4.11,4.11,0,0,1,1-.49,4.2,4.2,0,0,0-.92.61.08.08,0,0,1-.11,0A.07.07,0,0,1,55,94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.15,91.58a4.26,4.26,0,0,1,1-.52,4.44,4.44,0,0,0-.9.64.08.08,0,1,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.54,90.06a4.3,4.3,0,0,1,1-.53,4.49,4.49,0,0,0-.9.65.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.35,89.1a4.76,4.76,0,0,1,1-.54,4.11,4.11,0,0,0-.89.66.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.55,88.06a5,5,0,0,1,1-.55,4.45,4.45,0,0,0-.88.67.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.23,86.82a4.74,4.74,0,0,1,1-.57,4.09,4.09,0,0,0-.86.68.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,83.85a4.76,4.76,0,0,1,.93-.63,4.43,4.43,0,0,0-.82.74.07.07,0,1,1-.11-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,80.81a4.17,4.17,0,0,1,.84-.74,4.71,4.71,0,0,0-.72.83.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.77,75.54a4.89,4.89,0,0,1,.61-.94,4.79,4.79,0,0,0-.47,1,.07.07,0,0,1-.09.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.26,71.68a4.32,4.32,0,0,1,.38-1.06,4.53,4.53,0,0,0-.23,1.08.09.09,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.83,67.11A4.33,4.33,0,0,1,62.18,66a4.55,4.55,0,0,0-.2,1.09.08.08,0,0,1-.08.07s-.08,0-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,63.38a4.75,4.75,0,0,1,.4-1.05,4.47,4.47,0,0,0-.24,1.07.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.37,60.49a4.55,4.55,0,0,1,.43-1,4.33,4.33,0,0,0-.28,1.07.06.06,0,0,1-.08.06.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.37,57.13a4,4,0,0,1,.47-1,3.87,3.87,0,0,0-.32,1.05.08.08,0,0,1-.09.06.08.08,0,0,1-.07-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.21,53.23a4.86,4.86,0,0,1,.53-1,4.25,4.25,0,0,0-.38,1,.08.08,0,0,1-.1.06.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.67,49.37a4.48,4.48,0,0,1,.6-.95,4.76,4.76,0,0,0-.46,1,.07.07,0,1,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.47,44.18a4.53,4.53,0,0,1,.66-.9,4.53,4.53,0,0,0-.52,1,.08.08,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.14,39.52a4.29,4.29,0,0,1,.71-.87,4.54,4.54,0,0,0-.57.94.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.12,34.79a4.59,4.59,0,0,1,.7-.88,4.39,4.39,0,0,0-.56,1,.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.4,29.07a4.6,4.6,0,0,1,.61-.94,4.57,4.57,0,0,0-.47,1,.06.06,0,0,1-.09,0,.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.28,25.5a4.65,4.65,0,0,1,.45-1,4.92,4.92,0,0,0-.3,1.06.07.07,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.56,23a4.42,4.42,0,0,1,.29-1.08A4.53,4.53,0,0,0,56.71,23a.08.08,0,0,1-.08.08.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.84,19.65A4.18,4.18,0,0,1,55,18.53a4,4,0,0,0,0,1.1.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.7,17.11a4.66,4.66,0,0,1,0-1.12,4.4,4.4,0,0,0,.14,1.09.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.86,15.41a4.27,4.27,0,0,1,0-1.13,4.48,4.48,0,0,0,.14,1.1.07.07,0,0,1,0,.09.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,14.52a4.65,4.65,0,0,1,0-1.12,4.4,4.4,0,0,0,.14,1.09.07.07,0,0,1,0,.09.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.81,15.16a4.75,4.75,0,0,1,0-1.13,5,5,0,0,0,.15,1.1.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49,14.35a4.66,4.66,0,0,1,0-1.12,4.4,4.4,0,0,0,.14,1.09.07.07,0,0,1-.05.09.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.86,16.06a4.66,4.66,0,0,1,0-1.12A4.4,4.4,0,0,0,50,16a.07.07,0,0,1,0,.09.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.67,17.33a4.2,4.2,0,0,1,0-1.12,4,4,0,0,0,.15,1.09.09.09,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.52,19.27a4.67,4.67,0,0,1,0-1.12,4.53,4.53,0,0,0,.13,1.1.07.07,0,0,1-.05.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.21,20.59a4.65,4.65,0,0,1,0-1.12,4.51,4.51,0,0,0,.1,1.1.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.48,22.76a4.55,4.55,0,0,1,.11-1.11,4.49,4.49,0,0,0,0,1.1.08.08,0,0,1-.06.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.05,24.26a4.51,4.51,0,0,1,.17-1.1,4.49,4.49,0,0,0,0,1.1.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,25.65a4,4,0,0,1,.21-1.1,4.49,4.49,0,0,0-.06,1.1.07.07,0,0,1-.07.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.19,26.78a4.5,4.5,0,0,1,.29-1.09,4.1,4.1,0,0,0-.14,1.1.07.07,0,0,1-.08.07.07.07,0,0,1-.07-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.13,28a4.52,4.52,0,0,1,.24-1.09,4.5,4.5,0,0,0-.09,1.1.08.08,0,0,1-.07.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.3,27.07a4.08,4.08,0,0,1,.24-1.1,4.13,4.13,0,0,0-.09,1.1.08.08,0,0,1-.07.08.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.53,25.08A4,4,0,0,1,54.79,24a4.07,4.07,0,0,0-.11,1.1.07.07,0,0,1-.08.07s-.07,0-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.75,22.78a4.58,4.58,0,0,1,.16-1.11,4.5,4.5,0,0,0,0,1.1.08.08,0,0,1-.06.09.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.89,21.41A4.17,4.17,0,0,1,52,20.29a4.06,4.06,0,0,0,.09,1.1.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.75,19.21a4.2,4.2,0,0,1,0-1.12,4.94,4.94,0,0,0,.15,1.09.08.08,0,0,1-.06.1.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.06,17.44a4.2,4.2,0,0,1,0-1.12,4.94,4.94,0,0,0,.15,1.09.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.11,16.21a4.22,4.22,0,0,1,0-1.12,3.94,3.94,0,0,0,.18,1.08.09.09,0,0,1-.06.1.07.07,0,0,1-.09-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.61,15.76a4.71,4.71,0,0,1-.09-1.12,4.44,4.44,0,0,0,.24,1.08.07.07,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.64,15.56a4.25,4.25,0,0,1-.24-1.1,4.18,4.18,0,0,0,.38,1,.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.63,15.67a4.61,4.61,0,0,1-.37-1.06,4.76,4.76,0,0,0,.5,1,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,15.81a4.44,4.44,0,0,1-.46-1,4.45,4.45,0,0,0,.59.93s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.58,17.62a4.46,4.46,0,0,1-.58-1,4.49,4.49,0,0,0,.69.86.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.25,19.1a4.46,4.46,0,0,1-.64-.92,4.1,4.1,0,0,0,.74.81.07.07,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.23,20.17a4.26,4.26,0,0,1-.68-.9,4.12,4.12,0,0,0,.78.78.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.06,21.5a4.17,4.17,0,0,1-.71-.87,4.72,4.72,0,0,0,.8.75.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.6,23a5,5,0,0,1-.73-.86,4.49,4.49,0,0,0,.83.74.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.7,24.91a4.51,4.51,0,0,1-.75-.83,4.72,4.72,0,0,0,.84.71.07.07,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.75,26.47a3.94,3.94,0,0,1-.75-.83,4.34,4.34,0,0,0,.84.71.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.49,28.44a5,5,0,0,1-.76-.83,4.34,4.34,0,0,0,.85.7.07.07,0,0,1,0,.11.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.76,29.63A4.9,4.9,0,0,1,16,28.8a4.72,4.72,0,0,0,.84.71.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.52,32.29a4.61,4.61,0,0,1-.74-.85,4.5,4.5,0,0,0,.84.73.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.33,35.49a4.51,4.51,0,0,1-.72-.86,4.37,4.37,0,0,0,.81.74.07.07,0,0,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.7,39.77a4.52,4.52,0,0,1-.69-.89,4.43,4.43,0,0,0,.79.77.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.05,43.92a4.48,4.48,0,0,1-.67-.9,4.12,4.12,0,0,0,.77.79.08.08,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.45,45.05a4.43,4.43,0,0,1-.66-.9,4.43,4.43,0,0,0,.77.79.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.41,46a4.23,4.23,0,0,1-.66-.91,4.44,4.44,0,0,0,.76.8.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.13,46.46a5.21,5.21,0,0,1-.65-.91,4.12,4.12,0,0,0,.76.8.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.4,44.9a4.48,4.48,0,0,1-.67-.9,4.43,4.43,0,0,0,.77.79.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.92,42.93a4.26,4.26,0,0,1-.68-.9,4.12,4.12,0,0,0,.78.78.08.08,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.09,41a4.19,4.19,0,0,1-.69-.88,4.43,4.43,0,0,0,.79.77.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.92,37.65a4.57,4.57,0,0,1-.71-.88,4.78,4.78,0,0,0,.8.76.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.39,34.74a4.51,4.51,0,0,1-.72-.86,4.37,4.37,0,0,0,.81.74.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.94,30.88a4.84,4.84,0,0,1-.72-.85,4.38,4.38,0,0,0,.82.73.08.08,0,1,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.83,27.21a4.61,4.61,0,0,1-.74-.85,4.39,4.39,0,0,0,.83.73.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.14,25.6a4.56,4.56,0,0,1-.73-.85,4.44,4.44,0,0,0,.83.73.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.58,23.9A4,4,0,0,1,9.86,23a4.1,4.1,0,0,0,.82.74.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.87,23.05a4.58,4.58,0,0,1-.71-.87,4.1,4.1,0,0,0,.81.75A.08.08,0,0,1,10,23a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.1,22.23a4.18,4.18,0,0,1-.7-.87,4.43,4.43,0,0,0,.8.76.07.07,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.89,21.82a4.58,4.58,0,0,1-.71-.87,4.1,4.1,0,0,0,.81.75.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.23,20.58a4.12,4.12,0,0,1-.68-.89,4.43,4.43,0,0,0,.78.78.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.8,19.9A3.89,3.89,0,0,1,9.12,19a4.12,4.12,0,0,0,.78.78s0,.07,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.94,20.6a4.23,4.23,0,0,1-.71-.87,4.78,4.78,0,0,0,.8.76.06.06,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.57,23a4.45,4.45,0,0,1-.73-.84,4.38,4.38,0,0,0,.83.72.08.08,0,0,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.13,25.68a4.51,4.51,0,0,1-.75-.83,4.39,4.39,0,0,0,.84.71.07.07,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.26,30.69a4.55,4.55,0,0,1-.74-.84,4.39,4.39,0,0,0,.84.71.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.14,33.59a4.16,4.16,0,0,1-.73-.84,3.81,3.81,0,0,0,.83.72.09.09,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.25,35.24a4.27,4.27,0,0,1-.73-.86,4.43,4.43,0,0,0,.82.74.07.07,0,0,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.88,36.72a4.51,4.51,0,0,1-.72-.86,4.43,4.43,0,0,0,.82.74.07.07,0,0,1,0,.1.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.73,36.32a4.21,4.21,0,0,1-.73-.85,4.38,4.38,0,0,0,.82.73.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.47,34.07a4.9,4.9,0,0,1-.74-.84,4.71,4.71,0,0,0,.83.72.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.44,29.87a4.6,4.6,0,0,1-.75-.84,4,4,0,0,0,.84.71.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.77,25.21a4.51,4.51,0,0,1-.75-.83,4.39,4.39,0,0,0,.84.71.06.06,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.1,22.2a5,5,0,0,1-.74-.85,4.34,4.34,0,0,0,.83.72.07.07,0,0,1,0,.11.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.89,20.33a4.16,4.16,0,0,1-.72-.85,4.05,4.05,0,0,0,.82.73.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.06,18.91a4.18,4.18,0,0,1-.7-.87,4.05,4.05,0,0,0,.8.75.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.6,17.56a4.77,4.77,0,0,1-.69-.88,4.37,4.37,0,0,0,.79.76.08.08,0,0,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.8,16.38a4.53,4.53,0,0,1-.68-.9,4.12,4.12,0,0,0,.78.78.08.08,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.67,15.47a4.38,4.38,0,0,1-.65-.9,4.37,4.37,0,0,0,.76.79.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.6,15.62a4.79,4.79,0,0,1-.66-.91,4.43,4.43,0,0,0,.77.79.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.22,17.6a4.46,4.46,0,0,1-.69-.88,4.43,4.43,0,0,0,.79.77.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.16,20.27a4.85,4.85,0,0,1-.71-.86,4.37,4.37,0,0,0,.81.74.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.54,23.66a4.61,4.61,0,0,1-.74-.85,4.77,4.77,0,0,0,.83.73.07.07,0,1,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.3,27.26a4.9,4.9,0,0,1-.74-.84,4.84,4.84,0,0,0,.84.72.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.17,28.47a3.92,3.92,0,0,1-.74-.84,4.05,4.05,0,0,0,.83.72.08.08,0,0,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.06,29.09a4.26,4.26,0,0,1-.74-.85,4.77,4.77,0,0,0,.83.73.07.07,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.5,28.33a4.5,4.5,0,0,1-.73-.85,4,4,0,0,0,.82.72.07.07,0,0,1,0,.11.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.38,26a4.56,4.56,0,0,1-.73-.86,4.43,4.43,0,0,0,.82.74.07.07,0,1,1-.08.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.56,22.26a4.18,4.18,0,0,1-.7-.87,4.35,4.35,0,0,0,.8.75.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.24,16.83a4.11,4.11,0,0,1-.64-.92,4.08,4.08,0,0,0,.74.81.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.35,14.3a4.29,4.29,0,0,1-.59-1,4.8,4.8,0,0,0,.7.85.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.41,11.85a4.23,4.23,0,0,1-.53-1,4.72,4.72,0,0,0,.65.89.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.61,9.27a4.08,4.08,0,0,1-.45-1,4.45,4.45,0,0,0,.57.94.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.93,8.21a4.63,4.63,0,0,1-.4-1.05,4.46,4.46,0,0,0,.53,1,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.87,8.17a4.91,4.91,0,0,1-.39-1,4.5,4.5,0,0,0,.52,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,9.12a4.49,4.49,0,0,1-.41-1,4.38,4.38,0,0,0,.54,1,.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.54,10.73a4.26,4.26,0,0,1-.44-1,4.36,4.36,0,0,0,.57,1,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.93,13.87a4.71,4.71,0,0,1-.51-1,4.47,4.47,0,0,0,.63.91.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.45,16.62a4.76,4.76,0,0,1-.54-1,4.89,4.89,0,0,0,.66.89.07.07,0,1,1-.11.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.37,18.5a5.2,5.2,0,0,1-.56-1,4.43,4.43,0,0,0,.68.87.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.79,18.86a5,5,0,0,1-.54-1,4.05,4.05,0,0,0,.66.88.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.19,19.5a4.29,4.29,0,0,1-.56-1,4.43,4.43,0,0,0,.68.87.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.39,17.34a4.44,4.44,0,0,1-.48-1,4.32,4.32,0,0,0,.61.92.08.08,0,0,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.87,13.12A4.16,4.16,0,0,1,35.59,12a4.54,4.54,0,0,0,.41,1,.08.08,0,1,1-.13.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,10.81a4.14,4.14,0,0,1-.1-1.11,4.26,4.26,0,0,0,.24,1.07.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.4,9.52a4.2,4.2,0,0,1,0-1.12,4.42,4.42,0,0,0,.16,1.09.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.24,8.82a4.2,4.2,0,0,1,0-1.12,4.94,4.94,0,0,0,.15,1.09.09.09,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.3,8.32a4.66,4.66,0,0,1,0-1.12,4.4,4.4,0,0,0,.14,1.09.07.07,0,0,1-.05.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.2,8.49a4.2,4.2,0,0,1,0-1.12,4,4,0,0,0,.15,1.09.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.08,10.45a4.75,4.75,0,0,1,0-1.13,5,5,0,0,0,.15,1.1.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,13.91a4.2,4.2,0,0,1,0-1.12,4,4,0,0,0,.15,1.09.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.1,17.17a4.2,4.2,0,0,1,0-1.12,4.43,4.43,0,0,0,.17,1.09.09.09,0,0,1,0,.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46,20A4.22,4.22,0,0,1,46,18.88,4,4,0,0,0,46.18,20a.07.07,0,0,1,0,.09.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.74,21.58a4.23,4.23,0,0,1-.08-1.12,4.43,4.43,0,0,0,.23,1.08.09.09,0,0,1,0,.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48,22.43a4.68,4.68,0,0,1,0-1.12,4.46,4.46,0,0,0,.2,1.09.07.07,0,1,1-.14,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.32,22a5.25,5.25,0,0,1,0-1.12,5.08,5.08,0,0,0,.12,1.1.07.07,0,0,1,0,.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.68,20.51a4.59,4.59,0,0,1,.1-1.11,4.49,4.49,0,0,0,0,1.1.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.56,17.74a4.61,4.61,0,0,1,.12-1.11,5,5,0,0,0,0,1.1.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.39,15.58a4.07,4.07,0,0,1,.17-1.1,4.05,4.05,0,0,0,0,1.1.07.07,0,0,1-.07.08.06.06,0,0,1-.08-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.64,13a4.58,4.58,0,0,1,.16-1.11,4.5,4.5,0,0,0,0,1.1.08.08,0,0,1-.06.09.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.18,13.51a4.56,4.56,0,0,1,.26-1.09,4.52,4.52,0,0,0-.11,1.1.08.08,0,0,1-.07.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.51,12.91a4.62,4.62,0,0,1,.2-1.11,4.07,4.07,0,0,0,0,1.1.1.1,0,0,1-.07.09.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.7,14.78A4.54,4.54,0,0,1,62,13.69a4.51,4.51,0,0,0-.1,1.1.08.08,0,0,1-.07.08.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.66,18.86A4.75,4.75,0,0,1,61,17.8a4.41,4.41,0,0,0-.22,1.08.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.87,24.53a4.49,4.49,0,0,1,.51-1,4.74,4.74,0,0,0-.36,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.27,29a3.89,3.89,0,0,1,.56-1,4.77,4.77,0,0,0-.42,1,.07.07,0,0,1-.09.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.48,32.9a4.92,4.92,0,0,1,.6-.95,4.26,4.26,0,0,0-.45,1s-.06.07-.1,0a.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.58,34.93A4.93,4.93,0,0,1,60.2,34a4.34,4.34,0,0,0-.47,1,.09.09,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.34,33.78a4.46,4.46,0,0,1,.64-.92,4.32,4.32,0,0,0-.5,1,.09.09,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.93,31.48a4.28,4.28,0,0,1,.68-.89,4.59,4.59,0,0,0-.55,1,.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.74,28.26a4.63,4.63,0,0,1,.72-.86,4.12,4.12,0,0,0-.58.93.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.47,22.41a4.29,4.29,0,0,1,.71-.87,4.54,4.54,0,0,0-.57.94.08.08,0,1,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.6,19.61a4.67,4.67,0,0,1,.7-.88,4.32,4.32,0,0,0-.56.95.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.88,17.63a4.24,4.24,0,0,1,.7-.88,4.36,4.36,0,0,0-.57.95.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.67,17.2a4.63,4.63,0,0,1,.72-.86,4.72,4.72,0,0,0-.59.93.07.07,0,0,1-.1,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.07,16.59a4.54,4.54,0,0,1,.69-.88,4.75,4.75,0,0,0-.56.95.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.68,18.67a4.64,4.64,0,0,1,.75-.84,4,4,0,0,0-.61.92.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.57,22a4.55,4.55,0,0,1,.78-.8,5.19,5.19,0,0,0-.66.88.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.69,27.27a4.54,4.54,0,0,1,.81-.77,4.73,4.73,0,0,0-.69.86.06.06,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.87,34a4.26,4.26,0,0,1,.83-.76,4.06,4.06,0,0,0-.7.85.09.09,0,0,1-.11,0,.06.06,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.68,39.53a4.17,4.17,0,0,1,.77-.8,4.72,4.72,0,0,0-.65.89.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.65,43.11a4.63,4.63,0,0,1,.72-.86,5,5,0,0,0-.59.93.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.18,45.61a4.16,4.16,0,0,1,.67-.89,4.54,4.54,0,0,0-.54,1,.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.13,46.27a4.22,4.22,0,0,1,.67-.9,4.1,4.1,0,0,0-.53,1,.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.29,44.06A4.63,4.63,0,0,1,67,43.2a4.51,4.51,0,0,0-.58.93.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.06,38.93a5.36,5.36,0,0,1,.8-.78,4.75,4.75,0,0,0-.68.87.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.58,35.07a4.9,4.9,0,0,1,.85-.73,4.34,4.34,0,0,0-.72.83.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,31.12a4.91,4.91,0,0,1,.87-.71,4.43,4.43,0,0,0-.75.81.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.44,28a4.18,4.18,0,0,1,.87-.7,4.78,4.78,0,0,0-.76.8.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.84,25a4.91,4.91,0,0,1,.87-.71,4.43,4.43,0,0,0-.75.81.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.21,22.09a4.56,4.56,0,0,1,.85-.73,4.39,4.39,0,0,0-.73.83.09.09,0,0,1-.11,0s0-.07,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.35,21.71A4.21,4.21,0,0,1,77.2,21a4.71,4.71,0,0,0-.73.82.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.4,20.86a4.21,4.21,0,0,1,.85-.73,4.05,4.05,0,0,0-.73.82.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.64,20.77a3.92,3.92,0,0,1,.84-.74,4.05,4.05,0,0,0-.72.83.09.09,0,0,1-.11,0s0-.07,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.65,23.31a4.59,4.59,0,0,1,.88-.7,4.41,4.41,0,0,0-.76.8.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.45,25.32a4.19,4.19,0,0,1,.88-.69,4.43,4.43,0,0,0-.77.79s-.07,0-.11,0a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,29.34a4.88,4.88,0,0,1,.89-.68,4.8,4.8,0,0,0-.78.78.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.72,33.41a4.93,4.93,0,0,1,.89-.69,4.43,4.43,0,0,0-.77.79.08.08,0,1,1-.12-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.27,38a4.56,4.56,0,0,1,.85-.73,4.39,4.39,0,0,0-.73.83.09.09,0,0,1-.11,0s0-.07,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.24,41.49a4.6,4.6,0,0,1,.81-.78,4.43,4.43,0,0,0-.68.87.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.1,44.43a4.93,4.93,0,0,1,.76-.82,4.29,4.29,0,0,0-.63.9.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68,47.19a4,4,0,0,1,.69-.89,4.63,4.63,0,0,0-.56,1s-.06.05-.1,0a.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.05,49.67a4.17,4.17,0,0,1,.63-.92,4.73,4.73,0,0,0-.49,1,.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.68,51a4.89,4.89,0,0,1,.61-.94,4.3,4.3,0,0,0-.46,1,.09.09,0,0,1-.1.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.4,47.59a4.65,4.65,0,0,1,.67-.91,4.17,4.17,0,0,0-.53,1,.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.83,40.66a4.55,4.55,0,0,1,.78-.8,4.37,4.37,0,0,0-.66.89.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.48,32.77A5,5,0,0,1,68.3,32a4.34,4.34,0,0,0-.69.86.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.14,28.38a4.21,4.21,0,0,1,.81-.77,3.84,3.84,0,0,0-.69.86.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,22.21a4,4,0,0,1,.76-.82,4.29,4.29,0,0,0-.63.9.08.08,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.67,20.26a4.31,4.31,0,0,1,.74-.85,5.43,5.43,0,0,0-.61.92.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.89,21.05a4.25,4.25,0,0,1,.74-.84,4.32,4.32,0,0,0-.61.92.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
      </g>}
      {r_type_hair >= 9 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.28,71.33a8,8,0,0,0-2-1.52,7.59,7.59,0,0,1,2.1,1.41.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.46,74.28a8,8,0,0,0-2-1.49,8,8,0,0,1,2.13,1.37.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.52,72.88a7.55,7.55,0,0,0-2-1.49,7.56,7.56,0,0,1,2.12,1.37.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.82,71.45a7.77,7.77,0,0,0-2-1.53,7.58,7.58,0,0,1,2.09,1.42.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,72.79a7.6,7.6,0,0,0-2-1.54,7.77,7.77,0,0,1,2.09,1.43.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,74.24a7.8,7.8,0,0,0-2-1.47,7.63,7.63,0,0,1,2.14,1.36.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.83,71.67a7.79,7.79,0,0,0-2-1.52,8,8,0,0,1,2.1,1.41.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.32,73.82a7.8,7.8,0,0,0-2-1.47,7.82,7.82,0,0,1,2.14,1.35.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.18,71.52a7.83,7.83,0,0,0-2-1.58,7.64,7.64,0,0,1,2.06,1.46.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.32,72.3a7.59,7.59,0,0,0-2-1.5,7.77,7.77,0,0,1,2.11,1.38.07.07,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.42,72.26a7.41,7.41,0,0,0-2-1.52,7.45,7.45,0,0,1,2.1,1.41.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.64,70.83a7.6,7.6,0,0,0-2-1.54,8,8,0,0,1,2.09,1.43.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.46,71.29a7.38,7.38,0,0,0-2-1.55,7.6,7.6,0,0,1,2.08,1.44.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.63,73a7.59,7.59,0,0,0-2-1.5,8,8,0,0,1,2.12,1.38.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.08,73.68a7.58,7.58,0,0,0-2-1.51,7.61,7.61,0,0,1,2.11,1.4.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,72.83a7.83,7.83,0,0,0-2-1.49,7.87,7.87,0,0,1,2.13,1.38.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.72,70.59a7.42,7.42,0,0,0-2-1.55,7.6,7.6,0,0,1,2.08,1.44.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.28,70.19a7.85,7.85,0,0,0-2-1.55,7.72,7.72,0,0,1,2.09,1.44.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.45,69.77a7.39,7.39,0,0,0-1.93-1.59,7.61,7.61,0,0,1,2,1.48.08.08,0,0,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.91,69.16A7.6,7.6,0,0,0,15,67.57a7.82,7.82,0,0,1,2,1.48.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.62,68.91a7.42,7.42,0,0,0-1.93-1.6,7.86,7.86,0,0,1,2,1.49.08.08,0,0,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.94,68.23A7.63,7.63,0,0,0,17,66.61a7.46,7.46,0,0,1,2,1.51.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.91,67.23A7.5,7.5,0,0,0,14,65.56a7.72,7.72,0,0,1,2,1.56.09.09,0,0,1,0,.12.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.37,66.86a7.88,7.88,0,0,0-1.87-1.68,7.71,7.71,0,0,1,2,1.57.09.09,0,0,1,0,.12.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.47,67a7.85,7.85,0,0,0-1.85-1.7,7.69,7.69,0,0,1,2,1.59.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.31,66.35a7.45,7.45,0,0,0-1.83-1.71,7.52,7.52,0,0,1,1.95,1.61.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.86,66A7.63,7.63,0,0,0,17,64.25,7.72,7.72,0,0,1,19,65.87.08.08,0,0,1,19,66a.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.65,65.92a7.67,7.67,0,0,0-1.76-1.79,7.76,7.76,0,0,1,1.88,1.69.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.41,64.85a7.52,7.52,0,0,0-1.77-1.79,7.8,7.8,0,0,1,1.89,1.69.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.24,64.27a7.88,7.88,0,0,0-1.72-1.83,7.75,7.75,0,0,1,1.84,1.73.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,64.23a7.49,7.49,0,0,0-1.69-1.86,7.42,7.42,0,0,1,1.81,1.77.08.08,0,1,1-.12.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.19,63a7.43,7.43,0,0,0-1.63-1.9,7.76,7.76,0,0,1,1.76,1.81s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.91,63.54a7.79,7.79,0,0,0-1.56-2A7.78,7.78,0,0,1,23,63.46a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.91,62.73a7.83,7.83,0,0,0-1.57-2A7.61,7.61,0,0,1,20,62.65a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.73,62A7.57,7.57,0,0,0,20.29,60a7.79,7.79,0,0,1,1.58,2,.08.08,0,1,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,61.23a7.78,7.78,0,0,0-1.42-2.07,7.94,7.94,0,0,1,1.56,2,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.29,60.48a7.52,7.52,0,0,0-1.35-2.11,7.55,7.55,0,0,1,1.49,2,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.08,60.29a7.73,7.73,0,0,0-1.24-2.18,7.67,7.67,0,0,1,1.38,2.12.08.08,0,0,1,0,.11.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.84,59.69a7.77,7.77,0,0,0-1.2-2.21A7.47,7.47,0,0,1,20,59.63a.08.08,0,0,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.47,59.65a7.9,7.9,0,0,0-1.08-2.27,8,8,0,0,1,1.23,2.21.1.1,0,0,1-.05.11.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.69,58.63a7.85,7.85,0,0,0-1.08-2.26,8,8,0,0,1,1.23,2.21.09.09,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.14,58a7.5,7.5,0,0,0-1-2.32A7.66,7.66,0,0,1,19.29,58a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.7,57.35A7.81,7.81,0,0,0,15.81,55a7.71,7.71,0,0,1,1,2.3.08.08,0,0,1-.05.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.05,57.57a7.69,7.69,0,0,0-.83-2.37,7.8,7.8,0,0,1,1,2.34.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.45,56a7.55,7.55,0,0,0-.69-2.42A8,8,0,0,1,17.61,56a.09.09,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.13,55.86a7.6,7.6,0,0,0-.64-2.43,7.7,7.7,0,0,1,.79,2.4.07.07,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.64,55.83A7.54,7.54,0,0,0,21,53.4a7.79,7.79,0,0,1,.76,2.41.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.56,54.7A7.57,7.57,0,0,0,20,52.26a7.54,7.54,0,0,1,.73,2.42.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.94,53.56a7.94,7.94,0,0,0-.56-2.45,7.64,7.64,0,0,1,.71,2.43.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.17,53a7.66,7.66,0,0,0-.6-2.44A7.59,7.59,0,0,1,20.33,53a.09.09,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.94,52.11a7.81,7.81,0,0,0-.6-2.43,7.77,7.77,0,0,1,.75,2.41.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.52,51.94a7.57,7.57,0,0,0-.62-2.43,7.83,7.83,0,0,1,.78,2.41.09.09,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.38,50.56a7.58,7.58,0,0,0-.63-2.43,7.85,7.85,0,0,1,.79,2.41.08.08,0,0,1-.06.09.09.09,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.43,50.49a7.82,7.82,0,0,0-.65-2.42,7.47,7.47,0,0,1,.8,2.4.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.68,49.43A7.52,7.52,0,0,0,20,47a7.45,7.45,0,0,1,.82,2.39.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.13,48.82a7.8,7.8,0,0,0-.68-2.42,7.47,7.47,0,0,1,.83,2.39.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.21,47.82a7.51,7.51,0,0,0-.7-2.41,7.7,7.7,0,0,1,.85,2.38.08.08,0,0,1-.05.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.75,48.09a7.75,7.75,0,0,0-.69-2.41,7.68,7.68,0,0,1,.84,2.38.08.08,0,0,1-.06.1.09.09,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.12,47.07a7.8,7.8,0,0,0-.72-2.41A7.64,7.64,0,0,1,22.27,47a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.66,46.06a7.48,7.48,0,0,0-.76-2.4A7.71,7.71,0,0,1,20.81,46a.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.16,46a7.7,7.7,0,0,0-.74-2.4A7.63,7.63,0,0,1,23.31,46a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.91,46.08a7.6,7.6,0,0,0-.71-2.41,7.83,7.83,0,0,1,.87,2.38.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.6,44.36A7.69,7.69,0,0,0,20.8,42a7.9,7.9,0,0,1,.95,2.35.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.83,44.49a7.7,7.7,0,0,0-.74-2.4A7.88,7.88,0,0,1,25,44.46a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.16,43.84a7.94,7.94,0,0,0-.79-2.38,7.87,7.87,0,0,1,.94,2.35.07.07,0,0,1-.06.09.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.16,42.4A7.94,7.94,0,0,0,21.31,40a7.79,7.79,0,0,1,1,2.33.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.79,42.33A7.65,7.65,0,0,0,23,40a7.51,7.51,0,0,1,1,2.34.07.07,0,0,1-.05.09.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.45,42.74a8,8,0,0,0-.76-2.39,7.61,7.61,0,0,1,.91,2.36.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.81,40.47a7.7,7.7,0,0,0-.89-2.35,7.77,7.77,0,0,1,1,2.31.09.09,0,0,1-.05.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.83,41.84a7.7,7.7,0,0,0-.74-2.4A7.38,7.38,0,0,1,27,41.81a.07.07,0,0,1-.05.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.16,40.47a7.42,7.42,0,0,0-.82-2.37,7.53,7.53,0,0,1,1,2.34.07.07,0,0,1,0,.09.07.07,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.81,38.79a7.6,7.6,0,0,0-.91-2.34A7.52,7.52,0,0,1,24,38.75a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.4,40.1a7.74,7.74,0,0,0-.75-2.39,7.59,7.59,0,0,1,.9,2.36.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.26,38.13a7.55,7.55,0,0,0-.86-2.35,8,8,0,0,1,1,2.31.09.09,0,0,1-.06.1.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.79,39.38A7.52,7.52,0,0,0,28.08,37a7.47,7.47,0,0,1,.86,2.38.07.07,0,0,1-.05.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.16,36.66a7.57,7.57,0,0,0-.9-2.34,7.62,7.62,0,0,1,1,2.3s0,.08,0,.1a.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.36,37.65a7.42,7.42,0,0,0-.79-2.38,7.79,7.79,0,0,1,.95,2.35.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.71,36.32A7.65,7.65,0,0,0,25.87,34a7.42,7.42,0,0,1,1,2.33.07.07,0,0,1-.05.09.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.83,37.32a8.1,8.1,0,0,0-.73-2.41A7.66,7.66,0,0,1,29,37.29a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.24,37.88a7.63,7.63,0,0,0-.66-2.43,7.59,7.59,0,0,1,.82,2.4s0,.09-.07.09a.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.85,34.45a7.6,7.6,0,0,0-.91-2.34A7.9,7.9,0,0,1,26,34.41a.07.07,0,0,1-.05.09s-.08,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.22,35.22a7.94,7.94,0,0,0-.79-2.38,7.77,7.77,0,0,1,.94,2.35.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.57,35.87a7.88,7.88,0,0,0-.65-2.43,7.63,7.63,0,0,1,.8,2.4.08.08,0,0,1-.06.1.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.8,34.64a7.71,7.71,0,0,0-.7-2.4A7.88,7.88,0,0,1,30,34.62a.09.09,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.3,33.23a7.6,7.6,0,0,0-.86-2.36,7.75,7.75,0,0,1,1,2.32.08.08,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.59,32a7.6,7.6,0,0,0-.91-2.34A7.52,7.52,0,0,1,26.74,32a.08.08,0,1,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.2,33.33a7.46,7.46,0,0,0-.75-2.4,7.87,7.87,0,0,1,.91,2.36.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.11,31.48a7.69,7.69,0,0,0-.83-2.37,7.8,7.8,0,0,1,1,2.34.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.48,32a7.46,7.46,0,0,0-.74-2.39,7.59,7.59,0,0,1,.9,2.36.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.45,33.42A7.55,7.55,0,0,0,30.84,31a7.53,7.53,0,0,1,.76,2.41.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.34,29.31A7.68,7.68,0,0,0,26.46,27a7.5,7.5,0,0,1,1,2.31.07.07,0,0,1,0,.1.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.85,29.86a7.56,7.56,0,0,0-.79-2.39A7.72,7.72,0,0,1,29,29.82a.08.08,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.32,31.66a7.84,7.84,0,0,0-.62-2.43,7.55,7.55,0,0,1,.77,2.41.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.52,28.13a7.63,7.63,0,0,0-.8-2.37,7.73,7.73,0,0,1,1,2.34.07.07,0,0,1-.05.09.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,30.31a7.6,7.6,0,0,0-.64-2.43,7.61,7.61,0,0,1,.79,2.4.08.08,0,0,1-.06.1.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.4,29a7.8,7.8,0,0,0-.68-2.42A7.55,7.55,0,0,1,30.55,29a.07.07,0,0,1-.06.09A.07.07,0,0,1,30.4,29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.94,30.89a8,8,0,0,0-.48-2.46,7.9,7.9,0,0,1,.63,2.45A.08.08,0,0,1,33,31a.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.3,26.39a7.7,7.7,0,0,0-.74-2.4,7.72,7.72,0,0,1,.89,2.37.07.07,0,0,1-.05.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.49,27a7.9,7.9,0,0,0-.66-2.43,7.74,7.74,0,0,1,.81,2.4.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.91,25.73a7.68,7.68,0,0,0-.61-2.44,7.87,7.87,0,0,1,.77,2.42.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.69,27.49a7.63,7.63,0,0,0-.58-2.44,7.54,7.54,0,0,1,.73,2.42.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.47,29.54a7.72,7.72,0,0,0-.42-2.47,7.69,7.69,0,0,1,.58,2.46.09.09,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.73,24.54a7.9,7.9,0,0,0-.53-2.45,7.58,7.58,0,0,1,.68,2.44.06.06,0,0,1-.06.08.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.7,28.18a7.7,7.7,0,0,0-.49-2.47,7.93,7.93,0,0,1,.65,2.45.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.62,25.58a7.4,7.4,0,0,0-.47-2.46,7.9,7.9,0,0,1,.63,2.45.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.29,26.75a7.73,7.73,0,0,0-.42-2.48,7.75,7.75,0,0,1,.58,2.47.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.21,28.1a7.5,7.5,0,0,0-.35-2.49,7.5,7.5,0,0,1,.5,2.48.08.08,0,0,1-.07.09.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.19,30.25a7.53,7.53,0,0,0-.26-2.5,7.81,7.81,0,0,1,.42,2.5.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.64,28.85a7.78,7.78,0,0,0-.21-2.5,7.57,7.57,0,0,1,.36,2.5.07.07,0,0,1-.08.08.07.07,0,0,1-.07-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.21,25a7.83,7.83,0,0,0-.3-2.5A7.83,7.83,0,0,1,34.37,25a.07.07,0,0,1-.07.08.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.25,30.14a7.56,7.56,0,0,0,0-2.51,7.9,7.9,0,0,1,.21,2.52.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.8,26.94a7.56,7.56,0,0,0-.16-2.51A7.6,7.6,0,0,1,36,26.94a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.18,28.44a7.82,7.82,0,0,0,0-2.51,7.89,7.89,0,0,1,.19,2.52.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.85,25.43a7.56,7.56,0,0,0-.14-2.51A7.64,7.64,0,0,1,36,25.44a.07.07,0,0,1-.07.07.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.15,23.34a7.81,7.81,0,0,0-.18-2.5,7.89,7.89,0,0,1,.34,2.51.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.69,29a7.83,7.83,0,0,0,.09-2.51A7.41,7.41,0,0,1,38.84,29a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.64,26.63a7.55,7.55,0,0,0,0-2.51,7.67,7.67,0,0,1,.13,2.53.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.82,24.1a7.83,7.83,0,0,0,0-2.51A7.9,7.9,0,0,1,37,24.11a.08.08,0,0,1-.08.08.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.59,22.42a7.55,7.55,0,0,0,0-2.51,7.67,7.67,0,0,1,.17,2.53.08.08,0,0,1-.08.07.08.08,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.11,25.07a7.56,7.56,0,0,0,.09-2.51,7.41,7.41,0,0,1,.06,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.18,26.2a7.85,7.85,0,0,0,.18-2.51,8,8,0,0,1,0,2.53.07.07,0,0,1-.09.07.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.13,27.73a7.82,7.82,0,0,0,.24-2.5,8,8,0,0,1-.09,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.73,23.51A7.51,7.51,0,0,0,38.9,21a7.68,7.68,0,0,1,0,2.53.09.09,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.45,21.47a7.79,7.79,0,0,0,.18-2.5,7.42,7.42,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.7,22.14A7.48,7.48,0,0,0,40,19.65a7.64,7.64,0,0,1-.13,2.52.09.09,0,0,1-.09.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.6,24.09a7.76,7.76,0,0,0,.33-2.49,7.45,7.45,0,0,1-.18,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.68,25.76a7.71,7.71,0,0,0,.4-2.48,7.92,7.92,0,0,1-.25,2.52.09.09,0,0,1-.1.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.18,20.65a7.46,7.46,0,0,0,.41-2.48,7.65,7.65,0,0,1-.26,2.52.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.88,22a7.73,7.73,0,0,0,.47-2.47A7.63,7.63,0,0,1,42,22a.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.72,24.22a7.92,7.92,0,0,0,.5-2.46,7.67,7.67,0,0,1-.36,2.51.07.07,0,0,1-.09.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.25,22.71a7.34,7.34,0,0,0,.55-2.45,7.61,7.61,0,0,1-.4,2.5.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.65,21.07a7.56,7.56,0,0,0,.57-2.44,7.79,7.79,0,0,1-.42,2.49.1.1,0,0,1-.1.06s-.07-.06-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.14,25.86a7.71,7.71,0,0,0,.59-2.44,7.91,7.91,0,0,1-.44,2.49.08.08,0,1,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.73,24a7.52,7.52,0,0,0,.63-2.43A7.59,7.59,0,0,1,44.88,24a.08.08,0,0,1-.1.05.09.09,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.45,21.05a7.6,7.6,0,0,0,.64-2.43,7.56,7.56,0,0,1-.5,2.48.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.71,22.52a7.64,7.64,0,0,0,.66-2.42,7.58,7.58,0,0,1-.52,2.47.08.08,0,1,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,21.35a7.62,7.62,0,0,0,.72-2.41,7.49,7.49,0,0,1-.58,2.46.07.07,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.92,23.48a7.54,7.54,0,0,0,.72-2.41,7.86,7.86,0,0,1-.57,2.47.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.33,24.87a7.57,7.57,0,0,0,.7-2.42,7.77,7.77,0,0,1-.55,2.47.09.09,0,0,1-.1,0,.07.07,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.17,22.66A7.8,7.8,0,0,0,49,20.27a7.72,7.72,0,0,1-.64,2.45.08.08,0,1,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.36,26.76a7.79,7.79,0,0,0,.71-2.41,8.06,8.06,0,0,1-.56,2.47.09.09,0,0,1-.1.05s-.07-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.33,23.65a7.73,7.73,0,0,0,.82-2.38,7.72,7.72,0,0,1-.68,2.44.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.58,25.08a7.69,7.69,0,0,0,.8-2.38,8.08,8.08,0,0,1-.65,2.45.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.09,26.32a7.46,7.46,0,0,0,.78-2.39,7.83,7.83,0,0,1-.63,2.46.07.07,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.06,25a7.75,7.75,0,0,0,.86-2.37,7.91,7.91,0,0,1-.71,2.43.08.08,0,0,1-.11,0s-.05-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.27,26.94a7.67,7.67,0,0,0,.82-2.37A7.66,7.66,0,0,1,49.41,27a.08.08,0,0,1-.1.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.71,28.1a7.76,7.76,0,0,0,.76-2.39,8.09,8.09,0,0,1-.61,2.45.08.08,0,1,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.47,29.43a7.86,7.86,0,0,0,.74-2.4,7.78,7.78,0,0,1-.6,2.46.08.08,0,1,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.08,26.24A7.61,7.61,0,0,0,52,23.89a7.65,7.65,0,0,1-.75,2.42.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.56,28.57a7.67,7.67,0,0,0,.82-2.37,7.72,7.72,0,0,1-.68,2.44.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.89,27.32A7.6,7.6,0,0,0,52.8,25,7.71,7.71,0,0,1,52,27.39a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.08,28.74A7.59,7.59,0,0,0,52,26.39a7.71,7.71,0,0,1-.74,2.42.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.92,31.05a7.46,7.46,0,0,0,.78-2.39,7.7,7.7,0,0,1-.63,2.45.08.08,0,1,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.29,30.38A7.69,7.69,0,0,0,51.12,28a7.74,7.74,0,0,1-.69,2.44.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.88,29.24a7.64,7.64,0,0,0,.93-2.34A7.67,7.67,0,0,1,53,29.31a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.89,30.65a7.78,7.78,0,0,0,.88-2.35A7.92,7.92,0,0,1,52,30.71a.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.47,30.71a7.34,7.34,0,0,0,.93-2.33,7.61,7.61,0,0,1-.79,2.4.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.14,32.22A7.71,7.71,0,0,0,52,29.85a7.76,7.76,0,0,1-.7,2.44.09.09,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.15,33.49a7.67,7.67,0,0,0,.79-2.38,7.42,7.42,0,0,1-.65,2.44.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.72,32.76a7.72,7.72,0,0,0,.87-2.36,7.75,7.75,0,0,1-.73,2.43.08.08,0,0,1-.15-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.52,34.51a7.42,7.42,0,0,0,.82-2.37,7.47,7.47,0,0,1-.68,2.44.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.34,33.07a7.56,7.56,0,0,0,.92-2.33,7.59,7.59,0,0,1-.78,2.4.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.67,33.36A7.83,7.83,0,0,0,56.62,31a7.88,7.88,0,0,1-.82,2.39.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.13,35.14A8,8,0,0,0,54,32.78a7.68,7.68,0,0,1-.72,2.42.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.51,35.26a7.59,7.59,0,0,0,.88-2.35,7.9,7.9,0,0,1-.74,2.42.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.14,35.06a7.4,7.4,0,0,0,.93-2.34,7.59,7.59,0,0,1-.79,2.41.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.76,37a7.76,7.76,0,0,0,.79-2.38A7.74,7.74,0,0,1,51.9,37a.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,37a8,8,0,0,0,.84-2.37,7.62,7.62,0,0,1-.7,2.43.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.84,36.66a7.83,7.83,0,0,0,.9-2.34A7.46,7.46,0,0,1,57,36.73a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.62,38.27a7.46,7.46,0,0,0,.78-2.39,7.72,7.72,0,0,1-.64,2.45.09.09,0,0,1-.1.05.1.1,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.61,37.85a7.45,7.45,0,0,0,.86-2.36,7.81,7.81,0,0,1-.72,2.43.09.09,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.17,38.5a7.74,7.74,0,0,0,.88-2.36,7.63,7.63,0,0,1-.74,2.42.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.86,39.52a7.76,7.76,0,0,0,.79-2.38A7.74,7.74,0,0,1,54,39.58a.09.09,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.49,39.78a7.64,7.64,0,0,0,.82-2.38,7.72,7.72,0,0,1-.68,2.44.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.76,40.45a7.67,7.67,0,0,0,.82-2.37,7.41,7.41,0,0,1-.68,2.43.08.08,0,0,1-.1.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.24,41.22A7.76,7.76,0,0,0,55,38.83a7.43,7.43,0,0,1-.62,2.45.08.08,0,1,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,41.49a7.52,7.52,0,0,0,.71-2.41,7.58,7.58,0,0,1-.56,2.47.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.73,42.76a7.68,7.68,0,0,0,.68-2.42,7.74,7.74,0,0,1-.53,2.47.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.35,42.43A7.82,7.82,0,0,0,54.08,40a7.81,7.81,0,0,1-.58,2.47.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.66,41.92a7.6,7.6,0,0,0,.8-2.38A7.75,7.75,0,0,1,57.8,42a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.76,43.29a7.56,7.56,0,0,0,.73-2.41,7.87,7.87,0,0,1-.58,2.47.07.07,0,0,1-.1,0,.07.07,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,43.15a7.9,7.9,0,0,0,.76-2.4,8.08,8.08,0,0,1-.61,2.46.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.28,44.69a7.56,7.56,0,0,0,.65-2.42,7.82,7.82,0,0,1-.5,2.48.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.9,44.54a7.56,7.56,0,0,0,.69-2.41A7.56,7.56,0,0,1,54,44.6a.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.4,43.86a7.46,7.46,0,0,0,.75-2.4,7.78,7.78,0,0,1-.6,2.46.09.09,0,0,1-.1,0,.09.09,0,0,1-.05-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,43.65a7.44,7.44,0,0,0,.77-2.39,7.81,7.81,0,0,1-.62,2.46.07.07,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.1,45.81a7.78,7.78,0,0,0,.67-2.42,7.86,7.86,0,0,1-.53,2.48.08.08,0,0,1-.1.05.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.06,46.67a8.31,8.31,0,0,0,.6-2.44,7.62,7.62,0,0,1-.46,2.49.08.08,0,0,1-.1.05.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.17,45.59a7.52,7.52,0,0,0,.71-2.41,8.06,8.06,0,0,1-.56,2.47.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.55,45.32a7.56,7.56,0,0,0,.72-2.4,7.81,7.81,0,0,1-.58,2.46.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.28,47.33a7.57,7.57,0,0,0,.62-2.43,7.63,7.63,0,0,1-.47,2.49.09.09,0,0,1-.1,0s-.07-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.65,46.3a7.77,7.77,0,0,0,.7-2.41,7.78,7.78,0,0,1-.56,2.47.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.25,47.1a7.78,7.78,0,0,0,.67-2.42,7.86,7.86,0,0,1-.53,2.48.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.65,46.32a7.58,7.58,0,0,0,.7-2.41,7.46,7.46,0,0,1-.56,2.46.07.07,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.66,47.63a7.53,7.53,0,0,0,.67-2.41,7.85,7.85,0,0,1-.52,2.47.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.13,48.57a7.35,7.35,0,0,0,.64-2.43,7.54,7.54,0,0,1-.49,2.48.07.07,0,0,1-.1.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.88,49.32a7.56,7.56,0,0,0,.57-2.44A7.58,7.58,0,0,1,53,49.37a.08.08,0,0,1-.09.06s-.07-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.1,49.67a7.6,7.6,0,0,0,.6-2.44,7.87,7.87,0,0,1-.45,2.49.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,48a7.93,7.93,0,0,0,.67-2.42A7.54,7.54,0,0,1,59.1,48a.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.72,49.56a7.88,7.88,0,0,0,.65-2.43,7.83,7.83,0,0,1-.51,2.48.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.27,49.36a7.63,7.63,0,0,0,.66-2.43,7.83,7.83,0,0,1-.51,2.48.07.07,0,0,1-.1.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.51,48.72a7.57,7.57,0,0,0,.66-2.42,7.57,7.57,0,0,1-.51,2.48.07.07,0,0,1-.1,0,.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.69,51.42A8,8,0,0,0,55.27,49a7.9,7.9,0,0,1-.43,2.49.09.09,0,0,1-.1.05.07.07,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.49,51.3a7.61,7.61,0,0,0,.61-2.44,7.58,7.58,0,0,1-.47,2.49.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.39,50.47A7.58,7.58,0,0,0,62,48a7.91,7.91,0,0,1-.48,2.49.09.09,0,0,1-.1,0s-.07-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.07,50.94a7.58,7.58,0,0,0,.63-2.43A7.91,7.91,0,0,1,60.22,51a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.66,51.55a7.58,7.58,0,0,0,.63-2.43,7.8,7.8,0,0,1-.48,2.48.07.07,0,0,1-.1,0,.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.82,52.61a7.55,7.55,0,0,0,.61-2.43A7.62,7.62,0,0,1,58,52.67a.08.08,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.23,52.59a7.54,7.54,0,0,0,.6-2.43,7.87,7.87,0,0,1-.45,2.49.09.09,0,0,1-.1.05s-.07-.06-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.06,53.78a7.56,7.56,0,0,0,.57-2.44,7.54,7.54,0,0,1-.43,2.49.07.07,0,0,1-.09.06.1.1,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.48,53.63a7.58,7.58,0,0,0,.59-2.44,7.81,7.81,0,0,1-.44,2.49s-.06.07-.1,0a.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.11,54.92a7.9,7.9,0,0,0,.53-2.45,7.86,7.86,0,0,1-.38,2.5.09.09,0,0,1-.1.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.13,54.55a7.85,7.85,0,0,0,.59-2.44,7.81,7.81,0,0,1-.44,2.49s-.06.07-.1,0a.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.82,54.82a7.57,7.57,0,0,0,.58-2.44,7.65,7.65,0,0,1-.44,2.5.07.07,0,0,1-.09,0,.1.1,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.86,55.8a8,8,0,0,0,.56-2.45A7.88,7.88,0,0,1,57,55.84a.08.08,0,0,1-.1.06.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.4,55.62A8,8,0,0,0,62,53.17a7.91,7.91,0,0,1-.43,2.5.09.09,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.05,56.2a7.6,7.6,0,0,0,.6-2.44,7.57,7.57,0,0,1-.46,2.49.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.2,56.92a7.56,7.56,0,0,0,.57-2.44A7.79,7.79,0,0,1,56.35,57a.08.08,0,0,1-.1.06.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,57.89a7.6,7.6,0,0,0,.6-2.44,7.87,7.87,0,0,1-.45,2.49.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.14,57.92a7.46,7.46,0,0,0,.74-2.39A7.45,7.45,0,0,1,58.29,58c0,.05-.06.07-.1.05a.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.84,57.77a7.49,7.49,0,0,0,.77-2.4A8.09,8.09,0,0,1,60,57.83a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.68,58.93a7.42,7.42,0,0,0,.79-2.38A7.54,7.54,0,0,1,56.82,59a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.63,58a7.78,7.78,0,0,0,.8-2.38,7.69,7.69,0,0,1-.66,2.44.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.92,60.19a7.88,7.88,0,0,0,.92-2.34,7.57,7.57,0,0,1-.78,2.41.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.1,59.54a7.85,7.85,0,0,0,1-2.31,7.77,7.77,0,0,1-.84,2.38.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.9,60.34a7.72,7.72,0,0,0,1-2.29A7.82,7.82,0,0,1,58,60.42a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.17,59.78a7.78,7.78,0,0,0,1-2.3,7.8,7.8,0,0,1-.88,2.37.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.4,61.06a7.6,7.6,0,0,0,1.16-2.22,7.83,7.83,0,0,1-1,2.31.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.91,61.27a7.84,7.84,0,0,0,1.22-2.19A7.88,7.88,0,0,1,62,61.36a.07.07,0,0,1-.1,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.64,62.54A7.9,7.9,0,0,0,59,60.39a7.66,7.66,0,0,1-1.19,2.24s-.07,0-.11,0a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.75,62.69a7.58,7.58,0,0,0,1.4-2.09,7.54,7.54,0,0,1-1.28,2.19.08.08,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.06,64.06a8,8,0,0,0,1.49-2,8,8,0,0,1-1.38,2.12.08.08,0,1,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,64a7.77,7.77,0,0,0,1.53-2,7.58,7.58,0,0,1-1.42,2.09s-.07,0-.11,0A.08.08,0,0,1,59,64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.54,63.75a7.81,7.81,0,0,0,1.54-2,8,8,0,0,1-1.43,2.09.08.08,0,0,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.52,64.51a7.45,7.45,0,0,0,1.62-1.92,7.89,7.89,0,0,1-1.51,2,.07.07,0,1,1-.11-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,65.3a7.48,7.48,0,0,0,1.72-1.83,7.94,7.94,0,0,1-1.61,2,.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.57,65.84A7.91,7.91,0,0,0,61.32,64,7.6,7.6,0,0,1,59.67,66a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.28,65.89a7.67,7.67,0,0,0,1.77-1.78A7.76,7.76,0,0,1,63.38,66a.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.88,66.5a7.48,7.48,0,0,0,1.79-1.76A7.76,7.76,0,0,1,58,66.62a.08.08,0,0,1-.11,0s0-.09,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,67a7.65,7.65,0,0,0,1.87-1.67,7.75,7.75,0,0,1-1.78,1.79.07.07,0,0,1-.11,0A.08.08,0,0,1,61,67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.78,67.58A7.62,7.62,0,0,0,64.7,66a7.57,7.57,0,0,1-1.83,1.74.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.1,68A7.65,7.65,0,0,0,59,66.38a7.8,7.8,0,0,1-1.82,1.76.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.18,67.88a7.46,7.46,0,0,0,1.93-1.6A7.39,7.39,0,0,1,59.27,68a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.83,70.39A7.72,7.72,0,0,0,59.93,69a7.6,7.6,0,0,1-2,1.52.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.12,70.25a7.72,7.72,0,0,0,2.12-1.35,7.76,7.76,0,0,1-2,1.49.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.82,69.38a7.63,7.63,0,0,0,2-1.51,7.61,7.61,0,0,1-1.93,1.64.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.71,69.05a7.73,7.73,0,0,0,2-1.47,8,8,0,0,1-2,1.61.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.15,68.66a7.46,7.46,0,0,0,2-1.52,7.76,7.76,0,0,1-1.91,1.65.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.75,70.19a7.62,7.62,0,0,0,2.11-1.36,7.68,7.68,0,0,1-2,1.5.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.29,69a7.63,7.63,0,0,0,2-1.51,7.61,7.61,0,0,1-1.93,1.64.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.28,70.07a7.72,7.72,0,0,0,2.1-1.38,7.6,7.6,0,0,1-2,1.52.07.07,0,1,1-.07-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.38,68.71a7.38,7.38,0,0,0,2-1.5,7.61,7.61,0,0,1-1.93,1.64.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.07,68.91a7.61,7.61,0,0,0,2-1.49A7.38,7.38,0,0,1,60.15,69,.07.07,0,0,1,60,69a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.85,69.93a7.58,7.58,0,0,0,2.09-1.4,7.7,7.7,0,0,1-2,1.53.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.43,71.3A7.66,7.66,0,0,0,65.61,70a8.08,8.08,0,0,1-2.11,1.4.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.69,71.73a7.7,7.7,0,0,0,2.19-1.21,7.8,7.8,0,0,1-2.13,1.36.08.08,0,0,1-.11,0s0-.09,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.17,71.54a7.47,7.47,0,0,0,2.18-1.24,7.88,7.88,0,0,1-2.11,1.39.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.13,71.84a7.54,7.54,0,0,0,2.21-1.2A7.73,7.73,0,0,1,62.19,72a.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,70.93a7.68,7.68,0,0,0,2.13-1.32,7.86,7.86,0,0,1-2.06,1.46A.09.09,0,0,1,65,71a.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.8,69.82a7.78,7.78,0,0,0,2-1.45,7.53,7.53,0,0,1-2,1.58.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,68.51a7.82,7.82,0,0,0,2-1.58,7.77,7.77,0,0,1-1.86,1.71.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.22,67.73A7.68,7.68,0,0,0,69.06,66a7.6,7.6,0,0,1-1.75,1.83.08.08,0,1,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.41,67.06a7.68,7.68,0,0,0,1.84-1.71,7.56,7.56,0,0,1-1.74,1.83.08.08,0,0,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.05,66.05a7.83,7.83,0,0,0,1.68-1.86,7.66,7.66,0,0,1-1.58,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.54,65.54a7.69,7.69,0,0,0,1.68-1.87,7.71,7.71,0,0,1-1.57,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.63,64.33a7.45,7.45,0,0,0,1.57-2,7.85,7.85,0,0,1-1.46,2.07.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.45,64.39a7.8,7.8,0,0,0,1.47-2,7.82,7.82,0,0,1-1.35,2.14.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.89,63.31a7.53,7.53,0,0,0,1.4-2.08A7.72,7.72,0,0,1,66,63.41a.08.08,0,1,1-.12-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.24,62.5a7.6,7.6,0,0,0,1.24-2.18,7.73,7.73,0,0,1-1.11,2.27.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.31,61.39a7.53,7.53,0,0,0,1.16-2.23,7.61,7.61,0,0,1-1,2.31.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,60.39a7.85,7.85,0,0,0,1-2.31,7.52,7.52,0,0,1-.84,2.38.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.32,59.54a7.46,7.46,0,0,0,.92-2.33,7.73,7.73,0,0,1-.78,2.41.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.9,58.52a7.48,7.48,0,0,0,.76-2.4,7.8,7.8,0,0,1-.61,2.46.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.38,57.38A7.53,7.53,0,0,0,66.06,55a7.48,7.48,0,0,1-.53,2.47s-.06.07-.1,0a.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.46,56A8,8,0,0,0,67,53.56a7.44,7.44,0,0,1-.39,2.51.07.07,0,0,1-.09,0,.09.09,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.52,54.57a7.39,7.39,0,0,0,.55-2.45,7.61,7.61,0,0,1-.4,2.5.07.07,0,0,1-.09.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.46,52.9A7.66,7.66,0,0,0,66,50.45a7.62,7.62,0,0,1-.41,2.5.08.08,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.27,50.78a7.66,7.66,0,0,0,.6-2.44,7.87,7.87,0,0,1-.45,2.49.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.15,48.58a7.65,7.65,0,0,0,.63-2.43,7.59,7.59,0,0,1-.49,2.48.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,46a7.58,7.58,0,0,0,.7-2.41,7.89,7.89,0,0,1-.55,2.47.07.07,0,0,1-.1,0A.08.08,0,0,1,65,46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.45,44.35a7.6,7.6,0,0,0,.74-2.4,7.51,7.51,0,0,1-.59,2.46.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.87,42.59a7.75,7.75,0,0,0,.8-2.39A7.75,7.75,0,0,1,65,42.65a.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.45,41.19a7.6,7.6,0,0,0,.83-2.37,7.74,7.74,0,0,1-.69,2.44.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.2,40.33A7.72,7.72,0,0,0,65.07,38a7.69,7.69,0,0,1-.73,2.42.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.66,38.94a7.63,7.63,0,0,0,.9-2.35A7.67,7.67,0,0,1,65.8,39a.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.28,38a7.74,7.74,0,0,0,.93-2.33,7.53,7.53,0,0,1-.79,2.4.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.69,36.44a7.85,7.85,0,0,0,1-2.31,7.79,7.79,0,0,1-.85,2.38.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.15,36a7.65,7.65,0,0,0,1-2.31,7.61,7.61,0,0,1-.86,2.39.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.88,34.29a7.72,7.72,0,0,0,1-2.28A7.65,7.65,0,0,1,65,34.37a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.24,34.16a7.6,7.6,0,0,0,1-2.28,7.46,7.46,0,0,1-.91,2.36.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.43,32.68a7.5,7.5,0,0,0,1.09-2.26,7.56,7.56,0,0,1-1,2.34.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.57,31.8a7.71,7.71,0,0,0,1.12-2.24,8,8,0,0,1-1,2.33.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.61,33a7.6,7.6,0,0,0,1.06-2.27,7.45,7.45,0,0,1-.93,2.35.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.94,29.92a7.38,7.38,0,0,0,1.17-2.22,7.63,7.63,0,0,1-1,2.31A.08.08,0,0,1,63,30a.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.08,31.33a7.44,7.44,0,0,0,1.09-2.27,7.52,7.52,0,0,1-1,2.35.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.29,28.49a7.41,7.41,0,0,0,1.18-2.22,7.42,7.42,0,0,1-1,2.31.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.33,29.58a7.65,7.65,0,0,0,1.14-2.24,7.84,7.84,0,0,1-1,2.32.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.87,29.94A7.87,7.87,0,0,0,60,27.68,7.37,7.37,0,0,1,59,30a.08.08,0,0,1-.14-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.61,28a7.74,7.74,0,0,0,1.15-2.23,8,8,0,0,1-1,2.32.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.52,28.67a7.59,7.59,0,0,0,1.08-2.26,7.69,7.69,0,0,1-1,2.34s-.07.06-.1,0a.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,27.26A7.71,7.71,0,0,0,59.15,25a8,8,0,0,1-1,2.33.09.09,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.85,26A7.87,7.87,0,0,0,60,23.73,7.88,7.88,0,0,1,59,26a.07.07,0,0,1-.1,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.49,26.45a7.59,7.59,0,0,0,1.08-2.26,7.79,7.79,0,0,1-1,2.34.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.93,24.52a7.74,7.74,0,0,0,1.15-2.23,7.75,7.75,0,0,1-1,2.32.08.08,0,0,1-.11,0s-.05-.08,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.21,23.31a7.84,7.84,0,0,0,1.14-2.23,7.72,7.72,0,0,1-1,2.32.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.48,24.71a7.36,7.36,0,0,0,1.1-2.25,7.65,7.65,0,0,1-1,2.33.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.16,24.89a7.63,7.63,0,0,0,1.05-2.28A7.58,7.58,0,0,1,55.29,25a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.22,23.33a7.71,7.71,0,0,0,1.06-2.27,7.55,7.55,0,0,1-.93,2.35.07.07,0,0,1-.1,0s-.06-.07,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.49,21.79a7.59,7.59,0,0,0,1.08-2.26,7.85,7.85,0,0,1-1,2.35.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.63,22.34a7.92,7.92,0,0,0,1-2.31,8.11,8.11,0,0,1-.87,2.38.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.92,20.76a7.78,7.78,0,0,0,1-2.3,7.88,7.88,0,0,1-.89,2.38.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.27,19.45a7.49,7.49,0,0,0,1-2.31,7.68,7.68,0,0,1-.85,2.39.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,20.6a7.71,7.71,0,0,0,.94-2.33,7.9,7.9,0,0,1-.8,2.4.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.24,19.46a7.61,7.61,0,0,0,.89-2.35,7.67,7.67,0,0,1-.76,2.42.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.25,17.83a7.61,7.61,0,0,0,.89-2.35,7.65,7.65,0,0,1-.75,2.42.08.08,0,1,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.77,18.52a7.69,7.69,0,0,0,.83-2.37,7.74,7.74,0,0,1-.69,2.44s-.06.05-.1,0a.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.6,16.6a7.78,7.78,0,0,0,.8-2.38,7.76,7.76,0,0,1-.66,2.44.08.08,0,0,1-.1.05.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.21,17.44a7.68,7.68,0,0,0,.73-2.4,7.51,7.51,0,0,1-.59,2.46.08.08,0,1,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.24,15.49A7.62,7.62,0,0,0,49,13.08a7.74,7.74,0,0,1-.57,2.46c0,.05-.06.07-.1.05a.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.83,16.37A7.5,7.5,0,0,0,47.49,14,7.58,7.58,0,0,1,47,16.43a.07.07,0,0,1-.09.05c-.05,0-.07-.06-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.55,14.59a7.63,7.63,0,0,0,.62-2.44,7.85,7.85,0,0,1-.47,2.49.08.08,0,1,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.31,16.47A7.45,7.45,0,0,0,45.9,14a7.65,7.65,0,0,1-.44,2.5.08.08,0,0,1-.1.05.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.13,13.8a8,8,0,0,0,.53-2.46,7.59,7.59,0,0,1-.38,2.5.08.08,0,0,1-.1.06.09.09,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.27,15.32a7.61,7.61,0,0,0,.52-2.45,7.58,7.58,0,0,1-.37,2.5.09.09,0,0,1-.1.06.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.3,14.05a7.71,7.71,0,0,0,.46-2.47,7.86,7.86,0,0,1-.31,2.51.08.08,0,0,1-.1.06.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.54,15.89A7.71,7.71,0,0,0,43,13.42a7.68,7.68,0,0,1-.31,2.52.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.38,14.15a7.71,7.71,0,0,0,.39-2.48,7.94,7.94,0,0,1-.24,2.52.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.79,16a7.71,7.71,0,0,0,.39-2.48A7.38,7.38,0,0,1,40.94,16a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.68,14.83A7.76,7.76,0,0,0,40,12.34a7.7,7.7,0,0,1-.19,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.47,14.11a7.77,7.77,0,0,0,.3-2.49,7.91,7.91,0,0,1-.14,2.52.09.09,0,0,1-.1.07.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.16,16.23a7.47,7.47,0,0,0,.25-2.49,7.7,7.7,0,0,1-.1,2.53.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.81,15.53A7.87,7.87,0,0,0,37,13a8,8,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.39,15.21a7.57,7.57,0,0,0,.1-2.51,7.67,7.67,0,0,1,0,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.42,17.14a7.55,7.55,0,0,0,0-2.51,7.67,7.67,0,0,1,.13,2.53.08.08,0,0,1-.08.07.07.07,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.86,16.06a7.84,7.84,0,0,0-.06-2.51A7.68,7.68,0,0,1,34,16.08a.07.07,0,0,1-.08.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.47,17.63a7.79,7.79,0,0,0-.15-2.5,7.86,7.86,0,0,1,.31,2.51.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.91,17.18a7.54,7.54,0,0,0-.28-2.5,7.56,7.56,0,0,1,.44,2.5.08.08,0,0,1-.07.08.09.09,0,0,1-.09-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,18.49A7.45,7.45,0,0,0,30.56,16a7.69,7.69,0,0,1,.58,2.46.08.08,0,0,1-.07.09A.1.1,0,0,1,31,18.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.74,17.69a7.6,7.6,0,0,0-.51-2.46,7.56,7.56,0,0,1,.67,2.44.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.54,18.39A7.76,7.76,0,0,0,27.88,16a7.74,7.74,0,0,1,.81,2.4.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.22,20a7.43,7.43,0,0,0-.73-2.4,7.63,7.63,0,0,1,.89,2.37.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.59,19.73a7.4,7.4,0,0,0-.88-2.36,7.61,7.61,0,0,1,1,2.31.07.07,0,0,1,0,.1.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.64,20.79a7.85,7.85,0,0,0-1-2.31,7.91,7.91,0,0,1,1.13,2.27.09.09,0,0,1-.05.1.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.82,22.3A7.48,7.48,0,0,0,23.76,20,7.6,7.6,0,0,1,25,22.25a.09.09,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.38,22.54a7.8,7.8,0,0,0-1.17-2.23,7.62,7.62,0,0,1,1.31,2.17.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.81,24.29a7.8,7.8,0,0,0-1.15-2.24A8,8,0,0,1,24,24.23a.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.21,24.14A7.73,7.73,0,0,0,21,22a7.7,7.7,0,0,1,1.39,2.12.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.7,25.92a7.59,7.59,0,0,0-1.2-2.2,7.68,7.68,0,0,1,1.35,2.14.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.1,25.7a7.74,7.74,0,0,0-1.29-2.15,7.68,7.68,0,0,1,1.43,2.09.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.54,27.49a7.61,7.61,0,0,0-1.26-2.17,7.46,7.46,0,0,1,1.4,2.11.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.89,27.19a7.48,7.48,0,0,0-1.33-2.13A7.74,7.74,0,0,1,20,27.12a.07.07,0,0,1-.13.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.81,28.67a7.73,7.73,0,0,0-1.32-2.14A7.75,7.75,0,0,1,20,28.6a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,30.19A7.65,7.65,0,0,0,18.69,28a7.91,7.91,0,0,1,1.43,2.09.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.39,30.07A7.72,7.72,0,0,0,17,28a7.87,7.87,0,0,1,1.49,2,.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.44,31.73a7.75,7.75,0,0,0-1.31-2.15,7.7,7.7,0,0,1,1.45,2.08.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.31,33A7.71,7.71,0,0,0,17,30.83a7.88,7.88,0,0,1,1.42,2.09.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.89,33.21a7.88,7.88,0,0,0-1.32-2.14A7.75,7.75,0,0,1,17,33.14a.09.09,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.09,34.33a7.49,7.49,0,0,0-1.3-2.15,7.67,7.67,0,0,1,1.44,2.08.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.6,35.34a7.78,7.78,0,0,0-1.22-2.2,7.71,7.71,0,0,1,1.36,2.14.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.82,36.35a7.59,7.59,0,0,0-1.22-2.19A7.51,7.51,0,0,1,16,36.29a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.19,37.31A7.5,7.5,0,0,0,16,35.08a7.59,7.59,0,0,1,1.3,2.17.1.1,0,0,1-.05.11.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.84,38.47a7.9,7.9,0,0,0-1.12-2.25A8,8,0,0,1,16,38.41a.07.07,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.43,39.19a7.91,7.91,0,0,0-1.06-2.28,7.59,7.59,0,0,1,1.2,2.22.08.08,0,0,1,0,.11.08.08,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.25,40a7.65,7.65,0,0,0-1.06-2.28A7.52,7.52,0,0,1,15.39,40a.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.64,40.58a7.64,7.64,0,0,0-1-2.3,7.63,7.63,0,0,1,1.16,2.25s0,.08-.05.1a.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,41.93a7.51,7.51,0,0,0-.94-2.33,7.86,7.86,0,0,1,1.09,2.28.07.07,0,0,1-.05.1.07.07,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.84,42.79a7.77,7.77,0,0,0-.92-2.34A7.73,7.73,0,0,1,15,42.74a.08.08,0,0,1-.05.1s-.08,0-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.55,44.45a7.46,7.46,0,0,0-.84-2.37,7.9,7.9,0,0,1,1,2.33.1.1,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.76,46A7.48,7.48,0,0,0,13,43.6,7.76,7.76,0,0,1,13.91,46a.07.07,0,0,1-.05.09.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.76,47.13a7.73,7.73,0,0,0-.71-2.4,7.88,7.88,0,0,1,.86,2.38.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.32,46.8a7.68,7.68,0,0,0-.73-2.4,7.7,7.7,0,0,1,.88,2.37.07.07,0,0,1,0,.09.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.4,45.7a7.65,7.65,0,0,0-.78-2.38,7.85,7.85,0,0,1,.93,2.35.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.69,44.72a7.42,7.42,0,0,0-.82-2.37,7.8,7.8,0,0,1,1,2.34.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.07,43.3A7.81,7.81,0,0,0,15.18,41a7.6,7.6,0,0,1,1,2.3.08.08,0,0,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.39,41.7a7.39,7.39,0,0,0-1-2.31,7.61,7.61,0,0,1,1.13,2.26.07.07,0,0,1,0,.1s-.08,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.9,39.73a7.59,7.59,0,0,0-1.1-2.25A7.57,7.57,0,0,1,13,39.68a.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.41,38a7.64,7.64,0,0,0-1.18-2.22A7.83,7.83,0,0,1,13.55,38a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.16,37.09a7.77,7.77,0,0,0-1.25-2.18A7.7,7.7,0,0,1,12.3,37a.08.08,0,0,1,0,.1.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.84,35.35a7.88,7.88,0,0,0-1.32-2.14A7.91,7.91,0,0,1,13,35.28a.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.36,34.71A7.67,7.67,0,0,0,10,32.6a7.71,7.71,0,0,1,1.49,2,.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.47,33.09A7.57,7.57,0,0,0,11.06,31a7.73,7.73,0,0,1,1.55,2,.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.49,33.22a7.94,7.94,0,0,0-1.42-2.07,7.56,7.56,0,0,1,1.55,2,.08.08,0,1,1-.13.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.13,31.55a7.76,7.76,0,0,0-1.46-2,8,8,0,0,1,1.6,2,.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.06,31.26a7.52,7.52,0,0,0-1.48-2A8,8,0,0,1,9.2,31.18a.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,30.3a7.38,7.38,0,0,0-1.5-2,7.81,7.81,0,0,1,1.64,1.93.09.09,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.4,29.14a7.83,7.83,0,0,0-1.53-2,7.8,7.8,0,0,1,1.66,1.91.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.18,29.44a7.63,7.63,0,0,0-1.51-2,7.61,7.61,0,0,1,1.64,1.93.06.06,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.82,28.19a7.6,7.6,0,0,0-1.54-2A7.59,7.59,0,0,1,11,28.11a.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.67,25.52a7.86,7.86,0,0,0-1.59-1.95A7.81,7.81,0,0,1,9.8,25.43a.08.08,0,0,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.23,23.74a7.42,7.42,0,0,0-1.61-1.92,7.57,7.57,0,0,1,1.74,1.83.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.19,22.37a7.88,7.88,0,0,0-1.61-1.93,7.61,7.61,0,0,1,1.74,1.84.08.08,0,0,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.41,21.93A7.65,7.65,0,0,0,6.78,20a8,8,0,0,1,1.76,1.82.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.41,20.19a7.61,7.61,0,0,0-1.62-1.91,7.61,7.61,0,0,1,1.75,1.83.07.07,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.72,19.5a7.62,7.62,0,0,0-1.64-1.9,7.61,7.61,0,0,1,1.77,1.81s0,.09,0,.11a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9,17.52A7.63,7.63,0,0,0,7.4,15.59a7.8,7.8,0,0,1,1.74,1.84.07.07,0,0,1,0,.11.06.06,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.62,18.11A7.47,7.47,0,0,0,5,16.21,7.61,7.61,0,0,1,6.75,18a.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.82,17.76a7.65,7.65,0,0,0-1.63-1.91A7.8,7.8,0,0,1,8,17.67a.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7,16.5a7.61,7.61,0,0,0-1.62-1.91,7.61,7.61,0,0,1,1.75,1.83.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.36,14.59a7.64,7.64,0,0,0-1.59-1.94A7.76,7.76,0,0,1,7.49,14.5a.07.07,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.74,12.45a7.82,7.82,0,0,0-1.5-2,8,8,0,0,1,1.63,1.93.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.42,12.33a7.62,7.62,0,0,0-1.52-2,7.4,7.4,0,0,1,1.65,1.92.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.2,13.34a7.26,7.26,0,0,0-1.56-2,7.82,7.82,0,0,1,1.7,1.88.09.09,0,0,1,0,.11s-.09,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.5,13.66a7.59,7.59,0,0,0-1.55-2,7.6,7.6,0,0,1,1.68,1.89.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.25,15.44a7.47,7.47,0,0,0-1.6-1.94,7.43,7.43,0,0,1,1.73,1.85.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.61,15.66A8.05,8.05,0,0,0,8,13.71a7.81,7.81,0,0,1,1.71,1.87.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,14.65a7.6,7.6,0,0,0-1.54-2,7.59,7.59,0,0,1,1.67,1.9.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.8,18.37a7.43,7.43,0,0,0-1.59-1.94,7.56,7.56,0,0,1,1.72,1.85.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,16.57a7.66,7.66,0,0,0-1.57-2,7.79,7.79,0,0,1,1.7,1.87.08.08,0,1,1-.13.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.92,14.45a8,8,0,0,0-1.5-2,7.74,7.74,0,0,1,1.63,1.93.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.13,19a7.76,7.76,0,0,0-1.55-2,8,8,0,0,1,1.68,1.89.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.2,15.62a7.62,7.62,0,0,0-1.48-2,8,8,0,0,1,1.62,1.95.07.07,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14,17.92a7.62,7.62,0,0,0-1.52-2,7.64,7.64,0,0,1,1.66,1.92.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.85,18a7.83,7.83,0,0,0-1.48-2,7.77,7.77,0,0,1,1.61,2A.09.09,0,0,1,16,18a.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.42,14.16A7.38,7.38,0,0,0,14,12.08a7.77,7.77,0,0,1,1.56,2,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.38,16A7.75,7.75,0,0,0,16,13.89a7.75,7.75,0,0,1,1.53,2,.08.08,0,0,1-.14.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.47,14.5a7.53,7.53,0,0,0-1.36-2.12,7.91,7.91,0,0,1,1.5,2,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.57,12.91a7.84,7.84,0,0,0-1.31-2.14,7.65,7.65,0,0,1,1.45,2.07.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,12.92a7.65,7.65,0,0,0-1.24-2.19,7.72,7.72,0,0,1,1.38,2.13.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.85,14.21A7.57,7.57,0,0,0,18.62,12,7.87,7.87,0,0,1,20,14.15a.08.08,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.64,12a7.87,7.87,0,0,0-1.11-2.25A7.73,7.73,0,0,1,20.78,12a.08.08,0,1,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.93,13.14a7.65,7.65,0,0,0-1.06-2.28,7.86,7.86,0,0,1,1.21,2.22.08.08,0,0,1,0,.11.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.39,11.57a7.5,7.5,0,0,0-1-2.32,7.66,7.66,0,0,1,1.11,2.28.08.08,0,1,1-.15.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.61,10.79a7.71,7.71,0,0,0-.81-2.38,7.86,7.86,0,0,1,1,2.34.09.09,0,0,1-.05.1.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.85,12.21a7.78,7.78,0,0,0-.77-2.39A7.89,7.89,0,0,1,25,12.18a.07.07,0,0,1-.05.09.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.9,10.4A7.7,7.7,0,0,0,25.36,8a8.17,8.17,0,0,1,.7,2.43.08.08,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.85,10.69a7.76,7.76,0,0,0-.33-2.49A7.76,7.76,0,0,1,28,10.68a.08.08,0,0,1-.08.08.07.07,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.41,10.84a7.53,7.53,0,0,0-.18-2.5,7.85,7.85,0,0,1,.34,2.51.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.1,10.71a7.55,7.55,0,0,0,0-2.51,7.69,7.69,0,0,1,.17,2.53.09.09,0,0,1-.09.07.08.08,0,0,1-.07-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.64,10.26a7.77,7.77,0,0,0,.15-2.5,8,8,0,0,1,0,2.53.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.92,10.25a7.56,7.56,0,0,0,.26-2.5,7.68,7.68,0,0,1-.11,2.53.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.9,9.84a7.48,7.48,0,0,0,.28-2.49,7.69,7.69,0,0,1-.13,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.45,9a7.78,7.78,0,0,0,.36-2.49,8,8,0,0,1-.21,2.53.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.33,9.35a7.7,7.7,0,0,0,.54-2.45,7.91,7.91,0,0,1-.39,2.5.08.08,0,1,1-.15-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.53,10.12a7.81,7.81,0,0,0,.71-2.4,7.8,7.8,0,0,1-.57,2.46.08.08,0,0,1-.1.05.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.54,11a7.86,7.86,0,0,0,1-2.32,7.64,7.64,0,0,1-.83,2.39.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.82,11.72A7.54,7.54,0,0,0,60,9.52,7.54,7.54,0,0,1,59,11.81a.08.08,0,1,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.9,11.25a7.77,7.77,0,0,0,1.53-2A7.82,7.82,0,0,1,65,11.36a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,10.66A7.87,7.87,0,0,0,70.69,8.8a7.57,7.57,0,0,1-1.59,2,.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.91,11.55a7.67,7.67,0,0,0,1.76-1.79A7.57,7.57,0,0,1,72,11.67a.08.08,0,1,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.2,12A7.67,7.67,0,0,0,76,10.2a7.56,7.56,0,0,1-1.69,1.88.08.08,0,1,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.64,12.77A7.43,7.43,0,0,0,81.43,11a7.8,7.8,0,0,1-1.7,1.88.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.7,14.71a7.52,7.52,0,0,0,1.75-1.81,7.62,7.62,0,0,1-1.65,1.93.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M84.39,16a7.62,7.62,0,0,0,1.7-1.84,7.87,7.87,0,0,1-1.59,2,.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M86.2,17a7.64,7.64,0,0,0,1.64-1.9,7.65,7.65,0,0,1-1.53,2A.08.08,0,1,1,86.2,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M87.68,19.81a7.59,7.59,0,0,0,1.5-2,7.62,7.62,0,0,1-1.39,2.12.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M87.77,18.35a7.84,7.84,0,0,0,1.56-2,8.1,8.1,0,0,1-1.45,2.08.08.08,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M86.81,20.9a7.8,7.8,0,0,0,1.47-2A7.82,7.82,0,0,1,86.93,21a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M85.92,18.94a7.44,7.44,0,0,0,1.58-2A7.67,7.67,0,0,1,86,19.05a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M85.21,21.17a8,8,0,0,0,1.49-2,8,8,0,0,1-1.38,2.13.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M84.38,19.87a7.61,7.61,0,0,0,1.57-2A7.85,7.85,0,0,1,84.49,20a.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M84.27,17.89A7.64,7.64,0,0,0,85.91,16a7.33,7.33,0,0,1-1.54,2,.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M83,18.5a7.64,7.64,0,0,0,1.64-1.9,7.49,7.49,0,0,1-1.53,2A.08.08,0,0,1,83,18.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M82.66,20.82a7.46,7.46,0,0,0,1.56-2,7.93,7.93,0,0,1-1.45,2.08.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.62,19.65a8.06,8.06,0,0,0,1.62-1.92,7.89,7.89,0,0,1-1.51,2,.08.08,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M81.5,16.54a7.63,7.63,0,0,0,1.71-1.83,7.72,7.72,0,0,1-1.61,1.95.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.8,17.94a7.83,7.83,0,0,0,1.68-1.86,7.92,7.92,0,0,1-1.58,2,.08.08,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80,15.26a7.52,7.52,0,0,0,1.76-1.8,7.79,7.79,0,0,1-1.66,1.92.08.08,0,0,1-.1-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.71,16.39a7.63,7.63,0,0,0,1.73-1.81,7.71,7.71,0,0,1-1.63,1.93.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.82,15.1a7.3,7.3,0,0,0,1.75-1.8,7.4,7.4,0,0,1-1.65,1.92.07.07,0,1,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.93,16.55a7.44,7.44,0,0,0,1.72-1.82A7.72,7.72,0,0,1,75,16.67a.07.07,0,1,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.31,17.91A7.44,7.44,0,0,0,77,16.06a7.91,7.91,0,0,1-1.59,2,.07.07,0,1,1-.1-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.47,12.85a7.52,7.52,0,0,0,1.8-1.76A7.61,7.61,0,0,1,76.57,13a.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.42,13.87a7.67,7.67,0,0,0,1.77-1.78A7.76,7.76,0,0,1,75.52,14a.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.12,15.61a7.48,7.48,0,0,0,1.75-1.8,7.75,7.75,0,0,1-1.65,1.92.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.41,17a7.66,7.66,0,0,0,1.72-1.83,7.72,7.72,0,0,1-1.61,1.95.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.72,14.37A7.67,7.67,0,0,0,78.5,12.6a7.56,7.56,0,0,1-1.68,1.89.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.84,13.33a7.48,7.48,0,0,0,1.79-1.76,7.38,7.38,0,0,1-1.69,1.88.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.62,15.73a7.67,7.67,0,0,0,1.75-1.8,7.6,7.6,0,0,1-1.65,1.92.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.44,14.6a7.48,7.48,0,0,0,1.77-1.78,7.76,7.76,0,0,1-1.67,1.9.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77,18.61a7.63,7.63,0,0,0,1.67-1.87,7.66,7.66,0,0,1-1.57,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.08,19.33a7.68,7.68,0,0,0,1.66-1.89,7.68,7.68,0,0,1-1.55,2,.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.91,19.37a7.44,7.44,0,0,0,1.65-1.89,7.91,7.91,0,0,1-1.54,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.83,17.58a7.64,7.64,0,0,0,1.7-1.84,7.73,7.73,0,0,1-1.6,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.58,20.49a7.46,7.46,0,0,0,1.61-1.93,7.68,7.68,0,0,1-1.5,2,.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.06,18.29a7.67,7.67,0,0,0,1.69-1.86,8,8,0,0,1-1.58,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.89,20.78a7.85,7.85,0,0,0,1.6-1.94A7.86,7.86,0,0,1,78,20.89a.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.52,20.76a8,8,0,0,0,1.59-1.94,8.06,8.06,0,0,1-1.48,2.05.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.79,22.37a7.41,7.41,0,0,0,1.52-2,7.61,7.61,0,0,1-1.4,2.11.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.33,23a7.75,7.75,0,0,0,1.47-2,7.72,7.72,0,0,1-1.36,2.13s-.07,0-.11,0,0-.07,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.36,24.47a7.73,7.73,0,0,0,1.4-2.09,7.58,7.58,0,0,1-1.28,2.18.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.32,26.06a7.63,7.63,0,0,0,1.3-2.15,7.73,7.73,0,0,1-1.17,2.24.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.53,27.48a7.49,7.49,0,0,0,1.22-2.19,7.6,7.6,0,0,1-1.09,2.28.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.56,29.12a7.84,7.84,0,0,0,1.12-2.24,8,8,0,0,1-1,2.33.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.41,30.41a7.74,7.74,0,0,0,1.07-2.27,7.82,7.82,0,0,1-.94,2.35.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.8,32.52a7.74,7.74,0,0,0,1-2.31,7.66,7.66,0,0,1-.84,2.39.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.44,34.94a7.72,7.72,0,0,0,.87-2.36A8,8,0,0,1,77.59,35a.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77,37.22a7.48,7.48,0,0,0,.78-2.38,7.84,7.84,0,0,1-.63,2.45.07.07,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.33,39a7.56,7.56,0,0,0,.72-2.4A7.81,7.81,0,0,1,76.47,39a.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.82,41.11a7.5,7.5,0,0,0,.66-2.42A7.58,7.58,0,0,1,76,41.17a.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.38,42.79A7.65,7.65,0,0,0,76,40.36a7.85,7.85,0,0,1-.48,2.48.07.07,0,0,1-.1,0,.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75,44.35a7.84,7.84,0,0,0,.58-2.44,7.55,7.55,0,0,1-.44,2.49.08.08,0,0,1-.09.06.08.08,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.63,43.38A7.88,7.88,0,0,0,74.28,41a8.11,8.11,0,0,1-.5,2.48.09.09,0,0,1-.1,0,.07.07,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.56,40.85a7.68,7.68,0,0,0,.73-2.4,7.51,7.51,0,0,1-.59,2.46.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.93,38.47a7.54,7.54,0,0,0,.81-2.38,7.69,7.69,0,0,1-.66,2.44.1.1,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,35.86a7.65,7.65,0,0,0,.91-2.35,8,8,0,0,1-.77,2.42.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.77,33.67a7.48,7.48,0,0,0,1-2.3,7.75,7.75,0,0,1-.86,2.37.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.57,31.36a7.62,7.62,0,0,0,1.11-2.25,7.7,7.7,0,0,1-1,2.33.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,29.22A7.75,7.75,0,0,0,74.25,27a7.82,7.82,0,0,1-1.08,2.28.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,26.09A7.67,7.67,0,0,0,73.38,24a7.62,7.62,0,0,1-1.23,2.21.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.63,23.8a7.57,7.57,0,0,0,1.44-2.06,8,8,0,0,1-1.32,2.16.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.6,23a7.56,7.56,0,0,0,1.48-2,7.72,7.72,0,0,1-1.36,2.13.08.08,0,1,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.94,22.2a7.64,7.64,0,0,0,1.5-2,7.87,7.87,0,0,1-1.38,2.13.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.41,16.09A7.29,7.29,0,0,0,70,14.17a7.94,7.94,0,0,1-1.51,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.13,12.17a7.63,7.63,0,0,0,1.62-1.92,7.89,7.89,0,0,1-1.51,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.92,9.69a7.69,7.69,0,0,0,1.58-2A7.72,7.72,0,0,1,66,9.8a.08.08,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.31,8.46a7.81,7.81,0,0,0,1.5-2,7.82,7.82,0,0,1-1.38,2.12.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.27,8.13A7.74,7.74,0,0,0,63.65,6a7.85,7.85,0,0,1-1.26,2.19.08.08,0,1,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.69,8.54A7.51,7.51,0,0,0,62,6.38a7.88,7.88,0,0,1-1.16,2.25.07.07,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.81,7.44A7.54,7.54,0,0,0,61,5.24a7.49,7.49,0,0,1-1.09,2.28.08.08,0,1,1-.13-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.26,9.12a7.75,7.75,0,0,0,1.21-2.2,7.74,7.74,0,0,1-1.08,2.29.08.08,0,1,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.57,10.51a7.4,7.4,0,0,0,1.25-2.18A7.88,7.88,0,0,1,59.7,10.6a.08.08,0,1,1-.13-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.13,10.57a7.69,7.69,0,0,0,1.34-2.12,8,8,0,0,1-1.21,2.22.09.09,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.93,84.73a7.65,7.65,0,0,0-2.08-1.39A7.83,7.83,0,0,1,22,84.61a.08.08,0,1,1-.09.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.28,84.12a7.86,7.86,0,0,0-2.09-1.39A8,8,0,0,1,23.38,84a.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.68,84.55a7.58,7.58,0,0,0-2.09-1.4,7.54,7.54,0,0,1,2.19,1.28.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.68,85.59a7.75,7.75,0,0,0-2.1-1.39,7.74,7.74,0,0,1,2.19,1.27.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.15,87.17a7.78,7.78,0,0,0-2.09-1.39,7.74,7.74,0,0,1,2.19,1.27.08.08,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.56,89.18a7.68,7.68,0,0,0-2.08-1.4,7.86,7.86,0,0,1,2.18,1.28.08.08,0,0,1,0,.11s-.07,0-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.48,91.24a7.79,7.79,0,0,0-2.06-1.44,7.74,7.74,0,0,1,2.16,1.32.08.08,0,1,1-.09.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.76,93.81a7.8,7.8,0,0,0-2-1.51,7.61,7.61,0,0,1,2.11,1.4.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.93,97.13A7.89,7.89,0,0,0,25,95.47,7.6,7.6,0,0,1,27,97a.08.08,0,1,1-.11.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.84,98.77A7.71,7.71,0,0,0,26.07,97,8,8,0,0,1,28,98.67a.08.08,0,1,1-.12.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.2,100.58a7.8,7.8,0,0,0-1.61-1.92,7.61,7.61,0,0,1,1.74,1.84.06.06,0,0,1,0,.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.65,101.55a7.78,7.78,0,0,0-1.45-2,7.92,7.92,0,0,1,1.58,2,.07.07,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.71,103.17A7.71,7.71,0,0,0,29.43,101a7.65,7.65,0,0,1,1.42,2.09.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.84,104.51a7.63,7.63,0,0,0-1.17-2.22A7.83,7.83,0,0,1,32,104.45a.08.08,0,0,1,0,.11s-.09,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.41,106.2a7.65,7.65,0,0,0-1.1-2.26,7.79,7.79,0,0,1,1.25,2.21.07.07,0,0,1,0,.1s-.09,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.69,106.78a7.63,7.63,0,0,0-1-2.28,7.52,7.52,0,0,1,1.2,2.23.07.07,0,0,1,0,.1.1.1,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.31,108.49a7.62,7.62,0,0,0-1-2.31,7.76,7.76,0,0,1,1.14,2.26.07.07,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.54,109.77a7.49,7.49,0,0,0-.93-2.33,7.53,7.53,0,0,1,1.08,2.29.07.07,0,0,1,0,.09.06.06,0,0,1-.09,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.53,111.1a7.6,7.6,0,0,0-.86-2.36,7.75,7.75,0,0,1,1,2.32.09.09,0,0,1,0,.1.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.28,112a8.1,8.1,0,0,0-.79-2.39,8,8,0,0,1,.94,2.35.08.08,0,0,1-.06.1.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41,112.31a7.47,7.47,0,0,0-.71-2.4,7.68,7.68,0,0,1,.87,2.37.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.67,112.25a7.53,7.53,0,0,0-.6-2.44,7.28,7.28,0,0,1,.75,2.41.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.15,111a7.7,7.7,0,0,0-.45-2.47,7.72,7.72,0,0,1,.6,2.46.07.07,0,0,1-.07.08.06.06,0,0,1-.08-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.09,109.83a7.46,7.46,0,0,0-.29-2.49,7.77,7.77,0,0,1,.45,2.49.08.08,0,0,1-.07.08.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,108a7.83,7.83,0,0,0,0-2.51,7.61,7.61,0,0,1,.18,2.52.08.08,0,0,1-.08.08A.08.08,0,0,1,50,108Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,106.14a7.53,7.53,0,0,0,.38-2.49,7.63,7.63,0,0,1-.23,2.52.07.07,0,0,1-.09.06.07.07,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.67,103.61a7.63,7.63,0,0,0,.9-2.35,7.61,7.61,0,0,1-.76,2.41.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.61,100.57a7.64,7.64,0,0,0,1.59-1.94,7.72,7.72,0,0,1-1.48,2.05.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.65,97.84a7.58,7.58,0,0,0,2.16-1.27A7.74,7.74,0,0,1,57.71,98a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,95.67a8.12,8.12,0,0,0,2.33-.93A8,8,0,0,1,59,95.82a.09.09,0,0,1-.1,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.84,93.94a7.6,7.6,0,0,0,2.37-.83,7.74,7.74,0,0,1-2.33,1,.07.07,0,0,1-.09,0,.07.07,0,0,1,.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.07,92.51a7.74,7.74,0,0,0,2.39-.75,7.85,7.85,0,0,1-2.36.9.09.09,0,0,1-.1-.06.08.08,0,0,1,.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.84,90a7.46,7.46,0,0,0,2.42-.63,7.7,7.7,0,0,1-2.4.79.08.08,0,0,1-.09-.06.07.07,0,0,1,.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.15,82.48A7.64,7.64,0,0,0,63.61,82a7.94,7.94,0,0,1-2.44.65.08.08,0,0,1-.09-.07.08.08,0,0,1,.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.65,79.86a7.55,7.55,0,0,0,2.44-.61,7.79,7.79,0,0,1-2.41.76.07.07,0,0,1-.09-.06.08.08,0,0,1,.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.31,78.52a7.83,7.83,0,0,0,2.41-.69,7.73,7.73,0,0,1-2.39.84.08.08,0,1,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.82,78.32a7.87,7.87,0,0,0,2.41-.71,7.56,7.56,0,0,1-2.38.86.08.08,0,0,1-.1-.06.08.08,0,0,1,.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.18,79.09a7.57,7.57,0,0,0,2.42-.7,7.5,7.5,0,0,1-2.39.85.07.07,0,0,1-.09-.06.08.08,0,0,1,.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.58,79.89A7.6,7.6,0,0,0,60,79.18a7.83,7.83,0,0,1-2.38.87.08.08,0,0,1-.1-.06.09.09,0,0,1,.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.41,81.49a7.56,7.56,0,0,0,2.42-.65,7.74,7.74,0,0,1-2.4.81.08.08,0,0,1-.09-.06.07.07,0,0,1,.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.63,82.25A7.8,7.8,0,0,0,58,81.53a7.64,7.64,0,0,1-2.38.87.08.08,0,1,1,0-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.41,84.32a7.56,7.56,0,0,0,2.41-.69,7.75,7.75,0,0,1-2.39.85.08.08,0,0,1-.09-.06.08.08,0,0,1,.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.14,85.44a7.8,7.8,0,0,0,2.4-.75,7.74,7.74,0,0,1-2.37.9.08.08,0,0,1-.09-.06.07.07,0,0,1,.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.45,87.2a7.42,7.42,0,0,0,2.38-.79,7.79,7.79,0,0,1-2.35,1,.08.08,0,0,1-.09-.06.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.12,88.54a7.62,7.62,0,0,0,2.25-1.11,8,8,0,0,1-2.2,1.26.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.89,90.32a7.64,7.64,0,0,0,1.89-1.65A7.8,7.8,0,0,1,51,90.45a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.2,92a7.67,7.67,0,0,0,1.74-1.81,8,8,0,0,1-1.64,1.93.08.08,0,0,1-.11-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.17,94a7.61,7.61,0,0,0,1.17-2.22,7.63,7.63,0,0,1-1,2.31.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.82,96.11a7.36,7.36,0,0,0,.8-2.38A7.73,7.73,0,0,1,50,96.18a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.61,99a7.54,7.54,0,0,0,.23-2.5A7.69,7.69,0,0,1,48.76,99a.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.75,101.79a7.82,7.82,0,0,0-.1-2.51,7.62,7.62,0,0,1,.25,2.52.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.3,105.31a7.44,7.44,0,0,0-.41-2.47,7.46,7.46,0,0,1,.56,2.46.07.07,0,0,1-.07.09.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,107.46a7.7,7.7,0,0,0-.54-2.46,8,8,0,0,1,.7,2.44.09.09,0,0,1-.07.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.6,108.5a7.47,7.47,0,0,0-.64-2.42,7.72,7.72,0,0,1,.8,2.4.09.09,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.48,109.61a7.83,7.83,0,0,0-.69-2.41,7.68,7.68,0,0,1,.84,2.38.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.14,109.06a7.59,7.59,0,0,0-.76-2.39,7.61,7.61,0,0,1,.91,2.36.07.07,0,0,1,0,.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.05,108a7.64,7.64,0,0,0-.86-2.35,7.75,7.75,0,0,1,1,2.32.07.07,0,0,1-.05.09.09.09,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37,106.37a7.5,7.5,0,0,0-1-2.32,7.73,7.73,0,0,1,1.11,2.27.08.08,0,0,1-.05.1s-.08,0-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.32,104.37a7.41,7.41,0,0,0-1.06-2.28,7.78,7.78,0,0,1,1.21,2.23.07.07,0,0,1,0,.1.08.08,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.83,101.83a7.75,7.75,0,0,0-1.19-2.21A7.67,7.67,0,0,1,34,101.77a.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.25,99.15a7.58,7.58,0,0,0-1.43-2.07,8,8,0,0,1,1.57,2,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.25,95a7.3,7.3,0,0,0-1.8-1.75,7.75,7.75,0,0,1,1.92,1.65.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.46,89.52a7.37,7.37,0,0,0-2-1.51,7.57,7.57,0,0,1,2.11,1.39.08.08,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,82.69A7.74,7.74,0,0,0,25,81.26a7.76,7.76,0,0,1,2.17,1.31.08.08,0,1,1-.1.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.86,79A7.53,7.53,0,0,0,23.8,77.6,7.71,7.71,0,0,1,26,78.91.08.08,0,0,1,26,79a.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,76.68A7.55,7.55,0,0,0,23,75.23a7.72,7.72,0,0,1,2.15,1.33.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.79,73.87a7.76,7.76,0,0,0-2-1.5,7.57,7.57,0,0,1,2.11,1.39.07.07,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.69,71.15a7.65,7.65,0,0,0-2-1.58A7.67,7.67,0,0,1,24.8,71a.07.07,0,1,1-.1.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,68.46a7.85,7.85,0,0,0-1.89-1.65,7.69,7.69,0,0,1,2,1.54.08.08,0,0,1,0,.12.09.09,0,0,1-.12,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.79,62a7.64,7.64,0,0,0-1.54-2A7.63,7.63,0,0,1,14.92,62a.09.09,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.08,56.26a7.56,7.56,0,0,0-.72-2.4,7.66,7.66,0,0,1,.88,2.38.08.08,0,0,1-.06.09.09.09,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.68,50.21a7.55,7.55,0,0,0-.61-2.43,7.81,7.81,0,0,1,.77,2.41.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.4,42.69a7.62,7.62,0,0,0-.87-2.36,7.53,7.53,0,0,1,1,2.32s0,.08-.05.1a.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.31,37a7.75,7.75,0,0,0-1-2.3,7.63,7.63,0,0,1,1.16,2.25.08.08,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.79,35.31a7.75,7.75,0,0,0-1-2.29,7.88,7.88,0,0,1,1.18,2.24.08.08,0,0,1-.05.1.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.05,35.68a7.52,7.52,0,0,0-1-2.32,7.51,7.51,0,0,1,1.12,2.27.08.08,0,0,1-.14.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.21,39.13a7.73,7.73,0,0,0-.82-2.38,7.63,7.63,0,0,1,1,2.34.09.09,0,0,1-.06.1.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.4,44.5a7.66,7.66,0,0,0-.67-2.42,7.75,7.75,0,0,1,.82,2.4.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.68,51.93a7.71,7.71,0,0,0-.55-2.45,7.91,7.91,0,0,1,.71,2.43.08.08,0,0,1-.07.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.42,54.37a7.7,7.7,0,0,0-.54-2.45,7.61,7.61,0,0,1,.7,2.44.07.07,0,0,1-.07.08.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.71,57a7.59,7.59,0,0,0-.55-2.45A7.64,7.64,0,0,1,28.87,57a.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.6,58.7A7.58,7.58,0,0,0,27,56.27a7.83,7.83,0,0,1,.78,2.41.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.11,58.73a7.58,7.58,0,0,0-.73-2.4,8,8,0,0,1,.89,2.37.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.62,56.87A7.68,7.68,0,0,0,26,54.43a7.53,7.53,0,0,1,.76,2.41.08.08,0,0,1-.06.1.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.8,54.21a7.53,7.53,0,0,0-.6-2.44A7.77,7.77,0,0,1,28,54.18a.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.42,50.2a7.63,7.63,0,0,0-.58-2.45,7.6,7.6,0,0,1,.73,2.43.08.08,0,0,1-.06.09.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.24,48a7.66,7.66,0,0,0-.56-2.45A7.92,7.92,0,0,1,30.4,48a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.55,44.91A7.67,7.67,0,0,0,31,42.45a7.92,7.92,0,0,1,.68,2.44.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.78,41.85a7.46,7.46,0,0,0-.47-2.47,8,8,0,0,1,.63,2.46.07.07,0,0,1-.07.08.06.06,0,0,1-.08-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.57,37.85a7.5,7.5,0,0,0-.35-2.49,7.78,7.78,0,0,1,.51,2.48.08.08,0,0,1-.07.08s-.08,0-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.15,35.34a7.74,7.74,0,0,0-.3-2.49,7.72,7.72,0,0,1,.45,2.49.07.07,0,0,1-.07.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.86,32.36a7.47,7.47,0,0,0-.31-2.49A7.74,7.74,0,0,1,35,32.36a.08.08,0,0,1-.08.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.15,33.18a7.82,7.82,0,0,0-.1-2.51,8.19,8.19,0,0,1,.26,2.52.08.08,0,0,1-.08.07.07.07,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.53,32.12a7.56,7.56,0,0,0-.16-2.51,7.59,7.59,0,0,1,.31,2.51.08.08,0,0,1-.08.08.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.19,33.74a7.46,7.46,0,0,0-.29-2.49,7.46,7.46,0,0,1,.44,2.49.07.07,0,0,1-.07.08.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.24,37a7.39,7.39,0,0,0-.46-2.46A7.62,7.62,0,0,1,33.4,37a.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.48,40.41A7.44,7.44,0,0,0,32,38a7.66,7.66,0,0,1,.65,2.45.08.08,0,0,1-.06.09.1.1,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.27,45.59a7.92,7.92,0,0,0-.59-2.45,7.63,7.63,0,0,1,.74,2.42.08.08,0,0,1-.06.1.08.08,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.81,50.31a7.85,7.85,0,0,0-.63-2.43,7.68,7.68,0,0,1,.78,2.4.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.69,52.34a7.85,7.85,0,0,0-.63-2.43,8.16,8.16,0,0,1,.79,2.4.09.09,0,0,1-.07.1.09.09,0,0,1-.09-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.16,54.21a7.85,7.85,0,0,0-.63-2.43,7.83,7.83,0,0,1,.78,2.41.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.14,55.07a7.55,7.55,0,0,0-.61-2.43,7.72,7.72,0,0,1,.77,2.41.08.08,0,0,1-.06.09.08.08,0,0,1-.1-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,54a7.84,7.84,0,0,0-.62-2.43A7.81,7.81,0,0,1,24.11,54a.08.08,0,0,1-.06.09A.07.07,0,0,1,24,54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.58,52.66A7.58,7.58,0,0,0,23,50.23a7.68,7.68,0,0,1,.78,2.4.07.07,0,0,1-.06.09.08.08,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.12,50.47a7.49,7.49,0,0,0-.65-2.42,7.77,7.77,0,0,1,.81,2.39.08.08,0,0,1-.06.1.09.09,0,0,1-.1-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.52,47.25a7.52,7.52,0,0,0-.67-2.42,7.72,7.72,0,0,1,.83,2.39.08.08,0,0,1-.06.09.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.22,41.44A8.14,8.14,0,0,0,28.55,39a7.75,7.75,0,0,1,.82,2.4.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.79,31a7.75,7.75,0,0,0-1-2.32,7.86,7.86,0,0,1,1.11,2.27.08.08,0,0,1-.05.1.09.09,0,0,1-.1-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.34,23.86a7.79,7.79,0,0,0-.71-2.41,8,8,0,0,1,.86,2.38.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.32,21.7a8,8,0,0,0-.5-2.46,7.66,7.66,0,0,1,.65,2.45.06.06,0,0,1-.06.08.07.07,0,0,1-.09-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.55,21a7.69,7.69,0,0,0-.37-2.48A8,8,0,0,1,32.71,21a.09.09,0,0,1-.08.09.08.08,0,0,1-.08-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.73,22.64a7.62,7.62,0,0,0-.92-2.34,7.79,7.79,0,0,1,1.07,2.3.08.08,0,0,1-.06.1.07.07,0,0,1-.09-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.65,23.44a7.9,7.9,0,0,0-1.41-2.07,7.74,7.74,0,0,1,1.54,2,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.44,25.54a7.72,7.72,0,0,0-1.49-2,7.74,7.74,0,0,1,1.63,1.93.09.09,0,0,1,0,.11.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.07,27.28a7.78,7.78,0,0,0-1.48-2,8,8,0,0,1,1.62,1.95.08.08,0,0,1,0,.1.07.07,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.3,28.73a7.39,7.39,0,0,0-1.46-2,7.6,7.6,0,0,1,1.6,2,.09.09,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14,29.33a7.62,7.62,0,0,0-1.48-2,7.76,7.76,0,0,1,1.61,1.95.09.09,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.52,30.56a7.57,7.57,0,0,0-1.44-2.06,8,8,0,0,1,1.58,2,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.29,25.68a7.57,7.57,0,0,0-1.44-2.06,7.79,7.79,0,0,1,1.58,2,.08.08,0,0,1,0,.11.07.07,0,0,1-.1,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.41,19.79a7.85,7.85,0,0,0-1.26-2.17,7.86,7.86,0,0,1,1.4,2.1.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.63,12.21a7.63,7.63,0,0,0-.58-2.44,7.54,7.54,0,0,1,.73,2.42.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.1,7.61a7.56,7.56,0,0,0,.1-2.51,7.67,7.67,0,0,1,.05,2.53.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.31,6.82a7.49,7.49,0,0,0,.29-2.49,7.92,7.92,0,0,1-.13,2.52.08.08,0,0,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.76,6.73a7.76,7.76,0,0,0,.29-2.49,7.71,7.71,0,0,1-.14,2.53.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.2,6.33a7.75,7.75,0,0,0,.28-2.49,7.63,7.63,0,0,1-.13,2.52.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.33,7.08a7.82,7.82,0,0,0,.29-2.5,7.69,7.69,0,0,1-.14,2.53.07.07,0,0,1-.09.06.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.65,51a8,8,0,0,0,.5-2.46A7.87,7.87,0,0,1,71.8,51a.09.09,0,0,1-.1.06.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.34,49.9a7.65,7.65,0,0,0,.5-2.46A7.66,7.66,0,0,1,72.49,50a.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.68,50a7.64,7.64,0,0,0,.54-2.45,7.87,7.87,0,0,1-.39,2.5.08.08,0,0,1-.1,0,.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.39,48.5a7.65,7.65,0,0,0,.55-2.45,7.61,7.61,0,0,1-.4,2.5.08.08,0,0,1-.1.05.07.07,0,0,1,0-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.05,46a7.91,7.91,0,0,0,.62-2.43,7.85,7.85,0,0,1-.48,2.48.07.07,0,0,1-.09.05A.08.08,0,0,1,71,46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.67,43.49a7.77,7.77,0,0,0,.7-2.41,7.52,7.52,0,0,1-.56,2.47.08.08,0,0,1-.1.05.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.4,41.69a7.52,7.52,0,0,0,.77-2.39,8,8,0,0,1-.62,2.45.08.08,0,1,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.75,39.7a8,8,0,0,0,.84-2.37,7.62,7.62,0,0,1-.7,2.43.07.07,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.27,37.47a7.9,7.9,0,0,0,.93-2.34,7.85,7.85,0,0,1-.79,2.41.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,35a7.39,7.39,0,0,0,1-2.29A7.82,7.82,0,0,1,67.13,35a.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.62,36.4a7.59,7.59,0,0,0,1-2.31,7.52,7.52,0,0,1-.84,2.38.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.94,41.13a7.84,7.84,0,0,0,.83-2.38,7.74,7.74,0,0,1-.69,2.44.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,47a7.82,7.82,0,0,0,.65-2.42,7.83,7.83,0,0,1-.51,2.48.08.08,0,0,1-.1,0,.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.83,50.78a7.81,7.81,0,0,0,.56-2.44A7.79,7.79,0,0,1,68,50.83a.07.07,0,0,1-.09.06.1.1,0,0,1-.06-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.46,53.4a7.92,7.92,0,0,0,.5-2.46,7.93,7.93,0,0,1-.35,2.51.09.09,0,0,1-.1,0,.07.07,0,0,1-.05-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.64,52.46A7.61,7.61,0,0,0,69.16,50a7.59,7.59,0,0,1-.38,2.5.07.07,0,0,1-.09.05.08.08,0,0,1-.06-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.4,47.82A7.89,7.89,0,0,0,69,45.39a7.52,7.52,0,0,1-.47,2.48.08.08,0,0,1-.1.06.09.09,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.4,39.13a7.94,7.94,0,0,0,.9-2.34,8,8,0,0,1-.76,2.41.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.17,30.94a7.53,7.53,0,0,0,1.14-2.24,8,8,0,0,1-1,2.33.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.2,24.33a7.83,7.83,0,0,0,1.29-2.15,7.73,7.73,0,0,1-1.17,2.24.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.71,22.9A7.63,7.63,0,0,0,63,20.75,7.64,7.64,0,0,1,61.83,23a.07.07,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.85,21.79a7.86,7.86,0,0,0,1.39-2.09A7.88,7.88,0,0,1,64,21.89a.07.07,0,0,1-.1,0,.06.06,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.71,21.22a7.8,7.8,0,0,0,1.47-2,7.63,7.63,0,0,1-1.36,2.14.08.08,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.1,19.9a7.8,7.8,0,0,0,1.55-2A7.82,7.82,0,0,1,69.21,20a.08.08,0,0,1-.12-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.42,18.08A7.82,7.82,0,0,0,72,16.17a7.71,7.71,0,0,1-1.52,2,.08.08,0,0,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.46,16.45a7.86,7.86,0,0,0,1.67-1.88,7.72,7.72,0,0,1-1.56,2,.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.64,17.51a7.42,7.42,0,0,0,1.6-1.93,7.65,7.65,0,0,1-1.49,2,.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.58,15.59a8,8,0,0,0,1.49-2,7.82,7.82,0,0,1-1.38,2.12.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.71,13.72a7.9,7.9,0,0,0,1.23-2.19,7.7,7.7,0,0,1-1.1,2.27.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.45,11.93a7.77,7.77,0,0,0,1-2.29,8.11,8.11,0,0,1-.9,2.37.09.09,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.43,8.49a7.46,7.46,0,0,0,.78-2.39,7.72,7.72,0,0,1-.64,2.45.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.8,9.93a7.32,7.32,0,0,0,.84-2.36,7.7,7.7,0,0,1-.7,2.43.08.08,0,1,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.43,7.52a7.74,7.74,0,0,0,.88-2.36,7.9,7.9,0,0,1-.74,2.42.08.08,0,0,1-.11,0s-.05-.06,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.91,7.83A7.62,7.62,0,0,0,59,5.58a7.68,7.68,0,0,1-1,2.33.08.08,0,1,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.14,9.67a7.74,7.74,0,0,0,1.44-2,7.77,7.77,0,0,1-1.33,2.16s-.07,0-.11,0a.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.29,13.79a7.9,7.9,0,0,0,1.65-1.9,7.74,7.74,0,0,1-1.54,2,.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.73,20.34a7.66,7.66,0,0,0,1.57-2,7.69,7.69,0,0,1-1.46,2.07.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.27,21.59a7.43,7.43,0,0,0,1.54-2,7.43,7.43,0,0,1-1.42,2.09.08.08,0,1,1-.12-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.14,18.94A7.45,7.45,0,0,0,72.76,17a7.88,7.88,0,0,1-1.51,2,.08.08,0,1,1-.11-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.9,13.88a7.81,7.81,0,0,0,1.59-1.94A7.88,7.88,0,0,1,67,14a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.49,11.61a7.7,7.7,0,0,0,1.46-2,7.56,7.56,0,0,1-1.34,2.14.07.07,0,0,1-.11,0s0-.07,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.49,9.86a7.65,7.65,0,0,0,1.12-2.25,7.48,7.48,0,0,1-1,2.33.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.7,8.85a7.34,7.34,0,0,0,.93-2.33,7.61,7.61,0,0,1-.79,2.4.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.63,6.91a8,8,0,0,0,.75-2.4A7.8,7.8,0,0,1,52.77,7a.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.73,7.61a7.54,7.54,0,0,0,.72-2.41,7.55,7.55,0,0,1-.58,2.47.08.08,0,1,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,8.35a7.48,7.48,0,0,0,1-2.3,7.91,7.91,0,0,1-.86,2.38A.08.08,0,1,1,56,8.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.75,13.15A7.62,7.62,0,0,0,62.08,11a7.72,7.72,0,0,1-1.2,2.22.07.07,0,0,1-.11,0s0-.07,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.52,14.92a7.68,7.68,0,0,0,1.37-2.1A8,8,0,0,1,61.65,15a.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.8,14.59a7.62,7.62,0,0,0,1.42-2.07,7.4,7.4,0,0,1-1.31,2.17.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,16.27a7.48,7.48,0,0,0,1.42-2.07,7.5,7.5,0,0,1-1.3,2.17s-.07,0-.11,0a.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.3,18.64a7.57,7.57,0,0,0,1.44-2.06,7.51,7.51,0,0,1-1.32,2.16.08.08,0,0,1-.11,0,.07.07,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.69,17.69a7.79,7.79,0,0,0,1.44-2.06,7.74,7.74,0,0,1-1.32,2.16.08.08,0,1,1-.12-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,18.1A7.62,7.62,0,0,0,63.33,16a7.6,7.6,0,0,1-1.24,2.2.08.08,0,0,1-.11,0,.09.09,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.1,17.54a7.83,7.83,0,0,0,1.29-2.15,7.61,7.61,0,0,1-1.17,2.24.07.07,0,0,1-.11,0,.08.08,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.63,16.32a7.87,7.87,0,0,0,1.23-2.19,7.78,7.78,0,0,1-1.11,2.28.06.06,0,0,1-.1,0,.07.07,0,0,1,0-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.73,17.25A7.86,7.86,0,0,0,58.92,15a8.15,8.15,0,0,1-1.06,2.3.08.08,0,0,1-.14-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.77,16.1a7.5,7.5,0,0,0,1.09-2.26,7.56,7.56,0,0,1-1,2.34.07.07,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.28,15.59a7.75,7.75,0,0,0,1-2.3,7.6,7.6,0,0,1-.88,2.38.08.08,0,0,1-.1,0,.08.08,0,0,1,0-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.6,14.38A7.52,7.52,0,0,0,53.52,12a7.57,7.57,0,0,1-.78,2.41.08.08,0,0,1-.14-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.8,12.13a7.66,7.66,0,0,0,.67-2.42,7.48,7.48,0,0,1-.53,2.47.07.07,0,0,1-.1.05.08.08,0,0,1-.05-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.23,10.51A7.71,7.71,0,0,0,43.62,8a7.94,7.94,0,0,1-.24,2.52.08.08,0,0,1-.15,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,10.63a7.75,7.75,0,0,0,.28-2.49,7.64,7.64,0,0,1-.13,2.52.08.08,0,1,1-.16,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.48,12.9a7.52,7.52,0,0,0-.2-2.5,7.86,7.86,0,0,1,.36,2.51s0,.07-.08.08a.09.09,0,0,1-.08-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.5,15.53a7.38,7.38,0,0,0-1.11-2.25,7.63,7.63,0,0,1,1.26,2.2.07.07,0,0,1,0,.1.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.36,16.26a7.76,7.76,0,0,0-1.43-2.06,7.75,7.75,0,0,1,1.57,2,.08.08,0,0,1,0,.11.08.08,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.17,16.82a7.59,7.59,0,0,0-1.5-2,7.81,7.81,0,0,1,1.64,1.93.08.08,0,0,1,0,.11.09.09,0,0,1-.11,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.36,17.25a7.45,7.45,0,0,0-1.57-2,7.57,7.57,0,0,1,1.7,1.87.08.08,0,0,1,0,.11s-.09,0-.11,0Z"/>
      </g>}
      {r_type_hair >= 9 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,56.66a1.18,1.18,0,0,0-.32-.44c-.07-.06-.16-.11-.21-.08s-.06.17-.06.27a.44.44,0,0,1,0-.34c.12-.12.28,0,.38,0a1.51,1.51,0,0,1,.57.42.2.2,0,0,1,0,.28.2.2,0,0,1-.29,0A.43.43,0,0,1,22,56.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.07,56.78a1.22,1.22,0,0,0-.31-.45c-.07-.07-.16-.12-.21-.09s-.06.16-.07.26a.41.41,0,0,1,0-.34c.12-.11.27,0,.37,0a1.51,1.51,0,0,1,.56.43.22.22,0,0,1,0,.29.21.21,0,0,1-.29,0A.11.11,0,0,1,23.07,56.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,55a1.14,1.14,0,0,0-.33-.44c-.07-.06-.16-.11-.2-.09a.38.38,0,0,0-.07.27c0-.1-.08-.2,0-.33s.27-.06.37,0a1.35,1.35,0,0,1,.57.42.2.2,0,0,1,0,.28.22.22,0,0,1-.29,0A.21.21,0,0,1,23,55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.85,54.57a1.14,1.14,0,0,0-.32-.45c-.07-.06-.16-.12-.2-.09s-.07.17-.07.27c0-.1-.08-.21,0-.34s.27-.05.37,0a1.37,1.37,0,0,1,.56.43.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0A.11.11,0,0,1,23.85,54.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.06,56.35a1.12,1.12,0,0,0-.34-.43c-.07-.06-.16-.11-.2-.08s-.06.17-.06.27c0-.1-.09-.2,0-.33s.28-.06.38,0a1.41,1.41,0,0,1,.58.41.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0A.25.25,0,0,1,21.06,56.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.05,55.16a1.11,1.11,0,0,0-.34-.42c-.07-.06-.17-.1-.21-.07s-.06.17-.05.27a.44.44,0,0,1,0-.34.34.34,0,0,1,.38,0,1.49,1.49,0,0,1,.59.39.21.21,0,1,1-.3.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.41,55.37a1.11,1.11,0,0,0-.3-.46c-.07-.07-.15-.12-.2-.1s-.07.16-.08.26a.38.38,0,0,1,0-.33c.13-.12.28,0,.38,0a1.36,1.36,0,0,1,.54.45.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.06,55.65a1.11,1.11,0,0,0-.33-.43c-.08-.06-.17-.11-.21-.08s-.06.16-.06.27a.37.37,0,0,1,0-.34c.12-.13.27-.06.37,0a1.41,1.41,0,0,1,.58.41.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0A.25.25,0,0,1,22.06,55.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.2,55.83a1.19,1.19,0,0,0-.35-.43c-.07,0-.17-.1-.21-.07s-.06.17-.05.27a.41.41,0,0,1,0-.33c.12-.13.28-.07.38-.05a1.49,1.49,0,0,1,.59.39.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.2,56.32a1.18,1.18,0,0,0-.3-.46c-.07-.06-.15-.12-.2-.1s-.08.16-.08.26a.38.38,0,0,1,0-.33c.13-.12.28,0,.38,0a1.53,1.53,0,0,1,.54.45.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.11.11,0,0,1,24.2,56.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.64,55a1.17,1.17,0,0,0-.36-.42c-.07,0-.17-.09-.21-.06s-.05.17,0,.27a.39.39,0,0,1,0-.34c.12-.13.27-.07.37,0a1.42,1.42,0,0,1,.61.38.21.21,0,0,1,0,.28.21.21,0,0,1-.29,0S19.65,55.05,19.64,55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.29,55.8a1,1,0,0,0-.31-.45c-.07-.06-.16-.11-.2-.09s-.07.17-.07.27c0-.1-.08-.21,0-.34s.27,0,.37,0a1.37,1.37,0,0,1,.56.43.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.1,54.32a1.34,1.34,0,0,0-.34-.43c-.08,0-.17-.1-.21-.07s-.06.17-.06.27c0-.1-.09-.2,0-.34s.28-.06.38,0a1.38,1.38,0,0,1,.58.4.2.2,0,0,1-.29.27A.1.1,0,0,1,22.1,54.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.37,53.75a1.25,1.25,0,0,0-.35-.42c-.08,0-.17-.1-.21-.07s-.06.17,0,.27a.43.43,0,0,1,0-.34c.12-.13.27-.06.37,0a1.43,1.43,0,0,1,.6.38.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0S21.38,53.77,21.37,53.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.27,54.1a1.25,1.25,0,0,0-.36-.41c-.08,0-.17-.1-.21-.07s-.05.17-.05.27a.41.41,0,0,1,0-.33c.11-.13.27-.07.37-.05a1.47,1.47,0,0,1,.6.37.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0A.16.16,0,0,1,20.27,54.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.87,53.35a1.18,1.18,0,0,0-.33-.43c-.07-.05-.16-.1-.21-.08s-.06.17-.06.27a.41.41,0,0,1,0-.33c.11-.13.27-.06.37,0a1.32,1.32,0,0,1,.58.4.19.19,0,0,1,0,.28.21.21,0,0,1-.29,0A.43.43,0,0,1,22.87,53.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.5,53.24a1,1,0,0,0-.36-.4c-.08-.06-.17-.1-.22-.07s0,.18,0,.28a.39.39,0,0,1,0-.34c.1-.13.26-.08.37-.06a1.54,1.54,0,0,1,.61.36.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0A.15.15,0,0,1,19.5,53.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.73,52.62a1.15,1.15,0,0,0-.36-.41c-.07-.05-.16-.1-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.34s.27-.07.38,0a1.42,1.42,0,0,1,.6.37.19.19,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.54,52.06a1.08,1.08,0,0,0-.37-.4c-.08,0-.17-.09-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.33a.31.31,0,0,1,.37-.06,1.41,1.41,0,0,1,.61.35.2.2,0,0,1-.27.29A.15.15,0,0,1,19.54,52.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.87,52a1.14,1.14,0,0,0-.35-.42c-.08,0-.17-.09-.21-.06s0,.17,0,.27a.43.43,0,0,1,0-.34c.11-.13.27-.07.37,0a1.31,1.31,0,0,1,.6.37.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.15.15,0,0,1,21.87,52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.42,51.14a1.29,1.29,0,0,0-.37-.41c-.08,0-.17-.09-.21-.06s-.05.17,0,.27a.4.4,0,0,1,0-.33c.11-.14.27-.08.37-.06a1.34,1.34,0,0,1,.61.36.2.2,0,0,1,0,.28.22.22,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.29,51.52a1.07,1.07,0,0,0-.34-.43c-.07-.05-.16-.1-.21-.07s-.06.17,0,.27a.4.4,0,0,1,0-.34c.11-.13.27-.06.37,0a1.49,1.49,0,0,1,.59.39.21.21,0,0,1,0,.29.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.28,50.77a1.15,1.15,0,0,0-.36-.41c-.07-.06-.16-.1-.21-.07a.38.38,0,0,0,0,.27c0-.1-.1-.2,0-.33a.32.32,0,0,1,.37,0,1.42,1.42,0,0,1,.6.37.21.21,0,0,1,0,.29.23.23,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,49.71a1.08,1.08,0,0,0-.37-.4c-.07-.05-.17-.1-.21-.06s-.05.17,0,.27c0-.1-.11-.2-.05-.33a.33.33,0,0,1,.37-.07,1.47,1.47,0,0,1,.61.36.19.19,0,0,1,0,.28.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.3,49.78a1.16,1.16,0,0,0-.35-.42c-.08-.06-.17-.1-.21-.07s-.06.17,0,.27c0-.1-.1-.2,0-.34s.27-.07.37,0a1.39,1.39,0,0,1,.6.38.19.19,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.51,48.84a1.18,1.18,0,0,0-.37-.41c-.07,0-.16-.1-.21-.07a.41.41,0,0,0,0,.28c0-.1-.1-.2,0-.34s.26-.07.37,0a1.4,1.4,0,0,1,.6.36.22.22,0,0,1,0,.29.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.72,47.75a1.17,1.17,0,0,0-.37-.4c-.08-.05-.17-.1-.22-.06s0,.17,0,.27c0-.1-.11-.19-.05-.33a.33.33,0,0,1,.37-.07,1.55,1.55,0,0,1,.62.35.22.22,0,0,1,0,.29.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.33,47.25a1.07,1.07,0,0,0-.36-.41c-.07-.06-.17-.1-.21-.07a.38.38,0,0,0,0,.27.4.4,0,0,1,0-.33.32.32,0,0,1,.37-.05,1.37,1.37,0,0,1,.61.36.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,46.27a1.11,1.11,0,0,0-.38-.4c-.07,0-.17-.1-.21-.06s0,.17,0,.27c0-.1-.11-.19,0-.33a.33.33,0,0,1,.37-.07,1.44,1.44,0,0,1,.62.35.21.21,0,0,1-.28.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.32,45.81A1.29,1.29,0,0,0,23,45.4c-.08,0-.17-.09-.21-.06s0,.17,0,.27a.4.4,0,0,1,0-.33c.11-.13.27-.08.37-.06a1.54,1.54,0,0,1,.61.36.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0S23.33,45.83,23.32,45.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.39,44.79a1.16,1.16,0,0,0-.37-.4c-.08,0-.17-.09-.22-.06s0,.18,0,.28c0-.1-.1-.2,0-.34s.26-.08.36-.06a1.38,1.38,0,0,1,.62.35.2.2,0,1,1-.27.29A.15.15,0,0,1,22.39,44.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,45.45a1.14,1.14,0,0,0-.35-.42c-.07-.05-.17-.1-.21-.07s-.06.17-.05.27a.43.43,0,0,1,0-.34c.12-.13.28-.06.38,0a1.49,1.49,0,0,1,.59.39.19.19,0,0,1,0,.28.21.21,0,0,1-.29,0A.25.25,0,0,1,25,45.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.76,44.45A1.17,1.17,0,0,0,24.4,44c-.07-.05-.17-.1-.21-.07a.38.38,0,0,0,0,.27.37.37,0,0,1,0-.33c.12-.13.27-.07.38,0a1.45,1.45,0,0,1,.6.38.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.74,43.66a1.29,1.29,0,0,0-.37-.41c-.07,0-.17-.09-.21-.06a.38.38,0,0,0,0,.27.4.4,0,0,1,0-.33c.11-.13.27-.08.37-.06a1.47,1.47,0,0,1,.61.36.22.22,0,0,1,0,.29.21.21,0,0,1-.29,0S23.75,43.68,23.74,43.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.1,42.76a1.08,1.08,0,0,0-.37-.4c-.08,0-.17-.1-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.33a.33.33,0,0,1,.37-.07,1.54,1.54,0,0,1,.61.36.2.2,0,1,1-.27.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.75,43.37a1.34,1.34,0,0,0-.34-.43c-.08,0-.17-.1-.21-.07s-.06.17-.05.27c0-.1-.1-.2,0-.34s.28-.06.38,0a1.3,1.3,0,0,1,.58.39.2.2,0,0,1-.29.27S25.76,43.39,25.75,43.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.47,41.8a1.08,1.08,0,0,0-.37-.4c-.07,0-.17-.1-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.34a.32.32,0,0,1,.37-.05,1.35,1.35,0,0,1,.61.35.21.21,0,0,1,0,.29.22.22,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.1,42.37a1.14,1.14,0,0,0-.35-.42c-.07-.05-.17-.1-.21-.07s-.05.17-.05.27a.41.41,0,0,1,0-.33c.12-.13.28-.07.38,0a1.57,1.57,0,0,1,.59.38.21.21,0,1,1-.29.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.68,41.28a1.22,1.22,0,0,0-.35-.41c-.08-.06-.17-.1-.21-.07s-.06.17-.05.27a.41.41,0,0,1,0-.33.32.32,0,0,1,.37-.05,1.47,1.47,0,0,1,.6.37.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0A.18.18,0,0,1,24.68,41.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.7,42a1.14,1.14,0,0,0-.33-.44c-.07-.06-.16-.11-.2-.08s-.07.16-.06.26a.37.37,0,0,1,0-.33c.12-.13.28-.06.38,0a1.43,1.43,0,0,1,.57.42.2.2,0,1,1-.3.26A.21.21,0,0,1,26.7,42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.45,41a1.11,1.11,0,0,0-.33-.43c-.07-.06-.16-.11-.21-.08s-.06.17-.06.27a.44.44,0,0,1,0-.34.34.34,0,0,1,.37,0,1.44,1.44,0,0,1,.58.41.2.2,0,0,1,0,.29.2.2,0,0,1-.28,0A.25.25,0,0,1,26.45,41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.44,39.79a1.15,1.15,0,0,0-.36-.41c-.07-.05-.16-.1-.21-.07a.38.38,0,0,0,0,.27.37.37,0,0,1,0-.33c.11-.13.27-.07.37,0a1.53,1.53,0,0,1,.6.37.21.21,0,1,1-.29.29A.43.43,0,0,1,24.44,39.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.84,39.85a1.34,1.34,0,0,0-.34-.43c-.07-.05-.16-.1-.21-.07s-.06.17-.05.27a.4.4,0,0,1,0-.34c.11-.13.27-.06.37,0a1.39,1.39,0,0,1,.59.39.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.25.25,0,0,1,25.84,39.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.19,39.6a1.15,1.15,0,0,0-.31-.44c-.07-.06-.16-.12-.21-.09s-.07.17-.07.27c0-.1-.08-.21,0-.34s.28,0,.38,0a1.48,1.48,0,0,1,.56.42.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.16.16,0,0,1,27.19,39.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,38.43a1.25,1.25,0,0,0-.35-.42c-.08-.06-.17-.1-.21-.07s-.06.17-.05.27a.43.43,0,0,1,0-.34c.12-.13.27-.06.37,0a1.36,1.36,0,0,1,.6.39.2.2,0,0,1-.29.28A.25.25,0,0,1,25,38.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.48,38.29a1.34,1.34,0,0,0-.33-.44c-.07-.05-.16-.11-.21-.08s-.06.17-.06.27a.4.4,0,0,1,0-.34c.12-.12.27-.05.37,0a1.44,1.44,0,0,1,.58.41.21.21,0,1,1-.31.27A.21.21,0,0,1,26.48,38.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.34,37a1.09,1.09,0,0,0-.35-.43c-.07-.06-.16-.11-.2-.08s-.06.17-.06.27c0-.1-.09-.2,0-.33a.32.32,0,0,1,.37,0,1.51,1.51,0,0,1,.59.4.2.2,0,1,1-.29.27S25.34,37.06,25.34,37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.26,37.19a1,1,0,0,0-.31-.45c-.07-.06-.15-.12-.2-.09s-.07.16-.07.26c0-.1-.08-.2,0-.33s.28,0,.37,0a1.37,1.37,0,0,1,.56.43.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.25.25,0,0,1,27.26,37.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.26,36.12a1.14,1.14,0,0,0-.32-.45c-.07-.05-.16-.11-.21-.08s-.06.17-.06.27a.4.4,0,0,1,0-.34c.12-.12.28-.05.37,0a1.54,1.54,0,0,1,.58.42.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.85,36a1.15,1.15,0,0,0-.29-.46c-.07-.06-.16-.12-.2-.1s-.08.17-.09.27a.45.45,0,0,1,0-.34c.13-.11.28,0,.37,0a1.46,1.46,0,0,1,.55.45.2.2,0,0,1-.05.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.56,34.65a1.14,1.14,0,0,0-.33-.44c-.07-.06-.16-.11-.21-.08s-.06.17-.06.27a.44.44,0,0,1,0-.34.32.32,0,0,1,.37,0,1.35,1.35,0,0,1,.58.41.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.84,34.77a1.18,1.18,0,0,0-.3-.46c-.07-.06-.16-.11-.21-.09a.41.41,0,0,0-.07.27.41.41,0,0,1,0-.34c.12-.12.27,0,.37,0a1.44,1.44,0,0,1,.56.44.21.21,0,0,1,0,.28.2.2,0,0,1-.28,0A.25.25,0,0,1,26.84,34.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.2,34.61a1.13,1.13,0,0,0-.27-.47c-.07-.07-.15-.13-.2-.11s-.08.16-.09.26a.36.36,0,0,1,0-.33c.13-.11.27,0,.37,0a1.38,1.38,0,0,1,.53.47.2.2,0,0,1-.06.28.21.21,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.68,33.37a1.18,1.18,0,0,0-.3-.46c-.07-.06-.15-.12-.2-.09s-.08.16-.08.26a.38.38,0,0,1,0-.33.31.31,0,0,1,.37,0,1.28,1.28,0,0,1,.55.44.21.21,0,0,1,0,.29.22.22,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.09,33.32a1.23,1.23,0,0,0-.27-.48c-.07-.06-.15-.12-.2-.1s-.08.16-.09.26c0-.11-.06-.22,0-.34s.28,0,.37,0a1.4,1.4,0,0,1,.52.48.2.2,0,0,1-.33.23A.21.21,0,0,1,28.09,33.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.28,32.17a1.3,1.3,0,0,0-.3-.46c-.07-.06-.16-.12-.2-.1s-.08.17-.08.27a.41.41,0,0,1,0-.34.33.33,0,0,1,.37,0,1.46,1.46,0,0,1,.55.45.19.19,0,0,1,0,.28.2.2,0,0,1-.28,0A.21.21,0,0,1,26.28,32.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.88,32.52a1.2,1.2,0,0,0-.25-.49c-.06-.07-.14-.14-.19-.12s-.09.16-.11.26c0-.11,0-.22.05-.34s.28,0,.38.05a1.47,1.47,0,0,1,.49.5.21.21,0,0,1-.07.28.2.2,0,0,1-.28-.07A.19.19,0,0,1,28.88,32.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.83,31.81a1.2,1.2,0,0,0-.26-.48c-.06-.06-.14-.13-.19-.11a.39.39,0,0,0-.1.26c0-.1-.06-.21,0-.33s.28,0,.37,0a1.37,1.37,0,0,1,.51.48.2.2,0,0,1-.34.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.86,30.83a1.26,1.26,0,0,0-.28-.48c-.06-.06-.14-.12-.19-.1s-.09.16-.09.26a.41.41,0,0,1,0-.34c.14-.11.28,0,.38,0a1.47,1.47,0,0,1,.52.48.2.2,0,1,1-.33.23A.11.11,0,0,1,26.86,30.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.94,30.62a1.2,1.2,0,0,0-.25-.49c-.06-.07-.14-.14-.19-.12s-.09.16-.11.26c0-.11,0-.22.05-.34s.28,0,.37.05a1.49,1.49,0,0,1,.5.5.2.2,0,0,1-.07.28.21.21,0,0,1-.28-.07A.19.19,0,0,1,27.94,30.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.06,30.9a1.17,1.17,0,0,0-.22-.5c-.06-.07-.13-.14-.18-.13s-.1.15-.12.25c0-.1,0-.22.06-.33s.28,0,.37.06a1.43,1.43,0,0,1,.47.53.2.2,0,0,1-.08.27.2.2,0,0,1-.28-.08A.19.19,0,0,1,29.06,30.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.24,29.7a1,1,0,0,0-.25-.49c-.06-.07-.14-.14-.19-.12a.39.39,0,0,0-.1.26.39.39,0,0,1,0-.33c.14-.11.28,0,.38,0a1.53,1.53,0,0,1,.5.51.21.21,0,0,1-.07.27.21.21,0,0,1-.28-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.16,31.24a1.09,1.09,0,0,0-.2-.51c-.05-.07-.12-.15-.17-.13s-.11.14-.13.24c0-.11,0-.22.08-.33a.33.33,0,0,1,.36.08,1.38,1.38,0,0,1,.44.56.2.2,0,0,1-.1.27.2.2,0,0,1-.26-.1A.19.19,0,0,1,30.16,31.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,27.72a1.21,1.21,0,0,0-.2-.51c-.06-.07-.13-.15-.18-.13s-.1.14-.13.24a.39.39,0,0,1,.08-.33c.15-.09.28,0,.37.08a1.43,1.43,0,0,1,.44.55.2.2,0,0,1-.09.27.2.2,0,0,1-.27-.1A.19.19,0,0,1,28,27.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.33,28.49a1.25,1.25,0,0,0-.23-.5c-.06-.07-.14-.14-.19-.12s-.09.15-.11.25a.37.37,0,0,1,.06-.33c.14-.1.28,0,.37.05a1.51,1.51,0,0,1,.48.52.21.21,0,1,1-.36.2A.19.19,0,0,1,27.33,28.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.33,28.85a1.07,1.07,0,0,0-.21-.51c-.05-.07-.13-.15-.18-.13s-.1.15-.12.24a.33.33,0,0,1,.07-.32c.14-.09.28,0,.37.07a1.43,1.43,0,0,1,.45.54.2.2,0,0,1-.36.18A.19.19,0,0,1,28.33,28.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.19,29.48A1.25,1.25,0,0,0,29,29c0-.07-.12-.14-.17-.13s-.11.14-.14.24c0-.1,0-.22.09-.33s.27,0,.36.08a1.47,1.47,0,0,1,.44.56.2.2,0,0,1-.09.27.21.21,0,0,1-.27-.1A.19.19,0,0,1,29.19,29.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.11,29.75a1.12,1.12,0,0,0-.18-.52c0-.08-.11-.15-.16-.14s-.12.14-.15.23a.41.41,0,0,1,.1-.32c.15-.08.28,0,.36.1a1.44,1.44,0,0,1,.42.57.22.22,0,0,1-.11.27.21.21,0,0,1-.27-.11A.22.22,0,0,1,30.11,29.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.87,30.38a1.2,1.2,0,0,0-.16-.53c-.05-.08-.11-.16-.17-.15s-.11.14-.15.23a.42.42,0,0,1,.11-.32c.16-.07.28.05.36.11a1.41,1.41,0,0,1,.4.59.22.22,0,0,1-.12.26.2.2,0,0,1-.26-.12A.17.17,0,0,1,30.87,30.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.67,28.39a1.16,1.16,0,0,0-.16-.52c0-.08-.11-.16-.16-.15s-.12.14-.15.23a.41.41,0,0,1,.1-.32.34.34,0,0,1,.36.1,1.47,1.47,0,0,1,.4.59.2.2,0,0,1-.11.26.2.2,0,0,1-.26-.12A.19.19,0,0,1,29.67,28.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.27,27.28a1.33,1.33,0,0,0-.15-.53c-.05-.08-.12-.16-.17-.15s-.12.14-.15.23c0-.1,0-.22.11-.32s.27,0,.36.11a1.52,1.52,0,0,1,.39.59.2.2,0,0,1-.12.26.2.2,0,0,1-.26-.12A.17.17,0,0,1,29.27,27.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,28.57A1.09,1.09,0,0,0,30.9,28c0-.08-.1-.16-.16-.16s-.12.13-.16.22a.38.38,0,0,1,.13-.31c.16-.06.27.07.35.14a1.46,1.46,0,0,1,.35.61.2.2,0,0,1-.14.25.2.2,0,0,1-.25-.14A.17.17,0,0,1,31,28.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.18,27.41a1.18,1.18,0,0,0-.09-.54c0-.09-.09-.17-.15-.17s-.13.12-.17.21c0-.1,0-.22.14-.3s.27.08.34.15a1.46,1.46,0,0,1,.33.63.2.2,0,0,1-.15.24.21.21,0,0,1-.25-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.65,26.31a1.18,1.18,0,0,0-.09-.54c0-.08-.09-.17-.14-.17s-.13.12-.18.21c0-.1,0-.22.15-.3s.27.08.34.15a1.49,1.49,0,0,1,.32.64.22.22,0,0,1-.15.24.21.21,0,0,1-.25-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.12,25.51A1.05,1.05,0,0,0,30,25c0-.09-.09-.17-.15-.17s-.13.12-.18.21c0-.1,0-.22.15-.3s.27.08.34.15a1.45,1.45,0,0,1,.32.63.21.21,0,0,1-.15.25.21.21,0,0,1-.24-.16A.17.17,0,0,1,30.12,25.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.77,24.69a1.18,1.18,0,0,0-.05-.55c0-.08-.07-.18-.13-.18s-.14.11-.19.2c0-.1,0-.22.17-.29s.26.1.33.18a1.35,1.35,0,0,1,.26.65.2.2,0,1,1-.39.06A.09.09,0,0,1,30.77,24.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.8,25.58a1,1,0,0,0,0-.54c0-.09-.07-.18-.13-.18s-.14.1-.19.19c0-.1,0-.22.17-.29s.26.1.33.18a1.55,1.55,0,0,1,.26.66.22.22,0,0,1-.18.23.19.19,0,0,1-.22-.17A.22.22,0,0,1,31.8,25.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.69,26.38a1.11,1.11,0,0,0,0-.54c0-.09-.08-.18-.13-.18s-.14.1-.2.18c0-.09,0-.21.18-.28s.26.1.32.19a1.38,1.38,0,0,1,.25.66.19.19,0,0,1-.17.22.2.2,0,0,1-.23-.17A.25.25,0,0,1,32.69,26.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.62,23.61a1.14,1.14,0,0,0,0-.55c0-.09-.06-.18-.11-.19s-.15.1-.21.18c0-.1.05-.21.19-.28s.25.12.31.21a1.44,1.44,0,0,1,.22.67.2.2,0,0,1-.19.22.21.21,0,0,1-.22-.19S31.62,23.63,31.62,23.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.3,24.52a1.14,1.14,0,0,0,0-.55c0-.09-.07-.18-.12-.19s-.15.1-.21.18c0-.09.06-.21.19-.27s.26.11.32.2a1.44,1.44,0,0,1,.22.67.21.21,0,1,1-.41,0S32.3,24.54,32.3,24.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.27,25a1.13,1.13,0,0,0,0-.55c0-.09-.06-.18-.11-.19s-.15.1-.21.18c0-.1.06-.21.19-.27s.26.12.32.2a1.5,1.5,0,0,1,.2.68.2.2,0,1,1-.4,0A.19.19,0,0,1,33.27,25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.55,23a1.2,1.2,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.16.09-.22.17c0-.1.07-.22.21-.27s.24.14.3.22a1.56,1.56,0,0,1,.17.69.22.22,0,0,1-.2.21.2.2,0,0,1-.2-.2A.19.19,0,0,1,32.55,23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.47,23.55a1.12,1.12,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.22.17c0-.09.07-.21.21-.26s.24.13.3.22a1.43,1.43,0,0,1,.16.69.2.2,0,0,1-.4,0A.17.17,0,0,1,33.47,23.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.11,21.83a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.09-.2s-.16.07-.23.15c0-.09.08-.21.22-.25s.24.15.29.24a1.42,1.42,0,0,1,.13.69.21.21,0,0,1-.21.2.2.2,0,0,1-.19-.22A.19.19,0,0,1,33.11,21.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.94,22.5A1.06,1.06,0,0,0,34,22c0-.1,0-.19-.09-.21s-.16.08-.23.16c.05-.09.08-.21.22-.25s.24.15.29.24a1.4,1.4,0,0,1,.13.69.2.2,0,0,1-.21.19.19.19,0,0,1-.19-.21A.17.17,0,0,1,33.94,22.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.55,24.61a1.07,1.07,0,0,0,0-.54c0-.09-.05-.19-.1-.2s-.16.08-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,1,1-.4,0A.19.19,0,0,1,34.55,24.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.69,23.6a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.1-.2s-.16.08-.22.15c0-.09.07-.21.22-.25s.23.14.29.24a1.52,1.52,0,0,1,.14.69.21.21,0,0,1-.41,0A.19.19,0,0,1,34.69,23.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.51,26.62a1.12,1.12,0,0,0,0-.55c0-.09-.05-.19-.1-.19s-.16.09-.22.16c0-.09.06-.21.21-.26s.24.13.3.22a1.38,1.38,0,0,1,.18.68.2.2,0,0,1-.2.21.2.2,0,0,1-.2-.2A.17.17,0,0,1,35.51,26.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.86,21.63a1.07,1.07,0,0,0,.1-.54c0-.1,0-.2-.08-.21s-.16.07-.24.14c.06-.09.1-.2.24-.24s.23.16.27.26a1.35,1.35,0,0,1,.09.7.2.2,0,1,1-.4,0A.14.14,0,0,1,34.86,21.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,21.58a1,1,0,0,0,.11-.54c0-.09,0-.19-.07-.21s-.17.07-.24.14c.05-.09.09-.2.24-.23s.22.17.27.26a1.52,1.52,0,0,1,.07.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,36,21.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.6,22.68a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c.05-.09.09-.2.23-.24s.23.16.28.25a1.49,1.49,0,0,1,.1.7.2.2,0,0,1-.22.19.21.21,0,0,1-.19-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.49,23.1a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.16.06-.23.13.09-.2.23-.23.23.16.28.25a1.47,1.47,0,0,1,.09.7.2.2,0,0,1-.22.18.2.2,0,0,1-.18-.22A.19.19,0,0,1,36.49,23.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.61,26.26a1.12,1.12,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.22-.26s.24.15.29.24a1.4,1.4,0,0,1,.15.69.2.2,0,1,1-.4,0A.17.17,0,0,1,36.61,26.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.22,24.22a1.21,1.21,0,0,0,.08-.55c0-.09,0-.19-.09-.2s-.17.08-.24.15c.06-.09.09-.2.23-.25s.24.16.28.25a1.46,1.46,0,0,1,.13.7.21.21,0,0,1-.41,0A.19.19,0,0,1,36.22,24.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.8,25.19a1.09,1.09,0,0,0,.07-.55c0-.09,0-.19-.09-.2s-.16.08-.23.15c.05-.09.08-.2.23-.25s.23.15.28.24a1.46,1.46,0,0,1,.13.7.2.2,0,0,1-.21.19.19.19,0,0,1-.19-.21S36.8,25.21,36.8,25.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.05,22a1.19,1.19,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13.1-.2.24-.23.23.17.27.27a1.52,1.52,0,0,1,.06.71.21.21,0,0,1-.23.17A.2.2,0,0,1,37,22,.14.14,0,0,1,37.05,22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.44,23.78a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.05-.09.09-.2.24-.24s.22.17.27.26a1.46,1.46,0,0,1,.09.7.2.2,0,1,1-.4,0A.19.19,0,0,1,37.44,23.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.9,24.89a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.16.27.25a1.46,1.46,0,0,1,.11.7.21.21,0,0,1-.41,0S37.89,24.91,37.9,24.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38,22.66a1.19,1.19,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.25.13.1-.2.25-.23.22.17.26.27a1.52,1.52,0,0,1,.07.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.14.14,0,0,1,38,22.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.07,26.12a1.06,1.06,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.16.07-.23.15c.05-.09.08-.21.23-.25s.23.15.28.25a1.4,1.4,0,0,1,.12.69.21.21,0,0,1-.22.19.19.19,0,0,1-.18-.21A.22.22,0,0,1,38.07,26.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.71,23.81a1,1,0,0,0,.11-.54c0-.09,0-.19-.07-.21s-.17.07-.24.14a.41.41,0,0,1,.24-.24c.17,0,.22.17.27.27a1.47,1.47,0,0,1,.07.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,38.71,23.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.09,25.44a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14.09-.2.24-.23.23.16.27.25a1.36,1.36,0,0,1,.09.7.2.2,0,0,1-.22.18.2.2,0,0,1-.18-.22A.19.19,0,0,1,39.09,25.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.71,24.27a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.08-.21s-.16.07-.24.13.1-.19.25-.23.22.17.26.27a1.49,1.49,0,0,1,.07.7.21.21,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,39.71,24.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.29,25.22a1.25,1.25,0,0,0,.11-.53c0-.09,0-.2-.08-.21s-.16.06-.24.13.1-.2.25-.23.22.17.26.26a1.4,1.4,0,0,1,.08.71.2.2,0,0,1-.23.17.21.21,0,0,1-.15-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.22,26.31a1,1,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.16.07-.24.13.1-.2.24-.23.23.16.27.26a1.35,1.35,0,0,1,.09.7.2.2,0,1,1-.4,0A.19.19,0,0,1,40.22,26.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.67,23.38a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12a.37.37,0,0,1,.25-.22c.17,0,.21.17.25.27a1.4,1.4,0,0,1,.06.71.2.2,0,0,1-.4-.07A.19.19,0,0,1,40.67,23.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.39,24.17a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13c0-.08.1-.2.24-.23s.22.18.26.27a1.4,1.4,0,0,1,.06.71.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.1,23.11a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13.1-.2.25-.23.22.18.26.27a1.52,1.52,0,0,1,.06.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.14.14,0,0,1,42.1,23.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.93,25.09a1.27,1.27,0,0,0,.11-.54c0-.09,0-.19-.07-.21s-.17.07-.24.13.1-.19.24-.22.22.17.27.26a1.52,1.52,0,0,1,.06.71.2.2,0,0,1-.4-.06A.11.11,0,0,1,41.93,25.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.94,26.3a1.33,1.33,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.17.06-.24.13.1-.2.25-.23.22.17.26.27a1.46,1.46,0,0,1,.07.7.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42,27.34a1.16,1.16,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.09-.2.24-.23s.23.16.27.26a1.35,1.35,0,0,1,.08.7.2.2,0,0,1-.22.18.22.22,0,0,1-.18-.23A.19.19,0,0,1,42,27.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.9,24.37a1.33,1.33,0,0,0,.11-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13.1-.2.24-.23.22.17.27.27a1.48,1.48,0,0,1,.06.7.22.22,0,0,1-.23.18.21.21,0,0,1-.17-.23A.09.09,0,0,1,42.9,24.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.73,23.57a1.25,1.25,0,0,0,.11-.53c0-.1,0-.2-.07-.21s-.17.06-.24.13c0-.09.09-.2.24-.23s.22.17.26.26a1.3,1.3,0,0,1,.07.71.19.19,0,0,1-.22.17.21.21,0,0,1-.18-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.42,25.48a1,1,0,0,0,.11-.54c0-.09,0-.19-.07-.21s-.17.07-.24.14c.06-.09.1-.2.24-.23s.23.17.27.26a1.52,1.52,0,0,1,.07.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,43.42,25.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.16,24.67a1,1,0,0,0,.11-.54c0-.09,0-.19-.07-.21S44,24,44,24.06c0-.09.09-.2.24-.24s.22.17.27.27a1.47,1.47,0,0,1,.07.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,44.16,24.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.38,26.72a1,1,0,0,0,.11-.54c0-.09,0-.19-.07-.2s-.17.06-.24.13c0-.09.09-.2.24-.23s.22.17.27.26a1.52,1.52,0,0,1,.07.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,43.38,26.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43,28.27a1.12,1.12,0,0,0,.1-.53c0-.09,0-.2-.08-.21s-.16.07-.24.13.1-.2.24-.23.23.17.27.26a1.36,1.36,0,0,1,.08.7.2.2,0,0,1-.22.18.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.89,25.53A1.18,1.18,0,0,0,45,25c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.25-.24s.22.17.26.27a1.35,1.35,0,0,1,.08.7.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22A.11.11,0,0,1,44.89,25.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.82,26.86a1.07,1.07,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.25-.24s.22.17.26.27a1.35,1.35,0,0,1,.08.7.2.2,0,0,1-.22.18.22.22,0,0,1-.18-.23A.19.19,0,0,1,44.82,26.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.33,28.19a1.27,1.27,0,0,0,.11-.54c0-.09,0-.19-.08-.2s-.17.06-.24.13.1-.2.24-.23.23.17.27.26a1.4,1.4,0,0,1,.08.71.21.21,0,0,1-.23.17.19.19,0,0,1-.17-.23A.19.19,0,0,1,44.33,28.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.19,26a1.33,1.33,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.05-.09.09-.2.24-.24s.22.17.27.26a1.36,1.36,0,0,1,.08.7.2.2,0,0,1-.22.18.2.2,0,0,1-.18-.22S46.17,26,46.19,26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.42,29.76a1.07,1.07,0,0,0,.11-.54c0-.09,0-.19-.07-.21s-.17.07-.24.13.09-.19.24-.23.22.17.27.27a1.46,1.46,0,0,1,.07.7.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22A.14.14,0,0,1,44.42,29.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.69,28.71a1,1,0,0,0,.11-.54c0-.09,0-.19-.08-.2s-.16.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.26a1.36,1.36,0,0,1,.08.7.2.2,0,1,1-.4,0A.19.19,0,0,1,45.69,28.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47,27.57a1.33,1.33,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.05-.09.09-.2.24-.24s.22.17.27.26a1.52,1.52,0,0,1,.09.71.21.21,0,0,1-.41-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.79,28.93a1.07,1.07,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.16.07-.24.13.1-.2.25-.23.22.17.26.26a1.4,1.4,0,0,1,.08.71.19.19,0,0,1-.23.17.19.19,0,0,1-.17-.22A.14.14,0,0,1,46.79,28.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.13,27.22a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.09-.21.24-.24s.23.16.27.26a1.46,1.46,0,0,1,.09.7.2.2,0,0,1-.4,0A.19.19,0,0,1,48.13,27.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.06,30.85a1.12,1.12,0,0,0,.12-.53c0-.09,0-.19-.07-.21s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.18.26.27a1.52,1.52,0,0,1,0,.71.21.21,0,0,1-.23.17.21.21,0,0,1-.17-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.74,30.31a1.12,1.12,0,0,0,.12-.53c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.09.1-.2.25-.23s.22.17.26.27a1.36,1.36,0,0,1,.06.7.2.2,0,0,1-.23.17.19.19,0,0,1-.17-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.31,28.44a1.12,1.12,0,0,0,.11-.53c0-.09,0-.19-.08-.21s-.17.07-.24.13.1-.2.24-.23.23.17.27.26a1.4,1.4,0,0,1,.08.71.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22A.24.24,0,0,1,48.31,28.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.72,29.82a1.13,1.13,0,0,0,.12-.54c0-.09,0-.19-.08-.21s-.16.07-.24.13.1-.19.25-.22.22.17.26.26a1.52,1.52,0,0,1,.07.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,47.72,29.82Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.53,28.22a1.33,1.33,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.17.27.27a1.46,1.46,0,0,1,.08.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,49.53,28.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.14,29.6a1.12,1.12,0,0,0,.12-.53c0-.09,0-.2-.08-.21s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.27a1.35,1.35,0,0,1,.06.7.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.27,30.93a1.22,1.22,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.09.11-.2.25-.23s.22.18.26.27a1.4,1.4,0,0,1,0,.71.2.2,0,1,1-.4-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.23,29.48a1.12,1.12,0,0,0,.12-.53c0-.1,0-.2-.07-.21s-.17.06-.25.13c.06-.09.1-.2.25-.23s.22.17.26.27a1.46,1.46,0,0,1,.07.7.2.2,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,50.23,29.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.82,32.13A1.26,1.26,0,0,0,48,31.6c0-.09,0-.19-.07-.21s-.17.05-.24.12c.06-.08.1-.2.25-.22s.22.18.25.28a1.52,1.52,0,0,1,0,.71.2.2,0,0,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,30.94a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.36,1.36,0,0,1,0,.7.2.2,0,0,1-.23.17A.21.21,0,0,1,50,31,.19.19,0,0,1,50,30.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.56,32a1.13,1.13,0,0,0,.14-.53c0-.1,0-.2-.06-.22s-.17.06-.25.12.11-.19.26-.21.21.18.25.27a1.41,1.41,0,0,1,0,.71.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.13,31.38a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.05-.24.12c.06-.09.1-.2.25-.22s.21.18.25.28a1.48,1.48,0,0,1,0,.7.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.61,33.28a1.06,1.06,0,0,0,.16-.52c0-.09,0-.2-.06-.22s-.17.05-.25.12.11-.19.26-.21.21.18.24.28a1.41,1.41,0,0,1,0,.71.19.19,0,0,1-.24.15.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.22,32.54a1.18,1.18,0,0,0,.15-.52c0-.09,0-.2-.06-.22s-.17.06-.25.12.11-.19.26-.21.21.18.25.28a1.41,1.41,0,0,1,0,.71.19.19,0,0,1-.23.15.21.21,0,0,1-.16-.24A.21.21,0,0,1,51.22,32.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.61,33.47a1.1,1.1,0,0,0,.16-.53c0-.09,0-.19-.05-.21s-.17.05-.25.11.11-.19.26-.21.21.19.24.29a1.42,1.42,0,0,1,0,.71.2.2,0,0,1-.24.15.2.2,0,0,1-.15-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.59,33.86a1.23,1.23,0,0,0,.17-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.12-.19.27-.21.2.19.23.29A1.54,1.54,0,0,1,50,34a.21.21,0,0,1-.25.15.2.2,0,0,1-.15-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.26,34.55a1.06,1.06,0,0,0,.16-.52c0-.09,0-.19-.05-.21s-.17,0-.25.1.12-.19.27-.2.2.19.23.29a1.42,1.42,0,0,1,0,.71.21.21,0,0,1-.25.15.21.21,0,0,1-.14-.25A.11.11,0,0,1,48.26,34.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.89,34.66a1.11,1.11,0,0,0,.17-.52c0-.09,0-.2,0-.22a.37.37,0,0,0-.26.1c.07-.07.12-.18.27-.2s.2.2.23.3a1.43,1.43,0,0,1,0,.71A.21.21,0,0,1,51,35a.19.19,0,0,1-.14-.24A.15.15,0,0,1,50.89,34.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.53,35.34a1.15,1.15,0,0,0,.18-.51c0-.09,0-.2-.05-.22s-.17,0-.26.1a.43.43,0,0,1,.28-.2c.17,0,.2.2.23.3a1.44,1.44,0,0,1,0,.71.19.19,0,0,1-.24.14.21.21,0,0,1-.15-.25A.25.25,0,0,1,49.53,35.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.28,36a1.29,1.29,0,0,0,.19-.51c0-.09,0-.19,0-.22a.37.37,0,0,0-.26.1.43.43,0,0,1,.28-.2c.17,0,.19.2.22.3a1.56,1.56,0,0,1,0,.71.2.2,0,0,1-.39-.11A.21.21,0,0,1,48.28,36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.84,35.88a1.29,1.29,0,0,0,.19-.51c0-.09,0-.19-.05-.22s-.17,0-.25.1a.35.35,0,0,1,.27-.19c.17,0,.2.2.22.3a1.44,1.44,0,0,1,0,.71.2.2,0,0,1-.25.13A.2.2,0,0,1,50.8,36,.25.25,0,0,1,50.84,35.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.13,36.86a1.3,1.3,0,0,0,.2-.51c0-.09,0-.19,0-.22s-.17,0-.25.09.12-.18.27-.18.2.2.22.3a1.45,1.45,0,0,1,0,.71.21.21,0,0,1-.25.13.2.2,0,0,1-.14-.25A.25.25,0,0,1,49.13,36.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.27,37.06a1.3,1.3,0,0,0,.2-.51c0-.09,0-.19,0-.22s-.18,0-.26.09.13-.18.28-.19.19.21.22.31a1.47,1.47,0,0,1-.06.71.2.2,0,0,1-.25.13.2.2,0,0,1-.13-.25A.16.16,0,0,1,50.27,37.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.53,38.17a1.08,1.08,0,0,0,.21-.5c0-.09,0-.2,0-.22s-.18,0-.26.08c.07-.07.13-.17.28-.18s.19.21.21.31a1.47,1.47,0,0,1-.06.71.2.2,0,0,1-.25.13.21.21,0,0,1-.13-.26A.25.25,0,0,1,48.53,38.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,38.49a1.13,1.13,0,0,0,.21-.5c0-.09,0-.2,0-.22s-.17,0-.26.08c.07-.07.14-.18.29-.18s.18.21.2.31a1.46,1.46,0,0,1-.07.71.2.2,0,0,1-.26.12.19.19,0,0,1-.12-.25A.16.16,0,0,1,50,38.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,39.9a1.17,1.17,0,0,0,.23-.49c0-.09,0-.19,0-.22s-.18,0-.26.07a.39.39,0,0,1,.29-.17c.16,0,.18.22.2.32a1.44,1.44,0,0,1-.1.7.2.2,0,0,1-.26.12.2.2,0,0,1-.11-.26A.13.13,0,0,1,50,39.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.14,40.87a1.17,1.17,0,0,0,.24-.5c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.21.19.32a1.46,1.46,0,0,1-.1.7.2.2,0,0,1-.26.11.2.2,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.9,41.67a1.09,1.09,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.06.15-.16.3-.16s.17.23.18.33a1.46,1.46,0,0,1-.12.7A.2.2,0,0,1,50,42a.22.22,0,0,1-.11-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,42.15a1.24,1.24,0,0,0,.26-.48c0-.09,0-.2,0-.23s-.18,0-.27.06c.08-.06.15-.16.3-.15s.17.23.18.33a1.41,1.41,0,0,1-.13.69.21.21,0,0,1-.27.11.21.21,0,0,1-.1-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50,43.22a1.13,1.13,0,0,0,.27-.48c0-.09,0-.2,0-.23s-.18,0-.27.06c.08-.06.15-.16.3-.15s.16.23.17.33a1.47,1.47,0,0,1-.13.7.21.21,0,0,1-.27.1.2.2,0,0,1-.1-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.68,43.31a1.2,1.2,0,0,0,.28-.48c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.16.3-.14s.16.23.17.33a1.44,1.44,0,0,1-.15.69.21.21,0,0,1-.27.1.2.2,0,0,1-.09-.27A.15.15,0,0,1,51.68,43.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.61,44.51a1.14,1.14,0,0,0,.27-.47c0-.09,0-.19,0-.23s-.18,0-.27,0a.41.41,0,0,1,.3-.14c.16.06.16.23.17.34a1.37,1.37,0,0,1-.16.69.2.2,0,0,1-.36-.18A.15.15,0,0,1,50.61,44.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.91,44.89a1.13,1.13,0,0,0,.29-.46c0-.09,0-.19,0-.23s-.18,0-.28,0c.09-.06.16-.16.31-.14a.33.33,0,0,1,.16.34,1.36,1.36,0,0,1-.17.69.2.2,0,0,1-.27.09.21.21,0,0,1-.09-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.8,46.15a1.11,1.11,0,0,0,.29-.47c0-.08,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.15.07.15.24.15.34a1.47,1.47,0,0,1-.17.69.21.21,0,0,1-.27.08.2.2,0,0,1-.09-.27A.15.15,0,0,1,50.8,46.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.22,45.72a1.15,1.15,0,0,0,.3-.46c0-.09,0-.19,0-.22s-.18,0-.27,0a.36.36,0,0,1,.31-.12c.15.07.14.23.15.34a1.59,1.59,0,0,1-.19.68.21.21,0,0,1-.28.08.2.2,0,0,1-.07-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.91,46.93a1.15,1.15,0,0,0,.3-.46c0-.09,0-.19,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.24.15.34a1.44,1.44,0,0,1-.2.68.2.2,0,0,1-.27.08.2.2,0,0,1-.08-.27A.1.1,0,0,1,51.91,46.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.31,48a1.15,1.15,0,0,0,.3-.46c0-.09,0-.19,0-.22s-.18,0-.27,0a.36.36,0,0,1,.31-.12c.16.07.15.24.15.34a1.44,1.44,0,0,1-.2.68.2.2,0,0,1-.27.08.21.21,0,0,1-.08-.28A.12.12,0,0,1,51.31,48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.93,46.23a1.18,1.18,0,0,0,.32-.45c0-.09,0-.19,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.24.14.35a1.45,1.45,0,0,1-.21.67.2.2,0,0,1-.28.07.21.21,0,0,1-.07-.27A.15.15,0,0,1,54.93,46.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.22,48.56a1.16,1.16,0,0,0,.31-.45c0-.08,0-.18,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.24.14.35a1.35,1.35,0,0,1-.21.67.2.2,0,1,1-.34-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.71,47a1.16,1.16,0,0,0,.31-.45c0-.09,0-.19,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.24.14.34a1.35,1.35,0,0,1-.21.68.2.2,0,0,1-.27.08.2.2,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,48.12a1.24,1.24,0,0,0,.31-.45c0-.08.06-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.11c.16.08.14.25.14.35a1.44,1.44,0,0,1-.22.67.2.2,0,0,1-.28.07.21.21,0,0,1-.06-.28A.12.12,0,0,1,54,48.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.72,47.31a1.26,1.26,0,0,0,.32-.45c0-.09.05-.19,0-.22s-.18,0-.27,0c.09,0,.17-.14.32-.11s.13.24.13.35a1.36,1.36,0,0,1-.22.67.19.19,0,0,1-.27.07.2.2,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,47.78a1.29,1.29,0,0,0,.31-.46c0-.08.05-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.12c.16.08.14.24.14.35a1.47,1.47,0,0,1-.2.68.21.21,0,0,1-.28.07.2.2,0,0,1-.07-.28A.12.12,0,0,1,53,47.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.76,47.53a1.16,1.16,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.18,0-.28,0c.09,0,.18-.14.32-.11s.13.25.13.36a1.42,1.42,0,0,1-.23.66.2.2,0,0,1-.28.07.21.21,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.1,49.39a1,1,0,0,0,.32-.44c0-.09.06-.19,0-.22s-.18,0-.27,0a.36.36,0,0,1,.31-.11c.16.08.14.24.14.35a1.6,1.6,0,0,1-.22.67.22.22,0,0,1-.28.07.2.2,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.62,49.13A1.07,1.07,0,0,0,56,48.7c0-.08.06-.18,0-.22s-.18,0-.27,0a.4.4,0,0,1,.32-.1c.15.09.12.25.12.36a1.45,1.45,0,0,1-.25.66.2.2,0,1,1-.34-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.44,50.29a1.07,1.07,0,0,0,.33-.44c0-.08.07-.18,0-.22s-.18,0-.28,0c.09,0,.18-.14.32-.1s.13.25.13.35a1.53,1.53,0,0,1-.25.67.2.2,0,0,1-.28,0,.19.19,0,0,1,0-.28A.12.12,0,0,1,54.44,50.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.79,50.79a1.11,1.11,0,0,0,.34-.42c0-.09.07-.19,0-.22s-.17,0-.27,0a.39.39,0,0,1,.32-.09c.15.09.12.25.12.36a1.41,1.41,0,0,1-.27.65.19.19,0,0,1-.28,0,.2.2,0,0,1-.05-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.17,51.92a1.16,1.16,0,0,0,.34-.43c0-.08.06-.18,0-.22s-.18,0-.27,0c.09-.05.17-.13.32-.09s.12.25.12.35a1.43,1.43,0,0,1-.26.66.2.2,0,1,1-.33-.22S54.15,51.94,54.17,51.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.6,52.1a1.33,1.33,0,0,0,.35-.42c0-.09.07-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.32-.09c.15.09.12.26.11.36a1.41,1.41,0,0,1-.27.65.2.2,0,0,1-.33-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.83,52.28a1.21,1.21,0,0,0,.36-.41c0-.08.08-.18,0-.22s-.18,0-.27,0c.09,0,.18-.13.32-.08s.11.26.1.36a1.45,1.45,0,0,1-.29.65.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.84,53.43A1.25,1.25,0,0,0,55.2,53c0-.08.07-.18,0-.22s-.18,0-.27,0c.09,0,.18-.13.32-.08s.12.25.11.35a1.45,1.45,0,0,1-.27.66.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.14,53.92a1.14,1.14,0,0,0,.37-.41c0-.08.08-.17,0-.21s-.18,0-.28,0a.41.41,0,0,1,.33-.08c.15.1.11.26.1.36a1.45,1.45,0,0,1-.29.65.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28A.12.12,0,0,1,56.14,53.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.87,55a1.21,1.21,0,0,0,.36-.41c0-.08.07-.18,0-.22s-.17,0-.27,0c.09,0,.18-.12.32-.08a.34.34,0,0,1,.11.36,1.41,1.41,0,0,1-.29.65.2.2,0,0,1-.32-.24A.15.15,0,0,1,54.87,55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.85,55.46a1.17,1.17,0,0,0,.39-.38c0-.08.08-.18,0-.22s-.18,0-.28,0a.42.42,0,0,1,.34-.06c.13.1.09.26.07.37a1.42,1.42,0,0,1-.32.62.21.21,0,0,1-.29,0,.22.22,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.78,55.89a1.15,1.15,0,0,0,.39-.4c0-.07.08-.17,0-.21s-.17,0-.27,0c.09,0,.19-.12.33-.06s.1.26.08.36a1.58,1.58,0,0,1-.31.64.22.22,0,0,1-.29,0,.19.19,0,0,1,0-.28A.12.12,0,0,1,56.78,55.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.84,54.92a1.33,1.33,0,0,0,.38-.4c0-.08.08-.18,0-.22s-.17,0-.27,0a.36.36,0,0,1,.33-.07.32.32,0,0,1,.08.36,1.36,1.36,0,0,1-.31.64.2.2,0,1,1-.31-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.76,55.6a1.1,1.1,0,0,0,.37-.4c0-.08.08-.18,0-.22s-.18,0-.28,0a.4.4,0,0,1,.33-.07c.14.09.1.26.09.36a1.44,1.44,0,0,1-.3.64.2.2,0,1,1-.31-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.94,54.86a1.2,1.2,0,0,0,.4-.38c0-.08.08-.17,0-.22s-.17,0-.27,0a.4.4,0,0,1,.33-.06.33.33,0,0,1,.07.37,1.42,1.42,0,0,1-.33.63.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.87,54a1.18,1.18,0,0,0,.38-.39c0-.08.08-.18,0-.22s-.17,0-.27,0a.37.37,0,0,1,.33-.07c.14.1.1.26.08.37a1.55,1.55,0,0,1-.31.63.21.21,0,0,1-.29,0,.22.22,0,0,1,0-.29S57.85,54,57.87,54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,53.76a1.1,1.1,0,0,0,.38-.39c.05-.07.09-.17.06-.21s-.18,0-.28,0c.1,0,.19-.12.33-.06a.32.32,0,0,1,.08.36,1.42,1.42,0,0,1-.33.63.19.19,0,0,1-.28,0,.19.19,0,0,1,0-.28A.15.15,0,0,1,59,53.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.51,52.86a1.12,1.12,0,0,0,.39-.39c.05-.08.09-.17.05-.21s-.17,0-.27,0c.1,0,.19-.11.33,0s.09.26.08.36a1.53,1.53,0,0,1-.33.63.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.32,52.3a1.2,1.2,0,0,0,.37-.4c.05-.08.08-.18.05-.22s-.18,0-.28,0a.38.38,0,0,1,.33-.07c.14.09.1.26.09.36a1.46,1.46,0,0,1-.31.64.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.25,51.37a1,1,0,0,0,.38-.4c0-.07.08-.17,0-.21s-.18,0-.27,0a.39.39,0,0,1,.33-.06c.14.09.09.26.08.36a1.58,1.58,0,0,1-.31.64.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28A.12.12,0,0,1,59.25,51.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.73,50a1.21,1.21,0,0,0,.36-.41c0-.08.08-.18,0-.22a.36.36,0,0,0-.27,0c.09,0,.18-.13.32-.08s.11.26.1.36a1.45,1.45,0,0,1-.29.65.22.22,0,0,1-.29,0,.2.2,0,0,1,0-.28A.15.15,0,0,1,58.73,50Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.36,48.83a1.21,1.21,0,0,0,.36-.41c0-.08.07-.18,0-.22s-.18,0-.28,0c.1,0,.19-.12.33-.08s.11.26.1.37a1.42,1.42,0,0,1-.29.64.2.2,0,1,1-.32-.24A.15.15,0,0,1,59.36,48.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.6,47.74a1.16,1.16,0,0,0,.35-.43c0-.08.07-.18,0-.21s-.17,0-.27,0A.39.39,0,0,1,59,47c.15.09.12.25.11.36a1.4,1.4,0,0,1-.26.65.22.22,0,0,1-.29,0,.19.19,0,0,1,0-.28A.12.12,0,0,1,58.6,47.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.05,46.85a1.09,1.09,0,0,0,.35-.41c0-.08.08-.18,0-.22s-.18,0-.28,0a.4.4,0,0,1,.33-.09c.15.09.11.26.1.36a1.37,1.37,0,0,1-.27.65.22.22,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,46.05a1.23,1.23,0,0,0,.34-.43c0-.09.07-.19,0-.22s-.18,0-.28,0c.09,0,.18-.14.33-.1s.12.25.11.36a1.35,1.35,0,0,1-.25.66.2.2,0,0,1-.28.05.2.2,0,0,1,0-.28A.12.12,0,0,1,59,46.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.25,45a1.23,1.23,0,0,0,.34-.43c0-.09.06-.19,0-.22s-.18,0-.27,0a.37.37,0,0,1,.32-.1c.15.08.12.25.12.35a1.45,1.45,0,0,1-.25.67A.2.2,0,1,1,59.2,45,.12.12,0,0,1,59.25,45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.26,44.08a1.23,1.23,0,0,0,.34-.43c0-.09.06-.19,0-.22s-.18,0-.28,0c.09,0,.18-.14.32-.1s.13.25.12.35a1.47,1.47,0,0,1-.24.67.21.21,0,0,1-.28,0,.2.2,0,0,1-.06-.28S60.25,44.09,60.26,44.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,43.34a1.14,1.14,0,0,0,.32-.44c0-.09.06-.19,0-.22s-.18,0-.28,0a.38.38,0,0,1,.32-.11c.15.08.14.24.14.35a1.49,1.49,0,0,1-.23.67.21.21,0,0,1-.28.06A.19.19,0,0,1,59,43.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.35,42.46a1.16,1.16,0,0,0,.33-.44c0-.08,0-.18,0-.22s-.18,0-.27,0c.09,0,.17-.14.32-.11s.13.25.13.35a1.42,1.42,0,0,1-.23.67.2.2,0,1,1-.34-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.46,41.93a1,1,0,0,0,.3-.45c0-.09.06-.19,0-.22s-.18,0-.27,0c.09-.06.17-.15.31-.12s.15.24.15.34a1.44,1.44,0,0,1-.2.68.2.2,0,0,1-.27.08.2.2,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,41a1,1,0,0,0,.3-.45c0-.09,0-.19,0-.23a.39.39,0,0,0-.27,0,.39.39,0,0,1,.31-.13c.16.08.14.24.15.35a1.47,1.47,0,0,1-.2.68.2.2,0,0,1-.27.08A.21.21,0,0,1,59,41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.51,39.7a1.11,1.11,0,0,0,.29-.47c0-.08,0-.18,0-.22s-.18,0-.27.05c.08-.07.16-.16.3-.14a.33.33,0,0,1,.16.34,1.36,1.36,0,0,1-.17.69.2.2,0,0,1-.27.08.19.19,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,38.44a1.06,1.06,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.41.41,0,0,1,.3-.14c.16.07.16.24.17.34a1.45,1.45,0,0,1-.16.69.2.2,0,0,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.88,37.1a1.28,1.28,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.15.3-.13s.16.23.17.33a1.47,1.47,0,0,1-.17.69.2.2,0,1,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.13,35.73a1.24,1.24,0,0,0,.26-.48c0-.09,0-.19,0-.22s-.17,0-.26,0c.08-.06.15-.16.3-.15s.16.23.18.33a1.49,1.49,0,0,1-.14.7.2.2,0,0,1-.26.1.2.2,0,0,1-.1-.27A.21.21,0,0,1,60.13,35.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.78,35a1.11,1.11,0,0,0,.26-.48c0-.09,0-.19,0-.22a.36.36,0,0,0-.27.06c.08-.07.15-.17.3-.15s.16.22.18.32a1.47,1.47,0,0,1-.13.7.2.2,0,0,1-.27.1.19.19,0,0,1-.1-.26A.43.43,0,0,1,60.78,35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.48,34.69a1,1,0,0,0,.23-.49c0-.09,0-.2,0-.22s-.18,0-.27.07c.08-.07.14-.17.29-.17s.18.22.2.32a1.45,1.45,0,0,1-.09.7.2.2,0,0,1-.26.12.22.22,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.52,33a1.32,1.32,0,0,0,.22-.51c0-.09,0-.19,0-.22s-.18,0-.27.08c.08-.07.14-.17.29-.17s.18.21.21.31a1.43,1.43,0,0,1-.08.7.19.19,0,0,1-.25.13.2.2,0,0,1-.13-.26S59.5,33,59.52,33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.41,32.41a1.16,1.16,0,0,0,.2-.51c0-.09,0-.19,0-.22s-.18,0-.26.09.13-.18.28-.19.19.21.22.31a1.41,1.41,0,0,1-.05.7.2.2,0,0,1-.25.14.19.19,0,0,1-.13-.25A.15.15,0,0,1,58.41,32.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.05,32.23a1.15,1.15,0,0,0,.18-.51c0-.1,0-.2,0-.22s-.17,0-.25.1.12-.19.27-.2.2.2.23.3a1.56,1.56,0,0,1,0,.71.21.21,0,0,1-.25.14A.2.2,0,0,1,57,32.3.21.21,0,0,1,57.05,32.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.44,31.08a1.1,1.1,0,0,0,.16-.53c0-.09,0-.19-.05-.21s-.17,0-.25.1a.42.42,0,0,1,.27-.2c.17,0,.2.19.23.29a1.42,1.42,0,0,1,0,.71.21.21,0,0,1-.25.15.21.21,0,0,1-.15-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.07,30.79a1.09,1.09,0,0,0,.15-.52c0-.1,0-.2-.06-.22s-.17.05-.25.12c.06-.08.11-.19.26-.22s.21.19.25.29a1.49,1.49,0,0,1,0,.7.2.2,0,1,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.31,29.77a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.18.26.27a1.52,1.52,0,0,1,0,.71.2.2,0,0,1-.4-.07A.19.19,0,0,1,55.31,29.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,27.23a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.16.06-.23.13c0-.08.09-.2.24-.23s.22.16.27.26a1.46,1.46,0,0,1,.08.7.21.21,0,0,1-.23.18.21.21,0,0,1-.15-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.27,26a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.16.07-.23.14c.05-.09.09-.2.23-.24s.23.16.28.26a1.46,1.46,0,0,1,.09.7.2.2,0,0,1-.4,0A.19.19,0,0,1,54.27,26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.27,24.3a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c.05-.09.08-.2.23-.24s.23.16.28.25a1.46,1.46,0,0,1,.1.7.19.19,0,0,1-.21.18.19.19,0,0,1-.19-.21A.24.24,0,0,1,53.27,24.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.08,23.05a1,1,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14.09-.2.23-.24.23.16.28.25a1.35,1.35,0,0,1,.11.7.19.19,0,0,1-.21.18.19.19,0,0,1-.19-.21A.24.24,0,0,1,52.08,23.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.44,21.86a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c0-.09.08-.2.23-.24s.23.16.28.25a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.22.18.19.19,0,0,1-.18-.21A.19.19,0,0,1,51.44,21.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.36,20.79a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14.09-.2.24-.24.23.16.27.26a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.4,0A.19.19,0,0,1,50.36,20.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.25,19.89a1.18,1.18,0,0,0,.1-.54c0-.1,0-.2-.08-.21s-.17.07-.24.14c0-.09.09-.2.24-.24s.23.16.27.26a1.46,1.46,0,0,1,.09.7.2.2,0,1,1-.4,0A.14.14,0,0,1,49.25,19.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.37,18.92a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.25-.24s.22.17.26.26a1.4,1.4,0,0,1,.09.71.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22A.19.19,0,0,1,48.37,18.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.28,19.44a1.12,1.12,0,0,0,.11-.53c0-.1,0-.2-.08-.21s-.17.06-.24.13.09-.2.24-.23.23.17.27.26a1.47,1.47,0,0,1,.08.7.2.2,0,0,1-.22.18.2.2,0,0,1-.18-.22A.19.19,0,0,1,47.28,19.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.68,18.43a1.07,1.07,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.25-.24s.22.17.26.27a1.35,1.35,0,0,1,.08.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,46.68,18.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.5,18.93a1,1,0,0,0,.11-.53c0-.1,0-.2-.07-.21s-.17.06-.24.13c.05-.09.09-.2.24-.23s.22.17.27.26a1.52,1.52,0,0,1,.07.71.2.2,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,45.5,18.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.67,17.84a1.19,1.19,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.25.13.1-.2.25-.23.22.17.26.27a1.47,1.47,0,0,1,.07.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.14.14,0,0,1,44.67,17.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.88,18.53A1.13,1.13,0,0,0,44,18c0-.09,0-.19-.07-.2s-.17.06-.25.12.1-.19.25-.22.22.17.26.26a1.52,1.52,0,0,1,.07.71.2.2,0,1,1-.4-.06A.19.19,0,0,1,43.88,18.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.92,17.72a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13c.06-.08.1-.2.25-.23s.22.18.26.27a1.4,1.4,0,0,1,0,.71.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.92,17.18a1.12,1.12,0,0,0,.12-.53c0-.09,0-.19-.07-.21s-.16.06-.24.12.1-.19.25-.22.22.17.26.27a1.52,1.52,0,0,1,0,.71.2.2,0,0,1-.4-.07A.21.21,0,0,1,41.92,17.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.12,18.16a1.12,1.12,0,0,0,.13-.53c0-.1,0-.2-.07-.21s-.17,0-.24.12.1-.2.25-.22.21.17.25.27a1.4,1.4,0,0,1,.05.71.2.2,0,0,1-.23.16.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.8,17.64a1.13,1.13,0,0,0,.13-.54c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.25-.22.22.18.26.28a1.52,1.52,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,19.12a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.05-.25.12.11-.2.26-.22.21.18.25.28a1.37,1.37,0,0,1,0,.7.2.2,0,0,1-.23.17.21.21,0,0,1-.16-.24A.11.11,0,0,1,39,19.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.59,20.18a1.09,1.09,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.21.18.25.28a1.36,1.36,0,0,1,.05.7.2.2,0,1,1-.4-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.66,20a1.17,1.17,0,0,0,.14-.54c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.21.18.25.28a1.3,1.3,0,0,1,0,.71.19.19,0,0,1-.23.16.2.2,0,0,1-.16-.23A.14.14,0,0,1,39.66,20Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41,21.24a1.12,1.12,0,0,0,.13-.53c0-.09,0-.2-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.17.26.27a1.66,1.66,0,0,1,0,.71.21.21,0,0,1-.24.16.19.19,0,0,1-.16-.23A.19.19,0,0,1,41,21.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.9,20.83A1.26,1.26,0,0,0,39,20.3c0-.09,0-.2-.07-.21s-.17.05-.24.12c.06-.09.1-.2.25-.22s.21.18.25.27a1.31,1.31,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.88,21.63A1.09,1.09,0,0,0,40,21.1c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.21.18.25.28a1.36,1.36,0,0,1,0,.7.21.21,0,0,1-.24.17.2.2,0,0,1-.16-.23A.19.19,0,0,1,39.88,21.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.55,22.79a1.12,1.12,0,0,0,.13-.53c0-.1,0-.2-.07-.22s-.17.06-.24.13c.06-.08.1-.2.25-.22s.21.17.25.27a1.36,1.36,0,0,1,.06.7.2.2,0,0,1-.4-.06A.19.19,0,0,1,39.55,22.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.31,27.09a1.18,1.18,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c0-.09.08-.2.23-.24s.23.15.28.25a1.35,1.35,0,0,1,.11.7.2.2,0,0,1-.4,0A.19.19,0,0,1,39.31,27.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.34,27.87a1.1,1.1,0,0,0,.06-.55c0-.09,0-.19-.09-.2s-.16.08-.23.16c.05-.09.08-.21.22-.26s.24.15.29.24a1.32,1.32,0,0,1,.14.69.2.2,0,0,1-.21.2.19.19,0,0,1-.19-.21S38.34,27.89,38.34,27.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.76,28.73a1.18,1.18,0,0,0,.07-.54c0-.1,0-.19-.09-.21s-.16.08-.23.15c.05-.09.08-.2.23-.25s.23.16.28.25a1.46,1.46,0,0,1,.12.7.2.2,0,0,1-.21.19.21.21,0,0,1-.19-.22A.19.19,0,0,1,39.76,28.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.61,29.1a1.23,1.23,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.23.16c.05-.09.08-.21.22-.26s.24.15.29.24a1.41,1.41,0,0,1,.16.69.21.21,0,0,1-.41,0A.19.19,0,0,1,38.61,29.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.35,30.21a1.19,1.19,0,0,0,.06-.54c0-.09,0-.19-.1-.2s-.16.08-.23.16c.05-.09.08-.21.22-.26s.24.15.29.24a1.4,1.4,0,0,1,.15.69.2.2,0,0,1-.21.2.21.21,0,0,1-.19-.21S39.35,30.24,39.35,30.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.27,29.22a1.12,1.12,0,0,0,0-.55c0-.09,0-.19-.1-.2s-.16.09-.22.17c0-.09.07-.21.21-.26s.24.13.3.21a1.45,1.45,0,0,1,.18.69.21.21,0,0,1-.19.21.2.2,0,0,1-.21-.2A.17.17,0,0,1,37.27,29.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.84,30.39a1.26,1.26,0,0,0,0-.55c0-.09,0-.19-.11-.2s-.15.09-.21.17c0-.09.06-.21.2-.26s.25.13.31.22a1.49,1.49,0,0,1,.18.68.21.21,0,0,1-.41,0A.19.19,0,0,1,37.84,30.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.81,31.3a1.25,1.25,0,0,0,0-.55c0-.09-.05-.19-.1-.2s-.16.09-.22.17c0-.09.07-.21.21-.26s.24.13.3.22a1.53,1.53,0,0,1,.17.69.2.2,0,0,1-.2.2.21.21,0,0,1-.21-.2A.19.19,0,0,1,38.81,31.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.76,30.51a1.27,1.27,0,0,0,0-.55c0-.08-.06-.18-.12-.18s-.15.09-.21.17c0-.09.06-.21.2-.27s.25.12.31.2a1.4,1.4,0,0,1,.21.68.21.21,0,0,1-.19.22.2.2,0,0,1-.21-.19A.11.11,0,0,1,36.76,30.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.48,32.2a1.12,1.12,0,0,0,0-.55c0-.09-.05-.19-.1-.2s-.16.09-.22.17a.39.39,0,0,1,.21-.27c.17,0,.24.14.3.23a1.56,1.56,0,0,1,.16.69.2.2,0,0,1-.2.2.2.2,0,0,1-.21-.2A.19.19,0,0,1,39.48,32.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.55,31.72a1.09,1.09,0,0,0,0-.54c0-.09-.06-.19-.11-.19s-.16.09-.22.17c0-.1.06-.21.2-.27s.25.12.31.21a1.5,1.5,0,0,1,.2.68.2.2,0,0,1-.19.21.19.19,0,0,1-.21-.19A.22.22,0,0,1,37.55,31.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.38,32.87a1,1,0,0,0,0-.55c0-.09,0-.18-.11-.19s-.15.09-.21.17c0-.09.06-.21.2-.27s.25.13.3.22a1.39,1.39,0,0,1,.2.68.22.22,0,0,1-.2.21.21.21,0,0,1-.21-.19S38.38,32.89,38.38,32.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.22,32.18a1.16,1.16,0,0,0,0-.55c0-.09-.06-.18-.12-.19s-.15.11-.2.19c0-.1.05-.22.18-.28s.26.11.32.19a1.35,1.35,0,0,1,.24.67.2.2,0,1,1-.4,0A.17.17,0,0,1,36.22,32.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.11,33.24a1.28,1.28,0,0,0,0-.55c0-.08-.07-.18-.12-.18s-.15.1-.21.18c0-.1.06-.22.19-.28s.26.12.32.2a1.45,1.45,0,0,1,.23.67.21.21,0,0,1-.19.22.2.2,0,0,1-.22-.18S37.11,33.26,37.11,33.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.84,33.46a1.31,1.31,0,0,0,0-.55c0-.09-.08-.18-.13-.18s-.15.11-.2.19a.43.43,0,0,1,.17-.29c.17,0,.26.1.33.18a1.43,1.43,0,0,1,.26.66.2.2,0,1,1-.4.06A.17.17,0,0,1,35.84,33.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.29,34.59a1.28,1.28,0,0,0,0-.55c0-.09-.07-.18-.13-.18S37,34,37,34c0-.1,0-.22.19-.28s.25.11.31.19a1.41,1.41,0,0,1,.23.67.21.21,0,0,1-.18.23.2.2,0,0,1-.22-.19A.17.17,0,0,1,37.29,34.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.25,35.81a1.15,1.15,0,0,0,0-.55c0-.09-.07-.18-.12-.19s-.15.11-.2.19c0-.1.05-.22.18-.28s.26.11.32.19a1.58,1.58,0,0,1,.24.67.2.2,0,0,1-.18.22.22.22,0,0,1-.23-.18S37.25,35.83,37.25,35.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.72,35.16a1.16,1.16,0,0,0,0-.55c0-.09-.08-.18-.14-.18s-.14.12-.19.2c0-.1,0-.22.17-.29s.26.09.33.17a1.37,1.37,0,0,1,.28.65.2.2,0,1,1-.4.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.25,36.26a1.18,1.18,0,0,0,0-.55c0-.08-.08-.17-.13-.18s-.14.11-.2.2c0-.1,0-.22.17-.29s.26.1.33.18a1.27,1.27,0,0,1,.27.65.2.2,0,1,1-.4.06A.17.17,0,0,1,36.25,36.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.42,35.49a1,1,0,0,0-.09-.54c0-.09-.09-.17-.14-.17s-.14.12-.18.21c0-.1,0-.22.14-.3s.27.07.35.14a1.49,1.49,0,0,1,.32.64.2.2,0,0,1-.15.24.19.19,0,0,1-.24-.15A.17.17,0,0,1,34.42,35.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.7,36.6a1.18,1.18,0,0,0-.09-.54c0-.08-.09-.17-.14-.17s-.14.12-.18.21c0-.1,0-.22.14-.3s.27.08.35.15a1.45,1.45,0,0,1,.32.63.21.21,0,0,1-.15.25.2.2,0,0,1-.24-.15A.25.25,0,0,1,34.7,36.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.25,37.85a1.06,1.06,0,0,0,0-.55c0-.09-.07-.18-.13-.18s-.14.11-.19.2c0-.1,0-.22.17-.29s.26.09.33.17a1.56,1.56,0,0,1,.27.66.2.2,0,1,1-.4.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.17,38.07a1.32,1.32,0,0,0-.08-.54c0-.08-.09-.17-.15-.17s-.13.12-.18.21c0-.1,0-.22.15-.3s.27.08.34.16a1.34,1.34,0,0,1,.31.63.2.2,0,0,1-.39.09A.11.11,0,0,1,35.17,38.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.08,38.23a1.23,1.23,0,0,0-.12-.54c0-.08-.1-.16-.15-.16s-.13.13-.17.22c0-.1,0-.22.13-.31s.27.07.35.14a1.36,1.36,0,0,1,.35.61.2.2,0,0,1-.14.25.2.2,0,0,1-.25-.14S34.07,38.25,34.08,38.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.31,39.44a1.17,1.17,0,0,0-.08-.54c0-.09-.09-.18-.14-.17s-.14.11-.18.2c0-.1,0-.22.15-.3s.27.09.34.16a1.46,1.46,0,0,1,.3.64.19.19,0,0,1-.15.24.21.21,0,0,1-.24-.15A.11.11,0,0,1,35.31,39.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.94,38.57a1.33,1.33,0,0,0-.15-.52c0-.08-.11-.16-.17-.15s-.12.13-.15.23c0-.11,0-.22.11-.32s.27,0,.36.11a1.39,1.39,0,0,1,.39.59.2.2,0,0,1-.38.14A.19.19,0,0,1,32.94,38.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,39.87a1.2,1.2,0,0,0-.12-.54c0-.08-.1-.16-.16-.16s-.12.13-.16.22a.35.35,0,0,1,.13-.31c.16-.06.27.06.35.13a1.57,1.57,0,0,1,.35.62.21.21,0,0,1-.13.25.22.22,0,0,1-.26-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.7,40a1.2,1.2,0,0,0-.16-.52c0-.08-.11-.16-.17-.15s-.11.14-.14.24c0-.11,0-.22.1-.32s.28,0,.36.1a1.44,1.44,0,0,1,.4.58.2.2,0,0,1-.37.15A.19.19,0,0,1,32.7,40Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.71,41.39a1.08,1.08,0,0,0-.09-.55c0-.08-.09-.16-.14-.16s-.14.12-.18.21c0-.1,0-.22.14-.3s.27.07.35.14a1.51,1.51,0,0,1,.32.63.21.21,0,0,1-.15.25.19.19,0,0,1-.24-.15A.17.17,0,0,1,34.71,41.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.41,41.6a1.22,1.22,0,0,0-.13-.54c-.05-.08-.11-.16-.16-.15S33,41,33,41.13a.37.37,0,0,1,.12-.31c.16-.07.27,0,.35.12a1.38,1.38,0,0,1,.38.6.22.22,0,0,1-.13.26.21.21,0,0,1-.26-.13A.17.17,0,0,1,33.41,41.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.24,41.73a1,1,0,0,0-.18-.51c-.05-.08-.11-.16-.17-.15s-.11.14-.14.24a.41.41,0,0,1,.1-.32c.15-.08.27,0,.36.09a1.44,1.44,0,0,1,.42.57.21.21,0,0,1-.11.27.22.22,0,0,1-.27-.11A.19.19,0,0,1,32.24,41.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.38,43a1.06,1.06,0,0,0-.13-.53c0-.08-.1-.16-.16-.15s-.12.13-.16.22a.44.44,0,0,1,.13-.32c.15-.06.27.06.35.13a1.46,1.46,0,0,1,.37.6.21.21,0,1,1-.39.13S33.38,43.07,33.38,43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.34,42.81a1.23,1.23,0,0,0-.21-.51c-.05-.07-.13-.14-.18-.13s-.1.15-.12.24c0-.1,0-.22.07-.32s.28,0,.37.07a1.55,1.55,0,0,1,.45.55.2.2,0,0,1-.09.27.2.2,0,0,1-.27-.1A.1.1,0,0,1,31.34,42.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,43.94a1.22,1.22,0,0,0-.17-.52c-.05-.07-.12-.15-.17-.14s-.12.14-.15.24a.41.41,0,0,1,.1-.32c.15-.08.28,0,.36.09a1.44,1.44,0,0,1,.42.57.22.22,0,0,1-.11.27.2.2,0,0,1-.26-.11A.19.19,0,0,1,32,43.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.13,45.22A1.2,1.2,0,0,0,32,44.7c0-.08-.11-.16-.17-.14s-.11.13-.14.23c0-.1,0-.22.1-.32s.27,0,.36.1a1.44,1.44,0,0,1,.4.58.19.19,0,0,1-.11.26.2.2,0,0,1-.26-.11S32.14,45.25,32.13,45.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.73,45.21a1.2,1.2,0,0,0-.22-.51c-.05-.07-.13-.14-.18-.13s-.1.15-.12.25c0-.1,0-.22.07-.33s.28,0,.37.07a1.5,1.5,0,0,1,.46.53.21.21,0,0,1-.09.28.2.2,0,0,1-.27-.09A.19.19,0,0,1,30.73,45.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.38,46.67a1.27,1.27,0,0,0-.19-.52c-.05-.07-.12-.15-.17-.14s-.11.15-.14.24c0-.1,0-.22.09-.32s.28,0,.37.09a1.34,1.34,0,0,1,.42.56.2.2,0,0,1-.1.27.21.21,0,0,1-.27-.11A.17.17,0,0,1,31.38,46.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.27,47.15a1.1,1.1,0,0,0-.22-.5c-.05-.07-.13-.14-.18-.12s-.1.15-.12.24c0-.1,0-.21.06-.33s.28,0,.37.06a1.43,1.43,0,0,1,.47.53.21.21,0,0,1-.08.28.2.2,0,0,1-.27-.09S30.28,47.18,30.27,47.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.58,48.44a1.18,1.18,0,0,0-.21-.51c-.05-.08-.12-.15-.18-.13s-.1.14-.12.24a.41.41,0,0,1,.07-.33c.15-.09.28,0,.37.08a1.42,1.42,0,0,1,.45.54.2.2,0,0,1-.36.18A.19.19,0,0,1,30.58,48.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.79,49.69a1.1,1.1,0,0,0-.22-.5c-.06-.07-.13-.14-.18-.13s-.1.15-.12.25c0-.1,0-.22.06-.33s.28,0,.37.06a1.43,1.43,0,0,1,.47.53.2.2,0,0,1-.08.27.19.19,0,0,1-.27-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.8,50.51a1.09,1.09,0,0,0-.25-.49c-.06-.07-.13-.14-.18-.12s-.1.16-.11.26c0-.11-.05-.22,0-.34s.28,0,.37,0a1.5,1.5,0,0,1,.49.51.21.21,0,0,1-.06.28.21.21,0,0,1-.28-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.58,51.68a1.07,1.07,0,0,0-.21-.51c-.06-.07-.13-.14-.18-.13s-.11.15-.13.25a.41.41,0,0,1,.07-.33c.15-.09.28,0,.37.07a1.41,1.41,0,0,1,.46.53.2.2,0,0,1-.08.27.2.2,0,0,1-.28-.08S29.59,51.7,29.58,51.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.37,52.21a1.09,1.09,0,0,0-.25-.49c-.05-.07-.13-.13-.18-.11s-.1.15-.11.25c0-.1,0-.22.05-.33a.33.33,0,0,1,.37,0,1.34,1.34,0,0,1,.49.51.2.2,0,1,1-.34.2S28.38,52.23,28.37,52.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.49,53.05a1.08,1.08,0,0,0-.21-.5c-.06-.08-.13-.15-.18-.13s-.1.14-.13.24a.39.39,0,0,1,.08-.33c.14-.09.27,0,.36.07a1.44,1.44,0,0,1,.46.54.2.2,0,0,1-.36.18A.19.19,0,0,1,29.49,53.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.61,53.51a1.18,1.18,0,0,0-.24-.49c-.06-.07-.13-.14-.18-.13s-.1.16-.12.26c0-.11,0-.22.06-.34a.33.33,0,0,1,.37.06,1.43,1.43,0,0,1,.48.52.19.19,0,0,1-.08.27.2.2,0,0,1-.27-.08A.19.19,0,0,1,28.61,53.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.63,53a1.2,1.2,0,0,0-.26-.48c-.06-.07-.14-.13-.19-.11s-.09.15-.1.25c0-.1-.06-.21,0-.33s.27,0,.37,0a1.4,1.4,0,0,1,.51.49.2.2,0,1,1-.34.22A.11.11,0,0,1,27.63,53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.41,51.47a1.05,1.05,0,0,0-.28-.47c-.06-.07-.14-.13-.19-.1s-.08.15-.09.25a.36.36,0,0,1,0-.33c.13-.11.27,0,.37,0a1.45,1.45,0,0,1,.52.47.2.2,0,0,1-.33.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.19,50.4a1.18,1.18,0,0,0-.29-.47c-.07-.06-.15-.12-.2-.1s-.08.17-.08.27c0-.11-.07-.22,0-.34s.27,0,.37,0a1.46,1.46,0,0,1,.54.46.2.2,0,0,1-.05.28.2.2,0,0,1-.28,0A.21.21,0,0,1,27.19,50.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.8,48.52a1.15,1.15,0,0,0-.29-.46c-.06-.07-.15-.13-.2-.1s-.07.16-.08.26a.38.38,0,0,1,0-.34c.13-.11.28,0,.37,0a1.42,1.42,0,0,1,.54.47.2.2,0,1,1-.33.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.17,46.78a1.12,1.12,0,0,0-.28-.46c-.07-.07-.15-.13-.2-.1s-.08.16-.09.26a.45.45,0,0,1,0-.34c.13-.11.28,0,.38,0a1.53,1.53,0,0,1,.53.46.2.2,0,1,1-.32.24A.25.25,0,0,1,28.17,46.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.6,44.89a1.18,1.18,0,0,0-.29-.47c-.06-.06-.15-.12-.19-.1s-.08.16-.09.26a.41.41,0,0,1,0-.34c.13-.11.28,0,.38,0a1.38,1.38,0,0,1,.53.47.2.2,0,1,1-.33.23A.11.11,0,0,1,28.6,44.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.4,41.71a1.13,1.13,0,0,0-.27-.47c-.06-.07-.15-.13-.2-.11s-.08.16-.09.26c0-.11-.06-.22,0-.34s.28,0,.38,0a1.37,1.37,0,0,1,.51.48.2.2,0,0,1-.06.28.19.19,0,0,1-.27-.06A.09.09,0,0,1,29.4,41.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,39.38a1.3,1.3,0,0,0-.26-.49c-.06-.06-.13-.13-.18-.11s-.09.16-.11.25a.37.37,0,0,1,.05-.33c.13-.1.28,0,.37,0a1.49,1.49,0,0,1,.5.5.2.2,0,0,1-.35.21A.19.19,0,0,1,30,39.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.53,37.06a1.1,1.1,0,0,0-.22-.5c-.06-.07-.14-.14-.19-.12s-.1.15-.11.25a.37.37,0,0,1,.06-.33c.14-.1.28,0,.37,0a1.43,1.43,0,0,1,.47.53.2.2,0,0,1-.08.27.2.2,0,0,1-.27-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.8,35.75a1.11,1.11,0,0,0-.21-.51c-.05-.07-.13-.14-.18-.12s-.1.14-.12.24c0-.1,0-.22.07-.33s.28,0,.37.07a1.42,1.42,0,0,1,.45.54.21.21,0,0,1-.08.27.21.21,0,0,1-.28-.09S30.81,35.77,30.8,35.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,34.48a1.21,1.21,0,0,0-.2-.51c-.05-.07-.12-.15-.18-.13s-.1.14-.13.24c0-.1,0-.22.08-.33s.28,0,.37.08a1.51,1.51,0,0,1,.44.55.2.2,0,1,1-.36.18A.24.24,0,0,1,31,34.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.42,33.11a1.25,1.25,0,0,0-.18-.52c0-.08-.11-.15-.17-.14s-.11.14-.14.23a.41.41,0,0,1,.1-.32c.15-.08.28,0,.36.1a1.27,1.27,0,0,1,.41.57.19.19,0,0,1-.11.26.19.19,0,0,1-.26-.1A.25.25,0,0,1,31.42,33.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.37,32.29a1.21,1.21,0,0,0-.13-.53c0-.08-.1-.16-.15-.16s-.13.13-.17.23a.44.44,0,0,1,.13-.32c.16-.06.27.06.35.13a1.38,1.38,0,0,1,.36.61.19.19,0,0,1-.13.25.2.2,0,0,1-.25-.13A.19.19,0,0,1,32.37,32.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.65,31.28a1.22,1.22,0,0,0-.11-.54c0-.08-.1-.17-.15-.16s-.13.12-.17.21c0-.1,0-.22.13-.3a.33.33,0,0,1,.35.14,1.3,1.3,0,0,1,.34.62.2.2,0,1,1-.39.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.94,30.14a1.32,1.32,0,0,0-.08-.54c0-.08-.09-.17-.15-.17s-.13.12-.18.21c0-.1,0-.22.15-.3s.27.08.34.15a1.37,1.37,0,0,1,.31.64.2.2,0,0,1-.39.09A.11.11,0,0,1,32.94,30.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.5,28.84a1.17,1.17,0,0,0-.08-.54c0-.08-.09-.17-.14-.17s-.14.12-.18.21c0-.1,0-.22.15-.3s.27.08.34.16a1.42,1.42,0,0,1,.3.63.2.2,0,1,1-.39.09A.11.11,0,0,1,32.5,28.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.48,28.48a1.17,1.17,0,0,0,0-.55c0-.08-.08-.17-.14-.17s-.14.1-.19.19c0-.1,0-.22.17-.29s.26.1.33.18a1.52,1.52,0,0,1,.27.65.2.2,0,0,1-.17.23.2.2,0,0,1-.23-.16A.21.21,0,0,1,33.48,28.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.47,27.41a1.16,1.16,0,0,0,0-.55c0-.09-.07-.18-.13-.18s-.14.1-.19.19c0-.1,0-.22.17-.29s.26.11.33.19a1.54,1.54,0,0,1,.25.66.22.22,0,0,1-.18.23.2.2,0,0,1-.22-.18S33.47,27.43,33.47,27.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34,26.38a1.1,1.1,0,0,0,0-.54c0-.09-.06-.19-.11-.19s-.15.1-.21.18c0-.1.05-.22.19-.28s.25.12.32.2a1.51,1.51,0,0,1,.21.68.2.2,0,0,1-.18.22.22.22,0,0,1-.22-.19A.25.25,0,0,1,34,26.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.14,25.21a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.16.09-.22.16c0-.09.07-.21.21-.26s.24.14.3.23a1.41,1.41,0,0,1,.16.69.2.2,0,0,1-.4,0A.19.19,0,0,1,35.14,25.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.93,20.27a1.12,1.12,0,0,0,.12-.53c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.09.1-.2.25-.23s.22.17.26.27a1.4,1.4,0,0,1,.06.71.2.2,0,0,1-.4-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.18,19.65a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.07-.21S36.08,19,36,19c.07-.09.11-.2.26-.22s.21.18.25.28a1.49,1.49,0,0,1,0,.7.22.22,0,0,1-.24.17.21.21,0,0,1-.16-.24A.11.11,0,0,1,36.18,19.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.24,19.64a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17.05-.25.12c.06-.09.11-.2.26-.22s.21.18.25.28a1.53,1.53,0,0,1,0,.71.2.2,0,1,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.14,18.19a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.18.05-.25.12c.06-.08.11-.2.25-.22s.22.18.25.28a1.4,1.4,0,0,1,0,.71.21.21,0,0,1-.24.16.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.86,18.67a1.26,1.26,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.05-.24.12c.06-.08.1-.2.25-.22s.22.18.25.27a1.4,1.4,0,0,1,0,.71A.21.21,0,0,1,40,19a.2.2,0,0,1-.16-.23A.11.11,0,0,1,39.86,18.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.83,19.53A1.12,1.12,0,0,0,42,19c0-.09,0-.19-.07-.21s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.18.26.27a1.4,1.4,0,0,1,.05.71.2.2,0,1,1-.4-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.89,20.09a1.13,1.13,0,0,0,.12-.54c0-.09,0-.19-.07-.2s-.17.06-.25.12.11-.19.25-.22.22.17.26.27a1.35,1.35,0,0,1,.06.7.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.7,20.76a1.27,1.27,0,0,0,.11-.54c0-.09,0-.19-.07-.21s-.17.07-.24.13.1-.19.24-.22.22.17.27.26a1.4,1.4,0,0,1,.06.71.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.93,20.86a1.3,1.3,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.1-.2.24-.23s.23.16.27.26a1.47,1.47,0,0,1,.07.7.2.2,0,0,1-.22.18.21.21,0,0,1-.18-.23A.11.11,0,0,1,44.93,20.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.23,21.33a1.16,1.16,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.09-.2.24-.23s.23.16.27.26a1.46,1.46,0,0,1,.08.7.21.21,0,0,1-.23.18.21.21,0,0,1-.17-.23A.19.19,0,0,1,46.23,21.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.59,21.27a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.16.06-.24.13c.06-.08.1-.2.24-.23s.23.16.27.26a1.35,1.35,0,0,1,.09.7.2.2,0,0,1-.22.18.21.21,0,0,1-.18-.23A.19.19,0,0,1,47.59,21.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.54,22a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.08.1-.2.24-.23s.23.16.28.25a1.61,1.61,0,0,1,.09.7.2.2,0,0,1-.22.18.2.2,0,0,1-.18-.22A.19.19,0,0,1,48.54,22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.45,23.13a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c.05-.09.09-.21.23-.24s.23.16.28.25a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.22.18.19.19,0,0,1-.18-.22A.19.19,0,0,1,49.45,23.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,24.05a1.06,1.06,0,0,0,.08-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.15.27.25a1.35,1.35,0,0,1,.11.7.2.2,0,0,1-.4,0A.19.19,0,0,1,51,24.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.67,25.34a1.2,1.2,0,0,0,.09-.55c0-.09,0-.19-.08-.2s-.17.07-.24.14c.05-.09.09-.2.24-.24s.22.16.27.25a1.52,1.52,0,0,1,.1.71.2.2,0,0,1-.4,0A.14.14,0,0,1,51.67,25.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.85,25.62a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.09-.21s-.16.07-.23.14c.05-.08.09-.2.23-.24s.23.16.28.26a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.22.18.21.21,0,0,1-.19-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.75,27.26a1.33,1.33,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c0-.09.09-.2.24-.24s.22.17.27.26a1.47,1.47,0,0,1,.09.7.21.21,0,0,1-.41,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.28,28.19a1,1,0,0,0,.11-.53c0-.09,0-.19-.07-.21s-.17.06-.24.13.09-.2.24-.23.22.17.27.26a1.52,1.52,0,0,1,.07.71.2.2,0,0,1-.23.17.19.19,0,0,1-.17-.22A.24.24,0,0,1,55.28,28.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.91,28.13A1.25,1.25,0,0,0,57,27.6c0-.09,0-.2-.07-.21s-.17.06-.24.12a.37.37,0,0,1,.25-.22c.17,0,.21.17.25.27a1.37,1.37,0,0,1,.06.7.2.2,0,0,1-.23.17.2.2,0,0,1-.17-.23A.11.11,0,0,1,56.91,28.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,29.2a1.06,1.06,0,0,0,.14-.54c0-.09,0-.19-.06-.21s-.18.06-.25.12.11-.19.25-.22.22.18.26.28a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.39-.07A.14.14,0,0,1,57,29.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.16,29.86a1.38,1.38,0,0,0,.16-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.19.24.29a1.28,1.28,0,0,1,0,.7.21.21,0,0,1-.24.16.2.2,0,0,1-.15-.24A.09.09,0,0,1,58.16,29.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,31.23a1,1,0,0,0,.19-.51c0-.1,0-.2,0-.22s-.18,0-.26.09.12-.18.27-.19.2.2.23.3a1.44,1.44,0,0,1,0,.71.2.2,0,0,1-.25.14A.2.2,0,0,1,59,31.3.15.15,0,0,1,59,31.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.93,33.46a1.08,1.08,0,0,0,.21-.5c0-.09,0-.2,0-.22s-.17,0-.26.08c.07-.07.13-.17.28-.18s.19.21.22.31a1.47,1.47,0,0,1-.06.71.21.21,0,0,1-.39-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.58,34.37a1.22,1.22,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.26.07a.41.41,0,0,1,.29-.16c.16,0,.17.22.19.32a1.35,1.35,0,0,1-.1.7.19.19,0,0,1-.26.11.2.2,0,0,1-.11-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.24,36.34a1.1,1.1,0,0,0,.25-.48c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.17.23.18.33a1.36,1.36,0,0,1-.13.7.2.2,0,0,1-.26.1.21.21,0,0,1-.11-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.84,37.52a1.14,1.14,0,0,0,.27-.47c0-.09,0-.19,0-.23s-.18,0-.27,0a.41.41,0,0,1,.3-.14c.16.06.16.23.17.34a1.37,1.37,0,0,1-.16.69.19.19,0,0,1-.27.09.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.35,38.9a1.41,1.41,0,0,0,.3-.46c0-.09.05-.19,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.24.14.34a1.32,1.32,0,0,1-.19.68.21.21,0,0,1-.28.08A.21.21,0,0,1,61.3,39,.12.12,0,0,1,61.35,38.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,40.26a1.07,1.07,0,0,0,.31-.45c0-.08.06-.18,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.25.14.35a1.46,1.46,0,0,1-.21.68.2.2,0,1,1-.35-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,40.17a1.15,1.15,0,0,0,.3-.46c0-.09,0-.19,0-.22s-.18,0-.27,0a.36.36,0,0,1,.31-.12c.16.07.14.24.15.34a1.44,1.44,0,0,1-.2.68.2.2,0,0,1-.27.08.21.21,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.32,39a1.12,1.12,0,0,0,.3-.47c0-.08,0-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.15.24.15.34a1.48,1.48,0,0,1-.18.69.21.21,0,0,1-.27.08.2.2,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.71,38.4a1.14,1.14,0,0,0,.27-.47c0-.09,0-.19,0-.23s-.18,0-.27.06a.38.38,0,0,1,.3-.15c.16.06.16.23.18.33a1.5,1.5,0,0,1-.15.7.19.19,0,0,1-.26.09.19.19,0,0,1-.1-.26A.43.43,0,0,1,57.71,38.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.92,37.09a1.09,1.09,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.16.23.18.33a1.46,1.46,0,0,1-.12.7.2.2,0,0,1-.27.1.19.19,0,0,1-.1-.26S57.9,37.1,57.92,37.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.63,37.33a1.08,1.08,0,0,0,.24-.49c0-.09,0-.19,0-.22s-.17,0-.26.07c.07-.07.14-.17.29-.16s.17.22.19.32a1.34,1.34,0,0,1-.11.7.2.2,0,0,1-.26.11.19.19,0,0,1-.11-.26A.16.16,0,0,1,56.63,37.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.93,36.41a1.14,1.14,0,0,0,.22-.5c0-.09,0-.2,0-.22s-.17,0-.26.07a.39.39,0,0,1,.29-.17c.17,0,.18.22.2.32a1.45,1.45,0,0,1-.09.7.19.19,0,0,1-.25.12.2.2,0,0,1-.12-.26A.08.08,0,0,1,55.93,36.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.88,35.38a1.17,1.17,0,0,0,.21-.51c0-.09,0-.19,0-.22s-.18,0-.26.08c.07-.07.13-.17.28-.18s.19.21.21.32a1.42,1.42,0,0,1-.06.7.21.21,0,1,1-.39-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.88,35.88a1.05,1.05,0,0,0,.22-.49c0-.09,0-.2,0-.22s-.18,0-.27.07c.08-.07.14-.17.29-.17s.18.21.2.32a1.45,1.45,0,0,1-.09.7.2.2,0,1,1-.37-.14A.13.13,0,0,1,56.88,35.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,37.15a1.22,1.22,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.26.08a.39.39,0,0,1,.29-.17c.16,0,.18.21.2.32a1.35,1.35,0,0,1-.1.7.2.2,0,0,1-.26.12.2.2,0,0,1-.11-.26A.16.16,0,0,1,55,37.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,38.44a1.2,1.2,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.22.19.32a1.46,1.46,0,0,1-.12.7.2.2,0,1,1-.37-.15A.43.43,0,0,1,55,38.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.09,39.46a1.1,1.1,0,0,0,.25-.48c0-.09,0-.2,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.17.22.18.33a1.42,1.42,0,0,1-.12.69.19.19,0,0,1-.26.11.2.2,0,0,1-.11-.26A.43.43,0,0,1,54.09,39.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.59,40.58a1.12,1.12,0,0,0,.27-.48c0-.09,0-.19,0-.22a.36.36,0,0,0-.27.06c.08-.07.15-.17.3-.15s.16.23.17.33a1.34,1.34,0,0,1-.14.69.19.19,0,0,1-.26.1.2.2,0,0,1-.1-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.16,41.55a1.18,1.18,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.16.3-.14s.16.23.17.33a1.44,1.44,0,0,1-.15.69.21.21,0,0,1-.27.1.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.1,41.79a1.23,1.23,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.17,0-.27,0c.09-.06.16-.15.31-.13s.15.23.16.33a1.47,1.47,0,0,1-.17.69.2.2,0,0,1-.27.09.2.2,0,0,1-.09-.27A.1.1,0,0,1,56.1,41.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.24,42.16a1.17,1.17,0,0,0,.29-.47c0-.09,0-.19,0-.22s-.18,0-.28,0c.09-.06.16-.15.31-.13s.16.23.16.33a1.36,1.36,0,0,1-.17.69.2.2,0,0,1-.27.09.19.19,0,0,1-.08-.27A.15.15,0,0,1,55.24,42.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.77,40a1.18,1.18,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.16.3-.14s.16.22.17.33a1.34,1.34,0,0,1-.14.69.2.2,0,0,1-.27.1.2.2,0,0,1-.09-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.56,36.07a1.13,1.13,0,0,0,.21-.5c0-.09,0-.2,0-.22s-.18,0-.26.08c.07-.07.13-.18.28-.18s.19.21.21.31a1.46,1.46,0,0,1-.07.71.19.19,0,0,1-.25.12.19.19,0,0,1-.13-.25A.16.16,0,0,1,54.56,36.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.38,32.33a1.07,1.07,0,0,0,.17-.52c0-.09,0-.19,0-.21s-.18,0-.26.1.12-.19.27-.2.2.19.24.29a1.55,1.55,0,0,1,0,.71.22.22,0,0,1-.25.15.21.21,0,0,1-.15-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.83,26.69a1.25,1.25,0,0,0,.11-.53c0-.09,0-.19-.08-.21s-.16.06-.24.13.1-.2.25-.23.22.17.26.27a1.25,1.25,0,0,1,.07.7A.19.19,0,0,1,57,27a.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.16,23.17a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c0-.09.09-.2.23-.24s.23.15.28.25a1.46,1.46,0,0,1,.1.7.19.19,0,0,1-.21.18.2.2,0,0,1-.19-.22A.19.19,0,0,1,56.16,23.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.75,19.69a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c0-.09.08-.2.23-.24s.23.16.28.25a1.46,1.46,0,0,1,.1.7.21.21,0,0,1-.22.19.2.2,0,0,1-.18-.22A.24.24,0,0,1,54.75,19.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.56,16.58a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.09-.21s-.16.07-.23.14.09-.2.24-.24.22.17.27.26a1.46,1.46,0,0,1,.09.7.2.2,0,0,1-.4,0A.19.19,0,0,1,53.56,16.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,12.82a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.07-.21s-.17.07-.24.14c0-.09.09-.2.24-.24s.22.17.27.26a1.52,1.52,0,0,1,.07.71.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.85,10.67a1.19,1.19,0,0,0,.12-.54c0-.09,0-.19-.08-.21s-.17.06-.24.13c.06-.08.1-.2.24-.23s.23.17.27.27a1.46,1.46,0,0,1,.07.7A.2.2,0,0,1,50,11a.19.19,0,0,1-.17-.22A.14.14,0,0,1,49.85,10.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.92,9A1.3,1.3,0,0,0,48,8.47c0-.09,0-.19-.07-.21s-.17.07-.25.13.1-.19.25-.23.22.17.26.27a1.3,1.3,0,0,1,.07.71.19.19,0,0,1-.22.17.22.22,0,0,1-.18-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.41,8a1,1,0,0,0,.11-.53c0-.09,0-.19-.07-.21s-.17.06-.24.13c.06-.08.1-.2.25-.23s.22.17.26.27a1.47,1.47,0,0,1,.07.7.2.2,0,0,1-.23.17A.19.19,0,0,1,46.39,8,.24.24,0,0,1,46.41,8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,7.86a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.07-.25.13.1-.19.25-.22.22.17.26.26a1.52,1.52,0,0,1,.07.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,44,7.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.23,7.87a1.12,1.12,0,0,0,.12-.53c0-.09,0-.19-.07-.21s-.17.06-.24.13c.05-.09.1-.2.24-.23s.22.17.27.27a1.61,1.61,0,0,1,.06.7.2.2,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,42.23,7.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.59,7.7a1.19,1.19,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13c.06-.08.1-.2.25-.23s.22.18.26.27a1.52,1.52,0,0,1,.06.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.14.14,0,0,1,40.59,7.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.1,8.11a1,1,0,0,0,.12-.53c0-.1,0-.2-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.17.26.27a1.48,1.48,0,0,1,.06.7.2.2,0,1,1-.4-.06A.19.19,0,0,1,39.1,8.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.16,9.14a1.13,1.13,0,0,0,.13-.54c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.08.11-.2.25-.22s.22.17.26.27a1.36,1.36,0,0,1,.06.7.2.2,0,0,1-.4-.06A.19.19,0,0,1,37.16,9.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.74,10.08a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.18.26.28a1.48,1.48,0,0,1,0,.7.2.2,0,1,1-.4-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.66,11a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.06-.25.12.1-.19.25-.22.22.18.26.27a1.53,1.53,0,0,1,0,.71.21.21,0,0,1-.23.17.21.21,0,0,1-.16-.24A.19.19,0,0,1,32.66,11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.5,12.25a1.09,1.09,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.52,1.52,0,0,1,0,.71.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23A.11.11,0,0,1,30.5,12.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.93,13.6a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.05-.25.12c.06-.08.11-.2.26-.22s.21.18.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.23.16.21.21,0,0,1-.16-.24A.11.11,0,0,1,27.93,13.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.56,15a1.27,1.27,0,0,0,.11-.54c0-.09,0-.19-.08-.2s-.17.06-.24.13c.06-.09.1-.2.24-.23s.23.17.27.26a1.52,1.52,0,0,1,.07.71.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.23A.11.11,0,0,1,25.56,15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.64,17.43a1.32,1.32,0,0,0,0-.55c0-.08-.07-.17-.13-.18s-.14.11-.2.2c0-.1.05-.22.18-.29s.26.1.33.18a1.42,1.42,0,0,1,.25.66.2.2,0,0,1-.4.06A.19.19,0,0,1,23.64,17.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.48,21a1.05,1.05,0,0,0-.2-.51c-.05-.08-.12-.15-.18-.14s-.1.15-.12.25c0-.11,0-.22.07-.33s.28,0,.37.07a1.65,1.65,0,0,1,.45.55.2.2,0,0,1-.1.27.2.2,0,0,1-.27-.09S21.49,21,21.48,21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.81,22.94a1.05,1.05,0,0,0-.28-.47c-.06-.07-.14-.13-.19-.1s-.08.16-.09.26a.41.41,0,0,1,0-.34c.14-.11.28,0,.38,0a1.45,1.45,0,0,1,.52.47.19.19,0,0,1-.05.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.8,25.1a1.14,1.14,0,0,0-.33-.44c-.07-.05-.16-.11-.21-.08s-.06.17-.06.27a.44.44,0,0,1,0-.34c.12-.12.27-.05.37,0a1.35,1.35,0,0,1,.58.41.19.19,0,0,1,0,.28.19.19,0,0,1-.28,0S17.81,25.12,17.8,25.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.05,27.52a1.29,1.29,0,0,0-.37-.41c-.07-.05-.16-.09-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.33s.26-.08.37-.06a1.53,1.53,0,0,1,.6.37.2.2,0,0,1,0,.28.22.22,0,0,1-.29,0A.16.16,0,0,1,16.05,27.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.58,29.05a1.11,1.11,0,0,0-.38-.4c-.07,0-.17-.09-.21-.06s0,.17,0,.27a.37.37,0,0,1-.05-.33c.11-.14.27-.08.37-.06a1.44,1.44,0,0,1,.62.35.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0A.21.21,0,0,1,15.58,29.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.61,30.07a1.08,1.08,0,0,0-.37-.4c-.08-.05-.17-.09-.22-.06s0,.18,0,.28a.48.48,0,0,1-.05-.34c.11-.13.27-.08.37-.06a1.46,1.46,0,0,1,.62.34.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.94,29.39a1.17,1.17,0,0,0-.37-.4c-.08,0-.17-.09-.22-.06s0,.17,0,.27c0-.1-.11-.2-.05-.33s.27-.08.37-.06a1.44,1.44,0,0,1,.62.35.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0S17,29.41,16.94,29.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.07,28.91a1.19,1.19,0,0,0-.37-.41c-.07-.05-.16-.1-.21-.07a.38.38,0,0,0,0,.27.4.4,0,0,1,0-.33.32.32,0,0,1,.37-.05,1.37,1.37,0,0,1,.61.36.22.22,0,0,1,0,.29.21.21,0,0,1-.29,0A.21.21,0,0,1,18.07,28.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.14,27.77a1.17,1.17,0,0,0-.36-.42c-.07-.05-.16-.1-.21-.06s-.05.17,0,.27c0-.1-.1-.2,0-.34s.27-.07.37,0a1.56,1.56,0,0,1,.6.38.21.21,0,0,1,0,.29.22.22,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.49,26.67a1.34,1.34,0,0,0-.34-.43c-.08,0-.17-.1-.21-.07s-.06.17-.05.27c0-.1-.1-.2,0-.34s.27-.06.38,0a1.45,1.45,0,0,1,.59.39.2.2,0,0,1,0,.28.2.2,0,0,1-.29,0S18.5,26.69,18.49,26.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.54,26.15a1.09,1.09,0,0,0-.35-.43c-.07,0-.16-.1-.21-.07s-.05.17-.05.27a.4.4,0,0,1,0-.34c.11-.13.27-.06.37,0a1.41,1.41,0,0,1,.59.4.2.2,0,1,1-.29.27S17.54,26.17,17.54,26.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,24.72a1.14,1.14,0,0,0-.34-.43c-.07-.06-.16-.11-.21-.08s-.06.17-.05.27c0-.1-.09-.2,0-.33a.32.32,0,0,1,.37,0,1.51,1.51,0,0,1,.59.4.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0A.25.25,0,0,1,16,24.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.06,22.85a1.18,1.18,0,0,0-.32-.44c-.07-.06-.16-.11-.21-.08s-.06.17-.06.27a.44.44,0,0,1,0-.34c.12-.12.28-.05.38,0a1.51,1.51,0,0,1,.57.42.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0A.43.43,0,0,1,14.06,22.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.37,20.15a1.11,1.11,0,0,0-.28-.47c-.07-.06-.15-.12-.2-.1s-.08.16-.09.26c0-.1-.06-.21,0-.33s.27,0,.37,0a1.39,1.39,0,0,1,.53.47.19.19,0,0,1-.05.28.21.21,0,0,1-.28-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.28,18.34a1.18,1.18,0,0,0-.24-.49c-.06-.07-.14-.14-.19-.12s-.09.15-.11.25a.4.4,0,0,1,0-.33c.14-.1.28,0,.37.05a1.34,1.34,0,0,1,.49.51.2.2,0,1,1-.34.2A.11.11,0,0,1,11.28,18.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.81,18.21a1.15,1.15,0,0,0-.25-.49c-.06-.07-.14-.14-.19-.12s-.09.16-.1.26a.42.42,0,0,1,0-.34c.14-.1.28,0,.38,0a1.5,1.5,0,0,1,.49.51.2.2,0,0,1-.07.28.21.21,0,0,1-.27-.07A.11.11,0,0,1,9.81,18.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9,17.45a1.1,1.1,0,0,0-.22-.5c-.06-.07-.14-.14-.19-.12s-.09.15-.11.25a.37.37,0,0,1,.06-.33c.14-.1.28,0,.37.06a1.32,1.32,0,0,1,.47.52.2.2,0,0,1-.08.28A.21.21,0,0,1,9,17.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.1,14.47a1.2,1.2,0,0,0-.1-.54c0-.08-.1-.16-.15-.16s-.13.12-.17.22c0-.1,0-.22.13-.31s.27.07.35.14a1.55,1.55,0,0,1,.34.62.2.2,0,0,1-.39.11A.25.25,0,0,1,10.1,14.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.27,15.35a1.13,1.13,0,0,0-.15-.53c0-.07-.11-.15-.16-.14s-.12.13-.15.22c0-.1,0-.22.11-.31s.27,0,.35.11a1.39,1.39,0,0,1,.39.59.2.2,0,0,1-.12.26.21.21,0,0,1-.26-.12A.22.22,0,0,1,9.27,15.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.24,15.57a1.06,1.06,0,0,0-.15-.52c0-.08-.11-.16-.16-.15s-.12.13-.15.22c0-.1,0-.22.11-.31s.27,0,.35.11a1.41,1.41,0,0,1,.4.59.22.22,0,0,1-.12.26.2.2,0,0,1-.26-.12A.24.24,0,0,1,10.24,15.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.22,16.38A1.22,1.22,0,0,0,9,15.86c0-.07-.12-.15-.17-.13s-.11.14-.14.24c0-.11,0-.22.09-.33s.28,0,.36.08a1.64,1.64,0,0,1,.44.56.2.2,0,0,1-.37.17A.19.19,0,0,1,9.22,16.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.27,14.26a1.07,1.07,0,0,0-.08-.55c0-.08-.09-.17-.14-.16s-.14.11-.18.2c0-.1,0-.22.15-.3s.26.08.34.16a1.55,1.55,0,0,1,.31.63.2.2,0,0,1-.15.24.19.19,0,0,1-.24-.15A.17.17,0,0,1,11.27,14.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.64,16.94a1.16,1.16,0,0,0-.2-.51c-.06-.08-.13-.15-.18-.14s-.11.15-.13.25c0-.11,0-.22.08-.33s.28,0,.36.08a1.3,1.3,0,0,1,.45.55.2.2,0,0,1-.09.27.21.21,0,0,1-.27-.1A.19.19,0,0,1,10.64,16.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.29,15.58a1.1,1.1,0,0,0-.13-.54c0-.08-.1-.16-.15-.15s-.13.13-.16.22A.37.37,0,0,1,12,14.8c.16-.07.27,0,.35.12a1.49,1.49,0,0,1,.37.61.2.2,0,0,1-.39.12A.17.17,0,0,1,12.29,15.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.53,17a1.18,1.18,0,0,0-.19-.51c-.05-.08-.12-.15-.17-.14s-.11.14-.14.24c0-.11,0-.22.09-.33s.28,0,.37.09a1.34,1.34,0,0,1,.42.56.2.2,0,0,1-.1.27.21.21,0,0,1-.27-.1A.22.22,0,0,1,12.53,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.41,14.78a1,1,0,0,0-.07-.54c0-.09-.08-.18-.13-.18s-.14.12-.19.2c0-.1,0-.22.16-.29s.26.09.33.16a1.38,1.38,0,0,1,.29.65.2.2,0,0,1-.16.24.21.21,0,0,1-.23-.16A.11.11,0,0,1,14.41,14.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.36,16.74a1.1,1.1,0,0,0-.15-.53c-.05-.07-.11-.15-.16-.14s-.12.13-.16.22A.39.39,0,0,1,14,16c.16-.08.28,0,.36.11a1.39,1.39,0,0,1,.39.59.19.19,0,0,1-.12.25.2.2,0,0,1-.26-.11S14.36,16.76,14.36,16.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.63,15.33a1.17,1.17,0,0,0-.08-.54c0-.08-.08-.17-.13-.17s-.14.11-.19.2c0-.1,0-.22.16-.3s.26.09.33.17a1.32,1.32,0,0,1,.3.64.2.2,0,0,1-.15.24.21.21,0,0,1-.24-.16A.11.11,0,0,1,15.63,15.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.21,19.67a1.09,1.09,0,0,0-.26-.48c-.06-.07-.14-.13-.19-.11s-.08.15-.1.25a.4.4,0,0,1,0-.33c.14-.11.28,0,.38,0a1.49,1.49,0,0,1,.5.5.21.21,0,0,1-.06.28.2.2,0,0,1-.28-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.86,21a1.15,1.15,0,0,0-.28-.47c-.07-.07-.15-.13-.2-.1s-.08.16-.09.26c0-.11-.06-.22,0-.34s.28,0,.37,0a1.48,1.48,0,0,1,.53.47.2.2,0,0,1,0,.28.2.2,0,0,1-.28-.05A.11.11,0,0,1,15.86,21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.78,18.12a1.26,1.26,0,0,0-.15-.53c-.05-.07-.12-.15-.17-.14s-.12.13-.15.23c0-.11,0-.22.11-.32s.27,0,.36.11a1.42,1.42,0,0,1,.39.58.2.2,0,0,1-.12.26.2.2,0,0,1-.26-.11S18.78,18.14,18.78,18.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.32,16.47a1.09,1.09,0,0,0-.11-.54c0-.08-.1-.16-.15-.16s-.13.13-.17.22c0-.1,0-.22.14-.31s.27.07.34.14a1.34,1.34,0,0,1,.34.62.2.2,0,1,1-.39.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.91,20.72a1.14,1.14,0,0,0-.26-.48c-.06-.07-.15-.13-.2-.11s-.08.15-.09.25c0-.1-.06-.21,0-.33s.28,0,.38,0a1.4,1.4,0,0,1,.51.49.21.21,0,0,1-.06.28.21.21,0,0,1-.28-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.37,19.21a1.13,1.13,0,0,0-.2-.51c-.05-.07-.12-.14-.18-.13s-.1.15-.12.24a.39.39,0,0,1,.07-.32c.15-.09.28,0,.37.07a1.65,1.65,0,0,1,.45.55.21.21,0,0,1-.1.27.2.2,0,0,1-.27-.09S18.38,19.24,18.37,19.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.57,17.25a1.06,1.06,0,0,0-.15-.52c0-.08-.11-.16-.16-.15s-.12.13-.15.22c0-.1,0-.22.11-.31s.27,0,.36.11a1.49,1.49,0,0,1,.39.59.22.22,0,0,1-.12.26.2.2,0,0,1-.26-.12A.24.24,0,0,1,16.57,17.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.65,15.73a1.05,1.05,0,0,0-.08-.54c0-.08-.08-.17-.14-.17s-.13.12-.18.21c0-.1,0-.22.15-.3s.27.08.34.16a1.34,1.34,0,0,1,.31.63.2.2,0,0,1-.15.24.19.19,0,0,1-.24-.15A.19.19,0,0,1,16.65,15.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.9,14.61a1.16,1.16,0,0,0,0-.55c0-.09-.07-.18-.12-.18s-.15.1-.2.18c0-.09,0-.21.18-.28s.25.11.32.19a1.41,1.41,0,0,1,.24.66.2.2,0,0,1-.17.23.21.21,0,0,1-.23-.18A.17.17,0,0,1,16.9,14.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.94,14.25a1.13,1.13,0,0,0,0-.55c0-.09-.07-.19-.12-.19s-.15.09-.21.17c0-.09.06-.21.2-.27s.25.12.31.21a1.56,1.56,0,0,1,.21.67.21.21,0,0,1-.41,0S17.94,14.27,17.94,14.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.77,18.53a1.22,1.22,0,0,0-.22-.5c-.05-.08-.13-.15-.18-.13s-.1.15-.12.25c0-.11,0-.22.07-.33s.28,0,.37.06a1.44,1.44,0,0,1,.46.54.2.2,0,1,1-.36.18A.19.19,0,0,1,14.77,18.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.72,18.41a1.16,1.16,0,0,0-.18-.51c-.05-.08-.12-.16-.17-.14s-.11.14-.14.23a.37.37,0,0,1,.09-.32c.15-.08.28,0,.36.09a1.56,1.56,0,0,1,.43.57.2.2,0,0,1-.11.26.19.19,0,0,1-.26-.1A.19.19,0,0,1,17.72,18.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.44,17.68a1.2,1.2,0,0,0-.18-.52c0-.08-.12-.15-.17-.14s-.11.14-.14.24c0-.11,0-.22.09-.33s.28,0,.37.1a1.39,1.39,0,0,1,.42.57.2.2,0,0,1-.11.26.21.21,0,0,1-.27-.11A.17.17,0,0,1,15.44,17.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.7,18.23a1.22,1.22,0,0,0-.19-.52c-.05-.07-.12-.15-.17-.14s-.11.15-.14.24c0-.1,0-.22.09-.32s.28,0,.36.08a1.46,1.46,0,0,1,.43.57.19.19,0,0,1-.1.26.2.2,0,0,1-.27-.1A.17.17,0,0,1,16.7,18.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,19.27a1.17,1.17,0,0,0-.22-.5c-.06-.08-.13-.15-.19-.13s-.1.15-.12.25c0-.11,0-.22.07-.33s.28,0,.37.06a1.41,1.41,0,0,1,.46.53.2.2,0,1,1-.35.19A.19.19,0,0,1,17,19.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.22,19.92a1.18,1.18,0,0,0-.24-.49c-.06-.07-.14-.13-.19-.12s-.09.16-.11.26a.39.39,0,0,1,.05-.34c.14-.1.28,0,.37.05a1.49,1.49,0,0,1,.5.5.22.22,0,0,1-.07.28.2.2,0,0,1-.28-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.75,14.87a1.11,1.11,0,0,0,0-.54c0-.09-.07-.18-.12-.19s-.15.1-.2.19a.4.4,0,0,1,.18-.29c.17,0,.26.12.32.2a1.45,1.45,0,0,1,.23.67.2.2,0,0,1-.4,0A.25.25,0,0,1,18.75,14.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.71,15.34a1.06,1.06,0,0,0-.05-.55c0-.08-.08-.17-.13-.17s-.14.11-.19.19c0-.1,0-.22.16-.29s.26.1.33.17a1.46,1.46,0,0,1,.28.66.2.2,0,0,1-.4.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.79,17.36a1.11,1.11,0,0,0-.14-.53c0-.08-.1-.16-.16-.15s-.12.13-.15.23c0-.11,0-.23.11-.32s.28.05.36.12a1.58,1.58,0,0,1,.38.6.2.2,0,0,1-.13.25.2.2,0,0,1-.26-.12S17.79,17.39,17.79,17.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.85,16.62a1.22,1.22,0,0,0-.13-.54c0-.08-.11-.16-.16-.15s-.12.13-.16.22a.37.37,0,0,1,.12-.31.31.31,0,0,1,.35.12,1.39,1.39,0,0,1,.37.61.19.19,0,0,1-.13.25.19.19,0,0,1-.25-.13A.17.17,0,0,1,15.85,16.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.27,19.65a1.07,1.07,0,0,0-.24-.49c-.06-.07-.14-.14-.19-.12s-.09.16-.11.26a.42.42,0,0,1,0-.34c.14-.1.28,0,.37,0a1.63,1.63,0,0,1,.5.51.22.22,0,0,1-.07.28.2.2,0,0,1-.28-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.85,17.07a1.09,1.09,0,0,0-.11-.54c0-.08-.1-.16-.15-.16s-.13.13-.17.22c0-.1,0-.22.13-.31s.27.07.35.14a1.36,1.36,0,0,1,.35.61.2.2,0,1,1-.39.11A.17.17,0,0,1,18.85,17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.85,18.75a1.22,1.22,0,0,0-.22-.5c-.05-.08-.12-.15-.18-.13s-.1.14-.12.24A.41.41,0,0,1,15.4,18c.15-.09.28,0,.37.07a1.44,1.44,0,0,1,.46.54.2.2,0,1,1-.36.18A.19.19,0,0,1,15.85,18.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.9,20.69a1.28,1.28,0,0,0-.28-.47c-.07-.06-.15-.13-.2-.1s-.08.16-.09.26c0-.1-.06-.22,0-.34s.28,0,.37,0a1.48,1.48,0,0,1,.53.47.2.2,0,0,1,0,.28.2.2,0,0,1-.28,0A.09.09,0,0,1,14.9,20.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.37,16.13a1.09,1.09,0,0,0-.08-.55c0-.08-.09-.17-.14-.17s-.14.12-.18.21c0-.1,0-.22.15-.3s.26.08.34.16a1.55,1.55,0,0,1,.31.63.21.21,0,0,1-.16.24.2.2,0,0,1-.24-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.73,20.05a1.15,1.15,0,0,0-.23-.49c-.06-.07-.14-.14-.19-.12s-.09.15-.11.25a.4.4,0,0,1,.05-.33c.15-.1.28,0,.38.05a1.48,1.48,0,0,1,.48.51.21.21,0,0,1-.08.28.2.2,0,0,1-.27-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.52,15.86a1.27,1.27,0,0,0-.05-.54c0-.09-.08-.18-.14-.18s-.14.11-.19.2c0-.1,0-.22.17-.29s.26.09.33.17a1.48,1.48,0,0,1,.28.65.2.2,0,0,1-.17.23.2.2,0,0,1-.23-.16A.11.11,0,0,1,19.52,15.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.13,16.65a1.14,1.14,0,0,0-.06-.54c0-.09-.09-.18-.14-.18s-.14.11-.19.2c0-.1,0-.22.16-.29s.27.09.34.17a1.37,1.37,0,0,1,.28.65.2.2,0,0,1-.16.23.21.21,0,0,1-.24-.16A.22.22,0,0,1,21.13,16.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.08,15.85a1.13,1.13,0,0,0,0-.55c0-.08-.06-.18-.12-.18s-.15.09-.2.18c0-.1,0-.22.19-.28s.25.12.31.2a1.52,1.52,0,0,1,.22.68.21.21,0,0,1-.41,0S22.08,15.88,22.08,15.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.86,19a1.06,1.06,0,0,0-.15-.52c-.05-.08-.11-.16-.17-.15s-.11.13-.15.23a.42.42,0,0,1,.11-.32c.16-.07.28.05.36.11a1.39,1.39,0,0,1,.39.59.2.2,0,0,1-.12.26.21.21,0,0,1-.26-.12S20.86,19,20.86,19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.3,28.46a1.11,1.11,0,0,0-.38-.4c-.07,0-.17-.09-.21-.06s0,.18,0,.28a.39.39,0,0,1,0-.34c.11-.13.27-.08.37-.06a1.44,1.44,0,0,1,.62.35.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.62,35.57a1.13,1.13,0,0,0-.4-.38c-.07,0-.17-.09-.21,0s0,.17,0,.27a.4.4,0,0,1-.06-.33c.1-.14.26-.09.36-.08a1.4,1.4,0,0,1,.63.32.21.21,0,0,1,0,.29.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10,39.18a1.1,1.1,0,0,0-.39-.38c-.08,0-.17-.08-.22,0a.43.43,0,0,0,0,.28c0-.1-.12-.19-.07-.33s.27-.1.37-.09a1.4,1.4,0,0,1,.63.32.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.09,40.92a1.18,1.18,0,0,0-.39-.38c-.08,0-.17-.08-.22,0s0,.18,0,.28c0-.1-.12-.19-.07-.33s.26-.1.37-.09a1.4,1.4,0,0,1,.63.32.21.21,0,0,1,0,.29.22.22,0,0,1-.29,0S10.11,40.94,10.09,40.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.78,42.59a1.12,1.12,0,0,0-.39-.39c-.08,0-.17-.08-.22,0a.4.4,0,0,0,0,.27.4.4,0,0,1-.07-.33c.1-.14.27-.1.37-.08a1.4,1.4,0,0,1,.63.32.19.19,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.54,41.15a1.33,1.33,0,0,0-.4-.38c-.08,0-.17-.08-.21-.05s0,.18,0,.28c0-.1-.12-.19-.07-.33s.27-.1.37-.08a1.38,1.38,0,0,1,.63.31.21.21,0,0,1,0,.29.21.21,0,0,1-.29,0S11.55,41.17,11.54,41.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.21,34.86a1.42,1.42,0,0,0-.39-.38c-.08,0-.18-.09-.22,0s0,.17,0,.27a.4.4,0,0,1-.06-.33c.1-.14.26-.09.37-.08a1.54,1.54,0,0,1,.63.33.2.2,0,0,1,0,.28.2.2,0,0,1-.29,0A.09.09,0,0,1,14.21,34.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.88,31a1.19,1.19,0,0,0-.38-.4c-.07-.05-.17-.09-.21-.05s0,.17,0,.27c0-.1-.11-.19-.05-.33a.33.33,0,0,1,.37-.07,1.52,1.52,0,0,1,.62.34.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0A.15.15,0,0,1,16.88,31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.14,24.8a1.08,1.08,0,0,0-.3-.45c-.07-.06-.16-.12-.21-.09a.38.38,0,0,0-.07.26.38.38,0,0,1,0-.33c.12-.12.27-.05.37,0a1.44,1.44,0,0,1,.56.44.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0A.25.25,0,0,1,20.14,24.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.7,21.28a1.22,1.22,0,0,0-.19-.52c-.05-.07-.12-.15-.17-.13s-.11.14-.14.24a.39.39,0,0,1,.09-.33c.15-.08.27,0,.36.09a1.51,1.51,0,0,1,.44.55.21.21,0,0,1-.1.27.2.2,0,0,1-.27-.1A.19.19,0,0,1,22.7,21.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.58,18.36a1.16,1.16,0,0,0,0-.55c0-.09-.08-.18-.13-.18s-.14.11-.2.2c0-.1,0-.22.17-.29s.26.09.33.17a1.46,1.46,0,0,1,.28.66.21.21,0,0,1-.17.23.2.2,0,0,1-.23-.17A.09.09,0,0,1,24.58,18.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.87,16.27a1.18,1.18,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.17.07-.23.15c.05-.09.08-.21.22-.25s.24.15.29.24a1.46,1.46,0,0,1,.12.7.21.21,0,0,1-.41,0S25.86,16.3,25.87,16.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.09,15.9a1.12,1.12,0,0,0,.1-.53c0-.1,0-.2-.07-.21s-.17.07-.24.13.09-.2.24-.23.22.17.27.26a1.47,1.47,0,0,1,.08.7.21.21,0,0,1-.23.18.19.19,0,0,1-.17-.22A.19.19,0,0,1,27.09,15.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.08,15a1.25,1.25,0,0,0,.12-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.18.26.27a1.52,1.52,0,0,1,.05.71.2.2,0,0,1-.4-.07A.11.11,0,0,1,27.08,15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.54,17.8a1.14,1.14,0,0,0,0-.55c0-.09-.06-.18-.11-.19s-.15.1-.21.18c0-.09.05-.21.19-.27s.25.12.31.2a1.52,1.52,0,0,1,.22.68.21.21,0,0,1-.19.21.2.2,0,0,1-.22-.19S25.54,17.82,25.54,17.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.81,26.25a1.34,1.34,0,0,0-.32-.45c-.07-.05-.16-.11-.21-.08s-.07.17-.06.27a.37.37,0,0,1,0-.34c.12-.12.28,0,.37,0a1.37,1.37,0,0,1,.57.43.2.2,0,0,1,0,.28.22.22,0,0,1-.29,0A.21.21,0,0,1,20.81,26.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.23,33.78a1,1,0,0,0-.39-.39c-.07,0-.17-.09-.21-.06s0,.18,0,.28c0-.1-.12-.19-.06-.33s.26-.1.37-.08a1.45,1.45,0,0,1,.62.33.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.93,37.83a1.29,1.29,0,0,0-.39-.38c-.08,0-.18-.08-.22-.05s0,.18,0,.28c0-.1-.11-.19-.06-.33a.32.32,0,0,1,.36-.08,1.49,1.49,0,0,1,.64.32.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0A.15.15,0,0,1,14.93,37.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.07,41.19a1.21,1.21,0,0,0-.39-.39c-.08,0-.18-.08-.22,0s0,.17,0,.27a.4.4,0,0,1-.06-.33.32.32,0,0,1,.36-.08,1.49,1.49,0,0,1,.64.32.2.2,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.37,43.56a1.18,1.18,0,0,0-.39-.38c-.08,0-.17-.08-.21,0s0,.18,0,.28c0-.1-.12-.19-.07-.33s.27-.1.37-.09a1.4,1.4,0,0,1,.63.32.21.21,0,0,1,0,.29.21.21,0,0,1-.29,0S13.39,43.58,13.37,43.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14,44.69a1.18,1.18,0,0,0-.39-.38c-.08-.05-.18-.08-.22-.05s0,.18,0,.27a.39.39,0,0,1-.06-.33c.1-.14.26-.09.36-.08a1.49,1.49,0,0,1,.64.32.21.21,0,0,1,0,.29.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.12,44.71a1.22,1.22,0,0,0-.4-.38c-.08,0-.17-.08-.21,0s0,.18,0,.27c0-.09-.12-.18-.06-.33s.26-.09.36-.08a1.4,1.4,0,0,1,.63.32.21.21,0,0,1,0,.29.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.27,43a1.25,1.25,0,0,0-.4-.39c-.08,0-.17-.08-.21,0s0,.17,0,.27a.4.4,0,0,1-.07-.33c.1-.14.27-.1.37-.08a1.4,1.4,0,0,1,.63.32.19.19,0,0,1,0,.28.2.2,0,0,1-.29,0A.1.1,0,0,1,15.27,43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.51,40.24a1.32,1.32,0,0,0-.39-.39c-.08,0-.18-.08-.22,0s0,.17,0,.27a.4.4,0,0,1-.06-.33.32.32,0,0,1,.36-.08,1.49,1.49,0,0,1,.64.32.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.1,33a1.29,1.29,0,0,0-.37-.41c-.08,0-.17-.09-.21-.06s-.05.17,0,.27a.4.4,0,0,1,0-.33c.11-.14.27-.08.37-.06a1.43,1.43,0,0,1,.61.36.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0A.21.21,0,0,1,21.1,33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.56,28a1.18,1.18,0,0,0-.3-.46c-.07-.06-.16-.12-.2-.09s-.08.16-.08.26a.38.38,0,0,1,0-.33c.12-.12.27,0,.37,0a1.57,1.57,0,0,1,.56.45.21.21,0,0,1,0,.28.2.2,0,0,1-.28,0A.21.21,0,0,1,23.56,28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.16,24.77a1.1,1.1,0,0,0-.22-.5c-.05-.07-.13-.14-.18-.13s-.1.15-.12.25a.41.41,0,0,1,.07-.33c.14-.09.28,0,.37.06a1.44,1.44,0,0,1,.46.54.2.2,0,0,1-.08.27.21.21,0,0,1-.28-.09S25.17,24.79,25.16,24.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.8,23.31a1.07,1.07,0,0,0-.17-.52c-.05-.08-.11-.16-.17-.15s-.11.14-.14.24a.43.43,0,0,1,.1-.33c.15-.07.27,0,.36.1a1.56,1.56,0,0,1,.41.58.21.21,0,0,1-.11.27.21.21,0,0,1-.27-.12A.17.17,0,0,1,25.8,23.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.49,22.58a1.06,1.06,0,0,0-.13-.53c0-.08-.1-.17-.16-.16s-.12.13-.16.22c0-.1,0-.22.13-.31s.27.06.35.12a1.56,1.56,0,0,1,.37.61.2.2,0,0,1-.39.12A.17.17,0,0,1,26.49,22.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.22,21.2a1.33,1.33,0,0,0-.07-.55c0-.08-.09-.17-.14-.17s-.14.11-.19.2c0-.1,0-.22.16-.3s.27.09.34.17a1.39,1.39,0,0,1,.29.64.21.21,0,0,1-.16.24.2.2,0,0,1-.23-.16A.09.09,0,0,1,27.22,21.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.26,20.24a1.27,1.27,0,0,0,0-.55c0-.09-.07-.18-.12-.19s-.15.1-.21.18c0-.09.06-.21.19-.27s.26.12.32.2a1.51,1.51,0,0,1,.21.68.19.19,0,0,1-.19.21.19.19,0,0,1-.21-.18A.19.19,0,0,1,28.26,20.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.7,19.33a1.25,1.25,0,0,0,0-.55c0-.09-.05-.19-.1-.19s-.16.08-.22.16c0-.09.07-.21.21-.26s.24.13.3.22a1.53,1.53,0,0,1,.17.69.2.2,0,0,1-.2.2.2.2,0,0,1-.21-.2A.19.19,0,0,1,28.7,19.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.76,19a1.21,1.21,0,0,0,.07-.55c0-.09,0-.19-.09-.2s-.17.08-.23.15c0-.09.08-.2.22-.25s.24.15.29.24a1.59,1.59,0,0,1,.13.7.21.21,0,0,1-.22.19.2.2,0,0,1-.19-.21A.19.19,0,0,1,29.76,19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.6,18.09a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.16.06-.24.13c.06-.08.1-.2.25-.23s.22.16.26.26a1.46,1.46,0,0,1,.09.7.22.22,0,0,1-.23.18.21.21,0,0,1-.15-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.05,17a1.13,1.13,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.18.25.28a1.37,1.37,0,0,1,0,.7.2.2,0,0,1-.24.16A.2.2,0,0,1,32,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.3,15.67a1.22,1.22,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17.05-.25.12c.06-.08.11-.2.26-.22s.21.18.25.28a1.53,1.53,0,0,1,0,.71.2.2,0,1,1-.4-.08A.21.21,0,0,1,34.3,15.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.56,14.09a1.3,1.3,0,0,0,.14-.54c0-.09,0-.19-.07-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.4,1.4,0,0,1,0,.71.2.2,0,0,1-.39-.07A.14.14,0,0,1,38.56,14.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.51,13.58a1.12,1.12,0,0,0,.13-.53c0-.1,0-.2-.08-.21s-.16.06-.24.12.1-.19.25-.22.22.17.26.27a1.48,1.48,0,0,1,.06.7.2.2,0,1,1-.4-.06A.19.19,0,0,1,43.51,13.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.42,13.74a1.12,1.12,0,0,0,.12-.53c0-.09,0-.19-.08-.21s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.27a1.46,1.46,0,0,1,.07.7.2.2,0,0,1-.23.17.21.21,0,0,1-.15-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.38,14.36a1.12,1.12,0,0,0,.11-.53c0-.1,0-.2-.08-.21s-.17.06-.24.13c.06-.08.09-.2.24-.23s.23.17.27.26a1.36,1.36,0,0,1,.08.7.2.2,0,1,1-.4,0A.19.19,0,0,1,51.38,14.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.92,13.73a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.16.06-.24.13.1-.2.25-.23.22.16.26.26a1.35,1.35,0,0,1,.09.7.21.21,0,0,1-.23.18.21.21,0,0,1-.15-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.25,13.65a1.27,1.27,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c0-.09.09-.21.24-.24s.22.16.27.26a1.46,1.46,0,0,1,.09.7.22.22,0,0,1-.23.18.21.21,0,0,1-.15-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.07,13.31a1.33,1.33,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c0-.09.09-.2.24-.24s.22.17.27.26a1.46,1.46,0,0,1,.09.7.22.22,0,0,1-.23.18.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.42,12.75a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.07-.21s-.17.07-.24.14c.05-.09.09-.2.24-.24s.22.17.27.26a1.49,1.49,0,0,1,.08.7.21.21,0,0,1-.23.18.19.19,0,0,1-.17-.22A.14.14,0,0,1,65.42,12.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.09,11.89a1.19,1.19,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.25-.24s.22.17.26.27a1.35,1.35,0,0,1,.08.7.2.2,0,0,1-.23.17.19.19,0,0,1-.17-.22A.14.14,0,0,1,68.09,11.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.63,10.75a1.19,1.19,0,0,0,.12-.54c0-.09,0-.19-.08-.21s-.16.06-.24.13c.06-.08.1-.2.25-.23s.22.17.26.27a1.4,1.4,0,0,1,.06.71.19.19,0,0,1-.22.17.22.22,0,0,1-.18-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.64,10.5a1.09,1.09,0,0,0,.13-.53c0-.09,0-.19-.06-.21s-.17.06-.25.12.1-.19.25-.22.22.18.26.28a1.52,1.52,0,0,1,0,.71.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.75,11a1.3,1.3,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.18.26.28a1.48,1.48,0,0,1,.05.7.2.2,0,0,1-.4-.06A.09.09,0,0,1,71.75,11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.56,11.41a1.1,1.1,0,0,0,.15-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.19.25.29a1.49,1.49,0,0,1,0,.7.21.21,0,0,1-.24.16.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.46,13.25a1.15,1.15,0,0,0,.19-.52c0-.09,0-.19,0-.21s-.17,0-.26.09c.07-.08.13-.18.28-.19s.19.2.22.3a1.44,1.44,0,0,1,0,.71.19.19,0,0,1-.25.13.2.2,0,0,1-.14-.25S75.44,13.26,75.46,13.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.83,14.6a1,1,0,0,0,.2-.51c0-.09,0-.19,0-.21s-.18,0-.26.09c.07-.08.13-.18.28-.19s.19.2.21.31a1.3,1.3,0,0,1,0,.7.2.2,0,0,1-.38-.12A.21.21,0,0,1,74.83,14.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.53,12.39a1.23,1.23,0,0,0,.17-.52c0-.09,0-.19,0-.22s-.18,0-.26.11.12-.19.27-.2.2.19.23.29a1.32,1.32,0,0,1,0,.71.19.19,0,0,1-.24.14.2.2,0,0,1-.15-.24A.16.16,0,0,1,74.53,12.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.79,13.47a1.26,1.26,0,0,0,.15-.53c0-.09,0-.19,0-.21s-.18,0-.26.11c.07-.08.12-.19.27-.21s.2.19.24.28a1.42,1.42,0,0,1,0,.71.21.21,0,0,1-.24.16.2.2,0,0,1-.15-.24A.09.09,0,0,1,72.79,13.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.11,15.24a1.05,1.05,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.21.19.31a1.47,1.47,0,0,1-.09.71.2.2,0,0,1-.26.11.2.2,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.13,12.15a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.06-.25.12.11-.19.25-.22.22.18.26.27a1.4,1.4,0,0,1,0,.71.2.2,0,1,1-.39-.07A.19.19,0,0,1,71.13,12.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.42,13.54A1.24,1.24,0,0,0,74.6,13c0-.09,0-.2,0-.22s-.17,0-.25.1c.07-.08.12-.18.27-.2s.2.2.23.3a1.44,1.44,0,0,1,0,.71.19.19,0,0,1-.24.14.21.21,0,0,1-.15-.25S74.4,13.55,74.42,13.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.6,12.25a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.18.24.28a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.23.16.21.21,0,0,1-.16-.24A.09.09,0,0,1,72.6,12.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.87,14.25a1.21,1.21,0,0,0,.22-.5c0-.09,0-.19,0-.22s-.17,0-.26.08c.07-.07.14-.18.29-.18s.18.21.2.31a1.34,1.34,0,0,1-.07.71.19.19,0,0,1-.25.12.2.2,0,0,1-.13-.25A.43.43,0,0,1,75.87,14.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,12.9a1.1,1.1,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.41,1.41,0,0,1,0,.71.21.21,0,0,1-.24.16A.21.21,0,0,1,72,13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.5,12.77a1.23,1.23,0,0,0,.17-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.19.24.29a1.54,1.54,0,0,1,0,.71.22.22,0,0,1-.24.15.22.22,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.73,14a1.24,1.24,0,0,0,.18-.52c0-.09,0-.19,0-.22s-.17.05-.26.11c.07-.08.12-.19.27-.2s.21.19.24.29a1.43,1.43,0,0,1,0,.71.19.19,0,0,1-.24.14.19.19,0,0,1-.15-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.83,13.45a1,1,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.18.25.28a1.26,1.26,0,0,1,0,.7.2.2,0,0,1-.23.17.21.21,0,0,1-.16-.24A.19.19,0,0,1,70.83,13.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70,12.86a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.08-.21s-.16.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.27a1.52,1.52,0,0,1,.06.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,70,12.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.76,17.74a1.18,1.18,0,0,0,.24-.49c0-.09,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.17s.17.22.19.33a1.47,1.47,0,0,1-.11.7.2.2,0,0,1-.26.11.21.21,0,0,1-.12-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.46,18.74a1.31,1.31,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.06.15-.16.3-.14s.16.23.17.33a1.32,1.32,0,0,1-.14.69.2.2,0,0,1-.27.1.21.21,0,0,1-.1-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.64,18.07a1.1,1.1,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.26.08a.39.39,0,0,1,.29-.17c.16,0,.18.21.2.32a1.45,1.45,0,0,1-.09.7.2.2,0,0,1-.26.12.21.21,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.45,16.4a1.1,1.1,0,0,0,.16-.52c0-.09,0-.2,0-.22s-.17.05-.25.11.11-.19.26-.21.21.19.24.29a1.54,1.54,0,0,1,0,.71.2.2,0,0,1-.24.15.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.35,19.11a1.2,1.2,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.26.06c.07-.06.14-.16.29-.15s.17.22.19.32a1.46,1.46,0,0,1-.12.7.22.22,0,0,1-.27.11.2.2,0,0,1-.1-.27S74.33,19.13,74.35,19.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.08,16.81a1.21,1.21,0,0,0,.22-.5c0-.09,0-.19,0-.22s-.18,0-.27.08c.08-.07.14-.17.29-.18s.18.22.21.32a1.43,1.43,0,0,1-.08.7.2.2,0,0,1-.25.13.21.21,0,0,1-.13-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74,15.58a1.12,1.12,0,0,0,.2-.51c0-.09,0-.19,0-.21s-.17,0-.26.09c.07-.08.13-.18.28-.19s.19.2.22.3a1.45,1.45,0,0,1,0,.71.2.2,0,0,1-.38-.12A.21.21,0,0,1,74,15.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75,17a1.08,1.08,0,0,0,.24-.49c0-.09,0-.2,0-.22s-.18,0-.27.07c.08-.07.14-.17.29-.17s.18.22.2.32a1.44,1.44,0,0,1-.1.7A.2.2,0,0,1,75,17,.43.43,0,0,1,75,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.51,16.4a1.23,1.23,0,0,0,.17-.52c0-.09,0-.19-.05-.22s-.18.05-.26.11.12-.19.27-.2.2.19.23.29a1.32,1.32,0,0,1,0,.71.19.19,0,0,1-.24.14.2.2,0,0,1-.15-.24A.16.16,0,0,1,71.51,16.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.11,15.43a1.23,1.23,0,0,0,.17-.52c0-.1,0-.2-.06-.22s-.17.05-.25.11c.07-.08.12-.19.27-.21s.2.2.23.29a1.42,1.42,0,0,1,0,.71.21.21,0,0,1-.25.15.21.21,0,0,1-.15-.24A.16.16,0,0,1,72.11,15.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.08,16.62a1.33,1.33,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.06.15-.16.3-.15s.17.22.19.32a1.44,1.44,0,0,1-.12.7.22.22,0,0,1-.27.11.21.21,0,0,1-.11-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.18,17.38a1.19,1.19,0,0,0,.18-.52c0-.09,0-.19,0-.21s-.17,0-.25.09.12-.18.27-.19.2.2.23.3a1.4,1.4,0,0,1,0,.7.19.19,0,0,1-.24.14.2.2,0,0,1-.15-.24A.16.16,0,0,1,71.18,17.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,16.15a1,1,0,0,0,.19-.51c0-.09,0-.19,0-.22s-.17,0-.26.1c.07-.08.13-.18.28-.19s.19.2.22.3a1.34,1.34,0,0,1,0,.71.2.2,0,0,1-.38-.12A.21.21,0,0,1,73,16.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.9,18.58a1.09,1.09,0,0,0,.22-.5c0-.09,0-.19,0-.22s-.17,0-.26.08a.39.39,0,0,1,.29-.17c.17,0,.18.21.2.31a1.42,1.42,0,0,1-.08.7.2.2,0,1,1-.38-.13A.16.16,0,0,1,72.9,18.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.23,17.48a1.12,1.12,0,0,0,.21-.51c0-.09,0-.19,0-.21s-.17,0-.26.08c.07-.07.13-.18.29-.18s.18.21.21.31a1.55,1.55,0,0,1-.07.7.2.2,0,0,1-.38-.12A.16.16,0,0,1,73.23,17.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.82,14.42a1.26,1.26,0,0,0,.15-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.18.25.28a1.54,1.54,0,0,1,0,.71.19.19,0,0,1-.24.15.19.19,0,0,1-.15-.23A.08.08,0,0,1,71.82,14.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.93,17.73a1.25,1.25,0,0,0,.27-.48c0-.09,0-.19,0-.22s-.17,0-.27,0a.44.44,0,0,1,.31-.15c.16.06.16.23.17.33a1.47,1.47,0,0,1-.13.7.21.21,0,0,1-.27.1.2.2,0,0,1-.1-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,15a1.24,1.24,0,0,0,.18-.52c0-.09,0-.2,0-.22a.37.37,0,0,0-.26.1c.07-.08.12-.18.27-.2s.2.2.23.3a1.43,1.43,0,0,1,0,.71.21.21,0,0,1-.25.14.2.2,0,0,1-.14-.25A.11.11,0,0,1,73,15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.12,17.17a1.25,1.25,0,0,0,.19-.52c0-.09,0-.19,0-.22a.37.37,0,0,0-.26.1c.07-.08.12-.18.27-.19s.2.2.23.3a1.45,1.45,0,0,1,0,.71.2.2,0,0,1-.25.13.19.19,0,0,1-.13-.25A.11.11,0,0,1,72.12,17.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.88,15.41a1.22,1.22,0,0,0,.16-.52c0-.1,0-.2-.06-.22s-.18.05-.25.11.11-.19.26-.21.21.19.24.29a1.41,1.41,0,0,1,0,.71.21.21,0,0,1-.4-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72,18.13a1.17,1.17,0,0,0,.21-.51c0-.09,0-.19,0-.22s-.18,0-.26.09c.07-.08.13-.18.28-.19s.19.21.21.31a1.34,1.34,0,0,1-.05.71.21.21,0,0,1-.25.13.22.22,0,0,1-.14-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.94,15.92a1.1,1.1,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.27.08c.08-.07.14-.17.29-.17s.18.21.2.31a1.43,1.43,0,0,1-.07.7.21.21,0,0,1-.26.13.2.2,0,0,1-.12-.26A.15.15,0,0,1,74.94,15.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.62,14.46a1.21,1.21,0,0,0,.14-.52c0-.1,0-.2-.07-.22s-.17.06-.25.12c.07-.08.11-.19.26-.22s.21.19.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,1,1-.39-.07A.11.11,0,0,1,70.62,14.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.66,15.3a1,1,0,0,0,.14-.53c0-.09,0-.2-.06-.21s-.17,0-.25.11.11-.19.26-.21.21.18.25.27a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.48,14.13a1.12,1.12,0,0,0,.13-.53c0-.1,0-.2-.07-.21s-.17,0-.24.12c.06-.08.1-.2.25-.22s.22.17.26.27a1.52,1.52,0,0,1,0,.71.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23A.19.19,0,0,1,69.48,14.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.37,20a1.2,1.2,0,0,0,.25-.49c0-.09,0-.19,0-.22s-.18,0-.27.06a.42.42,0,0,1,.3-.16c.16.06.17.23.19.33a1.46,1.46,0,0,1-.12.7.2.2,0,0,1-.27.1.19.19,0,0,1-.1-.26S73.35,20,73.37,20Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.68,25.51a1.07,1.07,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.17,0-.27,0c.09-.05.17-.14.32-.11s.13.25.13.36a1.44,1.44,0,0,1-.24.66.2.2,0,0,1-.28.06.21.21,0,0,1-.06-.28A.12.12,0,0,1,74.68,25.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.23,33a1.3,1.3,0,0,0,.4-.38c.05-.08.09-.17.05-.21s-.17,0-.27,0c.1,0,.19-.11.33,0s.09.26.07.37a1.49,1.49,0,0,1-.34.61.2.2,0,1,1-.3-.27S76.21,33,76.23,33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.75,37.18a1.07,1.07,0,0,0,.44-.34c0-.07.1-.16.07-.2s-.17-.06-.27-.06a.4.4,0,0,1,.34,0c.13.12.06.28,0,.38a1.56,1.56,0,0,1-.4.58.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.73,36.93a1.21,1.21,0,0,0,.44-.33c.06-.07.11-.16.08-.2s-.17-.07-.27-.07a.44.44,0,0,1,.34,0c.12.12.05.28,0,.38a1.38,1.38,0,0,1-.41.57.2.2,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.37,34.93a1.17,1.17,0,0,0,.42-.36c0-.07.1-.16.07-.21a.41.41,0,0,0-.28,0c.1,0,.2-.1.34,0s.07.27,0,.37a1.45,1.45,0,0,1-.38.6.2.2,0,1,1-.28-.29S76.35,34.94,76.37,34.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.8,32.28a1,1,0,0,0,.38-.4c0-.07.09-.17,0-.21s-.17,0-.27,0c.09,0,.19-.12.33-.06a.32.32,0,0,1,.08.36,1.58,1.58,0,0,1-.31.64.22.22,0,0,1-.29,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.12,28.54a1.18,1.18,0,0,0,.34-.44c0-.08.06-.18,0-.22s-.18,0-.28,0a.36.36,0,0,1,.32-.1c.15.08.13.25.12.35a1.44,1.44,0,0,1-.24.66.2.2,0,1,1-.34-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.29,25.27a1.06,1.06,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0a.43.43,0,0,1,.31-.14c.16.07.15.24.16.34a1.45,1.45,0,0,1-.16.69.2.2,0,1,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.34,22.91a1.1,1.1,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.26.08a.39.39,0,0,1,.29-.17c.16,0,.18.21.2.31a1.47,1.47,0,0,1-.09.71.2.2,0,0,1-.26.12A.21.21,0,0,1,69.3,23,.43.43,0,0,1,69.34,22.91Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.39,22.17a1.16,1.16,0,0,0,.2-.51c0-.09,0-.2,0-.22s-.18,0-.26.09.12-.18.28-.19.19.2.21.31a1.4,1.4,0,0,1,0,.7.2.2,0,0,1-.25.14.21.21,0,0,1-.14-.25A.43.43,0,0,1,67.39,22.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,19.59a1.13,1.13,0,0,0,.14-.53c0-.09,0-.2-.06-.22s-.17.06-.25.12.11-.19.26-.21.21.18.25.28a1.37,1.37,0,0,1,0,.7.2.2,0,0,1-.24.16.2.2,0,0,1-.16-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,20.62a1,1,0,0,0,.17-.52c0-.09,0-.19,0-.21s-.17,0-.25.1a.39.39,0,0,1,.27-.2c.17,0,.2.19.23.29a1.32,1.32,0,0,1,0,.71.2.2,0,0,1-.24.15.21.21,0,0,1-.15-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.81,20.55A1.22,1.22,0,0,0,66,20c0-.09,0-.19-.06-.21s-.17.05-.25.11.11-.19.26-.21.21.19.25.29a1.5,1.5,0,0,1,0,.7.21.21,0,0,1-.24.16.19.19,0,0,1-.15-.24A.09.09,0,0,1,65.81,20.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.2,21.58a1.16,1.16,0,0,0,.2-.51c0-.09,0-.2,0-.22s-.18,0-.26.09c.07-.07.13-.18.28-.19s.19.21.22.31a1.41,1.41,0,0,1,0,.7.2.2,0,0,1-.25.14.21.21,0,0,1-.13-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.57,24.1a1.31,1.31,0,0,0,.25-.5c0-.08,0-.19,0-.22s-.18,0-.27.07c.08-.07.15-.17.3-.16s.17.22.19.32a1.58,1.58,0,0,1-.11.7.21.21,0,0,1-.27.11.2.2,0,0,1-.11-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.31,26.11a1.18,1.18,0,0,0,.27-.48c0-.08,0-.18,0-.22s-.18,0-.27.05c.08-.06.15-.16.3-.14s.16.23.17.34a1.37,1.37,0,0,1-.16.69.2.2,0,0,1-.36-.18A.15.15,0,0,1,70.31,26.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.14,28.43a1.07,1.07,0,0,0,.31-.45c0-.08.06-.18,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.24.14.35a1.42,1.42,0,0,1-.21.67.2.2,0,1,1-.35-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.41,31.23a1.27,1.27,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.18,0-.28,0c.09-.05.18-.14.32-.11s.13.25.13.36a1.33,1.33,0,0,1-.24.66.2.2,0,1,1-.33-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.33,31.25a1.07,1.07,0,0,0,.34-.43c0-.08.07-.18,0-.22s-.18,0-.27,0a.37.37,0,0,1,.32-.09c.15.08.12.25.12.35a1.47,1.47,0,0,1-.26.66.2.2,0,0,1-.28.06.21.21,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71,30.05a1.24,1.24,0,0,0,.33-.44c0-.09.06-.19,0-.22s-.18,0-.28,0c.09,0,.17-.14.32-.1s.13.24.13.35a1.49,1.49,0,0,1-.23.67.2.2,0,1,1-.34-.22A.12.12,0,0,1,71,30.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.87,28.61a1.19,1.19,0,0,0,.3-.46c0-.08,0-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.15.24.15.34a1.38,1.38,0,0,1-.19.69.19.19,0,0,1-.27.07.2.2,0,0,1-.08-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.75,26.74a1.24,1.24,0,0,0,.26-.48c0-.09,0-.19,0-.22s-.18,0-.27.05a.44.44,0,0,1,.31-.15c.16.06.16.23.17.33a1.38,1.38,0,0,1-.14.7.2.2,0,0,1-.26.1.2.2,0,0,1-.1-.27S68.73,26.75,68.75,26.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.37,24.72a1.21,1.21,0,0,0,.22-.5c0-.09,0-.19,0-.22s-.18,0-.26.08a.39.39,0,0,1,.29-.17c.16,0,.18.21.2.31a1.61,1.61,0,0,1-.08.71.22.22,0,0,1-.26.12.2.2,0,0,1-.12-.26A.1.1,0,0,1,67.37,24.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.4,22.51a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.05-.24.11.1-.19.25-.21.22.18.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.23.16.21.21,0,0,1-.16-.24A.11.11,0,0,1,63.4,22.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.45,19.54a1.33,1.33,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.05-.09.09-.2.24-.24s.22.17.27.26a1.4,1.4,0,0,1,.08.71.19.19,0,0,1-.22.17.2.2,0,0,1-.18-.22S61.43,19.56,61.45,19.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.46,17.19a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.16.27.26a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.4,0A.19.19,0,0,1,59.46,17.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.64,15.33a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.16.07-.23.14c0-.09.09-.2.23-.24s.23.16.28.26a1.46,1.46,0,0,1,.09.7.2.2,0,0,1-.22.18.2.2,0,0,1-.18-.22A.24.24,0,0,1,57.64,15.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.19,15.13a1.07,1.07,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.17.27.26a1.35,1.35,0,0,1,.09.7.2.2,0,0,1-.4,0A.19.19,0,0,1,56.19,15.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,14a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.17.06-.24.13c.06-.08.1-.2.24-.23s.23.16.27.26a1.46,1.46,0,0,1,.09.7.2.2,0,0,1-.22.18A.21.21,0,0,1,55,14,.19.19,0,0,1,55,14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.15,13a1.19,1.19,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.05-.09.09-.2.24-.24s.22.17.27.26a1.4,1.4,0,0,1,.08.71.2.2,0,0,1-.22.18.22.22,0,0,1-.18-.23A.19.19,0,0,1,55.15,13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.39,14a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.17.27.26a1.47,1.47,0,0,1,.09.7.2.2,0,0,1-.22.18.19.19,0,0,1-.18-.22A.14.14,0,0,1,56.39,14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.07,16.3a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c0-.08.09-.2.24-.24s.23.17.27.26a1.46,1.46,0,0,1,.1.7.21.21,0,0,1-.22.18.2.2,0,0,1-.19-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.23,18.42a1.13,1.13,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.1-.21.24-.24s.23.16.27.26a1.46,1.46,0,0,1,.09.7.2.2,0,0,1-.4,0A.19.19,0,0,1,62.23,18.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.51,19.66a1.12,1.12,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.22.18.26.27a1.52,1.52,0,0,1,0,.71.22.22,0,0,1-.24.17.21.21,0,0,1-.16-.24A.19.19,0,0,1,64.51,19.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.27,21.44a1,1,0,0,0,.16-.52c0-.09,0-.19-.05-.21s-.17,0-.25.1a.39.39,0,0,1,.27-.2c.17,0,.2.19.23.29a1.42,1.42,0,0,1,0,.71.21.21,0,0,1-.25.15.21.21,0,0,1-.14-.25A.11.11,0,0,1,66.27,21.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.46,19.54a1.1,1.1,0,0,0,.16-.53c0-.09,0-.19-.05-.21s-.17.05-.25.11.11-.19.26-.21.21.19.24.29a1.54,1.54,0,0,1,0,.71.21.21,0,0,1-.25.15.2.2,0,0,1-.15-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.57,18.4a1.22,1.22,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.06-.24.12.1-.19.25-.22.21.18.25.28a1.52,1.52,0,0,1,0,.71.2.2,0,1,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.05,17.49a1,1,0,0,0,.11-.54c0-.09,0-.19-.07-.2s-.17.06-.24.13c.06-.09.1-.2.24-.23s.23.17.27.26a1.4,1.4,0,0,1,.06.71.19.19,0,0,1-.22.17.22.22,0,0,1-.18-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.93,16.3a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.16.27.25a1.52,1.52,0,0,1,.1.71.21.21,0,0,1-.23.18.2.2,0,0,1-.18-.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.08,15.13a1,1,0,0,0,.1-.54c0-.09,0-.19-.08-.2s-.17.07-.24.14c.06-.09.1-.2.24-.24s.23.16.27.26a1.35,1.35,0,0,1,.09.7.2.2,0,0,1-.4,0A.19.19,0,0,1,55.08,15.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.84,12.89a1,1,0,0,0,.11-.54c0-.09,0-.19-.08-.2s-.16.06-.24.13c.06-.09.1-.2.25-.23s.22.16.26.26a1.36,1.36,0,0,1,.08.7.21.21,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,50.84,12.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.6,12.44a1.13,1.13,0,0,0,.12-.54c0-.09,0-.19-.08-.2s-.16.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.26a1.52,1.52,0,0,1,.07.71.2.2,0,1,1-.4-.06A.19.19,0,0,1,47.6,12.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.69,11.93a1.16,1.16,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13c0-.08.1-.2.24-.23s.22.18.26.27a1.4,1.4,0,0,1,.06.71.2.2,0,0,1-.23.17.21.21,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,11.8a1.26,1.26,0,0,0,.13-.53c0-.1,0-.2-.07-.22s-.17.06-.25.13c.06-.08.11-.2.25-.22s.22.17.26.27a1.4,1.4,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23A.21.21,0,0,1,39,11.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.42,12.08a1.22,1.22,0,0,0,.13-.53c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.09.11-.2.26-.23s.21.18.25.28a1.36,1.36,0,0,1,0,.7.2.2,0,0,1-.4-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.84,11.61a1.26,1.26,0,0,0,.14-.53c0-.09,0-.2-.07-.22s-.17.06-.24.12.11-.19.25-.21.22.18.25.27a1.41,1.41,0,0,1,0,.71.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.87,12.3a1.26,1.26,0,0,0,.14-.53c0-.1,0-.2-.07-.22s-.17.06-.25.12.11-.19.26-.21.21.18.25.27a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.39-.07A.09.09,0,0,1,25.87,12.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.33,12.13a1,1,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.26-.22.21.19.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,1,1-.39-.07A.19.19,0,0,1,24.33,12.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,13.17a1.22,1.22,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.06-.25.12c.07-.08.11-.19.26-.22s.21.18.25.28a1.52,1.52,0,0,1,0,.71.2.2,0,0,1-.4-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,13a1.1,1.1,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.41,1.41,0,0,1,0,.71.2.2,0,0,1-.23.16.21.21,0,0,1-.17-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.09,12.53a1.13,1.13,0,0,0,.14-.53c0-.09,0-.19-.06-.21s-.17.05-.25.12c.06-.09.11-.2.26-.22s.21.18.25.28a1.37,1.37,0,0,1,0,.7.19.19,0,0,1-.23.16.2.2,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.34,13.33a1.12,1.12,0,0,0,.13-.53c0-.1,0-.2-.07-.21s-.17.05-.24.12c.06-.09.1-.2.25-.22s.22.17.25.27a1.4,1.4,0,0,1,.05.71.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23A.09.09,0,0,1,34.34,13.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.92,13.61a1.12,1.12,0,0,0,.13-.53c0-.1,0-.2-.06-.22s-.17.06-.25.13c.06-.09.11-.2.26-.22s.21.17.25.27a1.52,1.52,0,0,1,0,.71.2.2,0,0,1-.23.16.2.2,0,0,1-.17-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.82,14a1.3,1.3,0,0,0,.13-.54c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.09.11-.2.25-.23s.22.18.26.28a1.36,1.36,0,0,1,0,.7.2.2,0,1,1-.4-.06A.09.09,0,0,1,40.82,14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.38,15.05a1.3,1.3,0,0,0,.11-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.25-.24s.22.17.26.27a1.26,1.26,0,0,1,.07.7.2.2,0,0,1-.22.18.22.22,0,0,1-.18-.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.09,16.85a1.14,1.14,0,0,0,.09-.54c0-.09,0-.19-.08-.2s-.16.07-.24.14c.06-.09.1-.21.24-.24s.23.16.27.26a1.35,1.35,0,0,1,.09.7.2.2,0,0,1-.4,0S52.07,16.87,52.09,16.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.39,18.14a1.2,1.2,0,0,0,.09-.55c0-.09,0-.19-.08-.2s-.17.07-.24.14c.05-.09.09-.2.24-.24s.23.16.27.25a1.52,1.52,0,0,1,.1.71.2.2,0,1,1-.4,0A.14.14,0,0,1,55.39,18.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.86,19a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.09-.21s-.16.08-.23.15c0-.09.09-.21.23-.25s.23.16.28.26a1.46,1.46,0,0,1,.1.7.19.19,0,0,1-.21.18.21.21,0,0,1-.19-.22A.19.19,0,0,1,57.86,19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.72,20.2a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14.09-.2.24-.24.23.17.27.26a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.4,0A.19.19,0,0,1,59.72,20.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.86,21.72a1,1,0,0,0,.11-.53c0-.1,0-.2-.07-.21s-.17.06-.24.13.09-.2.24-.23.22.17.27.26a1.52,1.52,0,0,1,.07.71A.2.2,0,0,1,61,22a.19.19,0,0,1-.17-.22A.19.19,0,0,1,60.86,21.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.75,26a1.1,1.1,0,0,0,.16-.53c0-.09,0-.19-.06-.21s-.17.05-.25.11.12-.19.27-.21.2.19.24.29a1.54,1.54,0,0,1,0,.71.21.21,0,0,1-.24.15.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.63,32.45a1.24,1.24,0,0,0,.26-.48c0-.09,0-.19,0-.22s-.18,0-.27.05c.08-.06.15-.16.3-.15s.16.23.18.33a1.49,1.49,0,0,1-.14.7.2.2,0,0,1-.37-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.54,39.66a1.07,1.07,0,0,0,.34-.43c0-.08.07-.18,0-.22s-.18,0-.28,0a.39.39,0,0,1,.32-.09c.15.08.13.25.12.35a1.38,1.38,0,0,1-.25.67.2.2,0,0,1-.28,0,.2.2,0,0,1-.05-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.14,46.73a1.12,1.12,0,0,0,.39-.39c0-.07.09-.17,0-.21s-.17,0-.27,0c.09,0,.19-.12.33-.06s.09.26.08.37a1.47,1.47,0,0,1-.34.62.2.2,0,0,1-.3-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.71,53.18a1.11,1.11,0,0,0,.42-.34c.06-.08.1-.17.07-.21s-.17-.06-.27-.05a.44.44,0,0,1,.34,0c.13.12.07.27,0,.38a1.49,1.49,0,0,1-.39.59.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.65,59.06a1.34,1.34,0,0,0,.43-.34c.05-.07.1-.16.07-.21s-.17-.06-.27,0a.4.4,0,0,1,.34,0c.13.12.06.27,0,.37a1.41,1.41,0,0,1-.4.59.2.2,0,0,1-.27-.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.3,62.72a1.14,1.14,0,0,0,.44-.33c0-.07.11-.16.08-.2s-.17-.07-.27-.06a.4.4,0,0,1,.34,0c.12.12,0,.28,0,.38a1.48,1.48,0,0,1-.41.58.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.22,66.32a1.18,1.18,0,0,0,.44-.32c.06-.07.12-.15.09-.2s-.17-.07-.27-.07c.1,0,.21-.08.34,0s0,.27,0,.37a1.46,1.46,0,0,1-.43.57.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.56,70a1.14,1.14,0,0,0,.45-.32c.06-.06.12-.15.09-.2s-.16-.07-.26-.07c.1,0,.21-.08.33,0a.33.33,0,0,1,0,.38,1.51,1.51,0,0,1-.43.56.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29S61.54,70,61.56,70Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.61,72.59a1.14,1.14,0,0,0,.45-.32c.06-.07.11-.16.09-.2S61,72,60.88,72c.1,0,.21-.08.34,0s.05.27,0,.37a1.37,1.37,0,0,1-.43.56.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.5,75a1.14,1.14,0,0,0,.45-.32c.06-.07.11-.16.08-.21s-.17-.06-.27-.06a.44.44,0,0,1,.34,0c.12.13,0,.28,0,.38a1.51,1.51,0,0,1-.42.57.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.28,77.28a1.11,1.11,0,0,0,.43-.33c.06-.07.11-.16.08-.2s-.17-.07-.27-.06a.4.4,0,0,1,.34,0c.12.12.06.28,0,.38a1.48,1.48,0,0,1-.41.57.21.21,0,0,1-.29,0,.22.22,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.68,78.72a1.34,1.34,0,0,0,.43-.34c.06-.07.11-.16.08-.21s-.17-.06-.27,0c.1,0,.2-.09.33,0s.06.27,0,.37a1.35,1.35,0,0,1-.41.58.19.19,0,0,1-.28,0,.2.2,0,0,1,0-.28A.13.13,0,0,1,57.68,78.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.75,79.48a1.14,1.14,0,0,0,.42-.35c.06-.07.1-.16.07-.21s-.17,0-.27,0a.4.4,0,0,1,.34,0c.13.11.07.27,0,.37a1.49,1.49,0,0,1-.39.59.2.2,0,0,1-.28-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.26,78.34a1.23,1.23,0,0,0,.42-.35c0-.07.1-.16.07-.21a.38.38,0,0,0-.27,0,.34.34,0,0,1,.33,0c.13.11.07.27,0,.37a1.36,1.36,0,0,1-.38.59.19.19,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.06,76.75a1.14,1.14,0,0,0,.42-.35c.06-.07.11-.16.08-.21s-.17,0-.27,0c.1,0,.2-.09.33,0a.32.32,0,0,1,0,.37,1.39,1.39,0,0,1-.39.59.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.67,74.56a1.07,1.07,0,0,0,.43-.34c.06-.07.11-.16.08-.21a.38.38,0,0,0-.27-.05c.1,0,.2-.09.33,0s.07.27,0,.37a1.41,1.41,0,0,1-.4.59.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.87,71.78a1.12,1.12,0,0,0,.43-.34c.05-.07.1-.16.08-.2s-.17-.06-.27-.06a.37.37,0,0,1,.33,0c.13.12.06.28,0,.38a1.45,1.45,0,0,1-.4.58.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.59,69.53a1.34,1.34,0,0,0,.43-.34c0-.07.1-.16.08-.21s-.17-.06-.27,0c.1,0,.2-.09.33,0s.06.27,0,.37a1.32,1.32,0,0,1-.4.58.2.2,0,0,1-.27-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.8,67.13a1.25,1.25,0,0,0,.43-.35c.05-.07.1-.16.07-.21s-.17,0-.27,0c.1,0,.2-.09.33,0s.07.27,0,.37a1.39,1.39,0,0,1-.39.59.19.19,0,0,1-.28,0,.21.21,0,0,1,0-.29S59.78,67.14,59.8,67.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.14,64.93a1.07,1.07,0,0,0,.41-.36c.06-.08.1-.17.07-.21s-.17,0-.27,0a.37.37,0,0,1,.33,0,.32.32,0,0,1,0,.37,1.43,1.43,0,0,1-.36.61.21.21,0,1,1-.29-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.19,63.16a1.17,1.17,0,0,0,.39-.38c.05-.08.09-.17.06-.21a.34.34,0,0,0-.27,0,.37.37,0,0,1,.33,0c.13.1.09.26.07.36a1.56,1.56,0,0,1-.34.63.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28A.15.15,0,0,1,58.19,63.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.18,63.18a1.18,1.18,0,0,0,.38-.39c0-.08.09-.18,0-.22s-.17,0-.27,0a.4.4,0,0,1,.33-.06c.14.1.1.26.08.37a1.51,1.51,0,0,1-.32.63.21.21,0,0,1-.31-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.86,64.51a1.12,1.12,0,0,0,.39-.39c0-.08.08-.17,0-.22s-.18,0-.28,0c.1,0,.19-.11.33-.06s.1.27.08.37a1.4,1.4,0,0,1-.32.63.2.2,0,0,1-.31-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.27,66.85a1.1,1.1,0,0,0,.38-.39c.05-.08.09-.17.05-.22s-.17,0-.27,0a.4.4,0,0,1,.33-.06c.14.1.09.27.08.37a1.42,1.42,0,0,1-.33.63.2.2,0,0,1-.3-.26A.15.15,0,0,1,56.27,66.85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.92,69.81a1.09,1.09,0,0,0,.39-.38c0-.08.09-.17.06-.22s-.18,0-.27,0a.4.4,0,0,1,.33-.06.33.33,0,0,1,.07.37,1.45,1.45,0,0,1-.34.63.19.19,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.53,72.83a1.2,1.2,0,0,0,.4-.38c0-.07.09-.17.06-.21s-.17,0-.27,0c.1,0,.19-.11.33,0a.33.33,0,0,1,.07.37,1.62,1.62,0,0,1-.35.62.22.22,0,0,1-.29,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.76,76.29a1.17,1.17,0,0,0,.4-.37c.05-.08.09-.17.06-.21s-.17,0-.27,0a.4.4,0,0,1,.33,0c.14.11.08.27.06.37a1.41,1.41,0,0,1-.35.61.21.21,0,0,1-.29,0,.22.22,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.46,78.78a1.08,1.08,0,0,0,.4-.37c0-.07.1-.17.07-.21a.41.41,0,0,0-.28,0c.1,0,.2-.1.34,0s.08.27.06.37a1.47,1.47,0,0,1-.36.61.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28A.15.15,0,0,1,54.46,78.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.93,81a1.11,1.11,0,0,0,.4-.38c.05-.07.09-.17.06-.21s-.17,0-.27,0a.37.37,0,0,1,.33,0,.33.33,0,0,1,.07.37,1.44,1.44,0,0,1-.35.62.21.21,0,0,1-.3-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.09,82.79a1.17,1.17,0,0,0,.39-.38c.05-.07.09-.17.06-.21a.34.34,0,0,0-.27,0,.37.37,0,0,1,.33,0c.14.1.09.27.07.37a1.5,1.5,0,0,1-.35.62.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,83.78a1.1,1.1,0,0,0,.38-.39c.05-.08.09-.17.06-.21s-.18,0-.28,0c.1,0,.19-.11.33-.05a.32.32,0,0,1,.08.36,1.42,1.42,0,0,1-.33.63.2.2,0,0,1-.28,0,.19.19,0,0,1,0-.28A.15.15,0,0,1,53,83.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.52,84.09a1.3,1.3,0,0,0,.4-.38c0-.08.09-.17,0-.21s-.17,0-.27,0c.1,0,.19-.11.33,0s.09.27.07.37a1.5,1.5,0,0,1-.35.62.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.72,84.66a1.28,1.28,0,0,0,.39-.38c0-.08.09-.18,0-.22s-.17,0-.27,0a.4.4,0,0,1,.33-.06c.14.1.09.27.07.37A1.39,1.39,0,0,1,54,85a.2.2,0,0,1-.3-.26A.15.15,0,0,1,53.72,84.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.61,86a1.23,1.23,0,0,0,.37-.41c.05-.08.08-.17,0-.21s-.17,0-.27,0a.4.4,0,0,1,.33-.08c.14.1.1.26.09.37a1.44,1.44,0,0,1-.3.64.2.2,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.79,87.22a1.21,1.21,0,0,0,.36-.41c0-.08.07-.18,0-.22s-.18,0-.27,0c.09,0,.18-.13.32-.08s.11.25.11.36a1.5,1.5,0,0,1-.29.65.22.22,0,0,1-.28,0,.21.21,0,0,1,0-.29A.15.15,0,0,1,50.79,87.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.05,87.56a1.13,1.13,0,0,0,.38-.4c0-.08.08-.18,0-.22s-.18,0-.28,0c.1,0,.19-.12.33-.07s.1.26.09.37a1.55,1.55,0,0,1-.31.63.2.2,0,0,1-.32-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.79,87.81a1.23,1.23,0,0,0,.37-.41c0-.08.08-.17,0-.21s-.18,0-.28,0a.41.41,0,0,1,.33-.08c.15.1.11.26.1.36a1.48,1.48,0,0,1-.3.65.19.19,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.29,87a1.22,1.22,0,0,0,.38-.4c0-.08.07-.18,0-.22s-.18,0-.28,0c.1,0,.19-.12.33-.07a.31.31,0,0,1,.09.36,1.44,1.44,0,0,1-.3.64.21.21,0,0,1-.29,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.19,90a1.11,1.11,0,0,0,.36-.41c.05-.08.08-.18,0-.22s-.17,0-.27,0a.39.39,0,0,1,.33-.07c.14.09.1.25.09.36a1.42,1.42,0,0,1-.29.64.19.19,0,0,1-.28,0,.2.2,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.94,92.24a1.36,1.36,0,0,0,.36-.42c0-.08.07-.18,0-.22s-.18,0-.27,0c.09,0,.18-.13.32-.08s.12.25.11.35a1.47,1.47,0,0,1-.28.66.2.2,0,0,1-.32-.24A.12.12,0,0,1,51.94,92.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,93.75a1.23,1.23,0,0,0,.35-.42c0-.08.07-.18,0-.22s-.18,0-.28,0a.4.4,0,0,1,.33-.09c.15.09.12.26.11.36a1.5,1.5,0,0,1-.27.66.19.19,0,0,1-.28,0A.2.2,0,0,1,52,93.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.09,94.63a1.23,1.23,0,0,0,.34-.43c0-.08.07-.18,0-.22s-.18,0-.28,0c.09,0,.18-.14.33-.1s.12.25.11.36a1.35,1.35,0,0,1-.25.66.2.2,0,0,1-.28,0,.2.2,0,0,1-.05-.28A.12.12,0,0,1,51.09,94.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.32,95.64a1.18,1.18,0,0,0,.34-.44c0-.08.06-.18,0-.22s-.18,0-.27,0a.4.4,0,0,1,.32-.1c.15.09.12.25.12.36a1.44,1.44,0,0,1-.24.66.2.2,0,1,1-.34-.22A.12.12,0,0,1,50.32,95.64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.06,97.72a1.07,1.07,0,0,0,.33-.44c0-.09.06-.19,0-.22s-.18,0-.28,0a.38.38,0,0,1,.32-.11c.15.08.13.25.13.35a1.42,1.42,0,0,1-.23.67.2.2,0,0,1-.34-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.87,99.2a1.16,1.16,0,0,0,.31-.45c0-.08.06-.18,0-.22s-.18,0-.28,0c.09-.06.17-.15.32-.12s.14.25.14.35a1.44,1.44,0,0,1-.22.67.2.2,0,0,1-.34-.2A.15.15,0,0,1,49.87,99.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.66,100.54a1,1,0,0,0,.3-.45c0-.09,0-.19,0-.22s-.18,0-.27,0c.09-.06.17-.15.31-.12s.15.24.15.34a1.44,1.44,0,0,1-.2.68.21.21,0,0,1-.28.08.21.21,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.56,103.24a1.09,1.09,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0c.09-.06.16-.15.31-.13s.15.23.16.34a1.43,1.43,0,0,1-.17.68.2.2,0,0,1-.36-.18A.15.15,0,0,1,49.56,103.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.54,104.17a1.28,1.28,0,0,0,.28-.47c0-.09,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.15.3-.13s.16.23.17.33a1.47,1.47,0,0,1-.17.69.2.2,0,0,1-.36-.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.93,98.73a1.07,1.07,0,0,0,.33-.44c0-.08.06-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.11c.16.08.13.25.13.35a1.42,1.42,0,0,1-.23.67.2.2,0,0,1-.28.07.21.21,0,0,1-.06-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.48,93.14a1.23,1.23,0,0,0,.37-.41c0-.08.07-.18,0-.22a.38.38,0,0,0-.28,0,.36.36,0,0,1,.33-.08c.14.09.11.26.1.36a1.48,1.48,0,0,1-.3.65.2.2,0,0,1-.32-.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.88,89.54a1.12,1.12,0,0,0,.39-.39c0-.08.09-.17,0-.21s-.17,0-.27,0a.37.37,0,0,1,.33,0c.14.1.09.26.08.36a1.53,1.53,0,0,1-.33.63.21.21,0,0,1-.31-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.78,87.9a1.08,1.08,0,0,0,.4-.37c0-.08.09-.17.06-.22s-.17,0-.27,0c.1,0,.19-.1.33,0s.09.26.07.37a1.51,1.51,0,0,1-.35.61.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,86.47a1.15,1.15,0,0,0,.41-.36c0-.08.1-.17.07-.21s-.17-.05-.27,0a.37.37,0,0,1,.33,0,.32.32,0,0,1,0,.37,1.46,1.46,0,0,1-.37.61.19.19,0,0,1-.28,0,.21.21,0,0,1,0-.29S58.93,86.48,59,86.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.07,86.23a1.15,1.15,0,0,0,.41-.36c.05-.08.1-.17.06-.21s-.17,0-.27,0a.39.39,0,0,1,.34,0c.13.11.07.27,0,.37a1.54,1.54,0,0,1-.36.61.21.21,0,0,1-.29,0,.19.19,0,0,1,0-.28A.15.15,0,0,1,58.07,86.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.43,91.62a1.22,1.22,0,0,0,.38-.4c0-.07.08-.17,0-.21s-.18,0-.27,0a.39.39,0,0,1,.33-.06c.14.09.1.26.08.36a1.36,1.36,0,0,1-.31.64.2.2,0,0,1-.28,0,.21.21,0,0,1,0-.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.32,96.88a1.14,1.14,0,0,0,.34-.43c0-.08.06-.18,0-.22a.39.39,0,0,0-.28,0,.43.43,0,0,1,.32-.1.34.34,0,0,1,.12.36,1.35,1.35,0,0,1-.25.66.2.2,0,0,1-.33-.23A.12.12,0,0,1,54.32,96.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.5,100.61a1.24,1.24,0,0,0,.31-.45c0-.09,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.11c.16.07.14.24.14.34a1.46,1.46,0,0,1-.21.68.2.2,0,0,1-.28.07.22.22,0,0,1-.07-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.45,102.34a1,1,0,0,0,.3-.46c0-.08,0-.18,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.07.15.24.15.34a1.48,1.48,0,0,1-.18.69.21.21,0,1,1-.36-.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.44,102.68a1.13,1.13,0,0,0,.29-.46c0-.09,0-.19,0-.23s-.18,0-.27.05c.08-.06.16-.16.3-.14a.33.33,0,0,1,.16.34,1.36,1.36,0,0,1-.17.69.2.2,0,1,1-.35-.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.91,103a1.18,1.18,0,0,0,.27-.48c0-.08,0-.19,0-.22s-.18,0-.27,0c.08-.06.15-.16.3-.14s.16.23.17.33a1.48,1.48,0,0,1-.15.7.2.2,0,0,1-.27.09.2.2,0,0,1-.1-.27A.15.15,0,0,1,46.91,103Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,103.17a1.11,1.11,0,0,0,.26-.48c0-.09,0-.19,0-.22a.36.36,0,0,0-.27.06c.08-.07.15-.17.3-.15s.16.22.18.32a1.46,1.46,0,0,1-.14.7.19.19,0,0,1-.26.1.19.19,0,0,1-.11-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.05,104.39a1.14,1.14,0,0,0,.25-.49c0-.09,0-.2,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.17.22.19.32a1.44,1.44,0,0,1-.12.7.19.19,0,0,1-.26.11.2.2,0,0,1-.11-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.91,104.15a1.29,1.29,0,0,0,.24-.5c0-.09,0-.19,0-.22s-.18,0-.26.07.14-.17.29-.16.18.22.19.32a1.35,1.35,0,0,1-.1.7.2.2,0,0,1-.26.11.2.2,0,0,1-.11-.26S42.89,104.16,42.91,104.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.77,105a1.09,1.09,0,0,0,.22-.5c0-.09,0-.19,0-.22s-.17,0-.26.08c.07-.07.14-.18.29-.18s.18.22.2.32a1.43,1.43,0,0,1-.08.7.2.2,0,0,1-.26.12.19.19,0,0,1-.12-.25A.16.16,0,0,1,41.77,105Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.36,105.57a1.17,1.17,0,0,0,.21-.51c0-.09,0-.19,0-.22s-.17,0-.26.09c.07-.08.13-.18.28-.18s.19.2.21.3a1.25,1.25,0,0,1-.06.71.19.19,0,0,1-.25.13.21.21,0,0,1-.13-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,105.37a1.15,1.15,0,0,0,.19-.52c0-.09,0-.19,0-.21s-.17,0-.26.09c.07-.08.13-.18.28-.19s.19.2.22.3a1.44,1.44,0,0,1,0,.71.22.22,0,0,1-.26.14.22.22,0,0,1-.13-.26S39,105.38,39,105.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.66,105.87a1.15,1.15,0,0,0,.18-.51c0-.09,0-.2-.05-.22s-.17,0-.25.1.12-.19.27-.2.2.2.23.3a1.44,1.44,0,0,1,0,.71.2.2,0,0,1-.25.14.21.21,0,0,1-.14-.25A.25.25,0,0,1,37.66,105.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.33,104.68a1.24,1.24,0,0,0,.18-.52c0-.09,0-.2,0-.22s-.17,0-.25.1a.39.39,0,0,1,.27-.2c.17,0,.2.2.23.3a1.44,1.44,0,0,1,0,.71.19.19,0,0,1-.24.14.21.21,0,0,1-.15-.25S37.31,104.69,37.33,104.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.23,104.35a1.18,1.18,0,0,0,.17-.52c0-.09,0-.19-.06-.21s-.17,0-.25.1.12-.19.27-.2.2.19.23.29a1.42,1.42,0,0,1,0,.71.21.21,0,0,1-.4-.1A.25.25,0,0,1,36.23,104.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.17,102.81a1.23,1.23,0,0,0,.17-.52c0-.1,0-.2-.06-.22s-.17,0-.25.1.12-.18.27-.2.2.2.23.29a1.42,1.42,0,0,1,0,.71.21.21,0,0,1-.25.15.21.21,0,0,1-.15-.24A.16.16,0,0,1,36.17,102.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.44,101.17a1.1,1.1,0,0,0,.16-.52c0-.09,0-.2-.05-.22s-.17,0-.25.11.11-.19.26-.21.21.19.24.29a1.54,1.54,0,0,1,0,.71.2.2,0,0,1-.24.15.21.21,0,0,1-.16-.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.13,98.65a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.07-.21S34,98,34,98c.06-.08.11-.2.26-.22s.21.18.25.28a1.52,1.52,0,0,1,0,.71.21.21,0,0,1-.24.16.21.21,0,0,1-.16-.24A.11.11,0,0,1,34.13,98.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.84,96.88a1,1,0,0,0,.11-.53c0-.09,0-.2-.07-.21s-.17.06-.24.13c.06-.09.1-.2.24-.23s.23.17.27.26a1.52,1.52,0,0,1,.07.71.2.2,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,32.84,96.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.57,94.93a1.14,1.14,0,0,0,.08-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14.09-.2.23-.24.23.16.28.25A1.35,1.35,0,0,1,32,95a.22.22,0,0,1-.22.19.2.2,0,0,1-.18-.22A.24.24,0,0,1,31.57,94.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.87,92.51a1.24,1.24,0,0,0,0-.55c0-.09-.05-.18-.11-.19s-.15.08-.22.16c.05-.09.07-.21.21-.26s.25.14.3.22a1.56,1.56,0,0,1,.17.69.22.22,0,0,1-.2.21.2.2,0,0,1-.2-.21A.17.17,0,0,1,29.87,92.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.22,89.75a1.14,1.14,0,0,0,0-.55c0-.09-.07-.18-.13-.18s-.14.1-.2.18c0-.09.05-.21.18-.28s.26.11.32.19a1.39,1.39,0,0,1,.24.67.2.2,0,0,1-.18.22.19.19,0,0,1-.22-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.62,88a1.06,1.06,0,0,0-.05-.55c0-.09-.08-.18-.13-.18s-.14.11-.19.2c0-.1,0-.22.17-.29s.26.09.33.17A1.56,1.56,0,0,1,28,88a.2.2,0,1,1-.4.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.34,84.8a1.18,1.18,0,0,0-.09-.54c0-.08-.09-.16-.15-.16s-.13.12-.17.21c0-.1,0-.22.14-.3s.27.07.34.14a1.52,1.52,0,0,1,.33.63.21.21,0,0,1-.15.25.2.2,0,0,1-.24-.15A.19.19,0,0,1,27.34,84.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,80a1.26,1.26,0,0,0-.15-.53c0-.08-.11-.16-.16-.15s-.12.13-.15.23c0-.1,0-.22.11-.32s.27.05.36.12a1.54,1.54,0,0,1,.38.59.2.2,0,0,1-.38.14A.22.22,0,0,1,26,80Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.3,76.58a1.33,1.33,0,0,0-.15-.53c0-.08-.11-.16-.17-.15s-.12.13-.15.23c0-.1,0-.22.11-.32s.28,0,.36.11a1.52,1.52,0,0,1,.39.59.21.21,0,0,1-.12.26.2.2,0,0,1-.26-.12A.17.17,0,0,1,24.3,76.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.31,76.13a1.26,1.26,0,0,0-.15-.53c-.05-.07-.12-.15-.17-.14s-.12.13-.15.23c0-.11,0-.23.11-.32s.27,0,.36.11a1.39,1.39,0,0,1,.39.59.21.21,0,0,1-.12.26.22.22,0,0,1-.26-.12A.25.25,0,0,1,25.31,76.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.94,77.59a1.1,1.1,0,0,0-.15-.53c0-.08-.11-.16-.16-.15s-.12.14-.15.23c0-.1,0-.22.11-.32s.27.05.35.11a1.39,1.39,0,0,1,.39.6.2.2,0,0,1-.12.26.21.21,0,0,1-.26-.13A.17.17,0,0,1,24.94,77.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.41,79.19a1.18,1.18,0,0,0-.15-.53c0-.08-.1-.16-.16-.16s-.12.14-.15.23a.42.42,0,0,1,.11-.32c.16-.07.28.06.36.12a1.47,1.47,0,0,1,.38.6.21.21,0,0,1-.12.26.21.21,0,0,1-.26-.13A.17.17,0,0,1,24.41,79.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.12,81.36a1.15,1.15,0,0,0-.13-.53c0-.08-.11-.16-.16-.16s-.13.14-.16.23a.39.39,0,0,1,.12-.32c.15-.07.27.06.35.12a1.44,1.44,0,0,1,.38.6.21.21,0,0,1-.13.26.2.2,0,0,1-.26-.13S25.12,81.38,25.12,81.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.15,83.78a1,1,0,0,0-.12-.53c0-.09-.1-.17-.15-.16s-.13.13-.17.22c0-.1,0-.22.13-.31s.27.06.35.13a1.48,1.48,0,0,1,.36.61.21.21,0,0,1-.13.25.22.22,0,0,1-.26-.13S26.15,83.8,26.15,83.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.44,86a1.32,1.32,0,0,0-.08-.54c0-.08-.09-.17-.14-.17s-.14.12-.18.21c0-.1,0-.22.15-.3s.26.08.34.15a1.52,1.52,0,0,1,.3.64.2.2,0,0,1-.39.09A.11.11,0,0,1,27.44,86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.14,88.31a1.15,1.15,0,0,0,0-.55c0-.08-.07-.18-.12-.18s-.15.1-.21.18c0-.09.05-.21.19-.28s.25.11.32.2a1.41,1.41,0,0,1,.23.66.21.21,0,0,1-.18.23.2.2,0,0,1-.22-.18A.19.19,0,0,1,29.14,88.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.23,90.18a1.12,1.12,0,0,0,0-.55c0-.09-.05-.19-.11-.19s-.15.09-.21.17c0-.1.06-.22.2-.27s.25.13.31.22a1.49,1.49,0,0,1,.18.68.21.21,0,0,1-.19.21.2.2,0,0,1-.21-.2A.17.17,0,0,1,30.23,90.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.36,90.54a1.19,1.19,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.15.08-.22.16c.05-.1.07-.21.22-.26s.24.14.29.23a1.4,1.4,0,0,1,.15.69.2.2,0,0,1-.4,0A.19.19,0,0,1,31.36,90.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.8,89.32a1.18,1.18,0,0,0,.07-.54c0-.1,0-.19-.09-.21s-.16.08-.23.15c.05-.09.08-.2.22-.24s.24.15.29.24a1.42,1.42,0,0,1,.12.69.2.2,0,0,1-.21.2.22.22,0,0,1-.19-.22A.19.19,0,0,1,32.8,89.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.22,86.77a1.19,1.19,0,0,0,.05-.54c0-.09,0-.19-.1-.2s-.16.08-.22.15c0-.09.07-.21.22-.25s.24.14.29.23a1.46,1.46,0,0,1,.14.7.19.19,0,0,1-.21.19.2.2,0,0,1-.19-.21A.19.19,0,0,1,33.22,86.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.29,85a1.07,1.07,0,0,0,0-.54c0-.09,0-.19-.1-.2s-.16.09-.23.16c0-.09.07-.21.22-.26s.24.14.29.23a1.41,1.41,0,0,1,.17.69.2.2,0,0,1-.4,0A.22.22,0,0,1,33.29,85Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.33,83.38a1.09,1.09,0,0,0,0-.54c0-.09-.07-.19-.12-.19s-.15.1-.21.18c0-.1,0-.22.19-.28s.26.12.32.2a1.52,1.52,0,0,1,.22.68.19.19,0,0,1-.19.21.2.2,0,0,1-.22-.18S32.33,83.41,32.33,83.38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.53,81.59a1.18,1.18,0,0,0-.05-.55c0-.08-.08-.17-.13-.17s-.14.11-.19.19c0-.1,0-.22.16-.29s.26.1.33.18a1.37,1.37,0,0,1,.28.65.2.2,0,0,1-.4.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31,79.61a1.18,1.18,0,0,0-.09-.54c0-.08-.09-.17-.15-.16s-.13.12-.17.21c0-.1,0-.22.14-.31s.27.08.34.15a1.41,1.41,0,0,1,.33.63.19.19,0,0,1-.15.24.19.19,0,0,1-.24-.14A.25.25,0,0,1,31,79.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.25,78.87a1.22,1.22,0,0,0-.13-.54c0-.08-.1-.16-.15-.15s-.13.13-.17.22c0-.1,0-.22.13-.31s.27.06.35.12a1.44,1.44,0,0,1,.36.61.22.22,0,0,1-.13.26.2.2,0,0,1-.25-.14A.17.17,0,0,1,30.25,78.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.43,76.48a1.06,1.06,0,0,0-.15-.52c-.05-.08-.11-.16-.17-.15S29,75.94,29,76a.42.42,0,0,1,.11-.32.33.33,0,0,1,.36.11,1.42,1.42,0,0,1,.39.58.2.2,0,0,1-.37.15A.31.31,0,0,1,29.43,76.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.37,73.08a1.22,1.22,0,0,0-.15-.53c0-.08-.11-.16-.17-.15s-.11.14-.15.23a.42.42,0,0,1,.11-.32c.16-.07.28,0,.36.12a1.39,1.39,0,0,1,.39.59.19.19,0,0,1-.12.25.2.2,0,0,1-.26-.12A.17.17,0,0,1,28.37,73.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.13,70.45a1.22,1.22,0,0,0-.15-.53c0-.08-.11-.16-.16-.15s-.12.13-.15.23c0-.11,0-.23.11-.32s.27,0,.36.12a1.47,1.47,0,0,1,.38.59.21.21,0,0,1-.13.26.19.19,0,0,1-.25-.12A.12.12,0,0,1,28.13,70.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.6,67a1.1,1.1,0,0,0-.15-.53c-.05-.08-.11-.15-.16-.15s-.12.14-.16.23a.39.39,0,0,1,.11-.31c.16-.08.28,0,.36.11A1.39,1.39,0,0,1,28,67a.19.19,0,0,1-.12.25.2.2,0,0,1-.26-.11S27.6,67,27.6,67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28,65.4a1.2,1.2,0,0,0-.16-.53c0-.08-.11-.15-.16-.15s-.12.14-.15.23c0-.1,0-.22.1-.31s.28,0,.36.1a1.54,1.54,0,0,1,.4.59.2.2,0,0,1-.12.26.2.2,0,0,1-.26-.12A.17.17,0,0,1,28,65.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.13,64.55A1.18,1.18,0,0,0,27,64c-.05-.08-.12-.16-.17-.14s-.12.13-.15.23a.41.41,0,0,1,.1-.32c.15-.08.28,0,.36.09a1.47,1.47,0,0,1,.42.58.2.2,0,0,1-.11.26.2.2,0,0,1-.27-.11A.17.17,0,0,1,27.13,64.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.28,65.47A1.16,1.16,0,0,0,26.1,65c-.05-.08-.12-.16-.17-.15s-.11.14-.14.24c0-.1,0-.22.09-.32s.28,0,.37.09a1.54,1.54,0,0,1,.42.57.21.21,0,0,1-.38.16A.19.19,0,0,1,26.28,65.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.85,68.25a1.14,1.14,0,0,0-.17-.52c-.05-.08-.12-.16-.17-.15s-.11.14-.14.24a.37.37,0,0,1,.09-.32.32.32,0,0,1,.36.09,1.41,1.41,0,0,1,.42.58.2.2,0,1,1-.37.15A.19.19,0,0,1,24.85,68.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.71,70.39a1,1,0,0,0-.16-.52c0-.08-.11-.16-.16-.15s-.12.14-.15.23a.41.41,0,0,1,.1-.32c.16-.08.28,0,.36.1a1.47,1.47,0,0,1,.4.59.2.2,0,0,1-.11.26.2.2,0,0,1-.26-.12S23.72,70.41,23.71,70.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.9,72.25a1.2,1.2,0,0,0-.16-.52c0-.08-.11-.16-.16-.15s-.12.14-.15.23c0-.1,0-.22.11-.32s.27,0,.35.11a1.44,1.44,0,0,1,.4.58.19.19,0,0,1-.12.26.2.2,0,0,1-.26-.11S22.9,72.28,22.9,72.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.48,73.31a1.07,1.07,0,0,0-.15-.53c0-.08-.11-.16-.16-.15s-.12.13-.15.23c0-.1,0-.22.11-.32s.27,0,.36.11a1.64,1.64,0,0,1,.39.59.21.21,0,0,1-.12.26.19.19,0,0,1-.26-.12A.19.19,0,0,1,21.48,73.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.38,73.6a1.18,1.18,0,0,0-.15-.52c-.05-.08-.11-.16-.17-.15s-.11.13-.15.23a.42.42,0,0,1,.11-.32c.16-.07.28,0,.36.11a1.39,1.39,0,0,1,.39.59.2.2,0,0,1-.12.26.21.21,0,0,1-.26-.12A.19.19,0,0,1,22.38,73.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.11,68.34a1.22,1.22,0,0,0-.19-.52c-.05-.07-.12-.15-.17-.14s-.11.15-.14.24c0-.1,0-.22.09-.32s.28,0,.36.08a1.46,1.46,0,0,1,.43.57.19.19,0,0,1-.1.26.2.2,0,0,1-.27-.1A.17.17,0,0,1,22.11,68.34Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.56,63.38a1.15,1.15,0,0,0-.23-.49c-.06-.07-.14-.14-.19-.12s-.09.15-.11.25c0-.1-.05-.22,0-.33s.28,0,.37.05a1.37,1.37,0,0,1,.49.52.2.2,0,0,1-.08.27.2.2,0,0,1-.27-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.25,61.78a1.23,1.23,0,0,0-.26-.49c-.06-.06-.14-.13-.19-.11s-.09.16-.1.26a.42.42,0,0,1,0-.34.33.33,0,0,1,.37,0,1.4,1.4,0,0,1,.51.49.2.2,0,1,1-.34.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.16,60.26a1.18,1.18,0,0,0-.3-.46c-.06-.06-.15-.12-.2-.09s-.07.16-.08.26c0-.1-.07-.21,0-.33s.27,0,.37,0a1.37,1.37,0,0,1,.55.45.21.21,0,0,1,0,.28.19.19,0,0,1-.28,0A.25.25,0,0,1,20.16,60.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.65,58a1,1,0,0,0-.33-.43c-.08-.06-.17-.11-.21-.08s-.06.17-.06.27c0-.1-.09-.2,0-.34s.28-.06.38,0a1.48,1.48,0,0,1,.58.4.2.2,0,1,1-.3.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.6,55.19a1.15,1.15,0,0,0-.36-.41c-.08-.05-.17-.09-.21-.06s-.05.17,0,.27a.38.38,0,0,1,0-.33c.11-.14.27-.08.37-.06a1.43,1.43,0,0,1,.61.36.21.21,0,0,1,0,.29.19.19,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.56,52.43a1.2,1.2,0,0,0-.38-.4C17.1,52,17,51.94,17,52s0,.18,0,.28a.48.48,0,0,1-.05-.34c.11-.13.27-.08.37-.06a1.28,1.28,0,0,1,.62.34.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.48,47.37a1.12,1.12,0,0,0-.39-.39c-.07,0-.17-.08-.21,0s0,.17,0,.27c0-.09-.12-.19-.06-.33s.26-.09.36-.07a1.4,1.4,0,0,1,.63.32.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.22,44.32a1.18,1.18,0,0,0-.39-.38c-.08,0-.18-.08-.22,0s0,.18,0,.27a.4.4,0,0,1-.06-.33c.1-.14.26-.09.37-.08a1.51,1.51,0,0,1,.63.32.22.22,0,0,1,0,.29.2.2,0,0,1-.28,0A.15.15,0,0,1,17.22,44.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.17,41.86a1.09,1.09,0,0,0-.39-.38c-.08,0-.18-.09-.22,0s0,.17,0,.27a.4.4,0,0,1-.06-.33.32.32,0,0,1,.36-.08,1.49,1.49,0,0,1,.64.32.2.2,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.4,40.31a1.09,1.09,0,0,0-.39-.38c-.08,0-.18-.09-.22,0s0,.17,0,.27a.4.4,0,0,1-.06-.33c.1-.14.26-.09.37-.08a1.51,1.51,0,0,1,.63.32.2.2,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.59,38.88a1,1,0,0,0-.39-.38c-.08,0-.17-.09-.21,0s0,.17,0,.27c0-.09-.12-.19-.06-.33a.32.32,0,0,1,.36-.08,1.4,1.4,0,0,1,.63.32.21.21,0,0,1,0,.29.22.22,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.75,37.45a1.1,1.1,0,0,0-.39-.38c-.08,0-.17-.09-.22,0s0,.17,0,.27a.4.4,0,0,1-.06-.33c.1-.14.27-.09.37-.08a1.42,1.42,0,0,1,.63.33.2.2,0,0,1,0,.28.19.19,0,0,1-.28,0A.15.15,0,0,1,17.75,37.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.44,34.8a1.28,1.28,0,0,0-.38-.39c-.08,0-.18-.09-.22,0s0,.17,0,.27c0-.1-.11-.19-.06-.33s.27-.09.37-.07a1.39,1.39,0,0,1,.62.33.2.2,0,1,1-.27.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.91,33.37a1.28,1.28,0,0,0-.38-.39c-.08,0-.17-.09-.21-.06s-.05.18,0,.28a.39.39,0,0,1-.05-.34c.1-.13.26-.08.36-.07a1.47,1.47,0,0,1,.63.35.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.82,34.21a1.05,1.05,0,0,0-.37-.39c-.08,0-.17-.09-.22-.06s0,.17,0,.27a.44.44,0,0,1-.05-.33.33.33,0,0,1,.37-.07,1.38,1.38,0,0,1,.62.35.2.2,0,1,1-.27.29A.15.15,0,0,1,19.82,34.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.31,35.67a1.11,1.11,0,0,0-.38-.4c-.08,0-.17-.08-.21-.05s0,.18,0,.27a.37.37,0,0,1-.05-.33c.1-.14.26-.09.36-.07a1.45,1.45,0,0,1,.63.34.19.19,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.7,36.63a1.11,1.11,0,0,0-.38-.4c-.08,0-.17-.09-.21,0s0,.17,0,.27a.37.37,0,0,1,0-.33c.1-.14.26-.09.37-.07a1.37,1.37,0,0,1,.62.34.19.19,0,0,1,0,.28.2.2,0,0,1-.28,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.26,35.44a1.12,1.12,0,0,0-.39-.39c-.08,0-.17-.09-.21,0s0,.17,0,.27c0-.1-.11-.19-.05-.33s.26-.09.36-.08a1.45,1.45,0,0,1,.63.34.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.83,26.55a1.18,1.18,0,0,0-.3-.46c-.07-.06-.15-.12-.2-.1s-.07.17-.08.27a.41.41,0,0,1,0-.34.35.35,0,0,1,.38,0,1.46,1.46,0,0,1,.55.45.2.2,0,0,1,0,.28.21.21,0,0,1-.29,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.76,22.69a1.14,1.14,0,0,0-.17-.51c-.05-.08-.12-.16-.18-.15a.4.4,0,0,0-.13.24.37.37,0,0,1,.09-.32c.15-.08.28,0,.36.09a1.44,1.44,0,0,1,.42.57.19.19,0,0,1-.1.26.2.2,0,0,1-.27-.1S24.77,22.72,24.76,22.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.77,17.27a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.09-.2s-.16.07-.23.14c.05-.09.09-.21.23-.24s.23.16.28.25a1.46,1.46,0,0,1,.1.7.2.2,0,1,1-.4,0A.19.19,0,0,1,28.77,17.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.41,14.76a1.26,1.26,0,0,0,.14-.53c0-.09,0-.19-.07-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.26,1.26,0,0,1,0,.7.2.2,0,0,1-.23.17.21.21,0,0,1-.16-.24A.19.19,0,0,1,30.41,14.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.87,10.17A1.22,1.22,0,0,0,32,9.64c0-.09,0-.19-.07-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.52,1.52,0,0,1,0,.71.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23A.19.19,0,0,1,31.87,10.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.75,7.56A1.09,1.09,0,0,0,36.87,7c0-.09,0-.19-.07-.21s-.16.06-.24.13c.06-.09.1-.2.25-.23s.22.18.26.28a1.48,1.48,0,0,1,0,.7.2.2,0,1,1-.4-.06A.13.13,0,0,1,36.75,7.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.54,6.18a1.19,1.19,0,0,0,.12-.54c0-.09,0-.19-.07-.21s-.17.06-.24.13.1-.2.24-.23.22.18.27.27a1.52,1.52,0,0,1,.06.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.14.14,0,0,1,41.54,6.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.83,6.21A1.16,1.16,0,0,0,46,5.67c0-.09,0-.19-.07-.21s-.17.06-.25.13c.06-.08.1-.2.25-.23s.22.17.26.27a1.47,1.47,0,0,1,.07.7.21.21,0,0,1-.23.18.2.2,0,0,1-.17-.23A.14.14,0,0,1,45.83,6.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.51,6.56A1.13,1.13,0,0,0,49.63,6c0-.09,0-.19-.08-.2s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.26a1.52,1.52,0,0,1,.07.71.21.21,0,0,1-.23.17.2.2,0,0,1-.17-.23A.19.19,0,0,1,49.51,6.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.86,7A1.07,1.07,0,0,0,53,6.48c0-.09,0-.19-.07-.21s-.17.07-.24.13.09-.19.24-.23.22.17.27.27a1.46,1.46,0,0,1,.07.7.22.22,0,0,1-.23.18.2.2,0,0,1-.17-.23A.14.14,0,0,1,52.86,7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.83,7.87a1,1,0,0,0,.11-.54c0-.09,0-.19-.08-.2s-.16.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.26a1.37,1.37,0,0,1,.08.7.21.21,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,54.83,7.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.44,8.51A1.07,1.07,0,0,0,56.55,8c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.25-.24s.22.17.26.27a1.35,1.35,0,0,1,.08.7.2.2,0,0,1-.22.18.22.22,0,0,1-.18-.23A.19.19,0,0,1,56.44,8.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.59,11.32a1.18,1.18,0,0,0,.1-.54c0-.09,0-.19-.08-.21s-.16.07-.24.14c.06-.09.1-.2.25-.24s.22.17.26.26a1.52,1.52,0,0,1,.09.71.21.21,0,0,1-.23.18.2.2,0,0,1-.17-.23A.19.19,0,0,1,59.59,11.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.16,16.79a1.18,1.18,0,0,0,.09-.54c0-.09,0-.19-.08-.21s-.17.07-.24.14c.06-.09.09-.2.24-.24s.23.16.27.26a1.46,1.46,0,0,1,.1.7.2.2,0,0,1-.22.18.19.19,0,0,1-.18-.22A.19.19,0,0,1,62.16,16.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63,20.41a1.12,1.12,0,0,0,.11-.53c0-.09,0-.2-.07-.21s-.17.06-.24.13c.06-.09.1-.2.25-.23s.22.17.26.27a1.36,1.36,0,0,1,.06.7.2.2,0,0,1-.23.17.2.2,0,0,1-.17-.23A.11.11,0,0,1,63,20.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64,21.49a1.16,1.16,0,0,0,.13-.54c0-.09,0-.19-.06-.21s-.17.06-.25.12.11-.19.26-.22.21.18.25.28a1.52,1.52,0,0,1,0,.71.21.21,0,0,1-.24.16.2.2,0,0,1-.16-.23A.15.15,0,0,1,64,21.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.57,22.72a1.22,1.22,0,0,0,.15-.52c0-.1,0-.2,0-.22s-.18.05-.26.11c.07-.08.12-.19.27-.21s.2.19.24.29a1.54,1.54,0,0,1,0,.71.2.2,0,1,1-.39-.09A.11.11,0,0,1,64.57,22.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.71,23.71a1.29,1.29,0,0,0,.19-.51c0-.09,0-.2-.05-.22a.37.37,0,0,0-.26.1.43.43,0,0,1,.28-.2c.17,0,.19.2.22.3a1.56,1.56,0,0,1,0,.71.2.2,0,0,1-.39-.11A.21.21,0,0,1,65.71,23.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.65,26.45a1.11,1.11,0,0,0,.24-.5c0-.09,0-.19,0-.22s-.18,0-.26.08c.07-.07.14-.18.29-.17s.17.21.19.32a1.45,1.45,0,0,1-.09.7.2.2,0,0,1-.38-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.89,30.26a1.13,1.13,0,0,0,.29-.46c0-.09,0-.19,0-.22s-.18,0-.27,0a.39.39,0,0,1,.31-.13c.16.06.15.23.15.34a1.32,1.32,0,0,1-.17.68.21.21,0,0,1-.28.09.22.22,0,0,1-.08-.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.21,32.55a1.14,1.14,0,0,0,.32-.44c0-.09,0-.19,0-.22s-.18,0-.27,0a.38.38,0,0,1,.32-.11c.15.08.13.24.13.35a1.36,1.36,0,0,1-.22.67.2.2,0,1,1-.34-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.83,33.6a1.35,1.35,0,0,0,.32-.44c0-.09,0-.19,0-.23s-.18,0-.27,0c.09-.06.17-.15.32-.11s.13.24.13.34a1.47,1.47,0,0,1-.22.68.2.2,0,0,1-.35-.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.62,31.29a1.25,1.25,0,0,0,.29-.47c0-.09,0-.19,0-.22s-.17,0-.27,0c.09-.06.16-.15.31-.14s.15.24.16.34a1.47,1.47,0,0,1-.17.69.2.2,0,0,1-.27.09.2.2,0,0,1-.09-.27A.1.1,0,0,1,66.62,31.29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.88,29.5a1.22,1.22,0,0,0,.25-.48c0-.09,0-.19,0-.22s-.18,0-.27.06c.08-.07.15-.17.3-.16s.16.23.18.33a1.36,1.36,0,0,1-.13.7.2.2,0,0,1-.26.1.2.2,0,0,1-.11-.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.25,27.9a1.28,1.28,0,0,0,.23-.5c0-.09,0-.19,0-.22s-.18,0-.26.07a.39.39,0,0,1,.29-.17c.16.05.18.22.2.32a1.43,1.43,0,0,1-.09.7.2.2,0,0,1-.26.12.2.2,0,0,1-.12-.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.43,24.68a1.13,1.13,0,0,0,.21-.5c0-.09,0-.2,0-.22s-.17,0-.26.08c.07-.07.14-.18.29-.18s.18.21.21.31a1.46,1.46,0,0,1-.07.71.19.19,0,0,1-.25.12.2.2,0,0,1-.13-.25A.25.25,0,0,1,66.43,24.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.07,23.32a1.16,1.16,0,0,0,.2-.51c0-.09,0-.19,0-.22s-.18,0-.26.09c.07-.07.13-.18.28-.19s.19.21.22.31a1.41,1.41,0,0,1,0,.7.2.2,0,0,1-.25.14.19.19,0,0,1-.13-.25A.15.15,0,0,1,68.07,23.32Z"/>
      </g>}
      {r_type_hair >= 9 && <g>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.47,31.59A1,1,0,0,1,11,31a.3.3,0,0,1,.16-.34.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.07.2.2,0,0,0-.07.26.8.8,0,0,0,.45.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.61,34.64a1,1,0,0,1-.46-.62.3.3,0,0,1,.16-.35.74.74,0,0,1,.36,0,.64.64,0,0,0-.33.07.19.19,0,0,0-.07.25.84.84,0,0,0,.44.43h0a.13.13,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.76,33.57a1,1,0,0,1-.45-.62.27.27,0,0,1,.16-.34.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.07.19.19,0,0,0-.07.26.79.79,0,0,0,.44.42h0a.14.14,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.53,30.86a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.06.25.8.8,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.82,31.82a1,1,0,0,1-.46-.61.29.29,0,0,1,.15-.35.88.88,0,0,1,.36,0,.84.84,0,0,0-.33.08.19.19,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.13.13,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.16,33.14a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.18.18,0,0,0-.07.25.8.8,0,0,0,.45.42h0a.12.12,0,0,1,.08.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.52,30.33a1,1,0,0,1-.47-.61.32.32,0,0,1,.16-.35.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.89.89,0,0,0,.45.41h0a.12.12,0,0,1,.08.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.52,31a1,1,0,0,1-.46-.62.27.27,0,0,1,.16-.34.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.13.13,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.54,31.11a1,1,0,0,1-.46-.61.29.29,0,0,1,.15-.35.89.89,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.84.84,0,0,0,.44.41h0a.12.12,0,0,1,.08.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.72,34a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.11,32.49a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.88.88,0,0,1,.36,0,.89.89,0,0,0-.33.08.19.19,0,0,0-.06.26.8.8,0,0,0,.45.42h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.56,32.81a1,1,0,0,1-.46-.62.28.28,0,0,1,.15-.34.9.9,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.14.14,0,0,1,.08.16.14.14,0,0,1-.17.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.58,31.46a1,1,0,0,1-.46-.62.28.28,0,0,1,.15-.34.75.75,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.58,32.46a1,1,0,0,1-.47-.62.28.28,0,0,1,.16-.34.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.8.8,0,0,0,.45.42h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.69,33.68a1,1,0,0,1-.46-.62.28.28,0,0,1,.16-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.11.11,0,0,1,.07.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.41,32.18a1,1,0,0,1-.46-.62.28.28,0,0,1,.15-.34.78.78,0,0,1,.37,0,.94.94,0,0,0-.34.07.2.2,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.12,33.52a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.06.25.82.82,0,0,0,.44.42h0a.13.13,0,0,1,.08.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.52,30a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.9.9,0,0,1,.36,0,.89.89,0,0,0-.33.08.2.2,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.71,34.4a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.18.18,0,0,0-.07.25.8.8,0,0,0,.45.42h0a.12.12,0,0,1,.08.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.54,30.47a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.73.73,0,0,1,.36,0,.77.77,0,0,0-.34.08.19.19,0,0,0-.06.25.8.8,0,0,0,.45.42h0a.14.14,0,0,1,.08.17.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.7,34.34a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.35.87.87,0,0,1,.36,0,.6.6,0,0,0-.33.07.18.18,0,0,0-.07.25.84.84,0,0,0,.44.43h0a.13.13,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.56,31.51a1,1,0,0,1-.47-.61.29.29,0,0,1,.16-.35.88.88,0,0,1,.36,0,.77.77,0,0,0-.34.08.2.2,0,0,0-.06.26.79.79,0,0,0,.45.41h0a.12.12,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.31,32.69a1,1,0,0,1-.46-.61.3.3,0,0,1,.16-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08A.18.18,0,0,0,11,32a.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.74,33.18a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.35.88.88,0,0,1,.36,0,.77.77,0,0,0-.34.08.2.2,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.4,32.13a1,1,0,0,1-.46-.62.27.27,0,0,1,.16-.34.74.74,0,0,1,.36,0,.77.77,0,0,0-.34.08.2.2,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.13.13,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.39,35.78a1,1,0,0,1-.46-.62.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.82.82,0,0,0,.43.43h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.17.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.45,35.25a1,1,0,0,1-.46-.61.3.3,0,0,1,.16-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.37,35.61a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.63.63,0,0,1,.36,0,.65.65,0,0,0-.34.07A.18.18,0,0,0,12,35a.84.84,0,0,0,.44.43h0a.14.14,0,0,1,.08.17.14.14,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.64,35a1,1,0,0,1-.45-.62.29.29,0,0,1,.16-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.88.88,0,0,0,.44.43h0a.13.13,0,0,1-.09.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.48,36a1,1,0,0,1-.45-.63.3.3,0,0,1,.16-.34.93.93,0,0,1,.37,0,.77.77,0,0,0-.34.07.18.18,0,0,0-.07.25.82.82,0,0,0,.43.43h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.84,34a1,1,0,0,1-.46-.62.3.3,0,0,1,.16-.34.74.74,0,0,1,.36,0,.88.88,0,0,0-.33.07.2.2,0,0,0-.07.26.82.82,0,0,0,.44.42h0A.12.12,0,0,1,15,34a.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.85,35.43a1,1,0,0,1-.46-.62.3.3,0,0,1,.17-.35.87.87,0,0,1,.36,0,.65.65,0,0,0-.34.07.2.2,0,0,0-.07.25.84.84,0,0,0,.44.43h0a.14.14,0,0,1,.08.17.14.14,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.87,34.79a1,1,0,0,1-.46-.62.28.28,0,0,1,.16-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.2.2,0,0,0-.06.26.79.79,0,0,0,.44.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.53,33.92a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.07.25.84.84,0,0,0,.45.42h0a.12.12,0,0,1-.09.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.63,32.59a1,1,0,0,1-.47-.62.28.28,0,0,1,.15-.34.76.76,0,0,1,.36,0,.76.76,0,0,0-.33.08.2.2,0,0,0-.06.26.8.8,0,0,0,.45.42h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.68,32.4a1,1,0,0,1-.46-.62.28.28,0,0,1,.15-.34.75.75,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.52,31a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.73.73,0,0,1,.36,0,.77.77,0,0,0-.34.08.19.19,0,0,0-.06.25.84.84,0,0,0,.45.42h0a.14.14,0,0,1,.08.16.14.14,0,0,1-.17.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.8,31.46a1,1,0,0,1-.46-.61.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.18.18,0,0,0-.06.25.82.82,0,0,0,.44.42h0a.14.14,0,0,1,.08.17.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.68,30.35a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.34.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.82.82,0,0,0,.45.41h0a.12.12,0,0,1,.07.16.13.13,0,0,1-.16.08S18.69,30.36,18.68,30.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.27,29.31a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.25.87.87,0,0,0,.45.42h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.9,30.15a1,1,0,0,1-.46-.62.28.28,0,0,1,.15-.34.91.91,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.78,28.89a1,1,0,0,1-.46-.62.27.27,0,0,1,.16-.34.73.73,0,0,1,.36,0,.77.77,0,0,0-.34.07.2.2,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.13.13,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.08,27.84a1,1,0,0,1-.46-.61.3.3,0,0,1,.16-.35.89.89,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.07.25.86.86,0,0,0,.45.43h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.68,28a1,1,0,0,1-.46-.63.29.29,0,0,1,.17-.34.87.87,0,0,1,.36,0,.6.6,0,0,0-.33.07.19.19,0,0,0-.08.25.84.84,0,0,0,.44.43h0a.13.13,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.25,26.88a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0A.65.65,0,0,0,20,26a.18.18,0,0,0-.07.25.79.79,0,0,0,.43.43h0a.11.11,0,0,1,.07.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.81,25.74a1,1,0,0,1-.44-.63.29.29,0,0,1,.17-.34.87.87,0,0,1,.36,0,.77.77,0,0,0-.34.07.2.2,0,0,0-.07.25.79.79,0,0,0,.43.43h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.89,25.63a1,1,0,0,1-.43-.64.28.28,0,0,1,.17-.33.74.74,0,0,1,.36,0,.88.88,0,0,0-.33.06.2.2,0,0,0-.08.26.82.82,0,0,0,.42.44h0a.14.14,0,0,1,.07.17.13.13,0,0,1-.17.06S20.89,25.64,20.89,25.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.85,26.12a1,1,0,0,1-.43-.65.29.29,0,0,1,.18-.33.73.73,0,0,1,.36,0,.72.72,0,0,0-.33.05.21.21,0,0,0-.09.26.82.82,0,0,0,.42.44h0a.12.12,0,0,1,.06.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.57,24.39a1,1,0,0,1-.43-.64.32.32,0,0,1,.18-.34.72.72,0,0,1,.36,0,.74.74,0,0,0-.33,0,.19.19,0,0,0-.08.25.76.76,0,0,0,.41.45h0a.13.13,0,0,1-.1.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.93,25.41a1,1,0,0,1-.4-.66.29.29,0,0,1,.2-.33.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.09.24.84.84,0,0,0,.39.47h0a.12.12,0,0,1,.06.16.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,24.5a1,1,0,0,1-.4-.66.3.3,0,0,1,.2-.33.88.88,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.8.8,0,0,0,.4.46h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.42,23.24a1,1,0,0,1-.39-.67.27.27,0,0,1,.19-.32.72.72,0,0,1,.36,0,.78.78,0,0,0-.34,0,.18.18,0,0,0-.09.24.82.82,0,0,0,.4.47h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.9,23.76a.94.94,0,0,1-.36-.68.28.28,0,0,1,.21-.31.74.74,0,0,1,.36,0,.64.64,0,0,0-.34,0,.19.19,0,0,0-.11.24.82.82,0,0,0,.37.48h0a.12.12,0,0,1,.05.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.91,23.68a1,1,0,0,1-.33-.7.29.29,0,0,1,.22-.3.62.62,0,0,1,.36.05.65.65,0,0,0-.34,0c-.11,0-.13.13-.12.23a.84.84,0,0,0,.35.5h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.08,21.31a1,1,0,0,1-.35-.68.3.3,0,0,1,.21-.32.85.85,0,0,1,.36.05.78.78,0,0,0-.34,0,.21.21,0,0,0-.11.25.91.91,0,0,0,.36.49h0a.13.13,0,0,1-.12.22A0,0,0,0,1,22.08,21.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.31,21.37a1,1,0,0,1-.3-.71.28.28,0,0,1,.23-.3.86.86,0,0,1,.36.07.65.65,0,0,0-.34,0,.19.19,0,0,0-.13.23.82.82,0,0,0,.33.51h0a.13.13,0,0,1-.14.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.19,20a1,1,0,0,1-.29-.71.29.29,0,0,1,.25-.29.69.69,0,0,1,.35.07.79.79,0,0,0-.34,0,.19.19,0,0,0-.13.23.8.8,0,0,0,.31.52h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A0,0,0,0,1,23.19,20Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.23,20.25a1,1,0,0,1-.24-.73.3.3,0,0,1,.27-.28.75.75,0,0,1,.34.1.8.8,0,0,0-.34,0,.19.19,0,0,0-.14.22.84.84,0,0,0,.28.55h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S24.23,20.26,24.23,20.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.37,18.73a1,1,0,0,1-.26-.73.28.28,0,0,1,.25-.28.78.78,0,0,1,.35.08.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.22.81.81,0,0,0,.3.54h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.14,19.48a1,1,0,0,1-.16-.75.28.28,0,0,1,.28-.25.64.64,0,0,1,.34.13.78.78,0,0,0-.33-.07.19.19,0,0,0-.17.2.82.82,0,0,0,.23.57h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.6,18.48a1,1,0,0,1-.19-.74c0-.14.14-.28.27-.26a.7.7,0,0,1,.35.12.7.7,0,0,0-.34-.06.2.2,0,0,0-.16.21.79.79,0,0,0,.25.56h0a.11.11,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.49,17.42a1,1,0,0,1-.2-.75.3.3,0,0,1,.28-.26.66.66,0,0,1,.34.12.66.66,0,0,0-.33-.06.19.19,0,0,0-.16.21.79.79,0,0,0,.25.56h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.91,18a1,1,0,0,1-.1-.77.28.28,0,0,1,.31-.22.74.74,0,0,1,.32.16.79.79,0,0,0-.33-.1.19.19,0,0,0-.18.19.87.87,0,0,0,.18.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S25.92,18,25.91,18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.19,16.44a1,1,0,0,1-.17-.75.29.29,0,0,1,.29-.25.73.73,0,0,1,.33.12.77.77,0,0,0-.33-.06c-.11,0-.16.1-.17.21a.82.82,0,0,0,.24.56h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.5,17a1,1,0,0,1-.09-.77.29.29,0,0,1,.32-.21.76.76,0,0,1,.32.17.72.72,0,0,0-.33-.11c-.11,0-.17.09-.19.19a.87.87,0,0,0,.17.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S26.5,17,26.5,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.46,15.28a1,1,0,0,1-.19-.75.29.29,0,0,1,.29-.25.7.7,0,0,1,.34.12.7.7,0,0,0-.34-.06.19.19,0,0,0-.16.21.87.87,0,0,0,.24.56h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.72,17.46a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.81.81,0,0,1,.3.21.69.69,0,0,0-.31-.15.2.2,0,0,0-.21.16.8.8,0,0,0,.08.61h0a.12.12,0,1,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.4,14.77a1,1,0,0,1-.16-.75.29.29,0,0,1,.29-.25.75.75,0,0,1,.33.14.66.66,0,0,0-.33-.07.2.2,0,0,0-.17.2.86.86,0,0,0,.23.57h0a.14.14,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.48,16.4a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2.79.79,0,0,1,.31.18,1,1,0,0,0-.32-.12.2.2,0,0,0-.2.18.84.84,0,0,0,.13.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.52,15.87a1,1,0,0,1-.12-.76.29.29,0,0,1,.31-.23A.68.68,0,0,1,27,15a.66.66,0,0,0-.33-.09c-.11,0-.16.09-.18.19a.82.82,0,0,0,.2.58h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0S26.52,15.88,26.52,15.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.91,19a1,1,0,0,1,.08-.77.29.29,0,0,1,.35-.14.71.71,0,0,1,.28.23.79.79,0,0,0-.29-.17.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.05.17.13.13,0,0,1-.17-.06S27.91,19.05,27.91,19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.36,19.9a1,1,0,0,1-.08-.77.28.28,0,0,1,.32-.21.88.88,0,0,1,.32.16.84.84,0,0,0-.33-.1.19.19,0,0,0-.19.18.8.8,0,0,0,.17.59h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0S26.37,19.9,26.36,19.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.38,21.68a1,1,0,0,1-.25-.73.28.28,0,0,1,.25-.28.82.82,0,0,1,.35.09.79.79,0,0,0-.34,0,.2.2,0,0,0-.14.23.81.81,0,0,0,.3.53h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.49,22.52a1,1,0,0,1-.35-.68.28.28,0,0,1,.21-.32.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.28,22.91a1,1,0,0,1-.41-.65.29.29,0,0,1,.18-.33.72.72,0,0,1,.36,0,.72.72,0,0,0-.33,0,.2.2,0,0,0-.09.25.79.79,0,0,0,.41.45h0a.13.13,0,1,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.16,24a1,1,0,0,1-.44-.64A.28.28,0,0,1,18.9,23a.63.63,0,0,1,.36,0,.76.76,0,0,0-.34.06.19.19,0,0,0-.07.26.8.8,0,0,0,.42.43h0a.12.12,0,1,1-.09.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.21,25.45a1,1,0,0,1-.45-.62.28.28,0,0,1,.16-.35.87.87,0,0,1,.36,0,.6.6,0,0,0-.33.07.18.18,0,0,0-.07.25.77.77,0,0,0,.44.43h0a.14.14,0,0,1,.08.17.14.14,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.54,25.86a1,1,0,0,1-.47-.62.28.28,0,0,1,.16-.34.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.8.8,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.78,26a1,1,0,0,1-.47-.61.32.32,0,0,1,.16-.35.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.08.2.2,0,0,0-.07.26.89.89,0,0,0,.45.41h0a.12.12,0,0,1,.08.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.51,25.64A1,1,0,0,1,12,25a.3.3,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.2.2,0,0,0-.07.26.89.89,0,0,0,.45.41h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.94,25.71a1.07,1.07,0,0,1-.47-.62.31.31,0,0,1,.16-.35.89.89,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.07.26.87.87,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.58,26.07a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.07.25.89.89,0,0,0,.45.43h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.86,27.08a1,1,0,0,1-.47-.62.28.28,0,0,1,.16-.34.88.88,0,0,1,.36,0,.84.84,0,0,0-.33.08.2.2,0,0,0-.07.26.8.8,0,0,0,.45.42H9A.13.13,0,0,1,9,27a.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.91,27.56A1,1,0,0,1,7.44,27a.29.29,0,0,1,.15-.35.91.91,0,0,1,.36,0,1,1,0,0,0-.33.09.19.19,0,0,0-.06.25.84.84,0,0,0,.45.42H8a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.08,28.65A1,1,0,0,1,7.61,28a.29.29,0,0,1,.15-.35.88.88,0,0,1,.36,0,.9.9,0,0,0-.33.09.19.19,0,0,0-.06.25.84.84,0,0,0,.45.42h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.11,29.72a1,1,0,0,1-.48-.61.3.3,0,0,1,.15-.35.88.88,0,0,1,.36,0,.9.9,0,0,0-.33.09.2.2,0,0,0-.06.26.86.86,0,0,0,.45.41h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.52,31.09a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.88.88,0,0,1,.36,0,.85.85,0,0,0-.33.09.19.19,0,0,0-.06.26.86.86,0,0,0,.45.41h0a.13.13,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7,29.29a1.07,1.07,0,0,1-.48-.61.3.3,0,0,1,.15-.35.88.88,0,0,1,.36,0,.9.9,0,0,0-.33.09.2.2,0,0,0-.06.26.82.82,0,0,0,.46.4h0a.13.13,0,0,1,.08.16A.14.14,0,0,1,7,29.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7,30.3a1,1,0,0,1-.48-.6.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.06.26.82.82,0,0,0,.45.41h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M6.94,28.08a1,1,0,0,1-.48-.6.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.6.6,0,0,0-.33.09.18.18,0,0,0-.06.25.82.82,0,0,0,.45.41H7a.12.12,0,1,1-.08.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M7.73,26.44a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.18.18,0,0,0-.07.25.84.84,0,0,0,.45.42h0a.13.13,0,1,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M8.57,25.31a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.35,1.09,1.09,0,0,1,.36,0,.94.94,0,0,0-.34.08.2.2,0,0,0-.06.26.79.79,0,0,0,.44.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.79,24.13a1,1,0,0,1-.46-.62.28.28,0,0,1,.16-.34.74.74,0,0,1,.36,0,.88.88,0,0,0-.33.07.19.19,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.07S9.79,24.14,9.79,24.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.63,23.08a1,1,0,0,1-.46-.62.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.19.19,0,0,0-.07.26.82.82,0,0,0,.43.43h0a.11.11,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.54,21.94a1,1,0,0,1-.45-.62.3.3,0,0,1,.17-.35.87.87,0,0,1,.36,0,.94.94,0,0,0-.34.07.19.19,0,0,0-.07.26.86.86,0,0,0,.43.43h0a.13.13,0,0,1,.08.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.19,20.77a1,1,0,0,1-.44-.63.28.28,0,0,1,.16-.34.93.93,0,0,1,.37,0,.77.77,0,0,0-.34.07.18.18,0,0,0-.07.25.84.84,0,0,0,.43.44h0a.14.14,0,0,1,.07.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.86,19.71a1,1,0,0,1-.43-.63.28.28,0,0,1,.17-.34.87.87,0,0,1,.36,0,.77.77,0,0,0-.34.07.19.19,0,0,0-.07.25.82.82,0,0,0,.42.44h0a.12.12,0,0,1,.07.17.14.14,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.59,18.56a1,1,0,0,1-.43-.64.29.29,0,0,1,.18-.34.87.87,0,0,1,.36,0,.93.93,0,0,0-.34.06.19.19,0,0,0-.08.25.88.88,0,0,0,.42.45h0a.14.14,0,0,1,.07.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.4,17.53a1,1,0,0,1-.42-.64.3.3,0,0,1,.18-.34.72.72,0,0,1,.36,0,.74.74,0,0,0-.33.05.2.2,0,0,0-.09.25.84.84,0,0,0,.42.45h0a.11.11,0,0,1,.07.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15,16.39a1.05,1.05,0,0,1-.4-.66.28.28,0,0,1,.19-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.05.2.2,0,0,0-.09.25.84.84,0,0,0,.41.46h0a.12.12,0,0,1,.06.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.91,15.54a1,1,0,0,1-.39-.66.3.3,0,0,1,.19-.33.92.92,0,0,1,.37,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.1.25.83.83,0,0,0,.4.46h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,14.68a1,1,0,0,1-.38-.67.29.29,0,0,1,.2-.32.86.86,0,0,1,.36,0,.78.78,0,0,0-.34,0,.19.19,0,0,0-.09.25.85.85,0,0,0,.38.47h0a.13.13,0,0,1,.06.17.14.14,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.6,13.68a1,1,0,0,1-.37-.67.3.3,0,0,1,.21-.32.72.72,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.77.77,0,0,0,.38.48h0a.12.12,0,0,1,.05.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.85,13.24a1,1,0,0,1-.35-.69.28.28,0,0,1,.21-.31.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24A.84.84,0,0,0,19,13h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.43,12.24a1,1,0,0,1-.34-.69.28.28,0,0,1,.22-.31.69.69,0,0,1,.35,0,.78.78,0,0,0-.34,0,.2.2,0,0,0-.11.24.87.87,0,0,0,.36.49h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.53,12a1,1,0,0,1-.33-.69.29.29,0,0,1,.22-.31.87.87,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.84.84,0,0,0,.36.5h0a.13.13,0,0,1,.05.17.14.14,0,0,1-.18.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.22,11.16a1,1,0,0,1-.33-.69.29.29,0,0,1,.22-.31.87.87,0,0,1,.36.05.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.87.87,0,0,0,.35.5h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.87,12.48a1,1,0,0,1-.31-.71.3.3,0,0,1,.24-.3.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.2.2,0,0,0-.12.24.82.82,0,0,0,.33.51h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.31,10.66A1,1,0,0,1,22,10a.28.28,0,0,1,.22-.31.85.85,0,0,1,.36.05.78.78,0,0,0-.34,0,.2.2,0,0,0-.12.24.84.84,0,0,0,.36.5h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.78,11a1,1,0,0,1-.32-.7.28.28,0,0,1,.23-.3.74.74,0,0,1,.36.06.69.69,0,0,0-.35,0,.2.2,0,0,0-.12.23.85.85,0,0,0,.34.51h0A.12.12,0,0,1,24,11a.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24,12a1,1,0,0,1-.3-.71A.3.3,0,0,1,24,11a.81.81,0,0,1,.35.07A.78.78,0,0,0,24,11a.2.2,0,0,0-.12.24.81.81,0,0,0,.32.51h0a.13.13,0,0,1-.14.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.75,11.46a1,1,0,0,1-.33-.7.3.3,0,0,1,.23-.31.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.19.19,0,0,0-.12.24.83.83,0,0,0,.34.5h0a.12.12,0,0,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.76,12.55a1,1,0,0,1-.32-.7.28.28,0,0,1,.23-.3.81.81,0,0,1,.35,0,.78.78,0,0,0-.34,0,.22.22,0,0,0-.12.24.83.83,0,0,0,.35.5h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.6,13.34a1,1,0,0,1-.34-.7.29.29,0,0,1,.22-.31.87.87,0,0,1,.36.05,1,1,0,0,0-.34,0,.19.19,0,0,0-.12.24.81.81,0,0,0,.36.5h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19,14.64a1,1,0,0,1-.36-.68.28.28,0,0,1,.21-.31.74.74,0,0,1,.36,0,.64.64,0,0,0-.34,0,.19.19,0,0,0-.11.24.84.84,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.93,16.15a1,1,0,0,1-.39-.67.28.28,0,0,1,.19-.32.62.62,0,0,1,.36,0,.61.61,0,0,0-.33,0,.18.18,0,0,0-.1.24.84.84,0,0,0,.39.47h0a.12.12,0,0,1,.06.16.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.83,17.49a1,1,0,0,1-.41-.66.29.29,0,0,1,.19-.33.86.86,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.83.83,0,0,0,.4.46h0a.12.12,0,0,1,.06.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16,18.91a1,1,0,0,1-.43-.64.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.7.7,0,0,0-.33,0,.21.21,0,0,0-.09.26.86.86,0,0,0,.42.44h0a.13.13,0,0,1,.07.17.14.14,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.15,20.14a1,1,0,0,1-.44-.64.28.28,0,0,1,.17-.34.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.06.19.19,0,0,0-.07.25.81.81,0,0,0,.43.44h0a.12.12,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.21,21.32a1,1,0,0,1-.45-.62.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.21.21,0,0,0-.08.26.88.88,0,0,0,.44.43h0a.13.13,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.18,22.69a1,1,0,0,1-.46-.62.3.3,0,0,1,.16-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.18.18,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.39,23.67a1,1,0,0,1-.46-.61.29.29,0,0,1,.15-.35.78.78,0,0,1,.37,0,.77.77,0,0,0-.34.08.19.19,0,0,0-.06.25.84.84,0,0,0,.44.43h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.38,24.73a1,1,0,0,1-.46-.61.28.28,0,0,1,.16-.35.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.2.2,0,0,0-.06.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.38,26.68a1,1,0,0,1-.47-.62.28.28,0,0,1,.15-.34.76.76,0,0,1,.36,0,.76.76,0,0,0-.33.08A.2.2,0,0,0,11,26a.82.82,0,0,0,.44.42h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.32,27.14a1,1,0,0,1-.46-.61.29.29,0,0,1,.15-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.19.19,0,0,0-.06.25.82.82,0,0,0,.44.42h0a.14.14,0,0,1,.08.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.84,28.22a1,1,0,0,1-.47-.62.28.28,0,0,1,.16-.34.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.8.8,0,0,0,.45.42h0a.13.13,0,0,1,.08.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M9.54,29.54a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.84.84,0,0,0,.45.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11,28.43a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.73.73,0,0,1,.36,0,.77.77,0,0,0-.34.08.19.19,0,0,0-.06.25.8.8,0,0,0,.45.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M10.5,29.26a1,1,0,0,1-.46-.61.29.29,0,0,1,.15-.35.89.89,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.84.84,0,0,0,.44.41h0a.12.12,0,0,1,.08.16.14.14,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.05,28.32a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.88.88,0,0,1,.36,0,.84.84,0,0,0-.33.08.2.2,0,0,0-.07.26.84.84,0,0,0,.45.42h0a.14.14,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.44,27.3a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.18.18,0,0,0-.06.25.82.82,0,0,0,.44.42h0a.13.13,0,0,1-.08.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.3,26.08a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.75.75,0,0,1,.36,0,.76.76,0,0,0-.33.08.19.19,0,0,0-.06.25.8.8,0,0,0,.45.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.9,24.57a1,1,0,0,1-.47-.62.28.28,0,0,1,.16-.34.88.88,0,0,1,.36,0,.84.84,0,0,0-.33.08.2.2,0,0,0-.07.26.8.8,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.85,23.31a1,1,0,0,1-.46-.61.28.28,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.18.18,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.13,21.77a1,1,0,0,1-.45-.62.29.29,0,0,1,.16-.35.88.88,0,0,1,.36,0,.72.72,0,0,0-.33.07.18.18,0,0,0-.07.25.84.84,0,0,0,.43.44h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07S16.13,21.78,16.13,21.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.56,19.8a1,1,0,0,1-.43-.64.32.32,0,0,1,.18-.34.87.87,0,0,1,.36,0,.88.88,0,0,0-.33.06.19.19,0,0,0-.08.25.76.76,0,0,0,.41.45h0a.13.13,0,0,1,.07.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.72,18.22a1,1,0,0,1-.4-.66.3.3,0,0,1,.19-.33.72.72,0,0,1,.36,0,.78.78,0,0,0-.34.05.19.19,0,0,0-.09.25.87.87,0,0,0,.4.46h0a.13.13,0,1,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.47,17a1,1,0,0,1-.38-.67A.29.29,0,0,1,19.3,16a.74.74,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.84.84,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.06S19.47,17,19.47,17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.4,16.23a1.05,1.05,0,0,1-.35-.69.29.29,0,0,1,.22-.31.73.73,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.79.79,0,0,0,.36.49h0a.13.13,0,0,1,.05.17.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.42,15.19a1,1,0,0,1-.35-.69.29.29,0,0,1,.22-.31.73.73,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.84.84,0,0,0,.37.49h0a.13.13,0,1,1-.12.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.42,14.88a1,1,0,0,1-.33-.7.3.3,0,0,1,.23-.31.88.88,0,0,1,.36.06.78.78,0,0,0-.34,0,.19.19,0,0,0-.12.24.88.88,0,0,0,.34.51h0a.12.12,0,0,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.49,14.49a1,1,0,0,1-.3-.72.29.29,0,0,1,.24-.29.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.12.23.83.83,0,0,0,.32.52h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.74,15.49a1,1,0,0,1-.29-.72.29.29,0,0,1,.25-.29.69.69,0,0,1,.35.07.79.79,0,0,0-.34,0,.19.19,0,0,0-.13.23.78.78,0,0,0,.31.52h0a.12.12,0,1,1-.14.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22,16.4a.94.94,0,0,1-.31-.7.27.27,0,0,1,.23-.3.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.19.19,0,0,0-.13.23.84.84,0,0,0,.34.51h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0S22,16.41,22,16.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.63,17.48a1,1,0,0,1-.32-.7.29.29,0,0,1,.23-.31.68.68,0,0,1,.35.06.78.78,0,0,0-.34,0,.22.22,0,0,0-.12.24.8.8,0,0,0,.35.5h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.8,18.66a1,1,0,0,1-.36-.68.31.31,0,0,1,.21-.32.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.18.18,0,0,0-.1.24.83.83,0,0,0,.36.49h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.58,19.94a1,1,0,0,1-.4-.66.3.3,0,0,1,.19-.33.62.62,0,0,1,.36,0,.61.61,0,0,0-.33,0,.2.2,0,0,0-.1.25.87.87,0,0,0,.4.46h0a.13.13,0,0,1,.06.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.5,21.6a1,1,0,0,1-.43-.64.28.28,0,0,1,.17-.33.77.77,0,0,1,.37,0,.76.76,0,0,0-.34.05.21.21,0,0,0-.08.26.82.82,0,0,0,.42.44h0a.12.12,0,0,1,.06.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,23.12a1,1,0,0,1-.45-.62.3.3,0,0,1,.17-.35.87.87,0,0,1,.36,0,.77.77,0,0,0-.34.07.2.2,0,0,0-.07.25.84.84,0,0,0,.44.43h0a.12.12,0,1,1-.09.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.69,25.15a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.18.18,0,0,0-.07.25.8.8,0,0,0,.45.42h0a.12.12,0,0,1,.08.16.12.12,0,0,1-.16.07S15.69,25.16,15.69,25.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.81,28.42a1,1,0,0,1-.47-.61.3.3,0,0,1,.15-.35.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.06.26.86.86,0,0,0,.45.41h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.94,27.4a1,1,0,0,1-.47-.61.28.28,0,0,1,.16-.35.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.2.2,0,0,0-.07.26.82.82,0,0,0,.45.41h0a.12.12,0,0,1,.08.16.14.14,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15,29.14a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.06.26.82.82,0,0,0,.45.41h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14,28.48a1,1,0,0,1-.48-.61.3.3,0,0,1,.16-.35.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.89.89,0,0,0,.45.41h0a.13.13,0,0,1,.08.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.09,27.66a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.88.88,0,0,1,.36,0,.85.85,0,0,0-.33.09.18.18,0,0,0-.06.25.8.8,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.76,27.14a1,1,0,0,1-.47-.61.29.29,0,0,1,.15-.35.9.9,0,0,1,.36,0,1.14,1.14,0,0,0-.33.08.2.2,0,0,0-.06.26.8.8,0,0,0,.45.42h0a.13.13,0,0,1,.08.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.79,24.18a1,1,0,0,1-.45-.62.28.28,0,0,1,.17-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.21.21,0,0,0-.07.26.88.88,0,0,0,.44.43h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.38,21.49a1,1,0,0,1-.4-.66.28.28,0,0,1,.2-.32.73.73,0,0,1,.36,0,.94.94,0,0,0-.34,0,.21.21,0,0,0-.1.25.85.85,0,0,0,.4.47h0a.13.13,0,0,1-.11.23S20.38,21.5,20.38,21.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.11,19.72a1,1,0,0,1-.32-.7.28.28,0,0,1,.23-.3.83.83,0,0,1,.35.05.78.78,0,0,0-.34,0,.2.2,0,0,0-.12.23.85.85,0,0,0,.35.51h0a.13.13,0,0,1,0,.18.15.15,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,18.7a1,1,0,0,1,0-.78.3.3,0,0,1,.34-.18.88.88,0,0,1,.3.2.83.83,0,0,0-.32-.14.19.19,0,0,0-.2.17.82.82,0,0,0,.1.6h0a.13.13,0,0,1-.21.14A0,0,0,0,1,27,18.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,16.93a1,1,0,0,1,.1-.76.29.29,0,0,1,.35-.14.81.81,0,0,1,.28.24,1,1,0,0,0-.29-.18.2.2,0,0,0-.23.14.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.17.12.12,0,0,1-.16-.06A0,0,0,0,1,29,16.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.67,15.13a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.18.81.81,0,0,1,.3.21.67.67,0,0,0-.31-.14c-.11,0-.18.06-.21.16a.82.82,0,0,0,.1.6h0a.13.13,0,1,1-.22.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.37,14.06a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.2.71.71,0,0,1,.31.19.8.8,0,0,0-.32-.13.2.2,0,0,0-.2.18.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.81,13.53a1,1,0,0,1-.07-.77.28.28,0,0,1,.32-.21.73.73,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.88.88,0,0,0,.15.6h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,32.81,13.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.52,11.77a1,1,0,0,1-.24-.73.29.29,0,0,1,.27-.27.71.71,0,0,1,.35.1.68.68,0,0,0-.34,0,.2.2,0,0,0-.15.22.82.82,0,0,0,.28.54h0a.12.12,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.48,11.78a1,1,0,0,1-.28-.72c0-.13.1-.29.24-.29a.86.86,0,0,1,.36.08.67.67,0,0,0-.34,0,.2.2,0,0,0-.14.23.83.83,0,0,0,.32.52h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.12,11.26a1,1,0,0,1-.35-.69.29.29,0,0,1,.22-.31.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.24.79.79,0,0,0,.36.49h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.43,10.21A1,1,0,0,1,40,9.54a.27.27,0,0,1,.19-.32.62.62,0,0,1,.36,0,.65.65,0,0,0-.34,0,.18.18,0,0,0-.09.24.84.84,0,0,0,.39.47h0a.11.11,0,0,1,.06.16.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.31,10.92a1,1,0,0,1-.4-.66.29.29,0,0,1,.19-.33.86.86,0,0,1,.36,0,.78.78,0,0,0-.34,0,.2.2,0,0,0-.09.25.83.83,0,0,0,.4.46h0a.12.12,0,0,1,.06.16.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.52,10.76a1,1,0,0,1-.42-.65.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.05.21.21,0,0,0-.08.26.82.82,0,0,0,.42.44h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.5,9.94a1,1,0,0,1-.44-.63A.29.29,0,0,1,43.23,9a.93.93,0,0,1,.37,0,.93.93,0,0,0-.34.06.21.21,0,0,0-.08.26.88.88,0,0,0,.43.44h0a.12.12,0,0,1-.09.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.13,10.94a1,1,0,0,1-.44-.63.28.28,0,0,1,.17-.34.63.63,0,0,1,.36,0,.76.76,0,0,0-.34.06.2.2,0,0,0-.07.26.82.82,0,0,0,.43.43h0a.12.12,0,0,1,.07.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.32,10.48a1,1,0,0,1-.46-.63A.29.29,0,0,1,45,9.51a.74.74,0,0,1,.36,0,.73.73,0,0,0-.33.08.18.18,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.13.13,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.06,11.51a1,1,0,0,1-.46-.62.3.3,0,0,1,.16-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.4,12.34a1,1,0,0,1-.47-.62.28.28,0,0,1,.16-.34.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.2.2,0,0,0-.07.26.84.84,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.74,13a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.63.63,0,0,1,.36,0,.72.72,0,0,0-.33.06.19.19,0,0,0-.07.26.82.82,0,0,0,.43.43h0a.13.13,0,0,1-.1.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.63,14.6a1.07,1.07,0,0,1-.33-.7.3.3,0,0,1,.23-.31.74.74,0,0,1,.36.06.83.83,0,0,0-.35,0,.2.2,0,0,0-.11.24.8.8,0,0,0,.35.5h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.33,15.58a1,1,0,0,1-.13-.76.3.3,0,0,1,.3-.24.67.67,0,0,1,.33.15.68.68,0,0,0-.33-.09.2.2,0,0,0-.18.2.86.86,0,0,0,.21.58h0a.13.13,0,0,1-.18.18S52.34,15.58,52.33,15.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.65,16.61a1,1,0,0,1,.16-.75.28.28,0,0,1,.36-.11.78.78,0,0,1,.26.25.82.82,0,0,0-.28-.19.19.19,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.14.14,0,0,1-.16-.07S54.65,16.62,54.65,16.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.78,17.18a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,1.09,1.09,0,0,1,.15.33.8.8,0,0,0-.2-.28.19.19,0,0,0-.26,0,.92.92,0,0,0-.23.57h0a.13.13,0,0,1-.13.13.13.13,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.67,17.4a1,1,0,0,1,.55-.55.28.28,0,0,1,.36.11.61.61,0,0,1,.07.35.73.73,0,0,0-.12-.32.2.2,0,0,0-.26,0,.81.81,0,0,0-.35.51h0a.12.12,0,1,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.4,18a1,1,0,0,1,.58-.51.3.3,0,0,1,.36.14.85.85,0,0,1,0,.35.72.72,0,0,0-.11-.32.2.2,0,0,0-.26-.05.82.82,0,0,0-.38.48h0a.13.13,0,0,1-.16.09.12.12,0,0,1-.09-.15A0,0,0,0,0,63.4,18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,18.73a1,1,0,0,1,.61-.48.28.28,0,0,1,.34.15.64.64,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.13.13,0,1,1-.24-.08A0,0,0,0,1,65,18.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,19.4a1,1,0,0,1,.61-.46.3.3,0,0,1,.35.16.73.73,0,0,1,0,.36.73.73,0,0,0-.08-.33.18.18,0,0,0-.25-.07.88.88,0,0,0-.43.44h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.8,20.2a1,1,0,0,1,.62-.45.28.28,0,0,1,.34.17.73.73,0,0,1,0,.36.94.94,0,0,0-.07-.34.2.2,0,0,0-.26-.07.86.86,0,0,0-.43.43h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.85,20.74a1,1,0,0,1,.63-.44.29.29,0,0,1,.34.17.88.88,0,0,1,0,.36.72.72,0,0,0-.07-.33.19.19,0,0,0-.25-.08.84.84,0,0,0-.44.43h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.12,21.8a1,1,0,0,1,.64-.43.3.3,0,0,1,.34.18.87.87,0,0,1,0,.36.88.88,0,0,0-.06-.33.2.2,0,0,0-.25-.09.85.85,0,0,0-.45.42h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.06-.17A0,0,0,0,1,68.12,21.8Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69,22.52a1,1,0,0,1,.65-.42.28.28,0,0,1,.33.18.72.72,0,0,1,0,.36.76.76,0,0,0-.05-.34.19.19,0,0,0-.25-.08.83.83,0,0,0-.45.41h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.28,23a1,1,0,0,1,.65-.4.29.29,0,0,1,.33.18.77.77,0,0,1,0,.37.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.87.87,0,0,0-.46.4h0a.13.13,0,0,1-.23-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.89,23.83a1,1,0,0,1,.66-.4.28.28,0,0,1,.33.19.88.88,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.83.83,0,0,0-.46.4h0a.12.12,0,0,1-.17.06.11.11,0,0,1-.06-.16S69.89,23.84,69.89,23.83Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.65,24.48a1,1,0,0,1,.66-.4.3.3,0,0,1,.33.19.92.92,0,0,1,0,.37.76.76,0,0,0,0-.34.21.21,0,0,0-.25-.1.83.83,0,0,0-.46.4h0a.13.13,0,0,1-.23-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.55,25.11a1,1,0,0,1,.65-.4.28.28,0,0,1,.33.19.72.72,0,0,1,0,.36.76.76,0,0,0,0-.34.19.19,0,0,0-.25-.09.87.87,0,0,0-.46.4h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17S69.54,25.12,69.55,25.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.39,24.25a1,1,0,0,1,.65-.41.29.29,0,0,1,.33.18.87.87,0,0,1,0,.36.88.88,0,0,0-.05-.33.21.21,0,0,0-.26-.09.82.82,0,0,0-.45.41h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17S68.39,24.26,68.39,24.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.8,23.62a1,1,0,0,1,.64-.42.3.3,0,0,1,.34.17.92.92,0,0,1,0,.37.93.93,0,0,0-.06-.34.19.19,0,0,0-.25-.08.88.88,0,0,0-.45.42h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.36,23.73a1,1,0,0,1,.63-.45.29.29,0,0,1,.34.17.87.87,0,0,1,0,.36.77.77,0,0,0-.07-.34A.2.2,0,0,0,65,23.4a.8.8,0,0,0-.43.44h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.07-.17S64.36,23.74,64.36,23.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.28,23.12a1,1,0,0,1,.61-.48.29.29,0,0,1,.35.15.88.88,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16A0,0,0,0,1,62.28,23.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.67,22.65a1,1,0,0,1,.58-.5.29.29,0,0,1,.36.13.87.87,0,0,1,0,.35.72.72,0,0,0-.11-.32.2.2,0,0,0-.26,0,.85.85,0,0,0-.38.48h0a.13.13,0,0,1-.16.08.11.11,0,0,1-.09-.15A0,0,0,0,0,60.67,22.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.24,23a1,1,0,0,1,.5-.58.29.29,0,0,1,.37.08.64.64,0,0,1,.1.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.85.85,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14S57.24,23,57.24,23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.81,23.41a1,1,0,0,1,.47-.62.3.3,0,0,1,.37.06.78.78,0,0,1,.12.34.75.75,0,0,0-.16-.29.2.2,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.13.13,0,0,1-.26,0S55.81,23.42,55.81,23.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.68,23.27a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.87.87,0,0,1,.14.33.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.55,21a1,1,0,0,1,.4-.66.29.29,0,0,1,.38,0,.92.92,0,0,1,.16.33.75.75,0,0,0-.2-.28.19.19,0,0,0-.26,0,.85.85,0,0,0-.23.57h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.05,21.49a1,1,0,0,1,.33-.69.29.29,0,0,1,.38,0,.77.77,0,0,1,.19.31.71.71,0,0,0-.22-.26.19.19,0,0,0-.26.06.83.83,0,0,0-.17.58h0a.12.12,0,0,1-.11.14.12.12,0,0,1-.14-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.65,20.71A1,1,0,0,1,55,20a.29.29,0,0,1,.38,0,.9.9,0,0,1,.18.31.8.8,0,0,0-.22-.26.19.19,0,0,0-.26.06.78.78,0,0,0-.17.58h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.09,22.39a1,1,0,0,1,.37-.67.28.28,0,0,1,.38,0A.79.79,0,0,1,55,22a.82.82,0,0,0-.21-.27.2.2,0,0,0-.26,0,.86.86,0,0,0-.2.58h0a.11.11,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.48,22.2a1,1,0,0,1,.43-.64.29.29,0,0,1,.38,0,.85.85,0,0,1,.14.34.73.73,0,0,0-.19-.29.19.19,0,0,0-.26,0,.85.85,0,0,0-.25.56h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,24.58a1,1,0,0,1,.52-.57.28.28,0,0,1,.37.1.73.73,0,0,1,.09.35.67.67,0,0,0-.14-.32.2.2,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.45,26.27a1,1,0,0,1,.58-.51.3.3,0,0,1,.36.14.89.89,0,0,1,0,.36.71.71,0,0,0-.1-.33.2.2,0,0,0-.26-.05.85.85,0,0,0-.39.47h0a.13.13,0,0,1-.16.09.13.13,0,0,1-.08-.16S59.44,26.27,59.45,26.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.21,28.93a1,1,0,0,1,.62-.45.29.29,0,0,1,.35.16.88.88,0,0,1,0,.36.72.72,0,0,0-.07-.33.18.18,0,0,0-.25-.07.79.79,0,0,0-.43.43h0a.14.14,0,0,1-.17.08A.13.13,0,0,1,62.2,29,0,0,0,0,0,62.21,28.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.41,30a1,1,0,0,1,.64-.43.3.3,0,0,1,.34.17.87.87,0,0,1,0,.36.88.88,0,0,0-.06-.33.19.19,0,0,0-.25-.08.81.81,0,0,0-.45.42h0a.13.13,0,0,1-.17.06A.12.12,0,0,1,64.4,30Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.3,31.21a1,1,0,0,1,.65-.42.29.29,0,0,1,.33.18.73.73,0,0,1,0,.36.61.61,0,0,0-.06-.33.2.2,0,0,0-.25-.09.88.88,0,0,0-.45.42h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.57,30.73a1,1,0,0,1,.65-.42.29.29,0,0,1,.33.19.72.72,0,0,1,0,.36.76.76,0,0,0-.05-.34.19.19,0,0,0-.25-.08.79.79,0,0,0-.45.41h0a.12.12,0,0,1-.16.07.13.13,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.56,31.76a.94.94,0,0,1,.65-.41.29.29,0,0,1,.33.18.72.72,0,0,1,0,.36.72.72,0,0,0,0-.33.2.2,0,0,0-.25-.09.79.79,0,0,0-.45.41h0a.13.13,0,1,1-.23-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.39,30.17a1,1,0,0,1,.65-.4.28.28,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.79.79,0,0,0-.45.41h0a.13.13,0,0,1-.23-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.82,29.76a1,1,0,0,1,.65-.41.28.28,0,0,1,.33.18.87.87,0,0,1,0,.36.65.65,0,0,0-.06-.34.19.19,0,0,0-.25-.08.82.82,0,0,0-.45.41h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.35,28.33a1,1,0,0,1,.63-.44.27.27,0,0,1,.34.17.63.63,0,0,1,0,.36.76.76,0,0,0-.06-.34A.19.19,0,0,0,64,28a.82.82,0,0,0-.43.43h0a.13.13,0,0,1-.16.07.13.13,0,0,1-.07-.17S63.35,28.34,63.35,28.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.44,26.8a1,1,0,0,1,.61-.47.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36.73.73,0,0,0-.08-.33.19.19,0,0,0-.26-.06.82.82,0,0,0-.42.44h0a.13.13,0,1,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.52,25.11a1,1,0,0,1,.58-.51.29.29,0,0,1,.36.13.89.89,0,0,1,0,.36.71.71,0,0,0-.1-.33.2.2,0,0,0-.26-.05.81.81,0,0,0-.38.48h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.34,23.88a1,1,0,0,1,.54-.54.3.3,0,0,1,.37.11.87.87,0,0,1,.07.35.75.75,0,0,0-.13-.32.2.2,0,0,0-.26,0,.83.83,0,0,0-.35.5h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15A0,0,0,0,1,58.34,23.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.35,22.58a1,1,0,0,1,.54-.56.3.3,0,0,1,.36.1.78.78,0,0,1,.08.36.66.66,0,0,0-.13-.32.2.2,0,0,0-.26,0,.84.84,0,0,0-.34.51h0a.14.14,0,0,1-.15.11.12.12,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.43,21.58a1,1,0,0,1,.48-.6.29.29,0,0,1,.38.07.88.88,0,0,1,.1.35.69.69,0,0,0-.15-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.3.53h0a.13.13,0,1,1-.25,0S57.42,21.58,57.43,21.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.54,20.39a1,1,0,0,1,.47-.61.29.29,0,0,1,.38.06.9.9,0,0,1,.11.34.79.79,0,0,0-.16-.3.21.21,0,0,0-.27,0,.78.78,0,0,0-.28.54h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.49,21.42a1,1,0,0,1,.52-.57.28.28,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.18.18,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.14.14,0,0,1-.15.11.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.89,20.56a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.14.14,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.74,21.68a1,1,0,0,1,.55-.54.31.31,0,0,1,.37.11.76.76,0,0,1,.06.36.76.76,0,0,0-.11-.32.2.2,0,0,0-.27,0,.89.89,0,0,0-.36.5h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15A0,0,0,0,0,59.74,21.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.77,23.45a1,1,0,0,1,.57-.52.29.29,0,0,1,.36.13.85.85,0,0,1,0,.35.72.72,0,0,0-.11-.32.19.19,0,0,0-.26,0,.85.85,0,0,0-.38.48h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.15A0,0,0,0,1,59.77,23.45Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.11,25.23a1,1,0,0,1,.6-.49.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.63.63,0,0,0-.09-.33.19.19,0,0,0-.25-.06.87.87,0,0,0-.41.46h0a.13.13,0,0,1-.16.08.12.12,0,0,1-.08-.16S61.1,25.24,61.11,25.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.53,27.71a1,1,0,0,1,.62-.46.28.28,0,0,1,.34.17.73.73,0,0,1,0,.36.77.77,0,0,0-.07-.34.21.21,0,0,0-.26-.07.82.82,0,0,0-.42.44h0a.11.11,0,0,1-.16.07.11.11,0,0,1-.07-.16S62.52,27.71,62.53,27.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.33,30.42A1,1,0,0,1,64,30a.29.29,0,0,1,.34.18.63.63,0,0,1,0,.36.76.76,0,0,0-.06-.34.19.19,0,0,0-.26-.07.8.8,0,0,0-.43.42h0a.11.11,0,0,1-.16.07.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.56,32.74a1,1,0,0,1,.65-.42.29.29,0,0,1,.33.18.73.73,0,0,1,0,.36.61.61,0,0,0-.06-.33.2.2,0,0,0-.25-.09.88.88,0,0,0-.45.42h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16S65.56,32.75,65.56,32.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.85,33.63a.94.94,0,0,1,.64-.41.3.3,0,0,1,.34.18.72.72,0,0,1,0,.36.72.72,0,0,0,0-.33.2.2,0,0,0-.25-.09.79.79,0,0,0-.45.41h0a.13.13,0,0,1-.23-.11S66.84,33.64,66.85,33.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.43,34.94a1,1,0,0,1,.65-.42.3.3,0,0,1,.34.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.19.19,0,0,0-.25-.08.76.76,0,0,0-.45.41h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17S67.43,35,67.43,34.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.89,34.12a1,1,0,0,1,.66-.41.29.29,0,0,1,.33.19.87.87,0,0,1,0,.36.76.76,0,0,0-.05-.34.2.2,0,0,0-.25-.09.85.85,0,0,0-.46.41h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17S67.89,34.13,67.89,34.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.82,33.92a1,1,0,0,1,.66-.4.27.27,0,0,1,.32.19.72.72,0,0,1,0,.36.78.78,0,0,0,0-.34.2.2,0,0,0-.25-.09.85.85,0,0,0-.46.41h0a.12.12,0,1,1-.22-.1A0,0,0,0,1,68.82,33.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.86,31.15a1,1,0,0,1,.66-.41.3.3,0,0,1,.33.19.86.86,0,0,1,0,.36.72.72,0,0,0,0-.33.2.2,0,0,0-.25-.09.8.8,0,0,0-.46.4h0a.12.12,0,0,1-.16.06.12.12,0,0,1-.06-.17S67.85,31.15,67.86,31.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.15,26.14a1,1,0,0,1,.64-.44.29.29,0,0,1,.34.17.73.73,0,0,1,0,.36.76.76,0,0,0-.06-.34.19.19,0,0,0-.25-.07.81.81,0,0,0-.44.43h0a.13.13,0,1,1-.24-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.09,19.22a1,1,0,0,1,.53-.56.3.3,0,0,1,.36.1.74.74,0,0,1,.08.35.66.66,0,0,0-.13-.31.18.18,0,0,0-.26,0,.81.81,0,0,0-.34.5h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,60.09,19.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.47,16.19a1,1,0,0,1,.35-.69.29.29,0,0,1,.38,0,.85.85,0,0,1,.18.32.73.73,0,0,0-.22-.27.19.19,0,0,0-.26.06.84.84,0,0,0-.18.58h0a.12.12,0,0,1-.11.14.14.14,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.7,15a1,1,0,0,1,.25-.73.28.28,0,0,1,.37-.06.74.74,0,0,1,.22.28.78.78,0,0,0-.25-.23.21.21,0,0,0-.25.09.82.82,0,0,0-.09.61h0a.12.12,0,0,1-.09.15.11.11,0,0,1-.15-.09A0,0,0,0,1,56.7,15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.9,12.59a1.07,1.07,0,0,1,.05-.77.29.29,0,0,1,.35-.15.86.86,0,0,1,.29.21.84.84,0,0,0-.3-.15.19.19,0,0,0-.22.15.77.77,0,0,0,.06.6h0a.11.11,0,0,1-.05.17.12.12,0,0,1-.17,0A0,0,0,0,0,55.9,12.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.37,13.51a1,1,0,0,1,.05-.77.28.28,0,0,1,.34-.16.64.64,0,0,1,.29.22.63.63,0,0,0-.3-.16.19.19,0,0,0-.22.15.85.85,0,0,0,.07.61h0a.13.13,0,1,1-.22.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.83,12.62a.92.92,0,0,1-.06-.76.28.28,0,0,1,.32-.21.73.73,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.76.76,0,0,0,.15.59h0a.13.13,0,1,1-.2.16S54.83,12.63,54.83,12.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,13.72a1.07,1.07,0,0,1,.22-.74.28.28,0,0,1,.37-.07.69.69,0,0,1,.24.27.9.9,0,0,0-.26-.22.2.2,0,0,0-.25.1.87.87,0,0,0-.07.61h0a.12.12,0,0,1-.08.15.12.12,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.08,15.53a1,1,0,0,1,.42-.65.29.29,0,0,1,.38,0,.87.87,0,0,1,.14.33.9.9,0,0,0-.18-.28.21.21,0,0,0-.27,0,.83.83,0,0,0-.24.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.91,16.55a1,1,0,0,1,.51-.58.3.3,0,0,1,.37.09.73.73,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.9.9,0,0,0-.32.52h0a.13.13,0,1,1-.25,0A0,0,0,0,1,60.91,16.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.79,18.85a1,1,0,0,1,.58-.51.29.29,0,0,1,.36.13.89.89,0,0,1,0,.36.61.61,0,0,0-.1-.33.19.19,0,0,0-.26,0,.83.83,0,0,0-.38.47h0a.11.11,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.09,20.07a1,1,0,0,1,.6-.48.29.29,0,0,1,.35.15.75.75,0,0,1,0,.36.76.76,0,0,0-.08-.33.2.2,0,0,0-.26-.06.84.84,0,0,0-.41.46h0a.13.13,0,0,1-.16.07.11.11,0,0,1-.08-.15A0,0,0,0,1,64.09,20.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.12,21.39a1,1,0,0,1,.62-.45.29.29,0,0,1,.34.16.73.73,0,0,1,0,.36.84.84,0,0,0-.07-.33.2.2,0,0,0-.26-.07.81.81,0,0,0-.43.44h0a.13.13,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.83,23.13a1,1,0,0,1,.63-.43.27.27,0,0,1,.34.17.87.87,0,0,1,0,.36.77.77,0,0,0-.07-.34.19.19,0,0,0-.25-.07.82.82,0,0,0-.44.42h0a.12.12,0,0,1-.17.07.12.12,0,0,1-.06-.16A0,0,0,0,1,65.83,23.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.1,24.55a1,1,0,0,1,.64-.43.3.3,0,0,1,.34.18.87.87,0,0,1,0,.36.88.88,0,0,0-.06-.33.19.19,0,0,0-.25-.08.78.78,0,0,0-.45.42h0a.12.12,0,0,1-.17.07.12.12,0,0,1-.06-.16A0,0,0,0,1,66.1,24.55Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.32,25.49a1,1,0,0,1,.65-.42.29.29,0,0,1,.33.19.87.87,0,0,1,0,.36.76.76,0,0,0-.06-.34A.19.19,0,0,0,68,25.2a.82.82,0,0,0-.45.41h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17S67.32,25.5,67.32,25.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.39,25.49a1,1,0,0,1,.64-.42.29.29,0,0,1,.34.18.87.87,0,0,1,0,.36.93.93,0,0,0-.06-.34.19.19,0,0,0-.25-.08.81.81,0,0,0-.45.42h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.06-.17A0,0,0,0,1,66.39,25.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.31,22.31a1,1,0,0,1,.62-.46.3.3,0,0,1,.35.16.88.88,0,0,1,0,.36.75.75,0,0,0-.07-.33A.2.2,0,0,0,65,22a.82.82,0,0,0-.42.44h0a.14.14,0,0,1-.17.08.14.14,0,0,1-.07-.17A0,0,0,0,0,64.31,22.31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.79,18.14a1,1,0,0,1,.53-.56.27.27,0,0,1,.36.1.74.74,0,0,1,.08.35.58.58,0,0,0-.13-.31.19.19,0,0,0-.26,0,.84.84,0,0,0-.34.51h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15S60.78,18.15,60.79,18.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.82,15.57a1,1,0,0,1,.2-.74.29.29,0,0,1,.37-.09.8.8,0,0,1,.24.28.77.77,0,0,0-.26-.22.2.2,0,0,0-.25.11.84.84,0,0,0-.06.6h0a.13.13,0,0,1-.09.16.12.12,0,0,1-.15-.08A0,0,0,0,1,55.82,15.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.32,13.72a1,1,0,0,1-.15-.76.28.28,0,0,1,.29-.24.88.88,0,0,1,.33.13.78.78,0,0,0-.33-.07.2.2,0,0,0-.17.2.81.81,0,0,0,.22.57h0a.14.14,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.68,12.38a1,1,0,0,1-.28-.72.29.29,0,0,1,.24-.29.86.86,0,0,1,.36.08.8.8,0,0,0-.34,0,.2.2,0,0,0-.14.23.86.86,0,0,0,.32.53h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.07,11.64a1,1,0,0,1-.35-.69.29.29,0,0,1,.22-.31.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.79.79,0,0,0,.36.49h0a.12.12,0,0,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,13a1,1,0,0,1-.32-.7.29.29,0,0,1,.24-.3.68.68,0,0,1,.35.06.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.8.8,0,0,0,.34.51h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.65,14.39a1,1,0,0,1,0-.77.31.31,0,0,1,.35-.17.86.86,0,0,1,.29.21.69.69,0,0,0-.31-.15c-.11,0-.18.06-.21.16a.82.82,0,0,0,.08.6h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.08,14.54a1,1,0,0,1,.44-.64.3.3,0,0,1,.38,0,.89.89,0,0,1,.13.34.71.71,0,0,0-.18-.29.18.18,0,0,0-.26,0,.77.77,0,0,0-.26.55h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.17,15.69a1,1,0,0,1,.57-.52.29.29,0,0,1,.36.12.75.75,0,0,1,.05.36.85.85,0,0,0-.1-.33.21.21,0,0,0-.27,0,.82.82,0,0,0-.37.49h0a.14.14,0,0,1-.16.1.14.14,0,0,1-.09-.16S64.17,15.7,64.17,15.69Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.29,16.68a1,1,0,0,1,.61-.47.3.3,0,0,1,.35.16.73.73,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.07.86.86,0,0,0-.41.45h0a.12.12,0,0,1-.23-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.1,17.78a1,1,0,0,1,.64-.44.29.29,0,0,1,.34.18.73.73,0,0,1,0,.36.76.76,0,0,0-.06-.34.19.19,0,0,0-.25-.08.84.84,0,0,0-.44.43h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.79,19.43a1,1,0,0,1,.65-.42.28.28,0,0,1,.33.18.87.87,0,0,1,0,.36.76.76,0,0,0-.06-.34.19.19,0,0,0-.25-.08.86.86,0,0,0-.45.41h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.55,18.25a1,1,0,0,1,.64-.42.29.29,0,0,1,.34.17.87.87,0,0,1,0,.36.92.92,0,0,0-.06-.33.19.19,0,0,0-.25-.08.88.88,0,0,0-.45.42h0a.12.12,0,0,1-.17.07.12.12,0,0,1-.06-.16S72.55,18.26,72.55,18.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.78,18.9a1,1,0,0,1,.64-.43.29.29,0,0,1,.34.18.87.87,0,0,1,0,.36.76.76,0,0,0-.06-.34.2.2,0,0,0-.25-.08A.88.88,0,0,0,72,19h0a.14.14,0,0,1-.17.07.13.13,0,0,1-.06-.17A0,0,0,0,1,71.78,18.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.53,17.64a1,1,0,0,1,.63-.45.3.3,0,0,1,.34.17.87.87,0,0,1,0,.36.7.7,0,0,0-.07-.33.19.19,0,0,0-.25-.08.84.84,0,0,0-.43.44h0a.13.13,0,0,1-.16.07.13.13,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.21,14.84a1,1,0,0,1,.57-.51.28.28,0,0,1,.36.12.75.75,0,0,1,0,.36.8.8,0,0,0-.1-.32.22.22,0,0,0-.27-.05.82.82,0,0,0-.37.48h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.15A0,0,0,0,1,65.21,14.84Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.58,11.14a1,1,0,0,1,.24-.73.28.28,0,0,1,.37-.07.77.77,0,0,1,.23.28.94.94,0,0,0-.26-.23.2.2,0,0,0-.25.1.8.8,0,0,0-.08.6h0a.13.13,0,0,1-.09.16.11.11,0,0,1-.15-.09A0,0,0,0,1,58.58,11.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,10.42a1,1,0,0,1,0-.77.29.29,0,0,1,.32-.2.79.79,0,0,1,.31.18,1,1,0,0,0-.32-.12.19.19,0,0,0-.19.18.83.83,0,0,0,.13.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S56.05,10.43,56,10.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.88,9.46a1,1,0,0,1-.19-.75c0-.13.15-.27.28-.25a.63.63,0,0,1,.34.12A.65.65,0,0,0,55,8.52a.2.2,0,0,0-.16.21.85.85,0,0,0,.24.56h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.19,10A1,1,0,0,1,54,9.29.29.29,0,0,1,54.22,9a1,1,0,0,1,.35.1.67.67,0,0,0-.34,0c-.11,0-.15.11-.15.22a.84.84,0,0,0,.28.54h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.93,9.68a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.81.81,0,0,1,.3.21.69.69,0,0,0-.31-.15.18.18,0,0,0-.21.16.81.81,0,0,0,.08.6h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.44,10a1,1,0,0,1,.38-.67.29.29,0,0,1,.38,0,.74.74,0,0,1,.16.32.68.68,0,0,0-.2-.27.2.2,0,0,0-.26,0,.87.87,0,0,0-.21.58h0a.13.13,0,1,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.28,10.76a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.1.72.72,0,0,1,.08.35.83.83,0,0,0-.13-.32.2.2,0,0,0-.27,0,.82.82,0,0,0-.33.51h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,66.28,10.76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.55,11.87a1,1,0,0,1,.58-.5.29.29,0,0,1,.36.13.75.75,0,0,1,.05.36.85.85,0,0,0-.1-.33.21.21,0,0,0-.27,0,.85.85,0,0,0-.38.48h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16A0,0,0,0,0,70.55,11.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.16,12.24a1,1,0,0,1,.6-.48.28.28,0,0,1,.35.14.89.89,0,0,1,0,.36.71.71,0,0,0-.1-.33.19.19,0,0,0-.25-.06.87.87,0,0,0-.41.46h0a.13.13,0,0,1-.16.09.14.14,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.19,12.68a1,1,0,0,1,.61-.48.3.3,0,0,1,.35.15.88.88,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.51,13.86a1,1,0,0,1,.62-.46.29.29,0,0,1,.35.16.88.88,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.07.82.82,0,0,0-.42.44h0a.13.13,0,0,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.35,15.12a1,1,0,0,1,.62-.46.3.3,0,0,1,.35.17.87.87,0,0,1,0,.36.7.7,0,0,0-.07-.33.18.18,0,0,0-.25-.07.79.79,0,0,0-.43.43h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.07-.16S72.35,15.13,72.35,15.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.84,14.61a1,1,0,0,1,.61-.47.28.28,0,0,1,.35.16.88.88,0,0,1,0,.36,1.21,1.21,0,0,0-.08-.34.2.2,0,0,0-.26-.06.8.8,0,0,0-.42.45h0a.12.12,0,0,1-.16.07.12.12,0,0,1-.08-.16S70.84,14.62,70.84,14.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.32,14.07a1,1,0,0,1,.6-.49.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.7.7,0,0,0-.09-.33A.2.2,0,0,0,70,13.7a.89.89,0,0,0-.4.46h0a.13.13,0,1,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.17,12.78a1.06,1.06,0,0,1,.55-.55.28.28,0,0,1,.36.11.63.63,0,0,1,.07.35.65.65,0,0,0-.12-.31.19.19,0,0,0-.26,0,.77.77,0,0,0-.35.5h0a.12.12,0,0,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.9,12a1,1,0,0,1,.49-.6.29.29,0,0,1,.37.06.76.76,0,0,1,.11.35.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.89.89,0,0,0-.3.54h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.23,12.37a1,1,0,0,1,.43-.64.3.3,0,0,1,.38,0,.84.84,0,0,1,.13.33.68.68,0,0,0-.18-.29.2.2,0,0,0-.26,0,.85.85,0,0,0-.25.56h0a.13.13,0,0,1-.13.13.14.14,0,0,1-.13-.13S61.23,12.38,61.23,12.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.24,12.13a1,1,0,0,1,.38-.68.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32.68.68,0,0,0-.2-.27.19.19,0,0,0-.26,0,.85.85,0,0,0-.21.58h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.65,12.91a1,1,0,0,1,.49-.59.28.28,0,0,1,.37.07.64.64,0,0,1,.1.35.69.69,0,0,0-.15-.31.19.19,0,0,0-.26,0,.79.79,0,0,0-.31.52h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.61,12.87a1,1,0,0,1,.56-.52.29.29,0,0,1,.36.12.72.72,0,0,1,.06.35.72.72,0,0,0-.11-.32.2.2,0,0,0-.27,0,.84.84,0,0,0-.36.49h0a.11.11,0,0,1-.15.09.12.12,0,0,1-.09-.15A0,0,0,0,1,66.61,12.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.33,14a1,1,0,0,1,.61-.47.29.29,0,0,1,.35.16.88.88,0,0,1,0,.36.77.77,0,0,0-.08-.34.2.2,0,0,0-.26-.06.84.84,0,0,0-.41.44h0a.12.12,0,0,1-.16.08.14.14,0,0,1-.08-.16S72.32,14,72.33,14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M75.58,14.71a1,1,0,0,1,.63-.45.29.29,0,0,1,.34.17.63.63,0,0,1,0,.36.7.7,0,0,0-.06-.33.21.21,0,0,0-.26-.08.86.86,0,0,0-.43.43h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.13,14.92a1,1,0,0,1,.64-.44.29.29,0,0,1,.34.17.73.73,0,0,1,0,.36.76.76,0,0,0-.06-.34.2.2,0,0,0-.26-.07.82.82,0,0,0-.43.43h0a.11.11,0,0,1-.16.07.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78,14a1,1,0,0,1,.63-.44.28.28,0,0,1,.34.17.87.87,0,0,1,0,.36.77.77,0,0,0-.07-.34.18.18,0,0,0-.25-.07.84.84,0,0,0-.44.43h0a.12.12,0,0,1-.16.07A.11.11,0,0,1,78,14,0,0,0,0,1,78,14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.18,15.64a1.07,1.07,0,0,1,.64-.43.29.29,0,0,1,.34.18.73.73,0,0,1,0,.36.65.65,0,0,0-.06-.34.19.19,0,0,0-.25-.07.82.82,0,0,0-.44.42h0a.12.12,0,0,1-.16.06.11.11,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.71,14.67a1,1,0,0,1,.63-.44.29.29,0,0,1,.34.17.74.74,0,0,1,0,.36.88.88,0,0,0-.06-.33.21.21,0,0,0-.26-.08.82.82,0,0,0-.43.43h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.05,15.8a1,1,0,0,1,.63-.43.29.29,0,0,1,.34.17.73.73,0,0,1,0,.36.72.72,0,0,0,0-.33.2.2,0,0,0-.26-.08.82.82,0,0,0-.44.42h0a.13.13,0,1,1-.24-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.37,16.79a1,1,0,0,1,.64-.42.29.29,0,0,1,.34.18.87.87,0,0,1,0,.36.93.93,0,0,0-.06-.34.19.19,0,0,0-.25-.08.83.83,0,0,0-.45.41h0a.13.13,0,1,1-.23-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.05,17.41A1,1,0,0,1,77.7,17a.28.28,0,0,1,.33.18.73.73,0,0,1,0,.36.65.65,0,0,0-.06-.34.19.19,0,0,0-.25-.08.88.88,0,0,0-.45.42h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.06-.17S77.05,17.42,77.05,17.41Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.34,19a1,1,0,0,1,.65-.41.29.29,0,0,1,.33.19.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.87.87,0,0,0-.45.41h0a.12.12,0,0,1-.16.06.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.81,22.21a1,1,0,0,1,.66-.39c.13,0,.31.06.33.19a.62.62,0,0,1,0,.36.65.65,0,0,0,0-.34.19.19,0,0,0-.25-.09.83.83,0,0,0-.46.4h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73,26.63a1,1,0,0,1,.67-.38.3.3,0,0,1,.33.2.85.85,0,0,1,0,.36,1,1,0,0,0,0-.34.21.21,0,0,0-.25-.1.84.84,0,0,0-.47.39h0a.13.13,0,0,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.75,31a1,1,0,0,1,.67-.38.28.28,0,0,1,.32.2.87.87,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.24-.1.85.85,0,0,0-.48.39h0a.12.12,0,0,1-.17.06.13.13,0,0,1-.06-.17S71.74,31,71.75,31Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.35,35.3a1,1,0,0,1,.66-.41.3.3,0,0,1,.33.19.86.86,0,0,1,0,.36.72.72,0,0,0,0-.33A.19.19,0,0,0,71,35a.83.83,0,0,0-.46.4h0a.13.13,0,1,1-.23-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.44,38.24a1,1,0,0,1,.64-.43.3.3,0,0,1,.34.17.87.87,0,0,1,0,.36.88.88,0,0,0-.06-.33.19.19,0,0,0-.25-.08.81.81,0,0,0-.45.42h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16S67.43,38.24,67.44,38.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.75,39.89a1,1,0,0,1,.62-.45.28.28,0,0,1,.34.17.73.73,0,0,1,0,.36.94.94,0,0,0-.07-.34.2.2,0,0,0-.26-.07A.86.86,0,0,0,66,40h0a.12.12,0,0,1-.16.07.12.12,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.89,40.87a1,1,0,0,1,.62-.46.3.3,0,0,1,.35.16.74.74,0,0,1,0,.36.73.73,0,0,0-.08-.33.18.18,0,0,0-.25-.07.88.88,0,0,0-.43.44h0a.13.13,0,0,1-.16.08.14.14,0,0,1-.08-.17S64.89,40.88,64.89,40.87Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.63,42.25a1,1,0,0,1,.6-.48.3.3,0,0,1,.35.16.74.74,0,0,1,0,.36.7.7,0,0,0-.08-.33.2.2,0,0,0-.26-.07.85.85,0,0,0-.41.45h0a.12.12,0,0,1-.16.08.14.14,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.87,41.71a1,1,0,0,1,.61-.47.28.28,0,0,1,.35.16.88.88,0,0,1,0,.36,1,1,0,0,0-.09-.34.19.19,0,0,0-.25-.06.84.84,0,0,0-.42.45h0a.14.14,0,0,1-.16.08.13.13,0,0,1-.08-.16S63.87,41.72,63.87,41.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.61,40.92a1,1,0,0,1,.63-.45.29.29,0,0,1,.34.16.87.87,0,0,1,0,.36.7.7,0,0,0-.07-.33.18.18,0,0,0-.25-.07.79.79,0,0,0-.43.43h0a.12.12,0,0,1-.17.07.12.12,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.75,38.7a1.07,1.07,0,0,1,.64-.43.29.29,0,0,1,.34.18.73.73,0,0,1,0,.36.65.65,0,0,0-.06-.34.2.2,0,0,0-.25-.08.86.86,0,0,0-.44.42h0a.11.11,0,0,1-.16.07.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.4,33.88a1,1,0,0,1,.66-.39.31.31,0,0,1,.33.2.86.86,0,0,1,0,.36.78.78,0,0,0,0-.34.2.2,0,0,0-.25-.09.83.83,0,0,0-.47.39h0a.13.13,0,0,1-.23-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.61,31.2a1,1,0,0,1,.67-.37.29.29,0,0,1,.32.21.74.74,0,0,1,0,.36.64.64,0,0,0,0-.34A.19.19,0,0,0,75.3,31a.88.88,0,0,0-.48.38h0a.13.13,0,0,1-.18.05.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.53,29.13a1,1,0,0,1,.68-.36.29.29,0,0,1,.32.21.85.85,0,0,1,0,.36.77.77,0,0,0,0-.34.2.2,0,0,0-.24-.11.84.84,0,0,0-.49.37h0a.12.12,0,0,1-.17.05.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M78.16,27.69a1,1,0,0,1,.67-.37.3.3,0,0,1,.32.21.72.72,0,0,1,0,.36.64.64,0,0,0,0-.34.18.18,0,0,0-.24-.1.84.84,0,0,0-.48.38h0a.13.13,0,0,1-.18.06.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.38,26.05a1,1,0,0,1,.67-.38.29.29,0,0,1,.32.2.74.74,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.1.8.8,0,0,0-.47.39h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.17A0,0,0,0,1,79.38,26.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M80.06,25.09a1,1,0,0,1,.66-.39.3.3,0,0,1,.33.2.88.88,0,0,1,0,.36.76.76,0,0,0,0-.34.21.21,0,0,0-.25-.1.87.87,0,0,0-.47.39h0a.13.13,0,0,1-.23-.11S80.05,25.09,80.06,25.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M79.09,24.55a1,1,0,0,1,.67-.38.28.28,0,0,1,.32.2.73.73,0,0,1,0,.36.76.76,0,0,0,0-.34.18.18,0,0,0-.24-.1.84.84,0,0,0-.47.39h0a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M77.58,26.34a1,1,0,0,1,.67-.37.28.28,0,0,1,.32.2.72.72,0,0,1,0,.36.64.64,0,0,0,0-.34.19.19,0,0,0-.24-.1.84.84,0,0,0-.48.38h0a.13.13,0,0,1-.17.05.12.12,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.75,29.18a1,1,0,0,1,.68-.37.29.29,0,0,1,.32.2.85.85,0,0,1,0,.36.66.66,0,0,0,0-.34.19.19,0,0,0-.24-.1.81.81,0,0,0-.48.38h0a.13.13,0,0,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.05,32.32a1.06,1.06,0,0,1,.67-.39.29.29,0,0,1,.32.2.62.62,0,0,1,0,.36.78.78,0,0,0,0-.34.2.2,0,0,0-.25-.09.8.8,0,0,0-.47.39h0a.12.12,0,0,1-.16,0,.12.12,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.61,35.57a1,1,0,0,1,.65-.41.3.3,0,0,1,.34.19.87.87,0,0,1,0,.36.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.89.89,0,0,0-.46.41h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65,37.79a1.06,1.06,0,0,1,.64-.44.3.3,0,0,1,.34.17.77.77,0,0,1,0,.37.76.76,0,0,0-.06-.34.18.18,0,0,0-.25-.07.79.79,0,0,0-.44.42h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.07-.16A0,0,0,0,0,65,37.79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.64,40a1,1,0,0,1,.61-.48.29.29,0,0,1,.35.15.9.9,0,0,1,0,.36.9.9,0,0,0-.09-.33.19.19,0,0,0-.25-.06.84.84,0,0,0-.42.45h0a.13.13,0,0,1-.24-.08A0,0,0,0,1,61.64,40Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.14,41.61a1,1,0,0,1,.59-.49.29.29,0,0,1,.35.14.64.64,0,0,1,0,.36.7.7,0,0,0-.09-.33.19.19,0,0,0-.26,0,.85.85,0,0,0-.4.46h0a.13.13,0,0,1-.16.09.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.81,43a1,1,0,0,1,.58-.51.29.29,0,0,1,.35.13.75.75,0,0,1,0,.36.67.67,0,0,0-.1-.32.18.18,0,0,0-.26-.05.85.85,0,0,0-.39.47h0a.12.12,0,0,1-.15.09A.13.13,0,0,1,59.8,43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.39,44.05a1,1,0,0,1,.57-.52.28.28,0,0,1,.36.12.75.75,0,0,1,0,.36.75.75,0,0,0-.1-.32.22.22,0,0,0-.27,0,.82.82,0,0,0-.37.49h0a.13.13,0,0,1-.16.09.11.11,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.5,44a1,1,0,0,1,.56-.54.29.29,0,0,1,.36.12.7.7,0,0,1,.06.35.72.72,0,0,0-.11-.32.2.2,0,0,0-.27,0,.84.84,0,0,0-.36.49h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.71,43.1a1,1,0,0,1,.53-.56.31.31,0,0,1,.37.1.78.78,0,0,1,.08.36.77.77,0,0,0-.13-.32.18.18,0,0,0-.26,0,.83.83,0,0,0-.35.5h0a.12.12,0,0,1-.14.1.11.11,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.41,38.88a1,1,0,0,1,.47-.61.29.29,0,0,1,.38.06.93.93,0,0,1,.12.34,1,1,0,0,0-.17-.3.2.2,0,0,0-.26,0,.79.79,0,0,0-.29.54h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.09,34.34a1,1,0,0,1,.36-.68.3.3,0,0,1,.38,0A.71.71,0,0,1,49,34a.71.71,0,0,0-.21-.27.19.19,0,0,0-.26,0,.79.79,0,0,0-.19.58h0a.13.13,0,0,1-.12.13.11.11,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.74,31.43A1,1,0,0,1,47,30.7a.28.28,0,0,1,.37-.08.89.89,0,0,1,.23.29.77.77,0,0,0-.25-.23.2.2,0,0,0-.25.1.8.8,0,0,0-.09.6h0a.13.13,0,0,1-.09.16.14.14,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.44,29.8a1,1,0,0,1,.15-.76.3.3,0,0,1,.37-.11.88.88,0,0,1,.25.26.69.69,0,0,0-.28-.2.19.19,0,0,0-.23.12.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.12.12,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.6,28.66a1,1,0,0,1,.06-.76.28.28,0,0,1,.34-.16.82.82,0,0,1,.29.22.8.8,0,0,0-.3-.16.19.19,0,0,0-.22.15.8.8,0,0,0,.06.61h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.17,0S45.6,28.67,45.6,28.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.05,26.69a1,1,0,0,1-.06-.77.31.31,0,0,1,.32-.21.92.92,0,0,1,.32.18.86.86,0,0,0-.33-.11c-.11,0-.17.08-.19.18a.85.85,0,0,0,.15.59h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.63,25.33a1,1,0,0,1-.14-.76.29.29,0,0,1,.29-.24.79.79,0,0,1,.34.14.84.84,0,0,0-.34-.08.2.2,0,0,0-.17.2.9.9,0,0,0,.21.58h0a.13.13,0,0,1-.18.18S44.64,25.33,44.63,25.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.15,25.16a1,1,0,0,1-.22-.74.3.3,0,0,1,.27-.27.8.8,0,0,1,.34.11.67.67,0,0,0-.34,0,.19.19,0,0,0-.15.21.83.83,0,0,0,.27.55h0a.13.13,0,0,1-.17.19A0,0,0,0,1,43.15,25.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.73,24.11a1,1,0,0,1-.26-.72c0-.13.12-.29.26-.28a.82.82,0,0,1,.35.08.8.8,0,0,0-.34,0,.18.18,0,0,0-.14.22.84.84,0,0,0,.3.54h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.74,24.67a1,1,0,0,1-.28-.72.31.31,0,0,1,.25-.29.86.86,0,0,1,.36.08.83.83,0,0,0-.35,0,.19.19,0,0,0-.13.22.8.8,0,0,0,.31.53h0a.12.12,0,0,1-.14.2A0,0,0,0,1,41.74,24.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.49,23.37a1,1,0,0,1-.29-.71.29.29,0,0,1,.24-.3.81.81,0,0,1,.35.07.78.78,0,0,0-.34,0,.2.2,0,0,0-.13.23.82.82,0,0,0,.33.52h0a.12.12,0,0,1,0,.17.14.14,0,0,1-.18,0S41.5,23.37,41.49,23.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.69,22.22a1,1,0,0,1-.3-.71c0-.13.1-.29.24-.29a.68.68,0,0,1,.35.06.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.78.78,0,0,0,.33.51h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.5,23a1,1,0,0,1-.29-.72.28.28,0,0,1,.24-.29.81.81,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.83.83,0,0,0,.32.52h0a.12.12,0,1,1-.14.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.1,22.12a1,1,0,0,1-.31-.7.29.29,0,0,1,.24-.3.88.88,0,0,1,.36.06.69.69,0,0,0-.35,0,.19.19,0,0,0-.12.23.87.87,0,0,0,.33.52h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.94,23a1,1,0,0,1-.26-.73.28.28,0,0,1,.25-.28.78.78,0,0,1,.35.08.78.78,0,0,0-.34,0,.21.21,0,0,0-.14.23.87.87,0,0,0,.31.53h0a.13.13,0,0,1,0,.18A.13.13,0,0,1,44,23S44,23,43.94,23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.78,24.27a1,1,0,0,1-.18-.75.31.31,0,0,1,.28-.26.78.78,0,0,1,.34.13.65.65,0,0,0-.33-.06.2.2,0,0,0-.17.2.87.87,0,0,0,.24.57h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.28,26.56a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.66.66,0,0,1,.29.2.73.73,0,0,0-.31-.14c-.11,0-.18.06-.21.16a.82.82,0,0,0,.1.6h0a.13.13,0,1,1-.21.14S46.28,26.57,46.28,26.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.6,28.73a1,1,0,0,1,.19-.74.29.29,0,0,1,.37-.1.88.88,0,0,1,.24.27.9.9,0,0,0-.27-.21.19.19,0,0,0-.24.11.82.82,0,0,0,0,.61h0a.12.12,0,0,1-.07.16.14.14,0,0,1-.17-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.72,30.63a1,1,0,0,1,.32-.7.28.28,0,0,1,.38,0,.8.8,0,0,1,.19.3.69.69,0,0,0-.23-.25.18.18,0,0,0-.25.07.81.81,0,0,0-.16.59h0a.11.11,0,0,1-.1.14.12.12,0,0,1-.14-.1A0,0,0,0,1,48.72,30.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.62,33.08a1,1,0,0,1,.4-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.63.63,0,0,0-.19-.28.19.19,0,0,0-.26,0,.78.78,0,0,0-.23.56h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.13-.12A0,0,0,0,1,49.62,33.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,35.49a1,1,0,0,1,.47-.61.29.29,0,0,1,.38.06.72.72,0,0,1,.11.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.26,0,.79.79,0,0,0-.29.54h0a.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.79,38a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.1.85.85,0,0,1,.08.35.77.77,0,0,0-.13-.32.2.2,0,0,0-.27,0A.82.82,0,0,0,53,38h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,52.79,38Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,40.61a1,1,0,0,1,.53-.56.31.31,0,0,1,.37.1.78.78,0,0,1,.08.36.77.77,0,0,0-.13-.32.2.2,0,0,0-.27,0,.81.81,0,0,0-.34.5h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.68,42.44a1,1,0,0,1,.55-.54.3.3,0,0,1,.37.11.87.87,0,0,1,.07.35.79.79,0,0,0-.13-.31.19.19,0,0,0-.26,0,.79.79,0,0,0-.35.5h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.92,45.4a1,1,0,0,1,.53-.56.3.3,0,0,1,.37.11.72.72,0,0,1,.08.35.9.9,0,0,0-.13-.32.2.2,0,0,0-.26,0,.79.79,0,0,0-.35.5h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.15S56.91,45.4,56.92,45.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,45.86a1,1,0,0,1,.54-.55.29.29,0,0,1,.36.11.78.78,0,0,1,.08.36.77.77,0,0,0-.13-.32.19.19,0,0,0-.26,0,.77.77,0,0,0-.35.5h0a.12.12,0,0,1-.14.09.12.12,0,0,1-.1-.15A0,0,0,0,1,58,45.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.68,45.19a1.05,1.05,0,0,1,.56-.54.28.28,0,0,1,.36.12.87.87,0,0,1,.07.35.88.88,0,0,0-.12-.32.2.2,0,0,0-.26,0,.86.86,0,0,0-.37.49h0a.12.12,0,1,1-.24-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.52,43.76a1,1,0,0,1,.55-.55.3.3,0,0,1,.36.11.92.92,0,0,1,.07.36.76.76,0,0,0-.12-.32.19.19,0,0,0-.26,0,.83.83,0,0,0-.36.49h0a.12.12,0,0,1-.15.1.13.13,0,0,1-.1-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.56,39.5a1,1,0,0,1,.56-.53.3.3,0,0,1,.36.11.76.76,0,0,1,.06.36.64.64,0,0,0-.11-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.49h0a.14.14,0,0,1-.15.1.14.14,0,0,1-.1-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.66,34.11a1,1,0,0,1,.53-.56.29.29,0,0,1,.37.09.93.93,0,0,1,.08.35.79.79,0,0,0-.13-.31.2.2,0,0,0-.27,0,.88.88,0,0,0-.34.51h0a.13.13,0,0,1-.25,0A0,0,0,0,1,53.66,34.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.43,31.25a1,1,0,0,1,.47-.6.3.3,0,0,1,.38.06.76.76,0,0,1,.11.35.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.84.84,0,0,0-.3.53h0a.12.12,0,0,1-.13.11.12.12,0,0,1-.12-.13A0,0,0,0,1,52.43,31.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.31,27.68a1,1,0,0,1,.32-.7A.28.28,0,0,1,51,27a.84.84,0,0,1,.19.31A.8.8,0,0,0,51,27a.2.2,0,0,0-.26.07.79.79,0,0,0-.16.59h0a.13.13,0,0,1-.11.15.13.13,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.67,25.5a1,1,0,0,1,.13-.76.29.29,0,0,1,.36-.12.93.93,0,0,1,.27.25.77.77,0,0,0-.29-.19.19.19,0,0,0-.23.12.88.88,0,0,0,0,.62h0a.13.13,0,0,1-.06.17.12.12,0,0,1-.17-.07A0,0,0,0,1,48.67,25.5Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.67,22.16a1,1,0,0,1-.17-.75.29.29,0,0,1,.29-.25.82.82,0,0,1,.34.12.84.84,0,0,0-.34-.06.18.18,0,0,0-.16.2.82.82,0,0,0,.23.57h0a.11.11,0,0,1,0,.17.13.13,0,0,1-.18,0S46.68,22.16,46.67,22.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.44,18.83a1.07,1.07,0,0,1-.38-.68.3.3,0,0,1,.21-.32.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.1.25.82.82,0,0,0,.38.47h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.83,17.42a1,1,0,0,1-.32-.7.29.29,0,0,1,.23-.31.81.81,0,0,1,.35.06,1,1,0,0,0-.34,0,.2.2,0,0,0-.12.24.88.88,0,0,0,.34.51h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.9,16.64a1,1,0,0,1-.27-.72.29.29,0,0,1,.25-.29.82.82,0,0,1,.35.08.8.8,0,0,0-.34,0,.2.2,0,0,0-.14.23.87.87,0,0,0,.31.53h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.08,16a1,1,0,0,1-.23-.73.27.27,0,0,1,.26-.27.82.82,0,0,1,.35.09.81.81,0,0,0-.34,0,.19.19,0,0,0-.15.22.82.82,0,0,0,.28.54h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40,17.49a1,1,0,0,1-.19-.75.27.27,0,0,1,.28-.25.66.66,0,0,1,.34.12.68.68,0,0,0-.34-.06.2.2,0,0,0-.16.21.79.79,0,0,0,.25.56h0a.12.12,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,40,17.49Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39,16.23a1,1,0,0,1-.15-.75.29.29,0,0,1,.29-.24.78.78,0,0,1,.34.13.83.83,0,0,0-.34-.07.2.2,0,0,0-.17.2.81.81,0,0,0,.22.57h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.68,17.31a1,1,0,0,1-.06-.77.28.28,0,0,1,.32-.21.79.79,0,0,1,.31.18.66.66,0,0,0-.32-.12.19.19,0,0,0-.19.18.88.88,0,0,0,.15.6h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.58,18.52a1,1,0,0,1,0-.77.29.29,0,0,1,.33-.18.83.83,0,0,1,.3.2.79.79,0,0,0-.31-.14.2.2,0,0,0-.21.17.82.82,0,0,0,.1.6h0a.13.13,0,0,1,0,.17.14.14,0,0,1-.18,0S38.58,18.53,38.58,18.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.59,19a1,1,0,0,1,.22-.74.28.28,0,0,1,.37-.08.88.88,0,0,1,.24.27.82.82,0,0,0-.27-.22.2.2,0,0,0-.24.1.82.82,0,0,0-.07.61h0a.13.13,0,0,1-.08.16A.13.13,0,0,1,37.6,19S37.6,19,37.59,19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.83,20.43a1,1,0,0,1,.26-.72.28.28,0,0,1,.37-.06.74.74,0,0,1,.22.28.78.78,0,0,0-.25-.23.19.19,0,0,0-.25.09.8.8,0,0,0-.1.6h0a.13.13,0,0,1-.09.15.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.12,22.06a1,1,0,0,1,.17-.75.29.29,0,0,1,.37-.1.77.77,0,0,1,.25.26.73.73,0,0,0-.27-.2.19.19,0,0,0-.24.11.81.81,0,0,0,0,.61h0a.12.12,0,0,1-.08.16.12.12,0,0,1-.16-.07S35.12,22.07,35.12,22.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.56,24.36a1,1,0,0,1-.07-.77.29.29,0,0,1,.32-.21.73.73,0,0,1,.31.17.76.76,0,0,0-.32-.11c-.11,0-.17.09-.19.19a.86.86,0,0,0,.15.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S34.56,24.37,34.56,24.36Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.47,28.67a1,1,0,0,1-.34-.69.29.29,0,0,1,.22-.32.83.83,0,0,1,.35.05.78.78,0,0,0-.34,0,.21.21,0,0,0-.11.25.86.86,0,0,0,.36.49h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.47,30.84a.94.94,0,0,1-.41-.64.29.29,0,0,1,.18-.34.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.05.19.19,0,0,0-.08.25.76.76,0,0,0,.41.45h0a.12.12,0,0,1,.06.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.27,33.93a1,1,0,0,1-.46-.62A.3.3,0,0,1,31,33a.88.88,0,0,1,.36,0A.75.75,0,0,0,31,33a.2.2,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.14.14,0,0,1,.08.17.14.14,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.84,36.75a1,1,0,0,1-.45-.62.29.29,0,0,1,.16-.35.87.87,0,0,1,.36,0,.7.7,0,0,0-.33.07.19.19,0,0,0-.07.26.77.77,0,0,0,.43.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.41,40.22a1,1,0,0,1-.41-.66.29.29,0,0,1,.18-.33.86.86,0,0,1,.36,0,.78.78,0,0,0-.34,0,.19.19,0,0,0-.08.25.81.81,0,0,0,.41.46h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.89,43.6a1,1,0,0,1-.36-.68.3.3,0,0,1,.21-.32.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.22.22,0,0,0-.11.25.87.87,0,0,0,.38.48h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.89,45.53a1,1,0,0,1-.33-.69.28.28,0,0,1,.22-.31.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.87.87,0,0,0,.35.5h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.56,49.11a1,1,0,0,1-.3-.71c0-.13.1-.29.24-.29a.85.85,0,0,1,.36.06.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.83.83,0,0,0,.33.52h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.18,51.38a1,1,0,0,1-.32-.7.28.28,0,0,1,.22-.31.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.19.19,0,0,0-.12.24.83.83,0,0,0,.34.5h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.22,51.6a1,1,0,0,1-.29-.71c0-.13.1-.29.24-.29a.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.2.2,0,0,0-.13.23.83.83,0,0,0,.32.52h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,50.32a1,1,0,0,1-.33-.69.29.29,0,0,1,.22-.31.87.87,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.84.84,0,0,0,.35.5h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S23,50.33,23,50.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.36,47.88a1,1,0,0,1-.34-.69.3.3,0,0,1,.22-.32.87.87,0,0,1,.36.05.77.77,0,0,0-.34,0,.2.2,0,0,0-.12.24.9.9,0,0,0,.36.5h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.19,42.24a1,1,0,0,1-.42-.65.31.31,0,0,1,.19-.34.87.87,0,0,1,.36,0,.76.76,0,0,0-.34.05.2.2,0,0,0-.09.25.81.81,0,0,0,.42.45h0a.12.12,0,0,1,.06.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.58,36.61a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.36,29.74a1,1,0,0,1-.4-.66.28.28,0,0,1,.19-.33.88.88,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.8.8,0,0,0,.4.46h0a.12.12,0,0,1,.06.16.12.12,0,0,1-.17.06S28.36,29.75,28.36,29.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.72,25.74a1,1,0,0,1-.21-.75.3.3,0,0,1,.28-.26.76.76,0,0,1,.34.11.8.8,0,0,0-.34-.05c-.11,0-.15.11-.15.22a.8.8,0,0,0,.26.55h0a.12.12,0,0,1-.16.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.55,20.84a1,1,0,0,1,.35-.69.29.29,0,0,1,.38,0,.7.7,0,0,1,.18.31.7.7,0,0,0-.22-.26.2.2,0,0,0-.26,0,.86.86,0,0,0-.18.59h0a.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.52,16.46a.94.94,0,0,1,.24-.73.29.29,0,0,1,.37-.07.7.7,0,0,1,.22.29.68.68,0,0,0-.25-.23.19.19,0,0,0-.25.09.8.8,0,0,0-.09.6h0a.13.13,0,0,1-.09.16.14.14,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.33,14.9a1,1,0,0,1,.06-.77.27.27,0,0,1,.34-.15.64.64,0,0,1,.29.22.68.68,0,0,0-.3-.16.19.19,0,0,0-.22.14.8.8,0,0,0,.06.61h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17-.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.76,12.76a1,1,0,0,1-.14-.76.29.29,0,0,1,.29-.24.88.88,0,0,1,.34.15.7.7,0,0,0-.33-.08.2.2,0,0,0-.18.2.82.82,0,0,0,.21.57h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.88,12.6a1,1,0,0,1-.17-.75A.29.29,0,0,1,35,11.6a.88.88,0,0,1,.33.13.78.78,0,0,0-.33-.07.21.21,0,0,0-.17.21.86.86,0,0,0,.23.57h0a.13.13,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.66,13.38a1,1,0,0,1-.14-.75.29.29,0,0,1,.3-.24.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08.2.2,0,0,0-.18.2.86.86,0,0,0,.21.58h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.61,14.32a1,1,0,0,1,0-.77.27.27,0,0,1,.33-.19.73.73,0,0,1,.3.19.65.65,0,0,0-.31-.13c-.12,0-.18.07-.21.17a.83.83,0,0,0,.13.6h0a.12.12,0,1,1-.2.14S34.62,14.33,34.61,14.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.05,17.31a1,1,0,0,1,.35-.68.29.29,0,0,1,.38,0,.81.81,0,0,1,.18.31.7.7,0,0,0-.22-.26.19.19,0,0,0-.26,0,.84.84,0,0,0-.18.58h0a.12.12,0,0,1-.11.14.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.55,24.51a1,1,0,0,1-.12-.76.29.29,0,0,1,.31-.23.77.77,0,0,1,.33.15.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.2.85.85,0,0,0,.19.58h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S29.56,24.52,29.55,24.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.16,37.9a1,1,0,0,1-.46-.63.3.3,0,0,1,.16-.34.75.75,0,0,1,.36,0,.73.73,0,0,0-.33.08.18.18,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.13.13,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.81,45.57a1,1,0,0,1-.38-.67.28.28,0,0,1,.2-.32.72.72,0,0,1,.36,0,.94.94,0,0,0-.34,0,.21.21,0,0,0-.1.25.84.84,0,0,0,.39.47h0a.13.13,0,0,1-.12.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.23,50.27a1,1,0,0,1-.38-.67.28.28,0,0,1,.2-.32.87.87,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.1.24.89.89,0,0,0,.39.48h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.1,55.1a1,1,0,0,1-.38-.66.29.29,0,0,1,.2-.33.74.74,0,0,1,.36,0,.64.64,0,0,0-.34,0,.19.19,0,0,0-.1.25.8.8,0,0,0,.39.47h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.69,57.65a1,1,0,0,1-.37-.67.28.28,0,0,1,.2-.32.86.86,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.1.24.84.84,0,0,0,.38.48h0a.13.13,0,0,1,.06.17.14.14,0,0,1-.18.06S17.7,57.66,17.69,57.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.6,59.79a1,1,0,0,1-.36-.68.29.29,0,0,1,.2-.32.85.85,0,0,1,.36,0,1,1,0,0,0-.34,0,.21.21,0,0,0-.1.25.87.87,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.23,62.64a1,1,0,0,1-.35-.69.28.28,0,0,1,.21-.31.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.19.19,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.13.13,0,0,1,.05.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.59,64.75a1,1,0,0,1-.34-.69.29.29,0,0,1,.22-.31.87.87,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.93.93,0,0,0,.36.5h0a.13.13,0,0,1,.05.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17.26,65.76a1,1,0,0,1-.34-.7.29.29,0,0,1,.22-.31.87.87,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.85.85,0,0,0,.36.5h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.21,66.14a1,1,0,0,1-.33-.7.29.29,0,0,1,.22-.31.72.72,0,0,1,.36.06.78.78,0,0,0-.34,0,.2.2,0,0,0-.12.24.81.81,0,0,0,.36.5h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.5,62.56a1,1,0,0,1-.35-.69.29.29,0,0,1,.22-.31.74.74,0,0,1,.36,0,.82.82,0,0,0-.35,0,.2.2,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.35,54a1,1,0,0,1-.3-.71.3.3,0,0,1,.24-.3.86.86,0,0,1,.36.07.65.65,0,0,0-.34,0,.19.19,0,0,0-.13.23.78.78,0,0,0,.33.51h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.48,48.39a1,1,0,0,1-.22-.74c0-.13.14-.28.28-.27a.8.8,0,0,1,.34.11.8.8,0,0,0-.34-.05.2.2,0,0,0-.15.22.84.84,0,0,0,.26.55h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.38,45.14a1,1,0,0,1-.16-.76.3.3,0,0,1,.29-.25.75.75,0,0,1,.33.14.66.66,0,0,0-.33-.07.2.2,0,0,0-.17.2.82.82,0,0,0,.23.57h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33,42.91a1,1,0,0,1-.17-.75.29.29,0,0,1,.29-.25.67.67,0,0,1,.34.13A.69.69,0,0,0,33.1,42c-.11,0-.16.1-.16.21a.81.81,0,0,0,.23.56h0a.12.12,0,1,1-.17.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.07,41.62a1,1,0,0,1-.16-.75.29.29,0,0,1,.29-.25.88.88,0,0,1,.33.13.78.78,0,0,0-.33-.07.21.21,0,0,0-.17.21.84.84,0,0,0,.23.57h0a.13.13,0,0,1-.18.18S34.07,41.63,34.07,41.62Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.47,40.72a1,1,0,0,1-.23-.74.29.29,0,0,1,.27-.27.83.83,0,0,1,.35.1.83.83,0,0,0-.34,0,.19.19,0,0,0-.15.22.85.85,0,0,0,.27.55h0a.13.13,0,0,1-.16.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.75,41.42a1,1,0,0,1-.24-.73.29.29,0,0,1,.27-.27.65.65,0,0,1,.34.1.64.64,0,0,0-.33,0,.19.19,0,0,0-.15.22.82.82,0,0,0,.28.54h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0A0,0,0,0,1,32.75,41.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.5,43.68a1,1,0,0,1-.2-.74.29.29,0,0,1,.27-.27.92.92,0,0,1,.34.12.67.67,0,0,0-.34,0,.19.19,0,0,0-.15.21.82.82,0,0,0,.26.55h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.89,46.93a1,1,0,0,1-.23-.74.27.27,0,0,1,.26-.27.71.71,0,0,1,.35.1.81.81,0,0,0-.34,0,.2.2,0,0,0-.15.22.84.84,0,0,0,.28.54h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,27.89,46.93Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.06,51.08a1,1,0,0,1-.23-.73.29.29,0,0,1,.26-.28.83.83,0,0,1,.35.1.8.8,0,0,0-.34,0,.21.21,0,0,0-.15.22.89.89,0,0,0,.28.55h0a.13.13,0,1,1-.16.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.68,54.11a1,1,0,0,1-.25-.73.29.29,0,0,1,.26-.28.82.82,0,0,1,.35.09,1,1,0,0,0-.34,0,.22.22,0,0,0-.15.23.81.81,0,0,0,.3.53h0a.13.13,0,1,1-.16.2S24.69,54.12,24.68,54.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.48,57.14a1,1,0,0,1-.3-.7.29.29,0,0,1,.23-.3.85.85,0,0,1,.36.06.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.87.87,0,0,0,.33.52h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.68,59.81a.94.94,0,0,1-.31-.7.29.29,0,0,1,.23-.31.86.86,0,0,1,.36.07.65.65,0,0,0-.34,0,.19.19,0,0,0-.13.23.84.84,0,0,0,.34.51h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.27,62.25a1,1,0,0,1-.31-.71.29.29,0,0,1,.24-.3.84.84,0,0,1,.35.07.79.79,0,0,0-.34,0,.19.19,0,0,0-.12.24.82.82,0,0,0,.33.51h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.55,64.15a1,1,0,0,1-.3-.72c0-.13.1-.29.24-.29a.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.12.23.83.83,0,0,0,.32.52h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.49,65.68a1,1,0,0,1-.3-.72.29.29,0,0,1,.24-.29.73.73,0,0,1,.36.07.79.79,0,0,0-.34,0,.19.19,0,0,0-.13.23.83.83,0,0,0,.32.52h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.44,66.13a1,1,0,0,1-.27-.72.28.28,0,0,1,.25-.28.69.69,0,0,1,.35.07.79.79,0,0,0-.34,0,.19.19,0,0,0-.14.22.89.89,0,0,0,.31.53h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.6,66.22a1,1,0,0,1-.25-.73.28.28,0,0,1,.25-.28,1,1,0,0,1,.35.09.78.78,0,0,0-.34,0,.18.18,0,0,0-.14.22.9.9,0,0,0,.29.54h0a.13.13,0,1,1-.16.2A0,0,0,0,1,23.6,66.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.82,64a1,1,0,0,1-.2-.75A.3.3,0,0,1,25.9,63a.92.92,0,0,1,.34.12.78.78,0,0,0-.33,0c-.12,0-.16.11-.16.21a.83.83,0,0,0,.25.56h0a.13.13,0,1,1-.17.19S25.82,64,25.82,64Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.1,60.22a.94.94,0,0,1-.12-.76.28.28,0,0,1,.3-.23.91.91,0,0,1,.33.15,1,1,0,0,0-.33-.09.2.2,0,0,0-.18.2.85.85,0,0,0,.19.58h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.53,56.69a1,1,0,0,1-.07-.77.29.29,0,0,1,.31-.21.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11.2.2,0,0,0-.19.19.82.82,0,0,0,.16.59h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.89,50.18a1,1,0,0,1,0-.77.3.3,0,0,1,.33-.19.83.83,0,0,1,.31.2.68.68,0,0,0-.32-.13.19.19,0,0,0-.2.17.78.78,0,0,0,.12.6h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0A0,0,0,0,0,31.89,50.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.88,44.82a1,1,0,0,1-.06-.77.29.29,0,0,1,.32-.2.68.68,0,0,1,.32.18.7.7,0,0,0-.32-.12.2.2,0,0,0-.2.18.85.85,0,0,0,.15.59h0a.12.12,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.19,40.06a1,1,0,0,1-.16-.75.29.29,0,0,1,.29-.25.93.93,0,0,1,.34.13.83.83,0,0,0-.34-.07c-.11,0-.16.1-.16.21a.83.83,0,0,0,.22.57h0a.13.13,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.94,36.78a1,1,0,0,1-.24-.73.3.3,0,0,1,.26-.28.83.83,0,0,1,.35.1.66.66,0,0,0-.34,0,.18.18,0,0,0-.14.22.84.84,0,0,0,.28.54h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.83,34a1,1,0,0,1-.29-.72.29.29,0,0,1,.24-.29.89.89,0,0,1,.36.07.83.83,0,0,0-.35,0,.19.19,0,0,0-.12.23.77.77,0,0,0,.32.52h0a.13.13,0,1,1-.15.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.5,31.81a1,1,0,0,1-.32-.7.28.28,0,0,1,.23-.3.74.74,0,0,1,.36.06.69.69,0,0,0-.35,0c-.1,0-.13.13-.12.24a.86.86,0,0,0,.34.5h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A0,0,0,0,1,37.5,31.81Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.13,30.46a1,1,0,0,1-.31-.7.29.29,0,0,1,.23-.31.89.89,0,0,1,.36.07.69.69,0,0,0-.35,0,.2.2,0,0,0-.12.23.88.88,0,0,0,.34.51h0a.13.13,0,1,1-.14.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.16,29.24a1,1,0,0,1-.33-.7.31.31,0,0,1,.23-.31.85.85,0,0,1,.36.06,1,1,0,0,0-.34,0,.19.19,0,0,0-.12.24.88.88,0,0,0,.34.51h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.85,28.05a1,1,0,0,1-.33-.7.28.28,0,0,1,.23-.3.83.83,0,0,1,.35.05.78.78,0,0,0-.34,0,.2.2,0,0,0-.11.24.83.83,0,0,0,.35.5h0A.13.13,0,0,1,38,28a.14.14,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.35,28.67a1,1,0,0,1-.29-.71.3.3,0,0,1,.25-.3,1,1,0,0,1,.35.07.79.79,0,0,0-.34,0,.19.19,0,0,0-.13.23.81.81,0,0,0,.32.52h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0S39.36,28.68,39.35,28.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.46,29.56a1,1,0,0,1-.28-.72.29.29,0,0,1,.25-.29.82.82,0,0,1,.35.08.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.83.83,0,0,0,.31.53h0a.13.13,0,1,1-.15.21S39.46,29.57,39.46,29.56Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.14,26.81a1,1,0,0,1-.31-.7.28.28,0,0,1,.23-.3.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0c-.11,0-.14.13-.12.24a.77.77,0,0,0,.33.5h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.82,27.73a1,1,0,0,1-.31-.7.3.3,0,0,1,.23-.3.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.18.18,0,0,0-.12.23.82.82,0,0,0,.33.51h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.52,28.76a1,1,0,0,1-.36-.68.29.29,0,0,1,.21-.32.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.14.14,0,0,1,.05.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.77,31.13a1,1,0,0,1-.42-.65.28.28,0,0,1,.18-.33.87.87,0,0,1,.36,0,.65.65,0,0,0-.34.06.19.19,0,0,0-.08.25.9.9,0,0,0,.41.45h0a.13.13,0,1,1-.1.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.46,35.15a1,1,0,0,1-.47-.62.28.28,0,0,1,.16-.34.74.74,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.84.84,0,0,0,.45.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.25,42.75a1,1,0,0,1-.41-.65.3.3,0,0,1,.19-.33.72.72,0,0,1,.36,0,.72.72,0,0,0-.33,0,.19.19,0,0,0-.09.25.87.87,0,0,0,.4.46h0a.13.13,0,0,1-.11.23S20.26,42.76,20.25,42.75Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.37,47.28a1,1,0,0,1-.4-.66.3.3,0,0,1,.19-.33.72.72,0,0,1,.36,0,.78.78,0,0,0-.34.05.2.2,0,0,0-.09.25.87.87,0,0,0,.4.46h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.67,52.67a1.06,1.06,0,0,1-.39-.67.28.28,0,0,1,.2-.32.62.62,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.77.77,0,0,0,.39.47h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12,56.19a1,1,0,0,1-.37-.68.29.29,0,0,1,.21-.32.85.85,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.79.79,0,0,0,.37.48h0a.12.12,0,0,1,.05.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.46,57.16a1,1,0,0,1-.36-.68.29.29,0,0,1,.21-.32.74.74,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M11.26,55.45a1,1,0,0,1-.37-.67.29.29,0,0,1,.21-.32.74.74,0,0,1,.36,0,.64.64,0,0,0-.34,0,.19.19,0,0,0-.11.24.84.84,0,0,0,.38.48h0a.13.13,0,1,1-.12.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M14.44,49.37a1,1,0,0,1-.41-.66.3.3,0,0,1,.2-.33.88.88,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.09.25.8.8,0,0,0,.4.46h0a.12.12,0,0,1,.06.16.12.12,0,0,1-.17.06S14.44,49.38,14.44,49.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.92,45.05a1,1,0,0,1-.4-.65.28.28,0,0,1,.19-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34.05.18.18,0,0,0-.09.24.8.8,0,0,0,.4.46h0a.13.13,0,0,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M17,40.81a1,1,0,0,1-.42-.64.29.29,0,0,1,.18-.34.87.87,0,0,1,.36,0,.93.93,0,0,0-.34.06.19.19,0,0,0-.08.25.87.87,0,0,0,.41.45h0a.13.13,0,0,1,.07.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.68,35.31a1,1,0,0,1-.46-.62.28.28,0,0,1,.16-.34.73.73,0,0,1,.36,0,.7.7,0,0,0-.33.08.18.18,0,0,0-.07.25.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.58,32.42a1,1,0,0,1-.46-.61.3.3,0,0,1,.16-.35.78.78,0,0,1,.37,0,.77.77,0,0,0-.34.08.2.2,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.13.13,0,0,1,.08.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.57,32a1,1,0,0,1-.46-.62.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.82.82,0,0,0,.43.43h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.17,33.4a1,1,0,0,1-.46-.62.29.29,0,0,1,.15-.35.94.94,0,0,1,.37,0,.77.77,0,0,0-.34.08.2.2,0,0,0-.06.26.86.86,0,0,0,.44.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.6,37a1,1,0,0,1-.45-.62.28.28,0,0,1,.17-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.21.21,0,0,0-.07.26.88.88,0,0,0,.44.43h0a.14.14,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M15.6,43.36a1,1,0,0,1-.41-.65.29.29,0,0,1,.18-.33.92.92,0,0,1,.37,0,.93.93,0,0,0-.34,0,.2.2,0,0,0-.09.25.85.85,0,0,0,.41.46h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.21,48.56a1,1,0,0,1-.4-.66.28.28,0,0,1,.19-.33.87.87,0,0,1,.36,0,.76.76,0,0,0-.34.05.2.2,0,0,0-.09.25.83.83,0,0,0,.4.46h0a.12.12,0,0,1,.06.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.37,51.35a1,1,0,0,1-.4-.66.28.28,0,0,1,.2-.32.73.73,0,0,1,.36,0,.94.94,0,0,0-.34,0,.2.2,0,0,0-.09.25.84.84,0,0,0,.39.47h0a.13.13,0,1,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.47,51.38a1,1,0,0,1-.4-.66.29.29,0,0,1,.2-.33.63.63,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.09.24.8.8,0,0,0,.39.47h0a.14.14,0,0,1,.06.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M12.57,50.34a1,1,0,0,1-.39-.66.27.27,0,0,1,.19-.32.73.73,0,0,1,.36,0,.94.94,0,0,0-.34,0,.2.2,0,0,0-.09.25.82.82,0,0,0,.4.47h0a.13.13,0,0,1-.11.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M13.87,45.93a1,1,0,0,1-.42-.65.29.29,0,0,1,.19-.33A.87.87,0,0,1,14,45a.76.76,0,0,0-.34.06.2.2,0,0,0-.09.25.9.9,0,0,0,.41.45h0a.14.14,0,0,1,.07.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M16.66,38.78a1,1,0,0,1-.44-.63.29.29,0,0,1,.17-.34.74.74,0,0,1,.36,0,.88.88,0,0,0-.33.06.21.21,0,0,0-.08.26.84.84,0,0,0,.43.44h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.81,26.53a1,1,0,0,1-.4-.66c0-.13.06-.31.19-.33a.66.66,0,0,1,.37,0,.64.64,0,0,0-.34,0c-.11,0-.12.15-.1.25a.87.87,0,0,0,.4.46h0a.14.14,0,0,1,.06.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.07,22.64a1,1,0,0,1-.25-.73.28.28,0,0,1,.25-.28.79.79,0,0,1,.35.09.66.66,0,0,0-.34,0,.19.19,0,0,0-.14.22.85.85,0,0,0,.29.54h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.59,21.17a1,1,0,0,1-.1-.77.29.29,0,0,1,.31-.22.78.78,0,0,1,.33.16.84.84,0,0,0-.33-.1c-.11,0-.17.09-.19.19a.87.87,0,0,0,.18.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,0,26.59,21.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.62,21.05a1,1,0,0,1-.16-.75c0-.13.15-.27.28-.25a.73.73,0,0,1,.34.13.78.78,0,0,0-.33-.07.2.2,0,0,0-.17.21.84.84,0,0,0,.23.57h0a.12.12,0,1,1-.17.18S25.63,21.06,25.62,21.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.34,22.23a1,1,0,0,1-.16-.75.29.29,0,0,1,.29-.25.88.88,0,0,1,.33.13.78.78,0,0,0-.33-.07.21.21,0,0,0-.17.21.84.84,0,0,0,.23.57h0a.13.13,0,0,1-.18.18S26.34,22.24,26.34,22.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.92,24a1,1,0,0,1-.26-.73.28.28,0,0,1,.25-.28.7.7,0,0,1,.35.09.66.66,0,0,0-.34,0,.19.19,0,0,0-.14.23.81.81,0,0,0,.3.53h0a.14.14,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.84,30.85a1,1,0,0,1-.45-.62.28.28,0,0,1,.17-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.2.2,0,0,0-.07.26.84.84,0,0,0,.44.43h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.17.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20,39.92a1,1,0,0,1-.43-.64.29.29,0,0,1,.18-.34.87.87,0,0,1,.36,0,.93.93,0,0,0-.34.06.21.21,0,0,0-.08.26.84.84,0,0,0,.43.44h0a.12.12,0,0,1,.07.17.12.12,0,0,1-.16.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.78,47.54a1,1,0,0,1-.39-.66.3.3,0,0,1,.2-.33.88.88,0,0,1,.36,0,.76.76,0,0,0-.34,0,.21.21,0,0,0-.1.25.88.88,0,0,0,.4.47h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.61,52.28a1,1,0,0,1-.39-.67.3.3,0,0,1,.2-.33.74.74,0,0,1,.36,0,.76.76,0,0,0-.34,0,.21.21,0,0,0-.1.25.84.84,0,0,0,.39.47h0a.14.14,0,0,1,.06.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.4,53.89a1,1,0,0,1-.38-.66.29.29,0,0,1,.2-.33.88.88,0,0,1,.36,0,.76.76,0,0,0-.34,0,.2.2,0,0,0-.1.25.91.91,0,0,0,.39.47h0a.12.12,0,0,1,.05.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.21,55a1,1,0,0,1-.38-.67A.29.29,0,0,1,19,54a.72.72,0,0,1,.36,0,1,1,0,0,0-.34,0,.2.2,0,0,0-.09.25.79.79,0,0,0,.38.47h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.82,53.66a1,1,0,0,1-.38-.67.29.29,0,0,1,.2-.32.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.21.21,0,0,0-.1.25.83.83,0,0,0,.39.47h0a.13.13,0,0,1,.06.17.12.12,0,0,1-.17.06S19.82,53.67,19.82,53.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.73,46.85a1,1,0,0,1-.37-.68.3.3,0,0,1,.21-.32.85.85,0,0,1,.36,0,.94.94,0,0,0-.34,0,.2.2,0,0,0-.1.25.85.85,0,0,0,.37.48h0a.13.13,0,0,1-.12.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.39,40a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.63.63,0,0,1,.36,0,.72.72,0,0,0-.33.06.21.21,0,0,0-.08.26.86.86,0,0,0,.43.43h0a.11.11,0,0,1,.07.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,33a1,1,0,0,1-.46-.62.3.3,0,0,1,.17-.35.87.87,0,0,1,.36,0,.77.77,0,0,0-.34.07.21.21,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.07.16A.12.12,0,0,1,30,33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.78,29.69A1,1,0,0,1,31.4,29a.28.28,0,0,1,.2-.32.73.73,0,0,1,.36,0,.94.94,0,0,0-.34,0,.19.19,0,0,0-.1.25.77.77,0,0,0,.39.47h0a.12.12,0,0,1,.06.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.72,27.65a1,1,0,0,1-.29-.71c0-.13.1-.29.24-.29a.69.69,0,0,1,.35.07.65.65,0,0,0-.34,0,.19.19,0,0,0-.13.23.86.86,0,0,0,.32.52h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0S32.72,27.66,32.72,27.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.3,26.14a1,1,0,0,1-.21-.75.3.3,0,0,1,.28-.26.8.8,0,0,1,.34.11.8.8,0,0,0-.34,0c-.11,0-.15.11-.15.22a.8.8,0,0,0,.26.55h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.9,25a1,1,0,0,1-.17-.75A.29.29,0,0,1,36,24a.74.74,0,0,1,.33.13.78.78,0,0,0-.33-.07.2.2,0,0,0-.16.21.79.79,0,0,0,.23.56h0a.13.13,0,1,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.86,24.17a1,1,0,0,1-.15-.76.28.28,0,0,1,.29-.24.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08.19.19,0,0,0-.17.21.83.83,0,0,0,.22.57h0a.14.14,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.53,23.63a1,1,0,0,1-.22-.74.28.28,0,0,1,.27-.26.71.71,0,0,1,.35.1.81.81,0,0,0-.34,0,.18.18,0,0,0-.15.21.76.76,0,0,0,.26.55h0a.13.13,0,1,1-.17.19A0,0,0,0,1,38.53,23.63Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.23,24.22a1,1,0,0,1-.29-.71.3.3,0,0,1,.25-.3.82.82,0,0,1,.35.08.66.66,0,0,0-.34,0c-.11,0-.14.12-.13.23a.86.86,0,0,0,.32.52h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.27,26.38a1,1,0,0,1-.17-.75.27.27,0,0,1,.28-.25.78.78,0,0,1,.34.13.83.83,0,0,0-.34-.07.2.2,0,0,0-.16.21.83.83,0,0,0,.24.57h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.37,27.94a1,1,0,0,1,.08-.77A.28.28,0,0,1,46.8,27a.76.76,0,0,1,.28.22.8.8,0,0,0-.3-.16.18.18,0,0,0-.22.14.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.05.17.12.12,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.9,32.08a1,1,0,0,1,.31-.71.3.3,0,0,1,.38,0,.79.79,0,0,1,.2.31,1,1,0,0,0-.23-.26.2.2,0,0,0-.26.07.8.8,0,0,0-.15.59h0a.13.13,0,0,1-.1.15.14.14,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.52,37a1,1,0,0,1,.47-.61.29.29,0,0,1,.38.06.72.72,0,0,1,.11.34.79.79,0,0,0-.16-.3.2.2,0,0,0-.26,0,.81.81,0,0,0-.29.54h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,42.44a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35A.68.68,0,0,0,53.8,42a.19.19,0,0,0-.26,0,.82.82,0,0,0-.33.51h0a.13.13,0,1,1-.25,0S53,42.45,53,42.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.44,47.54a1,1,0,0,1,.49-.59.29.29,0,0,1,.37.08.72.72,0,0,1,.11.34.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,1,1-.25,0A0,0,0,0,1,54.44,47.54Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.79,50.88a1,1,0,0,1,.47-.62.3.3,0,0,1,.38.06.9.9,0,0,1,.11.34.75.75,0,0,0-.16-.29.2.2,0,0,0-.27,0,.82.82,0,0,0-.28.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,53.68a.94.94,0,0,1,.44-.63.28.28,0,0,1,.37,0,.73.73,0,0,1,.14.34,1,1,0,0,0-.18-.3.2.2,0,0,0-.26,0,.85.85,0,0,0-.27.55h0a.13.13,0,0,1-.13.13.13.13,0,0,1-.12-.13A0,0,0,0,1,55,53.68Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.26,55.6a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.87.87,0,0,1,.14.33A.84.84,0,0,0,56,55a.2.2,0,0,0-.27,0,.83.83,0,0,0-.24.56h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,57.14a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.89.89,0,0,1,.15.33.73.73,0,0,0-.19-.28.2.2,0,0,0-.27,0,.87.87,0,0,0-.23.56h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.41,58.05a1,1,0,0,1,.39-.67.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32.68.68,0,0,0-.2-.27.19.19,0,0,0-.26,0,.85.85,0,0,0-.22.57h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.57,56.89a1,1,0,0,1,.41-.66.29.29,0,0,1,.38,0,.94.94,0,0,1,.15.32.8.8,0,0,0-.2-.28.19.19,0,0,0-.26,0,.86.86,0,0,0-.23.57h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.08,51.71a1,1,0,0,1,.45-.63.3.3,0,0,1,.38.05.7.7,0,0,1,.12.34.69.69,0,0,0-.17-.3.19.19,0,0,0-.26,0,.81.81,0,0,0-.27.55h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.14,46.6a1,1,0,0,1,.48-.6.29.29,0,0,1,.38.06.9.9,0,0,1,.11.34.79.79,0,0,0-.16-.3.2.2,0,0,0-.27,0,.88.88,0,0,0-.29.54h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.89,44.73a1,1,0,0,1,.47-.61.29.29,0,0,1,.37.06.77.77,0,0,1,.12.35.72.72,0,0,0-.16-.3.2.2,0,0,0-.27,0,.88.88,0,0,0-.29.54h0a.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13A0,0,0,0,1,50.89,44.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.78,45.07a1,1,0,0,1,.48-.61.3.3,0,0,1,.38.07.89.89,0,0,1,.11.35.83.83,0,0,0-.16-.31.2.2,0,0,0-.27,0,.88.88,0,0,0-.29.54h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.07,46.29a1,1,0,0,1,.47-.61.28.28,0,0,1,.37.06.73.73,0,0,1,.12.34.79.79,0,0,0-.16-.3.2.2,0,0,0-.27,0,.8.8,0,0,0-.28.54h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.39,48.46a1,1,0,0,1,.47-.6.29.29,0,0,1,.38.06.76.76,0,0,1,.11.34.79.79,0,0,0-.16-.3.19.19,0,0,0-.26,0,.89.89,0,0,0-.3.54h0a.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13S53.39,48.47,53.39,48.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.94,53.13a1,1,0,0,1,.46-.62.29.29,0,0,1,.37.05.89.89,0,0,1,.13.34,1,1,0,0,0-.17-.3.21.21,0,0,0-.27,0,.87.87,0,0,0-.27.55h0a.14.14,0,0,1-.14.12.13.13,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.8,58a1,1,0,0,1,.41-.66.29.29,0,0,1,.38,0,.89.89,0,0,1,.15.33.82.82,0,0,0-.19-.28.2.2,0,0,0-.27,0,.88.88,0,0,0-.23.57h0a.12.12,0,0,1-.12.13A.13.13,0,0,1,59.8,58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.47,59.71a1,1,0,0,1,.38-.68.3.3,0,0,1,.38,0,.81.81,0,0,1,.16.32.68.68,0,0,0-.2-.27.2.2,0,0,0-.26,0,.85.85,0,0,0-.21.58h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.49,59.6a1,1,0,0,1,.38-.67.28.28,0,0,1,.38,0,.81.81,0,0,1,.16.32.84.84,0,0,0-.2-.28.21.21,0,0,0-.27,0,.81.81,0,0,0-.2.58h0a.12.12,0,0,1-.12.13.13.13,0,0,1-.14-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.78,58.06a1,1,0,0,1,.41-.66.29.29,0,0,1,.38,0,.78.78,0,0,1,.15.32.92.92,0,0,0-.19-.28.2.2,0,0,0-.27,0,.92.92,0,0,0-.23.57h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.48,51.61A1,1,0,0,1,59,51a.29.29,0,0,1,.38.06.76.76,0,0,1,.11.35.83.83,0,0,0-.16-.31.2.2,0,0,0-.26,0,.85.85,0,0,0-.3.54h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.86,43a1,1,0,0,1,.57-.52.29.29,0,0,1,.36.12.91.91,0,0,1,.06.36.76.76,0,0,0-.11-.32.2.2,0,0,0-.27,0,.84.84,0,0,0-.37.48h0a.12.12,0,0,1-.15.09.11.11,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.81,37.94a1,1,0,0,1,.58-.52.3.3,0,0,1,.36.13.9.9,0,0,1,0,.36.72.72,0,0,0-.11-.33.19.19,0,0,0-.26,0,.82.82,0,0,0-.38.48h0a.13.13,0,0,1-.16.08A.13.13,0,0,1,56.8,38,0,0,0,0,0,56.81,37.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.63,35.61a1,1,0,0,1,.58-.51.29.29,0,0,1,.36.13.71.71,0,0,1,0,.35.86.86,0,0,0-.1-.32.22.22,0,0,0-.27,0,.85.85,0,0,0-.38.48h0a.13.13,0,0,1-.16.09.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.61,34.32a1,1,0,0,1,.56-.53.28.28,0,0,1,.36.12.76.76,0,0,1,.06.36.72.72,0,0,0-.11-.33.2.2,0,0,0-.26,0,.82.82,0,0,0-.37.48h0a.14.14,0,0,1-.16.1.15.15,0,0,1-.09-.16S55.61,34.33,55.61,34.32Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.46,35.3a1,1,0,0,1,.56-.53.28.28,0,0,1,.36.12.7.7,0,0,1,.06.35.72.72,0,0,0-.11-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.49h0a.13.13,0,0,1-.25-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.71,37.67a1,1,0,0,1,.59-.49.3.3,0,0,1,.36.14.89.89,0,0,1,0,.36.71.71,0,0,0-.1-.33.19.19,0,0,0-.26-.05.82.82,0,0,0-.4.46h0a.13.13,0,1,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.44,43.31a1,1,0,0,1,.6-.49.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.7.7,0,0,0-.09-.33.2.2,0,0,0-.26-.06.89.89,0,0,0-.4.46h0a.13.13,0,1,1-.24-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.15,46a1,1,0,0,1,.59-.5.28.28,0,0,1,.35.13.77.77,0,0,1,0,.36.8.8,0,0,0-.1-.32.19.19,0,0,0-.26-.06.85.85,0,0,0-.39.47h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.33,46.9a.94.94,0,0,1,.58-.51.28.28,0,0,1,.35.13.75.75,0,0,1,0,.36.8.8,0,0,0-.1-.32.18.18,0,0,0-.26-.05.82.82,0,0,0-.39.47h0a.14.14,0,0,1-.16.09.13.13,0,0,1-.08-.16S67.32,46.9,67.33,46.9Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.37,45.65a1,1,0,0,1,.59-.49.28.28,0,0,1,.35.14.64.64,0,0,1,0,.36.74.74,0,0,0-.09-.33.2.2,0,0,0-.26,0,.89.89,0,0,0-.4.46h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15A0,0,0,0,1,67.37,45.65Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.35,40.37a1,1,0,0,1,.61-.46.28.28,0,0,1,.35.16.73.73,0,0,1,0,.36.77.77,0,0,0-.08-.34A.19.19,0,0,0,64,40a.82.82,0,0,0-.42.44h0a.14.14,0,0,1-.17.08.12.12,0,0,1-.07-.16S63.35,40.38,63.35,40.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.46,35.14a1,1,0,0,1,.6-.48.3.3,0,0,1,.35.15.74.74,0,0,1,0,.36.74.74,0,0,0-.09-.33.19.19,0,0,0-.25-.06.8.8,0,0,0-.41.46h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16S59.45,35.15,59.46,35.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.59,29.74a1,1,0,0,1,.52-.57.29.29,0,0,1,.37.09.74.74,0,0,1,.08.35.79.79,0,0,0-.13-.31.21.21,0,0,0-.27,0,.82.82,0,0,0-.33.51h0a.13.13,0,1,1-.25,0S54.58,29.75,54.59,29.74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54,28.19a1,1,0,0,1,.48-.6.3.3,0,0,1,.38.07A.74.74,0,0,1,55,28a.69.69,0,0,0-.15-.31.19.19,0,0,0-.26,0,.82.82,0,0,0-.31.53h0a.13.13,0,0,1-.25,0A0,0,0,0,1,54,28.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53,28.39a1,1,0,0,1,.46-.62.29.29,0,0,1,.38.06.82.82,0,0,1,.12.34.74.74,0,0,0-.17-.3.18.18,0,0,0-.26,0,.82.82,0,0,0-.28.54h0a.14.14,0,0,1-.14.12.11.11,0,0,1-.11-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.2,27.27a1,1,0,0,1,.45-.63.28.28,0,0,1,.37.05.62.62,0,0,1,.13.34.74.74,0,0,0-.17-.3.2.2,0,0,0-.27,0,.84.84,0,0,0-.26.55h0a.12.12,0,0,1-.13.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.48,27.28a1,1,0,0,1,.49-.59.28.28,0,0,1,.37.07.76.76,0,0,1,.11.35.83.83,0,0,0-.16-.31.19.19,0,0,0-.26,0,.84.84,0,0,0-.3.53h0a.11.11,0,0,1-.13.11.14.14,0,0,1-.12-.14A0,0,0,0,0,54.48,27.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.53,29a1,1,0,0,1,.53-.56.31.31,0,0,1,.37.1.94.94,0,0,1,.08.36.77.77,0,0,0-.13-.32.2.2,0,0,0-.27,0,.84.84,0,0,0-.34.51h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15S55.52,29,55.53,29Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.36,33.7a1,1,0,0,1,.58-.5.28.28,0,0,1,.35.13.75.75,0,0,1,.05.36.71.71,0,0,0-.1-.33.2.2,0,0,0-.26,0,.87.87,0,0,0-.39.48h0a.13.13,0,0,1-.16.09.12.12,0,0,1-.08-.15A0,0,0,0,1,57.36,33.7Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.75,38.23a1,1,0,0,1,.6-.48.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36.6.6,0,0,0-.09-.33.18.18,0,0,0-.25-.06.8.8,0,0,0-.41.46h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15A0,0,0,0,1,59.75,38.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.07,43a1,1,0,0,1,.58-.5.3.3,0,0,1,.36.14.89.89,0,0,1,0,.36.9.9,0,0,0-.09-.33.2.2,0,0,0-.26-.05.8.8,0,0,0-.4.47h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15A0,0,0,0,1,61.07,43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.17,46.33a1,1,0,0,1,.57-.52.29.29,0,0,1,.36.12.86.86,0,0,1,.06.35,1.1,1.1,0,0,0-.11-.32.2.2,0,0,0-.27,0,.86.86,0,0,0-.37.49h0a.13.13,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.05,48.76a1,1,0,0,1,.54-.55.29.29,0,0,1,.37.1.87.87,0,0,1,.07.35.65.65,0,0,0-.12-.31.2.2,0,0,0-.27,0,.81.81,0,0,0-.34.5h0a.12.12,0,0,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.6,50.72a1,1,0,0,1,.51-.58.3.3,0,0,1,.37.09.73.73,0,0,1,.09.35.8.8,0,0,0-.14-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.32.52h0a.13.13,0,0,1-.25,0A0,0,0,0,1,60.6,50.72Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.3,52.3a1,1,0,0,1,.48-.6.29.29,0,0,1,.37.07.76.76,0,0,1,.11.35.83.83,0,0,0-.16-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.3.54h0a.14.14,0,0,1-.14.12.12.12,0,0,1-.11-.14A0,0,0,0,1,60.3,52.3Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.34,53.24a1,1,0,0,1,.47-.61.3.3,0,0,1,.38.06.76.76,0,0,1,.11.35.72.72,0,0,0-.16-.3.19.19,0,0,0-.26,0,.79.79,0,0,0-.29.54h0a.13.13,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,54.32a1,1,0,0,1,.46-.62.3.3,0,0,1,.38,0,.93.93,0,0,1,.12.34.7.7,0,0,0-.17-.29.18.18,0,0,0-.26,0,.8.8,0,0,0-.28.54h0a.14.14,0,0,1-.13.12.13.13,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,54.48a1,1,0,0,1,.46-.62.28.28,0,0,1,.37,0,.89.89,0,0,1,.13.34,1,1,0,0,0-.17-.3.21.21,0,0,0-.27,0,.87.87,0,0,0-.27.55h0a.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,53.79a1,1,0,0,1,.47-.62.29.29,0,0,1,.37,0,.78.78,0,0,1,.12.34.75.75,0,0,0-.16-.29.2.2,0,0,0-.27,0,.84.84,0,0,0-.28.55h0a.11.11,0,0,1-.13.11.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.44,54.53a1,1,0,0,1,.45-.63.3.3,0,0,1,.38.05.7.7,0,0,1,.12.34.81.81,0,0,0-.17-.3A.19.19,0,0,0,60,54a.77.77,0,0,0-.27.55h0a.13.13,0,0,1-.14.12.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.21,53.22a1,1,0,0,1,.48-.6.28.28,0,0,1,.37.07.76.76,0,0,1,.11.34.77.77,0,0,0-.15-.3.2.2,0,0,0-.27,0,.84.84,0,0,0-.3.53h0a.12.12,0,0,1-.14.11.11.11,0,0,1-.11-.13A0,0,0,0,1,63.21,53.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67.06,48.86a1,1,0,0,1,.56-.53.28.28,0,0,1,.36.12.76.76,0,0,1,.07.35.88.88,0,0,0-.12-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.48h0a.14.14,0,0,1-.15.1.14.14,0,0,1-.1-.16S67.06,48.87,67.06,48.86Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.31,45.33a1.06,1.06,0,0,1,.6-.49.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36.85.85,0,0,0-.09-.33A.19.19,0,0,0,70,45a.82.82,0,0,0-.4.46h0a.12.12,0,0,1-.15.08.13.13,0,0,1-.09-.16A0,0,0,0,0,69.31,45.33Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M71.05,42.71a1,1,0,0,1,.62-.46.29.29,0,0,1,.34.17.73.73,0,0,1,0,.36.77.77,0,0,0-.08-.34.2.2,0,0,0-.25-.07.88.88,0,0,0-.43.44h0a.13.13,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.33,41.59a1.05,1.05,0,0,1,.63-.45.3.3,0,0,1,.34.17.88.88,0,0,1,0,.36.72.72,0,0,0-.07-.33.18.18,0,0,0-.25-.07.79.79,0,0,0-.43.43h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.16A0,0,0,0,1,72.33,41.59Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.41,40.06a1,1,0,0,1,.63-.44.29.29,0,0,1,.34.18.73.73,0,0,1,0,.36.65.65,0,0,0-.06-.34.18.18,0,0,0-.25-.07.82.82,0,0,0-.44.42h0a.13.13,0,0,1-.17.06.13.13,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M72.1,40.25a1,1,0,0,1,.64-.44.29.29,0,0,1,.34.18.73.73,0,0,1,0,.36A.76.76,0,0,0,73,40a.19.19,0,0,0-.25-.07.82.82,0,0,0-.44.42h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M67,43.88a1,1,0,0,1,.61-.47.28.28,0,0,1,.34.15.74.74,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.06.82.82,0,0,0-.41.45h0a.14.14,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.21,47.13a1,1,0,0,1,.56-.53.31.31,0,0,1,.36.12.91.91,0,0,1,.06.36.81.81,0,0,0-.11-.32.2.2,0,0,0-.27,0,.86.86,0,0,0-.37.49h0a.12.12,0,0,1-.15.09.12.12,0,0,1-.09-.15A0,0,0,0,1,62.21,47.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.21,47.44a1,1,0,0,1,.54-.55.29.29,0,0,1,.36.1.78.78,0,0,1,.08.36.77.77,0,0,0-.13-.32.19.19,0,0,0-.26,0,.85.85,0,0,0-.35.51h0a.13.13,0,0,1-.15.09.12.12,0,0,1-.1-.14A0,0,0,0,1,59.21,47.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.17,44.4a1,1,0,0,1,.58-.5.28.28,0,0,1,.35.13.71.71,0,0,1,0,.35.86.86,0,0,0-.1-.32.2.2,0,0,0-.26-.05.87.87,0,0,0-.39.48h0a.12.12,0,0,1-.15.08.11.11,0,0,1-.09-.15A0,0,0,0,1,61.17,44.4Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.48,38.07a1,1,0,0,1,.62-.45.28.28,0,0,1,.34.16.75.75,0,0,1,0,.36.88.88,0,0,0-.07-.33.19.19,0,0,0-.26-.07.82.82,0,0,0-.43.43h0a.13.13,0,0,1-.16.08.14.14,0,0,1-.08-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M65.79,35.16a1,1,0,0,1,.64-.42.29.29,0,0,1,.34.18.73.73,0,0,1,0,.36.76.76,0,0,0-.06-.34.19.19,0,0,0-.25-.08.88.88,0,0,0-.45.42h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16S65.79,35.17,65.79,35.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M68.51,32.77a1,1,0,0,1,.66-.41.3.3,0,0,1,.33.19.92.92,0,0,1,0,.37.76.76,0,0,0,0-.34.2.2,0,0,0-.25-.09.83.83,0,0,0-.46.4h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.1,33.88a1,1,0,0,1,.64-.43.28.28,0,0,1,.33.17.73.73,0,0,1,0,.36.88.88,0,0,0-.06-.33.2.2,0,0,0-.26-.08.82.82,0,0,0-.44.42h0a.13.13,0,0,1-.17.07.13.13,0,0,1-.06-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.26,37a1,1,0,0,1,.56-.53.28.28,0,0,1,.36.12.72.72,0,0,1,.06.35.72.72,0,0,0-.11-.32.19.19,0,0,0-.26,0,.82.82,0,0,0-.37.49h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.1-.15A0,0,0,0,0,55.26,37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.87,38.24a1,1,0,0,1,.5-.58.27.27,0,0,1,.37.08.63.63,0,0,1,.1.34.7.7,0,0,0-.14-.3.19.19,0,0,0-.27,0,.83.83,0,0,0-.32.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.14S51.87,38.25,51.87,38.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.75,35.3a1,1,0,0,1,.54-.55.27.27,0,0,1,.36.1.72.72,0,0,1,.08.35.75.75,0,0,0-.13-.32.2.2,0,0,0-.26,0,.81.81,0,0,0-.34.5h0a.12.12,0,0,1-.24,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.56,28a1,1,0,0,1,.6-.5.3.3,0,0,1,.35.15.89.89,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.8.8,0,0,0-.4.47h0a.12.12,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66.53,21.58a1.06,1.06,0,0,1,.64-.44.3.3,0,0,1,.34.17.77.77,0,0,1,0,.37.76.76,0,0,0-.06-.34.21.21,0,0,0-.26-.08.86.86,0,0,0-.43.43h0a.12.12,0,0,1-.17.07.13.13,0,0,1-.07-.16S66.53,21.59,66.53,21.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.68,20.35a1,1,0,0,1,.64-.42.29.29,0,0,1,.34.18.87.87,0,0,1,0,.36.93.93,0,0,0-.06-.34.19.19,0,0,0-.25-.08.81.81,0,0,0-.45.42h0a.13.13,0,0,1-.17.06.12.12,0,0,1-.06-.16A0,0,0,0,1,69.68,20.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M70.2,21.41a1,1,0,0,1,.65-.42.3.3,0,0,1,.34.18.86.86,0,0,1,0,.36.72.72,0,0,0-.05-.33.2.2,0,0,0-.25-.09.89.89,0,0,0-.46.41h0a.13.13,0,1,1-.23-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.77,28.16a1,1,0,0,1,.64-.43.29.29,0,0,1,.33.18.73.73,0,0,1,0,.36.61.61,0,0,0-.06-.33.18.18,0,0,0-.25-.08.79.79,0,0,0-.44.42h0a.11.11,0,0,1-.16.07.14.14,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.1,34.39a1,1,0,0,1,.61-.47.29.29,0,0,1,.35.15.9.9,0,0,1,0,.36A.73.73,0,0,0,61,34.1a.2.2,0,0,0-.26-.06.84.84,0,0,0-.42.45h0a.12.12,0,0,1-.16.07.12.12,0,0,1-.08-.16S60.1,34.4,60.1,34.39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,35.26a1,1,0,0,1,.61-.47A.3.3,0,0,1,62,35a.74.74,0,0,1,0,.36.73.73,0,0,0-.08-.33.19.19,0,0,0-.25-.07.84.84,0,0,0-.42.45h0a.12.12,0,0,1-.23-.09S61,35.26,61,35.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M69.76,31.07a1,1,0,0,1,.66-.39.3.3,0,0,1,.33.2.88.88,0,0,1,0,.36.76.76,0,0,0,0-.34.21.21,0,0,0-.25-.1.88.88,0,0,0-.47.4h0a.13.13,0,0,1-.23-.12S69.75,31.08,69.76,31.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M74.85,27a1,1,0,0,1,.68-.38.3.3,0,0,1,.32.21.72.72,0,0,1,0,.36.64.64,0,0,0,0-.34.18.18,0,0,0-.24-.1.81.81,0,0,0-.48.38h0a.13.13,0,0,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.21,25.46a1,1,0,0,1,.67-.37.3.3,0,0,1,.33.2.85.85,0,0,1,0,.36,1,1,0,0,0,0-.34.21.21,0,0,0-.25-.1.85.85,0,0,0-.48.39h0a.12.12,0,0,1-.17.06.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M76.05,27.41a1,1,0,0,1,.68-.37.29.29,0,0,1,.32.21.87.87,0,0,1,0,.36.94.94,0,0,0,0-.34.2.2,0,0,0-.24-.11.9.9,0,0,0-.49.38h0a.13.13,0,0,1-.17.05.12.12,0,0,1,0-.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M73.47,33.78a1,1,0,0,1,.67-.39.29.29,0,0,1,.32.2.86.86,0,0,1,0,.36.73.73,0,0,0,0-.33.18.18,0,0,0-.24-.1.77.77,0,0,0-.47.39h0a.13.13,0,0,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.73,46.59a1,1,0,0,1,.58-.51.29.29,0,0,1,.35.13.75.75,0,0,1,0,.36.67.67,0,0,0-.1-.32.18.18,0,0,0-.26,0,.79.79,0,0,0-.38.48h0a.11.11,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.41,49.08a1,1,0,0,1,.55-.54.28.28,0,0,1,.36.11.87.87,0,0,1,.07.35.88.88,0,0,0-.12-.32.2.2,0,0,0-.26,0,.85.85,0,0,0-.36.5h0a.13.13,0,0,1-.25,0S63.41,49.09,63.41,49.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.21,51.62a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.08.79.79,0,0,1,.09.35.68.68,0,0,0-.14-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.1.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.08,51.46a1,1,0,0,1,.51-.58A.3.3,0,0,1,63,51a.73.73,0,0,1,.09.35.74.74,0,0,0-.14-.31.2.2,0,0,0-.27,0,.85.85,0,0,0-.32.53h0a.13.13,0,1,1-.25,0S62.08,51.47,62.08,51.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.7,49.61a1,1,0,0,1,.55-.55.28.28,0,0,1,.36.11.63.63,0,0,1,.07.35.65.65,0,0,0-.12-.31.18.18,0,0,0-.26,0,.8.8,0,0,0-.36.49h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M66,44.51a1,1,0,0,1,.6-.48.29.29,0,0,1,.35.14.75.75,0,0,1,0,.36.73.73,0,0,0-.08-.33.19.19,0,0,0-.26-.05.82.82,0,0,0-.41.45h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15S66,44.52,66,44.51Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M64.17,35.47a1,1,0,0,1,.64-.44.3.3,0,0,1,.34.17.77.77,0,0,1,0,.37.76.76,0,0,0-.06-.34.21.21,0,0,0-.26-.08.82.82,0,0,0-.43.43h0a.13.13,0,0,1-.16.07.12.12,0,0,1-.07-.17S64.17,35.48,64.17,35.47Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.64,28.6a1,1,0,0,1,.61-.48.3.3,0,0,1,.35.15.75.75,0,0,1,0,.36.76.76,0,0,0-.08-.33.2.2,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.13.13,0,0,1-.16.08.13.13,0,0,1-.08-.16S60.63,28.6,60.64,28.6Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.16,26.44a1,1,0,0,1,.55-.54.27.27,0,0,1,.36.11.61.61,0,0,1,.07.35A.73.73,0,0,0,58,26a.2.2,0,0,0-.26,0,.85.85,0,0,0-.36.5h0a.13.13,0,0,1-.15.1.14.14,0,0,1-.1-.15A0,0,0,0,0,57.16,26.44Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.75,24.44a1,1,0,0,1,.35-.68.3.3,0,0,1,.39,0,.83.83,0,0,1,.17.32.82.82,0,0,0-.21-.27.2.2,0,0,0-.26.05.8.8,0,0,0-.19.58h0a.14.14,0,0,1-.12.14.12.12,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.76,25a1,1,0,0,1,.19-.75.29.29,0,0,1,.37-.09.69.69,0,0,1,.24.27.6.6,0,0,0-.27-.21.2.2,0,0,0-.24.1.87.87,0,0,0,0,.61h0a.13.13,0,0,1-.08.16.12.12,0,0,1-.16-.08S49.76,25,49.76,25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,27.87a1,1,0,0,1-.08-.76.29.29,0,0,1,.31-.22.71.71,0,0,1,.32.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.19.85.85,0,0,0,.16.59h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M39.71,26.94a1,1,0,0,1-.29-.71c0-.13.1-.29.24-.29A.69.69,0,0,1,40,26a.65.65,0,0,0-.34,0,.2.2,0,0,0-.13.23.87.87,0,0,0,.33.52h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.84,28a1,1,0,0,1-.33-.7.3.3,0,0,1,.23-.31.68.68,0,0,1,.35.06.78.78,0,0,0-.34,0,.2.2,0,0,0-.11.24.79.79,0,0,0,.34.5h0a.12.12,0,1,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.4,29.93a1,1,0,0,1-.39-.67.28.28,0,0,1,.2-.32.63.63,0,0,1,.36,0,.64.64,0,0,0-.34,0,.2.2,0,0,0-.1.24.85.85,0,0,0,.4.47h0a.14.14,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.26,31.5a1,1,0,0,1-.43-.64.3.3,0,0,1,.17-.34.73.73,0,0,1,.36,0,.61.61,0,0,0-.33.06.19.19,0,0,0-.08.25.84.84,0,0,0,.43.44h0a.11.11,0,0,1,.07.16.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.29,34.42a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.34.74.74,0,0,1,.36,0,.72.72,0,0,0-.33.07.19.19,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.14.14,0,0,1,.08.17.14.14,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21,39a1,1,0,0,1-.44-.64.3.3,0,0,1,.17-.34.73.73,0,0,1,.36,0,.72.72,0,0,0-.33.06.2.2,0,0,0-.08.25.81.81,0,0,0,.43.44h0a.13.13,0,0,1,.07.16A.13.13,0,0,1,21,39Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.3,41.49a1,1,0,0,1-.41-.65.28.28,0,0,1,.18-.33.87.87,0,0,1,.36,0,.93.93,0,0,0-.34.06.19.19,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.67,40.36a1,1,0,0,1-.43-.64.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.76.76,0,0,0-.34,0,.21.21,0,0,0-.08.26.82.82,0,0,0,.42.44h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.94,37.27a1,1,0,0,1-.45-.62.29.29,0,0,1,.16-.35.88.88,0,0,1,.36,0,.72.72,0,0,0-.33.07.18.18,0,0,0-.07.25A.79.79,0,0,0,21,37h0a.11.11,0,0,1,.07.16.12.12,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.86,32.73a1,1,0,0,1-.46-.61.29.29,0,0,1,.15-.35.78.78,0,0,1,.37,0,.77.77,0,0,0-.34.08.19.19,0,0,0-.06.25.82.82,0,0,0,.44.42h0a.12.12,0,0,1-.09.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.89,30.21a1,1,0,0,1-.45-.62.28.28,0,0,1,.16-.34.75.75,0,0,1,.36,0,.88.88,0,0,0-.33.07.19.19,0,0,0-.07.26A.82.82,0,0,0,23,30h0a.11.11,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.33,29.46a1,1,0,0,1-.44-.64.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.06.2.2,0,0,0-.08.26.82.82,0,0,0,.42.44h0a.14.14,0,0,1,.07.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.41,29.24a1,1,0,0,1-.43-.63.27.27,0,0,1,.17-.34.73.73,0,0,1,.36,0,.93.93,0,0,0-.34.06.2.2,0,0,0-.07.26.86.86,0,0,0,.42.44h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.82,30.5a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.34.78.78,0,0,1,.37,0,.77.77,0,0,0-.34.07.21.21,0,0,0-.07.26.82.82,0,0,0,.44.42h0a.12.12,0,0,1,.08.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.43,34.6A1,1,0,0,1,18,34a.3.3,0,0,1,.16-.35.75.75,0,0,1,.36,0,.76.76,0,0,0-.33.08.2.2,0,0,0-.07.25.89.89,0,0,0,.45.43h0a.13.13,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M18.85,33.7a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.88.88,0,0,0,.45.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M19.7,32.77a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.73.73,0,0,1,.36,0,.84.84,0,0,0-.33.07.2.2,0,0,0-.07.26.84.84,0,0,0,.45.42h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.46,27.66A1,1,0,0,1,22,27a.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.61.61,0,0,0-.33.06.19.19,0,0,0-.08.25.82.82,0,0,0,.42.44h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.63,15.1a.93.93,0,0,1-.09-.76.28.28,0,0,1,.31-.22.74.74,0,0,1,.32.16.84.84,0,0,0-.33-.1.19.19,0,0,0-.18.19.79.79,0,0,0,.17.58h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.78,13.17a1,1,0,0,1-.15-.76.28.28,0,0,1,.29-.24.79.79,0,0,1,.34.14.84.84,0,0,0-.34-.08.21.21,0,0,0-.17.21A.83.83,0,0,0,29,13h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.32,11.35a1,1,0,0,1-.21-.74.29.29,0,0,1,.27-.27.85.85,0,0,1,.35.11.83.83,0,0,0-.34,0,.21.21,0,0,0-.16.22.85.85,0,0,0,.27.55h0a.13.13,0,0,1-.17.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.92,9.08a1,1,0,0,1-.35-.68.31.31,0,0,1,.21-.32.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.21.21,0,0,0-.11.25.85.85,0,0,0,.37.48h0a.13.13,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.27,8.72a1,1,0,0,1-.38-.67.28.28,0,0,1,.2-.32.88.88,0,0,1,.36,0,.76.76,0,0,0-.34,0A.18.18,0,0,0,38,8a.81.81,0,0,0,.39.48h0a.12.12,0,0,1,.06.17.12.12,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.61,10.54a1,1,0,0,1-.27-.73.3.3,0,0,1,.26-.28.82.82,0,0,1,.35.08.8.8,0,0,0-.34,0,.19.19,0,0,0-.14.22.81.81,0,0,0,.3.54h0a.14.14,0,0,1,0,.18.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.94,22.65a1,1,0,0,1-.07-.77.28.28,0,0,1,.32-.21.76.76,0,0,1,.32.17.86.86,0,0,0-.33-.11.19.19,0,0,0-.19.18.81.81,0,0,0,.16.59h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.36,35.77a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.35.89.89,0,0,1,.36,0,.73.73,0,0,0-.33.08.18.18,0,0,0-.07.25.88.88,0,0,0,.44.43h0a.11.11,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.49,42.31a1,1,0,0,1-.41-.65.28.28,0,0,1,.18-.33.72.72,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.13.13,0,0,1,.06.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.87,48.92a1,1,0,0,1-.37-.67.29.29,0,0,1,.21-.32.72.72,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.8.8,0,0,0,.38.48h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.18.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.4,53.28a1,1,0,0,1-.33-.69.29.29,0,0,1,.22-.31.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.87.87,0,0,0,.35.5h0a.12.12,0,1,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.6,57.71a1,1,0,0,1-.27-.72.3.3,0,0,1,.25-.29.79.79,0,0,1,.35.09.66.66,0,0,0-.34,0,.19.19,0,0,0-.13.22.79.79,0,0,0,.3.53h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.86,60.25a1,1,0,0,1-.25-.73.3.3,0,0,1,.26-.28,1,1,0,0,1,.35.09,1,1,0,0,0-.34,0,.2.2,0,0,0-.14.23.9.9,0,0,0,.29.54h0a.13.13,0,1,1-.16.2Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.62,62.53a1,1,0,0,1-.23-.74.29.29,0,0,1,.27-.27.83.83,0,0,1,.35.1.83.83,0,0,0-.34,0,.19.19,0,0,0-.15.22.85.85,0,0,0,.27.55h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.5,62.48a1,1,0,0,1-.28-.71.28.28,0,0,1,.24-.29.73.73,0,0,1,.36.07.83.83,0,0,0-.35,0,.2.2,0,0,0-.13.23.83.83,0,0,0,.32.52h0a.12.12,0,0,1-.14.2A0,0,0,0,1,22.5,62.48Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.56,62.45a1,1,0,0,1-.25-.73.28.28,0,0,1,.25-.28.82.82,0,0,1,.35.09.66.66,0,0,0-.34,0,.19.19,0,0,0-.14.23.85.85,0,0,0,.29.53h0a.11.11,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.55,61.54a1,1,0,0,1-.28-.72.28.28,0,0,1,.24-.29.82.82,0,0,1,.35.08.65.65,0,0,0-.34,0,.19.19,0,0,0-.13.22.86.86,0,0,0,.32.53h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.78,62.44a1,1,0,0,1-.19-.75.29.29,0,0,1,.28-.26.82.82,0,0,1,.34.12.68.68,0,0,0-.34-.06.2.2,0,0,0-.16.21.79.79,0,0,0,.25.56h0a.13.13,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.6,61.46a1,1,0,0,1-.23-.73.29.29,0,0,1,.27-.27.71.71,0,0,1,.35.1.69.69,0,0,0-.34,0,.19.19,0,0,0-.15.22.81.81,0,0,0,.27.54h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,61.24a1,1,0,0,1-.18-.75.27.27,0,0,1,.28-.25.66.66,0,0,1,.34.12.68.68,0,0,0-.34-.06.19.19,0,0,0-.16.21.87.87,0,0,0,.24.56h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.05,59.37a1,1,0,0,1-.19-.75.28.28,0,0,1,.28-.25.77.77,0,0,1,.34.12.65.65,0,0,0-.33-.06.21.21,0,0,0-.17.21.81.81,0,0,0,.25.56h0a.12.12,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,26.05,59.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.85,58.1a1,1,0,0,1-.19-.75.27.27,0,0,1,.28-.25.8.8,0,0,1,.34.11.8.8,0,0,0-.34-.05.19.19,0,0,0-.16.21.88.88,0,0,0,.25.56h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.27,57.81a1,1,0,0,1-.15-.76.29.29,0,0,1,.29-.24.74.74,0,0,1,.34.14.8.8,0,0,0-.33-.08c-.12,0-.17.1-.18.21a.83.83,0,0,0,.22.57h0a.13.13,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.61,56.57a1,1,0,0,1-.14-.76.28.28,0,0,1,.29-.24.75.75,0,0,1,.33.14.76.76,0,0,0-.33-.08.2.2,0,0,0-.17.2.83.83,0,0,0,.21.58h0a.13.13,0,0,1-.18.18S27.61,56.58,27.61,56.57Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.15,55.56a1,1,0,0,1-.16-.75.29.29,0,0,1,.29-.25.75.75,0,0,1,.33.14.66.66,0,0,0-.33-.07.2.2,0,0,0-.17.2.84.84,0,0,0,.23.57h0a.11.11,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.72,55.24a1,1,0,0,1-.11-.76.29.29,0,0,1,.31-.23.69.69,0,0,1,.32.16.75.75,0,0,0-.32-.1c-.12,0-.17.09-.19.2a.85.85,0,0,0,.19.58h0a.12.12,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.43,54.35a1,1,0,0,1-.15-.75.29.29,0,0,1,.29-.25.88.88,0,0,1,.33.13.78.78,0,0,0-.33-.07.19.19,0,0,0-.17.21.83.83,0,0,0,.22.57h0a.13.13,0,1,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,54.07a1,1,0,0,1-.11-.76c0-.13.18-.26.31-.23a.78.78,0,0,1,.33.16.84.84,0,0,0-.33-.1.2.2,0,0,0-.18.2.79.79,0,0,0,.18.58h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S29,54.08,29,54.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.85,52.71A1,1,0,0,1,27.7,52a.28.28,0,0,1,.29-.24.88.88,0,0,1,.33.13.78.78,0,0,0-.33-.07.2.2,0,0,0-.17.2.81.81,0,0,0,.22.57h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.11,52.05a1,1,0,0,1-.11-.76.29.29,0,0,1,.31-.23.78.78,0,0,1,.33.16.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.19.84.84,0,0,0,.18.58h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.12,51.13a1,1,0,0,1-.15-.76.28.28,0,0,1,.29-.24.74.74,0,0,1,.33.13.78.78,0,0,0-.33-.07.19.19,0,0,0-.17.2.81.81,0,0,0,.22.57h0a.13.13,0,1,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.56,50.53a1,1,0,0,1-.11-.77.28.28,0,0,1,.31-.22.73.73,0,0,1,.32.15.78.78,0,0,0-.33-.09.19.19,0,0,0-.18.19.81.81,0,0,0,.19.58h0a.13.13,0,0,1-.19.17Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.74,49.43a1,1,0,0,1-.15-.76.3.3,0,0,1,.3-.24.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08.19.19,0,0,0-.17.21.87.87,0,0,0,.21.57h0a.13.13,0,0,1-.18.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.87,48.58a1,1,0,0,1-.12-.76.29.29,0,0,1,.3-.23.77.77,0,0,1,.33.15.68.68,0,0,0-.33-.09.2.2,0,0,0-.18.2.81.81,0,0,0,.2.58h0a.12.12,0,0,1,0,.18.12.12,0,0,1-.17,0A0,0,0,0,1,29.87,48.58Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.34,47.23a1,1,0,0,1-.18-.75.29.29,0,0,1,.29-.25.82.82,0,0,1,.34.12.68.68,0,0,0-.34-.06.19.19,0,0,0-.16.21.81.81,0,0,0,.23.56h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.57,46.78a1,1,0,0,1-.14-.76.29.29,0,0,1,.3-.24.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08c-.12,0-.17.1-.18.21a.83.83,0,0,0,.22.57h0a.11.11,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.64,45.6a1,1,0,0,1-.22-.74.29.29,0,0,1,.27-.27.76.76,0,0,1,.34.11.78.78,0,0,0-.33-.05.21.21,0,0,0-.16.22.83.83,0,0,0,.27.55h0a.12.12,0,0,1-.16.19Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.73,44.43a1,1,0,0,1-.25-.73.29.29,0,0,1,.26-.28.82.82,0,0,1,.35.09.81.81,0,0,0-.34,0,.22.22,0,0,0-.15.23.86.86,0,0,0,.3.53h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S29.74,44.44,29.73,44.43Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.74,43.13a1,1,0,0,1-.3-.71c0-.13.1-.29.24-.29a.85.85,0,0,1,.36.06.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.87.87,0,0,0,.33.52h0a.13.13,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.61,42.25a1,1,0,0,1-.3-.71.3.3,0,0,1,.24-.3,1,1,0,0,1,.35.07.65.65,0,0,0-.34,0,.19.19,0,0,0-.12.23.81.81,0,0,0,.32.51h0a.13.13,0,0,1-.14.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.62,41.07a1,1,0,0,1-.34-.69.28.28,0,0,1,.22-.31.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.79.79,0,0,0,.36.49h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.27,39.93a1,1,0,0,1-.35-.69.28.28,0,0,1,.21-.31.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.12.12,0,1,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.22,38.84a1,1,0,0,1-.35-.69.3.3,0,0,1,.21-.31.72.72,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.14.14,0,0,1,.05.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.15,37.83a1,1,0,0,1-.35-.68.31.31,0,0,1,.21-.32,1,1,0,0,1,.36,0,.77.77,0,0,0-.34,0,.2.2,0,0,0-.11.24.84.84,0,0,0,.37.49h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.85,36.32a1,1,0,0,1-.37-.68.29.29,0,0,1,.21-.32.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.77.77,0,0,0,.38.48h0a.13.13,0,0,1,.06.17.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.64,34.84a1,1,0,0,1-.38-.67.29.29,0,0,1,.2-.32.72.72,0,0,1,.36,0,.65.65,0,0,0-.34,0,.2.2,0,0,0-.1.24.82.82,0,0,0,.39.48h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.23,33.38a1,1,0,0,1-.38-.66.3.3,0,0,1,.2-.33.88.88,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.1.25.87.87,0,0,0,.39.47h0a.12.12,0,0,1,.05.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.28,32.38a1,1,0,0,1-.4-.66.3.3,0,0,1,.19-.33.86.86,0,0,1,.36,0,.72.72,0,0,0-.33,0,.19.19,0,0,0-.1.25.83.83,0,0,0,.4.46h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.05,34.13a1,1,0,0,1-.44-.63.29.29,0,0,1,.17-.34.74.74,0,0,1,.36,0,.88.88,0,0,0-.33.06.21.21,0,0,0-.08.26.84.84,0,0,0,.43.44h0a.12.12,0,0,1,.06.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.87,37.15a1,1,0,0,1-.44-.64.29.29,0,0,1,.18-.34.87.87,0,0,1,.36,0,.93.93,0,0,0-.34.06.19.19,0,0,0-.07.25.84.84,0,0,0,.42.45h0a.14.14,0,0,1,.07.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.77,41.63a1,1,0,0,1-.39-.67.27.27,0,0,1,.19-.32.62.62,0,0,1,.36,0,.65.65,0,0,0-.34,0,.18.18,0,0,0-.09.24.84.84,0,0,0,.39.47h0a.14.14,0,0,1,.06.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.63,47.05a1,1,0,0,1-.3-.71.29.29,0,0,1,.24-.3.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.86.86,0,0,0,.33.52h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.37,50.08a1,1,0,0,1-.26-.72.29.29,0,0,1,.25-.29.7.7,0,0,1,.35.09.79.79,0,0,0-.34,0,.2.2,0,0,0-.14.23.83.83,0,0,0,.31.53h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.17,55.08a1,1,0,0,1-.26-.73.28.28,0,0,1,.25-.28.82.82,0,0,1,.35.08.78.78,0,0,0-.34,0,.21.21,0,0,0-.14.23.87.87,0,0,0,.31.53h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.93,59.31a1,1,0,0,1-.28-.72.3.3,0,0,1,.24-.29.86.86,0,0,1,.36.08.8.8,0,0,0-.34,0,.2.2,0,0,0-.14.23.86.86,0,0,0,.32.53h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.82,65.88a1,1,0,0,1-.22-.74c0-.13.12-.28.26-.27a.85.85,0,0,1,.35.11.8.8,0,0,0-.34,0,.21.21,0,0,0-.15.22.83.83,0,0,0,.27.55h0a.12.12,0,0,1-.16.19S24.82,65.89,24.82,65.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23,67.88a1,1,0,0,1-.27-.72c0-.13.12-.29.26-.29a.82.82,0,0,1,.35.09.67.67,0,0,0-.34,0,.18.18,0,0,0-.14.22.81.81,0,0,0,.3.53h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0S23,67.89,23,67.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.78,67.94a1,1,0,0,1-.29-.71.29.29,0,0,1,.25-.29.69.69,0,0,1,.35.07.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.86.86,0,0,0,.32.52h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.18,0S21.79,68,21.78,67.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.66,64.31a1,1,0,0,1-.27-.72.29.29,0,0,1,.25-.29.82.82,0,0,1,.35.08.78.78,0,0,0-.34,0,.19.19,0,0,0-.13.23.86.86,0,0,0,.3.53h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M21.23,55.31a1,1,0,0,1-.35-.69.29.29,0,0,1,.22-.31.87.87,0,0,1,.36,0,.77.77,0,0,0-.34,0,.19.19,0,0,0-.12.24.84.84,0,0,0,.37.49h0a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.09,48.44a1,1,0,0,1-.38-.67.3.3,0,0,1,.2-.33.88.88,0,0,1,.36,0,.94.94,0,0,0-.34,0,.2.2,0,0,0-.1.25.77.77,0,0,0,.39.47h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M20.83,44a1,1,0,0,1-.39-.66.27.27,0,0,1,.19-.32A.72.72,0,0,1,21,43a.94.94,0,0,0-.34,0,.2.2,0,0,0-.09.25.82.82,0,0,0,.4.47h0A.12.12,0,0,1,21,44a.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.27,39.44a1,1,0,0,1-.44-.63.29.29,0,0,1,.17-.34.63.63,0,0,1,.36,0,.7.7,0,0,0-.33.06.21.21,0,0,0-.08.26.82.82,0,0,0,.43.43h0a.13.13,0,0,1,.07.16.13.13,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,35.85a1,1,0,0,1-.46-.62.29.29,0,0,1,.16-.35.88.88,0,0,1,.36,0,.7.7,0,0,0-.33.08.19.19,0,0,0-.07.26.86.86,0,0,0,.44.42h0a.13.13,0,0,1,.08.16.14.14,0,0,1-.17.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.55,32.35a1,1,0,0,1-.45-.63.3.3,0,0,1,.17-.34.87.87,0,0,1,.36,0,.7.7,0,0,0-.33.07.19.19,0,0,0-.08.25.84.84,0,0,0,.44.43h0a.13.13,0,0,1,.07.16.12.12,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.82,28.71a1,1,0,0,1-.36-.68.29.29,0,0,1,.21-.32.85.85,0,0,1,.36,0,.77.77,0,0,0-.34,0,.22.22,0,0,0-.11.25.85.85,0,0,0,.37.48h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M31.62,26.83a1,1,0,0,1-.24-.74.27.27,0,0,1,.26-.27.82.82,0,0,1,.35.09.67.67,0,0,0-.34,0,.2.2,0,0,0-.15.22.87.87,0,0,0,.29.54h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32.45,25.69a1,1,0,0,1-.16-.75.31.31,0,0,1,.3-.25.75.75,0,0,1,.33.14.8.8,0,0,0-.33-.08.2.2,0,0,0-.17.21.83.83,0,0,0,.22.57h0a.14.14,0,0,1,0,.18.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.38,23.75a1,1,0,0,1,0-.77.28.28,0,0,1,.34-.17A.66.66,0,0,1,34,23a.79.79,0,0,0-.31-.14c-.11,0-.18.06-.21.16a.82.82,0,0,0,.09.6h0a.12.12,0,0,1-.21.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.07,22.1a1,1,0,0,1,.1-.76.29.29,0,0,1,.36-.14.9.9,0,0,1,.27.24.88.88,0,0,0-.29-.18.21.21,0,0,0-.23.14.8.8,0,0,0,0,.61h0a.13.13,0,0,1-.06.17.13.13,0,0,1-.17-.06A0,0,0,0,1,36.07,22.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.84,21a1,1,0,0,1-.09-.77.3.3,0,0,1,.32-.22.76.76,0,0,1,.32.17.7.7,0,0,0-.33-.1.19.19,0,0,0-.19.18.9.9,0,0,0,.17.59h0a.12.12,0,0,1-.19.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.85,19.92a1,1,0,0,1-.32-.7.28.28,0,0,1,.23-.3.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.19.19,0,0,0-.12.24.77.77,0,0,0,.34.5h0a.12.12,0,1,1-.13.21A0,0,0,0,1,41.85,19.92Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.41,20.09a1,1,0,0,1-.33-.7.31.31,0,0,1,.23-.31.85.85,0,0,1,.36.06.78.78,0,0,0-.34,0,.19.19,0,0,0-.12.24.83.83,0,0,0,.34.5h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.17,22.54a1,1,0,0,1-.05-.77.28.28,0,0,1,.32-.2.76.76,0,0,1,.32.17.82.82,0,0,0-.32-.11c-.12,0-.18.08-.2.18a.81.81,0,0,0,.14.59h0a.12.12,0,0,1,0,.17.11.11,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M50.66,30.84a1,1,0,0,1,.41-.65.29.29,0,0,1,.38,0,.76.76,0,0,1,.15.33.77.77,0,0,0-.19-.28.19.19,0,0,0-.26,0,.83.83,0,0,0-.24.56h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.17,40.06a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.08.76.76,0,0,1,.09.35A.68.68,0,0,0,53,39.6a.19.19,0,0,0-.26,0,.81.81,0,0,0-.32.52h0a.13.13,0,0,1-.15.11.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.14,46.61a.94.94,0,0,1,.5-.57.28.28,0,0,1,.37.08.74.74,0,0,1,.1.35,1,1,0,0,0-.14-.31.2.2,0,0,0-.27,0,.83.83,0,0,0-.32.52h0a.13.13,0,0,1-.14.1.12.12,0,0,1-.11-.14A0,0,0,0,1,55.14,46.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.54,49.76a1,1,0,0,1,.49-.59.28.28,0,0,1,.37.07.76.76,0,0,1,.11.35.81.81,0,0,0-.15-.31.2.2,0,0,0-.27,0,.84.84,0,0,0-.3.53h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.59,54.05a1,1,0,0,1,.45-.62.29.29,0,0,1,.38,0,1,1,0,0,1,.13.34.82.82,0,0,0-.17-.29.2.2,0,0,0-.27,0,.83.83,0,0,0-.27.55h0a.13.13,0,0,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.49,57.16a1,1,0,0,1,.41-.66.3.3,0,0,1,.38,0,.76.76,0,0,1,.15.33.7.7,0,0,0-.19-.28.2.2,0,0,0-.27,0,.87.87,0,0,0-.23.56h0a.14.14,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.61,59.26a1,1,0,0,1,.38-.67.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32.68.68,0,0,0-.2-.27.19.19,0,0,0-.26,0,.84.84,0,0,0-.21.57h0a.12.12,0,0,1-.12.13.11.11,0,0,1-.13-.11S58.61,59.27,58.61,59.26Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.07,60.92a1,1,0,0,1,.36-.68.29.29,0,0,1,.38,0,.83.83,0,0,1,.17.32.82.82,0,0,0-.21-.27.2.2,0,0,0-.26,0,.8.8,0,0,0-.19.58h0a.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.15,61.27a1,1,0,0,1,.36-.68.29.29,0,0,1,.38,0,.83.83,0,0,1,.17.32.82.82,0,0,0-.21-.27.2.2,0,0,0-.26,0,.93.93,0,0,0-.19.59h0a.12.12,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,62.54a1,1,0,0,1,.34-.7.29.29,0,0,1,.38,0,.87.87,0,0,1,.18.31.71.71,0,0,0-.22-.26.2.2,0,0,0-.26,0,.87.87,0,0,0-.17.59h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.34,63.09a1,1,0,0,1,.33-.7.29.29,0,0,1,.38,0,.84.84,0,0,1,.19.31.83.83,0,0,0-.23-.26.21.21,0,0,0-.26.06.91.91,0,0,0-.16.59h0a.12.12,0,0,1-.1.14.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.38,61.22a1,1,0,0,1,.35-.69.29.29,0,0,1,.38,0,.85.85,0,0,1,.18.32.84.84,0,0,0-.22-.27.2.2,0,0,0-.26,0,.88.88,0,0,0-.18.59h0a.12.12,0,0,1-.12.13.11.11,0,0,1-.13-.11A0,0,0,0,1,62.38,61.22Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M63.58,54.63A1,1,0,0,1,64,54a.3.3,0,0,1,.38.05.78.78,0,0,1,.12.34.69.69,0,0,0-.17-.3.2.2,0,0,0-.26,0,.84.84,0,0,0-.28.55h0a.12.12,0,0,1-.13.12.14.14,0,0,1-.12-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.51,41.86a1,1,0,0,1,.61-.48.3.3,0,0,1,.35.15.88.88,0,0,1,0,.36.85.85,0,0,0-.09-.33.19.19,0,0,0-.26-.06.86.86,0,0,0-.41.45h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.38,35.79a1,1,0,0,1,.62-.45.29.29,0,0,1,.34.17.73.73,0,0,1,0,.36.94.94,0,0,0-.07-.34.21.21,0,0,0-.26-.07.88.88,0,0,0-.43.44h0a.14.14,0,0,1-.16.07.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.52,33.37a1,1,0,0,1,.62-.45.29.29,0,0,1,.35.16.87.87,0,0,1,0,.36.7.7,0,0,0-.07-.33.19.19,0,0,0-.26-.07.86.86,0,0,0-.42.44h0a.14.14,0,0,1-.17.07.13.13,0,0,1-.07-.16S61.52,33.38,61.52,33.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.21,31.73a1,1,0,0,1,.61-.47.3.3,0,0,1,.35.15.73.73,0,0,1,0,.36.7.7,0,0,0-.08-.33.19.19,0,0,0-.26-.06.84.84,0,0,0-.41.44h0a.14.14,0,0,1-.17.08.12.12,0,0,1-.07-.16S60.2,31.73,60.21,31.73Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59,30.94a1.06,1.06,0,0,1,.6-.49.29.29,0,0,1,.35.15A.74.74,0,0,1,60,31a.85.85,0,0,0-.09-.33.2.2,0,0,0-.26-.06.81.81,0,0,0-.4.46h0a.12.12,0,0,1-.15.09A.14.14,0,0,1,59,31,0,0,0,0,0,59,30.94Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.76,29.77a.94.94,0,0,1,.58-.51.28.28,0,0,1,.35.13.75.75,0,0,1,.05.36.85.85,0,0,0-.1-.33.2.2,0,0,0-.26-.05.87.87,0,0,0-.39.48h0a.13.13,0,0,1-.15.09.13.13,0,0,1-.09-.16S57.75,29.77,57.76,29.77Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.25,31a1.05,1.05,0,0,1,.57-.53.3.3,0,0,1,.36.12.91.91,0,0,1,.06.36.82.82,0,0,0-.12-.32.19.19,0,0,0-.26,0,.86.86,0,0,0-.37.49h0a.13.13,0,0,1-.15.09.12.12,0,0,1-.09-.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.74,32.24a1,1,0,0,1,.57-.52.3.3,0,0,1,.36.13.75.75,0,0,1,0,.36.85.85,0,0,0-.1-.33.2.2,0,0,0-.26,0,.81.81,0,0,0-.38.48h0a.11.11,0,0,1-.15.09.13.13,0,0,1-.09-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.85,33.36a1,1,0,0,1,.6-.49.29.29,0,0,1,.35.15.74.74,0,0,1,0,.36.7.7,0,0,0-.08-.33.21.21,0,0,0-.26-.06.87.87,0,0,0-.41.46h0a.12.12,0,0,1-.16.08.13.13,0,0,1-.08-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.92,32.24a1,1,0,0,1,.6-.48.27.27,0,0,1,.35.14.89.89,0,0,1,0,.36.85.85,0,0,0-.09-.33.2.2,0,0,0-.26-.06.81.81,0,0,0-.4.46h0a.12.12,0,0,1-.16.08.12.12,0,0,1-.08-.15S58.92,32.25,58.92,32.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.22,30.6a1,1,0,0,1,.58-.51.28.28,0,0,1,.35.12.94.94,0,0,1,.06.36.72.72,0,0,0-.11-.32.2.2,0,0,0-.26-.05.85.85,0,0,0-.38.48h0a.13.13,0,1,1-.25-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.48,26a1,1,0,0,1,.43-.63.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.34.84.84,0,0,0-.18-.29.2.2,0,0,0-.27,0,.82.82,0,0,0-.25.55h0a.13.13,0,0,1-.13.13.12.12,0,0,1-.12-.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51,23.35a1,1,0,0,1,.22-.74.27.27,0,0,1,.37-.08.67.67,0,0,1,.23.27.77.77,0,0,0-.26-.22.2.2,0,0,0-.24.1.78.78,0,0,0-.07.61h0a.13.13,0,0,1-.08.16.13.13,0,0,1-.16-.09S51,23.36,51,23.35Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.2,21.08a1,1,0,0,1-.06-.77.29.29,0,0,1,.32-.2.67.67,0,0,1,.31.17.76.76,0,0,0-.32-.11.19.19,0,0,0-.19.18.76.76,0,0,0,.15.59h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,49.2,21.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.62,19.72a1,1,0,0,1-.24-.73.3.3,0,0,1,.26-.28.83.83,0,0,1,.35.1.81.81,0,0,0-.34,0,.22.22,0,0,0-.15.23.9.9,0,0,0,.29.54h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.79,18.09a1,1,0,0,1-.39-.66.3.3,0,0,1,.2-.33.86.86,0,0,1,.36,0,.78.78,0,0,0-.34,0,.2.2,0,0,0-.09.25.87.87,0,0,0,.39.47h0a.13.13,0,0,1-.12.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44.56,16.75a1,1,0,0,1-.42-.64.3.3,0,0,1,.18-.34.72.72,0,0,1,.36,0,.72.72,0,0,0-.33.05.2.2,0,0,0-.09.25.84.84,0,0,0,.42.45h0a.13.13,0,0,1,.07.17.14.14,0,0,1-.17.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.71,15.47a1,1,0,0,1-.41-.65.29.29,0,0,1,.18-.33.72.72,0,0,1,.36,0,.72.72,0,0,0-.33.05.19.19,0,0,0-.09.25.87.87,0,0,0,.4.46h0a.13.13,0,1,1-.1.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45,15a1,1,0,0,1-.44-.63.3.3,0,0,1,.17-.34.88.88,0,0,1,.36,0,.88.88,0,0,0-.33.07.2.2,0,0,0-.08.25.84.84,0,0,0,.43.44h0a.12.12,0,0,1,.07.17.13.13,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.83,14.83a1,1,0,0,1-.45-.63.29.29,0,0,1,.17-.34.73.73,0,0,1,.36,0,.94.94,0,0,0-.34.07.21.21,0,0,0-.07.26.88.88,0,0,0,.44.43h0a.14.14,0,0,1,.07.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.43,14.93a1,1,0,0,1-.43-.64.32.32,0,0,1,.18-.34.87.87,0,0,1,.36,0,.88.88,0,0,0-.33.06.19.19,0,0,0-.08.25.79.79,0,0,0,.41.45h0a.12.12,0,0,1,.07.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.55,16.1a1,1,0,0,1-.33-.7.29.29,0,0,1,.23-.31.74.74,0,0,1,.36.06.65.65,0,0,0-.34,0,.19.19,0,0,0-.12.24.79.79,0,0,0,.34.5h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.27,17.37a1,1,0,0,1-.11-.77.29.29,0,0,1,.3-.22.77.77,0,0,1,.33.15.68.68,0,0,0-.33-.09.19.19,0,0,0-.18.19.81.81,0,0,0,.19.58h0a.14.14,0,0,1,0,.18.14.14,0,0,1-.18,0S51.28,17.37,51.27,17.37Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.22,19.1a.94.94,0,0,1,.17-.75.28.28,0,0,1,.36-.1.77.77,0,0,1,.25.26.73.73,0,0,0-.27-.2.19.19,0,0,0-.24.11.81.81,0,0,0,0,.61h0a.13.13,0,0,1-.07.16.13.13,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.71,26.46a1,1,0,0,1,.51-.58.29.29,0,0,1,.37.09.73.73,0,0,1,.09.35.68.68,0,0,0-.14-.31.2.2,0,0,0-.26,0,.87.87,0,0,0-.33.52h0a.12.12,0,0,1-.14.11.13.13,0,0,1-.11-.15S55.71,26.47,55.71,26.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57.8,38.88a1,1,0,0,1,.59-.5.28.28,0,0,1,.35.13.75.75,0,0,1,0,.36.91.91,0,0,0-.1-.33.19.19,0,0,0-.26,0A.82.82,0,0,0,58,39h0a.13.13,0,0,1-.25-.07A0,0,0,0,0,57.8,38.88Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.1,48.46a1,1,0,0,1,.52-.56A.28.28,0,0,1,60,48a.72.72,0,0,1,.08.35.9.9,0,0,0-.13-.32.2.2,0,0,0-.26,0,.8.8,0,0,0-.34.51h0a.12.12,0,0,1-.15.1.12.12,0,0,1-.1-.14A0,0,0,0,1,59.1,48.46Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.66,55.34a1,1,0,0,1,.45-.63.28.28,0,0,1,.37,0,.73.73,0,0,1,.14.33,1,1,0,0,0-.18-.29.19.19,0,0,0-.26,0,.83.83,0,0,0-.27.55h0a.13.13,0,1,1-.25,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.82,59.56a1,1,0,0,1,.38-.67.29.29,0,0,1,.38,0,.92.92,0,0,1,.16.33.75.75,0,0,0-.2-.28.2.2,0,0,0-.26,0,.82.82,0,0,0-.21.58h0a.12.12,0,0,1-.12.13.12.12,0,0,1-.13-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62.69,62.86a1,1,0,0,1,.33-.7.29.29,0,0,1,.38,0,.77.77,0,0,1,.19.31.73.73,0,0,0-.23-.26.19.19,0,0,0-.26.06.91.91,0,0,0-.16.59h0a.12.12,0,0,1-.1.14.14.14,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.86,65.83a1,1,0,0,1,.28-.72.29.29,0,0,1,.38,0,1,1,0,0,1,.21.3.91.91,0,0,0-.24-.24.2.2,0,0,0-.26.08.79.79,0,0,0-.12.59h0A.13.13,0,0,1,62,66a.13.13,0,0,1-.15-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61.74,69.82a1,1,0,0,1,.21-.74.27.27,0,0,1,.37-.08.67.67,0,0,1,.23.27.77.77,0,0,0-.26-.22.2.2,0,0,0-.24.1.82.82,0,0,0-.07.61h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M62,72.68a1,1,0,0,1,.15-.76.29.29,0,0,1,.36-.11.78.78,0,0,1,.26.25.73.73,0,0,0-.28-.19.18.18,0,0,0-.23.12.81.81,0,0,0,0,.61h0a.12.12,0,1,1-.23.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M61,72.52a1,1,0,0,1,.15-.75.28.28,0,0,1,.36-.11.69.69,0,0,1,.26.25.86.86,0,0,0-.28-.2.2.2,0,0,0-.24.13.86.86,0,0,0,0,.61h0a.14.14,0,0,1-.07.17.13.13,0,0,1-.16-.07A0,0,0,0,1,61,72.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60,72.07a1,1,0,0,1,.16-.76.29.29,0,0,1,.36-.11.81.81,0,0,1,.26.26.86.86,0,0,0-.28-.2.2.2,0,0,0-.24.12.81.81,0,0,0,0,.61h0a.11.11,0,0,1-.07.16.11.11,0,0,1-.16-.07S60,72.08,60,72.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.47,74a1,1,0,0,1,.12-.77.29.29,0,0,1,.36-.12.78.78,0,0,1,.27.24.64.64,0,0,0-.29-.18.19.19,0,0,0-.23.13.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06S60.48,74,60.47,74Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.9,75.72A1,1,0,0,1,61,75a.31.31,0,0,1,.36-.14.87.87,0,0,1,.27.23.72.72,0,0,0-.29-.17.21.21,0,0,0-.23.14.8.8,0,0,0,0,.61h0a.12.12,0,0,1-.06.17.13.13,0,0,1-.17-.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.34,77.12a1,1,0,0,1,.14-.76.29.29,0,0,1,.36-.12.78.78,0,0,1,.26.25.82.82,0,0,0-.28-.19.21.21,0,0,0-.24.13.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.06.16.13.13,0,0,1-.17-.06A0,0,0,0,0,60.34,77.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.49,79a1,1,0,0,1,.22-.74.3.3,0,0,1,.37-.08.92.92,0,0,1,.24.28.77.77,0,0,0-.26-.22.19.19,0,0,0-.25.1.79.79,0,0,0-.07.6h0a.13.13,0,0,1-.09.16A.12.12,0,0,1,60.5,79S60.49,79,60.49,79Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M60.27,80.39a1,1,0,0,1,.28-.72.29.29,0,0,1,.38,0,.78.78,0,0,1,.21.3.72.72,0,0,0-.24-.25.21.21,0,0,0-.26.08.83.83,0,0,0-.13.59h0a.14.14,0,0,1-.1.15.13.13,0,0,1-.15-.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M59.52,82.1a1,1,0,0,1,.38-.67.3.3,0,0,1,.38,0,1,1,0,0,1,.17.33.76.76,0,0,0-.21-.28.19.19,0,0,0-.26,0,.84.84,0,0,0-.21.57h0a.13.13,0,0,1-.26,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58.84,85.54a1,1,0,0,1,.53-.56.31.31,0,0,1,.37.1.89.89,0,0,1,.08.35.79.79,0,0,0-.13-.31.19.19,0,0,0-.27,0,.79.79,0,0,0-.34.5h0a.13.13,0,0,1-.15.1.12.12,0,0,1-.1-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M58,88.14a1,1,0,0,1,.61-.46.28.28,0,0,1,.35.16.73.73,0,0,1,0,.36.77.77,0,0,0-.08-.34.19.19,0,0,0-.25-.06.82.82,0,0,0-.42.44h0a.12.12,0,0,1-.23-.09S58,88.14,58,88.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M57,90.31a1,1,0,0,1,.67-.39.28.28,0,0,1,.32.2.72.72,0,0,1,0,.36.64.64,0,0,0,0-.34.18.18,0,0,0-.24-.09.77.77,0,0,0-.47.39h0a.13.13,0,1,1-.22-.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.19,93.05a1,1,0,0,1,.67-.38.29.29,0,0,1,.32.2.72.72,0,0,1,0,.36.65.65,0,0,0,0-.34.2.2,0,0,0-.24-.1.85.85,0,0,0-.48.39h0a.13.13,0,0,1-.17,0,.12.12,0,0,1-.06-.17S56.18,93.05,56.19,93.05Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.17,94.18a1,1,0,0,1,.63-.45.29.29,0,0,1,.34.16.74.74,0,0,1,0,.36.73.73,0,0,0-.08-.33.18.18,0,0,0-.25-.07.81.81,0,0,0-.43.44h0a.13.13,0,0,1-.16.08.14.14,0,0,1-.08-.17S55.17,94.19,55.17,94.18Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55,91.54a1,1,0,0,1,.65-.42.3.3,0,0,1,.34.18.86.86,0,0,1,0,.36.72.72,0,0,0-.05-.33.2.2,0,0,0-.25-.09.83.83,0,0,0-.45.41h0a.12.12,0,0,1-.16.06.11.11,0,0,1-.07-.16Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.37,85.89a.94.94,0,0,1,.54-.55.27.27,0,0,1,.36.1.74.74,0,0,1,.08.35.66.66,0,0,0-.13-.31.19.19,0,0,0-.26,0,.87.87,0,0,0-.35.5h0a.13.13,0,0,1-.25,0S55.37,85.9,55.37,85.89Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.92,82.49a1,1,0,0,1,.37-.67.28.28,0,0,1,.38,0,.71.71,0,0,1,.17.32.79.79,0,0,0-.2-.27.2.2,0,0,0-.27,0,.86.86,0,0,0-.2.58h0a.11.11,0,0,1-.11.13.12.12,0,0,1-.14-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.89,78.42a1,1,0,0,1,.15-.75.28.28,0,0,1,.36-.12.72.72,0,0,1,.26.26.76.76,0,0,0-.28-.2.2.2,0,0,0-.24.12.86.86,0,0,0,0,.61h0a.12.12,0,0,1-.07.17.13.13,0,0,1-.16-.07A0,0,0,0,1,54.89,78.42Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.29,77.09a1,1,0,0,1,.09-.76.28.28,0,0,1,.35-.14.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.1,0-.18,0-.22.14a.85.85,0,0,0,0,.61h0a.13.13,0,0,1-.23.12S54.29,77.1,54.29,77.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56,76.66a1,1,0,0,1,.08-.77.31.31,0,0,1,.36-.14.87.87,0,0,1,.27.23.72.72,0,0,0-.29-.17.21.21,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17-.06S56,76.67,56,76.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.32,74.61a1,1,0,0,1,.09-.76.28.28,0,0,1,.35-.14.79.79,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.11,0-.18,0-.22.14a.85.85,0,0,0,0,.61h0a.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0S55.32,74.62,55.32,74.61Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M54.54,75.1a1,1,0,0,1,.08-.77.29.29,0,0,1,.35-.15.91.91,0,0,1,.28.23.83.83,0,0,0-.3-.17.19.19,0,0,0-.22.15.85.85,0,0,0,0,.61h0a.12.12,0,0,1-.05.17.12.12,0,0,1-.17-.06A0,0,0,0,1,54.54,75.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M56.28,75.12a1.07,1.07,0,0,1,.08-.77.28.28,0,0,1,.35-.14.63.63,0,0,1,.28.23.67.67,0,0,0-.29-.17.19.19,0,0,0-.23.14.85.85,0,0,0,0,.61h0a.14.14,0,0,1-.06.17.13.13,0,0,1-.17-.06S56.29,75.13,56.28,75.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.18,76.28a1,1,0,0,1,.07-.77.29.29,0,0,1,.35-.15.91.91,0,0,1,.28.23.75.75,0,0,0-.3-.17c-.11,0-.18,0-.22.15a.8.8,0,0,0,0,.61h0a.13.13,0,0,1,0,.17.12.12,0,0,1-.17-.06S55.18,76.29,55.18,76.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.66,79.59a1,1,0,0,1,.22-.74.3.3,0,0,1,.37-.08.77.77,0,0,1,.23.28.65.65,0,0,0-.25-.22.2.2,0,0,0-.25.1.8.8,0,0,0-.08.6h0a.13.13,0,1,1-.25.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.94,82.13a1,1,0,0,1,.36-.68.28.28,0,0,1,.38,0,.79.79,0,0,1,.17.31.78.78,0,0,0-.21-.26.19.19,0,0,0-.26,0,.81.81,0,0,0-.19.58h0a.13.13,0,0,1-.12.14.12.12,0,0,1-.13-.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M55.19,84.74a1.05,1.05,0,0,1,.49-.6.3.3,0,0,1,.38.07.88.88,0,0,1,.1.35.71.71,0,0,0-.15-.3.2.2,0,0,0-.27,0,.84.84,0,0,0-.3.53h0a.12.12,0,0,1-.14.11.12.12,0,0,1-.11-.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M53.79,88.15a1,1,0,0,1,.61-.47.28.28,0,0,1,.34.15.74.74,0,0,1,0,.36.73.73,0,0,0-.08-.33.2.2,0,0,0-.26-.06.82.82,0,0,0-.41.45h0a.14.14,0,0,1-.16.08.13.13,0,0,1-.08-.16A0,0,0,0,1,53.79,88.15Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.78,91.27a1,1,0,0,1,.53-.56.31.31,0,0,1,.37.1.94.94,0,0,1,.08.36.77.77,0,0,0-.13-.32.2.2,0,0,0-.27,0,.79.79,0,0,0-.34.5h0a.13.13,0,0,1-.15.1.13.13,0,0,1-.1-.15S52.77,91.27,52.78,91.27Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52.46,95a1,1,0,0,1,.42-.64.29.29,0,0,1,.38,0,.73.73,0,0,1,.14.34.84.84,0,0,0-.18-.29.21.21,0,0,0-.27,0,.83.83,0,0,0-.24.56h0a.13.13,0,1,1-.26,0S52.46,95,52.46,95Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M52,97a1,1,0,0,1,.39-.67.29.29,0,0,1,.38,0,.81.81,0,0,1,.16.32.79.79,0,0,0-.2-.27.19.19,0,0,0-.27,0,.82.82,0,0,0-.21.57h0a.12.12,0,0,1-.12.13A.12.12,0,0,1,52,97Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M51.1,99.14a1,1,0,0,1,.31-.7.28.28,0,0,1,.38,0,.75.75,0,0,1,.2.3.8.8,0,0,0-.23-.25.19.19,0,0,0-.26.07.8.8,0,0,0-.15.59h0a.12.12,0,0,1-.24,0A0,0,0,0,1,51.1,99.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M49.46,101.6a1,1,0,0,1,.2-.75.28.28,0,0,1,.37-.09.88.88,0,0,1,.24.27,1,1,0,0,0-.27-.21.19.19,0,0,0-.24.11.79.79,0,0,0-.06.6h0a.12.12,0,0,1-.08.16.13.13,0,0,1-.16-.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M48.67,103.71a1,1,0,0,1,.19-.75.29.29,0,0,1,.37-.09.62.62,0,0,1,.24.27.77.77,0,0,0-.26-.22A.21.21,0,0,0,49,103a.87.87,0,0,0-.05.61h0a.13.13,0,0,1-.08.16.12.12,0,0,1-.16-.08A0,0,0,0,1,48.67,103.71Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M47.81,104.83a1,1,0,0,1,.16-.75.29.29,0,0,1,.36-.11.72.72,0,0,1,.26.26.76.76,0,0,0-.28-.2c-.11,0-.19,0-.24.12a.81.81,0,0,0,0,.61h0a.14.14,0,0,1-.07.16.14.14,0,0,1-.17-.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M46.77,106.24a.93.93,0,0,1,.14-.75.28.28,0,0,1,.36-.12.78.78,0,0,1,.26.25.82.82,0,0,0-.28-.19.2.2,0,0,0-.24.12.86.86,0,0,0,0,.61h0a.13.13,0,0,1-.24.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M45.48,107.28a1,1,0,0,1,.1-.76.29.29,0,0,1,.35-.14.81.81,0,0,1,.28.24.67.67,0,0,0-.3-.18c-.1,0-.18,0-.22.14a.8.8,0,0,0,0,.61h0a.13.13,0,0,1-.23.11A0,0,0,0,0,45.48,107.28Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M44,107.76a1,1,0,0,1,0-.77.29.29,0,0,1,.34-.17.75.75,0,0,1,.29.2.73.73,0,0,0-.31-.14.2.2,0,0,0-.21.16.9.9,0,0,0,.09.61h0a.13.13,0,0,1,0,.18.13.13,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M43.73,108.78a1,1,0,0,1,.05-.77.29.29,0,0,1,.34-.16.93.93,0,0,1,.29.22.77.77,0,0,0-.3-.15.19.19,0,0,0-.22.15.82.82,0,0,0,.06.6h0a.13.13,0,0,1-.05.17.12.12,0,0,1-.17,0A0,0,0,0,1,43.73,108.78Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M42.56,108.52a1,1,0,0,1-.05-.77.29.29,0,0,1,.33-.2.87.87,0,0,1,.31.18,1,1,0,0,0-.32-.12.2.2,0,0,0-.2.18.83.83,0,0,0,.14.6h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0S42.56,108.53,42.56,108.52Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M41.29,107.67a1,1,0,0,1-.17-.75.29.29,0,0,1,.29-.25.74.74,0,0,1,.33.13.78.78,0,0,0-.33-.07.21.21,0,0,0-.17.21.83.83,0,0,0,.24.57h0a.14.14,0,0,1,0,.18.13.13,0,0,1-.18,0A0,0,0,0,1,41.29,107.67Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M40.05,106a1,1,0,0,1-.33-.7.29.29,0,0,1,.22-.3.72.72,0,0,1,.36,0,.65.65,0,0,0-.34,0c-.11,0-.13.13-.12.23a.89.89,0,0,0,.35.51h0a.12.12,0,0,1-.13.21Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.09,105.63a1,1,0,0,1-.44-.63.29.29,0,0,1,.18-.34.73.73,0,0,1,.36,0,.93.93,0,0,0-.34.06.21.21,0,0,0-.08.26.88.88,0,0,0,.43.44h0a.13.13,0,1,1-.1.23Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.2,104.83a1,1,0,0,1-.53-.55.27.27,0,0,1,.11-.36.87.87,0,0,1,.35-.07.88.88,0,0,0-.32.12.2.2,0,0,0,0,.26.84.84,0,0,0,.49.37h0a.13.13,0,0,1,.1.15.12.12,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.68,101.78a1,1,0,0,1-.63-.45.3.3,0,0,1,.05-.38,1,1,0,0,1,.34-.13.71.71,0,0,0-.29.18.19.19,0,0,0,0,.26.83.83,0,0,0,.55.27h0a.12.12,0,0,1,.12.13.13.13,0,0,1-.13.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.89,100.44a1,1,0,0,1-.66-.4.3.3,0,0,1,0-.38.76.76,0,0,1,.33-.15.77.77,0,0,0-.28.19.2.2,0,0,0,0,.27.81.81,0,0,0,.56.22h0a.12.12,0,0,1,.13.12.12.12,0,0,1-.12.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M34.76,99.42a1,1,0,0,1-.68-.38.3.3,0,0,1,0-.38.77.77,0,0,1,.32-.17.82.82,0,0,0-.27.21.2.2,0,0,0,0,.26.85.85,0,0,0,.58.21h0a.11.11,0,0,1,.13.11.14.14,0,0,1-.12.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36,99.51a1,1,0,0,1-.66-.41.3.3,0,0,1,0-.38.89.89,0,0,1,.33-.15.92.92,0,0,0-.28.19.2.2,0,0,0,0,.27.88.88,0,0,0,.57.23h0a.12.12,0,0,1,.12.12.12.12,0,0,1-.12.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.81,100.55a1,1,0,0,1-.62-.45.29.29,0,0,1,.05-.38.7.7,0,0,1,.34-.12.74.74,0,0,0-.3.17.2.2,0,0,0,0,.26.84.84,0,0,0,.55.28h0a.12.12,0,0,1,.12.13.13.13,0,0,1-.13.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M35.4,102.94a1,1,0,0,1-.61-.47.29.29,0,0,1,.06-.38.9.9,0,0,1,.34-.11.79.79,0,0,0-.3.16.19.19,0,0,0,0,.27.83.83,0,0,0,.53.29h0a.12.12,0,0,1,.12.13.14.14,0,0,1-.14.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.39,102.29a1,1,0,0,1-.56-.52.28.28,0,0,1,.09-.37.86.86,0,0,1,.35-.09,1,1,0,0,0-.31.14.19.19,0,0,0,0,.27.82.82,0,0,0,.51.33h0a.11.11,0,0,1,.1.14.12.12,0,0,1-.14.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M36.83,103.71a1,1,0,0,1-.54-.54.29.29,0,0,1,.1-.37.91.91,0,0,1,.35-.07.66.66,0,0,0-.31.13.19.19,0,0,0,0,.26.87.87,0,0,0,.5.35h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M37.77,103.82a1,1,0,0,1-.51-.58.29.29,0,0,1,.13-.36.85.85,0,0,1,.35,0,.72.72,0,0,0-.32.11.2.2,0,0,0-.05.26.89.89,0,0,0,.48.38h0a.13.13,0,0,1,.09.16.14.14,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M38.36,103a1,1,0,0,1-.52-.58.29.29,0,0,1,.13-.35.76.76,0,0,1,.36-.06.72.72,0,0,0-.33.11.19.19,0,0,0,0,.26.85.85,0,0,0,.48.38h0a.13.13,0,0,1,.09.16.13.13,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M33.86,101.63a1,1,0,0,1-.66-.39.29.29,0,0,1,0-.38.81.81,0,0,1,.32-.16,1,1,0,0,0-.28.2.2.2,0,0,0,0,.26.81.81,0,0,0,.57.22h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M32,100a1,1,0,0,1-.69-.34.29.29,0,0,1,0-.38.65.65,0,0,1,.31-.18.63.63,0,0,0-.26.22.2.2,0,0,0,.05.26.87.87,0,0,0,.59.17h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.36,98.12a1,1,0,0,1-.7-.33.29.29,0,0,1,0-.38.84.84,0,0,1,.31-.19.8.8,0,0,0-.26.22.2.2,0,0,0,.06.26.87.87,0,0,0,.59.17h0a.12.12,0,0,1,.14.11.12.12,0,0,1-.1.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.11,97.9a1,1,0,0,1-.7-.32.3.3,0,0,1,0-.38.84.84,0,0,1,.31-.19.83.83,0,0,0-.26.23.21.21,0,0,0,.07.26.85.85,0,0,0,.59.15h0a.12.12,0,0,1,.14.11.12.12,0,0,1-.1.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.14,97.66a.94.94,0,0,1-.7-.31.28.28,0,0,1,0-.37.75.75,0,0,1,.31-.2.7.7,0,0,0-.25.23.19.19,0,0,0,.06.26.89.89,0,0,0,.6.15h0a.13.13,0,0,1,.14.11.12.12,0,0,1-.1.14S28.15,97.67,28.14,97.66Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29,96a.94.94,0,0,1-.7-.32.29.29,0,0,1,0-.38.84.84,0,0,1,.31-.19.83.83,0,0,0-.26.23.19.19,0,0,0,.07.25.81.81,0,0,0,.59.16h0A.13.13,0,1,1,29,96Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,93.57a1,1,0,0,1-.7-.34.29.29,0,0,1,0-.38.7.7,0,0,1,.31-.18.78.78,0,0,0-.26.21.19.19,0,0,0,.06.26.79.79,0,0,0,.58.18h0a.13.13,0,1,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.57,91.27a1,1,0,0,1-.69-.35.29.29,0,0,1,0-.38.85.85,0,0,1,.32-.18.73.73,0,0,0-.27.22.19.19,0,0,0,.06.26.84.84,0,0,0,.58.18h0a.12.12,0,0,1,.14.11.14.14,0,0,1-.12.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.73,88.64a1,1,0,0,1-.68-.36.3.3,0,0,1,0-.38.71.71,0,0,1,.32-.17.71.71,0,0,0-.27.21.19.19,0,0,0,0,.26.85.85,0,0,0,.58.19h0a.11.11,0,0,1,.13.11.12.12,0,0,1-.11.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.54,86.71a1,1,0,0,1-.67-.38.29.29,0,0,1,0-.38.71.71,0,0,1,.32-.17.71.71,0,0,0-.27.21.18.18,0,0,0,0,.26.82.82,0,0,0,.57.21h0a.11.11,0,0,1,.13.11.12.12,0,0,1-.11.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25,84.72a1,1,0,0,1-.65-.41.29.29,0,0,1,0-.38,1.09,1.09,0,0,1,.33-.15.84.84,0,0,0-.28.2.19.19,0,0,0,0,.26.78.78,0,0,0,.56.23h0a.13.13,0,0,1,.13.13.12.12,0,0,1-.12.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.89,83.31a1,1,0,0,1-.64-.44.3.3,0,0,1,0-.38.83.83,0,0,1,.34-.13.71.71,0,0,0-.29.18.19.19,0,0,0,0,.26.86.86,0,0,0,.55.26h0a.13.13,0,0,1,.13.13.12.12,0,0,1-.13.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.39,81a1,1,0,0,1-.6-.49.29.29,0,0,1,.07-.37.76.76,0,0,1,.35-.11,1,1,0,0,0-.31.15.2.2,0,0,0,0,.27.84.84,0,0,0,.53.3h0a.12.12,0,0,1,.11.14.12.12,0,0,1-.13.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.18,77.33a1,1,0,0,1-.5-.58.29.29,0,0,1,.13-.36.64.64,0,0,1,.36,0,.74.74,0,0,0-.33.09.2.2,0,0,0-.05.26.91.91,0,0,0,.47.4h0a.12.12,0,0,1,.09.15.13.13,0,0,1-.16.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M22.61,74.8a1,1,0,0,1-.41-.65.29.29,0,0,1,.18-.33.72.72,0,0,1,.36,0,.72.72,0,0,0-.33,0,.19.19,0,0,0-.09.25.79.79,0,0,0,.41.45h0a.13.13,0,0,1,.06.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.34,74a1,1,0,0,1-.38-.67.3.3,0,0,1,.21-.32.73.73,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.84.84,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.36,74.22a1,1,0,0,1-.37-.67.28.28,0,0,1,.2-.32.72.72,0,0,1,.36,0,.64.64,0,0,0-.34,0,.18.18,0,0,0-.1.24.84.84,0,0,0,.38.48h0a.13.13,0,0,1,.05.17.13.13,0,0,1-.17.06Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.47,75.52a1,1,0,0,1-.44-.64.28.28,0,0,1,.18-.33.73.73,0,0,1,.36,0,.93.93,0,0,0-.34.06.21.21,0,0,0-.08.26.84.84,0,0,0,.43.44h0a.12.12,0,0,1,.07.16.11.11,0,0,1-.16.07Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.94,76a1,1,0,0,1-.44-.63.29.29,0,0,1,.17-.34A.88.88,0,0,1,25,75a.72.72,0,0,0-.33.07.19.19,0,0,0-.08.25.82.82,0,0,0,.43.43h0a.11.11,0,0,1,.07.16A.12.12,0,0,1,25,76Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.16,77.83a1,1,0,0,1-.51-.58.29.29,0,0,1,.13-.36.9.9,0,0,1,.36,0,.72.72,0,0,0-.33.11.19.19,0,0,0,0,.26.83.83,0,0,0,.47.38h0a.13.13,0,0,1,.09.16.12.12,0,0,1-.15.09Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26,79.78a1,1,0,0,1-.56-.53.29.29,0,0,1,.1-.37.85.85,0,0,1,.35-.08.77.77,0,0,0-.32.13.21.21,0,0,0,0,.27.82.82,0,0,0,.51.33h0a.12.12,0,1,1,0,.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,81.65a1,1,0,0,1-.6-.48.29.29,0,0,1,.07-.38.8.8,0,0,1,.34-.11.72.72,0,0,0-.3.16.2.2,0,0,0,0,.27.79.79,0,0,0,.53.29h0a.12.12,0,0,1,.11.14.11.11,0,0,1-.13.11Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.81,84.45a1,1,0,0,1-.65-.43.3.3,0,0,1,0-.38.87.87,0,0,1,.33-.14.73.73,0,0,0-.29.19.19.19,0,0,0,0,.26.81.81,0,0,0,.56.25h0a.12.12,0,0,1,.13.12.13.13,0,0,1-.13.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.64,86.13a1,1,0,0,1-.66-.4.29.29,0,0,1,0-.38.92.92,0,0,1,.33-.16.75.75,0,0,0-.28.2.19.19,0,0,0,0,.26.86.86,0,0,0,.57.23h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.58,87.57a1.07,1.07,0,0,1-.67-.39.3.3,0,0,1,0-.38.92.92,0,0,1,.33-.16.75.75,0,0,0-.28.2.2.2,0,0,0,0,.26.81.81,0,0,0,.57.22h0a.12.12,0,0,1,.14.11.14.14,0,0,1-.12.14Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30.15,85.92a1,1,0,0,1-.65-.41.29.29,0,0,1,0-.38.89.89,0,0,1,.33-.15,1,1,0,0,0-.29.19.2.2,0,0,0,0,.27.78.78,0,0,0,.57.23h0a.13.13,0,0,1,.12.13.12.12,0,0,1-.12.13Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M30,84.47a1,1,0,0,1-.64-.43.29.29,0,0,1,.05-.38.73.73,0,0,1,.33-.14.84.84,0,0,0-.29.18.2.2,0,0,0,0,.27.86.86,0,0,0,.55.26h0a.12.12,0,0,1,.12.13.12.12,0,0,1-.13.12Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M29.55,81.55A1,1,0,0,1,29,81a.29.29,0,0,1,.08-.37.88.88,0,0,1,.35-.1.81.81,0,0,0-.31.15.19.19,0,0,0,0,.26.81.81,0,0,0,.52.32h0a.13.13,0,0,1,0,.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M28.86,79a1,1,0,0,1-.52-.57.3.3,0,0,1,.13-.36.9.9,0,0,1,.36,0,.72.72,0,0,0-.33.11.19.19,0,0,0,0,.26.89.89,0,0,0,.48.38h0a.12.12,0,0,1-.06.24Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27.88,77.27a1,1,0,0,1-.47-.61.3.3,0,0,1,.16-.35.89.89,0,0,1,.36,0,.73.73,0,0,0-.33.08.2.2,0,0,0-.07.26.92.92,0,0,0,.45.42h0a.12.12,0,0,1,.07.16.12.12,0,0,1-.16.08Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M27,75.27a1,1,0,0,1-.38-.67.28.28,0,0,1,.2-.32.87.87,0,0,1,.36,0,.76.76,0,0,0-.34,0,.19.19,0,0,0-.1.24.84.84,0,0,0,.39.47h0a.13.13,0,0,1,.05.17.12.12,0,0,1-.17,0Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M26.4,73.53a1,1,0,0,1-.31-.7.28.28,0,0,1,.23-.3.72.72,0,0,1,.36.06.65.65,0,0,0-.34,0,.19.19,0,0,0-.13.23.85.85,0,0,0,.34.51h0a.13.13,0,1,1-.14.21S26.41,73.54,26.4,73.53Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M25.45,70.25a1,1,0,0,1-.22-.73.27.27,0,0,1,.26-.27.71.71,0,0,1,.35.1.67.67,0,0,0-.34,0c-.11,0-.15.11-.15.22a.83.83,0,0,0,.27.54h0a.13.13,0,0,1,0,.18.12.12,0,0,1-.17,0A0,0,0,0,1,25.45,70.25Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M24.74,67.1a1,1,0,0,1-.22-.73.29.29,0,0,1,.26-.28.85.85,0,0,1,.35.11.67.67,0,0,0-.34,0c-.11,0-.15.11-.15.22a.8.8,0,0,0,.28.54h0a.12.12,0,0,1,0,.17.12.12,0,0,1-.18,0S24.75,67.11,24.74,67.1Z"/>
        <path class="cls-1" fill={r_hair_color} stroke={r_hair_color} d="M23.66,65.42a1,1,0,0,1-.25-.72c0-.14.12-.29.26-.28a.7.7,0,0,1,.35.09.66.66,0,0,0-.34,0,.18.18,0,0,0-.14.22.87.87,0,0,0,.29.54h0a.14.14,0,0,1,0,.18.12.12,0,0,1-.18,0Z"/>
      </g>}
    </g>
  </g>


    )
}