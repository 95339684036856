import React from 'react'
import { useSelector } from 'react-redux'

export const PetitesLevres2aferme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetitesLevres2aferme" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
            <path fill={r_labia_color} className="st--5" d="M170.8,109.7c-0.6,7.9-2.9,31.1-3.5,31.1s-2.9-21.2-3.5-29.1c-0.2-3.4-2.2-8.3-2-10.9
						c0.3-3.4,0.1-11.2,0.7-13.4c1-3.8,3-17.7,4.7-17.7s3.2,12.9,4.2,16.7c0.4,1.6,0.8,9.6,1.1,11.9
						C172.9,101.4,171.1,105.1,170.8,109.7z"/>
            <path className="st2" d="M171.1,109.7c-0.5,6-1.2,12-1.8,18c-0.3,3-0.7,6-1.1,9l-0.3,2.2c-0.1,0.4-0.1,0.7-0.2,1.1
						c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.1-0.1,0.2v0.1l0,0c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2-0.1v0l0-0.1c0,0,0-0.1-0.1-0.2
						c-0.4-1.5-0.6-3-0.8-4.5c-0.9-6-1.6-12-2.2-18l-0.4-4.5l-0.2-2.2c-0.1-0.7-0.2-1.5-0.4-2.2c-0.3-1.5-0.7-2.9-1.1-4.4
						c-0.2-0.7-0.3-1.5-0.5-2.3c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.4,0-0.8,0-1.2c0.2-3,0.1-6,0.3-9c0-0.7,0-1.5,0.2-2.3
						c0-0.4,0.1-0.8,0.2-1.2s0.2-0.8,0.3-1.1c0.3-1.4,0.6-2.9,0.9-4.4c0.6-3,1.2-5.9,1.9-8.9c0.2-0.7,0.4-1.5,0.7-2.2
						c0.1-0.4,0.2-0.8,0.4-1.2c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0,0.6,0.1c0.4,0.3,0.6,0.7,0.8,1.1
						c0.2,0.4,0.3,0.7,0.4,1.1c0.2,0.7,0.4,1.5,0.6,2.2c0.7,3,1.2,5.9,1.7,8.9l0.4,2.2c0.1,0.4,0.2,0.7,0.2,1.1
						c0.1,0.4,0.2,0.8,0.2,1.1c0.2,1.5,0.3,3,0.4,4.5l0.3,4.5c0.1,0.8,0.1,1.5,0.2,2.2c0.1,0.8,0,1.6-0.1,2.3
						C172.3,103.9,171.3,106.7,171.1,109.7z M170.4,109.6c0.2-3.1,1.2-6,1.6-8.9c0.1-0.7,0.1-1.4,0.1-2.2c-0.1-0.7-0.2-1.5-0.2-2.3
						l-0.3-4.5c-0.1-1.5-0.2-3-0.4-4.5c0-0.4-0.1-0.7-0.2-1.1c-0.1-0.4-0.2-0.8-0.3-1.1l-0.4-2.2c-0.5-3-1-5.9-1.7-8.9
						c-0.2-0.7-0.3-1.5-0.6-2.1c-0.1-0.3-0.2-0.7-0.4-1c-0.1-0.3-0.3-0.5-0.5-0.7l0,0l0,0c-0.1,0.1-0.2,0.2-0.2,0.3
						c-0.2,0.3-0.3,0.7-0.4,1c-0.2,0.7-0.5,1.4-0.6,2.1c-0.7,2.9-1.2,5.9-1.8,8.8c-0.3,1.5-0.6,3-0.9,4.5c-0.3,1.4-0.5,2.9-0.5,4.4
						c-0.1,3-0.2,6-0.3,9c-0.1,1.5,0.1,2.9,0.5,4.3c0.3,1.5,0.7,2.9,1,4.4c0.2,0.8,0.3,1.5,0.4,2.3l0.2,2.3l0.4,4.5
						c0.5,6,1.1,12,1.9,18c0.2,1.5,0.4,3,0.7,4.4c0,0,0,0.1,0,0.1l0,0l0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l0,0l0,0c0,0,0-0.1,0-0.1
						c0.1-0.2,0.1-0.4,0.1-0.5c0.1-0.4,0.1-0.7,0.2-1.1l0.3-2.2c0.4-3,0.7-6,1-9C169.4,121.6,170,115.6,170.4,109.6L170.4,109.6z"/>
        </g>
    )
}