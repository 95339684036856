import React from 'react'
import { useSelector } from 'react-redux'

export const ContourVestibule = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="ContourVestibule" className="transition vestibule-target innerlabia-target">
        <path className="st2" d="M166.8,132.3c-0.1,0-0.1,0-0.2-0.1l0,0v-0.1v-0.1V132l-0.1-0.2l-0.1-0.3l-0.2-0.7l-0.3-1.4l-0.6-2.7
        c-0.4-1.8-0.7-3.6-1-5.5c-0.6-3.7-1.2-7.3-1.6-11c-0.2-1.8-0.4-3.7-0.5-5.6c-0.1-1.9-0.1-3.8,0.1-5.6c0.1-0.5,0.2-1,0.5-1.5
        c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.4-0.5c0.5-0.8,1-1.5,1.3-2.4c0.8-1.6,1.4-3.4,1.8-5.1l0.5-2.2l0.5,2.2l0,0
        c0.6,2.7,1.8,5.2,3.5,7.4c0.3,0.4,0.6,0.7,0.9,1.1c0.3,0.4,0.5,0.9,0.7,1.4c0.4,1.9,0.5,3.8,0.3,5.8c-0.1,1.9-0.4,3.8-0.7,5.6
        c-0.6,3.7-1.4,7.4-2.2,11.1c-0.4,1.8-0.9,3.7-1.4,5.5l-0.8,2.7l-0.4,1.4l-0.2,0.7l-0.1,0.3l-0.1,0.2v0.1v0.1v0l0,0
        C167.2,132.4,167,132.4,166.8,132.3C166.8,132.3,166.8,132.3,166.8,132.3z M166.8,131.7c-0.1,0-0.1,0-0.2,0.1l0,0l0,0l0,0
        v-0.1l0.1-0.2l0.1-0.3l0.2-0.7l0.4-1.3l0.7-2.7c0.5-1.8,0.9-3.6,1.3-5.5c0.8-3.7,1.6-7.4,2.1-11.1c0.3-1.9,0.5-3.7,0.6-5.6
        c0.2-1.8,0.1-3.7-0.3-5.5c-0.1-0.4-0.3-0.8-0.5-1.1c-0.3-0.3-0.6-0.7-0.9-1.1c-0.6-0.8-1.1-1.6-1.6-2.4
        c-1-1.7-1.7-3.5-2.2-5.4h1c-0.4,1.9-1.1,3.6-1.9,5.3c-0.4,0.9-0.9,1.7-1.5,2.5c-0.1,0.2-0.3,0.4-0.4,0.6
        c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.4-0.3,0.8-0.4,1.2c-0.3,1.8-0.3,3.6-0.2,5.5c0.1,1.8,0.3,3.7,0.5,5.5
        c0.4,3.7,0.9,7.4,1.5,11c0.3,1.8,0.6,3.7,1,5.5l0.5,2.7l0.3,1.4l0.2,0.7l0.1,0.3l0,0.2v0.1l0,0l0,0
        C167,131.7,166.9,131.7,166.8,131.7z"/>
    </g>
    )
}
