import React from 'react'

export const Fesses1 = (props) => {
    return (
        <g id="Fesses">
		<path className="st2" d="M333.5,152.8c-4.4,6.9-9.3,13.5-14.8,19.7c-5.5,6.2-11.5,11.9-18,16.9c-3.3,2.6-6.6,4.9-10.1,7.1
			c-3.5,2.2-7.1,4.3-10.8,6.1c-7.4,3.7-15.2,6.6-23.2,8.7c-16.1,4.2-33,5-49.4,2.3c-8.2-1.3-16.3-3.8-23.8-7.2
			c-1.9-0.9-3.7-1.9-5.5-2.9c-1.8-1.1-3.5-2.3-5.1-3.7c-1.7-1.3-3.1-2.9-4.4-4.6c-0.6-0.9-1.2-1.9-1.6-2.9c-0.4-1.1-0.6-2.2-0.6-3.3
			h1.5c0,1.1-0.2,2.2-0.6,3.3c-0.4,1-0.9,2-1.6,2.9c-1.3,1.7-2.7,3.3-4.4,4.6c-3.3,2.6-6.9,4.9-10.7,6.6c-7.6,3.5-15.6,5.9-23.8,7.2
			c-16.4,2.7-33.3,1.9-49.4-2.3c-8-2.1-15.8-5-23.3-8.7c-1.9-0.9-3.7-1.9-5.5-2.9s-3.6-2.1-5.3-3.2c-3.5-2.2-6.9-4.6-10.1-7.2
			c-1.6-1.2-3.2-2.6-4.8-3.9s-3.1-2.7-4.6-4.2c-3-2.8-5.9-5.8-8.6-8.9C9.3,166.3,4.4,159.7,0,152.8c4.7,6.7,9.9,13,15.5,19
			c5.6,5.9,11.7,11.4,18.3,16.3c13.1,9.7,28,16.8,43.8,20.7c19.8,4.9,40.5,4.7,60.2-0.6c3.9-1.1,7.7-2.4,11.4-4
			c3.7-1.6,7.2-3.6,10.4-6c1.6-1.2,3-2.6,4.2-4.1c0.6-0.8,1.1-1.6,1.5-2.4c0.4-0.8,0.6-1.8,0.7-2.7c0-0.4,0.4-0.7,0.8-0.7
			c0,0,0,0,0,0c0.4,0,0.7,0.3,0.7,0.7c0.1,0.9,0.3,1.8,0.7,2.7c0.4,0.9,0.9,1.7,1.5,2.4c1.2,1.5,2.7,2.9,4.2,4.1
			c3.2,2.4,6.7,4.4,10.4,6c7.5,3.2,15.3,5.4,23.3,6.6c8,1.3,16.1,1.8,24.2,1.4c8.1-0.3,16.2-1.4,24.1-3.4c7.9-1.9,15.6-4.7,22.9-8.2
			c1.8-0.9,3.6-1.8,5.5-2.7s3.6-2,5.3-3c3.5-2.1,6.9-4.4,10.2-6.9c6.5-4.9,12.7-10.3,18.3-16.2C323.6,165.8,328.8,159.5,333.5,152.8
			z"/>
	</g>
    )
}