import React from 'react'
import { useSelector } from 'react-redux'

export const PliD2 = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PliD2">
                            <path className="st2" d="M167.3,85.1c2,2.9,3.8,5.9,5.5,9c0.8,1.5,1.6,3.2,2.2,4.8c0.7,1.6,0.9,3.4,0.7,5.2
							c0.2-1.7-0.1-3.5-0.8-5.1c-0.6-1.6-1.4-3.2-2.3-4.7c-1.8-3-3.7-6-5.7-8.8c-0.1-0.1-0.1-0.3,0-0.4
							C166.9,84.8,167.1,84.9,167.3,85.1C167.2,85,167.2,85,167.3,85.1L167.3,85.1L167.3,85.1z"/>
                        </g>
    )
}