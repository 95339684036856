import React from 'react'
import { useWindowSize } from '../../services/hooks/useWindowSize'
import { first, second } from '../../services/translation/i18n'
import { ColorPicker } from '../colorPicker/colorPicker'

export const SkinColor = (props) => {

    const windowSize = useWindowSize()

    return (
        <div>
            <h2 className="text-black text-center text-bolder text-30">{first.t('ecran1-choixcouleurpeau')}</h2>
            {first.language !== second.language ? <h2 className="text-black text-center text-18 mt-03">{second.t('ecran1-choixcouleurpeau')}</h2> : ""}

            <div className={`flex mt-1 f-center ${windowSize.width < 350 ? 'f-column' : ''}`}>
                <div className={`text-center ${windowSize.width < 350 ? 'mb-1' : 'mr-1'}`}>
                    <p className="text-bold">{first.t('ecran1-votre-couleur')}</p>
                    {first.language !== second.language ? <p>{second.t('ecran1-votre-couleur')}</p> : ""}
                </div>
                <ColorPicker type={1} reduxAction={'SET_SKIN_COLOR'} />
            </div>
        </div>
    )
} 
