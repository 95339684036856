import React from 'react'
import {useSelector} from 'react-redux'

export const Hymen4 = (props) => {

    const r_allWhite = useSelector(state => state.parameters.allWhite)

    return (
        <path id="Hymen4" className="st8" fill={r_allWhite ? 'white' : '#e79ac3'} d="
        M166.9,99c-1,0-5.6,2.7-5.6,10.6s3.6,14.3,5.7,14.3s6.7-6.4,6.7-14.3S167.9,99,166.9,99z
								 M165.5,115.4c-0.5,0-0.1-1.3-0.3-1.7s-1.1-1.4-1.1-2s0.9-0.7,1-1.3s-0.7-0.6-0.6-1.5c0-0.6,0.5-1.1,1.2-1.1
								c0.5,0.1,0.9,0.5,1,1c0.1,0.9,0.1,1.8,0,2.7c0,0.9-0.1,1.9-0.2,2.8C166.2,115,166,115.4,165.5,115.4L165.5,115.4z
								 M168.1,115.4c-0.4,0,0.2-0.8-0.1-1.1s-0.3,0.2-0.6-0.1s0.1-1.5,0.1-2.7c-0.1-0.8-0.1-1.6-0.1-2.4c0.1-0.4,0.4-1.3,0.6-1.3
								s0.1,0.9,0.4,1.1s0.9-0.1,1,0.2s-0.9,1.2-1,2.3s0.5,2.3,0.4,2.9S168.5,115.4,168.1,115.4L168.1,115.4z"/>
    )
}
