import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresFond = (props) => {

	const r_labia_color = useSelector(state => state.parameters.labiaColor)

	return (
		<g id="PetiteLevres" className="transition" style={{opacity: props.show ? 1 : props.hide ? 0 : 1}}>
			<g id="PetitesLevresFond">
				<path fill={r_labia_color} className="st--5" d="M178.8,109.7c-1.2,7.2-10.4,31.3-11.7,31.3s-10.5-24.1-11.7-31.3c-0.5-2.7-0.5-5.4,0-8.1
							c0.6-3.1,2.8-12,3.8-14.2c1.7-3.8,5.1-17.7,7.9-17.7s5.5,12.9,7.2,16.7c0.7,1.6,3.3,10.8,3.9,12.9
							C179.2,102.7,179.4,106.2,178.8,109.7z"/>
				<path fill={r_labia_color} className="st2" d="M179.2,109.8c-0.7,3.1-1.6,6.2-2.7,9.2c-1,3-2.1,6-3.2,9s-2.3,5.9-3.6,8.8c-0.3,0.7-0.7,1.5-1,2.2
							c-0.3,0.7-0.7,1.5-1.2,2.1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.2-0.3-0.3-0.4-0.5
							c-0.2-0.3-0.4-0.7-0.6-1c-0.4-0.7-0.7-1.4-1-2.2c-2.6-5.8-4.8-11.8-6.9-17.8c-1.1-3-2-6.1-2.7-9.2c-0.6-3.2-0.6-6.5,0.1-9.6
							c0.7-3.1,1.4-6.2,2.2-9.3c0.4-1.6,0.9-3.1,1.5-4.6c0.6-1.4,1.1-3,1.6-4.5c1-3,2-6,3.3-9c0.3-0.7,0.7-1.5,1.1-2.2
							c0.2-0.4,0.5-0.7,0.7-1c0.3-0.4,0.7-0.7,1.1-0.8c0.5-0.1,1.1,0,1.5,0.4c0.3,0.3,0.6,0.6,0.8,1c0.4,0.7,0.8,1.4,1.1,2.2
							c1.2,3,2.1,6,3,9.1c0.4,1.5,0.9,3,1.5,4.5c0.6,1.5,1,3,1.5,4.6c0.9,3,1.8,6.1,2.6,9.2C179.6,103.3,179.7,106.6,179.2,109.8z
							 M178.5,109.7c0.5-3.1,0.4-6.3-0.4-9.3c-0.8-3-1.7-6.1-2.6-9.1c-0.5-1.5-0.9-3-1.5-4.5c-0.6-1.5-1.1-3-1.6-4.5
							c-1-3-1.8-6.1-3-9c-0.3-0.7-0.7-1.4-1.1-2c-0.4-0.6-1-1.2-1.4-1s-1,0.9-1.4,1.5c-0.4,0.7-0.7,1.4-1,2.1
							c-1.2,2.9-2.2,5.9-3.1,8.9c-0.5,1.5-1,3-1.6,4.5c-0.6,1.4-1.1,2.9-1.5,4.5c-0.8,3-1.6,6.1-2.3,9.2c-0.7,3-0.8,6.2-0.2,9.3
							c0.7,3.1,1.5,6.1,2.5,9.1c2,6,4.1,12,6.6,17.8c0.3,0.7,0.7,1.4,1,2.2c0.2,0.3,0.4,0.7,0.6,1s0.5,0.8,0.5,0.5
							c0.5-0.6,0.8-1.3,1.2-2c0.3-0.7,0.7-1.4,1-2.2c1.3-2.9,2.4-5.8,3.5-8.8s2.2-6,3.1-9C177.1,115.8,177.9,112.7,178.5,109.7
							L178.5,109.7z"/>
			</g>
			<PliG1/>
			<PliG2/>
			<PliD1/>
			<PliD2 />
		</g>
	)
}
