import React from 'react'
import { useSelector } from 'react-redux'

export const PrepuceFerme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PrépuceFerme" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                    <path fill={r_labia_color} className="st--5" d="M167.3,79.9c0,3.7-6.1,16-7.2,17.4c1.7-6.1,4.2-27.2,7-27.2s5.7,19.8,7.9,26.2
					C174.1,93.5,167.3,83.6,167.3,79.9z"/>
                    <path className="st2" d="M173.7,95.8c-0.4-1.4-1-2.7-1.6-4c-0.6-1.3-1.2-2.6-1.9-3.8s-1.3-2.5-1.9-3.8c-0.3-0.6-0.6-1.3-0.9-2
					c-0.3-0.7-0.5-1.5-0.6-2.2h1.1c0,0.8-0.2,1.7-0.4,2.5c-0.2,0.8-0.4,1.6-0.7,2.3c-0.5,1.5-1.1,3-1.7,4.5
					c-0.6,1.5-1.4,2.9-2.2,4.3c-0.8,1.4-1.7,2.7-2.8,3.9c0.9-1.3,1.6-2.7,2.3-4.2c0.7-1.4,1.3-2.9,1.9-4.4c0.5-1.5,1.1-3,1.5-4.5
					c0.5-1.5,0.8-3,1-4.5l0,0c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5c0.1,0.6,0.2,1.3,0.5,1.9c0.2,0.6,0.5,1.3,0.8,1.9
					l1.8,3.9c0.6,1.3,1.1,2.6,1.6,4C173.1,93,173.4,94.4,173.7,95.8z"/>
                </g>
    )
}