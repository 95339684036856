import React from 'react'
import { useSelector } from 'react-redux'

export const GrandLevreG2cFerme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="GrandLevreG2cFerme" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                    <path className="st2" d="M162.3,69.8c-1.8,2.6-3.1,5.5-3.6,8.6c-0.6,3.1-1,6.2-1.2,9.4c-0.3,6.3-0.2,12.6,0.2,18.9
					c0.4,6.3,1,12.6,1.9,18.8c0.4,3.1,0.9,6.2,1.4,9.3c0.5,3.1,1.1,6.2,2,9.2c-0.5-1.5-0.9-3-1.3-4.6c-0.4-1.5-0.7-3.1-1-4.6
					c-0.6-3.1-1.1-6.2-1.6-9.3c-0.9-6.3-1.6-12.5-2-18.8c-0.4-6.3-0.5-12.6-0.1-18.9c0.2-3.2,0.6-6.3,1.3-9.4
					C159,75.2,160.3,72.3,162.3,69.8z"/>
                </g>
    )
}