import React from 'react'
import { useSelector } from 'react-redux'

export const Incision = (props) => {

	return (
		<g id="incision" className="transition" style={{ opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(0px, -15px)' }}>
			<g>
				<defs>
					<path id="SVGID_12_" d="M160.6,147c0-10.2,3-18.5,6.6-18.5c3.7,0,6.6,8.3,6.6,18.5c0,2,14.5,3.4,22,22.3
									c17.5,44.2,35.7,129.9,21.7,153.6c-10.5,17.8-133.4,6.3-134.7,6.3C79.2,329.3,160.6,157.2,160.6,147z"/>
				</defs>
				<clipPath id="SVGID_13_">
					<use href="#SVGID_12_"  style={{"overflow":"visible"}}/>
							</clipPath>
				<g class="st22">
					<g>
						<path class="st5" d="M169.7,127.5c0.6,7.9-1.9,34.1-2.5,34.1s-3.2-24.3-2.5-32.1c0.3-3.5,0.8-8.3,1-10.9
										c0.3-3.4,0.7-13.4,0.7-13.4s-1-12.7,0.7-12.7s1.2,11.7,1.2,11.7s-0.2,9.6,0.1,11.9C168.8,119.2,169.3,123.2,169.7,127.5z"
						/>
						<path class="st2" d="M170,127.5c0.2,3.8-0.1,7.6-0.3,11.4c-0.2,3.8-0.5,7.6-0.9,11.3c-0.2,1.9-0.4,3.8-0.6,5.7
										c-0.2,1.9-0.4,3.8-0.8,5.7v0.1c0,0,0,0.1-0.2,0.2c-0.1,0-0.2,0-0.2-0.1v-0.1v-0.1c0-0.1-0.1-0.2-0.1-0.4
										c-0.1-0.2-0.1-0.5-0.2-0.7l-0.2-1.4c-0.1-0.9-0.3-1.9-0.4-2.8c-0.2-1.9-0.5-3.8-0.7-5.7c-0.4-3.8-0.7-7.5-1-11.3
										c-0.2-3.8-0.4-7.6,0-11.4c0.3-3.8,0.7-7.5,1-11.3l0.6-11.3v0.1c-0.1-2-0.2-4-0.3-6.1c0-1,0-2,0-3c0.1-1,0.1-2,0.4-3.1
										c0.1-0.3,0.2-0.6,0.7-0.9c0.3-0.1,0.6,0,0.8,0.1s0.3,0.3,0.3,0.4c0.3,0.6,0.4,1.1,0.5,1.6c0.2,1,0.2,2,0.3,3.1
										c0.1,2,0.1,4,0,6.1c-0.1,4-0.2,8.1,0,12C169.1,119.4,169.7,123.5,170,127.5z M169.3,127.5c-0.3-4-0.9-8-1.4-12
										c-0.2-4.1-0.1-8.1-0.1-12.1c0-2,0-4-0.1-6c-0.1-1-0.1-2-0.3-3c-0.1-0.5-0.2-1-0.4-1.3c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0.1,0
										c0.1-0.1-0.1,0.1-0.2,0.3c-0.3,0.9-0.3,1.9-0.3,2.9s0,2,0,3c0.1,2,0.2,4,0.3,6l0,0l0,0c-0.2,3.8-0.3,7.6-0.6,11.4
										s-0.7,7.6-1.1,11.3c-0.5,3.7-0.3,7.5-0.1,11.3c0.2,3.8,0.4,7.6,0.8,11.3c0.2,1.9,0.4,3.8,0.6,5.6c0.1,0.9,0.2,1.9,0.4,2.8
										l0.2,1.4c0,0.2,0.1,0.5,0.1,0.7c0,0.1,0.1,0.2,0.1,0.3v0.1l0,0c0,0-0.1-0.1-0.1,0c-0.2,0.1-0.1,0-0.1,0v-0.1
										c0.4-1.8,0.6-3.7,0.8-5.6c0.2-1.9,0.4-3.8,0.6-5.7C169.1,142.6,169.7,135,169.3,127.5z"/>
					</g>
				</g>
			</g>
			<g>
				<defs>
					<path id="SVGID_14_" d="M160.6,147c0-10.2,3-18.5,6.6-18.5c3.7,0,6.6,8.3,6.6,18.5c0,2,14.5,3.4,22,22.3
									c17.5,44.2,35.7,129.9,21.7,153.6c-10.5,17.8-133.4,6.3-134.7,6.3C79.2,329.3,160.6,157.2,160.6,147z"/>
				</defs>
				<clipPath id="SVGID_15_">
					<use href="#SVGID_14_"  style={{"overflow":"visible"}}/>
							</clipPath>
				<g class="st23">
					<g>
						<defs>
							<path id="SVGID_16_" d="M167.1,149.8c1.5,0,1.9-19.4,0.1-19.4S165.6,149.8,167.1,149.8z" />
						</defs>
						<clipPath id="SVGID_17_">
							<use href="#SVGID_16_"  style={{"overflow":"visible"}}/>
									</clipPath>
						<g id="Vestibule_56_" class="st24">

							<radialGradient id="Fond_2_" cx="167.7172" cy="6457.8633" r="17.1806" fx="167.7009" fy="6458.0049" gradientTransform="matrix(1 0 0 -1.1767 0 7723.2104)" gradientUnits="userSpaceOnUse">
								<stop offset="0" style={{"stopColor":"#A10D3C"}} />
								<stop offset="0.1331" style={{"stopColor":"#A41242"}} />
								<stop offset="0.3087" style={{"stopColor":"#AD2052"}} />
								<stop offset="0.508" style={{"stopColor":"#BC386E"}} />
								<stop offset="0.7232" style={{"stopColor":"#D05994"}} />
								<stop offset="0.8002" style={{"stopColor":"#D866A3"}} />
							</radialGradient>
							<path id="Fond_96_" class="st25" d="M176,124.2c-1.1-3.8-8.4-20.8-8.9-19.8c-0.6,1.2-7.6,17.6-8.8,20.6
											c-0.9,2.3,3.1,14.3,5.9,23.4c1.4,4.5,1.2,9.2,2.8,9.2s1.8-5.1,3.3-9.5C173.3,139,176.7,126.8,176,124.2z"/>
							<g>
								<path class="st5" d="M176,124.2c-1.1-3.8-8.4-20.8-8.9-19.8c-0.6,1.2-7.6,17.6-8.8,20.6c-0.9,2.3,3.1,14.3,5.9,23.4
												c1.4,4.5,1.2,9.2,2.9,9.2c1.6,0,1.8-5.1,3.3-9.5C173.3,139,176.7,126.8,176,124.2z M167.4,150.3
												c-3.4,0-6.1-19.4-4.8-26.8c3.4-1.2,6.6-1.2,9.9,0C173.9,129.9,170.8,150.3,167.4,150.3z"/>
								<g>
									<path class="st2" d="M176,124.2c-0.7-2.2-1.6-4.4-2.5-6.6s-1.8-4.3-2.8-6.5c-1-2.1-1.9-4.3-3.2-6.3
													c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0,0.1-0.1,0.2l-0.3,0.8l-0.7,1.6l-1.4,3.3l-5.5,13.1c-0.2,0.5-0.5,1.1-0.7,1.6
													l-0.1,0.4c0,0.1,0,0.3,0,0.4c0,0.3,0,0.6,0,0.9c0.1,1.2,0.4,2.3,0.6,3.4c0.5,2.3,1.2,4.6,1.9,6.8l2.1,6.8l1.1,3.4
													c0.4,1.1,0.7,2.3,0.9,3.4c0.2,1.2,0.4,2.3,0.6,3.5c0.1,0.6,0.2,1.2,0.4,1.7c0.1,0.5,0.3,1.2,0.7,1.4
													c0.4,0.3,0.9-0.1,1.1-0.6c0.3-0.5,0.4-1.1,0.6-1.6c0.3-1.1,0.5-2.3,0.8-3.4c0.5-2.3,1.3-4.6,2-6.8
													c0.7-2.3,1.4-4.5,2-6.8s1.3-4.5,1.8-6.8c0.3-1.1,0.5-2.3,0.7-3.5C176,126.6,176.2,125.4,176,124.2z M176,124.2
													c0.2,1.2,0,2.4-0.1,3.5c-0.2,1.2-0.4,2.3-0.7,3.5c-0.5,2.3-1.1,4.6-1.7,6.9c-0.6,2.3-1.3,4.5-2,6.8s-1.4,4.5-1.9,6.8
													c-0.2,1.2-0.5,2.3-0.8,3.5c-0.2,0.6-0.3,1.2-0.6,1.7c-0.2,0.3-0.3,0.6-0.6,0.7c-0.3,0.2-0.7,0.2-1,0
													c-0.6-0.4-0.7-1.1-0.9-1.6c-0.2-0.6-0.3-1.2-0.4-1.7c-0.2-1.2-0.4-2.3-0.7-3.5c-0.2-1.1-0.6-2.3-0.9-3.4l-1.1-3.4
													l-2.1-6.8c-0.7-2.3-1.3-4.5-1.9-6.8c-0.3-1.2-0.5-2.3-0.6-3.5c0-0.3-0.1-0.6,0-0.9c0-0.2,0-0.3,0-0.5l0.1-0.5
													c0.2-0.6,0.5-1.1,0.7-1.6l5.6-13l1.4-3.2l0.7-1.6l0.4-0.8l0.1-0.2c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2,0,0.2,0
													c0.2,0.1,0.2,0.2,0.3,0.4c1.2,2,2.2,4.2,3.1,6.4c1,2.2,1.9,4.3,2.8,6.5C174.4,119.8,175.3,122,176,124.2z"/>
									<path class="st2" d="M167.4,150.3c-0.6,0-1-0.5-1.4-1c-0.3-0.5-0.6-1-0.8-1.6c-0.4-1.1-0.8-2.2-1.1-3.3
													c-0.6-2.3-1-4.6-1.3-6.9c-0.3-2.3-0.6-4.6-0.7-7c-0.1-2.3-0.2-4.7,0.2-7l0,0v-0.1l0.1-0.1c1.6-0.6,3.3-0.9,5-0.9
													s3.4,0.3,5,0.9l0,0h0.1v0.1c0.4,2.4,0.3,4.7,0.2,7.1c-0.2,2.3-0.4,4.7-0.8,7c-0.4,2.3-0.8,4.6-1.4,6.9
													c-0.3,1.1-0.7,2.3-1.1,3.3c-0.2,0.5-0.5,1.1-0.8,1.6C168.4,149.8,168,150.3,167.4,150.3z M167.4,150.3
													c0.6,0,1-0.6,1.3-1c0.3-0.5,0.6-1,0.8-1.6c0.4-1.1,0.8-2.2,1.1-3.3c0.6-2.3,1-4.6,1.4-6.9c0.3-2.3,0.5-4.7,0.6-7
													s0.1-4.7-0.4-6.9l0.2,0.2l0,0c-1.5-0.6-3.2-0.9-4.8-0.9s-3.3,0.3-4.8,0.9l0.2-0.2c-0.4,2.3-0.5,4.6-0.4,6.9
													s0.2,4.7,0.5,7s0.7,4.6,1.2,6.9c0.3,1.1,0.6,2.3,1.1,3.3c0.2,0.5,0.5,1.1,0.8,1.6C166.3,149.8,166.8,150.3,167.4,150.3z
													"/>
								</g>
							</g>
							<path id="Fond_95_" class="st9" d="M176,126.4c-1.1-3.9-8.4-23-8.9-22c-0.6,1.3-7.6,18.8-8.8,21.8c-0.4,1.2,0.6-3.1,9-3.1
											S176.4,127.7,176,126.4z"/>
							<path id="Hymen_60_" class="st26" d="M167.1,117.9c-1,0-5.6,2-5.6,7.7c0,5.7,3.6,10.4,5.7,10.4c2,0,6.7-4.6,6.7-10.4
											C173.8,119.9,168.1,117.9,167.1,117.9z M168.9,131.1c-0.4,1-1.2,1.3-1.8,1.3s-1.4-0.2-1.8-1.3c-0.2-0.6,0.1-1.6,0-2.4
											c-0.1-0.7-0.7-1.5-0.7-2.3c0-0.7,0.6-1.2,0.7-1.6c0.1-0.7-0.1-1.4,0.1-1.8c0.5-0.7,1.3-0.4,1.6-0.4s1.2-0.3,1.7,0.4
											c0.3,0.4,0,1.1,0.2,1.8c0.1,0.5,0.7,1,0.7,1.7c0,0.8-0.6,1.6-0.7,2.3C168.8,129.6,169.1,130.5,168.9,131.1z"/>
							<g id="Anus_72_">
								<g>
									<path class="st2" d="M166.9,120.2c-0.1-0.9,0-1.8,0-2.6c0.1-0.9,0.2-1.7,0.2-2.6c0.3,0.9,0.2,1.8,0.2,2.7
													C167.3,118.5,167.2,119.4,166.9,120.2z"/>
								</g>
								<g>
									<path class="st2" d="M168.8,119.6c-0.3-0.3-0.6-0.6-0.9-0.8L167,118c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.6-0.6-0.7-1
													c0.3,0.3,0.6,0.5,0.9,0.8c0.3,0.3,0.6,0.5,0.9,0.8c0.3,0.3,0.6,0.6,0.9,0.9C168.4,118.9,168.7,119.2,168.8,119.6z"/>
								</g>
								<g>
									<path class="st2" d="M165.6,118.2c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.5-0.2,0.7-0.2
													s0.5-0.1,0.8-0.1c-0.2,0.1-0.4,0.2-0.7,0.4c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.2
													C166.1,118.3,165.8,118.3,165.6,118.2z"/>
								</g>
							</g>
							<g>
								<path class="st2" d="M167.2,114c0.4,0,0.8,0.3,1.2,0.5s0.7,0.5,1,0.8c0.6,0.6,1.1,1.4,1.1,2.3c-0.1,0.9-0.5,1.7-1.1,2.4
												c-0.6,0.7-1.2,1.3-2.2,1.5c-0.5,0.1-1,0-1.4-0.3s-0.7-0.6-0.9-1c-0.5-0.8-0.7-1.6-0.7-2.5s0.4-1.7,0.9-2.4
												C165.7,114.7,166.4,114,167.2,114z M167.2,114c-0.9,0.1-1.5,0.8-2,1.4c-0.5,0.7-0.8,1.5-0.8,2.3c0.1,0.8,0.4,1.6,0.8,2.3
												c0.4,0.7,1.1,1.2,1.9,1.1c0.7-0.2,1.4-0.7,1.9-1.3c0.5-0.6,1-1.4,1.1-2.1c0.1-0.8-0.4-1.6-0.9-2.2
												c-0.3-0.3-0.6-0.6-0.9-0.9C168,114.3,167.7,114,167.2,114z"/>
							</g>
							<g>
								<path class="st2" d="M164.8,138.4c0.8,0.2,1.7,0.4,2.6,0.4c0.4,0,0.9,0,1.3-0.1c0.4,0,0.8-0.1,1.3-0.3
												c-0.3,0.3-0.8,0.4-1.2,0.5s-0.9,0.1-1.3,0.1C166.5,139,165.6,138.8,164.8,138.4z"/>
							</g>
							<g>
								<path class="st2" d="M164.8,136.4c0.8,0.2,1.7,0.4,2.6,0.4c0.4,0,0.9,0,1.3-0.1c0.4,0,0.8-0.1,1.3-0.3
												c-0.3,0.3-0.8,0.4-1.2,0.5s-0.9,0.1-1.3,0.1C166.5,137,165.6,136.8,164.8,136.4z"/>
							</g>
							<g>
								<path class="st2" d="M164,131.3c1.1,0.4,2.2,0.6,3.4,0.6c0.6,0,1.1,0,1.7-0.1c0.6-0.1,1.1-0.2,1.6-0.5
												c-0.4,0.4-1,0.6-1.6,0.8c-0.6,0.1-1.2,0.2-1.8,0.2C166.2,132.2,165,131.9,164,131.3z"/>
							</g>
							<g>
								<path class="st2" d="M164,128.2c1.1,0.4,2.2,0.6,3.4,0.6c0.6,0,1.1,0,1.7-0.1c0.6-0.1,1.1-0.2,1.6-0.5
												c-0.4,0.4-1,0.6-1.6,0.8c-0.6,0.1-1.2,0.2-1.8,0.2C166.2,129.1,165,128.8,164,128.2z"/>
							</g>
							<g>
								<path class="st2" d="M164,125.1c1.1,0.4,2.2,0.6,3.4,0.6c0.6,0,1.1,0,1.7-0.1c0.6-0.1,1.1-0.2,1.6-0.5
												c-0.4,0.4-1,0.6-1.6,0.8c-0.6,0.1-1.2,0.2-1.8,0.2C166.2,126,165,125.7,164,125.1z"/>
							</g>
						</g>
					</g>
					<g>
						<defs>
							<path id="SVGID_18_" d="M160.6,147c0-10.2,3-18.5,6.6-18.5c3.7,0,6.6,8.3,6.6,18.5c0,2,14.5,3.4,22,22.3
											c17.5,44.2,35.7,129.9,21.7,153.6c-10.5,17.8-133.4,6.3-134.7,6.3C79.2,329.3,160.6,157.2,160.6,147z"/>
						</defs>
						<clipPath id="SVGID_19_">
							<use href="#SVGID_18_"  style={{"overflow":"visible"}}/>
									</clipPath>
						<path class="st27" d="M167.1,149.8c1.5,0,1.9-19.4,0.1-19.4S165.6,149.8,167.1,149.8z" />
					</g>
					<g>
						<defs>
							<path id="SVGID_20_" d="M160.6,147c0-10.2,3-18.5,6.6-18.5c3.7,0,6.6,8.3,6.6,18.5c0,2,14.5,3.4,22,22.3
											c17.5,44.2,35.7,129.9,21.7,153.6c-10.5,17.8-133.4,6.3-134.7,6.3C79.2,329.3,160.6,157.2,160.6,147z"/>
						</defs>
						<clipPath id="SVGID_21_">
							<use href="#SVGID_20_"  style={{"overflow":"visible"}}/>
									</clipPath>
						<g class="st28">
							<path class="st29" d="M165.2,190.7l-24.5,22.6c0,0-13.2,28.7-14.5,35.5c-1.3,6.7,5.9,28.9,12.8,33.4
											c6.9,4.4,20.4,11.9,29.1,3.5c8.8-8.4,17.5-17.4,17.5-17.4s6.6-2.1,7.7-4.4c1.1-2.4,6.2-9.7,4.6-14.4
											S165.2,190.7,165.2,190.7z"/>
							<g>
								<path class="st13" d="M172.2,264.1c-1.1-2.9,0-6.3,2.5-7.5l10.2-5.3l2.8-3.2l-1-3.6l0,0c-0.9-3.4,0.8-7.1,3.7-8.1
												c3-1,6.1,0.9,7,4.3l1.9,7.3l0.1,0.3c0.6,2.3,0,4.8-1.7,6.4l-6.4,6L191,261c-0.3,0.3-0.7,0.6-1.1,0.8l-11.4,5.3
												C176.1,268.1,173.3,266.8,172.2,264.1z"/>
								<path class="st2" d="M171.8,264.2c-0.6-1.7-0.6-3.6,0.2-5.2c0.4-0.8,0.9-1.6,1.6-2.1c0.3-0.3,0.7-0.5,1.1-0.7l1.1-0.6
												c3-1.5,6-3,9-4.5h-0.1l2.8-3.1v0.1c-0.3-1.4-0.7-2.8-1-4.2c-0.2-1.4-0.1-2.9,0.4-4.2s1.5-2.6,2.8-3.2
												c1.3-0.7,2.9-0.8,4.3-0.2c1.4,0.6,2.5,1.7,3.2,3c0.3,0.7,0.5,1.4,0.7,2.1l0.6,2.1l1.1,4.1c0.2,0.7,0.4,1.4,0.4,2.2
												s0,1.5-0.3,2.3c-0.2,0.7-0.5,1.5-1,2.1c-0.2,0.3-0.5,0.6-0.7,0.9l-0.8,0.7l-3.1,3l-3.1,3c-0.3,0.3-0.7,0.5-1,0.6l-1,0.5
												l-1.9,0.9l-7.8,3.5c-0.6,0.3-1.4,0.6-2.2,0.7s-1.6,0-2.3-0.3C173.4,266.9,172.3,265.6,171.8,264.2z M172.6,263.9
												c0.5,1.2,1.4,2.3,2.6,2.8c0.6,0.2,1.2,0.3,1.9,0.3c0.6-0.1,1.2-0.3,1.9-0.7l7.8-3.6l1.9-0.9l1-0.4
												c0.3-0.1,0.5-0.3,0.8-0.5l3.1-2.9l3.1-2.9l0.8-0.7c0.2-0.2,0.4-0.5,0.6-0.7c0.4-0.5,0.6-1.1,0.8-1.8
												c0.4-1.3,0.3-2.6-0.1-4l-1-4.2l-0.5-2.1c-0.2-0.7-0.3-1.4-0.6-2c-0.5-1.2-1.5-2.3-2.8-2.9c-1.2-0.6-2.7-0.6-4,0.1
												c-1.3,0.6-2.2,1.7-2.8,3s-0.7,2.8-0.4,4.2s0.7,2.8,1.1,4.1v0.1v0.1l-2.7,3.2l0,0l0,0c-2.9,1.6-5.9,3.2-8.9,4.8
												c-0.7,0.4-1.5,0.7-2.1,1.2s-1,1.2-1.4,1.9C172,260.7,172,262.4,172.6,263.9z"/>
							</g>
							<g>
								<path class="st13" d="M168.6,263.6c-2.5-2.7-2.6-7.2-0.3-10.1c1.4-1.8,2.9-3.6,4.4-5.3c1.3-1.5,2.6-3.1,3.9-4.6
												c1.7-2.5,3.4-5,5.2-7.5c0.3-0.5,0.7-1,1-1.5l-1.2-1.3c-2.1-2.1-2.7-5.7-1.3-8.7c1.6-3.4,5.3-4.7,8.2-2.8l8.1,5.2
												c0.4,0.2,0.7,0.5,1,0.8c2.7,2.7,3.1,7.4,0.8,10.5l-0.5,0.7c-1.8,2.5-3.7,5-5.5,7.5c-1.9,2.5-3.7,4.9-5.6,7.4l-0.3,0.4
												c-0.1,0.1-0.2,0.3-0.3,0.4c-1.6,1.6-3.2,3.1-4.9,4.7c-1.6,1.5-3.3,3.1-4.9,4.6C174.2,266.2,170.8,266,168.6,263.6z"/>
								<path class="st2" d="M168.3,263.9c-1.4-1.6-2.1-3.7-2-5.8c0-1,0.3-2.1,0.7-3c0.4-1,1.1-1.8,1.7-2.5l3.8-4.5l3.9-4.5l0,0
												c2.1-3,4.1-6,6.3-9v0.2c-0.6-0.7-1.3-1.3-1.8-2s-0.9-1.6-1.2-2.5c-0.5-1.8-0.4-3.7,0.3-5.4s2.1-3.2,3.8-3.8
												c0.9-0.3,1.8-0.4,2.8-0.2c0.9,0.1,1.8,0.6,2.5,1.1l4.6,2.9l2.3,1.4c0.7,0.4,1.5,1,2.2,1.7c2.6,2.8,3,7.3,0.9,10.5
												c-2.1,3-4.2,5.8-6.3,8.8l-3.2,4.3l-1.6,2.1l-0.8,1.1c-0.2,0.2-0.4,0.4-0.5,0.6l-0.5,0.5c-2.6,2.5-5.2,4.9-7.9,7.3
												c-0.7,0.6-1.3,1.2-2.1,1.8c-0.8,0.5-1.7,0.9-2.7,1C171.5,266.2,169.6,265.3,168.3,263.9z M168.9,263.3
												c1.1,1.2,2.8,1.9,4.5,1.7c0.8-0.1,1.6-0.4,2.3-0.9c0.6-0.5,1.3-1.2,1.9-1.8l3.9-3.7l3.9-3.7l0.5-0.5
												c0.2-0.2,0.2-0.3,0.4-0.4l0.8-1.1l1.6-2.1l3.3-4.3c2.1-2.9,4.3-5.7,6.4-8.6c1.9-2.8,1.7-6.9-0.6-9.5
												c-0.6-0.6-1.3-1.1-2-1.6l-2.3-1.5l-4.5-2.9c-0.7-0.5-1.5-0.9-2.3-1.1c-0.8-0.2-1.7-0.1-2.5,0.2c-1.6,0.6-2.9,1.9-3.6,3.5
												c-0.7,1.6-0.8,3.5-0.4,5.2c0.2,0.8,0.6,1.7,1.1,2.4s1.2,1.3,1.8,2l0.1,0.1l-0.1,0.1c-1.1,1.5-2.1,3-3.1,4.5l-3.1,4.5l0,0
												l0,0l-3.8,4.5l-3.7,4.6c-0.6,0.8-1.2,1.5-1.6,2.4c-0.4,0.9-0.6,1.8-0.6,2.8C167,260.1,167.6,262,168.9,263.3z"/>
							</g>
							<g>
								<path class="st13" d="M171.3,217.8l2.4,0.9l1.7,0.7c1-2.7,2.8-4.6,5.1-5.2c4.4-1.1,8.9,3.3,10.1,9.7c0.4,2.1,0.4,4.1,0,6
												c0.7-1.4,1.4-2.8,2.1-4.1c1.3-2.5,2.6-4.8,3.9-6.8c3.9-5.7-1.4-15.8-7.6-15.8c-0.4,0-5.9,0.1-10.6,0.2
												c-1.3,0-2.6,0.1-3.7,0.1c-2,0.1-3.3,0.2-3.3,0.4"/>
								<path class="st2" d="M171.3,217.8c1.4,0.5,2.8,0.9,4.2,1.5l-0.2,0.1c0.4-1.2,1-2.3,1.8-3.2s1.9-1.7,3.1-2.1
												c1.2-0.4,2.6-0.4,3.8,0.1c1.2,0.4,2.3,1.1,3.2,2c1.8,1.8,2.9,4.1,3.6,6.5c0.7,2.4,0.8,4.9,0.4,7.4l-0.8-0.3
												c1.3-2.4,2.5-4.9,3.8-7.3c0.7-1.2,1.4-2.4,2.2-3.6c0.7-1.1,1.1-2.3,1.2-3.6c0.2-2.6-0.7-5.2-2.1-7.4
												c-0.7-1.1-1.6-2-2.7-2.8c-1-0.7-2.3-1.2-3.5-1.2h0.1l-8.9,0.2c-1.5,0-2.9,0-4.4,0l-2.2-0.1h-1.1h-0.6
												c-0.2,0-0.4,0-0.5,0.1c0.3-0.3,0.7-0.3,1-0.4c0.4-0.1,0.7-0.2,1.1-0.2c0.7-0.1,1.5-0.2,2.2-0.3c1.5-0.1,2.9-0.2,4.4-0.2
												l8.8-0.2h0.1c1.5,0,3,0.6,4.2,1.4c1.2,0.8,2.2,1.9,3,3.1c1.6,2.4,2.5,5.3,2.3,8.2c-0.1,1.4-0.6,2.9-1.5,4.1
												c-0.8,1.1-1.5,2.3-2.2,3.4c-1.3,2.4-2.6,4.8-3.9,7.2l-1.4,2.5l0.5-2.8c0.4-2.3,0.3-4.8-0.3-7.1s-1.6-4.5-3.3-6.2
												c-0.8-0.8-1.8-1.5-2.9-1.9c-1.1-0.4-2.3-0.4-3.4-0.1c-2.3,0.7-3.9,2.7-4.8,4.9l-0.1,0.2l-0.1-0.1
												C174,219,172.6,218.4,171.3,217.8z"/>
							</g>
							<g>
								<path class="st13" d="M152.3,202.7c2.7-5.8,10.4-14,14.2-18.9c4.2-4.1,6.7-8.2,11-9.3c3-0.8,11.3-4.9,14.5-0.7
												c2.1,2.7,1.9,6.9-0.4,9.4c-6.8,6.1-11.2,14.1-17.1,20.6c-1.7,1.8-9.4-0.4-10.4-1.9"/>
								<path class="st2" d="M151.7,202.5c0.9-1.8,2-3.5,3.1-5.1s2.4-3.2,3.6-4.7c2.5-3.1,5.1-6.1,7.5-9.1l0,0l0,0
												c2.4-2.3,4.4-4.9,7.1-7.2c0.7-0.6,1.4-1.1,2.2-1.5c0.4-0.2,0.8-0.4,1.2-0.5l1.2-0.4c1.5-0.5,3.1-1.1,4.8-1.6
												c1.6-0.5,3.3-0.8,5.1-0.8c0.9,0,1.8,0.2,2.7,0.5c0.9,0.4,1.6,1,2.2,1.7c2.1,2.9,1.9,7.3-0.6,9.9l0,0l0,0
												c-2.3,2.1-4.4,4.4-6.4,6.9c-2,2.4-3.8,5-5.8,7.5c-1,1.3-1.9,2.5-3,3.7c-0.5,0.6-1,1.2-1.6,1.8l-0.4,0.4
												c-0.1,0.1-0.4,0.4-0.5,0.5c-0.4,0.2-0.9,0.3-1.3,0.4c-1.7,0.2-3.3-0.2-4.8-0.6c-0.8-0.2-1.5-0.5-2.2-0.9
												s-1.5-0.7-1.9-1.4c0.6,0.6,1.3,0.8,2.1,1.1c0.7,0.2,1.5,0.5,2.3,0.6c1.5,0.3,3.1,0.6,4.6,0.4c0.4-0.1,0.7-0.2,1-0.3
												c0.2-0.1,0.5-0.5,0.7-0.8c0.5-0.6,1-1.2,1.5-1.8c1-1.2,2-2.5,2.9-3.7c1.9-2.5,3.7-5.1,5.7-7.6s4.1-4.9,6.4-7l0,0
												c2.1-2.2,2.3-6.1,0.4-8.6c-0.9-1.2-2.4-1.8-4-1.8s-3.2,0.4-4.8,0.8c-1.6,0.5-3.1,1.1-4.7,1.6c-1.6,0.4-3,1.1-4.2,2.2
												c-2.5,2.1-4.5,4.8-6.9,7.2l0.1-0.1c-2.5,3.1-5.1,6.1-7.5,9.1c-1.2,1.5-2.4,3.1-3.6,4.7c-1.1,1.6-2.2,3.2-3,5L151.7,202.5
												z"/>
							</g>
							<path class="st30" d="M183.6,240c0.4,0.1,0.8,0.2,1,0.2c3.2,0.5,8.4-4.9,8.2-14.5c0-0.3,0-0.6,0-1
											c-0.6-9.7-8.8-14-12-17.8c-1.1-1.4-1.8-2.4-2.2-3.7c-0.9-2.4-1.3-6-3.6-14.7c-3.6-13.5-5.8-26.7-5.8-26.7l-3-0.8l1.5-36.7
											c-4.4,0.1-7.1,47.4-7.9,48.6c-0.5,0.7,6.4,2.2,7.7,2.8c1.2,0.5,3,7.7,5.5,15.4c1.3,3.9,1.8,8,1.9,12.3
											c0.1,5-0.4,10.2-1.2,15.3c-0.2,1.2-0.4,2.4-0.5,3.7c-0.3,1.8-0.4,3.4-0.3,4.9c0.5-0.5,1.1-1,1.6-1.5
											c-0.1-2.3,0.2-4.5,0.9-6.4c1-2.7,2.8-4.7,5.1-5.2c4.4-1.1,8.9,3.3,10.1,9.8c0.4,2.1,0.4,4.1,0,6c-0.6,3.3-2.2,6-4.4,7.2
											C185.5,238.2,184.6,239.2,183.6,240z"/>
							<g>
								<path class="st13" d="M134.4,251.5c9.3,4.8,12.2-8.5,14.4-18.3l0,0c0.6-2.9,1.2-5.5,1.9-7.2c0.2-1,0.3-2.1,0.4-3.2
												c-0.1,0.9-0.3,1.9-0.6,2.9c-1.8,6.3-6.8,10-11,8.3c-2.4-1-4.1-3.4-4.7-6.5c-0.4,1-0.8,2.1-1.3,3.2"/>
								<path class="st2" d="M134.4,251.5c1.1,0.4,2.2,0.7,3.3,0.7s2.2-0.3,3-0.9c1.8-1.2,2.9-3.2,3.8-5.3c0.9-2,1.5-4.2,2.1-6.4
												s1-4.4,1.5-6.7v0.3c0-0.1,0-0.1,0-0.3c0-0.1,0-0.1,0.1-0.2c0-0.1,0.2-0.3,0.5-0.4c0.1,0,0.2,0,0.3,0s0.1,0,0.2,0
												c0.1,0,0.1,0,0.2,0.1l-1.1,0.4c0.3-1.2,0.5-2.4,0.8-3.7c0.3-1.2,0.6-2.5,1-3.7v0.1c0.2-1,0.3-2,0.4-3.1l1.6,0.1
												c-0.3,2.3-1,4.6-2.2,6.6s-2.9,3.9-5.1,4.9c-1.1,0.5-2.4,0.8-3.6,0.6c-1.3-0.1-2.5-0.6-3.4-1.4c-2-1.5-2.9-3.9-3.2-6.2
												l0.7,0.1c-0.5,1-1,2.1-1.6,3.1c0.3-1.1,0.6-2.2,0.9-3.4l0.3-1.1l0.3,1.2c0.6,2.1,1.7,4,3.3,5.2c0.8,0.6,1.8,1,2.7,1.1
												c1,0.1,2-0.1,2.9-0.6c1.8-0.9,3.3-2.5,4.3-4.3s1.7-3.9,1.9-6l1.6,0.1c0,1.1-0.2,2.2-0.4,3.3v0.1v0.1
												c-0.8,2.2-1.3,4.7-1.9,7.1l-1.1,0.4c0.1,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.3-0.1,0.4-0.3,0.5-0.4
												c0.1-0.1,0.1-0.2,0.1-0.2c0-0.2,0-0.2,0-0.2v0.3c-0.5,2.2-1,4.5-1.6,6.7s-1.3,4.4-2.3,6.6c-1,2.1-2.3,4.2-4.4,5.5
												c-1.1,0.6-2.3,0.8-3.5,0.7C136.5,252.6,135.4,252.1,134.4,251.5z"/>
							</g>
							<g>
								<path class="st13" d="M168.1,248.9c4.5,2.5,11.6-6,15.1-8.6c0.1-0.1,0.2-0.2,0.4-0.3c1-0.8,1.9-1.8,2.7-2.9
												c1.6-2.1,3-4.6,4.4-7.2c0.3-1.9,0.3-3.9,0-6c-1.2-6.5-5.7-10.8-10.1-9.7c-2.3,0.6-4.1,2.5-5.1,5.2l1.7,0.7l-2.2,5.3
												c-0.2,0.1-0.3,0.3-0.5,0.4c-0.6,0.5-1.1,1-1.6,1.5c-3.3,3.3-6,7.4-8.1,11.9C163.3,242.5,165.2,247.2,168.1,248.9z"/>
								<path class="st2" d="M168.4,248.5c1,0.5,2.2,0.4,3.4,0c1.1-0.4,2.2-1.1,3.3-1.8c2.1-1.5,3.9-3.3,5.9-5
												c0.5-0.4,1-0.9,1.5-1.3c0.5-0.4,1-0.8,1.5-1.2c0.9-0.9,1.7-1.8,2.5-2.9c1.5-2.1,2.8-4.3,4-6.6l-0.1,0.2
												c0.4-2.3,0.3-4.7-0.3-7s-1.7-4.5-3.3-6.2c-0.8-0.8-1.8-1.5-2.9-2c-1.1-0.4-2.3-0.4-3.4-0.1c-1.1,0.3-2.1,1.1-2.8,2
												s-1.3,2-1.7,3.1l-0.3-0.6l1.7,0.7l0.5,0.2l-0.2,0.5l-2.2,5.3v0.1l-0.1,0.1c-0.4,0.4-0.9,0.8-1.3,1.2
												c-0.4,0.4-0.9,0.8-1.3,1.2c-0.8,0.8-1.6,1.7-2.3,2.6c-1.4,1.8-2.7,3.8-3.8,5.9c-0.5,1-1.2,2.1-1.5,3.1
												c-0.4,1-0.4,2.2-0.2,3.3C165.3,245.2,166.5,247.4,168.4,248.5z M167.9,249.4c-2.2-1.4-3.5-3.7-4-6.1
												c-0.2-1.2-0.2-2.5,0.2-3.8c0.5-1.2,1-2.2,1.6-3.3c1.1-2.1,2.4-4.1,3.9-6c0.7-0.9,1.5-1.9,2.4-2.7
												c0.4-0.4,0.9-0.8,1.3-1.2c0.4-0.4,0.9-0.8,1.4-1.2l-0.2,0.2l2.2-5.3l0.3,0.7l-1.7-0.7l-0.5-0.2l0.2-0.5
												c0.9-2.4,2.8-4.5,5.2-5.2c1.2-0.4,2.6-0.4,3.8,0s2.3,1.1,3.2,2c1.9,1.7,3,4.1,3.7,6.5c0.6,2.4,0.8,5,0.4,7.5v0.1v0.1
												c-1.2,2.3-2.5,4.6-4,6.7c-0.8,1.1-1.6,2.1-2.6,3c-0.5,0.5-1,0.9-1.5,1.3c-0.5,0.4-1,0.8-1.4,1.3c-1.9,1.7-3.8,3.5-6,5.1
												c-1.1,0.8-2.2,1.5-3.5,2C170.8,249.9,169.2,250.1,167.9,249.4z"/>
							</g>
							<path class="st30" d="M140.1,237c1.3,0.1,5.4-0.6,8.7-3.8l0,0c2.6-2.5,4.6-6.7,4.2-13.3c-0.6-10.7-0.9-21.9,3.2-31
											c3.3-7.2,4.6-13.6,5.9-14c1.3-0.4,8.9-1.6,8.1-2.1c-1.2-0.7,1.3-48.2-2.7-48.4l-2.5,35.5l-3.5,1.6c0,0-2.2,11.6-7.1,24.5
											c-4.9,12.9-4.1,14.2-7.6,17.5c-0.9,0.8-2,1.6-3.1,2.5c3.3,1.2,5.6,5.3,6.7,10c0.7,1.9,1,4.3,0.7,6.7
											c-0.1,1-0.3,1.9-0.6,2.9c-1.8,6.3-6.8,10-11,8.3c-2.4-1-4.1-3.5-4.7-6.6c-0.5-2.4-0.4-5.2,0.4-7.9c0,0,0,0,0-0.1
											c-0.3-1.2-0.5-2.5-0.6-3.7c-0.6,1.2-1.1,2.4-1.5,3.8c-1.1,4.2-0.8,8.1,0.4,11.1C135,234.4,137.8,236.9,140.1,237z"/>
							<line class="st31" x1="167.8" y1="131.1" x2="166.4" y2="131.1" />
							<line class="st31" x1="167.8" y1="132" x2="166.3" y2="132" />
							<line class="st31" x1="167.8" y1="132.9" x2="166.3" y2="132.8" />
							<line class="st31" x1="167.7" y1="133.6" x2="166.2" y2="133.6" />
							<line class="st31" x1="167.9" y1="130.3" x2="166.4" y2="130.3" />
							<line class="st31" x1="167.6" y1="135.3" x2="166.1" y2="135.3" />
							<line class="st31" x1="167.6" y1="136.2" x2="166.1" y2="136.1" />
							<line class="st31" x1="167.6" y1="137" x2="166.1" y2="137" />
							<line class="st31" x1="167.5" y1="137.8" x2="166" y2="137.8" />
							<line class="st31" x1="167.7" y1="134.5" x2="166.2" y2="134.4" />
							<line class="st31" x1="168" y1="129.5" x2="166.5" y2="129.5" />
							<line class="st31" x1="168" y1="128.7" x2="166.5" y2="128.6" />
							<g>
								<path class="st13" d="M139.5,233.9c4.3,1.7,9.2-2,11-8.3c0.3-1,0.5-1.9,0.6-2.9c0.1-2.2-0.2-4.4-0.7-6.7
												c-1.1-4.6-3.4-8.7-6.7-9.9c-1.9-0.7-4.1-0.4-6.6,1.4c-2.3,1.9-2.8,5-2.6,8.2c0.1,1.2,0.3,2.5,0.6,3.7
												c0.1,0.4,0.2,0.8,0.3,1.2c0.5,1.9,0.3,4-0.4,5.9c-0.1,0.3-0.2,0.5-0.3,0.8C135.4,230.4,137.1,232.9,139.5,233.9z"/>
								<path class="st2" d="M139.7,233.4c1.3,0.5,2.8,0.5,4.1-0.1c1.3-0.5,2.5-1.5,3.4-2.6c1-1.1,1.7-2.4,2.3-3.8
												c0.3-0.7,0.5-1.4,0.7-2.1c0.1-0.4,0.2-0.7,0.2-1.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.3c0-0.2,0.1-0.4,0.1-0.6l0,0
												c0.1-2.1-0.1-4.3-0.5-6.4c-0.2-1.1-0.5-2.1-0.9-3.1c-0.2-0.5-0.4-1-0.6-1.5c-0.1-0.2-0.2-0.5-0.4-0.7
												c-0.1-0.2-0.3-0.5-0.5-0.6c-0.2-0.2-0.4-0.3-0.6-0.6c-0.1-0.2-0.3-0.4-0.5-0.6c-0.3-0.4-0.7-0.8-1-1.1
												c-0.7-0.7-1.6-1.2-2.6-1.5c-1.9-0.5-4,0.2-5.7,1.5l0,0c-1.2,1-2,2.5-2.2,4.2c-0.3,1.6-0.2,3.3,0,5
												c0.1,0.8,0.3,1.7,0.5,2.5s0.5,1.7,0.5,2.6c0.2,1.8-0.3,3.7-1,5.3v-0.3c0.3,1.3,0.8,2.6,1.5,3.7
												C137.5,232,138.5,232.9,139.7,233.4z M139.3,234.3c-1.4-0.6-2.6-1.6-3.4-2.9c-0.8-1.2-1.3-2.6-1.7-4.1v-0.2l0.1-0.1
												c0.3-0.8,0.6-1.6,0.8-2.4c0.2-0.8,0.2-1.6,0.2-2.4c0-0.8-0.3-1.6-0.5-2.5c-0.2-0.8-0.4-1.7-0.5-2.6
												c-0.3-1.8-0.3-3.5,0-5.3s1.2-3.6,2.6-4.8l0,0l0,0c0.9-0.7,1.9-1.2,3.1-1.5c1.1-0.3,2.4-0.4,3.5-0.1
												c1.1,0.3,2.2,0.9,3,1.7c0.4,0.4,0.8,0.8,1.2,1.3c0.4,0.4,0.7,0.9,0.8,1.5s0.5,1,0.7,1.5s0.5,1,0.6,1.5
												c0.4,1,0.7,2.1,1,3.1c0.5,2.1,0.8,4.3,0.8,6.5l0,0l0,0l-0.1,0.6c0,0.1,0.1,0.2,0.1,0.3s0,0.2,0,0.3
												c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.8-0.4,1.5-0.7,2.2c-0.6,1.5-1.4,2.9-2.4,4.1c-1,1.2-2.3,2.3-3.9,2.9
												C142.6,234.9,140.9,234.9,139.3,234.3z"/>
							</g>

							<ellipse transform="matrix(0.9925 -0.1222 0.1222 0.9925 -18.9874 21.4278)" class="st30" cx="165.2" cy="165.5" rx="2.6" ry="2.1" />
							<g>
								<line class="st10" x1="166.3" y1="164.1" x2="164.3" y2="166.8" />
								<path class="st2" d="M166.7,164.4c-0.2,0.5-0.5,1-0.9,1.4s-0.7,0.8-1.2,1.2l-0.6-0.4c0.2-0.6,0.5-1,0.8-1.5
												c0.4-0.4,0.7-0.9,1.2-1.2L166.7,164.4z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<path class="st29" d="M255.4,201.1l35.3,8.9c0,0,25.8,19.9,29.9,25.4s5.7,29.2,0.2,36.5s-16.7,20.6-29.5,17.1
								c-12.7-3.4-25.7-7.5-25.7-7.5s-7.9,1.3-10-0.4c-2.1-1.7-10.6-5.9-10.8-10.9S255.4,201.1,255.4,201.1z"/>
				<g>
					<path class="st13" d="M278.2,271.4c0-3.2-2.6-5.7-5.7-5.7h-13l-4.2-1.6l-0.5-3.7l0,0c-0.5-3.6-3.7-6.1-7.3-5.6
									s-6.1,3.7-5.6,7.3l1,7.6v0.3c0.3,2.3,2,4.4,4.4,5l9.2,2.5l0.5,0.1c0.5,0.1,1,0.2,1.5,0.2l14.2-0.7
									C275.8,276.9,278.2,274.4,278.2,271.4z"/>
					<path class="st2" d="M277.8,271.4c0-1.7-0.8-3.4-2.2-4.4c-0.7-0.5-1.5-0.8-2.4-0.9c-0.8-0.1-1.8,0-2.7-0.1
									c-3.7,0-7.3-0.1-11-0.1l0,0l0,0l0,0l-4.2-1.6l0,0h-0.1v-0.1c-0.2-1.5-0.3-3.1-0.6-4.6c-0.4-1.5-1.3-2.8-2.6-3.6
									c-1.3-0.8-2.8-1.2-4.3-1s-2.9,0.9-3.9,2c-1,1.1-1.6,2.6-1.6,4c0,0.4,0,0.7,0.1,1.1l0.2,1.1l0.4,2.3l0.7,4.6
									c0.2,1.4,1,2.7,2.1,3.5c0.6,0.4,1.2,0.7,1.9,0.9l2.2,0.6l4.5,1.2l2.2,0.6c0.8,0.2,1.4,0.4,2.1,0.4l4.6-0.2l9.2-0.4
									c1.5,0,2.9-0.5,3.9-1.5C277.1,274.2,277.8,272.8,277.8,271.4z M278.6,271.4c0,1.7-0.7,3.3-1.9,4.5c-0.6,0.6-1.3,1-2.1,1.3
									c-0.8,0.3-1.7,0.4-2.4,0.4l-9.2,0.5l-4.6,0.2c-0.4,0-0.9,0-1.3-0.1s-0.8-0.2-1.1-0.3l-2.2-0.6l-4.5-1.2l-2.2-0.6
									c-0.8-0.2-1.6-0.6-2.2-1.1c-1.3-1-2.2-2.6-2.4-4.2l-0.6-4.6l-0.3-2.3l-0.1-1.1c0-0.4-0.1-0.8,0-1.2c0.1-1.6,0.8-3.2,1.9-4.3
									c1.1-1.1,2.6-1.8,4.2-2c1.6-0.2,3.2,0.3,4.4,1.2c1.3,0.9,2.2,2.2,2.6,3.7c0.2,0.8,0.3,1.5,0.4,2.3l0.3,2.3l-0.1-0.1l0,0
									l4.2,1.5h-0.1l0,0c3.7-0.1,7.3-0.1,11-0.2c0.9,0,1.8-0.1,2.8,0s1.9,0.5,2.7,1C277.6,267.5,278.6,269.4,278.6,271.4z"/>
				</g>
				<g>
					<path class="st13" d="M281.8,269.3c1.5-3.6-0.2-7.8-3.8-9.3c-2.3-0.9-4.5-1.9-6.8-2.7c-2-0.8-4-1.5-6.1-2.3
									c-2.9-1.5-5.7-2.9-8.6-4.4c-0.6-0.3-1.1-0.6-1.7-0.8l0.8-1.8c1.3-2.9,0.5-6.5-2.2-8.5c-3.1-2.3-7.5-1.7-9.9,1.4l-6.5,8.6
									c-0.3,0.4-0.6,0.8-0.8,1.3c-1.8,3.7-0.2,8.2,3.5,10l0.8,0.4c3,1.4,5.9,2.8,8.9,4.2c3,1.4,6,2.7,9,4.1l0.5,0.2
									c0.2,0.1,0.3,0.1,0.5,0.2c2.4,0.6,4.7,1.3,7.1,1.9s4.7,1.2,7.1,1.8C277,274.2,280.5,272.5,281.8,269.3z"/>
					<path class="st2" d="M281.4,269.1c0.8-1.8,0.7-3.9-0.2-5.7c-0.5-0.9-1.1-1.7-1.9-2.3c-0.8-0.6-1.7-1-2.7-1.4l-5.8-2.4
									L265,255l0,0l0,0l-5.1-2.7c-1.7-0.9-3.4-1.8-5.1-2.6l0,0l-0.1-0.1v-0.1c0.4-0.9,0.8-1.8,1.1-2.7c0.3-0.9,0.3-1.9,0.2-2.8
									c-0.3-1.9-1.3-3.6-2.9-4.6c-1.5-1.1-3.5-1.4-5.3-1s-3.4,1.5-4.4,3.1l-3.5,4.7l-1.7,2.3c-0.3,0.4-0.6,0.8-0.9,1.2
									c-0.2,0.4-0.5,0.8-0.7,1.2c-0.8,1.7-0.9,3.6-0.2,5.3c0.6,1.7,1.9,3.2,3.5,3.9l10.5,4.9l5.3,2.4l2.6,1.2
									c0.4,0.2,0.9,0.4,1.2,0.5l1.4,0.4l5.6,1.5l5.6,1.5l1.4,0.4c0.5,0.1,0.9,0.2,1.3,0.2c0.9,0,1.8-0.1,2.7-0.4
									C279.3,272.1,280.7,270.8,281.4,269.1z M282.2,269.4c-0.8,1.9-2.3,3.5-4.3,4.2c-1,0.4-2,0.5-3,0.5c-0.5,0-1-0.1-1.5-0.2
									l-1.4-0.3l-5.7-1.4l-5.6-1.5l-1.4-0.4c-0.6-0.2-1-0.4-1.4-0.6l-2.6-1.2l-5.3-2.4l-10.5-5c-1.9-0.9-3.3-2.6-4-4.5
									c-0.7-1.9-0.6-4.1,0.3-6c0.2-0.4,0.5-0.9,0.8-1.3c0.3-0.4,0.6-0.8,0.9-1.2l1.8-2.3l3.5-4.6c0.5-0.8,1.2-1.6,2.1-2.2
									c0.8-0.6,1.8-1,2.8-1.2c2-0.4,4.1,0.1,5.7,1.3c1.6,1.2,2.7,3,3,5c0.1,1,0,2-0.2,2.9c-0.3,1-0.8,1.8-1.1,2.7l-0.1-0.2l0,0
									c1.7,0.8,3.5,1.7,5.2,2.6l5.2,2.6l0,0l5.9,2.2l5.8,2.3c1,0.4,2,0.7,2.9,1.4c0.9,0.7,1.6,1.5,2.1,2.5
									C282.9,265,283.1,267.4,282.2,269.4z"/>
				</g>
				<g>
					<path class="st13" d="M260.2,228.8l-2.2,2l-1.5,1.4c-2.2-1.9-4.9-2.9-7.6-2.3c-5.1,1.1-8.1,7.2-6.7,13.7
									c0.4,2.1,1.3,3.9,2.4,5.5c-1.3-0.9-2.7-1.8-4-2.7c-2.4-1.6-4.7-3.2-7-4.3c-6.5-3.3-5-15.1,1.6-18c0.5-0.2,6.2-2.8,11.3-4.9
									c1.4-0.6,2.8-1.2,4-1.7c2.1-0.9,3.6-1.4,3.7-1.2"/>
					<path class="st2" d="M260.2,228.8c-1.2,1.2-2.4,2.4-3.6,3.6l-0.1,0.1l-0.1-0.1c-1.9-1.7-4.5-2.7-7-2.2
									c-2.5,0.4-4.6,2.2-5.8,4.4s-1.6,4.8-1.3,7.3s1.2,5,2.7,7l1.5,2l-2.1-1.4c-2.4-1.6-4.8-3.3-7.2-4.8c-1.2-0.8-2.4-1.5-3.7-2.1
									c-1.4-0.7-2.5-1.9-3.3-3.2s-1.1-2.9-1.2-4.4c-0.1-1.5,0.1-3,0.5-4.5c0.8-2.9,2.7-5.6,5.6-7l0,0l9.4-4.1
									c1.6-0.7,3.2-1.3,4.8-1.9c0.8-0.3,1.6-0.6,2.5-0.8c0.4-0.1,0.8-0.2,1.3-0.3c0.4-0.1,0.9-0.2,1.3-0.1c-0.2,0-0.4,0.1-0.6,0.2
									l-0.6,0.3l-1.1,0.6l-2.3,1.1c-1.5,0.7-3.1,1.4-4.7,2.1l-9.5,4.1l0,0c-2.4,1.1-4.1,3.6-4.9,6.2c-0.4,1.3-0.6,2.7-0.5,4.1
									c0.1,1.4,0.4,2.7,1,3.9s1.6,2.2,2.8,2.8c1.3,0.7,2.6,1.4,3.8,2.2c2.4,1.6,4.8,3.3,7.1,4.9l-0.6,0.6
									c-1.6-2.2-2.5-4.8-2.7-7.4c-0.3-2.6,0.2-5.4,1.5-7.7c0.6-1.2,1.5-2.2,2.6-3s2.3-1.4,3.7-1.6c1.3-0.2,2.7,0,3.9,0.4
									c1.3,0.5,2.4,1.2,3.4,2.1h-0.2C257.6,231,258.9,229.9,260.2,228.8z"/>
				</g>
				<g>
					<path class="st13" d="M274.1,205.9c-5.3-4-16.8-7.8-22.8-10.4c-6.1-1.7-12.6-2.6-18.9-2c-4.4,0.4-10.5-0.3-12.1,5.1
									c-1.1,3.5,0.8,7.2,4.3,8.3c9.7,2.3,17.7,7.5,26.6,10.6c2.5,0.9,9.8-4.9,10.3-6.7"/>
					<path class="st2" d="M273.7,206.4c-1.6-1.2-3.5-2.2-5.3-3.1c-1.9-0.9-3.8-1.7-5.7-2.5c-3.9-1.6-7.8-3-11.7-4.7h0.1
									c-3.5-1-7-1.7-10.6-2c-1.8-0.2-3.6-0.2-5.4-0.2c-0.9,0-1.8,0.1-2.7,0.1c-0.9,0.1-1.8,0.1-2.8,0.1c-1.8,0.1-3.6,0.2-5.2,0.7
									c-1.6,0.5-2.9,1.7-3.5,3.3c-0.7,1.6-0.6,3.4,0.1,4.9c0.7,1.5,2.1,2.7,3.7,3.2l0,0c3.4,0.8,6.7,2,9.9,3.4s6.3,2.9,9.4,4.3
									c1.6,0.7,3.1,1.4,4.7,2c0.8,0.3,1.6,0.6,2.4,0.9c0.7,0.3,1.5,0.1,2.2-0.2c1.6-0.6,3-1.5,4.4-2.5c0.7-0.5,1.4-1,2-1.6
									s1.3-1.1,1.7-1.9c-0.3,0.8-0.9,1.5-1.5,2.1s-1.3,1.2-1.9,1.8c-1.3,1.1-2.8,2.1-4.5,2.8c-0.4,0.2-0.9,0.3-1.3,0.4
									c-0.2,0-0.5,0.1-0.7,0c-0.3,0-0.6-0.1-0.7-0.2c-0.8-0.3-1.6-0.6-2.4-0.9c-1.6-0.6-3.2-1.3-4.8-2c-3.2-1.4-6.3-2.9-9.4-4.2
									c-3.2-1.3-6.4-2.5-9.7-3.3l0,0l0,0c-1.9-0.6-3.5-2-4.3-3.8c-0.9-1.8-0.9-3.9-0.2-5.7c0.6-1.9,2.3-3.4,4.1-4s3.7-0.7,5.5-0.8
									c0.9,0,1.8-0.1,2.7-0.2c0.9-0.1,1.8-0.1,2.8-0.2c1.8-0.1,3.7,0,5.5,0.2c3.7,0.3,7.3,1,10.9,2l0,0l0,0
									c3.8,1.6,7.8,3.1,11.6,4.7c1.9,0.8,3.9,1.7,5.8,2.6c1.9,0.9,3.8,1.9,5.5,3.2L273.7,206.4z"/>
				</g>
				<g>
					<path class="st13" d="M313.1,241.8" />
				</g>
				<g>
					<path class="st13" d="M313.1,241.8c-7.9,8.9-16.5-1.8-22.8-9.8l0,0c-1.9-2.3-3.6-4.4-5-5.7c-0.6-0.8-1.2-1.7-1.7-2.7
									c0.5,0.8,1.1,1.6,1.8,2.3c4.5,4.8,11.3,5.8,15.1,2.3c2.2-2,2.9-5.1,2.3-8.2c0.8,0.7,1.7,1.5,2.7,2.3"/>
					<path class="st2" d="M313.1,241.8c-0.7,1-1.6,1.9-2.6,2.6c-1,0.7-2.3,1.1-3.6,1.2c-1.3,0.1-2.6-0.2-3.8-0.6
									c-1.2-0.5-2.3-1.1-3.3-1.9c-2-1.5-3.8-3.2-5.4-5c-1.7-1.8-3.2-3.7-4.7-5.6l-0.1-0.3c0,0.1,0,0.1,0.1,0.3l0.1,0.1
									c0,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.3-0.1,0.3-0.2,0.4-0.3h-1.2c-0.8-1-1.6-2-2.4-2.9s-1.6-1.9-2.5-2.7l0,0
									v-0.1c-0.7-0.9-1.3-1.9-1.8-2.9l1.4-0.8c0.6,1,1.3,1.9,2.1,2.6c0.8,0.8,1.7,1.5,2.7,2.1c1.9,1.2,4.2,1.8,6.4,1.7
									c1.1-0.1,2.2-0.4,3.1-0.9c1-0.5,1.8-1.3,2.4-2.2c1.3-1.8,1.7-4.2,1.4-6.5l-0.1-1l0.7,0.7c0.4,0.4,0.8,0.9,1.2,1.3
									c0.4,0.4,0.8,0.9,1.2,1.3c-0.5-0.3-1-0.7-1.5-1s-1-0.7-1.4-1l0.6-0.3c0.6,2.4,0.4,5.1-1,7.3c-0.7,1.1-1.7,2-2.8,2.7
									c-1.1,0.6-2.4,1-3.7,1.1c-2.6,0.2-5.2-0.5-7.3-1.8c-1.1-0.6-2.1-1.4-3-2.3c-0.9-0.9-1.7-1.9-2.4-2.9l1.4-0.8
									c0.5,0.9,1,1.8,1.6,2.6l-0.1-0.1c1,0.9,1.8,1.9,2.6,2.9c0.8,1,1.6,2,2.4,3h-1.2c0.1-0.1,0-0.1,0.4-0.3
									c0.2-0.1,0.4-0.1,0.6,0.1c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c0.1,0.2,0.1,0.2,0.1,0.3l-0.1-0.3c1.5,1.9,3,3.8,4.6,5.6
									s3.3,3.5,5.1,4.9c1.9,1.4,4,2.6,6.3,2.6C309.1,244.8,311.3,243.5,313.1,241.8z"/>
				</g>
				<g>
					<path class="st32" d="M252.1,253.6c-2.9-0.1-5.6-1.7-7.6-4.4c-1.1-1.5-2-3.4-2.4-5.5c-1.4-6.5,1.6-12.6,6.7-13.7
									c2.7-0.6,5.4,0.4,7.6,2.3c1.5,1.3,2.8,3.2,3.6,5.3c0.8,0.2,1.6,0.4,2.3,0.6c-0.5-1.4-1.2-2.8-2.3-4.3c-0.7-1-1.4-2-2.1-3
									c-2.9-4.2-5.5-8.7-7.5-13.3c-1.6-3.9-2.8-7.9-3-12c-0.5-8.1-2.5-13.9-1.3-15.4c1.2-1.6,5-4.6,5.2-6
									c0.1-1.4-24.3-51.4-25.8-51.4l11.4,38.3l-1,0.6c0,0,6.6,18.8,8.3,32.7c1.1,8.9,2.1,12.4,2.2,15c0.1,1.5-0.2,2.7-0.8,4.5
									c-1.8,5-8.8,12.8-5.4,21.8c0.1,0.3,0.2,0.6,0.4,0.9c3.7,8.8,11.4,11.2,14.6,9.2c0.3-0.2,0.6-0.4,1-0.7
									C254.8,254.7,253.5,254.2,252.1,253.6z"/>
					<g>
						<path class="st2" d="M252,254c-0.4,0-0.9-0.1-1.4-0.2c-0.5-0.1-1-0.3-1.5-0.4c-1-0.4-1.8-0.9-2.6-1.5
										c-1.6-1.3-2.8-2.9-3.7-4.8c-1.7-3.6-2-8-0.5-11.8c0.8-1.9,2-3.6,3.7-4.8c1.7-1.1,3.9-1.6,6-1.2c2,0.4,3.9,1.5,5.3,2.9
										s2.5,3.2,3.2,5.1l-0.4-0.3c0.8,0.2,1.6,0.4,2.4,0.6l-0.6,0.7c-0.4-1.1-1-2.2-1.6-3.2s-1.4-2-2-3c-1.4-2-2.7-4.1-4-6.2
										c-2.5-4.2-4.7-8.7-6.1-13.5c-0.7-2.4-1.1-4.9-1.2-7.3c-0.2-2.4-0.5-4.8-0.8-7.2l-0.6-3.6c-0.1-0.6-0.2-1.2-0.2-1.9
										c0-0.6-0.1-1.3,0.2-2c0.1-0.2,0.1-0.4,0.3-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.2,0.4-0.5,0.6-0.7l1.3-1.3
										c0.9-0.9,1.8-1.7,2.4-2.6c0.2-0.2,0.3-0.5,0.4-0.7v-0.1c0-0.1,0,0.1,0,0V184c0-0.1-0.1-0.3-0.1-0.4
										c-0.2-0.6-0.4-1.1-0.7-1.7l-1.5-3.3l-3.1-6.6c-2.1-4.4-4.3-8.8-6.4-13.2l-6.5-13.1c-1.1-2.2-2.2-4.4-3.4-6.5
										c-0.6-1.1-1.2-2.2-1.7-3.2c-0.3-0.5-0.6-1.1-0.9-1.6c-0.2-0.3-0.3-0.5-0.5-0.8c-0.1-0.1-0.2-0.2-0.3-0.4
										c0-0.1-0.1-0.1-0.1-0.2c0,0-0.2-0.1,0,0l0.3-0.4c0.9,3.2,1.8,6.4,2.8,9.6c1,3.2,2,6.3,3,9.5c2,6.3,3.8,12.7,5.7,19.1
										l0.1,0.4l-0.3,0.2l-1,0.6l0.2-0.6c2.6,7.8,5,15.6,6.7,23.7c0.4,2,0.8,4,1.2,6.1c0.3,2,0.6,4.1,0.8,6.1c0.3,2,0.6,4,0.9,6
										c0.3,2,0.8,4,0.9,6.1c0,1.1-0.1,2.2-0.4,3.2c-0.3,1-0.7,2-1.1,2.9c-0.9,1.9-2,3.6-2.9,5.4c-1.8,3.6-3.2,7.5-2.6,11.5
										c0.2,2,1,3.8,1.9,5.6s2.1,3.4,3.6,4.7s3.2,2.4,5.1,2.9c0.9,0.2,1.9,0.3,2.8,0.2c0.5-0.1,0.9-0.2,1.3-0.4
										c0.4-0.2,0.8-0.5,1.2-0.8l0.2,0.9c-0.7-0.2-1.4-0.4-2.1-0.6c-0.3-0.1-0.7-0.3-1-0.4l-0.5-0.2l-0.2-0.1
										C252.1,254.1,252.1,254.1,252,254z M252.2,253.1l1.1,0.4c0.3,0.1,0.7,0.3,1,0.4c0.7,0.2,1.3,0.4,2,0.6l1.2,0.2l-1,0.7
										c-0.5,0.3-0.8,0.6-1.3,0.9s-1.1,0.4-1.6,0.5c-1.1,0.2-2.2,0-3.2-0.2c-2.1-0.5-4-1.7-5.5-3.1c-1.6-1.4-2.9-3.1-3.8-5
										c-1-1.8-1.8-3.8-2-6c-0.3-2.1-0.1-4.3,0.4-6.3s1.4-4,2.3-5.8c0.9-1.8,2-3.6,2.9-5.4c0.9-1.8,1.5-3.7,1.5-5.6
										c-0.1-2-0.5-4-0.9-6c-0.3-2-0.7-4-0.9-6.1c-0.3-2-0.5-4.1-0.8-6.1s-0.7-4-1.1-6c-1.7-8-4-15.8-6.7-23.5l-0.1-0.4l0.4-0.2
										l1-0.6l-0.2,0.6c-1.9-6.4-3.8-12.7-5.6-19.2c-0.9-3.2-1.7-6.4-2.7-9.6c-0.9-3.2-1.9-6.4-2.9-9.6l-0.1-0.4h0.4
										c0.2,0,0.2,0.1,0.3,0.1s0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.3,0.3,0.5,0.5,0.8
										c0.3,0.5,0.6,1.1,0.9,1.6c0.6,1.1,1.2,2.1,1.8,3.2c1.2,2.2,2.3,4.3,3.5,6.5c2.3,4.3,4.5,8.7,6.7,13.1
										c2.2,4.4,4.3,8.8,6.4,13.2l3.1,6.6l1.5,3.4c0.2,0.6,0.5,1.1,0.7,1.7c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0.1,0,0.2
										c0,0,0,0.2,0,0.3s0,0.2-0.1,0.3c-0.1,0.4-0.3,0.7-0.5,0.9c-0.8,1.1-1.7,1.9-2.5,2.8l-1.3,1.3c-0.2,0.2-0.4,0.4-0.6,0.7
										c-0.1,0.1-0.2,0.2-0.3,0.3s-0.1,0.2-0.1,0.3c-0.3,1-0.1,2.2,0.1,3.4l0.6,3.6c0.4,2.4,0.7,4.9,0.9,7.3
										c0.1,2.4,0.5,4.8,1.2,7.1c1.3,4.7,3.5,9,6,13.2c1.3,2.1,2.6,4.1,4,6.1c0.7,1,1.4,2,2.1,3.1s1.2,2.2,1.7,3.4l0.3,0.9l-1-0.3
										c-0.8-0.2-1.5-0.4-2.3-0.6l-0.3-0.1l-0.1-0.3c-0.6-1.8-1.7-3.4-3-4.7c-1.3-1.3-3-2.3-4.8-2.6s-3.7,0-5.2,1
										s-2.7,2.6-3.4,4.3c-1.4,3.5-1.1,7.6,0.5,11C245.3,250.1,248.3,253,252.2,253.1z"/>
					</g>
				</g>
				<g>
					<path class="st13" d="M276.3,255.7c-3.8,4.4-14.7,0.1-19.5-0.6c-0.2,0-0.3-0.1-0.5-0.1c-1.4-0.3-2.7-0.8-4.1-1.4
									c-2.6-1.1-5.1-2.7-7.6-4.4c-1.1-1.5-2-3.4-2.4-5.5c-1.4-6.5,1.6-12.6,6.7-13.7c2.7-0.6,5.4,0.4,7.6,2.3l-1.6,1.4l4.5,3.8
									c0.2,0,0.4,0.1,0.6,0.1c0.8,0.2,1.6,0.4,2.3,0.6c4.9,1.4,9.4,3.9,13.5,6.9C278.8,247.5,278.7,252.7,276.3,255.7z"/>
					<path class="st2" d="M276.7,256c-1,1.2-2.6,1.8-4,2c-1.5,0.2-2.9,0.1-4.4,0c-2.9-0.3-5.6-1-8.4-1.7
									c-0.7-0.2-1.4-0.3-2.1-0.5c-0.3-0.1-0.7-0.1-1-0.2c-0.3-0.1-0.7-0.1-1.1-0.2c-1.4-0.3-2.8-0.9-4.1-1.5
									c-2.6-1.2-5-2.8-7.4-4.3l-0.1-0.1l-0.1-0.1c-1.6-2.2-2.5-4.8-2.8-7.5s0.2-5.4,1.6-7.8c1.3-2.3,3.6-4.2,6.3-4.6
									c1.3-0.2,2.7-0.1,4,0.3c1.3,0.4,2.5,1.1,3.5,2l0.4,0.4l-0.4,0.4L255,234v-0.8l4.5,3.8l-0.2-0.1c0.6,0.1,1.2,0.3,1.9,0.4
									c0.6,0.2,1.2,0.3,1.8,0.5c1.2,0.4,2.4,0.8,3.6,1.3c2.3,1,4.5,2.2,6.7,3.6c1.1,0.7,2.1,1.4,3.1,2.2c1,0.9,1.6,2.2,1.9,3.5
									C278.9,251.1,278.3,253.9,276.7,256z M275.9,255.3c1.4-1.8,2-4.4,1.5-6.6c-0.3-1.1-0.8-2.2-1.6-3c-0.9-0.7-2-1.4-3-2.1
									c-2.1-1.3-4.2-2.5-6.5-3.5c-1.1-0.5-2.3-0.9-3.5-1.3c-0.6-0.2-1.2-0.3-1.8-0.5c-0.6-0.1-1.2-0.3-1.8-0.4h-0.1l-0.1-0.1
									l-4.5-3.8l-0.4-0.4l0.4-0.4l1.6-1.4v0.7c-0.9-0.8-2-1.5-3.1-2s-2.4-0.7-3.7-0.5c-1.2,0.2-2.4,0.7-3.4,1.5s-1.7,1.8-2.3,2.9
									c-1.1,2.2-1.5,4.8-1.3,7.3s1.1,4.9,2.6,7l-0.1-0.1c2.3,1.6,4.7,3.1,7.2,4.2c1.3,0.6,2.6,1.1,3.9,1.4c0.4,0.1,0.7,0.1,1,0.2
									c0.4,0.1,0.7,0.1,1.1,0.2c0.7,0.1,1.4,0.3,2.1,0.5c2.7,0.7,5.5,1.4,8.2,1.7c1.4,0.1,2.8,0.2,4.1,0
									C273.8,256.8,275.1,256.3,275.9,255.3z"/>
				</g>
				<path class="st30" d="M213,140.2c-0.7,0.8,30,45.6,31.3,46.3c1.3,0.7,5.9-0.8,7.9-0.9c2-0.1,5.6,4.8,12,9.8
								c8.1,6.3,12.4,16.6,16.1,26.6c2.3,6.2,6.1,8.9,9.9,10l0,0c4.8,1.3,9.5,0,10.8-0.7c2.5-1.2,4.4-4.8,4.4-9
								c0-3.3-1.2-6.9-4.1-10.2c-1-1.1-2-2-3.1-2.8c0.4,1.2,0.7,2.4,0.9,3.6c0,0,0,0,0.1,0c2,2.1,3.2,4.6,3.6,7
								c0.6,3.1-0.2,6.2-2.3,8.2c-3.8,3.6-10.6,2.6-15.1-2.3c-0.7-0.7-1.3-1.5-1.8-2.3c-1.3-2.1-1.9-4.3-2-6.4
								c-0.7-4.7,0-9.6,3-12.2c-1.6-0.2-3.1-0.4-4.3-0.7c-5.1-1.3-4.8-2.8-15.2-12.2c-10.5-9.3-25.5-28.2-25.5-28.2l-0.9,0.3
								L213,140.2z"/>
				<g>
					<path class="st13" d="M300.4,228.2c-3.8,3.6-10.6,2.6-15.1-2.3c-0.7-0.7-1.3-1.5-1.8-2.3c-1-1.9-1.6-4.1-2-6.4
									c-0.7-4.7,0-9.6,3-12.2c1.7-1.5,4.2-2.3,7.5-1.9c3.2,0.6,5.1,3.2,6.1,6.2c0.4,1.2,0.7,2.4,0.9,3.6c0.1,0.4,0.1,0.8,0.2,1.2
									c0.3,2,1.3,3.8,2.8,5.2c0.2,0.2,0.4,0.4,0.7,0.6C303.4,223.1,302.6,226.2,300.4,228.2z"/>
					<path class="st2" d="M300.8,228.6c-1.3,1.2-3,2-4.7,2.2s-3.5,0-5.1-0.5c-1.6-0.6-3.1-1.4-4.5-2.5c-0.7-0.5-1.3-1.1-1.8-1.8
									c-0.6-0.6-0.9-1.5-1.4-2.1l0,0l0,0c-0.9-1.9-1.5-3.9-1.8-5.9s-0.4-4.1-0.2-6.1c-0.2-2.1,0.6-4.2,1.9-5.9s3.4-2.9,5.5-3.2
									c1.1-0.2,2.1-0.2,3.2,0c1.1,0.1,2.1,0.5,3.1,1.2c1.8,1.2,3,3.2,3.7,5.2c0.4,1,0.6,2,0.8,3c0.1,0.5,0.2,1,0.3,1.5l0.1,0.8
									c0,0.2,0.1,0.5,0.1,0.7c0.2,0.9,0.6,1.8,1.2,2.6c0.5,0.8,1.2,1.4,2,2.1l0.1,0.1v0.2c0.3,1.5,0.3,3.1-0.1,4.7
									C302.8,226,302,227.5,300.8,228.6z M300.1,227.8c1-1,1.7-2.2,2.1-3.6c0.3-1.4,0.3-2.8,0.1-4.2l0.2,0.3
									c-0.7-0.6-1.6-1.4-2.2-2.3c-0.6-0.9-1.1-1.9-1.3-3c-0.1-0.3-0.1-0.5-0.1-0.8l-0.1-0.7c-0.1-0.5-0.2-1-0.3-1.5
									c-0.2-1-0.5-1.9-0.8-2.9c-0.7-1.8-1.7-3.6-3.3-4.6c-0.8-0.5-1.7-0.9-2.6-1c-1-0.1-2-0.1-2.9,0c-1.9,0.3-3.7,1.2-4.8,2.8
									c-0.6,0.8-1,1.6-1.4,2.5l-0.2,0.7c-0.1,0.2-0.1,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.1,0.5-0.1,0.7
									c-0.5,4,0.4,8.2,2.2,11.7l0,0c1,1.3,2.1,2.5,3.3,3.5c1.2,1,2.6,1.8,4.1,2.3s3.1,0.7,4.7,0.5
									C297.5,229.5,299,228.9,300.1,227.8z"/>
				</g>

				<ellipse transform="matrix(0.5673 -0.8235 0.8235 0.5673 -37.1622 273.1225)" class="st30" cx="241.3" cy="171.9" rx="3.6" ry="3.8" />
				<g>
					<line class="st10" x1="238.9" y1="170.3" x2="243.5" y2="173.5" />
					<path class="st2" d="M239.2,170c0.9,0.4,1.6,0.9,2.4,1.4c0.7,0.6,1.5,1.1,2.2,1.8l-0.4,0.6c-0.9-0.4-1.7-0.9-2.4-1.4
									c-0.8-0.5-1.5-1.1-2.2-1.7L239.2,170z"/>
				</g>
			</g>
		</g>


	)
}
