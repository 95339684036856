import React from 'react'
import { useSelector } from 'react-redux'

export const PliG1 = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PliG1">
            <path className="st2" d="M158.3,99.3c-0.4,0.8-0.7,1.6-0.9,2.4c-0.2,0.8-0.3,1.7-0.4,2.5c-0.2,1.7,0,3.4,0.5,5
							c-0.8-1.6-1.1-3.3-0.9-5.1c0.1-0.9,0.3-1.7,0.5-2.6C157.4,100.8,157.8,100,158.3,99.3z"/>
        </g>
    )
}