import React from 'react'
import { useSelector } from 'react-redux'

export const FondVestibule = (props) => {

    const r_allWhite = useSelector(state => state.parameters.allWhite)

    return (
        <path id="FondVestibule" fill={r_allWhite ? 'white' : '#d866a3'} className="st9" d="M181.5,107.2c-1.8-3.6-13.7-21.6-14.2-20.5c-0.6,1.2-12.3,18.5-14,21.3
								c-1.4,2.2,4.9,13.4,9.4,21.9c2.3,4.2,3,10,4.6,10s2.9-6.1,5.2-10.3C177.1,121,182.6,109.5,181.5,107.2z M167.2,121.3
								c-2.4,0-4.4-10.6-3.7-15.5c0.1-0.4,0.3-0.7,0.7-0.8c2-0.5,4.1-0.5,6.2,0c0.4,0.1,0.6,0.4,0.7,0.8
								C171.8,110.2,169.6,121.3,167.2,121.3z"/>
    )
}