import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './services/translation/i18n'
import './assets/scss/style.scss'

import Language from './pages/language';
import VuvleParameters from './pages/parameters';
import TypeFGM from './pages/type';
import SubTypeFGM from './pages/subtype';
import Care from './pages/care';
import Surgerie from './pages/surgerie';
import { first, second } from './services/translation/i18n';
import { useDispatch, useSelector } from 'react-redux';
import About from './pages/about';
import { Anatomie } from './pages/anatomie';

function App() {
  const r_action = useDispatch()
  const r_reload = useSelector((state) => state.language.reload)
  const r_loadingLanguage = useSelector(state => state.parameters.loadingLanguage)
  const r_switchTranslate = useSelector(state => state.parameters.switchTranslate)

  const [loading, setLoading] = useState(true)


  //GET TRANSLATION FROM LOCALSTORAGE
  useEffect(() => {

    r_action({ type: 'SET_LOADING_LANGUAGE', loadingLanguage: false });
    if (localStorage.getItem('languages') && window.location.pathname !== '/') {
      if (JSON.parse(localStorage.getItem('languages')).codesLanguages) {
        let codesLanguages = JSON.parse(localStorage.getItem('languages')).codesLanguages

        first.changeLanguage(codesLanguages[0])
        let stringLanguages = codesLanguages[0]

        if (codesLanguages[1]) {
          second.changeLanguage(codesLanguages[1])
          stringLanguages = `${codesLanguages[0]},${codesLanguages[1]}`
        }
        else { second.changeLanguage(codesLanguages[0]) }


        fetch(
          `http://web.bioscope-admin.beta-nd.com/wp-json/bioscope/v1/get-translations?languages=${stringLanguages}`
        )
          .then((res) => res.json())
          .then(
            (result) => {
              let tmpResult = { ...result }
              for (let i = 0; i < 2; i++) {
                if (result[codesLanguages[i]]) {
                  Object.keys(result[codesLanguages[i]]).forEach(keyTrad => {
                    tmpResult[codesLanguages[i]] = {
                      ...tmpResult[codesLanguages[i]],
                      [keyTrad]: tmpResult[codesLanguages[i]][keyTrad]
                    }
                  })
                }
              }

              r_action({ type: 'SET_SWITCH_LANGUAGE', switchTranslate: false });

              first.addResources(codesLanguages[0], 'translation', tmpResult[codesLanguages[0]])

              second.addResources(codesLanguages[1] ? codesLanguages[1] : codesLanguages[0], 'translation', tmpResult[codesLanguages[1] ? codesLanguages[1] : codesLanguages[0]])
              r_action({ type: 'RELOAD' })
              setLoading(false)
            },
            (error) => {
              console.log("error", error);
            }
          );
      } else { setLoading(false) }
    } else { setLoading(false) }
  }, [])

  // SET STORE FROM LOCAL STORAGE
  useEffect(() => {
    if (window.location.pathname !== '/anatomie') {
      if (localStorage.getItem('skinColor')) {
        let skinColor = JSON.parse(localStorage.getItem('skinColor'))
        r_action({ type: 'SET_SKIN_COLOR', color: skinColor.skinColor })
      }
      if (localStorage.getItem('labiaColor')) {
        let labiaColor = JSON.parse(localStorage.getItem('labiaColor'))
        r_action({ type: 'SET_LABIA_COLOR', color: labiaColor.labiaColor })
      }
    }

    // if (localStorage.getItem('hairColor')) {
    //   let hairColor = JSON.parse(localStorage.getItem('hairColor'))
    //   r_action({ type: 'SET_HAIR_COLOR', color: hairColor.hairColor })
    // }
    // if (localStorage.getItem('typeHair')) {
    //   let typeHair = JSON.parse(localStorage.getItem('typeHair'))
    //   r_action({ type: 'SET_TYPE_HAIR', typeHair: typeHair.typeHair })
      // console.log("LLCLCL");
    // }

    // if (localStorage.getItem('isClosed')) {
    //   let isClosed = JSON.parse(localStorage.getItem('isClosed'))
    //   r_action({ type: 'SET_IS_CLOSED', isClosed: isClosed.isClosed })
    // }

    if (localStorage.getItem('showHymen')) {
      let showHymen = JSON.parse(localStorage.getItem('showHymen'))
      r_action({ type: 'SET_SHOW_HYMEN', showHymen: showHymen.showHymen })
    }
    if (localStorage.getItem('typeHymen')) {
      let typeHymen = JSON.parse(localStorage.getItem('typeHymen'))
      r_action({ type: 'SET_TYPE_HYMEN', typeHymen: typeHymen.typeHymen })
    }

    // if (localStorage.getItem('labiaType')) {
    //   let labiaType = JSON.parse(localStorage.getItem('labiaType'))
    //   r_action({ type: 'SET_LABIA_TYPE', labiaType: labiaType.labiaType })
    // }

    // if (localStorage.getItem('skinOpacity')) {
    //   let skinOpacity = JSON.parse(localStorage.getItem('skinOpacity'))
    //   r_action({ type: 'SET_SKIN_OPACITY', opacity: skinOpacity.skinOpacity })
    // }

    if (localStorage.getItem('fgm_type')) {
      let fgm_type = JSON.parse(localStorage.getItem('fgm_type'))
      r_action({ type: 'SET_FGM_TYPE', fgm_type: fgm_type.fgm_type })
    }
    if (localStorage.getItem('fgm_subType')) {
      let fgm_subType = JSON.parse(localStorage.getItem('fgm_subType'))
      r_action({ type: 'SET_FGM_SUB_TYPE', fgm_subType: fgm_subType.fgm_subType })
    }

    if (localStorage.getItem('surgerie')) {
      let surgerie = JSON.parse(localStorage.getItem('surgerie'))
      r_action({ type: 'SET_FGM_SURGERIE', surgerie: surgerie.surgerie })
    }
  })

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Language />
        </Route>
        <Route exact path="/anatomie">
          <Anatomie loading={loading} />
        </Route>
        <Route exact path="/parameters">
          <VuvleParameters loading={loading} />
        </Route>
        <Route exact path="/fgm-type">
          <TypeFGM loading={loading} />
        </Route>
        <Route exact path="/fgm-subtype">
          <SubTypeFGM loading={loading} />
        </Route>
        <Route exact path="/care">
          <Care loading={loading} />
        </Route>
        <Route exact path="/surgerie">
          <Surgerie loading={loading} />
        </Route>
        <Route exact path="/about">
          <About loading={loading} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
