import React, { useEffect, useState } from 'react'
import { first, second } from '../../services/translation/i18n'
import ReactSlider from 'react-slider'
import { useDispatch, useSelector } from 'react-redux'
import { CirclePicker } from 'react-color'
import { useWindowSize } from '../../services/hooks/useWindowSize'

export const HairColor = (props) => {
    const r_action = useDispatch()
    const r_hair_color = useSelector(state => state.parameters.hairColor)

    const windowSize = useWindowSize()

    return (
        <div>
            <h2 className="text-black text-center text-bolder text-30">{first.t('ecran1-couleur-pilosite-choix')}</h2>
            {first.language !== second.language ? <h2 className="text-black text-center text-18 mt-03">{second.t('ecran1-couleur-pilosite-choix')}</h2> : ""}

            <div className={`flex mt-1 f-center ${windowSize.width < 350 ? 'f-column' : ''}`}>
                <div className={`text-center ${windowSize.width < 350 ? 'mb-1' : 'mr-1'}`}>
                    <p className="text-bold">{first.t('ecran1-couleur-votre-pilosite')}</p>
                    {first.language !== second.language ? <p>{second.t('ecran1-couleur-votre-pilosite')}</p> : ""}
                </div>
                <CirclePicker
                    width='200px'
                    colors={['#111111', '#252526', '#392d2c', '#684737', '#923840', '#a77f5d', '#bb9973', '#d0a666', '#e5c88f', '#e4d4b3', '#817a73', '#d0cec9', '#eeede7v']}
                    color={r_hair_color}
                    onChangeComplete={(color) => {
                        r_action({type: 'SET_HAIR_COLOR', color: color.hex})
                    }}
                />
            </div>
        </div>
    )
}
