import React from 'react'

export const CuisseD1 = (props) => {
    return (
        <g id="CuisseD">
		<path className="st2" d="M207,73.4c0.9-1.8,1.8-3.7,2.8-5.5l3.2-5.3l3.5-5.1c1.2-1.7,2.5-3.3,3.7-5c5.1-6.5,10.5-12.7,16.2-18.7
			l4.3-4.5l4.4-4.4c3-2.9,5.9-5.8,9-8.5s6.1-5.6,9.2-8.3s6.2-5.4,9.4-8c-5.9,5.8-11.9,11.4-17.7,17.2s-11.7,11.6-17.3,17.6
			S226.5,47,221.3,53.4c-1.3,1.6-2.6,3.2-3.9,4.8l-3.7,4.9l-3.5,5.1L207,73.4z"/>
	</g>
    )
}