import React from 'react'
import { useSelector } from 'react-redux'

export const PliG2 = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PliG2">
            <path className="st2" d="M167.1,84.5c-0.8,2-1.8,3.8-2.7,5.7s-1.9,3.7-2.7,5.6c-0.8,1.9-1.5,3.9-2,5.9c-0.5,2-0.7,4.1-0.6,6.2
							c-0.2-2.1,0-4.2,0.4-6.2c0.5-2,1.1-4.1,1.9-6c0.8-1.9,1.7-3.8,2.6-5.7s1.8-3.8,2.5-5.7l0,0c0.1-0.2,0.2-0.2,0.4-0.2
							c0,0,0,0,0,0C167.2,84.2,167.2,84.3,167.1,84.5C167.2,84.5,167.2,84.5,167.1,84.5L167.1,84.5z"/>
        </g>
    )
}