import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresLeftBigRigthMedium = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="bigLabiaLeft_x5F_mediumLabiaRight">
								<path fill={r_labia_color} className="st--5" d="M183.3,109.7c-1.2,7.2-14.9,31.3-16.2,31.3s-18.7-24.1-19.9-31.3c-0.5-2.7-0.5-5.4,0-8.1
									c0.6-3.1,8.1-12,9.1-14.2c1.7-3.8,7.9-17.7,10.7-17.7s8.4,12.9,10.1,16.7c0.7,1.6,4.9,10.8,5.5,12.9
									C183.7,102.7,183.9,106.2,183.3,109.7z"/>
								<path class="st2" d="M183.7,109.8c-0.3,1.7-0.9,3.3-1.5,4.9c-0.6,1.6-1.3,3.2-2,4.7c-1.4,3.1-3,6.1-4.6,9.1
									c-1.6,3-3.3,6-5.1,8.9c-0.5,0.7-0.9,1.4-1.4,2.1c-0.2,0.4-0.5,0.7-0.8,1c-0.1,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.2,0.2-0.4,0.3
									c-0.2,0.1-0.4,0-0.5,0c-0.1,0-0.2-0.1-0.2-0.1c-0.8-0.6-1.2-1.3-1.8-1.9c-0.5-0.7-1.1-1.3-1.6-2c-4.1-5.5-7.9-11.1-11.3-17
									c-0.9-1.5-1.7-3-2.5-4.5c-0.8-1.5-1.5-3.1-2.1-4.7c-0.5-1.7-0.7-3.4-0.7-5.1c0-0.9,0-1.7,0.1-2.6c0.1-0.9,0.1-1.7,0.5-2.6
									c0.6-1.6,1.6-3.1,2.5-4.5c0.9-1.4,1.9-2.8,2.9-4.2c1-1.4,2-2.8,2.9-4.2c0.2-0.4,0.4-0.7,0.6-1.1l0.5-1.2l1.1-2.3
									c1.4-3.1,3-6.2,4.7-9.1c0.9-1.5,1.8-2.9,3.1-4.2c0.3-0.3,0.7-0.6,1.3-0.8c0.3-0.1,0.7-0.1,0.9,0c0.3,0.1,0.5,0.2,0.7,0.4
									c0.8,0.6,1.3,1.3,1.8,2c3.9,5.7,6.4,12.1,9.2,18.3l2,4.7c0.6,1.6,1.4,3.1,1.8,4.8C184.1,102.8,184.3,106.4,183.7,109.8z
									 M183,109.6c0.6-3.3,0.4-6.7-0.6-9.9c-0.5-1.6-1.2-3.1-1.9-4.7l-2.1-4.7c-1.4-3.1-2.8-6.2-4.3-9.3c-1.5-3-3-6.1-4.9-8.8
									c-0.5-0.7-1-1.3-1.6-1.8c-0.3-0.2-0.6-0.3-0.8-0.3c-0.3,0.1-0.6,0.3-0.9,0.6c-1.1,1.1-2.1,2.6-2.9,4c-1.7,2.9-3.2,5.9-4.7,9
									l-1.1,2.3l-0.5,1.2c-0.2,0.4-0.4,0.8-0.6,1.2c-1,1.5-2,2.8-3,4.2c-1,1.4-2,2.7-3,4.1c-1,1.4-1.9,2.8-2.6,4.3
									c-0.3,0.7-0.4,1.6-0.5,2.4c-0.1,0.8-0.1,1.7-0.1,2.5c0,0.8,0.1,1.7,0.2,2.5c0.1,0.8,0.2,1.7,0.5,2.5
									c1.1,3.2,2.8,6.2,4.5,9.1c3.5,5.8,7.4,11.4,11.6,16.8c0.5,0.7,1.1,1.3,1.6,2c0.5,0.6,1.1,1.3,1.7,1.8
									c-0.3,0.2,0.2-0.2,0.4-0.5c0.3-0.3,0.5-0.7,0.7-1c0.5-0.7,0.9-1.4,1.4-2.1c1.8-2.9,3.5-5.8,5.1-8.8c1.6-3,3.2-6,4.6-9.1
									c0.7-1.5,1.4-3.1,2.1-4.6C182.1,112.9,182.7,111.3,183,109.6z"/>
							</g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}