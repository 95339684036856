import React from 'react'
import { useSelector } from 'react-redux'

export const ContourVestibuleFerme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="ContourVestibuleFerme" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
            <path className="st2" d="M167.4,132.1c-0.1-0.1-0.4,0.1-0.3,0c0,0,0,0,0,0.1l0,0l0,0v-1.2v-1.4l-0.1-2.7l-0.2-5.4l-0.4-10.9
							c-0.2-3.6-0.3-7.2-0.5-10.9l-0.2-2.7c0-0.9,0-1.9,0.1-2.8c0.2-1.8,0.5-3.7,0.9-5.4l0,0l0.8-3l0.2,3.1c0.1,1.8,0.2,3.6,0.2,5.5
							v5.4c0,3.6,0,7.2-0.1,10.9l-0.2,10.9l-0.1,5.4v2.7v2.6l0,0C167.5,132.1,167.5,132.1,167.4,132.1z M167.2,132.6
							c-0.1,0-0.1-0.1-0.1-0.2v-5.5v-5.4l0.1-10.9c0-3.6,0-7.2,0-10.9l-0.1-5.4c-0.1-1.8-0.2-3.6-0.3-5.4l1,0.1l0,0
							c-0.5,1.7-0.8,3.5-1,5.3c-0.1,0.9-0.1,1.8-0.1,2.6l0.2,2.7c0.2,3.6,0.3,7.3,0.5,10.9l0.3,10.9l0.1,5.4v2.7v2.4v0.2v0.2v0.1
							c0,0,0,0.1,0,0.1C167.6,132.5,167.3,132.6,167.2,132.6L167.2,132.6z"/>
        </g>
    )
}