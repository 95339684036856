import React from 'react'
import { useSelector } from 'react-redux'

export const Glans = (props) => {

const r_allWhite = useSelector(state => state.parameters.allWhite)
    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Glans" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                    <path fill={r_allWhite ? 'white' : ' #e54d4f'} className="st1" d="M169.5,81.1c0.1,0.5,0.2,1,0.1,1.5c-0.2,1.3-0.6,2.6-1.2,3.9c-0.5,1-1,1.6-1.5,1.6s-1.1-0.6-1.5-1.6
					c-0.5-1.2-0.9-2.5-1.2-3.9c0-0.5,0-1,0.1-1.5H169.5z"/>
                    <path className="st2" d="M169.5,81.1c0.3,0.6,0.4,1.3,0.3,2c-0.1,0.7-0.2,1.3-0.4,2c-0.2,0.7-0.4,1.3-0.7,1.9
					c-0.2,0.3-0.4,0.6-0.6,0.9c-0.3,0.4-0.7,0.6-1.2,0.6c-0.5,0-0.9-0.3-1.2-0.6c-0.2-0.3-0.4-0.6-0.6-0.9c-0.3-0.6-0.5-1.3-0.6-2
					c-0.4-1.3-0.5-2.6-0.2-3.9L169.5,81.1L169.5,81.1z M169.5,81.1h-5.2c-0.2,0.6-0.2,1.3,0,1.9c0.2,0.6,0.4,1.2,0.7,1.8
					c0.3,0.6,0.6,1.2,0.9,1.7c0.3,0.5,0.8,1,1,1s0.7-0.5,1-1c0.3-0.6,0.6-1.1,0.8-1.7C169.2,83.6,169.5,82.4,169.5,81.1z"/>
                    <path fill={r_allWhite ? 'white' : ' #e54d4f'} className="st1" d="M166.9,79.5c1.1,0.1,2.1,0.7,2.6,1.6h-5.2C164.8,80.1,165.8,79.5,166.9,79.5z" />
                </g>
    )
}