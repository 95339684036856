import React, {useState} from 'react'
import {first, second} from '../services/translation/i18n'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {StepsParameters} from '../components/stepsParameters/stepsParameters'
import {FiArrowLeft} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import {GlobalLayout} from '../components/globallayout/globalLayout'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


export default function Care(props) {
    const r_action = useDispatch()
    const r_fgmAll = useSelector(state => state.parameters.fgmAll);
    const r_type = useSelector(state => state.parameters.fgm_type);
    const [openPopup, setOpenPopup] = useState(false);
    const [disableCare, setdisableCare] = useState(false);
    const r_subType = useSelector(state => state.parameters.fgm_subType)
    const r_surgerie = useSelector(state => state.parameters.surgerie)

    const isFromStep4 = new URLSearchParams(window.location.search).get('fgm')

    const loading = props.loading

    const tabCare = [
        {
            title: 'title-accompagnement',
            short: 'content-accompagnement',
            btn: 'en-savoir-plus',
            popup: true
        },
    ]

    useEffect(() => {
        r_action({type: 'SET_STEP', step: 4})
        r_action({
            type: 'SET_ANIMATION_STEP', step: 0
        })
    }, [])

    useEffect(() => {

        if (r_fgmAll[+r_type - 1] !== undefined && r_fgmAll[+r_type - 1].subTypes.length !== 0) {
            let value = (r_fgmAll[+r_type - 1].subTypes.find(x => x.subType === r_subType)).content;
            if (value.toString() !== "ecran4-type1a-description") {
                if (value.toString() !== "ecran4-type2a-description") {
                    setdisableCare(true)
                }
            }
        } else {
            setdisableCare(false)
        }
    })

    return (
        <GlobalLayout className="bg-pattern-2" loading={loading}>
            <div className="flex f-column f-center f-1">
                <Popup
                    trigger={<div className="sample-trigger"></div>}
                    open={openPopup}
                    modal
                    nested
                    className={"r-bl r-br"}
                >
                    {close => (
                        <div className="modal">
                            <button className="close" onClick={() => {
                                setOpenPopup(false);
                            }}>
                                &times;
                            </button>
                            <div className="header text-center text-bold py-3">
                                {first.t('care-information-title')}
                                <span className={"text-14"}>
                                    <br/>
                                    {second.t('care-information-title')}
                                </span>
                            </div>
                            <div className="content">
                                <p className="px-1 mt-1 text-center text-14">
                                    {first.t('care-information-content').split('/n').map(line => <span
                                        className={"text-center text-14 block mb-02 text-bold"}>{line}</span>)}
                                </p>
                                <p className="px-1 mt-1 text-center text-14">
                                    {second.t('care-information-content').split('/n').map(line => <span
                                        className={"text-center text-14 block mb-02"}>{line}</span>)}
                                </p>
                            </div>
                            <div className="actions">
                                <button
                                    className="block bg-secondary py-1 w-full flex f-center f-column mt-2 r-bl r-br"
                                    style={{bottom: 0, left: 0, right: 0}}
                                    onClick={() => {
                                        setOpenPopup(false);
                                        close();
                                    }}
                                >
                                    <div className="text-uppercase text-bolder text-white mt-05">{first.t('close')}</div>
                                    {first.language !== second.language ? <div className="text-uppercase text-bolder text-white mt-05">{second.t('close')}</div> : ""}

                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
                <div className="w-xl-content flex f-full f-column f-1">
                    <StepsParameters/>
                    <div className="relative flex f-center mx-1">
                        <Link to={`${isFromStep4 === "true" ? '/fgm-type' : '/fgm-subtype'}`} className="block absolute text-white" style={{left: 0}}> <FiArrowLeft
                            size={30}/></Link>
                        <div className="f-1">
                            <h1 className="text-white text-center text-bolder text-30"> {first.t(`ecran4-priseencharge`)}</h1>
                            <h2 className="text-white text-center text-18 px-2">
                                {first.language !== second.language ? second.t('ecran4-priseencharge') : ""}
                            </h2>
                        </div>
                    </div>

                    <div className="px-2">
                        <p className="text-center text-20 text-bold mt-1 text-white">{first.t(`care-content`)}</p>
                        <p className="text-center text-18 text-white">
                            {first.language !== second.language ? second.t('care-content') : ""}
                        </p>
                    </div>

                    <div className="f-1 relative mt-1">

                        <div style={{height: 400}}>
                            <div className="relative overflow f-1 h-full">
                                <div className="flex absolute">
                                    {tabCare.map((careType, i) => {
                                        return (
                                            <div key={i}
                                                 className={`mr-05 cursor-pointer ${i === 0 ? 'ml-1' : 'ml-05'} bg-white r flex f-column f-full`}
                                                 style={{width: 300}}>
                                                <h2 className="px-1 pt-1 text-bold text-20 text-center">{first.t(careType.title)}</h2>
                                                {first.language !== second.language ?
                                                    <p className="px-1 text-center">{second.t(careType.title)}</p> : ""}

                                                <p className="px-1 mt-1 text-bold text-center text-18">{first.t(careType.short)}</p>
                                                {first.language !== second.language ?
                                                    <p className="px-1 mt-07 text-center">{second.t(careType.short)}</p> : ""}


                                                <a onClick={() => setOpenPopup(true)} target="_blank"
                                                   className="block bg-secondary py-1 w-full flex f-center f-column mt-2 r-bl r-br"
                                                   style={{bottom: 0, left: 0, right: 0}}>
                                                    <div
                                                        className="text-uppercase text-bolder text-white mt-05">{first.t(careType.btn)}</div>
                                                    {first.language !== second.language ? <div
                                                        className="text-uppercase text-white mb-05 mt-03">{second.t(careType.btn)}</div> : ""}

                                                </a>

                                            </div>
                                        )
                                    })}

                                    <div className={`relative mr-1 ml-05 bg-white r flex f-column f-full`}
                                         style={{width: 300}}>
                                        <h2 className="px-1 pt-1 text-bold text-20 text-center">{first.t(`chirurgie`)}</h2>
                                        {first.language !== second.language ?
                                            <p className="px-1 text-center">{second.t(`chirurgie`)}</p> : ""}
                                        <p className="px-1 mt-1 text-bold text-center text-18">
                                            {first.t('chirurgie-description').split('/n').map(line => <span
                                                className={"text-bold text-center text-18"}>{line}</span>)}
                                        </p>
                                        {first.language !== second.language ?
                                            <p className="px-1 mt-07 text-center">{second.t('chirurgie-description').split('/n').map(line =>
                                                <span>{line}</span>)}</p> : ""}
                                        <Link to="/surgerie"
                                              className={`block bg-secondary py-1 w-full flex f-center f-column mt-2 r-bl r-br ${!disableCare ? 'bg-neutral' : ''}`}
                                              style={{
                                                  bottom: 0,
                                                  left: 0,
                                                  right: 0,
                                                  pointerEvents: !disableCare ? "none" : "auto"
                                              }}>
                                            <div
                                                className="text-uppercase text-bolder text-white mt-05">{first.t('en-savoir-plus')}</div>
                                            {first.language !== second.language ? <div
                                                className="text-uppercase text-white mb-05 mt-03">{second.t('en-savoir-plus')}</div> : ""}
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </GlobalLayout>
    )
}
