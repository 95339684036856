import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresLeftMediumRightBig = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="mediumLabiaLeft_x5F_bigLabiaRight">
                <path fill={r_labia_color} className="st--5" d="M187.3,109.7c-1.2,7.2-18.9,31.3-20.2,31.3s-14.7-24.1-15.9-31.3c-0.5-2.7-0.5-5.4,0-8.1
									c0.6-3.1,4.1-12,5.1-14.2c1.7-3.8,7.9-17.7,10.7-17.7s8.4,12.9,10.1,16.7c0.7,1.6,8.9,10.8,9.5,12.9
									C187.7,102.7,187.9,106.2,187.3,109.7z"/>
                <path class="st2" d="M187.7,109.8c-0.3,1.8-1.1,3.3-1.8,4.9c-0.8,1.5-1.6,3-2.4,4.5c-1.7,3-3.6,5.8-5.5,8.7
									c-1.9,2.8-3.9,5.6-6,8.3c-1,1.4-2.1,2.7-3.2,4c-0.3,0.3-0.6,0.6-0.9,0.9c-0.1,0.1-0.2,0.2-0.3,0.2l-0.1,0.1
									c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0-0.3-0.1l-0.1-0.1c-0.3-0.2-0.4-0.4-0.5-0.5c-0.6-0.7-1-1.4-1.5-2.1
									c-3.6-5.8-6.7-11.9-9.6-18.1c-0.7-1.6-1.4-3.1-2-4.7c-0.6-1.6-1.3-3.2-1.7-4.8c-0.8-3.3-0.9-6.8-0.2-10.2
									c0.9-3.3,2.1-6.5,3.3-9.7c1.2-3.2,2.6-6.3,4.1-9.4c1.5-3.1,3-6.1,4.9-9c0.5-0.7,1-1.4,1.6-2.1c0.3-0.3,0.6-0.7,1-0.9
									c0.2-0.1,0.4-0.3,0.7-0.3c0.3-0.1,0.7,0,0.9,0.1c1,0.4,1.5,1.2,2.1,1.8c0.5,0.7,1,1.4,1.5,2.1c1.8,2.9,3.3,6,4.7,9.1l1,2.3
									l0.5,1.2c0.1,0.2,0.1,0.4,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.5c1,1.4,2.1,2.7,3.1,4c1.1,1.3,2.1,2.7,3.2,4
									c0.5,0.7,1,1.4,1.5,2.1c0.5,0.7,1,1.4,1.3,2.4C188.1,102.8,188.3,106.4,187.7,109.8z M187,109.6c0.6-3.3,0.4-6.7-0.6-10
									c-0.2-0.7-0.7-1.4-1.2-2.1c-0.5-0.7-1-1.4-1.5-2c-1-1.3-2.1-2.7-3.2-4c-1.1-1.3-2.2-2.6-3.2-4c-0.1-0.2-0.3-0.4-0.4-0.6
									c-0.1-0.2-0.2-0.5-0.3-0.6l-0.5-1.2l-1.1-2.3c-1.5-3.1-2.9-6.1-4.7-9c-0.4-0.7-0.9-1.4-1.4-2.1c-0.5-0.6-1.1-1.3-1.7-1.5
									c-0.4-0.2-1.1,0.4-1.6,0.9c-0.5,0.6-1,1.3-1.5,2c-1.8,2.8-3.4,5.9-4.9,8.9c-1.5,3.1-2.9,6.1-4.2,9.3
									c-1.3,3.2-2.6,6.3-3.5,9.6c-0.8,3.3-0.8,6.7,0,10c0.9,3.3,2.4,6.4,3.8,9.4c1.5,3.1,3.1,6.1,4.7,9.1c1.6,3,3.3,5.9,5.2,8.8
									c0.5,0.7,0.9,1.4,1.5,2c0.1,0.1,0.3,0.3,0.4,0.4l0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l0,0c0,0,0.1-0.1,0.2-0.1
									c0.3-0.3,0.6-0.6,0.8-0.9c1.1-1.3,2.2-2.6,3.2-3.9c2.1-2.7,4-5.5,6-8.3c1.9-2.8,3.8-5.7,5.5-8.6c0.9-1.5,1.7-2.9,2.5-4.5
									C185.9,112.8,186.7,111.2,187,109.6z"/>
            </g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}