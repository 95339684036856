import React from 'react'
import { useSelector } from 'react-redux'

export const Prepuce2a = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Prepuce2a" className="transition" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                    <path fill={r_labia_color} className="st--5" d="M167.4,80.1c0,3.7-4.9,15.2-6.1,16.6c1.7-6.2,2.9-24.3,5.8-24.2s3.4,15.7,5.6,22.1
					C171.8,91.8,167.4,83.8,167.4,80.1z"/>
                    <path className="st2" d="M173.2,96c-1-2.6-2.1-5.2-3.3-7.8c-0.6-1.3-1.2-2.6-1.8-3.9c-0.3-0.7-0.5-1.3-0.8-2
					c-0.3-0.7-0.4-1.5-0.5-2.2h1.1c-0.1,1.5-0.3,3.1-0.7,4.6c-0.4,1.5-0.8,2.9-1.3,4.4c-0.5,1.4-1.2,2.8-1.9,4.1
					c-0.7,1.3-1.6,2.5-2.7,3.7c1.6-2.5,2.9-5.2,3.8-8.1c0.4-1.4,0.8-2.9,1.1-4.3c0.3-1.4,0.5-2.9,0.6-4.3c0-0.3,0.2-0.5,0.5-0.5
					c0,0,0,0,0,0c0.3,0,0.5,0.2,0.5,0.5c0.1,0.6,0.2,1.3,0.4,1.9c0.2,0.7,0.5,1.3,0.7,2l1.6,3.9c0.5,1.3,1,2.6,1.5,4
					C172.6,93.2,173,94.6,173.2,96z"/>
                </g>
    )
}