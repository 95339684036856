import React from 'react'

export const Ventre1 = (props) => {
    return (
        <g id="Ventre">
		<path className="st2" d="M109.4,48.5c4.6-1.7,9.2-3.1,14-4.1c4.7-1.1,9.5-1.9,14.3-2.6c9.6-1.4,19.3-2.2,29-2.4
			c9.7,0.1,19.4,0.9,29,2.3c4.8,0.7,9.6,1.5,14.3,2.5c4.8,1,9.5,2.4,14,4.1l-0.6,1.5c-4.5-1.7-9.1-3.1-13.8-4.1
			c-4.7-1.1-9.5-1.9-14.2-2.6c-9.5-1.4-19.2-2.2-28.8-2.4c-9.7,0.1-19.3,0.9-28.8,2.3c-4.8,0.7-9.5,1.5-14.3,2.5
			c-4.7,1-9.3,2.3-13.8,4L109.4,48.5z"/>
	</g>
    )
}