import React, {useEffect, useState} from 'react'
import {first, second} from '../services/translation/i18n'
import {StepsParameters} from '../components/stepsParameters/stepsParameters'
import {FiArrowLeft} from "react-icons/fi";
import {Link} from 'react-router-dom'
import {GlobalLayout} from '../components/globallayout/globalLayout'
import {BlockIllu} from '../components/svg/blockIllu'
import {BlockIlluAnatomie} from '../components/svg/blockIlluAnatomie'
import {useDispatch, useSelector} from 'react-redux';
import {useWindowSize} from "../services/hooks/useWindowSize";


export const Anatomie = (props) => {

    const r_action = useDispatch()
    const [displayNavigation, setDisplayNavigation] = useState(true)
    const r_isClosed = useSelector(state => state.parameters.isClosed)
    const r_type_hair = useSelector(state => state.parameters.typeHair)
    const r_reload = useSelector((state) => state.language.reload);

    const windowSize = useWindowSize();

    const loading = props.loading

    useEffect(() => {
        // On check pour afficher les bonnes bulles au début de l'anatomie
        if (document.querySelector("#Glans .st1")) {
            document.querySelector("#Glans .st1").style.fill = "#FFFFFF";
        }
    })


    return (
        <GlobalLayout className="bg-pattern-2" loading={r_reload}>
            <div className="flex f-column f-center f-1">
                <div className="w-xl-content flex f-full f-column f-1">
                    <StepsParameters/>
                    <div className="relative flex f-center mx-1">
                        <Link to="/" className="block absolute text-white" style={{left: 0}}> <FiArrowLeft
                            size={30}/></Link>
                        <div className="f-1">
                            <h1 className="text-white text-center text-bolder text-30">{first.t('ecran1-vulve-intro')}</h1>
                            <h2 className="text-white text-center text-24">{second.t('ecran1-vulve-intro')}</h2>
                        </div>
                    </div>
                    <div className="f-1 flex f-full f-column mb-4">
                        <div className="r-tl r-tr bg-blured-back px-1 pb-2 mt-2 f-1 relative overflow-h">
                            <div className={"introduction"}>
                                <BlockIlluAnatomie className={"block-illu"} bg='white'/>
                                {/*<BlockIllu className={"block-illu"} bg='white'/>*/}
                            </div>


                            <div className="mt-4 mb-1">
                                <div className="relative overflow f-1 h-full">
                                    <div className="flex pb-4 ">
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span style={{background: "#4fae90", borderColor: '#4fae90'}}
                                                          className="bulle-title mr-1 r-full b-2-secondary shadow-small">1</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-monpubis')}
                                                    </span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    <span
                                                        className="text-italic text-14">{second.t('anatomie-monpubis')}</span>
                                                </div>
                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-monspubis")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-monspubis')}</div> : ""}
                                            </p>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span style={{background: "#ef2d47", borderColor: '#ef2d47'}}
                                                          className="bulle-title mr-1 r-full b-2-secondary shadow-small">2</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-glansclitoris')}
                                                    </span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-glansclitoris')}</span> : ""}
                                                </div>
                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-glansclitoris")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-glansclitoris')}
                                                    </div> : ""}
                                            </p>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span style={{background: "#3cbde8", borderColor: '#3cbde8'}}
                                                          className="bulle-title mr-1 r-full b-2-secondary shadow-small">3</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-duterlabia')}
                                                    </span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-duterlabia')}</span> : ""}
                                                </div>

                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-outerlabia")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-outerlabia')}
                                                    </div> : ""}
                                            </p>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span style={{background: "#937cb5", borderColor: '#937cb5'}}
                                                          className="bulle-title mr-1 r-full b-2-secondary shadow-small">4</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-innerlabia')}
                                                    </span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-innerlabia')}</span> : ""}
                                                </div>
                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-innerlabia")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-innerlabia')}</div> : ""}
                                            </p>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span style={{
                                                        background: "#FFF",
                                                        borderColor: '#FFF',
                                                        color: 'black'
                                                    }}
                                                          className="bulle-title mr-1 r-full b-2-secondary shadow-small">5</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-vaginalopening')}
                                                    </span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-vaginalopening')}</span> : ""}
                                                </div>

                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-vaginalopening")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-vaginalopening')}
                                                    </div> : ""}
                                            </p>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span style={{
                                                        background: "#E5CAB0",
                                                        borderColor: '#E5CAB0',
                                                        color: 'white'
                                                    }}
                                                          className="bulle-title mr-1 r-full b-2-secondary shadow-small">6</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-clitoris')}
                                                    </span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-clitoris')}</span> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span
                                                        style={{background: "#e167a7", borderColor: '#e167a7'}}
                                                        className="bulle-title mr-1 r-full b-2-secondary shadow-small">7</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-clitoralhood')}
                                                    </span>

                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-clitoralhood')}</span> : ""}
                                                </div>
                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-clitoralhood")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-clitoralhood')}
                                                    </div> : ""}
                                            </p>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                     <span style={{background: "#f8ef8d", borderColor: '#f8ef8d', color: "black"}}
                           className="bulle-title mr-1 r-full b-2-secondary shadow-small">8</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-uretralopening')}
                                                    </span>

                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-uretralopening')}</span> : ""}
                                                </div>
                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-uretralopening")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-uretralopening')}
                                                    </div> : ""}
                                            </p>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span style={{
                                                        background: "#ff7f00",
                                                        borderColor: '#ff7f00',
                                                        color: 'white'
                                                    }}
                                                          className="bulle-title mr-1 r-full b-2-secondary shadow-small">9</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-hymen')}
                                                    </span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-hymen')}</span> : ""}
                                                </div>
                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-hymen")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-hymen')}</div> : ""}
                                            </p>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span style={{background: "#fcfb0f", borderColor: '#fcfb0f', color: "black"}}
                                                          className="bulle-title mr-1 r-full b-2-secondary shadow-small">10</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-vestibule')}
                                                    </span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-vestibule')}</span> : ""}
                                                </div>
                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-vestibule")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-vestibule')}</div> : ""}
                                            </p>
                                        </div>
                                        <div className="mr-05 ml-1 bg-white r p-1 flex f-column"
                                             style={{width: 200, flex: "0 0 200px"}}>
                                            <div className="flex flex-wrap">
                                                <div className="flex f-center">
                                                    <span style={{background: "#c2925b", borderColor: '#c2925b'}}
                                                          className="bulle-title mr-1 r-full b-2-secondary shadow-small">11</span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens">
                                                    <span className="text-bold text-16 text-anatomie">
                                                        {first.t('anatomie-anus')}
                                                    </span>
                                                </div>
                                                <div className="f-1 f-center-h flex hyphens w-100 mt-05">
                                                    {first.language !== second.language ? <span
                                                        className="text-italic text-14">{second.t('anatomie-anus')}</span> : ""}
                                                </div>

                                            </div>
                                            <p className="mt-05">
                                                {first.t("anatomie-description-anus")}
                                                {first.language !== second.language ?
                                                    <div className={"text-14"}>
                                                        <br/>{second.t('anatomie-description-anus')}</div> : ""}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {displayNavigation &&
                <Link to="/parameters" className="block fixed bg-secondary py-1 w-full flex f-center f-column"
                      style={{bottom: 0}}>
                    <div className="text-uppercase text-bolder text-white mt-05">{first.t('start')}</div>
                    <div className="text-uppercase text-white mb-05 mt-03">
                        {first.language !== second.language ? second.t('start') : ""}
                    </div>
                </Link>}
            </div>
        </GlobalLayout>
    )
}
