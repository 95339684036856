import React from 'react'
import { useSelector } from 'react-redux'

export const Clipping_Path_type_3_final = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <path id="Clipping_Path_type_3_final" className="st10 transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}} d="M167.1,131.4c1.5,0,1.9-19.4,0-19.4S165.6,131.4,167.1,131.4z" />
    )
}