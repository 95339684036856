import React from 'react'
import { useSelector } from 'react-redux'

export const IncisionGlans = (props) => {

	return (
		<g id="incisionGlans" className="transition" style={{ opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(0px, -15px)' }}>
			<g>
				<path class="st12" d="M220.5,42c5.2,0.6,10.3,6.4,10.3,8.9c-0.1,2.5,11.9-12,11.9-12L227,30.7L220.5,42z" />
				<g>
					<path class="st13" d="M258.3,55.7l-0.9,7.8l-0.5,3.9l-0.2,2c-0.1,0.7-0.2,1.3-0.2,2v1.1c0,0.1,0,0.2,0.1,0.3
									c0.6,1.8,1.1,3.5,1.7,5.3l-2.4,2.5l-9.8,1.3c-1.3,0.2-2.4,1.2-2.6,2.5c-0.2,1.7,0.9,3.3,2.6,3.6l12.4,2.1
									c1.2,0.2,2.6-0.2,3.4-1.2l0.3-0.4l5-6c0.7-0.8,1.1-1.9,1.1-3.1v-0.8c0-2.5-0.1-5.1-0.2-7.6l0.1,1.4c0.1-0.6,0.1-1.3,0.1-2
									v-2l0.1-3.9l1.2-8.3c0-1-0.5-1.9-0.6-2.9c-0.3-3.2,4.4-8.3-7.5-20.6s-22.8-7.5-22.8-7.5h-14.3h-0.1c-2.3,0-4.5,1.4-5.4,3.6
									l-7.4,17.9l-6.7,6.8c-1.5,1.5-1.7,4-0.3,5.8c1.5,1.9,4.2,2.1,6.1,0.7l8.9-6.3c0.4-0.3,0.8-0.7,1.1-1.2l0.3-0.6l3.4-6.3
									c0,0,2.3-4.7,3.2-6.7"/>
					<path class="st2" d="M258.8,55.7L257.4,67l-0.3,2.8c-0.1,0.5-0.1,0.9-0.1,1.4v0.7c0,0.2,0,0.5,0,0.6l1.7,5.4l0.1,0.3
									l-0.2,0.2l-2.4,2.5l-0.1,0.1h-0.2l-6.8,0.9l-1.7,0.2l-0.9,0.1l-0.4,0.1c-0.1,0-0.2,0-0.4,0.1c-0.9,0.2-1.7,1.1-1.8,2
									c-0.2,1,0.3,2,1.1,2.5c0.4,0.3,0.8,0.5,1.4,0.5l1.7,0.3l6.8,1.2l3.4,0.6c1,0.2,2.1,0,2.8-0.6c0.4-0.3,0.7-0.8,1.1-1.2
									l1.1-1.3l2.2-2.7l1.1-1.3c0.4-0.4,0.6-0.8,0.8-1.3c0.2-0.5,0.3-1,0.3-1.5s0-1.1,0-1.7l-0.2-6.9h1l0.1,1.4l0,0l-1-0.1
									c0.1-0.6,0-1.2,0-1.9v-2v-3.9l0,0v-0.1c0.3-2.8,0.6-5.5,1-8.3v0.1c0-0.3-0.1-0.7-0.3-1.2c-0.2-0.5-0.4-1.1-0.4-1.8
									c0-1.4,0.3-2.4,0.4-3.4c0.3-2,0.1-3.9-0.6-5.8c-0.7-1.9-1.7-3.6-3-5.3c-1.2-1.7-2.6-3.2-4.1-4.7c-2.9-3-6.2-5.5-10-6.9
									c-1.9-0.7-3.9-1.2-5.9-1.3c-0.5,0-1,0-1.5,0s-1,0.1-1.5,0.1c-1,0.1-2,0.4-2.8,0.6l-0.3,0.1h-0.2l-10.2-0.1h-2.5
									c-0.8,0-1.8-0.1-2.4,0c-1.4,0.2-2.7,1-3.4,2.1c-0.2,0.3-0.3,0.6-0.5,1l-0.5,1.2l-1,2.3l-2,4.6c-1.4,3.1-2.7,6.2-4.1,9.3l0,0
									v0.1l-0.1,0.1l-4.4,4.5l-2.2,2.3c-0.7,0.7-1.1,1.5-1.1,2.5c-0.1,0.9,0.2,1.9,0.8,2.6c0.6,0.7,1.4,1.2,2.3,1.4
									c0.9,0.2,1.9,0,2.7-0.4c0.4-0.2,0.8-0.5,1.3-0.8l1.3-0.9l5.2-3.6l1.3-0.9c0.5-0.3,0.8-0.6,1.1-0.9c0.5-0.8,1-1.8,1.5-2.7
									c1-1.9,2-3.7,3-5.6c0.9-1.9,1.9-3.7,3-5.5c-0.7,2-1.6,3.9-2.4,5.8c-0.4,1-0.9,1.9-1.4,2.8l-1.5,2.8l-0.7,1.4
									c-0.3,0.5-0.4,0.9-0.8,1.4c-0.3,0.5-0.8,0.9-1.2,1.2l-1.3,0.9l-5.1,3.7l-1.3,0.9c-0.4,0.3-0.8,0.6-1.4,0.9
									c-1,0.6-2.2,0.8-3.4,0.6s-2.2-0.8-3-1.8c-0.7-0.9-1.1-2.1-1-3.3s0.6-2.3,1.4-3.2l2.2-2.2l4.4-4.5l-0.1,0.2l0,0
									c1.2-3.2,2.4-6.3,3.6-9.5l1.8-4.7l0.9-2.4l0.5-1.2c0.1-0.4,0.4-0.9,0.6-1.3c1-1.7,2.8-2.9,4.8-3.2c1.1-0.1,1.8-0.1,2.7-0.1
									h2.5l10.1-0.1l-0.5,0.1c1.2-0.5,2.3-0.7,3.5-0.9c0.6-0.1,1.1-0.1,1.7-0.1c0.6,0,1.1,0,1.7,0c2.3,0.1,4.5,0.6,6.7,1.4
									c4.2,1.6,7.9,4.4,10.9,7.6c1.5,1.6,2.9,3.3,4.2,5.1c1.3,1.8,2.4,3.8,3.2,6c0.8,2.2,0.9,4.6,0.5,6.9c-0.2,1.1-0.5,2.2-0.5,3
									c0,0.4,0.1,0.8,0.3,1.3c0.2,0.5,0.4,1.2,0.3,1.9v0.1l0,0c-0.4,2.8-0.9,5.5-1.4,8.3v-0.1l-0.2,3.9l-0.1,2
									c-0.1,0.6,0,1.3-0.2,2l-0.8,4.4l-0.2-4.5l0,0l-0.1-1.4h1l0.2,6.9c0,0.6,0,1.1,0,1.7s-0.2,1.3-0.4,1.8
									c-0.2,0.6-0.6,1.1-1,1.6l-1.1,1.3L264,87l-1.1,1.3c-0.4,0.4-0.7,0.9-1.2,1.3c-1,0.9-2.5,1.1-3.6,0.8l-3.4-0.6l-6.8-1.2
									l-1.7-0.3c-0.6-0.1-1.3-0.3-1.8-0.7c-1.1-0.8-1.7-2.2-1.5-3.5s1.2-2.5,2.5-2.8c0.2,0,0.3-0.1,0.5-0.1l0.4-0.1l0.9-0.1
									l1.7-0.2l6.8-0.9l-0.3,0.2l2.4-2.5l-0.1,0.5c-0.6-1.8-1.1-3.6-1.7-5.5c-0.1-0.4,0-0.6-0.1-0.8v-0.7c0-0.5,0.1-1,0.1-1.5
									l0.3-2.8l1.4-11.2L258.8,55.7z"/>
				</g>
				<g>
					<path class="st13" d="M253.8,50.2l-6.3,13.2l-8.8,8.1l-10.6-0.4l-3.2-4.1c-0.3-0.4-0.9-0.7-1.5-0.6
									c-0.8,0.1-1.4,0.9-1.3,1.8l1.3,7.6c0.1,0.9,0.8,1.6,1.7,1.8l1,0.2l13.7,3c0.8,0.2,1.6,0.1,2.4-0.3l0.9-0.5l13.6-7.4
									c0.7-0.4,1.2-0.9,1.6-1.6l0.4-0.7l7.5-13.7c0.1-0.1,0.1-0.2,0.2-0.3c0.8-1.7,0.9-3.6,0.3-5.3"/>
					<path class="st2" d="M254.2,50.4L248,63.7v0.1l-0.1,0.1l-8.8,8.1l-0.2,0.1h-0.2l-10.6-0.4h-0.2l-0.1-0.2l0,0l-3-3.9
									l-0.2-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.1-0.5-0.1-0.8,0.1c-0.2,0.1-0.4,0.3-0.5,0.6
									c-0.1,0.3,0,0.5,0,0.9l0.8,4.8l0.4,2.4c0.1,0.6,0.6,1.1,1.1,1.2l9.5,2.1l4.8,1c0.4,0.1,0.7,0.2,1.1,0.2c0.3,0,0.7-0.1,1-0.2
									s0.6-0.3,1-0.5l1.1-0.6l8.6-4.6l4.3-2.3c0.6-0.4,1.1-0.9,1.4-1.6l1.2-2.1l4.7-8.6c0.4-0.7,0.8-1.4,1.2-2.1
									c0.4-0.7,0.9-1.4,1.2-2.1c0.6-1.4,0.8-3.1,0.4-4.7c0.7,1.5,0.8,3.3,0.3,4.9c-0.3,0.8-0.7,1.5-1,2.3
									c-0.4,0.7-0.7,1.5-1.1,2.2l-4.6,8.6l-1.2,2.2c-0.4,0.8-1,1.5-1.8,2l-4.3,2.4l-8.6,4.7l-1.1,0.6c-0.4,0.2-0.7,0.4-1.2,0.6
									s-0.9,0.2-1.4,0.2s-1-0.1-1.3-0.2l-4.8-1l-9.6-2.1c-1-0.3-1.8-1.1-2-2.2l-0.4-2.4l-0.8-4.8c0-0.2-0.1-0.4-0.1-0.7
									c0-0.3,0-0.6,0.1-0.8c0.2-0.5,0.6-1,1.1-1.2s1.1-0.3,1.6-0.1c0.3,0.1,0.5,0.2,0.7,0.4c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.2
									l3,3.9l0,0l-0.4-0.2l10.6,0.4l-0.4,0.1l8.8-8.1l-0.1,0.2l6.3-13.2L254.2,50.4z"/>
				</g>
				<g>
					<path class="st13" d="M245.4,44.4l-4.8,8.7l-12.7,9.8L216.6,66l-1.3-1.3c-0.6-0.6-1.5-0.9-2.4-0.7c-1.4,0.3-2.3,1.6-2,3
									l1,5.2c0.2,1.2,1.3,2.1,2.6,2.1h1.5l15.5-0.1c0.7,0,1.4-0.2,2-0.6l0.7-0.5L249.6,63c0.7-0.5,1.4-1.2,1.8-2l0.1-0.2l5.5-9.9
									l0,0c0.8-1.4,1-2.9,0.8-4.3"/>
					<path class="st2" d="M245.8,44.7l-4.8,8.7l-0.1,0.1l-0.1,0.1l-12.6,9.8l-0.1,0.1H228l-11.3,3.1l-0.4,0.1l-0.3-0.3l-0.8-0.8
									l-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.3-1.1-0.4-1.7-0.2c-0.6,0.2-1,0.6-1.2,1.2c-0.1,0.3-0.1,0.6-0.1,0.9V67l0.1,0.3
									l0.1,0.6l0.9,4.5c0.2,0.6,0.6,1.1,1.1,1.3c0.3,0.1,0.5,0.2,0.9,0.2h1.2h9.3h4.7h1.2h0.6c0.2,0,0.3,0,0.5,0
									c0.7-0.1,1.2-0.4,1.8-0.9l15.5-10.3c0.6-0.4,1.1-0.9,1.5-1.5s0.8-1.3,1.1-2l2.3-4.1l2.3-4.1c0.8-1.3,1.1-2.6,1.3-4.2
									c0.7,1.5,0.4,3.3-0.4,4.7l-2.3,4.1l-2.3,4.1c-0.4,0.7-0.7,1.3-1.2,2.1c-0.5,0.7-1.1,1.3-1.8,1.8L234.4,74
									c-0.3,0.2-0.6,0.4-1,0.7c-0.4,0.2-0.8,0.3-1.3,0.4c-0.2,0-0.5,0-0.7,0h-0.6h-1.2h-4.7l-9.3,0.1h-1.2c-0.4,0-1-0.1-1.4-0.3
									c-0.9-0.4-1.6-1.2-1.8-2.1l-0.9-4.6l-0.1-0.6l-0.1-0.3l-0.1-0.4c0-0.5,0-1,0.2-1.4c0.3-0.9,1.1-1.6,2-1.9
									c0.9-0.3,2-0.1,2.8,0.4c0.2,0.1,0.4,0.3,0.5,0.5l0.4,0.4l0.8,0.8l-0.6-0.2l11.3-3.1l-0.2,0.1l12.7-9.7l-0.1,0.2l4.9-8.6
									L245.8,44.7z"/>
				</g>
				<g>
					<g>
						<path class="st32" d="M192.2,76L181,92l-5.7,9.4l0.2,1L187.2,92l2.8-3.4l-0.3-1l6.4-8.5c0.5-0.7,0.2-1.7-0.6-2L192.2,76z"
						/>
						<path class="st2" d="M192.4,76.4l-8.5,12.4l-2.1,3.1c-0.7,1-1.3,2.2-2,3.2l-4,6.5l0.1-0.4l0.1,1l-0.5-0.2
										c2-1.7,3.9-3.4,5.9-5.1s3.9-3.4,5.8-5.2l0,0c0.5-0.6,0.9-1.2,1.3-1.8c0.4-0.6,0.9-1.2,1.3-1.8l-0.1,0.4l-0.3-1l-0.1-0.2
										l0.1-0.2l5-6.6l1.2-1.7c0.2-0.3,0.3-0.4,0.3-0.7c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.3-0.2-0.7-0.3l-0.9-0.3
										C193.6,76.9,193,76.6,192.4,76.4z M192.1,75.5c0.7,0.2,1.4,0.4,2.2,0.6l1.1,0.3c0.3,0.1,0.8,0.3,1.1,0.6
										c0.3,0.4,0.5,0.8,0.4,1.3c0,0.5-0.4,0.9-0.5,1.2l-1.2,1.7l-5,6.6l0.1-0.4l0.3,1l0.1,0.3l-0.2,0.2c-0.5,0.5-1,1.1-1.5,1.6
										s-1,1.1-1.5,1.7l0,0l0,0c-1.9,1.7-3.9,3.4-5.8,5.1c-1.9,1.7-3.9,3.5-5.8,5.2l-0.3,0.3l-0.2-0.4l-0.4-0.9l-0.1-0.2l0.1-0.2
										l4-6.5c0.7-1.1,1.3-2.2,2-3.2l2.2-3.2L192.1,75.5z"/>
					</g>
					<g>
						<path class="st32" d="M185.1,91.1c-0.2,0-0.3,0-0.4-0.1c-0.3-0.2-0.4-0.7-0.2-1l5.8-8.3c0.1-0.2,0.4-0.3,0.6-0.3
										c0.2,0,0.3,0,0.4,0.1c0.3,0.2,0.4,0.7,0.2,1l-5.8,8.3C185.6,91,185.3,91.1,185.1,91.1z"/>
						<path class="st2" d="M190.8,81.8c0.1,0,0.1,0,0.2,0c0.1,0.1,0.2,0.3,0.1,0.4l-5.8,8.3c-0.1,0.1-0.2,0.1-0.2,0.1
										c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.1-0.1-0.1-0.2s0-0.1,0-0.2l5.8-8.3C190.7,81.8,190.8,81.8,190.8,81.8 M190.8,80.9
										c-0.4,0-0.8,0.2-1,0.5l-5.8,8.3c-0.4,0.5-0.2,1.3,0.3,1.7c0.2,0.1,0.5,0.2,0.7,0.2c0.4,0,0.8-0.2,1-0.5l5.8-8.3
										c0.4-0.5,0.2-1.3-0.3-1.7C191.3,81,191.1,80.9,190.8,80.9L190.8,80.9z"/>
					</g>
					<path class="st38" d="M246.3,15.1l-47.8,62.7c-0.3,0.4-0.8,0.7-1.3,0.8c-2.4,0.3-3.9-0.6-5.6-1.5c-0.5-0.3-0.6-0.9-0.3-1.3
									L237.4,9c1.7-2.5,5.1-3.1,7.5-1.3l0,0C247.4,9.3,248,12.6,246.3,15.1z"/>
					<polygon class="st39" points="187.2,92 175.7,102 175.7,101.4 187.8,91.2 								" />
					<path class="st2" d="M204.5,68.5c-1.1,0.4-2.4,0-3.3-0.8c-0.4-0.4-0.8-0.9-1.1-1.4s-0.5-1-0.5-1.6l1.1,1.2
									c0.4,0.4,0.7,0.7,1.1,1c0.4,0.3,0.8,0.6,1.2,0.8C203.5,68,203.9,68.2,204.5,68.5z"/>
					<path class="st2" d="M203.4,70c-1.1,0.3-2.3-0.2-3.2-0.9c-0.4-0.4-0.8-0.8-1.1-1.3s-0.5-1-0.6-1.5c0.4,0.4,0.8,0.8,1.1,1.1
									c0.4,0.4,0.7,0.7,1.1,1c0.4,0.3,0.8,0.6,1.2,0.8C202.4,69.5,202.9,69.8,203.4,70z"/>
					<path class="st2" d="M202.3,71.6c-0.6,0.1-1.1,0-1.6-0.2s-1-0.5-1.4-0.9s-0.8-0.8-1.1-1.2c-0.3-0.5-0.5-0.9-0.7-1.5
									c0.4,0.3,0.8,0.7,1.2,1s0.8,0.7,1.2,1s0.8,0.6,1.2,0.9C201.4,71,201.8,71.3,202.3,71.6z"/>
					<path class="st2" d="M201.2,73.2c-1.1,0.2-2.2-0.3-3.1-1c-0.4-0.4-0.8-0.8-1.1-1.2c-0.3-0.5-0.5-0.9-0.7-1.5
									c0.4,0.4,0.8,0.7,1.2,1.1c0.4,0.3,0.8,0.7,1.2,1s0.8,0.6,1.2,0.9C200.3,72.6,200.7,72.9,201.2,73.2z"/>
					<path class="st2" d="M203.7,59.6c1.1-0.4,2.4,0,3.3,0.8c0.4,0.4,0.8,0.9,1.1,1.4s0.5,1,0.5,1.6l-1.1-1.2
									c-0.4-0.4-0.7-0.7-1.1-1c-0.4-0.3-0.8-0.6-1.2-0.8C204.7,60,204.2,59.8,203.7,59.6z"/>
					<path class="st2" d="M204.8,58c1.1-0.3,2.3,0.2,3.2,0.9c0.4,0.4,0.8,0.8,1.1,1.3s0.5,1,0.6,1.5c-0.4-0.4-0.8-0.8-1.1-1.1
									c-0.4-0.4-0.7-0.7-1.1-1c-0.4-0.3-0.8-0.6-1.2-0.8C205.7,58.5,205.3,58.3,204.8,58z"/>
					<path class="st2" d="M205.8,56.4c0.6-0.1,1.1,0,1.6,0.2s1,0.5,1.4,0.9s0.8,0.8,1.1,1.2c0.3,0.5,0.5,0.9,0.7,1.5
									c-0.4-0.3-0.8-0.7-1.2-1s-0.8-0.7-1.2-1s-0.8-0.6-1.2-0.9C206.7,57,206.3,56.8,205.8,56.4z"/>
					<path class="st2" d="M206.9,54.8c1.1-0.2,2.2,0.3,3.1,1c0.4,0.4,0.8,0.8,1.1,1.2c0.3,0.5,0.5,0.9,0.7,1.5
									c-0.4-0.4-0.8-0.7-1.2-1.1c-0.4-0.3-0.8-0.7-1.2-1s-0.8-0.6-1.2-0.9C207.8,55.4,207.4,55.2,206.9,54.8z"/>
				</g>
				<g>
					<path class="st13" d="M239.3,35.6c-2.1,0.6-3.4,2.1-4.9,3.4c-1.5,1.3-2.9,2.7-4.3,4.1c-2.7,2.7-5.4,5.4-8.1,8.2l-6.8,4.6
									c-2.5,1.7-4.9,3.4-7.3,5.1c-1.4,0.6-2.9,1.1-4.3,1.7c-1.8,0.7-3.5,1.4-5.3,2.2c-1.8,0.8-2.9,2.8-2.5,4.8
									c0.5,2.3,2.1,2.6,4.3,2.7c2.3,0.1,4.4,0,6.3-0.4c1.9-0.4,3.7-0.8,5.6-1.2c0.2,0,0.3-0.1,0.5-0.2l0.7-0.3
									c2.7-1.4,5.4-2.8,8.1-4.2l8.1-4.3c0.2-0.1,0.4-0.3,0.6-0.4l0.4-0.4c3-2.6,6-5.2,8.9-7.9c1.5-1.3,2.9-2.7,4.3-4.1
									c1.4-1.4,3-2.6,3.7-4.7"/>
					<path class="st2" d="M239.3,35.6c-1.8,0.8-3,2.3-4.4,3.7s-2.8,2.7-4.2,4l-8.2,8.4l0,0l0,0l-7.1,4.8l-7,4.9h-0.1h-0.1
									c-3,1.2-6.1,2.4-9.1,3.7c-0.4,0.2-0.7,0.3-1,0.5s-0.6,0.4-0.8,0.7c-0.5,0.5-0.8,1.2-0.9,1.9c-0.1,0.7,0,1.4,0.3,2
									s0.7,0.9,1.4,1.1s1.5,0.2,2.3,0.2s1.6,0,2.4,0c1.6,0,3.1-0.3,4.7-0.7l2.4-0.5l1.2-0.3l0.6-0.1c0.2,0,0.3-0.1,0.4-0.1
									l4.4-2.2c2.9-1.5,5.8-3,8.7-4.6l2.2-1.2l1.1-0.6L229,61l0.4-0.3c1.2-1,2.5-2.1,3.7-3.2l3.7-3.2c1.2-1.1,2.5-2.2,3.6-3.3
									c1.2-1.1,2.4-2.2,3.6-3.3c1.2-1.1,2.4-2.1,3.2-3.6c-0.2,1.7-1.4,3.1-2.6,4.3c-1.1,1.2-2.3,2.3-3.5,3.5s-2.4,2.3-3.6,3.3
									l-3.7,3.3c-1.2,1.1-2.4,2.2-3.7,3.3l-0.6,0.4l-0.5,0.3l-1.1,0.6l-2.2,1.2c-2.9,1.6-5.8,3.1-8.7,4.6l-4.4,2.3
									c-0.2,0.1-0.5,0.2-0.7,0.3l-0.6,0.1l-1.2,0.3l-2.4,0.5c-0.8,0.2-1.6,0.4-2.5,0.5c-0.8,0.1-1.7,0.2-2.5,0.2s-1.7,0-2.5,0
									s-1.7,0-2.6-0.3c-0.5-0.1-0.9-0.3-1.4-0.6c-0.4-0.3-0.7-0.8-0.9-1.2c-0.4-0.9-0.5-1.8-0.4-2.8c0.2-0.9,0.6-1.8,1.2-2.5
									c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.3,0.8-0.4,1.2-0.6c3-1.2,6.1-2.5,9.2-3.7l-0.1,0.1l7-4.8l7.1-4.7l-0.1,0.1l8.3-8.3
									c1.4-1.3,2.9-2.7,4.3-4c0.7-0.6,1.5-1.3,2.3-1.9C237.4,36.1,238.3,35.7,239.3,35.6z"/>
				</g>
			</g>
		</g>

	)
}