import React from 'react'

export const Anus = (props) => {
    return (
        <g id="Anus_1_" className={"anus-target"}>
            <g>
                <path d="M166.03,177.28c-0.17-4.12,0.24-8.22,0.57-12.29c0.18-2.04,0.35-4.07,0.51-6.11c0.13-2.04,0.27-4.07,0.21-6.13
        c0.44,2.01,0.57,4.1,0.57,6.15c0.01,2.06-0.12,4.12-0.29,6.17c-0.19,2.05-0.41,4.08-0.69,6.12
        C166.65,173.22,166.33,175.24,166.03,177.28z"/>
            </g>
            <g>
                <path d="M174.79,174.32c-1.15-1.54-2.6-2.77-3.98-4.07l-4.26-3.8c-1.41-1.28-2.83-2.56-4.2-3.9c-0.69-0.67-1.36-1.35-2-2.07
        c-0.6-0.74-1.33-1.44-1.65-2.36c0.52,0.8,1.28,1.39,2,2.01c0.72,0.62,1.46,1.23,2.18,1.84c1.46,1.22,2.91,2.46,4.34,3.74
        c1.41,1.29,2.82,2.59,4.14,3.99C172.65,171.09,173.97,172.57,174.79,174.32z"/>
            </g>
            <g>
                <path d="M159.57,167.97c1.17-0.17,2.24-0.63,3.35-1l3.31-1.16c1.11-0.37,2.23-0.74,3.37-1.05c0.57-0.16,1.14-0.3,1.72-0.43
        c0.29-0.06,0.58-0.12,0.88-0.16c0.29-0.02,0.61-0.12,0.9,0c-0.56,0.08-1.09,0.41-1.62,0.63l-1.62,0.7
        c-1.08,0.46-2.19,0.87-3.3,1.26c-1.12,0.37-2.25,0.72-3.42,0.96C161.98,167.95,160.76,168.22,159.57,167.97z"/>
            </g>
        </g>
    )
}
