import React from 'react'
import { useSelector } from 'react-redux'

export const SurgerieGlans = (props) => {

    return (
        <g id="surgerieGlans" className="transition" style={{ opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(0px, -15px)' }}>
            <g>
                <path class="st37" d="M168.3,108.6l4.2-6.6l-5.4-11.2l-5.7,11.9l4.2,6C166.3,109.8,167.7,109.7,168.3,108.6z" />
            </g>
        </g>

    )
}