import React from 'react'
import { useSelector } from 'react-redux'

export const Suture3b = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Suture3b" className="st4 transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
        <path className="st2" d="M159.9,77.1c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        c-1.2-0.2-2.5-0.3-3.7-0.3C164.7,76.1,162.2,76.4,159.9,77.1z"/>
        <path className="st2" d="M159.9,80c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        c-1.2-0.2-2.4-0.3-3.7-0.3C164.7,78.9,162.2,79.2,159.9,80z"/>
        <path className="st2" d="M159.9,82.8c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        c-1.2-0.2-2.4-0.3-3.7-0.3C164.7,81.7,162.2,82.1,159.9,82.8z"/>
        <path className="st2" d="M159.9,85.6c2.3-1,4.7-1.6,7.2-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        c-1.2-0.2-2.4-0.3-3.7-0.3C164.7,84.6,162.2,84.9,159.9,85.6z"/>
        <path className="st2" d="M159.9,88.5c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        C167.1,87.1,163.4,87.4,159.9,88.5z"/>
        <path className="st2" d="M159.9,91.3c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        c-1.2-0.2-2.4-0.3-3.7-0.3C164.7,90.2,162.2,90.6,159.9,91.3z"/>
        <path className="st2" d="M159.9,94.1c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        C167.1,92.8,163.4,93,159.9,94.1z"/>
        <path className="st2" d="M159.9,97c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        c-1.2-0.2-2.4-0.3-3.7-0.3C164.7,95.9,162.2,96.3,159.9,97z"/>
        <path className="st2" d="M159.9,99.8c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        C167.1,98.4,163.4,98.7,159.9,99.8z"/>
        <path className="st2" d="M159.9,102.6c3.4-1.6,7.3-2,11-1.2c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        c-1.2-0.2-2.4-0.3-3.7-0.3C164.7,101.6,162.2,101.9,159.9,102.6z"/>
        <path className="st2" d="M159.9,105.5c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2
        c-1.2-0.3-2.4-0.6-3.6-0.8C167.1,104.1,163.4,104.4,159.9,105.5z"/>
        <path className="st2" d="M159.9,108.3c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2
        c-1.2-0.3-2.4-0.6-3.6-0.8c-1.2-0.2-2.4-0.3-3.7-0.3C164.7,107.2,162.2,107.6,159.9,108.3z"/>
        <path className="st2" d="M159.9,111.1c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2
        c-1.2-0.3-2.4-0.6-3.6-0.8c-1.2-0.2-2.5-0.3-3.7-0.3C164.7,110.1,162.2,110.4,159.9,111.1z"/>
        <path className="st2" d="M159.9,114c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2c-1.2-0.3-2.4-0.6-3.6-0.8
        c-1.2-0.2-2.4-0.3-3.7-0.3C164.7,112.9,162.2,113.3,159.9,114z"/>
        <path className="st2" d="M159.9,116.8c2.3-1,4.8-1.6,7.3-1.6c1.3,0,2.5,0.1,3.7,0.4c1.2,0.3,2.4,0.7,3.5,1.2
        c-1.2-0.3-2.4-0.6-3.6-0.8c-1.2-0.2-2.4-0.3-3.7-0.3C164.7,115.7,162.2,116.1,159.9,116.8z"/>
    </g>
    )
}