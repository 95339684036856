import React from 'react'
import { useSelector } from 'react-redux'

export const Skin = (props) => {
    const r_skin_color = useSelector(state => state.parameters.skinColor)
    const r_skin_opacity = useSelector(state => state.parameters.skinOpacity)

    return (
        <path fill={r_skin_color} style={{ opacity: r_skin_opacity }} id="Skin" className="st--3 transition" d="M166.7,189.1c0,2.5,1.4,5.2,4,7.8c0.3,0.4,0.7,0.7,1.1,1.1s0.8,0.7,1.2,1s0.9,0.7,1.3,1
	c1.8,1.3,3.7,2.5,5.7,3.5c3.3,1.7,6.7,3.2,10.3,4.5c13.4,4.5,27.5,6.4,41.6,5.7c2.3-0.1,4.6-0.3,7-0.5l2.8-0.3l1.4-0.2
	c2.4-0.3,4.8-0.7,7.2-1.2l2.9-0.6c1.5-0.3,2.9-0.7,4.4-1.1l1.3-0.3l0.3-0.1l1.3-0.4c0.5-0.2,1-0.3,1.5-0.5c1.5-0.5,3-0.9,4.5-1.5
	l1.5-0.5c1.5-0.5,3-1.1,4.5-1.7l0,0c21.5-8.9,42.9-25,60.8-51.9c0-38,0-130.6-60.8-152.8c-6.9,6.2-30.8,27.5-48.8,49
	c-15.5-6.2-44.5-9-57.1-9s-41.7,2.7-57.1,9C91.7,27.4,67.7,6.2,60.8-0.1C3.3,34.4,0,114.8,0,152.8c17.9,26.9,39.4,43,60.8,51.9l0,0
	c1.5,0.6,3,1.2,4.5,1.7l1.5,0.5c1.5,0.5,3,1,4.5,1.5c0.5,0.2,1,0.3,1.5,0.5l1.3,0.4l0.3,0.1l1.3,0.3c1.5,0.4,2.9,0.8,4.4,1.1
	l2.9,0.6c2.4,0.5,4.8,0.9,7.2,1.2l1.4,0.2l2.8,0.3c2.4,0.2,4.7,0.4,7,0.5c14.1,0.7,28.2-1.2,41.6-5.7c3.5-1.2,7-2.7,10.3-4.5
	c2-1,3.8-2.2,5.7-3.5c0.5-0.3,0.9-0.7,1.3-1s0.8-0.7,1.2-1s0.8-0.7,1.1-1.1C165.3,194.2,166.7,191.5,166.7,189.1z"/>
    )
}