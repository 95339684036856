import React from 'react'
import { useSelector } from 'react-redux'

export const SutureLabia = (props) => {

	return (
		<g id="suture" className="transition" style={{ opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(1px, -21px)' }}>
			<path class="st12" d="M107.4,158.2c-4.9-1.8-8.5-8.7-7.8-11.1s-14.4,8.8-14.4,8.8l13.2,11.8L107.4,158.2z" />
			<g>
				<path class="st13" d="M73.9,135.9l2.8-7.3l1.4-3.7l0.7-1.8c0.2-0.6,0.5-1.2,0.7-1.9l0.2-1c0-0.1,0-0.2,0-0.3
								c-0.1-1.8-0.2-3.7-0.4-5.5l2.9-1.9l9.8,1.1c1.3,0.1,2.6-0.6,3.1-1.9c0.6-1.6-0.1-3.4-1.7-4.1l-11.5-5
								c-1.2-0.5-2.5-0.4-3.6,0.4l-0.4,0.3l-6.2,4.6c-0.9,0.6-1.5,1.6-1.8,2.7l-0.2,0.8c-0.6,2.5-1.1,5-1.6,7.4l0.3-1.4
								c-0.3,0.6-0.4,1.3-0.6,1.9l-0.5,1.9l-1,3.8l-3.2,7.8c-0.3,1,0.1,2-0.1,2.9c-0.4,3.2-6.2,7,2.4,21.8s20.4,12.7,20.4,12.7
								l13.9,3.4h0.1c2.2,0.5,4.7-0.3,6.1-2.2l11.4-15.6l8.1-5c1.8-1.1,2.6-3.5,1.7-5.5c-1-2.1-3.6-3-5.8-2.2l-10.1,4
								c-0.5,0.2-0.9,0.5-1.3,0.9l-0.5,0.5l-4.8,5.4c0,0-3.4,4-4.7,5.8"/>
				<path class="st2" d="M73.5,135.7l4-10.6l1-2.7c0.2-0.4,0.4-0.9,0.5-1.3l0.2-0.7c0-0.2,0.1-0.5,0.1-0.6l-0.4-5.6v-0.3l0.2-0.2
								l2.9-1.9l0.2-0.1h0.2l6.8,0.7l1.7,0.2l0.9,0.1h0.4c0.1,0,0.2,0,0.4,0c1,0,1.9-0.6,2.2-1.5c0.4-0.9,0.2-2-0.4-2.7
								c-0.3-0.4-0.7-0.6-1.2-0.8l-1.6-0.7l-6.3-2.8l-3.2-1.4c-1-0.5-2-0.5-2.9-0.1c-0.4,0.2-0.9,0.6-1.3,0.9l-1.4,1l-2.8,2l-1.4,1
								c-0.5,0.3-0.8,0.6-1.1,1.1c-0.3,0.4-0.5,0.9-0.7,1.4c-0.1,0.5-0.3,1.1-0.4,1.7l-1.5,6.7l-1-0.2l0.3-1.3l0,0l1,0.3
								c-0.2,0.5-0.3,1.2-0.5,1.8L68,121l-0.9,3.8l0,0v0.1c-1,2.6-2,5.2-3,7.8v-0.1c-0.1,0.3-0.1,0.7,0,1.3c0,0.5,0.1,1.2,0,1.9
								c-0.3,1.3-0.9,2.2-1.2,3.2c-0.8,1.9-1,3.8-0.8,5.8s0.8,3.9,1.6,5.8c0.8,1.9,1.8,3.8,2.8,5.6c2,3.6,4.8,6.8,8.1,9.1
								c1.7,1.1,3.5,2,5.4,2.6c0.5,0.1,1,0.3,1.4,0.4c0.5,0.1,1,0.2,1.5,0.2c1,0.1,2,0.1,2.9,0h0.3l0.2,0.1l9.9,2.5l2.5,0.6
								c0.8,0.2,1.7,0.5,2.3,0.6c1.4,0.2,2.8-0.3,3.8-1.3c0.3-0.3,0.5-0.5,0.7-0.8l0.8-1l1.5-2l3.1-4c2.1-2.7,4.1-5.4,6.2-8l0,0
								l0.1-0.1h0.1l5.4-3.3l2.7-1.7c0.8-0.5,1.4-1.2,1.7-2.1c0.3-0.9,0.2-1.9-0.1-2.7c-0.4-0.8-1.1-1.5-1.9-1.9s-1.8-0.5-2.7-0.2
								c-0.4,0.1-0.9,0.3-1.4,0.5l-1.5,0.6l-5.9,2.3l-1.5,0.6c-0.5,0.2-0.9,0.4-1.3,0.7c-0.7,0.7-1.4,1.5-2.1,2.3
								c-1.4,1.6-2.9,3.1-4.2,4.7c-1.4,1.6-2.8,3.2-4.2,4.7c1.2-1.7,2.4-3.4,3.8-5.1c0.6-0.8,1.3-1.6,2-2.4l2.1-2.4l1-1.2
								c0.4-0.4,0.6-0.8,1.1-1.2c0.4-0.4,1-0.7,1.5-0.8l1.5-0.6l5.9-2.3l1.5-0.6c0.5-0.2,0.9-0.4,1.5-0.6c1.1-0.3,2.4-0.2,3.4,0.3
								c1.1,0.5,2,1.4,2.5,2.4c0.5,1.1,0.5,2.3,0.2,3.5c-0.4,1.1-1.1,2.1-2.2,2.7l-2.7,1.7l-5.4,3.3l0.1-0.1l0,0
								c-1.9,2.8-3.8,5.6-5.8,8.4l-2.9,4.2l-1.5,2.1l-0.7,1c-0.2,0.3-0.6,0.8-0.9,1.1c-1.4,1.4-3.5,2.2-5.5,2
								c-1.1-0.1-1.8-0.4-2.7-0.5l-2.5-0.6l-9.9-2.3h0.5c-1.3,0.2-2.4,0.1-3.6,0c-0.6-0.1-1.1-0.1-1.7-0.3c-0.6-0.1-1.1-0.2-1.7-0.4
								c-2.2-0.6-4.3-1.7-6.1-2.9c-3.7-2.6-6.6-6.2-8.8-10c-1.1-1.9-2.1-3.9-2.9-5.9c-0.8-2.1-1.5-4.2-1.6-6.5
								c-0.2-2.3,0.2-4.7,1.2-6.8c0.4-1,1-2,1.2-2.8c0.1-0.4,0.1-0.8,0.1-1.3s-0.1-1.2,0.1-1.9v-0.1l0,0c1.1-2.6,2.2-5.1,3.3-7.7
								v0.1l1.1-3.8l0.6-1.9c0.2-0.6,0.3-1.3,0.6-1.9l1.9-4.1l-0.9,4.4l0,0l-0.3,1.3l-1-0.2l1.5-6.7c0.1-0.6,0.2-1.1,0.4-1.7
								c0.2-0.6,0.5-1.2,0.8-1.7c0.4-0.5,0.9-1,1.4-1.3l1.4-1l2.8-2l1.4-1c0.5-0.3,0.9-0.7,1.5-1c1.2-0.6,2.7-0.5,3.7,0.1l3.2,1.4
								l6.3,2.8l1.6,0.7c0.6,0.2,1.2,0.6,1.6,1.1c0.8,1,1.1,2.5,0.6,3.8c-0.5,1.2-1.8,2.1-3.1,2.2c-0.2,0-0.3,0-0.5,0h-0.4l-0.9-0.1
								l-1.7-0.2l-6.8-0.7l0.3-0.1l-2.9,1.9l0.2-0.5c0.1,1.9,0.3,3.7,0.4,5.7c0,0.4-0.1,0.5-0.1,0.8l-0.2,0.7
								c-0.1,0.5-0.3,1-0.5,1.4l-1,2.6l-4,10.6L73.5,135.7z"/>
			</g>
			<g>
				<path class="st13" d="M77,142.2l9.3-11.3l10.5-5.7l10.2,2.9l2.1,4.8c0.2,0.5,0.7,0.9,1.3,0.9c0.9,0.1,1.6-0.6,1.7-1.4
								l0.6-7.7c0.1-0.9-0.4-1.8-1.2-2.2l-0.9-0.4L98,115.9c-0.7-0.3-1.6-0.5-2.4-0.2l-1,0.3l-15,3.9c-0.7,0.2-1.4,0.6-2,1.2
								l-0.6,0.6l-10.5,11.5c-0.1,0.1-0.2,0.2-0.3,0.3c-1.2,1.5-1.7,3.3-1.5,5.1"/>
				<path class="st2" d="M76.6,141.9l9.3-11.3l0.1-0.1h0.1l10.5-5.7l0.2-0.1l0.2,0.1l10.2,2.9l0.2,0.1l0.1,0.2l0,0l2,4.5l0.1,0.3
								c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.5,0.2,0.8,0.1c0.3-0.1,0.5-0.2,0.6-0.4c0.2-0.2,0.2-0.4,0.2-0.9
								l0.3-4.9l0.2-2.4c0-0.6-0.3-1.2-0.8-1.5l-8.8-4.3l-4.4-2.1c-0.4-0.2-0.7-0.3-1-0.4s-0.7-0.1-1-0.1s-0.7,0.2-1.1,0.3l-1.2,0.3
								l-9.5,2.4l-4.7,1.2c-0.7,0.2-1.3,0.6-1.8,1.2l-1.7,1.8l-6.6,7.2c-0.6,0.6-1.1,1.2-1.7,1.8s-1.2,1.1-1.6,1.8
								c-0.9,1.2-1.5,2.8-1.5,4.4c-0.3-1.6,0-3.4,0.9-4.8c0.5-0.7,1-1.3,1.6-1.9c0.5-0.6,1.1-1.2,1.6-1.8l6.6-7.3l1.6-1.8
								c0.5-0.7,1.4-1.3,2.3-1.5l4.7-1.3l9.5-2.5l1.2-0.3c0.4-0.1,0.8-0.2,1.3-0.3c0.5-0.1,1,0,1.4,0.1c0.5,0.1,0.9,0.3,1.2,0.5
								l4.4,2.2l8.8,4.3c0.9,0.5,1.5,1.5,1.4,2.6l-0.2,2.5l-0.4,4.9c0,0.2,0,0.4-0.1,0.7s-0.2,0.5-0.3,0.8c-0.3,0.5-0.8,0.8-1.3,0.9
								c-0.5,0.1-1.1,0-1.6-0.3c-0.2-0.2-0.4-0.3-0.6-0.6c-0.1-0.1-0.1-0.2-0.2-0.4l-0.1-0.3l-2-4.5l0,0l0.3,0.3l-10.2-2.9h0.4
								l-10.5,5.7l0.1-0.1L77.2,143L76.6,141.9z"/>
			</g>
			<g>
				<path class="st13" d="M83.8,149.9l6.8-7.3l14.6-6.4l11.7-0.3l0.9,1.5c0.4,0.7,1.2,1.2,2.1,1.3c1.4,0.1,2.6-1,2.7-2.5l0.2-5.3
								c0.1-1.2-0.8-2.4-2-2.7l-1.5-0.4l-15.1-3.6c-0.7-0.2-1.4-0.1-2.1,0.1l-0.8,0.3l-17.4,6.2c-0.8,0.3-1.6,0.8-2.3,1.5l-0.1,0.2
								l-7.7,8.3l0,0c-1.1,1.1-1.6,2.6-1.8,4"/>
				<path class="st2" d="M83.4,149.5l6.7-7.3l0.1-0.1h0.1l14.6-6.5h0.1h0.1l11.7-0.3h0.4l0.2,0.3l0.6,1l0.3,0.5
								c0.1,0.1,0.2,0.3,0.3,0.4c0.4,0.4,1,0.7,1.6,0.6c0.6,0,1.2-0.4,1.5-0.8c0.2-0.2,0.3-0.5,0.3-0.8v-0.2V136v-0.6l0.2-4.6
								c0-0.6-0.3-1.2-0.8-1.5c-0.2-0.2-0.5-0.3-0.8-0.4l-1.1-0.3l-9.1-2.2l-4.5-1.1l-1.1-0.3l-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1
								c-0.7-0.1-1.2,0.1-2,0.4l-17.5,6.3c-0.7,0.2-1.3,0.6-1.8,1.1s-1.1,1.1-1.6,1.7l-3.2,3.4l-3.2,3.4c-1,1.1-1.7,2.3-2.3,3.8
								c-0.3-1.6,0.4-3.3,1.5-4.5l3.2-3.4l3.2-3.4c0.5-0.6,1-1.1,1.6-1.7c0.6-0.6,1.3-1,2.1-1.3l17.5-6.4c0.4-0.1,0.7-0.3,1.2-0.4
								c0.4-0.1,0.9-0.1,1.3-0.1c0.2,0,0.5,0.1,0.7,0.1l0.6,0.1l1.1,0.3l4.5,1.1l9.1,2.2l1.1,0.3c0.4,0.1,0.9,0.3,1.3,0.6
								c0.8,0.6,1.3,1.5,1.3,2.5l-0.2,4.7v0.6v0.3v0.4c-0.1,0.5-0.3,0.9-0.5,1.3c-0.5,0.8-1.5,1.3-2.4,1.4c-1,0.1-1.9-0.3-2.6-1
								c-0.2-0.2-0.3-0.4-0.4-0.6l-0.3-0.5l-0.6-1l0.6,0.3l-11.7,0.3l0.2-0.1l-14.7,6.4l0.2-0.1l-6.8,7.2L83.4,149.5z"/>
			</g>
			<g>
				<path class="st2" d="M245.1,134.9c-1.3-1.5-3.1-2.6-5.1-2.9c-2-0.4-4.1-0.2-6.1,0.4c-2,0.6-3.8,1.7-5.4,3
								c-1.6,1.3-3.1,2.8-4.5,4.4c-5.4,6.5-11.2,12.8-18,17.8c-6.7,5.2-14.2,9.3-22.2,12.3c-4,1.4-8.1,2.6-12.2,3.4
								c-1,0.2-2.1,0.3-3.1,0.5l-1.6,0.2c-0.5,0.1-1.1,0.1-1.6,0.2l-3.2,0.3l-3.2,0.1c-4.2,0.1-8.5,0-12.7-0.6s-8.3-1.9-12.1-3.9
								c-3.7-2-6.9-5-9.4-8.4c-0.6-0.9-1.2-1.8-1.7-2.7s-1-1.9-1.4-2.9c-0.8-2-1.2-4.1-1-6.3c0.2-2.1,0.9-4.2,2.2-6
								c1.2-1.8,2.9-3.2,4.7-4.3c1.8-1.1,3.8-2,5.9-2.5c2.1-0.5,4.3-0.7,6.4-0.1c2.1,0.7,3.9,1.8,5.7,3s3.4,2.6,4.8,4.3
								c1.4,1.6,2.8,3.4,3.3,5.6c0.3,1.1,0.2,2.3-0.2,3.4c-0.4,1.1-1.3,1.9-2.3,2.4c0.7-0.8,1.2-1.7,1.4-2.7
								c0.2-0.9,0.1-1.9-0.1-2.9c-0.5-1.9-1.5-3.8-2.8-5.4c-1.3-1.6-2.8-3.1-4.5-4.3c-1.7-1.3-3.5-2.4-5.4-3.1c-2-0.6-4.1-0.4-6.2,0
								c-2,0.5-4,1.3-5.8,2.4c-1.8,1.1-3.4,2.5-4.6,4.2s-2,3.7-2.1,5.8s0.3,4.2,1,6.1c0.4,1,0.9,1.9,1.4,2.8
								c0.5,0.9,1.2,1.8,1.7,2.6c2.5,3.4,5.6,6.3,9.3,8.4c3.7,2,7.8,3.3,11.9,3.9c4.2,0.6,8.4,0.7,12.6,0.6l3.2-0.1l3.2-0.3
								c0.5-0.1,1.1-0.1,1.6-0.2l1.6-0.2c1-0.2,2.1-0.3,3.1-0.5c4.1-0.8,8.2-2,12.2-3.4c7.9-3,15.4-7.1,22.1-12.2l1.3-0.9l1.2-1
								l2.4-2c1.5-1.4,3.2-2.8,4.6-4.4c3-3,5.6-6.3,8.4-9.5c1.4-1.6,2.8-3.2,4.5-4.5c1.6-1.4,3.5-2.5,5.6-3.2s4.3-0.9,6.4-0.6
								c2.2,0.3,4.3,1.4,5.8,3.1L245.1,134.9z"/>
			</g>
			<g>
				<path class="st29" d="M264.3,207.2l35.3,8.9c0,0,25.8,19.9,29.9,25.4s5.7,29.2,0.2,36.5c-5.5,7.4-16.7,20.6-29.5,17.1
								c-12.7-3.4-25.7-7.5-25.7-7.5s-7.9,1.3-10-0.4c-2.1-1.7-10.6-5.9-10.8-10.9S264.3,207.2,264.3,207.2z"/>
				<g>
					<path class="st13" d="M287.1,277.4c0-3.2-2.6-5.7-5.7-5.7h-13l-4.2-1.6l-0.5-3.7l0,0c-0.5-3.6-3.7-6.1-7.3-5.6
									c-3.6,0.5-6.1,3.7-5.6,7.3l1,7.6v0.3c0.3,2.3,2,4.4,4.4,5l9.2,2.5l0.5,0.1c0.5,0.1,1,0.2,1.5,0.2l14.2-0.7
									C284.7,283,287.1,280.5,287.1,277.4z"/>
					<path class="st2" d="M286.7,277.4c0-1.7-0.8-3.4-2.2-4.4c-0.7-0.5-1.5-0.8-2.4-0.9c-0.8-0.1-1.8,0-2.7-0.1
									c-3.7,0-7.3-0.1-11-0.1l0,0l0,0l0,0l-4.2-1.6l0,0h-0.1v-0.1c-0.2-1.5-0.3-3.1-0.6-4.6c-0.4-1.5-1.3-2.8-2.6-3.6
									c-1.3-0.8-2.8-1.2-4.3-1s-2.9,0.9-3.9,2s-1.6,2.6-1.6,4c0,0.4,0,0.7,0.1,1.1l0.2,1.1l0.4,2.3l0.7,4.6c0.2,1.4,1,2.7,2.1,3.5
									c0.6,0.4,1.2,0.7,1.9,0.9l2.2,0.6l4.5,1.2l2.2,0.6c0.8,0.2,1.4,0.4,2.1,0.4l4.6-0.2l9.2-0.4c1.5,0,2.9-0.5,3.9-1.5
									C286.1,280.2,286.7,278.8,286.7,277.4z M287.6,277.4c0,1.7-0.7,3.3-1.9,4.5c-0.6,0.6-1.3,1-2.1,1.3
									c-0.8,0.3-1.7,0.4-2.4,0.4l-9.2,0.5l-4.6,0.2c-0.4,0-0.9,0-1.3-0.1s-0.8-0.2-1.1-0.3l-2.2-0.6l-4.5-1.2l-2.2-0.6
									c-0.8-0.2-1.6-0.6-2.2-1.1c-1.3-1-2.2-2.6-2.4-4.2l-0.6-4.6l-0.3-2.3l-0.1-1.1c0-0.4-0.1-0.8,0-1.2c0.1-1.6,0.8-3.2,1.9-4.3
									c1.1-1.1,2.6-1.8,4.2-2s3.2,0.3,4.4,1.2c1.3,0.9,2.2,2.2,2.6,3.7c0.2,0.8,0.3,1.5,0.4,2.3l0.3,2.3l-0.1-0.1l0,0l4.2,1.5
									h-0.1l0,0c3.7-0.1,7.3-0.1,11-0.2c0.9,0,1.8-0.1,2.8,0s1.9,0.5,2.7,1C286.5,273.5,287.5,275.4,287.6,277.4z"/>
				</g>
				<g>
					<path class="st13" d="M290.7,275.3c1.5-3.6-0.2-7.8-3.8-9.3c-2.3-0.9-4.5-1.9-6.8-2.7c-2-0.8-4-1.5-6.1-2.3
									c-2.9-1.5-5.7-2.9-8.6-4.4c-0.6-0.3-1.1-0.6-1.7-0.8l0.8-1.8c1.3-2.9,0.5-6.5-2.2-8.5c-3.1-2.3-7.5-1.7-9.9,1.4l-6.5,8.6
									c-0.3,0.4-0.6,0.8-0.8,1.3c-1.8,3.7-0.2,8.2,3.5,10l0.8,0.4c3,1.4,5.9,2.8,8.9,4.2c3,1.4,6,2.7,9,4.1l0.5,0.2
									c0.2,0.1,0.3,0.1,0.5,0.2c2.4,0.6,4.7,1.3,7.1,1.9s4.7,1.2,7.1,1.8C285.9,280.3,289.4,278.6,290.7,275.3z"/>
					<path class="st2" d="M290.4,275.1c0.8-1.8,0.7-3.9-0.2-5.7c-0.5-0.9-1.1-1.7-1.9-2.3c-0.8-0.6-1.7-1-2.7-1.4l-5.8-2.4
									L274,261l0,0l0,0l-5.1-2.7c-1.7-0.9-3.4-1.8-5.1-2.6l0,0l-0.1-0.1v-0.1c0.4-0.9,0.8-1.8,1.1-2.7c0.3-0.9,0.3-1.9,0.2-2.8
									c-0.3-1.9-1.3-3.6-2.9-4.6c-1.5-1.1-3.5-1.4-5.3-1c-1.8,0.4-3.4,1.5-4.4,3.1l-3.5,4.7l-1.7,2.3c-0.3,0.4-0.6,0.8-0.9,1.2
									c-0.2,0.4-0.5,0.8-0.7,1.2c-0.8,1.7-0.9,3.6-0.2,5.3c0.6,1.7,1.9,3.2,3.5,3.9l10.5,4.9l5.3,2.4l2.6,1.2
									c0.4,0.2,0.9,0.4,1.2,0.5l1.4,0.4l5.6,1.5l5.6,1.5l1.4,0.4c0.5,0.1,0.9,0.2,1.3,0.2c0.9,0,1.8-0.1,2.7-0.4
									C288.2,278.2,289.6,276.8,290.4,275.1z M291.1,275.4c-0.8,1.9-2.3,3.5-4.3,4.2c-1,0.4-2,0.5-3,0.5c-0.5,0-1-0.1-1.5-0.2
									l-1.4-0.3l-5.7-1.4l-5.6-1.5l-1.4-0.4c-0.6-0.2-1-0.4-1.4-0.6l-2.6-1.2l-5.3-2.4l-10.5-5c-1.9-0.9-3.3-2.6-4-4.5
									s-0.6-4.1,0.3-6c0.2-0.4,0.5-0.9,0.8-1.3c0.3-0.4,0.6-0.8,0.9-1.2l1.8-2.3l3.5-4.6c0.5-0.8,1.2-1.6,2.1-2.2
									c0.8-0.6,1.8-1,2.8-1.2c2-0.4,4.1,0.1,5.7,1.3s2.7,3,3,5c0.1,1,0,2-0.2,2.9c-0.3,1-0.8,1.8-1.1,2.7l-0.1-0.2l0,0
									c1.7,0.8,3.5,1.7,5.2,2.6l5.2,2.6l0,0l5.9,2.2l5.8,2.3c1,0.4,2,0.7,2.9,1.4c0.9,0.7,1.6,1.5,2.1,2.5
									C291.8,271,292,273.4,291.1,275.4z"/>
				</g>
				<g>
					<path class="st13" d="M269.1,234.8l-2.2,2l-1.5,1.4c-2.2-1.9-4.9-2.9-7.6-2.3c-5.1,1.1-8.1,7.2-6.7,13.7
									c0.4,2.1,1.3,3.9,2.4,5.5c-1.3-0.9-2.7-1.8-4-2.7c-2.4-1.6-4.7-3.2-7-4.3c-6.5-3.3-5-15.1,1.6-18c0.5-0.2,6.2-2.8,11.3-4.9
									c1.4-0.6,2.8-1.2,4-1.7c2.1-0.9,3.6-1.4,3.7-1.2"/>
					<path class="st2" d="M269.1,234.8c-1.2,1.2-2.4,2.4-3.6,3.6l-0.1,0.1l-0.1-0.1c-1.9-1.7-4.5-2.7-7-2.2
									c-2.5,0.4-4.6,2.2-5.8,4.4s-1.6,4.8-1.3,7.3s1.2,5,2.7,7l1.5,2l-2.1-1.4c-2.4-1.6-4.8-3.3-7.2-4.8c-1.2-0.8-2.4-1.5-3.7-2.1
									c-1.4-0.7-2.5-1.9-3.3-3.2s-1.1-2.9-1.2-4.4c-0.1-1.5,0.1-3,0.5-4.5c0.8-2.9,2.7-5.6,5.6-7l0,0l9.4-4.1
									c1.6-0.7,3.2-1.3,4.8-1.9c0.8-0.3,1.6-0.6,2.5-0.8c0.4-0.1,0.8-0.2,1.3-0.3c0.4-0.1,0.9-0.2,1.3-0.1c-0.2,0-0.4,0.1-0.6,0.2
									l-0.6,0.3l-1.1,0.6l-2.3,1.1c-1.5,0.7-3.1,1.4-4.7,2.1l-9.5,4.1l0,0c-2.4,1.1-4.1,3.6-4.9,6.2c-0.4,1.3-0.6,2.7-0.5,4.1
									c0.1,1.4,0.4,2.7,1,3.9s1.6,2.2,2.8,2.8c1.3,0.7,2.6,1.4,3.8,2.2c2.4,1.6,4.8,3.3,7.1,4.9l-0.6,0.6
									c-1.6-2.2-2.5-4.8-2.7-7.4c-0.3-2.6,0.2-5.4,1.5-7.7c0.6-1.2,1.5-2.2,2.6-3s2.3-1.4,3.7-1.6c1.3-0.2,2.7,0,3.9,0.4
									c1.3,0.5,2.4,1.2,3.4,2.1h-0.2C266.5,237,267.8,235.9,269.1,234.8z"/>
				</g>
				<g>
					<path class="st13" d="M283,212c-5.3-4-16.8-7.8-22.8-10.4c-6.1-1.7-12.6-2.6-18.9-2c-4.4,0.4-10.5-0.3-12.1,5.1
									c-1.1,3.5,0.8,7.2,4.3,8.3c9.7,2.3,17.7,7.5,26.6,10.6c2.5,0.9,9.8-4.9,10.3-6.7"/>
					<path class="st2" d="M282.6,212.4c-1.6-1.2-3.5-2.2-5.3-3.1c-1.9-0.9-3.8-1.7-5.7-2.5c-3.9-1.6-7.8-3-11.7-4.7h0.1
									c-3.5-1-7-1.7-10.6-2c-1.8-0.2-3.6-0.2-5.4-0.2c-0.9,0-1.8,0.1-2.7,0.1c-0.9,0.1-1.8,0.1-2.8,0.1c-1.8,0.1-3.6,0.2-5.2,0.7
									c-1.6,0.5-2.9,1.7-3.5,3.3c-0.7,1.6-0.6,3.4,0.1,4.9c0.7,1.5,2.1,2.7,3.7,3.2l0,0c3.4,0.8,6.7,2,9.9,3.4s6.3,2.9,9.4,4.3
									c1.6,0.7,3.1,1.4,4.7,2c0.8,0.3,1.6,0.6,2.4,0.9c0.7,0.3,1.5,0.1,2.2-0.2c1.6-0.6,3-1.5,4.4-2.5c0.7-0.5,1.4-1,2-1.6
									s1.3-1.1,1.7-1.9c-0.3,0.8-0.9,1.5-1.5,2.1s-1.3,1.2-1.9,1.8c-1.3,1.1-2.8,2.1-4.5,2.8c-0.4,0.2-0.9,0.3-1.3,0.4
									c-0.2,0-0.5,0.1-0.7,0c-0.3,0-0.6-0.1-0.7-0.2c-0.8-0.3-1.6-0.6-2.4-0.9c-1.6-0.6-3.2-1.3-4.8-2c-3.2-1.4-6.3-2.9-9.4-4.2
									c-3.2-1.3-6.4-2.5-9.7-3.3l0,0l0,0c-1.9-0.6-3.5-2-4.3-3.8c-0.9-1.8-0.9-3.9-0.2-5.7c0.6-1.9,2.3-3.4,4.1-4s3.7-0.7,5.5-0.8
									c0.9,0,1.8-0.1,2.7-0.2c0.9-0.1,1.8-0.1,2.8-0.2c1.8-0.1,3.7,0,5.5,0.2c3.7,0.3,7.3,1,10.9,2l0,0l0,0
									c3.8,1.6,7.8,3.1,11.6,4.7c1.9,0.8,3.9,1.7,5.8,2.6c1.9,0.9,3.8,1.9,5.5,3.2L282.6,212.4z"/>
				</g>
				<path class="st30" d="M261,259.6c-2.9-0.1-5.6-1.7-7.6-4.4c-1.1-1.5-2-3.4-2.4-5.5c-1.4-6.5,1.6-12.6,6.7-13.7
								c2.7-0.6,5.4,0.4,7.6,2.3c1.5,1.3,2.8,3.2,3.6,5.3c0.8,0.2,1.6,0.4,2.3,0.6c-0.5-1.4-1.2-2.8-2.3-4.3c-0.7-1-1.4-2-2.1-3
								c-2.9-4.2-5.5-8.7-7.5-13.3c-1.6-3.9-2.8-7.9-3-12c-0.5-8.1-2.5-13.9-1.3-15.4c1.2-1.6,8.9-8.2,7.8-9
								c-1.9-1.5-40.7-78.2-46.4-75.8l29.4,60l-3.3,3.1c0,0,8.9,22,10.6,35.9c1.1,8.9,2.1,12.4,2.2,15c0.1,1.5-0.2,2.7-0.8,4.5
								c-1.8,5-8.8,12.8-5.4,21.8c0.1,0.3,0.2,0.6,0.4,0.9c3.7,8.8,11.4,11.2,14.6,9.2c0.3-0.2,0.6-0.4,1-0.7
								C263.8,260.7,262.4,260.2,261,259.6z"/>
				<g>
					<path class="st13" d="M322,247.9c-7.9,8.9-16.5-1.8-22.8-9.8l0,0c-1.9-2.3-3.6-4.4-5-5.7c-0.6-0.8-1.2-1.7-1.7-2.7
									c0.5,0.8,1.1,1.6,1.8,2.3c4.5,4.8,11.3,5.8,15.1,2.3c2.2-2,2.9-5.1,2.3-8.2c0.8,0.7,1.7,1.5,2.7,2.3"/>
					<path class="st2" d="M322,247.9c-0.7,1-1.6,1.9-2.6,2.6c-1,0.7-2.3,1.1-3.6,1.2c-1.3,0.1-2.6-0.2-3.8-0.6
									c-1.2-0.5-2.3-1.1-3.3-1.9c-2-1.5-3.8-3.2-5.4-5c-1.7-1.8-3.2-3.7-4.7-5.6l-0.1-0.3c0,0.1,0,0.1,0.1,0.3l0.1,0.1
									c0,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.3-0.1,0.3-0.2,0.4-0.3h-1.2c-0.8-1-1.6-2-2.4-2.9s-1.6-1.9-2.5-2.7l0,0
									v-0.1c-0.7-0.9-1.3-1.9-1.8-2.9l1.4-0.8c0.6,1,1.3,1.9,2.1,2.6c0.8,0.8,1.7,1.5,2.7,2.1c1.9,1.2,4.2,1.8,6.4,1.7
									c1.1-0.1,2.2-0.4,3.1-0.9c1-0.5,1.8-1.3,2.4-2.2c1.3-1.8,1.7-4.2,1.4-6.5l-0.1-1l0.7,0.7c0.4,0.4,0.8,0.9,1.2,1.3
									c0.4,0.4,0.8,0.9,1.2,1.3c-0.5-0.3-1-0.7-1.5-1s-1-0.7-1.4-1l0.6-0.3c0.6,2.4,0.4,5.1-1,7.3c-0.7,1.1-1.7,2-2.8,2.7
									c-1.1,0.6-2.4,1-3.7,1.1c-2.6,0.2-5.2-0.5-7.3-1.8c-1.1-0.6-2.1-1.4-3-2.3c-0.9-0.9-1.7-1.9-2.4-2.9l1.4-0.8
									c0.5,0.9,1,1.8,1.6,2.6l-0.1-0.1c1,0.9,1.8,1.9,2.6,2.9c0.8,1,1.6,2,2.4,3h-1.2c0.1-0.1,0-0.1,0.4-0.3
									c0.2-0.1,0.4-0.1,0.6,0.1c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c0.1,0.2,0.1,0.2,0.1,0.3l-0.1-0.3c1.5,1.9,3,3.8,4.6,5.6
									s3.3,3.5,5.1,4.9c1.9,1.4,4,2.6,6.3,2.6C318.1,250.8,320.2,249.5,322,247.9z"/>
				</g>
				<g>
					<path class="st13" d="M285.2,261.7c-3.8,4.4-14.7,0.1-19.5-0.6c-0.2,0-0.3-0.1-0.5-0.1c-1.4-0.3-2.7-0.8-4.1-1.4
									c-2.6-1.1-5.1-2.7-7.6-4.4c-1.1-1.5-2-3.4-2.4-5.5c-1.4-6.5,1.6-12.6,6.7-13.7c2.7-0.6,5.4,0.4,7.6,2.3l-1.6,1.4l4.5,3.8
									c0.2,0,0.4,0.1,0.6,0.1c0.8,0.2,1.6,0.4,2.3,0.6c4.9,1.4,9.4,3.9,13.5,6.9C287.7,253.5,287.6,258.7,285.2,261.7z"/>
					<path class="st2" d="M285.6,262c-1,1.2-2.6,1.8-4,2c-1.5,0.2-2.9,0.1-4.4,0c-2.9-0.3-5.6-1-8.4-1.7
									c-0.7-0.2-1.4-0.3-2.1-0.5c-0.3-0.1-0.7-0.1-1-0.2s-0.7-0.1-1.1-0.2c-1.4-0.3-2.8-0.9-4.1-1.5c-2.6-1.2-5-2.8-7.4-4.3
									l-0.1-0.1l-0.1-0.1c-1.6-2.2-2.5-4.8-2.8-7.5s0.2-5.4,1.6-7.8c1.3-2.3,3.6-4.2,6.3-4.6c1.3-0.2,2.7-0.1,4,0.3
									c1.3,0.4,2.5,1.1,3.5,2l0.4,0.4l-0.4,0.4l-1.6,1.4v-0.8l4.5,3.8l-0.2-0.1c0.6,0.1,1.2,0.3,1.9,0.4c0.6,0.2,1.2,0.3,1.8,0.5
									c1.2,0.4,2.4,0.8,3.6,1.3c2.3,1,4.5,2.2,6.7,3.6c1.1,0.7,2.1,1.4,3.1,2.2c1,0.9,1.6,2.2,1.9,3.5
									C287.8,257.1,287.2,259.9,285.6,262z M284.8,261.4c1.4-1.8,2-4.4,1.5-6.6c-0.3-1.1-0.8-2.2-1.6-3c-0.9-0.7-2-1.4-3-2.1
									c-2.1-1.3-4.2-2.5-6.5-3.5c-1.1-0.5-2.3-0.9-3.5-1.3c-0.6-0.2-1.2-0.3-1.8-0.5c-0.6-0.1-1.2-0.3-1.8-0.4H268l-0.1-0.1
									l-4.5-3.8l-0.4-0.4l0.4-0.4l1.6-1.4v0.7c-0.9-0.8-2-1.5-3.1-2s-2.4-0.7-3.7-0.5c-1.2,0.2-2.4,0.7-3.4,1.5s-1.7,1.8-2.3,2.9
									c-1.1,2.2-1.5,4.8-1.3,7.3s1.1,4.9,2.6,7l-0.1-0.1c2.3,1.6,4.7,3.1,7.2,4.2c1.3,0.6,2.6,1.1,3.9,1.4c0.4,0.1,0.7,0.1,1,0.2
									c0.4,0.1,0.7,0.1,1.1,0.2c0.7,0.1,1.4,0.3,2.1,0.5c2.7,0.7,5.5,1.4,8.2,1.7c1.4,0.1,2.8,0.2,4.1,0
									C282.7,262.8,284,262.3,284.8,261.4z"/>
				</g>
				<path class="st30" d="M314.4,228.3c0-3.3-1.2-6.9-4.1-10.2c-1-1.1-2-2-3.1-2.8c0.4,1.2,0.7,2.4,0.9,3.6c0,0,0,0,0.1,0
								c2,2.1,3.2,4.6,3.6,7c0.6,3.1-0.2,6.2-2.3,8.2c-3.8,3.6-10.6,2.6-15.1-2.3c-0.7-0.7-1.3-1.5-1.8-2.3c-1.3-2.1-1.9-4.3-2-6.4
								c-0.7-4.7,0-9.6,3-12.2c-1.6-0.2-3.1-0.4-4.3-0.7c-5.1-1.3-4.8-2.8-15.2-12.2c-10.5-9.3-22-28.9-22-28.9l-5.7-0.7l-29.8-57.3
								c-4.9,2.5,33.8,79.7,32.8,81.7c-0.7,1.3,9.9-1.1,11.9-1.2c2-0.1,5.6,4.8,12,9.8c8.1,6.3,12.4,16.6,16.1,26.6
								c2.3,6.2,6.1,8.9,9.9,10l0,0c4.8,1.3,9.5,0,10.8-0.7C312.4,236.1,314.4,232.6,314.4,228.3z"/>
				<line class="st31" x1="220.8" y1="121.8" x2="222.7" y2="120.8" />
				<line class="st31" x1="221.5" y1="123.3" x2="223.4" y2="122.3" />
				<line class="st31" x1="222.2" y1="124.6" x2="224.1" y2="123.7" />
				<line class="st31" x1="222.9" y1="125.9" x2="224.8" y2="124.9" />
				<line class="st31" x1="220.1" y1="120.5" x2="222" y2="119.5" />
				<line class="st31" x1="224.2" y1="128.6" x2="226.1" y2="127.7" />
				<line class="st31" x1="224.9" y1="130.1" x2="226.8" y2="129.1" />
				<line class="st31" x1="225.6" y1="131.4" x2="227.5" y2="130.5" />
				<line class="st31" x1="226.3" y1="132.7" x2="228.2" y2="131.7" />
				<line class="st31" x1="223.5" y1="127.3" x2="225.4" y2="126.3" />
				<line class="st31" x1="219.4" y1="119.1" x2="221.3" y2="118.2" />
				<line class="st31" x1="218.8" y1="117.7" x2="220.7" y2="116.8" />
				<g>
					<path class="st13" d="M309.4,234.2c-3.8,3.6-10.6,2.6-15.1-2.3c-0.7-0.7-1.3-1.5-1.8-2.3c-1-1.9-1.6-4.1-2-6.4
									c-0.7-4.7,0-9.6,3-12.2c1.7-1.5,4.2-2.3,7.5-1.9c3.2,0.6,5.1,3.2,6.1,6.2c0.4,1.2,0.7,2.4,0.9,3.6c0.1,0.4,0.1,0.8,0.2,1.2
									c0.3,2,1.3,3.8,2.8,5.2c0.2,0.2,0.4,0.4,0.7,0.6C312.3,229.1,311.5,232.2,309.4,234.2z"/>
					<path class="st2" d="M309.7,234.6c-1.3,1.2-3,2-4.7,2.2s-3.5,0-5.1-0.5c-1.6-0.6-3.1-1.4-4.5-2.5c-0.7-0.5-1.3-1.1-1.8-1.8
									c-0.6-0.6-0.9-1.5-1.4-2.1l0,0l0,0c-0.9-1.9-1.5-3.9-1.8-5.9s-0.4-4.1-0.2-6.1c-0.2-2.1,0.6-4.2,1.9-5.9s3.4-2.9,5.5-3.2
									c1.1-0.2,2.1-0.2,3.2,0c1.1,0.1,2.1,0.5,3.1,1.2c1.8,1.2,3,3.2,3.7,5.2c0.4,1,0.6,2,0.8,3c0.1,0.5,0.2,1,0.3,1.5l0.1,0.8
									c0,0.2,0.1,0.5,0.1,0.7c0.2,0.9,0.6,1.8,1.2,2.6c0.5,0.8,1.2,1.4,2,2.1l0.1,0.1v0.2c0.3,1.5,0.3,3.1-0.1,4.7
									C311.7,232.1,310.9,233.5,309.7,234.6z M309,233.9c1-1,1.7-2.2,2.1-3.6c0.3-1.4,0.3-2.8,0.1-4.2l0.2,0.3
									c-0.7-0.6-1.6-1.4-2.2-2.3c-0.6-0.9-1.1-1.9-1.3-3c-0.1-0.3-0.1-0.5-0.1-0.8l-0.1-0.7c-0.1-0.5-0.2-1-0.3-1.5
									c-0.2-1-0.5-1.9-0.8-2.9c-0.7-1.8-1.7-3.6-3.3-4.6c-0.8-0.5-1.7-0.9-2.6-1c-1-0.1-2-0.1-2.9,0c-1.9,0.3-3.7,1.2-4.8,2.8
									c-0.6,0.8-1,1.6-1.4,2.5l-0.2,0.7c-0.1,0.2-0.1,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.1,0.5-0.1,0.7
									c-0.5,4,0.4,8.2,2.2,11.7l0,0c1,1.3,2.1,2.5,3.3,3.5c1.2,1,2.6,1.8,4.1,2.3s3.1,0.7,4.7,0.5
									C306.4,235.6,307.9,234.9,309,233.9z"/>
				</g>

				<ellipse transform="matrix(0.5673 -0.8235 0.8235 0.5673 -38.2815 283.0442)" class="st30" cx="250.2" cy="178" rx="3.6" ry="3.8" />
				<g>
					<line class="st10" x1="247.8" y1="176.4" x2="252.4" y2="179.5" />
					<path class="st2" d="M248.1,176c0.9,0.4,1.6,0.9,2.4,1.4c0.7,0.6,1.5,1.1,2.2,1.8l-0.4,0.6c-0.9-0.4-1.7-0.9-2.4-1.4
									c-0.8-0.5-1.5-1.1-2.2-1.7L248.1,176z"/>
				</g>
			</g>
			<g>
				<path class="st10" d="M170.5,135.8" />
			</g>
			<path class="st33" d="M225.4,102.7c0.3,4.8-0.2,9.7-1.4,14.4c-2.7,10-9.1,19.1-17.9,24.4c-8.9,5.3-25.5-0.3-27-1.1" />
			<g>
				<path class="st13" d="M87.6,159.9c2.2-0.1,3.8-1.3,5.6-2.2c1.7-0.9,3.5-1.9,5.2-2.9c3.3-1.9,6.6-4,9.8-6l7.7-2.8
								c2.8-1,5.6-2.1,8.3-3.2c1.5-0.2,3.1-0.4,4.6-0.6c1.9-0.3,3.8-0.5,5.6-0.8c2-0.3,3.5-2,3.6-4c0.1-2.4-1.4-3-3.6-3.6
								c-2.2-0.6-4.3-1.1-6.2-1.1c-1.9-0.1-3.8-0.1-5.7-0.1c-0.2,0-0.4,0-0.5,0.1l-0.7,0.2c-3,0.7-5.9,1.4-8.9,2.2l-8.9,2.2
								c-0.2,0.1-0.5,0.2-0.7,0.3l-0.5,0.3c-3.5,1.8-7,3.6-10.5,5.5c-1.7,1-3.4,1.9-5.1,2.9s-3.5,1.8-4.7,3.7"/>
				<path class="st2" d="M87.6,159.9c2-0.3,3.5-1.5,5.2-2.5s3.4-1.9,5.1-2.9l10-6.1h0.1h0.1l8-3l8-3h0.1h0.1
								c3.2-0.4,6.5-0.9,9.7-1.4c0.4-0.1,0.8-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.9-0.5c0.6-0.4,1-1,1.3-1.6s0.3-1.4,0.2-2
								s-0.5-1.1-1.1-1.4c-0.6-0.3-1.4-0.6-2.2-0.8s-1.6-0.4-2.3-0.6c-1.6-0.3-3.1-0.5-4.8-0.5l-2.5-0.1h-1.2h-0.6
								c-0.2,0-0.3,0-0.5,0l-4.8,1.1c-3.2,0.8-6.4,1.5-9.6,2.3l-2.4,0.6l-1.2,0.3l-0.6,0.1l-0.5,0.2c-1.4,0.7-2.9,1.5-4.3,2.2
								l-4.4,2.3c-1.5,0.7-2.9,1.5-4.3,2.3s-2.8,1.6-4.3,2.4c-1.5,0.7-2.9,1.4-4,2.7c0.6-1.6,2.1-2.7,3.5-3.5
								c1.4-0.9,2.8-1.7,4.2-2.5c1.4-0.8,2.9-1.6,4.3-2.4l4.4-2.3c1.5-0.8,2.9-1.5,4.4-2.3l0.7-0.3l0.6-0.2l1.2-0.3l2.4-0.6
								c3.2-0.8,6.4-1.6,9.6-2.4l4.8-1.2c0.3,0,0.6-0.1,0.8-0.1h0.6h1.2h2.5c0.8,0,1.6,0,2.5,0.1c0.8,0.1,1.7,0.2,2.5,0.4
								s1.6,0.4,2.4,0.6c0.8,0.2,1.6,0.4,2.4,0.9c0.4,0.2,0.8,0.5,1.2,0.9c0.3,0.4,0.5,0.9,0.6,1.4c0.2,1,0.1,1.9-0.3,2.8
								c-0.4,0.9-1,1.6-1.8,2.2c-0.4,0.3-0.8,0.5-1.3,0.6c-0.5,0.2-0.9,0.2-1.3,0.3c-3.2,0.5-6.5,0.9-9.8,1.4h0.1l-8,3l-8,2.9
								l0.1-0.1l-10.1,6.1c-1.7,1-3.4,1.9-5.2,2.8c-0.9,0.4-1.7,0.9-2.7,1.3C89.6,159.9,88.6,160,87.6,159.9z"/>
			</g>
			<path class="st34" d="M174.6,139.8c-0.8-3.2,1.1-19.2,0-22.6s-2-21-2-21s2.9,14,4,16.7s1.1,13.6,0.6,16.2
							C176.6,131.7,174.6,139.8,174.6,139.8z"/>
			<path class="st34" d="M157.5,140.6c0.7-3.2-0.1-22.2,0.8-25.6c0.9-3.4,1-16,1-16s-2.6,15-3.5,17.7s-0.9,10.6-0.5,13.2
							C155.7,132.5,157.5,140.6,157.5,140.6z"/>
			<g>
				<path class="st2" d="M171.8,135c0.1-1,0.3-2.1,0.9-3c0.5-0.9,1.4-1.6,2.4-2c2-0.7,4.2-0.6,6.2-0.2c2.1,0.4,4.1,1.4,5.5,2.9
								c1.5,1.4,2.7,3.1,4,4.7c0.6,0.8,1.3,1.5,2,2.3c0.7,0.7,1.5,1.3,2.4,1.9c1.6,1.2,3.4,2.3,5.1,3.4c3.5,2.1,7.2,3.9,11,5.2
								c3.8,1.4,7.8,2.3,11.9,2.7c4,0.4,8.2,0.4,12.2-0.5c2-0.5,3.9-1.2,5.6-2.3c1.7-1.1,3.2-2.5,4.3-4.2c1-1.7,1.6-3.7,1.6-5.7
								s-0.6-4-1.9-5.4l0.7-0.6c1.4,1.7,1.9,3.9,1.8,6c-0.1,2.1-0.8,4.2-1.9,5.9c-1.1,1.8-2.7,3.2-4.5,4.3s-3.7,1.8-5.7,2.3
								c-4,0.9-8.2,0.9-12.3,0.5s-8.1-1.4-12-2.7s-7.6-3.1-11.2-5.2c-1.8-1.1-3.5-2.1-5.2-3.3c-0.8-0.6-1.7-1.2-2.4-1.9
								c-0.8-0.7-1.4-1.5-2.1-2.3c-1.3-1.6-2.5-3.2-4-4.6c-1.4-1.4-3.2-2.3-5.2-2.7c-1-0.2-2-0.3-3-0.4c-1-0.1-2,0-3,0.2
								c-0.5,0.1-0.9,0.3-1.3,0.6c-0.4,0.3-0.7,0.7-1,1.1c-0.5,0.8-0.8,1.9-0.8,2.9L171.8,135z"/>
			</g>
		</g>

	)
}