import React from 'react'
import { useSelector } from 'react-redux'

export const Ligament = (props) => {

	return (
		<g id="ligament" className="transition" style={{ opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(0px, -15px)' }}>
			<g>
				<g>
					<defs>
						<path id="SVGID_23_" d="M168.1,107.5l7.2-7.1l-5.5-8.1c-1-2.2-4.5-2.1-5.4,0.1l-5.1,8.7l6.2,6.4
										C166.1,108.7,167.5,108.6,168.1,107.5z"/>
					</defs>
					<clipPath id="SVGID_24_">
						<use href="#SVGID_23_"  style={{"overflow":"visible"}}/>
								</clipPath>
					<g class="st40">

						<linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="166.7968" y1="6905.1436" x2="166.7086" y2="6893.519" gradientTransform="matrix(1 0 0 -1 0 6995.2754)">
							<stop offset="0" style={{"stopColor":"#F9B233"}} />
							<stop offset="1" style={{"stopColor":"#E54D4F"}} />
						</linearGradient>
						<rect x="163.7" y="90" class="st41" width="6.1" height="12.6" />

						<linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="163.5863" y1="6897.9756" x2="169.5542" y2="6897.9756" gradientTransform="matrix(1 0 0 -1 0 6995.2754)">
							<stop offset="0" style={{"stopColor":"#FFFFFF","stop-opacity":"0.6"}} />
							<stop offset="0.1417" style={{"stopColor":"#FFFFFF","stop-opacity":"0"}} />
							<stop offset="0.2983" style={{"stopColor":"#FFFFFF","stop-opacity":"0.6"}} />
							<stop offset="0.3962" style={{"stopColor":"#FFFFFF","stop-opacity":"0"}} />
							<stop offset="0.52" style={{"stopColor":"#FFFFFF","stop-opacity":"0.6"}} />
							<stop offset="0.6291" style={{"stopColor":"#FFFFFF","stop-opacity":"0"}} />
							<stop offset="0.7656" style={{"stopColor":"#FFFFFF","stop-opacity":"0.6"}} />
							<stop offset="0.8814" style={{"stopColor":"#FFFFFF","stop-opacity":"0"}} />
							<stop offset="1" style={{"stopColor":"#FFFFFF","stop-opacity":"0.6"}} />
						</linearGradient>
						<path class="st42" d="M167.4,90H166c-1.3,0-2.4,1.1-2.4,2.4v9.8c0,1.3,1.1,2.4,2.4,2.4h1.4c1.3,0,2.4-1.1,2.4-2.4v-9.8
										C169.8,91,168.7,90,167.4,90z"/>
					</g>
				</g>
			</g>
			<g>
				<path class="st12" d="M125.3,36c-5.2,0.6-10.3,6.4-10.3,8.9c0.1,2.5-11.9-12-11.9-12l15.6-8.3L125.3,36z" />
				<g>
					<path class="st13" d="M87.5,49.7l0.9,7.8l0.5,3.9l0.2,2c0.1,0.7,0.2,1.3,0.2,2v1.1c0,0.1,0,0.2-0.1,0.3
									c-0.6,1.8-1.1,3.5-1.7,5.3l2.4,2.5l9.8,1.3c1.3,0.2,2.4,1.2,2.6,2.5c0.2,1.7-0.9,3.3-2.6,3.6l-12.4,2.1
									c-1.2,0.2-2.6-0.2-3.4-1.2l-0.3-0.4l-5-6c-0.7-0.8-1.1-1.9-1.1-3.1v-0.8c0-2.5,0.1-5.1,0.2-7.6l-0.1,1.4
									c-0.1-0.6-0.1-1.3-0.1-2v-2l-0.1-3.9l-1.2-8.3c0-1,0.5-1.9,0.6-2.9c0.3-3.2-4.4-8.3,7.5-20.6s22.8-7.5,22.8-7.5h14.3h0.1
									c2.3,0,4.5,1.4,5.4,3.6l7.4,17.9l6.7,6.8c1.5,1.5,1.7,4,0.3,5.8c-1.5,1.9-4.2,2.1-6.1,0.7l-8.9-6.3
									c-0.4-0.3-0.8-0.7-1.1-1.2l-0.3-0.6l-3.4-6.3c0,0-2.3-4.7-3.2-6.7"/>
					<path class="st2" d="M88,49.6l1.4,11.2l0.3,2.8c0.1,0.5,0.1,0.9,0.1,1.5v0.7c0,0.3,0,0.4-0.1,0.8c-0.6,1.9-1.1,3.6-1.7,5.5
									l0-0.5l2.4,2.5L90,74l6.8,0.9l1.7,0.2l0.9,0.1l0.4,0.1c0.2,0,0.3,0.1,0.5,0.1c1.3,0.3,2.3,1.5,2.5,2.8s-0.4,2.7-1.5,3.5
									c-0.5,0.4-1.2,0.6-1.8,0.7l-1.7,0.3L91.1,84l-3.4,0.6c-1.2,0.3-2.7,0-3.6-0.8c-0.5-0.4-0.8-0.9-1.2-1.3l-1.1-1.3l-2.2-2.6
									l-1.1-1.3c-0.4-0.4-0.8-1-1-1.6s-0.4-1.2-0.4-1.9c0-0.6,0-1.2,0-1.7l0.2-6.9h1l-0.1,1.4l0,0L78,71l-0.8-4.4
									c-0.1-0.7-0.1-1.4-0.2-2l-0.1-2l-0.2-3.9v0.1c-0.5-2.8-1-5.5-1.4-8.3l0,0v-0.1c0-0.7,0.2-1.4,0.3-1.9
									c0.2-0.5,0.3-0.9,0.3-1.3c0-0.8-0.3-1.9-0.5-3c-0.4-2.2-0.3-4.7,0.5-6.9c0.7-2.2,1.9-4.2,3.2-6c1.3-1.8,2.7-3.5,4.2-5.1
									c3-3.2,6.7-6,10.9-7.6c2.1-0.8,4.4-1.3,6.7-1.4c0.6,0,1.1,0,1.7,0s1.1,0.1,1.7,0.1c1.2,0.2,2.2,0.4,3.5,0.9l-0.5-0.1
									l10.1,0.1h2.5c0.9,0,1.6,0,2.7,0.1c2,0.3,3.8,1.5,4.8,3.2c0.3,0.4,0.5,0.9,0.6,1.3l0.5,1.2l0.9,2.4l1.8,4.7
									c1.2,3.2,2.4,6.3,3.6,9.5l0,0l-0.1-0.2l4.4,4.5l2.2,2.2c0.8,0.8,1.4,2,1.4,3.2c0.1,1.2-0.3,2.4-1,3.3
									c-0.7,0.9-1.8,1.6-3,1.8s-2.4,0-3.4-0.6c-0.5-0.3-0.9-0.6-1.4-0.9l-1.3-0.9l-5.1-3.7l-1.3-0.9c-0.4-0.3-0.9-0.7-1.2-1.2
									c-0.3-0.5-0.5-1-0.8-1.4l-0.7-1.4l-1.5-2.8c-0.5-0.9-1-1.9-1.4-2.8c-0.9-1.9-1.7-3.9-2.4-5.8c1.1,1.8,2.1,3.7,3,5.5
									c0.9,1.9,2,3.7,3,5.6c0.5,0.9,1,1.9,1.5,2.7c0.3,0.4,0.6,0.6,1.1,0.9l1.3,0.9l5.2,3.6l1.3,0.9c0.4,0.3,0.9,0.6,1.3,0.8
									c0.8,0.5,1.8,0.6,2.7,0.4c0.9-0.2,1.8-0.7,2.3-1.4c0.6-0.7,0.8-1.7,0.8-2.6c-0.1-0.9-0.5-1.8-1.1-2.5l-2.2-2.3L134,41
									l-0.1-0.1v-0.1l0,0c-1.4-3.1-2.8-6.2-4.1-9.3l-2-4.6l-1-2.3l-0.5-1.2c-0.2-0.4-0.3-0.7-0.5-1c-0.8-1.2-2.1-2-3.4-2.1
									c-0.6-0.1-1.5,0-2.4,0h-2.5l-10.2,0.1h-0.2l-0.3-0.1c-0.8-0.3-1.8-0.5-2.8-0.6c-0.5-0.1-1-0.1-1.5-0.1s-1,0-1.5,0
									c-2,0.1-4,0.6-5.9,1.3c-3.8,1.5-7.1,3.9-10,6.9c-1.4,1.5-2.9,3.1-4.1,4.7c-1.2,1.7-2.3,3.4-3,5.3c-0.7,1.9-0.9,3.8-0.6,5.8
									c0.1,1,0.4,2,0.4,3.4c0,0.7-0.3,1.4-0.4,1.8c-0.2,0.5-0.3,0.9-0.3,1.2v-0.1c0.4,2.8,0.7,5.5,1,8.3v0.1l0,0v3.9v2
									c0,0.7-0.1,1.3,0,1.9l-1,0.1l0,0l0.1-1.4h1L78,71.7c0,0.6,0,1.2,0,1.7s0.1,1,0.3,1.5c0.2,0.5,0.5,0.9,0.8,1.3l1.1,1.3
									l2.2,2.7l1.1,1.3c0.4,0.4,0.7,0.9,1.1,1.2c0.8,0.6,1.8,0.9,2.8,0.6L91,83l6.8-1.2l1.7-0.3c0.5-0.1,1-0.2,1.4-0.5
									c0.8-0.6,1.2-1.6,1.1-2.5c-0.1-1-0.9-1.8-1.8-2c-0.1,0-0.2-0.1-0.4-0.1l-0.4-0.1l-0.9-0.1L96.7,76l-6.8-1h-0.2l-0.1-0.1
									l-2.4-2.5L87,72.1l0.1-0.3l1.7-5.4c0-0.1,0-0.4,0-0.6v-0.7c0-0.4-0.1-0.9-0.1-1.4L88.4,61L87,49.8L88,49.6z"/>
				</g>
				<g>
					<path class="st13" d="M92,44.3l6.3,13.2l8.8,8.1l10.6-0.4l3.2-4.1c0.3-0.4,0.9-0.7,1.5-0.6c0.8,0.1,1.4,0.9,1.3,1.8
									l-1.3,7.6c-0.1,0.9-0.8,1.6-1.7,1.8l-1,0.2l-13.7,3c-0.8,0.2-1.6,0.1-2.4-0.3l-0.9-0.5l-13.6-7.4c-0.7-0.4-1.2-0.9-1.6-1.6
									L87,64.3l-7.5-13.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.8-1.7-0.9-3.6-0.3-5.3"/>
					<path class="st2" d="M92.5,44.1l6.3,13.2l-0.1-0.2l8.8,8.1l-0.4-0.1l10.6-0.4l-0.4,0.2l0,0l3-3.9l0.2-0.2
									c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.3,0.7-0.4c0.5-0.2,1.1-0.2,1.6,0.1c0.5,0.2,0.9,0.7,1.1,1.2
									c0.1,0.3,0.1,0.5,0.1,0.8s-0.1,0.4-0.1,0.7l-0.8,4.8l-0.4,2.4c-0.2,1-1,1.9-2,2.2l-9.6,2.1l-4.8,1c-0.4,0.1-0.8,0.2-1.3,0.2
									s-1-0.1-1.4-0.2c-0.5-0.2-0.8-0.4-1.2-0.6l-1.1-0.6L93,69.4L88.7,67c-0.8-0.5-1.5-1.2-1.8-2l-1.2-2.2L81,54.3
									c-0.4-0.7-0.8-1.4-1.1-2.2c-0.4-0.7-0.8-1.4-1-2.3c-0.5-1.6-0.4-3.4,0.3-4.9c-0.4,1.6-0.3,3.2,0.4,4.7
									c0.3,0.7,0.8,1.4,1.2,2.1c0.4,0.7,0.8,1.4,1.2,2.1l4.7,8.6l1.2,2.1c0.3,0.7,0.8,1.2,1.4,1.6l4.3,2.3l8.6,4.6l1.1,0.6
									c0.4,0.2,0.7,0.4,1,0.5s0.6,0.2,1,0.2c0.3,0,0.7-0.1,1.1-0.2l4.8-1l9.5-2.1c0.6-0.1,1-0.7,1.1-1.2l0.4-2.4l0.8-4.8
									c0.1-0.5,0.1-0.7,0-0.9s-0.3-0.5-0.5-0.6S122,61,121.7,61c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.1,0.2l-0.2,0.2l-3,3.9
									l0,0l-0.1,0.2h-0.2l-10.6,0.4H107l-0.2-0.1L98,57.9l-0.1-0.1v-0.1l-6.3-13.2L92.5,44.1z"/>
				</g>
				<g>
					<path class="st13" d="M100.4,38.5l4.8,8.7l12.7,9.8l11.3,3.1l1.3-1.3c0.6-0.6,1.5-0.9,2.4-0.7c1.4,0.3,2.3,1.6,2,3l-1,5.2
									c-0.2,1.2-1.3,2.1-2.6,2.1h-1.5l-15.5-0.1c-0.7,0-1.4-0.2-2-0.6l-0.7-0.5L96.2,57c-0.7-0.5-1.4-1.2-1.8-2l-0.1-0.2L88.7,45
									l0,0c-0.8-1.4-1-2.9-0.8-4.3"/>
					<path class="st2" d="M100.9,38.2l4.8,8.7l-0.1-0.2l12.7,9.8l-0.2-0.1l11.3,3.1l-0.5,0.1l0.8-0.8c0.3-0.3,0.5-0.5,0.8-0.8
									c0.7-0.5,1.6-0.7,2.5-0.6c0.9,0.2,1.6,0.7,2.1,1.5c0.2,0.4,0.4,0.8,0.4,1.2c0.1,0.4,0,0.9-0.1,1.2l-0.9,4.3
									c-0.1,0.3-0.1,0.7-0.3,1.1s-0.4,0.8-0.7,1.1c-0.6,0.6-1.5,1-2.3,0.9h-8.8H118h-2.2h-1.1c-0.3,0-0.8,0-1.2-0.1
									c-0.4-0.1-0.8-0.2-1.2-0.4c-0.4-0.2-0.7-0.4-1-0.6l-1.8-1.2l-3.6-2.4l-7.3-4.8L96.8,58l-0.9-0.6c-0.3-0.2-0.6-0.5-0.9-0.7
									c-0.6-0.5-1-1.3-1.3-1.9l-1.1-1.9l-4.3-7.6c-0.6-1.5-0.7-3.1-0.3-4.6c-0.1,1.5,0.4,3,1.2,4.1l4.3,7.6l1.1,1.9
									c0.4,0.7,0.7,1.2,1.2,1.7c0.2,0.2,0.5,0.4,0.8,0.6l0.9,0.6l1.8,1.2l7.3,4.8l3.6,2.4l1.8,1.2c0.6,0.4,1.1,0.8,1.8,0.9
									c0.3,0.1,0.6,0,1,0h1.1h2.2h4.4h8.8c0.6,0,1.2-0.2,1.6-0.6c0.2-0.2,0.4-0.5,0.5-0.7s0.1-0.6,0.2-1l0.9-4.3
									c0.1-0.4,0.1-0.6,0.1-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.5-0.8-0.9-1.4-1c-0.6-0.1-1.2,0-1.7,0.4c-0.2,0.2-0.5,0.5-0.7,0.7
									l-0.8,0.8l-0.2,0.2l-0.3-0.1l-11.3-3.1h-0.1l-0.1-0.1L105,47.4l-0.1-0.1l-0.1-0.1l-4.8-8.7L100.9,38.2z"/>
				</g>
				<g>
					<g>
						<path class="st32" d="M153.6,70l11.2,16l5.7,9.4l-0.2,1l-11.7-10.3l-2.8-3.4l0.3-1l-6.4-8.5c-0.5-0.7-0.2-1.7,0.6-2
										L153.6,70z"/>
						<path class="st2" d="M151.6,71.1l-0.9,0.3c-0.3,0.1-0.5,0.2-0.7,0.3c-0.1,0.2-0.2,0.4-0.2,0.6s0.1,0.4,0.3,0.7l1.2,1.7
										l5,6.6l0.1,0.2l-0.1,0.2l-0.3,1l-0.1-0.4c0.4,0.6,0.8,1.2,1.3,1.8c0.4,0.6,0.9,1.2,1.3,1.8l0,0c1.9,1.7,3.9,3.5,5.8,5.2
										c1.9,1.7,3.9,3.4,5.9,5.1l-0.5,0.2l0.1-1l0.1,0.4l-4-6.5c-0.7-1.1-1.3-2.2-2-3.2l-2.1-3.1l-8.5-12.4
										C152.8,70.7,152.2,70.9,151.6,71.1z M162.6,82.2l2.2,3.2c0.7,1.1,1.3,2.2,2,3.2l4,6.5l0.1,0.2l-0.1,0.2l-0.4,0.9l-0.2,0.4
										l-0.3-0.3c-1.9-1.8-3.8-3.5-5.8-5.2c-1.9-1.7-3.9-3.4-5.8-5.1l0,0l0,0c-0.5-0.6-1-1.1-1.5-1.7c-0.5-0.5-1-1.1-1.5-1.6
										l-0.2-0.2l0.1-0.3l0.3-1l0.1,0.4l-5-6.6l-1.2-1.7c-0.2-0.2-0.5-0.7-0.5-1.2s0.1-0.9,0.4-1.3c0.3-0.4,0.8-0.6,1.1-0.6
										l1.1-0.3c0.7-0.2,1.4-0.4,2.2-0.6L162.6,82.2z"/>
					</g>
					<g>
						<path class="st32" d="M160.7,85.1c0.2,0,0.3,0,0.4-0.1c0.3-0.2,0.4-0.7,0.2-1l-5.8-8.3c-0.1-0.2-0.4-0.3-0.6-0.3
										c-0.2,0-0.3,0-0.4,0.1c-0.3,0.2-0.4,0.7-0.2,1l5.8,8.3C160.2,85,160.5,85.1,160.7,85.1z"/>
						<path class="st2" d="M154.9,75.9c0,0,0.1,0,0.2,0.1l5.8,8.3c0.1,0.1,0.1,0.2,0,0.2c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0-0.2,0
										c0,0-0.1,0-0.2-0.1l-5.8-8.3c-0.1-0.1-0.1-0.3,0.1-0.4C154.8,75.9,154.9,75.9,154.9,75.9 M154.9,74.9
										c-0.2,0-0.5,0.1-0.7,0.2c-0.5,0.4-0.7,1.1-0.3,1.7l5.8,8.3c0.2,0.3,0.6,0.5,1,0.5c0.2,0,0.5-0.1,0.7-0.2
										c0.5-0.4,0.7-1.1,0.3-1.7l-5.8-8.3C155.7,75.1,155.3,74.9,154.9,74.9L154.9,74.9z"/>
					</g>
					<path class="st38" d="M99.4,9.1l47.8,62.7c0.3,0.4,0.8,0.7,1.3,0.8c2.4,0.3,3.9-0.6,5.6-1.5c0.5-0.3,0.6-0.9,0.3-1.3
									L108.3,2.9c-1.7-2.5-5.1-3.1-7.5-1.3l0,0C98.3,3.3,97.7,6.7,99.4,9.1z"/>
					<polygon class="st39" points="158.6,86 170.1,96 170.1,95.5 158,85.2 								" />
					<path class="st2" d="M141.3,62.5c1.1,0.4,2.4,0,3.3-0.8c0.4-0.4,0.8-0.9,1.1-1.4s0.5-1,0.5-1.6l-1.1,1.2
									c-0.4,0.4-0.7,0.7-1.1,1c-0.4,0.3-0.8,0.6-1.2,0.8C142.3,62.1,141.9,62.3,141.3,62.5z"/>
					<path class="st2" d="M142.4,64.1c1.1,0.3,2.3-0.2,3.2-0.9c0.4-0.4,0.8-0.8,1.1-1.3s0.5-1,0.6-1.5c-0.4,0.4-0.8,0.8-1.1,1.1
									c-0.4,0.4-0.7,0.7-1.1,1c-0.4,0.3-0.8,0.6-1.2,0.8C143.4,63.6,142.9,63.8,142.4,64.1z"/>
					<path class="st2" d="M143.5,65.7c0.6,0.1,1.1,0,1.6-0.2s1-0.5,1.4-0.9s0.8-0.8,1.1-1.2c0.3-0.5,0.5-0.9,0.7-1.5
									c-0.4,0.3-0.8,0.7-1.2,1s-0.8,0.7-1.2,1s-0.8,0.6-1.2,0.9C144.4,65.1,144,65.3,143.5,65.7z"/>
					<path class="st2" d="M144.6,67.2c1.1,0.2,2.2-0.3,3.1-1c0.4-0.4,0.8-0.8,1.1-1.2c0.3-0.5,0.5-0.9,0.7-1.5
									c-0.4,0.4-0.8,0.7-1.2,1.1c-0.4,0.3-0.8,0.7-1.2,1s-0.8,0.6-1.2,0.9C145.5,66.6,145.1,66.9,144.6,67.2z"/>
					<path class="st2" d="M142.1,53.6c-1.1-0.4-2.4,0-3.3,0.8c-0.4,0.4-0.8,0.9-1.1,1.4s-0.5,1-0.5,1.6l1.1-1.2
									c0.4-0.4,0.7-0.7,1.1-1c0.4-0.3,0.8-0.6,1.2-0.8C141.1,54.1,141.6,53.9,142.1,53.6z"/>
					<path class="st2" d="M141,52c-1.1-0.3-2.3,0.2-3.2,0.9c-0.4,0.4-0.8,0.8-1.1,1.3s-0.5,1-0.6,1.5c0.4-0.4,0.8-0.8,1.1-1.1
									c0.4-0.4,0.7-0.7,1.1-1c0.4-0.3,0.8-0.6,1.2-0.8C140.1,52.6,140.5,52.3,141,52z"/>
					<path class="st2" d="M140,50.5c-0.6-0.1-1.1,0-1.6,0.2s-1,0.5-1.4,0.9s-0.8,0.8-1.1,1.2c-0.3,0.5-0.5,0.9-0.7,1.5
									c0.4-0.3,0.8-0.7,1.2-1s0.8-0.7,1.2-1s0.8-0.6,1.2-0.9C139.1,51,139.5,50.8,140,50.5z"/>
					<path class="st2" d="M138.9,48.9c-1.1-0.2-2.2,0.3-3.1,1c-0.4,0.4-0.8,0.8-1.1,1.2c-0.3,0.5-0.5,0.9-0.7,1.5
									c0.4-0.4,0.8-0.7,1.2-1.1c0.4-0.3,0.8-0.7,1.2-1s0.8-0.6,1.2-0.9C138,49.5,138.4,49.2,138.9,48.9z"/>
				</g>
				<g>
					<path class="st13" d="M106.5,29.6c2.1,0.6,3.4,2.1,4.9,3.4c1.5,1.3,2.9,2.7,4.3,4.1c2.7,2.7,5.4,5.4,8.1,8.2l6.8,4.6
									c2.5,1.7,4.9,3.4,7.3,5.1c1.4,0.6,2.9,1.1,4.3,1.7c1.8,0.7,3.5,1.4,5.3,2.2s2.9,2.8,2.5,4.8c-0.5,2.3-2.1,2.6-4.3,2.7
									c-2.3,0.1-4.4,0-6.3-0.4c-1.9-0.4-3.7-0.8-5.6-1.2c-0.2,0-0.3-0.1-0.5-0.2l-0.7-0.3c-2.7-1.4-5.4-2.8-8.1-4.2l-8.1-4.3
									c-0.2-0.1-0.4-0.3-0.6-0.4l-0.4-0.4c-3-2.6-6-5.2-8.9-7.9c-1.5-1.3-2.9-2.7-4.3-4.1c-1.4-1.4-3-2.6-3.7-4.7"/>
					<path class="st2" d="M106.5,29.6c1,0.1,1.9,0.5,2.8,1.1c0.8,0.6,1.6,1.2,2.3,1.9c1.5,1.3,2.9,2.7,4.3,4l8.3,8.3l-0.1-0.1
									l7.1,4.7l7,4.8l-0.1-0.1c3.1,1.2,6.1,2.4,9.2,3.7c0.4,0.1,0.8,0.3,1.2,0.6s0.8,0.6,1.1,0.9c0.6,0.7,1,1.6,1.2,2.5
									c0.1,0.9,0,1.9-0.4,2.8c-0.2,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.9,0.5-1.4,0.6c-0.9,0.2-1.8,0.2-2.6,0.3c-0.8,0-1.7,0-2.5,0
									s-1.7-0.1-2.5-0.2c-0.9-0.1-1.7-0.3-2.5-0.5l-2.4-0.5l-1.2-0.3l-0.6-0.1c-0.2-0.1-0.5-0.2-0.7-0.3l-4.4-2.3
									c-2.9-1.5-5.8-3-8.7-4.6l-2.2-1.2l-1.1-0.6l-0.5-0.3l-0.6-0.4c-1.3-1.1-2.5-2.2-3.7-3.3l-3.7-3.3c-1.2-1.1-2.5-2.2-3.6-3.3
									c-1.2-1.1-2.4-2.3-3.5-3.5s-2.3-2.6-2.6-4.3c0.8,1.5,2,2.5,3.2,3.6s2.4,2.2,3.6,3.3s2.4,2.2,3.6,3.3l3.7,3.2
									c1.2,1.1,2.5,2.2,3.7,3.2l0.4,0.3l0.5,0.3l1.1,0.6l2.2,1.2c2.9,1.5,5.8,3.1,8.7,4.6l4.4,2.2c0.1,0,0.2,0.1,0.4,0.1l0.6,0.1
									l1.2,0.3l2.4,0.5c1.6,0.4,3.1,0.6,4.7,0.7c0.8,0,1.6,0,2.4,0c0.8,0,1.6-0.1,2.3-0.2c0.7-0.2,1.2-0.5,1.4-1.1
									c0.3-0.6,0.4-1.4,0.3-2c-0.1-0.7-0.4-1.3-0.9-1.9c-0.2-0.3-0.5-0.5-0.8-0.7s-0.6-0.3-1-0.5c-3-1.3-6.1-2.5-9.1-3.7h-0.1
									h-0.1l-7-4.9l-7.1-4.8l0,0l0,0l-8.2-8.4c-1.4-1.3-2.8-2.7-4.2-4C109.5,31.9,108.3,30.4,106.5,29.6z"/>
				</g>
			</g>
			<g>
				<path class="st12" d="M261.2,101.8c3,4.2,2.2,12,0.3,13.6c-1.9,1.7,16.9,0.8,16.9,0.8L274,99L261.2,101.8z" />
				<g>
					<path class="st13" d="M276.5,139l-6.4,4.5l-3.2,2.3l-1.6,1.1c-0.5,0.4-1.1,0.7-1.6,1.2l-0.8,0.7c-0.1,0.1-0.1,0.2-0.2,0.3
									c-0.9,1.6-1.9,3.2-2.8,4.8l-3.5-0.1l-7.6-6.4c-1-0.8-2.5-1-3.6-0.2c-1.4,1-1.8,2.9-0.9,4.4l6.7,10.6
									c0.7,1.1,1.9,1.8,3.2,1.7h0.5l7.8-0.3c1.1,0,2.1-0.5,3-1.3l0.6-0.5c1.9-1.7,3.7-3.5,5.5-5.2l-1,1c0.5-0.4,1-0.8,1.5-1.3
									l1.5-1.3l3-2.6l7-4.7c0.8-0.7,1-1.7,1.7-2.4c2.2-2.4,9.1-2.3,10.2-19.4s-9.8-21.9-9.8-21.9l-9.6-10.6l-0.1-0.1
									c-1.5-1.7-4-2.4-6.3-1.6l-18.2,6.5l-9.5-0.4c-2.1-0.1-4.1,1.4-4.5,3.6c-0.4,2.3,1.2,4.5,3.5,5l10.6,2.4
									c0.5,0.1,1.1,0.1,1.6,0l0.7-0.2l7-1.8c0,0,5-1.4,7.2-2.1"/>
					<path class="st2" d="M276.8,139.5l-9.3,6.5l-2.3,1.6c-0.4,0.3-0.8,0.5-1.1,0.8l-0.5,0.5c-0.2,0.2-0.4,0.4-0.4,0.4l-2.8,4.8
									l-0.2,0.3h-0.3l-3.5-0.1h-0.2l-0.1-0.1l-5.3-4.4l-1.3-1.1l-0.7-0.6l-0.3-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
									c-0.8-0.5-1.9-0.5-2.7,0s-1.3,1.5-1.2,2.5c0,0.5,0.2,0.9,0.5,1.4l0.9,1.5l3.7,5.8l1.8,2.9c0.5,0.9,1.4,1.5,2.4,1.7
									c0.5,0.1,1.1,0,1.6,0l1.7-0.1l3.4-0.2l1.7-0.1c0.6,0,1-0.1,1.5-0.3s0.9-0.4,1.3-0.8c0.4-0.4,0.8-0.8,1.2-1.2l5-4.7l0.7,0.7
									l-1,1l0,0l-0.6-0.8c0.5-0.3,0.9-0.8,1.4-1.3l1.4-1.3l2.9-2.7l0,0h0.1c2.3-1.6,4.5-3.2,6.8-4.8l-0.1,0.1
									c0.2-0.2,0.5-0.6,0.7-1s0.6-1.1,1.1-1.5c1-0.9,2-1.4,2.8-2c1.7-1.1,3-2.6,3.9-4.3c0.9-1.8,1.5-3.7,1.9-5.7s0.6-4.1,0.8-6.2
									c0.3-4.1-0.1-8.3-1.6-12.1c-0.7-1.9-1.7-3.6-3-5.2c-0.3-0.4-0.6-0.8-1-1.1c-0.3-0.4-0.7-0.7-1.1-1c-0.7-0.6-1.6-1.2-2.3-1.6
									l-0.3-0.1l-0.1-0.2l-6.8-7.6l-1.7-1.9c-0.6-0.6-1.1-1.4-1.6-1.7c-1-0.9-2.5-1.3-3.9-1.1c-0.4,0.1-0.6,0.1-1.1,0.3l-1.2,0.4
									l-2.4,0.8l-4.8,1.6c-3.2,1.1-6.4,2.1-9.7,3.2l0,0h-0.1H251l-6.3-0.2l-3.2-0.1c-0.9,0-1.9,0.2-2.6,0.8
									c-0.7,0.6-1.2,1.4-1.4,2.3s0,1.9,0.5,2.6c0.5,0.8,1.3,1.4,2.1,1.7c0.4,0.1,1,0.2,1.5,0.4l1.5,0.4l6.1,1.4l1.5,0.4
									c0.5,0.1,0.9,0.2,1.4,0.2c0.9-0.2,2-0.5,3-0.7c2-0.5,4.1-1,6.1-1.5c2-0.6,4.1-1.1,6.1-1.5c-2,0.8-3.9,1.5-6,2.1
									c-1,0.4-2,0.6-3,0.9l-3.1,0.8l-1.5,0.4c-0.5,0.1-1,0.3-1.6,0.4s-1.2,0-1.7-0.1l-1.5-0.3l-6.2-1.3l-1.5-0.3
									c-0.5-0.1-1-0.2-1.6-0.4c-1.1-0.4-2.1-1.1-2.7-2.1c-0.6-1-0.9-2.2-0.7-3.4c0.2-1.2,0.9-2.2,1.8-3c0.9-0.7,2.1-1.1,3.3-1
									l3.2,0.1l6.3,0.3h-0.2l0,0c3.1-1.3,6.3-2.5,9.5-3.7l4.7-1.8l2.4-0.9l1.2-0.4c0.4-0.1,0.9-0.3,1.4-0.4c2-0.4,4.1,0.1,5.6,1.4
									c0.8,0.7,1.3,1.3,1.9,1.9l1.7,1.9l6.9,7.5l-0.4-0.3c1.2,0.6,2.1,1.2,3,2c0.4,0.4,0.9,0.8,1.3,1.2c0.4,0.4,0.8,0.8,1.2,1.3
									c1.5,1.8,2.6,3.8,3.4,5.9c1.6,4.2,2,8.8,1.7,13.2c-0.2,2.2-0.4,4.4-1,6.5c-0.5,2.2-1.2,4.3-2.3,6.3c-1.1,2-2.8,3.8-4.8,5
									c-0.9,0.6-2,1.1-2.5,1.6c-0.3,0.2-0.5,0.6-0.8,1.1s-0.6,1.1-1.2,1.5h-0.1l0,0c-2.3,1.5-4.7,3-7.1,4.5l0.1-0.1l-3,2.5
									l-1.5,1.2c-0.5,0.4-1,0.8-1.6,1.2l-3.8,2.4l3.2-3.2l0,0l1-1l0.7,0.7l-5,4.7c-0.4,0.4-0.8,0.8-1.3,1.2s-1,0.7-1.6,0.9
									s-1.3,0.3-1.8,0.3l-1.7,0.1l-3.4,0.2l-1.7,0.1c-0.6,0-1.1,0.1-1.8,0c-1.3-0.2-2.5-1.1-3.1-2.1l-1.8-2.9l-3.7-5.8l-0.9-1.5
									c-0.3-0.5-0.6-1.2-0.7-1.8c-0.1-1.3,0.5-2.7,1.6-3.4c1.1-0.8,2.7-0.8,3.8,0c0.1,0.1,0.3,0.2,0.4,0.3l0.3,0.3l0.7,0.6
									l1.3,1.1l5.3,4.4l-0.3-0.1l3.5,0.1l-0.4,0.3c1-1.6,1.9-3.2,2.9-4.9c0.2-0.3,0.4-0.4,0.6-0.6l0.5-0.5
									c0.4-0.3,0.8-0.6,1.2-0.9l2.3-1.6l9.3-6.5L276.8,139.5z"/>
				</g>
				<g>
					<path class="st13" d="M277.5,132.1l-14,4.2l-11.9-1.1l-6.8-8.1l0.9-5.1c0.1-0.5-0.1-1.1-0.6-1.5c-0.7-0.5-1.7-0.4-2.2,0.3
									l-4.8,6c-0.5,0.7-0.7,1.7-0.2,2.5l0.5,0.9l7,12.2c0.4,0.7,1,1.2,1.8,1.5l1,0.3l14.6,5.1c0.7,0.3,1.5,0.3,2.3,0.1l0.8-0.2
									l15.2-3.7c0.1,0,0.3-0.1,0.4-0.1c1.8-0.6,3.3-1.8,4.1-3.4"/>
					<path class="st2" d="M277.6,132.5l-14,4.2h-0.1h-0.1l-11.9-1.1h-0.2l-0.1-0.2l-6.8-8.1l-0.2-0.2v-0.2l0,0l0.8-4.8l0.1-0.3
									c0-0.1,0-0.1,0-0.2s0-0.3-0.1-0.4c-0.1-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.1-0.8,0s-0.4,0.3-0.7,0.7l-3,3.9l-1.5,1.9
									c-0.4,0.5-0.4,1.2-0.1,1.7l4.9,8.4l2.4,4.2c0.2,0.4,0.4,0.7,0.6,0.9c0.2,0.2,0.5,0.5,0.8,0.6s0.7,0.3,1.1,0.4l1.2,0.4
									l9.2,3.3l4.6,1.6c0.7,0.2,1.4,0.2,2.1,0l2.4-0.6l9.5-2.2c0.8-0.2,1.6-0.4,2.4-0.5c0.8-0.2,1.6-0.3,2.3-0.5
									c1.5-0.5,2.8-1.5,3.7-2.9c-0.6,1.5-1.9,2.8-3.5,3.5c-0.8,0.4-1.6,0.5-2.4,0.7s-1.6,0.4-2.4,0.6l-9.5,2.3l-2.4,0.6
									c-0.8,0.2-1.8,0.3-2.7,0l-4.6-1.6l-9-3.1l-1.2-0.4c-0.4-0.1-0.8-0.2-1.2-0.5c-0.4-0.2-0.8-0.5-1.1-0.9s-0.6-0.8-0.7-1.1
									l-2.4-4.2l-4.9-8.5c-0.5-0.9-0.4-2.1,0.3-2.9l1.5-1.9l3.1-3.8c0.1-0.2,0.2-0.3,0.4-0.5s0.4-0.4,0.7-0.5
									c0.5-0.2,1.1-0.2,1.6,0s0.9,0.6,1.1,1.1c0.1,0.3,0.2,0.5,0.2,0.8c0,0.1,0,0.3,0,0.4l-0.1,0.3l-0.9,4.8l0,0l-0.1-0.4l6.8,8.1
									l-0.3-0.2l11.9,1.1h-0.2l14-4.2L277.6,132.5z"/>
				</g>
				<g>
					<path class="st13" d="M276.1,121.9l-9.7,2.2l-15.7-2.8l-9.9-6.3l0.1-1.8c0-0.8-0.3-1.7-1-2.2c-1.1-0.9-2.8-0.6-3.6,0.5
									l-3.2,4.3c-0.7,1-0.7,2.4,0.2,3.3l1,1.1l10.5,11.5c0.5,0.5,1.1,0.9,1.8,1.1l0.8,0.2l17.9,4.5c0.9,0.2,1.8,0.2,2.7,0h0.2
									l11-2.5l0,0c1.5-0.4,2.8-1.2,3.7-2.3"/>
					<path class="st2" d="M276.3,122.4l-9.6,2.3h-0.1h-0.1l-15.8-2.8h-0.1l-0.1-0.1l-9.9-6.3l-0.3-0.2v-0.4l0.1-1.2v-0.6
									c0-0.2,0-0.3,0-0.5c-0.1-0.6-0.4-1.1-1-1.4c-0.5-0.3-1.2-0.4-1.7-0.1c-0.3,0.1-0.5,0.3-0.7,0.5l-0.1,0.2l-0.2,0.2l-0.3,0.5
									l-2.7,3.7c-0.3,0.5-0.4,1.1-0.2,1.7c0.1,0.3,0.2,0.5,0.5,0.8l0.8,0.9l6.3,6.9l3.1,3.4l0.8,0.9l0.4,0.4
									c0.1,0.1,0.2,0.2,0.4,0.3c0.5,0.4,1.1,0.6,1.9,0.8l18,4.6c0.7,0.2,1.4,0.2,2.1,0.1c0.7-0.1,1.5-0.3,2.2-0.5l4.5-1l4.5-1
									c1.5-0.3,2.7-0.9,4-1.9c-0.6,1.5-2.2,2.5-3.8,2.9l-4.5,1.1l-4.5,1.1c-0.8,0.2-1.5,0.4-2.3,0.5s-1.7,0.1-2.5-0.1l-18.1-4.5
									c-0.4-0.1-0.7-0.2-1.2-0.3c-0.4-0.2-0.8-0.4-1.2-0.7c-0.2-0.1-0.3-0.3-0.5-0.5l-0.4-0.4l-0.8-0.9l-3.2-3.4l-6.3-6.9
									l-0.8-0.9c-0.3-0.3-0.6-0.8-0.7-1.2c-0.3-0.9-0.2-2,0.3-2.8l2.8-3.8l0.3-0.5l0.2-0.2l0.2-0.3c0.3-0.4,0.7-0.6,1.2-0.8
									c0.9-0.4,2-0.3,2.8,0.2s1.4,1.4,1.6,2.3c0,0.2,0,0.5,0,0.7v0.6l-0.1,1.2l-0.3-0.6l9.9,6.3l-0.2-0.1l15.7,2.9h-0.2l9.7-2.2
									L276.3,122.4z"/>
				</g>
				<g>
					<g>
						<path class="st32" d="M292.6,106.4l-64.2,1.1L213,107c-0.5,0-47.4-2.3-47.4-3s44.3-4.1,44.9-4.1l18-0.4l64.1,1.9
										c1.3,0,3,2.4,3,2.4S294,106.3,292.6,106.4z"/>
						<path class="st2" d="M292.6,107l-64.6,0.8l-16.2-0.5l-16.1-0.9l-16.1-0.9l-8.1-0.5c-1.3-0.1-2.7-0.2-4-0.3l-1-0.1l-0.5-0.1
										h-0.3h-0.1h-0.1c0,0-0.1,0-0.2-0.2c-0.1-0.1,0.1-0.3,0.2-0.4h0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1l0.5-0.1l1-0.1
										c10.7-1.2,21.4-2,32.2-2.9c5.4-0.4,10.8-1,16.1-1l8.1-0.2l4-0.1c1.3,0,2.7,0.1,4,0.1l32.3,0.9l16.1,0.4l8.1,0.2l4,0.1
										c0.4,0,0.9,0.2,1.3,0.4c0.3,0.2,0.6,0.4,0.9,0.7c0.5,0.5,1,1,1.4,1.6l0.2,0.3l-0.2,0.4l0,0c-0.4,0.6-0.8,1.1-1.3,1.6
										c-0.3,0.2-0.5,0.5-0.9,0.7C293.5,106.8,293.1,107,292.6,107z M292.6,105.8c0.3,0,0.9-0.4,1.3-0.8s0.8-0.9,1.2-1.4l0,0v0.7
										c-0.4-0.5-0.8-1-1.2-1.4c-0.4-0.4-0.9-0.8-1.3-0.7l-4-0.1l-8.1-0.3l-16.1-0.6l-32.3-1c-1.3,0-2.7-0.1-4-0.1l-4,0.1
										l-8.1,0.2c-5.4,0-10.7,0.5-16.1,0.9c-10.7,0.9-21.5,1.6-32.2,2.8l-1,0.1l-0.5,0.1c-0.1,0-0.2,0-0.2,0.1h-0.1l0,0
										c0.1,0,0.1-0.2,0.1-0.3c-0.1-0.2-0.2-0.2-0.1-0.1l0,0h0.1h0.2l0.5,0.1l1,0.1c1.3,0.1,2.7,0.2,4,0.3l8.1,0.5l16.1,0.9
										l16.1,0.8l16,0.3L292.6,105.8z"/>
					</g>
					<g id="Glans_26_">
						<g>
							<path class="st1" d="M169.8,102.7c0,0.2,0.2,0.6,0.1,1.5s-0.9,2.9-1.4,3.9c-0.6,1-1.2,1.6-1.8,1.6c-0.7,0-1.3-0.6-1.8-1.6
											s-1.3-3.1-1.4-3.9c-0.1-0.8,0.1-1.3,0.2-1.5H169.8z"/>
							<path class="st2" d="M169.8,102.7c0.3,0.6,0.4,1.4,0.3,2.1c-0.1,0.7-0.3,1.4-0.5,2c-0.2,0.7-0.5,1.3-0.9,1.9
											c-0.2,0.3-0.4,0.6-0.7,0.9s-0.7,0.5-1.2,0.5s-0.9-0.3-1.2-0.5c-0.3-0.3-0.5-0.6-0.7-0.9c-0.4-0.6-0.6-1.3-0.8-2
											c-0.4-1.3-0.8-2.7-0.4-4.1L169.8,102.7L169.8,102.7z M169.8,102.7h-6.2c-0.2,0.7-0.2,1.4,0,2s0.5,1.3,0.8,1.9
											s0.6,1.2,1,1.7s0.9,0.9,1.2,0.9c0.4,0,0.8-0.4,1.2-0.9s0.6-1.1,0.9-1.7C169.4,105.3,170.1,104.1,169.8,102.7z"/>
						</g>
						<path class="st1" d="M166.7,101.1c2.1,0,3.1,1.6,3.1,1.6h-6.2C163.6,102.7,164.6,101.1,166.7,101.1z" />
					</g>
					<g>
						<path class="st32" d="M292.7,106.4l-64.1,2.4l-15.5-0.1c-0.5,0-47.4-1.2-47.5-1.9c-0.1-0.7,44.2-5,44.8-5l18-0.8l64.2,0.5
										c1.3-0.1,3.1,2.4,3.1,2.4S294,106.3,292.7,106.4z"/>
						<path class="st2" d="M292.7,107l-64.6,2.2L212,109l-16.1-0.5l-16.1-0.6l-8.1-0.4c-1.3-0.1-2.7-0.2-4-0.3l-1-0.1l-0.5-0.1
										c-0.1,0-0.2,0-0.3,0h-0.1h-0.1c0,0-0.1,0-0.2-0.2c-0.1-0.1,0.1-0.3,0.1-0.3h0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.2,0,0.3-0.1
										l0.5-0.1l1-0.2c5.3-0.8,10.7-1.3,16-1.9l16.1-1.7c5.3-0.5,10.7-1.2,16.1-1.3l8.1-0.4l4-0.2c1.3,0,2.7,0,4,0l32.3,0.2h16.1
										h8.1h4c0.4,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.4,0.9,0.7c0.6,0.5,1,1,1.4,1.6l0.2,0.3L296,104l0,0c-0.4,0.6-0.8,1.1-1.3,1.6
										c-0.2,0.3-0.5,0.5-0.9,0.7C293.6,106.7,293.2,106.9,292.7,107z M292.7,105.8c0.3,0,0.8-0.4,1.2-0.8
										c0.4-0.4,0.8-0.9,1.1-1.4l0,0v0.7c-0.4-0.5-0.8-1-1.2-1.4c-0.5-0.4-1-0.7-1.4-0.7l-4-0.1l-8.1-0.1l-16.2-0.2l-32.3-0.3
										c-1.3,0-2.7-0.1-4,0l-4,0.2l-8.1,0.3c-5.4,0.1-10.7,0.7-16.1,1.2l-16.1,1.6c-5.4,0.6-10.7,1.1-16,1.9l-1,0.2L166,107
										c-0.1,0-0.2,0-0.2,0.1h-0.1l0,0l0.1-0.2c0-0.1-0.1-0.1-0.1-0.1l0,0h0.1c0.1,0,0.2,0,0.2,0l0.5,0.1l1,0.1
										c1.3,0.1,2.7,0.2,4,0.2l8.1,0.3l16.1,0.5l16.1,0.4l16.1,0.1L292.7,105.8z"/>
					</g>
					<path class="st2" d="M224.1,107.8c-1-0.7-1.4-1.9-1.3-3.1c0-0.6,0.2-1.1,0.4-1.7c0.2-0.5,0.6-1,1-1.4l-0.3,1.6
									c-0.1,0.5-0.2,1-0.2,1.5s0,1,0.1,1.5C223.8,106.8,224,107.3,224.1,107.8z"/>
					<path class="st2" d="M222.2,107.9c-0.9-0.7-1.3-2-1.2-3.1c0-0.6,0.2-1.1,0.4-1.6s0.5-1,0.9-1.4c-0.1,0.6-0.2,1.1-0.2,1.6
									c-0.1,0.5-0.1,1-0.1,1.5s0,1,0.1,1.5C222,106.8,222.1,107.3,222.2,107.9z"/>
					<path class="st2" d="M220.2,108c-0.4-0.4-0.7-0.9-0.8-1.4c-0.2-0.5-0.2-1.1-0.2-1.7s0.2-1.1,0.3-1.6c0.2-0.5,0.4-1,0.7-1.4
									c0,0.5-0.1,1.1-0.1,1.6s-0.1,1-0.1,1.5s0,1,0,1.5C220.2,106.9,220.2,107.4,220.2,108z"/>
					<path class="st2" d="M218.3,108.1c-0.8-0.8-1.1-2-1-3.1c0-0.6,0.1-1.1,0.3-1.6s0.4-1,0.8-1.4c0,0.6-0.1,1.1-0.1,1.6
									s-0.1,1-0.1,1.5s0,1,0,1.5C218.3,107,218.3,107.5,218.3,108.1z"/>
					<path class="st2" d="M230.7,101.9c1,0.7,1.4,1.9,1.3,3.1c0,0.6-0.2,1.1-0.4,1.7c-0.2,0.5-0.6,1-1,1.4l0.3-1.6
									c0.1-0.5,0.2-1,0.2-1.5s0-1-0.1-1.5C230.9,102.9,230.8,102.4,230.7,101.9z"/>
					<path class="st2" d="M232.6,101.8c0.9,0.7,1.3,2,1.2,3.1c0,0.6-0.2,1.1-0.4,1.6s-0.5,1-0.9,1.4c0.1-0.6,0.2-1.1,0.2-1.6
									c0.1-0.5,0.1-1,0.1-1.5s0-1-0.1-1.5C232.8,102.9,232.7,102.4,232.6,101.8z"/>
					<path class="st2" d="M234.5,101.7c0.4,0.4,0.7,0.9,0.8,1.4c0.2,0.5,0.2,1.1,0.2,1.7s-0.2,1.1-0.3,1.6
									c-0.2,0.5-0.4,1-0.7,1.4c0-0.5,0.1-1.1,0.1-1.6s0.1-1,0.1-1.5s0-1,0-1.5C234.6,102.8,234.5,102.3,234.5,101.7z"/>
					<path class="st2" d="M236.4,101.6c0.8,0.8,1.1,2,1,3.1c0,0.6-0.1,1.1-0.3,1.6s-0.4,1-0.8,1.4c0-0.6,0.1-1.1,0.1-1.6
									s0.1-1,0.1-1.5s0-1,0-1.5C236.5,102.7,236.4,102.2,236.4,101.6z"/>
				</g>
				<g>
					<path class="st13" d="M278.6,111.5c-1.9-1.2-3.9-1.1-5.8-1.3c-2-0.2-3.9-0.4-5.9-0.5c-3.8-0.2-7.6-0.4-11.5-0.5l-7.9-2
									c-2.9-0.7-5.8-1.4-8.7-2c-1.4-0.7-2.8-1.4-4.2-2.1c-1.7-0.8-3.4-1.7-5.1-2.5c-1.8-0.8-4-0.3-5.3,1.3c-1.4,1.9-0.5,3.2,0.9,5
									c1.5,1.8,3,3.3,4.5,4.4s3.1,2.2,4.6,3.3c0.1,0.1,0.3,0.2,0.5,0.3l0.7,0.3c2.9,1.1,5.7,2.2,8.6,3.2l8.6,3.1
									c0.2,0.1,0.5,0.1,0.8,0.2l0.6,0.1c3.9,0.5,7.9,1,11.8,1.3c2,0.2,3.9,0.3,5.9,0.4s3.9,0.5,6-0.4"/>
					<path class="st2" d="M278.6,111.5c-1.8-0.8-3.7-0.7-5.7-0.8c-2-0.1-3.9-0.3-5.8-0.4l-11.7-0.5h-0.1h-0.1l-8.3-2l-8.3-1.9
									h-0.1h-0.1c-2.9-1.5-5.9-2.9-8.8-4.3c-0.4-0.2-0.7-0.3-1-0.4s-0.7-0.1-1-0.1c-0.7,0-1.4,0.2-2,0.6s-1.1,1-1.3,1.6
									c-0.3,0.6-0.2,1.2,0.1,1.8c0.3,0.6,0.8,1.2,1.4,1.9c0.5,0.6,1.1,1.2,1.6,1.8c1.1,1.2,2.3,2.1,3.7,3.1l2,1.4l1,0.7l0.5,0.4
									c0.2,0.1,0.3,0.1,0.4,0.2l4.6,1.7c3.1,1.2,6.1,2.3,9.2,3.4l2.3,0.8l1.2,0.4l0.6,0.2l0.5,0.1c1.6,0.2,3.2,0.4,4.8,0.6
									l4.9,0.6c1.6,0.2,3.3,0.4,4.9,0.5s3.2,0.2,4.9,0.4c1.6,0.2,3.2,0.4,4.8,0c-1.4,0.9-3.3,1-4.9,1s-3.3-0.2-4.9-0.3
									c-1.6-0.1-3.3-0.3-4.9-0.5l-4.9-0.5c-1.6-0.2-3.2-0.3-4.9-0.6l-0.7-0.2l-0.6-0.2l-1.2-0.4l-2.3-0.8
									c-3.1-1.1-6.2-2.2-9.3-3.4l-4.6-1.7c-0.2-0.1-0.5-0.3-0.7-0.4l-0.5-0.4l-1-0.7l-2-1.4c-0.7-0.5-1.4-0.9-2-1.5
									c-0.7-0.5-1.3-1.1-1.9-1.7c-0.6-0.6-1.1-1.2-1.7-1.9c-0.5-0.6-1.1-1.3-1.5-2.1c-0.2-0.4-0.4-0.9-0.4-1.4s0.1-1.1,0.3-1.5
									c0.4-0.9,1-1.6,1.8-2.1s1.7-0.8,2.7-0.8c0.5,0,0.9,0.1,1.4,0.2s0.9,0.3,1.2,0.5c3,1.4,5.9,2.9,8.9,4.3H239l8.3,2l8.3,2.1
									h-0.1l11.7,0.6c2,0.2,3.9,0.3,5.9,0.5c1,0.1,1.9,0.2,2.9,0.5C276.9,110.4,277.9,110.8,278.6,111.5z"/>
				</g>
			</g>
		</g>

	)
}
