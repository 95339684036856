import React from 'react'
import {Link} from 'react-router-dom'
import {first, second} from '../../../services/translation/i18n'

export const Header = (props) => {
    return (
        <>
            <div className="flex f-column f-center bg-black text-white py-1 relative" style={{zIndex: 5}}>
                <div className="w-xl-content">
                    <div className="mx-1 flex f-full-w f-center-h">
                        <div className="blockleft flex">
                            <Link to="/about" onClick={() => {
                                if (props.onLanguage) props.saveNewLanguage()
                            }}>
                                <div className="text-white text-uppercase text-bold text-16 text-spacing-1 mr-2">{first.t('bouton-about')}</div>
                                {first.language !== second.language ? <div
                                    className="text-white text-uppercase text-12 mt-03 text-spacing-1">{second.t('bouton-about')}</div> : ""}
                            </Link>
                        </div>
                        <Link to="/">
                            <div
                                className="text-white text-uppercase text-bold text-16 text-spacing-1">{first.t('langue')}</div>
                            {first.language !== second.language ? <div
                                className="text-white text-uppercase text-12 mt-03 text-spacing-1">{second.t('langue')}</div> : ""}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
