import React from 'react'
import {useSelector} from 'react-redux'

export const Hymen5 = (props) => {

    const r_allWhite = useSelector(state => state.parameters.allWhite)

    return (
        <path id="Hymen5" fill={r_allWhite ? 'white' : '#e79ac3'} className="st8"
              d="
              M 166.9 99 c -1 0 -5.6 2.7 -5.6 10.6 s 3.6 14.3 5.7 14.3 s 6.7 -6.4 6.7 -14.3 S 167.9 99 166.9 99 M 171.4 115 c -0.6927 2.4511 -3.3569 6.6605 -4.316 6.6605 c -1.0124 0 -3.2503 -3.1438 -3.943 -5.5415 c -0.4263 -1.492 -1.2788 -3.9963 -1.492 -6.0744 c -0.2131 -1.7051 0.8525 -0.4263 1.8649 1.2788 c 0.746 1.2255 0.9591 2.2912 1.2255 3.1438 c 0.2664 0.8525 -0.1066 2.3445 0.746 2.8241 c 1.8117 0.9591 1.2255 1.0657 1.7584 1.0657 c 0.5861 0 1.119 -2.6642 2.3978 -3.943 c 0.6927 -0.6927 0.6927 -1.9182 1.0657 -2.3445 c 0.746 -0.8525 0.746 -0.8525 1.0657 -1.8117 c 0.5861 -1.8117 0.9591 -0.746 0.9591 -0.5328 c -0.0533 2.0781 -0.9591 3.7832 -1.3321 5.2751 l -0.4 2.9999 Z
              "/>
    )
}
