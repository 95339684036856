import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresFermeLeftMediumRightBig = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="mediumLeft_x5F_bigRight">
                <path fill={r_labia_color} className="st--5" d="M185.1,109.5c-0.9,7.9-16.9,31.1-17.9,31.1s-11.7-23.2-12.6-31.1c-0.4-2.9-0.4-5.9,0-8.9
								c0.5-3.4,4.1-11.2,5.1-13.4c1.6-3.8,4.7-17.7,7.3-17.7s8,12.9,9.6,16.7c0.7,1.6,7.6,9.6,8.1,11.9
								C185.4,101.9,185.6,105.8,185.1,109.5z"/>
                <path class="st2" d="M185.4,109.5c-0.2,1.7-0.8,3.3-1.4,4.8c-0.6,1.5-1.3,3-2.1,4.5c-1.5,2.9-3.2,5.8-4.9,8.6
								c-1.7,2.8-3.5,5.6-5.4,8.3c-0.9,1.4-1.9,2.7-3,4c-0.3,0.3-0.5,0.6-0.9,1c-0.1,0.1-0.1,0.2-0.4,0.3c-0.1,0-0.2,0-0.3,0
								c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.3-0.4-0.4-0.6c-0.5-0.7-0.8-1.5-1.2-2.2
								c-2.9-5.9-5.5-12-7.7-18.2c-0.6-1.6-1.1-3.1-1.6-4.7c-0.5-1.6-0.9-3.2-1.2-4.8c-0.5-3.3-0.6-6.6-0.1-9.9
								c0.3-1.7,0.8-3.2,1.4-4.8c0.6-1.6,1.2-3.1,1.8-4.6c0.6-1.5,1.3-3.1,1.9-4.5c0.5-1.5,1-3.1,1.5-4.7c1-3.2,1.9-6.3,3.2-9.4
								c0.3-0.8,0.7-1.5,1.2-2.3c0.3-0.4,0.6-0.7,1.1-1c0.3-0.1,0.7-0.1,0.9,0c0.3,0.1,0.5,0.2,0.7,0.4c0.7,0.6,1.2,1.3,1.7,2
								c0.5,0.7,0.9,1.4,1.3,2.1c1.6,2.9,3,5.9,4.3,8.9l1,2.3l0.5,1.1c0.1,0.3,0.4,0.7,0.6,1c0.9,1.3,1.9,2.7,2.9,4
								c1,1.3,2,2.7,2.9,4c0.5,0.7,0.9,1.4,1.3,2.2c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.4,0.2,0.8,0.2,1.2
								C185.7,102.9,185.8,106.2,185.4,109.5z M184.9,109.5c0.4-3.2,0.3-6.5-0.3-9.8c-0.1-0.4-0.1-0.8-0.2-1.2
								c-0.1-0.4-0.2-0.7-0.4-1.1c-0.4-0.7-0.8-1.4-1.3-2.1c-0.9-1.3-1.9-2.7-2.9-4c-1-1.3-2-2.6-3-4c-0.2-0.4-0.5-0.7-0.7-1.1
								l-0.5-1.1l-1-2.3c-1.4-3-2.7-6-4.3-8.8c-0.4-0.7-0.8-1.4-1.3-2.1c-0.5-0.7-0.9-1.3-1.5-1.8c-0.3-0.2-0.6-0.3-0.7-0.3
								c-0.2,0.1-0.5,0.3-0.7,0.6c-0.4,0.6-0.8,1.3-1.1,2.1c-1.3,3-2.2,6.1-3.2,9.3c-0.5,1.6-0.9,3.2-1.5,4.7c-0.7,1.6-1.4,3-2,4.5
								c-0.7,1.5-1.3,3-1.9,4.5c-0.6,1.5-1.2,3.1-1.5,4.6c-0.5,3.2-0.5,6.5,0,9.8c0.6,3.2,1.7,6.3,2.8,9.4c1.2,3.1,2.4,6.1,3.8,9.1
								c1.3,3,2.7,6,4.3,8.9c0.4,0.7,0.8,1.4,1.2,2.1c0.1,0.2,0.2,0.3,0.3,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0,0-0.1,0
								c-0.1,0-0.1,0-0.2,0c-0.1,0,0,0,0.1-0.1c0.3-0.3,0.5-0.6,0.8-0.9c1-1.3,2-2.6,2.9-3.9c1.9-2.7,3.7-5.4,5.5-8.2
								c1.7-2.8,3.4-5.6,4.9-8.5c0.8-1.5,1.5-2.9,2.1-4.4C184.1,112.6,184.7,111.1,184.9,109.5z"/>
            </g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}