import React, { useEffect, useState } from "react";
import { first, second } from "../services/translation/i18n";
import { LanguageSelector } from "../components/languageSelector/languageSelector";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { GlobalLayout } from "../components/globallayout/globalLayout";
import { FiArrowLeft } from "react-icons/fi";

export default function About(props) {
  const r_action = useDispatch()
  const r_reload = useSelector((state) => state.language.reload);

  const loading = props.loading

  const history = useHistory()


  return (
    <GlobalLayout className="bg-pattern-1" loading={loading}>
      <div className="flex f-column f-center f-1 mb-4">
        <div className="w-xl-content flex f-full f-column f-1">
          <div onClick={() => history.goBack() ? history.goBack() : history.push('/')} className="block absolute text-white mt-1" style={{ left: '1em' }}> <FiArrowLeft size={30} /></div>
          <div className="css-style mx-1 mt-2">
            {first.t("about") && <div className="r bg-blured-back px-1 pb-2 pt-01 my-2 f-1" style={{ direction: first.dir() }} dangerouslySetInnerHTML={{
              __html: first.t("about")
            }}></div>}
            {first.language !== second.language && second.t("about") && <div className="r bg-blured-back px-1 pb-2 pt-1 my-2 f-1" style={{ direction: second.dir() }} dangerouslySetInnerHTML={{
              __html: second.t("about")
            }}></div>}
          </div>
        </div>
        <Link
          to="/"
          className="block fixed bg-secondary py-1 w-full flex f-center f-column"
          style={{ bottom: 0 }}
        >
          <div className="text-uppercase text-bolder text-white mt-05">
            {first.t("start")}
          </div>
          {first.language !== second.language ? <div className="text-uppercase text-white mb-05 mt-03">{second.t("start")}</div> : ""}
        </Link>
      </div>
    </GlobalLayout>
  );
}
