import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresLeftLittleRightMedium = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="littleLabiaLeft_x5F_mediumLabiaRigth">
                <path fill={r_labia_color} className="st--5" d="M183.4,109.7c-1.2,7.2-15,31.3-16.3,31.3s-10.5-24.1-11.7-31.3c-0.5-2.7-0.5-5.4,0-8.1
									c0.6-3.1,2.8-12,3.8-14.2c1.7-3.8,5.1-17.7,7.9-17.7s8.4,12.9,10.1,16.7c0.7,1.6,5,10.8,5.6,12.9
									C183.8,102.7,184,106.2,183.4,109.7z"/>
                <path class="st2" d="M183.8,109.8c-0.3,1.7-0.9,3.2-1.5,4.7c-0.6,1.5-1.3,3-1.9,4.5c-1.4,3-2.9,5.9-4.4,8.7
									c-1.5,2.9-3.1,5.7-4.8,8.5c-0.9,1.4-1.7,2.8-2.7,4.1c-0.3,0.3-0.5,0.6-0.9,1l-0.1,0.1c-0.1,0-0.2,0.1-0.3,0.1
									c-0.1,0-0.2,0-0.3-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.2-0.4-0.4-0.6c-0.4-0.7-0.8-1.5-1.1-2.2
									c-2.6-6-4.8-12.1-6.9-18.3c-0.5-1.5-1-3.1-1.4-4.7c-0.5-1.6-0.9-3.1-1.3-4.7c-0.7-3.2-0.8-6.6-0.1-9.8
									c0.7-3.2,1.4-6.4,2.2-9.5c0.4-1.6,0.9-3.2,1.5-4.7c0.6-1.5,1.1-3.1,1.6-4.6c1-3.1,2-6.2,3.4-9.2c0.4-0.7,0.7-1.5,1.2-2.2
									c0.3-0.4,0.5-0.7,1-1c0.2-0.1,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.2c0.9,0.5,1.4,1.1,2,1.8c0.5,0.7,1,1.4,1.4,2
									c3.5,5.6,5.8,11.7,8.5,17.6l2,4.5c0.6,1.5,1.3,3,1.7,4.6C184.2,103.1,184.4,106.5,183.8,109.8z M183.1,109.6
									c0.5-3.2,0.4-6.4-0.4-9.5c-0.4-1.5-1.1-3-1.7-4.5l-2-4.4c-2.8-5.9-5.2-11.9-8.6-17.5c-0.4-0.7-0.9-1.3-1.4-2
									c-0.5-0.6-1-1.2-1.6-1.5c-0.3-0.1-0.4-0.1-0.7,0.1c-0.3,0.2-0.5,0.5-0.7,0.8c-0.4,0.6-0.8,1.3-1.2,2c-1.3,2.9-2.3,6-3.3,9.1
									c-0.5,1.5-1,3.1-1.6,4.6c-0.7,1.5-1.1,3-1.6,4.6c-0.9,3.1-1.7,6.3-2.5,9.4c-0.7,3.1-0.8,6.4-0.1,9.6
									c0.7,3.2,1.7,6.3,2.7,9.3c2.2,6.1,4.5,12.2,7.2,18.1c0.4,0.7,0.7,1.5,1.1,2.1c0.1,0.2,0.2,0.3,0.3,0.5
									c0.1,0.1,0.1,0.1,0.1,0.2l0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l0,0c0.2-0.1,0.5-0.5,0.7-0.8c0.9-1.3,1.8-2.7,2.7-4
									c1.7-2.8,3.3-5.6,4.9-8.4c1.5-2.9,3-5.7,4.4-8.7c0.7-1.5,1.4-2.9,2-4.4C182.2,112.7,182.8,111.2,183.1,109.6z"/>
            </g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}