import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresFermeLeftBigRightMedium = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="bigLeft_x5F_mediumRight">
							<path fill={r_labia_color} className="st--5" d="M181.1,109.5c-0.9,7.9-12.9,31.1-13.9,31.1s-15.7-23.2-16.6-31.1c-0.4-2.9-0.4-5.9,0-8.9
								c0.5-3.4,5.1-11.2,6.1-13.4c1.6-3.8,7.7-17.7,10.3-17.7s6,12.9,7.6,16.7c0.7,1.6,5.6,9.6,6.1,11.9
								C181.4,101.9,181.6,105.8,181.1,109.5z"/>
							<path class="st2" d="M181.4,109.5c-0.2,1.7-0.7,3.3-1.2,4.8c-0.5,1.6-1.1,3.1-1.7,4.7c-1.2,3.1-2.5,6.1-3.9,9.1
								c-1.4,3-2.9,5.9-4.4,8.8c-0.4,0.7-0.8,1.4-1.2,2.2c-0.2,0.4-0.4,0.7-0.7,1.1c-0.1,0.2-0.2,0.3-0.4,0.5
								c-0.1,0.1-0.1,0.2-0.3,0.3c0,0-0.2,0.1-0.3,0.1c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.7-0.7-1.1-1.3-1.6-2
								c-0.5-0.7-0.9-1.4-1.4-2.1c-3.6-5.5-6.8-11.3-9.7-17.2c-0.7-1.5-1.4-3-2-4.5c-0.6-1.5-1.2-3.1-1.5-4.7
								c-0.5-3.3-0.6-6.6-0.2-9.9c0.3-1.7,1-3.2,1.6-4.7c0.7-1.5,1.4-3,2.1-4.5c0.7-1.5,1.5-2.9,2.2-4.4c0.6-1.5,1.3-3,2-4.5
								c1.4-3,2.8-6,4.5-8.8c0.4-0.7,0.9-1.4,1.4-2.1c0.5-0.7,1-1.4,1.7-1.9c0.2-0.1,0.4-0.3,0.7-0.4c0.3-0.1,0.7-0.1,0.9,0.1
								c0.5,0.2,0.8,0.6,1.1,0.9c0.5,0.7,0.9,1.5,1.3,2.2c1.4,3,2.4,6.2,3.5,9.3c0.5,1.6,1,3.2,1.6,4.6c0.7,1.4,1.5,2.9,2.3,4.3
								c0.8,1.4,1.6,2.9,2.3,4.4c0.4,0.7,0.7,1.5,1,2.3c0.3,0.8,0.4,1.7,0.6,2.5C181.7,102.9,181.8,106.3,181.4,109.5z M180.9,109.5
								c0.4-3.2,0.3-6.5-0.3-9.7c-0.2-0.8-0.3-1.6-0.6-2.3c-0.3-0.7-0.7-1.5-1-2.2c-0.7-1.5-1.5-2.9-2.4-4.3
								c-0.8-1.4-1.6-2.8-2.4-4.3c-0.7-1.6-1.2-3.1-1.7-4.7c-1.1-3.1-2-6.2-3.4-9.2c-0.4-0.7-0.7-1.4-1.2-2
								c-0.2-0.3-0.5-0.6-0.7-0.7c-0.1,0-0.2-0.1-0.3,0c-0.1,0-0.3,0.1-0.4,0.2c-0.6,0.4-1.1,1.1-1.5,1.7c-0.5,0.7-0.9,1.3-1.3,2
								c-1.7,2.8-3.1,5.8-4.5,8.7l-2,4.5c-0.7,1.5-1.5,2.9-2.3,4.4c-0.8,1.4-1.5,2.9-2.2,4.4c-0.7,1.5-1.4,3-1.7,4.5
								c-0.5,3.2-0.5,6.5,0,9.7c0.7,3.2,2.1,6.2,3.6,9.1c3,5.9,6.3,11.5,10,17c0.5,0.7,0.9,1.4,1.4,2c0.5,0.6,1,1.4,1.5,1.8
								c0,0,0.1,0,0.1,0c0,0-0.3,0-0.3,0c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.3,0.3-0.5c0.2-0.3,0.4-0.7,0.6-1l1.2-2.1
								c1.6-2.9,3.1-5.8,4.5-8.7c1.4-3,2.8-5.9,4-9c0.6-1.5,1.2-3,1.7-4.6C180.2,112.6,180.7,111.1,180.9,109.5z"/>
						</g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}