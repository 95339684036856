import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresFermeLeftLittleRightMedium = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="littleLeft_x5F_mediumRight">
							<path fill={r_labia_color} className="st--5" d="M181.1,109.5c-0.9,7.9-12.9,31.1-13.9,31.1s-7.9-23.2-8.8-31.1c-0.4-2.9-0.4-5.9,0-8.9
								c0.5-3.4,0.2-11.2,1.2-13.4c1.6-3.8,4.8-17.7,7.4-17.7s6,12.9,7.6,16.7c0.7,1.6,5.5,9.6,6,11.9
								C181.4,101.9,181.6,105.8,181.1,109.5z"/>
							<path class="st2" d="M181.4,109.5c-0.2,1.6-0.6,3.1-1.1,4.7c-0.5,1.5-1,3-1.6,4.5c-1.2,3-2.4,5.9-3.8,8.8
								c-1.3,2.9-2.7,5.7-4.2,8.5c-0.8,1.4-1.5,2.8-2.4,4.1c-0.1,0.2-0.2,0.3-0.4,0.5c-0.1,0.1-0.1,0.2-0.3,0.3l-0.1,0
								c0,0-0.1,0-0.2,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.2-0.2c-0.3-0.4-0.4-0.8-0.6-1.2c-0.3-0.7-0.6-1.5-0.8-2.3
								c-2-6-3.7-12.2-5.2-18.3c-0.4-1.5-0.7-3.1-1-4.6c-0.3-1.6-0.6-3.1-0.9-4.7c-0.5-3.1-0.5-6.4-0.1-9.5c0.3-3.1,0.2-6.3,0.4-9.5
								c0.1-0.8,0.1-1.6,0.3-2.4c0.1-0.4,0.2-0.8,0.4-1.2c0.1-0.3,0.3-0.7,0.4-1.1c0.5-1.5,1-3,1.4-4.5c0.9-3,1.8-6.1,3.1-9
								c0.3-0.7,0.7-1.5,1.2-2.2c0.3-0.3,0.5-0.7,1-1c0.2-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6,0.2,0.8,0.3c0.8,0.6,1.2,1.3,1.6,2
								c0.4,0.7,0.7,1.4,1.1,2.2c1.2,3,2.2,6,3.2,9c0.5,1.5,0.9,3,1.7,4.4l2.3,4.2c0.8,1.4,1.5,2.8,2.2,4.2c0.3,0.7,0.7,1.4,0.9,2.2
								c0.2,0.8,0.3,1.6,0.5,2.4C181.7,103.1,181.8,106.4,181.4,109.5z M180.9,109.5c0.4-3.1,0.3-6.3-0.2-9.4
								c-0.1-0.8-0.3-1.6-0.5-2.3c-0.2-0.7-0.6-1.4-0.9-2.1c-0.7-1.4-1.5-2.8-2.2-4.2l-2.3-4.1c-0.2-0.3-0.4-0.7-0.6-1.1
								c-0.2-0.4-0.3-0.8-0.4-1.1l-0.8-2.3c-1.1-3-2-6-3.2-8.9c-0.3-0.7-0.6-1.4-1-2.1c-0.4-0.7-0.8-1.3-1.3-1.7
								c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.1,0-0.3,0.1c-0.2,0.1-0.5,0.4-0.7,0.7c-0.4,0.6-0.8,1.3-1.1,2c-1.3,2.9-2.2,5.9-3.1,8.9
								c-0.5,1.5-0.9,3-1.5,4.5c-0.1,0.4-0.3,0.7-0.4,1.1c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.7-0.2,1.5-0.3,2.3
								c-0.3,3.1-0.3,6.3-0.6,9.5c-0.5,3.1-0.6,6.3-0.1,9.4c0.4,3.1,1.1,6.2,1.9,9.3c1.6,6.1,3.4,12.2,5.5,18.2
								c0.3,0.7,0.5,1.5,0.9,2.2c0.2,0.3,0.3,0.7,0.5,1c0,0,0,0,0,0c-0.1-0.1-0.2,0-0.2,0c0,0-0.1,0-0.1,0l0,0c0,0,0.1-0.1,0.1-0.1
								c0.1-0.1,0.2-0.3,0.3-0.4c0.8-1.3,1.6-2.7,2.4-4.1c1.5-2.8,2.9-5.6,4.3-8.4c1.4-2.9,2.7-5.7,3.9-8.7
								C179.2,115.5,180.4,112.6,180.9,109.5z"/>
						</g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}