const initialState = {
    step: 0,
    skinColor: '#bf9676',
    labiaColor: '#d866a3',
    allWhite: false,
    hairColor: '#000000',
    typeHair: 0,
    isClosed: true,
    showHymen: true,
    loadingLanguage: false,
    switchTranslate: false,
    typeHymen: 1,
    skinOpacity: 1,
    labiaType: 'littleBoth',

    fgm_type: 0,
    fgm_subType: 'a',
    surgerie: 0,

    animationOpacityFGM: 0,
    animationOpacityNormal: 1,

    fgmAll: [
        {
            title: 'ecran2-mgf-type-1',
            content: 'ecran2-mgf-type-1-description',
            btnChoice: 'ecran2-mgf-type-1-choix',
            fgm: 1,
            subTypes: [
                {
                    subType: 'a',
                    title: 'ecran4-type1a',
                    content: 'ecran4-type1a-description',
                    animation: {
                        0: { show: ['normal'], hide: ['fgm'] },
                        1: { show: ['Clipping_Path_Ferme', 'Glans1a'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                    },
                    nocare: true,
                },
                {
                    subType: 'b',
                    title: 'ecran4-type1b',
                    content: 'ecran4-type1b-description',
                    animation: {
                        0: { show: ['normal'], hide: ['fgm'] },
                        1: { show: ['Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                    },
                    care0: {
                        0: { show: ['Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                    },
                    care1: {
                        0: { show: ['Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                        1: { show: ['Desinfection', 'Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                        2: { show: ['SurgerieGlans', 'IncisionGlans', 'Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                        3: { show: ['SurgerieGlans', 'Ligament', 'Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                        //4: { show: ['SurgerieGlans', 'SutureGlans', 'Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                        5: { show: ['SuturePeau', 'Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                        6: { show: ['SoinGlans', 'Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme'] },
                    },
                }
            ]
        },
        {
            title: 'ecran2-mgf-type-2',
            content: 'ecran2-mgf-type-2-description',
            btnChoice: 'ecran2-mgf-type-2-choix',
            fgm: 2,
            subTypes: [
                {
                    subType: 'a',
                    title: 'ecran4-type2a',
                    content: 'ecran4-type2a-description',
                    animation: {
                        0: { show: ['normal'], hide: ['fgm'] },
                        1: { show: ['Clipping_Path_Ferme', 'Prepuce2a', 'Prepuce2aFerme', 'Glans'], hide: ['Prepuce', 'PrepuceFerme'] },
                        2: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'Glans'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme'] }
                    },
                    nocare: true,
                },
                {
                    subType: 'b',
                    title: 'ecran4-type2b',
                    content: 'ecran4-type2b-description',
                    animation: {
                        0: { show: ['normal'], hide: ['fgm'] },
                        1: { show: ['Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme',] },
                        2: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] }
                    },
                    care0: {
                        0: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] }
                    },
                    care1: {
                        0: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        1: { show: ['Desinfection', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        2: { show: ['SurgerieGlans', 'IncisionGlans', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        3: { show: ['SurgerieGlans', 'Ligament', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        //3: { show: ['SurgerieGlans', 'SutureGlans', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        4: { show: ['SuturePeau', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        5: { show: ['SoinGlans', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                    },
                },
                {
                    subType: 'c',
                    title: 'ecran4-type2c',
                    content: 'ecran4-type2c-description',
                    animation: {
                        0: { show: ['normal'], hide: ['fgm'] },
                        1: { show: ['Clipping_Path_Ferme', 'CicatriceExcision'], hide: ['Glans', 'Prepuce', 'PrepuceFerme',] },
                        2: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        3: { show: ['Clipping_Path_Ferme', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'GrandeLevreG2C', 'GrandeLevreD2C', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] }
                    },
                    care0: {
                        0: { show: ['Clipping_Path_Ferme', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'GrandeLevreG2C', 'GrandeLevreD2C', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] }
                    },
                    care1: {
                        0: { show: ['Clipping_Path_Ferme', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'GrandeLevreG2C', 'GrandeLevreD2C', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        1: { show: ['Desinfection', 'Clipping_Path_Ferme', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'GrandeLevreG2C', 'GrandeLevreD2C', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        2: { show: ['SurgerieGlans', 'IncisionGlans', 'Clipping_Path_Ferme', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'GrandeLevreG2C', 'GrandeLevreD2C', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        3: { show: ['SurgerieGlans', 'Ligament', 'Clipping_Path_Ferme', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'GrandeLevreG2C', 'GrandeLevreD2C', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        //3: { show: ['SurgerieGlans', 'SutureGlans', 'Clipping_Path_Ferme', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'GrandeLevreG2C', 'GrandeLevreD2C', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        4: { show: ['SuturePeau', 'Clipping_Path_Ferme', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'GrandeLevreG2C', 'GrandeLevreD2C', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        5: { show: ['SoinGlans', 'Clipping_Path_Ferme', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'GrandeLevreG2C', 'GrandeLevreD2C', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                    },
                }
            ]
        },
        {
            title: 'ecran2-mgf-type-3',
            content: 'ecran2-mgf-type-3-description',
            btnChoice: 'ecran2-mgf-type-3-choix',
            fgm: 3,
            subTypes: [
                {
                    subType: 'a',
                    title: 'ecran4-type3a',
                    content: 'ecran4-type3a-description',
                    animation: {
                        0: { show: ['normal'], hide: ['fgm'] },
                        1: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme'] },
                        2: { show: ['PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3intermediaire', 'Clipping_Path_type_3_intermediaire'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                        3: { show: ['PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3intermediaire', 'Suture3a', 'Clipping_Path_type_3_intermediaire'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                        4: { show: ['PetiteLevres3Final', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                    },
                    care0: {
                        0: { show: ['PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'],
                            hide: ['Prepuce2a', 'Prepuce2aFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                        1: { show: ['Desinfection', 'PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'], hide: ['Prepuce2a', 'Prepuce2aFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                        2: { show: ['Anestesie', 'PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'], hide: ['Prepuce2a', 'Prepuce2aFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                        3: { show: ['Incision', 'PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'], hide: ['Prepuce2a', 'Prepuce2aFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                        4: { show: ['SutureLabia', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Prepuce2a', 'Prepuce2aFerme',], hide: ['PetitesLevresFond', 'PetiteLevresFerme'], open: true, translateMask: false },
                        5: { show: ['SoinLabia', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Prepuce2a', 'Prepuce2aFerme',], hide: ['PetitesLevresFond', 'PetiteLevresFerme'], open: true, translateMask: false },
                        6: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Prepuce2a', 'Prepuce2aFerme',], hide: ['PetitesLevresFond', 'PetiteLevresFerme'], open: true, translateMask: false },
                    },
                    care1: {
                        0: { show: ['PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'], hide: ['Prepuce2a', 'Prepuce2aFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                    },
                },
                {
                    subType: 'c',
                    title: 'ecran4-type3c',
                    content: 'ecran4-type3c-description',
                    animation: {
                        0: { show: ['normal'], hide: ['fgm'] },
                        1: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme'] },
                        2: { show: ['PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3intermediaire', 'Clipping_Path_type_3_intermediaire', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme'], close: true },
                        3: { show: ['PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3intermediaire', 'Suture3b', 'Clipping_Path_type_3_intermediaire', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme'], close: true },
                        4: { show: ['PetiteLevres3Final', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme'], close: true, translateMask: true },
                    },
                    care0: {
                        0: { show: ['PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'],
                            hide: ['Glans','Prepuce2a', 'Prepuce2aFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                        1: { show: ['Desinfection', 'PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'], hide: ['Prepuce2a', 'Prepuce2aFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'Glans'], close: true },
                        2: { show: ['Anestesie', 'PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'], hide: ['Prepuce2a', 'Prepuce2aFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'Glans'], close: true },
                        3: { show: ['Incision', 'PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final'], hide: ['Prepuce2a', 'Prepuce2aFerme', 'PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'Glans'], close: true },
                        4: { show: ['SutureLabia', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'CicatriceExcision',], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Glans'], open: true, translateMask: false },
                        5: { show: ['SoinLabia', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'CicatriceExcision',], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Glans'], open: true, translateMask: false },
                        6: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'CicatriceExcision',], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Glans'], open: true, translateMask: false },
                    },
                    care1: {
                        0: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Glans'], open: true, translateMask: false },
                        1: { show: ['Desinfection', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        2: { show: ['SurgerieGlans', 'IncisionGlans', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        3: { show: ['SurgerieGlans', 'Ligament', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        4: { show: ['SuturePeau', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        5: { show: ['SoinGlans', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                    },
                },
                {
                    subType: 'b',
                    title: 'ecran4-type3b',
                    content: 'ecran4-type3b-description',
                    animation: {
                        0: { show: ['normal'], hide: ['fgm'] },
                        1: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        2: { show: ['PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3intermediaire', 'Clipping_Path_type_3_intermediaire', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                        3: { show: ['PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3intermediaire', 'Suture3a', 'Clipping_Path_type_3_intermediaire', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme'], close: true },
                        4: { show: ['PetiteLevres3Final', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3final', 'Cicatrice3', 'Clipping_Path_type_3_final', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'Glans'], close: true },
                    },
                    care0: {
                        0: { show: ['PetiteLevres3Final', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans'], close: true, translateMask: true },
                        1: { show: ['Desinfection', 'PetiteLevres3Final', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans'], close: true, translateMask: true },
                        2: { show: ['Anestesie', 'PetiteLevres3Final', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans'], close: true, translateMask: true },
                        3: { show: ['Incision', 'PetiteLevres3Final', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans'], close: true, translateMask: true },
                        4: { show: ['SutureLabia', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandLevreG2C', 'GrandLevreD2C', 'Prepuce2a', 'Prepuce2aFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme'], open: true, translateMask: false },
                        5: { show: ['SoinLabia', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandLevreG2C', 'GrandLevreD2C', 'Prepuce2a', 'Prepuce2aFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme'], open: true, translateMask: false },
                        6: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandLevreG2C', 'GrandLevreD2C', 'Prepuce2a', 'Prepuce2aFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme'], open: true, translateMask: false },
                    },
                    care1: {
                        0: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Glans', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Glans'], open: true, translateMask: false },
                        1: { show: ['Desinfection', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        2: { show: ['SurgerieGlans', 'IncisionGlans', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        3: { show: ['SurgerieGlans', 'Ligament', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        4: { show: ['SuturePeau', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        5: { show: ['SoinGlans', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                    },
                },
                {
                    subType: 'd',
                    title: 'ecran4-type4',
                    content: 'ecran4-type4b-description',
                    animation: {
                        0: { show: ['normal'], hide: ['fgm'] },
                        1: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        2: { show: ['PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3intermediaire', 'Clipping_Path_type_3_intermediaire', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans'], close: true },
                        3: { show: ['PetitesLevres2aferme', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3intermediaire', 'Suture3b', 'Clipping_Path_type_3_intermediaire', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans'], close: true },
                        4: { show: ['PetiteLevres3Final', 'Prepuce2a', 'Prepuce2aFerme', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans'], close: true, translateMask: true },
                    },
                    care0: {
                        0: { show: ['PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans', 'Prepuce2a', 'Prepuce2aFerme'], close: true, translateMask: true },
                        1: { show: ['Desinfection', 'PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans', 'Prepuce2a', 'Prepuce2aFerme'], close: true, translateMask: true },
                        2: { show: ['Anestesie', 'PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans', 'Prepuce2a', 'Prepuce2aFerme'], close: true, translateMask: true },
                        3: { show: ['Incision', 'PetiteLevres3Final', 'ContourVestibuleType3final', 'Cicatrice3', 'Pont', 'Clipping_Path_type_3_final', 'GrandLevreG2cFerme', 'GrandLevreD2cFerme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'ContourVestibuleFerme', 'ContourVestibule', 'Clipping_Path_Ferme', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans', 'Prepuce2a', 'Prepuce2aFerme'], close: true, translateMask: true },
                        4: { show: ['SutureLabia', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision', 'GrandLevreG2C', 'GrandLevreD2C'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans', 'Prepuce2a', 'Prepuce2aFerme'], open: true, translateMask: false },
                        5: { show: ['SoinLabia', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision', 'GrandLevreG2C', 'GrandLevreD2C'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans', 'Prepuce2a', 'Prepuce2aFerme'], open: true, translateMask: false },
                        6: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision', 'GrandLevreG2C', 'GrandLevreD2C'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans', 'Prepuce2a', 'Prepuce2aFerme'], open: true, translateMask: false },
                    },
                    care1: {
                        0: { show: ['Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision', 'GrandLevreG2C', 'GrandLevreD2C'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'GrandeLevreD', 'GrandeLevreG', 'GrandeLevreDFerme', 'GrandeLevreGFerme', 'Glans', 'Prepuce2a', 'Prepuce2aFerme'], open: true, translateMask: false },
                        1: { show: ['Desinfection', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        2: { show: ['SurgerieGlans', 'IncisionGlans', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        3: { show: ['SurgerieGlans', 'Ligament', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        4: { show: ['SuturePeau', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                        5: { show: ['SoinGlans', 'Clipping_Path_Ferme', 'PetitesLevre2a', 'PetitesLevres2aferme', 'CicatriceExcision'], hide: ['PetitesLevresFond', 'PetiteLevresFerme', 'Prepuce', 'PrepuceFerme', 'Glans'] },
                    },
                },
            ]
        },
        {
            title: 'ecran2-mgf-type-4',
            content: 'ecran2-mgf-type-4-description',
            btnChoice: 'ecran2-mgf-type-4-choix',
            link: '/care?fgm=true',
            fgm: 4,
            subTypes: []
        }
    ],
    chirurgie: [
        {
            label: 'ecran5-chirurgie',
            content: 'ecran5-chirurgie-description',

            animation: {
                0: { show: ['normal'], hide: ['fgm'] },
            }

        },
        {
            label: 'ecran5-reconstruction',
            content: 'ecran5-reconstruction-description',

            animation: {
                0: { show: ['normal'], hide: ['fgm'] },
            }

        },
    ],

    animationStep: 0
}

export default function (state = initialState, action) {

    let opacityFGM = 0
    let opacityNormal = 1



    switch (action.type) {
        case 'SET_STEP':
            return { ...state, step: action.step }

        case 'SET_SKIN_COLOR':
            if (action.color) {
                action.color !== 'white' && localStorage.setItem("skinColor", JSON.stringify({ skinColor: action.color }))
                return { ...state, skinColor: action.color }
            }
        case 'SET_LABIA_COLOR':
            if (action.color) {
                action.color !== 'white' && localStorage.setItem("labiaColor", JSON.stringify({ labiaColor: action.color }))
                return { ...state, labiaColor: action.color }
            }
        case 'SET_SVG_WHITE':
            return { ...state, allWhite: action.allWhite }
        case 'SET_HAIR_COLOR':
            if (action.color) {
                localStorage.setItem("hairColor", JSON.stringify({ hairColor: action.color }))
                return { ...state, hairColor: action.color }
            }
        case 'SET_TYPE_HAIR':
            if (action.typeHair) {
                localStorage.setItem("typeHair", JSON.stringify({ typeHair: action.typeHair }))
                return { ...state, typeHair: action.typeHair }
            }

        case 'SET_LOADING_LANGUAGE':
            localStorage.setItem("loadingLanguage", JSON.stringify({ loadingLanguage: action.loadingLanguage }))
            return { ...state, loadingLanguage: action.loadingLanguage }
        case 'SET_SWITCH_LANGUAGE':
            localStorage.setItem("switchTranslate", JSON.stringify({ loadingLanguage: action.switchTranslate }))
            return { ...state, loadingLanguage: action.switchTranslate }
        case 'SET_IS_CLOSED':
            localStorage.setItem("isClosed", JSON.stringify({ isClosed: action.isClosed }))
            return { ...state, isClosed: action.isClosed }

        case 'SET_SHOW_HYMEN':
            localStorage.setItem("showHymen", JSON.stringify({ showHymen: action.showHymen }))
            return { ...state, showHymen: action.showHymen }
        case 'SET_TYPE_HYMEN':
            localStorage.setItem("typeHymen", JSON.stringify({ typeHymen: action.typeHymen }))
            return { ...state, typeHymen: action.typeHymen }

        case 'SET_LABIA_TYPE':
            localStorage.setItem("labiaType", JSON.stringify({ labiaType: action.labiaType }))
            return { ...state, labiaType: action.labiaType }

        case 'SET_SKIN_OPACITY':
            localStorage.setItem("skinOpacity", JSON.stringify({ skinOpacity: action.opacity }))
            return { ...state, skinOpacity: action.opacity }

        case 'SET_FGM_TYPE':
            localStorage.setItem("fgm_type", JSON.stringify({ fgm_type: action.fgm_type }))
            return { ...state, fgm_type: action.fgm_type }
        case 'SET_FGM_SUB_TYPE':
            localStorage.setItem("fgm_subType", JSON.stringify({ fgm_subType: action.fgm_subType }))
            return { ...state, fgm_subType: action.fgm_subType }

        case 'SET_FGM_SURGERIE':
            localStorage.setItem("surgerie", JSON.stringify({ surgerie: action.surgerie }))
            return { ...state, surgerie: action.surgerie }

        case 'SET_OPACITY_FGM':
            opacityFGM = action.newOpacity / 100
            opacityNormal = 1 - opacityFGM
            return { ...state, animationOpacityFGM: opacityFGM, animationOpacityNormal: opacityNormal }

        case 'SET_ANIMATION_STEP':
            return { ...state, animationStep: action.animationStep }

        default:
            return state

    }
}
