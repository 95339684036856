import React from 'react'
import { useSelector } from 'react-redux'

export const GrandLevreD2cFerme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="GrandLevreD2cFerme" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                    <path className="st2" d="M172.8,69.8c1.9,2.5,3.2,5.5,3.8,8.6c0.7,3.1,1.1,6.2,1.3,9.4c0.4,6.3,0.4,12.6-0.1,18.9
					c-0.4,6.3-1.1,12.6-2,18.8c-0.5,3.1-1,6.2-1.6,9.3c-0.3,1.5-0.6,3.1-1,4.6c-0.3,1.6-0.8,3.1-1.3,4.6c0.9-3,1.5-6.1,2-9.2
					c0.5-3.1,1-6.2,1.4-9.3c0.8-6.2,1.5-12.5,1.8-18.8c0.4-6.3,0.5-12.6,0.2-18.9c-0.2-3.1-0.5-6.3-1.2-9.4
					C175.9,75.3,174.7,72.3,172.8,69.8z"/>
                </g>
    )
}