import React, {useState} from 'react'
import {first, second} from '../services/translation/i18n'
import {StepsParameters} from '../components/stepsParameters/stepsParameters'
import {ModalParameters} from '../components/modalParameters/modalParameters'
import {SkinColor} from '../components/parametersContent/skinColor'
import {LabiaColor} from '../components/parametersContent/labiaColor'
import {useDispatch} from 'react-redux'
import {useEffect} from 'react'
import {FiArrowLeft} from "react-icons/fi";
import {Link} from 'react-router-dom'
import {GlobalLayout} from '../components/globallayout/globalLayout'
import {BlockIllu} from '../components/svg/blockIllu'
import {Hymens} from '../components/parametersContent/hymens'
import {LabiaType} from '../components/parametersContent/labiaType'
import {HairType} from '../components/parametersContent/hairType'
import {HairColor} from '../components/parametersContent/hairColor'


export default function VuvleParameters(props) {
    const r_action = useDispatch()

    const [displayNavigation, setDisplayNavigation] = useState(true)

    const loading = props.loading

    const listParameters = [
        {
            img: '',
            title: 'ecran1-couleurpeau',
            content: <SkinColor/>
        },
        {
            img: '',
            title: 'ecran1-pilosite',
            content: <HairType/>
        },
        {
            img: '',
            title: 'ecran1-couleur-pilosite',
            content: <HairColor/>
        },
        {
            img: '',
            title: 'ecran1-hymen',
            content: <Hymens/>
        },
        {
            img: '',
            title: 'ecran1-couleur-levres',
            content: <LabiaColor/>
        },
        {
            img: '',
            title: 'ecran1-taille-levres',
            content: <LabiaType/>
        },
    ]

    const isModalOpen = (bool) => {
        setDisplayNavigation(!bool)
    }

    useEffect(() => {
        r_action({type: 'SET_STEP', step: 1})
        r_action({type: 'SET_FGM_TYPE', fgm_type: 0})
        r_action({type: 'SET_FGM_SUB_TYPE', fgm_subType: 'a'})
        r_action({type: 'SET_ANIMATION_STEP', animationStep: 0})

        if (localStorage.getItem('skinColor')) {
            let skinColor = JSON.parse(localStorage.getItem('skinColor'))
            r_action({type: 'SET_SKIN_COLOR', color: skinColor.skinColor})
        } else {
            r_action({type: 'SET_SKIN_COLOR', color: '#bf9676'})
        }
        if (localStorage.getItem('labiaColor')) {
            let labiaColor = JSON.parse(localStorage.getItem('labiaColor'))
            r_action({type: 'SET_LABIA_COLOR', color: labiaColor.labiaColor})
        } else {
            r_action({type: 'SET_LABIA_COLOR', color: '#d866a3'})
        }

        if (localStorage.getItem('hairColor')) {
            let hairColor = JSON.parse(localStorage.getItem('hairColor'))
            if(hairColor.hairColor === "white") {
                r_action({type: 'SET_HAIR_COLOR', color: '#684737'})
            }
        } else {
        }
        r_action({type: 'SET_SVG_WHITE', allWhite: false})
    }, [])

    return (
        <GlobalLayout className="bg-pattern-2" loading={loading}>
            <div className="flex f-column f-center f-1">
                <div className="w-xl-content flex f-full f-column f-1">
                    <StepsParameters/>
                    <div className="relative flex f-center mx-1">
                        <Link to="/anatomie" className="block absolute text-white" style={{left: 0}}> <FiArrowLeft
                            size={30}/></Link>
                        <div className="f-1">
                            <h1 className="text-white text-center text-bolder text-30">{first.t('ecran1-vulve')}</h1>
                            {first.language !== second.language ?
                                <h2 className="text-white text-center text-24 px-2">{second.t('ecran1-vulve')}</h2> : ""}
                        </div>
                    </div>
                    <div className="f-1 flex f-full f-column">
                        <div className="r-tl r-tr bg-blured-back px-1 pb-2 mt-2 f-1 relative overflow-h">
                            <p className="text-black text-18 text-center mt-04">{first.t('ecran1-vulve-intro')}</p>
                            <p className="text-black text-14 text-center mt-03">
                                {first.language !== second.language ? second.t('ecran1-vulve-intro') : ""}</p>
                            <BlockIllu/>

                            <div className="flex f-full f-wrap mt-1 pb-3">
                                {listParameters.map((parameter, i) => {
                                    return (
                                        <div className="w-1/2 my-05" key={i}>
                                            <ModalParameters
                                                isOpen={isModalOpen}
                                                content={parameter.content}
                                                disabled={!displayNavigation}
                                                trigger={
                                                    <div className={`h-full bg-white r-little ml-05 flex`}>
                                                        {/* <div className="bg-neutral px-07 r-tl-small r-bl-small">img</div> */}
                                                        <div className="f-1 my-05 mx-07 flex f-column f-center">
                                                            <div
                                                                className="text-bold text-center">{first.t(parameter.title)}</div>
                                                            <div className="text-center">
                                                                {first.language !== second.language ? second.t(parameter.title) : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                {displayNavigation &&
                <Link to="/fgm-type" className="block fixed bg-secondary py-1 w-full flex f-center f-column"
                      style={{bottom: 0}}>
                    <div className="text-uppercase text-bolder text-white mt-05">{first.t('ecran1-choix-fgm-btn')}</div>
                    <div
                        className="text-uppercase text-white mb-05 mt-03">{first.language !== second.language ? second.t("ecran1-choix-fgm-btn") : ""}</div>
                </Link>}
            </div>


        </GlobalLayout>
    )
}
