import React from 'react'
import { useSelector } from 'react-redux'

export const Fond = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <path id="Fond" className="st7" d="M184.9,104c-2.2-5.2-16.7-28.3-17.6-26.8c-1.2,1.7-15.2,23.9-17.4,27.9
								c-1.8,3.2,6.2,19.4,11.8,31.7c2.8,6.1,2.4,12.5,5.7,12.5s3.7-6.9,6.5-12.9C179.5,124.1,186.4,107.4,184.9,104z"/>
    )
}