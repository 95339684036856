import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresFermeLeftLittleRigthBig = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="littleLeft_x5F_bigRight" >
							<path fill={r_labia_color} className="st--5" d="M185.1,109.5c-0.9,7.9-16.9,31.1-17.9,31.1s-7.9-23.2-8.8-31.1c-0.4-2.9-0.4-5.9,0-8.9
								c0.5-3.4,0.2-11.2,1.2-13.4c1.6-3.8,4.8-17.7,7.4-17.7s8,12.9,9.6,16.7c0.7,1.6,7.5,9.6,8,11.9
								C185.4,101.9,185.6,105.8,185.1,109.5z"/>
							<path class="st2" d="M185.4,109.5c-0.2,1.7-0.8,3.2-1.4,4.7c-0.6,1.5-1.3,3-2.1,4.4c-1.5,2.9-3.1,5.7-4.8,8.5
								c-1.7,2.8-3.5,5.5-5.3,8.2c-0.9,1.3-1.9,2.7-2.9,4c-0.3,0.3-0.5,0.6-0.8,1c-0.1,0.2-0.3,0.3-0.5,0.5c0,0-0.1,0.1-0.2,0.1
								c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.1-0.1c-0.2-0.2-0.2-0.3-0.3-0.4c-0.4-0.8-0.7-1.5-1-2.3
								c-2.1-6.2-3.8-12.5-5.4-18.8c-0.4-1.6-0.7-3.2-1.1-4.8c-0.3-1.6-0.7-3.2-0.9-4.8c-0.5-3.2-0.5-6.5-0.1-9.8
								c0.3-3.2,0.2-6.5,0.4-9.7c0.1-0.8,0.1-1.6,0.3-2.5c0.2-0.9,0.6-1.6,0.8-2.3c0.5-1.5,1-3.1,1.4-4.6c1-3.1,1.9-6.2,3.3-9.2
								c0.4-0.7,0.8-1.5,1.4-2.2c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.7-0.1,0.9,0c0.5,0.2,0.8,0.5,1.2,0.8c1.2,1.3,2,2.7,2.8,4.1
								c1.6,2.9,2.9,5.8,4.2,8.8l0.9,2.3c0.2,0.4,0.3,0.8,0.5,1.1c0.2,0.3,0.4,0.7,0.6,1c0.9,1.3,1.9,2.6,2.9,3.9
								c1,1.3,1.9,2.6,2.8,4c0.4,0.7,0.9,1.4,1.2,2.2c0.2,0.4,0.3,0.9,0.4,1.2c0.1,0.4,0.1,0.8,0.2,1.2
								C185.7,103,185.8,106.3,185.4,109.5z M184.9,109.5c0.4-3.2,0.3-6.5-0.2-9.6c-0.1-0.4-0.1-0.8-0.2-1.2
								c-0.1-0.4-0.2-0.7-0.3-1.1c-0.3-0.7-0.8-1.4-1.2-2c-0.9-1.3-1.9-2.6-2.9-3.9c-1-1.3-2-2.6-3-3.9c-0.2-0.3-0.5-0.7-0.7-1
								c-0.2-0.4-0.3-0.8-0.5-1.2l-1-2.2c-1.3-3-2.6-5.9-4.2-8.7c-0.8-1.4-1.6-2.8-2.6-3.9c-0.3-0.3-0.6-0.5-0.8-0.6
								c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.2c-0.5,0.5-0.9,1.2-1.2,1.9c-1.4,2.9-2.3,6-3.3,9.1c-0.5,1.6-0.9,3.1-1.5,4.7
								c-0.2,0.8-0.6,1.6-0.8,2.3c-0.2,0.7-0.3,1.5-0.4,2.3c-0.3,3.2-0.3,6.5-0.7,9.7c-0.5,3.2-0.6,6.4-0.1,9.7
								c0.4,3.2,1.2,6.4,2,9.5c0.8,3.1,1.7,6.3,2.7,9.4c0.9,3.1,1.9,6.2,3.1,9.2c0.3,0.7,0.6,1.5,1,2.2c0,0.1,0.1,0.2,0.1,0.1
								c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.4-0.4c0.3-0.3,0.5-0.6,0.8-0.9
								c1-1.3,1.9-2.6,2.9-3.9c1.9-2.7,3.6-5.4,5.4-8.1c1.7-2.8,3.4-5.5,4.9-8.4c0.7-1.4,1.5-2.9,2.1-4.4
								C184.1,112.6,184.6,111,184.9,109.5z"/>
						</g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}