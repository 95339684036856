import React from 'react'
import { useSelector } from 'react-redux'

export const PetiteLevresFerme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevresFerme" className="transition" style={{opacity: props.show ? 1 : props.hide ? 0 : 1}}>
                        <path fill={r_labia_color} className="st--5" d="M176,109.5c-0.9,7.9-7.8,31.1-8.8,31.1s-7.9-23.2-8.8-31.1c-0.4-2.9-0.4-5.9,0-8.9
						c0.5-3.4,0.2-11.2,1.2-13.4c1.6-3.8,4.8-17.7,7.4-17.7s5.1,12.9,6.7,16.7c0.7,1.6,1.3,9.6,1.8,11.9
						C176.3,101.9,176.5,105.8,176,109.5z"/>
                        <path className="st2" d="M176.4,109.6c-0.5,3.1-1.2,6.1-2,9.1c-0.8,3-1.6,6-2.4,9s-1.8,5.9-2.8,8.9c-0.2,0.7-0.5,1.5-0.8,2.2
						c-0.1,0.4-0.3,0.7-0.4,1.1c-0.1,0.2-0.2,0.4-0.3,0.5c0,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0
						c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.4-0.3-0.7-0.5-1.1c-0.3-0.7-0.5-1.5-0.8-2.2
						c-2-5.9-3.7-11.8-5.3-17.8c-0.8-3-1.4-6.1-1.9-9.1c-0.4-3.1-0.4-6.3,0-9.4c0.3-3,0.2-6.1,0.5-9.3c0.1-0.8,0.1-1.6,0.3-2.4
						c0.1-0.4,0.2-0.8,0.3-1.2l0.4-1.1c0.5-1.4,1-2.9,1.4-4.4c0.9-3,1.8-5.9,3-8.8c0.3-0.7,0.7-1.4,1.1-2.1c0.2-0.4,0.5-0.7,0.7-1
						c0.3-0.4,0.8-0.7,1.3-0.7c0.5,0.1,1,0.3,1.3,0.7c0.3,0.3,0.5,0.6,0.7,1c0.4,0.7,0.7,1.4,1,2.2c1.1,2.9,1.9,5.9,2.8,8.9
						c0.4,1.5,0.9,3,1.4,4.4c0.4,1.5,0.7,3.1,0.9,4.7l0.5,4.6c0.1,0.8,0.2,1.5,0.3,2.3s0.3,1.5,0.4,2.3
						C176.7,103.4,176.8,106.5,176.4,109.6z M175.7,109.5c0.4-3,0.3-6.1-0.1-9.2c-0.1-0.8-0.2-1.5-0.4-2.3s-0.2-1.5-0.3-2.3
						l-0.6-4.6c-0.2-1.5-0.4-3-0.8-4.5c-0.6-1.4-1-3-1.5-4.4c-0.9-3-1.7-6-2.8-8.8c-0.3-0.7-0.6-1.4-1-2c-0.2-0.3-0.4-0.6-0.6-0.9
						c-0.1-0.2-0.3-0.3-0.6-0.4c-0.2,0-0.4,0.2-0.6,0.4c-0.2,0.3-0.4,0.6-0.6,0.9c-0.4,0.6-0.7,1.3-1,2c-1.2,2.8-2,5.8-2.9,8.8
						c-0.5,1.5-0.9,3-1.4,4.5c-0.1,0.4-0.3,0.8-0.4,1.1c-0.1,0.3-0.2,0.7-0.2,1c-0.1,0.7-0.2,1.5-0.2,2.3c-0.2,3.1-0.2,6.1-0.5,9.3
						c-0.4,3-0.5,6.1-0.2,9.2c0.4,3,1,6.1,1.8,9.1c1.4,6,3.1,12,5,17.9c0.2,0.7,0.5,1.5,0.8,2.2c0.1,0.4,0.3,0.7,0.4,1.1
						c0.1,0.3,0.3,0.6,0.5,0.9c0,0-0.1,0-0.1,0l0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0.2-0.3,0.3-0.7,0.4-1.1
						c0.3-0.7,0.5-1.5,0.8-2.2c1-2.9,1.9-5.9,2.7-8.9C173.4,121.6,174.7,115.6,175.7,109.5z"/>
                        <path className="st2" d="M167.1,84.4c-1.3,3.7-2.1,7.5-2.5,11.4c-0.4,3.9-0.6,7.8-0.4,11.7c-0.3-3.9-0.3-7.9,0-11.8
						c0.3-3.9,1.1-7.8,2.2-11.6c0.1-0.2,0.2-0.3,0.4-0.2C167,84.1,167.1,84.2,167.1,84.4L167.1,84.4z"/>
                        <path className="st2" d="M167.2,84.7c0.8,1.4,1.4,2.9,1.9,4.5c0.5,1.5,1,3,1.5,4.6c0.4,1.6,0.8,3.1,1,4.7c0.3,1.6,0.3,3.2,0,4.8
						c0.2-1.6,0.1-3.2-0.2-4.8c-0.3-1.6-0.7-3.1-1.1-4.7c-0.5-1.5-1-3-1.6-4.5c-0.6-1.5-1.2-2.9-2-4.3l0,0c-0.1-0.1,0-0.3,0.1-0.4
						C166.9,84.5,167.1,84.6,167.2,84.7C167.2,84.7,167.2,84.7,167.2,84.7L167.2,84.7z"/>
                        <path className="st2" d="M172.7,107.4c0.1,1.7,0.3,3.5,0.3,5.2c0.1,1.7,0,3.5-0.2,5.2c-0.3,1.7-0.7,3.4-1.2,5.1
						c-0.6,1.6-1,3.3-1.4,5c0.2-1.7,0.6-3.4,1.1-5.1c0.5-1.7,0.9-3.3,1.2-5c0.2-1.7,0.3-3.4,0.3-5.2L172.7,107.4z"/>
                        <path className="st2" d="M161.9,98.2c-0.3,0.9-0.5,1.8-0.6,2.7c-0.2,0.9-0.2,1.8-0.3,2.7c-0.1,1.8,0,3.7,0.3,5.5
						c-0.6-1.7-0.9-3.6-0.8-5.5c0.1-0.9,0.2-1.9,0.4-2.8C161.1,99.9,161.4,99,161.9,98.2z"/>
                    </g>
    )
}