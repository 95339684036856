import React, { useState } from 'react'
import { first, second } from '../../services/translation/i18n'
import { useDispatch } from 'react-redux'

export const LanguageSelector = (props) => {

    const r_action = useDispatch()

    const [nbLanguage, setNbLanguage] = useState('first')
    const [selectedLanguages, setSelectedLanguages] = useState(['en', 'en'])

    const languagesList = props.languagesList

    const setLanguage = (language) => {
        let tmpSelectedLanguages = [...selectedLanguages]
        if (nbLanguage === 'first') {
            first.changeLanguage(language.code)
            tmpSelectedLanguages[0] = language.code
            setNbLanguage('second')
        } else {
            second.changeLanguage(language.code)
            tmpSelectedLanguages[1] = language.code
            setNbLanguage('first')
        }

        r_action({ type: 'SET_SWITCH_LANGUAGE', switchTranslate: false });

        setSelectedLanguages(tmpSelectedLanguages)
        props.setSelectedLanguages(tmpSelectedLanguages)
    }





    return (
        <div className="f-1 flex f-full f-column px-1">
            <div className="r bg-blured-back px-1 pt-2 pb-5 mt-2 f-1">
                <h2 className="text-black text-center text-24 text-bolder">{first.t('choose-your-language')}</h2>
                {first.language !== second.language ? <h2 className="text-black text-center text-16 mb-2 mt-03">{second.t('choose-your-language')}</h2> : ""}
                {languagesList.map((language, i) => {
                    return (
                        <div
                            key={i}
                            className="flex r bg-white my-1"
                            onClick={() => setLanguage(language)}
                        >
                            <div className="f-1 px-2 py-1"><span className="text-bold">{language.name}</span> / {language.translation}</div>
                            <div className="flex f-center">
                                <div className="mr-1 text-uppercase text-12 text-secondary text-bold text-right">
                                    {language.code === first.language ? first.t('primary-language') : language.code === second.language ? second.t('secondary-language') : ''}
                                </div>

                                <div className={`mr-2 r-full b-2-white transition ${language.code === first.language || language.code === second.language ? 'shadow-small bg-tertiary p-03' : 'bg-white shadow-none p-0'}`}>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
