import React, {useState} from 'react'
import {first, second} from '../services/translation/i18n'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {StepsParameters} from '../components/stepsParameters/stepsParameters'
import {FiArrowLeft} from 'react-icons/fi'
import {Link, useHistory} from 'react-router-dom'
import {GlobalLayout} from '../components/globallayout/globalLayout'
import ReactSlider from 'react-slider'
import {BlockIllu} from '../components/svg/blockIllu'


export default function SubTypeFGM(props) {
    const r_action = useDispatch()
    const r_type = useSelector(state => state.parameters.fgm_type)
    const r_subType = useSelector(state => state.parameters.fgm_subType)
    const r_fgmAll = useSelector(state => state.parameters.fgmAll)
    const r_animationStep = useSelector(state => state.parameters.animationStep)

    const [totalItem, setTotalItem] = useState('initial');
    const loading = props.loading

    const history = useHistory()

    useEffect(() => {
        r_action({type: 'SET_STEP', step: 3})

        if (r_fgmAll[+r_type - 1] !== undefined) {
            setTotalItem(r_fgmAll[+r_type - 1].subTypes.length);
        }

        if (!r_fgmAll[+r_type - 1]) {
            history.push('/fgm-type')
        }

        if (localStorage.getItem('skinColor')) {
            let skinColor = JSON.parse(localStorage.getItem('skinColor'))
            r_action({type: 'SET_SKIN_COLOR', color: skinColor.skinColor})
        } else {
            r_action({type: 'SET_SKIN_COLOR', color: '#bf9676'})
        }
        if (localStorage.getItem('labiaColor')) {
            let labiaColor = JSON.parse(localStorage.getItem('labiaColor'))
            r_action({type: 'SET_LABIA_COLOR', color: labiaColor.labiaColor})
        } else {
            r_action({type: 'SET_LABIA_COLOR', color: '#d866a3'})
        }

        r_action({type: 'SET_SVG_WHITE', allWhite: false})
    }, [])

    return (
        <GlobalLayout className="bg-pattern-1 overflow-h" loading={loading}>
            <div className="flex f-column f-center f-1">
                <div className="w-xl-content flex f-full f-column f-1">
                    <StepsParameters/>
                    <div className="relative flex f-center mx-1">
                        <Link to="/fgm-type" className="block absolute text-white" style={{left: 0}}> <FiArrowLeft
                            size={30}/></Link>
                        <div className="f-1 px-2">

                            <h1 className="text-white text-center text-20 mt-03 text-bold">
                                {first.t(`ecran4-intro-sous-type-1`)}
                            </h1>
                            {first.language !== second.language ?
                                <h2 className="text-white text-center text-18 px-2">
                                    {second.t(`ecran4-intro-sous-type-1`)}
                                </h2>
                                : ""}
                        </div>
                    </div>

                    {/* <p className="text-center text-white mt-1 pt-05">{first.t(`content FGM/C type ${r_type}`)}</p> */}

                    <div className="f-1 flex f-column f-full relative mt-1 bg-blured-back r">
                        <BlockIllu className="mx-1 r-tl r-tr pb-1 pt-05" showPlay={true}/>

                        {r_fgmAll[+r_type - 1] && (r_fgmAll[+r_type - 1].subTypes[r_subType === 'a' ? 0 : r_subType === 'b' ? 1 : 2].animation !== undefined) && <div className="bg-white mx-1 p-1 mb-1 r">
                            <ReactSlider
                                className="horizontal-slider -mt-02"
                                thumbClassName="thumb"
                                trackClassName="track"
                                max={Object.keys(r_fgmAll[+r_type - 1].subTypes[r_subType === 'a' ? 0 : r_subType === 'b' ? 1 : 2].animation).length - 1}
                                onChange={(value) => r_action({type: 'SET_ANIMATION_STEP', animationStep: value})}
                                renderThumb={(props, state) => <div {...props}></div>}
                                value={r_animationStep}
                            />
                        </div>}

                        {/* {r_fgmAll[+r_type - 1] && <h2 className="mx-1">
                            <span className="text-18 text-bold">{first.t(`intro-sous-type`)}</span>
                            <br />
                            <span className="text-14 text-italic">{first.t(`intro-sous-type`)}</span></h2>} */}

                        <div className="mb-1">
                            <div className="relative overflow f-1 h-full">
                                <div className={`flex pb-4 ${totalItem === 1 ? "f-center-w" : ""}`}>
                                    {r_fgmAll[+r_type - 1] && r_fgmAll[+r_type - 1].subTypes.map((subType, i) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    r_action({type: 'SET_ANIMATION_STEP', animationStep: 0})
                                                    r_action({type: 'SET_FGM_SUB_TYPE', fgm_subType: subType.subType})

                                                }} key={i}
                                                className={`bloc-flex-200 mr-05 ${i === 0 ? 'ml-1' : 'ml-05'} bg-white r p-1 flex f-column`}
                                                style={{width: 200}}
                                            >
                                                <div className="flex">
                                                    <div className="flex f-center">
                                                        <div
                                                            className={`mr-2 r-full b-2-secondary shadow-small p-03 transition ${r_subType === subType.subType ? ' bg-secondary' : 'bg-white'}`}></div>
                                                    </div>
                                                    <div className="f-1">
                                                        <span
                                                            className="text-bold text-18">{first.t(`${subType.title}`)}</span>
                                                        {first.language !== second.language ? <div>
                                                            <span
                                                                className="text-italic text-14">{second.t(`${subType.title}`)}</span>
                                                        </div> : ""}
                                                    </div>
                                                </div>
                                                <p className="mt-1"
                                                   style={{direction: first.dir()}}>{first.t(`${subType.content}`)}</p>
                                                {first.language !== second.language ? <p className="mt-05 text-14"
                                                                                         style={{direction: second.dir()}}>{second.t(`${subType.content}`)}</p> : ""}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <Link to="/care" className="block fixed bg-secondary py-1 w-full flex f-center f-column"
                      style={{bottom: 0}}>
                    <div className="text-uppercase text-bolder text-white mt-05">{first.t('ecran4-priseencharge')}</div>
                    <div className="text-uppercase text-white mb-05 mt-03">
                        {first.language !== second.language ? second.t('ecran4-priseencharge') : ""}
                    </div>

                </Link>Í
            </div>
        </GlobalLayout>
    )
}
