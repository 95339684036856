import React from 'react'
import { useSelector } from 'react-redux'

export const Anestesie = (props) => {

	return (

		<g id="anestesie" className="transition" style={{ opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(0px, -15px)' }}>
			<g>
				<path class="st12" d="M270.7,58.8l-3.1,10.6l-0.1,0.4c-0.3,1-0.2,2.1,0.2,3.1l5.6,11.9l0.2,0.5c0.1,0.2,0.3,0.5,0.5,0.6
						l6.8,6.9c1.2,1.2,3.2,1.4,4.6,0.4c1.6-1.1,2-3.4,0.8-5l-5.1-7.1L277.7,71l3-8.9c0-0.1,0-0.2,0.1-0.2c0.8-2.8-0.8-5.7-3.6-6.5
						C274.5,54.4,271.5,56,270.7,58.8z"/>
				<g>
					<path class="st13" d="M266.6,59c-3.1-0.8-6.3,1-7.1,4.1L256,76.5l-0.1,0.5c-0.3,1.2-0.1,2.4,0.5,3.5c1.3,2.1,2.6,4.2,3.8,6.4
							c1.3,2.1,2.4,4.3,3.7,6.5l0.4,0.6c1.5,2,3.1,4.1,4.6,6.1s3,4.1,4.5,6.2c1.3,1.8,3.7,2.3,5.6,1.1c1.9-1.2,2.4-3.8,1.2-5.6
							c-1.4-2.1-2.7-4.3-4-6.5c-1.2-2-2.4-4-3.5-6c-0.8-2.3-1.7-4.6-2.5-6.9c-0.6-1.6-1.1-3.1-1.6-4.7l2.4-11.8l6.4-10.5"/>
					<path class="st2" d="M266.5,59.5c-1.6-0.4-3.3-0.1-4.6,0.9c-0.6,0.5-1.2,1.1-1.5,1.9c-0.3,0.7-0.5,1.6-0.8,2.5l-2.8,10.3
							l-0.3,1.3c-0.1,0.4-0.2,0.9-0.3,1.2c-0.1,0.8,0,1.6,0.4,2.3l2.7,4.6c0.9,1.5,1.8,3.1,2.7,4.6l2.6,4.7v-0.1
							c2.1,2.8,4.3,5.6,6.4,8.5l1.6,2.2l0.8,1.1l0.4,0.5c0.1,0.2,0.2,0.3,0.4,0.4c1.1,1.1,2.9,1.4,4.3,0.6c1.4-0.7,2.1-2.5,1.7-4
							c-0.1-0.4-0.2-0.7-0.5-1.1l-0.7-1.1l-1.4-2.3c-1.9-3-3.7-6.1-5.5-9.2l0,0l0,0l-2.1-5.8c-0.7-1.9-1.4-3.9-2-5.8l0,0v-0.1v-0.1
							l2.3-11.8l0,0v-0.1l0.1-0.1l6.9-10.2l-5.9,10.8l0.1-0.2l0,0L269,77.7v-0.3l0,0c0.7,1.9,1.3,3.9,2,5.8l2.1,5.8v-0.1
							c1.8,3.1,3.6,6.1,5.5,9.1l1.4,2.3l0.7,1.1c0.3,0.4,0.5,0.9,0.6,1.4c0.2,1,0.1,2-0.3,2.9s-1.1,1.7-2,2.2
							c-1.7,1-4.1,0.7-5.5-0.8c-0.2-0.2-0.3-0.4-0.5-0.6l-0.4-0.5l-0.8-1.1l-1.6-2.2c-2-2.9-4.2-5.7-6.4-8.5l0,0l0,0l-2.6-4.6
							c-0.9-1.6-1.8-3.1-2.7-4.6c-0.9-1.5-1.8-3-2.8-4.6c-0.4-0.9-0.6-1.9-0.5-2.9c0.1-0.5,0.2-0.9,0.3-1.4l0.3-1.3l2.8-10.3
							l0.3-1.3c0.1-0.4,0.3-0.9,0.5-1.3c0.4-0.9,1.1-1.6,1.8-2.2c1.5-1.2,3.6-1.6,5.5-1.1L266.5,59.5z"/>
				</g>
				<g>
					<path class="st13" d="M273.8,57.2c-3.6,1.4-9.7,6.4-10.5,8.1l-10,17.3l-2.3,15c-0.2,1.3-0.4,2.7-0.7,4l-0.8,4l-1.6,8
							c-0.4,2-2.3,3.3-4.3,2.9c-1.9-0.4-3.2-2.1-3-4l0.9-8c0.3-2.7,0.7-5.3,0.9-8l1.5-16.1c0-0.5,0.2-1,0.4-1.5l0.1-0.2l9.5-18.5
							l10.3-12.3l11.5-1.3"/>
					<path class="st2" d="M273.8,57.2c-1,0.5-1.9,1.1-2.8,1.8c-0.9,0.6-1.8,1.3-2.6,2c-0.8,0.7-1.7,1.4-2.5,2.2
							c-0.4,0.4-0.8,0.8-1.1,1.2c-0.3,0.4-0.7,0.8-0.9,1.2l0,0l0,0l-2.5,4.3c-0.8,1.4-1.6,2.9-2.5,4.3c-1.7,2.9-3.4,5.7-5.1,8.6l0,0
							l0.1-0.2c-1.3,8-2.3,16.1-4,24.1l-1.2,6c-0.1,0.5-0.2,1-0.4,1.6c-0.2,0.5-0.5,1.1-0.9,1.5c-0.8,0.9-1.9,1.4-3.1,1.3
							c-1.2,0-2.3-0.5-3.1-1.4s-1.2-2.1-1.1-3.2l0.7-6.1c0.4-4,0.9-8.1,1.3-12.1l1.1-12.2l0.1-1.5c0-0.5,0.1-1.1,0.4-1.6
							c0.5-1,0.9-1.8,1.4-2.8l2.8-5.4l5.6-10.8v-0.1l0,0c3.5-4.1,6.9-8.2,10.4-12.2l0.1-0.1h0.1c3.8-0.4,7.7-0.7,11.5-1
							c-3.8,0.6-7.6,1.1-11.5,1.6l0.2-0.1c-3.3,4.2-6.7,8.3-10.1,12.4l0.1-0.1l-5.6,10.9l-2.8,5.4c-0.5,0.9-1,1.8-1.4,2.7
							c-0.2,0.4-0.2,0.8-0.3,1.3l-0.1,1.5L243,94.4c-0.4,4.1-0.9,8.1-1.3,12.2l-0.7,6.1c-0.1,0.9,0.2,1.8,0.8,2.4
							c0.6,0.7,1.4,1,2.3,1.1c0.9,0,1.7-0.4,2.3-1c0.3-0.3,0.5-0.7,0.7-1.1c0.1-0.4,0.2-0.9,0.3-1.4l1.2-6c1.7-8,2.7-16,4-24.1v-0.1
							v-0.1l0,0c1.6-2.9,3.2-5.8,4.9-8.7c0.8-1.4,1.7-2.9,2.5-4.3l2.5-4.3v0.1c0.3-0.7,0.7-1,1.1-1.5c0.4-0.4,0.8-0.8,1.3-1.2
							c0.9-0.7,1.7-1.4,2.7-2.1c0.9-0.6,1.9-1.2,2.9-1.8C271.8,58,272.8,57.5,273.8,57.2z"/>
				</g>
				<g>
					<path class="st13" d="M291,68.8c-4.9-6.2-21.7-14.5-24.6-16c-2.9-1.4,12.2-11.9,12.2-11.9L298.8,64L291,68.8z" />
					<path class="st2" d="M291,68.8c-1.4-1.4-3-2.5-4.6-3.6s-3.3-2.2-4.9-3.2c-1.7-1-3.3-2.1-5-3.1c-1.6-1.1-3.3-2.1-5-3.2
							c-1.7-1.1-3.4-2-5.2-2.9c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.2,0.1-0.5,0.3-0.7c0.2-0.4,0.6-0.8,0.9-1.2c0.7-0.7,1.4-1.4,2.1-2.1
							c2.9-2.7,6-5.1,9.1-7.6l0,0l0.4-0.3l0.3,0.3l20.3,23.1l0,0l0.4,0.5l-0.5,0.3C296.3,65.9,293.5,67.4,291,68.8z M291,68.8
							c2.7-1.8,5.2-3.6,7.6-5.3l-0.1,0.8l0,0l-20.3-23.1l0.7,0.1l0,0c-3.3,2.1-6.6,4.3-9.6,6.9c-0.7,0.7-1.5,1.3-2.1,2.1
							c-0.3,0.4-0.6,0.8-0.9,1.2c-0.1,0.2-0.2,0.4-0.3,0.7c0,0.2,0,0.5,0.3,0.6c1.8,0.9,3.5,1.7,5.3,2.5s3.6,1.7,5.4,2.6
							c1.8,0.9,3.5,1.9,5.1,3c1.7,1.1,3.3,2.2,4.8,3.5C288.4,65.7,289.9,67.1,291,68.8z"/>
				</g>
				<g>
					<path class="st15" d="M202,102.6l25.7-1.4v0.4c-1.5,2-1.4,4,0.3,5.8v0.6l-25.7,1.4c-1.2,0.1-2.2-0.8-2.2-2l-0.1-2.6
							C199.9,103.6,200.8,102.6,202,102.6z"/>

					<radialGradient id="SVGID_6_" cx="235.7124" cy="6891.145" r="6.981" gradientTransform="matrix(1 0 0 -1 0 6995.2754)" gradientUnits="userSpaceOnUse">
						<stop offset="0" style={{"stopColor":"#FFFFFF"}} />
						<stop offset="0.2878" style={{"stopColor":"#FBFCFD"}} />
						<stop offset="0.5518" style={{"stopColor":"#F0F5F6"}} />
						<stop offset="0.8056" style={{"stopColor":"#DEE8EC"}} />
						<stop offset="1" style={{"stopColor":"#CADAE0"}} />
					</radialGradient>
					<path class="st16" d="M227.7,101.2l16.7-0.9l0.4,6.8l-16.7,0.9v-0.6c-1.8-1.8-1.9-3.8-0.3-5.8L227.7,101.2z" />

					<radialGradient id="SVGID_7_" cx="245.9072" cy="6891.6758" r="5.2042" gradientTransform="matrix(1 0 0 -1 0 6995.2754)" gradientUnits="userSpaceOnUse">
						<stop offset="0" style={{"stopColor":"#FFFFFF"}} />
						<stop offset="0.2878" style={{"stopColor":"#FBFCFD"}} />
						<stop offset="0.5518" style={{"stopColor":"#F0F5F6"}} />
						<stop offset="0.8056" style={{"stopColor":"#DEE8EC"}} />
						<stop offset="1" style={{"stopColor":"#CADAE0"}} />
					</radialGradient>
					<polygon class="st17" points="246.8,96.4 247.6,110.6 245,110.8 244.2,96.6 						" />

					<radialGradient id="SVGID_8_" cx="257.9" cy="6891.9756" r="19.7886" gradientTransform="matrix(1 0 0 -1 0 6995.2754)" gradientUnits="userSpaceOnUse">
						<stop offset="0" style={{"stopColor":"#FFFFFF"}} />
						<stop offset="0.2878" style={{"stopColor":"#FBFCFD"}} />
						<stop offset="0.5518" style={{"stopColor":"#F0F5F6"}} />
						<stop offset="0.8056" style={{"stopColor":"#DEE8EC"}} />
						<stop offset="1" style={{"stopColor":"#CADAE0"}} />
					</radialGradient>
					<polygon class="st18" points="285.5,100.2 285.6,102.8 274.5,104.6 230.4,106.9 230.2,102 274.1,99.7 						" />

					<radialGradient id="SVGID_9_" cx="9171.0449" cy="7625.6665" r="6.2518" gradientTransform="matrix(-3.323715e-02 -0.613 -0.9985 5.413709e-02 8095.873 5316.3906)" gradientUnits="userSpaceOnUse">
						<stop offset="0" style={{"stopColor":"#FFFFFF"}} />
						<stop offset="0.2878" style={{"stopColor":"#FBFCFD"}} />
						<stop offset="0.5518" style={{"stopColor":"#F0F5F6"}} />
						<stop offset="0.8056" style={{"stopColor":"#DEE8EC"}} />
						<stop offset="1" style={{"stopColor":"#CADAE0"}} />
					</radialGradient>
					<polygon class="st19" points="171,107.3 168,108.1 185.6,107.2 185.6,106.5 						" />

					<radialGradient id="SVGID_10_" cx="7168.9004" cy="7609.3989" r="5.5877" gradientTransform="matrix(-5.413709e-02 -0.9985 -0.9985 5.413709e-02 8179.1641 6852.6826)" gradientUnits="userSpaceOnUse">
						<stop offset="0" style={{"stopColor":"#FFFFFF"}} />
						<stop offset="0.2878" style={{"stopColor":"#FBFCFD"}} />
						<stop offset="0.5518" style={{"stopColor":"#F0F5F6"}} />
						<stop offset="0.8056" style={{"stopColor":"#DEE8EC"}} />
						<stop offset="1" style={{"stopColor":"#CADAE0"}} />
					</radialGradient>
					<path class="st20" d="M196.9,103.6v0.6l-6.7,0.4v0.7l-3.5,0.2c-0.7,0-1.2,0.6-1.2,1.3c0,0.7,0.6,1.2,1.3,1.2l3.5-0.2v0.7
							l6.7-0.4v0.6l1.5-0.1l-0.1-1.2l2-0.1l-0.2-2.9l-2,0.1l-0.1-1.2L196.9,103.6z"/>
					<path class="st2" d="M228.5,101.7l1-0.1V101h0.7l0.4,6.9h-0.7v-0.6l-1,0.1v0.6h-0.7v-0.7c-1.8-1.8-1.9-3.8-0.3-5.8v-0.4h0.7
							L228.5,101.7z"/>

					<radialGradient id="SVGID_11_" cx="230.3" cy="6891.1758" r="0" gradientTransform="matrix(1 0 0 -1 0 6995.2754)" gradientUnits="userSpaceOnUse">
						<stop offset="0" style={{"stopColor":"#FFFFFF"}} />
						<stop offset="0.2878" style={{"stopColor":"#FBFCFD"}} />
						<stop offset="0.5518" style={{"stopColor":"#F0F5F6"}} />
						<stop offset="0.8056" style={{"stopColor":"#DEE8EC"}} />
						<stop offset="1" style={{"stopColor":"#CADAE0"}} />
					</radialGradient>
					<path class="st21" d="M230.3,104.1" />
				</g>
				<g>
					<path class="st13" d="M285,55.9c3.1,2.5,2.8,4.4,3.7,7.7c2,7.2-1.4,14.9-2.7,21.1c-0.8,3.1-1.1,6.3-1.4,9.5s-0.5,6.4-0.4,9.7
							c0.1,2.5,1.6,4.9,4.1,5.8c3.3,1.3,7-0.4,8.3-3.7l0,0c1.1-3,2-6,2.8-9c0.4-1.5,0.8-3,1.1-4.5c0.3-1.5,0.8-3,1.1-4.6
							c1.5-6.1,4.6-12.4,6-18.5s6-9.3,10.7-13.6c5.1-4.7,6.6-11.7,6.8-18.5c0-1.2-8.7-13.8-12.4-16.3c-3.7-2.5-13.1-4.2-16.9-2.1
							l-37.3,20.8l-1.3,0.7c-0.9,0.5-1.7,1.2-2.3,2.2L238,68.3l-0.4,0.7c-0.4,0.7-0.7,1.4-0.7,2.3c-0.2,3-0.4,6-0.6,9l-0.7,9
							l-0.1,0.7l0.3,7.4c0.1,2.5,0.1,4.9,0.2,7.4c0,2.3,1.8,4.3,4.2,4.5c2.5,0.2,4.8-1.6,5-4.1c0.2-2.4,0.5-4.9,0.7-7.3l0.9-7.3
							l-0.1,0.7l2.3-8.8c0.6-2.2,1.2-4.4,1.8-6.7c0,0,9.7-19,14.7-20.8c7-2.5,10.1-3.4,10.1-3.4"/>
					<path class="st2" d="M285,55.9c1.2,0.8,2.3,2,2.8,3.4c0.6,1.4,0.8,2.9,1.2,4.3c0.9,2.9,0.8,6,0.4,8.9c-0.4,2.9-1.3,5.8-2,8.6
							c-0.8,2.8-1.5,5.7-1.8,8.5c-0.4,2.9-0.6,5.8-0.8,8.7c-0.1,1.5-0.1,2.9-0.1,4.4c0,0.7,0,1.5,0.1,2.1c0.1,0.7,0.4,1.3,0.7,1.9
							c0.7,1.2,1.7,2.1,3,2.6s2.7,0.5,3.9,0.1c1.3-0.4,2.4-1.3,3.1-2.4c0.4-0.6,0.6-1.2,0.8-1.9c0.2-0.7,0.5-1.4,0.7-2.1
							c1.8-5.6,2.8-11.3,4.4-17c1.7-5.7,4.2-11.1,5.5-16.7c0.7-3,2.4-5.7,4.4-8c2-2.2,4.3-4.1,6.4-6.1c2-1.9,3.5-4.4,4.5-7
							s1.5-5.5,1.7-8.3c0-0.7,0.1-1.4,0.1-2.2v-0.3v-0.1l0,0c0,0,0,0.1,0,0l-0.1-0.2c-0.1-0.3-0.3-0.6-0.5-0.9
							c-0.7-1.2-1.5-2.4-2.3-3.6c-1.6-2.4-3.4-4.7-5.2-7c-0.9-1.1-1.9-2.2-2.9-3.1c-0.9-0.9-2.2-1.5-3.5-2c-2.6-1-5.5-1.5-8.3-1.6
							c-1.4,0-2.8,0.1-4,0.4c-0.3,0.1-0.6,0.2-0.9,0.3l-1,0.5l-1.9,1.1l-7.7,4.3l-15.4,8.6l-7.7,4.3l-3.9,2.1l-1,0.5
							c-0.3,0.2-0.6,0.3-0.8,0.5c-0.5,0.4-1,0.9-1.3,1.4l-9.7,14.7L241,65l-2.4,3.7c-0.4,0.6-0.8,1.1-0.9,1.7
							c-0.1,0.3-0.1,0.6-0.1,0.9l-0.1,1.1c-0.4,5.9-0.8,11.7-1.3,17.6v-0.1c0.1,3.6,0.3,7.2,0.3,10.8v2.7v1.3c0,0.4,0.1,0.8,0.2,1.1
							c0.5,1.5,2,2.6,3.5,2.6c1.6,0.1,3.1-0.8,3.8-2.3c0.2-0.4,0.3-0.7,0.3-1.1c0.1-0.4,0.1-0.9,0.1-1.3l0.3-2.7
							c0.4-3.6,0.8-7.2,1.3-10.8v-0.1l1,0.1l-0.1,0.6v0.1l-1-0.2l2-7.7c0.7-2.6,1.4-5.1,2.1-7.7l0,0l0,0c1.4-2.7,2.9-5.4,4.4-8
							c1.6-2.6,3.2-5.2,5-7.6c0.9-1.2,1.9-2.4,3-3.5c0.6-0.5,1.2-1.1,1.9-1.5c0.4-0.2,0.8-0.4,1.1-0.5l1.1-0.4
							c2.9-0.9,5.9-1.7,8.8-2.4c-2.8,1.2-5.6,2.3-8.5,3.3c-0.7,0.2-1.5,0.4-2.1,0.7s-1.2,0.8-1.7,1.4c-1.1,1-2,2.2-2.9,3.4
							c-1.8,2.4-3.4,5-4.9,7.6s-3,5.3-4.4,7.9v-0.1c-0.7,2.6-1.4,5.1-2.1,7.7l-2,7.7l-1-0.2v-0.1l0.1-0.6l1,0.1v0.1
							c-0.4,3.6-0.8,7.2-1.2,10.8l-0.3,2.7c0,0.5-0.1,0.9-0.1,1.4c-0.1,0.5-0.2,1-0.4,1.5c-0.8,1.9-2.9,3.1-4.9,3
							c-2.1-0.1-4-1.5-4.7-3.4c-0.2-0.5-0.3-1-0.3-1.5v-1.4l-0.1-2.7c-0.1-3.6-0.3-7.2-0.4-10.8l0,0v-0.1L235,81l0.5-8.8l0.1-1.1
							c0-0.4,0.1-0.8,0.2-1.2c0.2-0.8,0.7-1.6,1.1-2.2l2.4-3.7l4.8-7.4l9.6-14.8c0.5-0.7,1-1.3,1.7-1.8c0.3-0.2,0.7-0.5,1-0.7l1-0.5
							l3.8-2.2l7.7-4.3l15.4-8.6l7.7-4.3l1.9-1.1l1-0.5c0.4-0.2,0.8-0.4,1.2-0.5c1.6-0.5,3.1-0.6,4.7-0.6c3.1,0.1,6,0.6,8.9,1.7
							c1.4,0.5,2.9,1.2,4.1,2.3c1.2,1,2.2,2.2,3.1,3.3c1.9,2.3,3.7,4.7,5.4,7.1c0.8,1.2,1.7,2.5,2.4,3.8c0.2,0.3,0.4,0.6,0.6,1.1
							l0.1,0.3c0,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3v0.1v0.3c0,0.7-0.1,1.5-0.1,2.2c-0.3,3-0.8,6-1.9,8.8s-2.8,5.5-5.1,7.6
							c-2.2,2-4.5,3.8-6.5,5.9c-1,1-1.9,2.1-2.6,3.3s-1.2,2.5-1.5,3.9c-1.4,5.8-3.9,11.2-5.6,16.7c-1.6,5.6-2.7,11.4-4.6,17
							c-0.2,0.7-0.5,1.4-0.7,2.1c-0.3,0.7-0.5,1.4-1,2.1c-0.9,1.4-2.2,2.4-3.7,2.9s-3.2,0.4-4.7-0.1c-1.5-0.6-2.8-1.7-3.5-3.1
							c-0.4-0.7-0.7-1.5-0.8-2.2c-0.1-0.8-0.1-1.6-0.1-2.3c0-1.5,0.1-3,0.2-4.4c0.2-2.9,0.5-5.9,0.9-8.8s1.1-5.8,1.9-8.6
							c0.8-2.8,1.6-5.6,2.1-8.5s0.5-5.8-0.2-8.6c-0.2-0.7-0.3-1.4-0.5-2.2c-0.1-0.7-0.3-1.4-0.5-2.1C287,58.1,286.1,56.9,285,55.9z"
					/>
				</g>
			</g>
		</g>
	)
}
