const initialState = {
    reload: true,
    codeUsedLanguages : ['en']
}

export default function (state = initialState, action) {

    switch (action.type) {
        case 'RELOAD':
            return { ...state, reload: !state.reload }
        case 'SET_USED_LANGUAGE':
            localStorage.setItem("languages", JSON.stringify({codesLanguages: action.codeUsedLanguages}))
            return {...state, codeUsedLanguages: action.codeUsedLanguages}

        default:
            return state

    }
}