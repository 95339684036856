import React from 'react'
import { useSelector } from 'react-redux'

export const GrandeLevreDFerme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="GrandeLevreDFerme" className="transition" style={{opacity: props.hide ? 0 : 1}}>
                    <path className="st2" d="M173.2,70c3.1,1.6,5.6,4,7.5,7c1.8,2.9,3.2,6.1,4.1,9.5c0.9,3.3,1.5,6.7,1.8,10.2c0.3,3.4,0.4,6.9,0.2,10.3
					c-0.3,6.9-1.5,13.7-3.5,20.3c-1,3.3-2.4,6.5-4,9.5c-1.7,3-3.8,5.9-6.8,7.6c1.3-1,2.5-2.3,3.5-3.7c0.5-0.7,0.9-1.4,1.4-2.1
					s0.8-1.5,1.2-2.2c1.4-3,2.6-6.2,3.5-9.5c1.8-6.5,2.8-13.2,3.2-20c0.4-6.7-0.1-13.5-1.5-20.1c-0.7-3.3-1.9-6.4-3.6-9.4
					C178.4,74.3,176.1,71.8,173.2,70z"/>
                </g>
    )
}