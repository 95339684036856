import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresLeftMediumRightLittle = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="mediumLabiaLeft_x5F_littleLabiaRight">
                <path fill={r_labia_color} className="st--5" d="M178.8,109.7c-1.2,7.2-10.4,31.3-11.7,31.3s-14.7-24.1-15.9-31.3c-0.5-2.7-0.5-5.4,0-8.1
									c0.6-3.1,4.1-12,5.1-14.2c1.7-3.8,7.9-17.7,10.7-17.7s5.5,12.9,7.2,16.7c0.7,1.6,3.3,10.8,3.9,12.9
									C179.2,102.7,179.4,106.2,178.8,109.7z"/>
                <path class="st2" d="M179.1,109.8c-0.6,3.2-1.6,6.3-2.6,9.4c-1,3.1-2.1,6.2-3.2,9.2c-1.1,3-2.3,6.1-3.7,9
									c-0.3,0.7-0.7,1.5-1.1,2.2c-0.2,0.4-0.4,0.7-0.6,1.1c-0.1,0.2-0.2,0.4-0.5,0.6c0,0-0.3,0.2-0.5,0.1c-0.1,0-0.2-0.1-0.2-0.1
									c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-1.1-1.3-1.9-2.7-2.7-4.1c-3.3-5.6-6.3-11.4-9-17.3c-0.7-1.5-1.3-3-1.9-4.5
									c-0.6-1.5-1.2-3-1.6-4.6c-0.7-3.2-0.8-6.5,0-9.8c0.8-3.2,2-6.2,3.1-9.3c1.1-3.1,2.4-6,3.8-9c1.4-2.9,2.8-5.9,4.5-8.7
									c0.9-1.4,1.7-2.8,3-4c0.3-0.3,0.7-0.6,1.4-0.6c0.6,0,1.1,0.4,1.4,0.7c0.6,0.7,1,1.4,1.4,2.2c1.4,3,2.3,6.1,3.2,9.2
									c0.5,1.5,0.9,3.1,1.5,4.6c0.6,1.5,1,3.1,1.5,4.7c0.9,3.1,1.8,6.2,2.7,9.4C179.6,103.1,179.7,106.5,179.1,109.8z
									 M178.5,109.6c0.5-3.2,0.4-6.4-0.4-9.5c-0.9-3.1-1.8-6.2-2.7-9.3c-0.5-1.5-0.9-3.1-1.5-4.6c-0.6-1.5-1.1-3.1-1.6-4.7
									c-1-3.1-1.8-6.2-3.2-9.1c-0.3-0.7-0.7-1.4-1.2-1.9c-0.2-0.3-0.5-0.4-0.7-0.4c-0.2,0-0.5,0.2-0.8,0.4c-1.1,1-2,2.4-2.9,3.7
									c-1.7,2.7-3.1,5.6-4.5,8.6c-1.4,2.9-2.8,5.9-3.9,8.9c-1.2,3-2.4,6-3.3,9.1c-0.8,3.1-0.8,6.4-0.1,9.5c0.8,3.1,2.1,6.1,3.5,9
									c2.8,5.8,5.9,11.6,9.2,17.1c0.9,1.4,1.7,2.8,2.7,3.9c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0
									c0,0,0.2-0.2,0.3-0.3c0.2-0.3,0.4-0.7,0.6-1c0.4-0.7,0.7-1.4,1-2.2c1.3-2.9,2.5-6,3.7-9c1.2-3,2.3-6.1,3.3-9.1
									C176.8,115.9,177.9,112.8,178.5,109.6z"/>
            </g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}