import React, { useState, useEffect } from 'react'
import { Clitoris1 } from './clitoris_1'
import { CuisseD1 } from './corps/cuisseD1'
import { CuisseG1 } from './corps/cuisseG1'
import { Ventre1 } from './corps/ventre1'
import { Fesses1 } from './corps/fesses1'
import { Anus } from './corps/anus'
import { Skin } from './corps/skin'
import { useDispatch, useSelector } from 'react-redux'

import { PetitesLevresFond } from './v2/petiteLevres/petiteLevreFond'
import { PetiteLevresFerme } from './v2/petiteLevres/petiteLevreFerme'
import { PetitesLevres1aFerme } from './v2/petiteLevres/1a/petitesLevres1aFerme'
import { PetitesLevres1a } from './v2/petiteLevres/1a/petitesLevres1a'
import { PetitesLevre2a } from './v2/petiteLevres/2a/petitesLevre2a'
import { PetitesLevres2aferme } from './v2/petiteLevres/2a/petitesLevres2aferme'
import { PetiteLevres3Final } from './v2/petiteLevres/3/petiteLevres3Final'
import { Glans } from './v2/glans/glans'
import { Glans1a } from './v2/glans/1a/glans1a'
import { GrandeLevreD } from './v2/grandeLevres/grandeLevreD'
import { GrandeLevreG } from './v2/grandeLevres/grandeLevreG'
import { GrandeLevreDFerme } from './v2/grandeLevres/grandeLevreDFerme'
import { GrandeLevreGFerme } from './v2/grandeLevres/grandeLevreGFerme'
import { GrandLevreG2cFerme } from './v2/grandeLevres/2c/grandLevreG2cFerme'
import { GrandLevreD2cFerme } from './v2/grandeLevres/2c/grandLevreD2cFerme'
import { GrandeLevreG2C } from './v2/grandeLevres/2c/grandeLevreG2C'
import { GrandeLevreD2C } from './v2/grandeLevres/2c/grandeLevreD2C'
import { Prepuce } from './v2/prepuce/prepuce'
import { Prepuce2a } from './v2/prepuce/2a/prepuce2a'
import { Prepuce2aFerme } from './v2/prepuce/2a/prepuce2aFerme'
import { PrepuceFerme } from './v2/prepuce/prepuceFerme'
import { Pont } from './v2/divers/pont'
import { Cicatrice3 } from './v2/divers/3/cicatrice3'
import { CicatriceExcision } from './v2/divers/cicatriceExcision'
import { Suture3a } from './v2/divers/3/suture3a'
import { Suture3b } from './v2/divers/3/suture3b'
import { ContourVestibuleFerme } from './v2/vestibule/contourVestibuleFerme'
import { ContourVestibule } from './v2/vestibule/contourVestibule'
import { ContourVestibuleType3final } from './v2/vestibule/3/contourVestibuleType3final'
import { ContourVestibuleType3intermediaire } from './v2/vestibule/3/contourVestibuleType3intermediaire'
import { Fond } from './v2/vestibule/fond'
import { Ridges } from './v2/vestibule/ridges'
import { HymenReste } from './v2/vestibule/hymen/HymenReste'
import { Hymen1v2 } from './v2/vestibule/hymen/hymen1'
import { Hymen2 } from './v2/vestibule/hymen/hymen2'
import { Hymen3 } from './v2/vestibule/hymen/hymen3'
import { Hymen4 } from './v2/vestibule/hymen/hymen4'
import { Hymen5 } from './v2/vestibule/hymen/hymen5'
import { FondVestibule } from './v2/vestibule/fondVestibule'
import { OrificeUrinaire } from './v2/vestibule/orificeUrinaire'
import { Clipping_Path_Ferme } from './v2/clipingPath/clippingPathFerme'
import { Clipping_Path_type_3_final } from './v2/clipingPath/clipping_Path_type_3_final'
import { Clipping_Path_type_3_intermediaire } from './v2/clipingPath/clipping_Path_type_3_intermediaire'
import { PetitesLevresBigBoth } from './v2/petiteLevres/petiteLevreBigBoth'
import { PetitesLevresMediumBoth } from './v2/petiteLevres/petiteLevreMediumBoth'
import { PetitesLevresLeftLittleRightMedium } from './v2/petiteLevres/petiteLevreLeftLittle_RightMedium'
import { PetitesLevresLeftMediumRightLittle } from './v2/petiteLevres/petiteLevreLeftMedium_RightLittle'
import { PetitesLevresLeftLittleRightBig } from './v2/petiteLevres/petiteLevreLeftLittle_RightBig'
import { PetitesLevresLeftBigRightLittle } from './v2/petiteLevres/petiteLevreLeftBig_RightLittle'
import { PetitesLevresLeftMediumRightBig } from './v2/petiteLevres/petiteLevreLeftMedium_RightBig'
import { PetitesLevresLeftBigRigthMedium } from './v2/petiteLevres/petiteLevreLeftBig_RightMedium'
import { PetitesLevresFermeBigBoth } from './v2/petiteLevres/petiteLevreFermeBigBoth'
import { PetitesLevresFermeMediumBoth } from './v2/petiteLevres/petiteLevreFermeMediumBoth'
import { PetitesLevresFermeLeftLittleRightMedium } from './v2/petiteLevres/petiteLevreFermeLeftLittle_RightMedium'
import { PetitesLevresFermeLeftMediumRightLittle } from './v2/petiteLevres/petiteLevreFermeLeftMedium_RightLittle'
import { PetitesLevresFermeLeftLittleRigthBig } from './v2/petiteLevres/petiteLevreFermeLeftLittle_RightBig'
import { PetitesLevresFermeLeftBigRightLittle } from './v2/petiteLevres/petiteLevreFermeLeftBig_RightLittle'
import { PetitesLevresFermeLeftMediumRightBig } from './v2/petiteLevres/petiteLevreFermeLeftMedium_RightBig'
import { PetitesLevresFermeLeftBigRightMedium } from './v2/petiteLevres/petiteLevreFermeLeftBig_RightMedium'
import { Poils } from './poils/poils'
import { useLocation } from 'react-router-dom'
import { Desinfection } from './surgerie/desinfection/desinfection'
import { Anestesie } from './surgerie/anesthesie'
import { Incision } from './surgerie/incision'
import { SutureLabia } from './surgerie/sutureLabia'
import { SoinLabia } from './surgerie/soinLabia'
import { SurgerieGlans } from './surgerie/surgerieGlans'
import { IncisionGlans } from './surgerie/incisionGlans'
import { Ligament } from './surgerie/ligament'
import { SutureGlans } from './surgerie/sutureGlans'
import { SuturePeau } from './surgerie/suturePeau'
import { SoinGlans } from './surgerie/soinGlans'

export const Illustration = (props) => {

    const r_action = useDispatch()
    const r_isClosed = useSelector(state => state.parameters.isClosed)
    const r_fgm_type = useSelector(state => state.parameters.fgm_type)
    const r_fgm_subType = useSelector(state => state.parameters.fgm_subType)
    const r_animation_opacity_fgm = useSelector(state => state.parameters.animationOpacityFGM)
    const r_animation_opacity_normal = useSelector(state => state.parameters.animationOpacityNormal)
    const r_fgmAll = useSelector(state => state.parameters.fgmAll)
    const r_animationStep = useSelector(state => state.parameters.animationStep)
    const r_showHymen = useSelector(state => state.parameters.showHymen)
    const r_typeHymen = useSelector(state => state.parameters.typeHymen)
    const r_labiaType = useSelector(state => state.parameters.labiaType)
    const r_skin_opacity = useSelector(state => state.parameters.skinOpacity)
    const r_surgerie = useSelector(state => state.parameters.surgerie)

    const location = useLocation()

    const [type, setType] = useState(r_fgm_type)
    const [count, setCount] = useState(0);
    const [timer, setTimer] = useState([]);
    const [subType, setSubType] = useState(r_fgm_subType)
    const [labiaMajora, setLabiaMajora] = useState(true)
    const [isClosed, setIsClosed] = useState(r_isClosed)
    const [showHymen, setShowHymen] = useState(r_showHymen)
    const [glans, setGlans] = useState(true)

    const [opacityFGM, setOpacityFGM] = useState(1)
    const [opacityNormal, setOpacityNormal] = useState(0)

    const [showOnThisStep, setShowOnThisStep] = useState([])
    const [hideOnThisStep, setHideOnThisStep] = useState([])

    const [animationStep, setAnimationStep] = useState(0)

    const [translateMask, setTranslateMask] = useState(false)


    const autoPlay = () => {

        if (r_fgmAll) {
            if (r_fgm_subType && r_fgm_type) {
                let objAnimation = r_fgmAll[+r_fgm_type - 1].subTypes[r_fgm_subType === 'a' ? 0 : r_fgm_subType === 'b' ? 1 : 2].animation
                if (location.pathname === '/surgerie') {
                    objAnimation = r_fgmAll[+r_fgm_type - 1].subTypes[r_fgm_subType === 'a' ? 0 : r_fgm_subType === 'b' ? 1 : 2][`care${r_surgerie}`]
                }
                if (objAnimation) {
                    let nbFrame = Object.keys(objAnimation).length
                    if(props.activateAutoPlay === "false") {
                        for (var i=0; i<timer.length; i++) {
                            clearTimeout(timer[i]);
                        }
                        r_action({ type: 'SET_ANIMATION_STEP', animationStep: count })
                    } else {

                        if(count !== 0 && count !== (nbFrame - 1)) {
                            setTimer([]);
                            let resetTime = 1;
                            for (let i = count; i < nbFrame; i++) {
                                let value = setTimeout(() => {
                                    r_action({ type: 'SET_ANIMATION_STEP', animationStep: i})
                                    setCount(i);
                                    if(i === (nbFrame - 1)) {
                                        setCount(0);
                                        props.onChange(false);
                                    }
                                }, resetTime === 1 ? '1000' : `${resetTime - 1}000`);
                                setTimer(prevArray => [...prevArray, value]);
                                resetTime++;
                            }

                        } else {
                            r_action({ type: 'SET_ANIMATION_STEP', animationStep: 0})
                            for (let i = 1; i < nbFrame; i++) {
                                let value = setTimeout(() => {
                                    if(props.activateAutoPlay !== "false") {
                                        r_action({ type: 'SET_ANIMATION_STEP', animationStep: i })
                                        setCount(i);
                                        if(i === (nbFrame - 1)) {
                                            setCount(0);
                                            props.onChange(false);
                                        }
                                    }
                                }, i === 1 ? '1000' : `${i}000`);

                                setTimer(prevArray => [...prevArray, value])
                            }
                        }
                    }

                }
            }
        }
    }

    const Timer = (callback, delay) => {
        var timerId, start, remaining = delay;
        this.pause = function() {
            window.clearTimeout(timerId);
            remaining -= new Date() - start;
        };

        this.resume = function() {
            start = new Date();
            window.clearTimeout(timerId);
            timerId = window.setTimeout(callback, remaining);
        };

        this.resume();
    }

    useEffect(() => {
        let tmpType = props.type || r_fgm_type
        let tmpSubType = props.subType || r_fgm_subType
        setType(tmpType)
        setSubType(tmpSubType)
    }, [r_fgm_subType, r_fgm_type, props.type, props.subType])

    useEffect(() => {
        let tmpOpacityFGM = typeof props.opacityFGM === 'number' ? props.opacityFGM : r_animation_opacity_fgm
        let tmpOpacityNormal = typeof props.opacityNormal === 'number' ? props.opacityNormal : r_animation_opacity_normal
        setOpacityFGM(tmpOpacityFGM)
        setOpacityNormal(tmpOpacityNormal)
    }, [r_animation_opacity_fgm, r_animation_opacity_normal, props.opacityFGM, props.opacityNormal])

    useEffect(() => {
        if ((type === 2 && subType === 'c') || (type === 3 && subType === 'b') || (type === 3 && subType === 'd') ) {
            setLabiaMajora(false)
        }
        else { setLabiaMajora(true) }

        if ((type === 2 && subType === 'a') || type === 3 || type === 4 || type === 0) { setGlans(true) }
        else { setGlans(false) }

    }, [type, subType])

    useEffect(() => {
        let currentAnimation = { show: ['normal'], hide: ['fgm'] }

        if (r_fgmAll) {
            if (r_fgm_subType && r_fgm_type) {
                if (location.pathname === '/surgerie') {
                    if (r_fgmAll[+r_fgm_type - 1].subTypes[r_fgm_subType === 'a' ? 0 : r_fgm_subType === 'b' ? 1 : r_fgm_subType === 'c' ? 2 : 3][`care${r_surgerie}`][animationStep]) {
                        currentAnimation = r_fgmAll[+r_fgm_type - 1].subTypes[r_fgm_subType === 'a' ? 0 : r_fgm_subType === 'b' ? 1 : r_fgm_subType === 'c' ? 2 : 3][`care${r_surgerie}`][animationStep]
                    }
                } else {
                    if (r_fgmAll[+r_fgm_type - 1].subTypes[r_fgm_subType === 'a' ? 0 : r_fgm_subType === 'b' ? 1 : r_fgm_subType === 'c' ? 2 : 3].animation[animationStep]) {
                        currentAnimation = r_fgmAll[+r_fgm_type - 1].subTypes[r_fgm_subType === 'a' ? 0 : r_fgm_subType === 'b' ? 1 : r_fgm_subType === 'c' ? 2 : 3].animation[animationStep]
                    }
                }

            }
            else {
                if (props.type) {
                    if (r_fgmAll[+props.type - 1].subTypes[0]) {
                        currentAnimation = r_fgmAll[+props.type - 1].subTypes[0].animation[animationStep]
                    }
                }
            }

            currentAnimation.close && setIsClosed(currentAnimation.close)
            currentAnimation.open && setIsClosed(!currentAnimation.open)
            setTranslateMask(currentAnimation.translateMask)
            setShowOnThisStep(currentAnimation.show)
            setHideOnThisStep(currentAnimation.hide)
        }
    }, [animationStep])

    useEffect(() => {
        let tmpAnimationStep = props.stepAnimation || r_animationStep
        if (tmpAnimationStep !== undefined) { setAnimationStep(tmpAnimationStep) }
    }, [props.stepAnimation, r_animationStep])

    useEffect(() => {setIsClosed(r_isClosed)}, [r_isClosed])

    useEffect(() => { setShowHymen(r_showHymen) }, [r_showHymen])

    useEffect(() => {props.activateAutoPlay && autoPlay()}, [props.activateAutoPlay])


    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 333.48 215.25" height="inherit"
            style={{transform: 'scale(1.3, 1.3)'}}
            space="preserve">

            <Clitoris1 />
            <Skin />
            <g id="Contour">
                <Fesses1 />
                <Ventre1 />
                <CuisseG1 />
                <CuisseD1 />
            </g>
            <Anus />
            <g>
                <g>
                    <defs>
                        <ellipse id="SVGID_1_" cx="168.5" cy="106" rx="23.7" ry="39.9" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use href="#SVGID_1_" style={{ transform: `${translateMask ? 'translate(0px, 46px)' : 'translate(0px, 0px)'}` }} />
                    </clipPath>
                    <g style={{ transform: 'translate(125px, 41px)' }}><Poils /></g>
                    <g id="GrandGroupe" className="st4">
                        <g id="PetitesLevresGroupe" className="transition clitoralhood-target" style={{ opacity: r_skin_opacity }}>

                            {!isClosed && r_labiaType === 'littleBoth' && <PetitesLevresFond show={showOnThisStep.find(component => component === 'PetitesLevresFond' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetitesLevresFond')} />}
                            {!isClosed && r_labiaType === 'bigBoth' && <PetitesLevresBigBoth show={showOnThisStep.find(component => component === 'PetitesLevresFond' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetitesLevresFond')} />}
                            {!isClosed && r_labiaType === 'mediumBoth' && <PetitesLevresMediumBoth show={showOnThisStep.find(component => component === 'PetitesLevresFond' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetitesLevresFond')} />}
                            {!isClosed && r_labiaType === 'leftLittle_rightMedium' && <PetitesLevresLeftLittleRightMedium show={showOnThisStep.find(component => component === 'PetitesLevresFond' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetitesLevresFond')} />}
                            {!isClosed && r_labiaType === 'leftMedium_rightLittle' && <PetitesLevresLeftMediumRightLittle show={showOnThisStep.find(component => component === 'PetitesLevresFond' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetitesLevresFond')} />}
                            {!isClosed && r_labiaType === 'leftLittle_rightBig' && <PetitesLevresLeftLittleRightBig show={showOnThisStep.find(component => component === 'PetitesLevresFond' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetitesLevresFond')} />}
                            {!isClosed && r_labiaType === 'leftBig_rightLittle' && <PetitesLevresLeftBigRightLittle show={showOnThisStep.find(component => component === 'PetitesLevresFond' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetitesLevresFond')} />}
                            {!isClosed && r_labiaType === 'leftMedium_rightBig' && <PetitesLevresLeftMediumRightBig show={showOnThisStep.find(component => component === 'PetitesLevresFond' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetitesLevresFond')} />}
                            {!isClosed && r_labiaType === 'leftBig_rightMedium' && <PetitesLevresLeftBigRigthMedium show={showOnThisStep.find(component => component === 'PetitesLevresFond' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetitesLevresFond')} />}


                            {isClosed && r_labiaType === 'littleBoth' && <PetiteLevresFerme show={showOnThisStep.find(component => component === 'PetiteLevresFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetiteLevresFerme')} />}
                            {isClosed && r_labiaType === 'bigBoth' && <PetitesLevresFermeBigBoth show={showOnThisStep.find(component => component === 'PetiteLevresFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetiteLevresFerme')} />}
                            {isClosed && r_labiaType === 'mediumBoth' && <PetitesLevresFermeMediumBoth show={showOnThisStep.find(component => component === 'PetiteLevresFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetiteLevresFerme')} />}
                            {isClosed && r_labiaType === 'leftLittle_rightMedium' && <PetitesLevresFermeLeftLittleRightMedium show={showOnThisStep.find(component => component === 'PetiteLevresFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetiteLevresFerme')} />}
                            {isClosed && r_labiaType === 'leftMedium_rightLittle' && <PetitesLevresFermeLeftMediumRightLittle show={showOnThisStep.find(component => component === 'PetiteLevresFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetiteLevresFerme')} />}
                            {isClosed && r_labiaType === 'leftLittle_rightBig' && <PetitesLevresFermeLeftLittleRigthBig show={showOnThisStep.find(component => component === 'PetiteLevresFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetiteLevresFerme')} />}
                            {isClosed && r_labiaType === 'leftBig_rightLittle' && <PetitesLevresFermeLeftBigRightLittle show={showOnThisStep.find(component => component === 'PetiteLevresFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetiteLevresFerme')} />}
                            {isClosed && r_labiaType === 'leftMedium_rightBig' && <PetitesLevresFermeLeftMediumRightBig show={showOnThisStep.find(component => component === 'PetiteLevresFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetiteLevresFerme')} />}
                            {isClosed && r_labiaType === 'leftBig_rightMedium' && <PetitesLevresFermeLeftBigRightMedium show={showOnThisStep.find(component => component === 'PetiteLevresFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PetiteLevresFerme')} />}


                            {isClosed && type === 1 && <PetitesLevres1aFerme show={showOnThisStep.find(component => component === 'PetitesLevres1aFerme')} hide={hideOnThisStep.find(component => component === 'PetitesLevres1aFerme' || component === 'fgm')} />}
                            {!isClosed && type === 1 && <PetitesLevres1a show={showOnThisStep.find(component => component === 'PetitesLevres1a')} hide={hideOnThisStep.find(component => component === 'PetitesLevres1a' || component === 'fgm')} />}

                            {!isClosed && type !== 1 && <PetitesLevre2a show={showOnThisStep.find(component => component === 'PetitesLevre2a')} hide={hideOnThisStep.find(component => component === 'PetitesLevre2a' || component === 'fgm')} />}
                            {isClosed && type !== 1 && <PetitesLevres2aferme show={showOnThisStep.find(component => component === 'PetitesLevres2aferme')} hide={hideOnThisStep.find(component => component === 'PetitesLevres2aferme' || component === 'fgm')} />}

                            {true && <PetiteLevres3Final show={showOnThisStep.find(component => component === 'PetiteLevres3Final')} hide={hideOnThisStep.find(component => component === 'PetiteLevres3Final' || component === 'fgm')} />}
                        </g>
                        <g>
                            <g id="Vestibule">
                                <defs>
                                    <path id="maskTest" d="M166.8,132c-0.4,0-6.5-30.9-3.5-34.6c1.8-2.2,3.1-4.8,3.9-7.5c0.9,2.8,2.4,5.4,4.5,7.6
									C175.6,101.8,167.1,132,166.8,132z"/>
                                </defs>
                                <clipPath id="maskTest_1_">
                                    <use xlinkHref="#maskTest" style={{ overflow: 'visible', transform: `${translateMask ? 'translate(0px, 15px)' : 'translate(0px, 0px)'}` }} />
                                </clipPath>


                                {true && <g className="st6">
                                    {!isClosed && <g id="Vestibule-2">

                                        <radialGradient id="Fond_1_" cx="514.9243" cy="-143.3144" r="17.1805" gradientTransform="matrix(1.99 0 0 -1.6 -857.36 -123.949)" gradientUnits="userSpaceOnUse">
                                            <stop offset="0" style={{ "stopColor": "#A10D3C" }} />
                                            <stop offset="0.13" style={{ "stopColor": "#A41242" }} />
                                            <stop offset="0.31" style={{ "stopColor": "#AD2052" }} />
                                            <stop offset="0.51" style={{ "stopColor": "#BC386E" }} />
                                            <stop offset="0.72" style={{ "stopColor": "#D05994" }} />
                                            <stop offset="0.8" style={{ "stopColor": "#D866A3" }} />
                                        </radialGradient>




                                        <Fond />
                                        <Ridges />

                                        {false && <HymenReste />}

                                        {showHymen && r_typeHymen === 1 && <Hymen1v2 />}
                                        {showHymen && r_typeHymen === 2 && <Hymen2 />}
                                        {showHymen && r_typeHymen === 3 && <Hymen3 />}
                                        {showHymen && r_typeHymen === 4 && <Hymen4 />}
                                        {showHymen && r_typeHymen === 5 && <Hymen5 />}

                                        <FondVestibule />

                                        <OrificeUrinaire />

                                    </g>}


                                    {isClosed && <Clipping_Path_Ferme show={showOnThisStep.find(component => component === 'Clipping_Path_Ferme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'Clipping_Path_Ferme')} />}

                                    {true && <Clipping_Path_type_3_intermediaire show={showOnThisStep.find(component => component === 'Clipping_Path_type_3_intermediaire')} hide={hideOnThisStep.find(component => component === 'Clipping_Path_type_3_intermediaire' || component === 'fgm')} />}

                                    {true && <Clipping_Path_type_3_final show={showOnThisStep.find(component => component === 'Clipping_Path_type_3_final')} hide={hideOnThisStep.find(component => component === 'Clipping_Path_type_3_final' || component === 'fgm')} />}

                                </g>}
                            </g>
                            <g id="ContoursVestibulesGroupe">
                                {isClosed && <ContourVestibuleFerme show={showOnThisStep.find(component => component === 'ContourVestibuleFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'ContourVestibuleFerme')} />}
                                {!isClosed && <ContourVestibule show={showOnThisStep.find(component => component === 'ContourVestibule' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'ContourVestibule')} />}

                                {true && <ContourVestibuleType3final show={showOnThisStep.find(component => component === 'ContourVestibuleType3final')} hide={hideOnThisStep.find(component => component === 'ContourVestibuleType3final' || component === 'fgm')} />}
                                {true && <ContourVestibuleType3intermediaire show={showOnThisStep.find(component => component === 'ContourVestibuleType3intermediaire')} hide={hideOnThisStep.find(component => component === 'ContourVestibuleType3intermediaire' || component === 'fgm')} />}
                            </g>
                        </g>
                    </g>

                    <g id="GlansGroupe" className="glansclitoris-target">
                        <Glans show={showOnThisStep.find(component => component === 'Glans' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'Glans')} />

                        {<Glans1a show={showOnThisStep.find(component => component === 'Glans1a')} />}
                    </g>

                    <g id="GrandesLevresGroupe" className="duterlabia-target">
                        {!isClosed && <GrandeLevreD show={showOnThisStep.find(component => component === 'GrandeLevreD' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'GrandeLevreD')} />}
                        {!isClosed && <GrandeLevreG show={showOnThisStep.find(component => component === 'GrandeLevreG' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'GrandeLevreG')} />}

                        {isClosed && <GrandeLevreDFerme show={showOnThisStep.find(component => component === 'GrandeLevreDFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'GrandeLevreDFerme')} />}
                        {isClosed && <GrandeLevreGFerme show={showOnThisStep.find(component => component === 'GrandeLevreGFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'GrandeLevreGFerme')} />}

                        {isClosed && (type === 2 && subType === 'c') || (type === 3 && subType === 'b') || (type === 3 && subType === 'd') && <GrandLevreG2cFerme show={showOnThisStep.find(component => component === 'GrandLevreG2cFerme')} hide={hideOnThisStep.find(component => component === 'GrandLevreG2cFerme' || component === 'fgm')} />}
                        {isClosed && (type === 2 && subType === 'c') || (type === 3 && subType === 'b') || (type === 3 && subType === 'd') && <GrandLevreD2cFerme show={showOnThisStep.find(component => component === 'GrandLevreD2cFerme')} hide={hideOnThisStep.find(component => component === 'GrandLevreD2cFerme' || component === 'fgm')} />}
                        {!isClosed && (type === 2 && subType === 'c') || (type === 3 && subType === 'b') || (type === 3 && subType === 'd') && <GrandeLevreG2C show={showOnThisStep.find(component => component === 'GrandLevreG2C')} hide={hideOnThisStep.find(component => component === 'GrandLevreG2C' || component === 'fgm')} />}
                        {!isClosed && (type === 2 && subType === 'c') || (type === 3 && subType === 'b') || (type === 3 && subType === 'd') && <GrandeLevreD2C show={showOnThisStep.find(component => component === 'GrandLevreD2C')} hide={hideOnThisStep.find(component => component === 'GrandLevreD2C' || component === 'fgm')} />}
                    </g>


                    <g id="PrepucesGroupe" className="st4 transition" style={{ opacity: r_skin_opacity }}>
                        {!isClosed && <Prepuce show={showOnThisStep.find(component => component === 'Prepuce' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'Prepuce')} />}
                        {isClosed && <PrepuceFerme show={showOnThisStep.find(component => component === 'PrepuceFerme' || component === 'normal')} hide={hideOnThisStep.find(component => component === 'PrepuceFerme')} />}

                        {!isClosed && (type == !1 && subType === 'a') || (type === 3 && location.pathname === '/surgerie') && <Prepuce2a show={showOnThisStep.find(component => component === 'Prepuce2a')} hide={hideOnThisStep.find(component => component === 'Prepuce2a' || component === 'fgm')} />}
                        {isClosed && (type == !1 && subType === 'a') || (type === 3 && location.pathname === '/surgerie') && <Prepuce2aFerme show={showOnThisStep.find(component => component === 'Prepuce2aFerme')} hide={hideOnThisStep.find(component => component === 'Prepuce2aFerme' || component === 'fgm')} />}

                    </g>




                    {true && <CicatriceExcision show={showOnThisStep.find(component => component === 'CicatriceExcision')} hide={hideOnThisStep.find(component => component === 'CicatriceExcision' || component === 'fgm')} />}

                    {true && <Pont show={showOnThisStep.find(component => component === 'Pont')} hide={hideOnThisStep.find(component => component === 'Pont' || component === 'fgm')} />}

                    {true && <Cicatrice3 show={showOnThisStep.find(component => component === 'Cicatrice3')} hide={hideOnThisStep.find(component => component === 'Cicatrice3' || component === 'fgm')} />}

                    {true && <Suture3a show={showOnThisStep.find(component => component === 'Suture3a')} hide={hideOnThisStep.find(component => component === 'Suture3a' || component === 'fgm')} />}

                    {true && <Suture3b show={showOnThisStep.find(component => component === 'Suture3b')} hide={hideOnThisStep.find(component => component === 'Suture3b' || component === 'fgm')} />}



                    <Desinfection show={showOnThisStep.find(component => component === 'Desinfection')} hide={hideOnThisStep.find(component => component === 'Desinfection' || component === 'fgm')} />
                    <Anestesie show={showOnThisStep.find(component => component === 'Anestesie')} hide={hideOnThisStep.find(component => component === 'Anestesie' || component === 'fgm')} />
                    <Incision show={showOnThisStep.find(component => component === 'Incision')} hide={hideOnThisStep.find(component => component === 'Incision' || component === 'fgm')} />
                    <SutureLabia show={showOnThisStep.find(component => component === 'SutureLabia')} hide={hideOnThisStep.find(component => component === 'SutureLabia' || component === 'fgm')} />
                    <SoinLabia show={showOnThisStep.find(component => component === 'SoinLabia')} hide={hideOnThisStep.find(component => component === 'SoinLabia' || component === 'fgm')} />
                    <SurgerieGlans show={showOnThisStep.find(component => component === 'SurgerieGlans')} hide={hideOnThisStep.find(component => component === 'SurgerieGlans' || component === 'fgm')} />
                    <IncisionGlans show={showOnThisStep.find(component => component === 'IncisionGlans')} hide={hideOnThisStep.find(component => component === 'IncisionGlans' || component === 'fgm')} />
                    <Ligament show={showOnThisStep.find(component => component === 'Ligament')} hide={hideOnThisStep.find(component => component === 'Ligament' || component === 'fgm')} />
                    <SutureGlans show={showOnThisStep.find(component => component === 'SutureGlans')} hide={hideOnThisStep.find(component => component === 'SutureGlans' || component === 'fgm')} />
                    <SuturePeau show={showOnThisStep.find(component => component === 'SuturePeau')} hide={hideOnThisStep.find(component => component === 'SuturePeau' || component === 'fgm')} />
                    <SoinGlans show={showOnThisStep.find(component => component === 'SoinGlans')} hide={hideOnThisStep.find(component => component === 'SoinGlans' || component === 'fgm')} />
                </g>
            </g>


        </svg>
    )
}
