import React, { useEffect, useState } from 'react'
import { Header } from './header/header'
import { useDispatch, useSelector } from 'react-redux';
import { first, second } from '../../services/translation/i18n';
import { useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

export const GlobalLayout = (props) => {

    const r_action = useDispatch()
    const r_reload = useSelector((state) => state.language.reload)

    const loading = props.loading

    return (

        <div className={`flex f-full f-column min-h-full-vh ${props.className}`}>
            {!loading
                ? <>
                    <Header onLanguage={props.onLanguage} saveNewLanguage={props.saveNewLanguage} />
                    {props.children}
                </>
                : <div className="flex f-center h-full-vh">
                    <Loader
                        type="Oval"
                        color="white"
                        height={70}
                        width={70}
                        timeout={3000}
                    />
                </div>}
        </div>
    )
}