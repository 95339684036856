import React from 'react'
import { useSelector } from 'react-redux'

export const PetitesLevre2a = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetitesLevre2a" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
        <path fill={r_labia_color} className="st--5" d="M173.9,109.7c-0.8,7.2-5.8,31.3-6.6,31.3s-4.8-18.1-5.6-25.3c-0.3-3.1-2.3-11.8-2-14.1
        c0.4-3.1,1.8-12,2.5-14.2c1.1-3.8,3.3-17.7,5.2-17.7s3.6,12.9,4.7,16.7c0.5,1.6,2.2,10.8,2.5,12.9
        C175,102.1,174.3,105.6,173.9,109.7z"/>
        <path className="st2" d="M174.2,109.7c-0.4,3-1,6-1.6,9s-1.2,6-1.8,9s-1.3,6-2,8.9c-0.2,0.7-0.4,1.5-0.6,2.2
        c-0.1,0.4-0.2,0.7-0.3,1.1c-0.1,0.2-0.1,0.4-0.2,0.5s-0.1,0.2-0.1,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.2
        c-0.3-0.1-0.3-0.2-0.3-0.3l-0.1-0.3c-0.1-0.2-0.2-0.4-0.2-0.5c-0.1-0.4-0.2-0.7-0.4-1.1c-0.2-0.7-0.4-1.5-0.6-2.2
        c-1.6-5.9-2.9-11.9-3.9-17.9c-0.3-1.5-0.4-3-0.7-4.5l-0.8-4.5c-0.3-1.5-0.5-3-0.8-4.5c-0.1-0.8-0.2-1.5-0.3-2.3
        c0-0.4,0-0.8,0-1.2c0-0.4,0.1-0.8,0.1-1.2c0.4-3,0.9-6.1,1.4-9.1c0.3-1.5,0.5-3,1-4.5s0.7-2.9,1-4.4c0.7-3,1.3-6,2.1-8.9
        c0.2-0.7,0.4-1.5,0.7-2.2c0.1-0.4,0.3-0.7,0.5-1.1c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.2,0.5-0.4,0.8-0.5c0.3,0,0.7,0.1,0.9,0.4
        c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.4,0.4,0.7,0.5,1.1c0.3,0.7,0.5,1.5,0.7,2.2c0.8,3,1.3,6,1.9,9c0.3,1.5,0.6,3,1,4.5
        s0.6,3,0.9,4.5l0.8,4.5l0.4,2.2c0.2,0.8,0.3,1.5,0.3,2.3C175.2,103.7,174.5,106.7,174.2,109.7z M173.5,109.7
        c0.3-3,0.9-6.1,0.7-9.1c-0.1-0.7-0.2-1.5-0.3-2.2l-0.4-2.2l-0.9-4.5c-0.3-1.5-0.6-3-0.9-4.5s-0.8-3-1-4.5c-0.6-3-1.2-6-1.9-8.9
        c-0.2-0.7-0.4-1.5-0.7-2.1c-0.1-0.3-0.3-0.7-0.4-1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.2,0.2
        c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.3-0.3,0.7-0.4,1c-0.3,0.7-0.5,1.4-0.7,2.1c-0.8,2.9-1.4,5.9-2,8.9c-0.3,1.5-0.6,3-1,4.5
        c-0.4,1.5-0.7,2.9-0.9,4.4c-0.5,3-1,6-1.5,9c-0.1,0.7-0.2,1.5-0.1,2.2c0,0.7,0.2,1.5,0.2,2.2c0.2,1.5,0.5,3,0.7,4.5l0.8,4.5
        c0.2,1.5,0.3,3,0.6,4.5c0.9,6,2.1,12,3.5,17.9c0.2,0.7,0.4,1.5,0.6,2.2c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.2,0.1,0.4,0.2,0.5
        l0.1,0.2c0,0.1,0.1,0.2,0,0.1s-0.1,0-0.1,0l0.1-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.3-1.1
        c0.2-0.7,0.4-1.5,0.6-2.2c0.7-3,1.4-6,2-8.9C171.7,121.7,172.8,115.7,173.5,109.7z"/>
    </g>
    )
}