import React from 'react'
import { useSelector } from 'react-redux'

export const Glans1a = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Glans1a" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
            <path className="st1" d="M169.4,82.6c-0.2,1.3-0.5,2.6-1,3.9c-0.5,1-1,1.6-1.5,1.6s-1.1-0.6-1.5-1.6c-0.5-1.2-0.9-2.5-1.2-3.9
					c0-0.5,0.1-1,0.4-1.4c0.3-1,1.2-1.7,2.3-1.7c1,0.1,1.9,0.7,2.3,1.7C169.4,81.7,169.5,82.2,169.4,82.6z"/>
            <path className="st2" d="M169.6,82.7c-0.1,1.2-0.3,2.4-0.7,3.6c-0.2,0.6-0.5,1.2-0.9,1.7c-0.2,0.3-0.6,0.6-1,0.6
					c-0.4,0.1-0.9-0.1-1.2-0.4c-0.5-0.5-0.8-1-1-1.7c-0.2-0.6-0.4-1.1-0.5-1.7c-0.2-0.6-0.3-1.2-0.4-1.8c-0.1-0.3-0.1-0.7,0-1
					c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.1-0.2,0.2-0.3l0,0c0.2-0.5,0.5-0.9,1-1.3c0.4-0.3,1-0.5,1.5-0.5c0.5,0,1,0.2,1.5,0.5
					c0.4,0.3,0.8,0.8,1,1.3l0,0c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.1,0.4C169.6,82.1,169.7,82.4,169.6,82.7z M169.2,82.7
					c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.4l0,0c-0.1-0.5-0.4-0.9-0.8-1.2c-0.4-0.3-0.9-0.5-1.3-0.5
					c-0.5,0-1,0.2-1.3,0.5c-0.4,0.3-0.6,0.7-0.8,1.2l0,0c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.3,0,0.5,0,0.8
					c0.1,0.6,0.3,1.1,0.5,1.6c0.3,1.1,0.8,2,1.5,2.9c0.2,0.1,0.3,0.2,0.4,0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.3-0.4,0.6-0.9,0.8-1.4
					C168.6,84.9,168.9,83.8,169.2,82.7L169.2,82.7z"/>
        </g>
    )
}