import React from 'react'
import { useSelector } from 'react-redux'

export const ContourVestibuleType3intermediaire = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="ContourVestibuleType3intermediaire" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                            <path className="st2" d="M167.1,130.8C167,130.8,167.1,130.8,167.1,130.8c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.1-0.6
							c0.1-0.4,0.1-0.9,0.1-1.3c0.1-0.9,0.1-1.8,0.1-2.7c0-1.8,0-3.6,0-5.4c-0.1-3.6-0.3-7.2-0.5-10.8s-0.4-7.2-0.7-10.8l-0.2-2.7
							c-0.1-0.9-0.1-1.8,0-2.8c0.1-1.8,0.3-3.6,0.6-5.5l0,0l0.7-3.4l0.3,3.4c0.2,1.8,0.3,3.6,0.4,5.4l0.2,2.7c0.1,0.9,0.1,1.8,0,2.8
							c-0.2,3.6-0.4,7.2-0.7,10.8s-0.5,7.2-0.6,10.8c-0.1,1.8-0.2,3.6-0.2,5.4c0,0.9,0,1.8,0,2.7c0,0.4,0.1,0.9,0.1,1.3
							c0,0.2,0.1,0.4,0.1,0.6C167,130.6,167.1,130.7,167.1,130.8C167.1,130.9,167.2,130.9,167.1,130.8z M167.1,131.4
							c-0.2,0-0.4-0.2-0.5-0.4c-0.1-0.1-0.1-0.2-0.1-0.4c-0.1-0.2-0.1-0.5-0.1-0.7c-0.1-0.5-0.1-0.9-0.1-1.4c0-0.9-0.1-1.8-0.1-2.7
							c0-1.8,0.1-3.6,0.1-5.4c0.1-3.6,0.3-7.2,0.6-10.8s0.4-7.2,0.6-10.8c0.1-0.9,0.1-1.8,0-2.7l-0.2-2.7c-0.2-1.8-0.3-3.6-0.5-5.4
							l1,0l0,0c-0.3,1.7-0.6,3.5-0.7,5.3c0,0.9,0,1.8,0,2.6l0.2,2.7c0.2,3.6,0.5,7.2,0.6,10.8s0.3,7.2,0.4,10.9c0,1.8,0,3.6,0,5.4
							c0,0.9-0.1,1.8-0.1,2.7c0,0.5-0.1,0.9-0.2,1.4c0,0.2-0.1,0.5-0.2,0.7c0,0.1-0.1,0.3-0.1,0.4
							C167.5,131.3,167.3,131.4,167.1,131.4z"/>
                        </g>
    )
}