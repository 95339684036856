import React from 'react'
import { useSelector } from 'react-redux'

export const PetiteLevres3Final = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres3Final" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
            <path fill={r_labia_color} className="st--5" d="M170.6,105.7c-0.6,8.1-2.9,35.3-3.5,35.3s-2.9-25.1-3.5-33.2c-0.2-3.7-0.2-7.5,0-11.2
						c0.3-3.5,0.1-11.6,0.7-13.9c1-3.9,1-13.1,2.7-13.1s1.2,8.2,2.2,12.1c0.4,1.7,0.8,10,1.1,12.3
						C170.8,97.9,170.9,101.8,170.6,105.7z"/>
            <path className="st2" d="M171,105.7l-1.6,18l-0.9,9l-0.5,4.5c-0.1,0.8-0.2,1.5-0.3,2.2c0,0.4-0.1,0.8-0.2,1.1l-0.1,0.3l-0.1,0.2
						c0,0,0,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0c-0.1,0-0.1,0-0.1-0.1c-0.1-0.2-0.2-0.4-0.2-0.6c-0.6-3-0.9-6-1.2-9l-1-9l-0.9-9
						c-0.2-3-0.6-6-0.6-9s0-6.1,0.2-9s0.1-6,0.2-9c0-0.8,0.1-1.5,0.2-2.3c0-0.4,0.1-0.8,0.2-1.2l0.2-1.1c0.3-1.4,0.5-2.9,0.6-4.4
						s0.3-3,0.6-4.5c0.1-0.8,0.3-1.5,0.5-2.3c0.1-0.4,0.3-0.8,0.5-1.2c0.2-0.3,0.5-0.4,0.8-0.4c0.3,0,0.6,0.2,0.8,0.5
						c0.4,0.7,0.6,1.5,0.7,2.4c0.1,0.8,0.2,1.5,0.2,2.3c0.1,1.5,0.2,3,0.4,4.5c0.1,0.7,0.2,1.5,0.3,2.2c0.1,0.4,0.2,0.7,0.2,1.1
						s0.1,0.8,0.2,1.1c0.2,1.5,0.3,3,0.4,4.5l0.3,4.5c0.1,1.5,0.3,3,0.4,4.5C171.2,99.7,171.2,102.7,171,105.7z M170.3,105.7
						c0.2-3,0.2-6,0-9c-0.1-1.5-0.3-3-0.4-4.5l-0.3-4.5c-0.1-1.5-0.2-3-0.4-4.5c0-0.4-0.1-0.7-0.2-1.1s-0.2-0.7-0.2-1.1
						c-0.2-0.8-0.3-1.5-0.3-2.3c-0.2-1.5-0.3-3-0.4-4.5c-0.1-0.8-0.1-1.5-0.2-2.2c-0.1-0.7-0.3-1.4-0.6-2c-0.1-0.1-0.1-0.1-0.1-0.1
						s0,0-0.1,0.1c-0.2,0.3-0.3,0.6-0.4,0.9c-0.2,0.7-0.3,1.4-0.4,2.1c-0.2,1.5-0.3,3-0.5,4.5s-0.3,3-0.6,4.5l-0.2,1.1
						c-0.1,0.4-0.1,0.7-0.1,1.1c-0.1,0.7-0.1,1.5-0.1,2.2c-0.1,3-0.2,6-0.3,9s-0.3,6-0.3,9s0.3,6,0.5,9l0.7,9l0.8,9
						c0.3,3,0.6,6,1.1,8.9c0,0.2,0.1,0.3,0.1,0.5c-0.1,0-0.1,0-0.2,0l0,0v-0.1l0.1-0.3c0.1-0.4,0.1-0.7,0.2-1.1
						c0.1-0.7,0.2-1.5,0.3-2.2l0.5-4.5l0.8-9C169.5,117.7,169.9,111.7,170.3,105.7L170.3,105.7z"/>
        </g>
    )
}