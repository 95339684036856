import React from 'react'
import { useSelector } from 'react-redux'

export const ContourVestibuleType3final = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <path id="ContourVestibuleType3final" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}} className="st10 transition" d="M167.1,131.4c1.5,0,1.9-19.4,0-19.4S165.6,131.4,167.1,131.4z" />
    )
}