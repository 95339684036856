import React from 'react'
import { useSelector } from 'react-redux'

export const OrificeUrinaire = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="OrificeUrinaire" className={"uretralopening-target"}>
            <path className="st2" d="M167.1,102.4c-0.1-0.9-0.1-1.8,0-2.6c0.1-0.9,0.2-1.7,0.2-2.6c0.2,0.9,0.3,1.8,0.2,2.6
									C167.4,100.7,167.3,101.5,167.1,102.4z"/>
            <path className="st2" d="M169,101.8c-0.3-0.3-0.6-0.6-0.9-0.8l-0.9-0.8c-0.3-0.3-0.6-0.5-0.9-0.9c-0.3-0.3-0.6-0.6-0.7-1
									c0.3,0.3,0.6,0.5,0.9,0.8c0.3,0.2,0.6,0.5,1,0.8s0.6,0.6,0.9,0.9C168.6,101,168.9,101.4,169,101.8z"/>
            <path className="st2" d="M165.7,100.4c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.5-0.2,0.7-0.3s0.5-0.1,0.7-0.2
									c0.3-0.1,0.5-0.1,0.8-0.1c-0.2,0.1-0.5,0.2-0.7,0.4c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.2
									C166.3,100.5,166,100.5,165.7,100.4z"/>
            <g id="Uretre">
                <path className="st2" d="M167.4,96.1c0.4,0.1,0.8,0.2,1.2,0.5c0.4,0.2,0.7,0.5,1,0.8c0.7,0.6,1.1,1.4,1.1,2.3
										c-0.1,0.9-0.5,1.7-1.1,2.4c-0.5,0.7-1.3,1.2-2.2,1.5c-0.5,0.1-1,0-1.4-0.3c-0.4-0.3-0.7-0.6-0.9-1
										c-0.4-0.8-0.7-1.6-0.7-2.5c0-0.9,0.4-1.7,0.9-2.4C165.8,96.8,166.6,96.3,167.4,96.1z M167.4,96.1c-0.8,0.2-1.6,0.7-2,1.4
										c-0.5,0.7-0.8,1.5-0.8,2.3c0.1,0.8,0.4,1.6,0.8,2.3c0.3,0.7,1.1,1.1,1.9,1.1c0.8-0.2,1.5-0.7,1.9-1.3
										c0.6-0.6,0.9-1.3,1.1-2.1c0-0.8-0.3-1.6-0.9-2.2c-0.3-0.3-0.6-0.6-0.9-0.9C168.2,96.4,167.8,96.2,167.4,96.1z"/>
            </g>
        </g>
    )
}
