import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresBigBoth = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="bigLabiaBoth">
                <path fill={r_labia_color} className="st--5" d="M187.3,109.7c-1.2,7.2-18.9,31.3-20.2,31.3s-18.7-24.1-19.9-31.3c-0.5-2.7-0.5-5.4,0-8.1
									c0.6-3.1,8.1-12,9.1-14.2c1.7-3.8,7.9-17.7,10.7-17.7s8.4,12.9,10.1,16.7c0.7,1.6,8.9,10.8,9.5,12.9
									C187.7,102.7,187.9,106.2,187.3,109.7z"/>
                <path class="st2" d="M187.7,109.8c-0.3,1.8-1.1,3.4-1.9,5c-0.8,1.6-1.6,3.1-2.5,4.6c-1.8,3-3.7,6-5.6,8.8
									c-2,2.9-4,5.7-6.1,8.5c-1.1,1.4-2.1,2.8-3.4,4c-0.2,0.2-0.3,0.3-0.5,0.5c0,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.3,0.1-0.5,0
									c-0.2-0.1-0.3-0.2-0.4-0.3c-0.4-0.3-0.7-0.7-1-1c-0.6-0.7-1.1-1.4-1.7-2c-4.2-5.6-8.1-11.4-11.7-17.4
									c-0.9-1.5-1.7-3-2.5-4.6c-0.8-1.6-1.6-3.1-2.2-4.8c-0.6-1.7-0.7-3.4-0.8-5.2c0-0.9,0-1.8,0.1-2.6c0-0.4,0.1-0.9,0.2-1.3
									c0.1-0.4,0.1-0.9,0.3-1.3c0.7-1.7,1.6-3.1,2.6-4.6c1-1.5,2-2.9,3-4.3c1-1.4,2.1-2.8,2.9-4.3c0.4-0.7,0.7-1.6,1.1-2.3
									l1.1-2.4c1.5-3.2,3-6.3,4.9-9.3c0.5-0.8,0.9-1.5,1.5-2.2c0.6-0.7,1.1-1.4,2-2c0.2-0.1,0.5-0.2,0.8-0.3
									c0.3,0,0.6,0.1,0.8,0.2c0.5,0.3,0.8,0.6,1.1,0.9c0.6,0.7,1.2,1.4,1.6,2.1c1.9,3,3.5,6.1,4.9,9.3l1.1,2.4l0.5,1.2
									c0.2,0.4,0.3,0.8,0.5,1.1c0.9,1.4,2.1,2.8,3.1,4.1c1.1,1.4,2.2,2.7,3.2,4.1c0.5,0.7,1.1,1.4,1.6,2.1c0.5,0.7,1,1.4,1.4,2.4
									C188.1,102.6,188.3,106.3,187.7,109.8z M187,109.6c0.6-3.4,0.4-6.9-0.6-10.2c-0.2-0.7-0.8-1.4-1.3-2.1
									c-0.5-0.7-1-1.4-1.6-2.1c-1.1-1.4-2.2-2.7-3.3-4.1c-1.1-1.4-2.2-2.7-3.2-4.2c-0.3-0.4-0.4-0.8-0.6-1.2l-0.5-1.2l-1.1-2.4
									c-1.5-3.1-3-6.3-4.9-9.2c-0.5-0.7-1-1.4-1.5-2c-0.3-0.3-0.6-0.6-0.9-0.7c-0.3-0.2-0.5-0.2-0.8,0c-0.6,0.4-1.2,1-1.7,1.7
									c-0.5,0.7-1,1.4-1.4,2.1c-1.8,2.9-3.4,6.1-4.9,9.2c-0.8,1.6-1.4,3.1-2.2,4.8c-1,1.5-2,2.9-3.1,4.3c-1,1.4-2.1,2.8-3.1,4.2
									c-1,1.4-2,2.9-2.6,4.4c-0.3,0.7-0.4,1.7-0.5,2.5c-0.1,0.9-0.1,1.7-0.1,2.6c0,0.9,0.1,1.7,0.2,2.6c0.1,0.9,0.3,1.7,0.6,2.5
									c1.2,3.3,2.9,6.3,4.7,9.3c3.7,5.9,7.6,11.7,11.9,17.1c0.5,0.7,1.1,1.3,1.7,2c0.3,0.3,0.6,0.6,0.9,0.9
									c0.1,0.1,0.2,0.1,0.2,0.1c0,0-0.2,0-0.2,0c0,0,0,0,0.1,0c0.1-0.1,0.3-0.2,0.4-0.4c1.2-1.2,2.2-2.6,3.3-4
									c2.1-2.7,4.2-5.6,6.1-8.4c2-2.9,3.9-5.8,5.7-8.8c0.9-1.5,1.7-3,2.5-4.6C185.9,112.9,186.7,111.3,187,109.6z"/>
            </g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}