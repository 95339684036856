import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresFermeLeftBigRightLittle = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="bigRight_x5F_littleLeft">
							<path fill={r_labia_color} className="st--5" d="M176,109.5c-0.9,7.9-7.8,31.1-8.8,31.1s-15.6-23.2-16.5-31.1c-0.4-2.9-0.4-5.9,0-8.9
								c0.5-3.4,4.9-11.2,5.9-13.4c1.6-3.8,7.7-17.7,10.3-17.7s5.1,12.9,6.7,16.7c0.7,1.6,1.3,9.6,1.8,11.9
								C176.3,101.9,176.5,105.8,176,109.5z"/>
							<path class="st2" d="M176.2,109.5c-0.4,3.2-1.1,6.4-1.9,9.5c-0.8,3.1-1.6,6.2-2.5,9.3c-0.9,3.1-1.8,6.2-2.9,9.2
								c-0.3,0.8-0.6,1.5-0.9,2.3c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.2,0.2c0,0-0.2,0.1-0.3,0.1
								c-0.1,0-0.3-0.1-0.3-0.1c-0.3-0.2-0.4-0.3-0.5-0.5c-0.1-0.2-0.3-0.3-0.4-0.5c-1-1.3-1.8-2.7-2.7-4
								c-3.4-5.5-6.6-11.1-9.3-16.9c-0.7-1.5-1.3-2.9-1.9-4.4c-0.6-1.5-1.1-3-1.4-4.6c-0.5-3.2-0.5-6.4-0.1-9.7
								c0.3-1.6,1-3.1,1.6-4.6c0.6-1.5,1.3-2.9,2.1-4.4c0.7-1.4,1.5-2.9,2.1-4.3c0.6-1.5,1.3-3,1.9-4.4c1.3-2.9,2.7-5.8,4.3-8.6
								c0.8-1.4,1.6-2.8,2.8-4c0.3-0.3,0.6-0.6,1.2-0.8c0.3-0.1,0.6-0.1,0.9,0c0.3,0.1,0.5,0.3,0.7,0.4c0.7,0.7,1,1.4,1.4,2.2
								c1.3,3,2.1,6.1,3,9.2c0.4,1.5,0.8,3.1,1.4,4.6l0.2,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.4,0.2,0.8,0.2,1.2
								c0.1,0.8,0.2,1.6,0.3,2.4c0.4,3.2,0.6,6.4,1.3,9.5C176.6,103.1,176.7,106.3,176.2,109.5z M175.8,109.5
								c0.4-3.2,0.3-6.4-0.2-9.5c-0.7-3.1-1-6.4-1.4-9.5c-0.1-0.8-0.2-1.6-0.4-2.4c-0.1-0.4-0.1-0.8-0.2-1.1c0-0.2-0.1-0.4-0.2-0.5
								l-0.2-0.6c-0.6-1.5-1-3.1-1.5-4.6c-0.9-3.1-1.7-6.2-3-9.1c-0.3-0.7-0.7-1.4-1.2-1.9c-0.3-0.2-0.4-0.3-0.6-0.2
								c-0.2,0.1-0.5,0.3-0.8,0.6c-1,1.1-1.9,2.5-2.7,3.8c-1.6,2.8-3,5.6-4.3,8.6l-2,4.4c-0.7,1.5-1.5,2.9-2.2,4.3
								c-0.7,1.4-1.5,2.8-2.1,4.3c-0.7,1.4-1.3,2.9-1.7,4.4c-0.5,3.1-0.5,6.4-0.1,9.5c0.6,3.1,2,6.1,3.3,9
								c2.8,5.7,6.1,11.3,9.6,16.7c0.9,1.3,1.8,2.7,2.7,3.9l0.4,0.4c0.1,0.1,0.3,0.3,0.3,0.3c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0
								c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0.3-0.7,0.6-1.5,0.9-2.2c1.1-3,2-6.1,3-9.1
								c0.9-3.1,1.8-6.2,2.6-9.3C174.6,115.8,175.3,112.6,175.8,109.5z"/>
						</g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}