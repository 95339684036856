import React from 'react'
import { first, second } from '../../services/translation/i18n'
import { ColorPicker } from '../colorPicker/colorPicker'
import { Hymen1v2 } from '../svg/v2/vestibule/hymen/hymen1'
import { Hymen2 } from '../svg/v2/vestibule/hymen/hymen2'
import { Hymen3 } from '../svg/v2/vestibule/hymen/hymen3'
import { Hymen4 } from '../svg/v2/vestibule/hymen/hymen4'
import { TypeHymen } from './typeHymen'
import SoloHymen1 from '../../assets/img/solo_hymen0.svg'
import SoloHymen2 from '../../assets/img/solo_hymen2.svg'
import SoloHymen3 from '../../assets/img/solo_hymen3.svg'
import SoloHymen4 from '../../assets/img/solo_hymen4.svg'
import SoloHymen5 from '../../assets/img/solo_hymen6.svg'

export const Hymens = (props) => {

    const typesHymens = [
        {
            label: 'ecran1-hymen-annulaire',
            img: SoloHymen1,
            nbSVG: 1
        },
        {
            label: 'hymen-crescentic',
            img: SoloHymen5,
            nbSVG: 5
        },
        {
            label: 'ecran1-hymen-cloisonne',
            img: SoloHymen4,
            nbSVG: 4
        },
        {
            label: 'hymen-cribriforme',
            img: SoloHymen3,
            nbSVG: 3
        },
        {
            label: 'hymen-non-perfore',
            img: SoloHymen2,
            nbSVG: 2
        },

    ]

    return (
        <div>
            <h2 className="text-black text-center text-bolder text-30">{first.t('ecran1-hymen-forme-choix')}</h2>
            {first.language !== second.language ? <h2 className="text-black text-center text-18 mt-03">{second.t('ecran1-hymen-forme-choix')}</h2> : ""}

            <div className="relative overflow f-1 h-full mt-1" style={{ height: 200 }}>
                <div className="flex absolute">
                    {typesHymens.map((typeHymen, i) => {
                        return (
                            <TypeHymen key={i} typeHymen={typeHymen} hymenNb={typeHymen.nbSVG} />
                        )
                    })}
                </div>

            </div>
        </div>
    )
}
