import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresFermeLeftMediumRightLittle = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="mediumRight_x5F_littleLeft">
                <path fill={r_labia_color} className="st--5" d="M176,109.5c-0.9,7.9-7.8,31.1-8.8,31.1s-11.6-23.2-12.5-31.1c-0.4-2.9-0.4-5.9,0-8.9
								c0.5-3.4,3.9-11.2,4.9-13.4c1.6-3.8,4.7-17.7,7.3-17.7s5.1,12.9,6.7,16.7c0.7,1.6,1.3,9.6,1.8,11.9
								C176.3,101.9,176.5,105.8,176,109.5z"/>
                <path class="st2" d="M176.2,109.5c-0.4,3.1-1.1,6.2-1.8,9.3c-0.7,3.1-1.5,6.1-2.4,9.1c-0.9,3-1.7,6-2.8,9
								c-0.3,0.7-0.5,1.5-0.8,2.2c-0.2,0.4-0.3,0.7-0.5,1.1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.3,0.4c-0.1,0-0.2,0.1-0.3,0.1
								c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.9-1.4-1.5-2.8-2.2-4.2
								c-2.7-5.7-5.1-11.5-7.1-17.5c-0.5-1.5-1-3-1.4-4.5c-0.4-1.5-0.9-3-1.1-4.6c-0.4-3.1-0.5-6.3-0.1-9.5c0.3-1.6,0.8-3.1,1.3-4.6
								c0.5-1.5,1.1-3,1.7-4.4c0.6-1.5,1.2-2.9,1.8-4.3c0.5-1.4,1-2.9,1.4-4.4c0.9-3,1.8-6,2.9-9c0.3-0.7,0.6-1.5,1-2.2
								c0.4-0.7,0.7-1.5,1.6-2c0.2-0.1,0.5-0.3,0.8-0.2c0.3,0,0.6,0.2,0.8,0.3c0.4,0.3,0.7,0.7,0.9,1c0.5,0.7,0.8,1.4,1.1,2.2
								c1.2,3,2,6,2.9,9c0.4,1.5,0.8,3,1.4,4.4c0.3,0.8,0.4,1.6,0.5,2.4c0.1,0.8,0.2,1.6,0.3,2.3l0.5,4.7c0.1,0.8,0.2,1.6,0.3,2.3
								c0.1,0.8,0.3,1.6,0.4,2.3C176.6,103.2,176.7,106.4,176.2,109.5z M175.8,109.5c0.4-3.1,0.3-6.2-0.2-9.3
								c-0.1-0.8-0.3-1.5-0.4-2.3c-0.2-0.8-0.3-1.6-0.4-2.3l-0.6-4.7c-0.1-0.8-0.2-1.5-0.3-2.3c-0.1-0.8-0.3-1.6-0.5-2.2
								c-0.6-1.5-1-3-1.5-4.5c-0.9-3-1.7-6.1-2.8-8.9c-0.3-0.7-0.6-1.4-1-2c-0.2-0.3-0.4-0.6-0.6-0.8c-0.3-0.2-0.3-0.2-0.6,0
								c-0.5,0.3-0.9,1-1.2,1.7c-0.4,0.7-0.7,1.4-0.9,2.1c-1.1,2.9-2,5.9-2.9,8.9c-0.5,1.5-0.9,3-1.5,4.5c-0.7,1.4-1.3,2.9-1.9,4.3
								c-0.6,1.4-1.2,2.9-1.8,4.3c-0.5,1.5-1.1,2.9-1.4,4.4c-0.5,3.1-0.6,6.2-0.1,9.3c0.4,3.1,1.5,6.1,2.5,9
								c2.2,5.9,4.7,11.6,7.5,17.3c0.7,1.4,1.4,2.8,2.2,4.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0
								c0,0,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.7,0.5-1l0.8-2.2c1-2.9,2-5.9,2.9-9c0.9-3,1.7-6,2.5-9.1
								C174.6,115.6,175.3,112.6,175.8,109.5z"/>
            </g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}