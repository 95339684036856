import React from 'react'
import { useSelector } from 'react-redux'

export const PetitesLevres1aFerme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetitesLevres1aFerme" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
            <path fill={r_labia_color} className="st--5" d="M175.5,109.5c-0.9,7.9-7.8,31.1-8.8,31.1s-7.9-23.2-8.8-31.1c-0.4-2.9-0.4-5.9,0-8.9
						c0.5-3.4,1.2-11.2,2.2-13.4c1.6-3.8,3.9-10.9,6.6-10.9s4,6.1,5.6,9.9c0.7,1.6,2.3,9.6,2.8,11.9
						C175.8,101.8,176,105.7,175.5,109.5z"/>
            <path className="st2" d="M175.9,109.5c-0.4,2.8-1,5.6-1.8,8.3c-0.7,2.8-1.4,5.5-2.2,8.2s-1.6,5.4-2.4,8.1c-0.4,1.3-0.9,2.7-1.4,4
						c-0.3,0.7-0.5,1.3-0.8,2c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0.1-0.2,0.1-0.3,0c-0.1,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
						c-0.2-0.3-0.3-0.7-0.5-1c-1.1-2.6-1.9-5.3-2.8-8s-1.6-5.4-2.4-8.2s-1.5-5.5-2.1-8.2c-0.7-2.8-1.1-5.6-1.3-8.5
						c-0.1-1.4,0-2.9,0.1-4.3c0.1-1.4,0.3-2.8,0.5-4.2c0.3-2.8,0.6-5.6,1.2-8.5c0.1-0.7,0.3-1.4,0.5-2.1c0.3-0.7,0.5-1.3,0.8-2
						c0.5-1.3,1.1-2.6,1.7-3.9c0.6-1.3,1.3-2.6,2.1-3.8c0.4-0.7,1-1.2,1.8-1.5c0.4-0.2,0.9-0.2,1.3-0.1c0.4,0.1,0.8,0.3,1.1,0.6
						c1,1.1,1.8,2.4,2.2,3.8c0.5,1.3,1,2.7,1.5,4c0.2,0.7,0.5,1.3,0.8,2s0.4,1.4,0.6,2.1c0.3,1.4,0.7,2.8,0.9,4.1l0.9,4.2
						c0.3,1.4,0.6,2.8,0.7,4.2C176.2,103.8,176.2,106.7,175.9,109.5z M175.2,109.4c0.3-2.8,0.3-5.6,0-8.4c-0.2-1.4-0.4-2.8-0.7-4.2
						l-0.9-4.1c-0.3-1.4-0.6-2.8-1-4.1c-0.2-0.7-0.4-1.4-0.6-2s-0.5-1.3-0.8-2c-0.5-1.3-1-2.7-1.5-4c-0.4-1.3-1.1-2.5-2-3.5
						c-0.4-0.4-1-0.5-1.5-0.3c-0.6,0.3-1,0.7-1.4,1.3c-0.8,1.1-1.4,2.4-2,3.6c-0.6,1.3-1.1,2.6-1.6,3.9c-0.2,0.6-0.6,1.4-0.8,2
						c-0.2,0.7-0.4,1.3-0.5,2c-0.5,2.8-0.8,5.6-1.2,8.4c-0.2,1.4-0.4,2.8-0.5,4.2c-0.1,1.4-0.2,2.8-0.1,4.2c0.1,2.8,0.5,5.6,1.1,8.3
						c0.6,2.8,1.3,5.5,2,8.2c0.7,2.7,1.5,5.5,2.2,8.2s1.6,5.4,2.6,8c0.1,0.3,0.3,0.7,0.4,1c0,0.1,0.1,0.1,0.1,0.2l0,0.1
						c0,0-0.1,0-0.1,0c0.1-0.1,0.2-0.2,0.2-0.4c0.3-0.6,0.6-1.3,0.8-1.9c0.5-1.3,0.9-2.7,1.4-4c0.9-2.7,1.7-5.4,2.4-8.1
						s1.5-5.5,2.1-8.2C174.3,115,174.8,112.2,175.2,109.4L175.2,109.4z"/>
            <path className="st2" d="M166.6,84.3c-1.3,3.7-2.1,7.5-2.5,11.4c-0.4,3.9-0.6,7.8-0.3,11.7c-0.3-3.9-0.3-7.8,0-11.8
						c0.3-3.9,1.1-7.8,2.2-11.6c0.1-0.2,0.2-0.2,0.4-0.2C166.6,84,166.7,84.2,166.6,84.3L166.6,84.3z"/>
            <path className="st2" d="M167.2,84.9c0.7,1.4,1.3,2.9,1.8,4.4c0.5,1.5,0.9,3,1.3,4.6c0.4,1.5,0.7,3.1,0.8,4.7
						c0.2,1.6,0.2,3.2-0.1,4.7c0.2-1.6,0.2-3.1-0.1-4.7c-0.2-1.6-0.6-3.1-1-4.6c-0.4-1.5-0.9-3-1.4-4.5c-0.5-1.5-1.1-2.9-1.9-4.3
						l0,0c-0.1-0.1,0-0.3,0.1-0.4C166.9,84.7,167.1,84.8,167.2,84.9C167.2,84.9,167.2,84.9,167.2,84.9L167.2,84.9z"/>
            <path className="st2" d="M172.2,107.3c0.1,1.7,0.3,3.5,0.3,5.2c0.1,1.7,0,3.5-0.2,5.2c-0.3,1.7-0.7,3.4-1.2,5.1
						c-0.6,1.6-1,3.3-1.4,5c0.2-1.7,0.6-3.4,1.1-5.1c0.5-1.7,0.9-3.3,1.2-5c0.2-1.7,0.3-3.4,0.3-5.2L172.2,107.3z"/>
            <path className="st2" d="M161.4,98.2c-0.3,0.9-0.5,1.8-0.6,2.7c-0.1,0.9-0.2,1.8-0.3,2.7c-0.1,1.8,0,3.7,0.3,5.5
						c-0.7-1.7-0.9-3.6-0.8-5.5c0.1-0.9,0.2-1.9,0.4-2.8C160.6,99.9,160.9,99,161.4,98.2z"/>
        </g>
    )
}