import React from 'react'
import { useSelector } from 'react-redux'

export const SoinGlans = (props) => {

    return (
        <g id="SoinGlans" className="transition" style={{ opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(0px, -15px)' }}>
            <defs>
			<rect id="SVGID_52_" x="0" y="21.5" width="333.5" height="215.2"/>
		</defs>
		<clipPath id="SVGID_53_">
			<use href="#SVGID_52_"  style={{"overflow":"visible"}}/>
		</clipPath>
		<g class="st66">
			<defs>
				<rect id="SVGID_54_" x="0" y="21.5" width="333.5" height="215.2"/>
			</defs>
			<clipPath id="SVGID_55_">
				<use href="#SVGID_54_"  style={{"overflow":"visible"}}/>
			</clipPath>
			<path class="st67" d="M167.1,98c0.1,1.1,0,10,0,10"/>
			<path class="st68" d="M167.1,98c0.2,0.8,0.3,1.7,0.3,2.5l0.1,2.5c0.1,1.7,0.1,3.4,0.1,5l-1,0c0-1.7,0-3.3,0.1-5l0.1-2.5
				C166.8,99.7,166.9,98.9,167.1,98"/>
			<line class="st69" x1="166.8" y1="88.6" x2="166.8" y2="94.9"/>
			<path class="st68" d="M166.8,88.6c0.2,1.1,0.3,2.1,0.4,3.2c0.1,1.1,0.2,2.1,0.1,3.2h-1c0-1.1,0.1-2.1,0.1-3.2
				C166.5,90.7,166.6,89.7,166.8,88.6"/>
			<path class="st67" d="M169.9,100.7c0,0.2,0.2,0.6,0.1,1.5c-0.1,0.9-0.9,2.9-1.4,3.9c-0.6,1-1.2,1.6-1.8,1.6
				c-0.7,0-1.3-0.6-1.8-1.6c-0.5-1-1.3-3.1-1.4-3.9c-0.1-0.8,0.1-1.3,0.2-1.5H169.9z"/>
			<path class="st68" d="M169.9,100.7c0.3,0.6,0.4,1.4,0.3,2.1c-0.1,0.7-0.3,1.4-0.5,2c-0.2,0.7-0.5,1.3-0.9,1.9
				c-0.2,0.3-0.4,0.6-0.7,0.9c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.3-1.2-0.5c-0.3-0.3-0.5-0.6-0.7-0.9c-0.4-0.6-0.6-1.3-0.8-2
				c-0.4-1.3-0.8-2.7-0.4-4.1H169.9z M169.9,100.7h-6.2c-0.2,0.7-0.2,1.4,0,2c0.2,0.6,0.5,1.3,0.8,1.9c0.3,0.6,0.6,1.2,1,1.7
				c0.4,0.5,0.9,0.9,1.2,0.9c0.4,0,0.8-0.4,1.2-0.9c0.4-0.5,0.6-1.1,0.9-1.7C169.5,103.2,170.1,102,169.9,100.7"/>
			<path class="st67" d="M166.8,99.1c2.1,0,3.1,1.6,3.1,1.6h-6.2C163.7,100.7,164.7,99.1,166.8,99.1"/>
		</g>
		<g class="st66">
			<defs>
				<path id="SVGID_56_" d="M164.5,95.9l-0.8,3.7v1.1h6.1v-2.4l-0.4-2.4c-0.5-1-1.5-1.5-2.4-1.5C166,94.4,165,94.9,164.5,95.9"/>
			</defs>
			<clipPath id="SVGID_57_">
				<use href="#SVGID_56_"  style={{"overflow":"visible"}}/>
			</clipPath>
			
				<linearGradient id="SVGID_58_" gradientUnits="userSpaceOnUse" x1="2624.8032" y1="3541.0083" x2="2625.8032" y2="3541.0083" gradientTransform="matrix(-8.806430e-02 11.6245 11.6245 8.806430e-02 -40764.4531 -30735.5957)">
				<stop  offset="0" style={{"stopColor":"#F9B233"}}/>
				<stop  offset="1" style={{"stopColor":"#E54D4F"}}/>
			</linearGradient>
			<polygon class="st70" points="169.9,94.4 169.8,100.7 163.7,100.7 163.7,94.4 			"/>
		</g>
		<g class="st66">
			<defs>
				<path id="SVGID_59_" d="M164.5,95.9l-1.3,5.8l2.2,6c0.6,1,2.1,1,2.7-0.1l2.2-6.6l-0.9-5.1c-0.5-1-1.5-1.5-2.4-1.5
					C166,94.4,165,94.9,164.5,95.9"/>
			</defs>
			<clipPath id="SVGID_60_">
				<use href="#SVGID_59_"  style={{"overflow":"visible"}}/>
			</clipPath>
			<defs>
				<filter id="Adobe_OpacityMaskFilter_2_" filterUnits="userSpaceOnUse" x="163.7" y="81.6" width="6.1" height="14.5">
					<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
				</filter>
			</defs>
			<mask maskUnits="userSpaceOnUse" x="163.7" y="81.6" width="6.1" height="14.5" id="SVGID_61_">
				<g class="st71">
					<rect x="163.7" y="81.6" class="st72" width="6.1" height="14.5"/>
					<g class="st53">
						<g>
							<defs>
								<path id="SVGID_62_" d="M167.4,81.6h-1.4c-1.3,0-2.4,1.1-2.4,2.4v9.8c0,1.3,1.1,2.4,2.4,2.4h1.4c1.3,0,2.4-1.1,2.4-2.4V84
									C169.8,82.7,168.7,81.6,167.4,81.6"/>
							</defs>
							<clipPath id="SVGID_63_" class="st72">
								<use href="#SVGID_62_"  style={{"overflow":"visible"}}/>
							</clipPath>
							
								<linearGradient id="SVGID_64_" gradientUnits="userSpaceOnUse" x1="2644.2083" y1="3560.8684" x2="2645.2083" y2="3560.8684" gradientTransform="matrix(0 12.4952 12.4952 0 -44326.957 -32957.1406)">
								<stop  offset="0" style={{"stopColor":"#FFFFFF"}}/>
								<stop  offset="1" style={{"stopColor":"#000000","stopColor":"#1D1D1B"}}/>
							</linearGradient>
							<rect x="163.7" y="81.6" class="st73" width="6.1" height="14.5"/>
						</g>
					</g>
				</g>
			</mask>
			<g class="st74">
				<g>
					<defs>
						<rect id="SVGID_65_" x="163.7" y="81.6" width="6.1" height="14.5"/>
					</defs>
					<clipPath id="SVGID_66_">
						<use href="#SVGID_65_"  style={{"overflow":"visible"}}/>
					</clipPath>
					<g class="st75">
						<defs>
							<path id="SVGID_67_" d="M167.4,81.6h-1.4c-1.3,0-2.4,1.1-2.4,2.4v9.8c0,1.3,1.1,2.4,2.4,2.4h1.4c1.3,0,2.4-1.1,2.4-2.4V84
								C169.8,82.7,168.7,81.6,167.4,81.6"/>
						</defs>
						<clipPath id="SVGID_68_">
							<use href="#SVGID_67_"  style={{"overflow":"visible"}}/>
						</clipPath>
						<defs>
							<filter id="Adobe_OpacityMaskFilter_3_" filterUnits="userSpaceOnUse" x="163.7" y="81.6" width="6.1" height="14.5">
								<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
							</filter>
						</defs>
						<mask maskUnits="userSpaceOnUse" x="163.7" y="81.6" width="6.1" height="14.5" id="SVGID_61_">
							<g class="st76">
								
									<linearGradient id="SVGID_69_" gradientUnits="userSpaceOnUse" x1="2438.2183" y1="4391.5479" x2="2439.2183" y2="4391.5479" gradientTransform="matrix(5.968 0 0 -5.968 -14387.543 26297.4609)">
									<stop  offset="0" style={{"stopColor":"#999999"}}/>
									<stop  offset="0.1417" style={{"stopColor":"#000000"}}/>
									<stop  offset="0.2983" style={{"stopColor":"#999999"}}/>
									<stop  offset="0.3962" style={{"stopColor":"#000000"}}/>
									<stop  offset="0.52" style={{"stopColor":"#999999"}}/>
									<stop  offset="0.6291" style={{"stopColor":"#000000"}}/>
									<stop  offset="0.7656" style={{"stopColor":"#999999"}}/>
									<stop  offset="0.8814" style={{"stopColor":"#000000"}}/>
									<stop  offset="1" style={{"stopColor":"#999999"}}/>
								</linearGradient>
								<rect x="163.7" y="81.6" class="st77" width="6.1" height="14.5"/>
							</g>
						</mask>
						
							<linearGradient id="SVGID_70_" gradientUnits="userSpaceOnUse" x1="2438.2183" y1="4391.5479" x2="2439.2183" y2="4391.5479" gradientTransform="matrix(5.968 0 0 -5.968 -14387.5439 26297.4629)">
							<stop  offset="0" style={{"stopColor":"#FFFFFF"}}/>
							<stop  offset="0.1417" style={{"stopColor":"#FFFFFF"}}/>
							<stop  offset="0.2983" style={{"stopColor":"#FFFFFF"}}/>
							<stop  offset="0.3962" style={{"stopColor":"#FFFFFF"}}/>
							<stop  offset="0.52" style={{"stopColor":"#FFFFFF"}}/>
							<stop  offset="0.6291" style={{"stopColor":"#FFFFFF"}}/>
							<stop  offset="0.7656" style={{"stopColor":"#FFFFFF"}}/>
							<stop  offset="0.8814" style={{"stopColor":"#FFFFFF"}}/>
							<stop  offset="1" style={{"stopColor":"#FFFFFF"}}/>
						</linearGradient>
						<rect x="163.7" y="81.6" class="st78" width="6.1" height="14.5"/>
					</g>
				</g>
			</g>
		</g>
		<g class="st66">
			<defs>
				<rect id="SVGID_71_" x="0" y="21.5" width="333.5" height="215.2"/>
			</defs>
			<clipPath id="SVGID_72_">
				<use href="#SVGID_71_"  style={{"overflow":"visible"}}/>
			</clipPath>
			<path class="st79" d="M176.2,59.6c4.1-3.2,11.8-2.8,13.6-0.9c1.7,1.8,0-16.9,0-16.9L172.9,47L176.2,59.6z"/>
			<path class="st80" d="M176.2,59.6c4.1-3.2,11.8-2.8,13.6-0.9c1.7,1.8,0-16.9,0-16.9L172.9,47L176.2,59.6z"/>
			<path class="st79" d="M212.7,42.7l4.8,6.2l2.4,3.1l1.2,1.6c0.4,0.5,0.8,1.1,1.2,1.5l0.8,0.7c0.1,0.1,0.2,0.1,0.3,0.2
				c1.6,0.9,3.3,1.7,4.9,2.6l0.1,3.5l-6,7.9c-0.8,1-0.9,2.5,0,3.6c1,1.4,3,1.7,4.4,0.7l10.3-7.2c1-0.7,1.7-2,1.5-3.3l0-0.5l-0.7-7.7
				c-0.1-1.1-0.6-2.1-1.4-2.9l-0.6-0.6c-1.8-1.8-3.7-3.5-5.5-5.3l1,0.9c-0.4-0.5-0.9-1-1.3-1.5l-1.4-1.4l-2.7-2.8l-5-6.7
				c-0.7-0.7-1.7-1-2.5-1.6c-2.5-2.1-2.7-9-19.8-9.3s-21.5,10.8-21.5,10.8l-10.1,10.1l-0.1,0.1c-1.6,1.6-2.2,4.1-1.3,6.4l7.4,17.9
				l0.1,9.5c0,2.2,1.6,4,3.9,4.3c2.3,0.3,4.5-1.5,4.9-3.8l1.9-10.7c0.1-0.5,0.1-1.1-0.1-1.6l-0.2-0.6l-2.1-6.9c0,0-1.7-5-2.5-7"/>
			<path class="st81" d="M213.1,42.4l7,8.9l1.7,2.2c0.3,0.4,0.6,0.8,0.9,1.1l0.5,0.5c0.2,0.2,0.4,0.4,0.4,0.4l5,2.6l0.3,0.1l0,0.3
				l0.1,3.5l0,0.2l-0.1,0.1l-4.2,5.5l-1,1.4l-0.5,0.7l-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.5,0.8-0.4,1.9,0.2,2.7
				c0.6,0.8,1.6,1.2,2.5,1.1c0.5-0.1,0.9-0.3,1.3-0.6l1.4-1l5.6-4l2.8-2c0.9-0.6,1.5-1.4,1.6-2.4c0-0.5-0.1-1.1-0.1-1.6l-0.2-1.7
				l-0.3-3.4l-0.2-1.7c0-0.6-0.1-1-0.3-1.5c-0.2-0.5-0.5-0.9-0.8-1.3c-0.4-0.4-0.8-0.8-1.2-1.2l-5-4.8l0.7-0.7l1,0.9l0,0L231,48
				c-0.4-0.5-0.9-0.9-1.3-1.4l-1.4-1.4l-2.8-2.8l0,0l0-0.1c-1.7-2.2-3.5-4.4-5.1-6.6l0.1,0.1c-0.2-0.2-0.6-0.4-1.1-0.7
				c-0.5-0.2-1.1-0.5-1.6-1c-1-1-1.5-1.9-2.1-2.7c-1.2-1.6-2.7-2.9-4.5-3.7c-1.8-0.8-3.8-1.4-5.8-1.7c-2-0.3-4.1-0.4-6.2-0.5
				c-4.1-0.1-8.3,0.5-12,2.1c-1.8,0.8-3.6,1.9-5,3.2c-0.4,0.3-0.7,0.7-1.1,1c-0.4,0.4-0.7,0.7-1,1.1c-0.6,0.8-1.2,1.7-1.5,2.4
				l-0.1,0.3l-0.2,0.2l-7.3,7.1l-1.8,1.8c-0.6,0.6-1.3,1.2-1.7,1.7c-0.9,1.1-1.2,2.6-0.9,3.9c0.1,0.4,0.2,0.6,0.3,1l0.5,1.2l0.9,2.4
				l1.8,4.7c1.2,3.2,2.4,6.3,3.6,9.5l0,0l0,0.1l0,0.1l0.1,6.3l0,3.2c0,0.9,0.3,1.8,0.9,2.5c0.6,0.7,1.5,1.2,2.4,1.3
				c0.9,0.1,1.9-0.1,2.6-0.6c0.8-0.5,1.4-1.3,1.6-2.2c0.1-0.4,0.2-1,0.3-1.5l0.3-1.6l1.1-6.2l0.3-1.6c0.1-0.5,0.2-0.9,0.1-1.4
				c-0.2-0.9-0.6-2-0.8-3c-0.6-2-1.2-4-1.8-6c-0.7-2-1.3-4-1.8-6.1c0.9,1.9,1.7,3.9,2.4,5.9c0.4,1,0.7,2,1,3l0.9,3l0.5,1.5
				c0.1,0.5,0.3,1,0.5,1.6c0.1,0.6,0,1.2-0.1,1.7l-0.3,1.6l-1,6.2l-0.3,1.6c-0.1,0.5-0.2,1-0.3,1.6c-0.3,1.1-1,2.1-2,2.8
				c-1,0.7-2.2,1-3.4,0.8c-1.2-0.1-2.3-0.7-3.1-1.6c-0.8-0.9-1.2-2.1-1.2-3.2l0-3.2l0-6.3l0,0.2l0,0c-1.4-3.1-2.8-6.2-4.1-9.3
				l-2-4.6l-1-2.3l-0.5-1.2c-0.2-0.4-0.4-0.9-0.5-1.4c-0.5-1.9,0-4.1,1.2-5.7c0.7-0.9,1.2-1.4,1.8-2l1.8-1.8l7.1-7.2l-0.3,0.4
				c0.5-1.2,1.1-2.1,1.8-3.1c0.4-0.5,0.7-0.9,1.1-1.3c0.4-0.4,0.8-0.8,1.2-1.2c1.7-1.5,3.6-2.8,5.7-3.7c4.2-1.8,8.7-2.5,13.1-2.3
				c2.2,0.1,4.4,0.2,6.6,0.6c2.2,0.4,4.4,1,6.4,2c2.1,1,3.9,2.6,5.2,4.5c0.6,0.9,1.2,1.9,1.7,2.5c0.2,0.3,0.6,0.5,1.1,0.7
				c0.5,0.3,1.1,0.6,1.6,1.1l0,0l0,0c1.6,2.3,3.2,4.6,4.8,6.9l-0.1-0.1l2.6,2.9l1.3,1.5c0.4,0.5,0.9,0.9,1.3,1.6l2.5,3.7l-3.3-3l0,0
				l-1-0.9l0.7-0.7l5,4.8c0.4,0.4,0.8,0.8,1.2,1.2c0.4,0.5,0.8,1,1,1.6c0.2,0.6,0.4,1.3,0.4,1.8l0.2,1.7l0.3,3.4l0.2,1.7
				c0,0.6,0.2,1.1,0.1,1.8c-0.1,1.3-1,2.5-2,3.2l-2.8,2l-5.6,4l-1.4,1c-0.5,0.4-1.1,0.7-1.8,0.8c-1.3,0.2-2.7-0.4-3.5-1.5
				c-0.8-1.1-0.9-2.6-0.2-3.8c0.1-0.1,0.2-0.3,0.3-0.4l0.3-0.3l0.5-0.7l1-1.4l4.2-5.5l-0.1,0.3l-0.1-3.5l0.3,0.4
				c-1.7-0.9-3.3-1.7-5.1-2.7c-0.3-0.2-0.4-0.4-0.6-0.5l-0.5-0.5c-0.4-0.4-0.6-0.8-0.9-1.1l-1.7-2.2l-7-8.9L213.1,42.4z"/>
		</g>
		<g class="st66">
			<defs>
				<path id="SVGID_73_" d="M170.5,93.1c0,11.4,9.2,20.6,20.6,20.6s20.6-9.2,20.6-20.6c0-11.4-9.2-20.6-20.6-20.6
					S170.5,81.7,170.5,93.1"/>
			</defs>
			<clipPath id="SVGID_74_">
				<use href="#SVGID_73_"  style={{"overflow":"visible"}}/>
			</clipPath>
			
				<radialGradient id="SVGID_75_" cx="2902.1643" cy="3657.1755" r="1" gradientTransform="matrix(-20.8588 25.2623 25.2623 20.8588 -31655.5195 -149513.6719)" gradientUnits="userSpaceOnUse">
				<stop  offset="0" style={{"stopColor":"#FFFFFF"}}/>
				<stop  offset="0.2873" style={{"stopColor":"#FBFCFD"}}/>
				<stop  offset="0.5517" style={{"stopColor":"#F0F5F6"}}/>
				<stop  offset="0.8062" style={{"stopColor":"#DEE8EB"}}/>
				<stop  offset="1" style={{"stopColor":"#CADAE0"}}/>
			</radialGradient>
			<polygon class="st82" points="232,89.2 195,133.9 150.3,97 187.2,52.2 			"/>
		</g>
		<g class="st66">
			<defs>
				<rect id="SVGID_76_" x="0" y="21.5" width="333.5" height="215.2"/>
			</defs>
			<clipPath id="SVGID_77_">
				<use href="#SVGID_76_"  style={{"overflow":"visible"}}/>
			</clipPath>
			<path class="st83" d="M212.8,93.1c0.1,5.7-2.3,11.3-6.4,15.2c-4,4-9.6,6.2-15.2,6.1c-5.6-0.1-11.1-2.3-15-6.3
				c-3.9-4-6.1-9.5-6.1-15c0-2.8,0.5-5.5,1.6-8.1l0.7-2c0.3-0.6,0.6-1.3,0.9-1.9c0.4-0.6,0.8-1.1,1.2-1.7l1.4-1.6c2-2,4.4-3.6,7-4.6
				c2.6-1,5.4-1.6,8.2-1.5c2.8,0,5.6,0.6,8.1,1.8c2.5,1.2,4.8,2.8,6.8,4.6c2,1.9,3.6,4.2,4.8,6.7C212,87.4,212.7,90.2,212.8,93.1
				 M210.7,93.1c0.2-5.1-1.6-10.4-5.3-14.3c-1.8-1.9-4.1-3.4-6.6-4.3c-2.5-0.9-5.1-1.4-7.7-1.3c-5.2,0.1-10.3,2.3-13.9,5.9l-1.3,1.4
				c-0.4,0.5-0.8,1-1.2,1.5c-0.4,0.5-0.8,1-1.1,1.6c-0.3,0.6-0.7,1.1-1,1.7c-1,2.4-1.6,5.1-1.6,7.7c0,5.3,2.2,10.5,6,14.1
				c3.7,3.7,8.9,5.8,14.1,5.7c5.2-0.1,10.3-2.3,13.9-6C208.7,103.3,210.6,98.2,210.7,93.1"/>
			<path class="st83" d="M207.1,93.1c0.6-1.9,0.8-3.9,0.5-5.7c-0.2-0.9-0.4-1.8-0.7-2.7c-0.3-0.9-0.8-1.7-1.4-2.4
				c-1.1-1.5-2.6-2.7-4.3-3.5c-1.7-0.8-3.7-1.2-5.7-1.1c1.9-0.6,4.1-0.6,6,0.1c1,0.3,1.9,0.8,2.8,1.4c0.9,0.6,1.6,1.4,2.3,2.2
				c1.3,1.7,2,3.8,2.1,5.9C208.9,89.3,208.3,91.4,207.1,93.1"/>
			<path class="st83" d="M181.6,102.7c0.8,1.8,2,3.4,3.5,4.6c0.8,0.6,1.6,1.1,2.5,1.4c0.9,0.4,1.8,0.6,2.7,0.8
				c0.9,0.2,1.9,0.2,2.8,0c0.9-0.1,1.9-0.4,2.8-0.7c1.8-0.7,3.4-1.8,4.7-3.4c-1,1.8-2.5,3.2-4.4,4.1c-1.9,0.9-4,1.2-6.1,0.9
				c-2.1-0.3-4-1.2-5.5-2.6C183.1,106.5,182,104.7,181.6,102.7"/>
			<path class="st83" d="M190.5,78c-1.2-0.5-2.4-0.7-3.5-0.7c-1.1,0-2.2,0.2-3.2,0.7c-1,0.5-1.9,1.2-2.6,2c-0.4,0.4-0.7,0.9-0.9,1.5
				c-0.3,0.5-0.4,1.1-0.6,1.7c-0.1-0.6-0.2-1.3-0.1-1.9c0.1-0.6,0.3-1.3,0.6-1.9c0.6-1.2,1.6-2.3,2.9-2.9c1.2-0.6,2.7-0.9,4.1-0.6
				C188.5,76.2,189.7,76.9,190.5,78"/>
			<path class="st83" d="M175.1,88.9c-0.9,0.9-1.5,1.9-1.9,3c-0.2,0.6-0.3,1.1-0.4,1.7c-0.1,0.6,0,1.1,0.1,1.7
				c0.2,1.1,0.6,2.2,1.3,3.1c0.3,0.5,0.8,0.9,1.2,1.3c0.5,0.4,1,0.7,1.5,1c-0.6-0.1-1.3-0.3-1.8-0.6c-0.6-0.3-1.1-0.7-1.6-1.2
				c-0.9-0.9-1.6-2.2-1.8-3.5c-0.2-1.3,0-2.7,0.6-3.9C172.9,90.4,173.9,89.4,175.1,88.9"/>
			<path class="st83" d="M195.7,99.4c1.1-0.7,2-1.5,2.6-2.5c0.6-1,1.1-2,1.2-3.2c0.1-0.6,0.1-1.1,0-1.7c0-0.6-0.2-1.1-0.4-1.7
				c-0.2-0.5-0.5-1.1-0.8-1.6c-0.3-0.5-0.8-0.9-1.2-1.4c0.6,0.3,1.2,0.6,1.6,1c0.5,0.5,0.9,1,1.2,1.6c0.7,1.2,0.9,2.6,0.8,3.9
				c-0.2,1.4-0.8,2.7-1.7,3.6C198.2,98.5,196.9,99.2,195.7,99.4"/>
			<path class="st84" d="M205.7,42l4.9,13.8L210,67.7l-7.8,7.2l-5.2-0.7c-0.5-0.1-1.1,0.2-1.5,0.6c-0.5,0.7-0.3,1.7,0.4,2.2l6.3,4.5
				c0.7,0.5,1.7,0.6,2.5,0.1l0.8-0.5l11.8-7.6c0.7-0.4,1.2-1.1,1.4-1.9l0.3-1l4.4-14.8c0.2-0.7,0.2-1.5,0-2.3l-0.2-0.8l-4.4-15
				c0-0.1-0.1-0.3-0.1-0.4c-0.6-1.8-2-3.2-3.6-4"/>
			<path class="st83" d="M206.1,41.8l4.9,13.8l0,0.1l0,0.1l-0.5,11.9l0,0.2l-0.2,0.1l-7.8,7.2l-0.2,0.2l-0.2,0h0l-4.9-0.6l-0.3,0
				c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.2-0.1,0.5,0,0.8c0.1,0.2,0.3,0.4,0.7,0.6l4,2.8l2,1.4
				c0.5,0.3,1.2,0.4,1.7,0.1l8.2-5.3l4.1-2.6c0.4-0.2,0.7-0.4,0.9-0.6c0.2-0.2,0.4-0.5,0.6-0.8c0.1-0.3,0.2-0.7,0.4-1.1l0.4-1.2
				l2.8-9.4l1.4-4.7c0.2-0.7,0.2-1.4-0.1-2.1l-0.7-2.4l-2.7-9.4c-0.2-0.8-0.4-1.6-0.6-2.4c-0.2-0.8-0.4-1.6-0.6-2.3
				c-0.6-1.4-1.6-2.7-3-3.6c1.5,0.6,2.9,1.8,3.7,3.3c0.4,0.8,0.6,1.6,0.9,2.3c0.3,0.8,0.5,1.6,0.7,2.3l2.8,9.4l0.7,2.3
				c0.3,0.8,0.4,1.8,0.1,2.7l-1.4,4.7l-2.8,9.4l-0.3,1.2c-0.1,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.5,0.8-0.8,1.2
				c-0.3,0.3-0.8,0.6-1.1,0.8l-4.1,2.6l-8.3,5.3c-0.9,0.5-2.1,0.5-3-0.1l-2-1.5l-4-2.9c-0.2-0.1-0.3-0.2-0.5-0.4
				c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.5-0.3-1.1-0.1-1.6c0.2-0.5,0.6-1,1.1-1.2c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3,0,0.4,0l0.3,0
				l4.9,0.7h0l-0.4,0.1l7.8-7.2l-0.2,0.3l0.5-11.9l0,0.2l-4.9-13.8L206.1,41.8z"/>
			<path class="st84" d="M195.6,43.8l2.7,9.5l-2.1,15.9l-5.8,10.2l-1.8,0c-0.8,0-1.7,0.4-2.2,1.1c-0.8,1.2-0.5,2.8,0.7,3.6l4.4,3
				c1,0.7,2.4,0.6,3.3-0.3l1.1-1.1l10.9-11c0.5-0.5,0.8-1.1,1-1.8l0.2-0.8l3.7-18.1c0.2-0.9,0.2-1.8-0.1-2.7l-0.1-0.2l-3.1-10.9v0
				c-0.4-1.5-1.3-2.7-2.5-3.6"/>
			<path class="st83" d="M196.1,43.7l2.7,9.5l0,0.1l0,0.1l-2,15.9l0,0.1l-0.1,0.1l-5.8,10.2l-0.2,0.3l-0.4,0l-1.2,0l-0.6,0
				c-0.2,0-0.3,0-0.5,0c-0.6,0.1-1.1,0.5-1.4,1c-0.3,0.5-0.3,1.2,0,1.7c0.1,0.3,0.3,0.5,0.5,0.7l0.2,0.1l0.2,0.2l0.5,0.3l3.8,2.6
				c0.5,0.3,1.2,0.3,1.7,0.1c0.3-0.1,0.5-0.3,0.7-0.5l0.8-0.8l6.6-6.6l3.3-3.3l0.8-0.8l0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.4
				c0.4-0.5,0.5-1.1,0.7-1.9l3.8-18.2c0.1-0.7,0.1-1.4,0-2.1c-0.2-0.7-0.4-1.5-0.6-2.2l-1.2-4.5l-1.2-4.5c-0.4-1.4-1.1-2.7-2.1-3.9
				c1.5,0.6,2.6,2.1,3,3.6l1.3,4.5l1.3,4.5c0.2,0.8,0.4,1.5,0.6,2.3c0.2,0.8,0.2,1.7,0,2.5l-3.7,18.3c-0.1,0.4-0.1,0.7-0.3,1.2
				c-0.1,0.4-0.4,0.8-0.6,1.2c-0.1,0.2-0.3,0.4-0.4,0.5l-0.4,0.4l-0.8,0.8l-3.3,3.3l-6.6,6.6l-0.8,0.8c-0.3,0.3-0.7,0.6-1.2,0.8
				c-0.9,0.4-1.9,0.3-2.8-0.2l-3.9-2.6l-0.5-0.3l-0.2-0.2l-0.3-0.2c-0.4-0.3-0.7-0.7-0.9-1.1c-0.4-0.9-0.4-1.9,0.1-2.8
				c0.4-0.9,1.3-1.5,2.2-1.7c0.2,0,0.5-0.1,0.7-0.1l0.6,0l1.2,0l-0.5,0.3l5.8-10.2l-0.1,0.2l2.2-15.8l0,0.2l-2.7-9.6L196.1,43.7z"/>
			<path class="st84" d="M185.1,41.8c-1.1,1.9-0.9,3.9-1,5.9c-0.1,2-0.2,3.9-0.2,5.9c-0.1,3.8,0,7.7,0,11.5l-1.6,8
				c-0.6,2.9-1.1,5.8-1.6,8.7c-0.6,1.4-1.3,2.8-1.9,4.3c-0.7,1.7-1.5,3.5-2.2,5.3c-0.7,1.8-0.1,4,1.6,5.2c2,1.3,3.3,0.4,5-1.2
				c1.7-1.5,3.1-3.1,4.2-4.7c1.1-1.6,2-3.2,3.1-4.8c0.1-0.1,0.2-0.3,0.2-0.5l0.2-0.7c0.9-2.9,1.9-5.8,2.8-8.7l2.7-8.8
				c0.1-0.2,0.1-0.5,0.1-0.8l0-0.6c0.3-3.9,0.6-7.9,0.8-11.8c0.1-2,0.1-3.9,0.2-5.9c0-2,0.3-3.9-0.7-6"/>
			<path class="st83" d="M185.1,41.8c-0.8,1.9-0.5,3.8-0.6,5.7c0,2-0.1,3.9-0.2,5.9l0.1,11.7v0.1l0,0.1l-1.6,8.4l-1.5,8.4l0,0.1
				l0,0.1c-1.3,3-2.6,6-3.8,9c-0.2,0.4-0.3,0.7-0.4,1.1c-0.1,0.3-0.1,0.7-0.1,1c0,0.7,0.3,1.4,0.7,1.9c0.4,0.5,1,1,1.6,1.2
				c0.6,0.2,1.2,0.1,1.8-0.2c0.6-0.4,1.2-0.9,1.8-1.4c0.6-0.5,1.2-1.1,1.7-1.7c1.1-1.2,2-2.4,2.9-3.8l1.3-2.1l0.7-1l0.3-0.5
				c0.1-0.2,0.1-0.3,0.2-0.4l1.5-4.7c1-3.1,2-6.2,3-9.4l0.7-2.3l0.4-1.2l0.2-0.6l0.1-0.5c0.2-1.6,0.2-3.3,0.4-4.9l0.3-4.9
				c0.1-1.6,0.2-3.3,0.2-4.9c0-1.6,0.1-3.3,0.2-4.9c0.1-1.6,0.3-3.2-0.2-4.8c1,1.4,1.2,3.2,1.2,4.8c0.1,1.6,0,3.3,0,4.9
				c0,1.6-0.1,3.3-0.2,4.9l-0.3,4.9c-0.1,1.6-0.2,3.3-0.3,4.9l-0.1,0.7l-0.2,0.6l-0.4,1.2l-0.7,2.4c-0.9,3.1-1.9,6.3-2.9,9.4
				l-1.5,4.7c-0.1,0.2-0.2,0.5-0.3,0.7l-0.3,0.5l-0.7,1l-1.3,2.1c-0.4,0.7-0.9,1.4-1.4,2.1c-0.5,0.7-1.1,1.3-1.6,1.9
				c-0.6,0.6-1.2,1.2-1.8,1.8c-0.6,0.6-1.2,1.1-2,1.6c-0.4,0.2-0.9,0.4-1.4,0.5c-0.5,0.1-1.1,0-1.5-0.2c-0.9-0.4-1.7-0.9-2.2-1.7
				c-0.6-0.8-0.9-1.7-0.9-2.6c0-0.5,0-0.9,0.1-1.4c0.1-0.5,0.3-0.9,0.4-1.3c1.3-3,2.6-6,3.9-9l0,0.1l1.6-8.4l1.7-8.4l0,0.1l0-11.8
				c0.1-2,0.1-3.9,0.3-5.9c0.1-1,0.1-2,0.3-2.9C184.1,43.6,184.5,42.6,185.1,41.8"/>
		</g>
        </g>

    )
}
