import React, { useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

export const ColorPicker = (props) => {

    const r_action = useDispatch()

    const [colorPickedOn, setColorPickedOn] = useState({x: 150, y: 150})

    const canvasRef = useRef(null)

    function drawCircle(ctx, d = 1) {
        let radius = 90
        let image = ctx.createImageData(2 * radius, 2 * radius)
        let data = image.data
        for (let x = -radius; x < radius; x++) {
            for (let y = -radius; y < radius; y++) {
                let [r, phi] = xy2polar(x, y);

                if (r > radius) {
                    continue;
                }

                let rowLength = 2 * radius
                let adjustedX = x + radius
                let adjustedY = y + radius
                let pixelWidth = 4
                let index = (adjustedX + (adjustedY * rowLength)) * pixelWidth

                let a = 0.7
                let b = 2
                let c = 0.8

                let [red, green, blue] = [c * 180 + a * r / radius * 205 * Math.sin(phi) + 10 * b * r / radius * Math.cos(phi),
                d * c * 134 + a * r / radius * 188 * Math.sin(phi) + 10 * b * r / radius * Math.cos(phi),
                d * c * 114 + a * r / radius * 191 * Math.sin(phi) + 23 * b * r / radius * Math.cos(phi)]
                let alpha = 255

                data[index] = red
                data[index + 1] = green
                data[index + 2] = blue
                data[index + 3] = alpha
            }
        }
        ctx.putImageData(image, 0, 0)
    }

    const drawColorPicked = (ctx) => {
        ctx.beginPath()
        ctx.arc(colorPickedOn.x, colorPickedOn.y, 10, 0, 2 * Math.PI, false)
        ctx.strokeStyle = '#4967e4'
        ctx.lineWidth = 1
        ctx.stroke()
    }

    function xy2polar(x, y) {
        let r = Math.sqrt(x * x + y * y)
        let phi = Math.atan2(y, x)
        return [r, phi]
    }

    function rgbToHex(R, G, B) { return toHex(R) + toHex(G) + toHex(B) }
    function toHex(n) {
        n = parseInt(n, 10);
        if (isNaN(n)) return "00"
        n = Math.max(0, Math.min(n, 255))
        return "0123456789ABCDEF".charAt((n - n % 16) / 16) + "0123456789ABCDEF".charAt(n % 16)
    }

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        props.type && drawCircle(context, props.type)
    }, [props.type])

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height);
        props.type && drawCircle(context, props.type)
        drawColorPicked(context)
    }, [colorPickedOn, props.type])


    return (

        <div className="bg-primary r-full flex f-center" style={{width: 180, height: 180, overflow: "hidden", flex:"0 0 180px"}}>
        <canvas width="180" height="180" ref={canvasRef} onClick={(event) => {
            const canvas = canvasRef.current
            let rect = canvas.getBoundingClientRect();
            let x = event.clientX - rect.left;
            let y = event.clientY - rect.top;
            setColorPickedOn({x, y})
            let img_data = canvas.getContext('2d').getImageData(x, y, 1, 1).data;
            let hex = "#" + ("000000" + rgbToHex(img_data[0], img_data[1], img_data[2])).slice(-6);
            r_action({ type: props.reduxAction, color: hex })
        }} />
        </div>

    )
}
