import React from 'react'
import { useSelector } from 'react-redux'

export const Clipping_Path_Ferme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Clipping_Path_Ferme" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                                        <path className="st2" d="M166.8,132.1c-0.4,0-0.3,0.1-0.4,0.1l0,0l0,0V132v-0.3V131v-1.4v-2.7l-0.1-5.4l-0.2-10.9
								c-0.1-3.6-0.2-7.2-0.4-10.9c0-0.9-0.1-1.8-0.2-2.7c0-0.9,0-1.8,0.1-2.8c0.1-0.9,0.2-1.8,0.3-2.7c0.1-0.5,0.2-0.9,0.3-1.4
								c0.1-0.5,0.2-1,0.4-1.4v0c0,0-0.1,0,0.2-0.2c0.2-0.1,0.5-0.1,0.6,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2v0.2v0.4v0.7
								v1.4v2.7c0,1.8,0.2,3.6,0.1,5.5c0,3.6-0.2,7.2-0.3,10.9l-0.4,10.9l-0.2,5.4l-0.1,2.7v1.4v1.3l0,0
								C166.8,132.2,167,132.2,166.8,132.1z M166.6,132.6c-0.2-0.1-0.2-0.2-0.2-0.2v-2.8l0-2.7l0.1-5.4l0.3-10.9
								c0.1-3.6,0.2-7.2,0.2-10.9c0-1.8-0.2-3.6-0.2-5.4l-0.1-2.7v-1.4v-0.7v-0.3v-0.2l0,0c0,0,0,0,0.1,0.2c0.2,0.2,0.4,0.2,0.6,0.1
								c0.3-0.2,0.1-0.1,0.2-0.2l0,0c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.4-0.2,0.9-0.3,1.3c-0.2,0.9-0.3,1.8-0.3,2.7
								c-0.1,0.9-0.1,1.8-0.1,2.6c0,0.9,0.1,1.8,0.2,2.7c0.1,3.6,0.2,7.2,0.3,10.9l0.1,10.9v5.4v2.7v1.4v0.7v0.3v0.2v0.1v0.1v0.1
								C166.9,132.4,167,132.6,166.6,132.6L166.6,132.6z"/>
                                    </g>
    )
}