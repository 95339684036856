import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresLeftBigRightLittle = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="bigLabiaLeft_x5F_littleLabiaRight">
                <path fill={r_labia_color} className="st--5" d="M178.8,109.7c-1.2,7.2-10.4,31.3-11.7,31.3s-18.7-24.1-19.9-31.3c-0.5-2.7-0.5-5.4,0-8.1
									c0.6-3.1,8.1-12,9.1-14.2c1.7-3.8,7.9-17.7,10.7-17.7s5.5,12.9,7.2,16.7c0.7,1.6,3.3,10.8,3.9,12.9
									C179.2,102.7,179.4,106.2,178.8,109.7z"/>
                <path class="st2" d="M179.1,109.8c-0.6,3.3-1.7,6.5-2.7,9.7c-1,3.2-2.2,6.3-3.3,9.4c-1.2,3.1-2.4,6.2-3.8,9.3
									c-0.4,0.8-0.7,1.5-1.2,2.3c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.1,0.2-0.3,0.3c0,0-0.1,0.1-0.2,0.2c-0.2,0.1-0.4,0-0.5,0
									c-0.3-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.3-0.4-0.5c-1.1-1.3-2.1-2.6-3.1-3.9c-3.9-5.4-7.6-10.9-11-16.7
									c-0.8-1.4-1.6-2.9-2.4-4.4c-0.8-1.5-1.5-3-2-4.6c-0.5-1.6-0.6-3.3-0.7-5c0-0.8,0-1.7,0.1-2.5c0.1-0.8,0.1-1.7,0.5-2.5
									c0.6-1.6,1.6-3,2.5-4.4c0.9-1.4,1.9-2.8,2.8-4.1c1-1.4,2-2.7,2.8-4.1c0.2-0.3,0.4-0.7,0.6-1l0.5-1.1l1-2.3
									c1.4-3,2.9-6,4.5-8.9c0.8-1.4,1.7-2.9,2.8-4.2c0.3-0.3,0.6-0.6,1-0.9c0.4-0.3,0.8-0.6,1.5-0.5c0.6,0.1,1,0.5,1.3,0.8
									c0.3,0.4,0.5,0.7,0.8,1.1c0.8,1.5,1.4,3.1,2,4.7c0.5,1.6,1,3.2,1.5,4.8c0.5,1.6,0.9,3.2,1.5,4.7c0.3,0.7,0.6,1.6,0.9,2.4
									l0.7,2.4c0.9,3.2,1.8,6.4,2.7,9.6C179.6,103,179.7,106.4,179.1,109.8z M178.5,109.6c0.5-3.2,0.4-6.6-0.5-9.7
									c-0.9-3.2-1.8-6.4-2.8-9.6l-0.8-2.4c-0.2-0.8-0.6-1.5-0.9-2.3c-0.6-1.6-1.1-3.2-1.6-4.8c-0.5-1.6-1-3.2-1.5-4.7
									c-0.5-1.6-1.1-3.1-1.9-4.5c-0.2-0.3-0.4-0.7-0.6-1c-0.2-0.3-0.5-0.5-0.7-0.5c-0.2,0-0.5,0.1-0.8,0.3
									c-0.3,0.2-0.6,0.5-0.8,0.8c-1,1.2-1.9,2.6-2.7,4c-1.7,2.9-3.1,5.8-4.5,8.8l-1,2.3l-0.5,1.1c-0.2,0.4-0.4,0.8-0.7,1.1
									c-0.9,1.4-2,2.7-2.9,4c-1,1.3-2,2.7-2.9,4c-0.9,1.4-1.9,2.7-2.5,4.2c-0.3,0.7-0.4,1.6-0.5,2.4c-0.1,0.8-0.1,1.6-0.1,2.5
									c0,1.6,0.2,3.3,0.6,4.9c1,3.1,2.7,6.1,4.4,8.9c3.4,5.7,7.2,11.2,11.2,16.4c1,1.3,2,2.6,3.1,3.8c0.3,0.3,0.6,0.6,0.8,0.7
									c0,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.5c0.4-0.7,0.7-1.5,1.1-2.2c1.4-3,2.6-6.1,3.8-9.2
									c1.2-3.1,2.3-6.2,3.4-9.4C176.8,116,177.8,112.9,178.5,109.6z"/>
            </g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}