import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresMediumBoth = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="mediumLabiaBoth">
                <path fill={r_labia_color} className="st--5" d="M183.3,109.7c-1.2,7.2-14.9,31.3-16.2,31.3s-14.7-24.1-15.9-31.3c-0.5-2.7-0.5-5.4,0-8.1
									c0.6-3.1,4.1-12,5.1-14.2c1.7-3.8,7.9-17.7,10.7-17.7s8.4,12.9,10.1,16.7c0.7,1.6,4.9,10.8,5.5,12.9
									C183.7,102.7,183.9,106.2,183.3,109.7z"/>
                <path class="st2" d="M183.7,109.8c-0.3,1.7-0.9,3.3-1.5,4.8c-0.6,1.6-1.3,3.1-2,4.6c-1.4,3-2.9,6-4.5,8.9
									c-1.6,2.9-3.2,5.8-4.9,8.7c-0.9,1.4-1.7,2.8-2.9,4.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.3,0.1-0.4,0.1
									c-0.3-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.3-0.5-0.7-0.8-1c-0.5-0.7-0.9-1.4-1.4-2.1
									c-3.4-5.7-6.5-11.6-9.2-17.7c-0.7-1.5-1.4-3-2-4.6c-0.6-1.5-1.2-3.1-1.7-4.7c-0.8-3.3-0.9-6.7-0.1-10c0.8-3.2,2-6.4,3.2-9.5
									c1.2-3.1,2.5-6.2,4-9.2c1.4-3,2.9-6,4.7-8.8c0.5-0.7,0.9-1.4,1.5-2.1c0.6-0.7,1.1-1.3,2-1.8c0.2-0.1,0.6-0.2,0.9-0.1
									c0.3,0.1,0.5,0.2,0.7,0.3c0.4,0.3,0.7,0.6,1,0.9c0.6,0.6,1.1,1.3,1.6,2c3.6,5.7,6.1,11.9,8.8,18l2,4.6
									c0.6,1.5,1.3,3.1,1.8,4.7C184.1,103,184.2,106.4,183.7,109.8z M183,109.6c0.5-3.2,0.4-6.6-0.5-9.7c-0.4-1.5-1.1-3.1-1.8-4.6
									l-2-4.5c-1.4-3-2.7-6.1-4.1-9.1c-1.4-3-2.9-6-4.7-8.7c-0.5-0.7-0.9-1.3-1.5-1.9c-0.5-0.6-1.2-1.1-1.6-0.9
									c-0.6,0.3-1.1,0.9-1.6,1.5c-0.5,0.6-1,1.3-1.4,2c-1.8,2.8-3.2,5.7-4.7,8.7c-1.4,3-2.8,6-4.1,9.1c-1.2,3.1-2.5,6.2-3.4,9.3
									c-0.8,3.2-0.8,6.6-0.1,9.8c0.8,3.2,2.2,6.2,3.7,9.2c2.9,6,6,11.8,9.5,17.5c0.4,0.7,0.9,1.4,1.4,2.1c0.2,0.3,0.5,0.7,0.7,1
									c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0.2,0.1,0.1,0.1c-0.2,0-0.2,0-0.1,0c0.1,0,0.1-0.1,0.2-0.2c1-1.2,1.9-2.7,2.8-4
									c1.7-2.8,3.4-5.7,5-8.6c1.6-2.9,3.1-5.9,4.5-8.9c0.7-1.5,1.4-3,2-4.5C182.1,112.8,182.7,111.2,183,109.6z"/>
            </g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}