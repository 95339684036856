import React from 'react'
import { useSelector } from 'react-redux'

export const GrandeLevreGFerme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="GrandeLevreGFerme" className="transition" style={{opacity: props.hide ? 0 : 1}}>
                    <path className="st2" d="M162.3,70c-2.8,1.8-5.2,4.4-6.8,7.4c-1.6,2.9-2.8,6.1-3.6,9.4c-1.4,6.6-2,13.3-1.5,20.1
					c0.3,6.8,1.4,13.5,3.2,20c0.9,3.3,2,6.4,3.5,9.5c0.3,0.8,0.8,1.5,1.2,2.2s0.9,1.4,1.3,2.1c1,1.4,2.2,2.6,3.5,3.7
					c-2.9-1.9-5.2-4.5-6.8-7.6c-1.6-3-3-6.2-4-9.5c-2-6.6-3.2-13.4-3.5-20.3c-0.2-3.4-0.1-6.9,0.2-10.3c0.3-3.4,0.9-6.8,1.8-10.2
					c0.9-3.3,2.3-6.5,4.1-9.5C156.7,74,159.2,71.6,162.3,70z"/>
                </g>
    )
}