import React, { useEffect, useState } from 'react'
import { first, second } from '../../services/translation/i18n'
import ReactSlider from 'react-slider'
import { useDispatch, useSelector } from 'react-redux'

export const HairType = (props) => {
    const r_action = useDispatch()
    const r_type_hair = useSelector(state => state.parameters.typeHair)
    useEffect(() => {
        console.log("Dans HAIR",r_type_hair)
    })

    return (
        <div className="flex f-center f-column">
            <h2 className="text-black text-center text-bolder text-30">{first.t('ecran1-precision-pilosite')}</h2>
            {first.language !== second.language ? <h2 className="text-black text-center text-18 mt-03">{second.t('ecran1-precision-pilosite')}</h2> : ""}

            <div className="mt-2 w-sm-full w-md-1/2 w-2/3">

                <ReactSlider
                    className="horizontal-slider mt-1"
                    thumbClassName="thumb"
                    trackClassName="track"
                    max={10}
                    onChange={(value) => r_action({ type: 'SET_TYPE_HAIR', typeHair: value -1})}
                    renderThumb={(props, state) => <div {...props}></div>}
                    value={r_type_hair}
                />

                <div className="flex f-full mt-2">
                    <div className="mt-2">
                        <p className="text-bold text-14">{first.t('ecran1-no-pilosite')}</p>
                        {first.language !== second.language ? <p className="text-italic text-14">{second.t('ecran1-no-pilosite')}</p> : ""}
                    </div>
                    <div className="mt-2">
                        <p className="text-bold text-14">{first.t('ecran1-importante-pilosite')}</p>
                        {first.language !== second.language ? <p className="text-italic text-14">{second.t('ecran1-importante-pilosite')}</p> : ""}
                    </div>
                </div>

            </div>
        </div>
    )
}
