import React from 'react'
import { useSelector } from 'react-redux'

export const Hymen3 = (props) => {

    const r_allWhite = useSelector(state => state.parameters.allWhite)

    return (
        <path id="Hymen3" fill={r_allWhite ? 'white' : '#e79ac3'} className="st8" d="
        M166.9,99c-1,0-5.6,2.7-5.6,10.6s3.6,14.3,5.7,14.3s6.7-6.4,6.7-14.3S167.9,99,166.9,99z
								 M168.5,106.1c0.7,0.5,0,0.9-0.4,0.7S168.3,106,168.5,106.1L168.5,106.1z M168.2,108.9c0.3-0.5,1.8,0.6,0.9,0.9
								S167.9,109.3,168.2,108.9L168.2,108.9z M167.4,111.8c-1.3-0.5-0.6-1.2,0.5-1.2S168.7,112.3,167.4,111.8L167.4,111.8z
								 M167.6,107.9c0,0.5-1,0.2-1,0C166.7,106.9,167.6,107.3,167.6,107.9L167.6,107.9z M166.9,109.3c0.2,0.5-0.8,1-1,0.5
								s0-0.4,0.3-0.5S166.6,108.8,166.9,109.3L166.9,109.3z M165.1,108.5c0.7,0.5,0,0.9-0.4,0.7S164.9,108.4,165.1,108.5
								L165.1,108.5z M165.2,111.9c-0.3,0-0.9,0.5-0.9-0.2s1.5-0.8,1.5-0.3S165.6,111.9,165.2,111.9L165.2,111.9z M165.3,117.7
								c0.1-1,0.9-0.5,1,0S165.3,117.9,165.3,117.7L165.3,117.7z M164.2,113.5c0.1-1,0.9-0.5,1,0S164.2,113.7,164.2,113.5
								L164.2,113.5z M167.2,113.8c-1.2,0.7-1.4-0.2-0.8-1c0.2-0.3,0.6-0.4,0.9-0.3s0.4,0.6,0.3,0.9
								C167.5,113.7,167.4,113.8,167.2,113.8L167.2,113.8z M167.2,118.2c-0.4-0.2,0.3-0.8,0.4-0.7
								C168.4,118,167.7,118.4,167.2,118.2L167.2,118.2z M167.4,115.9c0-1.1,0.6-0.8,1,0.1S167.4,116.9,167.4,115.9L167.4,115.9z
								 M168.6,112.6c1.1-0.9,1.4,0,0.9,0.9S167.6,113.5,168.6,112.6L168.6,112.6z M168.8,115.1c0.1-0.9,0.8-0.5,0.8,0
								S168.8,115.3,168.8,115.1L168.8,115.1z M169.2,110.9c0.1-0.9,0.8-0.5,0.8,0S169.1,111.1,169.2,110.9L169.2,110.9z
								 M165.3,114.6c0.5-0.3,1.4,1,0.9,1.2s-0.5,0-0.7-0.3S164.7,114.9,165.3,114.6L165.3,114.6z"/>
    )
}
