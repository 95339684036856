import React, { useState } from 'react'
import { first, second } from '../../services/translation/i18n'
import ReactSlider from 'react-slider'
import { useDispatch } from 'react-redux'

export const LabiaType = (props) => {
    const r_action = useDispatch()

    const [leftLabia, setLeftLabia] = useState(0)
    const [rightLabia, setRightLabia] = useState(0)

    const setLabiaType = (type, value) => {
        const tmpLeftLabia = type === 'left' ? value : leftLabia
        const tmpRightLabia = type === 'right' ? value : rightLabia

        let labiaType = 'littleBoth'
        if (tmpLeftLabia === 1 && tmpRightLabia === 1) { labiaType = 'mediumBoth' }
        if (tmpLeftLabia === 2 && tmpRightLabia === 2) { labiaType = 'bigBoth' }

        if (tmpLeftLabia === 0 && tmpRightLabia === 1) { labiaType = 'leftLittle_rightMedium' }
        if (tmpLeftLabia === 1 && tmpRightLabia === 0) { labiaType = 'leftMedium_rightLittle' }

        if (tmpLeftLabia === 0 && tmpRightLabia === 2) { labiaType = 'leftLittle_rightBig' }
        if (tmpLeftLabia === 2 && tmpRightLabia === 0) { labiaType = 'leftBig_rightLittle' }

        if (tmpLeftLabia === 1 && tmpRightLabia === 2) { labiaType = 'leftMedium_rightBig' }
        if (tmpLeftLabia === 2 && tmpRightLabia === 1) { labiaType = 'leftBig_rightMedium' }

        setLeftLabia(tmpLeftLabia)
        setRightLabia(tmpRightLabia)

        r_action({ type: 'SET_LABIA_TYPE', labiaType })
    }

    return (
        <div className="flex f-center f-column">
            <h2 className="text-black text-center text-bolder text-30">{first.t('ecran1-taille-levres-precision')}</h2>
            {first.language !== second.language ? <h2 className="text-black text-center text-18 mt-03">{second.t('ecran1-taille-levres-precision')}</h2> : ""}
            <div className="mt-2 w-sm-full w-md-1/2 w-2/3">
                <p className="text-bold text-14">{first.t('ecran1-levre-gauche')}</p>
                {first.language !== second.language ? <p className="text-italic text-14">{second.t('ecran1-levre-gauche')}</p> : ""}
                <ReactSlider
                    className="horizontal-slider mt-1"
                    thumbClassName="thumb"
                    trackClassName="track"
                    max={2}
                    onChange={(value) => setLabiaType('left', value)}
                    renderThumb={(props, state) => <div {...props}></div>}
                    value={leftLabia}
                />

                <p className="text-bold text-14 mt-3">{first.t('ecran1-levre-droite')}</p>
                {first.language !== second.language ? <p className="text-italic text-14">{second.t('ecran1-levre-droite')}</p> : ""}
                <ReactSlider
                    className="horizontal-slider mt-1"
                    thumbClassName="thumb"
                    trackClassName="track"
                    max={2}
                    onChange={(value) => setLabiaType('right', value)}
                    renderThumb={(props, state) => <div {...props}></div>}
                    value={rightLabia}
                />
            </div>
        </div>
    )
}
