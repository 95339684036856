import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresFermeBigBoth = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="bigBoth" >
							<path fill={r_labia_color} className="st--5" d="M185.1,109.5c-0.9,7.9-16.9,31.1-17.9,31.1s-15.7-23.2-16.6-31.1c-0.4-2.9-0.4-5.9,0-8.9
								c0.5-3.4,5.1-11.2,6.1-13.4c1.6-3.8,7.7-17.7,10.3-17.7s8,12.9,9.6,16.7c0.7,1.6,7.6,9.6,8.1,11.9
								C185.4,101.9,185.6,105.8,185.1,109.5z"/>
							<path class="st2" d="M185.4,109.5c-0.2,1.7-0.8,3.3-1.5,4.9c-0.6,1.6-1.4,3.1-2.2,4.6c-1.5,3-3.2,5.9-5,8.8
								c-1.8,2.9-3.6,5.7-5.5,8.5c-1,1.4-1.9,2.8-3.1,4.1c-0.2,0.2-0.3,0.3-0.5,0.5c0,0-0.1,0-0.1,0.1c-0.1,0,0,0-0.2,0.1
								c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.6-0.7-0.8-1c-0.5-0.7-1-1.4-1.4-2.1
								c-3.7-5.7-7-11.5-10-17.6c-0.7-1.5-1.4-3.1-2.1-4.6c-0.6-1.6-1.2-3.1-1.6-4.8c-0.6-3.3-0.7-6.8-0.2-10.1
								c0.3-1.7,1-3.3,1.7-4.8c0.7-1.5,1.4-3.1,2.2-4.6c0.7-1.5,1.6-3,2.2-4.5c0.6-1.6,1.3-3.1,2-4.6c1.4-3.1,2.9-6.1,4.7-9
								c0.4-0.7,0.9-1.4,1.5-2.1c0.3-0.3,0.6-0.7,0.9-1c0.4-0.3,0.7-0.7,1.4-0.7c0.7,0,1.1,0.4,1.4,0.7c0.3,0.3,0.6,0.7,0.9,1
								c0.5,0.7,1,1.4,1.4,2.1c1.7,2.9,3.2,6,4.5,9.1l1,2.3l0.5,1.2L177,86c0.1,0.1,0.2,0.3,0.3,0.5c0.9,1.4,1.9,2.7,2.9,4.1
								c1,1.4,2,2.7,3,4.1c0.5,0.7,0.9,1.4,1.3,2.2c0.2,0.4,0.4,0.8,0.5,1.3c0.1,0.4,0.2,0.8,0.2,1.3
								C185.7,102.7,185.8,106.2,185.4,109.5z M184.9,109.5c0.4-3.3,0.3-6.7-0.3-10c-0.1-0.4-0.2-0.8-0.2-1.2
								c-0.1-0.4-0.2-0.7-0.4-1.1c-0.4-0.7-0.9-1.4-1.3-2.1c-1-1.4-2-2.7-3-4c-1-1.3-2.1-2.6-3-4.1c-0.1-0.2-0.2-0.4-0.3-0.6
								l-0.3-0.6l-0.5-1.2l-1-2.3c-1.4-3-2.8-6.1-4.5-9c-0.4-0.7-0.9-1.4-1.4-2c-0.2-0.3-0.5-0.6-0.8-0.9c-0.3-0.3-0.6-0.4-0.8-0.4
								c-0.2,0-0.5,0.2-0.8,0.5c-0.3,0.3-0.5,0.6-0.8,0.9c-0.5,0.6-1,1.3-1.4,2c-1.7,2.8-3.2,5.9-4.6,8.9c-0.7,1.5-1.4,3.1-2.1,4.6
								c-0.7,1.6-1.6,3-2.3,4.5c-0.8,1.5-1.6,3-2.3,4.5c-0.7,1.5-1.4,3-1.8,4.6c-0.5,3.3-0.5,6.7,0,10c0.7,3.3,2.2,6.3,3.7,9.3
								c3.1,6,6.5,11.8,10.3,17.3c0.5,0.7,1,1.4,1.5,2c0.3,0.3,0.5,0.7,0.8,0.9c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0
								c-0.1,0-0.1,0-0.2,0c-0.1,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.4-0.4c1.1-1.3,2-2.6,3-4c2-2.7,3.8-5.5,5.6-8.4
								c1.8-2.8,3.5-5.7,5.1-8.7c0.8-1.5,1.5-3,2.2-4.5C184,112.7,184.6,111.1,184.9,109.5z"/>
						</g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}