import React, { useState } from "react";
import { first, second } from '../../services/translation/i18n'

export const ModalParameters = (props) => {
  const [bottomPosition, setBottomPosition] = useState(-500);

  return (
    <div className="h-full w-full">
      <div
        className={`h-full w-full ${props.triggerClass}`}
        onClick={() => {
          if (!props.disabled) {
            setBottomPosition(0);
            props.isOpen(true);
          }
        }}
      >
        {props.trigger}
      </div>
      <div
        className="r-tl r-tr bg-white absolute w-full transition"
        style={{
          left: 0,
          bottom: bottomPosition,
          zIndex: 99,
          height: props.height,
          opacity: bottomPosition === 0 ? 1 : 0,
        }}
      >
        <div className="px-1 py-2">{props.content}</div>


        <div
          className="w-full mt-1 py-1 bg-secondary text-white text-bold text-uppercase text-center"
          onClick={() => {
            setBottomPosition(-500);
            props.isOpen(false);
          }}
        >
          <div className="text-bold">{first.t('confirm')}</div>
            {first.language !== second.language ? second.t('confirm') : ""}
        </div>
      </div>
    </div>
  );
};
