import React, {useState, useEffect} from 'react'
import {Illustration} from './illustration'
import Switch from "react-switch";
import {useSelector, useDispatch} from 'react-redux';
import {first, second} from '../../services/translation/i18n'
import {FiChevronUp, FiChevronDown} from 'react-icons/fi';
import {BsPlay, BsStop, BsStopFill, BsStopwatch, BsFillStopwatchFill} from "react-icons/bs";
import {AiOutlinePause} from "react-icons/ai";
import {v4 as uuidv4} from 'uuid';
import {Link} from "react-router-dom";

import {ReactComponent as Vagin1} from '../../assets/img/vagin1.svg';
import {ReactComponent as RevealVestibul} from '../../assets/img/revealVestibul.svg';
import {ReactComponent as RevealClitoris} from '../../assets/img/reveal-clitoris.svg';
import {ReactComponent as RevealClitorisVestivul} from '../../assets/img/reveal-clitoris-vestibul.svg';
import {useWindowSize} from "../../services/hooks/useWindowSize";

export const BlockIlluAnatomie = (props) => {

    const r_action = useDispatch()
    const r_isClosed = useSelector(state => state.parameters.isClosed)
    const r_showHymen = useSelector(state => state.parameters.showHymen)
    const r_skin_opacity = useSelector(state => state.parameters.skinOpacity)

    const [showOptions, setShowOptions] = useState(false)
    const [playCurrentAnimation, setPlayCurrentAnimation] = useState(false)
    const [activateAutoPlay, setActivateAutoPlay] = useState(false);
    const windowSize = useWindowSize();

    function handleChange(newValue) {
        setPlayCurrentAnimation(newValue);
    }

    if(r_skin_opacity === undefined) {
        r_action({
            type: 'SET_SKIN_OPACITY',
            opacity: 1,
        })

    }
    return (
        <div className={`relative mt-1 flex f-center svg-illustration`}
             style={{height: windowSize.width > 510 ? 300 : 200, overflow: 'hidden', background: props.bg}}>

            {(r_skin_opacity === undefined && r_isClosed) && <Vagin1/>}
            {(r_skin_opacity === 1 && r_isClosed) && <Vagin1/>}
            {(r_skin_opacity === 1 && !r_isClosed) && <RevealVestibul/>}
            {(r_skin_opacity === 0 && !r_isClosed) && <RevealClitorisVestivul/>}
            {(r_skin_opacity === 0 && r_isClosed) && <RevealClitoris/>}


            {props.showPlay && !playCurrentAnimation && <div
                className="absolute shadow-small px-1 py-03 bg-primary r-little shadow flex"
                style={{bottom: '.5em', left: '.5em'}}
            >
                <div className="pointer" onClick={() => {
                    setPlayCurrentAnimation(true)
                    setActivateAutoPlay(uuidv4())
                }}>
                    <BsPlay color="white" size={30}/>
                </div>
            </div>}
            {playCurrentAnimation && <div
                className="absolute shadow-small px-1 py-03 bg-primary r-little shadow flex"
                style={{bottom: '.5em', left: '.5em'}}
            >
                <div className="pointer" onClick={() => {
                    setPlayCurrentAnimation(false)
                    setActivateAutoPlay("false")
                }}>
                    <AiOutlinePause color="white" size={30}/>
                </div>
            </div>}

            {!props.hideOptions && <div
                className="absolute shadow-small pointer bg-primary r-little shadow"
                style={{bottom: '.5em', right: '.5em', width: 120}}
            >
                <div className="flex f-center-h f-full-w" onClick={() => setShowOptions(!showOptions)}>
                    <div className="f-1">
                        <p className={`${first.language !== second.language ? 'pt-1' : ''} px-08 text-white text-uppercase text-12 text-bold`}>{first.t(`options`)}</p>
                        {first.language !== second.language ?
                            <p className="px-08 text-white text-uppercase text-10 pt-03 pb-1">
                                {second.t(`options`)}
                            </p>
                            : ""}
                    </div>
                    <div className="text-white mx-1">{showOptions ? <FiChevronDown size={25}/> :
                        <FiChevronUp size={25}/>}</div>
                </div>

                {showOptions &&
                <div className="bg-white mb-01 mx-01 p-05 r-bl-little r-br-little text-uppercase text-12">
                    {/*TODO : ajout slug traductions options 'labia open' + 'reveal clitoris' */}
                    <div className="flex f-center-h f-full-w bb-1-neutral pb-03 mb-08">
                        <div className="f-1 f-custom"
                             onClick={() => r_action({type: 'SET_IS_CLOSED', isClosed: !r_isClosed})}>
                            <div className="text-bold">{first.t(`reveal-vestibule`)}</div>
                            {first.language !== second.language ?
                                <div className="text-10">{second.t(`reveal-vestibule`)}</div> : ""}
                        </div>
                        <div>
                            <Switch
                                onChange={() => r_action({type: 'SET_IS_CLOSED', isClosed: !r_isClosed})}
                                checked={!r_isClosed}
                                onColor="#849cff"
                                onHandleColor="#0D31C6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={10}
                                width={35}
                            />
                        </div>
                    </div>

                    <div className="flex f-center-h f-full-w pb-03 mb-08">
                        <div className="f-1 f-custom"
                             onClick={() => r_action({type: 'SET_SKIN_OPACITY', opacity: r_skin_opacity ? 0 : 1})}>
                            <div className="text-bold">{first.t(`reveal-clitoris`)}</div>
                            {first.language !== second.language ?
                                <div className="text-10">{second.t(`reveal-clitoris`)}</div> : ""}
                        </div>
                        <div>
                            <Switch
                                onChange={() => {
                                    r_action({
                                        type: 'SET_SKIN_OPACITY',
                                        opacity: r_skin_opacity !== 1 ? 1 : 0
                                    })
                                }}
                                checked={r_skin_opacity !== 1 ? true : false}
                                onColor="#849cff"
                                onHandleColor="#0D31C6"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={10}
                                width={35}
                            />
                        </div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}
