import React from 'react'
import { useSelector } from 'react-redux'
import { PliD1 } from './pliD1'
import { PliD2 } from './pliD2'
import { PliG1 } from './pliG1'
import { PliG2 } from './pliG2'

export const PetitesLevresFermeMediumBoth = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PetiteLevres" className="transition" style={{ opacity: props.show ? 1 : props.hide ? 0 : 1 }}>
            <g id="mediumBoth">
							<path fill={r_labia_color} className="st--5" d="M181.1,109.5c-0.9,7.9-12.9,31.1-13.9,31.1s-11.7-23.2-12.6-31.1c-0.4-2.9-0.4-5.9,0-8.9
								c0.5-3.4,4.1-11.2,5.1-13.4c1.6-3.8,4.7-17.7,7.3-17.7s6,12.9,7.6,16.7c0.7,1.6,5.6,9.6,6.1,11.9
								C181.4,101.9,181.6,105.8,181.1,109.5z"/>
							<path class="st2" d="M181.4,109.5c-0.2,1.6-0.6,3.2-1.1,4.7c-0.5,1.5-1,3.1-1.6,4.6c-1.2,3-2.5,6-3.8,8.9
								c-1.4,2.9-2.8,5.8-4.3,8.6c-0.8,1.4-1.5,2.8-2.5,4.2c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.2,0.2c0,0-0.2,0.1-0.3,0.1
								c-0.1,0-0.3-0.1-0.3-0.1c-0.3-0.2-0.4-0.4-0.5-0.6c-0.2-0.4-0.4-0.7-0.6-1.1c-0.4-0.7-0.8-1.4-1.1-2.2
								c-2.8-5.8-5.3-11.7-7.5-17.8c-0.5-1.5-1-3-1.5-4.6c-0.5-1.5-0.9-3.1-1.2-4.7c-0.5-3.2-0.6-6.5-0.1-9.7
								c0.3-1.6,0.8-3.2,1.3-4.7c0.5-1.5,1.1-3,1.8-4.5l1.9-4.4c0.5-1.5,1-3,1.5-4.6c0.9-3.1,1.8-6.2,3-9.2c0.3-0.8,0.6-1.5,1.1-2.2
								c0.2-0.4,0.4-0.7,0.8-1.1c0.2-0.2,0.3-0.3,0.6-0.5c0.2-0.1,0.6-0.2,0.9-0.2c0.6,0.1,1,0.5,1.3,0.8c0.3,0.3,0.5,0.7,0.8,1
								c0.4,0.7,0.8,1.5,1.1,2.2c1.3,3,2.3,6,3.3,9.1c0.5,1.5,0.9,3.1,1.6,4.5c0.7,1.4,1.5,2.8,2.3,4.2c0.8,1.4,1.5,2.8,2.2,4.3
								c0.3,0.7,0.7,1.5,1,2.2c0.3,0.8,0.4,1.6,0.5,2.4C181.7,103,181.8,106.3,181.4,109.5z M180.9,109.5c0.4-3.2,0.3-6.4-0.2-9.5
								c-0.1-0.8-0.3-1.6-0.5-2.3c-0.3-0.7-0.6-1.4-1-2.2c-0.7-1.4-1.5-2.8-2.3-4.2c-0.8-1.4-1.6-2.8-2.4-4.2
								c-0.8-1.5-1.2-3-1.8-4.6c-1.1-3-2-6.1-3.3-9c-0.3-0.7-0.7-1.4-1.1-2.1c-0.2-0.3-0.4-0.6-0.6-0.9c-0.2-0.3-0.5-0.5-0.7-0.5
								c-0.1,0-0.4,0.1-0.6,0.4c-0.2,0.2-0.4,0.6-0.6,0.9c-0.4,0.7-0.7,1.4-1,2.1c-1.2,2.9-2.1,6-3,9.1c-0.5,1.5-0.9,3.1-1.5,4.6
								l-2,4.4c-0.6,1.5-1.3,2.9-1.9,4.4c-0.6,1.5-1.1,3-1.4,4.5c-0.5,3.1-0.5,6.4-0.1,9.5c0.5,3.2,1.6,6.2,2.7,9.2
								c2.3,6,4.9,11.9,7.8,17.6c0.4,0.7,0.7,1.4,1.1,2.1c0.2,0.3,0.4,0.7,0.6,1c0.1,0.1,0.3,0.3,0.3,0.3c0,0-0.3-0.1-0.3,0
								c0,0,0,0,0,0c0,0,0.1-0.1,0.2-0.2c0.4-0.6,0.9-1.3,1.3-2c0.4-0.7,0.8-1.4,1.2-2.1c1.5-2.8,3-5.7,4.4-8.6
								c1.4-2.9,2.7-5.8,3.9-8.8c0.6-1.5,1.2-3,1.7-4.5C180.2,112.6,180.7,111,180.9,109.5z"/>
						</g>
            <PliG1 />
            <PliG2 />
            <PliD1 />
            <PliD2 />
        </g>
    )
}