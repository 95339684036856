import React from 'react'
import { useSelector } from 'react-redux'

export const SoinLabia = (props) => {

    return (
        <g id="soins" className="transition" style={{ opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(0px, -15px)' }}>
            <g>
                <g>
                    <path class="st35" d="M175.5,126.4c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M176.7,126.4c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M175.5,123.4c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M176.7,123.4c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M175.3,120.4c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M176.6,120.4c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M175.2,117.6c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M176.4,117.6c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M174.9,114.7c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M176.1,114.7c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M174.5,111.9c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M175.7,111.9c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M174,109.2c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M175.2,109.2c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M173.4,106.4c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M174.6,106.4c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M172.6,103.6c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M173.9,103.6c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M171.7,100.8c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M173,100.8c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M170.7,98c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M171.9,98c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
                <g>
                    <path class="st35" d="M169.4,95.1c0.2-0.2,1.1-0.7,1.1-1" />
                    <path class="st35" d="M170.7,95.1c-0.3-0.1-0.8-0.9-1.1-1" />
                </g>
            </g>
            <g>
                <g>
                    <path class="st35" d="M155.8,126.3c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M154.8,126.3c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M155.8,123.3c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M154.9,123.3c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M155.9,120.3c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M155,120.3c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M156.1,117.4c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M155.1,117.4c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M156.3,114.6c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M155.3,114.6c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M156.6,111.8c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M155.6,111.8c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M156.9,109c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M156,109c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M157.4,106.3c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M156.5,106.3c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M158,103.5c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M157,103.5c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M158.7,100.7c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M157.7,100.7c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M159.5,97.9c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M158.5,97.9c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
                <g>
                    <path class="st35" d="M160.4,95c-0.1-0.2-0.8-0.7-0.9-1" />
                    <path class="st35" d="M159.5,95c0.3-0.1,0.6-0.9,0.8-1" />
                </g>
            </g>
            <g>
                <path class="st12" d="M198.3,73.7c4.1-3.2,11.8-2.8,13.6-0.9c1.7,1.8,0-16.9,0-16.9L195,61.1L198.3,73.7z" />
                <g>
                    <path class="st13" d="M234.8,56.7l4.8,6.2L242,66l1.2,1.5c0.4,0.5,0.8,1.1,1.2,1.5l0.8,0.7c0.1,0.1,0.2,0.1,0.3,0.2
									c1.6,0.9,3.3,1.7,4.9,2.6l0.1,3.5l-6,7.9c-0.8,1-0.9,2.5,0,3.6c1,1.4,3,1.7,4.4,0.7l10.3-7.2c1-0.7,1.7-2,1.5-3.3v-0.5
									l-0.7-7.7c-0.1-1.1-0.6-2.1-1.4-2.9L258,66c-1.8-1.8-3.7-3.5-5.5-5.3l1,0.9c-0.4-0.5-0.9-1-1.3-1.5l-1.4-1.4l-2.7-2.9
									l-5-6.7c-0.7-0.7-1.7-1-2.5-1.6c-2.5-2.1-2.7-9-19.8-9.3S199.3,49,199.3,49l-10.1,10.1l-0.1,0.1c-1.6,1.6-2.2,4.1-1.3,6.4
									l7.4,17.9l0.1,9.5c0,2.1,1.6,4,3.9,4.3c2.3,0.3,4.5-1.5,4.9-3.8l1.9-10.7c0.1-0.5,0.1-1.1-0.1-1.6l-0.2-0.7l-2.1-6.9
									c0,0-1.7-5-2.5-7"/>
                    <path class="st2" d="M235.2,56.4l7,8.9l1.7,2.2c0.3,0.4,0.6,0.8,0.9,1.1l0.5,0.5c0.2,0.2,0.4,0.4,0.4,0.4l5,2.6l0.3,0.1v0.3
									l0.1,3.5v0.2l-0.1,0.1l-4.2,5.5l-1,1.4l-0.5,0.7l-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.5,0.8-0.4,1.9,0.2,2.7
									c0.6,0.8,1.6,1.2,2.5,1.1c0.5-0.1,0.9-0.3,1.3-0.6l1.4-1l5.6-4l2.8-2c0.9-0.6,1.5-1.4,1.6-2.4c0-0.5-0.1-1-0.1-1.6l-0.2-1.7
									l-0.3-3.4l-0.2-1.7c0-0.6-0.1-1-0.3-1.5c-0.2-0.5-0.5-0.9-0.8-1.3c-0.4-0.4-0.8-0.8-1.2-1.2l-5-4.8l0.7-0.7l1,0.9l0,0
									L253,62c-0.4-0.5-0.9-0.9-1.3-1.4l-1.4-1.4l-2.8-2.8l0,0v-0.1c-1.7-2.2-3.5-4.4-5.1-6.6l0.1,0.1c-0.2-0.2-0.6-0.4-1.1-0.7
									c-0.5-0.2-1.1-0.5-1.6-1c-1-1-1.5-1.9-2.1-2.7c-1.2-1.6-2.7-2.9-4.5-3.7s-3.8-1.4-5.8-1.7s-4.1-0.4-6.2-0.5
									c-4.2-0.1-8.3,0.5-12,2.1c-1.8,0.8-3.6,1.9-5,3.2c-0.4,0.3-0.7,0.7-1.1,1c-0.4,0.4-0.7,0.7-1,1.1c-0.6,0.8-1.2,1.7-1.5,2.4
									l-0.1,0.3l-0.2,0.2l-7.3,7.1l-1.8,1.8c-0.6,0.6-1.3,1.2-1.7,1.7c-0.9,1.1-1.2,2.6-0.9,3.9c0.1,0.4,0.2,0.6,0.3,1l0.5,1.2
									l0.9,2.4l1.8,4.7c1.2,3.2,2.4,6.3,3.6,9.5l0,0v0.1v0.1l0.1,6.3v3.2c0,0.9,0.3,1.8,0.9,2.5c0.6,0.7,1.5,1.2,2.4,1.3
									c0.9,0.1,1.9-0.1,2.6-0.6c0.8-0.5,1.4-1.3,1.6-2.2c0.1-0.4,0.2-1,0.3-1.5L204,91l1.1-6.2l0.3-1.6c0.1-0.5,0.2-0.9,0.1-1.4
									c-0.2-0.9-0.6-2-0.8-3c-0.6-2-1.2-4-1.8-6c-0.7-2-1.3-4-1.8-6.1c0.9,1.9,1.7,3.9,2.4,5.9c0.4,1,0.7,2,1,3l0.9,3l0.5,1.5
									c0.1,0.5,0.3,1,0.5,1.6c0.1,0.6,0,1.2-0.1,1.7L206,85l-1,6.2l-0.3,1.6c-0.1,0.5-0.2,1-0.3,1.6c-0.3,1.1-1,2.1-2,2.8
									c-1,0.7-2.2,1-3.4,0.8c-1.2-0.1-2.3-0.7-3.1-1.6s-1.2-2.1-1.2-3.2V90v-6.3v0.2l0,0c-1.4-3.1-2.8-6.2-4.1-9.3l-2-4.6l-1-2.3
									l-0.5-1.2c-0.2-0.4-0.4-0.9-0.5-1.4c-0.5-1.9,0-4.1,1.2-5.7c0.7-0.9,1.3-1.4,1.8-2l1.8-1.8l7.1-7.2l-0.3,0.4
									c0.5-1.2,1.1-2.1,1.8-3.1c0.4-0.5,0.7-0.9,1.1-1.3c0.4-0.4,0.8-0.8,1.2-1.2c1.7-1.5,3.6-2.8,5.7-3.7
									c4.2-1.8,8.7-2.5,13.1-2.3c2.2,0.1,4.4,0.2,6.6,0.6c2.2,0.4,4.4,1,6.4,2c2.1,1,3.9,2.6,5.2,4.5c0.6,0.9,1.2,1.9,1.7,2.5
									c0.2,0.3,0.6,0.5,1.1,0.7c0.5,0.3,1.1,0.6,1.6,1.1l0,0l0,0c1.6,2.3,3.2,4.6,4.8,6.9l-0.1-0.1l2.6,2.9l1.3,1.5
									c0.4,0.5,0.9,0.9,1.3,1.6l2.5,3.7l-3.3-3l0,0l-1-0.9l0.7-0.7l5,4.8c0.4,0.4,0.8,0.8,1.2,1.2c0.4,0.5,0.8,1,1,1.6
									s0.4,1.3,0.4,1.8l0.2,1.7l0.3,3.4l0.2,1.7c0,0.6,0.2,1.1,0.1,1.8c-0.1,1.3-1,2.5-2,3.2l-2.8,2l-5.6,4l-1.4,1
									c-0.5,0.4-1.1,0.7-1.8,0.8c-1.3,0.2-2.7-0.4-3.5-1.5s-0.9-2.6-0.2-3.8c0.1-0.1,0.2-0.3,0.3-0.4l0.3-0.3l0.5-0.7l1-1.4
									l4.2-5.5l-0.1,0.3l-0.1-3.5l0.3,0.4c-1.7-0.9-3.3-1.7-5.1-2.7c-0.3-0.2-0.4-0.4-0.6-0.5l-0.5-0.5c-0.4-0.4-0.6-0.8-0.9-1.1
									l-1.7-2.2l-7-8.9L235.2,56.4z"/>
                </g>
                <g>
                    <g>

                        <radialGradient id="SVGID_22_" cx="219.6242" cy="6895.3354" r="32.7608" gradientTransform="matrix(1 0 0 -1 0 6995.2754)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" style={{"stopColor":"#FFFFFF"}} />
                            <stop offset="0.2878" style={{"stopColor":"#FBFCFD"}} />
                            <stop offset="0.5518" style={{"stopColor":"#F0F5F6"}} />
                            <stop offset="0.8056" style={{"stopColor":"#DEE8EC"}} />
                            <stop offset="1" style={{"stopColor":"#CADAE0"}} />
                        </radialGradient>
                        <circle class="st36" cx="213.2" cy="107.1" r="20.6" />
                        <path class="st2" d="M234.9,107.1c0.1,5.7-2.3,11.3-6.4,15.2c-4,4-9.6,6.2-15.2,6.1c-5.6-0.1-11.1-2.3-15-6.3
										s-6.1-9.5-6.1-15c0-2.8,0.6-5.5,1.6-8.1l0.7-2c0.3-0.6,0.6-1.3,0.9-1.9c0.4-0.6,0.8-1.1,1.2-1.7l1.4-1.6c2-2,4.4-3.6,7-4.6
										s5.4-1.6,8.2-1.5c2.8,0,5.6,0.6,8.1,1.8c2.5,1.2,4.8,2.8,6.8,4.6c2,1.9,3.6,4.2,4.8,6.7
										C234.1,101.5,234.8,104.3,234.9,107.1z M232.8,107.1c0.2-5.1-1.6-10.4-5.3-14.3c-1.8-1.9-4.1-3.4-6.6-4.3s-5.1-1.4-7.7-1.3
										c-5.2,0.1-10.3,2.3-13.9,5.9l-1.3,1.4c-0.4,0.5-0.8,1-1.2,1.5s-0.8,1-1.1,1.6s-0.7,1.1-1,1.7c-1,2.4-1.6,5.1-1.6,7.7
										c0,5.3,2.2,10.5,6,14.1c3.7,3.7,8.9,5.8,14.1,5.7c5.2-0.1,10.3-2.3,13.9-6C230.8,117.3,232.7,112.3,232.8,107.1z"/>
                    </g>
                    <g>
                        <path class="st2" d="M229.2,107.1c0.6-1.9,0.8-3.9,0.5-5.7c-0.2-0.9-0.4-1.8-0.7-2.7c-0.3-0.9-0.8-1.7-1.4-2.4
										c-1.1-1.5-2.6-2.7-4.3-3.5s-3.7-1.2-5.7-1.1c1.9-0.6,4.1-0.6,6,0.1c1,0.3,1.9,0.8,2.8,1.4c0.9,0.6,1.6,1.4,2.3,2.2
										c1.3,1.7,2,3.8,2.1,5.9C231,103.4,230.4,105.5,229.2,107.1z"/>
                    </g>
                    <g>
                        <path class="st2" d="M203.7,116.8c0.8,1.8,2,3.4,3.5,4.6c0.8,0.6,1.6,1.1,2.5,1.4c0.9,0.4,1.8,0.6,2.7,0.8
										c0.9,0.2,1.9,0.2,2.8,0c0.9-0.1,1.9-0.4,2.8-0.7c1.8-0.7,3.4-1.8,4.7-3.4c-1,1.8-2.5,3.2-4.4,4.1s-4,1.2-6.1,0.9
										c-2.1-0.3-4-1.2-5.5-2.6C205.2,120.6,204.1,118.7,203.7,116.8z"/>
                    </g>
                    <g>
                        <path class="st2" d="M212.6,92.1c-1.2-0.5-2.4-0.7-3.5-0.7s-2.2,0.2-3.2,0.7s-1.9,1.2-2.6,2c-0.4,0.4-0.6,0.9-0.9,1.5
										c-0.3,0.5-0.4,1.1-0.6,1.7c-0.1-0.6-0.2-1.3-0.1-1.9c0.1-0.6,0.3-1.3,0.6-1.9c0.6-1.2,1.6-2.3,2.9-2.9
										c1.2-0.6,2.7-0.9,4.1-0.6C210.6,90.3,211.8,91,212.6,92.1z"/>
                    </g>
                    <g>
                        <path class="st2" d="M197.2,102.9c-0.9,0.9-1.5,1.9-1.9,3c-0.2,0.6-0.3,1.1-0.3,1.7c-0.1,0.6,0,1.1,0.1,1.7
										c0.2,1.1,0.6,2.2,1.3,3.1c0.3,0.5,0.8,0.9,1.2,1.3c0.5,0.4,1,0.7,1.5,1c-0.6-0.1-1.3-0.3-1.8-0.6c-0.6-0.3-1.1-0.7-1.6-1.2
										c-0.9-0.9-1.6-2.2-1.8-3.5s0-2.7,0.6-3.9C195,104.4,196,103.5,197.2,102.9z"/>
                    </g>
                    <g>
                        <path class="st2" d="M217.8,113.4c1.1-0.7,2-1.5,2.6-2.5s1.1-2,1.2-3.2c0.1-0.6,0.1-1.1,0-1.7c0-0.6-0.2-1.1-0.4-1.7
										c-0.2-0.5-0.5-1.1-0.8-1.6s-0.8-0.9-1.2-1.4c0.6,0.3,1.2,0.6,1.6,1c0.5,0.5,0.9,1,1.2,1.6c0.7,1.2,0.9,2.6,0.8,3.9
										c-0.2,1.4-0.8,2.7-1.7,3.6C220.3,112.6,219,113.3,217.8,113.4z"/>
                    </g>
                </g>
                <g>
                    <path class="st13" d="M227.8,56.1l4.9,13.8l-0.5,11.9l-7.8,7.2l-5.2-0.7c-0.5-0.1-1.1,0.2-1.5,0.6c-0.5,0.7-0.3,1.7,0.4,2.2
									l6.3,4.5c0.7,0.5,1.7,0.6,2.5,0.1l0.8-0.5l11.8-7.6c0.7-0.4,1.2-1.1,1.4-1.9l0.3-1l4.4-14.8c0.2-0.7,0.2-1.5,0-2.3l-0.2-0.8
									l-4.4-15c0-0.1-0.1-0.3-0.1-0.4c-0.6-1.8-2-3.2-3.6-4"/>
                    <path class="st2" d="M228.2,55.9l4.9,13.8v0.1v0.1l-0.5,11.9V82l-0.2,0.1l-7.8,7.2l-0.2,0.2h-0.2l0,0l-4.9-0.6H219
									c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.2-0.1,0.5,0,0.8c0.1,0.2,0.3,0.4,0.7,0.6l4,2.8
									l2,1.4c0.5,0.3,1.2,0.4,1.7,0.1l8.2-5.3l4.1-2.6c0.4-0.2,0.7-0.4,0.9-0.6s0.4-0.5,0.6-0.8c0.1-0.3,0.2-0.7,0.4-1.1l0.4-1.2
									l2.8-9.4l1.4-4.7c0.2-0.7,0.2-1.4-0.1-2.1l-0.7-2.4l-2.7-9.4c-0.2-0.8-0.4-1.6-0.6-2.4c-0.2-0.8-0.4-1.6-0.6-2.3
									c-0.6-1.5-1.6-2.7-3-3.6c1.5,0.6,2.9,1.8,3.7,3.3c0.4,0.8,0.6,1.6,0.9,2.3c0.3,0.8,0.5,1.6,0.7,2.3l2.8,9.4l0.7,2.3
									c0.3,0.8,0.4,1.8,0.1,2.7l-1.4,4.7L242,84l-0.3,1.2c-0.1,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.5,0.8-0.8,1.2
									c-0.3,0.3-0.8,0.6-1.1,0.8l-4.1,2.6l-8.3,5.3c-0.9,0.5-2.1,0.5-3-0.1l-2-1.5l-4-2.9c-0.2-0.1-0.3-0.2-0.5-0.4
									c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.5-0.3-1.1-0.1-1.6s0.6-1,1.1-1.2c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3,0,0.4,0h0.3l4.8,0.7
									l0,0l-0.4,0.1l7.8-7.2l-0.2,0.3l0.5-11.9v0.2l-4.9-13.8L228.2,55.9z"/>
                </g>
                <g>
                    <path class="st13" d="M217.7,57.9l2.7,9.5l-2.1,15.9l-5.8,10.2h-1.8c-0.8,0-1.7,0.4-2.2,1.1c-0.8,1.2-0.5,2.8,0.7,3.6l4.4,3
									c1,0.7,2.4,0.6,3.3-0.3l1.1-1.1l10.9-11c0.5-0.5,0.8-1.1,1-1.8l0.2-0.8l3.7-18.1c0.2-0.9,0.2-1.8-0.1-2.7l-0.1-0.2
									l-3.1-10.9l0,0c-0.4-1.5-1.3-2.7-2.5-3.6"/>
                    <path class="st2" d="M218.2,57.8l2.7,9.5v0.1v0.1l-2,15.9v0.1l-0.1,0.1L213,93.8l-0.2,0.3h-0.4h-1.2h-0.6
									c-0.2,0-0.3,0-0.5,0c-0.6,0.1-1.1,0.5-1.4,1c-0.3,0.5-0.3,1.2,0,1.7c0.1,0.3,0.3,0.5,0.5,0.7l0.2,0.1l0.2,0.2l0.5,0.3
									l3.8,2.6c0.5,0.3,1.2,0.3,1.7,0.1c0.3-0.1,0.5-0.3,0.7-0.5l0.8-0.8l6.6-6.6l3.3-3.3l0.8-0.8l0.4-0.4
									c0.1-0.1,0.2-0.2,0.3-0.4c0.4-0.5,0.5-1.1,0.7-1.9l3.8-18.2c0.1-0.7,0.1-1.4,0-2.1c-0.2-0.7-0.4-1.5-0.6-2.2l-1.2-4.5
									l-1.2-4.5c-0.4-1.4-1.1-2.7-2.1-3.9c1.5,0.6,2.6,2.1,3,3.6l1.3,4.5l1.3,4.5c0.2,0.8,0.4,1.5,0.6,2.3c0.2,0.8,0.2,1.7,0,2.5
									l-3.7,18.3c-0.1,0.4-0.1,0.7-0.3,1.2c-0.1,0.4-0.4,0.8-0.6,1.2c-0.1,0.2-0.3,0.4-0.4,0.5l-0.4,0.4l-0.8,0.8l-3.3,3.3
									l-6.6,6.6l-0.8,0.8c-0.3,0.3-0.7,0.6-1.2,0.8c-0.9,0.4-1.9,0.3-2.8-0.2l-3.9-2.6l-0.5-0.3l-0.2-0.2l-0.3-0.2
									c-0.4-0.3-0.7-0.7-0.9-1.1c-0.4-0.9-0.4-1.9,0.1-2.8c0.4-0.9,1.3-1.5,2.2-1.7c0.2,0,0.5-0.1,0.7-0.1h0.6h1.2l-0.5,0.3
									l5.8-10.2l-0.1,0.2l2.2-15.8v0.2l-2.6-9.6L218.2,57.8z"/>
                </g>
                <g>
                    <path class="st13" d="M207.1,55.9c-1.1,1.9-0.9,3.9-1,5.9s-0.2,3.9-0.2,5.9c-0.1,3.8,0,7.7,0,11.5l-1.6,8
									c-0.6,2.9-1.1,5.8-1.6,8.7c-0.6,1.4-1.3,2.8-1.9,4.3c-0.7,1.7-1.5,3.5-2.2,5.3s-0.1,4,1.6,5.2c2,1.3,3.3,0.4,5-1.2
									c1.7-1.5,3.1-3.1,4.2-4.7c1.1-1.6,2-3.2,3.1-4.8c0.1-0.1,0.2-0.3,0.2-0.5l0.2-0.7c0.9-2.9,1.9-5.8,2.8-8.7l2.7-8.8
									c0.1-0.2,0.1-0.5,0.1-0.8v-0.6c0.3-3.9,0.6-7.9,0.8-11.8c0.1-2,0.1-3.9,0.2-5.9c0-2,0.3-3.9-0.7-6"/>
                    <path class="st2" d="M207.1,55.9c-0.8,1.9-0.5,3.8-0.6,5.7c0,2-0.1,3.9-0.2,5.9l0.1,11.7v0.1v0.1l-1.6,8.4l-1.5,8.4v0.1v0.1
									c-1.3,3-2.6,6-3.8,9c-0.2,0.4-0.3,0.7-0.4,1.1c-0.1,0.3-0.1,0.7-0.1,1c0,0.7,0.3,1.4,0.7,1.9s1,1,1.6,1.2s1.2,0.1,1.8-0.2
									c0.6-0.4,1.2-0.9,1.8-1.4s1.2-1.1,1.7-1.7c1.1-1.2,2-2.4,2.9-3.8l1.3-2.1l0.7-1l0.3-0.5c0.1-0.2,0.1-0.3,0.2-0.4l1.5-4.7
									c1-3.1,2-6.2,3-9.4l0.7-2.3l0.4-1.2l0.2-0.6l0.1-0.5c0.2-1.6,0.2-3.3,0.4-4.9l0.3-4.9c0.1-1.6,0.2-3.3,0.2-4.9
									s0.1-3.3,0.2-4.9c0.1-1.6,0.3-3.2-0.2-4.8c1,1.4,1.2,3.2,1.2,4.8c0.1,1.6,0,3.3,0,4.9s-0.1,3.3-0.2,4.9l-0.3,4.9
									c-0.1,1.6-0.2,3.3-0.3,4.9l-0.1,0.7l-0.2,0.6l-0.4,1.2l-0.7,2.4c-0.9,3.1-1.9,6.3-2.9,9.4l-1.5,4.7
									c-0.1,0.2-0.2,0.5-0.3,0.7l-0.3,0.5l-0.7,1l-1.3,2.1c-0.4,0.7-0.9,1.4-1.4,2.1s-1.1,1.3-1.6,1.9c-0.6,0.6-1.2,1.2-1.8,1.8
									c-0.6,0.6-1.2,1.1-2,1.6c-0.4,0.2-0.9,0.4-1.4,0.5s-1.1,0-1.5-0.2c-0.9-0.4-1.7-0.9-2.2-1.7c-0.6-0.8-0.9-1.7-0.9-2.6
									c0-0.5,0-0.9,0.1-1.4s0.3-0.9,0.4-1.3c1.3-3,2.6-6,3.9-9v0.1l1.6-8.4l1.7-8.4v0.1V67.4c0.1-2,0.1-3.9,0.3-5.9
									c0.1-1,0.1-2,0.3-2.9C206.2,57.6,206.5,56.7,207.1,55.9z"/>
                </g>
            </g>
            <g>
                <path class="st12" d="M146.1,147.6c0.2,5.2,5.6,10.8,8.1,10.9c2.5,0.1-12.8,11-12.8,11l-7.1-16.2L146.1,147.6z" />
                <g>
                    <path class="st13" d="M153.3,185.1l7.8-1.1l3.9-0.5l1.9-0.3c0.7-0.1,1.3-0.2,2-0.2h1.1c0.1,0,0.2,0,0.3,0
									c1.8,0.5,3.6,1.1,5.3,1.6l2.5-2.4l1.2-9.8c0.2-1.3,1.1-2.4,2.5-2.6c1.7-0.3,3.3,0.9,3.6,2.6l2.3,12.3
									c0.2,1.2-0.2,2.6-1.2,3.4l-0.4,0.3l-5.9,5.1c-0.8,0.7-1.9,1.1-3,1.1h-0.8c-2.5,0-5.1,0-7.6-0.1h1.4c-0.6,0.1-1.3,0.1-2,0.1
									l-2,0.1l-3.9,0.1l-8.3,1.4c-1,0-1.9-0.5-2.9-0.6c-3.2-0.3-8.2,4.5-20.7-7.1c-12.5-11.7-7.9-22.7-7.9-22.7l-0.2-14.3v-0.1
									c0-2.3,1.3-4.5,3.5-5.5l17.7-7.7l6.7-6.8c1.5-1.5,4-1.8,5.8-0.4c1.9,1.4,2.2,4.1,0.9,6.1l-6.1,9c-0.3,0.4-0.7,0.8-1.2,1.1
									l-0.6,0.3l-6.3,3.5c0,0-4.7,2.4-6.7,3.3"/>
                    <path class="st2" d="M153.3,184.6l11.2-1.6l2.8-0.4c0.5-0.1,0.9-0.2,1.5-0.2h0.7c0.3,0,0.4,0,0.8,0c1.9,0.5,3.6,1.1,5.5,1.6
									l-0.5,0.1l2.5-2.4l-0.2,0.3l0.8-6.8l0.2-1.7l0.1-0.9l0.1-0.4c0-0.2,0.1-0.3,0.1-0.5c0.3-1.3,1.5-2.4,2.8-2.6
									c1.3-0.2,2.7,0.3,3.5,1.4c0.4,0.5,0.7,1.2,0.8,1.8l0.3,1.7l1.3,6.8l0.6,3.4c0.3,1.2,0.1,2.7-0.8,3.7
									c-0.4,0.5-0.9,0.8-1.3,1.2l-1.3,1.1l-2.6,2.2l-1.3,1.1c-0.4,0.4-1,0.8-1.5,1c-0.6,0.3-1.2,0.4-1.8,0.4s-1.2,0-1.7,0
									l-6.9-0.1v-1h1.4l0,0l4.5,0.1l-4.4,0.9c-0.7,0.1-1.4,0.1-2,0.2l-2,0.1l-3.9,0.3h0.1c-2.7,0.5-5.5,1.1-8.2,1.6l0,0h-0.1
									c-0.7,0-1.4-0.2-1.9-0.3c-0.5-0.2-0.9-0.3-1.3-0.2c-0.8,0-1.9,0.3-3,0.6c-2.2,0.5-4.7,0.3-6.9-0.4s-4.2-1.8-6-3.1
									c-1.9-1.2-3.5-2.7-5.2-4.1c-3.3-3-6.1-6.6-7.8-10.8c-0.8-2.1-1.4-4.4-1.5-6.6c0-0.6-0.1-1.1,0-1.7c0-0.6,0.1-1.1,0.1-1.7
									c0.2-1.2,0.4-2.2,0.8-3.5l-0.1,0.5l-0.1-10.1v-2.5c0-0.9-0.1-1.6,0.1-2.7c0.3-2,1.5-3.8,3.1-4.9c0.4-0.3,0.9-0.5,1.3-0.6
									l1.2-0.5l2.3-1l4.7-1.9c3.1-1.3,6.3-2.5,9.4-3.8l0,0l-0.2,0.1l4.4-4.5l2.2-2.3c0.8-0.8,1.9-1.4,3.1-1.5
									c1.2-0.1,2.4,0.2,3.3,0.9c1,0.7,1.6,1.8,1.8,2.9c0.2,1.2,0,2.4-0.5,3.4c-0.3,0.5-0.6,0.9-0.9,1.4l-0.9,1.3l-3.6,5.2
									l-0.9,1.3c-0.3,0.4-0.7,0.9-1.1,1.3c-0.5,0.4-0.9,0.5-1.4,0.8l-1.4,0.8l-2.8,1.5c-0.9,0.5-1.8,1-2.8,1.4
									c-1.9,0.9-3.8,1.8-5.8,2.5c1.8-1.1,3.6-2.1,5.5-3.1s3.7-2.1,5.5-3.1c0.9-0.5,1.9-1,2.7-1.5c0.4-0.3,0.6-0.6,0.9-1.1l0.9-1.3
									l3.5-5.2l0.9-1.3c0.3-0.4,0.6-0.9,0.8-1.3c0.4-0.8,0.6-1.8,0.4-2.7c-0.2-0.9-0.7-1.7-1.4-2.3s-1.7-0.8-2.6-0.7
									c-0.9,0.1-1.8,0.5-2.4,1.2l-2.2,2.2l-4.4,4.5l-0.1,0.1H144l0,0c-3.1,1.5-6.1,2.9-9.2,4.3l-4.6,2.1l-2.3,1l-1.2,0.5
									c-0.4,0.2-0.7,0.3-1,0.5c-1.2,0.8-1.9,2.1-2.1,3.5c-0.1,0.6,0,1.5,0,2.4l0.1,2.5l0.2,10.2v0.2l-0.1,0.3
									c-0.3,0.8-0.5,1.8-0.6,2.8c0,0.5-0.1,1-0.1,1.5s0,1,0,1.5c0.1,2,0.6,4,1.4,5.8c1.5,3.7,4,7.1,7.1,9.9c1.6,1.4,3.1,2.8,4.8,4
									s3.5,2.2,5.3,2.9c1.9,0.7,3.8,0.8,5.8,0.5c1-0.1,2-0.5,3.4-0.5c0.7,0,1.4,0.2,1.8,0.4c0.5,0.2,0.9,0.3,1.2,0.3h-0.1
									c2.8-0.4,5.5-0.8,8.3-1.2h0.1l0,0h3.9h2c0.7,0,1.3,0,1.9,0l0.1,1l0,0h-1.4v-1l6.9,0.1c0.6,0,1.2,0,1.7,0s1-0.1,1.5-0.4
									c0.5-0.2,0.9-0.5,1.3-0.9l1.3-1.1l2.6-2.2l1.3-1.1c0.4-0.4,0.9-0.7,1.2-1.1c0.6-0.8,0.8-1.8,0.6-2.8l-0.6-3.4l-1.3-6.8
									l-0.3-1.7c-0.1-0.5-0.3-1-0.6-1.4c-0.6-0.8-1.6-1.2-2.6-1s-1.8,0.9-2,1.8c0,0.1-0.1,0.2-0.1,0.4l-0.1,0.4l-0.1,0.9l-0.2,1.7
									l-0.8,6.8v0.2l-0.1,0.1L176,185l-0.2,0.2l-0.3-0.1l-5.4-1.6c-0.1,0-0.4,0-0.6,0h-0.7c-0.4,0-0.9,0.1-1.4,0.2l-2.8,0.4
									l-11.2,1.6L153.3,184.6z"/>
                </g>
                <g>
                    <path class="st13" d="M148.8,184l14.4-2.6l10.1-6.3l2.4-10.3l-3.1-4.2c-0.3-0.4-0.4-1-0.2-1.6c0.4-0.8,1.3-1.1,2.1-0.8
									l7,3.2c0.8,0.4,1.3,1.2,1.3,2.1l-0.1,1l-0.8,14c0,0.8-0.4,1.6-1,2.2l-0.7,0.7l-10.7,11.2c-0.5,0.5-1.2,1-2,1.1l-0.8,0.2
									l-16.6,1c-0.1,0-0.3,0.1-0.4,0.1c-1.9,0.3-3.7-0.1-5.2-1.1"/>
                    <path class="st2" d="M148.7,183.5l14.4-2.6l-0.2,0.1l10.1-6.3l-0.2,0.3l2.4-10.3l0.1,0.4l0,0l-2.2-2.8l-1.1-1.4
									c-0.4-0.7-0.5-1.6,0-2.3c0.4-0.7,1.3-1.1,2.1-1c0.2,0,0.4,0.1,0.6,0.2l0.4,0.2l0.8,0.4l3.2,1.5l1.6,0.7l0.8,0.4
									c0.3,0.1,0.7,0.3,0.9,0.6c0.6,0.5,0.9,1.3,0.9,2l-0.1,1.8l-0.4,7.1l-0.2,3.5l-0.1,1.8v0.9c0,0.3-0.1,0.7-0.2,1
									c-0.2,0.7-0.6,1.3-1,1.8l-1.2,1.3l-2.5,2.6l-4.9,5.1l-2.5,2.6c-0.4,0.4-0.9,0.9-1.5,1.2c-0.3,0.2-0.6,0.3-0.9,0.3l-0.9,0.2
									l0,0l0,0l-8.3,0.5c-2.8,0.2-5.6,0.3-8.3,0.3h0.1c-2,0.4-4.2-0.2-5.7-1.4c0.9,0.5,1.8,0.8,2.7,0.9c0.9,0.1,1.9,0,2.8-0.2l0,0
									l0,0c2.8-0.3,5.5-0.5,8.3-0.6l8.3-0.5h-0.1l0.9-0.2c0.2-0.1,0.5-0.2,0.7-0.3c0.5-0.2,0.8-0.5,1.2-1l2.4-2.6l4.9-5.2l2.4-2.6
									l1.2-1.3c0.4-0.4,0.6-0.8,0.7-1.3c0.1-0.2,0.1-0.5,0.1-0.7v-0.9l0.1-1.8l0.2-3.5l0.4-7.1l0.1-1.7c0-0.4-0.2-0.9-0.5-1.1
									c-0.2-0.2-0.3-0.2-0.6-0.4l-0.8-0.4l-1.6-0.8l-3.2-1.5l-0.8-0.4l-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.1-0.8,0.1-1,0.5
									c-0.2,0.3-0.2,0.8,0,1.1l1,1.4l2.1,2.9l0,0l0.1,0.2l-0.1,0.2l-2.4,10.3v0.2l-0.2,0.1l-10.1,6.3l-0.1,0.1H163l-14.4,2.6
									L148.7,183.5z"/>
                </g>
                <g>
                    <path class="st13" d="M145.4,174.4l9.6-2.4l12.8-9.6l6-10.1l-0.9-1.5c-0.4-0.7-0.5-1.7-0.1-2.5c0.6-1.3,2.2-1.8,3.5-1.2
									l4.8,2.4c1.1,0.5,1.7,1.8,1.4,3L182,154l-4.2,14.9c-0.2,0.7-0.6,1.3-1.1,1.8l-0.6,0.6l-13.9,12.1c-0.7,0.6-1.5,1-2.4,1.3
									l-0.2,0.1l-11,2.7l0,0c-1.5,0.4-3,0.2-4.4-0.4"/>
                    <path class="st2" d="M145.3,173.9l9.6-2.4l-0.2,0.1l12.7-9.7l-0.2,0.2l6-10.1v0.6l-0.6-1l-0.3-0.5c-0.1-0.2-0.2-0.4-0.3-0.6
									c-0.3-0.9-0.2-2,0.3-2.8s1.4-1.4,2.4-1.4c0.5,0,1,0,1.4,0.2l0.3,0.1l0.3,0.1l0.5,0.3l4.2,2.1c0.8,0.5,1.4,1.4,1.5,2.3
									c0.1,0.5,0,1-0.1,1.4l-0.3,1.1l-2.5,9l-1.3,4.5l-0.3,1.1l-0.2,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0.4-0.4,0.8-0.7,1.1
									c-0.3,0.4-0.6,0.6-0.9,0.8l-14.1,12.2c-0.6,0.5-1.4,1-2.2,1.2s-1.5,0.4-2.3,0.6l-4.5,1.1l-4.5,1.1c-1.6,0.4-3.4,0.2-4.7-0.8
									c1.6,0.3,3,0.3,4.4-0.1l4.5-1.1l4.5-1.1c0.7-0.2,1.5-0.4,2.2-0.6c0.7-0.2,1.3-0.6,1.8-1l14-12.3c0.6-0.5,1.1-0.9,1.3-1.6
									c0.1-0.2,0.1-0.3,0.2-0.5l0.2-0.6l0.3-1.1l1.2-4.5l2.5-9l0.3-1.1c0.1-0.3,0.1-0.6,0.1-0.9c-0.1-0.6-0.4-1.1-0.9-1.4
									l-4.1-2.1l-0.5-0.3l-0.3-0.1l-0.2-0.1c-0.3-0.1-0.6-0.1-0.9-0.1c-0.6,0-1.1,0.4-1.5,0.9c-0.3,0.5-0.4,1.1-0.2,1.7
									c0,0.1,0.1,0.3,0.2,0.4l0.3,0.5l0.6,1l0.2,0.3l-0.2,0.3l-6,10.1l-0.1,0.1l-0.1,0.1l-12.8,9.6l-0.1,0.1h-0.1l-9.6,2.3
									L145.3,173.9z"/>
                </g>
                <g>
                    <path class="st13" d="M138.5,166.2c1.1-1.9,3-2.7,4.6-3.8c1.7-1.1,3.3-2.1,5.1-3.1c3.3-1.9,6.7-3.8,10-5.6l6.2-5.3
									c2.2-1.9,4.5-3.8,6.8-5.7c0.9-1.2,1.8-2.5,2.8-3.7c1.2-1.5,2.3-3,3.5-4.5c1.2-1.6,3.4-2.1,5.3-1.2c2.1,1.1,1.9,2.7,1.4,4.9
									s-1.2,4.3-2,6c-0.9,1.7-1.8,3.4-2.7,5c-0.1,0.2-0.2,0.3-0.3,0.4l-0.5,0.6c-2.1,2.3-4.1,4.5-6.2,6.7l-6.3,6.7
									c-0.2,0.2-0.4,0.4-0.6,0.5l-0.5,0.3c-3.3,2.2-6.6,4.4-9.9,6.5c-1.7,1.1-3.4,2.1-5.1,3.1c-1.7,1-3.3,2.2-5.5,2.3"/>
                    <path class="st2" d="M138.5,166.2c0.4-0.9,1-1.7,1.8-2.4s1.6-1.2,2.4-1.7c1.6-1.1,3.3-2.1,5-3.1l10.2-5.8l-0.1,0.1l6.4-5.6
									l6.5-5.5l-0.1,0.1c2-2.6,3.9-5.3,5.9-7.9c0.2-0.3,0.5-0.7,0.9-1s0.7-0.6,1.2-0.8c0.8-0.4,1.8-0.6,2.8-0.5s1.8,0.5,2.6,1.1
									c0.4,0.3,0.7,0.7,0.9,1.2s0.3,1,0.2,1.5c0,0.9-0.2,1.8-0.4,2.6s-0.4,1.6-0.7,2.4c-0.3,0.8-0.5,1.6-0.9,2.4
									c-0.3,0.8-0.7,1.5-1.1,2.2l-1.2,2.2l-0.6,1.1l-0.3,0.5c-0.1,0.2-0.3,0.4-0.4,0.6l-3.3,3.6c-2.2,2.4-4.5,4.8-6.7,7.2
									l-1.7,1.8l-0.8,0.9l-0.4,0.4l-0.5,0.5c-1.4,1-2.8,1.8-4.1,2.7l-4.1,2.7c-1.4,0.9-2.7,1.8-4.2,2.6c-1.4,0.8-2.8,1.7-4.3,2.5
									c-1.4,0.8-3.1,1.6-4.8,1.4c1.6-0.4,2.9-1.3,4.3-2.2c1.4-0.9,2.8-1.7,4.2-2.6c1.4-0.8,2.8-1.7,4.1-2.6l4.1-2.7
									c1.4-0.9,2.8-1.8,4.1-2.7l0.4-0.3l0.4-0.4l0.8-0.9l1.7-1.8c2.3-2.4,4.5-4.8,6.7-7.2l3.3-3.6c0.1-0.1,0.2-0.2,0.3-0.4
									l0.3-0.5l0.6-1.1l1.1-2.2c0.8-1.5,1.4-2.9,1.9-4.4c0.2-0.8,0.4-1.5,0.6-2.3c0.2-0.8,0.4-1.6,0.4-2.3s-0.2-1.2-0.7-1.7
									c-0.5-0.4-1.2-0.7-1.9-0.8s-1.4,0-2,0.3c-0.3,0.2-0.6,0.3-0.9,0.6c-0.3,0.2-0.5,0.5-0.7,0.8c-2,2.6-4,5.2-6,7.8v0.1h-0.1
									l-6.5,5.5l-6.5,5.5l0,0h-0.1l-10.2,5.7c-1.7,1-3.3,2-5,3C141.5,163.9,139.7,164.6,138.5,166.2z"/>
                </g>
            </g>

        </g>


    )
}
