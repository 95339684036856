import React, {useState} from 'react'
import { first, second } from '../services/translation/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { StepsParameters } from '../components/stepsParameters/stepsParameters'
import { FiArrowLeft } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { GlobalLayout } from '../components/globallayout/globalLayout'
import { BlockIllu } from '../components/svg/blockIllu'
import ReactSlider from 'react-slider'


export default function Surgerie(props) {
    const r_action = useDispatch()
    const r_type = useSelector(state => state.parameters.fgm_type)
    const r_subType = useSelector(state => state.parameters.fgm_subType)
    const r_fgmAll = useSelector(state => state.parameters.fgmAll)
    const r_animationStep = useSelector(state => state.parameters.animationStep)
    const r_surgerie = useSelector(state => state.parameters.surgerie)
    const r_chirurgie = useSelector(state => state.parameters.chirurgie)
    const [totalItem, setTotalItem] = useState('initial');

    const loading = props.loading

    useEffect(() => {
        r_action({ type: 'SET_STEP', step: 3 })
        r_action({ type: 'SET_ANIMATION_STEP', step: 0 })

        if (localStorage.getItem('skinColor')) {
            let skinColor = JSON.parse(localStorage.getItem('skinColor'))
            r_action({ type: 'SET_SKIN_COLOR', color: skinColor.skinColor })
        }
        else{r_action({ type: 'SET_SKIN_COLOR', color: '#bf9676' })}
        if (localStorage.getItem('labiaColor')) {
            let labiaColor = JSON.parse(localStorage.getItem('labiaColor'))
            r_action({ type: 'SET_LABIA_COLOR', color: labiaColor.labiaColor })
        }
        else{r_action({ type: 'SET_LABIA_COLOR', color: '#d866a3' })}

        r_action({type: 'SET_SVG_WHITE', allWhite : false})
        r_chirurgie.map((chirurgie, i) => {
            setTotalItem(i);
        });
    }, [])

    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function(url) {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        })
        // or alternatively
        // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }

    function createMarkup(content) {
        return {__html: content};
    }



    return (
        <GlobalLayout className="bg-pattern" loading={loading}>
            <div className="flex f-column f-center f-1">
                <div className="w-xl-content flex f-full f-column f-1">
                    <StepsParameters />
                    <div className="relative flex f-center mx-1">
                        <Link to="/care" className="block absolute text-white" style={{ left: 0 }}> <FiArrowLeft size={30} /></Link>
                        <div className="f-1">
                            <h1 className="text-white text-center text-bolder text-30"> {first.t(`ecran5-titre`)}</h1>
                            {first.language !== second.language ? <h2 className="text-white text-center text-18 px-2">{second.t(`ecran5-titre`)}</h2> : ""}
                        </div>
                    </div>
                    <div className="f-1 flex f-column f-full relative mt-1 bg-blured-back r-tl r-tr">
                        <BlockIllu className="mx-1 r py-05" showPlay={r_fgmAll[+r_type - 1] ? true : false}  />

                        {r_fgmAll[+r_type - 1] && <div className="bg-white mx-1 p-1 mb-3 r">
                            <ReactSlider
                                className="horizontal-slider -mt-02"
                                thumbClassName="thumb"
                                trackClassName="track"
                                max={Object.keys(r_fgmAll[+r_type - 1].subTypes[r_subType === 'a' ? 0 : r_subType === 'b' ? 1 : 2][`care${r_surgerie}`]).length - 1}
                                onChange={(value) => r_action({ type: 'SET_ANIMATION_STEP', animationStep: value })}
                                renderThumb={(props, state) => <div {...props}></div>}
                                value={r_animationStep}
                            />
                        </div>}

                        {r_fgmAll[+r_type - 1] && <h2 className="mx-1"><span className="text-18 text-bold">{first.t(`types-of-reconstructions`)}</span>
                            {first.language !== second.language ? <span className="text-14 text-italic"> / {second.t(`types-of-reconstructions`)}</span> : ""}
                        </h2>}

                        <div style={{ height: 300 }} className="mt-1 mb-5">
                            <div className={`relative overflow f-1 h-full ${totalItem === 1 ? "flex f-center-w" : ""}`}>
                                <div className={`flex absolute ${totalItem === 1 ? "f-center-w" : ""}`}>
                                    {r_fgmAll[+r_type - 1] && r_chirurgie && r_chirurgie.map((chirurgie, i) => {
                                        let showSurgerie = 2
                                        if (r_fgmAll[+r_type - 1]) {
                                            showSurgerie = Object.keys(r_fgmAll[+r_type - 1].subTypes[r_subType === 'a' ? 0 : r_subType === 'b' ? 1 : 2][`care${i}`]).length
                                        }
                                        if (showSurgerie > 1) {
                                            if(Object.keys(r_fgmAll[+r_type - 1].subTypes[r_subType === 'a' ? 0 : r_subType === 'b' ? 1 : 2][`care${i === 0 ? 1 : 0}`]).length > 1){}
                                            else{r_action({ type: 'SET_FGM_SURGERIE', surgerie: i })}
                                            return (
                                                <div onClick={() => r_action({ type: 'SET_FGM_SURGERIE', surgerie: i })} key={i} className={`mr-05 ${i === 0 ? 'ml-1' : 'ml-05'} bg-white r p-1 flex f-column`}
                                                     style={{ width: totalItem === 1 ? 300 : 200 }}
                                                >
                                                    <div className="flex">
                                                        <div className="flex f-center">
                                                            <div className={`mr-2 r-full b-2-secondary shadow-small p-03 transition ${r_surgerie === i ? ' bg-secondary' : 'bg-white'}`}></div>
                                                        </div>
                                                        <div className="f-1">
                                                            <span className="text-bold text-18">{first.t(chirurgie.label)}</span>
                                                            {first.language !== second.language ? <span className="text-italic text-14"><br/> {second.t(chirurgie.label)}</span> : ""}
                                                        </div>
                                                    </div>
                                                    <p className={`link-a mt-1 text-14 ${first.language !== "ar" ? 'text-left' : 'rr'}`} style={{ direction: first.dir() }} dangerouslySetInnerHTML={createMarkup((first.t(chirurgie.content)))}></p>
                                                    {first.language !== second.language ?
                                                        <p className={`mt-1 text-14 link-a ${second.language !== "ar" ? 'text-left': 'rr'}`} style={{ direction: first.dir() }} dangerouslySetInnerHTML={createMarkup((second.t(chirurgie.content)))}></p>
                                                        : ""}
                                                </div>
                                            )
                                        }

                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GlobalLayout>
    )
}
