import React from 'react'
import { useSelector } from 'react-redux'

export const Suture3a = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Suture3a" className="st4 transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                <path className="st2" d="M164.8,76.1c0.7-0.5,1.5-0.8,2.3-0.9c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,75.7,165.6,75.9,164.8,76.1z"/>
                <path className="st2" d="M164.8,78.9c0.6-0.5,1.5-0.8,2.3-0.9c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,78.6,165.5,78.7,164.8,78.9z"/>
                <path className="st2" d="M164.8,81.8c0.7-0.5,1.5-0.8,2.3-0.9c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,81.4,165.6,81.6,164.8,81.8z"/>
                <path className="st2" d="M164.8,84.6c1-0.8,2.4-1.1,3.6-0.6c0.4,0.1,0.8,0.4,1.1,0.6c-0.4-0.1-0.8-0.2-1.2-0.2
				c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,84.3,165.6,84.4,164.8,84.6z"/>
                <path className="st2" d="M164.8,87.4c0.7-0.5,1.5-0.8,2.3-0.8c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,87.1,165.6,87.2,164.8,87.4z"/>
                <path className="st2" d="M164.8,90.3c1-0.8,2.4-1.1,3.6-0.6c0.4,0.1,0.8,0.4,1.1,0.6c-0.4-0.1-0.8-0.2-1.2-0.2
				c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,89.9,165.6,90.1,164.8,90.3z"/>
                <path className="st2" d="M164.8,93.1c0.7-0.5,1.5-0.8,2.3-0.8c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,92.7,165.6,92.9,164.8,93.1z"/>
                <path className="st2" d="M164.8,95.9c0.7-0.5,1.5-0.8,2.3-0.9c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,95.6,165.5,95.7,164.8,95.9z"/>
                <path className="st2" d="M164.8,98.8c0.7-0.5,1.5-0.8,2.3-0.8c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,98.4,165.6,98.6,164.8,98.8z"/>
                <path className="st2" d="M164.8,101.6c0.7-0.5,1.5-0.8,2.3-0.9c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,101.3,165.6,101.4,164.8,101.6z"/>
                <path className="st2" d="M164.8,104.4c0.7-0.5,1.5-0.8,2.3-0.8c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,104.1,165.6,104.2,164.8,104.4z"/>
                <path className="st2" d="M164.8,107.3c0.7-0.5,1.5-0.8,2.3-0.9c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,106.9,165.6,107.1,164.8,107.3z"/>
                <path className="st2" d="M164.8,110.1c0.7-0.5,1.5-0.8,2.3-0.8c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,109.7,165.6,109.9,164.8,110.1z"/>
                <path className="st2" d="M164.8,112.9c0.6-0.5,1.5-0.8,2.3-0.9c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,112.6,165.5,112.7,164.8,112.9z"/>
                <path className="st2" d="M164.8,115.8c0.7-0.5,1.5-0.8,2.3-0.8c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.4,1.1,0.6
				c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4-0.1-0.8-0.1-1.2-0.1C166.3,115.4,165.6,115.6,164.8,115.8z"/>
            </g>
    )
}
