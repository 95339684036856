import i18n from "i18next";

const resources = {
  en: {
    translation: {
      bienvenue: "Welcome",
      about: "<h2>Let's talk FGM</h2>",
    },
  },
};

export const first = i18n.createInstance();
export const second = i18n.createInstance();

first.init({
  resources,
  lng: "en",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

second.init({
  resources,
  lng: "en",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

