import React from 'react'

export const CuisseG1 = (props) => {
    return (
<g id="CuisseG">
		<path className="st2" d="M60.8,0.1c3.2,2.6,6.3,5.3,9.5,8s6.2,5.5,9.2,8.3c6,5.6,12,11.4,17.6,17.4s11.1,12.2,16.2,18.8
			c1.2,1.7,2.5,3.3,3.7,5s2.4,3.4,3.5,5.1l3.2,5.3c1,1.8,1.9,3.7,2.8,5.5c-4.1-7.1-8.9-13.9-14.2-20.1C107,47.1,101.5,41,95.9,35
			S84.4,23.2,78.5,17.4c-2.9-2.9-5.8-5.8-8.8-8.6C66.8,5.8,63.8,2.9,60.8,0.1z"/>
	</g>
    )
}