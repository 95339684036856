import React from 'react'
import { useSelector } from 'react-redux'

export const Prepuce2aFerme = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Prepuce2aFerme" className="transition" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                    <path fill={r_labia_color} className="st--5" d="M167.4,80c0,3.7-3.9,16-4.6,17.4c0.2-7.4,1.9-24.2,4.5-24.2c2.4,0,3.4,14.2,4.2,21.5
					C170.9,92,167.4,83.6,167.4,80z"/>
                    <path className="st2" d="M171.4,95.8c-0.6-2.7-1.5-5.2-2.4-7.8c-0.5-1.3-0.9-2.6-1.3-3.9c-0.5-1.3-0.8-2.7-0.9-4.1h1.1
					c-0.1,1.5-0.3,3.1-0.7,4.6c-0.3,1.5-0.7,3-1.1,4.4s-0.9,2.9-1.5,4.3c-0.5,1.4-1.2,2.8-1.9,4.1c1.1-2.8,1.9-5.7,2.6-8.7
					c0.3-1.5,0.7-2.9,0.9-4.4c0.3-1.5,0.5-2.9,0.6-4.4c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5l0,0c0.1,1.3,0.4,2.6,0.8,3.9
					l1.1,3.9c0.4,1.3,0.7,2.6,1,4C171.1,93.1,171.3,94.4,171.4,95.8z"/>
                </g>
    )
}