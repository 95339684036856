import React from 'react'
import { useSelector } from 'react-redux'

export const Desinfection = (props) => {

    return (
        <g id="desinfection" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(0px, -15px)'}}>
            <g>
					<path class="st11" d="M150.9,108.1c3.9-1.3,8.3-2.1,12.9-1.8c4.6,0.2,9.2,1.5,13.3,3.6s7.6,4.9,10.6,8.2c1.5,1.7,2.8,3.5,4,5.5
						c0.6,1,1.2,2.1,1.8,3.5c0.3,0.7,0.6,1.4,0.8,2.4l0.1,0.4l0.1,0.5c0.1,0.3,0.1,0.7,0.2,1v0.3v0.4l0.1,0.8c0,0.3,0,0.5,0,0.8
						c0,0.4,0,0.7-0.1,1.1c-0.3,2.5-0.9,3.7-1.4,4.8c-0.5,1-1,1.8-1.4,2.5c-0.9,1.3-1.7,2.3-2.6,3.2c-1.7,1.8-3.3,3.2-5,4.4
						c-3.4,2.5-6.9,4.4-10.5,5.9c-7.3,3-15.1,4.2-22.7,3.7c-5.4-0.4-9.5-5.1-9.2-10.6c0.3-4.9,4.1-8.6,8.8-9.1h0.3
						c5-0.5,9.8-1.8,14-3.9c2.1-1,4.1-2.3,5.8-3.6c0.8-0.7,1.6-1.3,2-1.9c0.2-0.3,0.4-0.5,0.4-0.5s-0.1,0.1-0.2,0.4
						c-0.1,0.3-0.5,0.8-0.7,2.5c0,0.3,0,0.5,0,0.8v0.5c0,0.2,0,0.3,0,0.5l0.1,0.4c0,0.1,0,0.3,0.1,0.4l0.1,0.3
						c0.1,0.3,0.1,0.2,0.1,0.2c0-0.1-0.2-0.4-0.4-0.7c-0.4-0.7-0.9-1.4-1.5-2.1c-1.2-1.4-2.6-2.6-4.1-3.5s-3-1.5-4.7-1.7
						c-1.7-0.3-3.7-0.3-5.8,0l-0.8,0.1c-5.5,0.7-10.5-3.2-11.2-8.7C143.6,114,146.5,109.6,150.9,108.1z"/>
				</g>
				<g>
					<path class="st12" d="M130,76.6c4.1-3.2,11.8-2.8,13.6-0.9s0-16.9,0-16.9l-17,5.2L130,76.6z"/>
					<g>
						<path class="st13" d="M166.5,59.7l4.8,6.2l2.4,3.1l1.2,1.5c0.4,0.5,0.8,1.1,1.2,1.5l0.8,0.7c0.1,0.1,0.2,0.1,0.3,0.2
							c1.6,0.9,3.3,1.7,4.9,2.6l0.1,3.5l-6,7.9c-0.8,1-0.9,2.5,0,3.6c1,1.4,3,1.7,4.4,0.7l10.3-7.2c1-0.7,1.7-2,1.5-3.3v-0.5
							l-0.7-7.7c-0.1-1.1-0.6-2.1-1.4-2.9l-0.6-0.6c-1.8-1.8-3.7-3.5-5.5-5.3l1,0.9c-0.4-0.5-0.9-1-1.3-1.5l-1.4-1.4l-2.7-2.9
							l-5-6.7c-0.7-0.7-1.7-1-2.5-1.6c-2.5-2.1-2.7-9-19.8-9.3C135.4,41,131,52.1,131,52.1l-10.1,10.1l-0.1,0.1
							c-1.6,1.6-2.2,4.1-1.3,6.4l7.4,17.9l0.1,9.5c0,2.1,1.6,4,3.9,4.3c2.3,0.3,4.5-1.5,4.9-3.8l1.9-10.7c0.1-0.5,0.1-1.1-0.1-1.6
							l-0.2-0.7l-2.1-6.9c0,0-1.7-5-2.5-7"/>
						<path class="st2" d="M166.8,59.4l7,8.9l1.7,2.2c0.3,0.4,0.6,0.8,0.9,1.1l0.5,0.5c0.2,0.2,0.4,0.4,0.4,0.4l5,2.6l0.3,0.1v0.3
							l0.1,3.5v0.2l-0.1,0.1l-4.2,5.5l-1,1.4l-0.5,0.7l-0.3,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.5,0.8-0.4,1.9,0.2,2.7
							c0.6,0.8,1.6,1.2,2.5,1.1c0.5-0.1,0.9-0.3,1.3-0.6l1.4-1l5.6-4l2.8-2c0.9-0.6,1.5-1.4,1.6-2.4c0-0.5-0.1-1-0.1-1.6l-0.2-1.7
							l-0.3-3.4l-0.2-1.7c0-0.6-0.1-1-0.3-1.5s-0.5-0.9-0.8-1.3c-0.4-0.4-0.8-0.8-1.2-1.2l-5-4.8l0.7-0.7l1,0.9l0,0l-0.8,0.7
							c-0.4-0.5-0.9-0.9-1.3-1.4l-1.4-1.4l-2.8-2.8l0,0v-0.1c-1.7-2.2-3.5-4.4-5.1-6.6l0.1,0.1c-0.2-0.2-0.6-0.4-1.1-0.7
							c-0.5-0.2-1.1-0.5-1.6-1c-1-1-1.5-1.9-2.1-2.7c-1.2-1.6-2.7-2.9-4.5-3.7S161,43.3,159,43s-4.1-0.4-6.2-0.5
							c-4.2-0.1-8.3,0.5-12,2.1c-1.8,0.8-3.6,1.9-5,3.2c-0.4,0.3-0.7,0.7-1.1,1c-0.4,0.4-0.7,0.7-1,1.1c-0.6,0.8-1.2,1.7-1.5,2.4
							l-0.1,0.3l-0.2,0.2l-7.3,7.1l-1.8,1.8c-0.6,0.6-1.3,1.2-1.7,1.7c-0.9,1.1-1.2,2.6-0.9,3.9c0.1,0.4,0.2,0.6,0.3,1l0.5,1.2
							l0.9,2.4l1.8,4.7c1.2,3.2,2.4,6.3,3.6,9.5l0,0v0.1v0.1l0.1,6.3v3.2c0,0.9,0.3,1.8,0.9,2.5c0.6,0.7,1.5,1.2,2.4,1.3
							c0.9,0.1,1.9-0.1,2.6-0.6c0.8-0.5,1.4-1.3,1.6-2.2c0.1-0.4,0.2-1,0.3-1.5l0.3-1.6l1.1-6.2l0.3-1.6c0.1-0.5,0.2-0.9,0.1-1.4
							c-0.2-0.9-0.6-2-0.8-3c-0.6-2-1.2-4-1.8-6c-0.7-2-1.3-4-1.8-6.1c0.9,1.9,1.7,3.9,2.4,5.9c0.4,1,0.7,2,1,3l0.9,3l0.5,1.5
							c0.1,0.5,0.3,1,0.5,1.6c0.1,0.6,0,1.2-0.1,1.7l-0.3,1.6l-1,6.2l-0.3,1.6c-0.1,0.5-0.2,1-0.3,1.6c-0.3,1.1-1,2.1-2,2.8
							c-1,0.7-2.2,1-3.4,0.8c-1.2-0.1-2.3-0.7-3.1-1.6c-0.8-0.9-1.2-2.1-1.2-3.2v-3.2v-6.3v0.2l0,0c-1.4-3.1-2.8-6.2-4.1-9.3l-2-4.6
							l-1-2.3l-0.5-1.2c-0.2-0.4-0.4-0.9-0.5-1.4c-0.5-1.9,0-4.1,1.2-5.7c0.7-0.9,1.3-1.4,1.8-2l1.8-1.8l7.1-7.2l-0.3,0.4
							c0.5-1.2,1.1-2.1,1.8-3.1c0.4-0.5,0.7-0.9,1.1-1.3c0.4-0.4,0.8-0.8,1.2-1.2c1.7-1.5,3.6-2.8,5.7-3.7c4.2-1.8,8.7-2.5,13.1-2.3
							c2.2,0.1,4.4,0.2,6.6,0.6c2.2,0.4,4.4,1,6.4,2c2.1,1,3.9,2.6,5.2,4.5c0.6,0.9,1.2,1.9,1.7,2.5c0.2,0.3,0.6,0.5,1.1,0.7
							c0.5,0.3,1.1,0.6,1.6,1.1l0,0l0,0c1.6,2.3,3.2,4.6,4.8,6.9l-0.1-0.1l2.6,2.9l1.3,1.5c0.4,0.5,0.9,0.9,1.3,1.6l2.5,3.7l-3.3-3
							l0,0l-1-0.9l0.7-0.7l5,4.8c0.4,0.4,0.8,0.8,1.2,1.2c0.4,0.5,0.8,1,1,1.6s0.4,1.3,0.4,1.8l0.2,1.7l0.3,3.4l0.2,1.7
							c0,0.6,0.2,1.1,0.1,1.8c-0.1,1.3-1,2.5-2,3.2l-2.8,2l-5.6,4l-1.4,1c-0.5,0.4-1.1,0.7-1.8,0.8c-1.3,0.2-2.7-0.4-3.5-1.5
							s-0.9-2.6-0.2-3.8c0.1-0.1,0.2-0.3,0.3-0.4l0.3-0.3l0.5-0.7l1-1.4l4.2-5.5l-0.1,0.3l-0.1-3.5l0.3,0.4
							c-1.7-0.9-3.3-1.7-5.1-2.7c-0.3-0.2-0.4-0.4-0.6-0.5l-0.5-0.5c-0.4-0.4-0.6-0.8-0.9-1.1l-1.7-2.2l-7-8.9L166.8,59.4z"/>
					</g>
					<g>
						<g>
							
								<radialGradient id="SVGID_5_" cx="151.2972" cy="6892.4067" r="32.7608" gradientTransform="matrix(1 0 0 -1 0 6995.2754)" gradientUnits="userSpaceOnUse">
								<stop  offset="0" style={{"stopColor":"#FFFFFF"}}/>
								<stop  offset="0.2878" style={{"stopColor":"#FBFCFD"}}/>
								<stop  offset="0.5518" style={{"stopColor":"#F0F5F6"}}/>
								<stop  offset="0.8056" style={{"stopColor":"#DEE8EC"}}/>
								<stop  offset="1" style={{"stopColor":"#CADAE0"}}/>
							</radialGradient>
							<circle class="st14" cx="144.9" cy="110.1" r="20.6"/>
							<path class="st2" d="M166.5,110.1c0.1,5.7-2.3,11.3-6.4,15.2c-4,4-9.6,6.2-15.2,6.1c-5.6-0.1-11.1-2.3-15-6.3
								c-3.9-4-6.1-9.5-6.1-15c0-2.8,0.6-5.5,1.6-8.1l0.7-2c0.3-0.6,0.6-1.3,0.9-1.9c0.4-0.6,0.8-1.1,1.2-1.7l1.4-1.6
								c2-2,4.4-3.6,7-4.6s5.4-1.6,8.2-1.5c2.8,0,5.6,0.6,8.1,1.8c2.5,1.2,4.8,2.8,6.8,4.6c2,1.9,3.6,4.2,4.8,6.7
								C165.8,104.4,166.4,107.2,166.5,110.1z M164.5,110.1c0.2-5.1-1.6-10.4-5.3-14.3c-1.8-1.9-4.1-3.4-6.6-4.3s-5.1-1.4-7.7-1.3
								c-5.2,0.1-10.3,2.3-13.9,5.9l-1.3,1.4c-0.4,0.5-0.8,1-1.2,1.5s-0.8,1-1.1,1.6c-0.3,0.6-0.7,1.1-1,1.7c-1,2.4-1.6,5.1-1.6,7.7
								c0,5.3,2.2,10.5,6,14.1c3.7,3.7,8.9,5.8,14.1,5.7c5.2-0.1,10.3-2.3,13.9-6C162.4,120.3,164.4,115.2,164.5,110.1z"/>
						</g>
						<g>
							<path class="st2" d="M160.9,110.1c0.6-1.9,0.8-3.9,0.5-5.7c-0.2-0.9-0.4-1.8-0.7-2.7c-0.3-0.9-0.8-1.7-1.4-2.4
								c-1.1-1.5-2.6-2.7-4.3-3.5s-3.7-1.2-5.7-1.1c1.9-0.6,4.1-0.6,6,0.1c1,0.3,1.9,0.8,2.8,1.4c0.9,0.6,1.6,1.4,2.3,2.2
								c1.3,1.7,2,3.8,2.1,5.9C162.7,106.3,162.1,108.4,160.9,110.1z"/>
						</g>
						<g>
							<path class="st2" d="M135.4,119.7c0.8,1.8,2,3.4,3.5,4.6c0.8,0.6,1.6,1.1,2.5,1.4c0.9,0.4,1.8,0.6,2.7,0.8
								c0.9,0.2,1.9,0.2,2.8,0c0.9-0.1,1.9-0.4,2.8-0.7c1.8-0.7,3.4-1.8,4.7-3.4c-1,1.8-2.5,3.2-4.4,4.1s-4,1.2-6.1,0.9
								c-2.1-0.3-4-1.2-5.5-2.6C136.9,123.5,135.8,121.6,135.4,119.7z"/>
						</g>
						<g>
							<path class="st2" d="M144.2,95c-1.2-0.5-2.4-0.7-3.5-0.7s-2.2,0.2-3.2,0.7s-1.9,1.2-2.6,2c-0.4,0.4-0.6,0.9-0.9,1.5
								c-0.3,0.5-0.4,1.1-0.6,1.7c-0.1-0.6-0.2-1.3-0.1-1.9c0.1-0.6,0.3-1.3,0.6-1.9c0.6-1.2,1.6-2.3,2.9-2.9
								c1.2-0.6,2.7-0.9,4.1-0.6C142.2,93.2,143.5,93.9,144.2,95z"/>
						</g>
						<g>
							<path class="st2" d="M128.9,105.9c-0.9,0.9-1.5,1.9-1.9,3c-0.2,0.6-0.3,1.1-0.3,1.7c-0.1,0.6,0,1.1,0.1,1.7
								c0.2,1.1,0.6,2.2,1.3,3.1c0.3,0.5,0.8,0.9,1.2,1.3c0.5,0.4,1,0.7,1.5,1c-0.6-0.1-1.3-0.3-1.8-0.6c-0.6-0.3-1.1-0.7-1.6-1.2
								c-0.9-0.9-1.6-2.2-1.8-3.5c-0.2-1.3,0-2.7,0.6-3.9C126.7,107.4,127.7,106.4,128.9,105.9z"/>
						</g>
						<g>
							<path class="st2" d="M149.4,116.4c1.1-0.7,2-1.5,2.6-2.5s1.1-2,1.2-3.2c0.1-0.6,0.1-1.1,0-1.7c0-0.6-0.2-1.1-0.4-1.7
								c-0.2-0.5-0.5-1.1-0.8-1.6s-0.8-0.9-1.2-1.4c0.6,0.3,1.2,0.6,1.6,1c0.5,0.5,0.9,1,1.2,1.6c0.7,1.2,0.9,2.6,0.8,3.9
								c-0.2,1.4-0.8,2.7-1.7,3.6C151.9,115.5,150.7,116.2,149.4,116.4z"/>
						</g>
					</g>
					<g>
						<path class="st13" d="M159.4,59l4.9,13.8l-0.5,11.9l-7.8,7.2l-5.2-0.7c-0.5-0.1-1.1,0.2-1.5,0.6c-0.5,0.7-0.3,1.7,0.4,2.2
							l6.3,4.5c0.7,0.5,1.7,0.6,2.5,0.1l0.8-0.5l11.8-7.6c0.7-0.4,1.2-1.1,1.4-1.9l0.3-1l4.4-14.8c0.2-0.7,0.2-1.5,0-2.3l-0.2-0.8
							l-4.4-15c0-0.1-0.1-0.3-0.1-0.4c-0.6-1.8-2-3.2-3.6-4"/>
						<path class="st2" d="M159.9,58.8l4.9,13.8v0.1v0.1l-0.5,11.9v0.2l-0.2,0.1l-7.8,7.2l-0.2,0.2h-0.2l0,0l-4.9-0.6h-0.3
							c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.2-0.1,0.5,0,0.8c0.1,0.2,0.3,0.4,0.7,0.6l4,2.8
							l2,1.4c0.5,0.3,1.2,0.4,1.7,0.1l8.2-5.3l4.1-2.6c0.4-0.2,0.7-0.4,0.9-0.6s0.4-0.5,0.6-0.8c0.1-0.3,0.2-0.7,0.4-1.1l0.4-1.2
							l2.8-9.4l1.4-4.7c0.2-0.7,0.2-1.4-0.1-2.1L176,68l-2.7-9.4c-0.2-0.8-0.4-1.6-0.6-2.4c-0.2-0.8-0.4-1.6-0.6-2.3
							c-0.6-1.5-1.6-2.7-3-3.6c1.5,0.6,2.9,1.8,3.7,3.3c0.4,0.8,0.6,1.6,0.9,2.3c0.3,0.8,0.5,1.6,0.7,2.3l2.8,9.4l0.7,2.3
							c0.3,0.8,0.4,1.8,0.1,2.7l-1.4,4.7l-2.8,9.4l-0.3,1.2c-0.1,0.4-0.2,0.8-0.4,1.2c-0.2,0.4-0.5,0.8-0.8,1.2
							c-0.3,0.3-0.8,0.6-1.1,0.8l-4.1,2.6l-8.3,5.3c-0.9,0.5-2.1,0.5-3-0.1l-2-1.5l-4-2.9c-0.2-0.1-0.3-0.2-0.5-0.4
							c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.5-0.3-1.1-0.1-1.6s0.6-1,1.1-1.2c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3,0,0.4,0h0.3l4.8,0.7
							l0,0l-0.4,0.1l7.8-7.2l-0.2,0.3l0.5-11.9v0.2L159,59.2L159.9,58.8z"/>
					</g>
					<g>
						<path class="st13" d="M149.4,60.8l2.7,9.5L150,86.2l-5.8,10.2h-1.8c-0.8,0-1.7,0.4-2.2,1.1c-0.8,1.2-0.5,2.8,0.7,3.6l4.4,3
							c1,0.7,2.4,0.6,3.3-0.3l1.1-1.1l10.9-11c0.5-0.5,0.8-1.1,1-1.8l0.2-0.8l3.7-18.1c0.2-0.9,0.2-1.8-0.1-2.7l-0.1-0.2l-3.1-10.9
							l0,0c-0.4-1.5-1.3-2.7-2.5-3.6"/>
						<path class="st2" d="M149.9,60.7l2.7,9.5v0.1v0.1l-2,15.9v0.1l-0.1,0.1l-5.8,10.2l-0.2,0.3h-0.4h-1.2h-0.6c-0.2,0-0.3,0-0.5,0
							c-0.6,0.1-1.1,0.5-1.4,1c-0.3,0.5-0.3,1.2,0,1.7c0.1,0.3,0.3,0.5,0.5,0.7l0.2,0.1l0.2,0.2l0.5,0.3l3.8,2.6
							c0.5,0.3,1.2,0.3,1.7,0.1c0.3-0.1,0.5-0.3,0.7-0.5l0.8-0.8l6.6-6.6l3.3-3.3l0.8-0.8l0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.4
							c0.4-0.5,0.5-1.1,0.7-1.9l3.8-18.2c0.1-0.7,0.1-1.4,0-2.1c-0.2-0.7-0.4-1.5-0.6-2.2l-1.2-4.5l-1.2-4.5
							c-0.4-1.4-1.1-2.7-2.1-3.9c1.5,0.6,2.6,2.1,3,3.6l1.3,4.5l1.3,4.5c0.2,0.8,0.4,1.5,0.6,2.3c0.2,0.8,0.2,1.7,0,2.5l-3.7,18.3
							c-0.1,0.4-0.1,0.7-0.3,1.2c-0.1,0.4-0.4,0.8-0.6,1.2c-0.1,0.2-0.3,0.4-0.4,0.5l-0.4,0.4l-0.8,0.8l-3.3,3.3l-6.6,6.6l-0.8,0.8
							c-0.3,0.3-0.7,0.6-1.2,0.8c-0.9,0.4-1.9,0.3-2.8-0.2l-3.9-2.6l-0.5-0.3l-0.2-0.2l-0.3-0.2c-0.4-0.3-0.7-0.7-0.9-1.1
							c-0.4-0.9-0.4-1.9,0.1-2.8c0.4-0.9,1.3-1.5,2.2-1.7c0.2,0,0.5-0.1,0.7-0.1h0.6h1.2l-0.5,0.3l5.8-10.2l-0.1,0.2l2.2-15.8v0.2
							l-2.6-9.6L149.9,60.7z"/>
					</g>
					<g>
						<path class="st13" d="M138.8,58.8c-1.1,1.9-0.9,3.9-1,5.9c-0.1,2-0.2,3.9-0.2,5.9c-0.1,3.8,0,7.7,0,11.5l-1.6,8
							c-0.6,2.9-1.1,5.8-1.6,8.7c-0.6,1.4-1.3,2.8-1.9,4.3c-0.7,1.7-1.5,3.5-2.2,5.3s-0.1,4,1.6,5.2c2,1.3,3.3,0.4,5-1.2
							c1.7-1.5,3.1-3.1,4.2-4.7c1.1-1.6,2-3.2,3.1-4.8c0.1-0.1,0.2-0.3,0.2-0.5l0.2-0.7c0.9-2.9,1.9-5.8,2.8-8.7l2.7-8.8
							c0.1-0.2,0.1-0.5,0.1-0.8v-0.6c0.3-3.9,0.6-7.9,0.8-11.8c0.1-2,0.1-3.9,0.2-5.9c0-2,0.3-3.9-0.7-6"/>
						<path class="st2" d="M138.8,58.8c-0.8,1.9-0.5,3.8-0.6,5.7c0,2-0.1,3.9-0.2,5.9l0.1,11.7v0.1v0.1l-1.6,8.4L135,99v0.1v0.1
							c-1.3,3-2.6,6-3.8,9c-0.2,0.4-0.3,0.7-0.4,1.1c-0.1,0.3-0.1,0.7-0.1,1c0,0.7,0.3,1.4,0.7,1.9s1,1,1.6,1.2s1.2,0.1,1.8-0.2
							c0.6-0.4,1.2-0.9,1.8-1.4s1.2-1.1,1.7-1.7c1.1-1.2,2-2.4,2.9-3.8l1.3-2.1l0.7-1l0.3-0.5c0.1-0.2,0.1-0.3,0.2-0.4l1.5-4.7
							c1-3.1,2-6.2,3-9.4l0.7-2.3l0.4-1.2l0.2-0.6l0.1-0.5c0.2-1.6,0.2-3.3,0.4-4.9l0.3-4.9c0.1-1.6,0.2-3.3,0.2-4.9
							s0.1-3.3,0.2-4.9c0.1-1.6,0.3-3.2-0.2-4.8c1,1.4,1.2,3.2,1.2,4.8c0.1,1.6,0,3.3,0,4.9s-0.1,3.3-0.2,4.9l-0.3,4.9
							c-0.1,1.6-0.2,3.3-0.3,4.9l-0.1,0.7l-0.2,0.6l-0.4,1.2l-0.7,2.4c-0.9,3.1-1.9,6.3-2.9,9.4l-1.5,4.7c-0.1,0.2-0.2,0.5-0.3,0.7
							l-0.3,0.5l-0.7,1l-1.3,2.1c-0.4,0.7-0.9,1.4-1.4,2.1s-1.1,1.3-1.6,1.9c-0.6,0.6-1.2,1.2-1.8,1.8c-0.6,0.6-1.2,1.1-2,1.6
							c-0.4,0.2-0.9,0.4-1.4,0.5s-1.1,0-1.5-0.2c-0.9-0.4-1.7-0.9-2.2-1.7c-0.6-0.8-0.9-1.7-0.9-2.6c0-0.5,0-0.9,0.1-1.4
							s0.3-0.9,0.4-1.3c1.3-3,2.6-6,3.9-9v0.1l1.6-8.4l1.7-8.4V82V70.2c0.1-2,0.1-3.9,0.3-5.9c0.1-1,0.1-2,0.3-2.9
							C137.8,60.6,138.2,59.6,138.8,58.8z"/>
					</g>
				</g>
        </g>

    )
}
