import React from 'react'
import { useSelector } from 'react-redux'

export const SuturePeau = (props) => {

	return (
		<g id="suturePeau" className="transition" style={{ opacity: props.hide ? 0 : props.show ? 1 : 0, transform: 'translate(0px, -15px)' }}>
			<defs>
				<rect id="SVGID_31_" x="0.2" y="21.7" width="333.5" height="229.4" />
			</defs>
			<clipPath id="SVGID_32_">
				<use href="#SVGID_31_"  style={{"overflow":"visible"}}/>
		</clipPath>
			<g class="st46">
				<defs>
					<rect id="SVGID_33_" x="0.2" y="21.7" width="333.5" height="229.4" />
				</defs>
				<clipPath id="SVGID_34_">
					<use href="#SVGID_33_"  style={{"overflow":"visible"}}/>
			</clipPath>
				<path class="st47" d="M167.2,98.2c0.1,1.1,0,10,0,10" />
				<path class="st48" d="M167.2,98.2c0.2,0.8,0.3,1.7,0.3,2.5l0.1,2.5c0.1,1.7,0.1,3.4,0.1,5l-1,0c0-1.7,0-3.3,0.1-5l0.1-2.5
				C166.9,99.9,167,99.1,167.2,98.2"/>
				<line class="st49" x1="166.9" y1="88.8" x2="166.9" y2="95.1" />
				<path class="st48" d="M166.9,88.8c0.2,1.1,0.3,2.1,0.4,3.2c0.1,1.1,0.2,2.1,0.1,3.2h-1c0-1.1,0.1-2.1,0.1-3.2
				C166.7,90.9,166.8,89.8,166.9,88.8"/>
				<path class="st47" d="M170.1,100.8c0,0.2,0.2,0.6,0.1,1.5c-0.1,0.9-0.9,2.9-1.4,3.9c-0.6,1-1.2,1.6-1.8,1.6
				c-0.7,0-1.3-0.6-1.8-1.6c-0.5-1-1.3-3.1-1.4-3.9c-0.1-0.8,0.1-1.3,0.2-1.5H170.1z"/>
				<path class="st48" d="M170.1,100.8c0.3,0.6,0.4,1.4,0.3,2.1c-0.1,0.7-0.3,1.4-0.5,2c-0.2,0.7-0.5,1.3-0.9,1.9
				c-0.2,0.3-0.4,0.6-0.7,0.9c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.3-1.2-0.5c-0.3-0.3-0.5-0.6-0.7-0.9c-0.4-0.6-0.6-1.3-0.8-2
				c-0.4-1.3-0.8-2.7-0.4-4.1H170.1z M170.1,100.8h-6.2c-0.2,0.7-0.2,1.4,0,2c0.2,0.6,0.5,1.3,0.8,1.9c0.3,0.6,0.6,1.2,1,1.7
				c0.4,0.5,0.9,0.9,1.2,0.9c0.4,0,0.8-0.4,1.2-0.9c0.4-0.5,0.6-1.1,0.9-1.7C169.6,103.4,170.3,102.2,170.1,100.8"/>
				<path class="st47" d="M166.9,99.2c2.1,0,3.1,1.6,3.1,1.6h-6.2C163.9,100.8,164.9,99.2,166.9,99.2" />
			</g>
			<g class="st46">
				<defs>
					<path id="SVGID_35_" d="M164.7,96.1l-0.8,3.7v1.1h6.1v-2.4l-0.4-2.4c-0.5-1-1.5-1.5-2.4-1.5C166.1,94.6,165.2,95.1,164.7,96.1"
					/>
				</defs>
				<clipPath id="SVGID_36_">
					<use href="#SVGID_35_"  style={{"overflow":"visible"}}/>
			</clipPath>

				<linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="2626.0337" y1="3541.0317" x2="2627.0337" y2="3541.0317" gradientTransform="matrix(-8.806430e-02 11.6245 11.6245 8.806430e-02 -40764.4531 -30749.7383)">
					<stop offset="0" style={{"stopColor":"#F9B233"}} />
					<stop offset="1" style={{"stopColor":"#E54D4F"}} />
				</linearGradient>
				<polygon class="st50" points="170,94.6 170,100.9 163.8,100.8 163.9,94.5 			" />
			</g>
			<g class="st46">
				<defs>
					<path id="SVGID_38_" d="M164.7,96.1l-1.3,5.8l2.2,6c0.6,1,2.1,1,2.7-0.1l2.2-6.6l-0.9-5.1c-0.5-1-1.5-1.5-2.4-1.5
					C166.1,94.6,165.2,95.1,164.7,96.1"/>
				</defs>
				<clipPath id="SVGID_39_">
					<use href="#SVGID_38_"  style={{"overflow":"visible"}}/>
			</clipPath>
				<defs>
					<filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="163.9" y="81.8" width="6.1" height="14.5">
						<feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
					</filter>
				</defs>
				<mask maskUnits="userSpaceOnUse" x="163.9" y="81.8" width="6.1" height="14.5" id="SVGID_40_">
					<g class="st51">
						<rect x="163.9" y="81.8" class="st52" width="6.1" height="14.5" />
						<g class="st53">
							<g>
								<defs>
									<path id="SVGID_41_" d="M167.6,81.8h-1.4c-1.3,0-2.4,1.1-2.4,2.4V94c0,1.3,1.1,2.4,2.4,2.4h1.4c1.3,0,2.4-1.1,2.4-2.4v-9.8
									C170,82.9,168.9,81.8,167.6,81.8"/>
								</defs>
								<clipPath id="SVGID_42_" class="st52">
									<use href="#SVGID_41_"  style={{"overflow":"visible"}}/>
							</clipPath>

								<linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="2645.3533" y1="3560.8813" x2="2646.3533" y2="3560.8813" gradientTransform="matrix(0 12.4952 12.4952 0 -44326.957 -32971.2852)">
									<stop offset="0" style={{"stopColor":"#FFFFFF"}} />
									<stop offset="1" style={{"stopColor":"#1D1D1B"}} />
								</linearGradient>
								<rect x="163.9" y="81.8" class="st54" width="6.1" height="14.5" />
							</g>
						</g>
					</g>
				</mask>
				<g class="st55">
					<g>
						<defs>
							<rect id="SVGID_44_" x="163.9" y="81.8" width="6.1" height="14.5" />
						</defs>
						<clipPath id="SVGID_45_">
							<use href="#SVGID_44_"  style={{"overflow":"visible"}}/>
					</clipPath>
						<g class="st56">
							<defs>
								<path id="SVGID_46_" d="M167.6,81.8h-1.4c-1.3,0-2.4,1.1-2.4,2.4V94c0,1.3,1.1,2.4,2.4,2.4h1.4c1.3,0,2.4-1.1,2.4-2.4v-9.8
								C170,82.9,168.9,81.8,167.6,81.8"/>
							</defs>
							<clipPath id="SVGID_47_">
								<use href="#SVGID_46_"  style={{"overflow":"visible"}}/>
						</clipPath>
							<defs>
								<filter id="Adobe_OpacityMaskFilter_1_" filterUnits="userSpaceOnUse" x="163.9" y="81.8" width="6.1" height="14.5">
									<feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
								</filter>
							</defs>
							<mask maskUnits="userSpaceOnUse" x="163.9" y="81.8" width="6.1" height="14.5" id="SVGID_40_">
								<g class="st57">

									<linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="2438.2454" y1="4389.1504" x2="2439.2454" y2="4389.1504" gradientTransform="matrix(5.968 0 0 -5.968 -14387.543 26283.3184)">
										<stop offset="0" style={{"stopColor":"#999999"}} />
										<stop offset="0.1417" style={{"stopColor":"#000000"}} />
										<stop offset="0.2983" style={{"stopColor":"#999999"}} />
										<stop offset="0.3962" style={{"stopColor":"#000000"}} />
										<stop offset="0.52" style={{"stopColor":"#999999"}} />
										<stop offset="0.6291" style={{"stopColor":"#000000"}} />
										<stop offset="0.7656" style={{"stopColor":"#999999"}} />
										<stop offset="0.8814" style={{"stopColor":"#000000"}} />
										<stop offset="1" style={{"stopColor":"#999999"}} />
									</linearGradient>
									<rect x="163.9" y="81.8" class="st58" width="6.1" height="14.5" />
								</g>
							</mask>

							<linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="2438.2456" y1="4389.1504" x2="2439.2456" y2="4389.1504" gradientTransform="matrix(5.968 0 0 -5.968 -14387.5439 26283.3203)">
								<stop offset="0" style={{"stopColor":"#FFFFFF"}} />
								<stop offset="0.1417" style={{"stopColor":"#FFFFFF"}} />
								<stop offset="0.2983" style={{"stopColor":"#FFFFFF"}} />
								<stop offset="0.3962" style={{"stopColor":"#FFFFFF"}} />
								<stop offset="0.52" style={{"stopColor":"#FFFFFF"}} />
								<stop offset="0.6291" style={{"stopColor":"#FFFFFF"}} />
								<stop offset="0.7656" style={{"stopColor":"#FFFFFF"}} />
								<stop offset="0.8814" style={{"stopColor":"#FFFFFF"}} />
								<stop offset="1" style={{"stopColor":"#FFFFFF"}} />
							</linearGradient>
							<rect x="163.9" y="81.8" class="st59" width="6.1" height="14.5" />
						</g>
					</g>
				</g>
			</g>
			<g class="st46">
				<defs>
					<rect id="SVGID_50_" x="0.2" y="21.7" width="333.5" height="229.4" />
				</defs>
				<clipPath id="SVGID_51_">
					<use href="#SVGID_50_"  style={{"overflow":"visible"}}/>
			</clipPath>
				<path class="st60" d="M99.9,113c-4.9-1.8-8.5-8.7-7.8-11.1c0.7-2.4-14.4,8.8-14.4,8.8l13.2,11.8L99.9,113z" />
				<path class="st61" d="M99.9,113c-4.9-1.8-8.5-8.7-7.8-11.1c0.7-2.4-14.4,8.8-14.4,8.8l13.2,11.8L99.9,113z" />
				<path class="st60" d="M66.5,90.7l2.8-7.3l1.4-3.7l0.7-1.8c0.2-0.6,0.5-1.2,0.7-1.9l0.2-1c0-0.1,0-0.2,0-0.3
				c-0.1-1.8-0.2-3.7-0.4-5.5l2.9-1.9l9.8,1.1c1.3,0.1,2.6-0.6,3.1-1.9c0.6-1.6-0.1-3.4-1.7-4.1l-11.5-5c-1.2-0.5-2.5-0.4-3.6,0.4
				l-0.4,0.3l-6.2,4.6c-0.9,0.6-1.5,1.6-1.8,2.7L62.4,66c-0.6,2.5-1.1,5-1.6,7.4l0.3-1.4c-0.3,0.6-0.4,1.3-0.6,1.9l-0.5,1.9l-1,3.8
				l-3.2,7.8c-0.3,1,0.1,2-0.1,2.9c-0.4,3.2-6.2,7,2.4,21.8c8.6,14.8,20.4,12.7,20.4,12.7l13.9,3.4l0.1,0c2.2,0.5,4.7-0.3,6.1-2.2
				l11.4-15.6l8.1-5c1.8-1.1,2.6-3.5,1.7-5.5c-1-2.1-3.6-3-5.8-2.2l-10.1,4c-0.5,0.2-0.9,0.5-1.3,0.9l-0.5,0.5l-4.8,5.4
				c0,0-3.4,4-4.7,5.8"/>
				<path class="st62" d="M66,90.5l4-10.6l1-2.7c0.2-0.4,0.3-0.9,0.5-1.3l0.2-0.7c0-0.2,0.1-0.5,0.1-0.6l-0.4-5.6l0-0.3l0.2-0.2
				l2.9-1.9l0.2-0.1l0.2,0l6.8,0.7l1.7,0.2l0.9,0.1l0.4,0c0.1,0,0.2,0,0.4,0c1,0,1.9-0.6,2.2-1.5c0.4-0.9,0.2-2-0.4-2.7
				c-0.3-0.4-0.7-0.6-1.2-0.8L84.1,62l-6.3-2.8l-3.2-1.4c-1-0.5-2-0.5-2.9-0.1c-0.4,0.2-0.9,0.6-1.3,0.9l-1.4,1l-2.8,2l-1.4,1
				c-0.5,0.3-0.8,0.6-1.1,1.1c-0.3,0.4-0.5,0.9-0.7,1.4c-0.1,0.5-0.3,1.1-0.4,1.7l-1.5,6.7l-1-0.2l0.3-1.3l0,0l1,0.3
				c-0.2,0.5-0.3,1.2-0.5,1.8l-0.4,1.9l-0.9,3.8l0,0l0,0.1c-1,2.6-2,5.2-3,7.8l0-0.1c-0.1,0.3-0.1,0.7,0,1.3c0,0.5,0.1,1.2,0,1.9
				c-0.3,1.3-0.9,2.2-1.2,3.2c-0.8,1.9-1,3.8-0.8,5.8c0.2,2,0.8,3.9,1.6,5.8c0.8,1.9,1.8,3.8,2.8,5.6c2,3.6,4.8,6.8,8.1,9.1
				c1.7,1.1,3.5,2,5.4,2.6c0.5,0.1,1,0.3,1.4,0.4c0.5,0.1,1,0.2,1.5,0.2c1,0.1,2,0.1,2.8,0l0.3,0l0.2,0.1l9.8,2.5l2.5,0.6
				c0.8,0.2,1.7,0.5,2.3,0.6c1.4,0.2,2.8-0.3,3.8-1.3c0.3-0.3,0.5-0.5,0.7-0.8l0.8-1l1.5-2l3.1-4c2.1-2.7,4.1-5.4,6.2-8l0,0l0.1-0.1
				l0.1,0l5.4-3.3l2.7-1.7c0.8-0.5,1.4-1.2,1.7-2.1c0.3-0.9,0.2-1.9-0.1-2.7c-0.4-0.8-1.1-1.5-1.9-1.9c-0.8-0.4-1.8-0.5-2.7-0.2
				c-0.4,0.1-0.9,0.3-1.4,0.5l-1.5,0.6l-5.9,2.3l-1.5,0.6c-0.5,0.2-0.9,0.4-1.3,0.7c-0.7,0.7-1.4,1.5-2.1,2.3
				c-1.4,1.6-2.9,3.1-4.2,4.7c-1.4,1.6-2.8,3.2-4.2,4.7c1.2-1.7,2.4-3.4,3.8-5.1c0.6-0.9,1.3-1.6,2-2.4l2.1-2.4l1-1.2
				c0.4-0.4,0.6-0.8,1.1-1.2c0.4-0.4,1-0.7,1.5-0.8l1.5-0.6l5.9-2.3l1.5-0.6c0.5-0.2,0.9-0.4,1.5-0.6c1.1-0.3,2.4-0.2,3.4,0.3
				c1.1,0.5,2,1.4,2.5,2.4c0.5,1.1,0.5,2.3,0.2,3.5c-0.4,1.1-1.1,2.1-2.2,2.7l-2.7,1.7l-5.4,3.3l0.1-0.1l0,0
				c-1.9,2.8-3.8,5.6-5.8,8.4l-2.9,4.2l-1.5,2.1l-0.7,1c-0.2,0.3-0.6,0.8-0.9,1.1c-1.4,1.4-3.5,2.2-5.5,2c-1.1-0.1-1.8-0.4-2.7-0.5
				l-2.5-0.6l-9.9-2.3l0.5,0c-1.3,0.2-2.4,0.1-3.6,0c-0.6-0.1-1.1-0.1-1.7-0.3c-0.6-0.1-1.1-0.2-1.7-0.4c-2.2-0.6-4.3-1.7-6.1-2.9
				c-3.7-2.6-6.6-6.2-8.8-10c-1.1-1.9-2.1-3.9-2.9-5.9c-0.8-2.1-1.5-4.2-1.6-6.5c-0.2-2.3,0.2-4.7,1.2-6.8c0.4-1,1-2,1.2-2.8
				c0.1-0.4,0.1-0.8,0.1-1.3c0-0.5-0.1-1.2,0.1-1.9l0-0.1l0,0c1.1-2.6,2.2-5.1,3.3-7.7l0,0.1l1.1-3.8l0.6-1.9
				c0.2-0.6,0.3-1.3,0.6-1.9l1.9-4.1l-0.9,4.4l0,0l-0.3,1.3l-1-0.2l1.5-6.7c0.1-0.6,0.2-1.1,0.4-1.7c0.2-0.6,0.5-1.2,0.8-1.7
				c0.4-0.5,0.9-1,1.3-1.3l1.4-1l2.8-2l1.4-1c0.5-0.3,0.9-0.7,1.5-1c1.2-0.6,2.7-0.5,3.7,0.1l3.2,1.4l6.3,2.8l1.6,0.7
				c0.6,0.2,1.2,0.6,1.6,1.1c0.9,1,1.1,2.5,0.6,3.8c-0.5,1.2-1.8,2.1-3.1,2.2c-0.2,0-0.3,0-0.5,0l-0.4,0l-0.9-0.1l-1.7-0.2l-6.8-0.7
				l0.3-0.1l-2.9,1.9l0.2-0.5c0.1,1.9,0.3,3.7,0.4,5.7c0,0.4-0.1,0.5-0.1,0.8l-0.2,0.7c-0.1,0.5-0.3,1-0.5,1.4l-1,2.6l-4,10.6
				L66,90.5z"/>
				<path class="st60" d="M69.6,97.1l9.3-11.3L89.3,80l10.2,2.9l2.1,4.8c0.2,0.5,0.7,0.9,1.3,0.9c0.9,0.1,1.6-0.6,1.7-1.4l0.6-7.7
				c0.1-0.9-0.4-1.8-1.2-2.2l-0.9-0.4l-12.6-6.2c-0.7-0.3-1.6-0.5-2.4-0.2l-1,0.3l-15,3.9c-0.7,0.2-1.4,0.6-2,1.2l-0.6,0.6L59,88
				c-0.1,0.1-0.2,0.2-0.3,0.3c-1.2,1.5-1.7,3.3-1.5,5.1"/>
				<path class="st62" d="M69.2,96.7l9.3-11.3l0.1-0.1l0.1,0L89,79.6l0.2-0.1l0.2,0.1l10.2,2.9l0.2,0.1l0.1,0.2l0,0l2,4.5l0.1,0.3
				c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.5,0.2,0.8,0.1c0.3-0.1,0.5-0.2,0.6-0.4c0.2-0.2,0.2-0.4,0.2-0.9
				l0.3-4.9l0.2-2.4c0-0.6-0.3-1.2-0.8-1.5L95,73.6l-4.4-2.1c-0.4-0.2-0.7-0.3-1-0.4c-0.3-0.1-0.7-0.1-1-0.1c-0.3,0-0.7,0.2-1.1,0.3
				l-1.2,0.3L76.8,74l-4.7,1.2c-0.7,0.2-1.3,0.6-1.8,1.2l-1.7,1.8L62,85.4c-0.6,0.6-1.1,1.2-1.7,1.8c-0.6,0.6-1.2,1.1-1.6,1.8
				c-0.9,1.2-1.5,2.8-1.5,4.4c-0.3-1.6,0-3.4,0.9-4.8c0.5-0.7,1-1.3,1.6-1.9c0.5-0.6,1.1-1.2,1.6-1.8l6.6-7.3l1.6-1.8
				c0.5-0.7,1.4-1.3,2.3-1.5l4.7-1.3l9.5-2.5l1.2-0.3c0.4-0.1,0.8-0.2,1.3-0.3c0.5-0.1,1,0,1.4,0.1c0.5,0.1,0.9,0.3,1.2,0.5l4.4,2.1
				l8.8,4.3c0.9,0.5,1.5,1.5,1.4,2.6l-0.2,2.5l-0.4,4.9c0,0.2,0,0.4-0.1,0.7c-0.1,0.3-0.2,0.5-0.3,0.8c-0.3,0.5-0.8,0.8-1.3,0.9
				c-0.5,0.1-1.1,0-1.6-0.3c-0.2-0.2-0.4-0.3-0.6-0.6c-0.1-0.1-0.1-0.2-0.2-0.4l-0.1-0.3l-2-4.5l0,0l0.3,0.3l-10.2-2.9l0.4,0
				l-10.5,5.7l0.1-0.1l-9.3,11.3L69.2,96.7z"/>
				<path class="st60" d="M76.3,104.7l6.8-7.3L97.7,91l11.7-0.3l0.9,1.5c0.4,0.7,1.2,1.2,2.1,1.3c1.4,0.1,2.6-1,2.7-2.5l0.2-5.3
				c0.1-1.2-0.8-2.4-2-2.7l-1.5-0.4L96.9,79c-0.7-0.2-1.4-0.1-2.1,0.1L94,79.4l-17.4,6.2c-0.8,0.3-1.6,0.8-2.3,1.5l-0.1,0.2
				l-7.7,8.3l0,0c-1.1,1.1-1.7,2.6-1.8,4"/>
				<path class="st62" d="M76,104.4l6.7-7.3l0.1-0.1l0.1,0l14.6-6.5l0.1,0l0.1,0l11.7-0.3l0.4,0l0.2,0.3l0.6,1l0.3,0.5
				c0.1,0.1,0.2,0.3,0.3,0.4c0.4,0.4,1,0.7,1.6,0.6c0.6,0,1.2-0.4,1.5-0.8c0.2-0.2,0.3-0.5,0.3-0.8l0-0.2l0-0.3l0-0.6l0.2-4.6
				c0-0.6-0.3-1.2-0.8-1.5c-0.2-0.2-0.5-0.3-0.8-0.4l-1.1-0.3l-9.1-2.2l-4.5-1.1l-1.1-0.3l-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.1
				c-0.7-0.1-1.2,0.1-2,0.4l-17.5,6.3c-0.7,0.2-1.3,0.6-1.8,1.1c-0.5,0.5-1.1,1.1-1.6,1.7l-3.2,3.4l-3.2,3.4c-1,1.1-1.7,2.3-2.3,3.8
				c-0.3-1.6,0.4-3.3,1.5-4.5l3.2-3.4l3.2-3.4c0.5-0.6,1-1.1,1.6-1.7c0.6-0.6,1.3-1,2.1-1.3l17.5-6.4c0.4-0.1,0.7-0.3,1.2-0.4
				c0.4-0.1,0.9-0.1,1.3-0.1c0.2,0,0.5,0.1,0.7,0.1l0.6,0.1l1.1,0.3l4.5,1.1l9.1,2.2l1.1,0.3c0.4,0.1,0.9,0.3,1.3,0.6
				c0.8,0.6,1.3,1.5,1.3,2.5l-0.2,4.7l0,0.6l0,0.3l0,0.4c-0.1,0.5-0.3,0.9-0.5,1.3c-0.5,0.8-1.5,1.3-2.4,1.4c-1,0.1-1.9-0.3-2.6-1
				c-0.2-0.2-0.3-0.4-0.4-0.6l-0.3-0.5l-0.6-1l0.6,0.3l-11.7,0.3l0.2-0.1l-14.7,6.4l0.2-0.1l-6.8,7.2L76,104.4z"/>
				<path class="st62" d="M237.6,89.7c-1.3-1.5-3.1-2.6-5.1-2.9c-2-0.4-4.1-0.2-6.1,0.4c-2,0.6-3.8,1.7-5.4,3
				c-1.6,1.3-3.1,2.8-4.5,4.4c-5.4,6.5-11.2,12.8-18,17.8c-6.7,5.2-14.2,9.3-22.2,12.3c-4,1.4-8.1,2.6-12.2,3.4
				c-1,0.2-2.1,0.3-3.1,0.5l-1.6,0.2c-0.5,0.1-1.1,0.1-1.6,0.2l-3.2,0.3l-3.2,0.1c-4.2,0.1-8.5,0-12.7-0.6
				c-4.2-0.6-8.3-1.9-12.1-3.9c-3.7-2-6.9-5-9.4-8.4c-0.6-0.9-1.2-1.8-1.7-2.7c-0.5-0.9-1-1.9-1.4-2.9c-0.8-2-1.2-4.1-1-6.3
				c0.2-2.1,0.9-4.2,2.2-6c1.2-1.8,2.9-3.2,4.7-4.3c1.8-1.1,3.8-2,5.9-2.5c2.1-0.5,4.3-0.7,6.4-0.1c2.1,0.7,3.9,1.8,5.7,3
				c1.8,1.2,3.4,2.6,4.8,4.3c1.4,1.6,2.8,3.4,3.3,5.6c0.3,1.1,0.2,2.3-0.2,3.4c-0.4,1.1-1.3,1.9-2.3,2.4c0.7-0.8,1.2-1.7,1.4-2.7
				c0.2-0.9,0.1-1.9-0.1-2.9c-0.5-1.9-1.5-3.8-2.8-5.4c-1.3-1.6-2.8-3.1-4.5-4.3c-1.7-1.3-3.5-2.4-5.4-3.1c-2-0.6-4.1-0.4-6.2,0
				c-2,0.5-4,1.3-5.8,2.4c-1.8,1.1-3.4,2.5-4.6,4.2c-1.2,1.7-2,3.7-2.1,5.8c-0.1,2.1,0.3,4.2,1,6.1c0.4,1,0.8,1.9,1.4,2.8
				c0.5,0.9,1.2,1.8,1.7,2.6c2.5,3.4,5.6,6.3,9.3,8.4c3.7,2,7.8,3.3,11.9,3.9c4.2,0.6,8.4,0.7,12.6,0.6l3.2-0.1l3.2-0.3
				c0.5-0.1,1.1-0.1,1.6-0.2l1.6-0.2c1-0.2,2.1-0.3,3.1-0.5c4.1-0.8,8.2-2,12.2-3.4c7.9-3,15.4-7.1,22.1-12.2l1.3-0.9l1.2-1l2.4-2
				c1.5-1.4,3.2-2.8,4.6-4.4c3-3,5.6-6.3,8.4-9.5c1.4-1.6,2.8-3.2,4.5-4.5c1.6-1.4,3.5-2.5,5.6-3.2c2.1-0.7,4.3-0.9,6.4-0.6
				c2.2,0.3,4.3,1.4,5.8,3.1L237.6,89.7z"/>
				<path class="st60" d="M256.9,162l35.3,8.9c0,0,25.8,19.9,29.9,25.4c4.1,5.5,5.7,29.2,0.2,36.5c-5.5,7.3-16.7,20.6-29.5,17.1
				c-12.7-3.4-25.7-7.5-25.7-7.5s-7.9,1.3-10-0.4c-2.1-1.7-10.6-5.9-10.8-10.9C246,226,256.9,162,256.9,162"/>
				<path class="st63" d="M256.9,162l35.3,8.9c0,0,25.8,19.9,29.9,25.4c4.1,5.5,5.7,29.2,0.2,36.5c-5.5,7.3-16.7,20.6-29.5,17.1
				c-12.7-3.4-25.7-7.5-25.7-7.5s-7.9,1.3-10-0.4c-2.1-1.7-10.6-5.9-10.8-10.9C246,226,256.9,162,256.9,162z"/>
				<path class="st60" d="M279.7,232.2c0-3.2-2.6-5.7-5.7-5.7l-13,0l-4.2-1.6l-0.5-3.7l0,0c-0.5-3.6-3.7-6.1-7.3-5.6
				c-3.6,0.5-6.1,3.7-5.6,7.3l1,7.6l0,0.3c0.3,2.3,2,4.4,4.4,5l9.2,2.5l0.5,0.1c0.5,0.1,1,0.2,1.5,0.2l14.2-0.7
				C277.3,237.8,279.7,235.3,279.7,232.2"/>
				<path class="st62" d="M279.2,232.2c0-1.7-0.8-3.4-2.2-4.4c-0.7-0.5-1.5-0.8-2.4-0.9c-0.8-0.1-1.8,0-2.7-0.1
				c-3.7,0-7.3-0.1-11-0.1h0h0l0,0l-4.2-1.6l0,0l-0.1,0l0-0.1c-0.2-1.5-0.3-3.1-0.6-4.6c-0.4-1.5-1.3-2.8-2.6-3.6
				c-1.3-0.8-2.8-1.2-4.3-1c-1.5,0.2-2.9,0.9-3.9,2c-1,1.1-1.6,2.6-1.6,4c0,0.4,0,0.7,0.1,1.1l0.2,1.1l0.4,2.3l0.7,4.6
				c0.2,1.4,1,2.7,2.1,3.5c0.6,0.4,1.2,0.7,1.9,0.9l2.2,0.6l4.5,1.2l2.2,0.6c0.8,0.2,1.4,0.4,2.1,0.4l4.6-0.2l9.2-0.4
				c1.5,0,2.9-0.5,3.9-1.5C278.6,235,279.2,233.6,279.2,232.2 M280.1,232.2c0,1.7-0.7,3.3-1.9,4.5c-0.6,0.6-1.3,1-2.1,1.3
				c-0.8,0.3-1.7,0.4-2.4,0.4l-9.2,0.5l-4.6,0.2c-0.4,0-0.9,0-1.3-0.1c-0.4-0.1-0.8-0.2-1.1-0.3l-2.2-0.6l-4.5-1.2l-2.2-0.6
				c-0.8-0.2-1.6-0.6-2.2-1.1c-1.3-1-2.2-2.6-2.4-4.2l-0.6-4.6l-0.3-2.3l-0.1-1.1c0-0.4-0.1-0.8,0-1.2c0.1-1.6,0.8-3.2,1.9-4.3
				c1.1-1.1,2.6-1.8,4.2-2c1.6-0.2,3.2,0.3,4.4,1.2c1.3,0.9,2.2,2.2,2.6,3.7c0.2,0.8,0.3,1.5,0.4,2.3l0.3,2.3l-0.1-0.1l0,0l4.2,1.5
				l-0.1,0l0,0c3.7-0.1,7.3-0.1,11-0.2c0.9,0,1.8-0.1,2.8,0c1,0.1,1.9,0.5,2.7,1C279,228.3,280.1,230.2,280.1,232.2"/>
				<path class="st60" d="M283.3,230.1c1.5-3.6-0.2-7.8-3.8-9.3c-2.3-0.9-4.5-1.9-6.8-2.7c-2-0.8-4-1.5-6.1-2.3
				c-2.9-1.5-5.7-2.9-8.6-4.4c-0.6-0.3-1.1-0.6-1.7-0.8l0.8-1.8c1.3-2.9,0.5-6.5-2.2-8.5c-3.1-2.3-7.5-1.7-9.9,1.4l-6.5,8.6
				c-0.3,0.4-0.6,0.8-0.8,1.3c-1.8,3.7-0.2,8.2,3.5,10l0.8,0.4c3,1.4,5.9,2.8,8.9,4.2c3,1.4,6,2.7,9,4.1l0.5,0.2
				c0.2,0.1,0.3,0.1,0.5,0.2c2.4,0.6,4.7,1.3,7.1,1.9c2.4,0.6,4.7,1.2,7.1,1.8C278.4,235.1,281.9,233.4,283.3,230.1"/>
				<path class="st62" d="M282.9,229.9c0.8-1.8,0.7-3.9-0.2-5.7c-0.5-0.9-1.1-1.7-1.9-2.3c-0.8-0.6-1.7-1-2.7-1.4l-5.8-2.4l-5.8-2.3
				l0,0l0,0l-5.1-2.7c-1.7-0.9-3.4-1.8-5.1-2.6l0,0l-0.1-0.1l0-0.1c0.4-0.9,0.8-1.8,1.1-2.7c0.3-0.9,0.3-1.9,0.2-2.8
				c-0.3-1.9-1.3-3.6-2.9-4.6c-1.5-1.1-3.5-1.4-5.3-1c-1.8,0.4-3.4,1.5-4.4,3.1l-3.5,4.7l-1.7,2.3c-0.3,0.4-0.6,0.8-0.9,1.2
				c-0.2,0.4-0.5,0.8-0.7,1.2c-0.8,1.7-0.9,3.6-0.2,5.3c0.6,1.7,1.9,3.2,3.5,3.9l10.5,4.9l5.3,2.4l2.6,1.2c0.4,0.2,0.9,0.4,1.2,0.5
				l1.4,0.4l5.6,1.5l5.6,1.5l1.4,0.4c0.5,0.1,0.9,0.2,1.3,0.2c0.9,0,1.8-0.1,2.7-0.4C280.8,233,282.2,231.6,282.9,229.9
				 M283.7,230.3c-0.8,1.9-2.3,3.5-4.3,4.2c-1,0.4-2,0.5-3,0.5c-0.5,0-1-0.1-1.5-0.2l-1.4-0.3l-5.7-1.4l-5.6-1.5l-1.4-0.4
				c-0.6-0.2-1-0.4-1.4-0.6l-2.6-1.2l-5.3-2.4l-10.5-5c-1.9-0.9-3.3-2.6-4-4.5c-0.7-1.9-0.6-4.1,0.3-6c0.2-0.4,0.5-0.9,0.8-1.3
				c0.3-0.4,0.6-0.8,0.9-1.2l1.8-2.3l3.5-4.6c0.6-0.8,1.2-1.6,2.1-2.2c0.8-0.6,1.8-1,2.8-1.2c2-0.4,4.1,0.1,5.7,1.3
				c1.6,1.2,2.7,3,3,5c0.1,1,0,2-0.2,2.9c-0.3,1-0.8,1.8-1.1,2.7l-0.1-0.2l0,0c1.7,0.8,3.5,1.7,5.2,2.6l5.2,2.6l0,0l5.9,2.2l5.8,2.3
				c1,0.4,2,0.7,2.9,1.4c0.9,0.6,1.6,1.5,2.1,2.5C284.4,225.8,284.5,228.2,283.7,230.3"/>
				<path class="st60" d="M261.6,189.7l-2.2,2l-1.5,1.4c-2.2-1.9-4.9-2.9-7.6-2.3c-5.1,1.1-8.1,7.2-6.7,13.7c0.4,2.1,1.3,3.9,2.4,5.5
				c-1.3-0.9-2.7-1.8-4-2.7c-2.4-1.6-4.7-3.2-7-4.3c-6.5-3.3-5-15.1,1.6-18c0.5-0.2,6.2-2.8,11.3-4.9c1.4-0.6,2.8-1.2,4-1.7
				c2.1-0.9,3.6-1.4,3.7-1.2"/>
				<path class="st62" d="M261.6,189.7c-1.2,1.2-2.4,2.4-3.6,3.6l-0.1,0.1l-0.1-0.1c-1.9-1.7-4.5-2.7-7-2.2c-2.5,0.4-4.6,2.2-5.8,4.4
				c-1.2,2.2-1.6,4.8-1.3,7.3c0.3,2.5,1.2,5,2.7,7l1.5,2.1l-2.1-1.4c-2.4-1.6-4.8-3.3-7.2-4.8c-1.2-0.8-2.4-1.5-3.7-2.1
				c-1.4-0.7-2.5-1.9-3.3-3.2c-0.8-1.3-1.1-2.9-1.2-4.4c-0.1-1.5,0.1-3,0.5-4.5c0.8-2.9,2.7-5.6,5.6-7l0,0l9.4-4.1
				c1.6-0.7,3.2-1.3,4.8-1.9c0.8-0.3,1.6-0.6,2.5-0.8c0.4-0.1,0.8-0.2,1.3-0.3c0.4-0.1,0.9-0.2,1.3-0.1c-0.2,0-0.4,0.1-0.6,0.2
				l-0.6,0.3l-1.1,0.6l-2.3,1.1c-1.5,0.7-3.1,1.4-4.7,2.1l-9.5,4.1l0,0c-2.4,1.1-4.1,3.6-4.9,6.2c-0.4,1.3-0.6,2.7-0.5,4.1
				c0.1,1.4,0.4,2.7,1,3.9c0.6,1.2,1.6,2.2,2.8,2.8c1.3,0.7,2.6,1.4,3.8,2.2c2.4,1.6,4.8,3.3,7.1,4.9l-0.6,0.6
				c-1.6-2.2-2.5-4.8-2.7-7.4c-0.3-2.6,0.2-5.4,1.5-7.7c0.6-1.2,1.5-2.2,2.6-3c1.1-0.8,2.3-1.4,3.7-1.6c1.3-0.2,2.7,0,3.9,0.4
				c1.3,0.5,2.4,1.2,3.4,2.1l-0.2,0C259,191.9,260.3,190.7,261.6,189.7"/>
				<path class="st60" d="M275.5,166.8c-5.3-4-16.8-7.8-22.8-10.4c-6.1-1.7-12.6-2.6-18.9-2c-4.4,0.4-10.5-0.3-12.1,5.1
				c-1.1,3.5,0.8,7.2,4.3,8.3c9.7,2.3,17.7,7.5,26.6,10.6c2.5,0.9,9.8-4.9,10.3-6.7"/>
				<path class="st62" d="M275.2,167.3c-1.6-1.2-3.5-2.2-5.3-3.1c-1.9-0.9-3.8-1.7-5.7-2.5c-3.9-1.6-7.8-3-11.7-4.7l0.1,0
				c-3.5-1-7-1.7-10.6-2c-1.8-0.2-3.6-0.2-5.4-0.2c-0.9,0-1.8,0.1-2.7,0.1c-0.9,0.1-1.8,0.1-2.8,0.1c-1.8,0.1-3.6,0.2-5.2,0.7
				c-1.6,0.5-2.9,1.7-3.5,3.3c-0.7,1.6-0.6,3.4,0.1,4.9c0.7,1.5,2.1,2.7,3.7,3.2l0,0c3.4,0.8,6.7,2,9.9,3.4c3.2,1.3,6.3,2.9,9.4,4.3
				c1.6,0.7,3.1,1.4,4.7,2c0.8,0.3,1.6,0.6,2.4,0.9c0.7,0.3,1.5,0.1,2.2-0.2c1.6-0.6,3-1.5,4.4-2.5c0.7-0.5,1.4-1,2-1.6
				c0.6-0.6,1.3-1.1,1.7-1.9c-0.3,0.8-0.9,1.5-1.5,2.1c-0.6,0.6-1.2,1.2-1.9,1.8c-1.3,1.1-2.8,2.1-4.5,2.8c-0.4,0.2-0.9,0.3-1.3,0.4
				c-0.2,0-0.5,0.1-0.7,0c-0.3,0-0.6-0.1-0.7-0.2c-0.8-0.3-1.6-0.6-2.4-0.9c-1.6-0.6-3.2-1.3-4.8-2c-3.2-1.4-6.3-2.9-9.4-4.2
				c-3.2-1.3-6.4-2.5-9.7-3.3l0,0l0,0c-1.9-0.6-3.5-2-4.3-3.8c-0.9-1.8-0.9-3.9-0.2-5.7c0.6-1.9,2.3-3.4,4.1-4
				c1.8-0.6,3.7-0.7,5.5-0.8c0.9,0,1.8-0.1,2.7-0.2c0.9-0.1,1.8-0.1,2.8-0.2c1.8-0.1,3.7,0,5.5,0.2c3.7,0.3,7.3,1,10.9,2l0,0l0,0
				c3.8,1.6,7.8,3.1,11.6,4.7c1.9,0.8,3.9,1.7,5.8,2.6c1.9,0.9,3.8,2,5.5,3.2L275.2,167.3z"/>
				<path class="st64" d="M253.6,214.4c-2.9-0.1-5.6-1.7-7.6-4.4c-1.1-1.5-2-3.4-2.4-5.5c-1.4-6.5,1.6-12.6,6.7-13.7
				c2.7-0.6,5.4,0.4,7.6,2.3c1.5,1.3,2.8,3.2,3.6,5.3c0.8,0.2,1.6,0.4,2.3,0.6c-0.5-1.4-1.2-2.8-2.3-4.3c-0.7-1-1.4-2-2.1-3
				c-2.9-4.2-5.5-8.7-7.5-13.3c-1.6-3.9-2.8-7.9-3-12c-0.5-8.1-2.5-13.9-1.3-15.4c1.2-1.6,8.9-8.2,7.8-9
				c-1.9-1.5-40.7-78.2-46.4-75.8l29.4,60l-3.3,3.1c0,0,8.9,22,10.6,35.9c1.1,8.9,2.1,12.4,2.2,15c0.1,1.5-0.2,2.7-0.8,4.5
				c-1.8,5-8.8,12.8-5.4,21.8c0.1,0.3,0.2,0.6,0.4,0.9c3.7,8.8,11.4,11.2,14.6,9.2c0.3-0.2,0.6-0.4,1-0.7
				C256.3,215.5,254.9,215,253.6,214.4"/>
				<path class="st61" d="M253.6,214.4c-2.9-0.1-5.6-1.7-7.6-4.4c-1.1-1.5-2-3.4-2.4-5.5c-1.4-6.5,1.6-12.6,6.7-13.7
				c2.7-0.6,5.4,0.4,7.6,2.3c1.5,1.3,2.8,3.2,3.6,5.3c0.8,0.2,1.6,0.4,2.3,0.6c-0.5-1.4-1.2-2.8-2.3-4.3c-0.7-1-1.4-2-2.1-3
				c-2.9-4.2-5.5-8.7-7.5-13.3c-1.6-3.9-2.8-7.9-3-12c-0.5-8.1-2.5-13.9-1.3-15.4c1.2-1.6,8.9-8.2,7.8-9
				c-1.9-1.5-40.7-78.2-46.4-75.8l29.4,60l-3.3,3.1c0,0,8.9,22,10.6,35.9c1.1,8.9,2.1,12.4,2.2,15c0.1,1.5-0.2,2.7-0.8,4.5
				c-1.8,5-8.8,12.8-5.4,21.8c0.1,0.3,0.2,0.6,0.4,0.9c3.7,8.8,11.4,11.2,14.6,9.2c0.3-0.2,0.6-0.4,1-0.7
				C256.3,215.5,254.9,215,253.6,214.4z"/>
				<path class="st60" d="M314.5,202.7c-7.9,8.9-16.5-1.8-22.8-9.8c0,0,0,0,0,0c-1.9-2.3-3.6-4.4-5-5.7c-0.6-0.8-1.2-1.7-1.7-2.7
				c0.5,0.8,1.1,1.6,1.8,2.3c4.5,4.8,11.3,5.8,15.1,2.3c2.2-2,2.9-5.1,2.3-8.2c0.8,0.7,1.7,1.5,2.7,2.3"/>
				<path class="st62" d="M314.5,202.7c-0.7,1-1.6,1.9-2.6,2.6c-1,0.7-2.3,1.1-3.6,1.2c-1.3,0.1-2.6-0.2-3.8-0.6
				c-1.2-0.5-2.3-1.1-3.3-1.9c-2-1.5-3.8-3.2-5.4-5c-1.7-1.8-3.2-3.7-4.7-5.6L291,193c0,0.1,0,0.1,0.1,0.3c0,0,0.1,0.1,0.1,0.1
				c0,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.1,0.6,0c0.3-0.1,0.3-0.2,0.4-0.3l-1.2,0c-0.8-1-1.6-2-2.4-2.9c-0.8-0.9-1.6-1.9-2.5-2.7l0,0
				l0-0.1c-0.7-0.9-1.3-1.9-1.8-2.9l1.4-0.8c0.6,1,1.3,1.9,2.1,2.6c0.8,0.8,1.7,1.5,2.7,2.1c1.9,1.2,4.2,1.8,6.4,1.7
				c1.1-0.1,2.2-0.4,3.1-0.9c1-0.5,1.8-1.3,2.4-2.2c1.2-1.8,1.7-4.2,1.4-6.5l-0.1-1l0.7,0.7c0.4,0.4,0.8,0.9,1.2,1.3
				c0.4,0.4,0.8,0.9,1.2,1.3c-0.5-0.3-1-0.7-1.5-1c-0.5-0.3-1-0.7-1.4-1l0.6-0.3c0.6,2.4,0.4,5.1-1,7.3c-0.7,1.1-1.7,2-2.8,2.7
				c-1.1,0.6-2.4,1-3.7,1.1c-2.6,0.2-5.2-0.5-7.3-1.8c-1.1-0.6-2.1-1.4-3-2.3c-0.9-0.9-1.7-1.9-2.4-2.9l1.4-0.8
				c0.5,0.9,1,1.8,1.6,2.6l-0.1-0.1c1,0.9,1.8,1.9,2.6,2.9c0.8,1,1.6,2,2.4,3l-1.2,0c0.1-0.1,0-0.1,0.4-0.3c0.2-0.1,0.4-0.1,0.6,0.1
				c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.1,0.2,0.1,0.3l-0.1-0.3c1.5,1.9,3,3.8,4.6,5.6c1.6,1.8,3.3,3.5,5.1,4.9
				c1.9,1.4,4,2.6,6.3,2.6C310.6,205.7,312.8,204.3,314.5,202.7"/>
				<path class="st60" d="M277.7,216.5c-3.8,4.4-14.7,0.1-19.5-0.6c-0.2,0-0.3-0.1-0.5-0.1c-1.4-0.3-2.7-0.8-4.1-1.4
				c-2.6-1.1-5.1-2.7-7.6-4.4c-1.1-1.5-2-3.4-2.4-5.5c-1.4-6.5,1.6-12.6,6.7-13.7c2.7-0.6,5.4,0.4,7.6,2.3l-1.6,1.4l4.5,3.8
				c0.2,0,0.4,0.1,0.6,0.1c0.8,0.2,1.6,0.4,2.3,0.6c4.9,1.4,9.4,3.9,13.5,6.9C280.2,208.4,280.1,213.5,277.7,216.5"/>
				<path class="st62" d="M278.1,216.8c-1,1.2-2.6,1.8-4,2c-1.5,0.2-2.9,0.1-4.4,0c-2.9-0.3-5.6-1-8.4-1.7c-0.7-0.2-1.4-0.3-2.1-0.5
				c-0.3-0.1-0.7-0.1-1-0.2c-0.3-0.1-0.7-0.1-1.1-0.2c-1.4-0.3-2.8-0.9-4.1-1.5c-2.6-1.2-5-2.8-7.4-4.3l-0.1-0.1l-0.1-0.1
				c-1.6-2.2-2.5-4.8-2.8-7.5c-0.3-2.7,0.2-5.4,1.6-7.8c1.3-2.3,3.6-4.2,6.3-4.6c1.3-0.2,2.7-0.1,4,0.3c1.3,0.4,2.5,1.1,3.5,2
				l0.4,0.4l-0.4,0.4l-1.6,1.4l0-0.8l4.5,3.8l-0.2-0.1c0.6,0.1,1.2,0.3,1.9,0.4c0.6,0.2,1.2,0.3,1.8,0.5c1.2,0.4,2.4,0.8,3.6,1.3
				c2.3,1,4.5,2.2,6.7,3.6c1.1,0.7,2.1,1.4,3.1,2.2c1,0.9,1.6,2.2,1.9,3.5C280.4,211.9,279.8,214.7,278.1,216.8 M277.3,216.2
				c1.4-1.8,2-4.4,1.5-6.6c-0.3-1.1-0.8-2.2-1.6-3c-0.9-0.7-2-1.4-3-2.1c-2.1-1.3-4.2-2.5-6.5-3.5c-1.1-0.5-2.3-0.9-3.5-1.3
				c-0.6-0.2-1.2-0.3-1.8-0.5c-0.6-0.1-1.2-0.3-1.8-0.4l-0.1,0l-0.1-0.1l-4.5-3.8l-0.4-0.4l0.4-0.4l1.6-1.4l0,0.7
				c-0.9-0.8-2-1.5-3.1-2c-1.1-0.5-2.4-0.7-3.7-0.5c-1.2,0.2-2.4,0.7-3.4,1.5c-1,0.8-1.7,1.8-2.3,2.9c-1.1,2.2-1.5,4.8-1.3,7.3
				c0.2,2.5,1.1,4.9,2.6,7l-0.1-0.1c2.3,1.6,4.7,3.1,7.2,4.2c1.3,0.6,2.6,1.1,3.9,1.4c0.4,0.1,0.7,0.1,1,0.2
				c0.4,0.1,0.7,0.1,1.1,0.2c0.7,0.1,1.4,0.3,2.1,0.5c2.7,0.7,5.5,1.4,8.2,1.7c1.4,0.1,2.8,0.2,4.1,0
				C275.2,217.7,276.5,217.2,277.3,216.2"/>
				<path class="st64" d="M306.9,183.1c0-3.3-1.2-6.9-4.1-10.2c-1-1.1-2-2-3.1-2.8c0.4,1.2,0.7,2.4,0.9,3.6c0,0,0,0,0.1,0
				c2,2.1,3.2,4.6,3.6,7c0.6,3.1-0.2,6.2-2.3,8.2c-3.8,3.6-10.6,2.6-15.1-2.3c-0.7-0.7-1.3-1.5-1.8-2.3c-1.3-2.1-1.9-4.3-2-6.4
				c-0.7-4.7,0-9.6,3-12.2c-1.6-0.2-3.1-0.4-4.3-0.7c-5.1-1.3-4.8-2.8-15.2-12.2c-10.5-9.3-22-28.9-22-28.9l-5.7-0.7L209,66.1
				c-4.9,2.5,33.8,79.7,32.8,81.7c-0.7,1.3,9.9-1.1,11.9-1.2c2-0.1,5.6,4.8,12,9.8c8.1,6.3,12.4,16.6,16.1,26.6
				c2.3,6.2,6.1,8.9,9.9,10c0,0,0,0,0,0c4.8,1.3,9.5,0,10.8-0.7C305,191,306.9,187.4,306.9,183.1"/>
				<path class="st61" d="M306.9,183.1c0-3.3-1.2-6.9-4.1-10.2c-1-1.1-2-2-3.1-2.8c0.4,1.2,0.7,2.4,0.9,3.6c0,0,0,0,0.1,0
				c2,2.1,3.2,4.6,3.6,7c0.6,3.1-0.2,6.2-2.3,8.2c-3.8,3.6-10.6,2.6-15.1-2.3c-0.7-0.7-1.3-1.5-1.8-2.3c-1.3-2.1-1.9-4.3-2-6.4
				c-0.7-4.7,0-9.6,3-12.2c-1.6-0.2-3.1-0.4-4.3-0.7c-5.1-1.3-4.8-2.8-15.2-12.2c-10.5-9.3-22-28.9-22-28.9l-5.7-0.7L209,66.1
				c-4.9,2.5,33.8,79.7,32.8,81.7c-0.7,1.3,9.9-1.1,11.9-1.2c2-0.1,5.6,4.8,12,9.8c8.1,6.3,12.4,16.6,16.1,26.6
				c2.3,6.2,6.1,8.9,9.9,10c0,0,0,0,0,0c4.8,1.3,9.5,0,10.8-0.7C305,191,306.9,187.4,306.9,183.1z"/>
				<line class="st64" x1="213.4" y1="76.6" x2="215.3" y2="75.7" />
				<line class="st61" x1="213.4" y1="76.6" x2="215.3" y2="75.7" />
				<line class="st64" x1="214.1" y1="78.1" x2="215.9" y2="77.1" />
				<line class="st61" x1="214.1" y1="78.1" x2="215.9" y2="77.1" />
				<line class="st64" x1="214.8" y1="79.4" x2="216.6" y2="78.5" />
				<line class="st61" x1="214.8" y1="79.4" x2="216.6" y2="78.5" />
				<line class="st64" x1="215.4" y1="80.7" x2="217.3" y2="79.7" />
				<line class="st61" x1="215.4" y1="80.7" x2="217.3" y2="79.7" />
				<line class="st64" x1="212.7" y1="75.3" x2="214.6" y2="74.3" />
				<line class="st61" x1="212.7" y1="75.3" x2="214.6" y2="74.3" />
				<line class="st64" x1="216.8" y1="83.4" x2="218.7" y2="82.5" />
				<line class="st61" x1="216.8" y1="83.4" x2="218.7" y2="82.5" />
				<line class="st64" x1="217.4" y1="84.9" x2="219.3" y2="83.9" />
				<line class="st61" x1="217.4" y1="84.9" x2="219.3" y2="83.9" />
				<line class="st64" x1="218.2" y1="86.2" x2="220" y2="85.3" />
				<line class="st61" x1="218.2" y1="86.2" x2="220" y2="85.3" />
				<line class="st64" x1="218.8" y1="87.5" x2="220.7" y2="86.6" />
				<line class="st61" x1="218.8" y1="87.5" x2="220.7" y2="86.6" />
				<line class="st64" x1="216.1" y1="82.1" x2="218" y2="81.2" />
				<line class="st61" x1="216.1" y1="82.1" x2="218" y2="81.2" />
				<line class="st64" x1="212" y1="73.9" x2="213.9" y2="73" />
				<line class="st61" x1="212" y1="73.9" x2="213.9" y2="73" />
				<line class="st64" x1="211.3" y1="72.5" x2="213.2" y2="71.6" />
				<line class="st61" x1="211.3" y1="72.5" x2="213.2" y2="71.6" />
				<path class="st60" d="M301.9,189c-3.8,3.6-10.6,2.6-15.1-2.3c-0.7-0.7-1.3-1.5-1.8-2.3c-1-1.9-1.6-4.1-2-6.4
				c-0.7-4.7,0-9.6,3-12.2c1.7-1.5,4.2-2.3,7.5-1.9c3.2,0.6,5.1,3.2,6.1,6.2c0.4,1.2,0.7,2.4,0.9,3.6c0.1,0.4,0.1,0.8,0.2,1.2
				c0.3,2,1.3,3.8,2.8,5.2c0.2,0.2,0.4,0.4,0.7,0.6C304.8,184,304.1,187,301.9,189"/>
				<path class="st62" d="M302.3,189.4c-1.3,1.2-3,2-4.7,2.2c-1.7,0.2-3.5,0-5.1-0.5c-1.6-0.6-3.1-1.4-4.5-2.5
				c-0.7-0.5-1.3-1.1-1.8-1.8c-0.6-0.6-0.9-1.5-1.4-2.1l0,0l0,0c-0.9-1.9-1.5-3.9-1.8-5.9c-0.3-2-0.4-4.1-0.2-6.1
				c-0.2-2.1,0.6-4.2,1.9-5.9c1.3-1.7,3.4-2.9,5.5-3.2c1.1-0.2,2.1-0.2,3.2,0c1.1,0.1,2.1,0.5,3.1,1.2c1.8,1.2,3,3.2,3.7,5.2
				c0.4,1,0.6,2,0.8,3c0.1,0.5,0.2,1,0.3,1.5l0.1,0.8c0,0.2,0.1,0.5,0.1,0.7c0.2,0.9,0.6,1.8,1.2,2.6c0.5,0.8,1.2,1.4,2,2.1l0.1,0.1
				l0,0.2c0.3,1.5,0.3,3.1-0.1,4.7C304.2,186.9,303.4,188.3,302.3,189.4 M301.6,188.7c1-1,1.7-2.2,2.1-3.6c0.3-1.4,0.4-2.8,0.1-4.2
				l0.2,0.3c-0.7-0.6-1.6-1.4-2.2-2.3c-0.6-0.9-1.1-1.9-1.3-3c-0.1-0.3-0.1-0.5-0.1-0.8l-0.1-0.7c-0.1-0.5-0.2-1-0.3-1.5
				c-0.2-1-0.5-1.9-0.8-2.9c-0.7-1.8-1.7-3.6-3.3-4.6c-0.8-0.5-1.7-0.9-2.6-1c-1-0.1-2-0.1-2.9,0c-1.9,0.3-3.7,1.2-4.8,2.8
				c-0.6,0.8-1,1.6-1.4,2.5l-0.2,0.7c-0.1,0.2-0.1,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.1,0.5-0.1,0.7
				c-0.5,4,0.4,8.2,2.2,11.7l0,0c1,1.3,2.1,2.5,3.3,3.5c1.2,1,2.6,1.8,4.1,2.3c1.5,0.5,3.1,0.7,4.7,0.5
				C299,190.4,300.4,189.7,301.6,188.7"/>
				<path class="st64" d="M245.9,134.9c-1.1,1.6-3.5,2-5.2,0.8c-1.7-1.2-2.2-3.5-1.1-5.1c1.1-1.6,3.5-2,5.2-0.8
				C246.6,131,247.1,133.3,245.9,134.9"/>
				<path class="st61" d="M245.9,134.9c-1.1,1.6-3.5,2-5.2,0.8c-1.7-1.2-2.2-3.5-1.1-5.1c1.1-1.6,3.5-2,5.2-0.8
				C246.6,131,247.1,133.3,245.9,134.9z"/>
				<line class="st64" x1="240.4" y1="131.2" x2="245" y2="134.3" />
				<path class="st62" d="M240.6,130.8c0.9,0.4,1.6,0.9,2.4,1.4c0.7,0.6,1.5,1.1,2.2,1.8l-0.4,0.6c-0.9-0.4-1.7-0.9-2.4-1.4
				c-0.8-0.5-1.5-1.1-2.2-1.7L240.6,130.8z"/>
				<path class="st65" d="M218,57.5c0.3,4.8-0.2,9.7-1.4,14.4c-2.7,10-9.1,19.1-17.9,24.4c-8.9,5.3-25.6-0.3-27-1.1" />
				<path class="st60" d="M80.1,114.8c2.2-0.1,3.8-1.3,5.6-2.2c1.7-0.9,3.5-1.9,5.2-2.9c3.3-1.9,6.6-4,9.8-6l7.7-2.8
				c2.8-1,5.6-2.1,8.3-3.2c1.5-0.2,3.1-0.4,4.6-0.6c1.9-0.3,3.8-0.5,5.6-0.8c2-0.3,3.5-2,3.6-4c0.1-2.4-1.4-3-3.6-3.6
				s-4.3-1.1-6.2-1.1c-1.9-0.1-3.8-0.1-5.7-0.1c-0.2,0-0.4,0-0.5,0.1l-0.7,0.2c-3,0.7-5.9,1.4-8.9,2.2L96,92
				c-0.2,0.1-0.5,0.2-0.7,0.3l-0.5,0.3c-3.5,1.8-7,3.6-10.5,5.5c-1.7,0.9-3.4,1.9-5.1,2.9c-1.7,1-3.5,1.8-4.7,3.7"/>
				<path class="st62" d="M80.1,114.8c2-0.3,3.5-1.5,5.2-2.5c1.7-1,3.4-1.9,5.1-2.9l10-6.1l0.1,0l0.1,0l8-3l8-3l0.1,0l0.1,0
				c3.2-0.4,6.5-0.9,9.7-1.4c0.4-0.1,0.8-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.9-0.5c0.6-0.4,1-1,1.3-1.6c0.3-0.6,0.3-1.4,0.2-2
				c-0.1-0.6-0.5-1.1-1.1-1.4c-0.6-0.3-1.4-0.6-2.2-0.8c-0.8-0.2-1.6-0.4-2.3-0.6c-1.6-0.3-3.1-0.5-4.8-0.5L117,88l-1.2,0l-0.6,0
				c-0.2,0-0.3,0-0.5,0l-4.8,1.1c-3.2,0.8-6.4,1.5-9.6,2.3L98,92.1l-1.2,0.3l-0.6,0.1l-0.5,0.2c-1.4,0.7-2.9,1.5-4.3,2.2L87,97.2
				c-1.5,0.7-2.9,1.5-4.3,2.3c-1.4,0.8-2.8,1.6-4.3,2.4c-1.5,0.7-2.9,1.4-4,2.7c0.6-1.6,2.1-2.7,3.5-3.5c1.3-0.9,2.8-1.7,4.2-2.5
				c1.4-0.8,2.9-1.6,4.3-2.4l4.4-2.3c1.5-0.8,2.9-1.5,4.4-2.3l0.7-0.3l0.6-0.2l1.2-0.3l2.4-0.6c3.2-0.8,6.4-1.6,9.6-2.4l4.8-1.2
				c0.3,0,0.6-0.1,0.8-0.1l0.6,0l1.2,0l2.5,0c0.8,0,1.6,0,2.5,0.1c0.8,0.1,1.7,0.2,2.5,0.4c0.8,0.2,1.6,0.4,2.4,0.6
				c0.8,0.2,1.6,0.4,2.4,0.9c0.4,0.2,0.8,0.5,1.2,0.9c0.3,0.4,0.5,0.9,0.6,1.4c0.2,1,0.1,1.9-0.3,2.8c-0.4,0.9-1,1.6-1.8,2.2
				c-0.4,0.3-0.8,0.5-1.3,0.6c-0.5,0.2-0.9,0.2-1.3,0.3c-3.2,0.5-6.5,0.9-9.8,1.4l0.1,0l-8,3l-8,2.9l0.1-0.1l-10.1,6.1
				c-1.7,1-3.4,1.9-5.2,2.8c-0.9,0.4-1.7,0.9-2.7,1.3C82.1,114.7,81.1,114.9,80.1,114.8"/>
				<path class="st62" d="M164.3,89.9c0.1-1,0.3-2.1,0.9-3c0.5-0.9,1.4-1.6,2.4-2c2-0.7,4.2-0.6,6.2-0.2c2.1,0.4,4.1,1.4,5.5,2.9
				c1.5,1.4,2.7,3.1,4,4.7c0.6,0.8,1.3,1.5,2,2.3c0.7,0.7,1.5,1.3,2.4,1.9c1.6,1.2,3.4,2.3,5.1,3.4c3.5,2.1,7.2,3.9,11,5.2
				c3.8,1.4,7.8,2.3,11.9,2.7c4,0.4,8.2,0.4,12.2-0.5c2-0.5,3.9-1.2,5.6-2.3c1.7-1.1,3.2-2.5,4.3-4.2c1-1.7,1.6-3.7,1.6-5.7
				c0-2-0.6-4-1.9-5.4l0.7-0.6c1.4,1.7,1.9,3.9,1.8,6c-0.1,2.1-0.8,4.2-1.9,5.9c-1.1,1.8-2.7,3.2-4.5,4.3c-1.8,1.1-3.7,1.8-5.7,2.3
				c-4,0.9-8.2,0.9-12.3,0.5c-4.1-0.4-8.1-1.4-12-2.7c-3.9-1.3-7.6-3.1-11.2-5.2c-1.8-1-3.5-2.1-5.2-3.3c-0.8-0.6-1.7-1.2-2.4-1.9
				c-0.8-0.7-1.4-1.5-2.1-2.3c-1.3-1.6-2.5-3.2-4-4.6c-1.4-1.4-3.2-2.3-5.2-2.7c-1-0.2-2-0.3-3-0.4c-1-0.1-2,0-3,0.2
				c-0.5,0.1-0.9,0.3-1.3,0.6c-0.4,0.3-0.7,0.7-1,1.1c-0.5,0.8-0.8,1.9-0.8,2.9L164.3,89.9z"/>
			</g>
		</g>

	)
}
