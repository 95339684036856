import React from 'react'
import { useSelector } from 'react-redux'

export const Clitoris1 = (props) => {

	const r_allWhite = useSelector(state => state.parameters.allWhite) 

    return (
        <g id="Clitoris">
            <path fill={r_allWhite ? 'white' : ' #e54d4f'} className="st0" d="M202.9,136.3c-0.8,0.6-7.7-6.2-9.4-8.7s-3.2-7-5.5-9.1s-10.2-8.7-11.7-10.4s-6.4-9.8-9.4-9.8s-7.9,8-9.4,9.8
		s-9.5,8.2-11.7,10.4s-3.8,6.7-5.5,9.1s-8.6,9.3-9.4,8.7s9.2-17,11.7-20.6s12.1-13.4,15.9-17.7s4.5-24.2,8.4-24.2s4.6,20,8.4,24.2
		s13.3,14.1,15.8,17.7S203.7,135.7,202.9,136.3z"/>
            <path fill={r_allWhite ? 'white' : ' #e54d4f'} className="st1" d="M169.1,91.4c0.6,2.9,6.1,11.4,8.6,13.9s15.7,20.6,9.9,23.7s-11.2-5-13.8-9.6s-4.2-18.8-7-18.8
		s-4.4,14.2-7,18.8c-2.6,4.7-8,12.7-13.8,9.6s7.4-21.2,9.9-23.7s8-11,8.6-13.9"/>
            <path className="st2" d="M169.1,91.4c0.6,1.6,1.4,3.1,2.4,4.6c0.9,1.5,1.9,2.9,2.9,4.3c1,1.4,2,2.8,3.1,4c0.5,0.6,1.2,1.2,1.8,1.9
		s1.1,1.4,1.6,2.1c2.1,2.8,4,5.7,5.7,8.8c0.9,1.5,1.6,3.1,2.3,4.8c0.3,0.8,0.6,1.7,0.8,2.6c0.2,0.9,0.3,1.9,0.1,2.8
		c-0.1,0.5-0.3,1-0.6,1.4c-0.3,0.4-0.8,0.8-1.2,1c-0.9,0.4-1.8,0.7-2.8,0.7c-1.9-0.1-3.7-0.8-5.1-2.1c-2.6-2.4-4.9-5.2-6.5-8.3
		c-0.8-1.6-1.5-3.3-1.9-5c-0.5-1.7-0.9-3.4-1.3-5s-0.8-3.3-1.4-5c-0.2-0.8-0.5-1.6-0.9-2.3c-0.2-0.4-0.3-0.7-0.6-1
		c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.3-0.4,0.6-0.6,1c-0.3,0.8-0.6,1.5-0.9,2.3c-0.5,1.6-0.9,3.3-1.4,5
		s-0.8,3.4-1.3,5c-0.4,1.7-1.1,3.4-1.9,5c-1.7,3.1-3.9,6-6.5,8.4c-1.4,1.3-3.2,2-5.1,2.1c-1,0-1.9-0.3-2.8-0.7
		c-0.5-0.2-0.9-0.6-1.2-1c-0.3-0.4-0.5-0.9-0.6-1.4c-0.2-0.9-0.1-1.9,0.1-2.8c0.2-0.9,0.5-1.7,0.8-2.6c0.6-1.6,1.4-3.2,2.2-4.8
		c1.7-3,3.6-6,5.7-8.8c0.5-0.7,1-1.4,1.6-2.1s1.2-1.4,1.8-1.9c1.1-1.3,2.2-2.7,3.1-4.1c1-1.4,2-2.8,2.9-4.3c1-1.4,1.7-3,2.3-4.6
		c-0.2,0.9-0.4,1.7-0.8,2.5c-0.3,0.8-0.7,1.6-1.1,2.4c-0.8,1.5-1.7,3-2.6,4.5c-0.9,1.5-2,2.9-3.1,4.2c-0.6,0.7-1.2,1.3-1.7,2
		s-1,1.4-1.6,2c-2,2.8-3.9,5.7-5.5,8.7c-0.8,1.5-1.5,3-2.1,4.6c-0.7,1.5-0.9,3.1-0.8,4.7c0.1,0.7,0.5,1.2,1.2,1.5
		c0.7,0.3,1.4,0.5,2.2,0.5c1.6-0.1,3.1-0.8,4.3-1.8c2.5-2.3,4.6-5,6.2-8c0.8-1.5,1.4-3.1,1.8-4.7c0.5-1.6,0.9-3.3,1.3-5
		s0.8-3.4,1.4-5c0.3-0.9,0.6-1.7,1-2.5c0.2-0.4,0.4-0.9,0.7-1.2c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.6-0.3,1-0.4c0.4,0,0.7,0.1,1,0.4
		c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.4,0.5,0.8,0.7,1.2c0.4,0.8,0.7,1.7,1,2.5c0.5,1.7,1,3.4,1.4,5s0.8,3.4,1.3,5c0.4,1.6,1,3.2,1.8,4.7
		c1.6,3,3.6,5.7,6.2,7.9c1.2,1.1,2.7,1.7,4.3,1.8c0.8,0,1.5-0.2,2.2-0.5c0.6-0.3,1.1-0.8,1.2-1.5c0.1-1.6-0.1-3.2-0.8-4.7
		c-0.6-1.6-1.3-3.1-2.1-4.6c-1.6-3-3.5-5.9-5.5-8.7c-0.5-0.7-1-1.4-1.6-2s-1.1-1.2-1.7-1.9c-1.1-1.4-2.1-2.8-3.1-4.2
		c-1-1.5-1.8-3-2.6-4.5c-0.4-0.8-0.8-1.6-1.1-2.4C169.6,93.1,169.3,92.2,169.1,91.4z"/>
        </g>
    )
}