import React from 'react'
import { useSelector } from 'react-redux'

export const Prepuce = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Prépuce" className="transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
            <path fill={r_labia_color} className="st--5" d="M167.4,80c0,3.7-8.6,16-9.8,17.4c1.8-6.1,6.6-26.2,9.6-26.2s7.1,18.8,9.5,25.2
        C175.7,93.5,167.4,83.6,167.4,80z"/>
            <path className="st2" d="M175.1,95.8c-0.5-1.4-1.1-2.7-1.9-4c-0.7-1.3-1.5-2.5-2.3-3.8s-1.6-2.5-2.4-3.8c-0.4-0.6-0.8-1.3-1.1-2
        c-0.2-0.3-0.3-0.7-0.5-1.1c-0.2-0.4-0.3-0.8-0.3-1.2h1.1c-0.1,0.9-0.3,1.8-0.7,2.6c-0.3,0.8-0.7,1.6-1.1,2.3
        c-0.8,1.5-1.6,3-2.5,4.4s-1.8,2.8-2.8,4.2c-1,1.4-2.1,2.7-3.2,3.9c1-1.4,1.9-2.8,2.8-4.2s1.8-2.9,2.6-4.3s1.6-2.9,2.3-4.5
        c0.3-0.8,0.7-1.5,1-2.3c0.3-0.7,0.5-1.5,0.6-2.2l0,0c0-0.3,0.3-0.5,0.5-0.5c0,0,0,0,0,0c0.3,0,0.5,0.2,0.5,0.5
        c0.1,0.7,0.3,1.3,0.6,1.9c0.3,0.6,0.7,1.3,1,1.9c0.7,1.3,1.5,2.5,2.2,3.9s1.4,2.6,2,4C174.3,92.9,174.8,94.3,175.1,95.8z"/>
        </g>
    )
}