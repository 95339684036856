import React, {useEffect, useState} from "react";
import {first, second} from "../services/translation/i18n";
import {LanguageSelector} from "../components/languageSelector/languageSelector";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {GlobalLayout} from "../components/globallayout/globalLayout";

export default function Language() {
    const r_action = useDispatch()
    const r_reload = useSelector((state) => state.language.reload);

    const [languagesList, setLanguagesList] = useState([])
    const [selectedLanguages, setSelectedLanguages] = useState(['en'])

    const r_loadingLanguage = useSelector(state => state.parameters.loadingLanguage)
    const r_switchTranslate = useSelector(state => state.parameters.switchTranslate)
    const [loading, setLoading] = useState(true)


    const saveNewLanguage = async () => {

        let stringLanguages = selectedLanguages[0]

        if (selectedLanguages[1] && selectedLanguages[0] !== selectedLanguages[1]) {
            stringLanguages = `${selectedLanguages[0]},${selectedLanguages[1]}`
        }


        fetch(
            `https://bioscope-60507.grdnrs-dev.com/wp-json/bioscope/v1/get-translations?languages=${stringLanguages}`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    let tmpResult = {...result}
                    for (let i = 0; i < 2; i++) {
                        if (result[selectedLanguages[i]]) {
                            Object.keys(result[selectedLanguages[i]]).forEach(keyTrad => {
                                if (result[selectedLanguages[i]][keyTrad]) {
                                    tmpResult[selectedLanguages[i]] = {
                                        ...tmpResult[selectedLanguages[i]],
                                        [keyTrad]: tmpResult[selectedLanguages[i]][keyTrad].replaceAll(/\\/g, '')
                                    }
                                }

                            })
                        }
                    }

                    first.addResources(selectedLanguages[0], 'translation', tmpResult[selectedLanguages[0]])
                    second.addResources(selectedLanguages[1] ? selectedLanguages[1] : selectedLanguages[0], 'translation', tmpResult[selectedLanguages[1] ? selectedLanguages[1] : selectedLanguages[0]])
                    r_action({type: 'RELOAD'})
                    r_action({type: 'SET_USED_LANGUAGE', codeUsedLanguages: selectedLanguages})
                    r_action({type: 'SET_LOADING_LANGUAGE', loadingLanguage: true});


                },
                (error) => {
                    console.log("error get-translations", error);
                }
            )
    }


    useEffect(() => {
        fetch("https://bioscope-60507.grdnrs-dev.com/wp-json/bioscope/v1/get-languages")
            .then((res) => res.json())
            .then(
                (result) => {
                    let orderAlphabetically = result.sort(function (a, b) {
                        var textA = a.translation.toUpperCase();
                        var textB = b.translation.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    setLanguagesList(orderAlphabetically)
                    result.forEach(resultLanguage => {

                        console.log(resultLanguage)
                        first.addResource(resultLanguage.code, 'translation', 'bienvenue', resultLanguage.bienvenue)
                        second.addResource(resultLanguage.code, 'translation', 'bienvenue', resultLanguage.bienvenue)
                        first.addResource(resultLanguage.code, 'translation', 'intro', resultLanguage.intro)
                        second.addResource(resultLanguage.code, 'translation', 'intro', resultLanguage.intro)
                        first.addResource(resultLanguage.code, 'translation', 'choose-your-language', resultLanguage['choose-your-language'])
                        second.addResource(resultLanguage.code, 'translation', 'choose-your-language', resultLanguage['choose-your-language'])
                        first.addResource(resultLanguage.code, 'translation', 'start', resultLanguage.start)
                        second.addResource(resultLanguage.code, 'translation', 'start', resultLanguage.start)
                        first.addResource(resultLanguage.code, 'translation', 'choix-langue-intro', resultLanguage['choix-langue-intro'])
                        second.addResource(resultLanguage.code, 'translation', 'choix-langue-intro', resultLanguage['choix-langue-intro'])
                        first.addResource(resultLanguage.code, 'translation', 'langue', resultLanguage['langue'])
                        second.addResource(resultLanguage.code, 'translation', 'langue', resultLanguage['langue'])
                        first.addResource(resultLanguage.code, 'translation', 'bouton-about', resultLanguage['bouton-about'])
                        second.addResource(resultLanguage.code, 'translation', 'bouton-about', resultLanguage['bouton-about'])
                        first.addResource(resultLanguage.code, 'translation', 'primary-language', resultLanguage['primary-language'])
                        second.addResource(resultLanguage.code, 'translation', 'secondary-language', resultLanguage['secondary-language'])
                        r_action({type: 'RELOAD'})
                    });
                    setLoading(false)
                },
                (error) => {
                    console.log('error get language', error)
                }
            );
    }, []);


    return (
        <GlobalLayout className="bg-pattern-1" onLanguage={true} saveNewLanguage={saveNewLanguage} loading={loading}>
            <div className="flex f-column f-center f-1">
                <div className="w-xl-content flex f-full f-column f-1">
                    <div className="mt-2">
                        <h1 className="text-white text-center text-bolder text-30">
                            {first.t("bienvenue")}
                        </h1>
                        {first.language !== second.language ?
                            <h2 className="text-white text-center text-18 mt-03">{second.t("bienvenue")}</h2> : ""}

                        <p className="text-white mt-1 text-18 text-center">
                            {first.t("choix-langue-intro").replaceAll(/\\/g, '')}
                        </p>
                        {first.language !== second.language ?
                            <p className="text-white text-14 text-center mt-03">{second.t("choix-langue-intro").replaceAll(/\\/g, '')}</p> : ""}
                    </div>
                    <LanguageSelector languagesList={languagesList} setSelectedLanguages={setSelectedLanguages}/>
                </div>
                <Link
                    to="/anatomie"
                    className="block fixed bg-secondary py-1 w-full flex f-center f-column"
                    style={{bottom: 0}}
                    onClick={() => saveNewLanguage()}
                >
                    <div className="text-uppercase text-bolder text-white mt-05">
                        {first.t("start")}
                    </div>
                    {first.language !== second.language ?
                        <div className="text-uppercase text-white mb-05 mt-03">{second.t("start")}</div> : ""}
                </Link>
            </div>
        </GlobalLayout>
    );
}
