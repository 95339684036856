import React from 'react'
import { useSelector } from 'react-redux'

export const Pont = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="Pont" className="st4 transition" style={{opacity: props.hide ? 0 : props.show ? 1 : 0}}>
                <path className="st2" d="M163.1,117.5c-0.2-0.6-0.4-1.3-0.4-2c0-0.7,0.1-1.5,0.5-2.1c0.3-0.7,0.9-1.3,1.6-1.6
				c0.7-0.4,1.4-0.5,2.2-0.5c1.6,0,3,0.8,3.8,2.2c0.3,0.7,0.5,1.4,0.4,2.1c-0.1,0.7-0.3,1.4-0.6,2c0.3-1.3,0.1-2.6-0.5-3.7
				c-0.7-1-1.9-1.6-3.1-1.6c-1.2-0.1-2.4,0.5-3,1.5C163.3,114.9,163,116.2,163.1,117.5z"/>
            </g>
    )
}
