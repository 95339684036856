import React from 'react'
import { useSelector } from 'react-redux'

export const PliD1 = (props) => {

    const r_labia_color = useSelector(state => state.parameters.labiaColor)

    return (
        <g id="PliD1">
            <path className="st2" d="M174.4,107.7c0.1,1.6,0.3,3.2,0.4,4.8c0.1,1.6,0,3.3-0.4,4.9c-0.4,1.6-1,3.1-1.7,4.6
							c-0.7,1.4-1.3,3-1.8,4.5c0.3-1.6,0.8-3.1,1.5-4.6c0.7-1.5,1.2-3,1.6-4.5c0.3-1.6,0.5-3.2,0.4-4.8L174.4,107.7z"/>
        </g>
    )
}